import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactSelect from 'react-select';
import InputType1 from '../../../../../common/InputType1';
import Table1Container from './Table1Container';

const PreviewContent = (props) => {
  const { onSearch, setSearchText, specList, searchText, loading } = props;
  return (
    <>
      <div className="bg-sky-white p-3 d-flex flex-wrap sm-radius">
        <div className="col-4 pe-4 mb-4">
          <InputType1 placeholder="Yard Name" value="" />
        </div>
        <div className="col-2 pe-4">
          <InputType1 placeholder="Days In Dock" value="" />
        </div>
        <div className="col-2 pe-4">
          <InputType1 placeholder="Days Afloat" value="" />
        </div>
        <div className="col-2 pe-4">
          <InputType1 placeholder="Total Repair Days" value="" />
        </div>
        <div className="col-2 pe-4">
          <InputType1 placeholder="Discount [%]" value="" />
        </div>
        <div className="col-6 pe-4">
          <InputType1 placeholder="Payment Terms" value="" />
        </div>
        <div className="col-6 pe-4">
          <InputType1 placeholder="Yard Comments" value="" />
        </div>
      </div>
      <div className="border-down my-3" />
      <div>
        <div className="d-flex justify-content-between align-items-center my-4">
          <div className="search large search-large spec-search">
            <div className="d-flex justify-content-end">
              <span
                id="search-click"
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search-pre')?.focus(); }}
              />
              <span
                className="icon-search"
                id="search-click1"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              value={searchText}
              className="text-box large with-close"
              placeholder="Search by Sec No.,Sec Name, Spec No., Spec Name, ServiceLine No., ServiceLine Name"
              onKeyPress={onSearch}
              id="search-pre"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div>
            <span data-tooltip-id="view-child" data-tooltip-content={`Currency ${specList.projectCurrency}`}>
              <ReactSelect value={{ label: `Currency ${specList.projectCurrency}`, value: '' }} isDisabled />
            </span>
            <Tooltip data-tooltip-id="view-child" place="bottom" className="tooltip mt-3" />
          </div>
        </div>
        <Table1Container
          {...props}
          loading={loading}
        />
      </div>
      <div className="border-down my-3" />
      <div className="bg-sky-white p-3 d-flex justify-content-end sm-radius">
        <div className="w-270 pe-4">
          <InputType1 label="Discount" value="" />
        </div>
        <div className="w-270 pe-4">
          <InputType1 label="Estimate Without Discount" value="" />
        </div>
        <div className="w-270 pe-4">
          <InputType1 label="Yard Final Estimate" value="" />
        </div>
      </div>
    </>
  );
};
export default PreviewContent;
