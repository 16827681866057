import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { getComponents, bulkUploadComponent, generateTemplateComponent } from '../../Vessel/services';

import InnerTable from './InnerTable';
import ModalBox from '../../../../common/ModalBox';
import BulkUploadModal from '../../../DataConfig/CurrencyContainer/bulkUploadModal';
import showAlert from '../../../../../utils/alert';
import helper from '../../../../../utils/helper';
import Loader from '../../../../common/loader';
import { useStatus } from '..';

const Components = () => {
  const params = qs.parse(window.location.search);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  const { status } = useStatus() || {};
  const [file, setFile] = useState();
  const [uploadModal, setUploadModal] = useState(false);

  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const { MAX_FILE_SIZE } = helper;

  const reloadComponent = () => {
    getComponents({ id: params.id }).then((res) => {
      setLoading(false);
      setTableData(res.data);
    });
  };
  useEffect(() => {
    reloadComponent();
    setLoading(true);
  }, []);
  const ondrop = (e) => {
    const [first] = e.target.files;

    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;

      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);

        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }

    e.target.files = [];

    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('componentDataFile', file);

    bulkUploadComponent(body, params.id).then((res) => {
      if (res.success) {
        reloadComponent();
        setLoading(false);
        showAlert(res.success ? 'Successfully Uploaded' : res.error?.response?.data?.message, res.success ? 'success' : 'error');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setLoading(false);
        setUploadModal(false);
        setFile('');
        setIsUpload(true);
      }
    });
  };
  const generatTemplateForExcel = () => {
    generateTemplateComponent();
  };
  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };
  if (loading) {
    return (
      <div data-testid="v-components"><Loader /></div>
    );
  }
  return (
    <div className="pt-4" data-testid="v-components">
      {openModal ? (
        <ModalBox
          heading="Upload Excel"
          closeModal={() => {
            setOpenModal(false);
          }}
          buttonName="Save"
          modalClassName="medium modal-scroll"
        />
      ) : (
        ''
      )}
      {
        openDeleteModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Delete"
            closeModal={() => setOpenDeleteModal(false)}
            buttonName="Delete"
          />
        ) : (
          ''
        )
      }
      <button
        className="primary-btn large mb-4"
        id="add-name"
        type="button"
        disabled={status !== 'Active'}
        onClick={() => {
          setUploadModal(true);
        }}
      >
        Bulk Upload
      </button>
      {uploadModal && (
      <ModalBox
        modalClassName="medium"
        heading="Upload Excel"
        onSave={() => { bulkUpload(); }}
        closeModal={() => onCloseModal()}
        buttonName="Upload"
        saveDisable={isUpload}
      >
        <BulkUploadModal
          file={file}
          ondrop={ondrop}
          name="Component"
          setFile={setFile}
          generatTemplateForExcel={generatTemplateForExcel}
        />
      </ModalBox>
      )}
      <div className="card mb-5 p-5">
        {tableData && tableData.length
          ? <InnerTable innerTableData={tableData} />
          : <div className="d-flex justify-content-center pt-5">No data found</div>}
      </div>
    </div>
  );
};
export default Components;
