import React from 'react';
import Cards from './cards';
// import Loader from '../../../common/loader';

const CardWrapper = (props) => {
  const { deferredList } = props;
  if (deferredList?.totalItems > 0) {
    return (
      <Cards
        tableData={deferredList?.items}
      />
    );
  }

  return (
    <div className="resource-footer-no-data mt-5 p-4 d-flex justify-content-center " data-testid="deferredList-cardwrapper">
      <span className="icon-exclamation_fill font-24
brand-color me-3 animate__animated animate__headShake "
      />
      <div className="font-18 animate__animated animate__headShake">No data found</div>
    </div>
  );
};
export default CardWrapper;
