import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { fileImages } from '../../../../../common/mappingConst';
import helper from '../../../../../../utils/helper';
import ModalBox from '../../../../../common/ModalBox';

const Attachments = (props) => {
  const { allFiles, deleteModal, setOpenDeleteModal,
    openDeleteModal, removeAttach, fromNonSpec, isTSI, projectDetails } = props;
  const { formatBytes } = helper;
  return (
    <div>
      {allFiles && allFiles?.length > 0 ? (
        allFiles?.map((urlData) => {
          return (
            <div className="p-2rem mb-4 d-flex card justify-content-between mt-4" data-testid="attachment">
              <div className="d-flex align-items-center">
                <div className="thmb-icon-md me-3">
                  <span className={fileImages[_.toLower(urlData.type)]} />
                </div>
                <div>
                  <div
                    className="value"
                  >
                    <span data-tooltip-id="project-fname" data-tooltip-content={urlData?.fname || urlData?.name}>
                      {urlData?.fname || urlData?.name}
                    </span>

                  </div>
                  <div className="text-ellipsis">
                    <span className="value" data-tooltip-id="project-fname" data-tooltip-content={urlData?.name}>{formatBytes(urlData?.size)}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex col-gap-2">
                {!fromNonSpec && (
                <button
                  id="delete"
                  className="link-btn"
                  disabled={!isTSI || projectDetails?.isClosed}
                  type="button"
                  onClick={() => { deleteModal(urlData); }}
                >
                  <span className="icon-delete font-24" />
                </button>
                )}

                <button type="button" className="link-btn large">
                  <a
                    href={urlData?.path}
                    target="_blank"
                    download="downloaded_file"
                    className=""
                    rel="noreferrer"
                  >
                    <span className="icon-download font-22" />
                  </a>
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">
            No data found
          </div>
          <Tooltip id="project-fname" place="top" className="tooltip mt-3" />
        </div>
      )}
      {openDeleteModal ? (
        <ModalBox
          modalClassName="small"
          heading="Remove Attachment"
          onSave={() => { removeAttach(); }}
          closeModal={() => {
            setOpenDeleteModal(false);
          }}
          buttonName="Delete"
        >
          <p>Are you sure to delete the attachment?</p>
        </ModalBox>
      ) : ('')}
    </div>
  );
};
export default Attachments;
