/* eslint-disable consistent-return */
/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import { cloneDeep } from 'lodash';
import { getCancelledDefferredJobCount, getCancelledJob, updateSpecJobStatus } from '../services';
import { statusCancelledColor } from '../../Specify/SpecsList/WODefectList/Children/utilities';
import Loader from '../../../../../common/loader';
import ModalBox from '../../../../../common/ModalBox';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';

const CancelledJobsModal = (props) => {
  const { visibleSpec, setVisibleSpec, searchText, setSearchText, projectId, closeModal, onSave,
    projInfo, getSpecListData } = props;
  const { roles } = helper;
  const [cancelledJob, setCancelledJob] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const statusList = [
    { label: 'Not Started', value: 0, isDisabled: false },
    { label: 'In Progress', value: 1, isDisabled: false },
    { label: 'Completed', value: 4, isDisabled: false },
    { label: 'Deferred to next DD', value: 6, isDisabled: true },
    { label: 'Cancelled', value: 3, isDisabled: true },
    { label: 'On Hold', value: 5, isDisabled: false },
    { label: 'Delayed', value: 2, isDisabled: false }
  ];
  let isSearchUpdated = false;
  const searchInputRef = useRef(null);
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isAuthor = userRole === roles.AUTHOR;

  const reload = () => {
    setLoading(true);
    getCancelledJob({ projectId, status: 0, searchString: isSearchUpdated ? searchText : '' }).then((res) => {
      if (res.success) {
        setCancelledJob(res.data);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });

    getCancelledDefferredJobCount(projectId).then((res) => {
      if (res.success) {
        setCount(res.data);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    reload();
  }, []);
  const changeVisibility = (key) => {
    const visibility = [...visibleSpec];
    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }
    setVisibleSpec(visibility);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearchUpdated = true;
      getCancelledJob({ projectId, status: 0, searchString: isSearchUpdated ? searchText : '' }).then((res) => {
        if (res.success) {
          setCancelledJob(res.data);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!searchText) {
      getCancelledJob({ projectId, status: 0, searchString: isSearchUpdated ? searchText : '' }).then((res) => {
        if (res.success) {
          setCancelledJob(res.data);
        }
        setLoading(false);
      });
    }
  }, [searchText]);

  useEffect(() => {
    if (visibleSpec?.length === 2) {
      setTableData(cancelledJob);
    } else if (visibleSpec?.includes(1)) {
      setTableData(cancelledJob?.filter((item) => item.statusName === 'Cancelled'));
    } else if (visibleSpec.includes(2)) {
      setTableData(cancelledJob?.filter((item) => item.statusName !== 'Cancelled'));
    } else {
      setTableData([]);
    }
  }, [visibleSpec, cancelledJob]);

  const updateJobStatus = (specId, status) => {
    setLoading(true);
    updateSpecJobStatus(specId, status).then((res) => {
      if (res.success) {
        showAlert('Specification job status updated successfully', 'success');
        reload();
        getSpecListData();
      } else {
        setLoading(false);
      }
    }).catch(() => {
      setLoading(false);
    });
  };

  const updateTable = (status, specId) => {
    const temp = cloneDeep(tableData);
    const updatedData = temp.map((i) => {
      if (i.id === specId) {
        return { ...i, status };
      }
      return i;
    });
    setTableData(updatedData);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999
    }),
    menuList: (provided) => ({
      ...provided
    }),
    option: (provided, state) => ({
      ...provided
    })
  };

  return (
    <div data-testid="cancelled-jobs">
      <ModalBox
        modalClassName="medium"
        heading={(
          <div className="d-flex align-items-center">
            <div className="">Deferred/Cancelled Jobs</div>
            <div className="couter-bubbles status-due ms-3 font-12">
              {count?.totalCount && tableData?.length !== count?.totalCount ? `${tableData?.length} / ${count?.totalCount}` : count?.totalCount}
            </div>
          </div>
        )}
        closeModal={closeModal}
        isCloseModal
        removeCancelBtn
        onSave={onSave}
      >
        <div className="deferr">
          <div className="d-flex mb-3 spec-search">
            <div className="search col-2 large">
              <div className="d-flex justify-content-end">
                <span className="icon-search" id="Search" onClick={() => onSearch({ key: 'Enter' })} />
                <span
                  className={searchText ? 'icon-close-line' : ''}
                  onClick={() => {
                    setSearchText('');
                    searchInputRef.current.focus();
                  }}
                />
              </div>
              <input
                type="text"
                placeholder="Search by Item, Spec No"
                id="search"
                ref={searchInputRef}
                className="text-box large with-close"
                value={searchText}
                onKeyPress={onSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  isSearchUpdated = false;
                }}
              />
            </div>
            <div className="ms-5 mt-2 check-box">
              <input type="checkbox" id="Work Orders" name="" checked={visibleSpec.indexOf(1) > -1} onClick={() => changeVisibility(1)} />
              <label htmlFor="Work Orders">Cancelled</label>
            </div>
            <div className="ms-5 mt-2 check-box">
              <input type="checkbox" id="Defects" name="" checked={visibleSpec.indexOf(2) > -1} onClick={() => changeVisibility(2)} />
              <label htmlFor="Defects">Deferred to Next DD</label>
            </div>
          </div>
          <div className={tableData?.length ? 'scroll-wrap' : 'scroll-wrap height-auto'}>
            {visibleSpec?.length > 0
              && tableData?.map((items, index) => {
                return (
                  <div
                    className={index === 0 ? 'card p-4 mt-5 mb-4'
                      : index + 1 === tableData?.length ? 'card p-4 mb-5' : 'card p-4 mb-4'}
                    key={items.id}
                  >
                    <div className="d-flex">
                      <div className="col-8">
                        <div className="brand-color">{items?.specno}</div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center mt-2">
                            <div data-tooltip-id="project-variation" data-tooltip-content={items?.title} className="cursor-pointer">
                              {_.truncate(items?.title, { length: 40 })}
                            </div>
                            <div className="docTypeName black width-fit ms-2 white" style={{ background: statusCancelledColor(items.statusName) }}>
                              {items.statusName === 'DeferredToNextDD' ? 'Deferred To Next DD' : items.statusName}
                            </div>
                          </div>
                        </div>
                      </div>
                      {projInfo?.phase === 4
                        && (
                          <Select
                            className="modal-select ms-4 col-4 ms-auto"
                            id="jobStatus"
                            placeholder="Update Job Status"
                            options={statusList}
                            menuPlacement="auto"
                            styles={customStyles}
                            onChange={(e) => {
                              if (isAuthor) {
                                updateTable(e.value, items.id);
                                updateJobStatus(items?.id, e.value);
                              } else {
                                showAlert('Please contact your Vessel Supervisor to update the status of the specification.', 'error');
                              }
                            }}
                            value={statusList.find((roleItem) => roleItem.value === items.status)}
                          />
                        )}
                    </div>
                    <div className="d-flex mt-2 cursor-pointer">
                      <div className="value">Comments :</div>
                      <div className="label ms-2" data-tooltip-id="project-variation" data-tooltip-content={items?.comments}>
                        {_.truncate(items?.comments, { length: 60 })}
                      </div>
                    </div>
                  </div>
                );
              })}
            {tableData?.length === 0 && !loading && (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">No data found</div>
              </div>
            )}
            {loading && (
              <div style={{ height: '100px' }}>
                <Loader />
              </div>
            )}
            <Tooltip id="project-variation" place="top" className="tooltip mt-3" />
          </div>
        </div>
      </ModalBox>
    </div>
  );
};
export default CancelledJobsModal;
