/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import InputType1 from '../../../../../../common/InputType1';

const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

const Navbar = (props) => {
  const { saveForm, sectionList, handleChange, formData,
    isSaved, setIsSaved, specId, sectionName, addClicked, setIsAddClicked, isTSI,
    projInfo } = props;
  return (
    <div data-testid="sp-nav">
      {!isSaved ? (
        <div className="d-flex col-gap-2 align-items-center p-4 bg-sky-white card" data-testid="wodefect-nav">
          <div className="col-2">
            <label>
              Sec #
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              value={sectionList?.filter((option) => option.value === formData.sectionId)}
              onChange={(option) => handleChange(option, 'sectionId', 'select')}
              className="modal-select"
              label="code"
              isDisabled={specId !== ZERO_UUID}
              options={sectionList}
              id="sectionId"
              noMargin
            />
          </div>
          <div className="col-2">
            <InputType1
              value={formData?.specNoAutoGenerated}
              type="text"
              id="specNoAutoGenerated"
              disabled
              label="Spec #"
              className="text-box large"
              noMargin
            />
          </div>
          <div className="col-6">
            <InputType1
              value={formData?.specTitle}
              type="text"
              id="specTitle"
              isMandatory="true"
              label="Specification"
              className="text-box large"
              onChange={(e) => {
                handleChange(e, 'specTitle', 'text');
              }}
            />

          </div>
          <button
            id="save"
            className="primary-btn mt-auto large"
            data-testid="save"
            type="button"
            onClick={() => {
              saveForm();
            }}
          >
            {addClicked ? 'Add' : 'Save'}
          </button>
        </div>

      )
        : (
          <div className="d-flex col-gap-2 align-items-center bg-sky-white card-inverse p-3">
            <div className="d-flex col-3">
              <div className="">Sec # : </div>
              <div className="value ml-8">
                {sectionName}
              </div>
            </div>
            <div className="d-flex col-3">
              <div className="">Spec # : </div>
              <div className="value ml-8">{formData?.specNoAutoGenerated}</div>
            </div>
            <div className="d-flex col-4">
              <div>Specification:  </div>
              <div data-tooltip-id="project-poha" data-tooltip-content={formData?.specTitle} className="value ml-8 text-ellipsis">
                <span>{formData?.specTitle}</span>

              </div>
            </div>
            {isTSI && (
            <button type="button" onClick={() => { setIsSaved(false); setIsAddClicked(false); }} className="link-btn col-1" disabled={projInfo?.isClosed}>
              <span className="icon-edit font-22" />
            </button>
            )}

          </div>
        )}
      <Tooltip id="project-poha" place="bottom" className="tooltip" />
    </div>

  );
};

export default Navbar;
