/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import BulkUploadModal from '../CurrencyContainer/bulkUploadModal';
import ModalBox from '../../../common/ModalBox';
import {
  deleteSubTask,
  deleteTask,
  getRoleList,
  getTask,
  getTaskDetail,
  saveTask,
  GetProjectTypeList,
  publishTaskList,
  getPublishedStatusDetails,
  saveTaskDetail,
  exportExcelTask,
  generatTemplate,
  bulkUploadTask
} from './services';
import AddTags from './addTask';
import './style.scss';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import InnerTable1 from './InnerTable1';
import Loader from '../../../common/loader';

const Task = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { ZERO_UUID, generateOptions, formValidation, addColor, MAX_FILE_SIZE } = helper;
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);

  const initialFormData = {
    taskName: '',
    priorityId: '',
    phaseId: 0,
    responsibleId: ZERO_UUID,
    offset: '',
    status: 0,
    taskCategoryList: [],
    previous_id: ZERO_UUID
  };
  const [formData, setFormData] = useState(initialFormData);
  const [formDataSub, setFormDataSub] = useState('');
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [ResponsibleList, setResponsibleList] = useState([]);
  const [ProjectTypeList, setProjectTypeList] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [specificationStatus, setSpecificationStatus] = useState({
    isPublished: false,
    publishedDate: ''
  });
  const [sortData, setSortData] = useState({
    sortProperty: 'TaskName',
    isDescending: false
  });
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  useEffect(() => {
    setLoading(true);
  }, []);
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const reloadTable = (page) => {
    setLoading(true);
    getTask({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending
    }).then((res) => {
      setLoading(false);
      setTableData(res.data);
      if (setCount) {
        setCount(res?.data?.totalItems || 0);
      }
      setLoading(false);
    });
  };

  const cancel = () => {
    setTableData([]);
    reloadTable(1);
  };

  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);

  const vesselId = localStorage.getItem('vesselId');
  useEffect(() => {
    getRoleList(vesselId || ZERO_UUID).then((res) => {
      setResponsibleList(generateOptions(res.data, 'id', 'name'));
    });
    GetProjectTypeList(0).then((res) => {
      setLoading(false);
      setProjectTypeList(res.data);
    });
    getPublishedStatusDetails().then((res) => {
      if (res?.data) setLoading(false);
      setSpecificationStatus(res?.data);
    });
  }, []);

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const editData = (data) => {
    getTaskDetail(data.id).then((res) => {
      setLoading(false);
      setFormData(res.data);
      setOpenModal(true);
    });
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };
  const removeSubTask = (data) => {
    const fd = cloneDeep(formData);
    const fdd = fd.taskCategoryList;
    fdd.splice(data.indexToRemove, 1);
    fd.taskCategoryList = fdd;
    setOpenDeleteModal(false);
    setFormData(fd);
  };
  const deteleSubData = (data) => {
    if (data.id) {
      setFormDataSub(data);
      setOpenDeleteModal(true);
    } else {
      removeSubTask(data);
    }
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  /* istanbul ignore next */
  const deleteApiCall = () => {
    if (formDataSub) {
      deleteSubTask(formDataSub.id).then(() => {
        removeSubTask(formDataSub);
        setFormDataSub('');
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      });
    } else {
      deleteTask(formData.id).then(() => {
        setFormData(initialFormData);
        setFormDataSub('');
        reloadTable();
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      });
    }
  };
  const publishData = () => {
    setOpenPublishModal(true);
  };

  /* istanbul ignore next */
  const publishApiCall = () => {
    const taskDetailData = [];
    const tableRows = tableData?.items;
    cloneDeep(tableRows).forEach((element) => {
      taskDetailData.push({
        taskId: element.id,
        taskCategoryId: ZERO_UUID,
        projectType: element.projectType
      });
      element?.taskCategoryList?.forEach((s) => {
        taskDetailData.push({
          taskId: s.taskId,
          taskCategoryId: s.id,
          projectType: s.projectType
        });
      });
    });
    saveTaskDetail(taskDetailData).then((res) => {
      if (res.success) {
        publishTaskList().then((pres) => {
          if (pres.success) {
            getPublishedStatusDetails().then((specRes) => {
              if (specRes?.data) setSpecificationStatus(specRes?.data);
            });
            setOpenPublishModal(false);
            reloadTable();
            if (specificationStatus.isPublished) {
              showAlert('Successfully Republished', 'success');
            } else {
              showAlert('Successfully Published', 'success');
            }
          }
        });
      }
    });
  };
  /* istanbul ignore next */
  const getValidSubTask = () => {
    let isValid = true;
    formData.taskCategoryList?.forEach((i) => {
      const v = String(i.taskCategoryName)?.trim();
      if (!v || v === null || v === '' || v === ' ') {
        isValid = false;
        addColor('taskCategoryName', 'add');
      } else {
        addColor('taskCategoryName', '');
      }
    });

    return isValid;
  };
  /* istanbul ignore next */
  const saveApiCall = () => {
    let isValid = true;
    if (formData.offset < 1) {
      isValid = false;
    }
    formData.taskCategoryList?.forEach((subEl) => {
      if (subEl.offset < 1) {
        isValid = false;
      }
    });
    if (isValid && formValidation(['taskName', 'priorityId', 'taskCategoryName'], formData) && getValidSubTask()) {
      saveTask(formData).then((res) => {
        if (res.success) {
          reloadTable();
          setFormData(initialFormData);
          setOpenModal(false);
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
        }
      });
    } else if (isValid) {
      showAlert('Please enter the mandatory(*) fields', 'error');
    } else {
      showAlert('Please enter a valid Milestone(days) ', 'error');
    }
  };
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadTask(body).then((res) => {
      if (res.success) {
        reloadTable();
        setLoading(false);
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setLoading(false);
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };
  useEffect(() => {
    reloadTable();
  }, [sortData]);

  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };
  const handleExportExcelTask = () => {
    if (tableData?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelTask(searchText);
    }
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable();
    }
  }, [searchText]);
  return (
    <div data-testid="task">
      {loading ? <Loader /> : (
        <div className="card p-5 mb-4 height-fix">
          <div className="d-flex mb-3 justify-content-between">
            <div className="d-flex col-gap-1 spec-search">
              <div className="search large">
                <div className="d-flex justify-content-end">
                  <span
                    className={searchText ? ' icon-close-line ' : ''}
                    onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                  />
                  <span id="lens" className="icon-search" onClick={() => { onSearch({ key: 'Enter' }); }} />
                </div>

                <input
                  onKeyPress={onSearch}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  type="text"
                  className="text-box large with-close"
                  placeholder="Search by Task Name, Phase"
                  id="search"
                  value={searchText}
                />
              </div>
              <button
                type="button"
                className="primary-btn large"
                id="create-task"
                onClick={() => {
                  setFormData(initialFormData);
                  setFormDataSub('');
                  setOpenModal(true);
                }}
              >
                Create Task
              </button>
              <button
                type="button"
                id="add-name"
                className="secondary-btn large"
                onClick={() => {
                  setUploadModal(true);
                }}
              >
                Bulk Upload
              </button>
              {uploadModal && (
              <ModalBox
                modalClassName="medium"
                heading="Upload Excel"
                onSave={() => { bulkUpload(); }}
                closeModal={() => onCloseModal()}
                buttonName="Upload"
                saveDisable={isUpload}
              >
                <BulkUploadModal
                  file={file}
                  ondrop={ondrop}
                  setFile={setFile}
                  generatTemplateForExcel={generatTemplateForExcel}
                  name="Task"
                  setIsUpload={setIsUpload}
                />
              </ModalBox>
              )}
              <div className="d-flex justify-content-end ">
                <button type="button" id="excel" className="link-btn large  " onClick={handleExportExcelTask}>
                  <span className="icon-download me-4 " />
                  Excel Download
                </button>
              </div>
            </div>
            <div className="d-flex col-gap-2">
              <div className="priority-ind ">
                <span className="icon-disc high"> </span>
                High
              </div>
              <div className="priority-ind ">
                <span className="icon-disc medium"> </span>
                Medium
              </div>
              <div className="priority-ind ">
                <span className="icon-disc low"> </span>
                Low
              </div>
            </div>
          </div>
          {loading ? <Loader /> : (
            <InnerTable1
              loading={loading}
              currentpage={currentpage}
              cancel={cancel}
              expanded={expanded}
              setExpanded={setExpanded}
              reloadTable={reloadTable}
              ProjectTypeList={ProjectTypeList}
              tableData={tableData}
              tableRows={tableData?.items}
              setTableData={setTableData}
              pageClick={pageClick}
              editData={editData}
              deteleData={deteleData}
              publishData={publishData}
              deteleSubData={deteleSubData}
              onSizeChange={onSizeChange}
              pageSize={pageSize}
              specificationStatus={specificationStatus}
              setSpecificationStatus={setSpecificationStatus}
              handleSort={handleSort}
              sortData={sortData}
            />
          )}
          {openModal ? (
            <ModalBox
              modalClassName="medium"
              onSave={() => saveApiCall()}
              closeModal={() => {
                setOpenModal(false);
                reloadTable();
              }}
              heading={`${formData.id ? 'Update' : 'Create New'} Task`}
              buttonName={formData.id ? 'Update' : 'Save'}
            >
              <AddTags
                ResponsibleList={ResponsibleList}
                formData={formData}
                setFormData={setFormData}
                deteleSubData={deteleSubData}
              />
            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              openDeleteModal={openDeleteModal}
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <p>
                {
              formDataSub
                ? 'Are you sure you want to delete Sub Task'
                : `Are you sure you want to delete Task? ${formData.taskCategoryList?.length ? 'The sub tasks under the task will also get deleted.' : ''}`
            }
              </p>
            </ModalBox>
          ) : (
            ''
          )}
          {openPublishModal ? (
            <ModalBox
              modalClassName="medium"
              onSave={() => publishApiCall()}
              closeModal={() => setOpenPublishModal(false)}
              heading={specificationStatus.isPublished ? 'Republish' : 'Publish'}
              buttonName={specificationStatus.isPublished ? 'Republish' : 'Publish'}
            >
              {specificationStatus.isPublished
                ? (
                  <div>
                    <div>
                      Are you sure you want to Re-publish Task?
                    </div>
                    <div>
                      Once Re-published, Tasks saved in draft mode only will be published
                      and made available to application users.
                    </div>
                  </div>
                )
                : (
                  <div>
                    <div>
                      Are you sure you want to publish Task?
                    </div>
                    <div>
                      Once published, Tasks saved in draft mode only will be published
                      and made available to application users.
                    </div>
                  </div>
                )}
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      )}

    </div>
  );
};
export default Task;
