/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import Attachments from './Attachments/index';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import Stepper from './Stepper';
import VesselDetails from './VesselDetails';
import Confirm from './ConfirmPage/Confirm';
import Header from '../../Header';
import { getConfirmation } from './services';
import PolicyFooter from '../../../Admin/policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const index = () => {
  const navigate = useNavigate();
  const initialData = [
    {
      name: 'Vessel Details',
      componentName: 'vessel-details',
      indexing: 0,
      isDone: false
    },
    {
      name: 'Project Details',
      componentName: 'project-details',
      indexing: 1,
      isDone: false
    },
    {
      name: 'Plans and Drawings',
      componentName: 'attachments',
      indexing: 2,
      isDone: false
    },
    {
      name: 'Confirm',
      componentName: 'confirm',
      indexing: 3,
      isDone: false
    }
  ];
  const [active, setactive] = useState('vessel-details');
  const [vesselInfo, setVesselInfo] = useState({});
  const [projectId, setProjectId] = useState();
  const [isCreate, setIsCreate] = useState(true);
  const [stepperList, setStepperList] = useState(initialData);
  const [selectedVessel, setSelectedVessel] = useState();
  const [surveyInfo, setSurveyInfo] = useState({});
  const [isSelectedVessel, setIsSelectedVessel] = useState();
  const [isTsiDisabled, setIsTsiDisabled] = useState(false);
  const userRole = localStorage.getItem('USER_ROLE');
  // const [currencySymbol, setCurrencySymbol] = useState('');
  const params = qs.parse(window.location.search);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const { state } = useLocation();
  const handleActiveComponent = (data) => {
    setactive(data);
  };

  const getBasicDetails = () => {
    const payload = {
      id: params?.projectId
    };
    getConfirmation(payload).then((res) => {
      if (res?.success) {
        if (localStorage.getItem('USER_ID') === res?.data?.backupTsiId) {
          setIsTsiDisabled(true);
        }
      }
    });
  };
  useEffect(() => {
    if (params?.projectId && params?.vesselId) {
      setProjectId(params?.projectId);
      setSelectedVessel(params?.vesselId);
      setIsCreate(false);
      getBasicDetails();
      if (state?.screenCompleted === 1) {
        stepperList[0].isDone = true;
        stepperList[1].isDone = true;
        setStepperList(stepperList);
        handleActiveComponent('vessel-details');
      } else if (state?.screenCompleted === 2) {
        stepperList[0].isDone = true;
        stepperList[1].isDone = true;
        stepperList[2].isDone = true;
        setStepperList(stepperList);
        handleActiveComponent('vessel-details');
      }
    }
  }, []);

  const handleSetProjectId = (id) => {
    setProjectId(id);
  };

  const getVesselInfo = (data) => {
    setVesselInfo(data);
  };

  // const getFormDataDetails = (data) => {
  //   setReqInfo(data);
  // };

  // const getCurrencySymbol = (data) => {
  //   setCurrencySymbol(data);
  // };

  return (
    <div>
      <div className="header" data-testid="newpjt-main">
        <Header />
        <div className="sub-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="main-head">{isCreate ? 'Create New Project' : 'Edit Project'}</div>
            </div>
            <div className="d-flex justify-content-end">
              <Stepper
                handleActiveComponent={handleActiveComponent}
                active={active}
                stepperList={stepperList}
                projectId={projectId}
              />
            </div>

            <div className="d-flex align-items-center" data-tooltip-id="view-new" data-tooltip-content="Back to Project">
              <button onClick={() => navigate('/user/projects')} type="button" className="link-btn me-4 ms-4">
                <span className="icon-close me-2 font-28" />
              </button>
              <Tooltip data-tooltip-id="view-new" place="left" className="tooltip mt-3" />
            </div>
          </div>
        </div>
      </div>
      <div className="project-top-10 projectlist-wrapper">
        {active === 'vessel-details' && (
          <VesselDetails
            isSelectedVessel={isSelectedVessel}
            handleActiveComponent={handleActiveComponent}
            getVesselInfo={getVesselInfo}
            setSelectedVessel={setSelectedVessel}
            selectedVessel={selectedVessel}
            setStepperList={setStepperList}
            stepperList={stepperList}
            isTsiDisabled={isTsiDisabled}
            handleSetProjectId={handleSetProjectId}
            initialData={initialData}
            setProjectId={setProjectId}
            projectId={projectId}
          />
        )}
        {active === 'project-details' && (
          <ProjectDetails
            // getProjectName={getProjectName}
            // getSurveyName={getSurveyName}
            // getCurrencySymbol={getCurrencySymbol}
            // getFormDataDetails={getFormDataDetails}
            setIsSelectedVessel={setIsSelectedVessel}
            setSurveyInfo={setSurveyInfo}
            surveyInfo={surveyInfo}
            handleActiveComponent={handleActiveComponent}
            vesselInfo={vesselInfo?.generalInfo}
            setVesselInfo={setVesselInfo}
            handleSetProjectId={handleSetProjectId}
            projectId={projectId}
            isTsiDisabled={isTsiDisabled}
            setStepperList={setStepperList}
            stepperList={stepperList}
          />
        )}
        {active === 'attachments' && (
          <Attachments
            // projectName={projectName}
            // surveyName={surveyName}
            // currencySymbol={currencySymbol}
            // reqInfo={reqInfo}
            projectId={projectId}
            vesselInfo={vesselInfo}
            isTsiDisabled={isTsiDisabled}
            handleActiveComponent={handleActiveComponent}
            setStepperList={setStepperList}
            stepperList={stepperList}
          />
        )}
        {active === 'confirm' && (
          <Confirm
            projectId={projectId}
            isTsiDisabled={isTsiDisabled}
            handleActiveComponent={handleActiveComponent}
            setSurveyInfo={setSurveyInfo}
            surveyInfo={surveyInfo}
          />
        )}
      </div>
      {userRole === 'Admin' ? (
        <div className="footer justify-content-between font-13">
          <PolicyFooter
            setShowPrivacyPolicy={setShowPrivacyPolicy}
            setShowTermsPolicy={setShowTermsPolicy}
          />
        </div>
      ) : (
        <div className="footer justify-content-between font-13">
          <div className="copy-text copy-right">
            © 2024 ThinkPalm
          </div>

        </div>
      )}

      {showPrivacyPolicy && (
      <PrivacyPolicy
        setShowPrivacyPolicy={setShowPrivacyPolicy}
        showPrivacyPolicy={showPrivacyPolicy}
      />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
      <Tooltip data-tooltip-id="view-close" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default index;
