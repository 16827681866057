import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <div className="container copy-right">© 2024 ThinkPalm</div>
    </div>
  );
}
export default Footer;
