import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import Loader from '../../../../common/loader';

import { getAcCodeReport, DownloadAcCodeReport } from '../services';
import AcCodeReportComponent from './AcCodeReportComponent';
import { repairDates } from '../../PhasePage/Repair/services';

const AcCodeReport = ({ projInfo }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({
    projectId: '',
    repairStartDate: '',
    repairEndDate: '',
    specList: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [repairStartDate, setRepairStartDate] = useState('');
  const [repairEndDate, setRepairEndDate] = useState('');
  const getRepairDates = () => {
    const payload = {
      projectId: id
    };
    repairDates(payload).then((res) => {
      setRepairStartDate(res?.data?.repairStartDate);
      setRepairEndDate(res?.data?.repairEndDate);
    });
  };
  const reloadTable = () => {
    const payload = {
      page,
      pageSize,
      search: '',
      sortProperty: 'id',
      isDescending: true,
      projectId: id
    };
    getAcCodeReport(payload).then((res) => {
      console.log(res);
      setTableData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    reloadTable();
    getRepairDates();
  }, []);
  useEffect(() => {
    reloadTable();
  }, [page, pageSize]);
  const downloadReport = () => {
    DownloadAcCodeReport(id);
  };
  return (
    <div data-testid="AcCodeReport">
      <div className="align-items-center d-flex justify-content-between report-ctrl">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <div className="font-20 font-bold">AC Code Report</div>
            <div className="ms-3 couter-bubbles status-due ">{tableData?.totalItems}</div>
          </div>
          <div className="d-flex align-items-center me-5">
            <label>
              Repair Period
              <span className="ms-2">:</span>
            </label>
            &nbsp;
            <div className="value">
              {repairStartDate
                ? moment(repairStartDate).format('DD-MMM-yyyy') : ''}

            </div>
            <div className="mx-4 value"> - </div>
            <div className="value">
              {repairEndDate
                ? moment(repairEndDate).format('DD-MMM-yyyy') : ''}

            </div>
          </div>

        </div>
      </div>
      <div className="report-canvas mt-4">
        {loading ? (
          <Loader />
        ) : (
          <AcCodeReportComponent
            tableData={tableData}
            setPage={setPage}
            setPageSize={setPageSize}
            page={page}
            pageSize={pageSize}
            downloadReport={downloadReport}
            projInfo={projInfo}
          />
        )}
      </div>
      <Tooltip id="project-specName" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default AcCodeReport;
