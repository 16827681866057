import React from 'react';
import Cards from './Cards';
import Loader from '../../../common/loader';

const CardWrapper = (props) => {
  const { yardCostSummaryList, loading, ...rest } = props;
  if (yardCostSummaryList.totalItems > 0) {
    return (
    // tableData.items?.map((urlData) => {
    //   return (
      <Cards
        tableData={yardCostSummaryList?.items}
        {... rest}
      />
    //   );
    // })
    );
  } if (loading) {
    return <Loader />;
  }
  return (
    <div className="resource-footer-no-data mt-5 p-4 d-flex justify-content-center ">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">No data found</div>
    </div>
  );
};
export default CardWrapper;
