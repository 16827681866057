/* eslint-disable max-len */
/* istanbul ignore file */
import React from 'react';
import ModalBox from '../../../../../common/ModalBox';

const YardConfirmationModal = (props) => {
  const { setShowYardConfirmModal, handleEmailPreview,
    yardMailList, setConfirmationModalForYard } = props;
  const handleConfirmSend = () => {
    setShowYardConfirmModal(false);
    setConfirmationModalForYard(true);
  };
  const mailSentData = yardMailList?.yardData.filter((yard) => yard.isMailSent === true);

  const mailNotSentData = yardMailList?.yardData.filter((yard) => yard.isMailSent === false);

  return (
    <div>
      <ModalBox
        heading="Confirm Yard"
        closeModal={() => setShowYardConfirmModal(false)}
        buttonName="Confirm and Send"
        modalClassName="large modal-scroll"
        onSave={handleConfirmSend}
        saveDisable={yardMailList?.yardData?.length === 0}

      >
        <>
          <div data-testid="yard-confirm-modal">
            {mailNotSentData && mailNotSentData?.map((items, index) => {
              return (
                <div className="mb-3 p-3 sm-radius border d-flex justify-content-between align-items-center bg-sky-blue">
                  <div className="col-9">
                    <div className="font-bold mb-1">{items?.yardName}</div>
                  </div>
                  <div className="d-flex col-2">

                    <button type="button" className="link-btn me-2" onClick={() => handleEmailPreview(index)}>
                      <span className="icon-eye me-2" />
                      Email Preview
                    </button>
                  </div>
                  <div className="col-1" />

                </div>

              );
            })}
            {mailSentData && mailSentData?.map((items, index) => {
              return (
                <div className="mb-3 p-3 sm-radius border d-flex justify-content-between align-items-center bg-blue ">
                  <div className="col-9">
                    <div className="font-bold mb-1">{items?.yardName}</div>
                  </div>
                  <div className="d-flex col-2">

                    <button type="button" className="link-btn me-2" onClick={() => handleEmailPreview(index)}>
                      <span className="icon-eye me-2" />
                      Email Preview
                    </button>
                  </div>
                  <div className="col-1 font-18 ms-4">
                    {items?.isMailSent && (
                    <div className="icon-check-rnd tick-green me-2">
                      <span className="tick-green  me-2" />
                      <span>Sent</span>
                    </div>
                    )}
                  </div>

                </div>

              );
            })}
            {yardMailList?.yardData?.length === 0 && (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">
                  No data found
                </div>
              </div>
            )}

          </div>

        </>
      </ModalBox>
    </div>
  );
};
export default YardConfirmationModal;
