/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React from 'react';
import _, { toNumber } from 'lodash';
import InputType1 from '../../../common/InputType1';
import { TotalEstimate, SecTotal } from './totalEstimate';
import helper from '../../../../utils/helper';

const ContentHeader = (props) => {
  const { specList, setSpecList } = props;
  const { fieldValidation } = helper;
  /* istanbul ignore next */
  const onUpdateHeader = (e, type, value) => {
    const currentSpecList = { ...specList };
    if (type === 'float') {
      currentSpecList.rfqData[e.target.name] = e.target.value.replace(/[^0-9.]/g, '');
    } else if (type === 'int') {
      if (e.target.name === 'inDockDays'
        || e.target.name === 'daysAfloat') {
        if (toNumber(e.target.value) < 100) currentSpecList.rfqData[e.target.name] = _.toNumber(e.target.value.replace(/[^0-9]/g, ''));
      } else if (e.target.name === 'discountPercentage') {
        currentSpecList.rfqData[e.target.name] = _.toNumber(fieldValidation(e.target.value.replace(/[^0-9]/g, ''), 3));
      } else {
        currentSpecList.rfqData[e.target.name] = _.toNumber(e.target.value.replace(/[^0-9]/g, ''));
      }
      if (e.target.name === 'discountPercentage' && value === 'dis') {
        currentSpecList?.sectionData?.map((i, index) => {
          i?.specDataList?.map((j) => {
            j?.serviceLineList?.map((k) => {
              if (k?.specificationLevel !== 4) {
                k.discount = e.target.value ? e.target.value.replace(/[^0-9]/g, '') : 0;
                k.amount = (((k.quantity * k.rate) / 100)
                  * (100 - k.discount)) ? (((k.quantity * k.rate) / 100)
                    * (100 - k.discount)).toString() : null;
              }
            });
          });
          currentSpecList.sectionData[index].total = SecTotal(currentSpecList.sectionData[index]);
        });
      }
    } else {
      currentSpecList.rfqData[e.target.name] = e.target.value;
    }
    currentSpecList.rfqData.total = TotalEstimate(currentSpecList);
    currentSpecList.rfqData.yardFinalEstimate = (currentSpecList?.rfqData
      ?.total - currentSpecList?.rfqData?.discount)?.toFixed(2)?.toString();
    currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList?.rfqData
      ?.total)?.toString();
    currentSpecList.rfqData.repairDays = currentSpecList?.rfqData?.inDockDays + currentSpecList?.rfqData?.daysAfloat;
    setSpecList(currentSpecList);
  };
  return (
    <>
      <div className="bg-sky-white p-3 d-flex flex-wrap sm-radius">
        <div className="col-4 pe-4 mb-4">
          <InputType1 label="Yard Name" className="cursor-disable" placeholder="Yard Name" value={specList?.rfqData?.yardName} />
        </div>
        <div className="col-2 pe-4">
          <InputType1 label="Days In Dock" placeholder="Days In Dock" id="dockdays" name="inDockDays" value={specList?.rfqData?.inDockDays} onChange={(e) => { onUpdateHeader(e, 'int'); }} />
        </div>
        <div className="col-2 pe-4">
          <InputType1 label="Days Afloat" placeholder="Days Afloat" id="daysafloat" name="daysAfloat" value={specList?.rfqData?.daysAfloat} onChange={(e) => { onUpdateHeader(e, 'int'); }} />
        </div>
        <div className="col-2 pe-4">
          <InputType1 label="Total Repair Days" placeholder="Total Repair Days" id="repairday" name="repairDays" value={specList?.rfqData?.repairDays} onChange={(e) => { onUpdateHeader(e, 'int'); }} />
        </div>
        <div className="col-2 pe-4">
          <InputType1
            label="Discount [%]"
            placeholder="Discount [%]"
            id="discoutp"
            name="discountPercentage"
            value={specList?.rfqData?.discountPercentage}
            onChange={(e) => { onUpdateHeader(e, 'int'); }}
            onBlur={(e) => { onUpdateHeader(e, 'int', 'dis'); }}
          />
        </div>
        <div className="col-6 pe-4">
          <InputType1 label="Payment Terms" placeholder="Payment Terms" id="term" name="paymentTerms" value={specList?.rfqData?.paymentTerms} onChange={(e) => { onUpdateHeader(e); }} />
        </div>
        <div className="col-6 pe-4">
          <InputType1 label="Yard Comments" placeholder="Yard Comments" id="comment" name="comment" value={specList?.rfqData?.comment} onChange={(e) => { onUpdateHeader(e); }} />
        </div>
      </div>
      <div className="border-down my-3" />
    </>
  );
};
export default ContentHeader;
