import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import _ from 'lodash';
import { statusDot, jobNameMap } from './constant';

const AddAssignee = (props) => {
  const { specList, addAssignee, setSpecList, isCompleted, isTSI } = props;
  const AssigneeDropDown = [
    { label: 'Yard', value: 1 },
    { label: 'Vessel', value: 2 },
    { label: 'Sub Contractor', value: 3 }
  ];
  const nameIdentify = {
    1: 'Yard',
    2: 'Vessel',
    3: 'Sub Contractor'
  };
  const onAssigneeChange = (e) => {
    const assignees = e.value;
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[addAssignee.mainKey]
      .specDataList[addAssignee.subKey].specAssignedUsers = assignees;
    setSpecList(currentSpec);
  };
  const specData = specList.specQuoteList[addAssignee.mainKey].specDataList[addAssignee.subKey];
  return (
    <div>
      <div className="d-flex bg-sky-white align-items-center p-3 justify-content-between">
        <div className="d-flex align-items-center">
          <div className="d-flex head-14 ">{specList.specQuoteList[addAssignee.mainKey].specDataList[addAssignee.subKey].specificationNo}</div>
          <div className="  ms-4 head-14">
            <span
              data-tooltip-id="project-phrep"
              data-tooltip-content={specList.specQuoteList[addAssignee.mainKey]
                .specDataList[addAssignee.subKey].title}
            >
              {' '}
              {_.truncate(specList.specQuoteList[addAssignee.mainKey]
                .specDataList[addAssignee.subKey].title, { length: 50 })}

            </span>

          </div>
          <span className="icon-woha ms-3" />
          <div className="couter-bubble p-2 ms-3 width-fit bg-white">{`${specData.workOrderClosedCount}/${specData.workOrderTotalCount}`}</div>
        </div>
        <div className="d-flex align-items-center ">
          {statusDot(specData.jobStatus)}
          {jobNameMap[specData.jobStatus]}
        </div>
      </div>
      <div className="mt-5">
        <div className="opacity-6 mb-2">Assignee Name</div>
        <Select
          autoFocus
          className="modal-select"
          label="Assigne Name"
          isDisabled={isCompleted || !isTSI}
          id="assignee"
          options={AssigneeDropDown}
          value={{ label: nameIdentify[specData.specAssignedUsers],
            value: specData.specAssignedUsers }}
          onChange={(e) => onAssigneeChange(e)}
        />
      </div>
      <Tooltip id="project-phrep" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddAssignee;
