import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import timeMoney from '../../../../images/time-money.png';
import PolicyFooter from '../../../Admin/policyFooter';
// import PolicyFooter from '../../../Admin/policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const ExitPage = ({ isManual }) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  // const navigate = useNavigate();
  return (
    <>
      <div className="exit-page-policy-footer">
        <div className="exit-page" data-testid="exit-page">
          <div className="">
            <div className="d-flex justify-content-center px-3">
              <img className="exit-page-symbol mt-5" src={timeMoney} alt="" />
            </div>
            <div className="head-18 d-flex justify-content-center pt-5">Thanks for your valuable Time!</div>
            <div className="font-14 d-flex justify-content-center pt-4">
              You have chosen not to participate in the Quote for the Dry Docking project.
            </div>
            <div className="font-14 d-flex justify-content-center pt-2">
              You can now exit page by clicking close button.
            </div>
            <div className="d-flex justify-content-center pt-5">
              <button id="cancel" className="primary-btn large mb-5" type="button" onClick={() => { window.close(); }}>
                Close
              </button>
            </div>
          </div>

        </div>
      </div>
      {isManual ? ('') : (
        <div className="footer justify-content-between font-13">
          <PolicyFooter
            setShowPrivacyPolicy={setShowPrivacyPolicy}
            setShowTermsPolicy={setShowTermsPolicy}
          />
        </div>
      )}

      {showPrivacyPolicy && (
      <PrivacyPolicy
        setShowPrivacyPolicy={setShowPrivacyPolicy}
        showPrivacyPolicy={showPrivacyPolicy}
      />
      )}
      {showTermsPolicy && (
      <TermsOfUse
        setShowTermsPolicy={setShowTermsPolicy}
        showTermsPolicy={showTermsPolicy}
      />
      )}
    </>
  );
};

export default ExitPage;
