/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import { s3UploadMutiple, saveProjectAttachments } from '../NewProject/services';
import UploadImage from './uploadImage';
import ModalBox from '../../../common/ModalBox';
import Loader from '../../../common/loader';
import GalleryFolderView from './GalleryFolderView';

const Gallery = forwardRef((props, ref) => {
  const {
    uploadView,
    setIsUploadView,
    images,
    getImages,
    loading,
    setCurrentpage,
    setFormData,
    formData,
    initialFormData,
    nextPageClick,
    previousPageClick,
    isReport,
    handleUploadImage,
    onNameChange,
    nameChange,
    setNameChange,
    folderType,
    setFolderType,
    active,
    setActive, disableBtn
  } = props;
  const { ZERO_UUID } = helper;
  const [file, setFile] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [clickedImage, setClickedImage] = useState('');
  const [leftClick, setLeftClick] = useState();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  // const [rightClick, setRightClick] = useState();
  const acceptedFiles = ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff'];
  const { MAX_FILE_SIZE } = helper;
  const { id } = useParams();
  // const [nameChange, setNameChange] = useState();
  // const onNameChange = (e) => {
  //   setNameChange(e.target.value);
  // };
  const [customLoading, setCustomLoading] = useState(false);
  const [reloadFolder, setReloadFolder] = useState();

  useEffect(() => {
    getImages();
    setCurrentpage(1);
    document.body.classList.add('model-open');
  }, []);
  const saveApi = (data) => {
    saveProjectAttachments(data)?.then((res) => {
      if (res.success) {
        showAlert('Successfully Saved', 'success');
        // getImages();
        setIsUploadView(false);
        // setIsButtonClicked(true);
        setFormData(initialFormData);
        setNameChange('');
        setReloadFolder(Math.random());
      }
    });
  };
  useImperativeHandle(ref, () => ({
    saveApiCall() {
      const inputObj = formData;
      inputObj.name = nameChange;
      inputObj.galleryId = active;
      inputObj.projectAttachments.map((item, key) => {
        inputObj.projectAttachments[key].subName = nameChange;
        if (inputObj.projectAttachments.length === 1) {
          inputObj.projectAttachments[key].subNumber = 0;
        } else {
          inputObj.projectAttachments[key].subNumber = key + 1;
        }
        return null;
      });
      saveApi(inputObj);
    }
  }));

  const onRemoveImg = (key) => {
    const currentImage = { ...formData };
    currentImage.projectAttachments.splice(key, 1);
    setFormData(currentImage);
  };

  /* istanbul ignore next */
  const ondrop = async (e) => {
    if (e?.target.files.length < 11) {
      const name = formData.projectAttachments.map((items) => {
        return items.name;
      });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate File(s) Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formData);
          // const addDocs = _.cloneDeep(addAttachs);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fileName: item.path,
              projectId: id,
              fileType: item.fileType,
              fileSize: item.size,
              documentCategory: ZERO_UUID,
              phase: 4
            };

            return attach.projectAttachments.push(newItem);
          });
          setFormData(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 10 files at a time', 'error');
    }
    e.target.value = '';
  };

  const goBackToCustom = () => {
    setIsUploadView(false);
    // setCustomLoading(true);
    // setFolderType(2);
    // setFormData(initialFormData);
    // onNameChange('');
    // setReloadFolder(Math.random());
    // document.getElementById('fileName').value = '';
    // document.getElementById('name').value = '';
    setFormData(initialFormData);
    setNameChange('');
    setReloadFolder(Math.random());
  };
  return (
    <div data-testid="gallery-index" className="image-gallery">
      {loading ? (
        <Loader />
      ) : (
        <div>
          {uploadView ? (
            <UploadImage
              ondrop={ondrop}
              goBackToCustom={goBackToCustom}
              formData={formData}
              onRemoveImg={onRemoveImg}
              onNameChange={onNameChange}
              nameChange={nameChange}
            />
          ) : (
            <div>
              <GalleryFolderView
                id={id}
                folderType={folderType}
                setFolderType={setFolderType}
                isReport={isReport}
                handleUploadImage={handleUploadImage}
                isButtonClicked={isButtonClicked}
                setIsButtonClicked={setIsButtonClicked}
                uploadView={uploadView}
                setCustomLoading={setCustomLoading}
                customLoading={customLoading}
                active={active}
                setActive={setActive}
                reloadFolder={reloadFolder}
                disableBtn={disableBtn}
                setLeftClick={setLeftClick}
              />
              {/* {images && images?.items?.length > 0 ? (
                <GalleryFolderView
                  id={id}
                  folderType={folderType}
                  setFolderType={setFolderType}
                  isReport={isReport}
                  handleUploadImage={handleUploadImage}
                />
              // <ImageGallery
              //   setLeftClick={setLeftClick}
              //   images={images}
              //   setOpenModal={setOpenModal}
              //   setClickedImage={setClickedImage}
              //   isReport={isReport}
              //   handleUploadImage={handleUploadImage}
              // />
              ) : (
                <div className="mt-5 p-4 d-flex justify-content-center align-
                items-center" data-testid="yard-attach">
                  <span className="icon-exclamation_fill font-24 brand-color me-3 an
                  imate__animated animate__headShake " />
                  <div className="font-18 animate__animated animate__headShake">
                    No data found
                  </div>
                </div>
              )} */}
            </div>
          )}
          {openModal ? (
            <ModalBox
              modalClassName="large"
              removeClass={false}
              closeModal={() => {
                setOpenModal(false);
                document.body.classList.add('model-open');
              }}
              heading={(
                <div className="d-flex">
                  <div className="me-2">Image Preview</div>
                </div>
              )}
            >
              <div className="d-flex align-items-center">
                {leftClick !== 0 ? (
                  <span
                    className="icon-arrow-thin-left font-26 cursor-pointer"
                    style={{ height: '30px', width: '30px' }}
                    id="leftclick0"
                    onClick={() => {
                      setLeftClick(leftClick - 1);
                    }}
                  />
                ) : (
                  <div>
                    {images?.hasPrev ? (
                      <span
                        className="icon-arrow-thin-left font-26 cursor-pointer"
                        style={{ height: '30px', width: '30px' }}
                        id="leftclick1"
                        onClick={() => {
                          previousPageClick();
                          setLeftClick(0);
                        }}
                      />
                    ) : (
                      <div className="ms-5" />
                    )}
                  </div>
                )}

                <div>
                  <a href={images.items[leftClick].imageUrl} target="_blank" rel="noreferrer">
                    <img src={images.items[leftClick].imageUrl} alt="" className="h-30 w-300" />
                  </a>
                </div>
                {/* <img alt="pic 1" className="h-30 w-300"
              src={images.items[leftClick].imageUrl} /> */}
                {leftClick + 1 !== images?.items.length ? (
                  <span
                    className="icon-arrow-thin-right font-26 d-flex justify-content-end cursor-pointer"
                    style={{ height: '30px', width: '30px' }}
                    id="leftclick2"
                    onClick={() => {
                      setLeftClick(leftClick + 1);
                    }}
                  />
                ) : (
                  <div>
                    {images?.hasNext ? (
                      <span
                        className="icon-arrow-thin-right font-26 d-flex justify-content-end cursor-pointer"
                        style={{ height: '30px', width: '30px' }}
                        id="leftclick3"
                        onClick={() => {
                          nextPageClick();
                          setLeftClick(0);
                        }}
                      />
                    ) : (
                      <div className="ms-5" />
                    )}
                  </div>
                )}
              </div>

              <div className="d-flex ms-5 mt-3 value font-18 justify-content-center">
                {/* <div className="me-2">{images.items[leftClick].specNumber}</div> */}
                <div className="d-flex">
                  <label
                    className=" value"
                    data-tooltip-id="project-gallerysystem"
                    data-tooltip-content={images?.items[leftClick].imageName?.concat(' ') + images?.items[leftClick].subNumber}
                  >
                    {_.truncate(images.items[leftClick].imageName, {
                      length: 120,
                      separator: ' '
                    })}
                  </label>
                  {images.items[leftClick].subNumber !== 0 && <div className="ms-2">{images.items[leftClick].subNumber}</div>}
                </div>
              </div>
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
});

export default Gallery;
