import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Loader from '../../../../common/loader';
import { getPOReport, pOReportDownload } from '../services';
import { repairDates } from '../../PhasePage/Repair/services';
import { Postatus } from '../../PhasePage/Specify/SpecsList/POPR/constants';
import PoReportTable from './PoReportTable';

const PoReport = () => {
  const statusList = [
    { label: 'Draft', value: 'Draft' },
    { label: 'Pending Approval', value: 'pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'Ordered', value: 'ordered' },
    { label: 'Cancelled', value: 'cancelled' }
  ];
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const [repairStartDate, setRepairStartDate] = useState('');
  const [repairEndDate, setRepairEndDate] = useState('');
  // const [jobDoneByValue, setJobDoneByValue] = useState(0);
  const [tableData, setTableData] = useState({
    projectId: '',
    repairStartDate: '',
    repairEndDate: '',
    items: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getRepairDates = () => {
    const payload = {
      projectId: id
    };
    repairDates(payload).then((res) => {
      setRepairStartDate(res?.data?.repairStartDate);
      setRepairEndDate(res?.data?.repairEndDate);
      setLoading(false);
    });
  };
  const reloadTable = (statusItem) => {
    const payload = {
      page,
      pageSize,
      search: '',
      sortProperty: 'asc',
      isDescending: true,
      projectId: id,
      status: statusItem
    };
    getPOReport(payload).then((res) => {
      setTableData(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    reloadTable('');
    getRepairDates();
    setLoading(true);
  }, []);

  useEffect(() => {
    const statusValue1 = statusValue ? statusValue.value : '';
    // const jobDoneByValue1 = jobDoneByValue ? jobDoneByValue.value : 0;
    reloadTable(statusValue1);
  }, [statusValue, page, pageSize]);

  const isClearable = true;
  const getColor = (s) => {
    const sList = cloneDeep(Postatus);
    const f = sList.filter((i) => i.label === s);
    if (f.length) return f[0].color;
    return 'fade-color';
  };
  const downloadReport = () => {
    const statusValue1 = statusValue ? statusValue.value : '';
    const payload = {
      projectId: id,
      status: statusValue1
      // jobDoneBy: jobDoneByValue1
    };
    pOReportDownload(payload);
  };
  return (
    <div data-testid="poProgressReport">
      <div className="align-items-center d-flex justify-content-between report-ctrl">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <div className="font-20 font-bold">PO/PR Report</div>
            <div className="ms-3 couter-bubbles status-due ">{tableData?.totalItems}</div>
          </div>
          <div className="d-flex align-items-center me-5">
            <label>
              Repair Period
              <span className="ms-2">:</span>
            </label>
            &nbsp;
            <div className="value">
              {repairStartDate
                ? moment(repairStartDate).format('DD-MMM-yyyy') : ''}

            </div>
            <div className="mx-4 value"> - </div>
            <div className="value">
              {repairEndDate
                ? moment(repairEndDate).format('DD-MMM-yyyy') : ''}

            </div>
          </div>

          <div data-tooltip-id="project-popr" data-tooltip-content={statusValue?.label}>
            <Select
              id="poselect"
              className="modal-select mx-3 w-250"
              placeholder="Status"
              options={statusList}
              isClearable={isClearable}
              value={statusValue}
              onChange={(e) => {
                if (e) {
                  setStatusValue(e);
                } else {
                  setStatusValue('');
                }
              }}
            />
          </div>
        </div>
        {/* <div>
          <button type="button" className="secondary-btn">
            Print/Share
          </button>
        </div> */}
      </div>
      <div className="report-canvas mt-4">
        {loading ? (
          <Loader />
        ) : (
          <PoReportTable
            tableData={tableData}
            getColor={getColor}
            setPage={setPage}
            setPageSize={setPageSize}
            page={page}
            pageSize={pageSize}
            downloadReport={downloadReport}
          />
        )}
      </div>

      <Tooltip id="project-popr" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default PoReport;
