import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  SaveReferenceDoc,
  GetReferenceDoc,
  DeleteReferenceDoc,
  S3Upload
} = apiEndPoints;

export const saveReferenceDoc = (payload) => {
  return serviceHandler({
    url: `${SaveReferenceDoc}`,
    methordType: 'post',
    payload
  });
};
export const getReferenceDoc = (payload) => {
  return serviceHandler({
    url: `${GetReferenceDoc}`,
    methordType: 'post',
    payload
  });
};
export const deleteReferenceDoc = (payload) => {
  return serviceHandler({
    url: `${DeleteReferenceDoc}?id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const s3Upload = (payload) => {
  return serviceHandler({
    url: `${S3Upload}`,
    methordType: 'post',
    payload
  });
};
