/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* istanbul ignore file */
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import ModalBox from '../../../common/ModalBox';
import CustomFolderContent from './CustomFolderContent';
import GalleryContent from './GalleryContent';
import GallryLeftPanel from './GallryLeftPanel';
import {
  getFolderListing, getGalleryImages, SaveGalleryFolderData,
  getCustomFolderListing, deleteCustomFolderListing
} from './services';
import tiff from '../../../../images/tiff.png';

const GalleryFolderView = ({
  id,
  folderType,
  setFolderType,
  isReport,
  handleUploadImage,
  isButtonClicked,
  setIsButtonClicked,
  uploadView,
  setCustomLoading,
  customLoading,
  active,
  setActive,
  reloadFolder,
  disableBtn,
  setLeftClick
}) => {
  const { ZERO_UUID, formValidation } = helper;
  const [folderList, setFolderList] = useState([]);
  const [folderActiveData, setFolderActiveData] = useState({});
  const [openChildFolder, setOpenChildFolder] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [clickedImage, setClickedImage] = useState({});
  const [newLoading, setNewLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAddNewShow, setIsAddNewShow] = useState(false);
  const initialValue = {
    name: '',
    parentId: ZERO_UUID,
    projectId: id
  };
  const [formData, setFormData] = useState(initialValue);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getImages = (specid) => {
    setLoading(true);
    getGalleryImages({ projectId: id, specId: specid }).then((res) => {
      if (res.success) {
        setImageList(res.data);
        setLoading(false);
        setCustomLoading(false);
        if (folderType === 2) {
          setFolderActiveData(res.data);
        }
      }
    });
  };
  const getChildData = (row) => {
    if (row) {
      setActive(row.id);
      setFolderActiveData(row);
      setOpenChildFolder('');
      getCustomFolderListing(id, row.id).then((res) => {
        if (res.success) {
          setImageList(res.data);
          setLoading(false);
          setCustomLoading(false);
          if (folderType === 2) {
            setFolderActiveData(res.data);
          }
        }
      });
    }
  };
  const galleryFolderList = (type) => {
    setImageList([]);
    setOpenChildFolder('');
    setFolderType(type);
    if (reloadFolder && active) {
      //
    } else {
      setActive();
    }
    if (type === 1) {
      setLoading(true);
      getFolderListing({ projectId: id, folderType: type }).then((res) => {
        setLoading(false);
        if (res.success) {
          setFolderList(res.data);
          setActive(res.data[0].specId);
          setFolderActiveData(res.data[0]);
        }
      });
    } else {
      setLoading(true);
      getCustomFolderListing(id, ZERO_UUID).then((res) => {
        setLoading(false);
        if (res.success) {
          setFolderList(res.data);
          // if (reloadFolder && active) {
          //   getChildData(folderActiveData);
          // } else {
          getChildData(res.data[0]);
          // }
        }
      });
    }
  };

  useEffect(() => {
    if (isButtonClicked) {
      getImages(ZERO_UUID);
      setIsButtonClicked(false);
    }
  }, [isButtonClicked]);
  useEffect(() => {
    if (!uploadView) {
      getImages(ZERO_UUID);
    }
    if (folderType === 1) {
      galleryFolderList(folderType);
    }
  }, []);

  useEffect(() => {
    if (reloadFolder) {
      setFolderType(2);
      getCustomFolderListing(id, ZERO_UUID).then((res) => {
        setLoading(false);
        if (res.success) {
          setFolderList(res.data);
          const fd = res.data.filter((i) => i.id === active);
          if (fd) {
            getChildData(fd[0]);
          }
        }
      });
    }
  }, [reloadFolder]);

  const navigateImages = (prev) => {
    const temp = { ...clickedImage };
    if (prev) {
      temp.key -= 1;
    } else {
      temp.key += 1;
    }
    temp.url = imageList[temp.key].imageUrl;
    setClickedImage(temp);
  };
  const handleCreateNew = () => {
    if (formValidation(['name'], formData)) {
      setNewLoading(true);
      SaveGalleryFolderData(formData).then((res) => {
        setNewLoading(false);
        if (res.success) {
          setIsAddNewShow(false);
          setFormData(initialValue);
          showAlert('Successfully Saved', 'success');
          galleryFolderList(folderType);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const cancel = () => {
    setIsAddNewShow(false);
    setFormData(initialValue);
  };
  const handleRename = (item) => {
    const i = cloneDeep(formData);
    i.name = item.imageName;
    i.id = item.id;
    setFormData(i);
    setIsAddNewShow(false);
  };
  const handleDelete = (data) => {
    setOpenDeleteModal(data);
  };
  const deleteApiCall = () => {
    const i = cloneDeep(openDeleteModal);
    deleteCustomFolderListing(i?.id).then((res) => {
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
        setOpenDeleteModal(false);
        galleryFolderList(folderType);
      }
    });
  };
  return (
    <div className="scroll-wrap" data-testid="image-folder">
      <div className="side-panel__container bg-white">
        <div className="side-panel__content">
          {/* <div className="doc-shadow doc-subhead"> */}
          <div className="d-flex">
            <div className="left-wrap c-w-300">
              <GallryLeftPanel
                folderList={folderList}
                setActive={setActive}
                active={active}
                folderType={folderType}
                setFolderActiveData={setFolderActiveData}
                setOpenChildFolder={setOpenChildFolder}
                isAddNewShow={isAddNewShow}
                setIsAddNewShow={setIsAddNewShow}
                handleCreateNew={handleCreateNew}
                cancel={cancel}
                formData={formData}
                setFormData={setFormData}
                handleRename={handleRename}
                handleDelete={handleDelete}
                loading={loading}
                galleryFolderList={galleryFolderList}
                getChildData={getChildData}
                isReport={isReport}
                newLoading={newLoading}
                disableBtn={disableBtn}
              />
            </div>
            <div className="right-wrap c-w-300">
              {folderType === 1 ? (
                <GalleryContent
                  folderActiveData={folderActiveData}
                  active={active}
                  getImages={getImages}
                  imageList={imageList}
                  openChildFolder={openChildFolder}
                  setOpenChildFolder={setOpenChildFolder}
                  isReport={isReport}
                  handleUploadImage={handleUploadImage}
                  setOpenModal={setOpenModal}
                  setClickedImage={setClickedImage}
                  loading={loading}
                />
              ) : (
                <CustomFolderContent
                  imageList={imageList}
                  isReport={isReport}
                  handleUploadImage={handleUploadImage}
                  setClickedImage={setClickedImage}
                  setOpenModal={setOpenModal}
                  loading={loading}
                  customLoading={customLoading}
                  setLeftClick={setLeftClick}
                />
              )}
            </div>
          </div>
          {openModal ? (
            <ModalBox
              modalClassName="large"
              removeClass={false}
              closeModal={() => {
                setOpenModal(false);
              }}
              heading={(
                <div className="d-flex">
                  <div className="me-2">Image Preview</div>
                </div>
              )}
            >
              <div className="d-flex align-items-center">
                {clickedImage.key > 0 ? (
                  <span
                    className="icon-arrow-thin-left font-26 cursor-pointer"
                    style={{ height: '30px', width: '30px' }}
                    id="leftclick0"
                    onClick={() => {
                      navigateImages(true);
                    }}
                  />
                ) : (
                  <span className="font-26" style={{ height: '30px', width: '30px' }} />
                )}

                <img src={clickedImage.url} alt="pic" className="h-30 w-300" />

                {clickedImage.key !== imageList.length - 1 ? (
                  <span
                    className="icon-arrow-thin-right font-26 cursor-pointer ms-3"
                    style={{ height: '30px', width: '30px' }}
                    id="leftclick0"
                    onClick={() => {
                      navigateImages();
                    }}
                  />
                ) : (
                  <span className="font-26" style={{ height: '30px', width: '30px' }} />
                )}
              </div>
              <div className="d-flex justify-content-center value mt-2">
                {imageList[clickedImage.key].imageName}
              </div>
            </ModalBox>
          ) : (
            ''
          )}

          {/* </div> */}
          {openDeleteModal ? (
            <div className="side-pannel-modal">
              <ModalBox
                modalClassName="small  mt-g-0"
                heading="Delete"
                onSave={() => deleteApiCall()}
                closeModal={() => setOpenDeleteModal(false)}
                buttonName="Delete"
              >
                <p>
                  On deleting, all images under this folder
                  will be deleted. Do you want to continue?
                </p>
              </ModalBox>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default GalleryFolderView;
