import React from 'react';
import moment from 'moment';
import helper from '../../../../../utils/helper';

const ProjectDetails = (props) => {
  const { projectDetails } = props;
  const { formatNumber } = helper;
  const array = [
    { label: 'Project Created Date', key: 'createdDate', isDate: true },
    { label: 'Project Type', key: 'projectType' },
    { label: 'Survey Type', key: 'surveyType', isPlanned: true },
    { label: 'Estimated Amount', key: 'estimatedBudget', isAmount: true },
    { label: 'Drydocking Due Date', key: 'ddDudate', isDate: true },
    { label: 'Special Survey Due Date', key: 'specialSurveyDueDate', isDate: true },
    { label: 'Tailshaft Survey Due Date', key: 'tailshaftSurveyDueDate', isDate: true },
    { label: 'Intermediate Hull Survey Due Date', key: 'intermediateSurveyDueDate', isDate: true }
  ];
  const renderList = (item) => {
    if (item?.isDate) {
      return (
        <div className="d-flex col-12 mt-4">
          <label className="col-6">
            {item.label}
            {' '}
            <span className="ms-2">:</span>
          </label>
          <div className="value col-6">
            {projectDetails && projectDetails[item.key] ? moment(projectDetails[item.key]).format('DD-MMM-YYYY') : '-'}
          </div>
        </div>
      );
    }
    if (item?.isAmount) {
      return (
        <div className="d-flex col-12 mt-4">
          <label className="col-6">
            {item.label}
            <span className="ms-2">:</span>
          </label>
          <div className="value col-6">
            {projectDetails.currencySymbol}
            {' '}
            {projectDetails && (formatNumber(projectDetails[item.key]) || '-')}
          </div>
        </div>
      );
    }
    if (item.isPlanned) {
      if (projectDetails?.projectType?.toLowerCase()?.includes('plan')) {
        return (
          <div className="d-flex col-12 mt-4">
            <label className="col-6">
              {item.label}
              {' '}
              <span className="ms-2">:</span>
            </label>
            <div className="value">{projectDetails && (projectDetails[item.key] || '-')}</div>
          </div>
        );
      }
      return null;
    }
    return (
      <div className="d-flex col-12 mt-4">
        <label className="col-6">
          {item.label}
          {' '}
          <span className="ms-2">:</span>
        </label>
        <div className="value">{projectDetails && (projectDetails[item.key] || '-')}</div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-wrap col-12  font-14 ">
      <div className="head-16 mb-4">Project Details</div>
      {array.map((item) => {
        return renderList(item);
      })}
      {projectDetails?.vesselMappedUsers?.map((i) => (
        <div className="d-flex col-12 mt-4">
          <label className="col-6">
            {i.roleName}
            {i.roleName ? (
              <span className="ms-2">:</span>
            ) : (<div />)}
          </label>
          <div className="value col-6">{i.userName}</div>
        </div>
      ))}
    </div>
  );
};

export default ProjectDetails;
