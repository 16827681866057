/* eslint-disable no-alert */
import axios from './axiosClient';
import showAlert from './alert';

let hasError = true;

export const serviceHandler = ({ url, payload, methordType = 'get', options }) => {
  return axios[methordType](`${url}`, payload, options)
    .then((response) => {
      if (response.status === 200) return { success: true, data: response.data };
      return { success: false };
    })
    .catch((error) => {
      if (hasError) {
        hasError = false;
        setTimeout(() => {
          hasError = true;
        }, 2000);
        if (error?.response && error?.response?.config?.method !== 'get' && !url?.includes('Project/ProjectMessage/List')) {
          switch (error?.response?.status) {
            case 500:
              showAlert(error.response?.data?.Message || 'Internal Server Error', 'error');
              console.log('error', error);
              break;
            case 402:
              showAlert('error.response.data', 'error');
              break;
            case 401:
              localStorage.clear();
              window.location.assign('/login');
              break;
            case 404:
              showAlert('Something went wrong.', 'error');
              break;
            case 403:
              window.location.assign('/permission-denied');
              break;
            case 400:
              showAlert(error?.response?.data?.title || 'Something went wrong.', 'error');
              break;
            case 422:
              console.log('error', error);
              if (error.config.responseType === 'blob') {
                new Promise((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = () => {
                    resolve(JSON.parse(reader.result));
                  };

                  reader.onerror = () => {
                    reject(error);
                  };

                  reader.readAsText(error.response.data);
                })
                  .then((data) => showAlert(data?.Message || 'Something went wrong', 'error'))
                  .catch(() => showAlert('Something went wrong', 'error'));
              } else if (error?.response?.data?.Message === 'Please contact vessel master to download the specs.') {
                showAlert(error?.response?.data?.Message, 'success');
              } else {
                showAlert(error?.response?.data?.Message || 'Something went wrong', 'error');
              }
              return { success: false, data: error };
            default:
              break;
          }
        } else {
          switch (error?.response?.status) {
            case 422:
              if (error?.response?.data?.Message === 'Please contact vessel master to download the specs.') {
                showAlert(error?.response?.data?.Message, 'success');
              } else {
                showAlert(error?.response?.data?.Message || 'Something went wrong', 'error');
              }
              break;
            case 401:
              localStorage.clear();
              window.location.assign('/login');
              break;
            default:
              // showAlert(error?.response?.data?.Message || 'Something went wrong', 'error');
              break;
          }
        }
        return { success: false, error };
      }
      return { success: false, error };
    });
};

// eslint-disable-next-line consistent-return
export const downloadErrorHandler = (error) => {
  console.log('error', JSON.stringify(error));
  if (error) {
    switch (error.response.status) {
      case 401:
        // localStorage.clear();
        // window.location.assign('/login');
        break;
      default:
        showAlert('No Rows Found', 'error');
        break;
    }
  }
};
