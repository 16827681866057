import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  SaveYard,
  GetYardList,
  GetYardDetails,
  DeleteYard,
  PublishYardList,
  GenerateTemplateYard,
  BulkUploadYard,
  ExportExcelYard
} = apiEndPoints;

const { downloadFile, currentDate } = helper;
export const getYardList = (payload) => {
  return serviceHandler({
    url: `${GetYardList}`,
    methordType: 'post',
    payload
  });
};
export const getYardDetails = (payload) => {
  return serviceHandler({
    url: `${GetYardDetails}`,
    methordType: 'get',
    payload
  });
};
export const saveYard = (payload) => {
  return serviceHandler({
    url: `${SaveYard}`,
    methordType: 'post',
    payload
  });
};
export const deleteYard = (payload) => {
  return serviceHandler({
    url: `${DeleteYard}?yardId=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const publishYardList = (payload) => {
  return serviceHandler({
    url: `${PublishYardList}`,
    methordType: 'put',
    payload
  });
};
export const exportExcelYard = (searchText) => {
  return axios
    .get(`${ExportExcelYard}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Yard_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const bulkUploadYard = (payload) => {
  return serviceHandler({
    url: `${BulkUploadYard}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateYard}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Yard Details_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
