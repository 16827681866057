import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';

const CreateCurrency = (props) => {
  const { formData, handleChange, countryList, AddCurrency, removeRow } = props;
  return (
    <div className="container-fluid" data-testid="create-currency">
      {
        formData.map((cList, key) => {
          return (
            <div className="row mb-3 align-items-center ">
              <div className="col-4">
                <label>
                  Country
                  <span className="text-primaryRed-1">*</span>
                </label>
                <Select
                  autoFocus
                  menuPlacement={key > 2 ? 'top' : 'bottom'}
                  value={countryList.filter((option) => option.value === cList?.countryId)}
                  onChange={(option) => handleChange(key, option, 'countryId', 'countryId')}
                  className="modal-select mt-2"
                  label="country"
                  id="countryId"
                  options={_.sortBy(countryList, 'label')}
                  maxMenuHeight={160}
                />
              </div>
              <div className="col-4">
                <InputType1 disabled id="currencyName" name="currencyName" value={cList?.currencyName} className="modal-inputs" label="Currency" />
              </div>
              <div className="col-3">
                <InputType1
                  value={cList?.rate}
                  name="rate"
                  onChange={(e) => handleChange(key, e, '', 'rate')}
                  label="Current Rate"
                  id="rate"
                  className="modal-inputs"
                  isMandatory="true"
                />
              </div>
              <div className="col-1">
                {formData.length > 1 ? (
                  <button className="link-btn mt-4" disabled={cList.id} type="button" data-test="click1" onClick={() => { removeRow(key); }}>
                    <span className="icon-delete font-24" />
                  </button>
                ) : ''}
              </div>
            </div>
          );
        })
      }
      <button className="link-btn mt-5 " type="button" id="add" onClick={AddCurrency}>
        Add More Currency
      </button>
      {formData.length < 5 && <div className="py-5 my-5" />}
    </div>
  );
};
export default CreateCurrency;
