import React from 'react';
import helper from '../../../../utils/helper';
import InputType1 from '../../../common/InputType1';
import ModalBox from '../../../common/ModalBox';
import SectionCard from './SectionCard';

const Sections = ({
  paintCostDetails,
  handleAddNew,
  isOpenRow,
  setIsOpenRow,
  isOpenHull,
  setIsOpenHull,
  setOpenModalSection,
  handleChangeSection,
  openModalSection,
  addSection,
  setNewSection,
  newSection,
  isTSI,
  setisOpenModal
}) => {
  const { charValidation } = helper;
  console.log('paintCostDetails', paintCostDetails);
  return (
    <div data-testid="p-3" className="text-content-grey">
      <div className="border-down d-flex justify-content-between p-3 mt-4">
        <div className="font-20 font-bold ">Spec Section</div>
        {isTSI && (
        <button
          onClick={() => {
            setNewSection('');
            setOpenModalSection(true);
          }}
          type="button"
          className="secondary-btn mb-2"
        >
          Add Section
        </button>
        )}

      </div>
      <div className="ps-3">
        <div className="font-18 ">
          <span
            id="hullId"
            className={isOpenHull === 'hull' ? 'font-10 icon-arrow-thin-down me-3 pointer' : ' icon-arrow-thin-right pointer me-2'}
            onClick={() => {
              if (isOpenHull === 'hull') setIsOpenHull('');
              else setIsOpenHull('hull');
            }}
          />
          <span className="font-bold">Hull</span>
        </div>
        {paintCostDetails.map((section, index) => {
          if (section.type < 5 && isOpenHull === 'hull') {
            return (
              <div className="ps-4">
                <SectionCard
                  isOpenRow={isOpenRow}
                  setIsOpenRow={setIsOpenRow}
                  section={section}
                  index={index}
                  handleAddNew={handleAddNew}
                  paintCostDetailsLength={paintCostDetails?.length - 1}
                  handleChangeSection={handleChangeSection}
                  isTSI={isTSI}
                  setisOpenModal={setisOpenModal}
                />
              </div>
            );
          }
          if (section.type >= 5) {
            return (
              <div className="">
                <SectionCard
                  isOpenRow={isOpenRow}
                  setIsOpenRow={setIsOpenRow}
                  section={section}
                  index={index}
                  handleAddNew={handleAddNew}
                  paintCostDetailsLength={paintCostDetails?.length - 1}
                  handleChangeSection={handleChangeSection}
                  isTSI={isTSI}
                  setisOpenModal={setisOpenModal}
                />
              </div>
            );
          }
          return '';
        })}
      </div>
      {openModalSection ? (
        <ModalBox
          modalClassName="medium"
          onSave={() => addSection()}
          closeModal={() => {
            setNewSection('');
            setOpenModalSection(false);
          }}
          heading="Add Section"
          buttonName="Save"
        >
          <div className="col-4">
            <InputType1
              autoFocus
              id="Section"
              onChange={(e) => setNewSection(charValidation(e.target.value, 300))}
              value={newSection}
              className="modal-inputs small "
              label="Section"
              isMandatory="true"
            />
          </div>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default Sections;
