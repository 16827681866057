import React from 'react';
import { truncate } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../../../../common/loader';
import Pagination from '../../../../../../common/pagination';

const ComponentList = (props) => {
  const { list,
    setOpenDeleteModal,
    pageClick,
    currentpage,
    onSizeChange,
    pageSize,
    loading,
    isTsiDisabled,
    isTSI,
    fromSupplementary,
    activeTabSupplementary
  } = props;
  if (loading) return <div data-testid="com-2"><Loader /></div>;
  return (
    <>
      <div className="scroll-area" data-testid="com-2">
        <table className="w-full html-table">
          <thead>
            <tr>
              <th>
                Component
              </th>
              <th>
                Maker
              </th>
              <th>
                Model
              </th>
              <th>
                Action
              </th>
            </tr>
          </thead>
          <tbody>

            {list && list.items?.length ? (
              <>
                {list.items?.map((item) => {
                  return (
                    <tr>
                      <td>
                        <span
                          className="mb-2 text-ellipsis"
                          data-tooltip-id="project-comp"
                          data-tooltip-content={item.name}
                        >
                          {truncate(item.name, { length: 25 })}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-ellipsis"
                          data-tooltip-id="project-comp"
                          data-tooltip-content={item.makerName}
                        >
                          {truncate(item.makerName, { length: 25 })}
                        </span>
                      </td>
                      <td>
                        <span
                          className="text-ellipsis text-uppercase"
                          data-tooltip-id="project-comp"
                          data-tooltip-content={item.model}
                        >
                          {truncate(item.model, { length: 25 })}
                        </span>
                      </td>
                      <td>
                        <button id="delete" disabled={isTsiDisabled || !isTSI || (fromSupplementary && !activeTabSupplementary)} onClick={() => setOpenDeleteModal(item.id)} type="button" className="link-btn">
                          <span className="icon-delete font-24" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan="4">
                  <div className="mt-2 p-4 d-flex justify-content-center align-items-center ">
                    <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                    <div className="font-18 animate__animated animate__headShake">
                      No data found
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {list?.totalItems > 10 && (
        <Pagination
          pageClick={pageClick}
          pageCount={list.totalPages}
          currentpage={currentpage}
          onSizeChange={onSizeChange}
          size={pageSize}
        />
      )}
      <Tooltip id="project-comp" place="top" className="tooltip mt-3" />
    </>
  );
};
export default ComponentList;
