import axios from 'axios';
import helper from '../../../utils/helper';
import { serviceHandler } from '../../../utils/serviceHandler';
import API_END_POINTS from '../../../utils/apiUrlConstants';

const {
  GetComments,
  GetChatMsgs,
  GetSofs,
  SaveSofs,
  DeleteSofs,
  yardNotification,
  saveYardNotification,
  getSnooze,
  VesselManagementUsersUrl,
  fileDownloadFromS3BucketUrl,
  getAuditLogUrl
} = API_END_POINTS;
const { downloadFile } = helper;
export const getCommentsOnPhase = (payload) => {
  return serviceHandler({
    url: `${GetComments}?projectId=${payload.id}&type=${payload.type}`,
    methordType: 'get',
    payload
  });
};

export const getChatMessages = (payload) => {
  return serviceHandler({
    url: `${GetChatMsgs}`,
    methordType: 'post',
    payload
  });
};

export const getSOFData = (projectId) => {
  return serviceHandler({
    url: `${GetSofs}?projectId=${projectId}`,
    methordType: 'get'
  });
};

export const saveSOF = (payload) => {
  return serviceHandler({
    url: `${SaveSofs}`,
    methordType: 'post',
    payload
  });
};

export const deleteSOF = (sofId) => {
  return serviceHandler({
    url: `${DeleteSofs}?sofId=${sofId}`,
    methordType: 'delete'
  });
};

export const getYardNotification = (payload) => {
  return serviceHandler({
    url: `${yardNotification}`,
    methordType: 'get',
    payload
  });
};
export const SaveYardNotification = (payload) => {
  return serviceHandler({
    url: `${saveYardNotification}`,
    methordType: 'post',
    payload
  });
};
export const getSnoozeNotification = (payload) => {
  return serviceHandler({
    url: `${getSnooze}?id=${payload.id}&days=${5}`,
    methordType: 'get',
    payload
  });
};

export const fileDownloadFromS3Bucket = (id, name) => {
  return axios
    .post(`${fileDownloadFromS3BucketUrl}`, { aId: id }, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, name);
    });
};

export const getVesselManagementUsers = (payload) => {
  return serviceHandler({
    url: `${VesselManagementUsersUrl}?vesselId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const GetAuditLogUrl = (payload) => {
  return serviceHandler({
    url: `${getAuditLogUrl}`,
    methordType: 'post',
    payload
  });
};
