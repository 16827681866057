import React from 'react';
import { Tooltip } from 'react-tooltip';

const UploadBg = (props) => {
  const { bgImg, ondrop, setBgImg } = props;
  return (
    <>
      <p>Please upload a png, jpg or jpeg image of 1024 * 1024 size for better visibility</p>
      <div className={`drag-drop-file my-4 ${bgImg && 'file-added '}`}>
        <div className="icon-buble mb-5">
          <span className="icon-file-text2" />
        </div>
        <input className="p-5 align-self-center" id="upload" type="file" onChange={(e) => { ondrop(e); }} />
        {!bgImg && (<p>Choose a file or drag and drop it here</p>)}
        {bgImg && (
          <div className="font-18 d-flex attached-file">
            <span className="icon-check-rnd me-2 font-20"> </span>
            <div>
              <span data-tooltip-id="project-addattach" data-tooltip-content={bgImg?.split('?')[0].split('UploadedFiles/')[1]}>
                {_.truncate(bgImg?.split('?')[0].split('UploadedFiles/')[1], {
                  length: 20,
                  separator: ' '
                })}
              </span>

            </div>
            {/* <div>{bgImg?.split('?')[0].split('UploadedFiles/')[1]}</div> */}
            <button type="button" id="delete" className="link-btn clear-file" onClick={() => { setBgImg(''); }}>
              <span className="icon-delete ms-4 font-20"> </span>
            </button>
          </div>
        )}
        <Tooltip id="project-addattach" place="top" className="tooltip mt-3" />
      </div>
    </>
  );
};
export default UploadBg;
