/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Loader from '../../../../../../common/loader';
import Card from './card';

const CardWrapper = (props) => {
  const { allPurchaseOrder, loading, currency,
    fromVariation, editData, deletePo, isTsiDisabled, isTSI, isVesselUser,
    fromSupplementary, activeTabSupplementary, projInfo, projectData } = props;
  if (loading) {
    return <Loader />;
  } if (allPurchaseOrder.length > 0) {
    return (
      <table className="w-full html-table h-full">
        <thead>
          <tr>
            <th> #</th>
            <th> PO/PR </th>
            <th>
              Category
            </th>
            <th>
              Type
            </th>
            <th> Amount</th>
            <th>  Owner's Cost</th>
            {isTSI && !isVesselUser ? <th> Actions</th> : ''}
          </tr>
        </thead>
        <tbody>
          {' '}
          {
            allPurchaseOrder.map((item) => {
              return (
                <>
                  <Card
                    item={item}
                    editData={editData}
                    deletePo={deletePo}
                    isTsiDisabled={isTsiDisabled}
                    isTSI={isTSI}
                    currency={currency}
                    fromVariation={fromVariation}
                    fromSupplementary={fromSupplementary}
                    activeTabSupplementary={activeTabSupplementary}
                    projInfo={projInfo}
                    isVesselUser={isVesselUser}
                    projectData={projectData}
                  />
                </>
              );
            })
          }
        </tbody>
      </table>
    );
  }
  return (
    <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="card-wrapper">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">
        No data found
      </div>
    </div>
  );
};

export default CardWrapper;
