/* eslint-disable radix */
import React, { useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';
import ModalBox from '../../../common/ModalBox';
import { TotalEstimate, SecTotal } from './totalEstimate';
import helper from '../../../../utils/helper';

const QuotationTable = (props) => {
  const { formatNumber, fieldValidation, roles } = helper;

  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const { specList, mainSec, showsubSec, onSecOpen, setSpecList, setShowMore,
    setShowAttach, onAddServiceLine, onRemoveLine } = props;
  const [addComment, setAddComment] = useState(false);
  const [comment, setComment] = useState('');

  const onServiceFieldChange = (e, index, sub, num) => {
    const currentSpecList = { ...specList };
    // // if (e.target.name === 'quantity') {
    // //   currentSpecList.sectionData[index].specDataList[sub]
    // //     .serviceLineList[num][e.target.name] =
    // restrictDecimal(charValidation(_.isNaN(parseInt(e.target.value.replace(/[^0-9.]/g, '')))))
    // //       ? 0 : parseInt(e.target.value.replace(/[^0-9]/g, ''));
    // // }
    if (e.target.name === 'rate') {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 5, 'returnString');
    } else if (e.target.name === 'quantity') {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6);
    } else {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 3, 'returnString');
    }
    const rowDetails = currentSpecList.sectionData[index].specDataList[sub]
      .serviceLineList[num];
    if (rowDetails.quantity && rowDetails.rate && rowDetails.discount) {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num]
        .amount = (((rowDetails.quantity * rowDetails.rate) / 100) * (100 - rowDetails.discount))
          .toFixed(2).toString();
    } else if (rowDetails.quantity && rowDetails.rate) {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num].amount = (rowDetails.quantity * rowDetails.rate)
          .toFixed(2).toString();
    } else {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num].amount = '0';
    }
    currentSpecList.rfqData.total = TotalEstimate(currentSpecList);
    currentSpecList.rfqData.yardFinalEstimate = (currentSpecList.rfqData
      .total - currentSpecList.rfqData.discount).toString();
    currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList.rfqData
      .total - currentSpecList.rfqData.discount).toString();
    currentSpecList.sectionData[index].total = SecTotal(currentSpecList.sectionData[index])
      .toString();
    setSpecList(currentSpecList);
  };
  const onSaveComment = () => {
    const currentSpecList = { ...specList };
    currentSpecList.sectionData[addComment[0]].specDataList[addComment[1]]
      .serviceLineList[addComment[2]].comments = comment;
    setSpecList(currentSpecList);
    setAddComment(false);
    setComment('');
  };

  // const formatNumber = (number) => {
  //   if (number == null) {
  //     return '';
  //   }
  //   return number.toLocaleString('en-IN', {
  //     style: 'decimal',
  //     maximumFractionDigits: 2,
  //     minimumFractionDigits: 2
  //   });
  // };

  return (
    <>
      <div className="spc-map ps-3">
        <div className="d-flex border-down py-2 ps-3">
          <div className="col-1 font-bold">SEC#</div>
          <div className="col-8 font-bold">SPEC SECTION</div>
          <div className="col-3 font-bold text-left-align">TOTAL</div>
        </div>
        {
        specList.sectionData?.map((sec, mainIndex) => {
          return (
            <div className={(sec.projectSpecId === mainSec && showsubSec) ? 'highlight-tender' : ''}>
              <div className="d-flex border-down py-2 ps-3">
                <div className="col-1 d-flex align-items-center">
                  <span
                    id={sec.projectSpecId}
                    className={sec.projectSpecId === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec.projectSpecId, true); }}
                  />
                  <span>{sec.specificationNo}</span>
                </div>
                <div className="col-8">
                  <span data-tooltip-id="project-qttable" data-tooltip-content={sec.title}>{_.truncate(sec.title, { length: 30 })}</span>

                </div>
                <div className="col-3 text-left-align head-14">
                  {specList.rfqData?.currencySymbol}
                  {' '}
                  {formatNumber(sec.total)}

                </div>
              </div>
              {
              sec.projectSpecId === mainSec && sec.specDataList.map((subsec, subIndex) => {
                return (
                  <>
                    <div className="d-flex mb-2 py-2 ps-3">
                      <div className="col-1" />
                      <div className="col-11 d-flex align-items-center">
                        <span
                          id={subsec.projectSpecId}
                          className={subsec.projectSpecId === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                          onClick={() => { onSecOpen(subsec.projectSpecId, false); }}
                        />
                        <span className="me-4">{subsec.specificationNo}</span>
                        <div className="">
                          <span data-tooltip-id="project-qttable" data-tooltip-content={subsec.title}>
                            {_.truncate(subsec.title, { length: 30 })}
                          </span>
                        </div>
                        {subsec.projectSpecId === showsubSec && (
                        <>
                          <button type="button" id="viewmore" className="primary-btn small ms-5" onClick={() => { setShowMore(subsec); }}>View More</button>
                          <button type="button" id="attach" className="primary-btn small ms-5" onClick={() => { setShowAttach({ key1: mainIndex, key2: subIndex, section: subsec }); }}>Attachments</button>
                        </>
                        )}
                      </div>
                    </div>
                    {
                      subsec.projectSpecId === showsubSec
                       && subsec.serviceLineList.map((serv, num) => {
                         return (
                           <>
                             {num === 0 && (
                             <div className="d-flex ps-3 align-items-center q-table-mt1">
                               <div className="col-1" />
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                               <div className="col-04 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                               <div className="col-0 bg-sky-white ps-3 border-down py-2">UOM</div>
                               <div className="col-0 bg-sky-white ps-2 border-down py-2">Qty</div>
                               <div className="col-0 bg-sky-white ps-2 border-down py-2">Rate</div>
                               <div className="col-0 bg-sky-white ps-3 border-down py-2">Disc[%]</div>
                               <div className="col-1 bg-sky-white ps-3 border-down py-2 text-left-align">Amount</div>
                               <div className=" bg-sky-white ps-3 border-down py-2">Comments </div>
                             </div>
                             )}
                             <div className="d-flex ps-3 my-2 border-down">
                               <div className="col-1" />
                               <div className="col-1 ps-3 ">{serv.number}</div>
                               <div className="col-04 ps-3 text-justify text-break-all">
                                 <span>{serv.serviceLineName}</span>
                               </div>
                               <div className="col-0 ps-3  ">
                                 <span data-tooltip-id="project-qttable" data-tooltip-content={serv.uomName}>
                                   {_.truncate(serv.uomName, { length: 7 }) || '-'}
                                 </span>
                               </div>
                               <div className="col-0 ps-2 pb-1">
                                 <InputType1
                                   label=""
                                   disabled={serv.specificationLevel === 4 && serv.quantityEnterBy === 'TSI'}
                                   name="quantity"
                                  //  min="0"
                                  //  type="number"
                                  //  step="0.01"
                                   id={'quantity'.concat(num)}
                                   className="small"
                                   value={serv.quantity}
                                   onChange={(e) => {
                                     onServiceFieldChange(e, mainIndex, subIndex, num);
                                   }}
                                 />
                               </div>
                               <div className="col-0 ps-2 pb-1">
                                 <InputType1
                                   label=""
                                   disabled={serv.specificationLevel === 4}
                                   name="rate"
                                  //  min="0"
                                  //  type="number"
                                  //  step="0.01"
                                   id={'rate'.concat(num)}
                                   className="small"
                                   value={!serv.rate || serv.rate === '0' ? '' : serv.rate}
                                   onChange={(e) => {
                                     onServiceFieldChange(e, mainIndex, subIndex, num);
                                   }}
                                 />
                               </div>
                               <div className="col-0 ps-3  pb-1">
                                 <InputType1
                                   label=""
                                   disabled={serv.specificationLevel === 4}
                                   name="discount"
                                   id={'discount'.concat(num)}
                                   className="small"
                                   value={!serv.discount || serv.discount === '0' ? '' : serv.discount}
                                   onChange={(e) => {
                                     onServiceFieldChange(e, mainIndex, subIndex, num);
                                   }}
                                 />
                               </div>
                               <div className=" ps-3 col-1 text-left-align">
                                 <span data-tooltip-id="project-qttable" data-tooltip-content={formatNumber(serv.amount || '0')}>
                                   {specList.rfqData?.currencySymbol?.concat(' ', _.truncate(formatNumber(serv.amount || '0'), { length: 9 }))}
                                 </span>

                               </div>
                               <div className=" ps-3  align-items-center">
                                 <span id={'comment'.concat(num)} className="font-20 icon-add-comment brand-color pointer" onClick={() => { setAddComment([mainIndex, subIndex, num]); setComment(serv.comments); }} />
                                 {serv.isFromYard && specList?.rfqData.status !== 4
                                 && <span className="icon-delete font-20 red ms-4 pointer" onClick={() => { onRemoveLine(mainIndex, subIndex, num); }} />}
                               </div>
                             </div>
                           </>
                         );
                       })
                      }
                    {subsec.projectSpecId === showsubSec && specList?.rfqData?.status !== 4 && (
                    <div className="ps-3 d-flex my-2">
                      <div className="col-1" />
                      <div>
                        <button type="button" className="link-btn" onClick={() => onAddServiceLine(mainIndex, subIndex)}>Add Service Line</button>
                      </div>
                    </div>
                    )}
                  </>
                );
              })
            }
            </div>
          );
        })
      }
      </div>
      {addComment && (
      <ModalBox
        modalClassName="small"
        heading={specList.sectionData[addComment[0]]?.specDataList[addComment[1]]
          .serviceLineList[addComment[2]].comments ? 'Update Comment' : 'Add Comment'}
        onSave={() => onSaveComment()}
        closeModal={() => {
          setAddComment(false);
          setComment('');
        }}
        buttonName="Save"
      >
        <textarea disabled={isAdmin ? false : (specList?.rfqData?.status === 4)} placeholder="Comment" className="text-box w-full h-text-area pt-2" onChange={(e) => { setComment(e.target.value); }} value={comment} />
      </ModalBox>
      )}
      <Tooltip id="project-qttable" place="top" className="tooltip mt-3" />
    </>
  );
};
export default QuotationTable;
