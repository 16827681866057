import React from 'react';
import InputType1 from '../../../../../../common/InputType1';

const AddNewSec = (props) => {
  const { newMainData, setNewMainData } = props;
  const handleChange = (e) => {
    const currentData = { ...newMainData };
    if (e.target.name === 'specificationNo') {
      currentData[e.target.name] = e.target.value.replace(/[^0-9]/g, '').substring(0, 10);
    } else {
      currentData[e.target.name] = e.target.value.trimLeft().toUpperCase().substring(0, 500);
    }
    setNewMainData(currentData);
  };
  return (
    <div className="row">
      <div className="col-4">
        <InputType1
          autoFocus
          id="specificationNo"
          name="specificationNo"
          value={newMainData.specificationNo}
          onChange={(e) => handleChange(e)}
          className="modal-input"
          label="Section number"
          isMandatory="true"
        />
      </div>
      <div className="col-8">
        <InputType1
          id="title"
          value={newMainData.title}
          name="title"
          onChange={(e) => handleChange(e)}
          className="modal-input"
          label="Section name"
          isMandatory="true"
        />
      </div>
    </div>
  );
};
export default AddNewSec;
