/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Select from 'react-select';
import { projectSpecStatusData } from '../../Projects/PhasePage/Repair/services';
import { pieChartConfig, chartColors, chartValues } from '../../Projects/PhasePage/Repair/SpecComponents/constant';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ projectNameList, selectedproject, setSelectedProject }) => {
  // const [chartData, setChartData] = useState(data);
  const [statusData, setStatusData] = useState({});
  const [loader, setLoader] = useState(true);

  const handleChange = (e) => {
    setSelectedProject(e.id);
  };
  const overAllTaskCounts = () => {
    projectSpecStatusData({
      projectId: selectedproject
    }).then((res) => {
      setStatusData(res?.data);
      setLoader(false);
    });
  };
  let hasData = false;
  if (projectNameList.filter((options) => options.phase === 4).length !== 0) {
    hasData = true;
  }
  // console.log(projectNameList, 'projectNameList');
  const data = {
    datasets: [
      {

        data: chartValues(statusData),
        backgroundColor: chartColors,
        borderColor: [
          'rgba(255, 255, 255)',
          'rgba(255, 255, 255)',
          'rgba(255, 255, 255)'
        ],
        borderWidth: 2,
        cutout: 80,
        radius: 100
      }
    ]
  };
  useEffect(() => {
    if (selectedproject) {
      overAllTaskCounts();
    }
  }, [selectedproject]);

  return (
    <div className=" widget-card " data-testid="piechart-dashboard">
      <div className="head">
        <h4 className="head-16 d-flex align-items-center ">
          <span>Job Progress</span>
        </h4>
      </div>
      <div className="body">
        <div><label>Please select a project</label></div>
        <Select
          value={projectNameList?.filter((options) => options.id === selectedproject)}
          onChange={(e) => handleChange(e)}
          className="modal-select mt-4"
          label="Project Name"
          options={projectNameList.filter((options) => options.phase === 4)}
          placeholder="Project Name"
          id="projectNameList"
        />
      </div>
      {projectNameList.length >= 0 && statusData?.totalCount !== 0 ? (
        <div className=" body ">
          <div className="doughnut-dashboard relative ">
            {!loader && hasData ? (
              <Doughnut data={data} />
            ) : (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="card-wrapper">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">
                  No record found!!
                </div>
              </div>
            )}
            {hasData && (
            <div className="percentage-status2 ">
              <div className="font-22 brand-color ">
                {statusData?.completedPercentage}
                %
              </div>
              <div className="font-16 mt-2">Completed</div>
            </div>
            )}

          </div>
          {hasData && (
          <div className=" font-13  d-flex mt-5 col-12 align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center  mb-3">
              <span className="icon-disc" style={{ color: '#0fdf4d' }} />
              <div className="d-flex">
                <div className="ms-2 me-2">
                  Total
                  {' '}
                  <span>:</span>
                  {' '}
                </div>
                <div className=" me-5">{statusData?.totalCount}</div>
              </div>
            </div>
            {
            pieChartConfig.map((conf, key) => {
              return (
                <div className="d-flex col-6 justify-content-between align-items-center flex-wrap">
                  <div className="d-flex align-items-center mb-3 whitespace-pre">
                    <>
                      <span className="icon-disc" style={{ color: conf.color }} />
                      <div className="d-flex">
                        <div className="ms-2 me-2">
                          {conf?.label}
                          <span className="ms-2">:</span>
                        </div>
                        <div className=" me-5">{statusData && statusData[conf?.key]}</div>
                      </div>
                    </>
                  </div>
                </div>
              );
            })
          }
          </div>
          )}

        </div>
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="dashboard">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">
            No data found
          </div>
        </div>
      )}

    </div>

  );
};
export default PieChart;
