/* istanbul ignore file */
export const HeadingLabel = (location) => {
  if (location.includes('/admin/data/spec/')) {
    return 'Specification Setup';
  }
  if (location.includes('/admin/data/task')) {
    return 'Task';
  }
  if (location.includes('/admin/data/tags')) {
    return 'Tags';
  }
  if (location.includes('/admin/data/kfp')) {
    return 'KFP';
  }
  if (location.includes('/admin/data/vesseldata')) {
    return 'Vessel Types';
  }
  if (location.includes('/admin/data/currency')) {
    return 'Currency';
  }
  if (location.includes('/admin/data/uom')) {
    return 'UOM';
  }
  if (location.includes('/admin/data/ac-code')) {
    return 'AC Code';
  }
  if (location.includes('/admin/data/yard')) {
    return 'Yard Details';
  }
  if (location.includes('/admin/data/general-config')) {
    return 'General Project Config';
  }
  if (location.includes('/admin/data/templates')) {
    return 'Templates';
  }
  return 'heading';
};
