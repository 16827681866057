import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  getCurrencyUrl,
  saveCurrencyUrl,
  deleteCurrencyUrl,
  countryListUrl, SetBaseCurrency,
  exportCurrencyExcel,
  BulkUploadCurrency,
  GenerateTemplateCurrency
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getCountryList = (payload) => {
  return serviceHandler({
    url: `${countryListUrl}`,
    methordType: 'get',
    payload
  });
};
export const getCurrency = (payload) => {
  return serviceHandler({
    url: `${getCurrencyUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveCurrency = (payload) => {
  return serviceHandler({
    url: `${saveCurrencyUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteCurrency = (payload) => {
  return serviceHandler({
    url: `${deleteCurrencyUrl}?currencyId=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const setBaseCurrency = (payload) => {
  return serviceHandler({
    url: `${SetBaseCurrency}?id=${payload.id}`,
    methordType: 'put',
    payload
  });
};
export const exportExcelCurrency = (searchText) => {
  return axios
    .get(`${exportCurrencyExcel}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Currency_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const bulkUploadCurrency = (payload) => {
  return serviceHandler({
    url: `${BulkUploadCurrency}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateCurrency}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Currency_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
