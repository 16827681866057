import { serviceHandler } from '../../../../../../../utils/serviceHandler';
import API_END_POINTS from '../../../../../../../utils/apiUrlConstants';

const { GetProjSpecAttach, SaveProjSpecAttachMulti, DeleteProjSpecAttach } = API_END_POINTS;

export const getSpecAttachs = (payload) => {
  return serviceHandler({
    url: `${GetProjSpecAttach}?projectId=${payload.projectId}&specId=${payload.specId}&search=${payload.search}`,
    methordType: 'get',
    payload
  });
};

export const saveSpecAttachs = (payload) => {
  return serviceHandler({
    url: `${SaveProjSpecAttachMulti}`,
    methordType: 'post',
    payload
  });
};

export const deleteSpecAttachs = (payload) => {
  return (serviceHandler({
    url: `${DeleteProjSpecAttach}?specId=${payload.specId}&documentId=${payload.documentId}`,
    methordType: 'put',
    payload
  }));
};
