import React from 'react';
import AttachCard from './attachCard';

const CardWrapper = (props) => {
  const { allFiles, showDeleteModal, isTsiDisabled, isTSI, projInfo } = props;
  if (allFiles?.specDocuments?.length > 0) {
    return (
      allFiles?.specDocuments?.map((index) => {
        return (
          <AttachCard
            index={index}
            showDeleteModal={showDeleteModal}
            isTsiDisabled={isTsiDisabled}
            isTSI={isTSI}
            projInfo={projInfo}
          />
        );
      })
    );
  }
  return (
    <div data-testid="card-wrapper" className="mt-5 p-4 d-flex justify-content-center align-items-center ">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">
        No data found
      </div>
    </div>
  );
};

export default CardWrapper;
