/* istanbul ignore file */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import showAlert from '../../../../../utils/alert';
import helper from '../../../../../utils/helper';
import SidePanelCustom from './SupplementarySpecs/sidePanelCustom';
import QuotationPrew from './quotationPrew';
import AddSupplementaryJobs from './SupplementarySpecs/AddNewJobs/addSupplementaryJobs';
import SpecListing from './SupplementarySpecs/index';
import { saveSpec, submitSpecsApproval, supplemetarySpecLists } from './SupplementarySpecs/services';
import SubmitView from './SupplementarySpecs/submitView';
import ModalBox from '../../../../common/ModalBox';
import { getConfirmation, getProjectDetailsTSI, getProjectHasApprover } from '../../NewProject/services';
import Loader from '../../../../common/loader';
import ImportRFQ from './ReceivedQuotes/importRFQ';
import ReceivedQuotes from './ReceivedQuotes';
import { importYardQuoteData, receivedQuote } from './ReceivedQuotes/services';
import ServiceLineModal from './ServiceLineModal';
import { GetEmptyACCode, ExportExcelForSpecLibrary } from '../Specify/services';
import QuoteStatusModal from './quoteStatusModal';
import { exportYardExcel, getQuoteStatusList, getSupplementaryYardMailList, getAllYardListExport } from './services';
import EmailPreview from './SupplementarySpecs/EmailPreview';
import YardConfirmationModal from './SupplementarySpecs/yardConfirmationModal';
import ReceivedSpecs from './SupplementarySpecs/receivedSpec';
import ConfirmationModalForYard from './SupplementarySpecs/confirmationModalForYard';
import SupplementarySendSuccessModal from './SupplementarySpecs/supplementarySendSuccess';
import Attachments from './ReceivedQuotes/attachments';
import SpecList from '../Specify/SpecsList';
import SidePanel from '../../../../common/sidePanel';
import Comments from '../Repair/AddVariation/ReceivedQuotes/comments';
import SelectExportYard from './SelectExportYard';

const Tender = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const { state } = useLocation();
  const { formValidation, ZERO_UUID, projectPhase, roles, MAX_FILE_SIZE } = helper;
  const [isSupplementaryClicked, setIsSupplementaryClicked] = useState(false);
  const [isComponentClicked, setIsComponentClicked] = useState(false);
  const [specDetails, setSpecDetails] = useState();
  const [projInfo, setProjInfo] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [sendForApproval, setSendForApproval] = useState(false);
  const [specId, setSpecId] = useState(ZERO_UUID);
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const [isTsiDisabled, setIsTsiDisabled] = useState(false);
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR;
  const isAdmin = userRole === roles.ADMIN;
  const isVesselUser = userRole === roles.VESSEL_USER;
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const { id } = useParams();
  const [file, setFile] = useState('');
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quoteLength, setQuoteLength] = useState(0);
  const [openYardQuote, setOpenYardQuote] = useState(false);
  const [addClicked, setIsAddClicked] = useState(false);
  const [currency, setCurrency] = useState(false);
  const [currencyId, setCurrencyId] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showServiceLineModal, setShowServiceLineModal] = useState(false);
  const [continueServs, setContinueServs] = useState(false);
  const [emptySpecList, setEmptySpecList] = useState();
  const [emptyAcCode, setEmptyAcCode] = useState();
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const [activeTab, setActiveTab] = useState(true);
  const [activeTabSupplementary, setActiveTabSupplementary] = useState(true);
  const [showYardConfirmModal, setShowYardConfirmModal] = useState(false);
  const [yardMailList, setYardMailList] = useState([]);
  const [previewList, setPreviewList] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [pendingData, setPendingData] = useState(false);
  const [statusState, setStatusState] = useState({
    new: true,
    approved: false,
    pending: false,
    rejected: false
  });
  const [listOfSpecs, setListOfSpecs] = useState([]);
  const [viewSpec, setViewSpec] = useState(false);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [allFiles, setAllFiles] = useState();
  const [comments, setComments] = useState('');
  const [viewComments, setViewComments] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState();
  const [recSpecList, setRecSpecList] = useState([]);
  const [icon, setIcon] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const onReloadSpecData = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      if (res.success) {
        localStorage.setItem('managementId', res?.data?.managementId);
        setProjectBasicDetails(res.data);
      }
    });
  };
  useEffect(() => {
    onReloadSpecData();
  }, []);
  const fromSupplementary = true;
  const initialFormData = {
    sectionId: '',
    sectionNo: '',
    sectionTitle: '',
    sectionType: 0,
    specId: '',
    specNoAutoGenerated: '',
    specTitle: '',
    specType: 0,
    projectId: '',
    specAssigned: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmited, setIsSubmitted] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [openViewQuoteStatusModal, setOpenViewQuoteStatusModal] = useState(false);
  const [confirmationModalForYard, setConfirmationModalForYard] = useState(false);
  const [supplementarySended, setSupplementarySended] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const isSidePanel = false;
  const [exportModal, setExportModal] = useState(false);
  const [yardList, setYardList] = useState([]);
  const [selectedYard, setSelectedYard] = useState({});
  const [loader, setLoader] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState(1);
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  const getName = (resubmit = false) => {
    let n = 'Submit';
    if (resubmit) {
      n = 'Resubmit';
    }
    if (hasApprover) {
      n += ' for Approval';
    }
    return n;
  };
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type)?.then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);

  const getBasicDetails = () => {
    const payload = {
      id
    };
    getConfirmation(payload).then((res) => {
      if (res?.success) {
        setProjectDetails(res?.data);
        setProjInfo(res?.data);
        setCurrency(res?.data?.currencySymbol);
        setCurrencyId(res?.data?.currencyId);
        if (localStorage.getItem('USER_ID') === res?.data?.backupTsiId) {
          setIsTsiDisabled(true);
        }
      }
    });
  };

  const getQuotes = () => {
    receivedQuote(id).then((resp) => {
      if (resp?.success) {
        setQuoteLength(resp?.data?.length);
      }
    });
  };

  const getEmptySpecListSupplementary = () => {
    GetEmptyACCode({ projectId: id, type: 4 }).then((res) => {
      if (res.success) {
        setLoading(false);
        setEmptyAcCode(res.data);
      }
    });
  };

  const yardListExports = () => {
    getAllYardListExport().then((res) => {
      if (res.data) {
        const options = res.data.map((opt) => {
          return { label: opt.yardName, value: opt.id, status: opt.status };
        });
        setYardList(options);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getBasicDetails();
    yardListExports();
    getQuotes();
    if (state?.from === 'Comparequotes') {
      setActiveTab(false);
    }
  }, []);

  const onClose = () => {
    setShowSideBar(false);
    setIsComponentClicked(false);
    setIsSupplementaryClicked(false);
    setIsComponentClicked(false);
    setIsSubmitted(false);
    setStatusState({
      new: true,
      approved: false,
      pending: false,
      rejected: false
    });
    setActiveTabSupplementary(true);
  };

  const backtoList = () => {
    setIsSupplementaryClicked(false);
    getEmptySpecListSupplementary();
    const temp = {
      new: true,
      approved: false,
      pending: false,
      rejected: false
    };
    setStatusState(temp);
    supplemetarySpecLists({ projectId: id, status: 1 }).then((res) => {
      if (res.success) {
        setListOfSpecs(res.data);
      }
    });
  };

  /* istanbul ignore next */
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE}`, 'error');
      } else {
        setFile(first);
      }
    } else if (first) {
      showAlert('Please upload a excel file type', 'error');
    }
  };

  /* istanbul ignore next */
  const saveForm = () => {
    formData.projectId = id;
    formData.specId = specId || ZERO_UUID;
    // eslint-disable-next-line radix
    let isValid = false;
    // eslint-disable-next-line radix
    if (parseInt(formData?.specNoAutoGenerated) < (parseInt(formData?.sectionNo) + 100)) {
      isValid = true;
    }
    if (!formData?.sectionNo) {
      isValid = true;
    }
    if (isValid && formValidation(['sectionId', 'specTitle', 'specAssigned'], formData)) {
      // setIsSaved(true);
      setIsAddLoading(true);
      saveSpec(formData).then((res) => {
        setIsAddLoading(false);
        if (res.success) {
          setSpecId(res?.data?.id);
          setIsSaved(true);
          setSpecDetails(res?.data);
          if (addClicked) {
            showAlert('Successfully Saved', 'success');
          } else {
            showAlert('Successfully Updated', 'success');
          }
        }
      });
    } else if (!isValid) showAlert('Invalid Specification Number', 'error');
    else showAlert('Please enter the mandatory(*) fields', 'error');
  };

  /* istanbul ignore next */
  const saveApiCall = () => {
    const body = new FormData();
    body.append('file', file);
    if (file) {
      importYardQuoteData(id, body).then((res) => {
        if (res?.data?.importSuccess) {
          showAlert('File has been successfully Imported', 'success');
          setFileUploaded(true);
        } else {
          showAlert(`${res?.data?.message}`, 'error');
        }
        setFile('');
        setOpenYardQuote(false);
      });
    } else {
      showAlert('Please choose a file', 'error');
    }
  };

  useEffect(() => {
    getEmptySpecListSupplementary();
    yardListExports();
  }, []);

  const handleSubmitApproval = () => {
    if (continueServs === true || (emptyAcCode?.length === 0)) {
      setSendForApproval(true);
    } else {
      setShowServiceLineModal(true);
    }
  };

  const handleViewQuote = () => {
    setOpenViewQuoteStatusModal(true);
    getQuoteStatusList({ projectId: id }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setStatusData(res.data);
      }
    });
  };
  const fromNotification = localStorage.getItem('fromNotification');

  useEffect(() => {
    if (fromNotification === 'true') {
      handleViewQuote();
    }
  }, [fromNotification]);

  const handleSendSpecs = () => {
    getSupplementaryYardMailList({ projectId: id }).then((res) => {
      if (res.success) {
        setYardMailList(res.data);
        setShowYardConfirmModal(true);
      }
    });
  };

  const handleAdditionalSpecShow = () => {
    setActiveTabSupplementary(true);
  };

  const handleReceivedSpecShow = () => {
    setActiveTabSupplementary(false);
  };

  const handleRadioButton = (e, field, status) => {
    const temp = {
      new: field === 'new',
      approved: field === 'approved',
      pending: field === 'pending',
      rejected: field === 'rejected'
    };
    setStatusState(temp);
    getEmptySpecListSupplementary();
    supplemetarySpecLists({ projectId: id, status }).then((res) => {
      if (res.success) {
        setListOfSpecs(res.data);
      }
    });
  };

  const supplementarySpecNewList = () => {
    const temp = {
      new: true,
      approved: false,
      pending: false,
      rejected: false
    };
    setStatusState(temp);
    supplemetarySpecLists({ projectId: id, status: 1 }).then((res) => {
      if (res.success) {
        handleRadioButton('', 'new', 1);
        setListOfSpecs(res.data);
      }
    });
  };

  useEffect(() => {
    supplementarySpecNewList();
  }, [activeTabSupplementary]);

  useEffect(() => {
    supplementarySpecNewList();
  }, []);

  useEffect(() => {
    supplemetarySpecLists({ projectId: id, status: 2 }).then((res) => {
      if (res.data.length > 0) {
        setPendingData(true);
      } else {
        setPendingData(false);
      }
    });
  }, [statusState]);

  const onSubmit = () => {
    const payload = {
      specificationList: listOfSpecs,
      projectApprovalData: {
        projectId: id,
        type: projectPhase?.TENDER,
        level: 0,
        status: 1,
        comment: ''
      }
    };
    submitSpecsApproval(payload).then((res) => {
      if (res?.success) {
        const temp = {
          new: false,
          approved: false,
          pending: true,
          rejected: false
        };
        setSendForApproval(false);
        setStatusState(temp);
        handleRadioButton('', 'pending', 2);
        setStatusUpdate(true);
        setLoading(false);
        setIsSubmitted(true);
        showAlert(' Submitted Successfully', 'success');
      } else {
        // eslint-disable-next-line no-lonely-if
        if (res?.data?.response?.status !== 422) {
          setLoading(true);
        }
      }
    });
  };
  const handleEmailPreview = (index) => {
    setShowEmailPreview(true);
    setPreviewList(yardMailList?.yardData[index]);
  };

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };

  const viewMore = (data) => {
    setViewSpec(true);
    setSelectedSpec(data);
  };
  const viewMoreClose = () => {
    setSelectedSpec({});
    setViewSpec(false);
  };

  const viewMoreAttachments = (subsec) => {
    setAllFiles(subsec?.specDocuments);
    setViewAttachments(true);
  };

  const viewMoreComments = (commnt) => {
    setViewComments(true);
    setComments(commnt);
  };

  const excelExportYard = () => {
    exportYardExcel(id, selectedYard.yardName, selectedYard.yardId).then(() => {
      setExportModal(false);
      setSelectedYard([]);
    });
  };

  const exportExcel = () => {
    ExportExcelForSpecLibrary(id, projectDetails.name).then(() => { });
  };

  return (
    <div className="card mt-4 tender-mr" data-testid="tender-main">
      <div className="list-tab-head d-flex pt-4 px-4 border-down">
        <div className="d-flex w-full">
          <div className="d-flex col-8">
            <div
              className={`tab-items text-center px-2 me-4  ${activeTab ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(true);
              }}
            >
              Pre-Quotation Preview
            </div>
            {!isVesselUser && (
              <div
                id="received-quote"
                className={`tab-items text-center px-2 ${activeTab ? '' : ' active'}`}
                onClick={() => {
                  setActiveTab(false);
                }}
              >
                <div>Received Quotes</div>
              </div>
            )}
            {!isVesselUser && <div className="quote-bubble">{quoteLength || 0}</div>}
          </div>
          <div className="col-2 d-flex justify-content-end align-item">
            {activeTab && !isVesselUser && (
              <button className="primary-btn small ms-2" onClick={() => exportExcel()}>
                Download Specs (Excel)
              </button>
            )}
          </div>
          <div className="col-2 d-flex justify-content-end align-item">
            {activeTab && !isVesselUser && (
              <button className="secondary-btn small ms-2" onClick={() => handleViewQuote()}>
                View Quote Sent Status
              </button>
            )}
          </div>
        </div>
        {!activeTab && !isVesselUser && (
          <div className="d-flex w-full justify-content-end">
            {userRole === 'admin' ? (
              <button
                type="button"
                id="excel"
                className="brand-color head-14 pe-4 pb-3 "
                onClick={() => {
                  setExportModal(true);
                }}
              >
                <span className="icon-download me-2" />
                Template Download
              </button>
            ) : (
              <div />
            )}

            <button className="secondary-btn small ms-2" onClick={() => handleViewQuote()}>
              View Quote Sent Status
            </button>
            <button
              disabled={projInfo?.isRepairStarted}
              type="button"
              className="secondary-btn small ms-2"
              id="yard-quote"
              onClick={() => {
                setOpenYardQuote(true);
              }}
            >
              Import Yard Quote
            </button>
          </div>
        )}
      </div>
      {openViewQuoteStatusModal && (
        <QuoteStatusModal isLoading={isLoading} statusData={statusData} setOpenViewQuoteStatusModal={setOpenViewQuoteStatusModal} />
      )}
      {exportModal && (
        <ModalBox
          heading="Select Yard to Export"
          closeModal={() => {
            setExportModal(false);
            setSelectedYard([]);
          }}
          buttonName="Download"
          modalClassName="medium"
          onSave={() => excelExportYard()}
        >
          {!loader && <SelectExportYard yardList={yardList} selectedYard={selectedYard} setSelectedYard={setSelectedYard} />}
        </ModalBox>
      )}
      {openYardQuote && (
        <ModalBox
          heading="Import Yard Quote"
          closeModal={() => {
            setOpenYardQuote(false);
            setFile('');
          }}
          buttonName="Import"
          modalClassName="medium modal-scroll"
          onSave={() => saveApiCall()}
        >
          <ImportRFQ ondrop={ondrop} setFile={setFile} file={file} />
        </ModalBox>
      )}
      {activeTab ? (
        <QuotationPrew setShowSideBar={setShowSideBar} showSideBar={showSideBar} setIsSupplementaryClicked={setIsSupplementaryClicked} />
      ) : (
        <ReceivedQuotes
          isTSI={isTSI}
          isTsiDisabled={isTsiDisabled}
          setFileUploaded={setFileUploaded}
          fileUploaded={fileUploaded}
          setQuoteLength={setQuoteLength}
          currency={currency}
          activeTab={activeTab}
          projectId={id}
          quoteLength={quoteLength}
          projectDetails={projectDetails}
          state={state}
        />
      )}
      {viewSpec ? (
        <SidePanel
          title={(
            <span className="" title={`${selectedSpec.specificationNo}. ${selectedSpec.title}`}>
              {`${selectedSpec.specificationNo}. ${_.truncate(selectedSpec.title, { length: 30 })}`}
            </span>
          )}
          size="large"
          onClose={() => viewMoreClose()}
          showView="true"
          classHeader="bg-white"
        >
          <SpecList
            isSidePanel={isSidePanel}
            isTSI={isTSI}
            projectId={id}
            isSidebar
            currencyId={currencyId}
            isTsiDisabled={isTsiDisabled}
            specDetails={selectedSpec}
            selectedSpec={selectedSpec.id || ''}
            fromSupplementary={fromSupplementary}
            activeTabSupplementary={activeTabSupplementary}
            currency={selectedSpec?.currencySymbol}
          />
        </SidePanel>
      ) : viewAttachments ? (
        <SidePanel
          title="Attachments"
          size="large"
          onClose={() => {
            setViewAttachments(false);
          }}
          classHeader="bg-white"
          showView="true"
        >
          <div className="side-panel__container bg-white specify-side">
            <div className="side-panel__content">
              <Attachments allFiles={allFiles} />
            </div>
          </div>
        </SidePanel>
      ) : viewComments ? (
        <SidePanel
          title="Comments"
          size="large"
          onClose={() => {
            setViewComments(false);
          }}
          showView="true"
          classHeader="bg-white"
        >
          <div className="side-panel__container bg-white specify-side">
            <div className="">
              <Comments comments={comments} fromSupplementary={fromSupplementary} />
            </div>
          </div>
        </SidePanel>
      ) : (
        <SidePanelCustom
          onBack={backtoList}
          size="large"
          isComponentClicked={isComponentClicked}
          title={isSupplementaryClicked ? (addClicked ? 'Add Supplementary Specs' : 'Edit Supplementary Specs') : 'Supplementary Specs'}
          onClose={onClose}
          buttonName="save"
          showView={showSideBar}
          classHeader="bg-white"
          fromSupplementary={fromSupplementary}
          setIcon={setIcon}
          icon={icon}
        >
          <div className={isSupplementaryClicked ? 'd-flex font-16 justify-content-between' : 'd-flex font-16 justify-content-between border-down'}>
            {!isSupplementaryClicked && !isSubmited && !isVesselUser && (
              <>
                <div className="d-flex ">
                  <div
                    className={`tab-items text-center mr-15 px-2 me-2  ${activeTabSupplementary ? ' active ' : ''}`}
                    type="button"
                    onClick={handleAdditionalSpecShow}
                  >
                    Supplementary Specs
                  </div>
                  <div
                    className={`tab-items text-center ml-30 px-2 ${activeTabSupplementary ? '' : ' active'}`}
                    type="button"
                    onClick={handleReceivedSpecShow}
                    id="recList"
                  >
                    Received Quotes
                  </div>
                </div>
              </>
            )}
            {icon ? (
              <div className="info-supplementary z-index-1 animate__animated animate__headShake">
                <div className="d-flex justify-content-end ms-5 mt-2">
                  <span onClick={() => setIcon(false)} className="cursor-pointer font-22 icon-close ms-5 brand-color" />
                </div>
                {isVesselUser ? (
                  <div>
                    Note : Supplementary specs will list in the main spec list after approval.
                  </div>
                ) : (
                  <div>
                    Note : Supplementary specs added will reflect in main list on approval. But these will not be included in the main quote for RFQ.
                    User has to send the supplementary specs separately to yard to quote.
                  </div>
                )}
              </div>
            ) : (
              ''
            )}

            <div className="border-down" />

            {activeTabSupplementary && !isSupplementaryClicked && !isSubmited && (
              <div className="d-flex justify-content-end px-2">
                <button
                  type="button"
                  className="secondary-btn large"
                  data-testid="add"
                  disabled={buttonDisable || pendingData || projectDetails?.phase > 3}
                  onClick={() => {
                    setIsSupplementaryClicked(true);
                    setIsAddClicked(true);
                    setSpecDetails({});
                    setFormData(initialFormData);
                    setIsSaved(false);
                    setSpecId(ZERO_UUID);
                  }}
                >
                  Add New
                </button>
              </div>
            )}
          </div>
          {!activeTabSupplementary && (
            <ReceivedSpecs
              specList={listOfSpecs}
              setRecSpecList={setRecSpecList}
              recSpecList={recSpecList}
              onSecOpen={onSecOpen}
              mainSec={mainSec}
              showsubSec={showsubSec}
              projectId={id}
              viewMore={viewMore}
              viewMoreAttachments={viewMoreAttachments}
              viewMoreComments={viewMoreComments}
              activeTabSupplementary={activeTabSupplementary}
              setShowSideBar={setShowSideBar}
              setIsComponentClicked={setIsComponentClicked}
              projectDetails={projectDetails}
              yardMailList={yardMailList}
            />
          )}
          {activeTabSupplementary && (
            <>
              {!isSupplementaryClicked && !isSubmited && !isVesselUser && (
                <>
                  <div className="d-flex mb-4 mt-4">
                    <div className="ps-2 d-flex align-items-center">
                      <input type="radio" id="html" className="me-3" checked={statusState.new} onClick={(e) => handleRadioButton(e, 'new', 1)} />
                      New
                    </div>
                    <div className="ps-5  d-flex align-items-center">
                      <input
                        type="radio"
                        id="html"
                        className="me-3"
                        checked={statusState.approved}
                        onClick={(e) => handleRadioButton(e, 'approved', 3)}
                      />
                      Approved
                    </div>
                    <div className="ps-5  d-flex align-items-center">
                      <input
                        type="radio"
                        id="html"
                        className="me-3"
                        checked={statusState.pending}
                        onClick={(e) => handleRadioButton(e, 'pending', 2)}
                      />
                      Approval Pending
                    </div>
                    <div className="ps-5  d-flex align-items-center">
                      <input
                        type="radio"
                        id="html"
                        className="me-3"
                        checked={statusState.rejected}
                        onClick={(e) => handleRadioButton(e, 'rejected', 4)}
                      />
                      Rejected Specs
                    </div>
                  </div>
                  <div className="border-down mb-3" />
                </>
              )}
              {showSideBar
                && (isSupplementaryClicked && activeTabSupplementary ? (
                  <AddSupplementaryJobs
                    setIsComponentClicked={setIsComponentClicked}
                    specDetails={specDetails}
                    setSpecDetails={setSpecDetails}
                    saveForm={saveForm}
                    isLoading={isAddLoading}
                    formData={formData}
                    setFormData={setFormData}
                    setIsSaved={setIsSaved}
                    isSaved={isSaved}
                    specId={specId}
                    setIsAddClicked={setIsAddClicked}
                    addClicked={addClicked}
                    currency={currency}
                    currencyId={currencyId}
                    activeTab={activeTabSupplementary}
                    isTSI={isTSI}
                    selectedAssignee={selectedAssignee}
                    setSelectedAssignee={setSelectedAssignee}
                    fromSupplementary={fromSupplementary}
                    activeTabSupplementary={activeTabSupplementary}
                    projInfo={projInfo}
                    isVesselUser={isVesselUser}
                  />
                ) : isSubmited ? (
                  <SubmitView
                    projectId={id}
                    setIsSubmitted={setIsSubmitted}
                    specList={listOfSpecs}
                    setSpecList={setListOfSpecs}
                    getEmptySpecListSupplementary={getEmptySpecListSupplementary}
                    setIsSupplementaryClicked={setIsSupplementaryClicked}
                    backtoList={backtoList}
                  />
                ) : loading ? (
                  <Loader />
                ) : (
                  <SpecListing
                    setIsSupplementaryClicked={setIsSupplementaryClicked}
                    setSpecDetails={setSpecDetails}
                    setFormData={setFormData}
                    initialFormData={initialFormData}
                    setIsSaved={setIsSaved}
                    setSpecId={setSpecId}
                    specList={listOfSpecs}
                    isSubmited={isSubmited}
                    setButtonDisable={setButtonDisable}
                    setSpecList={setListOfSpecs}
                    buttonDisable={buttonDisable}
                    sendForApproval={sendForApproval}
                    setSendForApproval={setSendForApproval}
                    onSubmit={onSubmit}
                    projInfo={projInfo}
                    currency={currency}
                    setIsAddClicked={setIsAddClicked}
                    getEmptySpecListSupplementary={getEmptySpecListSupplementary}
                    isTSI={isTSI}
                    listOfSpecs={listOfSpecs}
                    statusUpdate={statusUpdate}
                    fromSupplementary={fromSupplementary}
                    projectBasicDetails={projectBasicDetails}
                  />
                ))}

              {!isSupplementaryClicked ? (
                !isSubmited && (
                  <footer className="side-panel__footer">
                    {statusState.new && !isVesselUser && (
                      <button
                        disabled={buttonDisable || listOfSpecs?.length < 1 || pendingData || loading || isAdmin || projectDetails?.phase > 3}
                        type="button"
                        className="primary-btn me-4 large"
                        onClick={() => setSendForApproval(true)}
                      >
                        {listOfSpecs && listOfSpecs?.length > 0 && listOfSpecs[0]?.isRevision ? getName(true) : getName()}
                      </button>
                    )}
                    {statusState.approved && (
                      <button
                        disabled={projectDetails?.phase > 3 || listOfSpecs?.length === 0}
                        type="button"
                        className="primary-btn me-4 large"
                        onClick={() => handleSendSpecs()}
                      >
                        Send to Yard
                      </button>
                    )}
                    {statusState.pending && <></>}
                  </footer>
                )
              ) : (
                <>
                  <footer className="side-panel__footer">
                    <button type="button" className="secondary-btn me-4 large" onClick={backtoList}>
                      Back to Supplementary Specs
                    </button>
                  </footer>
                </>
              )}
            </>
          )}

          {showServiceLineModal && (
            <ServiceLineModal
              setShowServiceLineModal={setShowServiceLineModal}
              setSendForApproval={setSendForApproval}
              id={id}
              setContinueServs={() => setContinueServs()}
              emptySpecList={emptySpecList}
              emptyAcCode={emptyAcCode}
              continueServs={continueServs}
            />
          )}
          {showYardConfirmModal && (
            <YardConfirmationModal
              setShowYardConfirmModal={setShowYardConfirmModal}
              setShowEmailPreview={setShowEmailPreview}
              yardMailList={yardMailList}
              handleEmailPreview={handleEmailPreview}
              setConfirmationModalForYard={setConfirmationModalForYard}
              confirmationModalForYard={confirmationModalForYard}
            />
          )}
          {confirmationModalForYard && (
            <ConfirmationModalForYard
              setConfirmationModalForYard={setConfirmationModalForYard}
              yardMailList={yardMailList}
              setSupplementarySended={setSupplementarySended}
            />
          )}
        </SidePanelCustom>
      )}
      {showEmailPreview && (
        <EmailPreview
          showEmailPreview={showEmailPreview}
          setShowEmailPreview={setShowEmailPreview}
          yardMailList={yardMailList}
          previewList={previewList}
        />
      )}
      {supplementarySended && (
        <SupplementarySendSuccessModal
          setSupplementarySended={setSupplementarySended}
          setConfirmationModalForYard={setConfirmationModalForYard}
          setShowYardConfirmModal={setShowYardConfirmModal}
          setShowSideBar={setShowSideBar}
          supplementarySpecNewList={supplementarySpecNewList}
        />
      )}
    </div>
  );
};
export default Tender;
