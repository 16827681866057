import React from 'react';
import Cards from './card';

const CardWrapper = (props) => {
  const { vessels, handleSort, sortData, loading } = props;
  return (
    <Cards
      loading={loading}
      vessels={vessels?.items || []}
      handleSort={handleSort}
      sortData={sortData}
    />
  );
};
export default CardWrapper;
