import React from 'react';
import Select from 'react-select';
import moment from 'moment';

const ShipDueCard = (props) => {
  const { setIsEditDue, intervals, surveyInfo, setSurveyInfo,
    updateInfo, showChangeModal, resetForm } = props;
  const handleChange = (e, key) => {
    const currentInfo = { ...surveyInfo };
    currentInfo[key] = e.value;
    setSurveyInfo(currentInfo);
  };
  const updateDate = (e) => {
    const currentInfo = { ...surveyInfo };
    currentInfo[e.target.name] = e.target.value;
    setSurveyInfo(currentInfo);
  };
  return (
    <div className="container" data-testid="due-edit">
      <div className="card-inverse relative vessel-info p-5 ">
        <button id="upload" type="button" className="secondary-btn small absolute align-fix" onClick={() => showChangeModal()}>{`${surveyInfo?.vesselImageUrl ? 'Change Vessel Image' : 'Upload Vessel Image'}`}</button>
        <h4 className="head-16 white mb-4">Survey Notification</h4>
        <div className="row mb-5">
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Drydocking
                {' '}
                <span>{moment(surveyInfo.dryDockingSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2">
                <div className="half-width">
                  <label className="mb-2 label-white">Due Date</label>
                  <div className="date-picker">
                    <input
                      id="dock-date"
                      type="date"
                      className="text-box large full-width"
                      name="dryDockingSurveyDueDate"
                      value={surveyInfo.dryDockingSurveyDueDate}
                      onChange={(e) => { updateDate(e); }}
                    />
                  </div>
                </div>
                <div className="half-width">
                  <label className="mb-2 label-white">Next Due</label>
                  <div className="date-picker full-width ">
                    <Select
                      placeholder=""
                      value={intervals.filter((option) => option.value === surveyInfo
                        .dryDockingSurveyIntervalScaleId)}
                      options={intervals}
                      id="doc-scale"
                      className="select-box large"
                      onChange={(e) => { handleChange(e, 'dryDockingSurveyIntervalScaleId'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Tailshaft Survey
                {' '}
                <span>{moment(surveyInfo.tailshaftSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2">
                <div className="half-width">
                  <label className="mb-2 label-white">Due Date</label>
                  <div className="date-picker">
                    <input
                      type="date"
                      id="tail-date"
                      name="tailshaftSurveyDueDate"
                      value={surveyInfo.tailshaftSurveyDueDate}
                      className="text-box large full-width"
                      onChange={(e) => { updateDate(e); }}
                    />
                  </div>
                </div>
                <div className="half-width">
                  <label className="mb-2 label-white">Next Due</label>
                  <div className="date-picker full-width ">
                    <Select
                      placeholder=""
                      options={intervals}
                      className="select-box large"
                      value={intervals.filter((option) => option.value === surveyInfo
                        .tailshaftSurveyIntervalScaleId)}
                      id="tail-scale"
                      onChange={(e) => { handleChange(e, 'tailshaftSurveyIntervalScaleId'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Intermediate Hull Survey
                {' '}
                <span>{moment(surveyInfo.intermediateSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2">
                <div className="half-width">
                  <label className="mb-2 label-white">Due Date</label>
                  <div className="date-picker">
                    <input
                      type="date"
                      id="inter-date"
                      name="intermediateSurveyDueDate"
                      value={surveyInfo.intermediateSurveyDueDate}
                      className="text-box large full-width"
                      onChange={(e) => { updateDate(e); }}
                    />
                  </div>
                </div>
                <div className="half-width">
                  <label className="mb-2 label-white">Next Due</label>
                  <div className="date-picker full-width ">
                    <Select
                      placeholder=""
                      options={intervals}
                      id="inter-scale"
                      className="select-box large"
                      value={intervals.filter((option) => option.value === surveyInfo
                        .intermediateSurveyIntervalScaleId)}
                      onChange={(e) => { handleChange(e, 'intermediateSurveyIntervalScaleId'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Special Survey
                {' '}
                <span>{moment(surveyInfo.specialSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2">
                <div className="half-width">
                  <label className="mb-2 label-white">Due Date</label>
                  <div className="date-picker">
                    <input
                      type="date"
                      id="special-date"
                      format="DD/MMM/YYYY"
                      name="specialSurveyDueDate"
                      value={surveyInfo.specialSurveyDueDate}
                      className="text-box large full-width"
                      onChange={(e) => { updateDate(e); }}
                    />
                  </div>
                </div>
                <div className="half-width">
                  <label className="mb-2 label-white">Next Due</label>
                  <div className="date-picker full-width ">
                    <Select
                      placeholder=""
                      options={intervals}
                      id="special-scale"
                      className="select-box large"
                      value={intervals.filter((option) => option.value === surveyInfo
                        .specialSurveyIntervalScaleId)}
                      onChange={(e) => { handleChange(e, 'specialSurveyIntervalScaleId'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex col-gap-1 justify-content-end">
          <button type="button" className="secondary-btn large" onClick={() => { setIsEditDue(false); resetForm(); }}>Reset</button>
          <button type="button" className="primary-btn large" onClick={updateInfo}>Save</button>
        </div>
      </div>
    </div>
  );
};
export default ShipDueCard;
