/* eslint-disable prefer-template */
/* istanbul ignore file */
import React from 'react';

const classMap = {
  Low: 'status-low',
  Medium: 'status-medium',
  High: 'status-high'
};

const PriorityCard = (props) => {
  const { countList } = props;
  return (
    <div className="card p-4 h-full">
      <h3 className="head-16 mb-3">Task Priority</h3>
      <ul className="d-flex flex-wrap w-full ">
        {
          countList.taskPriority?.map((item) => {
            return (
              <li className="d-flex wp-50 py-2">
                <div className={'me-3 couter-bubble ' + classMap[item.name]}>{item.count}</div>
                <label>{item.name}</label>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
export default PriorityCard;
