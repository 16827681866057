import React from 'react';
import SummeryCard from './childrens/summeryCard';
import ProgressCard from './childrens/progressCard';
import PriorityCard from './childrens/priorityCard';

const Cards = (props) => {
  return (
    <div className="row mb-4">
      <div className="col-lg-4 col-sm-6">
        <PriorityCard {...props} />
      </div>
      <div className="col-lg-4 col-sm-6">
        <ProgressCard {...props} />
      </div>
      <div className="col-lg-4 col-sm-6">
        <SummeryCard {...props} />
      </div>
    </div>
  );
};
export default Cards;
