import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import ModalBox from '../../../../../common/ModalBox';
import SpecLineTable from './SpecLineTable';
import AddNonSpecItem from './AddNonSpecItem';
import helper from '../../../../../../utils/helper';
import { getCurrencyList, s3UploadMutiple, getProjectDetailsTSI } from '../../../NewProject/services';
import showAlert from '../../../../../../utils/alert';
import { savegetNonSpecTsi, deleteNonSpecTsi, SaveAcCodeMapping } from '../services';
import { currencyConverter } from '../../Repair/NonSpecItem/services';
import Loader from '../../../../../common/loader';
import Attachments from '../../Tender/ReceivedQuotes/attachments';
import SidePanel from '../../../../../common/sidePanel';

const NonSpecList = ({
  specDetails,
  reloadTable,
  loading,
  acCodeList,
  isTsiDisabled,
  currency,
  currencyId
}) => {
  const acceptedFiles = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/tiff',
    'application/pdf'
  ];
  const { id } = useParams();
  const { formValidation, MAX_FILE_SIZE, generateOptions, ZERO_UUID } = helper;
  const initialData = {
    projectId: '',
    parentId: '',
    nonSpecItem: '',
    acCodeId: '',
    acCodeName: '',
    amount: '',
    currencyId: '',
    attachments: []
  };
  const [projectCurrency, setProjectCurrency] = useState();
  const [formData, setFormData] = useState(initialData);
  const [searchText, setSearchText] = useState('');
  const [searchTextFixed, setSearchTextFixed] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [selectedServiceLines, setSelectedServiceLines] = useState([]);
  const [mappingModal, setMappingModal] = useState(false);
  const [mappedAcCode, setMappedAcCode] = useState('');
  const [viewAttachments, setViewAttachments] = useState(false);
  const [attachments, setAttachments] = useState();
  const [projInfo, setProjInfo] = useState({});
  const [newACCode, setnewACCode] = useState(false);
  const projectDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      setProjInfo(res?.data);
    });
  };
  const onSelectServiceLine = (idd) => {
    const selectedLines = [...selectedServiceLines];
    if (selectedLines.indexOf(idd) > -1) {
      selectedLines.splice(selectedLines.indexOf(idd), 1);
    } else {
      selectedLines.push(idd);
    }
    setSelectedServiceLines(selectedLines);
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      setSearchTextFixed(searchText.toLowerCase());
    }
  };
  const getProjectCurrencyList = () => {
    getCurrencyList().then((res) => {
      if (res.data) {
        setProjectCurrency(generateOptions(res?.data, 'id', 'currencyName'));
      }
    });
  };
  /* istanbul ignore next */
  const getCurrency = (cId, pId, amount) => {
    currencyConverter(cId, pId, amount).then((resp) => {
      if (resp.success) {
        const fd = cloneDeep(formData);
        fd.ownerCost = resp.data;
        fd.currencyId = cId;
        fd.amount = amount;
        setFormData(fd);
      }
    });
  };
  const saveApiCall = () => {
    const f = cloneDeep(formData);
    f.projectId = id;
    f.parentId = specDetails.id;
    f.acCodeId = f.acCodeId || ZERO_UUID;

    // eslint-disable-next-line prefer-const
    let isValid = true;
    if (isValid && formValidation(['nonSpecItem', 'amount', 'currencyId'], f)) {
      /* istanbul ignore next */
      savegetNonSpecTsi(f).then((res) => {
        if (res.success) {
          showAlert('Successfully Saved', 'success');
          setFormData(initialData);
          setOpenModal(false);
          setnewACCode(false);
          reloadTable();
        }
      });
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!formValidation(['nonSpecItem', 'amount', 'currencyId'], f)) {
        showAlert('Please enter the mandatory(*) fields', 'error');
      } else {
        showAlert('Please upload files', 'error');
      }
    }
  };
  /* istanbul ignore next */
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const selectedFiles = [];
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE}MB`, 'error');
        else selectedFiles.push(item);
      });

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        setFileLoading(true);
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formData);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              path: item.path,
              type: item.type,
              size: item.size,
              phase: 1
            };
            return attach.attachments.push(newItem);
          });
          setFormData(attach);
          if (res.success) {
            showAlert('File Uploaded', 'success');
          }
        }
        setFileLoading(false);
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    document.getElementById('upload').value = '';
  };
  const editData = (data) => {
    setFormData(data);
    setOpenModal(true);
  };
  const deleteData = (data) => {
    setOpenDeleteModal(data);
  };
  const deleteApiCall = () => {
    deleteNonSpecTsi(openDeleteModal.id).then((res) => {
      setOpenDeleteModal(false);
      reloadTable();
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const viewAttachment = (item) => {
    setAttachments(item?.attachments);
    setViewAttachments(true);
  };
  const onMappSpec = () => {
    const f = {
      specificationId: specDetails.id,
      acCodeId: mappedAcCode,
      serviceLines: selectedServiceLines
    };
    let isValid = true;
    if (f.serviceLines.length < 1) {
      isValid = false;
    }
    if (isValid && formValidation(['acCodeId'], f)) {
      /* istanbul ignore next */
      SaveAcCodeMapping(f).then((res) => {
        if (res.success) {
          showAlert('Successfully Saved', 'success');
          setSelectedServiceLines([]);
          setMappingModal(false);
          setMappedAcCode('');
          reloadTable();
        }
      });
    } else {
      showAlert('Please choose AC code', 'error');
      if (!isValid) {
        showAlert('Please choose spec item', 'error');
      }
    }
  };
  useEffect(() => {
    getProjectCurrencyList();
    projectDetails();
  }, []);
  useEffect(() => {
    setFormData(initialData);
    setSearchText('');
    setSearchTextFixed('');
    setOpenModal(false);
    setOpenDeleteModal(false);
    setFileLoading(false);
    setSelectedServiceLines([]);
    setMappingModal(false);
    setMappedAcCode('');
  }, [specDetails]);

  if (loading) return <div data-testid="non-spec-index"><Loader /></div>;
  return (
    <div className="col-8 d-flex" data-testid="non-spec-index">
      <div className="card p-5 height-fix  flex-grow-1">
        <div className="right-content-wrap">
          <div className="row justify-content-start mb-4">
            <h4 className="col-9 sub-heading break-all">{`${specDetails?.specificationNo}.${specDetails?.title}`}</h4>
          </div>
          <div className="">
            <div className="d-flex my-4 justify-content-between tab-content-ctrl">
              <div className="d-flex">
                <div className="search">
                  <span
                    className="icon-search"
                    id="search-click"
                    onClick={() => {
                      onSearch({ key: 'Enter' });
                    }}
                  />
                  <input
                    type="text"
                    className="text-box"
                    placeholder="Search by Non Spec Line"
                    id="search"
                    onKeyPress={onSearch}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={searchText}
                  />
                </div>
                <button
                  type="button"
                  id="add_serviceline"
                  disabled={projInfo?.isClosed === true}
                  className="secondary-btn ms-4"
                  onClick={() => {
                    setOpenModal(true);
                    setFormData(initialData);
                  }}
                >
                  Add Non Spec Line
                </button>
              </div>
            </div>
            <SpecLineTable
              projInfo={projInfo}
              specLines={specDetails?.specLines}
              editData={editData}
              isTsiDisabled={isTsiDisabled}
              deleteData={deleteData}
              searchTextFixed={searchTextFixed}
              onSelectServiceLine={onSelectServiceLine}
              selectedServiceLines={selectedServiceLines}
              currency={currency}
              viewAttachment={viewAttachment}
            />
          </div>
        </div>
      </div>
      {openModal ? (
        <ModalBox
          modalClassName="medium"
          onSave={() => saveApiCall()}
          closeModal={() => {
            setOpenModal(false);
            setnewACCode(false);
          }}
          heading={`${formData.id ? 'Update' : 'Add'} Non Spec Line`}
          buttonName={formData.id ? 'Update' : 'Save'}
        >
          <AddNonSpecItem
            fileLoading={fileLoading}
            ondrop={ondrop}
            acCodeList={acCodeList}
            formData={formData}
            setFormData={setFormData}
            getCurrency={getCurrency}
            projectCurrency={projectCurrency}
            currency={currency}
            currencyId={currencyId}
            newACCode={newACCode}
            setnewACCode={setnewACCode}
          />
        </ModalBox>
      ) : (
        ''
      )}
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete Non Spec Line ?</p>
        </ModalBox>
      ) : (
        ''
      )}
      {mappingModal ? (
        <ModalBox
          modalClassName="medium modal-scroll"
          onSave={() => onMappSpec()}
          closeModal={() => setMappingModal(false)}
          heading="AC Code List"
          buttonName="Map to the Spec"
          saveDisable={!mappedAcCode}
        >
          <Select
            autoFocus
            menuPosition="fixed"
            className="m-3"
            placeholder="AC code"
            options={acCodeList}
            id="acCodeId"
            classNamePrefix="react-tag"
            value={acCodeList.filter((val) => val.value === mappedAcCode)}
            onChange={(e) => {
              setMappedAcCode(e.value);
            }}
          />
        </ModalBox>
      ) : (
        ''
      )}
      <SidePanel
        title="Attachments"
        size="medium"
        onClose={() => { setViewAttachments(false); }}
        showView={viewAttachments}
      >
        <Attachments
          allFiles={attachments}
          fromNonSpec
        />

      </SidePanel>
    </div>
  );
};
export default NonSpecList;
