import React from 'react';

// eslint-disable-next-line no-unused-vars
const Stepper = ({ handleActiveComponent, active, stepperList, projectId }) => {
  return (
    <div className="progressbar-container">
      <ul className="progressbar">
        {stepperList.map((i, index) => {
          return (
            <li
              onClick={() => {
                if (projectId || i?.componentName === 'vessel-details') {
                  handleActiveComponent(i.componentName);
                }
              }}
              className={i.componentName === active || i.indexing < index
                ? i?.isDone ? 'step is-active is-done ' : 'step is-active cursor-default'
                : i?.isDone ? 'is-done step ' : 'step cursor-default'}
            >
              <span className="">{i.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Stepper;
