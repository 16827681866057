/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import brandLogo from '../../../images/brand-logo.svg';
import InputType1 from '../../common/InputType1';
import Caption from '../common/caption';
import Footer from '../common/footer';
import { forgetPassword } from './services';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [noEmail, setNoEmail] = useState('');
  const navigate = useNavigate();
  const handleBackToLogin = () => {
    navigate('/login');
  };

  const handleResetPassword = () => {
    if (email) {
      forgetPassword({ id: email })?.then((res) => {
        if (res.success) {
          navigate('/password-recovery', { state: { email } });
        }
      });
    } else {
      setNoEmail('Please enter the Email');
    }
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    setNoEmail('');
  };

  return (
    <>
      <div data-testid="forgotpage">
        <div className="wrapper login-wrap">
          <Caption />
          <div className="card login-card animate__animated animate__bounceIn">
            <div className="brand-logo mb-5">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">
                <img src={brandLogo} alt="Maridock Logo" className="brand-img" />
              </a>
            </div>

            <h4 className="main-head mt-5">Password Recovery</h4>
            <p className="mb-5 mt-5 font-18">Please enter the email ID to which the reset password link should be sent</p>

            <div className="mb-5 d-flex flex-column">
              <InputType1 type="text" label="Email" autoFocus data-testid="email" isMandatory="true" maxLength={64} value={email} className={noEmail ? 'text-box xlarge error-field' : 'text-box xlarge'} onChange={handleEmail} />
              <p style={{ color: 'red' }}>{noEmail}</p>
            </div>
            <button data-testid="resetbutton" className="primary-btn xlarge full-width my-3 mt-5 mb-5" type="button" onClick={() => handleResetPassword()}>
              Reset my Password
            </button>

            <button data-testid="back" className="link-btn mb-2 mt-5" type="button" onClick={handleBackToLogin}>
              <span className="icon-arrow-left2 pe-2 me-2" />
              Back to Login
            </button>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default ForgotPassword;
