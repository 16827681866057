/* eslint-disable consistent-return */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../common/loader';
import tiff from '../../../../images/tiff.png';

const CustomFolderContent = (props) => {
  const { imageList, isReport, handleUploadImage,
    setOpenModal, setClickedImage, loading, customLoading, setLeftClick } = props;
  return (
    <div data-testid="image-custom-content" className="cursor-pointer">
      {loading || customLoading ? (
        <Loader />
      ) : imageList.length > 0 ? (
        <div className="parent-gallery">
          {imageList.map((item, key) => {
            return (
              <div
                className="img-thumbnail "
                id="click2"
                onClick={() => {
                  if (!(item.imageName.endsWith('.tiff') || item.imageName.endsWith('.tif'))) {
                    if (isReport) {
                      const imageTo = {
                        ...item,
                        imageDescription: '',
                        imageId: item?.id,
                        imageName: item?.imageName,
                        order: 1,
                        subImageName: item?.imageName
                      };
                      handleUploadImage(imageTo);
                    } else {
                      setClickedImage({ url: item?.imageUrl, key });
                      setOpenModal(true);
                      setLeftClick(key);
                    }
                  }
                }}
              >
                <div className="image">
                  {item?.imageName.endsWith('.tiff') || item?.imageName.endsWith('.tif')
                    ? (
                      <a href={item?.imageUrl} download="downloaded_file" className="" rel="noopener noreferrer">
                        <img src={tiff} alt="" />
                      </a>
                    )
                    : <img alt="pic 1" className="image-dimension" src={item?.imageUrl} />}
                </div>
                <div className="name mt-3 font-13">
                  {item?.imageName && (
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <label className="ms-2 value" data-tooltip-id="project-system" data-tooltip-content={item?.imageName}>
                          {_.truncate(item?.imageName, {
                            length: 12,
                            separator: ' '
                          })}
                        </label>
                        {/* {item?.subNumber !== 0 && (
                        <div className="ms-2">
                          {item?.subNumber}
                        </div>
                        )} */}
                      </div>
                      {/* {item?.imageDescription
                  && (
                  <div
                    data-tooltip-id="project-system"
                    data-tooltip-content={item?.imageDescription}
                    className=""
                  >
                    <span className="icon-Info font-22 " />
                  </div>
                  )} */}
                    </div>
                  )}
                  <div className="d-flex font-13">
                    <div className="ms-2 opacity-8">{moment(item?.updatedAt).format('DD-MMM-YYYY')}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">No data found</div>
        </div>
      )}
      <Tooltip id="project-system" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default CustomFolderContent;
