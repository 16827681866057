import React from 'react';
import Select from 'react-select';

const SelectExportYard = (props) => {
  const { yardList, selectedYard } = props;
  const handleChange = (e) => {
    selectedYard.yardName = e.label;
    selectedYard.yardId = e.value;
  };

  return (
    <>
      <div className="modal-content" data-testid="SelectExport">
        <label>Select Yard</label>
        <Select
          autoFocus
          menuPosition="fixed"
          onChange={(option) => handleChange(option)}
          className="modal-select"
          options={yardList}
          id="yardId"
          name="yardId"
        />
      </div>
    </>
  );
};

export default SelectExportYard;
