import axios from 'axios';
import { downloadErrorHandler, serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  getManagementGroupListUrl,
  saveManagementGroupUrl,
  deleteManagementGroupUrl,
  s3fileUploadUrl,
  GenerateTemplateManagementGroup,
  BulkUploadManagementGroup,
  ExportExcelManagement
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getManagementGroupList = (payload) => {
  return serviceHandler({
    url: `${getManagementGroupListUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveManagementGroupList = (payload) => {
  return serviceHandler({
    url: `${saveManagementGroupUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteManagementItem = (payload) => {
  return serviceHandler({
    url: `${deleteManagementGroupUrl}?Id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const s3Upload = (payload) => {
  return serviceHandler({
    url: `${s3fileUploadUrl}`,
    methordType: 'post',
    payload
  });
};
export const exportExcelManagementGroup = (searchText) => {
  return axios
    .get(`${ExportExcelManagement}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Management_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      downloadErrorHandler(errors);
      // Promise.reject(errors);
    });
};

export const bulkUploadManagementGroup = (payload) => {
  return serviceHandler({
    url: `${BulkUploadManagementGroup}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateManagementGroup}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Management Group_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
