import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  getProjectsUrl,
  SavePinProjects,
  GetActiveProjectCount,
  GetProjectListForGantt,
  GetOverAllTaskCounts,
  ProjectApprovals,
  getManagementListUrl,
  DashBoard
} = apiEndPoints;

export const getProjects = (payload) => {
  return serviceHandler({
    url: `${getProjectsUrl}`,
    methordType: 'post',
    payload
  });
};

export const savePinProjects = (payload) => {
  return serviceHandler({
    url: `${SavePinProjects}`,
    methordType: 'post',
    payload
  });
};

export const getActiveProjectCount = () => {
  return serviceHandler({
    url: `${GetActiveProjectCount}`,
    methordType: 'get'
  });
};
export const getOverAllTaskCounts = (payload) => {
  return serviceHandler({
    url: `${GetOverAllTaskCounts}?projectId=${payload.projectId}&phaseId=${payload.phaseId}`,
    methordType: 'post',
    payload
  });
};
export const projectApprovals = (payload) => {
  return serviceHandler({
    url: `${ProjectApprovals}`,
    methordType: 'post',
    payload
  });
};
export const dashBoard = (payload) => {
  return serviceHandler({
    url: `${DashBoard}`,
    methordType: 'post',
    payload
  });
};

export const getGanttList = (payload) => {
  return serviceHandler({
    url: `${GetProjectListForGantt}`,
    methordType: 'post',
    payload
  });
};
export const getManagementList = (payload) => {
  return serviceHandler({
    url: `${getManagementListUrl}`,
    methordType: 'get',
    payload
  });
};
