import axios from 'axios';
import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';
import helper from '../../../../../utils/helper';

const { SaveAttachment,
  GetAttachments,
  DeleteAttachment,
  S3Upload,
  GetVesselAttachmentList,
  getDocumentCategoryList,
  SaveVesselAttachment,
  ContactGenerateTemplate,
  ContactImportExcel
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const saveAttachments = (payload) => {
  return serviceHandler({
    url: `${SaveAttachment}`,
    methordType: 'post',
    payload
  });
};
export const getAttachments = (payload) => {
  return serviceHandler({
    url: `${GetAttachments}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const deleteAttachment = (payload) => {
  return serviceHandler({
    url: `${DeleteAttachment}?id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};

export const s3Upload = (payload) => {
  return serviceHandler({
    url: `${S3Upload}`,
    methordType: 'post',
    payload
  });
};

export const getVesselAttachmentList = (payload) => {
  return serviceHandler({
    url: `${GetVesselAttachmentList}?vesselId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const getDocumentCategryList = (payload) => {
  return serviceHandler({
    url: `${getDocumentCategoryList}?status=${payload}`,
    methordType: 'get',
    payload
  });
};

export const saveVesselAttachments = (payload) => {
  return serviceHandler({
    url: `${SaveVesselAttachment}`,
    methordType: 'post',
    payload
  });
};
export const contactGenerateTemplate = (payload) => {
  return axios
    .get(`${ContactGenerateTemplate}?projectId=${payload.id}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Contacts_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const contactImportExcel = (payload, id) => {
  return serviceHandler({
    url: `${ContactImportExcel}?projectId=${id}`,
    methordType: 'post',
    payload
  });
};
