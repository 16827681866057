import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  SaveUser,
  GetUserList,
  DeleteUser,
  DisableUser,
  UpdatePassword,
  GetNonMappedFleetsUrl,
  GetNonMappedVesselsUrl,
  GenerateTemplateUser,
  BulkUploadUser,
  ExportExcelUserList,
  GetUserListByVesselIdUrl
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const UserListByVessel = (payload) => {
  return serviceHandler({
    url: `${GetUserListByVesselIdUrl}`,
    methordType: 'post',
    payload
  });
};
export const getUserList = (payload) => {
  return serviceHandler({
    url: `${GetUserList}`,
    methordType: 'post',
    payload
  });
};
export const saveUser = (payload) => {
  return serviceHandler({
    url: `${SaveUser}`,
    methordType: 'post',
    payload
  });
};
export const deleteUser = (payload) => {
  return serviceHandler({
    url: `${DeleteUser}?id=${payload.id}`,
    methordType: 'put',
    payload
  });
};
export const disableUser = (payload) => {
  return serviceHandler({
    url: `${DisableUser}?userId=${payload.id}&isDisabled=${payload.isDisabled}`,
    methordType: 'put',
    payload
  });
};
export const updatePassword = (payload) => {
  return serviceHandler({
    url: `${UpdatePassword}`,
    methordType: 'post',
    payload
  });
};

export const GetNonMappedFleets = (payload) => {
  return serviceHandler({
    url: `${GetNonMappedFleetsUrl}?managementId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const GetNonMappedVessels = (managementId, roleId) => {
  return serviceHandler({
    url: `${GetNonMappedVesselsUrl}?managementId=${managementId}&roleId=${roleId}`,
    methordType: 'get'
  });
};
export const exportExcelUserList = (searchText, vesselId) => {
  return axios
    .get(`${ExportExcelUserList}?search=${searchText}&vesselId=${vesselId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `User List_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const bulkUploadUser = (payload) => {
  return serviceHandler({
    url: `${BulkUploadUser}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateUser}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `User List_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
