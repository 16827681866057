import React, { useEffect, useState } from 'react';
import Comments from '../../../Comments';
import helper from '../../../../../../utils/helper';

const PreviewFooter = (props) => {
  const { saveApiCall, id, setIsRejectClicked, setIsApproveClicked, level,
    setIsSubmitClicked, setButtonText, specList, buttonClicked, setButtonClicked } = props;
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles?.ADMIN;
  const [canApprove, setCanApprove] = useState(false);
  useEffect(() => {
    if (!isAdmin && level && level !== 0) {
      setCanApprove(true);
    } else {
      setCanApprove(false);
    }
  }, [level]);
  return (
    <div className="fixed-footer card d-flex justify-content-between" data-testid="footer">
      <div>
        <Comments buttonClicked={buttonClicked} setButtonClicked={setButtonClicked} id={id} appendClassName="comments-btn" />
      </div>
      {canApprove && (
      <div className="d-flex">
        <button
          type="button"
          disabled={specList?.length < 1}
          className="secondary-btn large mr-10"
          data-testid="reject"
          onClick={() => {
            saveApiCall(); setIsRejectClicked(true); setButtonText('reject');
          }}
        >
          Reject
        </button>
        <button
          type="button"
          className="secondary-btn large mr-10"
          data-testid="submit"
          disabled={specList?.length < 1}
          onClick={() => {
            saveApiCall(); setIsSubmitClicked(true); setButtonText('revision');
          }}
        >
          Submit for revision
        </button>
        <button
          type="button"
          className="primary-btn large mr-10"
          data-testid="approve"
          disabled={specList?.length < 1}
          onClick={() => {
            saveApiCall(); setIsApproveClicked(true); setButtonText('approve');
          }}
        >
          Approve
        </button>
      </div>
      )}
    </div>
  );
};
export default PreviewFooter;
