import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  getVesselTypeUrl,
  saveVesselTypeUrl,
  deleteVesselTypeUrl,
  saveVesselSubTypeUrl,
  deleteVesselSubTypeUrl,
  ExportExcelVesselType
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getVesselType = (payload) => {
  return serviceHandler({
    url: `${getVesselTypeUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveVesselType = (payload) => {
  return serviceHandler({
    url: `${saveVesselTypeUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteVesselType = (payload) => {
  return serviceHandler({
    url: `${deleteVesselTypeUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const saveVesselSubType = (payload) => {
  return serviceHandler({
    url: `${saveVesselSubTypeUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteVesselSubType = (payload) => {
  return serviceHandler({
    url: `${deleteVesselSubTypeUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const exportExcelVesselType = (searchText) => {
  return axios
    .get(`${ExportExcelVesselType}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Vessel Type_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
