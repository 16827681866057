import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const MajorSpecPackagesList = (props) => {
  const { isExpand, formDataToFillList, setIsExpand, handleChangeType, from,
    isTsiDisabled } = props;
  const [state, setstate] = useState(formDataToFillList);
  useEffect(() => {
    setstate(formDataToFillList);
  }, [formDataToFillList]);
  return (
    <div className="modal-sp my-4" data-testid="major-specpkg">
      {state && state.map((i, index) => {
        const { childData } = i;
        return (
          <div key={i.id} className="border mb-4">
            <div className="p-3  align-items-center justify-content-between d-flex">
              <div className=" d-flex">
                {from !== 'confirm' && (
                  <div className="check-box ps-1">
                    <input
                      checked={i.isChecked}
                      disabled={isTsiDisabled || i.disableSpec}
                      onChange={(e) => {
                        handleChangeType(index, 'main');
                        e.preventDefault();
                      }}
                      type="checkbox"
                      value={i.id}
                      id={`main-${i.id}`}
                    />
                    <label htmlFor={`main-${i.id}`} />
                  </div>
                )}

                <div
                  className={`ms-4  text-uppercase ${isExpand === i.id && 'font-bold'}`}
                >
                  <span data-tooltip-id="view-spec" data-tooltip-content={`${i.specificationNo} - ${i.title} `}>
                    {`${i.specificationNo} - ${i.title} `}
                  </span>

                </div>
              </div>

              <div className="">
                {isExpand === i.id ? (
                  <span
                    onClick={() => {
                      setIsExpand('');
                    }}
                    className="font-10 icon-arrow-thin-up cursor-pointer"
                  />
                ) : (
                  <span
                    onClick={() => {
                      setIsExpand(i.id);
                    }}
                    className="font-10 icon-arrow-thin-down cursor-pointer"
                  />
                )}
              </div>
            </div>
            {isExpand === i.id ? (
              <div className="ps-5 my-3">
                {childData.map((item, k) => {
                  return (
                    <div key={item.id} className="d-flex ms-4 my-4">
                      {from !== 'confirm' && (
                        <div className="check-box ps-1">
                          <input
                            checked={item.isChecked}
                            disabled={isTsiDisabled || item.disableSpec}
                            onChange={(e) => {
                              handleChangeType(index, 'sub', k);
                              e.preventDefault();
                            }}
                            type="checkbox"
                            value={item.id}
                            id={`sub-${item.id}`}
                          />
                          <label htmlFor={`sub-${item.id}`} />
                        </div>
                      )}
                      <div className="ms-4 text-uppercase">
                        <span data-tooltip-id="view-spec" data-tooltip-content={`${i.specificationNo} - ${i.title} `}>
                          {`${item.specificationNo} - ${item.title} `}
                        </span>

                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      })}
      <Tooltip id="view-spec" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default MajorSpecPackagesList;
