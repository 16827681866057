import React from 'react';
import WohaCard from './wohaCard';
import Loader from '../../../common/loader';

const WohaWrapper = (props) => {
  const { dropDownOption,
    selectedCard, onSelected, loading, isSaved, isTsiDisabled,
    onSave, onCancel, setSelectedCard, workOrder, setCurrentSpec, currentSpec, isTSI,
    projectBasicDetails, workorderDefect } = props;
  if (loading) {
    return <Loader data-testid="loader" />;
  } if (workOrder?.items?.length > 0) {
    return workOrder?.items?.map((item) => {
      return (
        <WohaCard
          data-testid="woha-card"
          dropDownOption={dropDownOption}
          item={item}
          selectedCard={selectedCard}
          onSelected={onSelected}
          isSaved={isSaved}
          onSave={onSave}
          onCancel={onCancel}
          isTsiDisabled={isTsiDisabled}
          setSelectedCard={setSelectedCard}
          setCurrentSpec={setCurrentSpec}
          isTSI={isTSI}
          currentSpec={currentSpec}
          projectBasicDetails={projectBasicDetails}
          workorderDefect={workorderDefect}
        />
      );
    });
  }
  return (<div className="d-flex justify-content-center mt-3" data-testid="woha-cardWrapper">No data available</div>);
};
export default WohaWrapper;
