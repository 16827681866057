import React from 'react';
import moment from 'moment';
import userImg from '../../../../images/user-placeholder.jpg';

const RecievedMsg = (props) => {
  const { item } = props;
  return (
    <div className="d-flex col-gap-1 pb-4">
      {item.profileImageUrl ? (
        <img src={item.profileImageUrl} className="thmb-md ship-thumb" alt="" />
      ) : (
        <img src={userImg} alt="" className="thmb-md ship-thumb" />
      )}
      {/* <img src={item?.profileImageUrl} className="thmb-md" alt="" /> */}
      <div>
        <div className="card p-4  font-14">
          <p className="value  pb-2">{item?.name}</p>
          <p className="left-word-break">
            {item?.message}
          </p>
        </div>
        <div className="font-13 mt-3">
          {moment.utc(item?.updatedAt).format('DD-MMM-YYYY HH:mm:ss')}
          (UTC)
        </div>
      </div>
    </div>
  );
};

export default RecievedMsg;
