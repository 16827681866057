export const listingConfig = [
  {
    label: 'Yard Information',
    type: 'heading'
  },
  {
    label: 'Repair Manager : ',
    apiKey: 'repairManager'
  },
  {
    label: 'Repair Manager Phone : ',
    apiKey: 'repairManagerPhone'
  },
  {
    label: 'Repair Manager E-mail : ',
    apiKey: 'repairManagerMail'
  },
  {
    label: 'Commercial Manager : ',
    apiKey: 'commercialManager'
  },
  {
    label: 'Commercial Manager Phone : ',
    apiKey: 'commercialManagerPhone'
  },
  {
    label: 'Commercial Manager E-mail : ',
    apiKey: 'commercialManagerMail'
  },
  {
    label: 'Vessel Information',
    type: 'heading'
  },
  {
    label: 'Vessel Name : ',
    apiKey: 'vesselName'
  },
  {
    label: 'Type of vessel : ',
    apiKey: 'vesselType'
  },
  {
    label: 'Yard Actual : ',
    apiKey: 'actualAmount',
    type: 'amount'
  },
  {
    label: 'Yard Est. : ',
    apiKey: 'ownersEstimate',
    type: 'amount'
  },
  {
    label: 'Variance (%) : ',
    apiKey: 'variancePercentage',
    type: 'percent'
  },
  {
    label: 'Vessel Age : ',
    apiKey: 'vesselAge'
  },
  {
    label: 'Classification Society : ',
    apiKey: 'classificationSociety'
  },
  {
    label: 'Date of Arrival Yard : ',
    apiKey: 'arrivalDate',
    type: 'date'
  },
  {
    label: 'Date Docked : ',
    apiKey: 'dockDate',
    type: 'date'
  },
  {
    label: 'Date Undocked : ',
    apiKey: 'undockDate',
    type: 'date'
  },
  {
    label: 'Date of Departure : ',
    apiKey: 'departureDate',
    type: 'date'
  },
  {
    label: 'No. of Days Commited : ',
    apiKey: 'commitedTRD'
  },
  {
    label: 'No. of Days Actual : ',
    apiKey: 'actualTRD'
  }
  // {
  //   label: 'Fleet Manage:r',
  //   apiKey: 'fleetManager'
  // },
  // {
  //   label: 'Technical Superintendent:',
  //   apiKey: 'tsi'
  // }
];
export const editableFields = [
  {
    label: 'Repair Manager : ',
    apiKey: 'repairManager',
    type: 'string'
  },
  {
    label: 'Repair Manager Phone : ',
    apiKey: 'repairManagerPhone',
    type: 'number'
  },
  {
    label: 'Repair Manager E-mail : ',
    apiKey: 'repairManagerMail',
    type: 'mail'
  },
  {
    label: 'Commercial Manager : ',
    apiKey: 'commercialManager',
    type: 'string'
  },
  {
    label: 'Commercial Manager Phone : ',
    apiKey: 'commercialManagerPhone',
    type: 'number'
  },
  {
    label: 'Commercial Manager E-mail : ',
    apiKey: 'commercialManagerMail',
    type: 'mail'
  }
];
export const isValid = (subItems) => {
  let isFilled = true;
  subItems.map((item) => {
    if (!item.rating) {
      isFilled = false;
    }
    return null;
  });
  return isFilled;
};
export const isFilledAll = (ratingList) => {
  let canEnableConfirm = true;
  ratingList?.map((item) => {
    item?.evaluationLines.map((field) => {
      if (!field.rating) {
        canEnableConfirm = false;
      }
      return null;
    });
    return null;
  });
  return canEnableConfirm;
};
