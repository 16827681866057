import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  SaveRole,
  GetRoleList,
  DeleteRole,
  SavePermission,
  GetPermission,
  SaveWorkFlow,
  GetWorkFlow,
  ManagementGroupShortListUrl
} = apiEndPoints;

export const saveRole = (payload) => {
  return serviceHandler({
    url: `${SaveRole}`,
    methordType: 'post',
    payload
  });
};

export const getRoleList = (vesselId) => {
  return serviceHandler({
    url: `${GetRoleList}?vesselId=${vesselId}`,
    methordType: 'get'
  });
};

export const deleteRole = (payload) => {
  return serviceHandler({
    url: `${DeleteRole}?id= ${payload.id}`,
    methordType: 'put',
    payload
  });
};
export const getPermission = (payload) => {
  return serviceHandler({
    url: `${GetPermission}?roleId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const savePermission = (payload) => {
  return serviceHandler({
    url: `${SavePermission}`,
    methordType: 'post',
    payload
  });
};
export const saveWorkflow = (payload) => {
  return serviceHandler({
    url: `${SaveWorkFlow}`,
    methordType: 'post',
    payload
  });
};
export const getWorkflow = (payload) => {
  return serviceHandler({
    url: `${GetWorkFlow}?managementGroupId=${payload}`,
    methordType: 'get'
  });
};

export const getManagementGroupShortList = () => {
  return serviceHandler({
    url: `${ManagementGroupShortListUrl}`,
    methordType: 'get'
  });
};
