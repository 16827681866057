/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  CompareQuotes, getKfp, saveCQ, saveComment, saveAssignee, sendForApproval, getUnits, getprojectStatus,
  getprojectDetails, updateStatus, saveDOCost, saveServiceLine, getAcCode
} from "./services";
import CheckList from "./CheckList";
import MappingData from "./MappingData";
import Loader from "../../../../../common/loader";
import ModalBox from "../../../../../common/ModalBox";
import helper from "../../../../../../utils/helper";
import AddRemarks from "../SupplementaryJobs/addRemarks";
import showAlert from "../../../../../../utils/alert";
import FooterApproval from "./FooterApproval";
import SubmitView from "./submitView";
import Comments from "../../../Comments";
import { excelExportQuote } from "../services";
import ExportExcelModal from "./ExportExcelModal";
import { getProjectHasApprover } from "../../../NewProject/services";

const CompareQuotesHome = () => {

  const { roles, ZERO_UUID } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const managementId = localStorage.getItem('managementId');
  const isTSI = userRole === roles.AUTHOR
    || userRole === roles.EDITOR
    || userRole === roles.ADMIN;
  const isApprover = userRole === roles.AUTHOR || userRole === roles.ADMIN;
  const [checkList, setCheckList] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [kfpList, setKfpList] = useState([]);
  const [specCount, setSpecCount] = useState('');
  const [units, setUnits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenedRemarks, setOpenedRemarks] = useState(false);
  const [approvalSend, setApprovalSend] = useState(false);
  const [projectStatus, setProjectStatus] = useState('');
  const [projectDetails, setProjectDetails] = useState('');
  const [approveClick, setApproveClick] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [remarks, setRemarks] = useState('');
  const [canSend, setCanSend] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedYard, setSelectedYard] = useState('');
  const [addComment, setAddComment] = useState(false);
  const [yardComment, setYardComment] = useState('');
  const [selectedExcelYard, setSelectedExcelYard] = useState([]);
  const [acCodes, setAcCodes] = useState([]);
  const [selectModal, setSelectModal] = useState({ status: false, index: '', data: '' });
  const [sidePanelData, setSidePanelData] = useState({});
  const [showExportModal, setshowExportModal] = useState(false);
  const [applyAllModal, setApplyAllModal] = useState({
    status: false, pos: { i: '', j: '', k: '', l: '' }, value: '', field: '', formula: '', arr: {}
  });
  const [commentValue, setCommentValue] = useState({
    comment: '',
    index: '',
    rfqId: '',
    isSelected: ''
  });
  const [kpfRow, setKPFRow] = useState({
    i: '',
    j: '',
    k: '',
    l: ''
  });
  const [yardSelect, setYardSelect] = useState({
    rfqId: '',
    comment: '',
    isSelected: false,
    additionalDiscount: 0,
    additionalDiscountPercentage: 0
  });
  const disableFields = !(projectDetails?.tenderApprovalStatus === 0 || projectDetails?.tenderApprovalStatus === 3);
  const repairStarted = generalData?.repairStartDate ? true : false;
  const { id } = useParams();
  const Exportdata = {
    projectId: id,
    yardIds: []
  }
  const navigate = useNavigate();
  let temp = 0;
  const [calculate, setCalculate] = useState(false);
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);
  const [sortedQuotes, setSortQuotes] = useState([]);
  const [newACCode, setnewACCode] = useState(false);

  const getName = (resubmit = false) => {
    let n = 'Submit';
    if (resubmit) {
      n = 'Resubmit';
    }
    if (hasApprover) {
      n += ' for Approval';
    }
    return n;
  };
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  const reload = async (value) => {
    window.scrollTo(0, 0);
    if (value) setIsLoading(true);
    const payload = {
      projectId: id
    }
    await CompareQuotes(payload).then((res) => {
      setSearchList(res?.data?.serviceLines);
      res?.data?.checkLists?.specQuoteList.map((j, index) => {
        if (j.type !== 3) {
          temp = temp + 1;
          setSpecCount(temp);
        }
      });
      if (res?.data?.shipYards?.length) {
        res?.data?.checkLists?.specQuoteList?.map((j, index) => {
          j?.specDataList?.map((k, inde) => {
            k?.serviceLineList?.map((l, m) => {
              var lightColor = '#DAFFE0';
              const serviceLine = {
                acCode: '',
                acCodeId: '00000000-0000-0000-0000-000000000000',
                color: lightColor,
                comments: null,
                discount: '',
                id: '00000000-0000-0000-0000-000000000000',
                isFromYard: false,
                number: '',
                order: 0,
                ownerCost: 0,
                ownerEstimate: 0,
                parentSpecId: '00000000-0000-0000-0000-000000000000',
                quantity: 0,
                serviceLineName: '',
                specChild: null,
                specificationLevel: 3,
                type: 1,
                unitPrice: 0,
                uomId: '00000000-0000-0000-0000-000000000000',
                uomName: '',
                yardEstimate: 0
              };
              res?.data?.shipYards?.map((mm) => {
                if (l?.isFromYard) {
                  if (!mm?.specQuoteList[index]?.specChild[inde]?.specChild[m] || mm?.specQuoteList[index]?.specChild[inde]?.specChild[m]?.id !== l.id) {
                    mm?.specQuoteList[index].specChild[inde].specChild.splice(m, 0, serviceLine);
                  }
                  if (mm?.specQuoteList[index]?.specChild[inde]?.specChild[m]?.isFromYard) {
                    l.color = lightColor;
                    mm.specQuoteList[index].specChild[inde].specChild[m]['color'] = lightColor;
                  }
                }
              });
            });
          });
        });
      }
      setCheckList(res?.data?.checkLists?.specQuoteList);
      setGeneralData(res?.data?.projectInfo);
      setSearchList(res?.data?.serviceLines);
      res?.data?.shipYards?.length > 0 && res?.data?.shipYards?.map((i, index) => {
        if (index === 0) {
          i.showFull = true;
        } else i.showFull = false;
      });
      setCompareData(res?.data?.shipYards);
      res?.data?.shipYards?.map((i) => {
        if (i?.isSelected) {
          setSelectedYard(i.id);
          setCanSend(true);
          const temp = {
            rfqId: i.rfqId,
            comment: i.comment,
            isSelected: i.isSelected,
            additionalDiscount: i.additionalDiscount,
            additionalDiscountPercentage: i.additionalDiscountPercentage
          };
          setYardSelect(temp);
        }
      });
      setCalculate(true);
      setIsLoading(false);
    });
    await getKfp(payload).then((response) => {
      setKfpList(response.data);
    });
    await getUnits().then((response) => {
      const options = response?.data?.map((opt) => {
        return ({ label: opt.uomName, value: opt.uomId, name: opt.unit });
      });
      setUnits(options);
    });
    const i = {
      projectId: id,
      type: 4
    };
    await getprojectStatus(i).then((response) => {
      setProjectStatus(response?.data);
    });
    await getprojectDetails(i).then((response) => {
      setProjectDetails(response?.data);
    });
  };

  useEffect(() => {
    getAcCode({
      page: 1,
      pageSize: 100,
      search: '',
      sortProperty: 'asc',
      isDescending: false,
      managementGroupId: managementId || ZERO_UUID
    }).then((response) => {
      const options = response?.data?.items?.map((opt) => {
        return ({ label: opt.name, value: opt.id, name: opt.code });
      });
      setAcCodes(options);
    });
  }, [managementId]);

  useEffect(() => {
    reload(1);
  }, []);
  /* istanbul ignore next */
  const SaveCQ = (data, alert) => {
    saveCQ(data).then((res) => {
      if (res?.success && (!alert || data.isFromYard)) showAlert('Saved', 'success');
    });
  };
  /* istanbul ignore next */
  const SaveComment = (data, alertValue) => {
    saveComment(data).then((res) => {
      if (res?.success && alertValue === 'save') showAlert('Saved', 'success');
    });
  };
  /* istanbul ignore next */
  const SaveAssignee = (data) => {
    saveAssignee(data).then((res) => {
      if (res?.success) {
        showAlert('Saved', 'success');
        if (data.Assign === 1) reload(1);
      }
    })
  };

  /* istanbul ignore next */
  const SendForApproval = () => {
    const data = {
      projectId: id,
      type: 4
    };
    sendForApproval(data).then((response) => {
      if (response.success) {
        setApprovalSend(true);
        reload(1);
      }
    });
  };
  /* istanbul ignore next */
  const onSave = (isSuccess) => {
    if (remarks || buttonText === 'approve') {
      const data = {
        projectId: id,
        type: 4,
        level: projectStatus,
        status: buttonText === 'revision' ? 3 : 2,
        comment: remarks
      };
      updateStatus(data).then((res) => {
        if (res.success) {
          setOpenModal(true);
          if (isSuccess) {
            setApprovalSend(true);
          }
        }
      });
      setOpenedRemarks(false);
      reload(1);
    } else {
      showAlert('Please enter mandatory field', 'error');
    }
  };
  /* istanbul ignore next */
  const postDOCostDetails = (data) => {
    saveDOCost(data).then((res) => {
      if (res?.success) showAlert('Saved', 'success');
    });
  };
  /* istanbul ignore next */
  const newServiceLine = (data) => {
    saveServiceLine(data).then((response) => {
      if (response.success) {
        showAlert('Saved', 'success');
        reload();
      }
    });
  };
  /* istanbul ignore next */
  const ExportQuote = () => {
    Exportdata.yardIds = selectedExcelYard;
    excelExportQuote(Exportdata).then(() => {
      setshowExportModal(false);
      setSelectedExcelYard([]);
      showAlert('Successfully Downloaded', 'success');
    });
  };

  const [selectAll, setSelectedAll] = useState(false);
  useEffect(() => {
    if (!selectAll) {
      setSelectedExcelYard([]);
    }
  }, [selectAll])
  /* istanbul ignore next */
  const onYardSelect = async (eid) => {
    let selYard = [...selectedExcelYard];
    if (eid === 'selectAll') {
      const selectedallYard = [];
      compareData.map((item) => {
        selectedallYard.push(item.id);
      })
      setSelectedAll(!selectAll);
      selYard = selectedallYard;
    } else {
      if (selectedExcelYard.indexOf(eid) > -1) {
        selYard.splice(selectedExcelYard.indexOf(eid), 1);
      } else {
        selYard.push(eid);
      }
    }
    setSelectedExcelYard(selYard);
  };

  return (
    <div data-testid="qewewr">
      {isLoading && <Loader />}
      {openModal &&
        <ModalBox
          modalClassName="medium"
          onSave={() => {
            SaveComment(yardSelect, 'select');
            setTimeout(SendForApproval(), 1000);
          }}
          closeModal={() => {
            setMessage('sendforapproval');
            if (approvalSend) {
              setApprovalSend(false);
              setOpenModal(false);
            } else {
              setOpenModal(false)
            }
          }}
          heading={approvalSend ? "" : "Yard Confirmation Request"}
          buttonName={approvalSend ? "" : getName()}
        >
          {!approvalSend ?
            <div>
              <div>{hasApprover?'Are you sure you want to send for approval?':'Are you sure you want to submit?'}</div>
            </div>
            :
            <SubmitView
              projectDetails={projectDetails}
              message={message}
            />
          }
        </ModalBox>
      }
      {isOpenedRemarks &&
        <ModalBox
          closeModal={() => {
            setApproveClick(false);
            setOpenedRemarks(false);
            setButtonText('');
            setRemarks('');
          }}
          buttonName="Submit"
          modalClassName="medium modal-scroll"
          onSave={() => {
            onSave(true);
          }}
        >
          <AddRemarks
            name={projectDetails?.name}
            nameTooltip={projectDetails?.nameTooltip}
            setRemarks={setRemarks}
            buttonText={buttonText}
            setButtonText={setButtonText}
            remarks={remarks}
            approveClick={approveClick}
            CompareQuote={true}
          />
        </ModalBox>
      }
      {!isLoading && checkList &&
        <div className="d-flex compare-quote-classes compare-quote-container ms-2 mb-cq">
          <CheckList
            checkList={checkList}
            setCheckList={setCheckList}
            compareData={compareData}
            setCompareData={setCompareData}
            generalData={generalData}
            SaveAssignee={SaveAssignee}
            specCount={specCount}
            isTSI={isTSI}
            disableFields={disableFields}
            newServiceLine={newServiceLine}
            units={units}
            acCodes={acCodes}
            ExportQuote={ExportQuote}
            onYardSelect={onYardSelect}
            newACCode={newACCode}
            setnewACCode={setnewACCode}
          />
          <MappingData
            checkList={checkList}
            compareData={compareData}
            setCompareData={setCompareData}
            generalData={generalData}
            kfpList={kfpList}
            SaveCQ={SaveCQ}
            SaveComment={SaveComment}
            specCount={specCount}
            searchList={searchList}
            units={units}
            setCanSend={setCanSend}
            isTSI={isTSI}
            closeModal={closeModal}
            setCloseModal={setCloseModal}
            kpfRow={kpfRow}
            setKPFRow={setKPFRow}
            disableFields={disableFields}
            projectDetails={projectDetails}
            repairStarted={repairStarted}
            canSend={canSend}
            setSelectedYard={setSelectedYard}
            addComment={addComment}
            setAddComment={setAddComment}
            commentValue={commentValue}
            setCommentValue={setCommentValue}
            selectModal={selectModal}
            setSelectModal={setSelectModal}
            postDOCostDetails={postDOCostDetails}
            sidePanelData={sidePanelData}
            setSidePanelData={setSidePanelData}
            applyAllModal={applyAllModal}
            setApplyAllModal={setApplyAllModal}
            setYardSelect={setYardSelect}
            calculate={calculate}
            setCalculate={setCalculate}
            yardComment={yardComment}
            setYardComment={setYardComment}
            sortedQuotes={sortedQuotes}
            setSortQuotes={setSortQuotes}
          />
          {isTSI &&
            <div className="fixed-footer card d-flex justify-content-end">
              <div className="me-auto">
                <Comments id={id} appendClassName="comments-btn" />
              </div>
              <div className="text-black font-12 col-6">
            <div className="footer-tender">© 2024 ThinkPalm</div>

          </div>
              <button type="button" className="secondary-btn large me-5" onClick={() => { setshowExportModal(true); }}>Excel Download</button>
              <button
                className='secondary-btn large me-5'
                data-test="my-button"
                onClick={() => {
                  navigate(`/user/project/${id}/tender?vesselId=${projectDetails?.vesselId}`,
                    { state: { from: 'Comparequotes' } });
                }}
              >
                Back
              </button>
              {userRole !== roles.ADMIN &&
                <button
                  className={`primary-btn large me-5 ${canSend && !generalData?.repairStartDate &&
                    (projectDetails?.tenderApprovalStatus === 0 ||
                      projectDetails?.tenderApprovalStatus === 3 ||
                      projectDetails?.tenderApprovalStatus === 2) && selectedYard !== generalData?.previousSelectedYard
                    ? '' : 'disabled'}`}
                  data-test="click2"
                  onClick={() => {
                    if (canSend && !generalData?.repairStartDate &&
                      (projectDetails?.tenderApprovalStatus === 0 ||
                        projectDetails?.tenderApprovalStatus === 3 ||
                        projectDetails?.tenderApprovalStatus === 2) && selectedYard !== generalData?.previousSelectedYard)
                      setOpenModal(true);
                  }}
                >
                  {projectDetails?.tenderApprovalStatus === 0 ? getName():
                    projectDetails?.tenderApprovalStatus === 1 || projectDetails?.tenderApprovalStatus === 3 ?
                    getName(true) :
                    getName()}
                </button>}
            </div>
          }
          {showExportModal && (
            <ModalBox
              heading="Select Quote to Export"
              closeModal={() => {
                setshowExportModal(false);
                setSelectedExcelYard([]);
                setSelectedAll(false);
              }}
              buttonName="Download"
              modalClassName="medium"
              onSave={() => ExportQuote()}
              saveDisable={selectedExcelYard.length === 0}>
              <div className={`${compareData.length > 7 ? "scroll-wrap" : ''}`}>
                <ExportExcelModal compareData={compareData} onYardSelect={onYardSelect} selectedExcelYard={selectedExcelYard} selectAll={selectAll} />
              </div>
            </ModalBox>
          )}
          {!isApprover && projectStatus > 0 &&
            <FooterApproval
              id={id}
              showExportModal={showExportModal}
              setSelectedExcelYard={setSelectedExcelYard}
              ExportQuote={ExportQuote}
              selectedExcelYard={selectedExcelYard}
              compareData={compareData}
              onYardSelect={onYardSelect}
              projectStatus={projectStatus}
              setButtonText={setButtonText}
              setApproveClick={setApproveClick}
              setOpenedRemarks={setOpenedRemarks}
              reload={reload}
              setMessage={setMessage}
              fromFooter={false}
              setshowExportModal={setshowExportModal}
            />}
        </div>
      }
    </div>
  );
};

export default CompareQuotesHome;
