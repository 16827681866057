/* eslint-disable */
import React from 'react';
import { truncate } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const Table1Container = ({
  formData,
  formDataVesselType,
  onOpenTask,
  expandConfig,
  onOpenTaskSub,
  expandSubConfig,
  setLoading,
  checkBoxStatus,
  handleChangeType,
}) => {
  return (
    <div className="spec-table-container scroll-wrap-map-spec-admin" data-testid="gt-table-container">
      <table className="card-table over relative">
        <thead className="v-thead-fixed z-index-1100">
          <tr>
            <th className="fixed-gnt whitespace-pre">SEC #</th>
            <th className="whitespace-pre">Spec Section</th>
            {formDataVesselType.map((element) => {
              return (
                <th className="whitespace-pre">
                  <div>
                    {element.shortName}
                    <span
                      data-tooltip-id="project-Spectitle"
                      data-tooltip-content={element.name}
                      className="font-22 icon-Info ms-2 pointer text-blue"
                    />
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {formData?.map((original, orIndex) => {
            return (
              <>
                <tr>
                  <td className="whitespace-pre fixed-gnt bg-white font-14 ps-2">
                    <div className="d-flex flex-row">
                      <div
                        id={original.id}
                        data-test="click1"
                        onClick={() => {
                          onOpenTask(original, orIndex);
                        }}
                        className="priority-ind d-flex"
                      >
                        {expandConfig.indexOf(original.id) > -1 ? (
                          <span className="pointer mt-2 icon-arrow-thin-down font-8 me-2 font-semibold" />
                        ) : (
                          <span className="pointer mt-2 icon-arrow-thin-right font-12 me-3 font-semibold" />
                        )}
                        <div className="text-ellipsis w-150">{original?.specificationNo}</div>
                      </div>
                    </div>
                  </td>

                  <td>
                    {' '}
                    <span className="text-ellipsis" data-tooltip-id="view-dataconfig3" data-tooltip-content={original.title}>
                      {truncate(original.title, {
                        length: 70,
                        separator: ' ',
                      })}
                    </span>
                  </td>
                  {formDataVesselType.map((element) => {
                    let mainId = 'main';
                    mainId = mainId + '-';
                    mainId = mainId + element.id;
                    mainId = mainId + '-';
                    mainId = mainId + original.id;
                    const row = { ...original, index: orIndex };
                    return (
                      <td>
                        <div className="check-box">
                          <input
                            checked={checkBoxStatus('main', element, row)}
                            data-test="change1"
                            onChange={(e) => {
                              setLoading(true);
                              handleChangeType(element, row, 'main');
                              e.preventDefault();
                            }}
                            type="checkbox"
                            id={mainId}
                          />
                          <label htmlFor={mainId} />
                        </div>
                      </td>
                    );
                  })}
                </tr>
                {expandConfig.indexOf(original.id) > -1 &&
                  original?.childData?.map((sub, indexSub) => {
                    return (
                      <>
                        <tr>
                          <td className="whitespace-pre fixed-gnt bg-white font-14 ps-2">
                            <div className="d-flex flex-row">
                              <div
                                id={sub.id}
                                data-test="click2"
                                onClick={() => {
                                  onOpenTaskSub(sub);
                                }}
                                className="priority-ind d-flex ps-3"
                              >
                                {expandSubConfig.indexOf(sub.id) > -1 ? (
                                  <span className="ps-3 pointer mt-2 icon-arrow-thin-down font-8 me-2 font-semibold" />
                                ) : (
                                  <span className="ps-3 pointer mt-2 icon-arrow-thin-right font-12 me-3 font-semibold" />
                                )}
                                <div className="text-ellipsis w-150">{sub?.specificationNo}</div>
                              </div>
                            </div>
                          </td>

                          <td>
                            {' '}
                            <span className="text-ellipsis" data-tooltip-id="view-dataconfig2" data-tooltip-content={sub.title}>
                              {truncate(sub.title, {
                                length: 70,
                                separator: ' ',
                              })}
                            </span>
                          </td>
                          {formDataVesselType.map((element) => {
                            let subSpecId = 'sub1';
                            subSpecId = subSpecId + '-';
                            subSpecId = subSpecId + element.id;
                            subSpecId = subSpecId + '-';
                            subSpecId = subSpecId + sub.id;
                            const row1 = { ...sub, index: indexSub };

                            return (
                              <td>
                                <div className="check-box">
                                  <input
                                    checked={checkBoxStatus('sub1', element, row1)}
                                    data-test="change2"
                                    onChange={(e) => {
                                      setLoading(true);
                                      handleChangeType(element, row1, 'sub1');
                                      e.preventDefault();
                                    }}
                                    type="checkbox"
                                    id={subSpecId}
                                  />
                                  <label htmlFor={subSpecId} />
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                        {expandSubConfig.indexOf(sub.id) > -1 &&
                          sub?.childData?.map((subService, indexSubService) => {
                            return (
                              <>
                                <tr>
                                  <td className="whitespace-pre fixed-gnt bg-white font-14 ps-2">
                                    <div className="d-flex flex-row">
                                      <div id={subService.id} className="priority-ind d-flex ps-5">
                                        <div className="text-ellipsis w-150 ps-5">{subService?.specificationNo}</div>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    {' '}
                                    <span className="text-ellipsis" data-tooltip-id="view-dataconfig" data-tooltip-content={subService.title}>
                                      {truncate(subService.title, {
                                        length: 70,
                                        separator: ' ',
                                      })}
                                    </span>
                                  </td>
                                  {formDataVesselType.map((element) => {
                                    let subServiceIdDiv = 'sub2';
                                    subServiceIdDiv = subServiceIdDiv + '-';
                                    subServiceIdDiv = subServiceIdDiv + element.id;
                                    subServiceIdDiv = subServiceIdDiv + '-';
                                    subServiceIdDiv = subServiceIdDiv + subService.id;
                                    const row2 = { ...subService, index: indexSubService };
                                    return (
                                      <td>
                                        <div className="check-box">
                                          <input
                                            checked={checkBoxStatus('sub2', element, row2)}
                                            data-test="change3"
                                            onChange={(e) => {
                                              setLoading(true);
                                              handleChangeType(element, row2, 'sub2');
                                              e.preventDefault();
                                            }}
                                            type="checkbox"
                                            id={subServiceIdDiv}
                                          />
                                          <label htmlFor={subServiceIdDiv} />
                                        </div>
                                      </td>
                                    );
                                  })}
                                </tr>
                              </>
                            );
                          })}
                      </>
                    );
                  })}
              </>
            );
          })}
        </tbody>
      </table>
      <Tooltip id="project-Spectitle" place="top" className="tooltip mt-4" />
      <Tooltip id="view-dataconfig2" place="top" className="tooltip mt-4" />
      <Tooltip id="view-dataconfig3" place="top" className="tooltip mt-4" />
      <Tooltip id="view-dataconfig" place="top" className="tooltip mt-4" />
    </div>
  );
};
export default Table1Container;
