import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { pieChartConfig, chartColors, chartValues } from '../SpecComponents/constant';

ChartJS.register(ArcElement, Tooltip, Legend);
const JobCard = (props) => {
  const { statusData } = props;
  const data = {
    datasets: [
      {
        data: chartValues(statusData),
        backgroundColor: chartColors,
        borderColor: [
          'rgba(255, 255, 255)',
          'rgba(255, 255, 255)',
          'rgba(255, 255, 255)'
        ],
        borderWidth: 2,
        cutout: 80,
        radius: 100
      }
    ]
  };
  return (
    <div className="card" style={{ height: '362px' }} data-testid="jobcard">
      <div className="px-4 py-3">
        <div className="head-16">Job Progress</div>
      </div>

      <div className="pt-2 border-down" />
      <div className="d-flex r-card">
        <div className="align-items-center d-flex flex-column justify-content-center">
          <div className="doughnut relative">
            <Doughnut data={data} />
            <div className="percentage-status">
              <div className="font-22 brand-color ">
                {statusData.completedPercentage}
                %
              </div>
              <div className="font-16 mt-2">Completed</div>
            </div>
          </div>
          <div className="ps-5">
            <div className="d-flex">
              <div className="ms-2 me-2">
                Total
                <span className="ms-2">:</span>
              </div>
              <div className=" me-5">{statusData.totalCount}</div>
            </div>
          </div>
        </div>
        <div className=" font-13">
          <div className="d-flex align-items-center mt-5 mb-3" />
          {
            pieChartConfig.map((conf) => {
              return (
                <div className="d-flex align-items-center mb-3">
                  <span className="icon-disc" style={{ color: conf.color }} />
                  <div className="d-flex">
                    <div className="ms-2 me-2">
                      {conf.label}
                      <span className="ms-2">:</span>
                    </div>
                    <div className=" me-5">{statusData[conf.key]}</div>
                  </div>
                </div>
              );
            })
          }
        </div>

      </div>
      {/* <div className="d-flex opacity-6 ms-5">
        <div>Report generated  </div>
        <div className="ms-3">20/07/2022</div>
      </div> */}

    </div>
  );
};

export default JobCard;
