import React from 'react';

const AddComment = (props) => {
  const { commentData, setCommentData, level, onCommentSubmit, secData, isDisabled } = props;
  const onCommentUpdate = (e) => {
    const currentData = { ...commentData };
    currentData.field = e.target.value;
    setCommentData(currentData);
  };
  if (commentData?.data?.id !== secData.id) {
    return null;
  }
  return (
    <div className="close-comment" key={commentData?.data?.number}>
      <h3 className="font-16 ps-3">{commentData?.data?.comment ? 'Edit Comment' : 'Add Comment'}</h3>
      <div className="p-3">
        <textarea disabled={isDisabled} id="cmt-area" value={commentData.field} onChange={(e) => onCommentUpdate(e)} className="text-box w-full h-text-area p-3" />
      </div>
      <div className="d-flex justify-content-end">
        <button id="cmt-cancel" type="button" className="secondary-btn small me-3" onClick={() => { setCommentData({}); }}>Cancel</button>
        <button id="cmt-save" type="button" disabled={isDisabled} className="primary-btn small me-3" onClick={() => { onCommentSubmit(level); }}>Submit</button>
      </div>
    </div>
  );
};
export default AddComment;
