/* istanbul ignore file */
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="page-not-found">
      <div className="img" />
      <h1 className="mt-4">Page not found</h1>
      <p className="mt-4">
        {'We\'re sorry, the page you requested could not be found. Please go back'}
        <span className="d-block">
          to the homepage or contact us
          <span className="brand-color"> support@maridock.com</span>
        </span>
      </p>
      <div className="d-flex justify-content-center">
        <button type="button" className="primary-btn mt-4" onClick={() => navigate('/')}>
          Go to Home
        </button>
      </div>
    </div>
  );
};
export default PageNotFound;
