import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { truncate } from 'lodash';
import Loader from '../../../common/loader';
import VendorSectionCard from './VendorSectionCard';

const VendorCard = (props) => {
  const { vendorData, isOpenHull, regions, collapseVendor,
    handleGuarantee, handleCollapseVendor, loading, isOpenRow,
    // eslint-disable-next-line no-unused-vars
    isTSI, setVendorItem } = props;

  if (loading) {
    return (
      <div data-testid="p-4">
        <Loader />
      </div>
    );
  }

  return (
    <div data-testid="p-4">
      <div className="border-down  v-p">
        <div className="d-flex justify-content-between">
          <div className="align-items-center d-flex">
            <div className="font-20 text-uppercase text-content-grey">
              <span className="text-ellipsis" data-tooltip-id="project-cost123" data-tooltip-content={`${vendorData.vendor}`}>
                {truncate(vendorData.vendor, { length: 30 })}
              </span>
            </div>
            <div
              className="check-box ms-4"
              onClick={() => {
                console.log('vendorData.vendor', vendorData.vendor);
                handleGuarantee(vendorData.vendor);
              }}
              id="vendor-data"
            >
              <input type="checkbox" id={`Guarantee-${vendorData.vendor}`} checked={vendorData.withGuarantee} />
              <label className="text-content-grey " htmlFor="Guarantee">
                With Guarantee
              </label>
            </div>
          </div>
          <div className="">
            <span
              id="v-click"
              onClick={() => {
                handleCollapseVendor(vendorData);
              }}
              className={`${
                collapseVendor.includes(vendorData.vendor) ? 'icon-arrow-thin-right' : 'icon-arrow-thin-left '
              } circle-arrow font-16 me-4`}
            />
          </div>
        </div>
        <button
          className="link-btn"
          type="button"
          id="attachments"
          onClick={() => {
            setVendorItem(vendorData);
          }}
        >
          Attachments
        </button>
      </div>
      <div className="font-18 pl-1 text-content-grey">
        <div className="region-div">
          {regions?.map((r) => {
            if (r.isShow) {
              return (
                <div className="region-div-inner paint-l-border">
                  <span className="icon-location font-18 me-1 pt-1 brand-color" />
                  {r.label}
                </div>
              );
            }
            return '';
          })}
          {!regions || !regions.length ? (
            <div className="region-div-inner paint-l-border">
              <span className="icon-location font-18 me-1 pt-1 white" />
              .
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {vendorData?.rowData?.map((i, key) => {
        if ((i.type < 5 && isOpenHull === 'hull') || i.type >= 5) {
          return (
            <VendorSectionCard
              isOpenRow={isOpenRow}
              {...props}
              i={i}
              ikey={key}
              isTSI={isTSI}
            />
          );
        }
        return '';
      })}
      <Tooltip id="project-cost123" className="tooltip mt-3" />
    </div>
  );
};
export default VendorCard;
