import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const DeferredSpecList = (props) => {
  const { openModal, formattedlist, selectedSpecList, setSelectedSpecList } = props;

  return (
    <div className="d-flex align-items-center">
      <table className="w-full html-table align-items-center">
        <thead>
          <tr>
            <th className="col-3">Sec#</th>
            <th className="col-3">Deferred spec</th>
            <th className="col-3">
              <div className="d-flex">
                <div>Map to section</div>
                <span className="text-primaryRed-1">*</span>
              </div>

            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-3">{openModal.specificationNo}</td>
            <td className="col-3">
              <span
                className="word-break break-all"
                data-tooltip-id="project-titles"
                data-tooltip-content={openModal.title}
              >
                {_.truncate(openModal.title, { length: 16 })}
              </span>
            </td>
            <td className="col-3 align-items-center">
              <Select
                id="defId"
                className=""
                value={selectedSpecList}
                options={formattedlist}
                onChange={(e) => { setSelectedSpecList(e, 'defId'); }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Tooltip id="project-titles" place="top" className="tooltip-w5 m-4" />

    </div>
  );
};

export default DeferredSpecList;
