/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../../common/loader';
import { getProjectHasApprover } from '../services';

const PreConfirm = (props) => {
  const {
    projectId, name, nameTooltip, confirmModal, setIsPreOpened, loading, projectDetails
  } = props;
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(projectId, type)?.then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);

  if (loading) return <Loader />;
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">
      <div className="head-18 pb-4">
        <span data-tooltip-id="project-page" data-tooltip-content={nameTooltip}>
          {' '}
          {name}
        </span>

      </div>
      <div className="mt-3">
        {hasApprover
          ? <p className="font-15 font-semibold">Project will be sent for next level of approval</p>
          : ''}
      </div>
      <span className="head-18">{`Are you sure you want to ${projectDetails?.planApprovalStatus === 3 ? 're' : ''}submit?`}</span>
      <div className="d-flex">
        <button type="button" className="secondary-btn large m-3 p-3" id="backtoprojects" onClick={() => setIsPreOpened(false)}>
          Cancel
        </button>
        <button disabled={loading} type="button" className="primary-btn large m-3 p-3" id="save" onClick={() => confirmModal()}>
          {`${projectDetails?.planApprovalStatus === 3 ? 'Resubmit' : 'Submit'}`}
        </button>
      </div>
      <Tooltip id="project-page" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default PreConfirm;
