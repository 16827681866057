import axios from 'axios';
import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';
import helper from '../../../../../utils/helper';
import showAlert from '../../../../../utils/alert';

const {
  ProjectCollation,
  ProjectEvaluation,
  ProjectCloseYard,
  ProjectCloseProject,
  ProjectCloseDocument,
  CloseProject,
  sendForApprovalUrl,
  ExportPaintCost,
  ProjectReport,
  ShareDocument,
  DownloadYardEvaluation
} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const getProjectCollation = (payload) => {
  return serviceHandler({
    url: `${ProjectCollation}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const exportPaintCost = (payload) => {
  return axios
    .get(`${ExportPaintCost}?projectId=${payload.id}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const saveProjectCollation = (payload) => {
  return serviceHandler({
    url: `${ProjectCollation}`,
    methordType: 'post',
    payload
  });
};
export const getProjectEvaluation = (payload) => {
  return serviceHandler({
    url: `${ProjectEvaluation}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const saveProjectEvaluation = (payload) => {
  return serviceHandler({
    url: `${ProjectEvaluation}`,
    methordType: 'post',
    payload
  });
};
export const downloadYardEvaluation = (payload) => {
  return axios
    .get(`${DownloadYardEvaluation}?projectId=${payload}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((error) => {
      showAlert(error?.response?.data?.Message || 'Something went wrong', 'error');
    });
};
export const projectCloseProject = (payload) => {
  return serviceHandler({
    url: `${ProjectCloseProject}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const projectCloseYard = (payload) => {
  return serviceHandler({
    url: `${ProjectCloseYard}`,
    methordType: 'post',
    payload
  });
};
export const projectCloseDocument = (payload) => {
  return serviceHandler({
    url: `${ProjectCloseDocument}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const closeProject = (payload) => {
  return serviceHandler({
    url: `${CloseProject}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const sendForApproval = (payload) => {
  return serviceHandler({
    url: `${sendForApprovalUrl}?projectId=${payload?.projectId}&type=${payload?.type}`,
    methordType: 'get'
  });
};
export const saveCloseDocument = (payload) => {
  return serviceHandler({
    url: `${ProjectCloseDocument}`,
    methordType: 'post',
    payload
  });
};
export const exportCloseReport = (payload) => {
  return axios
    .get(`${ProjectReport}?projectId=${payload.id}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((error) => {
      showAlert(error?.response?.data?.Message || 'Something went wrong', 'error');
    });
};
export const CloseShareDocument = (payload) => {
  return serviceHandler({
    url: `${ShareDocument}?projectId=${payload?.projectId}`,
    methordType: 'post',
    payload
  });
};
