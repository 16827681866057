/* istanbul ignore file */
import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const AdditonalSpecs = (props) => {
  const { specList, mainSec, showsubSec, onSecOpen, editData, isVesselUser,
    deteleData, setIsAddClicked, isTSI, projectBasicDetails } = props;
  const handleAssignedTo = (subsec) => {
    console.log(subsec, 'subSec');
    if (subsec?.specAssigned === 1) {
      return 'Y';
    } if (subsec?.specAssigned === 2) {
      return 'V';
    }
    if (subsec?.specAssigned === 3) {
      return 'S';
    }
    return '';
  };
  return (
    <>
      <div className="spc-map ps-3 supplementary-table" data-testid="additional-spec-table">
        {specList?.length === 0 ? ''
          : (
            <div className={isVesselUser ? 'd-flex border-down mb-2 ps-3 py-2 ps-3' : 'd-flex border-down mb-2 ps-3'}>
              <div className="col-2 font-bold">SEC#</div>
              <div className="col-8 font-bold">SPEC SECTION</div>
            </div>
          )}
        {
        specList?.map((sec) => {
          let showDot = false;
          if (isVesselUser) {
            sec?.childData?.forEach((el) => {
              if (el?.isEditable && !showDot) {
                showDot = true;
              }
            });
          }
          return (
            <div>
              <div className="d-flex border-down py-2 ps-3 ">
                <div className="col-2 d-flex align-items-center">
                  <span
                    data-testid="open"
                    className={sec?.id === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec?.id, true); }}
                  />
                  <div
                    className=""
                  >
                    <span
                      data-tooltip-id="project-supplementary"
                      data-tooltip-content={sec?.specificationNo}
                      className={showDot ? 'dot-indic' : ''}
                    >
                      {_.truncate(sec?.specificationNo, { length: 10 })}
                    </span>
                  </div>
                </div>
                {/* <div title={sec?.title} className="pe-5 text-ellipsis">
                {_.truncate(sec?.title, { length: 50 })}</div> */}
                <div className="pe-5 text-ellipsis">
                  {' '}
                  <span
                    data-tooltip-id="project-supplementary"
                    data-tooltip-content={sec?.title}
                  >
                    {_.truncate(sec?.title, { length: 60 })}
                  </span>
                </div>

              </div>
              {
                sec?.id === mainSec && sec?.childData?.map((subsec) => {
                  return (
                    <>
                      <div className="d-flex mb-2 pb-2 ps-3 mt-3 border-down">
                        <div className="col-1" />
                        <div className="col-11 d-flex align-items-center">
                          <span
                            data-testid="open"
                            className={subsec?.id === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                            onClick={() => { onSecOpen(subsec?.id, false); }}
                          />
                          {console.log(subsec?.isEditable, 'sjdhfbsbfd')}
                          <span
                            data-tooltip-id="project-supplementary"
                            data-tooltip-content={subsec?.specificationNo}
                          >
                            {_.truncate(subsec?.specificationNo, { length: 10 })}
                          </span>
                          {/* <div title={subsec?.title} className="col-7 text-ellipsis ps-5">
                          {_.truncate(subsec?.title, { length: 80 })}</div> */}
                          <div className="col-7 text-ellipsis ps-5">
                            <span
                              data-tooltip-id="project-supplementary"
                              data-tooltip-content={subsec?.title}
                            >
                              {_.truncate(subsec?.title, { length: 50 })}
                            </span>
                          </div>

                          <div
                            className="bg-sky-blue px-2 cursor-auto"
                            style={{ position: 'absolute',
                              left: '750px' }}
                          >
                            {handleAssignedTo(subsec)}
                          </div>
                          <div className="editDeleteSpec">
                            <div className="d-flex ml-8">
                              <span
                                data-tooltip-id="project-supplementary"
                                data-tooltip-content={isVesselUser && !subsec?.isEditable ? 'User cannot edit as spec is with shore user for further processing.' : ''}
                                id="edit"
                                onClick={() => {
                                  if (subsec?.isEditable) editData(subsec); setIsAddClicked(false);
                                }}
                                className={!subsec?.isEditable ? 'icon-edit font-24 brand-color disabled-field' : 'icon-edit font-24 brand-color cursor-pointer'}
                              />
                              {(isTSI || !isVesselUser) && (
                                <button
                                  data-tooltip-id="project-supplementary"
                                  data-tooltip-content={isVesselUser && !subsec?.isEditable ? 'User cannot delete as spec is with shore user for further processing.' : ''}
                                  type="button"
                                  onClick={() => {
                                    if (subsec?.isEditable) deteleData(subsec);
                                  }}
                                  disabled={projectBasicDetails?.isClosed}
                                >
                                  <span className={!subsec?.isEditable ? 'icon-delete font-24 brand-color disabled-field ml-12' : 'icon-delete font-24 brand-color ms-3 cursor-pointer'} />
                                </button>
                              )}

                            </div>

                          </div>

                        </div>
                      </div>
                      <div className="">
                        {subsec?.id === showsubSec
                            && subsec.specServiceLine.map((serv, num) => {
                              return (
                                <div>
                                  {num === 0 && (
                                    <div className="d-flex ps-3 align-items-center">
                                      <div className="col-1" />
                                      <div className="col-2 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                                      <div className="col-7 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                                      <div className="col-1 bg-sky-white ps-3 border-down py-2">UOM</div>
                                      <div className="col-1 bg-sky-white ps-3 border-down py-2">Qty</div>

                                    </div>
                                  )}
                                  <div className="d-flex ">
                                    <div className="col-1" />
                                    <div className="border-down col-2 p-2 ml-12">{serv.number}</div>
                                    <div className="col-7 p-2 border-down text-justify text-break-all">{serv.serviceLineName}</div>

                                    <div className="col-1 ps-2 border-down">
                                      <span
                                        data-tooltip-id="project-supplementary"
                                        data-tooltip-content={serv.uom || '-'}
                                      >
                                        {_.truncate(serv.uom, { length: 7 })}
                                      </span>
                                    </div>
                                    <div className="col-1 p-2 border-down ">{serv.quantity || '-'}</div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </>
                  );
                })
              }
            </div>
          );
        })
      }

      </div>
      {specList?.length === 0 && (
      <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
        <div className="font-18 animate__animated animate__headShake">
          No data found
        </div>
      </div>
      )}
      <Tooltip id="project-supplementary" place="top" className="tooltip mt-3" />
    </>
  );
};
export default AdditonalSpecs;
