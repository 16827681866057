import React from 'react';
import ReactTable from 'react-table-v6';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';

const OtherCostTable = ({
  repairCostSummary,
  projectBasicDetails,
  renderVariance,
  renderVariancePercentage
}) => {
  const { formatNumber } = helper;
  const data = [
    'Deviation Cost',
    'Off Hire Cost'
  ];
  const tableData = [];
  data.forEach((i, index) => {
    if (index === 0) {
      tableData.push(
        {
          name: i,
          estimated: repairCostSummary?.otherCostData?.estimatedDeviationCost,
          actual: repairCostSummary?.otherCostData?.actualDeviationCost,
          variance: repairCostSummary?.otherCostData?.varianceDeviationCost,
          variancepercent: repairCostSummary?.otherCostData?.varianceDeviationCostPercentage
        }
      );
    } else {
      tableData.push(
        {
          name: i,
          estimated: repairCostSummary?.otherCostData?.estimatedOffHireCost,
          actual: repairCostSummary?.otherCostData?.actualOffHireCost,
          variance: repairCostSummary?.otherCostData?.varianceOffHireCost,
          variancepercent: repairCostSummary?.otherCostData?.varianceOffHirePercentage
        }
      );
    }
  });
  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: false,
      resizable: false,
      width: 310,
      accessor: 'number',
      sortable: false,
      Cell: (row) => {
        const { index } = row;
        return <div className="p2-4">{data[index]}</div>;
      }
    },
    {
      Header: 'Estimated',
      accessor: 'estimated',
      sortable: false,
      expander: false,
      width: 300,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        if (original.estimated) {
          return `${projectBasicDetails?.currencySymbol} ${original.estimated ? formatNumber(original.estimated) : formatNumber(0)}`;
        }
        return `${projectBasicDetails?.currencySymbol} ${formatNumber(0)}`;
      }
    },
    {
      Header: 'Actual',
      width: 300,
      accessor: 'actual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        if (original.actual) {
          return `${projectBasicDetails?.currencySymbol} ${original.actual ? formatNumber(original.actual) : formatNumber(0)}`;
        }
        return `${projectBasicDetails?.currencySymbol} ${formatNumber(0)}`;
      }
    },
    {
      Header: 'Variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.estimated, original.actual, row, '');
      }
    },
    {
      Header: 'Variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.estimated, original.actual, row);
      }
    }
  ];
  const showPagination = false;
  return (
    <div className="" data-testid="costtable">
      <ReactTable
        className="table-right-header"
        data={tableData}
        columns={columns}
        pageSize={tableData && tableData.length > 0 ? tableData.length : 1}
        showPagination={showPagination}
      />
      <Tooltip
        id="project-specName"
        place="top"
        className="tooltip mt-3"
      />
    </div>
  );
};

export default OtherCostTable;
