import React, { useEffect } from 'react';
import helper from '../../../../utils/helper';

const UserManual = () => {
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const { roles } = helper;
  useEffect(() => {
    if (!localStorage.getItem('USER_TOKEN')) {
      window.location.replace('/login');
    }
  }, []);
  const getUrl = () => {
    if (userRole === roles.VESSEL_USER) {
      return `${window.location.origin}/UserManual/Vessel_output/index.htm`;
    }
    return `${window.location.origin}/UserManual/${userRole === roles.ADMIN ? 'admin_output' : 'TSI_output'}/index.htm`;
  };
  return (
    <div data-testid="user-manual">
      <iframe
        id="test"
        width="100%"
        style={{ height: 'calc(100vh - 2em)' }}
        src={getUrl()}
        title="DRYD-User Guide"
      />
    </div>

  );
};
export default UserManual;
