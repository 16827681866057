/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import ReactTable from 'react-table-v6';
import { Tooltip } from 'react-tooltip';
import TableSortHeader from '../../../common/TableSortHeader';
import TableLoader from '../../../common/TableLoader';
import helper from '../../../../utils/helper';
import 'react-tooltip/dist/react-tooltip.css';

const CurrencyTable = (props) => {
  const { tableData, deteleData, editData, handleSort, sortData, loading } = props;
  const { formatNumber } = helper;

  const column = [
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="COUNTRY"
            headingProperty="countryName"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      sortable: false,
      resizable: false,
      accessor: 'countryName',
      width: 250,
      Cell: (row) => {
        const { value, original } = row;
        return (
          <div className="d-flex align-items-center">
            <img src={`data:image/jpeg;base64,${original.flagImage}`} className="me-4" style={{ height: 25 }} />
            {value}
          </div>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="CURRENCY"
            headingProperty="currencyName"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      resizable: false,
      sortable: false,
      accessor: 'currencyName'
    },
    // {
    //   Header: () => {
    //     return (
    //       <TableSortHeader
    //         heading="PREVIOUS RATE"
    //         headingProperty="previousRate"
    //         data={sortData}
    //         handleSort={handleSort}
    //       />
    //     );
    //   },
    //   resizable: false,
    //   sortable: false,
    //   accessor: 'previousRate',
    //   Cell: (row) => {
    //     const { value, original } = row;
    //     return (
    //       <div className="d-flex align-items-center">
    //         {`${original.currencySymbol} ${value}`}
    //       </div>
    //     );
    //   }
    // },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="CURRENT RATE"
            headingProperty="rate"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      resizable: false,
      sortable: false,
      accessor: 'rate',
      Cell: (row) => {
        const { value, original } = row;
        return (
          <div className="d-flex align-items-center">
            {`${original.currencySymbol} ${formatNumber(value)}`}
          </div>
        );
      }
    },
    {
      Header: 'ACTIONS',
      resizable: false,
      sortable: false,
      accessor: 'actions',
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="d-flex">
            <div>
              <button
                type="button"
                className="link-btn"
                onClick={() => {
                  editData(original);
                }}
              >
                <span className="icon-edit icon-btn  brand-color  font-24" />
              </button>
            </div>
            <div
              data-tooltip-id="project-currency2"
              data-tooltip-content={original?.mappedMessage}
            >
              <button
                type="button"
                className="link-btn ms-3"
                disabled={original.isMapped}
                onClick={() => {
                  deteleData(original);
                }}
              >
                <span className="icon-delete icon-btn  brand-color   font-24" />
              </button>
            </div>
            <Tooltip id="project-currency2" place="top" className="tooltip" />

          </div>
        );
      }
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="table-currency">
      <ReactTable
        className="table table-scroll"
        data={tableData?.items}
        pageSize={tableData?.items?.length}
        columns={column}
        minRows={0}
        showPagination={false}
        loading={loading}
        LoadingComponent={TableLoader}
      />
      <Tooltip id="project-value1" place="top" className="tooltip" />

    </div>
  );
};
export default CurrencyTable;
