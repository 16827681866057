import React, { useState } from 'react';

const Card = (props) => {
  const { vesselInfo } = props;
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="card mt-3 p-5">
      <div className="d-flex align-items-center justify-content-between pointer pt-2" onClick={() => { setIsOpened(!isOpened); }}>
        <h4 className="font-16 font-semibold head-black">Classification</h4>
        <span className={`font-12 ${!isOpened ? ' icon-arrow-thin-down' : ' icon-arrow-thin-up open'}`} />
      </div>
      {isOpened && (
        <div className="mb-2 pt-4">
          <div className="row">
            <div className="col-3"><label className="">Class :</label></div>
            <div className="col-9">
              <p className="value">{vesselInfo?.vesselClass || '-'}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3"><label className="">Class No :</label></div>
            <div className="col-9">
              <p className="value">{vesselInfo?.classNo || '-'}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3 "><label className="">Class Notation :</label></div>
            <div className="col-9"><p className="value">{vesselInfo?.classNotation || '-'}</p></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Card;
