import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetMgmtAcCodesUrl,
  saveAcCodeUrl,
  deleteAcCodeUrl,
  GenerateTemplateAcCode,
  BulkUploadAcCode,
  exportAcCodeExcel
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getAcCode = (payload) => {
  return serviceHandler({
    url: `${GetMgmtAcCodesUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveAcCode = (payload) => {
  return serviceHandler({
    url: `${saveAcCodeUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteAcCode = (payload) => {
  return serviceHandler({
    url: `${deleteAcCodeUrl}?Id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const exportExcelAcCode = (searchText) => {
  return axios
    .get(`${exportAcCodeExcel}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `AC Code_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const bulkUploadAcCode = (payload) => {
  return serviceHandler({
    url: `${BulkUploadAcCode}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateAcCode}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `AC Code_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
