/* eslint-disable prefer-template */
/* istanbul ignore file */
import React from 'react';

export const classMap = {
  Due: 'status-due',
  Overdue: 'status-overdue',
  'In Progress': 'status-progress',
  InProgress: 'status-progress',
  Completed: 'status-complete',
  'Not Applicable': 'status-na',
  NotApplicable: 'status-na',
  'On Hold': 'status-hold',
  Onhold: 'status-hold'
};

const ProgressCard = (props) => {
  const { countList } = props;
  return (
    <div className="card p-4 h-full">
      <h3 className="head-16 mb-3">Task Progress</h3>
      <ul className="d-flex flex-wrap w-full ">
        {
          countList.taskStatus?.map((item) => {
            return (
              <li className="d-flex wp-50 py-2">
                <div className={'me-3 couter-bubble ' + classMap[item.name]}>{item.count}</div>
                <label>{item.name === 'Due' ? 'To Do' : item.name}</label>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
export default ProgressCard;
