/* eslint-disable */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import _ from 'lodash';
import '../styles/calendar.css';
import { getSurveyList } from '../service';
import Loader from '../../../common/loader';
import Gantt from '../Children/GanttView/index';
import { getGanttList } from '../../Projects/ListProject/services';

const Calender = (props) => {
  const [value, onChange] = useState(new Date(moment().startOf('month')));
  const [ganttView, setGanttView] = useState(true);
  const [ganttList, setGanttList] = useState();

  const {
    year,
    setYear,
    focusBtn,
    setFocusBtn,
    monthName,
    setMonthName,
    surveyList,
    setSurveyList,
    monthSurveyList,
    setMonthSurveyList,
    yearClick,
    setYearClick,
  } = props;
  const onDisable = () => {
    setGanttView(!ganttView);
  };
   /* istanbul ignore next */
  const handleMonthClick = (e) => {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    setMonthName(monthNames[e.getMonth()]);

    const firstDate = moment(e).format(`${year}-MM-DD`) + 'T' + moment(e).format('HH:mm:ss') + '.000Z';

    const lastDate = new Date(year, e.getMonth() + 1, 1);

    const temp = JSON.stringify(lastDate);
    const lastDataNew = temp.replace('"', '');
    const lastDataNew1 = lastDataNew.replace('"', '');

    getSurveyList({ fromDate: firstDate, toDate: lastDataNew1 }).then((res) => {
      if (res.success) {
        const data = res.data;
        setMonthSurveyList(data);
      }
    });
  };

  const years = Array.from(Array(new Date().getFullYear() - 1980), (_, i) => (i + 1998).toString());
 /* istanbul ignore next */
  const handleTileContent = (date, yearClick) => {
    const surveyMnthArr = surveyList?.map((items) => items.surveyMonth);

    if (yearClick) {
      const dates = new Date(date.toString().replace(new Date().getFullYear(), year));

      return surveyMnthArr.indexOf(dates.getMonth() + 1) > -1 ? (
        <div className="react-calendar__tile-count">
          {surveyList.filter((items) => items.surveyMonth === date.getMonth() + 1)[0].surveyData.length}
        </div>
      ) : null;
    }
    return surveyMnthArr.indexOf(date.getMonth() + 1) > -1 ? (
      <div className="react-calendar__tile-count">{surveyList.filter((items) => items.surveyMonth === date.getMonth() + 1)[0].surveyData.length}</div>
    ) : null;
  };

   /* istanbul ignore next */
  const handleYear = (items, index) => {
    setMonthSurveyList([]);
    setYearClick(true);

    setYear(items);
    setFocusBtn(items);
    const firstMonth = '01';
    const lastMonth = '12';
    const utcDate = new Date().getUTCDate();

    const FrDate = `${items}-${firstMonth}-${utcDate}T00:00:00.000Z`;
    const LsDate = `${items}-${lastMonth}-${utcDate}T00:00:00.000Z`;

    getSurveyList({ fromDate: FrDate, toDate: LsDate }).then((res) => {
      if (res.success) {
        const data = res.data;
        setSurveyList(data);
        let currMonth = new Date().getMonth() + 1;
        res.data.map((items) => {
          if (currMonth === items?.surveyMonth) {
            const monthList = [];
            monthList.push(items);
            setMonthSurveyList(monthList);
          }
        });
      }
    });
  };
  const reload = () => {
    getGanttList().then((res) => {
      setGanttList(res?.data);
    });
  };
  useEffect(() => {
    setFocusBtn(new Date().getFullYear().toString());
    document?.getElementById(new Date()?.getFullYear() - 8)?.scrollIntoView();
    // const firstMonth = '01';
    // const lastMonth = '12';
    // const utcDate = new Date().getUTCDate();
    // const utcHours = new Date().getUTCHours();
    // const utcMinutes = new Date().getUTCMinutes();
    // const utcSeconds = new Date().getUTCSeconds();
    const fDate = `${year}-01-01T00:00:00.000Z`;
    const LDate = `${year}-12-31T00:00:00.000Z`;
    getSurveyList({ fromDate: fDate, toDate: LDate }).then((res) => {
      if (res?.success) {
        const data = res.data;
        setSurveyList(data);
        let currMonth = new Date().getMonth() + 1;
        res.data.map((items) => {
          if (currMonth === items?.surveyMonth) {
            const monthList = [];
            monthList.push(items);
            setMonthSurveyList(monthList);
          }
        });
      }
    });
    reload();
  }, []);

  return (
    <div className="widget-card" data-testid="calendar">
      <div className="head">
        <div className="d-flex justify-content-between">
          <h4 className="head-16 ">{`${ganttView ? 'Survey Gantt' : 'Survey Calendar'}`}</h4>
          <div className="toggle-switch mt-1 ms-5">
            <input type="checkbox" id="id" checked={ganttView} onChange={() => onDisable()} />
            <label htmlFor="id"></label>
          </div>
        </div>
      </div>
      {!ganttView ? (
        <div className="body survey-calendar">
          <div className="row">
            <>
              <div className="col-1 font-30 yearslist  scroll-wrap-year">
                <div className="">
                  {
                    <ul className="calendar-year-ribbon">
                      {years?.map((items, index) => {
                        return (
                          <li onClick={() => handleYear(items)} type="button" id={items} className={items === focusBtn ? 'focusYear' : ''}>
                            {items}
                          </li>
                        );
                      })}
                    </ul>
                  }

                  <br />
                </div>
              </div>
              <div className="col-7 font-16 ps-1 pe-1">
                <div className="year">{year}</div>
                <Calendar
                  onChange={onChange}
                  value={value}
                  className="calender"
                  handleYear={handleYear}
                  view="year"
                  onClickMonth={handleMonthClick}
                  tileContent={({ activeStartDate, date, view }) => handleTileContent(date, yearClick)}
                />
              </div>
              <div className="calendar-month-info scroll-wrap-year">
                <div className="p-4">
                  <div className="d-flex align-items-center pb-3 border-down">
                    <h4 className="head-16">{`${monthName}  ${year}`}</h4>
                  </div>
                  {surveyList?.length === 0 || monthSurveyList?.length === 0 ? (
                    <div className="font-16 mt-5">
                      <span className="icon-exclamation_fill brand-color font-18 me-2" />
                      No surveys due
                    </div>
                  ) : (
                    ''
                  )}
                  {monthSurveyList?.length > 0 &&
                    monthSurveyList?.map((items) => {
                      if (items.surveyData.length) {
                        const groupedData = _.groupBy(items.surveyData, (p) => p.vesselName);

                        return (
                          <>
                            {Object.values(groupedData).map((item) => {
                              if (item.length)
                                return (
                                  <>
                                    <div className="d-flex align-items-center my-2">
                                      <div className=" head-14 ">{item[0].vesselName}</div>
                                    </div>
                                    {item.map((i) => {
                                      return (
                                        <div className="font-14">
                                          <div className="mb-1">{i.type}</div>
                                          <div>{moment(i.surveyDate).format('DD-MMM-YYYY')}</div>
                                          <br />
                                        </div>
                                      );
                                    })}
                                  </>
                                );
                              return '';
                            })}
                          </>
                        );
                      }
                      return '';
                    })}
                </div>
              </div>
            </>
          </div>
        </div>
      ): <Gantt projects={ganttList}/>}
    </div>
  );
};
export default Calender;
