/* istanbul ignore file */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import showAlert from '../../../../../utils/alert';
import Comments from '../../Comments';

const NavigateButtons = (props) => {
  const navigate = useNavigate();
  const { selectedVessel, setSelectedVessel, setVesselInfo, handleActiveComponent,
    stepperList, setStepperList, isTsiDisabled, projectId, projectDetails,
    isChanged, multiProjChecking } = props;
  return (
    <div className="my-4 mt-5 pt-5 d-flex justify-content-between" data-testid="buttons">
      {projectId && projectDetails?.planApprovalStatus === 3
        ? <Comments id={projectId} /> : <div />}
      <div className="d-flex justify-content-end col-gap-1 " data-testid="buttons">
        <button
          type="button"
          className="secondary-btn large"
          onClick={() => {
            if (!isTsiDisabled) {
              if (selectedVessel) {
                setSelectedVessel('');
                setVesselInfo({});
              } else {
                navigate('/user/projects');
              }
            } else {
              navigate('/user/projects');
            }
          }}
        >
          Cancel

        </button>
        {multiProjChecking === false && isChanged === true ? (
          <button
            disabled
            type="button"
            className="primary-btn large"
          >
            Continue
          </button>
        ) : (
          <button
            onClick={() => {
              if (selectedVessel) {
                stepperList[0].isDone = true;
                setStepperList(stepperList);
                handleActiveComponent('project-details');
              } else {
                showAlert('Please choose a vessel', 'error');
              }
            }}
            type="button"
            className="primary-btn large"
          >
            Continue
          </button>
        )}

      </div>
    </div>
  );
};

export default NavigateButtons;
