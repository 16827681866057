/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import Pagination from '../../../common/pagination';
import CardWrapper from './cardWrapper';
import { closedProjectDeferredSpecList } from './service';

const DeferredToNext = () => {
  const [deferredList, setDeferredList] = useState({});
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useOutletContext() || '';
  let isSearched = false;
  const reloadTable = (page) => {
    closedProjectDeferredSpecList({
      page: page || currentpage,
      pageSize,
      search: isSearched ? searchText : '' }).then((res) => {
      if (res.success) {
        setDeferredList(res.data);
        if (setCount) {
          setCount(res?.data?.totalItems || 0);
        }
      }
    });
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearched = true;
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };

  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  useEffect(() => {
    reloadTable();
  }, [pageSize, currentpage]);
  return (
    <div className="container" data-testid="deferredList">
      <div className="d-flex my-4 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search">
          <div className="search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
                id="search-icon-close"
              />
              <span
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
                id="search-icon"
              />
            </div>
            <input
              type="text"
              className="text-box large with-close"
              placeholder="Search by Project Name"
              id="search"
              value={searchText}
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
                isSearched = false;
              }}
            />
          </div>
        </div>
      </div>
      <CardWrapper deferredList={deferredList} />
      {deferredList?.totalItems > 10 && (
        <Pagination
          pageClick={pageClick}
          pageCount={deferredList.totalPages}
          currentpage={currentpage}
          records={deferredList.totalItems}
          size={pageSize}
          onSizeChange={onSizeChange}
        />
      )}
    </div>
  );
};

export default DeferredToNext;
