/* istanbul ignore file */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const Resource = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const [count, setCount] = useState();

  const subHeaderList = [
    { name: 'Reference web links', url: 'web' },
    { name: 'Yard Cost History', url: 'yard' },
    { name: 'Reference Doc', url: 'reference' },
    { name: 'Deferred to next DD', url: 'deferredtonext' }
  ];
  return (
    <div className="page-wrapper">
      <div className="sub-header" data-testid="admin-resource">
        <div className="d-flex  justify-content-center">
          <>
            {subHeaderList ? (
              <div className="d-flex item-set col-gap-2">
                {subHeaderList.map((i) => {
                  return (
                     <button
                       onClick={() => navigate(`/admin/resource/${i.url}`)}
                       className={location.includes(`/admin/resource/${i.url}`) ? 'rnd-btn active' : 'rnd-btn'}
                       type="button"
                     >
                       {i.name}
                       {location.includes(`/admin/resource/${i.url}`)
                        && count !== '' && i.url !== 'usermanual' ? <span className="count-bar">{count}</span> : ''}
                     </button>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </>
        </div>
      </div>
      <Outlet context={[count, setCount]} />
    </div>
  );
};
export default Resource;
