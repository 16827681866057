import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import InputType1 from '../../../common/InputType1';
import ModalBox from '../../../common/ModalBox';
import { DeleteProjectType, GetProjectTypeList, SaveProjectType } from './services';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import AreaLoader from '../../../common/areaLoader';

const TypeSurvey = () => {
  const { charValidation, formValidation, handleValidation } = helper;
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialFormData = {
    name: '',
    shortName: '',
    description: '',
    status: 0
  };
  const [formData, setFormData] = useState(initialFormData);
  const reloadTable = () => {
    GetProjectTypeList().then((res) => {
      setLoading(false);
      setTableData(res.data);
    });
  };

  useEffect(() => {
    reloadTable();
    setLoading(true);
  }, []);

  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    DeleteProjectType(formData.id).then((res) => {
      if (res.success) {
        setLoading(false);
        setFormData(initialFormData);
        reloadTable();
        setOpenDeleteModal(false);
        setOpenModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const saveApiCall = () => {
    if (formValidation(['name', 'shortName'], formData)) {
      SaveProjectType(formData).then((res) => {
        if (res.success) {
          setLoading(false);
          reloadTable();
          setFormData(initialFormData);
          setOpenModal(false);
          showAlert('Successfully Saved', 'success');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    if (field === 'shortName') {
      fd[field] = charValidation(e.target.value, 4);
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['name', 'shortName'], fd, field);
  };
  return (
    <div className="border md-radius p-5 mb-5 half-width-gap-1" data-testid="type-project">
      {loading ? (
        <div style={{ height: '100px', width: '150px', margin: '20% auto' }}>
          <AreaLoader />
        </div>
      ) : (
        <div>
          <h4 className="head-16 mb-4">Project Type</h4>
          <div>
            {tableData && tableData.map((original) => (
              <div className="md-radius mt-2 p-2 row row-hover">
                <div className="col-11 text-break-all">
                  {original.name}
                  <span className="color-light-blue ms-1">
                    :
                    {' '}
                    {original.shortName}
                  </span>
                </div>
                <div
                  className="btn-set col-1 align-self-center"
                  data-tooltip-id="project-tooltip"
                  data-tooltip-content={original?.mappedMessage}
                >
                  <button
                    data-testid="delete"
                    disabled={original.isMapped}
                    onClick={() => deteleData(original)}
                    className="link-btn"
                    type="button"
                  >
                    <span className="icon-delete font-20" />
                  </button>
                  <Tooltip id="project-tooltip" place="top" className="tooltip mt-2 me-5" />

                </div>
              </div>
            ))}
          </div>
          <button id="add-new" onClick={() => { setOpenModal(true); setFormData(initialFormData); }} type="button" className="link-btn mt-4">
            <span className="icon-plus-square me-2" />
            Add New
          </button>
          {openModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Project Type"
              onSave={() => saveApiCall()}
              closeModal={() => setOpenModal(false)}
              buttonName="Save"
            >
              <div className="row">
                <div className="col-8">
                  <InputType1 autoFocus id="name" onChange={(e) => handleChange(e, 'name')} value={formData.name} className="modal-iput" label="Name" isMandatory="true" />
                </div>
                <div className="col-4">
                  <InputType1 id="shortName" onChange={(e) => handleChange(e, 'shortName')} value={formData.shortName} className="modal-iput" label="Short Name" isMandatory="true" />
                </div>
              </div>

            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => { setOpenDeleteModal(false); setOpenModal(false); }}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete Project Type ?</p>
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      )}

    </div>
  );
};

export default TypeSurvey;
