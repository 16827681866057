import apiEndPoints from '../../../../../utils/apiUrlConstants';
import { serviceHandler } from '../../../../../utils/serviceHandler';

const {
  GetProjectDDHistory,
  RepairSpecList,
  ProjectSpecDetailsList,
  GetProjectDetails

} = apiEndPoints;

export const GetDDHistory = (payload) => {
  return serviceHandler({
    url: `${GetProjectDDHistory}`,
    methordType: 'post',
    payload
  });
};
export const getAllSpecList = (payload) => {
  return serviceHandler({
    url: `${RepairSpecList}?projectId=${payload.projectId}&searchString=${payload.searchString}`,
    methordType: 'get',
    payload
  });
};
export const projectSpecDetailsList = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecDetailsList}?projectSpecId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const getProjectDetails = (payload) => {
  return serviceHandler({
    url: `${GetProjectDetails}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
