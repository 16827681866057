import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  getFoldersUrl,
  GetDataUrl,
  GetSystemFilesUrl,
  SaveDataUrl,
  DeleteDataUrl,
  SearchDmsUrl
} = apiEndPoints;

export const getFolders = (payload) => {
  return serviceHandler({
    url: `${getFoldersUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const GetData = (payload) => {
  return serviceHandler({
    url: `${GetDataUrl}`,
    methordType: 'post',
    payload
  });
};
export const GetSystemFiles = (payload) => {
  return serviceHandler({
    url: `${GetSystemFilesUrl}`,
    methordType: 'post',
    payload
  });
};

export const SaveData = (payload) => {
  return serviceHandler({
    url: `${SaveDataUrl}`,
    methordType: 'post',
    payload
  });
};

export const DeleteData = (payload) => {
  return serviceHandler({
    url: `${DeleteDataUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const GetSearchData = (payload) => {
  return serviceHandler({
    url: `${SearchDmsUrl}`,
    methordType: 'post',
    payload
  });
};
