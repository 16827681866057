import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const ImageUpload = (props) => {
  const { file, ondrop, setFile } = props;
  const onUpload = (e) => {
    setFile(e);
  };
  return (
    <div className="p-3 mt-2" data-testid="upload-card">
      <div className="row">
        {!file ? (
          <div id="fileName-div" className="drag-drop-file my-4 py-5 file-waiting col-12">
            <input className="p-5 align-self-center" id="fileName" multiple title="" type="file" name="fileName" onChange={(e) => { onUpload(e); }} isMandatory="true" />
            <div className="d-flex row w-full">
              <div className="d-flex flex-column justify-content-center align-items-center col-7">
                <div className="icon-buble mb-2">
                  <span className="icon-upload-cloud" />
                </div>
                <div>Choose a file or drag it here</div>
                <div className="font-12">(File formats: jpeg, jpg, png, tiff - Max size 5MB)</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-5">
                <div className="div_btn">
                  Browse File
                </div>
              </div>
            </div>
          </div>
        )
          : (
            <div id="fileName-div" className="drag-drop-files my-4 py-5 file-added col-12 d-flex align-items-center flex-row">
              <div className="font-18 d-flex col-7 align-items-center">
                <span className="icon-image me-2 font-20"> </span>
                <div>
                  {Array.from(file?.target?.files).map((item) => (
                    <span data-tooltip-id="project-specc" data-tooltip-content={item.name}>
                      {_.truncate(item.name, { length: 25 })}
                      <br />
                    </span>
                  ))}

                </div>
                <span className="icon-close ms-2 font-20 red" onClick={() => { setFile(''); }}> </span>
              </div>
              <div>
                <button type="button" id="upload" className="primary-btn" onClick={() => { ondrop(file); setFile(''); }}>
                  Upload
                </button>
              </div>
            </div>
          )}
      </div>
      <Tooltip id="project-specc" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ImageUpload;
