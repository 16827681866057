import React from 'react';
import Table1Container from './Table1Container';

const SpecifyApproval = (props) => {
  return (
    <div data-testid="a-2">
      <Table1Container
        {...props}
      />
    </div>
  );
};
export default SpecifyApproval;
