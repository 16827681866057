import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Picky } from 'react-picky';
import 'react-picky/dist/picky.css';

const AddTag = ({ tagData, setTagData, acCodes }) => {
  const handleChange = (e) => {
    setTagData(e);
  };
  return (
    <>
      <div className="modal-content" data-testid="create-tag">
        <div className="row">
          <div
            className="col-12"
            data-tooltip-id="project-specCard"
            data-tooltip-content={tagData.name}
          >
            <label>
              Name
              <span className="text-primaryRed-1">*</span>
            </label>
            <Picky
              value={tagData}
              options={acCodes}
              onChange={(e) => { handleChange(e); }}
              valueKey="tagId"
              labelKey="name"
              multiple
              includeFilter
              dropdownHeight={600}
              clearFilterOnClose
            />
          </div>
        </div>
        <Tooltip id="project-specCard" place="top" className="tooltip mt-3" />
      </div>
    </>
  );
};
export default AddTag;
