/* eslint-disable space-infix-ops */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { cloneDeep, map } from 'lodash';
import Components from '../../../Specify/SpecsList/Components';
import Description from '../../../Specify/SpecsList/Description';
import ServiceLine from '../../../Specify/SpecsList/ServiceLine';
import InspectionsApprovals from '../../../Specify/SpecsList/InspectionsApprovals';
import Attachment from '../../../Specify/SpecsList/Attachment';
import POPR from '../../../Specify/SpecsList/POPR';
import WODefectList from '../../../Specify/SpecsList/WODefectList';
import helper from '../../../../../../../utils/helper';

const SubList = (props) => {
  const { selectedSpec, projectId, specDetails, onReload,
    isTsiDisabled, setIsComponentClicked, currency, fromSpecify,
    fromVariation, currencyId, fromSupplementary, activeTabSupplementary, projInfo } = props;
  const { roles } = helper;
  const [active, setActive] = useState('Description');
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR
  || userRole === roles.EDITOR
  || userRole === roles.ADMIN || userRole === roles.VESSEL_USER;

  useEffect(() => {
    setActive('Description');
  }, [specDetails]);
  return (
    <div className="d-flex" data-testid="speclist_main">
      <div className="flex-grow-1">
        <div className="right-content-wrap">

          <div className="list-tab-head d-flex justify-content-between border-down ">
            <div id="desc" onClick={() => setActive('Description')} className={`tab-items ${active === 'Description' && 'active'}`}>
              Description
            </div>
            <div id="serline" onClick={() => setActive('ServiceLine')} className={`tab-items ${active === 'ServiceLine' && 'active'}`}>
              Service Line
            </div>
            <div id="comp" onClick={() => setActive('Components')} className={`tab-items ${active === 'Components' && 'active'}`}>
              Components
            </div>
            <div id="tag" onClick={() => setActive('InspectionsApprovals')} className={`tab-items ${active === 'InspectionsApprovals' && 'active'}`}>
              Tags
            </div>
            <div id="attach" onClick={() => setActive('Attachment')} className={`tab-items ${active === 'Attachment' && 'active'}`}>
              Attachments
            </div>
            <div id="popr" onClick={() => setActive('POPR')} className={`tab-items ${active === 'POPR' && 'active'}`}>
              PO/PR
            </div>
            <div id="wodefect" onClick={() => setActive('WODefectList')} className={`tab-items ${active === 'WODefectList' && 'active'}`}>
              WO/Defect List
            </div>
          </div>
          <div className="">

            {active === 'Description' && <Description fromSpecify isTSI={isTSI} isTsiDisabled={isTsiDisabled} projectSpecificationId={selectedSpec} projectId={projectId} projInfo={projInfo} />}
            {active === 'ServiceLine' && <ServiceLine activeTabSupplementary={activeTabSupplementary} fromSupplementary={fromSupplementary} fromSpecify isTSI={isTSI} isTsiDisabled={isTsiDisabled} specDetails={specDetails} projectSpecificationId={selectedSpec} projectId={projectId} projInfo={projInfo} />}
            {active === 'Components' && <Components fromSpecify isTSI={isTSI} isTsiDisabled={isTsiDisabled} specDetails={specDetails} projectSpecificationId={selectedSpec} projectId={projectId} setIsComponentClicked={setIsComponentClicked} projInfo={projInfo} />}
            {active === 'InspectionsApprovals' && <InspectionsApprovals fromSpecify isTSI={isTSI} isTsiDisabled={isTsiDisabled} projectSpecificationId={selectedSpec} projectId={projectId} projInfo={projInfo} />}
            {active === 'Attachment' && <Attachment fromSpecify isTSI={isTSI} isTsiDisabled={isTsiDisabled} projectSpecificationId={selectedSpec} projectId={projectId} projInfo={projInfo} />}
            {active === 'POPR' && <POPR currencyId={currencyId} fromSpecify fromVariation={fromVariation} currency={currency} isTSI={isTSI} projectSpecificationId={selectedSpec} isTsiDisabled={isTsiDisabled} projectId={projectId} onReload={onReload} projInfo={projInfo} />}
            {active === 'WODefectList' && <WODefectList fromVariation={fromVariation} fromSpecify isTSI={isTSI} isTsiDisabled={isTsiDisabled} projectSpecificationId={selectedSpec} projectId={projectId} onReload={onReload} />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubList;
