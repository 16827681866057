import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';

const {
  getSpecPackagesUrl,
  GetSpecificationPackageUrl,
  saveSpecPackagesUrl,
  deleteSpecPackagesUrl,
  getAllVesselTypeUrl,
  getGeneralTariffUrl,
  GetProjectTypeListUrl,
  PublishSpecPackage
} = apiEndPoints;

export const getSpecPackages = (payload) => {
  return serviceHandler({
    url: `${getSpecPackagesUrl}`,
    methordType: 'post',
    payload
  });
};
export const getSpecPackageDetail = (payload) => {
  return serviceHandler({
    url: `${GetSpecificationPackageUrl}?id=${payload}`,
    methordType: 'get',
    payload
  });
};
export const saveSpecPackages = (payload) => {
  return serviceHandler({
    url: `${saveSpecPackagesUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteSpecPackages = (payload) => {
  return serviceHandler({
    url: `${deleteSpecPackagesUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const getVesselType = (payload) => {
  return serviceHandler({
    url: `${getAllVesselTypeUrl}`,
    methordType: 'get',
    payload
  });
};
export const GetProjectTypeList = (payload) => {
  return serviceHandler({
    url: `${GetProjectTypeListUrl}?status=${payload}`,
    methordType: 'get',
    payload
  });
};
export const getMajorSpec = (payload) => {
  return serviceHandler({
    url: `${getGeneralTariffUrl}?type=2&isPublished=true`,
    methordType: 'get',
    payload
  });
};
export const publishSpecPackage = (payload) => {
  return serviceHandler({
    url: `${PublishSpecPackage}?id=${payload.id}`,
    methordType: 'put',
    payload
  });
};
