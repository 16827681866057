import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ModalBox from '../../../common/ModalBox';
import AddKfp from './addKfp';
import { deleteKfp, getKfp, saveKfp, getUomList, bulkUploadKFP, generatTemplate, exportExcelKfp } from './services';
import Pagination from '../../../common/pagination';
import showAlert from '../../../../utils/alert';
import KfpTable from './kfpTable';
import helper from '../../../../utils/helper';
import BulkUploadModal from '../CurrencyContainer/bulkUploadModal';
import Loader from '../../../common/loader';

const Kfp = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { formValidation, handleValidation, MAX_FILE_SIZE } = helper;
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState({});
  const [uomList, setUomList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialFormData = {
    kpCategoryId: '',
    uomId: '',
    code: '',
    description: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [sortData, setSortData] = useState({
    sortProperty: 'KpCategory',
    isDescending: false
  });
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);

  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const reloadTable = (page, size) => {
    setLoading(true);
    getKfp({
      page: page || currentpage,
      pageSize: size || pageSize,
      search: searchText,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending
    }).then((res) => {
      setLoading(false);
      if (res.data) {
        setTableData(res.data);
        if (setCount) {
          setCount(res?.data?.totalItems || 0);
        }
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);
  useEffect(() => {
    getUomList({
      page: 1,
      pageSize: 100
    }).then((uom) => {
      if (uom.data) {
        setUomList(uom.data.items);
      }
    });
  }, []);

  const editData = (data) => {
    setFormData(data);
    setOpenModal(true);
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };
  const deleteApiCall = () => {
    deleteKfp({ id: formData.id }).then((res) => {
      if (res.success) {
        setLoading(false);
        showAlert('Deleted Successfully', 'success');
        setFormData(initialFormData);
        reloadTable();
        setOpenDeleteModal(false);
      }
    });
  };
  const saveApiCall = () => {
    if (formValidation(['kpCategoryId', 'uomId', 'code', 'description'], formData)) {
      saveKfp(formData).then((res) => {
        if (res.success) {
          reloadTable();
          setFormData(initialFormData);
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }

          setOpenModal(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const handleChange = (e, isSelect, field) => {
    const kfpData = { ...formData };
    if (isSelect) {
      kfpData[isSelect] = e.value;
    } else {
      kfpData[e.target.name] = e.target.value;
    }
    setFormData(kfpData);
    handleValidation(['kpCategoryId', 'uomId', 'code', 'description'], kfpData, field);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const onSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };

  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadKFP(body).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };

  useEffect(() => {
    reloadTable();
  }, [sortData]);

  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };
  const handleKfpExcelExport = () => {
    if (tableData?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelKfp(searchText);
    }
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable();
    }
  }, [searchText]);
  return (
    <div data-testid="kfp">
      {loading ? <Loader /> : (
        <div className="card p-5 mb-4 height-fix">
          <div className="d-flex mb-3 justify-content-between">
            <div className="d-flex col-gap-1 spec-search">
              <div className="search large">
                <div className="d-flex justify-content-end">
                  <span
                    className={searchText ? ' icon-close-line ' : ''}
                    data-test="click1"
                    onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                  />
                  <span className="icon-search " data-test="click2" onClick={() => { onSearch({ key: 'Enter' }); }} />
                </div>

                <input
                  type="text"
                  id="search"
                  value={searchText}
                  className="text-box large with-close"
                  placeholder="Search by code"
                  onKeyPress={onSearch}
                  onChange={onSearchText}
                />
              </div>
              <button
                type="button"
                className="primary-btn large"
                id="add-kfp"
                onClick={() => {
                  setOpenModal(true);
                  setFormData(initialFormData);
                }}
              >
                Add KFP
              </button>
              <button
                type="button"
                id="add-name"
                className="secondary-btn large"
                onClick={() => {
                  setUploadModal(true);
                }}
              >
                Bulk Upload
              </button>
              {uploadModal && (
              <ModalBox
                modalClassName="medium"
                heading="Upload Excel"
                onSave={() => { bulkUpload(); }}
                closeModal={() => onCloseModal()}
                buttonName="Upload"
                saveDisable={isUpload}
              >
                <BulkUploadModal
                  file={file}
                  ondrop={ondrop}
                  setFile={setFile}
                  generatTemplateForExcel={generatTemplateForExcel}
                  name="KFP"
                  setIsUpload={setIsUpload}
                />
              </ModalBox>
              )}
            </div>
            <div className="d-flex col-gap-1">
              <button type="button" id="excel" className="link-btn large " onClick={handleKfpExcelExport}>
                <span className="icon-download me-4" />
                Excel Download
              </button>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div>
              <KfpTable
                tableData={tableData}
                deteleData={deteleData}
                editData={editData}
                onSearch={onSearch}
                handleChange={handleChange}
                handleSort={handleSort}
                sortData={sortData}
                loading={loading}
              />
              {tableData?.totalItems > 10 && (
                <Pagination
                  pageClick={pageClick}
                  pageCount={tableData.totalPages}
                  currentpage={currentpage}
                  onSizeChange={onSizeChange}
                  size={pageSize}
                />
              )}
            </div>
          )}
          {openModal ? (
            <ModalBox
              modalClassName="medium"
              heading={formData.id ? 'Update Key Formula Parameters' : 'Add Key Formula Parameters'}
              onSave={() => saveApiCall()}
              closeModal={() => setOpenModal(false)}
              buttonName="Save"
            >
              <AddKfp
                uomList={uomList}
                formData={formData}
                handleChange={handleChange}
              />
            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete KFP ?</p>
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      )}

    </div>
  );
};
export default Kfp;
