import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const SpecCard = (props) => {
  const { gtSpecData, selectedSpec, onSpecSelect, onAccordianClose,
    closedSection, onSearch, searchText, setSearchText } = props;
  return (
    <div className="border md-radius dependent-height" data-testid="spec-card">
      <div className="d-flex col-gap-1 border-down p-3">
        <div className="value mt-2">Specifications</div>
        <div className="search small">
          <span
            id="search-click"
            className="icon-search"
            onClick={() => { onSearch({ key: 'Enter' }); }}
          />
          <input
            value={searchText}
            type="text"
            id="search"
            className="text-box medium"
            placeholder="Search by Specification"
            onKeyPress={onSearch}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="scrollable-modal-spec p-3">
        {gtSpecData && gtSpecData?.length > 0 ? (
          gtSpecData?.map((item) => {
            return (
              <div key={item.id}>
                <div className="collapse-head d-flex justify-content-between mt-1">
                  <div
                    className="d-flex align-items-center pointer"
                    data-testid="close"
                    id={item.id}
                    onClick={() => { onAccordianClose(item.id); }}
                  >
                    {closedSection.indexOf(item.id) < 0
                      ? <span className="icon-arrow-thin-right font-16 me-3" />
                      : <span className="icon-arrow-thin-down font-10 me-3" />}
                    <span
                      className="count me-2"
                      data-tooltip-id="project-specCard"
                      data-tooltip-content={item.specificationNo}
                    >
                      {_.truncate(item.specificationNo, { length: 8 })}

                    </span>
                    <span
                      data-tooltip-id="project-specCard"
                      data-tooltip-content={item.title}
                    >
                      {_.truncate(item.title, { length: 25 })}

                    </span>
                  </div>
                </div>
                {closedSection.indexOf(item.id) > -1
                  && (
                    <div className="collapse-body">
                      {
                        item?.specifications?.map((field) => {
                          return (

                            field?.isMapped === false && (
                              <div
                                key={field.id}
                                className={field.id === selectedSpec.id
                                  ? 'spec-item active pointer'
                                  : 'spec-item pointer'}
                                id={field.id}
                                onClick={() => { onSpecSelect(field, item?.id); }}
                              >
                                <span className="count me-2">{field?.specificationNo}</span>
                                <span
                                  data-tooltip-id="project-specCard"
                                  data-tooltip-content={field.title}
                                >
                                  {_.truncate(field.title, { length: 55 })}
                                </span>
                              </div>
                            )
                          );
                        })
                      }
                    </div>
                  )}
              </div>
            );
          })
        ) : <div className="d-flex justify-content-center pt-5">No data found</div>}
      </div>
      <Tooltip id="project-specCard" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default SpecCard;
