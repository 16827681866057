/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
// import ContactRole from './ContactRole/contactRole';
import DocumentCategory from './documentCategory';
import JobAssigned from './JobAssigned';
import TypeContact from './TypeContact';
import TypeProject from './TypeProject';
import TypeSurvey from './TypeSurvey';
import TypeVesselCategory from './TypeVesselCategory';
import FuelType from './FuelType';

const GeneralConfig = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const [updatecategory, setUpdateCategory] = useState(true);
  useEffect(() => {
    if (setCount) {
      setCount('');
    }
  }, []);
  return (
    <div className="card p-5 mb-4 height-fix " data-testid="general-config">
      <div className="scroll-wrap">
        <div className="d-flex flex-wrap col-gap-2">
          <TypeSurvey />
          <TypeProject />
          <TypeContact updatecategory={updatecategory} />
          {/* <ContactRole updatecategory={updatecategory} setUpdateCategory={setUpdateCategory} /> */}
          <JobAssigned />
          <TypeVesselCategory />
          <DocumentCategory />
          <FuelType />
        </div>
      </div>
    </div>
  );
};

export default GeneralConfig;
