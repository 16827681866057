import React, { useState } from 'react';
import _ from 'lodash';
import { Tooltip } from 'react-tooltip';

const RepairInvoice = (props) => {
  const { specList, data } = props;
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [expand, setExpand] = useState(false);

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  return (
    <div className="border md-radius" data-testid="repair_invoice">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer'}
            onClick={() => { setExpand(!expand); }}
            id="expand"
          />
        </div>
      </div>
      {expand
      && (
      <div className="spc-map me-2 col-12" data-testid="sections">
        <div className="d-flex border-down align-items-center mb-2 ps-3 py-3">
          <div className="col-12 head-14 font-semibold">Spec Sections</div>
          {/* <div className="col-2 head-14 font-semibold d-flex pe-3">UOM</div> */}
        </div>
        {
            specList?.map((sec) => {
              return (
                <div className={(sec.id === mainSec && showsubSec) ? 'highlight-tende' : ''}>
                  <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                    <div className="col-1 d-flex align-items-center">
                      <span
                        id={sec.id}
                        className={sec.id === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                        onClick={() => { onSecOpen(sec.id, true); }}
                      />
                      <Tooltip id="repa" place="top" className="tooltip mt-3" />
                      <span
                        data-tooltip-id="repa"
                        data-tooltip-content={sec.number}
                      >
                        {_.truncate(sec.number, { length: 4 })}
                      </span>
                    </div>
                    <div
                      className="col-11"
                    >
                      <span
                        data-tooltip-id="project-repairname"
                        data-tooltip-content={sec.specName}
                      >
                        {_.truncate(sec.specName, { length: 30 })}
                      </span>

                    </div>
                    <Tooltip id="project-repairname" place="top" className="tooltip mt-3" />
                    {/* <div className="col-2 d-flex pe-2">
                    {formatNumber(sec.yardInvoice) || '-'}</div> */}
                  </div>
                  {
                  sec.id === mainSec && sec.specs.map((subsec) => {
                    return (
                      <>
                        <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                          <div className="col-1" />
                          <div className="col-11 d-flex align-items-center">
                            <span
                              id={subsec.id}
                              className={subsec.id === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                              onClick={() => { onSecOpen(subsec.id, false); }}
                            />
                            <span className="me-4">{subsec.number}</span>
                            <Tooltip id="project-repairsubname" place="top" className="tooltip mt-3" />
                            <div
                              className=""
                              data-tooltip-id="project-repairsubname"
                              data-tooltip-content={subsec.specName}
                            >
                              {_.truncate(subsec.specName, { length: 30 })}

                            </div>
                          </div>
                          {/* <div className="col-2 d-flex justify-content-end pe-2">
                          {formatNumber(subsec.yardInvoice) || '-'}</div> */}
                        </div>
                        {
                          subsec.id === showsubSec
                           && subsec.serviceLines.map((serv) => {
                             return (
                               <>
                                 <div className="d-flex border-down py-2 ps-3 h-260">
                                   <div className="col-1" />
                                   <div className="ps-5 col-11 d-flex align-items-center">
                                     <span className="me-4">{serv.number}</span>
                                     <Tooltip id="project-repairsername" place="top" className="tooltip mt-3" />
                                     <div
                                       className=""
                                       data-tooltip-id="project-repairsername"
                                       data-tooltip-content={serv.specName}
                                     >
                                       {_.truncate(serv.specName, { length: 30 })}

                                     </div>
                                   </div>
                                   {/* <div className="col-2 d-flex pe-2">{serv.unit}</div> */}
                                 </div>
                               </>
                             );
                           })
                          }
                      </>
                    );
                  })
                }
                </div>
              );
            })
          }
      </div>
      )}
    </div>
  );
};
export default RepairInvoice;
