import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import SidePanel from '../../../common/sidePanel';
import image2 from '../../../../images/user-placeholder.jpg';

const ViewDetails = (props) => {
  const { data, onClose, showView, onEditClick } = props;
  const getVessels = (item) => {
    let list = [];
    if (item && item?.vessels) {
      list = item.vessels.map((v) => {
        return v.name;
      });
    }
    return list.join(', ');
  };
  const getFleets = (item) => {
    let list = [];
    if (item && item?.fleets) {
      list = item.fleets.map((v) => {
        return v.name;
      });
    }
    return list.join(', ');
  };
  return (
    <SidePanel data-testid="view-details" title="User Details" onClose={onClose} showView={showView}>
      {showView && (
        <div className="row mt-3" data-testid="view-details">
          <div className="col-3 d-flex justify-content-center">
            <img src={data?.profileImage || image2} className="thmb-xlg" alt="" />
          </div>
          <div className="col-9 font-16">
            <div className="d-flex justify-content-between">
              <div className="d-flex head-16 text-capitalize mb-1 break-all">
                <div>
                  <span className="text-ellipsis pe-2" data-tooltip-id="view-details" data-tooltip-content={`${data.firstName}`}>
                    {_.truncate(data?.firstName, {
                      length: 25,
                      separator: ' '
                    })}
                  </span>
                </div>

                <span className="text-ellipsis pe-2" data-tooltip-id="view-details" data-tooltip-content={`${data.middleName}`}>
                  {_.truncate(data?.middleName, {
                    length: 15,
                    separator: ' '
                  })}
                </span>
                <span className="text-ellipsis pe-2" data-tooltip-id="view-details" data-tooltip-content={`${data.lastName}`}>
                  {_.truncate(data?.lastName, {
                    length: 15,
                    separator: ' '
                  })}
                </span>
              </div>
              <button className="primary-btn" type="button" disabled={data.isDisabled} onClick={onEditClick}>
                Edit
              </button>
            </div>
            <div className="mt-3 d-flex flex-column flex-wrap">
              {data?.isLocalUser ? (
                ''
              ) : (
                <>
                  <span className="mb-3 d-flex" data-tooltip-id="view-details" data-tooltip-content={`${data.email}`}>
                    <span className="icon-email font-22 me-3" />
                    {_.truncate(data?.email, {
                      length: 30,
                      separator: ' '
                    })}
                  </span>
                  <span className="mb-3 d-flex">
                    <span className="icon-cell-phone font-26 me-4" />
                    {data?.mobile}
                  </span>
                  <span className="mb-3  d-flex">
                    <span className="icon-phone font-20 me-3" />
                    {data?.phone}
                  </span>
                </>
              )}
              <div>
                <span className="mb-3 d-flex" data-tooltip-id="view-pr" data-tooltip-content={`${data.userName}`}>
                  <span className="me-3 head-14">Username : </span>
                  {_.truncate(data?.userName, {
                    length: 30,
                    separator: ' '
                  })}
                </span>
              </div>
            </div>
            {data?.isAdmin ? (
              ''
            ) : (
              <div>
                <h4 className="mt-5 mb-3  head-16">Management &amp; Role</h4>
                <div className="mt-4">
                  {data.roleData.map((item) => {
                    return (
                      <div key={item?.managementId} className="skywhite-bg p-4 my-3 md-radius relative">
                        <div className="text-capitalize head-14 pb-2">
                          <span className="text-ellipsis" data-tooltip-id="view-pr" data-tooltip-content={item?.management}>
                            {item?.management}
                          </span>
                        </div>
                        <div className="d-flex">
                          <label className="pe-2 head-14">
                            Role
                            {' '}
                            :
                            {' '}
                          </label>
                          <div className="text-capitalize pb-1">
                            {item.role}
                          </div>
                          {data?.isVesselUser && !data?.isLocalUser ? <div className="">(Vessel Reviewer)</div> : ''}
                        </div>
                        {item.role?.toLowerCase().includes('fleet') || item.role?.toLowerCase()?.includes('director') ? (
                          <div className="mt-2">
                            <label className="pe-2 head-14">Fleets: </label>
                            <span className="text-capitalize chip me-2">{getFleets(item) || '-'}</span>
                          </div>
                        ) : (
                          <div className="mt-2">
                            <label className="pe-2 head-14">
                              {data?.isLocalUser ? 'Vessel' : 'Vessels'}
                              {' '}
                              :
                              {' '}
                            </label>
                            <span className="text-capitalize chip me-2">{getVessels(item) || '-'}</span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <Tooltip id="view-pr" place="top" className="tooltip mt-3" />
          <Tooltip id="view-details" place="top" className="tooltip" />
        </div>
      )}
    </SidePanel>
  );
};
export default ViewDetails;
