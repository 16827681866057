/* istanbul ignore file */
import { serviceHandler } from '../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../utils/apiUrlConstants';

const {
  GetSectionsByProject,
  SaveSupplementorySpec,
  GetSupplementorySpeList,
  DeleteSupplementorySpec,
  SubmitSpecsForApproval,
  GetSectionsByProjectVariation,
  SaveSupplementorySpecVariation,
  GetSupplementorySpeListVariation,
  DeleteVariations,
  VariationPreview,
  SendVariationPreview,
  VariationQuoteList,
  UpdateVariationStatus,
  ImportReceivedQuote,
  SaveWatchSatus,
  GetReceivedNotificationCount,
  GetSupplementarySpec,
  GetReceivedQuoteYardList,
  updateSupplementaryStatus,
  supplementaryReceivedQuotes,
  importReceivedQuoteSupplementary

} = apiEndPoints;

export const saveSpec = (payload) => {
  return serviceHandler({
    url: `${SaveSupplementorySpec}`,
    methordType: 'post',
    payload
  });
};
export const getSpecListById = (projectId) => {
  return serviceHandler({
    url: `${GetSupplementorySpeList}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const getSectionById = (projectId) => {
  return serviceHandler({
    url: `${GetSectionsByProject}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const DeleteProjectSpec = (specId) => {
  return serviceHandler({
    url: `${DeleteSupplementorySpec}?specId=${specId}`,
    methordType: 'delete'
  });
};
export const submitSpecsApproval = (payload) => {
  return serviceHandler({
    url: `${SubmitSpecsForApproval}`,
    methordType: 'post',
    payload
  });
};
export const getSectionByIdVariation = (projectId) => {
  return serviceHandler({
    url: `${GetSectionsByProjectVariation}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const saveSpecVariation = (payload) => {
  return serviceHandler({
    url: `${SaveSupplementorySpecVariation}`,
    methordType: 'post',
    payload
  });
};
export const getSpecListByIdVariation = (projectId) => {
  return serviceHandler({
    url: `${GetSupplementorySpeListVariation}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const getVariationQuoteList = (projectId) => {
  return serviceHandler({
    url: `${VariationQuoteList}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const DeleteVariation = (specId) => {
  return serviceHandler({
    url: `${DeleteVariations}?specId=${specId}`,
    methordType: 'delete'
  });
};
export const VariationPreviewMail = (projectId) => {
  return serviceHandler({
    url: `${VariationPreview}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const variationPreviewSend = (payload) => {
  return serviceHandler({
    url: `${SendVariationPreview}?projectId=${payload.projectId}`,
    methordType: 'post',
    payload
  });
};
export const UpdateVarStatus = (payload) => {
  return serviceHandler({
    url: `${UpdateVariationStatus}`,
    methordType: 'post',
    payload
  });
};
export const importYardQuoteDatas = (id, payload) => {
  return serviceHandler({
    url: `${ImportReceivedQuote}?projectId=${id}`,
    methordType: 'post',
    payload
  });
};
export const getNotification = (id, payload) => {
  return serviceHandler({
    url: `${SaveWatchSatus}?projectId=${id}`,
    methordType: 'post',
    payload
  });
};
export const notificationCount = (projectId) => {
  return serviceHandler({
    url: `${GetReceivedNotificationCount}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const supplemetarySpecLists = (payload) => {
  return serviceHandler({
    url: `${GetSupplementarySpec}?projectId=${payload.projectId}&supplementorySpecStatus=${payload.status}`,
    methordType: 'get'
  });
};
export const getReceivedQuoteYardList = (projectId) => {
  return serviceHandler({
    url: `${GetReceivedQuoteYardList}?projectId=${projectId}`,
    methordType: 'get'
  });
};
export const UpdateSupplementaryStatus = (payload) => {
  return serviceHandler({
    url: `${updateSupplementaryStatus}`,
    methordType: 'post',
    payload
  });
};
export const getSupplementaryReceivedQuotes = (payload) => {
  return serviceHandler({
    url: `${supplementaryReceivedQuotes}?projectId=${payload.projectId}&yardId=${payload.yardId}`,
    methordType: 'get'
  });
};
export const ImportReceivedQuoteSupplementary = (id, payload) => {
  return serviceHandler({
    url: `${importReceivedQuoteSupplementary}?projectId=${id}`,
    methordType: 'post',
    payload
  });
};
