import React from 'react';

const EmptySpecSelected = ({ viewSpec }) => {
  return (
    <div className="col-8 d-flex">
      <div className="card p-5 flex-grow-1">
        <div className="d-flex justify-content-center text-center flex-column mt-110 right-content-wrap">
          <div className="select-ind" />
          {viewSpec
            ? <div>Please select a specification to view the details</div>
            : <div>Please select a non spec item to view the details</div>}
        </div>
      </div>
    </div>
  );
};
export default EmptySpecSelected;
