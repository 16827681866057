import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment';
import Pagination from '../../../../common/pagination';

const SofReportComponent = ({ tableData, setPage, setPageSize, page, pageSize,
  downloadReport }) => {
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  return (
    <div data-testid="SofReportComponent">
      {tableData && tableData.sofDataList && tableData.sofDataList.items?.length ? (
        <div className="p-4">
          <div className="border-down row py-3">
            <div className="col-10 font-semibold">Statement</div>
            <div className="col-2 font-semibold">Date & Time</div>
          </div>
          <div className="scroll-wrap">
            {tableData.sofDataList.items.map((i) => {
              return (
                <div className="border-down row py-3">
                  <div className="col-10">
                    <div className="text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i.statement}>
                        {i.statement}
                      </span>

                    </div>
                  </div>
                  <div className="col-2">
                    {/* {i.updatedAt} */}
                    {/* <p>{moment(new Date(i.updatedAt)).format('DD-MMM-YYYY')}</p> */}
                    {moment.utc(i.updatedAt).format('DD-MMM-YYYY HH:mm')}
                    {' '}
                    (UTC)
                  </div>
                </div>
              );
            })}
          </div>
          {tableData?.sofDataList?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.sofDataList?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-5">
              <div className="footer-sof">© 2024 ThinkPalm</div>

            </div>
            <button
              onClick={() => downloadReport()}
              type="button"
              className="primary-btn large mx-3"
            >
              Download Report
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="card d-flex fixed-footer justify-content-center">
            <div className="text-black font-12">
              <div className="footer-sof">© 2024 ThinkPalm</div>
            </div>
          </div>
        </>
      )}
      <Tooltip id="project-job" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default SofReportComponent;
