/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
export const HandleErrors = (existingPassword, newPassword, confirmPassword, setErrors) => {
  if (!existingPassword) {
    setErrors({ existingPassword: 'Please Enter the existing Password' });
  } else if (existingPassword === newPassword) {
    setErrors({ newPassword: 'Password must be different from existing' });
  } else if (!newPassword) {
    setErrors({ newPassword: 'Please Enter the New Password' });
  } else if (
    newPassword.length < 8
    || newPassword.search(/[0-9]/) === -1
    || (newPassword.search(/[A-Z]/) === -1 && newPassword.search(/[a-z]/) === -1)
    || newPassword.search(/[!\@\#\$\%\^\&\()\_\:\.]/) === -1
  ) {
    setErrors({
      newPassword: 'Password must be at least 8 characters, include a number, a letter and a symbol.'
    });
  } else if (!confirmPassword) {
    setErrors({ confirmPassword: 'Please Enter the Confirm Password' });
  } else if (newPassword === confirmPassword) {
    if (newPassword.length < 8) {
      setErrors({ newPassword: 'Password must be at least 8 characters, include a number, a letter and a symbol.' });
    } else if (confirmPassword.length < 8) {
      setErrors({ confirmPassword: 'Password must be at least 8 characters, include a number, a letter and a symbol.' });
    }
  } else if (newPassword && !confirmPassword) {
    setErrors({ confirmPassword: 'Please Enter confirm password' });
  } else if (!newPassword && confirmPassword) {
    setErrors({ newPassword: 'Please Enter New Password' });
  } else if (newPassword !== confirmPassword) {
    setErrors({ newPassword: 'Password does not match' });
  } else {
    return '';
  }
  return null;
};
