/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../../common/pagination';

const JobProgressReportComponent = ({ tableData, getColor, downloadReport,
  setPage, setPageSize, page, pageSize }) => {
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  return (
    <div className="p-4" data-testid="JobProgressReportComponent">
      {tableData && tableData.specList && tableData.specList.items?.length ? (
        <>
          <div>
            <div className="border-down row py-3">
              <div className="col-6 font-semibold p-0">Job Details</div>
              <div className="col-2 font-semibold p-0">Status</div>
              <div className="col-2 font-semibold p-0">Staff Role</div>
              <div className="col-2 font-semibold p-0">Assigned To</div>
            </div>
            <div className="scroll-wrap">
              {tableData.specList.items.map((i) => {
                return (
                  <div className="border-down row py-3">
                    <div className="col-6 ">
                      <div className="d-flex rw-1">
                        <div className="">{i.specificationNo}</div>
                        <div className="px-2">-</div>
                        <div className="text-ellipsis " data-tooltip-id="project-job" data-tooltip-content={i.title}>
                          {i.title}
                        </div>
                      </div>
                    </div>
                    <div className="col-2 ">
                      <div className="d-flex align-items-center">
                        <span className="icon-disc" style={{ color: getColor(i.jobStatusName) }} />
                        <div className="ms-2 opacity-6">{i.jobStatusName}</div>
                      </div>
                    </div>
                    <div className="col-2 text-ellipsis " data-tooltip-id="project-job" data-tooltip-content={i.specAssignedStaffName || ''}>
                      {i.specAssignedStaffName || ''}
                    </div>
                    <div className="col-2 text-ellipsis " data-tooltip-id="project-job" data-tooltip-content={i.specAssignedName || ''}>
                      {i.specAssignedName || ''}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {tableData?.specList?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.specList?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className="footer-jobprogress">© 2024 ThinkPalm</div>

            </div>
            <button
              onClick={() => downloadReport()}
              type="button"
              className="primary-btn large mx-3"
            >
              Download Report
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="fixed-footer card d-flex justify-content-end">

            <div className="text-black font-12 col-6">
              <div className="mt-2 mb-2">© 2024 ThinkPalm</div>

            </div>
          </div>
        </>
      )}
      <Tooltip id="project-job" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default JobProgressReportComponent;
