/*eslint-disable*/
/* istanbul ignore file */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../common/loader';
import SidePanel from '../../common/sidePanel';
import { SaveYardNotification, getSnoozeNotification, getYardNotification } from '../Projects/services';
import helper from '../../../utils/helper';

const Notification = (props) => {
  const { setShowNotificationPanel, getAll, fromProjectPhaseHeader, showNotificationPanel, notificationData, setNotificationData } = props;
  const navigate = useNavigate();
  const [snooze, setSnooze] = useState([]);
  const [loading, setLoading] = useState(false);
  // const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  // const isAdmin = userRole === roles.ADMIN;
  // console.log(userRole, 'userRole');
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isViewer = userRole === roles.VIEWER;
  // const [clickApprovalNotification, setClickApprovalNotification] = useState(false);
  /* istanbul ignore next */
  const handleViewMore = (index) => {
    const a = notificationData?.notifications?.map((item) => item)[index];
    localStorage.setItem('fromNotification', true);
    navigate(`/user/project/${a?.projectId}/tender?vesselId=${a?.vesselId}`);

    if (fromProjectPhaseHeader) {
      setShowNotificationPanel(false);
    }

    SaveYardNotification({
      id: a?.id,
      status: 2,
      notification: a.notification,
      projectRfqId: a.projectRfqId,
    }).then((res) => {
      if (res.success) {
        getAll();
      }
    });
  };
  const handleClickApproval = (index) =>{
    // setClickApprovalNotification()
    const a = notificationData?.notifications?.map((item) => item)[index];
    SaveYardNotification({
      id: a?.id,
      status: 2,
      notification: a.notification,
      projectRfqId: a.projectRfqId,
    }).then((res) => {
      if (res.success) {
        getAll();
      }
    });
  }
  useEffect(() => {
    reload();
  }, []);
  const reload = () => {
    getYardNotification().then((res) => {
      console.log('1');
      if (res.success) {
        setNotificationData(res.data);
        setLoading(false);
      }
    });
  };

  // const handleCreate = (index) => {
  //   const a = notificationData?.notifications?.map((item) => item)[index];
  //   localStorage.setItem('fromNotification', true);
  //   navigate(`/user/projects/create?vesselId=${a?.vesselId}`);
  // };
  const handleCreate = (index) => {
    const a = notificationData?.notifications?.map((item) => item)[index];
    localStorage.setItem('fromNotification', true);
    navigate(`/user/projects/create?vesselId=${a?.vesselId}`, { replace: true });
    setShowNotificationPanel(false);
  };
  const getSnooze = (index) => {
    setLoading(true);
    const snoozeid = notificationData?.notifications?.map((item) => item.id)[index];
    // console.log(snoozeid, 'snooze');
    getSnoozeNotification({ id: snoozeid }).then((res) => {
      setSnooze(res.data);
      reload();
      setLoading(false);
    });
  };
 

  return (
    <div data-testid="notification">
      <SidePanel title="Notification" id="notification" showView={showNotificationPanel} onClose={() => setShowNotificationPanel(false)}>
        {loading ? (
          <Loader />
        ) : (
          <>
          {notificationData?.notifications?.length === 0 && (
             <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
             <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
             <div className="font-18 animate__animated animate__headShake">
               No data found
             </div>
           </div>
          )}
          <ul className='notification-list'>
            {notificationData?.notifications?.map((items, index) => {
              console.log(items, 'items');
              return (
                
                  <li className="d-flex justify-content-between">
                    <div className="d-flex justify-content-between">
                      <div className="circle-ico-btn relative icon-disable ">
                        <span className="icon-notification-on brand-color" />
                        <span className="dot-indicator" />
                      </div>
                      <div>
                      <span onClick={() => handleClickApproval(index)}>
                        <div className="ps-5 head-14 pb-1 cursor-pointer">
                          <span data-tooltip-id="project-notification" data-tooltip-content={items?.notification}>
                            {_.truncate(items?.notification, { length: 50 })}
                          </span>
                          <span className={items?.status === 1 ? 'dot-indicator-notification-bell' : ''} />
                        </div>
                       
                        <div className="ps-5 font-12 pb-2 dot-indicator">{items?.vesselName}</div>
                        <div className="ps-5 font-12  dot-indicator">
                        
                          {moment(items?.createdAt.split('.')[0]).format('DD-MMM-YYYY HH:mm')}
                         
                          <span className="ps-1">(UTC)</span>
                        </div>
                        </span>
                        
                      </div>
                    </div>
                    {items.type === 1 && items.priority === 1 ? (
                      <div className="d-flex mt-5"> 
                        { !isViewer ? (
                          <>
                            <button type="button" id="view-more" className="brand-color mb-4 mt-2 cursor-pointer" onClick={() => getSnooze(index)}>
                              Snooze
                            </button>
                            <button
                              type="button"
                              id="view-more"
                              className="primary-btn ms-2 cursor-pointer"
                              onClick={() => {
                                localStorage.setItem('createProjectVesselId', items.vesselId);
                                handleCreate(index);
                              }}
                            >
                              Create Project
                            </button>
                          </>
                        ):(
                          ''
                        )}
                      </div>
                    ) : (
                      items.type === 2 ?  <div className="d-flex mt-5">
                      <button type="button" id="view-more" className="secondary-btn " onClick={() => handleViewMore(index)}>
                        View More
                      </button>
                    </div> : ''
                     
                    )}
                  </li>
                
              );
            })}
            </ul>
          </>
        )}
        <Tooltip id="project-notification" place="top" className="tooltip mt-3" />
      </SidePanel>
    </div>
  );
};
export default Notification;
