/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import AreaLoader from '../../../common/areaLoader';
import CreateFolder from './CreateFolder';

const GallryLeftPanel = (props) => {
  const {
    folderList,
    setActive,
    active,
    folderType,
    setFolderActiveData,
    setOpenChildFolder,
    isAddNewShow,
    setIsAddNewShow,
    handleCreateNew,
    cancel,
    formData,
    setFormData,
    handleRename,
    handleDelete,
    loading,
    galleryFolderList,
    getChildData,
    isReport,
    newLoading,
    disableBtn
  } = props;
  return (
    <div className="" data-testid="image-left-bar">
      <div className="tab-set d-flex">
        <div
          className={`half-width ${folderType === 1 && 'active'}`}
          onClick={() => {
            galleryFolderList(1);
          }}
          id="clickfolder"
          data-testid="click1"
        >
          Spec Files
        </div>
        <div
          className={`half-width ${folderType === 2 && 'active'}`}
          onClick={() => {
            galleryFolderList(2);
          }}
          id="click2"
          data-testid="click2"
        >
          Custom Files
        </div>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <AreaLoader />
        </div>
      ) : (
        <div className="relative">
          {folderType === 2 && !isReport ? (
            <div className="d-flex justify-content-end pb-4">
              <button className="link-btn" type="button" disabled={disableBtn?.isClosed} data-testid="addNewClick" onClick={() => setIsAddNewShow(true)}>
                <span className="font-20 icon-add-folder me-2 " />
                Create New Folder
              </button>
            </div>
          ) : (
            ''
          )}
          {folderType === 2 && isAddNewShow ? (
            <CreateFolder
              handleRename={handleRename}
              handleDelete={handleDelete}
              handleCreateNew={handleCreateNew}
              cancel={cancel}
              formData={formData}
              setFormData={setFormData}
              newLoading={newLoading}
            />
          ) : (
            ''
          )}
          {folderType === 1 ? (
            <>
              {folderList?.map((item) => {
                return (
                  <div className={active === item.specId ? 'folder-list active' : 'folder-list'}>
                    <div
                      className="text-ellipsis folder cursor-pointer py-2 col-11"
                      onClick={() => {
                        setActive(item.specId);
                        setFolderActiveData(item);
                        setOpenChildFolder('');
                      }}
                      id="click3"
                      // data-testid="click3"
                    >
                      {active === item.specId ? (
                        <span className="font-26 icon-folder-open me-2 icon-ind" />
                      ) : (
                        <span className="font-26 icon-folder me-2 icon-ind" />
                      )}
                      <span data-tooltip-id="project-system" data-tooltip-content={item.specName} className="ms-3 text-ellipsis">
                        <span className="pe-3 ">{item.specNumber}</span>
                        {item.specName}
                      </span>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {folderList?.map((item) => {
                if (item && item?.id === formData?.id && item?.isFolder) {
                  return (
                    <CreateFolder
                      handleCreateNew={handleCreateNew}
                      cancel={cancel}
                      formData={formData}
                      setFormData={setFormData}
                      newLoading={newLoading}
                    />
                  );
                }
                if (item && item?.isFolder) {
                  return (
                    <div className={active === item.id ? 'folder-list active' : 'folder-list'}>
                      <div className="row">
                        <div
                          className="text-ellipsis folder cursor-pointer py-2 col-11"
                          onClick={() => {
                            getChildData(item);
                          }}
                          id="click3"
                          data-testid="click3"
                        >
                          {active === item.id ? (
                            <span className="font-26 icon-folder-open me-2 icon-ind" />
                          ) : (
                            <span className="font-26 icon-folder me-2 icon-ind" />
                          )}
                          <span data-tooltip-id="project-system" data-tooltip-content={item.imageName} className="ms-3 text-ellipsis">
                            {item.imageName}
                          </span>
                          <span>
                            (
                            {item?.itemCount}
                            )
                          </span>
                        </div>
                        <div className="align-items-center col-1 d-flex justify-content-end">
                          {folderType === 2 && !isReport && !disableBtn?.isClosed ? (
                            <div className="menudropdown mt-2">
                              <span className="dropbtn font-18 icon-dotted-menu-vertical  text-blue cursor-pointer" />
                              <div className="menudropdown-content animate__animated animate__bounceIn md-radius">
                                <a
                                  data-testid="add-custom-rename"
                                  href="#"
                                  onClick={() => {
                                    handleRename(item);
                                  }}
                                >
                                  Rename
                                </a>
                                <a
                                  data-testid="add-custom-delete"
                                  href="#"
                                  onClick={() => {
                                    handleDelete(item);
                                  }}
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                    </div>
                  );
                }
                return '';
              })}
            </>
          )}

        </div>
      )}
      {' '}
      <Tooltip id="project-system" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default GallryLeftPanel;
