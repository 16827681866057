/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';

const Navbar = (props) => {
  const { onSearch, setSearchText, visiblesubSpec, changeVisibilityunmap,
    searchUpdated, showUpload, isShowImport, searchText, handleSearchClose, isVesselUser,
    projectBasicDetails, loading, downloadExcel, purchaseOrder } = props;
  return (
    <div className="doc-shadow  doc-subhead d-flex  align-items-center" data-testid="poha-nav">
      <div className="d-flex col-12 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search">
          <div className="search large width-24">
            <div className="d-flex justify-content-end">
              <span className="icon-search" id="search_click" onClick={() => { onSearch({ key: 'Enter' }); }} />
              <span
                className={searchText ? ' icon-close-line ' : ''}
                id="search_click2"
                onClick={() => { handleSearchClose(searchText); document.getElementById('search')?.focus(); }}
              />
            </div>
            <input
              type="text"
              id="search"
              className="text-box large with-close"
              onKeyPress={onSearch}
              value={searchText}
              placeholder="Search by Type, PR No, PO No, Description"
              onChange={(e) => {
                setSearchText(e.target.value);
                searchUpdated();
              }}
            />
          </div>
          <button type="button" className="primary-btn mt-1 whitespace-pre" disabled={!isShowImport || isVesselUser || projectBasicDetails?.isClosed || loading || projectBasicDetails?.phase > 4} onClick={showUpload}>Import Excel</button>
          <button type="button" className="primary-btn mt-1 whitespace-pre" disabled={loading || purchaseOrder?.items?.length === 0} onClick={downloadExcel}>Download Excel</button>
        </div>
        <div className="d-flex">
          <div className="ms-5 whitespace-pre check-box">
            <input type="checkbox" id="pohamap" name="" checked={visiblesubSpec?.indexOf('0') > -1} onClick={() => { changeVisibilityunmap('0'); }} />
            <label htmlFor="pohamap">Mapped</label>
          </div>
          <div className="ms-5 whitespace-pre check-box">
            <input type="checkbox" id="pohaunmap" name="" checked={visiblesubSpec?.indexOf('1') > -1} onClick={() => { changeVisibilityunmap('1'); }} />
            <label htmlFor="pohaunmap">UnMapped</label>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Navbar;
