import axios from 'axios';
import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';
import helper from '../../../../../utils/helper';

const {
  getMajorSpecsUrl,
  saveMajorSpecsUrl,
  deleteMajorSpecsUrl,
  saveMajorSpecsificationUrl,
  GetUnitOfMeasuresList,
  GetTagsListUrl,
  publishSpecificationDataUrl,
  getSearchResultsUrl,
  GenerateTemplateGenralTariff,
  BulkUploadMajorspec,
  BulkUploadGenralTariff,
  GetDescription,
  SaveDescription,
  ExportExcelSpecificationMaster,
  GetEmptySpecListAdmin,
  DeleteSpecification,
  MajorSpecsSectionList,
  MoveDeferredSpecs
} = apiEndPoints;
const { downloadFile, currentDate } = helper;
export const getMajorSpecs = (payload, type) => {
  return serviceHandler({
    url: `${getMajorSpecsUrl}?parentId=${payload}&type=${type}`,
    methordType: 'get',
    payload
  });
};
export const saveMajorSpecs = (payload) => {
  return serviceHandler({
    url: `${saveMajorSpecsUrl}`,
    methordType: 'post',
    payload
  });
};
export const GetDescriptions = (pSId) => {
  return serviceHandler({
    url: `${GetDescription}?projectSpecificationId=${pSId}`,
    methordType: 'get'
  });
};
export const SaveDescriptions = (payload) => {
  return serviceHandler({
    url: `${SaveDescription}`,
    methordType: 'post',
    payload
  });
};
export const saveMajorSpecsification = (payload) => {
  return serviceHandler({
    url: `${saveMajorSpecsificationUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteMajorSpecs = (payload) => {
  return serviceHandler({
    url: `${deleteMajorSpecsUrl}`,
    methordType: 'delete',
    payload
  });
};
export const getUomList = (payload) => {
  return serviceHandler({
    url: `${GetUnitOfMeasuresList}`,
    methordType: 'post',
    payload
  });
};

export const getTags = (payload) => {
  return serviceHandler({
    url: `${GetTagsListUrl}?search=${payload || ''}`,
    methordType: 'get',
    payload
  });
};

export const publishList = (payload) => {
  return serviceHandler({
    url: `${publishSpecificationDataUrl}?type=${payload}`,
    methordType: 'get',
    payload
  });
};
export const getSearchResults = (payload) => {
  return serviceHandler({
    url: `${getSearchResultsUrl}?search=${encodeURIComponent(payload.search)}&type=${payload.type}`,
    methordType: 'get',
    payload
  });
};
export const exportExcelGeneralTariff = (num) => {
  return axios
    .get(`${ExportExcelSpecificationMaster}?type=${num.type}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      if (num.type === 1) {
        downloadFile(url, `General Tariff_${currentDate()}.xlsx`);
      } else {
        downloadFile(url, `Major Specs_${currentDate()}.xlsx`);
      }
    });
};

export const bulkUploadMajorSpec = (payload) => {
  return serviceHandler({
    url: `${BulkUploadMajorspec}`,
    methordType: 'post',
    payload
  });
};

export const bulkUploadgeneralTarriff = (payload) => {
  return serviceHandler({
    url: `${BulkUploadGenralTariff}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = (payload) => {
  return axios
    .get(`${GenerateTemplateGenralTariff}?type=${payload.type}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      if (payload.type === 1) {
        downloadFile(url, `General Tariff_${currentDate()}.xlsx`);
      } else {
        downloadFile(url, `Major Specification_${currentDate()}.xlsx`);
      }
    });
};
export const getEmptySpecListAdmin = (payload) => {
  return serviceHandler({
    url: `${GetEmptySpecListAdmin}?type=${payload}`,
    methordType: 'get',
    payload
  });
};
export const deleteMajorSpec = (payload) => {
  return serviceHandler({
    url: `${DeleteSpecification}?id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const majorSpecsSectionList = (payload) => {
  return serviceHandler({
    url: `${MajorSpecsSectionList}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const moveDeferredSpecs = (payload) => {
  return serviceHandler({
    url: `${MoveDeferredSpecs}`,
    methordType: 'post',
    payload
  });
};
