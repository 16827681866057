import axios from 'axios';
import { serviceHandler } from '../../../utils/serviceHandler';
import apiEndPoints from '../../../utils/apiUrlConstants';
import helper from '../../../utils/helper';

const {
  ProjectVariationSpecList,
  SaveVariationQuote,

  UpdateQuoteStatusVariation,

  downloadExcelVariation,
  DownloadExcelYard

} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const projectSpecListVariation = (payload) => {
  return serviceHandler({
    url: `${ProjectVariationSpecList}?key=${payload.key}`,
    methordType: 'get',
    payload
  });
};

export const saveQuoteVariation = (payload) => {
  return serviceHandler({
    url: `${SaveVariationQuote}`,
    methordType: 'post',
    payload
  });
};
export const updateQuoteStatusVariation = (payload) => {
  return serviceHandler({
    url: `${UpdateQuoteStatusVariation}`,
    methordType: 'post',
    payload
  });
};

export const downloadExcel = (payload) => {
  return axios
    .get(`${downloadExcelVariation}?key=${payload.key}&isManual=${payload.isManual}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const downloadExcelYard = (payload) => {
  return axios
    .get(`${DownloadExcelYard}?key=${payload.key}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
