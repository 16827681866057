import React from 'react';
import { cloneDeep } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const AddVesselData = ({ formData, setFormData, categoryList }) => {
  const { charValidation, handleValidation } = helper;
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['name', 'categoryId'], fd, field);
  };
  return (
    <div className="modal-content" data-testid="add-vdata">
      <div className="row">
        <div className="col-5">
          <InputType1
            autoFocus
            id="name"
            value={formData.name}
            onChange={(e) => handleChange(e, 'name')}
            className="modal-input"
            label="Vessel Type"
            isMandatory="true"
            noMargin
          />
        </div>
        <div className="col-7">
          <label>
            Vessel Category
            <span className="text-primaryRed-1">*</span>
          </label>
          <Select
            menuPosition="fixed"
            value={categoryList.filter((option) => option.value === formData.categoryId)}
            onChange={(option) => handleChange(option, 'categoryId', 'select')}
            className="modal-select"
            label="Vessel Category"
            options={categoryList}
            id="categoryId"
          />
        </div>
        {/* <div className="col-7">
          <InputType1 id="shortname"
          value={formData.shortName}
          onChange={(e) => handleChange(e, 'shortName')}
          className="modal-input" label="Short Name" isMandatory="true" />
        </div> */}
      </div>
    </div>
  );
};

export default AddVesselData;
