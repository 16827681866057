/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

export const sample = [
  {
    id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    taskName: 'test task',
    priority: 'string',
    priorityId: 1,
    phaseId: 1,
    phase: 'string',
    responsible: 'string',
    responsibleId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    offset: 'string',
    status: 0,
    statusName: 'Due',
    startDate: '',
    endDate: '',
    statusDates: [
      {
        status: 3,
        startDate: '2022-11-03T06:58:40.247Z',
        endDate: '2022-11-05T06:58:40.247Z'
      },
      {
        status: 3,
        startDate: '2022-11-07T06:58:40.247Z',
        endDate: '2022-11-12T06:58:40.247Z'
      }
    ],
    projectType: [
      'string'
    ],
    taskCategoryList: [
      {
        id: '3fa85f64-5717-4562-b3fc-2c963f66afa7',
        taskId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        taskCategoryName: 'subtask',
        offset: '3',
        status: 0,
        statusName: 'Due',
        startDate: '',
        endDate: '',
        statusDates: [
          {
            status: 3,
            startDate: '2022-11-01T06:58:40.247Z',
            endDate: '2022-11-04T06:58:40.247Z'
          }
        ],
        projectType: [
          'string'
        ],
        microTaskList: [
          {
            id: '3fa85f64-5717-4562-b3fc-2c963f67afa9',
            projectId: '3fa85f64-5717-4562-b3fc-2c963f66afa8',
            taskCategoryId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
            microTaskName: 'test micro',
            offset: '10',
            status: 0,
            statusName: 'string',
            startDate: 'string',
            endDate: 'string',
            statusDates: [
              {
                status: 3,
                startDate: '2022-11-03T06:58:40.247Z',
                endDate: '2022-11-06T06:58:40.247Z'
              }
            ]
          }
        ]
      }
    ]
  }
];
export const getMonthList = { Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12
};
export const statusColor = (status) => {
  if (status === 0) {
    return '#16B5F9';
  } if (status === 1) {
    return '#F22C00';
  } if (status === 2) {
    return '#9C59B8';
  } if (status === 3) {
    return '#08B863';
  } if (status === 4) {
    return '#B7B7B7';
  }
  return '#FF9950';
};
export const customDropDown = (changeStatus, data, type, taskId, key, projectDetails) => {
  return (
    <div>
      {!projectDetails?.isClosed && (
        <div className={`dot-dropdown first_child ${key === 0 ? 'z-index-999' : ''}`} key={data.id}>
          <div className="dropdown">
            <button type="button" className="dropbtn">
              <span className="icon-dotted-menu-vertical"> </span>
            </button>
            {data.status !== 3 && (
            <div className="dropdown-content">
              {(data.status === 0 || data.status === 5 || data.status === 4)
            && (
            <a onClick={() => { changeStatus(data, type, taskId, 2); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(0) }} />
              Start
            </a>
            )}
              {(data.status === 2 || data.status === 1)
            && (
            <a onClick={() => { changeStatus(data, type, taskId, 5); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(1) }} />
              Hold
            </a>
            )}
              {(data.status === 2 || data.status === 1)
            && (
            <a onClick={() => { changeStatus(data, type, taskId, 3); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(2) }} />
              Completed
            </a>
            )}
              {(data.status !== 3 && data.status !== 4) && (
              <a onClick={() => { changeStatus(data, type, taskId, 4); }}>
                <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(3) }} />
                N/A
              </a>
              )}
            </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};
