import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { fileImages } from '../../../../common/mappingConst';
import helper from '../../../../../utils/helper';

const Listing = (props) => {
  const { allAttachs, deleteData, isExpand, setIsExpand } = props;
  const { formatBytes } = helper;
  return (
    <div className="table-responsive mb-5" data-testid="listing">
      <table className="card-table">
        <thead>
          <tr>
            <th>Vessel Plans and Drawings</th>
          </tr>
        </thead>
        <tbody>
          {allAttachs && allAttachs?.map((urlData) => {
            return (
              <tr>
                <td>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <span
                        className="head-14 text-ellipsis"
                        data-tooltip-id="project-currentVesselName"
                        data-tooltip-content={urlData.documentCategoryName}
                      >
                        {_.truncate(urlData.documentCategoryName, {
                          length: 50,
                          separator: ' '
                        })}
                      </span>
                      <button
                        type="button"
                        className="ms-3 primary-btn small count-badge"
                      >
                        {urlData?.vesselAttachments?.length}
                      </button>
                    </div>
                    <span />
                    {' '}

                    {/* <button type="button" className="primary-btn small mt-1"> */}
                    {/* {countOfAttach.map((items) => items)} */}
                    {/* <span className=" count-badge font-10" /> */}
                    {/* </button> */}
                    <div className="">
                      {isExpand === urlData.documentCategory ? (
                        <span
                          onClick={() => {
                            setIsExpand('');
                          }}
                          className="font-10 icon-arrow-thin-up cursor-pointer"
                        />
                      ) : (
                        <span
                          onClick={() => {
                            setIsExpand(urlData.documentCategory);
                          }}
                          className="font-10 icon-arrow-thin-down cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                  {isExpand === urlData.documentCategory && (
                  <div className="ps-5 my-3">
                      {urlData.vesselAttachments.map((image) => {
                        return (
                          <div className="ms-4 pt-3 pb-3 pe-3">
                            <div className="d-flex justify-content-between full-width align-items-center">
                              <div className="d-flex col-gap-1">
                                <div className="thmb-icon-md">
                                  <span className={fileImages[_.toLower(image.fileType)]} />
                                  {/* <img src={fileImages[urlData?.fileExtension]}
                                  alt="" style={{ height: 24 }} /> */}
                                </div>
                                <div className="d-flex flex-column justify-content-center">
                                  <a
                                    href={image.physicalFileName}
                                    target="_blank"
                                    download="downloaded_file"
                                    className=""
                                    rel="noreferrer"
                                  >
                                    <span
                                      className="head-14 mb-1 text-ellipsis"
                                      data-tooltip-id="project-currentVesselName"
                                      data-tooltip-content={image.name}
                                    >
                                      {_.truncate(image.name, {
                                        length: 50,
                                        separator: ' '
                                      })}
                                    </span>
                                  </a>
                                  <div className="font-16">
                                    <label className="me-2">Format:</label>
                                    <span className="value">{image.fileType}</span>
                                    <span className="mx-4">|</span>
                                    <label className="me-2"> Size :</label>
                                    <span className="value">{formatBytes(image.fileSize)}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex col-gap-2">
                                <button className="link-btn" type="button">
                                  <a href={image.physicalFileName} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                                    <span className="icon-download font-20" />
                                  </a>
                                </button>
                                <button id="delete" className="link-btn" type="button" onClick={() => { deleteData(image); }}>
                                  <span className="icon-delete font-20" />
                                </button>
                              </div>

                            </div>

                          </div>
                        );
                      })}
                    {' '}

                  </div>
                  )}

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {allAttachs?.length < 1 && <div className="bg-white d-flex justify-content-center align-items-center py-3">No records found</div>}
      {allAttachs && allAttachs.length > 0 && <div className="mt-3 d-flex justify-content-end">{`Total ${allAttachs.length} items`}</div>}
      <Tooltip id="project-currentVesselName" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Listing;
