/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import SidePanel from '../../../common/sidePanel';
import Pagination from '../../../common/pagination';
import Navbar from './navbar';
import helper from '../../../../utils/helper';
import { getPurchaseOrderRequests, getSpecByProjectId, mapPoPrToSpec, importPohaData, getTotalPohaCount, savePohaStatus, exportExcelPOHA } from './services';
import { getAcCode } from '../PhasePage/Specify/SpecsList/ServiceLine/services';
import { getProjectDetailsTSI } from '../NewProject/services';
import showAlert from '../../../../utils/alert';
import CardWrapper from './cardWrapper';
import UploadCard from '../WohaPage/uploadCard';

let isSearchUpdated = false;
const PohaPage = ({ onCloseWindow, openModal, onReload,
  isTsiDisabled, isShowImport, currency, isTSI, projInfo }) => {
  const { generateOptions, getDropDownOptions, roles, ZERO_UUID } = helper;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const params = qs.parse(window.location.search);
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const managementId = localStorage.getItem('managementId');
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const [purchaseOrder, setPurchaseOrder] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [specList, setSpecList] = useState([]);
  const [acCodeList, setAcCodeList] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [visibleSpec, setVisibleSpec] = useState(['0', '1']);
  const [viewUpload, setViewUpload] = useState(false);
  const [file, setFile] = useState('');
  const [count, setCount] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [visiblesubSpec, setVisiblesubSpec] = useState(['0', '1']);
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const { id } = useParams();

  const onReloadSpecData = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectBasicDetails(res.data);
    });
  };

  useEffect(() => {
    onReloadSpecData();
  }, []);

  const showView = openModal === 'poha';
  const changeVisibility = (key) => {
    const visibility = [...visibleSpec];

    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }

    setVisibleSpec(visibility);
  };

  const checkType = () => {
    if (visibleSpec.length === 2) {
      return 3;
    } if (visibleSpec.length === 0) {
      return 0;
    } if (visibleSpec[0] === '0') {
      return 2;
    }
    return 1;
  };

  const changeVisibilityunmap = (key) => {
    const visibility = [...visiblesubSpec];
    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }
    setVisiblesubSpec(visibility);
  };

  const checkTypeunmap = () => {
    if (visiblesubSpec.length === 2) {
      return 0;
    } if (visiblesubSpec.length === 0) {
      return 3;
    } if (visiblesubSpec[0] === '0') {
      return 1;
    }
    return 2;
  };

  const getPurchaseOrder = (p = page) => {
    getPurchaseOrderRequests({
      page: p,
      pageSize,
      vesselId: params.vesselId,
      projectId: id,
      search: isSearchUpdated ? searchText : '',
      MapType: checkTypeunmap(),
      prPoType: checkType()
    }).then((res) => {
      if (res.success) {
        setPurchaseOrder(res.data);
        setLoading(false);
      }
    });
  };

  const totalSpecCount = () => {
    getTotalPohaCount({ id }).then((response) => {
      if (response?.success) {
        setCount(response?.data);
      }
    });
  };

  const getSpecList = () => {
    getSpecByProjectId({ projectId: id }).then((res) => {
      if (res.success) {
        setSpecList(getDropDownOptions(res?.data, 'id', 'specificationNo', 'title'));
      }
    });
  };

  const getAcCodeList = () => {
    getAcCode({
      page: 1,
      pageSize: 1000,
      managementGroupId: managementId || ZERO_UUID }).then((res) => {
      if (res.success) {
        setAcCodeList(generateOptions(res.data.items, 'id', 'name'));
      }
    });
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearchUpdated = true;
      getPurchaseOrder(1);
      setPage(1);
    }
  };

  const onCancel = () => {
    setSelectedCard('');
  };

  const searchUpdated = () => {
    isSearchUpdated = false;
  };

  const saveSpecOrder = () => {
    const newData = { ...selectedCard };
    let isValidSpecMapped = false;
    if (!newData?.specificationList?.length) {
      isValidSpecMapped = true;
    }
    if (newData?.specificationList?.length) {
      newData?.specificationList.forEach((element) => {
        if (element?.specId && element?.specId !== '') {
          isValidSpecMapped = true;
        } else {
          isValidSpecMapped = false;
        }
      });
    }
    if (isValidSpecMapped) {
      mapPoPrToSpec(newData).then((res) => {
        if (res.success) {
          setSelectedCard('');
          showAlert('Successfully Saved', 'success');
          getPurchaseOrder();
        }
      });
    } else if (!isValidSpecMapped) {
      showAlert('Please select valid Spec', 'error');
    }
  };

  const onSelected = (key, isNew) => {
    const po = _.cloneDeep(key);
    if (isNew) {
      po.specificationList.push({ specId: '', isAcCodeMapped: false });
    }
    setSelectedCard(po);
  };

  useEffect(() => {
    getAcCodeList();
  }, [managementId]);

  useEffect(() => {
    setLoading(true);
    getSpecList();
    savePohaStatus({ projectId: id });
  }, []);

  useEffect(() => {
    getPurchaseOrder();
    totalSpecCount();
  }, [visibleSpec, visiblesubSpec]);

  const showUpload = () => {
    setViewUpload(!viewUpload);
    setFile('');
  };

  const onUpload = () => {
    const body = new FormData();
    body.append('file', file);
    setIsUploading(true);
    importPohaData(body, params.vesselId, id).then((res) => {
      if (res.success) {
        getPurchaseOrder();
        totalSpecCount();
        showAlert(res.data.message, res.data.importSuccess ? 'success' : 'error');
        setViewUpload(false);
        setIsUploading(false);
        setFile('');
        onReload();
      }
    });
  };

  const handleSearchClose = () => {
    setSearchText('');
  };

  useEffect(() => {
    if (searchText === '') {
      setPage(1);
      getPurchaseOrder(1);
    }
  }, [searchText]);

  useEffect(() => {
    getPurchaseOrder();
  }, [page, pageSize]);

  const downloadExcel = () => {
    const payload = {
      vesselId: projectBasicDetails?.vesselId,
      projectId: projectBasicDetails?.id,
      search: searchText,
      MapType: checkTypeunmap(),
      prPoType: checkType()
    };
    exportExcelPOHA(payload);
  };

  const pageClick = (e) => {
    setPage(e.selected + 1);
  };

  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };

  return (
    <SidePanel
      title={(
        <div className="d-flex align-items-center">
          <div className="">POHA</div>
          <div className="couter-bubbles status-due ms-3 font-12">{count.totalCount && purchaseOrder?.items?.length !== count.totalCount ? `${purchaseOrder?.items?.length} / ${count.totalCount}` : count.totalCount}</div>
        </div>
      )}
      frompurchase
      changeVisibility={changeVisibility}
      visibleSpec={visibleSpec}
      bgColor="bg-sky-white"
      onClose={onCloseWindow}
      size="large"
      openModal={openModal}
      showView={showView}
    >
      <div className="ps-3 pe-3" data-testid="poha-page">
        <Navbar
          searchUpdated={searchUpdated}
          onSearch={onSearch}
          setSearchText={setSearchText}
          changeVisibility={changeVisibility}
          visibleSpec={visibleSpec}
          showUpload={showUpload}
          downloadExcel={downloadExcel}
          isTsiDisabled={isTsiDisabled}
          isShowImport={isShowImport}
          searchText={searchText}
          handleSearchClose={handleSearchClose}
          changeVisibilityunmap={changeVisibilityunmap}
          visiblesubSpec={visiblesubSpec}
          setVisiblesubSpec={setVisiblesubSpec}
          isVesselUser={isVesselUser}
          projectBasicDetails={projectBasicDetails}
          loading={loading}
          purchaseOrder={purchaseOrder}
        />
        {viewUpload && (
          <UploadCard
            poha
            file={file}
            setFile={setFile}
            onUpload={onUpload}
            isUploading={isUploading}
          />
        )}
        {purchaseOrder?.items && purchaseOrder?.items?.length > 0
          ? (
            <div className="scroll-wrap-poha mt-4">
              <CardWrapper
                specList={specList}
                selectedCard={selectedCard}
                loading={loading}
                isTsiDisabled={isTsiDisabled}
                acCodeList={acCodeList}
                currency={currency}
                saveSpecOrder={saveSpecOrder}
                onSelected={onSelected}
                onCancel={onCancel}
                setSelectedCard={setSelectedCard}
                purchaseOrder={purchaseOrder}
                isTSI={isTSI}
                projInfo={projInfo}
                isVesselUser={isVesselUser}
                projectBasicDetails={projectBasicDetails}
                getPurchaseOrder={getPurchaseOrder}
              />
            </div>
          )
          : <div data-testid="card-wrapper" className="mt-3 p-4 d-flex justify-content-center"> No data found</div>}
      </div>
      <footer className="d-flex justify-content-start side-panel__footer">
        {purchaseOrder?.totalItems > 10 && (
          <Pagination
            pageClick={pageClick}
            pageCount={purchaseOrder?.totalPages}
            currentpage={page}
            onSizeChange={onSizeChange}
            size={pageSize}
            height="w-full"
          />
        )}
      </footer>
    </SidePanel>
  );
};

export default PohaPage;
