import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  GetComponentListByVesselProjectSpecUrl
} = apiEndPoints;

export const getComponentList = (payload, id) => {
  return serviceHandler({
    url: `${GetComponentListByVesselProjectSpecUrl}?vesselId=${payload}&projectId=${id}`,
    methordType: 'post',
    payload
  });
};
