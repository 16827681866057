/* eslint-disable no-unused-vars */
/* istanbul ignore file */
/* eslint-disable react/no-unknown-property */
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';
import InnerTable2 from './InnerTable2';
import { deleteMajorSpec } from './services';
import ModalBox from '../../../../common/ModalBox';
import showAlert from '../../../../../utils/alert';

const TableTwo = ({
  formDataSpec,
  SpecificationLevel,
  showPagination,
  uomList,
  handleSaveScetion,
  editIndex,
  SpecificationTypeNumber,
  specArray,
  mainProp,
  setFormDataSpec,
  cancel,
  isRefreshService,
  ZERO_UUID,
  setOpenKfpModal,
  stateTag,
  setStateTag,
  charValidation,
  expanded,
  setOpenDescriptionModal,
  setActiveModalData,
  setIsDescriptionEdit,
  setExpanded,
  setEditIndex,
  setOpenTagModal,
  editData,
  handleTableChange,
  handleTableJob,
  setSubElementId,
  setPSpecificationId,
  reloadTable,
  getEmptySpecificationApi,
  loadingSave
}) => {
  const [indexValue, setIndexValue] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const deleteApiCall = () => {
    const item = formDataSpec.map((i) => i.id);
    deleteMajorSpec({ id: item[indexValue] }).then((res) => {
      if (res.success) {
        getEmptySpecificationApi();
        // setLoading(false);
        setOpenDeleteModal(false);
        reloadTable();
        showAlert('Successfully Deleted ', 'success');
      }
    });
  };

  const [openDltModal, setOpenDltModal] = useState(false);
  const dltApiCall = () => {
    deleteMajorSpec({ id: openDltModal.id }).then((res) => {
      if (res.success) {
        getEmptySpecificationApi();
        // setLoading(false);
        setOpenDltModal(false);
        reloadTable();
        showAlert('Successfully Deleted ', 'success');
      }
    });
  };
  const alertFunction = () => {
    showAlert('Please save the unsaved data ', 'error');
    setOpenDltModal(false);
  };
  const subColoumTwo = [
    {
      Header: '',
      expander: true,
      resizable: false,
      sortable: false,
      width: 50,
      Expander: ({ isExpanded, original, index }) => (isExpanded ? (
        <span
          onClick={() => {
            setSubElementId('');
            const quotesCopy = cloneDeep(formDataSpec);
            const expandedRows = quotesCopy?.map((data, key) => {
              return false;
            });
            setExpanded(expandedRows);
            cancel();
          }}
          id={original.specificationNo}
          className="font-10 icon-arrow-thin-down"
        />
      ) : (
        <span
          onClick={() => {
            setPSpecificationId(original.id);
            const quotesCopy = cloneDeep(formDataSpec);
            const expandedRows = quotesCopy?.map((data, key) => {
              if (index === key) return true;
              return false;
            });
            setExpanded(expandedRows);
            setEditIndex();
            setTimeout(() => {
              const element = document.getElementById(original.specificationNo);
              element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 20);
          }}
          id={original.specificationNo}
          className="font-16 icon-arrow-thin-right"
        />
      ))
    },
    {
      Header: 'Spec #',
      sortable: false,
      accessor: 'specificationNo',
      width: 80,
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            data-tooltip-id="project-Spec#"
            data-tooltip-content={original.specificationNo}
          >
            {original.specificationNo}
          </span>
        );
      }
    },
    {
      Header: 'Specification',
      sortable: false,
      accessor: 'title',
      Cell: (row) => {
        const { original, index } = row;
        if (index === editIndex) {
          // eslint-disable-next-line jsx-a11y/no-autofocus
          return <input id="new-title" autoFocus={!!original.isNew} className="text-box c-w-full" type="text" value={original.title} onChange={(e) => handleTableChange(e, 'title', original)} />;
        }
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-SpecId"
            data-tooltip-content={original.title}
          >
            {original.title}
          </span>
        );
      }
    },
    {
      Header: '',
      accessor: 'title',
      sortable: false,
      width: 250,
      colspan: 4,
      Cell: (row) => {
        const { original, index } = row;
        return (
          <div className="usermenu-item d-flex justify-content-end">
            {index === editIndex ? (
              <div onClick={() => handleTableJob(original, formDataSpec)} className="check-box mt-3">
                <input
                  type="checkbox"
                  name="isMapped"
                  value="isMapped"
                  checked={original.isMapped}
                />
                <label htmlFor="isMapped">Is it Project Specific</label>
              </div>
            ) : ''}
            <span className="ms-3 circle-ico-spec relative" tooltip="Description" flow="right">
              <span
                onClick={() => {
                  if (!original.id || original.id === ZERO_UUID) {
                    //
                  } else {
                    setOpenDescriptionModal(true);
                    setActiveModalData(original);
                    setStateTag();
                    setIsDescriptionEdit(original.description);
                  }
                }}
                className={
                  !original.id || original.id === ZERO_UUID ? 'icon-task1 font-24 brand-color disabled-field' : 'icon-task1 font-24 brand-color '
                }
              />
              {original.description ? <span className="dot-indicator" /> : ''}
            </span>
            <span className="circle-ico-spec relative" tooltip="Tag" flow="right">
              <span
                onClick={() => {
                  if (!original.id || original.id === ZERO_UUID) {
                    //
                  } else {
                    setOpenTagModal(true);
                    setActiveModalData(original);
                    setStateTag({ id: '' });
                    document.body.classList.add('model-open');
                  }
                }}
                className={
                  !original.id || original.id === ZERO_UUID
                    ? 'icon-tag font-24 brand-color ms-4 disabled-field'
                    : 'icon-tag font-24 brand-color ms-4 '
                }
              />
              {original.tags && original.tags.length ? <span className="dot-indicator" /> : ''}
            </span>
          </div>
        );
      }
    },
    {
      Header: '',
      accessor: 'packageName',
      sortable: false,
      width: 160,
      Cell: (row) => {
        const { index } = row;
        if (index === editIndex) {
          return <div />;
        }
        return (
          <div>
            <button
              type="button"
              data-tooltip-id="edit-tooltip"
              // data-tooltip-content={row?.mappedMessage}
              // disabled={original.isMapped}
              className="link-btn"
              onClick={() => {
                editData(row);
              }}
            >
              <span className="icon-edit icon-btn  brand-color cursor-pointer font-24" />
            </button>
            <Tooltip id="edit-tooltip" place="top" className="tooltip mt-4 me-5" />
            <button
              type="button"
              data-tooltip-id="delete-tooltip"
              // data-tooltip-content={original?.mappedMessage}
              // disabled={original?.isMapped}
              className="link-btn ms-3"
              onClick={() => {
                console.log('hlo');
                setOpenDeleteModal(true);

                // eslint-disable-next-line react/destructuring-assignment
                setIndexValue(row.index);
              }}
            >
              <span className="icon-delete icon-btn  brand-color cursor-pointer font-24" />
            </button>
            <Tooltip id="delete-tooltip" place="top" className="tooltip mt-4 me-5" />

          </div>
        );
      }
    }
  ];
  if (formDataSpec && formDataSpec.length) {
    return (
      <div className="sub-table" data-testid="mt-tab-2">
        {openDeleteModal && (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete Specification ?</p>
        </ModalBox>
        )}
        {openDltModal && (
        <>
          {openDltModal?.id ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => dltApiCall()}
              closeModal={() => setOpenDltModal(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete service line ?</p>
            </ModalBox>
          ) : alertFunction()}
        </>
        )}

        <ReactTable
          className=""
          columns={subColoumTwo}
          data={formDataSpec}
          pageSize={formDataSpec && formDataSpec.length > 0 ? formDataSpec.length : 1}
          showPagination={showPagination}
          expanded={expanded}
          resolveData={(items) => items?.map((d, idx) => {
            return {
              ...d,
              refIndex: idx
            };
          })}
          SubComponent={(subProp) => {
            const { serviceLines, id } = subProp.original;
            return (
              <InnerTable2
                indexTosave={subProp.index}
                SpecificationType={SpecificationTypeNumber}
                SpecificationLevel={SpecificationLevel}
                parentSpecificationId={id}
                showPagination={showPagination}
                serviceLines={serviceLines}
                serviceKFPArray={[]}
                formData={formDataSpec}
                specArray={specArray}
                mainProp={mainProp}
                setFormData={setFormDataSpec}
                handleSaveScetion={handleSaveScetion}
                cancel={cancel}
                isRefreshService={isRefreshService}
                ZERO_UUID={ZERO_UUID}
                uomList={uomList}
                setOpenKfpModal={setOpenKfpModal}
                state={stateTag}
                setstate={setStateTag}
                charValidation={charValidation}
                editIndex={editIndex}
                loadingSave={loadingSave}
                setOpenDltModal={setOpenDltModal}
              />
            );
          }}
        />
        <Tooltip id="project-Spec" place="top" className="tooltip mt-4 me-5" />
        <Tooltip id="project-SpecId" place="top" className="tooltip mt-4 me-5" />
        <Tooltip id="project-Spec#" place="top" className="tooltip mt-4 me-5" />
      </div>
    );
  }
  return null;
};
export default TableTwo;
