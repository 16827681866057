/* eslint-disable */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import image2 from '../../../../images/user-placeholder.jpg';

const Card = (props) => {
  const { onEditUser, onDeleteUser, userData, showDetails, onDisable, vesselId } = props;
  const userId = localStorage.getItem('USER_ID');
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const isAdmin = userDetails && userDetails[0] && userDetails[0].roleType === 1 ? true : false;

  return (
    <div className={`card p-2rem mb-2 ${userData.isDisabled ? 'card-disable' : ''}`} data-testid="card-view">
      <div className="d-flex col-gap-2 justify-content-start">
        <div
          id="details"
          className="d-flex spacing-bfr-mngmnt cursor-pointer"
          onClick={() => {
            showDetails(userData);
          }}
        >
          <div className="me-4 w-1/4">
            <img src={userData?.profileImage || image2} alt="" className="thmb-lg" />
          </div>
          <div className="w-3/4">
            <h6 className="head-16 mb-2 text-capitalize break-all">
              <span className="text-ellipsis pe-2" data-tooltip-id="project-cardname" data-tooltip-content={`${userData.firstName}`}>
                {_.truncate(userData?.firstName, {
                  length: 15,
                  separator: ' ',
                })}
              </span>
              <Tooltip id="project-cardname" place="top" className="tooltip mt-3" />
              <span className="text-ellipsis pe-2" data-tooltip-id="project-cardname" data-tooltip-content={`${userData.middleName}`}>
                {_.truncate(userData?.middleName, {
                  length: 15,
                  separator: ' ',
                })}
              </span>
              <span className="text-ellipsis pe-2" data-tooltip-id="project-cardname" data-tooltip-content={`${userData.lastName}`}>
                {_.truncate(userData?.lastName, {
                  length: 15,
                  separator: ' ',
                })}
              </span>
            </h6>
            {userData.roleData.map((item) => {
              return (
                <div key={item.managementId} data-tooltip-id="project-cardname" data-tooltip-content={item.role}>
                  {_.truncate(item.role, { length: 25 })}
                </div>
              );
            })}
          </div>
        </div>
        {userData?.isAdmin ? (
          <div className="management">
            <h6 className="head-16 mb-2">Management</h6>
            <div>All Managements</div>
          </div>
        ) : (
          <div className="management">
            <h6 className="head-16 mb-2">Management</h6>
            {userData.roleData.map((item) => {
              return (
                <div key={item.managementId} data-tooltip-id="project-cardname" data-tooltip-content={item.management}>
                  {_.truncate(item.management, { length: 30 })}
                </div>
              );
            })}
          </div>
        )}
        <div className="vessels">
          <h6 className="head-16 mb-2">{vesselId ? 'Vessel' : 'Vessels'}</h6>
          {userData.roleData.map((roleItem) => {
            let vessels = '';
            return (
              <div key={roleItem.managementId + roleItem.roleId} className="d-flex timeline-text-wrap">
                {roleItem.vessels.length > 0 ?(
                  <div>
                {roleItem.vessels.map((vesselItem, index) => {
                  vessels = vessels + vesselItem.name + `${roleItem.vessels.length !== index + 1 ? ', ' : ''}` ;
                })}
                <div data-tooltip-id="project-cardname" data-tooltip-content={vessels}>
                  {_.truncate(vessels, { length: 17 })}
                </div>
                </div>
                ):(
                  <div>{'-'} </div>
                )}
              </div>
            );
          })}
        </div>
        
        {userData.email && (
          <div className="align-items-center d-flex col-2 text-ellipsis">
            <span className="icon-email font-20 me-2 " />
            <span data-tooltip-id="project-Cardemail" data-tooltip-content={`${userData.email}`} className=" text-ellipsis">
            {_.truncate(userData?.email, {length:19})}
            </span>
            <Tooltip id="project-Cardemail" place="top" className="tooltip mt-3" />
          </div>
        )}
        <div className="d-flex col-gap-1 btn-width ml-auto align-items-center">
          <button
            id="edit"
            type="button"
            disabled={userData.isDisabled || (userData?.email && !isAdmin)}
            className="link-btn"
            onClick={() => {
              onEditUser(userData);
            }}
          >
            <span className="icon-edit font-22" />
          </button>
          <button
            id="delete"
            type="button"
            onClick={() => {
              onDeleteUser(userData);
            }}
            className="link-btn d-none"
          >
            <span className="icon-delete font-22" />
          </button>
          <div className={userData?.email && !isAdmin ? "toggle-switch ms-2 cursor-disable" : "toggle-switch ms-2"}>
            <input data-test="check" disabled={userData?.email && !isAdmin} type="checkbox" id={userData.id} checked={!userData.isDisabled} onChange={() => onDisable(userData)} />
            <label htmlFor={userData.id}>{`${userData.isDisabled ? 'Inactive' : 'Active'}`}</label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
