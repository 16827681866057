/* eslint-disable */
import React from "react";

const FormulaColorGenerator = (props) => {
  const { formula } = props;
  let coloredString = formula;

  const regex = /([\+\-\*\/])([A-Za-z0-9.]+)/g;

  coloredString = coloredString.replace(regex, (match, operator, number) => {
    const letters = '0123456789ABCDEF';
    let colors = '#';
    for (let i = 0; i < 6; i++) {
      colors += letters[Math.floor(Math.random() * 16)];
    }
    return `<span>${operator}<span style="color: ${colors};">${number}</span></span>`;
  });

  coloredString = coloredString.replace(/<span/g, '<span key={Math.random()}');

  return (
    <div>
      <div dangerouslySetInnerHTML={formula ? { __html: coloredString } : { __html: '' }} />
    </div>
  );
};

export default FormulaColorGenerator;
