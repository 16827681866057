import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { getKfp } from '../../Kfp/services';
import AddKFPTable from './AddKFPTable';
import KFPPagination from './KFPPagination';

const AddKfpToSpec = ({ state, setstate }) => {
  const defaultInput = {
    page: 1,
    pageSize: 5,
    search: ''
  };
  const [inputObject, setInputObject] = useState(defaultInput);
  const [kfpList, setKfpList] = useState([]);

  const reloadTable = (i) => {
    getKfp(i).then((res) => {
      if (res.success) {
        setKfpList(res.data);
      }
    });
  };

  useEffect(() => {
    reloadTable(inputObject);
  }, []);

  const pageClick = (e) => {
    const t = cloneDeep(inputObject);
    t.page = e.selected + 1;
    setInputObject(t);
    reloadTable(t);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(inputObject);
    }
  };
  const onSearchText = (e) => {
    const t = cloneDeep(inputObject);
    t.search = e.target.value;
    setInputObject(t);
  };
  return (
    <>
      <div data-testid="mt-add-kfp" className="modal-content">
        <div className="search large mb-1 w-100 mb-4">
          <span
            className="icon-search "
            id="search-click"
            onClick={() => {
              onSearch({ key: 'Enter' });
            }}
          />
          <input type="text" id="search" className="text-box large" placeholder="Search by code" onKeyPress={onSearch} onChange={onSearchText} />
        </div>
        <AddKFPTable kfpList={kfpList} setstate={setstate} state={state} />
        <KFPPagination
          pageClick={pageClick}
          kfpList={kfpList}
          inputObject={inputObject}
        />
      </div>
    </>
  );
};
export default AddKfpToSpec;
