/* eslint-disable max-len */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';

const WorkFLowSetup = (props) => {
  const [state, setstate] = useState();
  const [defaultWF, setDefaultWF] = useState(false);
  const { roleList, workFlowList, setWorkFlowList, managementList, management, setManagement,
    ZERO_UUID, defaultWorkFlow } = props;
  const { generateOptions } = helper;
  const r = roleList?.filter((item) => (item.type === 2 || item.type === 3 || item.type === 4)); // Author editor viewer
  const rList = generateOptions(r, 'id', 'name');

  const changeApprover = (e, key, type) => {
    const currentList = [...workFlowList];
    if (type === 1) {
      if (!e || !e.value || e.value !== currentList[key]?.secondApprover) {
        currentList[key].firstApproverName = e?.label || undefined;
        currentList[key].firstApprover = e?.value || undefined;
      } else {
        showAlert('First and second approver should not be same', 'warning');
      }
    } else if (type === 2) {
      if (!e || !e.value || e.value !== currentList[key]?.firstApprover) {
        currentList[key].secondApprover = e ? e.value : undefined;
        currentList[key].secondApproverName = e ? e.label : undefined;
      } else {
        showAlert('First and second approver should not be same', 'warning');
      }
    }
    setWorkFlowList(currentList);
  };

  return (
    <>
      <div className="modal-content" data-testid="workflowsetup">
        <div className="d-flex align-items-center mb-4">
          <div className="col-6">
            <Select
              autoFocus
              menuPosition="fixed"
              id="approver1"
              placeholder="Select Management"
              options={managementList}
              value={managementList.filter((item) => item.value === management)}
              onChange={(e) => {
                if (e) {
                  setManagement(e.value);
                } else {
                  setManagement(ZERO_UUID);
                }
              }}
            />
          </div>
          {management !== ZERO_UUID
            && (
              <div className="col-6 d-flex justify-content-end me-4 px-1">
                <div className="check-box gap-1">
                  <input
                    type="checkbox"
                    id="Remember"
                    name=""
                    value="Remember"
                    checked={defaultWF}
                    onClick={(e) => {
                      setDefaultWF(!defaultWF);
                      if (e.target.checked) {
                        setWorkFlowList(defaultWorkFlow);
                      } else {
                        setWorkFlowList(workFlowList.map((item) => ({
                          ...item,
                          firstApprover: ZERO_UUID,
                          firstApproverName: '',
                          secondApprover: ZERO_UUID,
                          secondApproverName: '',
                          managementGroupId: ZERO_UUID
                        })));
                      }
                    }}
                  />
                  <label htmlFor="Remember">Set Default Approval Workflow</label>
                </div>
              </div>
            )}
        </div>
        <ul className="px-2 me-4">
          <li className="col-gap-1 d-flex my-2 pb-3 head-black font-semibold">
            <div className="d-flex col-6 relative">
              Workflow
              <span onClick={() => setstate(true)} className="cursor-pointer font-18 icon-Info ms-1 mt-1 second-brand-color" />
              {state ? (
                <div className="info-details animate__animated animate__headShake">
                  <div className="d-flex justify-content-end mt-2">
                    <span onClick={() => setstate(false)} className="cursor-pointer font-22 icon-close ms-5 brand-color" />
                  </div>
                  <div>
                    Note: If any of the approvers are not mapped, the workflow will be auto approved by the logged in user.
                  </div>
                </div>
              ) : ''}
            </div>
            <div className="col-3">
              1
              <sup>st</sup>
              Approver
            </div>
            <div className="col-3">
              2
              <sup>nd</sup>
              Approver
            </div>
          </li>
          {workFlowList.map((item, key) => {
            return (
              <li className="col-gap-1 d-flex mt-2 border-up py-3 align-items-center me-3">
                <label className="col-6">{item.workFlow}</label>
                <div className="col-3" data-tooltip-id="project-projectName" data-tooltip-content={item.firstApproverName || ''}>
                  <Select
                    menuPosition="fixed"
                    id="approver1"
                    isClearable
                    placeholder="Select"
                    options={rList}
                    value={item.firstApproverName ? { label: item.firstApproverName, value: item.firstApprover } : null}
                    onChange={(e) => {
                      changeApprover(e, key, 1);
                    }}
                  />
                </div>
                <div className="col-3" data-tooltip-id="project-projectName" data-tooltip-content={item.secondApproverName || ''}>
                  <Select
                    menuPosition="fixed"
                    isDisabled={item.workFlowCode === 5}
                    id="approver2"
                    isClearable
                    options={rList}
                    placeholder="Select"
                    value={item.secondApproverName ? { label: item.secondApproverName, value: item.secondApprover } : null}
                    onChange={(e) => {
                      changeApprover(e, key, 2);
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
        <Tooltip id="project-projectName" place="top" className="tooltip mt-4" />
      </div>
    </>
  );
};
export default WorkFLowSetup;
