import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const ApproveConfirm = (props) => {
  const { onApprove, setShowApprove, projInfo } = props;
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">
      <div className="head-18 pb-4">
        <span className="white-space-preserve" data-tooltip-id="project-close" data-tooltip-content={projInfo?.name}>
          {' '}
          {projInfo?.name}
        </span>

      </div>
      <div className="mt-3">
        <p className="font-15 font-semibold">Project will be sent to approval phase</p>
      </div>
      <span className="head-18">Are you sure you want to approve</span>
      <div className="d-flex">
        <button type="button" className="secondary-btn large m-3 p-3" id="backtoprojects" onClick={() => setShowApprove(false)}>
          Cancel
        </button>
        <button type="button" className="primary-btn large m-3 p-3" id="save" onClick={() => onApprove()}>
          Submit
        </button>
      </div>
      <Tooltip id="project-close" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ApproveConfirm;
