/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { toLower } from 'lodash';
import Addattachments from './AddAttchments';
import ModalBox from '../../../common/ModalBox';
import { fileImages } from '../../../common/mappingConst';

const Attachments = (props) => {
  const { formDataAttachment, setAttachFormData, initialFormData,
    addCliked, isTSI, vendorData, setIsAddClicked, ondrop, saveVendorItem } = props;
  console.log('vendorData', vendorData);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [fileIndex, setFileIndex] = React.useState(0);
  const onDelete = (index) => {
    setDeleteModal(true);
    setFileIndex(index);
  };
  const deleteFile = () => {
    vendorData.attachments.splice(fileIndex, 1);
    saveVendorItem(vendorData);
    setDeleteModal(false);
  };
  return (
    <div>
      {addCliked ? (
        <>
          <div className="d-flex col-gap-1 pb-4">
            <button
              id="back"
              onClick={() => {
                setIsAddClicked(false);
              }}
              type="button"
              className="link-btn"
            >
              <span className="icon-arrow-left2 me-2" />
              Back
            </button>
          </div>
          <Addattachments
            formDataAttachment={formDataAttachment}
            initialFormData={initialFormData}
            setAttachFormData={setAttachFormData}
            ondrop={ondrop}
          />
        </>
      ) : (
        <div>
          <div className="d-flex col-gap-1 pb-3">
            {isTSI && (
              <button
                type="button"
                id="add-new"
                className="secondary-btn large"
                onClick={() => {
                  setIsAddClicked(true);
                  setAttachFormData(initialFormData);
                }}
              >
                Add New
              </button>
            )}
          </div>
          <div data-testid="attachments" className="scroll-wrap">
            {vendorData?.attachments && vendorData?.attachments?.length > 0 ? (
              vendorData?.attachments?.map((data, index) => {
                return (
                  <div className="card d-flex justify-content-between align-items-center pb-2 pt-2">
                    <div className="align-items-center d-flex font-16 col-gap-1">
                      <div className="thmb-icon-md"><span className={fileImages[toLower(data?.type)]} /></div>
                      <div className="pb-2 value w-36em text-ellipsis" data-tooltip-id="file-name" data-tooltip-content={data?.fname}>
                        {data?.fname}
                      </div>
                      <Tooltip id="file-name" place="top" className="tooltip m-4" />
                    </div>
                    {/* {isTSI && ( */}

                    <div className="d-flex">
                      <button type="button" className="link-btn  me-4">
                        <a href={data?.path} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                          <span className="icon-download font-20" />
                        </a>
                      </button>
                      <button
                        type="button"
                        id="delete"
                        className="link-btn"
                        onClick={() => {
                          onDelete(index);
                        }}
                      >
                        <span className="icon-delete font-20" />
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                );
              })
            ) : (
              <div className=" p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">No data found</div>
              </div>
            )}
          </div>
        </div>
      )}
      {deleteModal ? (
        <ModalBox
          openDeleteModal={deleteModal}
          modalClassName="small"
          heading="Delete"
          onSave={() => deleteFile()}
          closeModal={() => setDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete this document?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};

export default Attachments;
