import React from 'react';

const VariancePercentage = ({ estimatedValue, actualValue, original,
  index }) => {
  const isUp = estimatedValue < actualValue;
  if (original?.variancePercentage === 0 || original?.variancepercent === 0) {
    return (
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content="0"
        className="d-flex align-items-center justify-content-end"
      >
        0
      </span>
    );
  }
  return (
    <div key={index} id={index} className="d-flex align-items-center justify-content-end" data-testid="deviation">
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content={Math.abs(original?.variancePercentage)
          || Math.abs(original.variancepercent)}
        id="de-vi-at"
        className={`text-ellipsis ${isUp ? 'red' : ' green'}`}
      >
        <>
          {Math.abs(original?.variancePercentage || original.variancepercent)}
        </>
      </span>
      <span id="de-vi" className={`font-18 ms-2 ${isUp ? 'icon-arrow-up2 red' : 'icon-arrow-down2 green '}`} />
    </div>
  );
};

export default VariancePercentage;
