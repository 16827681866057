import React from 'react';
import ReactTable from 'react-table-v6';

const EmptyTable = () => {
  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: true,
      resizable: false,
      width: 50
    },
    {
      Header: 'SEC #',
      accessor: 'specificationNo',
      sortable: false,
      width: 108
    },
    {
      Header: 'SPEC SECTION',
      accessor: 'title',
      sortable: false
    },
    {
      Header: 'Actions',
      accessor: 'packageName',
      sortable: false,
      width: 180
    }
  ];

  return (
    <div className="table no-row-empty" data-testid="mt-tab-2-empty">
      <ReactTable
        className=""
        data={[]}
        columns={columns}
        pageSize={1}
        showPagination={false}
      />
    </div>
  );
};
export default EmptyTable;
