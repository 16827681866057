import { serviceHandler } from '../../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../../utils/apiUrlConstants';

const {
  SaveProjectSpecTags,
  DeleteProjectSpecTags,
  GetProjectSpecTags,
  GetTags
} = apiEndPoints;

export const saveProjectSpecTags = (payload) => {
  return serviceHandler({
    url: `${SaveProjectSpecTags}`,
    methordType: 'post',
    payload
  });
};

export const deleteProjectSpecTags = (payload) => {
  return serviceHandler({
    url: `${DeleteProjectSpecTags}?projectSpecificationId=${payload.id}&tagId=${payload.tagId}`,
    methordType: 'post',
    payload
  });
};
export const getProjectSpecTag = (payload) => {
  return serviceHandler({
    url: `${GetProjectSpecTags}?projectSpecificationId=${payload.id}&request=${payload.search}`,
    methordType: 'get',
    payload
  });
};
export const getTags = (payload) => {
  return serviceHandler({
    url: `${GetTags}`,
    methordType: 'post',
    payload
  });
};
