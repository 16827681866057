/* istanbul ignore file */
import React from 'react';
import Loader from './loader';

const TableLoader = ({ loading }) => {
  return (
    <div>
      {loading ? (
        <div className="table-loader-wrap">
          <div className="table-loading">
            <Loader />
            {/* <div>
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div> */}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default TableLoader;
