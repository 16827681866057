/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SpecContent from './children/specContent';
import NonSpecContent from './children/nonSpecContent';
import helper from '../../../../../../utils/helper';

const Phases = (props) => {
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = (userRole === roles.VESSEL_USER);
  const location = useLocation();
  const { gtSpecData, mtSpecData, setVisibleSpec, viewSpec, changeView,
    setSelectedSpec, closedSection, setClosedSection, setSelectedSpecType,
    handleChange, arrayList, showContent
  } = props;
  const onAccordianClose = (secId, type = true) => {
    const currentList = [...closedSection];
    if (type) {
      if (currentList.indexOf(secId) > -1) {
        currentList.splice(currentList.indexOf(secId), 1);
      } else {
        // currentList.push(secId);
        currentList[0] = secId;
        const element = document?.getElementById(secId);
        element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    } else {
      currentList[0] = secId;
      const element = document?.getElementById(secId);
      element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
    setClosedSection(currentList);
  };
  useEffect(() => {
    if (location.state) {
      if (location.state?.specDataURL && location.state?.specTypeURL) {
        const visibility = ['gt', 'mt'];
        setVisibleSpec(visibility);
        changeView(true);
        setTimeout(() => {
          const ddata = location.state?.specTypeURL === 'gt' ? gtSpecData : mtSpecData;
          ddata.forEach((element) => {
            if (element.id === location.state?.specDataURL?.parentSpecificationId) {
              onAccordianClose(element.id, false);
              element?.specifications?.forEach((field) => {
                if (location.state?.specDataURL.id === field.id) {
                  setSelectedSpec(field);
                  setSelectedSpecType(location.state?.specTypeURL);
                  setTimeout(() => {
                    const elementDiv = document?.getElementById(field.id);
                    elementDiv?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                  }, 50);
                }
              });
            }
          });
        }, 50);
      }
    }
  }, [location]);
  return (
    <div className="col-4">
      <div className="card">
        <div className=" left-content-wrap">
          <div className="list-tab-head d-flex  pt-4 border-down ">
            <div className={`tab-items col-6 text-center ${viewSpec ? ' active ' : ''}`} onClick={() => { changeView(true); setSelectedSpec({}); }}>
              Specification
            </div>
            {!isTSI && (
            <div className={`tab-items col-6  text-center ${viewSpec ? '' : ' active'}`} onClick={() => { changeView(false); setSelectedSpec({}); }}>
              Non Spec Items
            </div>
            )}

          </div>
          {viewSpec ? (
            <SpecContent
              {...props}
              onAccordianClose={onAccordianClose}
              handleChange={handleChange}
              arrayList={arrayList}
              showContent={showContent}
              isTSI={isTSI}
            />
          )
            : (
              <NonSpecContent
                {...props}
                onAccordianClose={onAccordianClose}
              />
            )}
        </div>
      </div>
    </div>
  );
};
export default Phases;
