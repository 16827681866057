/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Header from '../Header';
import Calender from './Children/calender';
import Task from './Children/task';
import VesselInfo from './Children/vesselInfo';
import { getProjects, projectApprovals, dashBoard } from '../Projects/ListProject/services';
import Pagination from '../../common/pagination';
import ProjectApproval from './Children/projectApproval';
import { getSpecCounts, getProjectsList, postWidget, getWidget } from './service';
import helper from '../../../utils/helper';
import PieChart from './Children/pieChart';
import SidePanel from '../../common/sidePanel';
import { getProfileData, userRolesListing } from '../../Login/services';
import RoleSelector from './Children/roleSelector';
import Loader2 from '../../common/loader2';
import PolicyFooter from '../../Admin/policyFooter';
import PrivacyPolicy from '../../Policies/privacy';
import TermsOfUse from '../../Policies/TermsOfUse';

function DashBoard(props) {
  const { showHeader = true } = props;
  const [vesselRoles, setVesselRoles] = useState([]);
  const [vesselCount, setVesselCount] = useState([]);
  const [projectApproval, setProjectApproval] = useState([]);
  const [approveCount, setApproveCount] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [currentVesselPage, setCurrentVesselPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [vesselPageSize, setVesselPageSize] = useState(5);
  const [selectedproject, setSelectedProject] = useState('');
  const [selectedProjectTask, setSelectedProjectTask] = useState('');
  const [projectNameList, setProjectNameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [focusBtn, setFocusBtn] = useState();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [monthName, setMonthName] = useState(monthNames[new Date().getMonth()]);
  const [surveyList, setSurveyList] = useState([]);
  const [monthSurveyList, setMonthSurveyList] = useState([]);
  const [yearClick, setYearClick] = useState(false);
  const [showView, setShowView] = useState(false);
  const [toggleChecked, setToggleChecked] = useState([]);
  const [data, setData] = useState([]);
  const [noData, setNoData] = useState('');
  const userRole = localStorage.getItem('USER_ROLE');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const [isViewMode, setIsViewMode] = useState(localStorage.getItem('isFirst') === 'true');
  const { generateOptions, roles } = helper;
  const initialState = {
    firstName: '',
    lastName: '',
    middleName: '',
    profileImage: ''
  };
  const [user, setuser] = useState(initialState);
  const [role, setRole] = useState([]);
  const reloadTable = (page) => {
    getProjects({
      page: page || currentpage,
      pageSize,
      search: ''
    }).then((res) => {
      setLoading(false);
      setProjects(res?.data.items);
    });
  };
  const getProjectApproval = (page) => {
    projectApprovals({
      page: page || currentpage,
      pageSize: 6,
      search: ''
    }).then((res) => {
      setLoading(false);
      setProjectApproval(res?.data);
      setApproveCount(res?.data);
    });
  };
  const dashBoardVessel = (page) => {
    dashBoard({
      page: page || currentVesselPage,
      pageSize: 6,
      search: ''
    }).then((res) => {
      setLoading(false);
      setVesselRoles(res?.data);
      setVesselCount(res?.data);
    });
  };

  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
  };
  const vesselPageClick = (e) => {
    setCurrentVesselPage(e.selected + 1);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const onSizeVesselChange = (e) => {
    setVesselPageSize(e.target.value);
    setCurrentVesselPage(1);
  };
  useEffect(() => {
    reloadTable();
    dashBoardVessel();
    getProjectApproval();
  }, [currentpage, pageSize, currentVesselPage, vesselPageSize]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    getProjectsList().then((res) => {
      if (res.success) {
        setLoading(false);
        setProjectNameList(generateOptions(res.data, 'id', 'name'));
        if (res.data.length > 0) {
          setSelectedProject(res.data.filter((options) => options.phase === 4)[0]?.id);
        }
      }
    });
  }, []);

  useEffect(() => {
    getProjectsList().then((res) => {
      if (res.success) {
        setProjectNameList(generateOptions(res.data, 'id', 'name'));
        if (res.data.length > 0) {
          setSelectedProjectTask(res.data[0].id);
        }
      }
    });
  }, []);
  useEffect(() => {
    setLoading(true);
  }, []);
  const handleWidget = () => {
    setShowView(true);
    getWidget().then((res) => {
      if (res.success) {
        setLoading(false);
        setData(res.data);
        const a = res.data;
        if (res.data.indexOf(1) < 0) {
          a.push(1);
          setToggleChecked(a);
        } else {
          setToggleChecked(res.data);
        }
      }
    });
  };

  const onViewClose = () => {
    setShowView(false);
  };
  const handleToggle = (id) => {
    const arr = [...toggleChecked];

    let flag = 1;
    for (let i = 0; i < arr.length + 1; i += 1) {
      if (arr[i] === id && id !== 1) {
        arr.splice(i, 1); flag = 0; break;
      }
    }
    if (flag === 1) {
      arr.push(id);
    }

    setToggleChecked(arr);
  };

  const widgets = [
    { name: 'Job Progress', id: 2 },
    { name: 'Vessel and Roles', id: 3 },
    { name: 'Task', id: 4 },
    { name: 'Projects', id: 5 }
  ];

  useEffect(() => {
    getWidget().then((res) => {
      if (res.success) {
        setData(res.data);
        setLoading(false);
        setToggleChecked(res.data);
      }
    });
  }, []);

  const handleSave = () => {
    setShowView(false);
    postWidget(toggleChecked).then((res) => {
      getWidget().then((resp) => {
        setLoading(false);
        if (resp.success) {
          setData(resp.data);
          setToggleChecked(res.data);
        }
        if (toggleChecked.length === 0) {
          setNoData('No Widgets selected');
        } else {
          setNoData('');
        }
      });
    });
  };

  useEffect(() => {
    const USER_ID = localStorage.getItem('USER_ID');
    if (USER_ID) {
      getProfileData(USER_ID).then((res) => {
        const userDetails = res?.data;
        if (userDetails) {
          setuser(userDetails);
          setLoading(false);
        }
      });
    }
    if (localStorage.getItem('isFirst') === 'true') {
      userRolesListing().then((resp) => {
        if (resp.success) {
          setRole(resp.data);
          setLoading(false);
        }
      });
    }
  }, []);

  const nameUser = `${user?.firstName} ${user?.middleName} ${user?.lastName}`;

  return (
    <>
      <div data-testid="dashboard">
        <div className={showHeader ? 'header' : ''}>
          {showHeader && <Header />}
          <div className="sub-header z-index-998">
            <div className="d-flex justify-content-between align-items-center mx-2">
              <div className="">
                <h2 className="head-16">Dashboard</h2>
                <p>{`Welcome ${nameUser}`}</p>
              </div>
              <button className="secondary-btn" id="w-config" type="button" onClick={handleWidget}>
                Widget Config
              </button>
            </div>
          </div>
          {showView && (
          <SidePanel title="Widget Configuration" size="small" showView={showView} onClose={onViewClose}>
            <div className="">
              {widgets.map((items) => (
                <div className="card no-shadow py-3 px-4 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="">{items.name}</p>
                    <div className="toggle-switch ms-2 " onClick={() => handleToggle(items.id)}>
                      <input type="checkbox" id="id" checked={toggleChecked.indexOf(items.id) > -1} />
                      <label />
                    </div>
                  </div>
                </div>
              ))}

            </div>
            <footer className="side-panel__footer">
              <button
                autoFocus
                type="button"
                id="save"
                className="primary-btn"
                onClick={handleSave}
              >
                Apply/Close
              </button>
            </footer>

          </SidePanel>
          )}
        </div>
        <div>
          {loading ? <div className="project-top-8  m-5"><Loader2 /></div> : (
            <div className="d-flex flex-wrap project-top-8  width-manage ">
              <div className="head-14">{noData}</div>
              <div className="col-lg-12 col-md-12 d-block">
                <Calender
                  year={year}
                  setYear={setYear}
                  focusBtn={focusBtn}
                  setFocusBtn={setFocusBtn}
                  monthName={monthName}
                  setMonthName={setMonthName}
                  surveyList={surveyList}
                  setSurveyList={setSurveyList}
                  monthSurveyList={monthSurveyList}
                  setMonthSurveyList={setMonthSurveyList}
                  yearClick={yearClick}
                  setYearClick={setYearClick}
                  handleToggle={handleToggle}
                  loading={loading}
                />
              </div>

              <div className={data.includes(2) ? 'col-xl-4 col-lg-6 col-md-12 d-block' : 'col-xl-4 col-lg-6 col-md-12 d-none'}>
                <PieChart
                  projectNameList={projectNameList}
                  selectedproject={selectedproject}
                  setSelectedProject={setSelectedProject}
                  loading={loading}
                />
              </div>
              <div className={data.includes(3) ? 'col-xl-4 col-lg-6 col-md-12 d-block' : 'col-xl-4 col-lg-6 col-md-12 d-none'}>
                <div className=" widget-card">
                  <div className="head">
                    <h4 className="head-16 d-flex align-items-center ">
                      <span>Vessel and Roles</span>

                      <span className="couter-bubble status-due ms-4">{vesselCount?.totalItems}</span>
                    </h4>
                  </div>
                  <div className="body scroll-wraps scroll-wrap-dashboard">
                    {vesselRoles?.items?.map((items) => {
                      return (
                        <>

                          <VesselInfo items={items} loading={loading} />

                        </>
                      );
                    })}
                  </div>

                  <div className="d-flex justify-content-left mt-4 ms-3">
                    {vesselCount?.totalItems > 5 && (
                    <Pagination
                      pageClick={vesselPageClick}
                      pageCount={vesselRoles?.totalPages}
                      currentpage={currentVesselPage}
                      onSizeChange={onSizeVesselChange}
                      size={vesselPageSize}
                      noRowsDisplay
                    />
                    )}
                  </div>
                </div>
              </div>
              <div className={data.includes(4) ? 'col-xl-4 col-lg-6 col-md-12  d-block' : 'col-xl-4 col-lg-6 col-md-12 d-none'}>
                <Task
                  projectNameList={projectNameList}
                  selectedproject={selectedProjectTask}
                  setSelectedProject={setSelectedProjectTask}
                />
              </div>

              <div className={data.includes(5) ? 'col-xl-4 col-lg-6 col-md-12 d-block' : 'col-xl-4 col-lg-6 col-md-12 d-none'}>
                <div className="widget-card">
                  <div className="head">
                    <h4 className="head-16 d-flex align-items-center ">
                      <span>Projects</span>
                      <span className="couter-bubble status-due ms-4">{projectApproval?.totalItems}</span>
                    </h4>
                  </div>
                  <div className="body scroll-wraps scroll-wrap-dashboard">
                    {projectApproval?.items?.map((items) => {
                      return (
                        <ProjectApproval items={items} />
                      );
                    })}
                  </div>

                  <div className="">
                    <div className="d-flex justify-content-left mt-4 ms-3">
                      {approveCount?.totalItems > 5 && (
                      <Pagination
                        pageClick={pageClick}
                        pageCount={projectApproval?.totalPages}
                        currentpage={currentpage}
                        onSizeChange={onSizeChange}
                        size={pageSize}
                        noRowsDisplay
                      />
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}

        </div>
        <RoleSelector
          setIsViewMode={setIsViewMode}
          isViewMode={isViewMode}
          user={user}
          role={role}
        />
        {userRole === 'Admin' ? (
          <div className="">
            <div className="">
              <div className="footer ms-5 me-5 justify-content-between font-13">
                <PolicyFooter
                  setShowPrivacyPolicy={setShowPrivacyPolicy}
                  setShowTermsPolicy={setShowTermsPolicy}
                />
              </div>
            </div>

          </div>
        ) : (

          <div className="footer"><div className="container login-footer">© 2024 ThinkPalm</div></div>
        )}
      </div>

      {showPrivacyPolicy && (
      <PrivacyPolicy
        setShowPrivacyPolicy={setShowPrivacyPolicy}
        showPrivacyPolicy={showPrivacyPolicy}
      />
      )}
      {showTermsPolicy && (
      <TermsOfUse
        setShowTermsPolicy={setShowTermsPolicy}
        showTermsPolicy={showTermsPolicy}
      />
      )}
    </>
  );
}

export default DashBoard;
