/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import Loader from '../../../../../common/loader';

const previewContent = (props) => {
  const { specList, mainSec, showsubSec, onSecOpen, handleInputChange, viewMore, loading } = props;
  console.log(loading, 'jjjjj');
  return (
    <div>
      {loading ? <Loader /> : (
        <div className="spc-map ps-3" data-testid="pc-table">
          <div className="d-flex border-down py-2 ps-3">
            <div className="col-1 font-bold">SEC #</div>
            <div className="col-8 font-bold">SECTION</div>
          </div>
          {
        specList?.map((sec, index) => {
          return (
            <div className={(sec.id === mainSec && showsubSec) ? 'highlight-tender' : ''}>
              <div className="d-flex border-down py-2 ps-3">
                <div className="col-1 d-flex align-items-center">
                  <span
                    id={sec.projectSpecId}
                    className={sec.id === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec.id, true); }}
                  />
                  <span data-tooltip-id="project-supplementary" data-tooltip-content={sec.specificationNo}>
                    {_.truncate(sec.specificationNo, { length: 7 })}
                  </span>

                </div>
                <div
                  className="col-8"
                  data-tooltip-id="project-supplementary"
                  data-tooltip-content={sec?.title}
                >
                  {sec?.title}

                </div>
              </div>
              {
                sec.id === mainSec && sec.childData.map((subsec, subIndex) => {
                  return (
                    <>
                      <div className="d-flex mb-2 py-2 ps-3">
                        <div className="col-1 check-box d-flex justify-content-center">
                          {/* <input type="checkbox" id={subsec?.id} checked={subsec?.isChecked} onClick={(e) => { handleInputChange(e, index, subIndex); }} />
                          <label className="mr-15" htmlFor={subsec?.id} /> */}
                        </div>
                        <div className="d-flex  w-full">
                          <div className="col-10 d-flex align-items-center">
                            <span
                              id={subsec.id}
                              className={subsec.id === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                              onClick={() => { onSecOpen(subsec.id, false); }}
                            />
                            <span className="me-4">{subsec?.specificationNo}</span>
                            <div className="text-ellipsis w-56">
                              <span
                                data-tooltip-id="project-supplementary"
                                data-tooltip-content={subsec?.title}
                              >
                                {subsec?.title}
                              </span>

                            </div>
                          </div>
                          <div className="col-2">
                            {subsec.id === showsubSec ? (
                              <button
                                className="primary-btn small"
                                type="button"
                                onClick={() => {
                                  viewMore(subsec);
                                }}
                              >
                                View More
                              </button>
                            ) : ''}
                          </div>
                        </div>
                      </div>
                      {
                        subsec.id === showsubSec
                        && subsec.specServiceLine.map((serv, num) => {
                          return (
                            <>
                              {num === 0 && (
                                <div className="d-flex ps-3 align-items-center">
                                  <div className="col-1" />
                                  <div className="col-2 bg-sky-white ps-3 border-down py-2">Service LN #</div>
                                  <div className="col-4 bg-sky-white ps-3 border-down py-2">Service Liness</div>
                                  <div className="col-2 bg-sky-white ps-3 border-down py-2">UOM</div>
                                  <div className="col-2 bg-sky-white ps-3 border-down py-2">Quantity</div>
                                </div>
                              )}
                              <div className="d-flex pb-2 ps-3 py-1">
                                <div className="col-1" />
                                <div className="col-2 ps-3 border-down">{serv.number}</div>
                                <div className="col-4 text-justify text-break-all ps-3 border-down text-ellipsis">
                                  <span>
                                    {serv.serviceLineName}
                                  </span>

                                </div>
                                <div className="col-2 ps-3 border-down">{serv.uom || ''}</div>
                                <div className="col-2 ps-3 border-down">{serv.quantity || '-'}</div>

                              </div>
                            </>
                          );
                        })
                      }
                    </>
                  );
                })
              }
            </div>
          );
        })
      }
        </div>
      )}
      <Tooltip id="project-supplementary" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default previewContent;
