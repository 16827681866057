import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import ModalBox from '../../../../common/ModalBox';
import CreateSpecPackages from './CreateSpecPackages';
import Pagination from '../../../../common/pagination';
import { getSpecPackages, getVesselType, GetProjectTypeList, getMajorSpec, saveSpecPackages, getSpecPackageDetail, deleteSpecPackages, publishSpecPackage } from './services';
import helper from '../../../../../utils/helper';
import showAlert from '../../../../../utils/alert';
import SpecTable from './specTable';
import Loader from '../../../../common/loader';

const SpecPackages = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';

  const { generateOptions, charValidation, formValidation, handleValidation } = helper;
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [confimPublish, setConfimPublish] = useState(false);
  const initialData = {
    // tenantId: '',
    packageName: '',
    projectType: '',
    projectTypeName: '',
    vesselType: '',
    vesselTypeName: '',
    ageOfVessel: '',
    specificationMappingData: []
  };
  const ageOfVesselList = [
    { value: '0-5', label: '0-5' },
    { value: '6-10', label: '6-10' },
    { value: '11-15', label: '11-15' },
    { value: '16-20', label: '16-20' },
    { value: '21-25', label: '21-25' },
    { value: '26-30', label: '26-30' },
    { value: '30+', label: '30+' }
  ];
  const [formData, setFormData] = useState(initialData);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [formDataVesselType, setFormDataVesselType] = useState([]);
  const [ProjectTypeList, setProjectTypeList] = useState([]);
  const [isExpand, setIsExpand] = useState('');
  const [formDataToFillList, setFormDataToFillList] = useState([]);
  const [formDataToFillListBKP, setFormDataToFillListBKP] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  /* istanbul ignore next */
  const handleChangeType = (index, type, subIndex = '') => {
    const temp = cloneDeep(formDataToFillList);
    if (type === 'main') {
      if (temp[index]) { temp[index].isChecked = !temp[index]?.isChecked; }
      temp[index]?.childData.forEach((element, k) => {
        temp[index].childData[k].isChecked = temp[index].isChecked;
      });
    } else {
      temp[index].childData[subIndex].isChecked = !temp[index].childData[subIndex].isChecked;
      let found = true;
      temp[index].childData.forEach((element, k) => {
        if (!temp[index].childData[k].isChecked) {
          found = false;
        }
        temp[index].isChecked = found;
      });
    }
    setFormDataToFillList(temp);
  };
  /* istanbul ignore next */
  const handleChangeForm = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      if (field === 'projectType') {
        fd.projectTypeName = e.label;
      }
      if (field === 'vesselType') {
        fd.vesselTypeName = e.label;
      }
      fd[field] = e.value;
      fd.packageName = `${fd.projectTypeName}_${fd.vesselTypeName}_${fd.ageOfVessel}`;
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['packageName', 'projectType', 'vesselType', 'ageOfVessel'], fd, field);
  };
  const reloadTable = (page) => {
    getSpecPackages({
      page: page || currentpage,
      pageSize,
      search: searchText
    }).then((res) => {
      setLoading(false);
      setTableData(res.data);
      console.log('res?.data?.length', res?.data?.totalItems);
      setCount(res?.data?.totalItems);
    });
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    reloadTable();
    window.scrollTo(0, 0);
  }, [currentpage, pageSize]);

  useEffect(() => {
    getVesselType().then((res) => {
      // const generalConfig = cloneDeep(res?.data).map((item) => {
      //   const i = cloneDeep(item);
      //   i.value = item.id;
      //   i.label = `${item.name}${item.shortName}`;
      //   return i;
      // });
      // setFormDataVesselType(generalConfig);
      if (res.data && res.data.length) {
        setFormDataVesselType(generateOptions(res.data, 'id', 'name'));
      }
    });
    GetProjectTypeList(0).then((res) => {
      if (res.data && res.data.length) {
        setProjectTypeList(generateOptions(res.data, 'id', 'name'));
      }
    });
    setIsExpand('');
    getMajorSpec().then((res) => {
      setFormDataToFillList(res.data);
      setFormDataToFillListBKP(res.data);
    });
  }, []);

  const editData = (data) => {
    if (data.id) {
      getSpecPackageDetail(data.id).then((res) => {
        const iniData = {
          id: res.data?.id,
          prevousId: res.data?.prevousId,
          status: res.data?.status,
          tenantId: res.data?.tenantId,
          packageName: res.data?.packageName,
          projectType: res.data?.projectType,
          projectTypeName: res.data?.projectTypeName,
          vesselType: res.data?.vesselType,
          vesselTypeName: res.data?.vesselTypeName,
          ageOfVessel: res.data?.ageOfVessel,
          specificationMapping: res.data?.specificationMapping,
          specificationMappingData: []
        };
        setFormData(iniData);
        setFormDataToFillList(res.data.specificationMappingData);
        setOpenModal(true);
        setIsExpand('');
      });
    }
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    deleteSpecPackages(formData?.id).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert('Successfully Deleted', 'success');
        setOpenDeleteModal(false);
        setFormData(initialData);
      }
    });
  };
  /* istanbul ignore next */
  const publishApiCall = () => {
    setConfimPublish(false);
    publishSpecPackage({ id: formData.id }).then((res) => {
      if (res.success) {
        setOpenModal(false);
        reloadTable();
        showAlert(formData.status === 1 ? 'Successfully Republished' : 'Successfully Published', 'success');
      }
    });
  };
  /* istanbul ignore next */
  const saveApiCall = () => {
    if (formValidation(['packageName', 'projectType', 'vesselType', 'ageOfVessel'], formData)) {
      const formValues = cloneDeep(formData);
      formValues.specificationMappingData = formDataToFillList;
      saveSpecPackages(formValues).then((res) => {
        if (res.success) {
          reloadTable();
          setFormData(initialData);
          setOpenModal(false);
          if (formValues.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    if (document?.getElementsByClassName('scroll-wrap')[0]) {
      document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
    }
  };
  const createPackageHandleClick = () => {
    setOpenModal(true);
    setFormData(initialData);
    setFormDataToFillList(formDataToFillListBKP);
    setIsExpand('');
  };
  /* istanbul ignore next */
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  /* istanbul ignore next */
  const publishSpecPkg = () => {
    setConfimPublish(true);
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  return (
    <div data-testid="spec-specpackage">
      {loading ? <Loader /> : (
        <div className="card p-5 mb-4 height-fix">
          {openModal ? (
            <ModalBox
              modalClassName="large"
              heading="Create Package"
              onSave={() => saveApiCall()}
              closeModal={() => setOpenModal(false)}
              buttonName="Save"
              extraButton={formData.id ? (formData.status === 1 ? 'Republish' : 'Publish') : null}
              onClickExtra={() => publishSpecPkg()}
            >
              <CreateSpecPackages
                formData={formData}
                setFormData={setFormData}
                formDataVesselType={formDataVesselType}
                ProjectTypeList={ProjectTypeList}
                handleChangeType={handleChangeType}
                handleChangeForm={handleChangeForm}
                isExpand={isExpand}
                formDataToFillList={formDataToFillList}
                setIsExpand={setIsExpand}
                setFormDataToFillList={setFormDataToFillList}
                ageOfVesselList={ageOfVesselList}
              />
            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete spec package ?</p>
            </ModalBox>
          ) : (
            ''
          )}
          {confimPublish ? (
            <ModalBox
              modalClassName="medium"
              heading={formData.status === 1 ? 'Republish' : 'Publish'}
              onSave={() => publishApiCall()}
              closeModal={() => setConfimPublish(false)}
              buttonName={formData.status === 1 ? 'Republish' : 'Publish'}
            >
              {formData.status === 1 ? (
                <p>
                  Are you sure you want to republish?
                  Once republished, the mapped data will be available for application users.
                </p>
              )
                : (
                  <p>
                    Are you sure you want to publish?
                    Once republished, the mapped data will be available for application users.
                  </p>
                )}
            </ModalBox>
          ) : (
            ''
          )}
          <div className="d-flex mb-5 col-gap-1 spec-search">
            <div className="search large">
              <div className="d-flex justify-content-end">
                <span className="icon-search" id="lens" onClick={() => { onSearch({ key: 'Enter' }); }} />
                <span
                  className={searchText ? ' icon-close-line ' : ''}
                  onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                />
              </div>

              <input type="text" value={searchText} className="text-box large with-close" placeholder="Search by Package Name" id="search" onKeyPress={onSearch} onChange={(e) => { setSearchText(e.target.value); }} />
            </div>
            <button
              type="button"
              className="primary-btn large"
              id="create-pkg"
              onClick={() => {
                createPackageHandleClick();
              }}
            >
              Create Package
            </button>
          </div>
          <div>
            <SpecTable
              tableData={tableData}
              deteleData={deteleData}
              editData={editData}
              pageClick={pageClick}
            />
            {tableData?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.totalPages}
              records={tableData?.totalItems}
              currentpage={currentpage}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
            )}
          </div>
        </div>
      )}
    </div>

  );
};
export default SpecPackages;
