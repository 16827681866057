/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import SupplementaryTable from './supplementaryTable';
import ModalBox from '../../../../../../common/ModalBox';
import ImportReceivedQuote from './ImportReceived';
import { getVariationQuoteList, importYardQuoteDatas } from '../../../Tender/SupplementarySpecs/services';
import helper from '../../../../../../../utils/helper';
import showAlert from '../../../../../../../utils/alert';
import Loader from '../../../../../../common/loader';

const ReceivedQuotes = (props) => {
  const { recSpecList, onSecOpen, mainSec, viewMore,
    showsubSec, setRecSpecList, viewMoreAttachments, viewMoreComments,
    projectId, showView, projInfo,
    setShowView, isTSI, isLoading } = props;
  const { MAX_FILE_SIZE } = helper;
  const [openYardQuote, setOpenYardQuote] = useState(false);
  const [file, setFile] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  /* istanbul ignore next */
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE}`, 'error');
      } else {
        setFile(first);
      }
    } else if (first) {
      showAlert('Please upload a excel file type', 'error');
    }
  };
    /* istanbul ignore next */
  const saveApiCall = () => {
    const body = new FormData();
    body.append('file', file);
    const id = projectId;
    if (file) {
      importYardQuoteDatas(id, body)?.then((res) => {
        if (res?.data?.importSuccess) {
          setOpenYardQuote(false);
          getVariationQuoteList(projectId).then((resp) => {
            setRecSpecList(resp.data);
          });
          showAlert('File has been successfully Imported', 'success');
          setFileUploaded(true);
        } else {
          showAlert(res?.data?.message, 'error');
        }
        setFile('');
        setOpenYardQuote(false);
      });
    } else {
      showAlert('Please choose a file', 'error');
    }
  };

  return (
    <div>
      {isLoading ? <Loader /> : (
        <div className="" data-testid="received-quotes">
          <div className="mb-5">
            {isTSI && (
            <button
              type="button"
              className="secondary-btn large"
              disabled={projInfo.phase > 4}
              id="yard-quote"
              onClick={() => {
                setOpenYardQuote(true);
              }}
            >
              Import Received Quotes
            </button>
            )}

          </div>
          <div />
          {recSpecList?.length > 0 ? (
            <SupplementaryTable
              specList={recSpecList}
              onSecOpen={onSecOpen}
              mainSec={mainSec}
              showsubSec={showsubSec}
              setRecSpecList={setRecSpecList}
              viewMore={viewMore}
              viewMoreAttachments={viewMoreAttachments}
              viewMoreComments={viewMoreComments}
              showView={showView}
              setShowView={setShowView}
              isTSI={isTSI}
            />
          )
            : (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">
                  No data found
                </div>
              </div>
            )}
          {openYardQuote && (
          <ModalBox
            heading="Import Received Quotes"
            closeModal={() => {
              setOpenYardQuote(false);
              setFile('');
            }}
            onSave={() => saveApiCall()}
            buttonName="Import"
            modalClassName="medium modal-scroll"
          >
            <ImportReceivedQuote ondrop={ondrop} setFile={setFile} file={file} />
          </ModalBox>
          )}
        </div>
      )}

    </div>

  );
};
export default ReceivedQuotes;
