/* eslint-disable no-unused-vars */
/* istanbul ignore file */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { cloneDeep, map } from 'lodash';
import VendorContainer from './VendorContainer';
import Sections from './Sections';
import { s3UploadMutiple, getProjectDetailsTSI } from '../NewProject/services';
import { GetPaintCost, PaintCostRegion, SavePaintCost, SaveSection, importPaintCost, paintCostExport } from './services';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import RegionCard from './RegionCard';
import Loader from '../../../common/loader';
import CreateVendor from './CreateVendor';
import UploadCard from '../WohaPage/uploadCard';
import ModalBox from '../../../common/ModalBox';
import BlankPaintCost from './BlankPaintCost';

const PaintCost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { createUUID, charValidation, fieldValidation, roles, MAX_FILE_SIZE } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR || userRole === roles.ADMIN;
  const [file, setFile] = useState('');
  const [projInfo, setProjInfo] = useState();
  const initialRegions = [
    { isShow: true, label: 'Asia', value: 1 },
    { isShow: true, label: 'Europe', value: 2 },
    { isShow: true, label: 'Africa', value: 3 },
    { isShow: true, label: 'Middle East', value: 4 },
    { isShow: true, label: 'America', value: 5 }
  ];
  const [regionList, setRegionList] = useState([]);
  const [allRegionsHidden, setAllRegionsHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vloading, setVLoading] = useState(false);
  const [vendorList, setVendorList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [paintCostDetails, setPaintCostDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteVendorModal, setOpenDeleteVendorModal] = useState(false);
  const [addCliked, setIsAddClicked] = useState(false);
  const [vendorItem, setVendorItem] = useState();
  const [openModalVendor, setOpenModalVendor] = useState(false);
  const [openModalSection, setOpenModalSection] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(false);
  const [vendor, setVendor] = useState('');
  const [newSection, setNewSection] = useState('');
  const [openModalRegionDelete, setOpenModalRegionDelete] = useState(false);
  const [isOpenRow, setIsOpenRow] = useState([]);
  const [isOpenHull, setIsOpenHull] = useState('hull');
  const [collapseVendor, setCollapseVendor] = useState([]);
  const [saveDisable, setsaveDisable] = useState(false);
  const [showBlank, setShowBlank] = useState(true);
  const initialFormData = {
    attachments: []
  };
  const [formDataAttachment, setAttachFormData] = useState(initialFormData);
  const [showAttachments, setShowAttachments] = useState(false);
  const acceptedFiles = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/bmp',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  const [viewUpload, setViewUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isOpenModal, setisOpenModal] = useState();

  useEffect(() => {
    const d = cloneDeep(regionList) || [];
    let f = true;
    d.forEach((ele) => {
      if (ele.isShow) {
        f = false;
      }
    });
    setAllRegionsHidden(f);
  }, [regionList]);
  /* istanbul ignore next */
  useEffect(() => {
    if (vendorList.length && regionList.length) {
      const slider = document.querySelector('.items');
      if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
          isDown = true;
          slider.classList.add('active');
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mouseup', () => {
          isDown = false;
          slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - slider.offsetLeft;
          const walk = (x - startX) * 3; // scroll-fast
          slider.scrollLeft = scrollLeft - walk;
        });
      }
    }
  }, [vendorList, regionList]);
  const reloadTable = () => {
    setVLoading(true);
    GetPaintCost(id).then((res) => {
      setVLoading(false);
      if (res.data) {
        setPaintCostDetails(res.data?.sections);
        if (isOpenRow && isOpenRow.length) {
          //
        } else {
          const d = [];
          d.push(res.data?.sections[0]?.id);
          setIsOpenRow(d);
        }
        setVendorList(res.data?.vendorDatas);
        if (res.data?.vendorDatas && res.data?.vendorDatas.length) {
          setShowBlank(false);
        }
      }
      document.body.classList.remove('model-open');
    });
    PaintCostRegion(id).then((res) => {
      if (res.data) {
        const rList = res.data.map((i) => {
          return {
            label: i.region,
            value: i.regionValue,
            isShow: true
          };
        });
        setRegionList(rList);
      }
    });
  };
  const reloadAttachments = () => {
    setVLoading(true);
    GetPaintCost(id).then((res) => {
      setVLoading(false);
      if (res.data) {
        // setPaintCostDetails(res.data?.sections);
        setVendorList(res.data?.vendorDatas);
      }
    });
    // PaintCostRegion(id).then((res) => {
    //   if (res.data) {
    //     const rList = res.data.map((i) => {
    //       return {
    //         label: i.region,
    //         value: i.regionValue,
    //         isShow: true
    //       };
    //     });
    //     setRegionList(rList);
    //   }
    // });
  };
  const updateVendorListWithNewSection = (sectionRow, d) => {
    const fData = cloneDeep(vendorList) || [];
    vendorList.forEach((e, index) => {
      fData[index].rowData?.push({
        sectionId: sectionRow.id,
        type: 5,
        regionGrandTotal: regionList.map((i) => {
          return {
            region: i.label,
            regionValue: i.value,
            grandTotal: 0
          };
        }),
        vendorSectionRow: []
        // vendorSectionRow: sectionRow.rows.map(((dt) => {
        //   return {
        //     rowId: dt.id,
        //     paintVolume: 0,
        //     estimatedQuantity: 0,
        //     regionPrices: regionList.map(((i) => {
        //       return {
        //         region: i.label,
        //         regionValue: i.value,
        //         pricePerLitre: 0,
        //         totalPrice: 0,
        //         grandTotal: 0
        //       };
        //     }))
        //   };
        // }))
      });
    });
    setVendorList(fData);
    const formData = {
      sections: d,
      vendorDatas: fData,
      projectId: id
    };
    SavePaintCost(formData).then(() => {
      reloadTable();
    });
  };
  const addSection = () => {
    if (newSection.trim()) {
      const d = cloneDeep(paintCostDetails);
      const paint = {
        sectionName: newSection,
        projectId: id,
        type: 5,
        rows: [
          // {
          //   tuFc: '',
          //   paintSpecification: '',
          //   surfaceArea: 0,
          //   minDft: 0,
          //   estimatedLoss: 0
          // }
        ],
        totalArea: 0
      };
      SaveSection(paint).then((res) => {
        if (res.success) {
          const newPaint = {
            ...paint,
            id: res.data.id,
            rows: []
          };
          d.push(newPaint);
          setPaintCostDetails(d);
          setOpenModalSection(false);
          updateVendorListWithNewSection(newPaint, d);
          setNewSection('');
          showAlert('Successfully Saved', 'success');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const getProjDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      if (res?.success) {
        setLoading(false);
        setProjInfo(res.data);
      }
    });
  };
  useEffect(() => {
    getProjDetails();
  }, []);
  const SaveData = (dataForm = '') => {
    setsaveDisable(true);
    let formData = '';
    if (dataForm) {
      formData = dataForm;
    } else {
      formData = {
        sections: paintCostDetails,
        vendorDatas: vendorList,
        projectId: id
      };
    }
    SavePaintCost(formData).then((res) => {
      if (res.success) {
        if (dataForm === '') {
          showAlert('Successfully Saved', 'success');
        }
        reloadTable();
      }
      setsaveDisable(false);
    });
  };
  /* istanbul ignore next */
  const addVendor = () => {
    if (vendor.trim()) {
      const d = cloneDeep(vendorList) || [];
      let found = false;
      d.forEach((ele) => {
        if (ele.vendor.toLowerCase().trim() === vendor.toLowerCase().trim()) {
          found = true;
        }
      });
      if (found) {
        showAlert('Vendor already added', 'error');
      } else {
        const vendorData = {
          vendor: vendor.toUpperCase(),
          withGuarantee: false,
          isSelected: false,
          attachments: [],
          regionGrandTotal: regionList.map((i) => {
            return {
              region: i.label,
              regionValue: i.value,
              grandTotal: 0
            };
          }),
          rowData: paintCostDetails.map((p) => {
            return {
              sectionId: p.id,
              type: p.type,
              regionGrandTotal: regionList.map((i) => {
                return {
                  region: i.label,
                  regionValue: i.value,
                  grandTotal: 0
                };
              }),
              vendorSectionRow: p.rows.map((dt) => {
                return {
                  rowId: dt.rowId,
                  paintVolume: 0,
                  estimatedQuantity: 0,
                  regionPrices: regionList.map((i) => {
                    return {
                      region: i.label,
                      regionValue: i.value,
                      pricePerLitre: 0,
                      totalPrice: 0,
                      grandTotal: 0
                    };
                  })
                };
              })
            };
          })
        };
        d.push(vendorData);
        showAlert('Successfully Added', 'success');
        setVendorList(d);
        setVendor('');
        // setOpenModalVendor('');
        if (!showBlank) {
          const i = {
            sections: paintCostDetails,
            vendorDatas: d,
            projectId: id
          };
          SaveData(i);
        }
      }
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  /* istanbul ignore next */
  const removeVendor = (removeIndex) => {
    const d = cloneDeep(vendorList) || [];
    d.splice(removeIndex, 1);
    showAlert('Successfully Removed', 'success');
    setVendorList(d);
    setOpenDeleteVendorModal(false);
    if (!showBlank) {
      const i = {
        sections: paintCostDetails,
        vendorDatas: d,
        projectId: id
      };
      SaveData(i);
    }
  };
  /* istanbul ignore next */
  const updateVendorList = () => {
    const vList = cloneDeep(vendorList) || [];
    vendorList.forEach((e, mi) => {
      vList[mi]?.regionGrandTotal?.push({
        region: selectedRegion.label,
        regionValue: selectedRegion.value,
        grandTotal: 0
      });
      e.rowData?.forEach((s, si) => {
        s.vendorSectionRow.forEach((d, di) => {
          vList[mi]?.rowData[si]?.regionGrandTotal?.push({
            region: selectedRegion.label,
            regionValue: selectedRegion.value,
            grandTotal: 0
          });
          vList[mi]?.rowData[si]?.vendorSectionRow[di]?.regionPrices?.push({
            region: selectedRegion.label,
            regionValue: selectedRegion.value,
            pricePerLitre: 0,
            totalPrice: 0,
            grandTotal: 0
          });
        });
      });
    });
    setVendorList(vList);
    setSelectedRegion();
    if (!showBlank) {
      const i = {
        sections: paintCostDetails,
        vendorDatas: vList,
        projectId: id
      };
      SaveData(i);
    }
  };
  const exportExcel = () => {
    setExportDisabled(true);
    paintCostExport(id)
      .then(() => {
        setExportDisabled(false);
        showAlert('Successfully Downloaded', 'success');
      })
      .catch(() => {
        setExportDisabled(false);
      });
  };
  /* istanbul ignore next */
  const addRegion = () => {
    if (selectedRegion) {
      const d = cloneDeep(regionList) || [];
      const pexIds = map(d, 'value');
      if (pexIds.includes(selectedRegion.value)) {
        showAlert('Region already selected', 'error');
      } else {
        d.push(selectedRegion);
        showAlert('Successfully Added', 'success');
        setRegionList(d);
        if (vendorList && vendorList.length) {
          updateVendorList();
        }
        setOpenModal(false);
      }
    } else {
      showAlert('Please select region', 'error');
    }
    setSelectedRegion('');
  };
  /* istanbul ignore next */
  const updateDeleteRegionVendorList = (findIx, dd) => {
    const vList = cloneDeep(vendorList) || [];
    vendorList.forEach((e, mi) => {
      vList[mi]?.regionGrandTotal.splice(findIx, 1);
      e.rowData.forEach((s, si) => {
        s.vendorSectionRow.forEach((d, di) => {
          vList[mi]?.rowData[si]?.regionGrandTotal.splice(findIx, 1);
          vList[mi]?.rowData[si]?.vendorSectionRow[di]?.regionPrices?.splice(findIx, 1);
        });
      });
    });
    setVendorList(vList);
    setRegionList(dd);
    if (!showBlank) {
      const i = {
        sections: paintCostDetails,
        vendorDatas: vList,
        projectId: id
      };
      SaveData(i);
    }
    showAlert('Successfully Removed', 'success');
    setOpenModalRegionDelete(false);
  };
  /* istanbul ignore next */
  const removeRegion = () => {
    let d = cloneDeep(regionList) || [];
    const pexIds = map(d, 'value');
    if (pexIds.includes(openModalRegionDelete.value)) {
      const sd = [];
      let findIx = '';
      d.forEach((ele, index) => {
        if (ele.value === openModalRegionDelete.value) findIx = index;
        if (ele.value !== openModalRegionDelete.value) sd.push(ele);
      });
      d = sd;
      if (findIx !== '') {
        updateDeleteRegionVendorList(findIx, d);
      }
    }
  };

  const updateVendorListWithNewRow = (newIndex, newRowId, pl) => {
    const vList = cloneDeep(vendorList) || [];
    vendorList.forEach((e, mi) => {
      e.rowData.forEach((s, si) => {
        if (si === newIndex) {
          vList[mi].rowData[si].regionGrandTotal = regionList.map((i) => {
            return {
              region: i.label,
              regionValue: i.value,
              grandTotal: 0
            };
          });
          vList[mi]?.rowData[si]?.vendorSectionRow.push({
            tuFc: '',
            paintSpecification: '',
            areaPercentage: 0,
            surfaceArea: 0,
            minDft: 0,
            estimatedLoss: 0,
            paintVolume: 0,
            estimatedQuantity: 0,
            rowId: newRowId,
            regionPrices: regionList.map((i) => {
              return {
                region: i.label,
                regionValue: i.value,
                pricePerLitre: 0,
                totalPrice: 0,
                grandTotal: 0
              };
            })
          });
        }
      });
    });
    setVendorList(vList);
    setSelectedRegion();
    const i = {
      sections: pl,
      vendorDatas: vList,
      projectId: id
    };
    SaveData(i);
  };
  const handleAddNew = (index) => {
    const pl = cloneDeep(paintCostDetails);
    const newRowId = pl[index]?.rows?.length + 1;
    pl[index]?.rows.push({
      tuFc: '',
      paintSpecification: '',
      areaPercentage: 0,
      surfaceArea: 0,
      minDft: 0,
      estimatedLoss: 0,
      rowId: newRowId
    });
    setPaintCostDetails(pl);
    updateVendorListWithNewRow(index, newRowId, pl);
  };
  const handleRemoveAddedRow = () => {
    const { index, indexRemoved } = isOpenModal;
    const pl = cloneDeep(paintCostDetails);
    pl[index]?.rows.splice(indexRemoved, 1);
    const fData = cloneDeep(vendorList) || [];
    fData.forEach((v, vendorIndex) => {
      if (fData && fData[vendorIndex]) {
        fData[vendorIndex]?.rowData.forEach((v1, ikey) => {
          if (ikey === index) {
            fData[vendorIndex].rowData[ikey].vendorSectionRow?.splice(indexRemoved, 1);
            if (fData[vendorIndex].rowData[ikey].vendorSectionRow && fData[vendorIndex].rowData[ikey].vendorSectionRow.length) {
              fData[vendorIndex].rowData[ikey].vendorSectionRow?.forEach((v2, iKsy) => {
                fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices?.forEach((i, regionIndex) => {
                  fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex].totalPrice = fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].estimatedQuantity
                  * fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex].pricePerLitre;
                });
                fData[vendorIndex].rowData[ikey].regionGrandTotal?.forEach((gt, gIndex) => {
                  let t = 0;
                  fData[vendorIndex].rowData[ikey].vendorSectionRow?.forEach((i, iindex) => {
                    fData[vendorIndex].rowData[ikey].vendorSectionRow[iindex].regionPrices?.forEach((r, rindex) => {
                      if (gIndex === rindex) {
                        t += r.totalPrice;
                      }
                    });
                  });
                  fData[vendorIndex].rowData[ikey].regionGrandTotal[gIndex].grandTotal = t;
                });
              });
            } else {
              fData[vendorIndex].rowData[ikey].regionGrandTotal = regionList.map((i) => {
                return {
                  region: i.label,
                  regionValue: i.value,
                  grandTotal: 0
                };
              });
            }
          }
        });
      }
    });
    regionList.forEach((reg, regIndex) => {
      fData.forEach((v, vIndex) => {
        let outerTotal = 0;
        v.rowData.forEach((r) => {
          r.regionGrandTotal.forEach((g, gIndex) => {
            if (regIndex === gIndex) {
              outerTotal += g.grandTotal;
            }
          });
        });
        fData[vIndex].regionGrandTotal[regIndex].grandTotal = outerTotal;
      });
    });
    const i = {
      sections: pl,
      vendorDatas: fData,
      projectId: id
    };
    SaveData(i);
    setisOpenModal(false);
  };
  useEffect(() => {
    // setLoading(true);
    reloadTable();
  }, []);

  /* istanbul ignore next */
  const handleChangeSection = (field, value, sectionIndex, rowIndex) => {
    const fData = cloneDeep(paintCostDetails);
    if (fData && fData[sectionIndex]) {
      if (field === 'totalArea') {
        fData[sectionIndex][field] = charValidation(value.replace(/[^0-9,.]/g, ''), 7);
      } else if (field === 'paintSpecification') {
        fData[sectionIndex].rows[rowIndex][field] = value;
      } else if (field === 'tuFc') {
        fData[sectionIndex].rows[rowIndex][field] = value?.value;
      } else {
        fData[sectionIndex].rows[rowIndex][field] = charValidation(value.replace(/[^0-9,.]/g, ''), 7);
      }
      fData[sectionIndex].rows?.forEach((ele, eleIndex) => {
        if (fData[sectionIndex].rows[eleIndex].tuFc === 'tu') {
          fData[sectionIndex].rows[eleIndex].surfaceArea = (
            (fData[sectionIndex].rows[eleIndex].areaPercentage / 100)
            * fData[sectionIndex].totalArea
          ).toFixed(2);
        } else if (fData[sectionIndex].rows[eleIndex].tuFc === 'fc') {
          fData[sectionIndex].rows[eleIndex].surfaceArea = fData[sectionIndex].totalArea;
          fData[sectionIndex].rows[eleIndex].areaPercentage = 100;
        }
      });
      setPaintCostDetails(fData);
    }
  };

  const saveBlank = () => {
    if (vendorList && vendorList.length && regionList && regionList.length) {
      if (showBlank) {
        // eslint-disable-next-line no-use-before-define
        handleAddNew(0);
      } else {
        SaveData();
      }
    } else if (!vendorList?.length && !regionList?.length) {
      showAlert('Please add vendor and region', 'error');
    } else if (!vendorList || !vendorList.length) {
      showAlert('Please add vendor', 'error');
    } else {
      showAlert('Please add region', 'error');
    }
  };
  const handleUpdateTotal = (fDataa) => {
    const fData = cloneDeep(fDataa);
    regionList.forEach((reg, regIndex) => {
      fData.forEach((v, vIndex) => {
        let outerTotal = 0;
        v.rowData.forEach((r) => {
          r.regionGrandTotal.forEach((g, gIndex) => {
            if (regIndex === gIndex) {
              outerTotal += g.grandTotal;
            }
          });
        });
        fData[vIndex].regionGrandTotal[regIndex].grandTotal = outerTotal;
      });
    });
    setVendorList(fData);
  };
  /* istanbul ignore next */
  const handleChangeVendor = (value, field, vendorIndex, ikey, iKsy) => {
    const fData = cloneDeep(vendorList) || [];
    if (fData && fData[vendorIndex]) {
      fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy][field] = fieldValidation(value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6);
      fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices?.forEach((i, regionIndex) => {
        fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex].totalPrice = fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].estimatedQuantity
          * fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex].pricePerLitre;
      });
      fData[vendorIndex].rowData[ikey].regionGrandTotal?.forEach((gt, gIndex) => {
        let t = 0;
        fData[vendorIndex].rowData[ikey].vendorSectionRow?.forEach((i, iindex) => {
          fData[vendorIndex].rowData[ikey].vendorSectionRow[iindex].regionPrices?.forEach((r, rindex) => {
            if (gIndex === rindex) {
              t += r.totalPrice;
            }
          });
        });
        fData[vendorIndex].rowData[ikey].regionGrandTotal[gIndex].grandTotal = t;
      });

      setVendorList(fData);
      handleUpdateTotal(fData);
    }
  };
  /* istanbul ignore next */
  const handleChangeVendorRegion = (value, field, vendorIndex, ikey, iKsy, regionIndex) => {
    const fData = cloneDeep(vendorList) || [];
    if (fData && fData[vendorIndex]) {
      fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex][field] = value.replace(/[^0-9,.]/g, '');
      fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex].totalPrice = fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].estimatedQuantity
        * fData[vendorIndex].rowData[ikey].vendorSectionRow[iKsy].regionPrices[regionIndex].pricePerLitre;
      fData[vendorIndex].rowData[ikey].regionGrandTotal?.forEach((gt, gIndex) => {
        let t = 0;
        fData[vendorIndex].rowData[ikey].vendorSectionRow?.forEach((i, iindex) => {
          fData[vendorIndex].rowData[ikey].vendorSectionRow[iindex].regionPrices?.forEach((r, rindex) => {
            if (gIndex === rindex) {
              t += r.totalPrice;
            }
          });
        });
        fData[vendorIndex].rowData[ikey].regionGrandTotal[gIndex].grandTotal = t;
      });
      setVendorList(fData);
      handleUpdateTotal(fData);
    }
  };
  const handleCollapseVendor = (vendorData) => {
    let f = cloneDeep(collapseVendor);
    if (collapseVendor.includes(vendorData.vendor)) {
      f = f.filter((i) => i !== vendorData.vendor);
    } else {
      f.push(vendorData.vendor);
    }
    setCollapseVendor(f);
  };
  const saveAttachments = (vName) => {
    const fData = cloneDeep(vendorList) || [];
    fData.forEach((e, index) => {
      if (e.vendor === vName) {
        fData[index].attachments = [...fData[index]?.attachments, ...formDataAttachment.attachments];
        setVendorItem(fData[index]);
      }
    });
    setVendorList(fData);
    setIsAddClicked(false);
    const i = {
      sections: paintCostDetails,
      vendorDatas: fData,
      projectId: id
    };
    SaveData(i);
  };
  const handleGuarantee = (vName) => {
    const fData = cloneDeep(vendorList) || [];
    fData.forEach((e, venIndex) => {
      if (e.vendor === vName) {
        fData[venIndex].withGuarantee = !fData[venIndex].withGuarantee;
      }
    });
    setVendorList(fData);
    setTimeout(() => {
      setLoading(false);
    }, 50);
  };
  const handleRegionHide = (i) => {
    const d = cloneDeep(regionList) || [];
    d.forEach((ele, index) => {
      if (ele.value === i.value) {
        d[index].isShow = !d[index].isShow;
      }
    });
    setRegionList(d);
  };
  const handleSelectVendor = (i) => {
    const d = cloneDeep(vendorList) || [];
    d.forEach((ele, index) => {
      if (index === i) {
        d[index].isSelected = !d[index].isSelected;
      }
    });
    setVendorList(d);
  };
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = formDataAttachment?.attachments?.map((items) => {
        return items.name;
      });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formDataAttachment);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fname: item.name,
              path: item.path,
              type: item.type,
              size: item.size
            };

            return attach.attachments.push(newItem);
          });
          setAttachFormData(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
    // const showUpload = () => {
    //   setViewUpload(true);
    //   setFile('');
    // };
  };
  const onUpload = () => {
    const body = new FormData();
    body.append('file', file);
    setIsUploading(true);
    importPaintCost(id, body).then((res) => {
      if (res.success) {
        reloadTable();
        // getPurchaseOrder();
        // totalSpecCount();
        showAlert('Successfully Imported', 'success');
        setViewUpload(false);
        setIsUploading(false);
        setFile('');
        // onReload();
      }
    });
  };
  const saveVendorItem = (ii) => {
    setVendorItem(ii);
    const fData = cloneDeep(vendorList) || [];
    fData.forEach((e, index) => {
      if (e.vendor === ii.vendor) {
        fData[index] = ii;
      }
    });
    setVendorList(fData);
    setIsAddClicked(false);
    const i = {
      sections: paintCostDetails,
      vendorDatas: fData,
      projectId: id
    };
    SaveData(i);
  };
  return (
    <div data-testid="p-1">
      {vloading ? (
        <div className="window-min-height">
          <Loader />
        </div>
      ) : (
        <>
          <div className="sub-header" data-testid="tsi-task">
            <div className="row">
              <div className="col-3 d-flex justify-content-start">
                <button
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    userRole === roles.ADMIN ? navigate('/admin/projectList') : navigate('/user/projects');
                  }}
                  type="button"
                  className="link-btn me-4"
                >
                  <span className="icon-arrow-left2 me-2" />
                  Back to Projects
                </button>
                <div className="">
                  <h2 className="main-head ">Paint Cost</h2>
                  <div className="d-flex Currency-media">
                    <div className="font-bold">Currency:&nbsp;</div>
                    <div>USD (Dollar)</div>
                  </div>
                </div>
              </div>
              {showBlank ? (
                ''
              ) : (
                <RegionCard
                  regionList={regionList}
                  handleRegionHide={handleRegionHide}
                  setOpenModalRegionDelete={setOpenModalRegionDelete}
                  setOpenModal={setOpenModal}
                  addRegion={addRegion}
                  openModal={openModal}
                  setSelectedRegion={setSelectedRegion}
                  initialRegions={initialRegions}
                  openModalRegionDelete={openModalRegionDelete}
                  removeRegion={removeRegion}
                  isTSI={isTSI}
                />
              )}
              {showBlank ? (
                ''
              ) : (
                <div className="align-self-sm-center col-2 d-flex justify-content-end ">
                  <button id="add-new" onClick={() => setOpenModalVendor(true)} type="button" className="secondary-btn secondary-btn-border">
                    Vendors (
                    {vendorList?.length}
                    )
                  </button>
                </div>
              )}
            </div>
          </div>
          {showBlank ? (
            <BlankPaintCost
              setVendor={setVendor}
              setOpenModalVendor={setOpenModalVendor}
              addVendor={addVendor}
              vendorList={vendorList}
              vendor={vendor}
              isTSI={isTSI}
              regionList={regionList}
              handleRegionHide={handleRegionHide}
              setOpenModalRegionDelete={setOpenModalRegionDelete}
              setOpenModal={setOpenModal}
              addRegion={addRegion}
              openModal={openModal}
              setSelectedRegion={setSelectedRegion}
              initialRegions={initialRegions}
              openModalRegionDelete={openModalRegionDelete}
              removeRegion={removeRegion}
              selectedRegion={selectedRegion}
              setOpenDeleteVendorModal={setOpenDeleteVendorModal}
            />
          ) : (
            <div className="content-wrap mt-4 paint-min-item-height ">
              <div className="container-fluid">
                <div className="paint-cost">
                  <div className="grid-container">
                    <main className="grid-item main d-flex mt-5">
                      <div className="item fixed-item card-inner-table">
                        <Sections
                          isOpenRow={isOpenRow}
                          setIsOpenRow={setIsOpenRow}
                          isOpenHull={isOpenHull}
                          setIsOpenHull={setIsOpenHull}
                          paintCostDetails={paintCostDetails}
                          handleAddNew={handleAddNew}
                          setOpenModalSection={setOpenModalSection}
                          handleChangeSection={handleChangeSection}
                          openModalSection={openModalSection}
                          addSection={addSection}
                          setNewSection={setNewSection}
                          newSection={newSection}
                          isTSI={isTSI}
                          handleRemoveAddedRow={handleRemoveAddedRow}
                          isOpenModal={isOpenModal}
                          setisOpenModal={setisOpenModal}
                        />
                      </div>
                      <VendorContainer
                        isOpenRow={isOpenRow}
                        setIsOpenRow={setIsOpenRow}
                        isOpenHull={isOpenHull}
                        setIsOpenHull={setIsOpenHull}
                        vendorList={vendorList}
                        regions={regionList}
                        handleChangeVendor={handleChangeVendor}
                        ondrop={ondrop}
                        handleChangeVendorRegion={handleChangeVendorRegion}
                        collapseVendor={collapseVendor}
                        handleCollapseVendor={handleCollapseVendor}
                        handleGuarantee={handleGuarantee}
                        loading={loading}
                        handleSelectVendor={handleSelectVendor}
                        allRegionsHidden={allRegionsHidden}
                        isTSI={isTSI}
                        formDataAttachment={formDataAttachment}
                        setAttachFormData={setAttachFormData}
                        saveAttachments={saveAttachments}
                        showAttachments={showAttachments}
                        setShowAttachments={setShowAttachments}
                        initialFormData={initialFormData}
                        addCliked={addCliked}
                        setIsAddClicked={setIsAddClicked}
                        vendorItem={vendorItem}
                        setVendorItem={setVendorItem}
                        saveVendorItem={saveVendorItem}
                      />
                    </main>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {openModalVendor ? (
        <CreateVendor
          setVendor={setVendor}
          setOpenModalVendor={setOpenModalVendor}
          addVendor={addVendor}
          vendorList={vendorList}
          vendor={vendor}
          isTSI={isTSI}
          setOpenDeleteVendorModal={setOpenDeleteVendorModal}
        />
      ) : (
        ''
      )}

      {viewUpload && (
        <ModalBox
          modalClassName="medium"
          closeModal={() => setViewUpload(false)}
          heading="Upload Excel"
        >
          <UploadCard paintcost file={file} setFile={setFile} onUpload={onUpload} isUploading={isUploading} />
          {' '}
        </ModalBox>
      )}

      <div className="fixed-footer-close card d-flex justify-content-end col-gap-1 pe-4">
        {userRole === 'admin' ? (
          <div className="text-black font-12 footer-repair" style={{ marginRight: '350px' }}>
            <div>© 2024 ThinkPalm</div>

          </div>

        ) : (
          <div className="text-black font-12 footer-repair" style={{ marginRight: '444px' }}>
            <div>© 2024 ThinkPalm</div>

          </div>
        )}

        <button disabled={window.history?.length < 3} onClick={() => window.history.back()} type="button" className="secondary-btn large">
          Back
        </button>
        {isTSI && (
          <button
            onClick={() => {
              if (showBlank) {
                saveBlank();
              } else {
                SaveData();
              }
            }}
            type="button"
            className="primary-btn large"
            id="saveId"
            disabled={saveDisable || projInfo?.isClosed}
          >
            Save Changes
          </button>
        )}
        {showBlank ? (
          ''
        ) : (
          <button id="add-new" onClick={() => exportExcel()} type="button" disabled={exportDisabled} className="primary-btn large ">
            Export Excel
          </button>
        )}
      </div>
      {openDeleteVendorModal !== false ? (
        <ModalBox
          modalClassName="medium"
          onSave={() => removeVendor(openDeleteVendorModal)}
          closeModal={() => setOpenDeleteVendorModal(false)}
          heading="Delete"
          buttonName="Delete"
        >
          <p>Are you sure you want to delete Vendor ?</p>
        </ModalBox>
      ) : (
        ''
      )}
      {isOpenModal ? (
        <ModalBox
          openDeleteModal={isOpenModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => handleRemoveAddedRow()}
          closeModal={() => setisOpenModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete row ?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default PaintCost;
