/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import brandLogo from '../../images/brand-logo.svg';
import userImg from '../../images/user-placeholder.jpg';
import helper from '../../utils/helper';
import { getProfileData } from '../Login/services';
import UpdatePassword from './Users/UserList/updatePassword/updatePassword';
import ViewProfile from './viewProfile';
// import UserManual from './Resource/UserManual';

const AdminHeader = () => {
  const navigate = useNavigate();
  const DashboardLocation = useLocation();
  const location = window.location.pathname;
  const { logOut } = helper;
  const signOut = () => {
    logOut(location);
  };
  const initialState = {
    firstName: '',
    lastName: '',
    middleName: '',
    profileImage: ''
  };
  const [user, setuser] = useState(initialState);
  const [showView, setShowView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // const [isclose, setIsclose] = useState(false);
  const [showUpdatePasswordPage, setShowUpdatePasswordPage] = useState(false);
  useEffect(() => {
    const USER_ID = localStorage.getItem('USER_ID');
    if (USER_ID) {
      getProfileData(USER_ID).then((res) => {
        sessionStorage.setItem('userDetails', JSON.stringify(res.data.roleData));
        const userDetails = res?.data;
        if (userDetails) {
          setuser(userDetails);
        }
      });
    }
  }, [location, DashboardLocation]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.matches('.dashboard-dropdown, .dashboard-dropdown *')) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  // useEffect(() => {
  //   const handleClickOutsides = (event) => {
  //     if (!event.target.matches('.dashboard-dropdown, .dashboard-dropdown *')) {
  //       setIsSidebarOpen(false);
  //     }
  //   };
  //   if (isSidebarOpen) {
  //     document.addEventListener('mousedown', handleClickOutsides);
  //   }
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutsides);
  //   };
  // }, [isSidebarOpen]);
  useEffect(() => {
    setIsOpen(false);
    setIsSidebarOpen(false);
  }, [DashboardLocation]);

  const handleViewProfile = () => {
    setShowView(true);
  };

  const onViewClose = () => {
    setShowView(false);
  };

  const onUpdatePasswordPageClose = () => {
    setShowUpdatePasswordPage(false);
  };

  const handleUpdatePassword = () => {
    setShowUpdatePasswordPage(true);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  // const toggle = () => {
  //   setIsclose(!isclose);
  // };
  function useMediaQuery(query) {
    const [matches, setMatches] = useState(window?.matchMedia(query)?.matches);

    useEffect(() => {
      const mediaQueryList = window?.matchMedia(query);
      const documentChangeHandler = () => setMatches(mediaQueryList.matches);

      mediaQueryList.addListener(documentChangeHandler);

      return () => {
        mediaQueryList.removeListener(documentChangeHandler);
      };
    }, [query]);

    return matches;
  }
  const isPageWide = useMediaQuery('(min-width: 992px)');

  const nameUser = `${user.firstName} ${user.middleName} ${user.lastName}`;
  let stagingBlink = '';
  let stagingBlinkHContent = 'This is the Maridock live environment. Please enter project specific actual data only.';
  if (window.location?.hostname?.includes('app.maridock')) {
    stagingBlink = 'app-build';
    stagingBlinkHContent = 'This is the Maridock live environment. Please enter project specific actual data only.';
  }
  if (window.location?.hostname?.includes('staging.maridock')) {
    stagingBlink = 'staging-build';
  }
  return (
    <>
      <div className="header-main">
        <div
          data-tooltip-id="live-content"
          data-tooltip-content={stagingBlinkHContent}
          className={`brand-logo ${stagingBlink}`}
        >
          <a href="">
            <img src={brandLogo} alt="Maridock Logo" className="brand-logo-img" />
          </a>
        </div>
        <div className={`main-nav ${isSidebarOpen ? 'show' : ''}`}>
          <ul className="d-flex nav-item">
            <li
              className={location.includes('/admin/dashboard') ? 'active' : ''}
              onClick={() => {
                navigate('/admin/dashboard');
              }}
            >
              <a className="">Dashboard</a>
            </li>
            <li
              className={location.includes('/admin/projectList') ? 'active' : ''}
              onClick={() => {
                navigate('/admin/projectList');
              }}
            >
              <a className="">Projects</a>
            </li>
            <li
              className={location.includes('/admin/data') ? 'active' : ''}
              onClick={() => {
                navigate('/admin/data/vesseldata');
              }}
            >
              <a className="">Data Config</a>
            </li>
            <li
              className={location.includes('/admin/vessel') || location.includes('/admin/details') ? 'active' : 'md-menu'}
              onClick={() => {
                navigate('/admin/vessel/management');
              }}
            >
              <a className="">Vessel & Management</a>
            </li>
            <li
              className={location.includes('/admin/users') ? 'active' : 'md-menu'}
              onClick={() => {
                navigate('/admin/users/roles');
              }}
            >
              <a className="">Users & Permissions</a>
            </li>
            <li
              className={location.includes('/admin/resource') ? 'active' : 'md-menu'}
              onClick={() => {
                navigate('/admin/resource/web');
              }}
            >
              <a className="">Resources</a>
            </li>
            <li className="md-menu-ctrl">
              {
                isOpen
                && (
                <ul className=" dashboard-dropdown d-block">
                  <li
                    className={location.includes('/admin/vessel') || location.includes('/admin/details') ? 'active' : ''}
                    onClick={() => {
                      navigate('/admin/vessel/management');
                    }}
                  >
                    <a className="">Vessel & Management</a>
                  </li>
                  <li
                    className={location.includes('/admin/users') ? 'active' : ''}
                    onClick={() => {
                      navigate('/admin/users/roles');
                    }}
                  >
                    <a className="">Users & Permissions</a>
                  </li>
                  <li
                    className={location.includes('/admin/resource') ? 'active' : ''}
                    onClick={() => {
                      navigate('/admin/resource/web');
                    }}
                  >
                    <a className="">Resources</a>
                  </li>
                </ul>
                )
              }
              <a><span onClick={toggleDropdown} className="icon-dotted-menu-horizontal" /></a>
            </li>
          </ul>
        </div>
        <div className="usermenu-item">
          <ul className="d-flex item-set">
            {/* {!location.includes('/admin/resource') && ( */}
            <li className="me-4" data-tooltip-id="project-manual" data-tooltip-content="User Manual">
              <a href={`${window.location.origin}/usermanual`} target="_blank" className="circle-ico-btn relative" rel="noreferrer">
                <span className="icon-help-book" data-tooltip-id="project-manual" data-tooltip-content="User Manual" />
                {/* <span className="dot-indicator" /> */}
              </a>
            </li>

            <li id="drop-down1" className="dropdown down-animate">
              <a href="#" className="d-flex ms-4">
                <div className="profile-img">{user?.profileImage ? <img src={user?.profileImage} alt="" /> : <img src={userImg} alt="" />}</div>
                <div className="d-flex flex-column u-box">
                  <div className="user-nme d-flex">
                    <h6 className="head-14 mb-2 d-flex text-capitalize text-break-all">
                      <span>Hello,</span>
                      <span className="truncate" data-tooltip-id="project-nameUser" data-tooltip-content={nameUser}>
                        &nbsp;
                        {nameUser}
                      </span>
                      <Tooltip id="project-nameUser" place="left" className="tooltip mg-left" />
                    </h6>
                  </div>
                  <div className="role">{localStorage.getItem('USER_ROLE_NAME')}</div>
                </div>
                <span className="icon-arrow-thin-down font-8 mt-2 pt-1 ms-2" />
              </a>
              <ul className="dropdown_menu dropdown_menu--animated down-animate">
                <li className="dropdown_item-1" onClick={handleViewProfile}>
                  View Profile
                </li>
                <li className="dropdown_item-2" onClick={handleUpdatePassword}>
                  Update Password
                </li>
                <li
                  className="dropdown_item-3"
                  onClick={() => {
                    signOut();
                  }}
                >
                  Logout
                </li>
              </ul>
            </li>
            <li className="nav-toggle">
              <a><span className="icon-menu" onClick={handleSidebarToggle} /></a>
            </li>
          </ul>
          <div>
            {isPageWide ? '' : ''}
          </div>
        </div>

        <Tooltip id="project-manual" place="top" className="tooltip mt-3" />
      </div>
      {showView && <ViewProfile showView={showView} onClose={onViewClose} data={user} />}
      {showUpdatePasswordPage && (
        <UpdatePassword
          showUpdatePasswordPage={showUpdatePasswordPage}
          setShowUpdatePasswordPage={setShowUpdatePasswordPage}
          onUpdatePasswordPageClose={onUpdatePasswordPageClose}
          data={user}
        />
      )}
      <Tooltip id="live-content" place="right" className="tooltip mt-3" />
    </>
  );
};
export default AdminHeader;
