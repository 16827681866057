/* eslint-disable no-unused-vars */
import JoditEditor from 'jodit-react';
import React, { useMemo } from 'react';

const EmailPreview = (props) => {
  const { emailDetails, email } = props;
  const config = useMemo(
    () => ({
      readonly: true
    })
  );
  const emails = email?.join(', ');
  return (
    <div className="p-5" data-testid="e-preview">
      <div className="row mb-3">
        <div className=" col-6 head-14">
          <img
            src={emailDetails?.vesselManagementData?.logoURL}
            alt="new"
            height="150px"
            width="150px"
          />
          <br />
          To,
          <p className="d-flex">{emails}</p>
        </div>
        <div className="head-12 mt-3">
          {`Subject: ${emailDetails?.subject} `}

        </div>
      </div>
      <div className="d-flex align-text-justify mt-5">
        <div className="editor-wrap-description view-jodit">
          <JoditEditor
            config={config}
            value={emailDetails.mailContent}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};
export default EmailPreview;
