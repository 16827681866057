import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../../common/pagination';

const VariationOrdersReportComponent = ({ tableData, setPage, setPageSize, page, pageSize,
  downloadReport }) => {
  const statusList = [
    { label: 'Created', value: '#1DBBFF' },
    { label: 'Sent', value: '#129E06' },
    { label: 'Received', value: '#FAD200' },
    { label: 'Add to Spec list', value: '#2269D5' },
    { label: 'Cancelled', value: '#D92700' },
    { label: 'Deferred To Next DD', value: '#A146FC' }
  ];
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };

  const getColor = (s) => {
    const f = statusList.filter((i) => i.label === s);
    if (f.length) return f[0].value;
    return 'fade-color';
  };
  return (
    <div data-testid="VariationOrdersReportComponent">
      {tableData && tableData.specList && tableData.specList.items?.length ? (
        <div className="">
          <div>
            <div className="border-down row py-3">
              <div className="col-10 font-semibold">Variation</div>
              <div className="col-2 font-semibold">Status</div>
            </div>
            <div className="scroll-wrap">
              {tableData.specList.items.map((i) => {
                return (
                  <div className="border-down row py-3">
                    <div className="col-10">
                      <div className="d-flex rw-1">
                        <div className="">{i.specificationNo}</div>
                        <div className="px-2">-</div>
                        <div className="text-ellipsis pe-2" data-tooltip-id="project-variation" data-tooltip-content={i.title}>
                          {i.title}
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="d-flex w-250 align-items-center">
                        <span className="icon-disc" style={{ color: getColor(i.statusName) }} />
                        <div className="ms-2">{i.statusName}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {tableData?.specList?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.specList?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className=" footer-jobprogress">© 2024 ThinkPalm</div>

            </div>
            <button
              onClick={() => downloadReport()}
              type="button"
              className="primary-btn large mx-3"
            >
              Download Report
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="fixed-footer card d-flex justify-content-end">

            <div className="text-black font-12 col-6">
              <div className="mt-2 mb-2">© 2024 ThinkPalm</div>

            </div>
          </div>
        </>
      )}
      <Tooltip id="project-variation" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default VariationOrdersReportComponent;
