import React from 'react';
import ListProject from '../../TSI/Projects/ListProject';

const Projects = () => {
  return (
    <>
      <div className="" data-testid="admin-dashboard">
        <div className="container-fluid ">
          <ListProject showHeader={false} />
        </div>
      </div>
    </>
  );
};
export default Projects;
