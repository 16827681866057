import axios from 'axios';
import { serviceHandler } from '../../utils/serviceHandler';
import apiEndPoints from '../../utils/apiUrlConstants';

const { loginUrl, getProfileDataUrl, UserRolesListing, TermsAndConditions,
  TermsContentApi, MicrosoftTokenValidationUrl } = apiEndPoints;

export const apiLogin = (payload) => {
  return serviceHandler({
    url: `${loginUrl}`,
    methordType: 'post',
    payload
  });
};
export const getProfileData = (payload) => {
  return serviceHandler({
    url: `${getProfileDataUrl}?id=${payload}`,
    methordType: 'get',
    payload
  });
};
export const userRolesListing = (payload) => {
  return serviceHandler({
    url: `${UserRolesListing}`,
    methordType: 'get',
    payload
  });
};
export const termsAndConditions = (payload) => {
  return serviceHandler({
    url: `${TermsAndConditions}?userId=${payload}`,
    methordType: 'get'
  });
};

export const termsContentApi = (token) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: token
    }
  };
  return axios
    .get(`${TermsContentApi}`, null, config)
    .then((response) => {
      return response;
    });
};

export const microsoftTokenValidation = (payload) => {
  return serviceHandler({
    url: `${MicrosoftTokenValidationUrl}?authCode=${payload}`,
    methordType: 'get'
  });
};
