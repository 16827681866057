/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import { s3Upload } from '../../../Admin/Vessels/Management/services';
import ModalBox from '../../../common/ModalBox';
import CreateSubFolder from './CreateSubFolder';
import { GetData, SaveData, DeleteData } from './services';
import Addattachments from './Addattachments';
import DocumentContainerComponent from './DocumentContainerComponent';

const DocumentContainer = ({
  subFolders,
  parentFromId,
  projectFromId,
  handleFolderData,
  folderType,
  isTSI,
  projInfo
}) => {
  const acceptedFiles = ['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  const { formValidation, ZERO_UUID, MAX_FILE_SIZE } = helper;
  const [active, setActive] = useState('');
  const [fileLoading, setFileLoading] = useState(false);

  const initialValue = {
    name: '',
    parentId: ZERO_UUID,
    projectId: ZERO_UUID,
    isFolder: true,
    document: {
      fileName: '',
      fileType: '',
      size: 0,
      path: ''
    }
  };
  const [formData, setFormData] = useState(initialValue);
  const [isAddNewShow, setIsAddNewShow] = useState(false);
  const [childFolders, setChildFolders] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const handleActiveData = (item, open = '', activeItem) => {
    if (activeItem === item.id) {
      const inPut = {
        projectId: projectFromId,
        parentId: item.id,
        phase: 0,
        sortProperty: 'UpdatedAt',
        isDescending: true
      };
      GetData(inPut).then((res) => {
        setChildFolders(res.data);
        if (open === 'open') {
          setActive(item.id);
        }
      });
    }
  };
  /* istanbul ignore next */
  const handleCreateNew = () => {
    console.log(formData);
    if (formValidation(['name'], formData)) {
      SaveData(formData).then((res) => {
        if (res.success) {
          console.log('folder', res);
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
          handleFolderData({ id: parentFromId });
          if (active) {
            const inPut = {
              projectId: projectFromId,
              parentId: active,
              phase: 0,
              sortProperty: 'UpdatedAt',
              isDescending: true
            };
            GetData(inPut).then((product) => {
              setChildFolders(product.data);
            });
          }
          setIsAddNewShow(false);
          setFormData(initialValue);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const cancel = () => {
    setIsAddNewShow(false);
    const i = { ...initialValue, parentId: parentFromId, projectId: projectFromId };
    setFormData(i);
  };
  useEffect(() => {
    const i = { ...initialValue, parentId: parentFromId, projectId: projectFromId };
    setFormData(i);
    setActive('');
  }, [parentFromId, projectFromId]);
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFileLoading(true);
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          const item = cloneDeep(openUploadModal);
          const Attach = { ...initialValue };
          Attach.name = e.target.files[0].name;
          Attach.parentId = item.id;
          Attach.projectId = projectFromId;
          Attach.isFolder = false;
          Attach.document = {
            fileName: e.target.files[0].name,
            fileType: e.target.files[0].type,
            size: first.size,
            path: res.data
          };
          setFormData(Attach);
          setFileLoading(false);
        } else {
          setFileLoading(false);
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
  };
  /* istanbul ignore next */
  const uploadAttachment = () => {
    if (formValidation(['name'], formData)) {
      SaveData(formData).then((res) => {
        if (res.success) {
          showAlert('Successfully Uploaded', 'success');
          handleActiveData(openUploadModal, '', active);
          setOpenUploadModal(false);
          const i = { ...initialValue, parentId: parentFromId, projectId: projectFromId };
          setFormData(i);
        }
      });
    } else {
      showAlert('Please choose a file', 'error');
    }
  };
  const handleRename = (item) => {
    setFormData(item);
    setIsAddNewShow(false);
  };
  const handleDelete = (data, main) => {
    const i = {
      ...data,
      isMain: main,
      message: main === 'main'
        ? 'On deleting, all files under this sub folder will be deleted. Do you want to continue?'
        : 'Are you sure you want to delete file?'
    };
    setOpenDeleteModal(i);
  };
  const handleCreateNewFolder = () => {
    setIsAddNewShow(true);
    const i = { ...initialValue, parentId: parentFromId, projectId: projectFromId };
    setFormData(i);
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const deleteApiCall = () => {
    const i = cloneDeep(openDeleteModal);
    DeleteData(i?.id).then((res) => {
      if (res.success) {
        if (i.isMain === 'main') {
          handleFolderData({ id: parentFromId });
        } else {
          handleActiveData({ id: active }, '', active);
        }
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const sortData = (type, item) => {
    const inPut = {
      projectId: projectFromId,
      parentId: item.id,
      phase: 0,
      sortProperty: 'UpdatedAt',
      isDescending: true
    };
    if (type === 'ascending') {
      inPut.sortProperty = 'Name';
      inPut.isDescending = false;
    }
    if (type === 'descending') {
      inPut.sortProperty = 'Name';
      inPut.isDescending = true;
    }
    if (type === 'newest') {
      inPut.sortProperty = 'UpdatedAt';
      inPut.isDescending = true;
    }
    if (type === 'oldest') {
      inPut.sortProperty = 'UpdatedAt';
      inPut.isDescending = false;
    }
    GetData(inPut).then((res) => {
      console.log('folder', res);
      setChildFolders(res.data);
    });
  };
  return (
    <div className="" data-testid="dms-3">
      {folderType === 2 && formData.parentId !== ZERO_UUID ? (
        <div className="d-flex justify-content-end pb-2">
          <button disabled={projInfo?.isClosed} id="create-new" className="link-btn" type="button" onClick={() => handleCreateNewFolder(true)}>
            <span className="font-20 icon-add-folder me-2" />
            Create New Subfolder
          </button>
        </div>
      ) : ''}
      {isAddNewShow ? <CreateSubFolder handleCreateNew={handleCreateNew} cancel={cancel} formData={formData} setFormData={setFormData} /> : ''}
      {subFolders && subFolders.length ? (
        <DocumentContainerComponent
          subFolders={subFolders}
          formData={formData}
          childFolders={childFolders}
          isTSI={isTSI}
          active={active}
          folderType={folderType}
          sortData={sortData}
          formatBytes={formatBytes}
          handleDelete={handleDelete}
          handleRename={handleRename}
          setFormData={setFormData}
          setOpenUploadModal={setOpenUploadModal}
          handleCreateNew={handleCreateNew}
          cancel={cancel}
          setActive={setActive}
          handleActiveData={handleActiveData}
          deleteApiCall={deleteApiCall}
          projInfo={projInfo}
        />
      ) : (
        <div className="d-flex justify-content-center p-5">No data found</div>
      )}
      {openDeleteModal ? (
        <div className="side-pannel-modal">
          <ModalBox
            modalClassName="small"
            heading="Delete"
            onSave={() => deleteApiCall()}
            closeModal={() => setOpenDeleteModal(false)}
            buttonName="Delete"
          >
            <p>{openDeleteModal.message}</p>
          </ModalBox>
        </div>
      ) : (
        ''
      )}
      {openUploadModal ? (
        <div className="side-pannel-modal">
          <ModalBox
            modalClassName="medium"
            heading="Add Attachment"
            onSave={() => uploadAttachment()}
            closeModal={() => {
              setOpenUploadModal(false);
              const i = { ...initialValue, parentId: parentFromId, projectId: projectFromId };
              setFormData(i);
            }}
            buttonName="Save"
          >
            <Addattachments
              formData={formData}
              ondrop={ondrop}
              setFormData={setFormData}
              initialValue={initialValue}
              loading={fileLoading}
            />
          </ModalBox>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default DocumentContainer;
