import { serviceHandler } from '../../../utils/serviceHandler';
import apiEndPoints from '../../../utils/apiUrlConstants';

const { forgotPassword, resetPassword, verifyUserToken } = apiEndPoints;

export const forgetPassword = (payload) => {
  return serviceHandler({
    url: `${forgotPassword}?username=${payload.id}`,
    methordType: 'put',
    payload
  });
};

export const resetingPassword = (payload) => {
  return serviceHandler({
    url: `${resetPassword}`,
    methordType: 'put',
    payload
  });
};

export const verifyEmailExpiry = (payload) => {
  return serviceHandler({
    url: `${verifyUserToken}?key=${payload.token}`,
    methordType: 'get',
    payload
  });
};
