/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';
import ModalBox from '../../../../../common/ModalBox';

const StartRepair = ({ projInfo, reloadAfterStart, setStartRepairClicked,
  showEditModal, formData, isRepairStarted, hasChange,
  setHasChange }) => {
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const start = () => {
    setHasChange(false);
    setStartRepairClicked(true);
    showEditModal('start');
    setOpenDeleteModal(false);
  };
  return (
    <div className="mb-9" data-testid="spec-start">
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          isCloseModal={!openDeleteModal}
          onSave={() => start()}
          closeModal={() => setOpenDeleteModal(false)}
          heading="Start Repair"
          buttonName="Yes"
          isNoLabel="No"
        >
          <div className="text-content-black">
            Are you sure you want to start the repair phase for
            <span
              className="px-1 value font-semibold white-space-preserve"
              data-tooltip-id="project-sectitle"
              data-tooltip-content={projInfo?.nameTooltip}
            >
              {projInfo?.name}
              &nbsp;
              ?
            </span>
          </div>

        </ModalBox>
      ) : ''}
      <div className="card d-flex justify-content-center mt-5 p-5">
        {isTSI && !isRepairStarted ? (
          <button
            onClick={() => { setOpenDeleteModal(true); }}
            className="primary-btn large"
            type="button"
            id="start-repair"
          >
            Start Repair
            <span className="font-20 icon-sort-up rotate-90 ms-4" />
          </button>
        ) : <div>Repair Phase Not Started</div>}
      </div>
      <Tooltip id="project-sectitle" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default StartRepair;
