import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetPurchaseOrdeRequests, GetSpecificationListByProjectId, MapPoPrToSpec, ImportPohaData,
  GetTotalPohaCount, SavePohaStatus, PohaStatus, exportExcelPOHAUrl, DeletePoha
} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const getPurchaseOrderRequests = (payload) => {
  return serviceHandler({
    url: `${GetPurchaseOrdeRequests}`,
    methordType: 'post',
    payload
  });
};
export const getSpecByProjectId = (payload) => {
  return serviceHandler({
    url: `${GetSpecificationListByProjectId}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const getTotalPohaCount = (payload) => {
  return serviceHandler({
    url: `${GetTotalPohaCount}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const mapPoPrToSpec = (payload) => {
  return serviceHandler({
    url: `${MapPoPrToSpec}`,
    methordType: 'post',
    payload
  });
};
export const importPohaData = (payload, vesselId, id) => {
  return serviceHandler({
    url: `${ImportPohaData}?vesselId=${vesselId}&projectId=${id}`,
    methordType: 'post',
    payload
  });
};
export const savePohaStatus = (payload) => {
  return serviceHandler({
    url: `${SavePohaStatus}?projectId=${payload.projectId}`,
    methordType: 'put',
    payload
  });
};
export const exportExcelPOHA = (payload) => {
  return axios
    .post(`${exportExcelPOHAUrl}`, payload, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })

    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const updatePohaStatus = (payload) => {
  return serviceHandler({
    url: `${PohaStatus}?id=${payload.id}&status=${payload.status}`,
    methordType: 'put',
    payload
  });
};
export const deletePohaApi = (payload) => {
  return serviceHandler({
    url: `${DeletePoha}?poPrId=${payload.id}`,
    methordType: 'put',
    payload
  });
};
