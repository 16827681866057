import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
// import image2 from '../../../../images/user-placeholder.jpg';

const VesselInfo = ({ items }) => {
  return (
    <div className="d-flex flex-column border-down pb-3">
      <div className="d-flex flex-wrap mt-3" data-testid="vesselinfo-dashboard">
        <div className="me-3">
          {items?.imageUrl ? (
            <img src={items?.imageUrl} alt="Vessel Thumbnail" className="thmb-sm" />
          ) : (
            <img src="/static/media/vessel-thmd.304ab3bca4863ea93cc6.jpg" alt="Vessel Thumbnail" className="thmb-sm" />
          )}
        </div>
        <div className="">
          <div className="head-14">
            {items?.name}
          </div>
          {items?.mappedUsers?.length ? (
            <div className="font-14">
              {items?.mappedUsers?.map((item3) => {
                return (
                  <div className="d-flex mt-2 align-items-center">
                    <label
                      data-tooltip-id="project-items"
                      data-tooltip-content={item3.roleName}
                    >
                      {_.truncate(item3?.roleName, { length: 25 })}
                    </label>
                    <div className="mx-1">:</div>
                    <div
                      className=" value"
                      data-tooltip-id="project-items"
                      data-tooltip-content={item3.userName}
                    >
                      {' '}
                      {_.truncate(item3.userName, {
                        length: 20
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : 'No data found'}
          <Tooltip id="project-items" place="top" className="tooltip mt-3" />
        </div>

      </div>
    </div>
  );
};

export default VesselInfo;
