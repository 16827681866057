import React from 'react';
import InputType1 from '../../../../../common/InputType1';
import UploadFile from './uploadFile';

const AddNewJob = (props) => {
  const { newJob, setNewJob, onCancelAdd, onSaveNewJob } = props;
  const onFieldChange = (e) => {
    const currentImage = { ...newJob };
    currentImage[e.target.name] = e.target.value.trimStart();
    setNewJob(currentImage);
  };
  return (
    <>
      <div className="card p-5">
        <InputType1 label="Name" isMandatory="true" name="name" id="name" onChange={(e) => { onFieldChange(e); }} value={newJob.name} />
        <UploadFile {...props} />
        <InputType1 label="Description" isMandatory="true" id="desc" name="description" onChange={(e) => { onFieldChange(e); }} value={newJob.description} />
      </div>
      <div className="d-flex mt-5 pt-3 justify-content-end">
        <button type="button" id="cancel" className="secondary-btn me-3" onClick={() => { onCancelAdd(); }}>Cancel</button>
        <button type="button" id="save" disabled={!newJob.description || !newJob.name || !newJob.path} className="primary-btn" onClick={() => { onSaveNewJob(); }}>Add</button>
      </div>
    </>
  );
};
export default AddNewJob;
