import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';

const JobDetails = (props) => {
  const {
    tableData,
    handleInputChange,
    isTsiDisabled
    // selectedJobList,
    // getAttachments,
    // setSaveAttachment
  } = props;

  const column = [
    {
      sortable: false,
      accessor: 'jobName',
      width: 50,
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="check-box ps-1">
            <input
              className="check-box"
              type="checkbox"
              disabled={isTsiDisabled}
              checked={original?.isChecked}
              id={original?.id}
              onChange={(e) => {
                handleInputChange(e, row);
              }}
            />
            <label htmlFor={original?.id} />
          </div>
        );
      }
    },
    {
      Header: () => (
        <div className="d-flex align-items-center emergency-header">
          List of jobs to be carried out
        </div>
      ),
      sortable: false,
      accessor: 'title',
      width: 430,
      Cell: (row) => {
        const { value } = row;
        return (
          <span className="text-ellipsis" data-tooltip-id="project-det" data-tooltip-content={`${value}`}>
            {value}
          </span>
        );
      }
    },
    {
      Header: () => (
        <div className="d-flex align-items-center emergency-header">
          Specs to be included
        </div>
      ),
      sortable: false,
      width: 220,
      accessor: 'specificationNo',
      headerStyle: { justifyContent: 'center' },
      style: { justifyContent: 'center' },
      Cell: (row) => {
        const { value } = row;
        return (
          <span className="text-ellipsis" data-tooltip-id="project-det" data-tooltip-content={`${value}`}>
            {value}
          </span>
        );
      }
    }
    // {
    //   Header: '',
    //   sortable: false,
    //   accessor: 'action',
    //   Cell: (row) => {
    //     const { original } = row;
    //     return (
    //       <div className="usermenu-item">
    //         <span className="circle-ico-spec relative" tooltip="Attachments" flow="right">
    //           <span
    //             id="attachment-click"
    //             onClick={() => {
    //               setShowUpdateModal(true);
    //               setSaveAttachment(original);
    //               getAttachments(original?.jobAttachmentId);
    //             }}
    //             className="icon-attachments font-24 brand-color"
    //           />
    // {original && original?.jobAttachments?.length > 0 ? <span className="dot-indicator" /> : ''}
    //         </span>
    //       </div>
    //     );
    //   }
    // }
  ];
  return (
    <div className="stripped-react-table pt-5 job-details-table" data-testid="job-details">
      <ReactTable
        className="table"
        data={tableData}
        columns={column}
        minRows={0}
        showPagination={false}
        pageSize={tableData?.length}
      />
      <Tooltip id="project-det" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default JobDetails;
