import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import SidePanel from '../common/sidePanel';
import userImg from '../../images/user-placeholder.jpg';

const ViewProfile = (props) => {
  const { showView, onClose, data } = props;
  const getVessels = (item) => {
    let list = [];
    if (item && item?.vessels) {
      list = item.vessels.map((v) => {
        return v.name;
      });
    }
    return list.join().replace(/,/g, ', ');
  };

  return (
    <div data-testid="viewProfile">
      <SidePanel title="User Details" showView={showView} onClose={onClose}>
        {showView && (
          <div className="row mt-3">
            <div className="col-3 d-flex justify-content-center">
              {data?.profileImage ? <img src={data?.profileImage} className="thmb-xlg" alt="" /> : <img src={userImg} className="thmb-xlg" alt="" />}
            </div>
            <div className="col-9 font-16">
              <div className="d-flex justify-content-between">
                <div className="d-flex head-16 text-capitalize mb-1 text-break-all">
                  <span
                    className="text-ellipsis pe-2"
                    data-tooltip-id="project-heade"
                    data-tooltip-content={`${data.firstName}`}
                  >
                    {_.truncate(data?.firstName, {
                      length: 15,
                      separator: ' '
                    })}
                  </span>
                  <span
                    className="text-ellipsis pe-2"
                    data-tooltip-id="project-heade"
                    data-tooltip-content={`${data.middleName}`}
                  >
                    {_.truncate(data?.middleName, {
                      length: 15,
                      separator: ' '
                    })}
                  </span>
                  <span
                    className="text-ellipsis pe-2"
                    data-tooltip-id="project-heade"
                    data-tooltip-content={`${data.lastName}`}
                  >
                    {_.truncate(data?.lastName, {
                      length: 15,
                      separator: ' '
                    })}
                  </span>
                </div>
              </div>
              <div className="mt-3 d-flex flex-column flex-wrap">
                {data?.isLocalUser ? '' : (
                  <>
                    <span className="mb-3 d-flex" data-tooltip-id="project-heade" data-tooltip-content={`${data.email}`}>
                      <span className="icon-email font-22 me-3" />
                      {_.truncate(data?.email, {
                        length: 30,
                        separator: ' '
                      })}
                    </span>
                    <span className="mb-3 d-flex">
                      <span className="icon-cell-phone font-26 me-4" />
                      {data?.mobile}
                    </span>
                    <span className="mb-3  d-flex">
                      <span className="icon-phone font-20 me-3" />
                      {data?.phone}
                    </span>
                  </>
                )}
                <div>
                  {' '}
                  <span className="mb-3 d-flex" data-tooltip-id="project-headd" data-tooltip-content={`${data.userName}`}>
                    <span className="me-3 head-14">Username : </span>
                    {_.truncate(data?.userName, {
                      length: 30,
                      separator: ' '
                    })}
                  </span>
                  {' '}
                </div>
              </div>

              <h4 className="mt-4 mb-3 head-16 font-semibold mt-4">MANAGEMENT AND ROLE</h4>
              <div className="mt-4" data-testid="vessels">
                {data?.roleData?.map((item) => {
                  return (
                    <div key={item} className="my-3 skywhite-bg p-4">
                      <div className="text-capitalize head-16 pb-2">{item.management}</div>
                      <div className="d-flex">
                        <label className="pe-2 head-14">
                          Role
                          {' '}
                          :
                          {' '}
                        </label>
                        <div className="text-capitalize pb-1">
                          {item.role}
                        </div>
                        {data?.isVesselUser && !data?.isLocalUser ? <div className="">(Vessel Reviewer)</div> : ''}
                      </div>
                      <div className="">
                        <span className="pe-2 head-14">
                          {data?.isLocalUser ? 'Vessel' : 'Vessels'}
                          {' '}
                          :
                          {' '}
                        </span>
                        <span className="text-capitalize">{getVessels(item) || '-'}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Tooltip id="project-headd" place="right" className="tooltip" />
            <Tooltip id="project-heade" place="top" className="tooltip" />
          </div>
        )}
      </SidePanel>
    </div>
  );
};
export default ViewProfile;
