import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
// import image from '../../../../images/management-grp.jpg';
import image2 from '../../../../images/management-placeholder.jpg';

const Cards = (props) => {
  const {
    data,
    setOpenDeleteModal,
    setShowModal,
    setFormData,
    setItem,
    setFile,
    setFileName
  } = props;
  return (
    <div className="card p-2rem mb-2" data-testid="v-cards">
      <div className="d-flex col-gap-2 align-items-center justify-content-between">
        <div id="details" className="d-flex align-items-center col-4">
          <div className="me-4">
            <img src={data?.logoURL || image2} alt="" className="thmb-lg" />
          </div>
          <div className="d-flex flex-column flex-wrap">
            <h6 className="head-14 mb-2 text-capitalize text-break-all">
              <span
                className="text-ellipsis pe-2"
                data-tooltip-id="project-card"
                data-tooltip-content={data?.name}
              >
                {_.truncate(data?.name, {
                  length: 30,
                  separator: ' '
                })}
              </span>
            </h6>

            <span
              className="mb-3"
              data-tooltip-id="project-location"
              data-tooltip-content={data?.location}
            >
              <span className="icon-location1 font-18 me-1 brand-color" />
              {_.truncate(data?.location, {
                length: 30,
                separator: ' '
              })}
            </span>
            <span
              className="text-ellipsis pe-2"
              data-tooltip-id="project-address"
              data-tooltip-content={data?.address}
            >
              {_.truncate(data?.address, {
                length: 30,
                separator: ' '
              })}
            </span>
          </div>
        </div>
        {/* <div className="col-4">
        <h6 className="head-16 mb-2">Management</h6>
        {userData.roleData.map((item) => {
          return <div>{item.management}</div>;
        })}
      </div> */}
        <div className="align-items-center col-3">
          <span
            className="mb-3  d-flex text-ellipsis"
            data-tooltip-id="project-location"
            data-tooltip-content={data?.emailAddress}
          >
            <span className="icon-email font-22 me-3" />
            {_.truncate(data?.emailAddress, {
              length: 30,
              separator: ' '
            })}
          </span>
          {data?.mobileNo && (
            <span
              className="mb-3 d-flex"
              data-tooltip-id="project-location"
              data-tooltip-content={data?.mobileNo}
            >
              <span className="icon-cell-phone font-26 me-4" />
              {_.truncate(data?.mobileNo, {
                length: 30,
                separator: ' '
              })}
            </span>
          )}
          {data?.phoneNo && (
            <span
              className="mb-3  d-flex"
              data-tooltip-id="project-location"
              data-tooltip-content={data?.phoneNo}
            >
              <span className="icon-phone font-20 me-3" />
              {_.truncate(data?.phoneNo, {
                length: 30,
                separator: ' '
              })}
            </span>
          )}
          {data?.fax && (
            <span
              className="mb-3  d-flex"
              data-tooltip-id="project-location"
              data-tooltip-content={data?.fax}
            >
              <span className="icon-meeting font-20 me-3" />
              {_.truncate(data?.fax, {
                length: 30,
                separator: ' '
              })}
            </span>
          )}
          <Tooltip id="project-location" place="top" className="tooltip mt-3" />
        </div>
        <div className="d-flex col-gap-1 col-2">
          <div>
            {' '}
            <button
              id="edit"
              type="button"
              className="link-btn"
              // disabled={data.isMapped}
              onClick={() => {
                setFormData(data); setFile(data?.logoURL);
                setFileName(data?.logoFileName); setShowModal(true);
              }}
            >
              <span className="icon-edit font-22" />
            </button>

          </div>

          <div
            data-tooltip-id="project-location2"
            data-tooltip-content={data?.mappedMessage}
          >
            <button
              id="delete"
              type="button"
              disabled={data.isMapped}
              onClick={() => { setOpenDeleteModal(true); setItem(data?.id); }}
              className="link-btn"
            >
              <span className="icon-delete font-22" />
            </button>

          </div>
          <Tooltip id="project-location2" place="top" className="tooltip mt-3" />

        </div>
      </div>
      <Tooltip id="project-card" place="top" className="tooltip mt-3" />
      <Tooltip id="project-location" place="top" className="tooltip mt-3" />
      <Tooltip id="project-address" place="top" className="tooltip mt-3" />
    </div>

  );
};
export default Cards;
