/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import InputType1 from '../../../../../common/InputType1';
import AddComment from './addComment';
import { totalAtContract, totalAtRepair, totalAtYard } from './closeHelper';
import UploadWindow from './uploadWindow';
import helper from '../../../../../../utils/helper';

const Estimation = (props) => {
  const { mainSec, showsubSec, specList, setSpecList, addAttach,
    setShowUpload, showUpload, onSaveAttach, isDisabled, projInfo } = props;
  const { formatNumber } = helper;
  const [commentData, setCommentData] = useState({});
  const onInvoiceAmount = (e, key1, key2, key3) => {
    const currentList = { ...specList };
    const text = e.target.value.replace(/[^0-9.]/g, '');
    const validated = text.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      currentList.collation[key1].yardInvoice = e.target.value.replace(/[^0-9.]/g, '');
    }
    setSpecList(currentList);
  };
  const onCommentClick = (data, key1, key2, key3) => {
    setCommentData({
      data, key1, key2, key3, field: data.comment
    });
  };
  const onCommentSubmit = (lvl) => {
    const currentList = { ...specList };
    if (lvl === 1) {
      currentList.collation[commentData.key1].comment = commentData.field;
    } else if (lvl === 2) {
      currentList.collation[commentData.key1].specs[commentData.key2].comment = commentData.field;
    } else if (lvl === 3) {
      currentList.collation[commentData.key1].specs[commentData.key2]
        .serviceLines[commentData.key3].comment = commentData.field;
    }
    setSpecList(currentList);
    setCommentData({});
  };
  return (
    <div className="spc-map card col-6" data-testid="estimation">
      <div className="d-flex border-down align-items-center mb-2 ps-3 bg-sky-white h-65">
        <div className="col-3 head-14 font-semibold pe-4">Yard Est.</div>
        <div className="col-3  head-14 font-semibold pe-4">Yard Actual</div>
        <div className="col-6 head-14 font-semibold d-flex justify-content-between">
          <div className="col-5 margin-left-auto">From Yard Invoice</div>
          <button type="button" className="rnd-btn me-3" onClick={addAttach}>Attachments</button>
        </div>
      </div>
      {
        specList?.collation?.map((sec, mainKey) => {
          return (
            <div className={(sec.id === mainSec && showsubSec) ? 'highlight-tende' : ''}>
              <div className="d-flex border-down py-1 ps-3 relative h-260 align-items-center">
                <div className="col-3 d-flex justify-content-end pe-5">{formatNumber(sec.ownerContractEstimate)}</div>
                <div className={sec.ownerRepairEstimate < sec.yardInvoice ? 'col-3 font-bold red d-flex justify-content-end pe-5' : 'col-3 d-flex justify-content-end pe-5'}>{formatNumber(sec.ownerRepairEstimate)}</div>
                <div className="col-5 d-flex justify-content-between ms-4">
                  <div className="col-5 margin-left-auto text-right">
                    <InputType1
                      value={sec.yardInvoice}
                      name="yardInvoice"
                      id={sec.id}
                      disabled={isDisabled}
                      onChange={(e) => { onInvoiceAmount(e, mainKey); }}
                      className="w-full"
                    />
                  </div>
                  <div className=" col-7 ps-5 relative">
                    <span id={sec.id} className="icon-add-comment brand-color font-26 mt-1 ms-2 pointer" onClick={() => { onCommentClick(sec, mainKey, 'err', 'err'); }} />
                    {sec.comment && <div className="message-dot" />}
                  </div>
                </div>
                <AddComment
                  commentData={commentData}
                  setCommentData={setCommentData}
                  isDisabled={isDisabled}
                  secData={sec}
                  onCommentSubmit={onCommentSubmit}
                  level={1}
                />
              </div>
              {
              sec.id === mainSec && sec.specs.map((subsec, subKey) => {
                return (
                  <>
                    <div className="d-flex border-down py-1 ps-3 relative align-items-center h-260">
                      <div className="col-3 d-flex justify-content-end pe-5">{formatNumber(subsec.ownerContractEstimate)}</div>
                      <div className={subsec.ownerRepairEstimate < subsec.yardInvoice ? 'col-3 d-flex font-bold red align-items-center justify-content-end pe-5' : 'col-3 d-flex align-items-center justify-content-end pe-5'}>
                        <div className="">{formatNumber(subsec.ownerRepairEstimate)}</div>
                      </div>
                      <div className="col-5 d-flex justify-content-between ms-4">
                        <div className="col-5 text-right">-</div>
                        <div className="relative col-7 ps-5">
                          <span id={subsec.id} className="pointer icon-add-comment brand-color font-26 mt-1 ms-2" onClick={() => { onCommentClick(subsec, mainKey, subKey, 'err'); }} />
                          {subsec.comment && <div className="message-dot" />}
                        </div>
                      </div>
                      <AddComment
                        commentData={commentData}
                        setCommentData={setCommentData}
                        isDisabled={isDisabled}
                        secData={subsec}
                        onCommentSubmit={onCommentSubmit}
                        level={2}
                      />
                    </div>
                    {
                      subsec.id === showsubSec
                       && subsec.serviceLines.map((serv, num) => {
                         return (
                           <div className="ms-1 border-down py-1 relative d-flex align-items-center h-260">
                             <div className="col-3 d-flex justify-content-end pe-5">{formatNumber(serv.ownerContractEstimate)}</div>
                             <div className={serv.ownerRepairEstimate < serv.yardInvoice ? 'col-3 font-bold red d-flex justify-content-end pe-5' : 'col-3 d-flex justify-content-end pe-5'}>{formatNumber(serv.ownerRepairEstimate)}</div>
                             <div className="col-5 d-flex justify-content-between ms-4 ps-1">
                               <div className="col-5 text-right">
                                 -
                               </div>
                               <div className="relative col-7 ps-5 ms-2">
                                 <span id={serv.id.concat('comment')} className="pointer icon-add-comment brand-color font-26 mt-1" onClick={() => { onCommentClick(serv, mainKey, subKey, num); }} />
                                 {serv.comment && <div className="message-dot" />}
                               </div>
                             </div>
                             <AddComment
                               commentData={commentData}
                               setCommentData={setCommentData}
                               isDisabled={isDisabled}
                               secData={serv}
                               onCommentSubmit={onCommentSubmit}
                               num={num}
                               level={3}
                             />
                           </div>
                         );
                       })
                      }
                  </>
                );
              })
            }
            </div>
          );
        })
      }
      <div className="d-flex align-items-center mt-4 ps-3 ">
        <div className="col-3 head-14 text-right pe-5">
          <div className="opacity-6">
            {projInfo.currencySymbol}
            {' '}
            {formatNumber(totalAtContract(specList?.collation))}
          </div>
          <div className="h-48">Yard Est.</div>
        </div>
        <div className="col-3  head-14 text-right pe-5">
          <div className={totalAtRepair(specList?.collation) < totalAtYard(specList?.collation) ? 'red font-bold' : 'opacity-6'}>
            {projInfo.currencySymbol}
            {' '}
            {formatNumber(totalAtRepair(specList?.collation))}
          </div>
          <div className="h-48">Yard Actual</div>
        </div>
        <div className="col-3  head-14 text-right pe-5">
          <div className="opacity-6">
            {projInfo.currencySymbol}
            {' '}
            {formatNumber(totalAtYard(specList?.collation))}
          </div>
          <div className="h-48">From Yard Invoice</div>
        </div>
      </div>
      {showUpload && (
      <UploadWindow
        setShowUpload={setShowUpload}
        specList={specList}
        setSpecList={setSpecList}
        onSaveAttach={onSaveAttach}
        isDisabled={isDisabled}
        projInfo={projInfo}
      />
      )}
    </div>
  );
};
export default Estimation;
