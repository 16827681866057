import React, { useState } from 'react';
import _ from 'lodash';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';

const InvoiceList = (props) => {
  const { specList, data, projInfo, allDocs } = props;
  const [expand, setExpand] = useState(false);
  const { formatNumber } = helper;

  return (
    <div className="border md-radius" data-testid="invoice-data">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer'}
            onClick={() => { setExpand(!expand); }}
            id="expand"
          />
        </div>
      </div>
      {expand
      && (
      <div className="spc-map col-12">
        <div className="d-flex border-down align-items-center mb-2 ps-3 py-3">
          <div className="col-10 head-14 font-semibold">Spec Sections</div>
          <div className="col-2 head-14 font-semibold d-flex justify-content-end pe-3">Yard Invoice</div>
        </div>
        {
            specList?.map((sec) => {
              return (
                <div>
                  <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                    <div className="col-1 d-flex align-items-center">
                      <span
                        id={sec.id}
                        className="font-14 icon-sort-up brand-color rotate-90 me-2"
                      />
                      <Tooltip id="project-yinvoice" place="top" className="tooltip mt-3" />
                      <span
                        data-tooltip-id="project-yinvoice"
                        data-tooltip-content={sec.number}
                      >
                        {_.truncate(sec.number, { length: 4 })}
                      </span>
                    </div>
                    <Tooltip id="project-ysecinvoice" place="top" className="tooltip mt-3" />
                    <div
                      className="col-9"
                    >
                      <span
                        data-tooltip-id="project-ysecinvoice"
                        data-tooltip-content={sec.specName}
                      >
                        {_.truncate(sec.specName, { length: 30 })}
                      </span>

                    </div>
                    <div className="col-2 d-flex justify-content-end pe-2">{sec.yardInvoice ? projInfo.currencySymbol.concat(' ', formatNumber(sec.yardInvoice)) : '-'}</div>
                  </div>
                </div>
              );
            })
          }
        <div className="px-5 pb-5 mt-4">
          <div className="font-semibold mb-1">Comments</div>
          <div className="ps-2">{allDocs?.comment}</div>
        </div>
      </div>
      )}
    </div>
  );
};
export default InvoiceList;
