import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import Select from 'react-select';

const AddAttachments = (props) => {
  const { handleChange, formData, ondrop, setFormData, documentCategoryList } = props;
  const deleteFile = (key) => {
    const attachment = { ...formData };
    attachment.projectAttachments.splice(key, 1);
    setFormData(attachment);
  };
  return (
    <>
      <div>
        <div className="col-12" data-testid="add-attach">
          <label>
            Document Category
            <span className="text-primaryRed-1 m-2">*</span>
          </label>
          <Select
            autoFocus
            menuPosition="fixed"
            value={documentCategoryList?.filter((option) => option.value
              === formData.documentCategory)}
            // onChange={handleDocumentCategoryList}
            onChange={(e) => handleChange(e, 'documentCategory', 'select')}
            className="modal-select z-index-99"
            label="Document Category"
            options={documentCategoryList}
            id="documentCategory"
          // id="Code"
          />
        </div>
        <div className="drag-drop-file my-4 file-added">
          <div className="icon-buble mb-5">
            <span className="icon-file-text2" />
          </div>

          <input
            className="p-5 align-self-center"
            id="upload"
            type="file"
            name="name"
            multiple
            onChange={(e) => {
              ondrop(e);
            }}
            isMandatory="true"
          />

          <p>Choose a file or drag it here(jpeg, jpg, png, tiff, pdf, doc - max size(5MB))</p>
        </div>
        <div className="attached-file-multi">
          {formData?.projectAttachments?.map((item, key) => {
            return (
              <>
                <div className="d-flex item">
                  <span className="icon-check-rnd me-2 file-added-multi font-20"> </span>

                  <div>
                    <span data-tooltip-id="project-addattach" data-tooltip-content={item?.name}>
                      {_.truncate(item.name, {
                        length: 25,
                        separator: ' '
                      })}
                    </span>
                  </div>
                  <button
                    type="button"
                    id="delete"
                    className="link-btn clear-file"
                    onClick={() => {
                      deleteFile(key);
                    }}
                  >
                    <span className="icon-delete delete-icon-multi ms-4 font-20 "> </span>
                  </button>
                </div>
                <Tooltip id="project-addattach" place="top" className="tooltip mt-3" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AddAttachments;
