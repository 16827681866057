import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { fileImages } from '../../../../../../common/mappingConst';
import helper from '../../../../../../../utils/helper';

const Attachments = (props) => {
  const { allFiles } = props;
  const { formatBytes } = helper;
  return (
    <div>
      {allFiles && allFiles?.length > 0 ? (
        allFiles?.map((urlData) => {
          return (
            <div className="p-2rem mb-4 d-flex card justify-content-between" data-testid="attachment">
              <div className="d-flex align-items-center">
                <div className="thmb-icon-md me-3">
                  <span className={fileImages[_.toLower('xlsx')]} />
                  <img
                    src={fileImages?.xlsx}
                    alt=""
                    style={{ height: 24 }}
                  />
                </div>
                <div>
                  <a
                    href={urlData?.path}
                    target="_blank"
                    download="downloaded_file"
                    className=""
                    rel="noreferrer"
                  >
                    <div
                      className="text-ellipsis text-black font-bold"
                    >
                      <span
                        data-tooltip-id="project-recieved"
                        data-tooltip-content={urlData?.documentCategoryName}
                      >
                        {_.truncate(urlData?.fname, { length: 35, separator: ' ' })}
                      </span>

                    </div>
                  </a>

                  <div className="text-ellipsis">
                    <span
                      className="value"
                      data-tooltip-id="project-recieved"
                      data-tooltip-content={urlData?.name}
                    >
                      {formatBytes(urlData?.size)}

                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex col-gap-2">
                <button type="button" className="link-btn large">
                  <a
                    href={urlData?.path}
                    target="_blank"
                    download="downloaded_file"
                    className=""
                    rel="noreferrer"
                  >
                    <span className="icon-download font-22" />
                  </a>
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="attachment">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">
            No data found
          </div>
        </div>
      )}
      <Tooltip id="project-recieved" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Attachments;
