/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import brandLogo from '../../../../images/brand-logo.svg';
import vesselThmd from '../../../../images/vessel-thmd.jpg';

const PageHeader = (props) => {
  const { specList } = props;
  let stagingBlink = '';
  let stagingBlinkHContent = 'This is the Maridock live environment. Please enter project specific actual data only.';
  if (window.location?.hostname?.includes('app.maridock')) {
    stagingBlink = 'app-build';
    stagingBlinkHContent = 'This is the Maridock live environment. Please enter project specific actual data only.';
  }
  if (window.location?.hostname?.includes('staging.maridock')) {
    stagingBlink = 'staging-build';
  }
  return (
    <div className="header">
      <div className="header-main d-flex justify-content-between">
        <div
          data-tooltip-id="live-content"
          data-tooltip-content={stagingBlinkHContent}
          className={`brand-logo ${stagingBlink}`}
        >
          <a href="">
            <img src={brandLogo} alt="Maridock Logo" className="brand-logo-img" />
          </a>
        </div>
        <div className="card sm-radius bg-sky-white pe-4 pt-1  pb-1 ps-3" datat-testid="projcard">
          <div className="d-flex justify-content-between align-items-center">
            <img className="thmb-sm ship-thumb" src={specList?.vesselImageUrl ? specList?.vesselImageUrl : vesselThmd} alt="" />
            <div>
              <label
                className="font-15 pt-1 pt-3 me-5 pe-5 value font-semibold"
                data-tooltip-id="project-repair"
                data-tooltip-content={specList?.projectFullName}
              >
                {_.truncate(specList?.projectName, {
                  length: 40,
                  separator: ''
                })}
              </label>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <span className="icon-disc inprogress font-10 brand-color" />
                  <p className="value font-13 ps-1 ">
                    {specList?.projectStatus}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pe-5" />
      </div>
      <Tooltip id="project-repair" place="top" className="tooltip mt-3" />
      <Tooltip id="live-content" place="right" className="tooltip mt-3" />
    </div>
  );
};
export default PageHeader;
