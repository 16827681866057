/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { cloneDeep } from 'lodash';
import Navbar from './navbar';
import SubList from './addSubItems';
import { getSectionById, getSectionByIdVariation } from '../services';
import helper from '../../../../../../../utils/helper';
import NavbarSupplementary from './navbarSupplementary';

const AddSupplementaryJobs = (props) => {
  const { setIsComponentClicked, specDetails, currency, saveForm, formData,
    setFormData, setIsSaved, isSaved, specId, addClicked,
    setIsAddClicked, fromVariation, isTSI, currencyId,
    fromSupplementary, activeTabSupplementary, isVesselUser,
    setSelectedAssignee, selectedAssignee, isLoading, projInfo } = props;
  const [sectionList, setSectionList] = useState();
  const [sectionName, setSectionName] = useState('');

  const [sectionListVariation, setSectionListVariation] = useState();
  const [sectionNameVariation, setSectionNameVariation] = useState('');

  const { generateOptions, handleValidation } = helper;

  const { id } = useParams();
  console.log(projInfo, 'projInfo');

  /* istanbul ignore next */
  useEffect(() => {
    if (fromVariation) {
      getSectionByIdVariation(id)?.then((resp) => {
        if (resp.success) {
          setSectionList(generateOptions(resp.data, 'sectionId', 'sectionNo'));
          if (formData?.sectionId) {
            resp?.data?.map((item) => {
              if (formData?.sectionId === item?.sectionId) {
                setSectionName(item?.sectionNo);
              }
              return null;
            });
          }
        }
      });
    } else {
      getSectionById(id).then((resp) => {
        if (resp.success) {
          setSectionList(generateOptions(resp.data, 'sectionId', 'sectionNo'));
          if (formData?.sectionId) {
            resp?.data?.map((item) => {
              if (formData?.sectionId === item?.sectionId) {
                setSectionName(item?.sectionNo);
              }
              return null;
            });
          }
        }
      });
    }
  }, [specId]);

  /* istanbul ignore next */
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;

      if (field === 'sectionId') {
        fd.specNoAutoGenerated = e?.specNoAutoGenerated;
        fd.sectionNo = e?.sectionNo;
      }
      if (field === 'specAssigned') {
        specDetails.specAssigned = e.value;
        specDetails.specAssignedName = e.label;
      }
    } else {
      fd[field] = e.target.value;
    }
    fd.specId = formData?.specId || e?.specId;
    setFormData(fd);
    handleValidation(['sectionId', 'specTitle', 'specAssigned'], fd, field);
  };
  const onReload = () => {
  };

  return (
    <div className="mt-4" data-testid="wo-defect">
      {fromSupplementary
        ? (
          <NavbarSupplementary
            formData={formData}
            saveForm={saveForm}
            isLoading={isLoading}
            sectionList={sectionList}
            handleChange={handleChange}
            setFormData={setFormData}
            setIsSaved={setIsSaved}
            isSaved={isSaved}
            specId={specId}
            sectionName={sectionName}
            addClicked={addClicked}
            setIsAddClicked={setIsAddClicked}
            isTSI={isTSI}
            fromSupplementary={fromSupplementary}
            selectedAssignee={selectedAssignee}
            specDetails={specDetails}
            setSelectedAssignee={setSelectedAssignee}
            projInfo={projInfo}
            isVesselUser={isVesselUser}
          />
        )
        : (
          <Navbar
            formData={formData}
            saveForm={saveForm}
            sectionList={sectionList}
            handleChange={handleChange}
            setFormData={setFormData}
            setIsSaved={setIsSaved}
            isSaved={isSaved}
            specId={specId}
            sectionName={sectionName}
            addClicked={addClicked}
            setIsAddClicked={setIsAddClicked}
            isTSI={isTSI}
            fromSupplementary={fromSupplementary}
            selectedAssignee={selectedAssignee}
            specDetails={specDetails}
            setSelectedAssignee={setSelectedAssignee}
            projInfo={projInfo}
          />
        )}

      {specDetails?.id && (
        <div className="scroll-area mt-5">
          <SubList
            specDetails={specDetails}
            fromVariation={fromVariation}
            projectId={id}
            selectedSpec={specDetails?.id}
            isTsiDisabled={false}
            fromSpecify
            onReload={onReload}
            currency={currency}
            setIsComponentClicked={setIsComponentClicked}
            currencyId={currencyId}
            fromSupplementary={fromSupplementary}
            activeTabSupplementary={activeTabSupplementary}
            projInfo={projInfo}
          />
        </div>
      )}

    </div>

  );
};
export default AddSupplementaryJobs;
