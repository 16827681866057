/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { cloneDeep, findIndex, truncate } from 'lodash';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../common/pagination';
import { saveTaskDetail } from './services';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import TableSortHeader from '../../../common/TableSortHeader';
import TableLoader from '../../../common/TableLoader';

const InnerTable1 = (props) => {
  const { ZERO_UUID } = helper;
  const {
    currentpage,
    publishData,
    setTableData,
    cancel,
    expanded,
    setExpanded,
    reloadTable,
    ProjectTypeList,
    tableData,
    pageClick,
    editData,
    deteleData,
    onSizeChange,
    pageSize,
    specificationStatus,
    tableRows,
    handleSort,
    sortData,
    loading,
  } = props;
  const [openedList, setOpenedList] = useState([]);

  const saveTaskDetails = () => {
    const taskDetailData = [];
    cloneDeep(tableRows).forEach((element) => {
      taskDetailData.push({
        taskId: element.id,
        taskCategoryId: ZERO_UUID,
        projectType: element.projectType,
      });
      element?.taskCategoryList?.forEach((s) => {
        //   if (element.projectType?.length) {
        //     taskDetailData.push({
        //       taskId: s.taskId,
        //       taskCategoryId: s.id,
        //       projectType: union(element.projectType, s.projectType)
        //     });
        //   } else {
        taskDetailData.push({
          taskId: s.taskId,
          taskCategoryId: s.id,
          projectType: s.projectType,
        });
        //   }
      });
    });
    saveTaskDetail(taskDetailData).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert('Successfully saved as draft', 'success');
      }
    });
  };
  // const updateMainTask = (tempp, taskId, sid, ele) => {
  //   const temp = [...tempp];
  //   const index = findIndex(temp, (i) => { return i.id === taskId; });
  //   if (index > -1) {
  //     const tempTask = temp[index];
  //     let found = true;
  //     tempTask.taskCategoryList.forEach((i) => {
  //       if (i.projectType.indexOf(ele.id) === -1) {
  //         found = false;
  //       }
  //     });

  //     const newProjectType = tempTask.projectType;
  //     const idx = newProjectType?.indexOf(ele.id);
  //     if (idx > -1) {
  //       if (found) {
  //         //
  //       } else newProjectType.splice(idx, 1);
  //     } else {
  //       // eslint-disable-next-line no-lonely-if
  //       if (found) newProjectType.push(ele.id);
  //     }
  //   }
  //   return temp;
  // };

  // const updateSubTask = (tempp, taskId, ele, addRemove) => {
  //   const temp = [...tempp];
  //   const index = findIndex(temp, (i) => { return i.id === taskId; });
  //   if (index > -1) {
  //     const tempTask = temp[index];
  //     tempTask.taskCategoryList.forEach((i, subTaskIndex) => {
  //       const newProjectType = tempTask.taskCategoryList[subTaskIndex].projectType;
  //       const idx = newProjectType?.indexOf(ele.id);
  //       if (idx > -1) {
  //         if (addRemove === 'remove') newProjectType.splice(idx, 1);
  //       } else {
  //         // eslint-disable-next-line no-lonely-if
  //         if (addRemove === 'add') newProjectType.push(ele.id);
  //       }

  //       tempTask.taskCategoryList[subTaskIndex].projectType = newProjectType;
  //     });
  //   }
  //   return temp;
  // };
  /* istanbul ignore file */
  const handleChangeType = (ele, rowIndex, type, original) => {
    const temp = [...tableRows];
    if (type === 'main') {
      const newProjectType = temp[rowIndex].projectType;
      const idx = newProjectType?.indexOf(ele.id);
      if (idx > -1) {
        newProjectType.splice(idx, 1);
        // temp = updateSubTask(temp, temp[rowIndex].id, ele, 'remove');
      } else {
        newProjectType.push(ele.id);
        // temp = updateSubTask(temp, temp[rowIndex].id, ele, 'add');
      }
      temp[rowIndex].projectType = newProjectType;
    } else {
      const index = findIndex(temp, (i) => {
        return i.id === original.taskId;
      });
      if (index > -1) {
        const tempTask = temp[index];
        const newProjectType = tempTask.taskCategoryList[rowIndex]?.projectType;
        const idx = newProjectType.indexOf(ele.id);
        if (idx > -1) {
          newProjectType.splice(idx, 1);
        } else {
          newProjectType.push(ele.id);
        }

        tempTask.taskCategoryList[rowIndex].projectType = newProjectType;
        temp[index] = tempTask;
        // temp = updateMainTask(temp, original.taskId, rowIndex, ele);
      }
    }
    const dummy = { ...tableData, items: temp };
    setTableData(dummy);
  };
  const checkBoxStatus = (type, ele, rowIndex, original) => {
    const temp = [...tableRows];
    let status = false;
    if (type === 'main') {
      const newProjectType = temp[rowIndex]?.projectType;
      const idx = newProjectType?.indexOf(ele.id);
      if (idx > -1) {
        status = true;
      }
    } else {
      const index = findIndex(temp, (i) => {
        return i.id === original.taskId;
      });
      if (index > -1) {
        const tempTask = temp[index];
        const newProjectType = tempTask.taskCategoryList[rowIndex]?.projectType;
        const idx = newProjectType?.indexOf(ele.id);
        if (idx > -1) {
          status = true;
        }
      }
    }
    return status;
  };
  const onOpenTask = (data) => {
    const currentKeys = [...openedList];
    if (openedList.indexOf(data.id) > -1) {
      const index = openedList.indexOf(data.id);
      currentKeys.splice(index, 1);
    } else {
      currentKeys.push(data.id);
    }
    setOpenedList(currentKeys);
  };
  return (
    <div data-testid="task-table ">
      <div className="container-fluid">
        <div className="scroll-wrap-task-admin relative d-flex gant-tabl" style={{ overflow: 'auto', scrollbarGutter: 'stable' }}>
          <table className="card-table over relative">
            <thead className="v-thead-fixed z-index-1100">
              <tr>
                <th className="fixed-gnt whitespace-pre">
                  <TableSortHeader heading="Task" headingProperty="taskName" data={sortData} handleSort={handleSort} />
                </th>
                <th className="whitespace-pre">
                  <TableSortHeader heading="Responsible" headingProperty="responsible" data={sortData} handleSort={handleSort} />
                </th>
                <th className="whitespace-pre">
                  <TableSortHeader heading="Milestone" headingProperty="offset" data={sortData} handleSort={handleSort} />
                </th>
                <th className="whitespace-pre text-center">Actions</th>
                {ProjectTypeList.map((element) => {
                  return (
                    <th className="whitespace-pre">
                      <div>
                        {element.shortName}
                        <span
                          data-tooltip-id="project-short"
                          data-tooltip-content={element.name}
                          className="font-22 icon-Info ms-2 pointer text-blue"
                        />
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableRows?.map((item, indexMain) => {
                let offsetDayss = item?.offset || '0';
                offsetDayss = offsetDayss.concat(' Days');
                const classNameForPhase = item.phase ? item.phase.toLowerCase() : '';
                let classNameForPriority = item.priority ? item.priority.toLowerCase() : '';
                classNameForPriority = classNameForPriority.concat('icon-disc me-1 mt-1 ');

                return (
                  <>
                    <tr className="5">
                      <td className="whitespace-pre fixed-gnt bg-white font-14 ps-2">
                        <div className="d-flex flex-row">
                          <div
                            id={item.id}
                            onClick={() => {
                              onOpenTask(item);
                            }}
                            className="priority-ind d-flex"
                          >
                            {openedList.indexOf(item.id) > -1 ? (
                              <span className="pointer mt-2 icon-arrow-thin-down font-8 me-2 font-semibold" />
                            ) : (
                              <span className="pointer mt-2 icon-arrow-thin-right font-12 me-3 font-semibold" />
                            )}
                            <span className={classNameForPriority}> </span>
                            <div className="text-ellipsis w-150">
                              <span data-tooltip-id="project-Spectitle" data-tooltip-content={item.taskName}>
                                {truncate(item?.taskName, {
                                  length: 20,
                                  separator: ' ',
                                })}
                              </span>
                            </div>
                          </div>
                          <div className="phase-ind ms-4">
                            {item.phase && item.phase !== '0' ? <div className={classNameForPhase}>{item.phase.substr(0, 1)}</div> : <div />}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="responsile text-ellipsis">
                          <span className="text-ellipsis" data-tooltip-id="project-offset" data-tooltip-content={item.responsible}>
                            {item.responsible}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="text-ellipsis" data-tooltip-id="project-offset" data-tooltip-content={offsetDayss}>
                          {truncate(offsetDayss, {
                            length: 20,
                            separator: ' ',
                          })}
                        </span>
                      </td>
                      <td>
                        <div className="d-flex justify-content-start">
                          <div>
                           <button
                            type="button"
                            className="link-btn"
                            onClick={() => {
                              editData(item);
                            }}
                            // disabled={item?.isMapped}
                          >
                            <span className="icon-edit icon-btn  brand-color font-22" />
                          </button>
                          </div>
                          <div  data-tooltip-id="project-dlt1"
                            data-tooltip-content={item?.mappedMessage}>
                            <button
                            type="button"
                            className="link-btn"
                            onClick={() => {
                              deteleData(item);
                            }}
                            disabled={item?.isMapped}
                           
                          >
                            <span className="icon-delete icon-btn  brand-color font-22" />
                          </button></div>
                          <Tooltip id="project-dlt1" place="top" className="tooltip" />

                        </div>
                      </td>
                      {ProjectTypeList.map((element) => {
                        let mainId = 'main-';
                        mainId = mainId.concat(element.id);
                        mainId = mainId.concat('-');
                        mainId = mainId.concat(item.id);
                        return (
                          <td className="whitespace-pre">
                            <div className="check-box">
                              <input
                                checked={checkBoxStatus('main', element, indexMain, item)}
                                onChange={() => handleChangeType(element, indexMain, 'main', item)}
                                type="checkbox"
                                value={element.id}
                                id={mainId}
                              />
                              <label htmlFor={mainId} />
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                    {openedList.indexOf(item.id) > -1 &&
                      item?.taskCategoryList?.map((sub, indexSub) => {
                        let offsetDays = sub?.offset || '0';
                        offsetDays = offsetDays.concat(' Days');
                        return (
                          <>
                            <tr className="sub">
                              <td className="whitespace-pre fixed-gnt bg-white font-13 ps-5 font-semibold">
                                <div className="ps-2 ms-3 d-flex justify-content-between align-items-center">
                                  <div className="pointer value d-flex align-items-center">
                                    <span data-tooltip-id="project-utility" data-tooltip-content={sub.taskCategoryName}>
                                      {truncate(sub.taskCategoryName, { length: 27 })}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td />
                              <td>
                                <span className="text-ellipsis" data-tooltip-id="project-offset" data-tooltip-content={offsetDays}>
                                  {truncate(offsetDays, {
                                    length: 20,
                                    separator: ' ',
                                  })}
                                </span>
                              </td>
                              <td />
                              {ProjectTypeList.map((element) => {
                                let idSub = 'sub-';
                                idSub = idSub.concat(element.id);
                                idSub = idSub.concat('-');
                                idSub = idSub.concat(sub.id);
                                return (
                                  <td className="whitespace-pre">
                                    <div className="check-box">
                                      <input
                                        checked={checkBoxStatus('sub', element, indexSub, sub)}
                                        onChange={() => handleChangeType(element, indexSub, 'sub', sub)}
                                        type="checkbox"
                                        value={element.id}
                                        id={idSub}
                                      />
                                      <label htmlFor={idSub} />
                                    </div>
                                  </td>
                                );
                              })}
                            </tr>
                          </>
                        );
                      })}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {tableData?.totalItems === 0 && (
            <div className="d-flex justify-content-center py-5">No rows found</div>
          )} 
      {tableData?.totalItems > 10 && (
        <Pagination
          pageClick={pageClick}
          pageCount={tableData.totalPages}
          currentpage={currentpage}
          records={tableData.totalItems}
          onSizeChange={onSizeChange}
          size={pageSize}
        />
      )}
      <div className="d-flex justify-content-between mt-2 align-items-center">
        {specificationStatus.isPublished ? (
          <div className="footer-btn d-flex justify-content-end">
            <div className="value font-14">{`Last published date : ${moment(specificationStatus.publishedDate).format('DD-MMM-YYYY')}`}</div>
          </div>
        ) : (
          ''
        )}
        {tableRows?.length ? (
          <div className="footer-btn mt-4 d-flex justify-content-end">
            <button id="cancel" onClick={() => cancel()} type="button" className="secondary-btn large me-4">
              Cancel
            </button>
            <button id="save" onClick={() => saveTaskDetails()} type="button" className="primary-btn large me-4">
              Save As Draft
            </button>
            <button id="publish" onClick={() => publishData()} type="button" className="primary-btn large">
              {specificationStatus.isPublished ? 'Republish' : 'Publish'}
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <Tooltip id="project-Spectitle" place="top" className="tooltip mt-4" />
      <Tooltip id="project-responsible1" place="top" className="tooltip mt-4" />
      <Tooltip id="project-offset" place="top" className="tooltip mt-4" />
      <Tooltip id="project-short" className="tooltip " />
      <Tooltip id="project-value1" place="top" className="tooltip" />
    </div>
  );
};
export default InnerTable1;
