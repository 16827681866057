import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

function DeferredSpecList(props) {
  const { dataList } = props;
  const truncate = (string, length) => {
    if (string.length > length) {
      return `${string.substring(0, length)}...`;
    }
    return string;
  };
  return (
    <div data-testid="deferredLists">
      <div className="head-14 mb-5">{dataList?.projectName}</div>
      {dataList?.deferredSpecList.map((items) => {
        return (
          <div className="mt-3 py-2 d-flex align-items-center bg-sky-white head-black md-radius">

            <div className="ps-4 w-100">
              <span
                data-tooltip-id="project-phase"
                data-tooltip-content={items?.specificationNo}
              >
                {' '}
                {truncate(items?.specificationNo, {
                  length: 8,
                  separator: ' '
                })}

              </span>
            </div>
            <div className="ms-3">
              <span
                data-tooltip-id="project-phase"
                data-tooltip-content={items?.title}
              >
                {' '}
                {truncate(items?.title, {
                  length: 60,
                  separator: ' '
                })}

              </span>

            </div>

            <Tooltip id="project-phase" place="top" className="tooltip mt-3" />
          </div>
        );
      })}
    </div>
  );
}

export default DeferredSpecList;
