import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { fileImages } from '../../../../common/mappingConst';
import helper from '../../../../../utils/helper';

const Attachments = (props) => {
  const { allFiles } = props;
  const { formatBytes } = helper;
  return (
    <div className="table-responsive mb-5" data-testid="add-attachs">
      <table className="card-table">
        <thead>
          <tr>
            <th><span className="head-14"> Vessel Plans and Drawings</span></th>
          </tr>
        </thead>
        <tbody>
          {allFiles?.map((item) => {
            return (
              <>
                <tr>
                  <td>
                    <div className="">
                      <div className="d-flex flex-column justify-content-center">
                        <span className="head-14 mb-1 text-ellipsis" data-tooltip-id="project-utility" data-tooltip-content={item?.documentCategoryName}>
                          {_.truncate(item?.documentCategoryName, {
                            length: 50,
                            separator: ' '
                          })}
                        </span>
                      </div>
                      <div className=" my-3">
                        {item?.projectAttachments?.map((doc) => {
                          return (
                            <div className="ms-4 pt-3 pb-3 pe-3">
                              <div className="d-flex justify-content-between full-width align-items-center">
                                <div className="d-flex col-gap-1">
                                  <div className="thmb-icon-md">
                                    <span className={fileImages[_.toLower(doc.fileType)]} />
                                  </div>
                                  <div className="d-flex flex-column justify-content-center">
                                    <a
                                      href={doc.physicalFileName}
                                      target="_blank"
                                      download="downloaded_file"
                                      className=""
                                      rel="noreferrer"
                                    >
                                      <span className="font-semibold mb-1 text-ellipsis" data-tooltip-id="project-utility" data-tooltip-content={doc.name}>
                                        {_.truncate(doc.name, {
                                          length: 50,
                                          separator: ' '
                                        })}
                                      </span>
                                    </a>
                                    <div className="font-14">
                                      <label className="me-2">Format :</label>
                                      <span className="value">{doc.fileType}</span>
                                      <span className="mx-4">|</span>
                                      <label className="me-2"> Size :</label>
                                      <span className="value">{formatBytes(doc.fileSize)}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </td>
                </tr>

              </>
            );
          })}
        </tbody>
      </table>
      <Tooltip id="project-utility" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Attachments;
