import React from 'react';
import { AccordList } from '../../NewProject/VesselDetails/constants';
import Card from '../../NewProject/VesselDetails/Card';

const VesselDetails = (props) => {
  const { vesselInfo } = props;
  return (

    <div data-testid="vessel-info">
      { AccordList.map((list) => {
        return <Card data={list} vesselInfo={vesselInfo} />;
      })}
    </div>

  );
};
export default VesselDetails;
