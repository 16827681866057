import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ModalBox from '../../../common/ModalBox';
import CreatePage from './CreateUom';
import { deleteUnitOfMeasure, bulkUploadUOM, generatTemplate, getUnitOfMeasuresList, saveUnitOfMeasure, exportExcelUOM } from './services';
import Pagination from '../../../common/pagination';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import UomTable from './uomTable';
import BulkUploadModal from '../CurrencyContainer/bulkUploadModal';
import Loader from '../../../common/loader';

const Uom = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { formValidation, MAX_FILE_SIZE } = helper;
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const initialFormData = {
    uomName: '',
    unit: '',
    status: 0,
    unitStatus: ''
  };
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const [formData, setFormData] = useState(initialFormData);
  const [sortData, setSortData] = useState({
    sortProperty: 'UomName',
    isDescending: false
  });
  useEffect(() => {
    setLoading(true);
  }, []);
  const reloadTable = (page) => {
    setLoading(true);
    getUnitOfMeasuresList({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending
    }).then((res) => {
      setLoading(false);
      setTableData(res.data);
      if (setCount) {
        setCount(res?.data?.totalItems || 0);
      }
    });
  };

  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);

  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    deleteUnitOfMeasure(formData.uomId).then((res) => {
      if (res.success) {
        setFormData(initialFormData);
        reloadTable();
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const saveApiCall = () => {
    if (formValidation(['uomName', 'unit'], formData)) {
      saveUnitOfMeasure(formData).then((res) => {
        if (res.success) {
          reloadTable();
          setFormData(initialFormData);
          setOpenModal(false);
          showAlert('Successfully Saved', 'success');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  useEffect(() => {
    reloadTable();
  }, [sortData]);

  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };
  const handleExcelExportUOM = () => {
    if (tableData?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelUOM(searchText);
    }
  };

  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };
  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadUOM(body).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setIsUpload(true);
        setFile();
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  return (
    <div data-testid="uom">
      {loading ? <Loader /> : (
        <div>
          {openModal ? (
            <ModalBox modalClassName="medium" heading="Add Units" onSave={() => saveApiCall()} closeModal={() => setOpenModal(false)} buttonName="Save">
              <CreatePage formData={formData} setFormData={setFormData} />
            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete UOM ?</p>
            </ModalBox>
          ) : (
            ''
          )}
          <div className="card p-5 mb-4 height-fix">
            <div className="d-flex mb-3 justify-content-between">
              <div className="d-flex col-gap-1 spec-search">
                <div className="search large">
                  <div className="d-flex justify-content-end">
                    <span
                      className={searchText ? ' icon-close-line ' : ''}
                      onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                    />
                    <span className="icon-search" onClick={() => { onSearch({ key: 'Enter' }); }} />
                  </div>

                  <input
                    type="text"
                    className="text-box large with-close"
                    id="search"
                    value={searchText}
                    placeholder="Search by unit name, units"
                    onKeyPress={onSearch}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
                <button
                  type="button"
                  id="add-unit"
                  className="primary-btn large"
                  onClick={() => {
                    setOpenModal(true);
                    setFormData(initialFormData);
                  }}
                >
                  Add Units
                </button>
                <button
                  type="button"
                  id="add-name"
                  className="secondary-btn large"
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  Bulk Upload
                </button>
                {uploadModal && (
                <ModalBox
                  modalClassName="medium"
                  heading="Upload Excel"
                  onSave={() => { bulkUpload(); }}
                  closeModal={() => onCloseModal()}
                  buttonName="Upload"
                  saveDisable={isUpload}
                >
                  <BulkUploadModal
                    file={file}
                    ondrop={ondrop}
                    setFile={setFile}
                    generatTemplateForExcel={generatTemplateForExcel}
                    name="Unit Of Measure"
                    setIsUpload={setIsUpload}
                  />
                </ModalBox>
                )}
              </div>

              <div className="d-flex col-gap-1">
                <button type="button" id="excel" className="link-btn large " onClick={handleExcelExportUOM}>
                  <span className="icon-download me-4" />
                  Excel Download
                </button>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <UomTable
                  tableData={tableData}
                  deteleData={deteleData}
                  onSearch={onSearch}
                  onSizeChange={onSizeChange}
                  handleSort={handleSort}
                  sortData={sortData}
                />
                {tableData?.totalItems > 10 && (
                <Pagination
                  pageClick={pageClick}
                  pageCount={tableData.totalPages}
                  currentpage={currentpage}
                  onSizeChange={onSizeChange}
                  size={pageSize}
                />
                )}
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};
export default Uom;
