import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { fileDownloadFromS3Bucket } from '../TSI/Projects/services';
import ShareReport from '../TSI/Projects/Report/Children/ShareReport';
import { getShareReport } from '../TSI/Projects/Report/services';

const FileDownload = ({ id, name, documentId, projectid,
  isDaily, fromReportHistory, setReport }) => {
  const [loading, setLoading] = useState(false);
  const handleDownload = () => {
    if (id) {
      fileDownloadFromS3Bucket(id, name).then(() => {});
    }
  };
  const [shareReport, setShareReport] = useState(false);
  const [data, setData] = useState('');
  const ShareReports = () => {
    const categoryValue = isDaily ? 1 : 7;
    getShareReport({ projectId: projectid, category: categoryValue, documentId })?.then((res) => {
      if (res.success) {
        setData(res.data);
        setLoading(false);
        if (res.data && res.data.reportLink) {
          res.data.reportLink = res.data.reportLink.replace('http://', 'https://');
        }
      }
    });
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div className="d-flex ">
      <button onClick={handleDownload} className="link-btn" type="button">
        <span
          className="font-22 brand-color icon-download cursor-pointer"
          data-tooltip-id="project-utility"
          data-tooltip-content="Download"
        />
      </button>
      {fromReportHistory && (
        <button
          className="link-btn"
          type="button"
          id="click1"
          onClick={() => {
            ShareReports();
            setShareReport(true);
            setReport(false);
          }}
        >
          <span
            className="share-btn-daily icon-mail-send font-26 ms-4 brand-color cursor-pointer"
            data-tooltip-id="project-utility"
            data-tooltip-content="Send via email"
          />
        </button>
      )}
      <Tooltip id="project-utility" place="top" className="tooltip mt-3" />
      {shareReport ? <ShareReport shareReport={shareReport} ShareReports={ShareReports} setShareReport={setShareReport} data={data} loading={loading} setLoading={setLoading} setReport={setReport} isDaily={isDaily} /> : ''}
    </div>
  );
};
export default FileDownload;
