import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';
import Loader from '../../../common/loader2';

function Addattachments(props) {
  const { handleChange, formData, ondrop, setFormData, fileLoading } = props;
  console.log(formData, 'formDataformData');
  console.log(ondrop, 'ondrop');
  const deleteFile = () => {
    setFormData({ fileName: '', name: '' });
    // const element = document.getElementById('fileName-div');
    // if (element) {
    //   element.classList.add('file-not-added');
    //   element.classList.remove('file-added');
    // }
  };

  // const style = { borderColor: 'red' };

  return (
    <>
      <div className="col-12" data-testid="add-refdocs">
        <InputType1 autoFocus className="modal-input" label="Attachment Name" name="name" id="name" value={formData.name} onChange={(e) => handleChange(e, 'name')} isMandatory="true" />
      </div>
      {fileLoading ? (
        <div id="fileName-div" className="drag-drop-file my-4 file-added">
          <Loader />
        </div>
      )
        : (
          <div id="fileName-div" className="drag-drop-file my-4 file-added">
            <div className="icon-buble mb-5">
              <span className="icon-file-text2" />
            </div>
            <input className="p-5 align-self-center" id="fileName" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
            {!formData.fileName && (
              <p>Choose a file or drag it here(jpeg, jpg, png, tiff, pdf, doc)</p>
            )}
            {formData.fileName && (
              <div className="font-18 d-flex attached-file">
                <span className="icon-check-rnd me-2 font-20"> </span>
                <div className="text-ellipsis">
                  <span
                    data-tooltip-id="project-urlData"
                    data-tooltip-content={`${formData?.fileName?.split('?')[0].split('UploadedFiles/')[1]}`}
                  >
                    {_.truncate(formData?.fileName?.split('?')[0].split('UploadedFiles/')[1], {
                      length: 20,
                      separator: '..'
                    })}
                  </span>

                </div>
                <button type="button" id="delete" className="link-btn clear-file" onClick={() => { deleteFile(); }}>
                  <span className="icon-delete ms-4 font-20 "> </span>
                </button>
              </div>
            )}
            <Tooltip id="project-urlData" place="top" className="tooltip mt-4" />
          </div>
        )}
    </>

  );
}
export default Addattachments;
