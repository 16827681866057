/* eslint-disable */
import React, { useEffect } from 'react';
import Select from 'react-select';
import { cloneDeep } from 'lodash';
import InputType1 from '../../../../../common/InputType1';
import SidePanel from '../../../../../common/sidePanel';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';

const AddServiceLine = (props) => {
  const {
    showSideBar, onClose, specData, newServiceLine, units, acCodes, newSL, setNewSL, newACCode, setnewACCode
  } = props;
  const { formatNumber, ZERO_UUID } = helper;
  const initialData = {
    id: '00000000-0000-0000-0000-000000000000',
    serviceLineName: '',
    type: 0,
    specificationLevel: 0,
    uomId: '00000000-0000-0000-0000-000000000000',
    uomName: '',
    acCodeId: '00000000-0000-0000-0000-000000000000',
    acCode: '',
    number: '',
    quantityEnterBy: '',
    quantity: '',
    order: 0,
    rate: '',
    unit: '',
    discount: '',
    amount: '',
    comments: '',
    isFromYard: false,
    formula: '',
    ownerEstimate: 0,
    ownerCost: 0,
    isNew: true,
    specProgressBarValue: 0,
    jobStatus: 0,
    jobStatusName: '',
    parentSpecificationId: '00000000-0000-0000-0000-000000000000'
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (specData?.serviceLineList?.length) {
      const filteredObjects = specData?.serviceLineList?.filter(
        (obj) => obj.specificationLevel === 3
      );
      const numbers = filteredObjects?.map((obj) => obj.number.replace(/-.*/, ''));
      const result = numbers.map((item) => item.split('.').pop());
      let maxFirstDigit = -Infinity;
      for (let i = 0; i < result.length; i++) {
        const firstDigit = parseInt(result[i]);
        if (firstDigit > maxFirstDigit) {
          maxFirstDigit = firstDigit;
        }
      }
      initialData.number = `${specData?.specificationNo}.${maxFirstDigit + 1}`;
      initialData.parentSpecificationId = specData?.projectSpecId;
    }
    setNewSL(initialData);
  }, [specData]);

  const onselectionchange = (e, field, id) => {
    const temp = cloneDeep(newSL);
    temp[field] = e.label;
    temp[id] = e.value;
    if (field === 'uomName') temp.unit = e.name;
    setNewSL(temp);
  };

  const onFieldChange = (e) => {
    const temp = cloneDeep(newSL);
    let num = 0;
  
    if (e.target) {
      const fieldName = e.target.name;
      const value = e.target.value;
  
      if (fieldName === 'discount') {
        num = value.match(/^\d{0,3}(\.\d{0,2})?$/) ? parseFloat(value) || 0 : temp[fieldName];
        if (num <= 100) {
          temp[fieldName] = num.toString();
        }
      } else if (fieldName === 'quantity') {
        num = value.match(/^\d{0,6}(\.\d{0,2})?$/) ? parseFloat(value) || 0 : temp[fieldName];
        temp[fieldName] = num;
      } else if (fieldName === 'rate') {
        num = value.match(/^\d{0,5}(\.\d{0,2})?$/) ? parseFloat(value) || 0 : temp[fieldName];
        temp[fieldName] = num.toString();
      } else {
        temp[fieldName] = value || '';
      }
      temp.amount = (temp.quantity * temp.rate)
        - (((temp.quantity * temp.rate) / 100) * temp.discount);
      setNewSL(temp);
    }
  };  

  return (
    <SidePanel
      size="small"
      title="Add New Service Line"
      showView={showSideBar}
      onClose={() => {
        onClose();
        setnewACCode(false);
      }}
      onSave={() => {
        if (newSL?.serviceLineName) {
          const temp = cloneDeep(newSL);
          temp.amount = (temp.amount).toString();
          temp.quantity = temp.quantity === '' ? 0 : temp.quantity;
          newServiceLine(temp);
          onClose();
          setNewSL(initialData);
          setnewACCode(false);
        } else {
          showAlert('Please enter mandatory field', 'error');
        }
      }}
      className="side-bar"
    >
      <div data-testid="new-sline">
        <div className="ps-3 align-items-center">
          <div className="mb-3">
            <InputType1
              label="Service Line#"
              name="number"
              id="number"
              className="cursor-disable"
              value={newSL.number}
            />
          </div>
          <div className="mb-3">
            <InputType1
              label="Service Line Name"
              isMandatory="true"
              Placeholder="Enter Service Line Name"
              name="serviceLineName"
              id="serviceLineName"
              className=""
              value={newSL.serviceLineName}
              onChange={(e) => { onFieldChange(e); }}
            />
          </div>
          <div className="mb-3">
            <label>
              UOM
            </label>
            <Select
              className=""
              placeholder="Select UOM"
              options={units}
              classNamePrefix="react-tag"
              id="UOM"
              value={units && units.filter((val) => val.value === newSL.uomId)}
              onChange={(e) => { onselectionchange(e, 'uomName', 'uomId'); }}
            />
          </div>
          {newACCode
            ? (
              <div className="mb-3">
                <InputType1
                  id="acCode"
                  name="acCode"
                  placeholder="Enter AC Code"
                  maxLength="50"
                  value={newSL.acCode ? newSL.acCode : ''}
                  onChange={(e) => onFieldChange(e)}
                  className="modal-input"
                  label="AC Code"
                />
              </div>
            ) :
            <div className="mb-3">
              <label>
                AC Code
              </label>
              <Select
                className=""
                placeholder="Select AC Code"
                options={acCodes}
                classNamePrefix="react-tag"
                id="ACCODE"
                value={acCodes && acCodes.filter((val) => val.value === newSL.acCodeId)}
                onChange={(e) => { onselectionchange(e, 'acCode', 'acCodeId'); }}
              />
            </div>
          }
          <div className="check-box mb-3">
            <input
              type="checkbox"
              id="newACCode"
              name="newACCode"
              checked={newACCode}
              onChange={() => {
                if (!newACCode) {
                  const temp = cloneDeep(newSL);
                  temp.acCodeId = ZERO_UUID;
                  temp.acCode = '';
                  setNewSL(temp);
                }
                setnewACCode(!newACCode);
              }}
            />
            <label htmlFor="newACCode">Input AC Code Manually</label>
          </div>
          <div className="mb-3">
            <InputType1
              min="0"
              type="number"
              step="0.01"
              label="Quantity"
              Placeholder="Enter Quantity"
              name="quantity"
              id="quantity"
              className=""
              value={newSL.quantity}
              onChange={(e) => { onFieldChange(e); }}
            />
          </div>
          <div className="mb-3">
            <InputType1
              label="Rate"
              Placeholder="Enter Rate"
              name="rate"
              id="rate"
              className=""
              value={newSL.rate}
              onChange={(e) => { onFieldChange(e); }}
              min="0"
              type="number"
              step="0.01"
            />
          </div>
          <div className="mb-3">
            <InputType1
              label="Discount[%]"
              name="discount"
              id="discount"
              Placeholder="Enter Discount"
              className=""
              value={newSL.discount}
              onChange={(e) => { onFieldChange(e); }}
            />
          </div>
          <div className="mb-3">
            <InputType1
              label="Amount"
              name="amount"
              Placeholder="Amount"
              id="amount"
              className="cursor-disable"
              value={formatNumber(newSL.amount)}
            />
          </div>
          <div className="mb-3">
            <InputType1
              label="Comments"
              Placeholder="Enter Comments"
              name="comments"
              id="comments"
              className=""
              value={newSL.comments}
              onChange={(e) => { onFieldChange(e); }}
            />
          </div>
        </div>
      </div>
    </SidePanel>
  );
};
export default AddServiceLine;
