import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import showAlert from '../../../../../utils/alert';
import ModalBox from '../../../../common/ModalBox';
import SidePanel from '../../../../common/sidePanel';
import ViewMore from '../../../../Quotations/YardQuotation/ViewMore';
import PreviewContent from './childrens/previewContent';
import PreviewFooter from './childrens/previewFooter';
import SelectYard from './childrens/selectYard';
import { getAllYardList, getAllSpecList, getSendRfqYardList, saveProjectRfq, rfqConfirmYardList } from './services';
import Loader from '../../../../common/loader';

const QuotationPrew = (props) => {
  const { id } = useParams();
  const [showYard, setShowYard] = useState(false);
  const [shortListedYard, setShortListedYard] = useState([]);
  const [yardList, setYardList] = useState([]);
  const [selectedYard, setSelectedYard] = useState([]);
  const [specList, setSpecList] = useState({});
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [sendedYardList, setSendedYardList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { setShowSideBar, setIsSupplementaryClicked, showSideBar } = props;
  const [yardPage, setYardPage] = useState(1);
  const [emailDetails, setEmailDetails] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [days, setDays] = useState(60);
  const [slotDate, setSlotDate] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendRfqList = () => {
    getSendRfqYardList({ id }).then((sendList) => {
      if (sendList.success) {
        setLoading(false);
        const listIds = sendList.data.map((item) => item.yardId);
        setSendedYardList(listIds);
      }
    });
  };
  const getSpecListData = () => {
    getAllSpecList({ id, type: searchText }).then((resp) => {
      if (resp.success) {
        setSpecList(resp.data);
      }
      setLoading(false);
    });
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const confirmYard = (date, day) => {
    const sDate = date || slotDate;
    const eday = day || days;
    if (sDate && getTodayDate() >= sDate) {
      showAlert('Please select a valid date.', 'error');
      return;
    }
    if (sDate && eday) {
      const date1 = `${moment(sDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
      rfqConfirmYardList({
        projectId: id,
        yardId: selectedYard,
        expiryDays: eday,
        slotRequestDate: date1
      }).then((resp) => {
        if (resp.success) {
          setLoading(false);
          setEmailDetails(resp.data);
          setListLoading(false);
        }
      });
    }
  };
  useEffect(() => {
    getAllYardList().then((res) => {
      if (res.success) {
        setYardList(res.data);
      }
    });
    getSpecListData();
    sendRfqList();
  }, []);
  const onSelectYard = () => {
    setShowYard(!showYard);
  };
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  const onCancelYard = (show = true) => {
    const visibility = yardPage === 2 ? false : show;
    setShowYard(visibility);
    setSelectedYard([]);
    setShortListedYard([]);
    setYardPage(1);
    setSlotDate('');
  };
  const onSaveYard = () => {
    const saveReq = [];
    const date = `${moment(slotDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
    selectedYard.map((item) => {
      saveReq.push({
        projectId: id, yardId: item, expiryDays: days, slotRequestDate: date
      });
      return null;
    });
    if (yardPage === 1) {
      setYardPage(2);
      setListLoading(true);
    } else if (slotDate) {
      saveProjectRfq(saveReq).then((res) => {
        if (res.success) {
          showAlert('Successfully sent RFQ to selected yards', 'success');
          sendRfqList();
          setSlotDate('');
          onCancelYard(false);
        }
      });
    } else {
      showAlert('Please select the slot request date.', 'error');
    }
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getSpecListData();
    }
  };
  useEffect(() => {
    if (!searchText) {
      getSpecListData();
    }
  }, [searchText]);
  useEffect(() => {
    if (!showSideBar) {
      setLoading(true);
      getSpecListData();
    }
  }, [showSideBar]);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div data-testid="prew-main">
      {loading ? <Loader /> : (
        <div className="p-4">
          <PreviewContent
            specList={specList}
            mainSec={mainSec}
            showsubSec={showsubSec}
            onSecOpen={onSecOpen}
            setSearchText={setSearchText}
            onSearch={onSearch}
            setShowMore={setShowMore}
            searchText={searchText}
            loading={loading}
          />
          <PreviewFooter
            onSelectYard={onSelectYard}
            setIsSupplementaryClicked={setIsSupplementaryClicked}
            setShowSideBar={setShowSideBar}
            id={id}
          />
          {showYard && (
          <ModalBox
            modalClassName={yardPage === 1 ? 'large' : 'medium modal-scroll'}
            heading={yardPage === 1 ? 'Choose Yards' : 'Confirm RFQ'}
            onSave={() => { onSaveYard(); }}
            closeModal={() => { onCancelYard(yardPage === 1); }}
            closeIcon={() => { onCancelYard(false); }}
            id="select-yard"
            saveDisable={selectedYard.length < 1}
            time={yardPage === 1 ? 500 : 5000}
            buttonName={yardPage === 1 ? '' : 'Confirm and Send RFQ'}
            removeTopMargin
          >
            <div>
              <SelectYard
                yardList={yardList}
                shortListedYard={shortListedYard}
                setShortListedYard={setShortListedYard}
                setSelectedYard={setSelectedYard}
                selectedYard={selectedYard}
                sendedYardList={sendedYardList}
                yardPage={yardPage}
                emailDetails={emailDetails}
                setDays={setDays}
                days={days}
                slotDate={slotDate}
                setSlotDate={setSlotDate}
                setYardPage={setYardPage}
                listLoading={listLoading}
                onSave={() => {
                  if (slotDate) {
                    confirmYard();
                  }
                  onSaveYard();
                }}
                specList={specList}
                closeModal={() => { onCancelYard(yardPage === 1); }}
                closeModalBtn={() => { onCancelYard(false); }}
                saveDisable={selectedYard.length < 1 || specList.repairStartDate}
                buttonName={yardPage === 1 ? 'Send RFQ to Selected Yard' : ''}
                confirmYard={confirmYard}
              />
            </div>

          </ModalBox>
          )}
          <SidePanel
            title={<span data-tooltip-id="project-more" data-tooltip-content={showMore.title}>{`${showMore.specificationNo}. ${_.truncate(showMore.title, { length: 55 })}`}</span>}
            size="large"
            onClose={() => { setShowMore(false); }}
            showView={showMore}
          >
            <Tooltip id="project-more" place="bottom" className="tooltip ms-5" />
            {showMore && <ViewMore showMore={showMore} isYard={false} />}
          </SidePanel>
        </div>
      )}

    </div>
  );
};
export default QuotationPrew;
