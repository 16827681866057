import React from 'react';
import ModalBox from '../../../../../common/ModalBox';

const StatusConfirmModal = (props) => {
  const { setShowStatusConfirmModal, setShowSideBar, setIsComponentClicked } = props;
  return (
    <div data-testid="status-confirm-modal">

      <ModalBox
        modalClassName="medium"
        // onSave={() => onSubmit()}
        isCloseModal={false}
      >
        <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">

          <span className="icon-check-rnd tick-green font-40 pb-3" />

          <div className="mt-3">
            <p className="font-15 font-semibold text-black mb-4">Status has been updated successfully!!</p>
          </div>
          <button
            type="button"
            className="primary-btn medium "
            id="backtoprojects"
            onClick={() => {
              setShowStatusConfirmModal(false);
              setShowSideBar(false);
              setIsComponentClicked(false);
            }}
          >
            Close
          </button>
        </div>
      </ModalBox>
    </div>
  );
};
export default StatusConfirmModal;
