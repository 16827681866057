/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import showAlert from '../../../../../../../utils/alert';
import helper from '../../../../../../../utils/helper';
import Loader from '../../../../../../common/loader';
import ModalBox from '../../../../../../common/ModalBox';
import AddTag from './Children/addTag';
import Cards from './Children/cards';
import { saveProjectSpecTags, getProjectSpecTag, getTags, deleteProjectSpecTags } from './services';

let isSearched = false;
const Tags = (props) => {
  const { projectSpecificationId, isTsiDisabled, isTSI, fromSpecify, fromSpecLibrary, fromSupplementary,
    projInfo, reloadAfterGTMT } = props;
  const { formValidation } = helper;
  const [openModal, setOpenModal] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [acCodes, setAcCodes] = useState([]);
  const [allTagData, setAllTagData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortData, setSortData] = useState({
    sortProperty: 'name',
    isDescending: false,
  });
  const [sLoader, setSLoder] = useState(false);

  const getAllData = (load) => {
    if (!load) setSLoder(true);
    getProjectSpecTag({ id: projectSpecificationId, search: isSearched ? searchText : '' }).then((res) => {
      setSLoder(false);
      if (res.data) {
        const arr = res.data.map((i) => ({
          name: i.name,
          tagId: i.tagId,
          code: i.code
        }));
        setAllTagData(arr);
        setTagData(arr);
      }
    });
  };
  useEffect(() => {
    getAllData();
    getTags({ page: 1, pageSize: 1000, sortProperty: sortData.sortProperty, isDescending: sortData.isDescending }).then((resp) => {
      if (resp.data) {
        const arr = resp.data.items.map((i) => ({
          name: i.name,
          tagId: i.id,
          code: i.code
        }));
        setAcCodes(arr);
      }
    });
  }, [projectSpecificationId]);

  const saveApiCall = () => {
    const temp = tagData.map((i) => {
      return {
        ...i,
        projectSpecificationId: projectSpecificationId
      };
    });
    if (formValidation(['name'], tagData)) {
      saveProjectSpecTags(temp).then((res) => {
        if (res.success) {
          setOpenModal(false);
          showAlert('Successfully Saved', 'success');
          reloadAfterGTMT('', projectSpecificationId, false, true);
          getAllData();
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const deleteApiCall = () => {
    deleteProjectSpecTags({ id: projectSpecificationId, tagId: showDelete }).then((res) => {
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
        getAllData();
        setShowDelete(false);
      }
    });
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearched = true;
      getAllData();
    }
  };
  useEffect(() => {
    if (!searchText) {
      getAllData();
    }
  }, [searchText]);
  return (
    <div className="list-tab-content content-scroller-height" data-testid="tag-spec">
      <div className="d-flex my-4 justify-content-between tab-content-ctrl">
        <div className="d-flex">
          <div className="search spec-search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
              />
              <span
                className="icon-search"
                id="search-click"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              value={searchText}
              className="text-box with-close large"
              placeholder="Search"
              id="search"
              onKeyPress={onSearch}
              onChange={(e) => {
                isSearched = false;
                setSearchText(e.target.value);
              }}
            />
          </div>
          {isTSI ? (
            fromSpecLibrary ? (
              ''
            ) : (
              <button
                type="button"
                disabled={isTsiDisabled || fromSupplementary || projInfo?.isClosed}
                id="add-tag"
                className="secondary-btn large ms-4"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Add Tag
              </button>
            )
          ) : (
            ''
          )}
        </div>
      </div>
      {sLoader ? (
        <Loader />
      ) : (
        <div className="scroll-area">
          {allTagData.map((item) => {
            return (
              <Cards
                item={item}
                setShowDelete={setShowDelete}
                isTsiDisabled={isTsiDisabled}
                isTSI={isTSI}
                fromSpecLibrary={fromSpecLibrary}
                fromSupplementary={fromSupplementary}
                projInfo={projInfo}
              />
            );
          })}
          {allTagData.length < 1 && (
            <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
              <div className="font-18 animate__animated animate__headShake">No data found</div>
            </div>
          )}
        </div>
      )}
      {openModal ? (
        <ModalBox
          modalClassName="small"
          onSave={() => saveApiCall()}
          removeClass={!fromSpecify}
          onDelete={() => deleteApiCall()}
          closeModal={() => {
            setOpenModal(false);
            getAllData(true);
          }}
          heading="Add Tag"
          buttonName="Save"
        >
          <AddTag tagData={tagData} setTagData={setTagData} acCodes={acCodes} allTagData={allTagData} setAllTagData={setAllTagData} />
        </ModalBox>
      ) : (
        ''
      )}
      {showDelete ? (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          removeClass={!fromSpecify}
          onSave={() => deleteApiCall()}
          closeModal={() => {
            setShowDelete(false);
          }}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete Tag ?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default Tags;
