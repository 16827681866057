import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { fileImages } from '../../../../../../common/mappingConst';
import helper from '../../../../../../../utils/helper';

const AttachCard = (props) => {
  const { index, showDeleteModal, isTsiDisabled, isTSI, projInfo } = props;
  const { formatBytes } = helper;
  return (
    <div className="card-inner-table p-3 mt-3 d-flex justify-content-between align-items-center" data-testid="attachcard">
      <div className="d-flex align-items-center">
        <div className="thmb-icon-md">
          <span className={fileImages[_.toLower(index.type)]} />
        </div>
        <div className="ps-4">
          <a
            href={index.path}
            target="_blank"
            download="downloaded_file"
            className=""
            rel="noreferrer"
          >
            <div
              className="value"
              data-tooltip-id="project-attach"
              data-tooltip-content={`${index.fname}`}
            >
              {_.truncate(index?.fname, {
                length: 90,
                separator: ''
              })}
            </div>
          </a>

          <div className="d-flex text-ellipsis">
            Size
            {' '}
            <span className="ms-2 me-2">:</span>
            <p className="value">
              {' '}
              {formatBytes(index.size)}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex me-3 align-items-center col-gap-2">
        <button type="button" className="link-btn large">
          <a href={index.path} target="_blank" download="downloaded_file" className="" rel="noopener noreferrer">
            <span className="icon-download font-20" />
          </a>
        </button>
        {isTSI ? (
          <button
            className="link-btn large"
            type="button"
            disabled={isTsiDisabled || projInfo?.isClosed}
            id="delete"
            onClick={() => { showDeleteModal(index.documentId); }}
          >
            <span className="icon-delete font-20" />
          </button>
        ) : ''}
      </div>
      <Tooltip id="project-attach" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default AttachCard;
