import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ModalBox from '../../../../common/ModalBox';
import CreateSpecPackages from './CreateNonSpec';
import { getNonSpec, saveNonSpec, deleteNonSpec } from './services';
import helper from '../../../../../utils/helper';
import showAlert from '../../../../../utils/alert';
import NonSpecTable from './nonSpecTable';
import Pagination from '../../../../common/pagination';
import Loader from '../../../../common/loader';

const NonSpec = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';

  const { formValidation } = helper;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const firstLetter = String.fromCharCode(65);
  const initialFormData = { title: '', specificationNo: firstLetter };
  const [formData, setFormData] = useState(initialFormData);
  const [tableData, setTableData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const reloadTable = (page) => {
    getNonSpec({
      page: page || currentpage,
      pageSize,
      search: searchText,
      type: 3,
      sortProperty: 'specificationNo'
    }).then((res) => {
      setLoading(false);
      setTableData(res.data);
      setCount(res?.data?.totalItems);
    });
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);
  const editData = (data) => {
    setFormData(data);
    setOpenModal(true);
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    deleteNonSpec({ id: formData.id }).then((res) => {
      if (res.success) {
        setLoading(false);
        showAlert('Successfully Deleted ', 'success');
        reloadTable();
        setOpenDeleteModal(false);
      }
    });
  };
  const saveApiCall = () => {
    if (formValidation(['title', 'specificationNo'], formData)) {
      saveNonSpec(formData).then((res) => {
        if (res.success) {
          setLoading(false);
          setOpenModal(false);
          setFormData(initialFormData);
          reloadTable();
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
    console.log(formData, 'formData');
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const onSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);

  return (
    <div data-testid="spec-nonspec">
      {loading ? <Loader /> : (
        <div>
          {openModal ? (
            <ModalBox modalClassName="medium" heading="Add Non Spec" onSave={() => saveApiCall()} closeModal={() => setOpenModal(false)} buttonName="Save">
              <CreateSpecPackages formData={formData} setFormData={setFormData} />
            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete Non Spec section ?</p>
            </ModalBox>
          ) : (
            ''
          )}
          <div className="card p-5 mb-4 height-fix">
            <div className="d-flex mb-5 col-gap-1 spec-search">
              <div className="search large">
                <div className="d-flex justify-content-end">
                  <span
                    className={searchText ? ' icon-close-line ' : ''}
                    onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                  />
                  <span className="icon-search" onClick={() => { onSearch({ key: 'Enter' }); }} />
                </div>

                <input type="text" value={searchText} id="search" className="text-box large with-close" placeholder="Search by sec#, section name" onKeyPress={onSearch} onChange={onSearchText} />
              </div>
              <button
                type="button"
                className="primary-btn large"
                id="add-nonspec"
                onClick={() => {
                  setOpenModal(true);
                  const letter = String.fromCharCode(65 + count);
                  const iFormData = { title: '', specificationNo: letter };
                  setFormData(iFormData);
                }}
              >
                Add Non Spec
              </button>
            </div>
            <div>
              <NonSpecTable
                tableData={tableData}
                deteleData={deteleData}
                editData={editData}
                onSearch={onSearch}
                onSizeChange={onSizeChange}
              />
              {tableData?.totalItems > 10 && (
              <Pagination
                pageClick={pageClick}
                pageCount={tableData.totalPages}
                currentpage={currentpage}
                onSizeChange={onSizeChange}
                size={pageSize}
              />
              )}
            </div>
          </div>

        </div>
      )}
    </div>
  );
};
export default NonSpec;
