/* istanbul ignore file */
import React from 'react';
import arealoader from '../../images/dot-loader.gif';

const AreaLoader = () => {
  return (
    <div className="">
      <span className="text color">
        <img src={arealoader} alt="" />
      </span>
    </div>
  );
};
export default AreaLoader;
