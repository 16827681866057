import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import { s3UploadMutiple } from '../../../NewProject/services';

const Addattachment = (props) => {
  const { formData, setFormData } = props;
  const { MAX_FILE_SIZE } = helper;
  const acceptedFiles = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = formData.projectAttachments.map((items) => { return items.name; });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formData);
          // const addDocs = _.cloneDeep(addAttachs);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fileName: item.name,
              path: item.path,
              fileType: item.type,
              type: item.type,
              fileSize: item.size,
              documentCategory: formData.documentCategory,
              phase: 1
            };

            return (
              attach.projectAttachments.push(newItem)
            );
          });
          setFormData(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };

  const deleteFile = (key) => {
    const attachment = { ...formData };
    attachment.projectAttachments.splice(key, 1);
    setFormData(attachment);
  };
  return (
    <>
      <div data-testid="Add-attachments">
        <div className="drag-drop-file my-4 file-added">
          <div className="icon-buble mb-5">
            <span className="icon-file-text2" />
          </div>
          <input className="p-5 align-self-center" id="upload" type="file" name="name" multiple onChange={(e) => { ondrop(e); }} isMandatory="true" />
          <p>Choose a file or drag it here(jpeg, jpg, png, tiff, pdf, doc - max size(5MB))</p>
        </div>
        <div className="attached-file-multi">
          {formData?.projectAttachments?.map((item, key) => {
            return (
              <>
                <div className="d-flex item">
                  <span className="icon-check-rnd me-2 file-added-multi font-20"> </span>
                  <div>
                    <span data-tooltip-id="project-addattach" data-tooltip-content={item?.name}>
                      {_.truncate(item.name, {
                        length: 25
                      })}
                    </span>

                  </div>
                  <button type="button" id="delete" className="link-btn clear-file" onClick={() => { deleteFile(key); }}>
                    <span className="icon-delete delete-icon-multi ms-4 font-20 "> </span>
                  </button>
                </div>
              </>
            );
          })}
        </div>
        <Tooltip id="project-addattach" place="top" className="tooltip mt-3" />
      </div>
    </>
  );
};

export default Addattachment;
