/* istanbul ignore file */
/*eslint-disable*/
import React, { useEffect, useState, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import { cloneDeep } from 'lodash';
import Loader from '../../../common/loader';
import { getReportTemplateLists, saveTemplates } from './services';

const TextEditor = (props) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [cancelData, setCancelData] = useState('');
  const [isEditClick, setIsEditClick] = useState(true);
  const { projectSpecificationId, activeModalData, formData, setFormData, showbtn, setShowbtn, readonly
  } = props;
  const buttons = [
    //  'font',
    'fontsize',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'ul',
    'ol',
    'indent',
    'video',
    'align',
    'brush',
    'image',
    'table',
    'link',
    'hr'
  ];
  const config = useMemo(
    () => ({
      readonly,
      toolbarAdaptive: false,
      tabIndex: -1,
      buttons,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: 'insert_as_html',
      uploader: {
        insertImageAsBase64URI: true,
        isMultiple: true
      },
      spellcheck: true
    }),
    [readonly]
  );
  const GetReportTemplateList = () => {
    getReportTemplateLists(
      activeModalData.id
    ).then((res) => {
      if (res.success) {
        setLoading(false);
        setCancelData(res?.data?.bodyHtml);
        setFormData(res?.data);
      }
    });
  };
  console.log(activeModalData);
  const cancel = () => {
    const fd = cloneDeep(formData);
    fd.bodyHtml = cancelData;
    setFormData(fd);
    setShowbtn(true);
  };
  const SaveTemplate = () => {
    const payload = {
      projectSpecificationId: activeModalData.id,
      bodyHtml: content
    };
    saveTemplates(payload).then((res) => {
      if (res.success) {
        showAlert('Successfully Updated', 'success');
        setShowbtn(false);
        setIsEditClick(true);
        setCancelData('');
        setFormData({});
        GetReportTemplateList();
      }
    });
  };
  useEffect(() => {
    if (projectSpecificationId) {
      GetReportTemplateList();
    }
  }, [projectSpecificationId]);
  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <div data-testid="description-main" className="description-main">
      {loading ? (
        <div className="drag-drop-file my-4">
          {' '}
          <Loader />
        </div>
      ) : (
        <>
          <div className="row jodit-bg jodit-temp-height">
            <div className="jj">
              <JoditEditor
                // ref={editor}
                value={formData.bodyHtml}
                config={config}
                cancel={cancel}
                SaveTemplate={SaveTemplate}
                onBlur={(newContent) => {
                  const w = cloneDeep(formData);
                  w.bodyHtml = newContent;
                  setFormData(w);
                  setContent(newContent);
                }}

              />
            </div>
            {/* </div> */}
          </div>

        </>
      )}

    </div>
  );
};
export default TextEditor;
