import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const UploadFile = (props) => {
  const { ondrop, setNewJob, newJob } = props;
  const onDeletFile = () => {
    const currentImage = { ...newJob };
    currentImage.path = '';
    setNewJob(currentImage);
  };
  return (
    <div className="p-3 mt-2" data-testid="upload-card">
      <div className="row">
        {!newJob.path ? (
          <div id="fileName-div" className="drag-drop-file my-4 file-waiting col-12">
            <input className="p-5 align-self-center" id="fileName" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
            <div className="d-flex row w-full">
              <div className="d-flex flex-column justify-content-center align-items-center col-8">
                <div className="icon-buble mb-2">
                  <span className="icon-upload-cloud" />
                </div>
                <div>Choose a file or drag it here</div>
                <div className="font-12">(File formats: jpeg, jpg, png, tiff, doc, pdf - Max size 5MB)</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-4">
                <div className="div_btn">
                  Browse File
                </div>
              </div>
            </div>
          </div>
        )
          : (
            <div id="fileName-div" className="drag-drop-file my-4 file-added col-12 d-flex align-items-center flex-row">
              <div className="font-18 d-flex col-7 align-items-center">
                <span className="icon-image me-2 font-20"> </span>
                <div>
                  <span
                    data-tooltip-id="project-fileName"
                    data-tooltip-content={newJob.fileName}
                  >
                    {' '}
                    {_.truncate(newJob.fileName, { length: 25 })}

                  </span>

                </div>
                <span id="delete" className="icon-close ms-3 font-20 red" onClick={() => { onDeletFile(); }}> </span>
              </div>
            </div>
          )}
      </div>
      <Tooltip id="project-fileName" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default UploadFile;
