import { serviceHandler } from '../../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../../utils/apiUrlConstants';

const { GetPurchaseOrder, SaveSpecPurchaseOrder, DeletePoPr } = apiEndPoints;

export const getPurchaseOrderRequestsBySpec = (payload) => {
  return serviceHandler({
    url: `${GetPurchaseOrder}?specId=${payload.specId}&search=${payload.search}&prPoType=${payload.prPoType}`,
    methordType: 'get',
    payload
  });
};

export const saveSpecPurchaseOrder = (payload) => {
  return serviceHandler({
    url: `${SaveSpecPurchaseOrder}`,
    methordType: 'post',
    payload
  });
};

export const deletePopr = (payload) => {
  return serviceHandler({
    url: `${DeletePoPr}?specId=${payload.specId}&PrPoId=${payload.id}&PrPoType=${payload.poprType}`,
    methordType: 'put',
    payload
  });
};
