/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable space-infix-ops */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _, { cloneDeep, map } from 'lodash';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Components from './Components';
import Description from './Description';
import ServiceLine from './ServiceLine';
import InspectionsApprovals from './InspectionsApprovals';
import Attachment from './Attachment';
import POPR from './POPR';
import WODefectList from './WODefectList';
import ModalBox from '../../../../../common/ModalBox';
import { getSpecMaster, getSpecsDependent, saveDependentSpecs } from '../services';
import { getProfileData } from '../../../../../Login/services';
import SpecMainCard from './DependentSpecs';
import showAlert from '../../../../../../utils/alert';
import Loader from '../../../../../common/loader';
import helper from '../../../../../../utils/helper';

const SpecList = (props) => {
  const {
    selectedSpec,
    isDraft,
    projectId,
    specDetails,
    onReload,
    isTsiDisabled,
    isTSI,
    isSidePanel,
    isSidebar,
    handleStatusChange,
    handleAssigneeChange,
    selectedStatus,
    setSelectedStatus,
    setSelectedAssignee,
    selectedAssignee,
    currency,
    fromSpecify,
    currencyId,
    fromMainSpecify,
    fromSpecLibrary,
    fromViewMore,
    loading,
    setLoading,
    fromVariation,
    fromSupplementary,
    activeTabSupplementary,
    projectData,
    reloadAfterGTMT
  } = props;
  const [active, setActive] = useState('Description');
  const [tempSpec, setTempSpec] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [specMaster, setSpecMaster] = useState();
  const [dependentList, setDependentList] = useState();
  const [searchText, setSearchText] = useState('');
  const [specMainId, setSpecMainId] = useState('');
  const [selectedSpecs, setSelectedSpecs] = useState({});
  const [closedSection, setClosedSection] = useState([]);
  const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
  const StatusList = [
    { label: 'Not Started', value: 0 },
    { label: 'In Progress', value: 1 },
    { label: 'Completed', value: 2 }
  ];
  const MasterStatusList = [
    { label: 'Not Started', value: 0 },
    { label: 'In Progress', value: 1 },
    { label: 'Completed', value: 2 },
    { label: 'Approved', value: 3 }
  ];
  const AssigneeDropDown = [
    { label: 'Yard', value: 1 },
    { label: 'Vessel', value: 2 },
    { label: 'Sub Contractor', value: 3 }
  ];
  const [status, setStatus] = useState();
  const [statusDraft, setStatusDraft] = useState();
  const { charValidation, roles } = helper;
  const userId = localStorage?.getItem('USER_ID');
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = userRole === roles.VESSEL_USER;
  const [isApprover, setIsApprover] = useState(false);
  useEffect(() => {
    if (selectedSpec !== tempSpec) {
      setTempSpec(selectedSpec);
      if (fromSpecLibrary) {
        setActive('Description');
      } else {
        setActive('Description');
      }
    }
    if (specDetails?.completionStatus === 0) {
      setSelectedStatus({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc skywhite" />
            <span>Not Started</span>
          </div>
        ),
        value: 0
      });
    } else if (specDetails?.completionStatus === 1) {
      setSelectedStatus({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc pending" />
            <span>In Progress</span>
          </div>
        ),
        value: 1
      });
    } else if (specDetails?.completionStatus === 2) {
      setSelectedStatus({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc active" />
            <span>Completed</span>
          </div>
        ),
        value: 2
      });
    } else if (specDetails?.completionStatus === 3) {
      setSelectedStatus({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc submit" />
            <span>Approved</span>
          </div>
        ),
        value: 3
      });
    }
  }, [specDetails]);

  useEffect(() => {
    if (selectedSpec !== tempSpec) {
      setTempSpec(selectedSpec);
      if (fromSpecLibrary) {
        setActive('Description');
      } else {
        setActive('Description');
      }
    }
    if (specDetails?.specAssignedTo === 1) {
      setSelectedAssignee({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc skywhite" />
            <span>Yard</span>
          </div>
        ),
        value: 1
      });
    } else if (specDetails?.specAssignedTo === 2) {
      setSelectedAssignee({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc pending" />
            <span>Vessel</span>
          </div>
        ),
        value: 2
      });
    } else if (specDetails?.specAssignedTo === 3) {
      setSelectedAssignee({
        label: (
          <div className="d-flex align-items-center">
            <span className="icon-disc active" />
            <span>Sub Contractor</span>
          </div>
        ),
        value: 3
      });
    }
  }, [specDetails]);

  const getStatusList = () => {
    getProfileData(userId).then((res) => {
      if (res.data.isLocalAdmin) {
        setIsApprover(true);
      }
      const list = MasterStatusList.map((opt) => {
        return {
          label: (
            <div className="d-flex align-items-center">
              <span className={`icon-disc ${opt?.value === 2 ? 'active' : opt?.value === 1 ? 'pending' : opt?.value === 3 ? 'submit' : 'skywhite'}`} />
              <span className="ml-8">{opt.label}</span>
            </div>
          ),
          value: opt.value
        };
      });
      setStatusDraft(list);
      const list2 = StatusList.map((opt) => {
        return {
          label: (
            <div className="d-flex align-items-center">
              <span className={`icon-disc ${opt?.value === 2 ? 'active' : opt?.value === 1 ? 'pending' : 'skywhite'}`} />
              <span className="ml-8">{opt.label}</span>
            </div>
          ),
          value: opt.value
        };
      });
      setStatus(list2);
    }).catch(() => { });
  };

  useEffect(() => {
    getStatusList();
  }, [selectedSpecs]);
  /* istanbul ignore next */
  const getSpectMasterList = (l) => {
    const dList = l || dependentList;
    getSpecMaster(projectId, searchText).then((resp) => {
      if (resp?.success) {
        if (dList && dList?.length > 0) {
          dList.map((el) => {
            const list = resp?.data.filter((e) => e.id === el.parentId);
            list?.map((c, i) => {
              c?.specifications?.map((spec, ind) => {
                if (spec?.dependentSpecId || spec?.id === el.dependentSpecId) {
                  // eslint-disable-next-line no-param-reassign
                  spec.isMapped = true;
                }
                return null;
              });
              return null;
            });
            return null;
          });
        }
        setSpecMaster(resp.data);
      }
    });
  };
  /* istanbul ignore next */
  const handleOpenDependedSpec = () => {
    getSpecsDependent(specDetails?.id).then((resp) => {
      if (resp?.success) {
        setLoading(false);
        const s = [];
        resp.data.forEach((i) => {
          s.push({
            ...i,
            idApi: i.id
          });
        });
        setDependentList(s);
        getSpectMasterList(s);
      } else {
        getSpectMasterList();
      }
    });
    setOpenModal(true);
  };
  const onSearch = (e) => {
    if (!e || e.key === 'Enter') {
      getSpectMasterList();
    }
  };
  /* istanbul ignore next */
  const handleInput = (e, index) => {
    const updatedList = cloneDeep(dependentList);
    updatedList[index].order = charValidation(e, 2) || 0;
    setDependentList(updatedList);
  };
  /* istanbul ignore next */
  const onAccordianClose = (secId) => {
    const currentList = [...closedSection];
    if (currentList.indexOf(secId) > -1) {
      currentList.splice(currentList.indexOf(secId), 1);
    } else {
      // currentList.push(secId);
      currentList[0] = secId;
    }
    setClosedSection(currentList);
  };
  const onSpecSelect = (field, id) => {
    setSelectedSpecs(field);
    if (id) setSpecMainId(id);
  };
  /* istanbul ignore next */
  const saveApiCall = () => {
    const payload = cloneDeep(dependentList);

    payload?.forEach((item) => {
      item.id = item.idApi || ZERO_UUID;
      item.projectSpecId = specDetails?.id;
    });
    const data = {
      projectSpecId: specDetails?.id,
      dependentSpecs: payload
    };
    saveDependentSpecs(data).then((resp) => {
      if (resp?.success) {
        showAlert('Successfully Saved', 'success');
        setOpenModal(false);
      }
    });
  };
  /* istanbul ignore next */
  const onArrowClick = (dir) => {
    if (selectedSpecs && selectedSpecs.id) {
      if (dir === 'right') {
        let depUpdatedList = {};
        const specs = cloneDeep(specMaster);
        if (specs && specs?.length > 0) {
          specs.map((el) => {
            if (el?.id === specMainId) {
              if (el?.specifications && el?.specifications?.length > 0) {
                el?.specifications?.map((c, i) => {
                  if (c?.id === selectedSpecs?.id) {
                    c.isMapped = true;
                    c.parentId = specMainId;
                    c.dependentSpecId = c?.id;
                    c.projectId = projectId;
                    // c.id = ZERO_UUID;
                    depUpdatedList = c;
                  }
                  return null;
                });
              }
            }
            return null;
          });
        }
        const depList = [...dependentList, depUpdatedList];
        const uniqueList = depList.filter((item, index) => {
          return depList.findIndex((items) => items.id === item.id) === index;
        });
        const filtered = uniqueList.filter((el) => Object.keys(el).length);
        setDependentList(filtered);
        setSpecMaster(specs);
      } else {
        // getSpectMasterList();
        const specs = cloneDeep(specMaster);
        if (specs && specs?.length > 0) {
          specs.map((el) => {
            if (el?.id === selectedSpecs?.parentId) {
              if (el?.specifications && el?.specifications?.length > 0) {
                el?.specifications?.map((c, i) => {
                  if ((c?.dependentSpecId || c?.id) === selectedSpecs?.dependentSpecId) {
                    c.isMapped = false;
                  }
                  return null;
                });
              }
            }
            return null;
          });
        }
        const updatedList = dependentList.filter((item) => item.id !== selectedSpecs?.id);
        setDependentList(updatedList);
        setSpecMaster(specs);
      }
    }
  };
  return (
    <div className={`d-flex ${isSidePanel ? 'col-8' : ''}`} data-testid="speclist_main">
      <div className={`flex-grow-1 ${isSidePanel ? 'card p-5' : ''}`}>
        {fromSpecLibrary ? (
          <div className={`${isSidePanel ? 'right-content-wrap' : ''}`}>
            {isTSI && !isSidebar ? (
              <div className="row mb-4">
                <div className="col-9">
                  <div className="d-flex">
                    <h4
                      className=" sub-heading break-all text-ellipsis w-yardcomment"
                      data-tooltip-id="project-spec"
                      data-tooltip-content={`${specDetails.specificationNo}. ${specDetails.title}`}
                    >
                      {`${specDetails.specificationNo}. ${_.truncate(specDetails.title, { length: 30 })}`}
                    </h4>
                    {!fromViewMore && (
                      <Select
                        className="status-select w-150 ms-5"
                        options={status}
                        isSearchable={false}
                        isDisabled={isTsiDisabled}
                        placeholder="Select"
                        value={status?.filter((list) => list.value === selectedStatus?.value)}
                        onChange={(e) => {
                          handleStatusChange(e);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-3">
                  <div className="d-flex justify-content-end">
                    {!fromViewMore && (
                      <button
                        disabled={isTsiDisabled}
                        id="depended-spec"
                        onClick={() => {
                          setClosedSection([]);
                          handleOpenDependedSpec();
                        }}
                        type="button"
                        className="secondary-btn"
                      >
                        Add Dependent Spec
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="list-tab-head d-flex justify-content-between border-down ">
              <div id="desc" onClick={() => setActive('Description')} className={`tab-items col-xxxl-2  ${active === 'Description' && 'active '}`}>
                Description
              </div>
              <div
                id="tag"
                onClick={() => setActive('InspectionsApprovals')}
                className={`tab-items me-md-auto ms-md-auto ${active === 'InspectionsApprovals' && 'active'}`}
              >
                Tags
              </div>
            </div>
            <div className="">
              {active === 'Description' && (
                <Description
                  fromSpecLibrary
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                />
              )}
              {active === 'InspectionsApprovals' && (
                <InspectionsApprovals
                  fromSpecLibrary
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  fromSupplementary={fromSupplementary}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={`${isSidePanel ? 'right-content-wrap' : ''}`}>
            {isTSI && !isSidebar ? (
              <div className="mb-4 d-flex justify-content-between">
                <div className=" ">
                  <div className="d-flex">
                    <h4
                      className=" sub-heading break-all text-ellipsis w-yardcomment"
                      data-tooltip-id="project-spec"
                      data-tooltip-content={`${specDetails.specificationNo}. ${specDetails.title}`}
                    >
                      {`${specDetails.specificationNo}. ${_.truncate(specDetails.title, { length: 35 })}`}
                    </h4>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {!fromViewMore && (
                    specDetails.isDraft
                      ? (
                        <Select
                          className="status-select w-150 ms-5"
                          options={statusDraft}
                          isSearchable={false}
                          isDisabled={isTsiDisabled}
                          placeholder="Select"
                          value={statusDraft?.filter((list) => list.value === selectedStatus?.value)}
                          onChange={(e) => {
                            if (e.value === 3 && !isApprover) {
                              showAlert('Only Vessel User with Approver status can Approve the Draft.', 'error');
                            } else {
                              handleStatusChange(e);
                            }
                          }}
                        />
                      ) : (
                        <Select
                          className="status-select w-150 ms-5"
                          options={status}
                          isSearchable={false}
                          isDisabled={isTsiDisabled}
                          placeholder="Select"
                          value={status?.filter((list) => list.value === selectedStatus?.value)}
                          onChange={(e) => {
                            if (e.value === 3 && !isMaster) {
                              showAlert('Only Master can Approve the Draft', 'error');
                            } else {
                              handleStatusChange(e);
                            }
                          }}
                        />
                      )
                  )}
                  {!isVesselUser && (
                    <Select
                      className="status-select w-150 ms-5"
                      options={AssigneeDropDown}
                      isSearchable={false}
                      isDisabled={isTsiDisabled}
                      placeholder="Select"
                      value={AssigneeDropDown?.filter((list) => list.value === selectedAssignee?.value)}
                      onChange={(e) => {
                        handleAssigneeChange(e);
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="list-tab-head d-flex justify-content-between border-down ">
              <div id="desc" onClick={() => setActive('Description')} className={`tab-items ${active === 'Description' && 'active'}`}>
                Description
              </div>
              <div id="serline" onClick={() => setActive('ServiceLine')} className={`tab-items ${active === 'ServiceLine' && 'active'}`}>
                Service Line
              </div>
              <div id="comp" onClick={() => setActive('Components')} className={`tab-items ${active === 'Components' && 'active'}`}>
                Components
              </div>
              <div
                id="tag"
                onClick={() => setActive('InspectionsApprovals')}
                className={`tab-items ${active === 'InspectionsApprovals' && 'active'}`}
              >
                Tags
              </div>
              <div id="attach" onClick={() => setActive('Attachment')} className={`tab-items ${active === 'Attachment' && 'active'}`}>
                Attachments
              </div>
              <div id="popr" onClick={() => setActive('POPR')} className={`tab-items ${active === 'POPR' && 'active'}`}>
                PO/PR
              </div>
              <div id="wodefect" onClick={() => setActive('WODefectList')} className={`tab-items ${active === 'WODefectList' && 'active'}`}>
                WO/Defect List
              </div>
            </div>
            <div className="">
              {active === 'Description' && (
                <Description
                  fromSpecify
                  fromMainSpecify
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  fromSupplementary={fromSupplementary}
                  reloadAfterGTMT={reloadAfterGTMT}
                />
              )}
              {active === 'ServiceLine' && (
                <ServiceLine
                  fromSpecify
                  specDraftFromSpecify="yes"
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  specDetails={specDetails}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  fromSupplementary={fromSupplementary}
                  activeTabSupplementary={activeTabSupplementary}
                  isDraft={isDraft}
                  projectData={projectData}
                  reloadAfterGTMT={reloadAfterGTMT}
                />
              )}
              {active === 'Components' && (
                <Components
                  fromSpecify
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  specDetails={specDetails}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  fromSupplementary={fromSupplementary}
                  activeTabSupplementary={activeTabSupplementary}
                  reloadAfterGTMT={reloadAfterGTMT}
                />
              )}
              {active === 'InspectionsApprovals' && (
                <InspectionsApprovals
                  fromSpecify
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  fromSupplementary={fromSupplementary}
                  reloadAfterGTMT={reloadAfterGTMT}
                />
              )}
              {active === 'Attachment' && (
                <Attachment
                  fromSpecify
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  fromSupplementary={fromSupplementary}
                  reloadAfterGTMT={reloadAfterGTMT}
                />
              )}
              {active === 'POPR' && (
                <POPR
                  currencyId={currencyId}
                  fromSpecify
                  currency={currency}
                  projectSpecificationId={selectedSpec}
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectId={projectId}
                  onReload={onReload}
                  fromVariation={fromVariation}
                  fromSupplementary={fromSupplementary}
                  activeTabSupplementary={activeTabSupplementary}
                  reloadAfterGTMT={reloadAfterGTMT}
                  projectData={projectData}
                />
              )}
              {active === 'WODefectList' && (
                <WODefectList
                  fromSpecify
                  isTSI={isTSI}
                  isTsiDisabled={isTsiDisabled}
                  projectSpecificationId={selectedSpec}
                  projectId={projectId}
                  onReload={onReload}
                  fromVariation={fromVariation}
                  fromSupplementary={fromSupplementary}
                  reloadAfterGTMT={reloadAfterGTMT}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {openModal ? (
        <ModalBox
          modalClassName="sub-large"
          onSave={() => saveApiCall()}
          closeModal={() => setOpenModal(false)}
          heading="Add Dependent Specs"
          buttonName="Save"
        >
          <div className="spec-list side">
            {loading ? (
              <Loader />
            ) : (
              <SpecMainCard
                gtSpecData={specMaster}
                dependentList={dependentList}
                onSpecSelect={onSpecSelect}
                specDetails={specDetails}
                selectedSpec={selectedSpecs}
                setSelectedSpecs={setSelectedSpecs}
                onAccordianClose={onAccordianClose}
                closedSection={closedSection}
                searchText={searchText}
                onSearch={onSearch}
                handleInput={handleInput}
                setSearchText={setSearchText}
                onArrowClick={onArrowClick}
              />
            )}
            {/* <div className="scroll-area"> */}
          </div>
          {/* </div> */}
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="project-spec" place="top" className="tooltip" />
      <Tooltip id="project-spec" place="top" className="tooltip" />
    </div>
  );
};
export default SpecList;
