/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import TableSortHeader from '../../../common/TableSortHeader';
import TableLoader from '../../../common/TableLoader';

const YardTable = (props) => {
  const { tableData, deteleData, editData, handleSort, sortData } = props;
  /* istanbul ignore next */
  const column = [
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Yard."
            headingProperty="yardName"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      sortable: false,
      accessor: '',
      width: 250,
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="text-ellipsis">
            <span
              data-tooltip-id="project-yardNametable"
              data-tooltip-content={original.yardName}
              className="font-semibold mb-2 head-black text-ellipsis"
            >
              {original.yardName}
            </span>
            <div className="d-flex flex-wrap align-items-center">
              <div className="d-flex flag me-1">
                <img src={`data:image/jpeg;base64,${original.flagImage}`} className="me-2" />
                {/* FlagImage */}
                <div className="">{original?.countryName}</div>
              </div>
              <div className="d-flex align-items-center">
                <span className="icon-globe brand-color me-2" />
                <div className="d-flex flex-wrap align-items-center">
                  <div>{`${original.locationInfo?.latitude}`}</div>
                  <div className="me-1">,</div>
                  <div>{original.locationInfo?.longitude}</div>
                  {/* {`${}, $`} */}
                </div>
              </div>
              <div className="docTypeName font-bold">{original?.dockTypeName}</div>
            </div>
          </div>
        );
      }
    },
    {
      Header: 'dockType',
      sortable: false,
      accessor: 'yardTypeName',
      width: 120,
      Cell: (row) => {
        const { original } = row;
        const { dockTypeInfo } = original;
        return (
          <div>
            {
            dockTypeInfo?.map((item5) => {
              return (
                <div
                  className="text-ellipsis d-flex"
                  data-tooltip-id="project-yardNametable"
                  data-tooltip-content={item5?.dockTypeName}
                >
                  <span>{item5?.dockTypeName}</span>
                </div>
              );
            })
            }
          </div>
        );
      }
    },
    {
      Header: <div className="d-flex">
        Length & Breadth
        <div className="text-lowercase ps-1">(m)</div>
      </div>,
      sortable: false,
      accessor: '',
      width: 180,
      Cell: (row) => {
        const { original } = row;
        const { dockTypeInfo } = original;
        return (
          <div>
            {
            dockTypeInfo?.map((items) => {
              return (
                <div className="text-ellipsis d-flex">
                  <span>
                    {items?.length}

                  </span>
                  <span className="px-1">x</span>
                  <span>
                    {items?.breadth}

                  </span>
                </div>
              );
            })
            }
          </div>
        );
      }
    },
    {
      Header: 'Contact',
      sortable: false,
      accessor: 'contact',
      width: 280,
      Cell: (row) => {
        const { original } = row;
        const { contactInfo } = original;
        return (
          <div className="text-ellipsis">
            <span
              data-tooltip-id="project-yardNametable"
              data-tooltip-content={contactInfo.contactPersonName}
              className="mb-2 text-ellipsis"
            >
              {contactInfo.contactPersonName}
            </span>
            <div className="d-flex align-items-center mb-2">
              <span className="icon-phone font-16 me-3" />
              <span className="text-ellipsis">{contactInfo.phone}</span>
            </div>
            <div className="d-flex align-items-center">
              <span className="icon-email font-20 me-3" />
              <span
                className="text-ellipsis"
                data-tooltip-id="project-yardNametable"
                data-tooltip-content={contactInfo?.email?.join(', ')}
              >
                {contactInfo?.email?.join(', ')}
              </span>
            </div>
          </div>
        );
      }
    },
    {
      Header: 'Actions',
      sortable: false,
      accessor: 'packageName',
      width: 100,
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="action-column">
            <div>
              <button
                type="button"
                className="link-btn"
                // disabled={original.isMapped}
                onClick={() => {
                  editData(original);
                }}
              >
                <span className="icon-edit font-22" />
              </button>
            </div>
            {/* <Tooltip id="project-yardName1" place="top" className="tooltip mt-4" /> */}

            <div
              data-tooltip-id="project-yardNametable"
              data-tooltip-content={original?.mappedMessage}
            >
              {' '}
              <button
                type="button"
                className="link-btn"
                disabled={original.isMapped}
                onClick={() => {
                  deteleData(original);
                }}
              >
                <span className="icon-delete font-22" />
              </button>
            </div>
            <Tooltip id="project-yardNametable" place="top" className="tooltip mt-4" />

          </div>
        );
      }
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="table-yard">
      <ReactTable
        className="table yardTable table-scroll"
        isExpanded={false}
        data={tableData?.items}
        columns={column}
        minRows={0}
        showPagination={false}
        pageSize={tableData?.items?.length}
        LoadingComponent={TableLoader}
      />
      <Tooltip id="project-value1" place="top" className="tooltip" />

    </div>
  );
};
export default YardTable;
