import React, { useEffect, useState } from 'react';
import NonSpecChildContent from './nonSpecChildContent';

const NonSpecContent = (props) => {
  const { selectedSpec, setSelectedSpec, closedSection, setClosedSection,
    gtNonSpecData, currency, reloadTable } = props;
  const [activeSpecData, setActiveSpecData] = useState('');
  useEffect(() => {
    reloadTable();
  }, []);
  const onAccordianClose = (secId) => {
    const currentList = [...closedSection];
    if (currentList.indexOf(secId) > -1) {
      currentList.splice(currentList.indexOf(secId), 1);
    } else {
      currentList[0] = secId;
    }
    setClosedSection(currentList);
  };

  return (
    <>
      <div className="spec-list side px-4" data-testid="non-spec-content">
        <div className="scroll-area">
          <NonSpecChildContent
            gtSpecData={gtNonSpecData}
            selectedSpec={selectedSpec}
            closedSection={closedSection}
            onAccordianClose={onAccordianClose}
            activeSpecData={activeSpecData}
            currency={currency}
            setActiveSpecData={setActiveSpecData}
            setSelectedSpec={setSelectedSpec}
          />
        </div>
      </div>
    </>
  );
};
export default NonSpecContent;
