/* eslint-disable prefer-template */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import helper from '../../../../../../utils/helper';

const NonSpecTable = (props) => {
  const { specList, mainSec, onSecOpen, onAddSpecLine, viewAttachment,
    isTSI, editData, deleteData, projInfoDetail } = props;
  const { formatNumber } = helper;

  return (
    <div className="spc-map ps-3" data-testId="not-spec-table">
      <div className="d-flex border-down py-3 ps-3">
        <div className="col-8 font-bold">NON SPEC SECTION</div>
        <div className="col-2 font-bold  text-right">OWNER&apos;S COST</div>
      </div>
      {specList?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6">No data found</div>}
      {specList?.map((sec) => {
        return (
          <div className={sec.id === mainSec ? 'highlight-tender' : ''}>
            <div className="d-flex border-down py-2 ps-3">
              <div className=" d-flex align-items-center">
                <span
                  id={sec.id}
                  data-test="click1"
                  className={
                    sec.id === mainSec
                      ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                      : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                  }
                  onClick={() => {
                    onSecOpen(sec.id, true);
                  }}
                />
              </div>
              <div className="col-non">
                <span data-tooltip-id="project-nonspec" data-tooltip-content={sec?.title}>
                  {sec.specificationNo}
                  {' - '}
                  {_.truncate(sec.title, { length: 40 })}
                </span>
              </div>
              <div className="col-2  text-ellipsis text-right">
                <span data-tooltip-id="project-nonspec" data-tooltip-content={sec?.totalOwnerCost}>
                  {' '}
                  {sec.projectCurrencySymbol}
                  {' '}
                  {sec?.totalOwnerCost ? formatNumber(sec.totalOwnerCost) : 0}

                </span>

              </div>
            </div>
            {sec.id === mainSec
              && sec.nonSpecLineList.map((serv, num) => {
                return (
                  <>
                    <>
                      {num === 0 && (
                        <div className="d-flex ps-3 align-items-center">
                          <div className="col-2 bg-sky-white ps-3 border-down py-2">Non Spec Line</div>
                          <div className="col-1 bg-sky-white ps-3 border-down py-2">PO/PR</div>
                          <div className="col-2 bg-sky-white ps-3 border-down py-2">Item</div>
                          <div className="col-2 bg-sky-white ps-3 border-down py-2">AC Code</div>
                          <div className="col-1 bg-sky-white ps-3 border-down py-2 text-right">Amount</div>
                          <div className="col-2 bg-sky-white ps-3 border-down py-2 text-right">Owner`s Cost</div>
                          <div className="col-1 bg-sky-white ps-3 border-down py-2">Attachment</div>
                          <div className="col-1 bg-sky-white ps-3 border-down py-2">Actions</div>
                        </div>
                      )}
                      <div className="d-flex pb-2 ps-3 py-1">
                        <div className="col-2 text-ellipsis ps-3 border-down">
                          {serv.specificationNo}
                          <span
                            className="ms-4"
                            data-tooltip-id="project-nonspec"
                            data-tooltip-content={serv.title}
                          >
                            {serv.title}
                          </span>
                        </div>
                        <div
                          className="col-1 text-ellipsis ps-3 border-down"
                        >
                          <span
                            data-tooltip-id="project-nonspec"
                            data-tooltip-content={serv.prPoNo}
                          >
                            {serv.prPoNo || '-'}
                          </span>
                        </div>
                        <div
                          className="col-2 text-ellipsis ps-3 border-down"
                        >
                          <span
                            data-tooltip-id="project-nonspec"
                            data-tooltip-content={serv.item}
                          >
                            {serv.item || '-'}
                          </span>

                        </div>
                        <div
                          className="col-2 ps-3 border-down text-ellipsis"

                        >
                          <span
                            data-tooltip-id="project-nonspec"
                            data-tooltip-content={serv.accode}
                          >
                            {serv.accode || '-'}
                          </span>

                        </div>
                        <div
                          className="col-1 ps-3 border-down text-ellipsis text-right"
                        >
                          <span
                            data-tooltip-id="project-nonspec"
                            data-tooltip-content={serv.amount ? serv.currencySymbol + ' ' + formatNumber(serv.amount) : ''}
                          >
                            {serv.currencySymbol}
                            {' '}
                            {serv.amount ? formatNumber(serv?.amount) : '-'}
                          </span>
                        </div>
                        <div
                          className="col-2 ps-3 border-down text-ellipsis text-right"
                        >
                          <span
                            data-tooltip-id="project-nonspec"
                            data-tooltip-content={serv.ownerCost ? serv.projectCurrencySymbol + ' ' + formatNumber(serv.ownerCost) : ''}
                          >
                            {serv?.ownerCost ? serv.projectCurrencySymbol : ''}
                            {' '}
                            {serv?.ownerCost ? formatNumber(serv?.ownerCost) : '-'}

                          </span>

                        </div>
                        <div className="col-1 ps-3 border-down rq-attachment" id="viewAttachment" onClick={() => viewAttachment(serv)}>
                          {serv?.specDocuments?.length > 0 && <span className="dot-indicator-non-spec" />}
                          <button
                            id="viewAttachment2"
                            className="brand-color ml-30"
                            type="button"
                            onClick={() => {
                              viewAttachment(serv);
                            }}
                          >
                            <span id="attachment-click" className="icon-attachments font-24 brand-color" />
                          </button>
                        </div>
                        <div className="d-flex col-1 ps-3 border-down">
                          <button disabled={!isTSI || projInfoDetail?.phase === 5} type="button" id="edit" className="link-btn me-3" onClick={() => editData(serv, sec)}>
                            <span className="icon-edit font-20" />
                          </button>
                          <button
                            disabled={!isTSI || projInfoDetail?.phase === 5}
                            type="button"
                            id="delete"
                            className="link-btn"
                            onClick={() => {
                              deleteData(serv);
                            }}
                          >
                            <span className="icon-delete font-20" />
                          </button>
                        </div>
                      </div>
                    </>
                  </>
                );
              })}
            {sec.id === mainSec && (
              <div className="ps-3 d-flex my-2">
                <div className="ml-8">
                  <button id="nonspec" type="button" disabled={!isTSI || projInfoDetail?.phase === 5} className="link-btn" onClick={() => onAddSpecLine(sec)}>
                    {' '}
                    <span className="icon-plus-square me-2" />
                    Add New Non Spec Line
                  </button>
                </div>
              </div>
            )}
          </div>
        );
      })}
      <Tooltip id="project-nonspec" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default NonSpecTable;
