import React from 'react';
import Card from './card';

const CardWrapper = (props) => {
  const { workOrder, onDelete, fromSupplementary, isTsiDisabled, isTSI, fromVariation } = props;
  if (workOrder.length > 0) {
    return workOrder.map((item2) => {
      return (
        <div data-testid="card-wrapper">
          <Card
            onDelete={onDelete}
            item2={item2}
            isTsiDisabled={isTsiDisabled}
            isTSI={isTSI}
            fromVariation={fromVariation}
            fromSupplementary={fromSupplementary}
          />
        </div>
      );
    });
  }
  return (
    <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="card-wrapper">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">
        No data found
      </div>
    </div>
  );
};
export default CardWrapper;
