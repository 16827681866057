import { cloneDeep } from 'lodash';
import React from 'react';
import Select from 'react-select';
import { type, category, status } from './constants';
import InputType1 from '../../../../../../common/InputType1';
import helper from '../../../../../../../utils/helper';
import { currencyConverter } from '../../../Repair/NonSpecItem/services';

const AddTag = (props) => {
  const { purchaseOrder, setPurchaseOrder, currencyId, currency, projectCurrency } = props;
  const { charValidation, handleValidation, restrictDecimal } = helper;
  const getCurrency = (cId, pId, amount) => {
    currencyConverter(cId, pId, amount).then((resp) => {
      if (resp.success) {
        const fd = cloneDeep(purchaseOrder);
        fd.ownerCost = resp.data;
        fd.currencyId = cId;
        fd.amount = amount;
        setPurchaseOrder(fd);
      }
    });
  };
  const handleChange = (e, field) => {
    const po = cloneDeep(purchaseOrder);
    if (field === 'amount') {
      if (e && e.target && e.target.value) {
        po[field] = charValidation(restrictDecimal(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, '')), 10);
      }
      if (po.currencyId) { getCurrency(po.currencyId, currencyId, po[field]); }
    } else if (field === 'acCode') {
      if (e && e.target && e.target.value) {
        po[field] = e.target.value;
      }
      po.acCodeId = '';
    } else if (e && e.target && e.target.value) {
      po[field] = e.target.value;
    }
    setPurchaseOrder(po);
    handleValidation(['prPoNo', 'description', 'status', 'acCodeId', 'amount', 'currencyId'], po, field);
  };

  const handleSelectChange = (e, field, name) => {
    const po = cloneDeep(purchaseOrder);
    po[field] = e.value;
    if (field === 'currencyId') {
      getCurrency(e.value, currencyId, purchaseOrder.amount);
    }
    if (name) {
      po[name] = e.label;
    }
    setPurchaseOrder(po);
  };
  const getTitle = () => {
    const value = projectCurrency?.filter((val) => val.value === purchaseOrder?.currencyId);
    if (value && value.length) return value[0].label;
    return '';
  };
  return (
    <div>
      <div className="col-12 d-flex">
        <div className="col-6 pe-3 label-space" data-testid="add-tag">
          <InputType1 autoFocus className="modal-input" label="PO #" id="prPoNo" name="prPoNo" value={purchaseOrder.prPoNo} onChange={(e) => handleChange(e, 'prPoNo')} isMandatory="true" />
        </div>
        <div className="col-6 label-space" data-testid="add-file">
          <InputType1 className="modal-input" label="Item" id="description" name="description" value={purchaseOrder.description} onChange={(e) => handleChange(e, 'description')} isMandatory="true" />
        </div>
      </div>
      <div className="col-12 d-flex mt-4">
        <div className="col-4 pe-3" data-testid="add-file">
          <label className="d-flex mb-2">Type</label>
          <Select
            className="modal-select"
            placeholder="Select"
            id="add-type"
            options={type}
            onChange={((e) => {
              handleSelectChange(e, 'purchaseType');
            })}
          />
        </div>
        <div className="col-4 pe-3" data-testid="add-file">
          <label className="d-flex mb-2">Category</label>
          <Select
            className="modal-select"
            placeholder="Select"
            id="add-cat"
            options={category}
            onChange={((e) => {
              handleSelectChange(e, 'category');
            })}
          />
        </div>
        <div className="col-4" data-testid="add-file">
          <label required className="d-flex mb-2">
            Status
            <span className="text-primaryRed-1">*</span>
          </label>
          <Select
            className="modal-select"
            placeholder="Select"
            id="status"
            options={status}
            onChange={((e) => {
              handleSelectChange(e, 'status');
            })}
          />
        </div>
      </div>
      <div className="col-12 d-flex mt-4">
        <div className="col-4 label-space pe-3">
          <InputType1
            id="amount"
            name="amount"
            value={purchaseOrder.amount}
            onChange={(e) => handleChange(e, 'amount')}
            className="modal-input hide-arrows"
            label="Amount"
            type="text"
            isMandatory="true"
          />
        </div>
        <div className="col-4 pe-3" data-testid="add-file" title={getTitle()}>
          <label required className="d-flex mb-2">
            Currency
            <span className="text-primaryRed-1">*</span>
          </label>
          <Select
            className="modal-select po-currency"
            label="Currency"
            id="currencyId"
            placeholder="Select"
            isDisabled={purchaseOrder.amount === ''}
            options={projectCurrency}
            value={projectCurrency?.filter((e) => e.value === purchaseOrder?.currencyId)}
            onChange={(e) => handleSelectChange(e, 'currencyId', '')}
          />
        </div>
        <div className="col-4 label-space">
          <InputType1
            id="ownerCost"
            name="ownerCost"
            disabled
            value={purchaseOrder.ownerCost}
            onChange={(e) => handleChange(e, 'ownerCost')}
            className="modal-input hide-arrows"
            label={`Owner's Cost (${currency})`}
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

export default AddTag;
