/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* istanbul ignore file */
import moment from 'moment';

export const statusColor = (status) => {
  if (status === 1) {
    return '#555969';
  } if (status === 2) {
    return '#fa7900';
  } if (status === 3) {
    return '#D1414B';
  }
  return '';
};
export const barColor = (phase) => {
  if (phase === 1) {
    return '#56c1b2';
  } if (phase === 2) {
    return '#3cabf0';
  } if (phase === 3) {
    return '#111961';
  } if (phase === 4) {
    return '#6499c3';
  } if (phase === 5) {
    return '#babdba';
  }
  return '#D6CECE';
};
export const barColor1 = (phase, hasValue) => {
  if (hasValue) {
    if (phase === 1) {
      return '#56c1b2';
    } if (phase === 2) {
      return '#3cabf0';
    } if (phase === 3) {
      return '#111961';
    } if (phase === 4) {
      return '#6499c3';
    } if (phase === 5) {
      return '#babdba';
    }
    return '#D1414B';
  }
  return '#ffffff';
};

export const itemList = (data) => {
  const itemData = [];
  data?.map((item) => {
    console.log('start', moment(item?.startDate)?._d);
    console.log('end', moment(item?.endDate)?._d);
    if (item?.startDate && item?.endDate) {
      itemData.push({
        id: item.id,
        // eslint-disable-next-line no-underscore-dangle
        start: item?.ddDueDate ? moment(item?.ddDueDate)?._d : '',
        // eslint-disable-next-line no-underscore-dangle
        end: item?.trdEndDate ? moment(item?.trdEndDate)?._d : '',
        name: item?.nameTooltip,
        progress: '100',
        approvalStatus: item?.approvalText,
        trd: item?.totalRepairDays,
        styles: { progressColor: barColor(item?.phaseId),
          progressSelectedColor: barColor(item?.phaseId) }
      });
    }
    return null;
  });
  return itemData;
};
