/* eslint-disable eqeqeq */
import React from 'react';
import { cloneDeep } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const AddTask = ({ formData, setFormData, ResponsibleList, deteleSubData }) => {
  const { Priority, Phase, ZERO_UUID, charValidation, handleValidation } = helper;
  const handleChange = (e, field, type, index) => {
    const fd = cloneDeep(formData);
    if (type === 'taskCategoryList') {
      const fdd = fd.taskCategoryList;
      fdd[index][field] = field === 'taskCategoryName' ? charValidation(e.target.value, 150) : e.target.value?.replace(/[^0-9]/g, '').substring(0, 2);
      fd.taskCategoryList = fdd;
    } else if (type === 'select') {
      fd[field] = e.value;
    } else fd[field] = field === 'taskName' ? charValidation(e.target.value, 150) : e.target.value?.replace(/[^0-9]/g, '').substring(0, 2);
    handleValidation(['taskName', 'priorityId', 'taskCategoryName'], fd, field);
    setFormData(fd);
  };
  const addMoreSubTask = () => {
    const fd = cloneDeep(formData);
    const fdd = fd.taskCategoryList;
    fdd.push({
      taskCategoryName: '',
      priorityId: '',
      phaseId: 0,
      responsibleId: ZERO_UUID,
      offset: '',
      status: 0,
      previous_id: ZERO_UUID
    });
    fd.taskCategoryList = fdd;
    setFormData(fd);
  };
  return (
    <>
      <div className="modal-content scrollable-modal p-2" data-testid="add-task">
        <div className="row">
          <div className="col-8">
            <InputType1
              autoFocus
              id="taskName"
              value={formData.taskName}
              onChange={(e) => handleChange(e, 'taskName', '')}
              className="modal-input"
              label="Task"
              isMandatory="true"
              name="taskName"
            />
          </div>
          <div className="col-4">
            <label>
              Priority
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              menuPosition="fixed"
              value={Priority.filter((option) => option.value === formData.priorityId)}
              onChange={(option) => handleChange(option, 'priorityId', 'select')}
              className="modal-select mt-2"
              label="Priority"
              options={Priority}
              id="priorityId"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <label>Phase</label>
            <Select
              menuPosition="fixed"
              value={Phase.filter((option) => option.value === formData.phaseId)}
              onChange={(option) => handleChange(option, 'phaseId', 'select')}
              className="modal-select mt-2"
              label="Phase"
              options={Phase}
              id="phaseId"
            />
          </div>
          <div className="col-4">
            <label>Responsible</label>
            <Select
              menuPosition="fixed"
              value={ResponsibleList.filter((option) => option.value === formData.responsibleId)}
              onChange={(option) => handleChange(option, 'responsibleId', 'select')}
              className="modal-select mt-2"
              label="Responsible"
              options={ResponsibleList.filter((opt) => opt.type !== 3)}
              id="responsibleId"
              name="responsibleId"
            />
          </div>
          <div className="col-4">
            <InputType1
              type="text"
              id="offset"
              value={formData.offset}
              onChange={(e) => handleChange(e, 'offset', '')}
              className="modal-input"
              label="Milestone(days)"
              name="offset"
              isMandatory="true"
            />
          </div>
        </div>
        {formData.taskCategoryList.map((i, index) => {
          return (
            <div className="mt-5">
              <div className="d-flex justify-content-between mb-3">
                <h5 className="head-16 ">
                  Sub Task -
                  <span>{index + 1}</span>
                </h5>
                <button
                  onClick={() => {
                    const dd = { ...i, indexToRemove: index };
                    deteleSubData(dd);
                  }}
                  className="secondary-btn small"
                  type="button"
                  id="delete"
                >
                  <span className="icon-delete me-1" />
                  Delete
                </button>
              </div>
              <div className="p-3 subtask">
                <div className="row">
                  <div className="col-8">
                    <InputType1
                      id="taskCategoryName"
                      value={i.taskCategoryName}
                      onChange={(e) => handleChange(e, 'taskCategoryName', 'taskCategoryList', index)}
                      className="modal-input"
                      label="Sub Task"
                      isMandatory="true"
                    />
                  </div>
                  <div className="col-4">
                    <InputType1
                      type="text"
                      value={i.offset}
                      onChange={(e) => handleChange(e, 'offset', 'taskCategoryList', index)}
                      className="modal-input"
                      label="Milestone(days)"
                      id="milestone"
                      isMandatory="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <button onClick={() => addMoreSubTask()} id="add-sub" type="button" className="link-btn mt-5">
          Add Sub Task
        </button>
      </div>
    </>
  );
};
export default AddTask;
