import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import vesselThmd from '../../../../../../../../images/vessel-thmd.jpg';

const Library = (props) => {
  const { onSearch, cardDetails, handleEyeView, searchText, setSearchText } = props;
  return (
    <div className="" data-testid="library">
      <div className="px-2  mt-4">
        <div className="d-flex col-gap-1 spec-search justify-content-between w-full">
          <div className="search col-6 large">
            <div className="d-flex justify-content-end">
              <span
                id="icon-search"
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
            </div>
            <input
              type="text"
              id="search"
              className="text-box large with-close"
              placeholder="Search by Project Name"
              value={searchText}
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>
        {cardDetails?.projects?.length > 0 && cardDetails?.projects?.map((items) => {
          return (
            <div className=" card border-dot mt-4 p-3 d-flex align-items-center shadow-medium md-radius">
              <div className="px-3">
                <img className="thmb-sm ship-thumb" src={items?.vesselImage || vesselThmd} alt="" />
              </div>
              <div className="">
                <h3 className="brand-color font-semibold font-14">
                  <span
                    data-tooltip-id="project-lib"
                    data-tooltip-content={items?.projectFullName}
                  >
                    {_.truncate(items?.projectName, { length: 40 })}
                  </span>
                </h3>
                <div className="d-flex justify-content-center font-12">
                  <span
                    data-tooltip-id="project-lib"
                    data-tooltip-content={items?.vesselName}
                  >
                    {_.truncate(items?.vesselName, { length: 15 })}
                  </span>
                  <div className="ps-5">
                    <span>Docking Date : </span>
                    <span
                      data-tooltip-id="project-lib"
                      data-tooltip-content={items?.dDdate ? moment(items?.dDdate).format('DD-MMM-YYYY') : '-'}
                    >
                      {_.truncate(items?.dDdate ? moment(items?.dDdate).format('DD-MMM-YYYY') : '-', { length: 20 })}
                    </span>
                  </div>
                </div>
              </div>
              <div className="ml-auto">
                <div className="d-flex">
                  <button
                    type="button"
                    className="link-btn"
                    id="eyebtn"
                    onClick={() => handleEyeView(items)}
                  >
                    <span className="icon-eye font-20" />
                  </button>
                  <button
                    type="button"
                    className="view-txt-respo link-btn font-14 ps-2"
                    id="eye"
                    onClick={() => handleEyeView(items)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Tooltip id="project-lib" place="top" className="tooltip m-4" />
    </div>
  );
};
export default Library;
