import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  SaveSurveyTypeUrl,
  GetSurveyTypeListUrl,
  GetSurveyTypeDetailUrl,
  DeleteSurveyTypeUrl,
  SaveContactCategoryUrl,
  GetContactCategoryListUrl,
  GetContactCategoryDetailUrl,
  DeleteContactCategoryUrl,
  SaveProjectTypeUrl,
  GetProjectTypeListUrl,
  GetProjectTypeDetailUrl,
  DeleteProjectTypeUrl,
  PublishGeneralConfigUrl,
  SaveJobAssignedUrl,
  GetJobAssignedListUrl,
  GetJobAssignedDetailUrl,
  DeleteJobAssignedUrl,
  SaveContactRoleUrl,
  GetContactRoleListUrl,
  GetContactRoleDetailUrl,
  DeleteContactRoleUrl,
  SaveVesselCategoryUrl,
  GetVesselCategoryListUrl,
  DeleteVesselCategoryUrl,
  saveDocumentCategory,
  deleteDocumentCategory,
  getFuelType,
  getFuelAdd,
  DeletegetFuel

} = apiEndPoints;

export const SaveContactRole = (payload) => {
  return serviceHandler({
    url: `${SaveContactRoleUrl}`,
    methordType: 'post',
    payload
  });
};

export const GetContactRoleList = (payload) => {
  return serviceHandler({
    url: `${GetContactRoleListUrl}`,
    methordType: 'get',
    payload
  });
};
export const getContactRoleList = (payload) => {
  return serviceHandler({
    url: `${GetContactRoleListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetContactRoleDetail = (payload) => {
  return serviceHandler({
    url: `${GetContactRoleDetailUrl}`,
    methordType: 'get',
    payload
  });
};

export const DeleteContactRole = (payload) => {
  return serviceHandler({
    url: `${DeleteContactRoleUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const SaveJobAssigned = (payload) => {
  return serviceHandler({
    url: `${SaveJobAssignedUrl}`,
    methordType: 'post',
    payload
  });
};

export const SaveDocumentCategory = (payload) => {
  return serviceHandler({
    url: `${saveDocumentCategory}`,
    methordType: 'post',
    payload
  });
};

export const GetJobAssignedList = (payload) => {
  return serviceHandler({
    url: `${GetJobAssignedListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetJobAssignedDetail = (payload) => {
  return serviceHandler({
    url: `${GetJobAssignedDetailUrl}`,
    methordType: 'get',
    payload
  });
};

export const DeleteJobAssigned = (payload) => {
  return serviceHandler({
    url: `${DeleteJobAssignedUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const DeleteDocumentCategory = (payload) => {
  return serviceHandler({
    url: `${deleteDocumentCategory}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const SaveSurveyType = (payload) => {
  return serviceHandler({
    url: `${SaveSurveyTypeUrl}`,
    methordType: 'post',
    payload
  });
};

export const GetSurveyTypeList = (payload) => {
  return serviceHandler({
    url: `${GetSurveyTypeListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetSurveyTypeDetail = (payload) => {
  return serviceHandler({
    url: `${GetSurveyTypeDetailUrl}`,
    methordType: 'get',
    payload
  });
};

export const DeleteSurveyType = (payload) => {
  return serviceHandler({
    url: `${DeleteSurveyTypeUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const SaveContactCategory = (payload) => {
  return serviceHandler({
    url: `${SaveContactCategoryUrl}`,
    methordType: 'post',
    payload
  });
};

export const GetContactCategoryList = (payload) => {
  return serviceHandler({
    url: `${GetContactCategoryListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetContactCategoryDetail = (payload) => {
  return serviceHandler({
    url: `${GetContactCategoryDetailUrl}`,
    methordType: 'get',
    payload
  });
};

export const DeleteContactCategory = (payload) => {
  return serviceHandler({
    url: `${DeleteContactCategoryUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const SaveProjectType = (payload) => {
  return serviceHandler({
    url: `${SaveProjectTypeUrl}`,
    methordType: 'post',
    payload
  });
};

export const GetProjectTypeList = (payload) => {
  return serviceHandler({
    url: `${GetProjectTypeListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetProjectTypeDetail = (payload) => {
  return serviceHandler({
    url: `${GetProjectTypeDetailUrl}`,
    methordType: 'get',
    payload
  });
};

export const DeleteProjectType = (payload) => {
  return serviceHandler({
    url: `${DeleteProjectTypeUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const PublishGeneralConfig = (payload) => {
  return serviceHandler({
    url: `${PublishGeneralConfigUrl}`,
    methordType: 'put',
    payload
  });
};

export const SaveVesselCategory = (payload) => {
  return serviceHandler({
    url: `${SaveVesselCategoryUrl}`,
    methordType: 'post',
    payload
  });
};

export const GetVesselCategoryList = (payload) => {
  return serviceHandler({
    url: `${GetVesselCategoryListUrl}`,
    methordType: 'get',
    payload
  });
};

export const DeleteVesselCategory = (payload) => {
  return serviceHandler({
    url: `${DeleteVesselCategoryUrl}?Id=${payload}`,
    methordType: 'put',
    payload
  });
};
export const GetFuelData = (payload) => {
  return serviceHandler({
    url: `${getFuelType}`,
    methordType: 'get',
    payload
  });
};
export const getAddFuel = (payload) => {
  return serviceHandler({
    url: `${getFuelAdd}`,
    methordType: 'post',
    payload
  });
};
export const DeleteFuelGet = (payload) => {
  return serviceHandler({
    url: `${DeletegetFuel}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};
