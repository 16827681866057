/* eslint-disable no-unused-vars */
/* istanbul ignore file */
/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { isNextDay } from 'react-dates';
import { useNavigate } from 'react-router-dom';

const SubHeader = (props) => {
  const { setRfqId, rfqId, goToNext, setIndex, subHeaderList,
    fileUploaded, setIsViewMoreClose } = props;
  const navigate = useNavigate();
  const location = window.location.pathname;
  useEffect(() => {
    if (subHeaderList?.length > 0) {
      // if (fileUploaded) {
      //   console.log('index', subHeaderList[subHeaderList?.length - 1]);
      //   setRfqId(subHeaderList[subHeaderList?.length - 1]?.id);
      // } else {
      setRfqId(subHeaderList[0]?.id);
      // }
    }
  }, [subHeaderList]);
  return (
    <>
      <div data-testid="admin-resource" className=" d-flex">
        <button className="link-btn" type="button" onClick={() => goToNext('prev')}>
          <span className="icon-arrow-thin-left font-18 me-2" />
        </button>
        <div className="d-flex sub-header-quote y-names">
          <>
            {subHeaderList ? (
              <div className="d-flex item-set col-gap-1">
                {subHeaderList.map((i, index) => {
                  return (
                    <button
                      onClick={() => {
                        setRfqId(i?.id);
                        setIndex(index);
                        setIsViewMoreClose(false);
                      }}
                      className={i?.id === rfqId ? 'rnd-btn active w-200' : 'text-ellipsis rnd-btn w-200'}
                      type="button"
                    >
                      <span
                        className="text-ellipsis w-150"
                        data-tooltip-id="project-addRem"
                        data-tooltip-content={i?.yardName}
                      >
                        {i?.yardName}
                      </span>
                    </button>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </>
        </div>
        <button className="link-btn" type="button" onClick={() => goToNext('next')}>
          <span className="icon-arrow-thin-right font-18 me-2" />
        </button>
        <Tooltip id="project-addRem" place="top" className="tooltip mt-3" />
      </div>
    </>
  );
};
export default SubHeader;
