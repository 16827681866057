import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../../../../../common/InputType1';
import Loader from '../../../../../../../common/loader';

const Cards = (props) => {
  const { allServiceLine, onEditService, sLoader,
    onDeleteService, setSelectedServiceLines,
    selectedServiceLines, onQuantityChange, onQuantitySelect, serviceData,
    onQuantityCancel, onQuantityAdd, searchText, isTsiDisabled,
    isTSI, fromSupplementary, activeTabSupplementary, isVesselUser,
    projInfo, isDraft, projectData } = props;

  const onSelectServiceLine = (id) => {
    const selectedLines = [...selectedServiceLines];
    if (selectedLines.indexOf(id) > -1) {
      selectedLines.splice(selectedLines.indexOf(id), 1);
    } else {
      selectedLines.push(id);
    }
    setSelectedServiceLines(selectedLines);
  };

  const onSelectAll = () => {
    let selectedLines = [];
    allServiceLine.map((item) => {
      if (item.specificationLevel === 3) {
        selectedLines.push(item.id);
      }
      return null;
    });
    if (selectedLines.length === selectedServiceLines.length) {
      selectedLines = [];
    }
    setSelectedServiceLines(selectedLines);
  };

  const checkAllSelected = () => {
    const lvl3Items = allServiceLine.filter((item) => item.specificationLevel === 3);
    if (lvl3Items.length === selectedServiceLines.length && lvl3Items.length > 0) {
      return true;
    }
    return false;
  };

  if (sLoader) return <Loader />;
  return (
    <>
      {allServiceLine.filter((fields) => fields.serviceLineName
        ?.toLowerCase()?.includes(searchText?.toLowerCase())).length > 0 && (
          <table className="w-full html-table h-full">
            <colgroup>
              {isTSI ? <col className={isDraft ? 'col-3' : 'col-2'} /> : ''}
              <col className="col-2" />
              <col className="col-2" />
              <col className="col-2" />
              <col className="col-2" />
              {isTSI ? <col className={isDraft ? 'col-1' : 'col-2'} /> : ''}
            </colgroup>
            <thead>
              <tr className="">
                {isTSI ? (
                  <th className="">
                    <div className={isVesselUser || isDraft ? 'check-box align-items-center disabled' : 'check-box align-items-center'}>
                      <input type="checkbox" id="all" className="me-2 pointer" checked={checkAllSelected()} onClick={onSelectAll} disabled={isVesselUser || isDraft} />
                      <label htmlFor="all">#</label>
                    </div>
                  </th>
                ) : ''}
                <th className="">Service Line</th>
                <th className="">AC Code</th>
                <th className="">Qty</th>
                <th className="">UOM</th>
                {isTSI ? <th className="">Actions</th> : ''}
              </tr>
            </thead>
            <tbody>
              {allServiceLine.filter((fields) => fields.serviceLineName
                ?.toLowerCase().includes(searchText?.toLowerCase()))
                .map((item) => {
                  if (item.specificationLevel === 3) {
                    return (
                      <tr>
                        {isTSI ? (
                          <td className="">
                            <div className={isVesselUser || isDraft ? 'check-box align-items-center disabled' : 'check-box align-items-center'} disabled={isVesselUser}>
                              <input disabled={isTsiDisabled || isVesselUser || isDraft} type="checkbox" data-test="number" id={item.number} className="me-2 pointer" checked={selectedServiceLines.indexOf(item.id) > -1} onClick={() => { onSelectServiceLine(item.id); }} />
                              <label className="text-ellipsis" htmlFor={item.number} data-tooltip-id="project-secservice" data-tooltip-content={item.number}>
                                {item.number}
                              </label>
                            </div>
                          </td>
                        ) : ''}
                        <td className="text-break-all"><span data-tooltip-id="project-secservice" data-tooltip-content={item.serviceLineName}>{item.serviceLineName}</span></td>
                        <td className=""><span data-tooltip-id="project-secservice" data-tooltip-content={item.acCode}>{_.truncate(item.acCode, { length: 12 })}</span></td>
                        <td className=""><span data-tooltip-id="project-secservice" data-tooltip-content={item.quantity}>{item.quantity}</span></td>
                        <td className=""><span data-tooltip-id="project-secservice" data-tooltip-content={item.uom}>{_.truncate(item.uom, { length: 12 })}</span></td>
                        {isTSI ? (
                          <td className="">
                            <div className="d-flex">
                              <button type="button" id="edit" disabled={projInfo?.isClosed || projectData?.isClosed || ((isVesselUser && !isDraft) || (!isVesselUser && isDraft))} className="link-btn me-3" onClick={() => onEditService(item)}>
                                <span className="icon-edit font-20" />
                              </button>
                              <button type="button" id="delete" disabled={((isVesselUser && !isDraft) || (!isVesselUser && isDraft)) || (fromSupplementary && !activeTabSupplementary) || projInfo?.isClosed || projectData?.isClosed || projectData?.phase > 2} className="link-btn" onClick={() => { onDeleteService(item); }}>
                                <span className="icon-delete font-20" />
                              </button>
                            </div>
                          </td>
                        ) : ''}
                      </tr>
                    );
                  }
                  return (
                    <tr>
                      {isTSI ? (
                        <td className="">
                          <div className={isVesselUser ? 'check-box align-items-center disabled' : 'check-box align-items-center'} disabled={isVesselUser}>
                            <input type="checkbox" className="me-2" disabled />
                            <span data-tooltip-id="project-secservice" data-tooltip-content={item.number}>{_.truncate(item.number, { length: 8 })}</span>
                          </div>
                        </td>
                      ) : ''}
                      <td className="">
                        <span data-tooltip-id="project-secservice" data-tooltip-content={item.serviceLineName}>
                          {_.truncate(item.serviceLineName, { length: 15 })}
                        </span>
                      </td>
                      <td className="">{item.acCode}</td>
                      <td className="">
                        <div className="relative">
                          {(item.quanityEnterBy === 'TSI' || item.quanityEnterBy === 'TSI ')
                            ? <div className="second-brand-color font-semibold pointer" data-test="quantity" id={item.number} onClick={() => { onQuantitySelect(item); }}>{(item.quantity || item.quantity === 0) ? item.quantity : '+ Quantity'}</div>
                            : <div className="font-semibold" id={item.number}>{item.quantity}</div>}
                          {item.number === serviceData.number
                              && (
                                <div className="quatity_popup">
                                  <div><InputType1 disabled={isTsiDisabled || isVesselUser} autoFocus min="0" value={serviceData.quantity} onChange={(e) => { onQuantityChange(e); }} placeholder="quantity" /></div>
                                  <div className="mt-3 d-flex justify-content-end me-2">
                                    <button type="button" id="onQuantityCancel" className="secondary-btn me-2" onClick={onQuantityCancel}>Cancel</button>
                                    <button type="button" id="onQuantityAdd" disabled={isTsiDisabled} className="primary-btn" onClick={onQuantityAdd}>Save</button>
                                  </div>
                                </div>
                              )}
                        </div>
                      </td>
                      <td className=""><span data-tooltip-id="project-secservice" data-tooltip-content={item.uom}>{item.uom}</span></td>
                      {isTSI ? <td className=""> </td> : ''}
                    </tr>
                  );
                })}
            </tbody>
          </table>
      )}
      {allServiceLine.filter((fields) => fields.serviceLineName
        ?.toLowerCase()?.includes(searchText?.toLowerCase()))
        .length < 1 && (
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">
              No data found
            </div>
          </div>
      )}
      <Tooltip id="project-secservice" place="top" className="tooltip mt-4" />
    </>
  );
};
export default Cards;
