/* eslint-disable react/no-unescaped-entities */
import moment from 'moment';
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import Loader from '../../../common/loader';
import helper from '../../../../utils/helper';

function Cards(props) {
  const { tableData, loading } = props;
  const { formatNumber } = helper;

  if (loading) {
    return <Loader />;
  }

  console.log(tableData, 'tableData...');

  return (
    <div className="">
      <div data-testid="card-yard-cost" className="mb-4 table-responsive">
        {/* {tableData?.items?.length > 0 && ( */}
        <table className="card-table">
          <thead>
            <tr>
              <th>
                Project and Vessel name
              </th>
              <th>
                Yard
              </th>
              <th>
                Docking Date
              </th>
              <th>
                Yard Cost With Discount
              </th>
              <th>
                Owner's Cost
              </th>
              <th>
                Total Cost
              </th>
            </tr>
          </thead>
          <tbody>

            {tableData?.map((items) => {
              return (
                <tr>
                  <td>
                    <h6 className="mb-2 head-14 timeline-text-wrap">
                      <span
                        className=""
                        data-tooltip-id="project-projectName"
                        data-tooltip-content={items?.projectName}
                      >
                        {_.truncate(items?.projectName, { length: 30 })}
                      </span>
                    </h6>
                    <span
                      className=" "
                      data-tooltip-id="project-projectName"
                      data-tooltip-content={items?.vesselName}
                    >
                      {_.truncate(items?.vesselName, { length: 30 })}
                    </span>
                  </td>
                  <td>
                    <span
                      className=" "
                      data-tooltip-id="project-projectName"
                      data-tooltip-content={items?.yardName}
                    >
                      {_.truncate(items?.yardName, { length: 30 })}
                    </span>
                  </td>
                  <td>
                    <span className={items?.ddDate ? 'mb-2 ' : 'ps-5 mb-2 '}>
                      {items?.ddDate ? moment.utc(items?.ddDate).local().format('DD-MMM-yyyy') : '-'}
                    </span>
                  </td>
                  <td>
                    <span className="me-2">{items?.yardCost ? items?.currencySymbol : ''}</span>
                    <span
                      className={items?.yardCost ? '' : 'ps-5'}
                      data-tooltip-id="project-projectName"
                      data-tooltip-content={items?.yardCost ? formatNumber(items?.yardCost) : '-'}
                    >
                      {_.truncate(items?.yardCost ? formatNumber(items?.yardCost) : '-', { length: 15 })}
                    </span>

                  </td>
                  <td>

                    <span className="me-2">{items?.ownerCost ? items?.currencySymbol : ''}</span>
                    <span
                      className={items?.ownerCost ? '' : 'ps-5'}
                      data-tooltip-id="project-projectName"
                      data-tooltip-content={items?.ownerCost ? formatNumber(items?.ownerCost) : '-'}
                    >
                      {_.truncate(items?.ownerCost ? formatNumber(items?.ownerCost) : '-', { length: 15 })}
                    </span>
                  </td>
                  <td>
                    <span className={items?.totalCost ? 'head-14 me-2' : ''}>{items?.totalCost ? items?.currencySymbol : ''}</span>
                    <span
                      className={items?.totalCost ? ' head-14' : 'ps-5'}
                      data-tooltip-id="project-projectName"
                      data-tooltip-content={items?.totalCost ? formatNumber(items?.totalCost) : '-'}
                    >
                      {_.truncate(items?.totalCost ? formatNumber(items?.totalCost) : '-', { length: 15 })}
                    </span>
                  </td>
                </tr>

              );
            })}
          </tbody>
        </table>

        <Tooltip id="project-projectName" place="top" className="tooltip mt-4" />
      </div>

    </div>
  );
}

export default Cards;
