import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import helper from '../../../../../utils/helper';
import { getProjectStatus } from '../../NewProject/services';
import ApprovalSpecify from './ApprovalProcess/ApprovalSpecify';
import Specify from './index';

const SpecifyWrapper = () => {
  const { id } = useParams();
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.ADMIN
   || userRole === roles.VESSEL_USER;
  const [state, setstate] = useState('');
  useEffect(() => {
    getProjectStatus(id, 2).then((res) => {
      if (res?.success) {
        console.log('SPECIFY 2-', res.data);
        if (res.data && res.data !== 0) {
          setstate('approve');
        }
      }
    });
  }, []);
  return (
    <div data-testid="specify-main-wraper">
      {isTSI
        ? <Specify isTSI={isTSI} />
        : (
          <>
            {userRole === roles.EDITOR && state === ''
              ? <Specify isTSI={userRole === roles.EDITOR && state === ''} />
              : <ApprovalSpecify isTSI={isTSI} />}
          </>
        )}
    </div>
  );
};
export default SpecifyWrapper;
