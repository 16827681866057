/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import ModalBox from '../../../common/ModalBox';
import CreateACCode from './CreateAcCode';
import { deleteAcCode, getAcCode, saveAcCode, generatTemplate, bulkUploadAcCode, exportExcelAcCode } from './services';
import { getManagementGroupShortList } from '../../Users/Roles/services';
import Pagination from '../../../common/pagination';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import AcCodeTable from './acCodeTable';
import BulkUploadModal from '../CurrencyContainer/bulkUploadModal';
import Loader from '../../../common/loader';

const AcCode = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { formValidation, MAX_FILE_SIZE, ZERO_UUID } = helper;
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const initialFormData = {
    id: '',
    code: '',
    name: '',
    managementGroupId: '',
    managementGroupList: []
  };
  const [formData, setFormData] = useState(initialFormData);
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [managementId, setManagementId] = useState('');
  const [managementList, setManagementList] = useState([]);
  const [mgmtListOfAcCode, setMgmtListOfAcCode] = useState([]);

  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const [sortData, setSortData] = useState({
    sortProperty: 'Code',
    isDescending: false
  });
  const reloadTable = (page) => {
    setLoading(true);
    getAcCode({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending,
      managementGroupId: managementId || ZERO_UUID
    }).then((res) => {
      res.data.items && res.data.items.map((item) => {
        if (item?.managementGroupList) {
          const names = item.managementGroupList.map((list) => list.name);
          item.managementGroupName = names.join(', ');
        }
      });
      setTableData(res.data);
      if (setCount) {
        setCount(res?.data?.totalItems || 0);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);

  useEffect(() => {
    getManagementGroupShortList({
      page: 1,
      pageSize: 500
    }).then((res) => {
      if (res.data) {
        const temp = res.data
          .filter(({ id }) => id !== ZERO_UUID)
          .map(({ id, name }) => ({ id, name }));
        setManagementList(temp);
      }
    });
  }, []);

  const editData = (data) => {
    setMgmtListOfAcCode(data?.managementGroupList || []);
    setFormData(data);
    setOpenModal(true);
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    deleteAcCode(formData.id).then((res) => {
      setFormData(initialFormData);
      reloadTable();
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
      }
    });
    setOpenDeleteModal(false);
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  const saveApiCall = () => {
    let isValid = false;
    if (mgmtListOfAcCode.length > 0) {
      isValid = true;
      formData.managementGroupList = mgmtListOfAcCode;
    } else {
      formValidation(['id'], { id: '' });
    }
    if (formValidation(['code', 'name'], formData) && isValid) {
      if (formData.id === '') {
        formData.id = ZERO_UUID;
      }
      saveAcCode(formData).then((res) => {
        if (res.success) {
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
          reloadTable();
          setFormData(initialFormData);
          setOpenModal(false);
          setMgmtListOfAcCode([]);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };

  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file || '');
    bulkUploadAcCode(body).then((res) => {
      if (res.success) {
        reloadTable();

        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };

  useEffect(() => {
    reloadTable();
  }, [sortData, managementId]);

  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };

  const handleExcelExport = () => {
    if (tableData?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelAcCode(searchText);
    }
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  return (
    <div data-testid="accode">
      {loading ? <Loader /> : (
        <div>
          {openModal ? (
            <ModalBox
              modalClassName="medium"
              onSave={() => saveApiCall()}
              closeModal={() => {
                setOpenModal(false);
                setMgmtListOfAcCode([]);
              }}
              heading={`${formData.id ? 'Update' : 'Add'} AC Cost Masters`}
              buttonName={formData.id ? 'Update' : 'Save'}
            >
              <CreateACCode
                formData={formData}
                setFormData={setFormData}
                managementList={managementList}
                mgmtListOfAcCode={mgmtListOfAcCode}
                setMgmtListOfAcCode={setMgmtListOfAcCode}
              />
            </ModalBox>
          ) : (
            ''
          )}
          {openDeleteModal ? (
            <ModalBox
              openDeleteModal={openDeleteModal}
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete AC code ?</p>
            </ModalBox>
          ) : (
            ''
          )}
          <div className="card p-5 height-fix">
            <div className="d-flex mb-3 justify-content-between">
              <div className="d-flex col-gap-1 spec-search">
                <div className="search large">
                  <div className="d-flex justify-content-end">
                    <span
                      className={searchText ? ' icon-close-line ' : ''}
                      data-test="click1"
                      onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}

                    />
                    <span className="icon-search" data-test="click2" onClick={() => { onSearch({ key: 'Enter' }); }} />
                  </div>
                  <input
                    value={searchText}
                    type="text"
                    id="search"
                    className="text-box large with-close"
                    placeholder="Search by Code, Name"
                    onKeyPress={onSearch}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="primary-btn large"
                  id="add-accode"
                  onClick={() => {
                    setOpenModal(true);
                    setFormData(initialFormData);
                  }}
                >
                  Add AC Code
                </button>
                <button
                  type="button"
                  id="add-name"
                  className="secondary-btn large"
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  Bulk Upload
                </button>
                {uploadModal && (
                <ModalBox
                  modalClassName="medium"
                  heading="Upload Excel"
                  onSave={() => { bulkUpload(); }}
                  closeModal={() => onCloseModal()}
                  buttonName="Upload"
                  saveDisable={isUpload}
                >
                  <BulkUploadModal
                    file={file}
                    ondrop={ondrop}
                    setFile={setFile}
                    generatTemplateForExcel={generatTemplateForExcel}
                    name="AC Code"
                    setIsUpload={setIsUpload}
                  />
                </ModalBox>
                )}
              </div>
              <div className="col-3">
                <Select
                  id="managementGroupId"
                  className="modal-select"
                  name="id"
                  label="name"
                  isClearable
                  placeholder="Filter by Management"
                  options={managementList.map(item => ({
                    value: item.id,
                    label: item.name
                  }))}
                  onChange={(option) => {
                    if (option) {
                      setManagementId(option.value);
                    } else {
                      setManagementId('');
                    }
                  }}
                  value={managementList
                    .map(item => ({ value: item.id, label: item.name }))
                    .filter((option) => option.value === managementId)}
                />
              </div>
              <div className="d-flex col-gap-1">
                <button type="button" id="excel" className="link-btn large " onClick={handleExcelExport}>
                  <span className="icon-download me-4" />
                  Excel Download
                </button>
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <AcCodeTable
                  loading={loading}
                  tableData={tableData}
                  deteleData={deteleData}
                  editData={editData}
                  onSearch={onSearch}
                  onSizeChange={onSizeChange}
                  handleSort={handleSort}
                  sortData={sortData}
                />
                {tableData?.totalItems > 10 && (
                <Pagination
                  pageClick={pageClick}
                  pageCount={tableData.totalPages}
                  currentpage={currentpage}
                  onSizeChange={onSizeChange}
                  size={pageSize}
                />
                )}
              </div>
            )}
          </div>
        </div>
      )}

    </div>
  );
};
export default AcCode;
