/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import PasswordStrengthBar from 'react-password-strength-bar';
import { lowerCase } from 'lodash';
import InputType1 from '../../../common/InputType1';

const AddVesselUser = (props) => {
  const { handleChange, currentUser, setCurrentData, password, errors, vesselId, vesselName,
    tempUserName, setTempUserName } = props;
  const [isDisabled, setIsDisabled] = useState(currentUser.id !== undefined);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const subString = `@${lowerCase(vesselName).replace(/\s/g, '')}`;
    const uName = currentUser.userName.replace(subString, '');
    setTempUserName(uName);
  }, []);
  const onSetResetPassword = () => {
    setIsDisabled(false);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleUserName = (e) => {
    const temp = currentUser;
    temp.userName = (`${e.target.value}@${lowerCase(vesselName)}`).replace(/\s/g, '');
    setCurrentData(temp);
    setTempUserName((e.target.value).replace(/\s/g, ''));
  };

  return (
    <div className="row add-user me-2" data-testid="add-v-user">
      <div className="">
        <h4 className="mt-5 mb-3  head-16">Login Details</h4>
        <div className="d-flex mb-3">
          <div className="col-6" data-tooltip-id="username" data-tooltip-content={currentUser?.userName}>
            <Tooltip id="username" place="top" className="tooltip mt-3" />
            <InputType1
              className="modal-input"
              value={vesselId ? tempUserName : currentUser?.userName}
              onChange={(e) => {
                if (vesselId) {
                  handleUserName(e);
                } else {
                  handleChange(e, 'userName');
                }
              }}
              label="Username"
              name="userName"
              isMandatory="true"
              id="userName"
              autocomplete="false"
            />
            {vesselId
              && (
                <div className="username-label">
                  {currentUser?.userName}
                </div>
              )}
          </div>
          <div className="ms-3 col-6">
            <div className="relative">
              <InputType1
                className={errors.password ? 'text-box modal-input w-full error-field' : 'text-box modal-input w-full'}
                type={showPassword ? 'text' : 'password'}
                value={password || ''}
                maxLength={16}
                onChange={(e) => handleChange(e, 'pwdHash')}
                isMandatory="true"
                label="Password"
                data-testid="passWord"
                id="pwdHash"
                name="pwdHash"
                disabled={isDisabled}
                autocomplete="new-password"
              />
              {!isDisabled && (
                <button type="button" className="link-btn visible-btn" data-testid="shownewpassword" onClick={handleShowPassword} id="shownewpassword">
                  {showPassword ? <span className="icon-eye-close font-24" />
                    : <span className="icon-eye font-24" />}
                </button>
              )}
              <PasswordStrengthBar password={password} minLength={8} shortScoreWord=" " scoreWords={['weak', 'weak', 'average', 'good', 'strong']} />
              <div style={{ color: 'red' }} className="font-13">{errors.password}</div>
            </div>
            {currentUser.id && (
              <button
                type="button"
                id="reset"
                className="link-btn"
                onClick={() => {
                  onSetResetPassword();
                }}
              >
                Click here to reset password
              </button>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};

export default AddVesselUser;
