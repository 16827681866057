/* istanbul ignore file */
import React, { useEffect } from 'react';
import 'gantt-task-react/dist/index.css';
import { ViewMode } from 'gantt-task-react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked
}) => {
  const changeColor = (id) => {
    if (id === 'year') {
      document.getElementById(id).style.background = '#2269D5';
      document.getElementById(id).style.color = '#fff';
      document.getElementById('month').style.background = '#fff';
      document.getElementById('month').style.color = '#2269D5';
      document.getElementById('week').style.background = '#fff';
      document.getElementById('week').style.color = '#2269D5';
    } else if (id === 'month') {
      document.getElementById(id).style.background = '#2269D5';
      document.getElementById(id).style.color = '#fff';
      document.getElementById('year').style.background = '#fff';
      document.getElementById('year').style.color = '#2269D5';
      document.getElementById('week').style.background = '#fff';
      document.getElementById('week').style.color = '#2269D5';
    } else {
      document.getElementById(id).style.background = '#2269D5';
      document.getElementById(id).style.color = '#fff';
      document.getElementById('year').style.background = '#fff';
      document.getElementById('year').style.color = '#2269D5';
      document.getElementById('month').style.background = '#fff';
      document.getElementById('month').style.color = '#2269D5';
    }
  };
  useEffect(() => {
    changeColor('month');
  }, []);
  return (
    <>

      <div className="d-flex justify-content-between card mb-2">
        <div className="Switch ml-12 d-flex align-items-center">
          <label className="Switch_Toggle">
            <input
              type="checkbox"
              defaultChecked={isChecked}
              onClick={() => onViewListChange(!isChecked)}
            />
            <span className="Slider" />
          </label>
          <div className="ml-8 font-14 font-semibold">Show Project List</div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="priority-ind d-flex border-right border-left align-items-center pe-5 ps-5">
            <div className="phase-ind ms-4 d-flex">
              <div
                data-tooltip-id="project-ganttView"
                data-tooltip-content="Plan"
                className="plan m-2"
              >
                P
              </div>
              <div
                data-tooltip-id="project-ganttView"
                data-tooltip-content="Specify"
                className="specify m-2"
              >
                S
              </div>
              <div
                data-tooltip-id="project-ganttView"
                data-tooltip-content="Tender"
                className="tender m-2"
              >
                T
              </div>
              <div
                data-tooltip-id="project-ganttView"
                data-tooltip-content="Repair"
                className="repair m-2"
              >
                R
              </div>
              <div
                data-tooltip-id="project-ganttView"
                data-tooltip-content="Close"
                className="close m-2"
              >
                C
              </div>
              <div
                data-tooltip-id="project-ganttView"
                data-tooltip-content="Not Started"
                className="notstarted m-2"
              >
                NS
              </div>
            </div>
          </div>
          <div className="priority-ind d-flex  align-items-center ms-5">
            <span className="icon-disc high m-2" />
            <label className="m-2">High</label>
            <span className="icon-disc medium m-2" />
            <label className="m-2">Medium</label>
            <span className="icon-disc low m-2" />
            <label className="m-2">Low</label>
          </div>
        </div>
        <div className="ViewContainer d-flex">
          <button
            className="secondary-btn small m-2"
            type="button"
            id="year"
            onClick={() => {
              onViewModeChange(ViewMode.Year);
              changeColor('year');
            }}
          >
            Year
          </button>
          <button
            className="secondary-btn small m-2"
            id="month"
            type="button"
            onClick={() => {
              onViewModeChange(ViewMode.Month);
              changeColor('month');
            }}
          >
            Month
          </button>
          {/* <button
            className="secondary-btn small m-2"
            type="button"
            onClick={() => onViewModeChange(ViewMode.QuarterDay)}
          >
            Quarter of Day
          </button>
          <button
            className="secondary-btn small m-2"
            type="button"
            onClick={() => onViewModeChange(ViewMode.HalfDay)}
          >
            Half of Day
          </button>
          <button
            className="secondary-btn small m-2"
            type="button"
            onClick={() => onViewModeChange(ViewMode.Day)}
          >
            Day
          </button> */}
          <button
            className="secondary-btn small m-2"
            type="button"
            id="week"
            onClick={() => {
              onViewModeChange(ViewMode.Week);
              changeColor('week');
            }}
          >
            Week
          </button>
          <Tooltip id="project-ganttView" place="top" className="tooltip mt-3" />
        </div>
      </div>
    </>
  );
};
