/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { toLower } from 'lodash';
import CreateSubFolder from './CreateSubFolder';
import { fileImages } from '../../../common/mappingConst';

const DocumentContainerComponent = ({
  subFolders,
  sortData,
  formatBytes,
  handleDelete,
  handleRename,
  formData,
  childFolders,
  setFormData,
  setOpenUploadModal,
  handleCreateNew,
  cancel,
  setActive,
  handleActiveData,
  active,
  folderType,
  projInfo
}) => {
  return (
    <div className="scroll-pannel-system" data-testid="doc-container-compo">
      {subFolders
        && subFolders.map((i) => {
          return (
            <div className="card p-4 mb-3">
              {i.id === formData.id ? (
                <CreateSubFolder
                  handleCreateNew={handleCreateNew}
                  cancel={cancel}
                  formData={formData}
                  setFormData={setFormData}
                />
              ) : (
                <div className=" d-flex justify-content-between ">
                  <div
                    id="dco-container1"
                    onClick={() => {
                      if (active === i.id) setActive('');
                      else handleActiveData(i, 'open', i.id);
                    }}
                    className="cursor-pointer d-flex justify-content-start text-ellipsis c-w-300"
                  >
                    {active === i.id ? (
                      <span className="font-26 icon-folder-open me-4 icon-ind" />
                    ) : (
                      <span className="font-26 icon-folder me-4 icon-ind" />
                    )}
                    <span
                      data-tooltip-id="project-dms"
                      data-tooltip-content={i.name}
                      className="me-5 text-ellipsis"
                    >
                      {i.name}
                    </span>
                  </div>
                  {folderType === 2 ? (
                    <div className="d-flex">
                      {active === i.id ? (
                        <div className="menudropdown">
                          <span className="dropbtn font-20 icon-sort me-2 icon-ind cursor-pointer" />
                          <div className="menudropdown-content">
                            <a
                              id="dco-container2"
                              href="#"
                              onClick={() => {
                                sortData('ascending', i);
                              }}
                            >
                              Ascending
                            </a>
                            <a
                              id="dco-container3"
                              href="#"
                              onClick={() => {
                                sortData('descending', i);
                              }}
                            >
                              Descending
                            </a>
                            <a
                              id="dco-container4"
                              href="#"
                              onClick={() => {
                                sortData('newest', i);
                              }}
                            >
                              Newest
                            </a>
                            <a
                              id="dco-container5"
                              href="#"
                              onClick={() => {
                                sortData('oldest', i);
                              }}
                            >
                              Oldest
                            </a>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {projInfo?.isClosed ? '' : (
                        <>
                          <span
                            id="dco-container8"
                            onClick={() => {
                              setOpenUploadModal(i);
                            }}
                            title="Upload File"
                            className="font-20 icon-upload-cloud me-2 text-blue cursor-pointer"
                          />
                          <div className="menudropdown">
                            <span className="dropbtn font-20 icon-dotted-menu-vertical me-2 text-blue cursor-pointer" />
                            <div className="menudropdown-content">
                              <a
                                id="dco-container6"
                                href="#"
                                onClick={() => {
                                  handleRename(i);
                                }}
                              >
                                Rename
                              </a>
                              <a
                                id="dco-container7"
                                href="#"
                                onClick={() => {
                                  handleDelete(i, 'main');
                                }}
                              >
                                Delete
                              </a>
                            </div>
                          </div>

                        </>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {active === i.id ? (
                <div className="mt-3">
                  {childFolders && childFolders.length ? (
                    <>
                      {childFolders.map((c) => {
                        return (
                          <>
                            {c.id === formData.id ? (
                              <CreateSubFolder
                                handleCreateNew={handleCreateNew}
                                cancel={cancel}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            ) : (
                              <div className="ms-5 mt-3 p-4 bg-white d-flex justify-content-between ">
                                <div className="d-flex justify-content-start ">
                                  <div className="thmb-icon-md">
                                    <span className={fileImages[toLower(c?.document.type)]} />
                                    {/* <img src={fileImages[c?.document.type]}
                                    alt="" style={{ height: 24 }} /> */}
                                  </div>
                                  <div className="c-w-300 text-ellipsis">
                                    <span
                                      data-tooltip-id="project-dms"
                                      data-tooltip-content={c.name}
                                      className="ms-3 text-ellipsis"
                                    >
                                      {c.name}
                                    </span>
                                    <div className="d-flex mt-1 ms-3">
                                      <div className="border-right pe-3">{moment(c.document.auditInfo.updatedAt).format('DD-MMM-YYYY HH:mm')}</div>
                                      <div className="border-right px-3">{formatBytes(c.document.size)}</div>
                                      <div className="px-3 text-uppercase text-ellipsis">
                                        <span
                                          data-tooltip-id="project-dms"
                                          data-tooltip-content={c.document.createdBy}
                                        >
                                          {c.document.createdBy}
                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <a href={c?.document?.path} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                                    <span className="icon-download font-20 me-2 text-blue cursor-pointer" />
                                  </a>
                                  {folderType === 2 && !projInfo?.isClosed ? (
                                    <div className="menudropdown">
                                      <span className="dropbtn font-20 icon-dotted-menu-vertical me-2 text-blue cursor-pointer" />
                                      <div className="menudropdown-content">
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleRename(c);
                                          }}
                                        >
                                          Rename
                                        </a>
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleDelete(c, 'sub');
                                          }}
                                        >
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                      <div className="font-18 animate__animated animate__headShake">
                        No data found
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      <Tooltip id="project-dms" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default DocumentContainerComponent;
