import React from 'react';

const ViewMappedSpec = (props) => {
  const { item } = props;
  return (
    <>
      <div className="d-flex align-items-center border-down mt-3 pb-3">
        <span className="col-3">Spec Name</span>
      </div>
      {item.specificationList.map((specItem) => {
        return (
          <div className="mt-3 ms-2 d-flex align-items-center">
            <div className="col-6">
              {specItem.specTitle}
            </div>
          </div>
        );
      })}
    </>
  );
};
export default ViewMappedSpec;
