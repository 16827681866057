import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../../../common/InputType1';
import helper from '../../../../../../utils/helper';

const Sections = (props) => {
  const { mainSec, showsubSec, onSecOpen, specList, setSpecList, isDisabled } = props;
  const { formatNumber } = helper;

  const onCommentChange = (e) => {
    const currentList = { ...specList };
    currentList.comment = e.target.value;
    setSpecList(currentList);
  };

  return (
    <div className="spc-map card me-3 col-6" data-testid="sections">
      <div className="d-flex border-down align-items-center mb-2 ps-3 bg-sky-white h-65">
        <div className="col-8 head-14 font-semibold">Spec Section</div>
        <div className="col-2  head-14 font-semibold d-flex justify-content-end pe-3">UOM</div>
        <div className="col-2 head-14 font-semibold d-flex justify-content-end pe-3">Qty</div>
      </div>
      {
        specList?.collation?.map((sec) => {
          return (
            <div className={(sec.id === mainSec && showsubSec) ? 'highlight-tende' : ''}>
              <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                <div className="col-2 d-flex align-items-center">
                  <span
                    id={sec.id}
                    className={sec.id === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec.id, true); }}
                  />
                  <span
                    className="me-3"
                    data-tooltip-id="project-specName"
                    data-tooltip-content={sec.number}
                  >
                    {_.truncate(sec.number, { length: 5 })}

                  </span>
                </div>
                <div
                  className="col-7"
                >
                  <span
                    data-tooltip-id="project-specName"
                    data-tooltip-content={sec.specName}
                  >
                    {_.truncate(sec.specName, { length: 30 })}
                  </span>

                </div>
                <div className="col-2" />
                <div className="col-2" />
              </div>
              {
              sec.id === mainSec && sec.specs.map((subsec) => {
                return (
                  <>
                    <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                      <div className="col-1" />
                      <div className="col-11 d-flex align-items-center">
                        <span
                          id={subsec.id}
                          className={subsec.id === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                          onClick={() => { onSecOpen(subsec.id, false); }}
                        />
                        <span
                          className="me-4 "
                          data-tooltip-id="project-specName"
                          data-tooltip-content={subsec.number}
                        >
                          {subsec.number}

                        </span>
                        <div
                          className=""
                          data-tooltip-id="project-specName"
                          data-tooltip-content={subsec.specName}
                        >
                          {_.truncate(subsec.specName, { length: 30 })}

                        </div>
                      </div>
                    </div>
                    {
                      subsec.id === showsubSec
                       && subsec.serviceLines.map((serv) => {
                         return (
                           <>
                             <div className="d-flex border-down py-2 ps-3 h-260">
                               <div className="col-1" />
                               <div className="ps-5 col-7 d-flex align-items-center">
                                 <span className="me-4">{serv.number}</span>
                                 <div
                                   className=""
                                   data-tooltip-id="project-specName"
                                   data-tooltip-content={serv.specName}
                                 >
                                   {_.truncate(serv.specName, { length: 30 })}

                                 </div>
                               </div>
                               <div className="col-2 d-flex justify-content-end pe-2">
                                 <span
                                   data-tooltip-id="project-specName"
                                   data-tooltip-content={serv.unit}
                                 >
                                   {_.truncate(serv.unit, { length: 8 }) || '-'}
                                 </span>

                               </div>
                               <div className="col-2 d-flex justify-content-end pe-2">
                                 <span
                                   data-tooltip-id="project-specName"
                                   data-tooltip-content={formatNumber(serv.quantity)}
                                 >
                                   {_.truncate((formatNumber(serv.quantity) || '-'), { length: 8 })}
                                 </span>

                               </div>
                             </div>
                           </>
                         );
                       })
                      }
                  </>
                );
              })
            }
            </div>
          );
        })
      }
      <div className="px-5 pb-5 mt-4">
        <div>Comments</div>
        <div><InputType1 disabled={isDisabled} id="evl-comnt" value={specList.comment} onChange={(e) => { onCommentChange(e); }} /></div>
      </div>
      <Tooltip id="project-specName" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Sections;
