/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import SidePanel from '../common/sidePanel';

const PrivacyPolicy = (props) => {
  const { showPrivacyPolicy, setShowPrivacyPolicy } = props;
  return (
    <div>
      <SidePanel title="Privacy Policy" size="medium" showView={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)}>
        <div className="ms-3">

          <div className="font-13">
            ThinkPalm Technologies Pvt. Ltd., its affiliates and subsidiaries
            and each of their directors, officers, employees, and authorized
            representatives (collectively, “ThinkPalm”, “we”, “us” or “our”)
            respect your privacy and
            are committed to protecting it through compliance
            with this Privacy Policy (this “policy”, “document”).
            This document describes our policies and practices for collecting, using, maintaining,
            protecting, and disclosing information that we collect from you or that you provide
            to us when you use the Maridock App.
          </div>
          <div className="head-14 mb-3 mt-3">Your Consent</div>
          <div className="font-13">
            Please read this document carefully to understand our policies and
            practices regarding your information and how we will treat it.
            By accessing or using the Maridock App,
            you agree that this Policy governs our collection, use, maintenance, protection,
            and disclosure of any information submitted to us on or through the application.
            If you do not agree to be bound by this Policy, you may not access
            or use the Maridock App.
          </div>
          <div className="head-14 mb-3 mt-3">Information We Collect</div>
          <div className="font-13">
            The Maridock App collects information about you during the application operation.
            We collect your personal details and information related to the business
            you represent when you request information from us, order or register the software,
            or otherwise voluntarily send us such information.
          </div>
          <div className="font-13">We may collect the following types of personal information:</div>
          <div className="m-5 font-13">
            <ul style={{ listStyle: 'disc' }}>

              <li>
                Contact Information: Name, Email address,
                phone number, and other relevant contact details.
              </li>
              <li>
                Vessel Information: Details about the vessel,
                including its name, registration number, dimensions, and specifications.
              </li>
              <li>
                Yard Information: Details about the yard, including its name,
                country, phone number, and Email address.
              </li>
              <li>
                Location Information: GPS data and location
                details when using the application.
              </li>
              <li>
                Log Data: Information about your usage of the application,
                such as IP addresses, browser type, and operating system.
              </li>
            </ul>
          </div>

          <div className="head-14 mb-3 mt-3">How We Use Information</div>
          <div className="font-13">We use your personal information for the following purposes:</div>
          <div className="m-5">
            <li>
              Providing Services: To offer Maridock management services,
              facilitate communication between users, and provide relevant information and updates.
            </li>
            <li>
              Application Improvement: To enhance and optimize our application performance,
              features, and user experience.
            </li>
            <li>
              Customer Support: To respond to inquiries,
              provide technical assistance, and address user concerns.
            </li>
            <li>
              Legal Compliance: To comply with applicable laws,
              regulations, or legal processes.
            </li>
          </div>
          <div className="head-14 mb-3 mt-3">How We Share Information</div>
          <div className="font-13">We may share your personal information in the following circumstances:</div>
          <div className="m-5 font-13">
            <ul style={{ listStyle: 'disc' }}>
              <li>
                Service Providers: We may engage trusted third-party service
                providers to assist us in delivering our services.
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition,
                or sale of our business or assets,
                your personal information may be transferred to the acquiring entity.
              </li>
              <li>
                Legal Requirements: We may disclose your personal information
                if required by law, regulation, legal or regulatory process,
                statutory disclosures, or to protect our rights, property, or safety.
              </li>
              <li>
                Legal Compliance: To comply with applicable laws,
                regulations, or legal processes.
              </li>
            </ul>

          </div>
          <div className="head-14 mb-3">How We Store and Secure Information</div>
          <div className="font-13">
            We endeavor to use reasonable security safeguards, practices, and procedures,
            and we commit to the highest level of care to protect the privacy
            and integrity of personal information, throughout its useful life-cycle,
            from collection until destruction. Your information is stored securely
            and accessed only by authorized personnel.
          </div>
          <div className="head-14 mb-3 mt-3">Data Retention</div>
          <div className="font-13">
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this policy, or as required by law.
            We will securely delete or anonymize your data when it is no longer needed.
          </div>
          <div className="head-14 mb-3 mt-3">Links to Other Websites</div>
          <div className="font-13">
            Our Maridock Application may contain links to other websites that
            are not operated by us. We are not responsible for the privacy
            practices of these third-party websites. We encourage you to review
            their privacy policies when visiting their sites.
          </div>
          <div className="head-14 mb-3 mt-3">Cookies and Web Beacons</div>
          <div className="font-13">
            Like any other website, Maridock App uses cookies.
            These cookies are used to store information including visitors’ preferences,
            and the pages on the website that the visitor accessed or visited.
            The information is used to optimize the users’ experience by customizing our
            web page content based on visitors’ browser type and/or other information.
          </div>
          <div className="head-14 mb-3 mt-3">Children's Privacy</div>
          <div className="font-13">
            Our Maridock Application is not intended for children under the age of 15.
            We do not knowingly collect personal information from individuals in this age group.
            If you are a parent or guardian and believe that your child has provided us
            with personal information, please contact us immediately.
          </div>
          <div className="head-14 mb-3 mt-3">Policy Changes</div>
          <div className="font-13">
            We may occasionally update this Policy and such changes will be updated on this page.
            If we make any significant changes to this Policy we will endeavor to provide you with
            reasonable notice of such changes, such as to your Email address on record,
            and we will obtain your consent. To the extent permitted under the applicable law,
            your continued use of our Services after we publish or send a notice about our
            changes to this Policy shall constitute your consent to the updated Policy.
          </div>
          <div className="head-14 mb-3 mt-3">Contact Information</div>
          <div className="font-13">
            If you have questions or complaints regarding this Notice or you have a request
            based on the above rights, you may reach out to us at:
          </div>
          <br />
          <div className="body-gray border p-3">
            <div className="head-14">
              ThinkPalm Technologies Pvt. Ltd.,
            </div>
            <div className="font-13">
              B-1, 1st Floor, Athulya Building,
            </div>
            <div className="font-13">
              Infopark SEZ, Infopark Kochi P.O.,
            </div>
            <div className="font-13">
              Kochi – 682042, Kerala, India
            </div>
            <div className="font-13">
              <span className="icon-phone font-20 me-3"> Tel: +91 484 4104100</span>

            </div>
          </div>
        </div>

      </SidePanel>
    </div>
  );
};
export default PrivacyPolicy;
