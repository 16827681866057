import React from 'react';
import { useNavigate } from 'react-router-dom';
import expiryImage from '../../../images/password-expiry img.png';

const ExpiredPage = () => {
  const navigate = useNavigate();
  const redirectPage = () => {
    navigate('/forget-password');
  };
  return (

    <div className="card stylecard no-shadow py-5 px-4 mb-3" data-testid="expiry">
      <div className="d-flex flex-column align-items-center">
        <img src={expiryImage} alt="#" className="expired-img" />
        <div className="font-20 mt-3 my-3">The link you followed has expired!!</div>
        <button type="button" className="primary-btn my-3" onClick={redirectPage}>Please Try Again</button>
      </div>
    </div>

  );
};
export default ExpiredPage;
