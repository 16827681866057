/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import Loader from '../../../common/loader';
import tiff from '../../../../images/tiff.png';

const GalleryContent = (props) => {
  const { active, folderActiveData, getImages, imageList, openChildFolder, setOpenChildFolder, isReport, handleUploadImage, setOpenModal, setClickedImage, loading } = props;
  return (
    <div data-testid="image-content">
      {folderActiveData?.childFolders?.length > 0 ? (
        folderActiveData?.childFolders?.map((item) => {
          return (
            <div className="card cursor-pointer p-4 mb-3">
              <div className="d-flex justify-content-between ">
                <div
                  id="click2"
                  data-testid="click2"
                  onClick={() => {
                    if (openChildFolder === item.specId) {
                      setOpenChildFolder('');
                    } else {
                      setOpenChildFolder(item.specId);
                      getImages(item.specId);
                    }
                  }}
                  className="cursor-pointer folder d-flex justify-content-start text-ellipsis"
                >
                  {active === item.specId ? (
                    <span className="font-26 icon-folder-open me-4 icon-ind" />
                  ) : (
                    <span className="font-26 icon-folder me-4 icon-ind" />
                  )}
                  <span
                    data-tooltip-id="project-system"
                    data-tooltip-content={item.specNumber.concat(' ') + item.specName.concat(' (').concat(item.fileCount).concat(')')}
                    className="me-5 text-ellipsis"
                  >
                    {item.specNumber}
                    {' '}
                    {_.truncate(item.specName, {
                      length: 60,
                      separator: ' '
                    })}
                    <span className="ps-3">
                      (
                      {item.fileCount}
                      )

                    </span>
                  </span>
                </div>
              </div>
              { openChildFolder === item.specId ? (
                <div className="parent-gallery pb-5">
                  {loading ? (
                    <Loader />
                  ) : (imageList.length > 0 ? (
                    imageList.map((subitem, key) => {
                      return (
                        <div
                          className="img-thumbnail"
                          id="click3"
                          data-testid="click3"
                          onClick={() => {
                            if (!(subitem?.imageUrl.includes('.tiff') || subitem?.imageUrl.includes('.tif'))) {
                              if (isReport) {
                                handleUploadImage(subitem);
                              } else {
                                setClickedImage({ url: subitem?.imageUrl, key });
                                setOpenModal(true);
                                // setLeftClick(key);
                              }
                            }
                          }}
                        >
                          <div className="image-sub">
                            {subitem?.imageUrl.includes('.tiff') || subitem?.imageUrl.includes('.tif')
                              ? (
                                <a href={subitem?.imageUrl} download="downloaded_file" className="" rel="noopener noreferrer">
                                  <img src={tiff} alt="" />
                                </a>
                              )
                              : <img alt="pic 1" className="image-dimension" src={subitem?.imageUrl} />}

                          </div>
                          <div className="name mt-3 font-13">
                            {subitem && (
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex">
                                <label
                                  className="ms-2 value truncate"
                                  data-tooltip-id="project-system"
                                  data-tooltip-content={subitem.imageName}
                                >
                                  {_.truncate(subitem.imageName, {
                                    length: 12,
                                    separator: ' '
                                  })}

                                </label>
                                {subitem?.subNumber !== 0 && (
                                <div className="ms-2">{subitem?.subNumber}</div>
                                )}
                              </div>
                              {subitem?.imageDescription
                            && (
                            <div
                              data-tooltip-id="project-system"
                              data-tooltip-content={subitem?.imageDescription}
                              className="truncate"
                            >
                              <span className="icon-Info font-22 " />
                            </div>
                            )}
                            </div>
                            )}
                            <div className="d-flex font-13">
                              <div className="ms-2 opacity-8">{moment(subitem?.updatedAt).format('DD-MMM-YYYY')}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                      <div className="font-18 animate__animated animate__headShake">No data found</div>
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">No data found</div>
        </div>
      )}
      <Tooltip id="project-system" place="top" className="tooltip mt-3" />

    </div>
  );
};

export default GalleryContent;
