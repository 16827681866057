import React from 'react';
import { upperFirst } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const TableSortHeader = ({ heading, headingProperty, id, data, handleSort }) => {
  return (
    <>
      <span id={id} className={upperFirst(data?.sortProperty) === upperFirst(headingProperty) && 'text-blue'}>
        {heading}
        {(upperFirst(data?.sortProperty)
          === upperFirst(headingProperty)) && (data?.isDescending) ? (
            <>
              <span
                data-test="click1"
                onClick={() => handleSort(upperFirst(headingProperty), false)}
                className="icon-arrow-up2 ms-2 font-18 cursor-pointer"
                data-tooltip-id="project-value1"
                data-tooltip-content="Descending Order"
              />

            </>
          ) : (
            <>
              <span
                data-test="click2"
                onClick={() => handleSort(upperFirst(headingProperty), true)}
                className="icon-arrow-down2 ms-2 font-18 cursor-pointer"
                data-tooltip-id="project-value1"
                data-tooltip-content="Ascending Order"
              />

            </>
          )}
      </span>
      <Tooltip id="project-value2" place="right" className="tooltip" />
    </>
  );
};
export default TableSortHeader;
