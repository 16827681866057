import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetVesselList,
  GetVesselDetails,
  UpdateVesselGeneralInfo,
  ChangeVesselImage,
  ToggleVesselStatus,
  GetSurveyNotificationsByVessel,
  SaveSurveyNotificationsByVessel,
  GetSurveyIntervalScaleList,
  GetComponentListByVessel,
  S3Upload,
  GetPreviousNextVessel,
  BulkUploadVessel,
  GenerateTemplateVessel,
  BulkUploadComponent,
  SaveVesselInfo,
  GetNamesList,
  GenerateTemplateComponent
} = apiEndPoints;

const { downloadFile, currentDate } = helper;
export const getVesselList = (payload) => {
  return serviceHandler({
    url: `${GetVesselList}`,
    methordType: 'post',
    payload
  });
};
export const getVesselDetails = (payload) => {
  return serviceHandler({
    url: `${GetVesselDetails}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const updateGeneralInfo = (payload) => {
  return serviceHandler({
    url: `${UpdateVesselGeneralInfo}`,
    methordType: 'put',
    payload
  });
};
export const changeVesselImage = (payload) => {
  return serviceHandler({
    url: `${ChangeVesselImage}`,
    methordType: 'put',
    payload
  });
};
export const toggleVesselStatus = (payload) => {
  return serviceHandler({
    url: `${ToggleVesselStatus}?vesselId=${payload.id}`,
    methordType: 'put',
    payload
  });
};
export const getNotification = (payload) => {
  return serviceHandler({
    url: `${GetSurveyNotificationsByVessel}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const saveNotification = (payload) => {
  return serviceHandler({
    url: `${SaveSurveyNotificationsByVessel}`,
    methordType: 'post',
    payload
  });
};
export const getIntervals = (payload) => {
  return serviceHandler({
    url: `${GetSurveyIntervalScaleList}`,
    methordType: 'get',
    payload
  });
};
export const getComponents = (payload) => {
  return serviceHandler({
    url: `${GetComponentListByVessel}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const s3Upload = (payload) => {
  return serviceHandler({
    url: `${S3Upload}`,
    methordType: 'post',
    payload
  });
};
export const prevVessel = (payload) => {
  return serviceHandler({
    url: `${GetPreviousNextVessel}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const getNamesList = (payload) => {
  return serviceHandler({
    url: `${GetNamesList}?search=${payload.text}`,
    methordType: 'get',
    payload
  });
};
export const bulkUploadVessel = (payload) => {
  return serviceHandler({
    url: `${BulkUploadVessel}`,
    methordType: 'post',
    payload
  });
};
export const saveVesselDetails = (payload) => {
  return serviceHandler({
    url: `${SaveVesselInfo}`,
    methordType: 'post',
    payload
  });
};
export const bulkUploadComponent = (payload, id) => {
  return serviceHandler({
    url: `${BulkUploadComponent}/${id}`,
    methordType: 'post',
    payload
  });
};
export const generateTemplate = () => {
  return axios
    .get(`${GenerateTemplateVessel}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Vessel_${currentDate()}.xlsx`);
    });
};
export const generateTemplateComponent = () => {
  return axios
    .get(`${GenerateTemplateComponent}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Component_${currentDate()}.xlsx`);
    });
};
