/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../../utils/helper';

const MajorSpecCard = (props) => {
  const { mtSpecData, selectedSpec, onSpecSelect, onDeleteSpec, onEditSpec, createDraft,
    addNewSpec, onAccordianClose, closedSection, isTsiDisabled,
    showContent, isVesselUser, replaceDraft, projInfo, showDataMS, setShowDataMS } = props;
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isEditor = userRole === roles.EDITOR;
  // eslint-disable-next-line max-len
  const isReplace = (userRole === roles.ADMIN || userRole === roles.AUTHOR || userRole === roles.EDITOR);
  const updateView = () => {
    setShowDataMS(!showDataMS);
  };

  return (
    <div className="border p-3 md-radius my-2">
      <div className="d-flex align-items-center justify-content-between ms-3 ">
        <div className="d-flex align-items-center pointer" onClick={updateView} id="accord">
          {showDataMS ? <span className="icon-arrow-thin-down font-10 me-3 brand-color" />
            : <span className="icon-arrow-thin-right font-16 me-3 brand-color" />}
          <p className="link-btn">Major Spec</p>
        </div>
        <div className="d-flex align-items-center">
          {userRole === roles.ADMIN ? (
            <button
              disabled={isTsiDisabled}
              type="button"
              className="icon-btn circle-ico-btn-sm "
              onClick={addNewSpec}
            >
              <span className="icon-plus-square" />
            </button>
          ) : ''}
        </div>
      </div>
      {showDataMS && mtSpecData?.map((item) => {
        return (
          <div key={item.id} className={item.isDraftCreated ? 'draft-icom' : ''}>
            <div className={item.isDraftCreated ? 'draft-ico collapse-head d-flex justify-content-between mt-1' : 'collapse-head d-flex justify-content-between mt-1'}>
              <div className="d-flex align-items-center pointer" id={item.id} data-test="click1" onClick={() => { onAccordianClose(item.id); }}>
                {closedSection.indexOf(item.id) < 0
                  ? <span className="icon-arrow-thin-right font-16 me-3 brand-color" />
                  : <span className="icon-arrow-thin-down font-10 me-3 brand-color" />}
                <span
                  className="count me-2"
                  data-tooltip-id="project-title"
                  data-tooltip-content={item.specificationNo}
                >
                  {_.truncate(item.specificationNo, { length: 8 })}
                </span>
                <span
                  className="whitespace-pre"
                  data-tooltip-id="project-title"
                  data-tooltip-content={item.title}
                >
                  {_.truncate(item.title, { length: 18 })}

                </span>
              </div>
              <Tooltip id="project-title" place="top" className="tooltip m-4" />
              <div className="d-flex align-items-center">
                {userRole === roles.ADMIN ? (
                  <button
                    type="button"
                    className="link-btn ms-3 pointer"
                    disabled={item.isMapped || isTsiDisabled}
                    onClick={() => { onDeleteSpec(item.id); }}
                  >
                    <span
                      className="icon-delete font-24"
                    />
                  </button>
                ) : ''}
                {item.specificationNo === 'DEF' ? '' : (
                  <button type="button" disabled={isTsiDisabled} data-test="click2" className="link-btn ms-3 pointer" onClick={() => { onEditSpec(item.id, '', item); }}>
                    <span className="icon-plus-square font-24" />
                  </button>
                )}
              </div>
            </div>
            {closedSection.indexOf(item.id) > -1
              && (
                <div className="collapse-body">
                  {item?.specifications?.map((field) => {
                    let clName = 'spec-item pointer ';
                    if (field.id === selectedSpec.id) {
                      clName = clName.concat('active ');
                    }
                    if (field?.completionStatus === 1) {
                      clName = clName.concat('spec-partial ');
                    }
                    if (field?.completionStatus === 2) {
                      clName = clName.concat('spec-complete ');
                    }
                    if (field?.completionStatus === 3) {
                      clName = clName.concat('spec-approve ');
                    }
                    if (isVesselUser && field?.isDraftCreated) {
                      clName = clName.concat('opacity-4');
                    }
                    if (!isVesselUser && field?.isDraft) {
                      clName = clName.concat('opacity-4');
                    }
                    return (
                      <div
                        key={field.id}
                        id={field.id}
                        className={clName}
                        onClick={() => { onSpecSelect(field, 'mt'); }}
                      >
                        <div className="d-flex justify-content-between align-content-center">
                          <div className="d-flex">
                            <span
                              className="count me-2"
                              data-tooltip-id="project-title"
                              data-tooltip-content={field.specificationNo}
                            >
                              {_.truncate(field.specificationNo, { length: field?.isDraft ? 18 : 10 })}
                            </span>
                            <span
                              data-tooltip-id="project-info"
                              data-tooltip-content={field.title}
                            >
                              {_.truncate(
                                field.title.toUpperCase(), { length: field?.isDraft ? 14 : 22 }
                              )}

                            </span>
                            <Tooltip id="project-info" place="top" className="tooltip m-4" />
                          </div>
                          <div className="d-flex button-ctrl">
                            {((!field.isDeferredSpec && !isVesselUser && !field?.isDraft)
                              || (!field.isDeferredSpec && isVesselUser && field?.isDraft)) && !field?.isDraft && (
                                <button
                                  type="button"
                                  className="link-btn ms-3"
                                  disabled={field.isMapped
                                    || isTsiDisabled
                                    || (field?.isLocked && userRole === roles?.VESSEL_USER)}
                                  onClick={() => { onEditSpec(item.id, field); }}
                                >
                                  <span className="icon-edit font-20" />
                                </button>
                            )}
                            {((!isEditor && isVesselUser && field?.isDraft)
                              || (!isEditor && !isVesselUser && !field?.isDraft)) ? (
                                <button
                                  type="button"
                                  className="link-btn ms-3"
                                  disabled={field.isMapped
                                  || isTsiDisabled
                                  || (field?.isLocked && userRole === roles?.VESSEL_USER)}
                                  onClick={() => { onDeleteSpec(field.id, true, field?.isDraft); }}
                                >
                                  <span
                                    className="icon-delete font-20"
                                    data-tooltip-id="project-title"
                                    data-tooltip-content={field.isMapped ? 'These jobs are added specifically from plan phase and cannot be deleted' : ''}
                                  />
                                </button>
                              ) : ''}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="spec-ctrl mt-2 d-flex justify-content-between">
                            <div className="d-flex">
                              {' '}
                              <div className="">
                                <span className="icon-woha font-16 me-2" />
                                {field.wodCount}
                              </div>
                              <div className="ms-5">
                                <span className="icon-poha font-16 me-2" />
                                {field.poCount}
                              </div>

                            </div>
                            {field.isDeferredSpec && (
                              <div className="d-flex ">
                                <button type="button" className="secondary-btn small" onClick={() => { showContent(field); }}>Map DEF</button>
                              </div>
                            )}
                          </div>
                          {isVesselUser && !field?.isDraft && !field?.isDraftCreated && projInfo.phase === 2 ? (
                            <div>
                              <button onClick={() => createDraft(field, 'mt')} type="button" className="primary-btn small">Edit</button>
                            </div>
                          ) : ''}
                          {isReplace && !field?.isDraft && field?.isDraftCreated ? (
                            <div>
                              <button
                                data-tooltip-id="project-replace"
                                onClick={() => replaceDraft(field, 'mt')}
                                type="button"
                                className="primary-btn small"
                              >
                                Replace
                              </button>
                              <Tooltip id="project-replace" place="top" className="tooltip m-4" />
                            </div>
                          ) : ''}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};
export default MajorSpecCard;
