/* eslint-disable */
/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { dashboardGantt } from '../../service';
import { getManagementList } from '../../../Projects/ListProject/services';
import helper from '../../../../../utils/helper';
import { barColor1 } from '../../../Projects/ListProject/GanttView/utilities';


const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
// monthList.splice(moment()._d.getMonth(), 0, 'krish');
// console.log(monthList, 'jfjf');
const monthwise = [...monthList.slice(moment()._d.getMonth(), 12),
  ...monthList, ...monthList, ...monthList.slice(0, moment()._d.getMonth())];
const monthInYear = 12 - moment()._d.getMonth();
const currentYear = moment()._d.getFullYear() - 1;
console.log(monthwise, 'month');
const getYear = (key) => {
  if (key < monthInYear) {
    return currentYear;
  } if (key < monthInYear + 12) {
    return currentYear + 1;
  } if (key < monthInYear + 24) {
    return currentYear + 2;
  } return currentYear + 3;
};

const Gantt = () => {
  const [phase, setPhase] = useState('0');
  const [ganttData, setGanttData] = useState([]);
  const [managementList, setManagementList] = useState([]);
  const [selectedManagement, setSelectedManagement] = useState('');
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;

  const { generateOptions } = helper;
  const phaseArray = [
    { label: 'Plan', value: '1' },
    { label: 'Specify', value: '2' },
    { label: 'Tender', value: '3' },
    { label: 'Repair', value: '4' },
    { label: 'Close', value: '5' },
    { label: 'Not Started', value: '6' },
    { label: 'All Phases', value: '0' }
  ];
  const getMonthList = { Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12 };
  useEffect(() => {
    dashboardGantt({ phase }).then((res) => {
      if (res.success) {
        setGanttData(res.data);
      }
    });
    getManagementList().then((res) => {
      if (res.success) {
        const options = generateOptions(res.data, 'id', 'name');
        options.push({ label: 'All Management', value: '' });
        setManagementList(options);
      }
    });
  }, []);
  const handleManagement = (selected) => {
    setSelectedManagement(selected.value);
  };
  const handlePhase = (selectedPhase) => {
    setPhase(selectedPhase.value);
    dashboardGantt({ phase: selectedPhase.value }).then((res) => {
      if (res.success) {
        setGanttData(res.data);
      }
    });
  };
  const multipleSurveys = (allData, survey, month, year) => {
    const surveyList = survey.filter((item4) => moment(allData.repairStartDate || item4?.surveyDueDate)._d
      .getMonth() + 1 === month && moment(allData.repairStartDate || item4?.surveyDueDate)._d
      .getFullYear() === year);
    const surveyName = [];
    surveyList.map((item) => {
      if (item.surveyShortName) {
        surveyName.push(item.surveyShortName);
      }
      return null;
    });
    return surveyName.join(', ');
  };
  const multipleSurveyDate = (allData, survey, month, year) => {
    const surveyList = survey.filter(
      (item4) =>
        moment(allData.repairStartDate || item4?.surveyDueDate)._d.getMonth() + 1 === month &&
        moment(allData.repairStartDate || item4?.surveyDueDate)._d.getFullYear() === year
    );
    const surveyDate = [];
    surveyList.map((item) => {
      if (item.surveyShortName) {
        if (allData.repairStartDate) {
          surveyDate.push(item.surveyName + ' on'.concat(moment(allData.repairStartDate).format(' DD-MMM-YYYY')));
        } else {
          surveyDate.push(item.surveyName + ' due on'.concat(moment(item.surveyDueDate).format(' DD-MMM-YYYY')));
        }
      }
      return null;
    });
    return surveyDate.join(', ');
  };

  const surveyMonths = (survey, color) => {
    return (
      <>
        {
          monthwise.map((num, key) => {
            return (
              <td
                className="whitespace-pre white text-center"

              >
                {multipleSurveys(color, survey, getMonthList[num], getYear(key)) && (
                <span
                  className="p-2 font-12"
                  style={{ backgroundColor: barColor1(color.phaseId,
                    multipleSurveys(color, survey, getMonthList[num], getYear(key))), borderRadius:'3px' }}
                  data-tooltip-id="dashboard-ganttView"
                  data-tooltip-content={`${multipleSurveyDate(color, survey, getMonthList[num], getYear(key))}${' '}${color.projectName}`}
                >
                  {multipleSurveys(color, survey, getMonthList[num], getYear(key))}
                </span>
                )}
              </td>
            );
          })
        }
      </>

    );
  };
  return (
    <div className="projectlist-wrapper" data-testid="gantt-view">
      <div className="p-4">
        <div className="d-flex justify-content-between priority-ind my-2 col-12">
          <div className="d-flex col-6">
            {isAdmin && (
            <div className="col-5 z-index-120 me-5">
              <Select
                autoFocus
                className="modal-select cursor-pointer w-full"
                placeholder="Management"
                id="management"
                options={managementList}
                onChange={(e) => handleManagement(e)}
                value={managementList.filter((option) => option.value === selectedManagement)}
              />
            </div>
            )}
            <div className="col-4 z-index-120">
              <Select
                autoFocus
                className="modal-select cursor-pointer w-full"
                placeholder="Phases"
                id="Phases"
                options={phaseArray}
                onChange={(e) => handlePhase(e)}
                value={phaseArray.filter((option) => option.value === phase)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="priority-ind d-flex border-right border-left
             align-items-center pe-5 ps-5"
            >
              <div className="phase-ind ms-4 d-flex font-14">
                <div
                  className="plan m-2"
                  data-tooltip-id="dashboard-ganttDash"
                  data-tooltip-content="Plan"
                >
                  P
                </div>
                <div
                  className="specify m-2"
                  data-tooltip-id="dashboard-ganttDash"
                  data-tooltip-content="Specify"
                >
                  S

                </div>
                <div
                  className="tender m-2"
                  data-tooltip-id="dashboard-ganttDash"
                  data-tooltip-content="Tender"
                >
                  T

                </div>
                <div
                  className="repair m-2"
                  data-tooltip-id="dashboard-ganttDash"
                  data-tooltip-content="Repair"
                >
                  R

                </div>
                <div
                  className="close m-2"
                  data-tooltip-id="dashboard-ganttDash"
                  data-tooltip-content="Close"
                >
                  C

                </div>
                <div
                  className="notstarted m-2"
                  data-tooltip-id="dashboard-ganttDash"
                  data-tooltip-content="Not Started"
                >
                  NS

                </div>
                <Tooltip id="dashboard-ganttDash" place="top" className="tooltip" />
              </div>
            </div>
          </div>

        </div>
        <div className="relative d-flex gant-tabl" style={{ overflow: 'auto', height: '434px', scrollbarGutter: 'stable'}}>
          <div style={{ height: '470px' }}>
            <table className="card-table-gantt over relative">
              <thead className="v-thead-fixed z-index-1100 ">
                <tr>
                  {/* <th className="fixed-gnt">Management</th> */}
                  <th className="fixed-gnt whitespace-pre" >Management and  Vessel Name</th>
                  {monthwise.map((items, key) => {
                    return (
                      <th className='text-center' >
                        {items}
                        <div className="font-12">{getYear(key)}</div>
                      </th>
                    );
                  })}

                </tr>
              </thead>
              <tbody>
                {ganttData.filter((management) => (management.managementId === selectedManagement
               || !selectedManagement))
                  .map((items) => {
                    return (
                      <>
                        <tr>
                          <td
                            className="whitespace-pre fixed-gnt head-12 head-black"
                          >
                            <span
                              data-tooltip-id="dashboard-ganttView"
                              data-tooltip-content={items?.managementGroupName}
                            >
                              {_.truncate(items?.managementGroupName, {
                                length: 35,
                                separator: ' '
                              })}
                            </span>
                          </td>
                        </tr>

                        { items.vessels.map((item2) => {
                          return (
                            <tr>
                               <td
                                className="whitespace-pre fixed-gnt bg-white font-12 ps-5"
                              >
                                <span
                                  data-tooltip-id="dashboard-ganttView"
                                  data-tooltip-content={item2?.vesselName}
                                >
                                  {_.truncate(item2?.vesselName, {
                                    length: 20,
                                    separator: ' '
                                  })}
                                </span>
                              </td>
                              {surveyMonths(item2?.surveys, item2)}
                            </tr>
                          );
                        })}

                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <Tooltip id="dashboard-ganttView" place="top" className="tooltip mt-3" />
        </div>

      </div>
    </div>
  );
};
export default Gantt;
