/* eslint-disable max-len */
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Header from '../../Header';
import ProjectList from './ProjectList';
import { getProjects, savePinProjects, getActiveProjectCount, getGanttList, getManagementList } from './services';
import GanttView from './GanttView/index';
import helper from '../../../../utils/helper';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const index = (props) => {
  const { showHeader = true } = props;
  const { roles, generateOptions, PhaseTypes } = helper;
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [managementList, setManagementList] = useState([]);
  const [ganttList, setGanttList] = useState();
  const [currentpage, setCurrentpage] = useState(1);
  const [isListView, setIsListView] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [activeCount, setActiveCount] = useState(null);
  const [filter, setFilter] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedManagement, setSelectedManagement] = useState([]);
  const [tempselectedOptions, settempSelectedOptions] = useState([]);
  const [tempselectedManagement, settempSelectedManagement] = useState([]);
  const [selectedPhaseCount, setSelectedPhaseCount] = useState(0);
  const [selectedManagementCount, setSelectedManagementCount] = useState(0);
  const [searchBoxText, setSearchBoxText] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [searchadv, setSearchAdv] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);

  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR;

  const reloadTable = (page, clear = '') => {
    const selectedPhases = tempselectedOptions?.map((option) => option.value);
    const selectedManagementIds = tempselectedManagement?.map((option) => option.value);
    setSelectedOptions(tempselectedOptions);
    setSelectedPhaseCount(tempselectedOptions.length);
    setSelectedManagement(tempselectedManagement);
    setSelectedManagementCount(tempselectedManagement.length);
    getProjects({
      page: page || currentpage,
      phaseList: clear ? [] : selectedPhases,
      pageSize,
      managementIds: clear ? [] : selectedManagementIds,
      search: searchText
    }).then((res) => {
      setProjects(res?.data);
      setLoading(false);
    });
  };

  const handleChange = (selected) => {
    settempSelectedOptions(selected);

    if (selected.length > 0 || selectedManagementCount > 0) {
      setSearchBoxText('Advanced filter applied');
    } else {
      setSearchBoxText('');
    }
    setSearchClicked(false);
  };

  const handleManagement = (selected) => {
    settempSelectedManagement(selected);
    if (selected.length > 0 || selectedPhaseCount > 0) {
      setSearchBoxText('Advanced filter applied');
    } else {
      setSearchBoxText('');
    }
    setSearchClicked(false);
  };

  const reload = () => {
    getGanttList().then((res) => {
      setGanttList(res?.data);
    });
  };
  const savePin = (body) => {
    savePinProjects(body).then((res) => {
      if (res?.success) {
        reloadTable();
        reload();
      }
    });
  };

  const getCount = () => {
    getActiveProjectCount().then((res) => {
      if (res?.success) {
        setActiveCount(res?.data);
      } else {
        setActiveCount(0);
      }
    }).catch(() => {
      setActiveCount(0);
    });
  };

  useEffect(() => {
    reloadTable();
    getCount();
  }, [currentpage, pageSize]);
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
      setSearchBoxText('');
    }
  };

  useEffect(() => {
    reload();
    setLoading(true);
    getManagementList().then((res) => {
      if (res.success) {
        const options = generateOptions(res.data, 'id', 'name');
        setManagementList(options);
      }
    });
  }, []);

  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);

  return (
    <>
      <div data-testid="tsi-project-index">
        <div className={showHeader ? 'header' : ''}>
          {showHeader && <Header />}
          <div className="sub-header">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-lg-8 col-md-9 col-sm-12">
                  <div className="d-flex align-items-center">
                    <h2 className="main-head me-5">
                      Projects
                      {activeCount !== null ? (
                        <>
                          {activeCount === projects?.totalItems ? (
                            <span className="bubble hee">{activeCount}</span>
                          ) : (
                            <span className="bubble hmm">{`${projects?.totalItems ? projects?.totalItems : 0} / ${activeCount} `}</span>
                          )}
                        </>
                      ) : (
                        <span className="bubble">0</span>
                      )}
                    </h2>
                    {isListView && (
                    <div className="search large search-project spec-search">
                      <div className="d-flex justify-content-end">
                        <span
                          className={searchText ? ' icon-close-line ' : ''}
                          onClick={() => {
                            setSearchText('');
                            document.getElementById('search')?.focus();
                          }}
                        />
                        <span
                          className="icon-search"
                          data-testid="search"
                          onClick={() => {
                            onSearch({ key: 'Enter' });
                            setSearchBoxText('Advanced filter applied');
                          }}
                        />
                        <button
                          data-tooltip-id="project-management"
                          data-tooltip-content={filter ? '' : 'Advanced Search'}
                          type="button"
                          className="advance-arrow"
                          onClick={() => {
                            setFilter(!filter);
                          }}
                        >
                          <span
                            className={filter ? 'icon-arrow-thin-up' : 'icon-arrow-thin-down'}
                            data-testid="search"
                            onClick={() => {
                              setFilter(false);
                            }}
                          />
                        </button>
                      </div>
                      <input
                        type="text"
                        id="search"
                        className="text-box large"
                        placeholder="Search by Project Name"
                        data-testid="searchInput"
                        value={searchText}
                        onKeyPress={onSearch}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                      {
                        searchadv && (
                          <div className="applied-search search-advanced">
                            { !searchClicked && (selectedPhaseCount + selectedManagementCount) > 0 && (
                            <span className="count-badge">{selectedPhaseCount + selectedManagementCount}</span>
                            )}
                            {searchBoxText && (
                            <>
                              <span>
                                {searchBoxText}
                              </span>
                              <span
                                className="icon-close-line "
                                onClick={() => {
                                  setSearchAdv(false);
                                  setSearchBoxText('');
                                  setSelectedOptions([]);
                                  setSelectedManagement([]);
                                  setSelectedPhaseCount([]);
                                  setSelectedManagementCount([]);
                                  settempSelectedManagement([]);
                                  settempSelectedOptions([]);
                                  reloadTable(1, 'clear');
                                }}
                              />

                            </>
                            )}
                          </div>
                        )
                      }

                      {filter ? (
                        <ul className="searchdropdown">
                          <div className="d-flex justify-content-between">
                            <div className="head-14">Advanced Search</div>
                            <button type="button" className="link-btn">
                              <span
                                className="icon-close font-26"
                                onClick={() => {
                                  setFilter(false);
                                  settempSelectedOptions(selectedOptions);
                                  settempSelectedManagement(selectedManagement);
                                }}
                              />
                            </button>
                          </div>
                          <li className="dropdown_item-1">
                            <div className="search search-cursor mt-4">
                              Project Phase
                              <MultiSelect
                                options={PhaseTypes}
                                value={tempselectedOptions}
                                onChange={(e) => handleChange(e)}
                                labelledBy="Select Phase"
                              />
                            </div>
                          </li>
                          <li className="dropdown_item-2">
                            <div className="search word-bk mt-4">
                              Management
                              <MultiSelect
                                options={managementList}
                                value={tempselectedManagement}
                                onChange={(e) => handleManagement(e)}
                                labelledBy="Select Management"
                                // onClick={() => {
                                //   reloadTable(1);
                                // }}
                              />
                            </div>
                          </li>
                          <li className="dropdown_item-3 text-align-end mt-4 ">
                            <div className="d-flex justify-content-end">
                              <span
                                data-testid="create"
                                type="button"
                                className="brand-color mt-3 me-2"
                                // className="primary-btn large search-btnw"
                                // onKeyPress={onSearch}
                                onClick={() => {
                                  setFilter(false);
                                  setSelectedOptions([]);
                                  setSelectedManagement([]);
                                  setSelectedPhaseCount([]);
                                  setSelectedManagementCount([]);
                                  settempSelectedManagement([]);
                                  settempSelectedOptions([]);
                                  setSearchAdv(false);
                                  reloadTable(1, 'clear');
                                }}
                              >
                                Clear
                              </span>
                              <button
                                data-testid="create"
                                type="button"
                                className="primary-btn large ms-2"
                                // onKeyPress={onSearch}
                                onClick={() => {
                                  reloadTable(1);
                                  setFilter(false);
                                  setLoading(true);
                                  setSearchAdv(true);
                                  setSearchClicked(false);
                                }}
                              >
                                Search
                              </button>
                            </div>
                          </li>
                        </ul>
                      ) : (
                        ''
                      )}
                    </div>
                    )}
                    {isTSI ? (
                      <button
                        onClick={() => {
                          navigate('/user/projects/create');
                        }}
                        data-testid="create"
                        type="button"
                        className="primary-btn large ms-4"
                      >
                        Create Project
                      </button>
                    ) : ''}
                  </div>
                </div>

                <div className="col-lg-4 col-md-3 d-flex align-items-end justify-content-end col-sm-12">
                  <div className="d-flex mt-3 font-14 lineBtn-set ">
                    <button
                      type="button"
                      data-testid="listview"
                      className={isListView ? 'link-btn active' : 'link-btn'}
                      onClick={() => {
                        setIsListView(true);
                        reloadTable(1);
                      }}
                    >
                      Line View
                    </button>

                    <button
                      type="button"
                      data-testid="ganttview"
                      className={!isListView ? 'link-btn active ms-4' : 'link-btn ms-4'}
                      onClick={() => {
                        setIsListView(false);
                      }}
                    >
                      GANTT View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isListView ? (
          <div>
            <ProjectList
              projects={projects}
              savePin={savePin}
              setCurrentpage={setCurrentpage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              currentpage={currentpage}
              loading={loading}
            />
          </div>
        ) : (
          <GanttView projects={ganttList} />
        )}

        {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
        )}
        {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
        )}
        <Tooltip id="project-management" place="bottom" className="tooltip" />
      </div>

    </>
  );
};
export default index;
