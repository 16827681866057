/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { cloneDeep } from 'lodash';
import ModalBox from '../../../../../common/ModalBox';
import PreviewContent from './previewContent';
import PreviewFooter from './previewFooter';
import { getSpecListById, reject, submit, projectApproval } from './services';
import AddRemarks from './addRemarks';
import { submitSpecsApproval } from '../SupplementarySpecs/services';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import SidePanel from '../../../../../common/sidePanelCustom';
import SpecList from '../../Specify/SpecsList';
import PreConfirm from '../../../PreConfirm';
import Loader from '../../../../../common/loader';
// import Loader from '../../../../../common/loader';

const index = (props) => {
  const navigate = useNavigate();
  const [specList, setSpecList] = useState();
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [rejectClick, setIsRejectClicked] = useState(false);
  const [approveClick, setIsApproveClicked] = useState(false);
  const [submitClick, setIsSubmitClicked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState({});
  const [viewSpec, setViewSpec] = useState(false);
  const [level, setLevel] = useState();
  const { addColor, projectPhase, roles } = helper;
  const { isTSI, id, projectDetails, userRole } = props;
  const [buttonClicked, setButtonClicked] = useState(false);
  const isTsiDisabled = true;
  const getSpecListData = () => {
    projectApproval(id, projectPhase.TENDER).then((resp) => {
      if (resp.success) {
        setLoading(false);
        setLevel(resp?.data);
        const payload = {
          id,
          approvalLevel: resp?.data,
          approvalStatus: 1
        };
        getSpecListById(payload).then((res) => {
          if (res.success) {
            setLoading(false);
            setSpecList(res.data);
          }
        });
      }
    });
  };
  console.log(loading, 'loading');
  useEffect(() => {
    getSpecListData();
    setIsSaved(false);
  }, [isSaved]);
  useEffect(() => {
    setLoading(true);
  }, []);

  const respCall = (text) => {
    setIsOpened(false);
    setIsRejectClicked(false);
    setIsSubmitClicked(false);
    setIsApproveClicked(false);
    setButtonClicked(true);
    setRemarks('');
    setIsSaved(true);
    setMainSec('');
    setShowSubSec('');
    showAlert(`Successfully ${text}`, 'success');
    navigate('/user/projects');
  };
  const handleValidation = (val) => {
    let isValid = false;
    const v = String(val)?.trim();
    if (!v || v === null || v === '' || v === ' ') {
      isValid = false;
      addColor('remarkstext', 'add');
    } else {
      isValid = true;
      addColor('remarkstext', '');
    }
    return isValid;
  };
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  /* istanbul ignore next */
  // const handleInputChange = (event, mainIndex, subIndex) => {
  //   const temp = cloneDeep(specList);
  //   if (event.target.checked) {
  //     temp[mainIndex].childData[subIndex].isChecked = true;
  //   } else {
  //     temp[mainIndex].childData[subIndex].isChecked = false;
  //   }
  //   console.log(temp);
  //   setSpecList(temp);
  // };
  /* istanbul ignore next */
  const rejectSpecs = () => {
    if (handleValidation(remarks)) {
      const payload = {
        specificationList: specList,
        projectApprovalData: {
          projectId: id,
          type: projectPhase.TENDER,
          level,
          status: 4,
          comment: remarks
        }
      };
      reject(payload).then((resp) => {
        setLoading(false);
        if (resp.success) {
          respCall('Rejected');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const approveSpecs = () => {
    const payload = {
      specificationList: specList,
      projectApprovalData: {
        projectId: id,
        type: projectPhase.TENDER,
        level,
        status: 2,
        comment: remarks
      }
    };
    submitSpecsApproval(payload).then((resp) => {
      setLoading(false);
      if (resp.success) {
        respCall('Approved');
      }
    });
  };
  /* istanbul ignore next */
  const submitSpecs = () => {
    if (handleValidation(remarks)) {
      const payload = {
        specificationList: specList,
        projectApprovalData: {
          projectId: id,
          type: projectPhase.TENDER,
          level,
          status: 3,
          comment: remarks
        }
      };
      submit(payload).then((resp) => {
        setLoading(false);
        if (resp.success) {
          respCall('Submitted for revision');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const saveApiCall = () => {
    setIsOpened(true);
    // const temp = cloneDeep(specList);
    // const check = [];
    // if (temp && temp?.length > 0) {
    //   temp?.map((el) => {
    //     const isChecked = el?.childData.some((c) => c.isChecked === true);
    //     check.push(isChecked);
    //     return isChecked;
    //   });
    //   if (check?.includes(true)) {
    //     setIsOpened(true);
    //   } else {
    //     showAlert('Select specifications to approve or reject', 'error');
    //   }
    // }
  };
  /* istanbul ignore next */
  const onSave = () => {
    if (rejectClick) {
      rejectSpecs();
    } else if (submitClick) {
      submitSpecs();
    } else if (approveClick) {
      approveSpecs();
    }
  };
  const viewMore = (data) => {
    setViewSpec(true);
    setSelectedSpec(data);
  };
  return (
    <div data-testid="prew-main" className="p-4">
      {specList && specList?.length > 0 ? (
        <PreviewContent
          loading={loading}
          specList={specList}
          mainSec={mainSec}
          showsubSec={showsubSec}
          onSecOpen={onSecOpen}
          // isLoading={loading}
          // handleInputChange={handleInputChange}
          viewMore={viewMore}
        />
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">
            No data found
          </div>
        </div>
      )}

      <PreviewFooter
        saveApiCall={saveApiCall}
        id={id}
        rejectSpecs={rejectSpecs}
        setIsRejectClicked={setIsRejectClicked}
        setIsApproveClicked={setIsApproveClicked}
        setIsSubmitClicked={setIsSubmitClicked}
        setButtonText={setButtonText}
        specList={specList}
        onSave={onSave}
        approveSpecs={approveSpecs}
        onSecOpen={onSecOpen}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        level={level}
      />
      {isOpened ? (
        <ModalBox
          closeModal={() => {
            setIsOpened(false);
            setIsApproveClicked(false);
            setIsRejectClicked(false);
            setIsSubmitClicked(false);
            setButtonText('');
            setRemarks('');
          }}
          buttonName="Submit"
          modalClassName="medium modal-scroll"
          onSave={() => onSave()}
        >
          <AddRemarks
            name={projectDetails?.name}
            nameTooltip={projectDetails?.nameTooltip}
            setRemarks={setRemarks}
            buttonText={buttonText}
            setButtonText={setButtonText}
            remarks={remarks}
            approveClick={approveClick}
          />
        </ModalBox>
      ) : ('')}

      {selectedSpec.id
        ? (
          <>
            {viewSpec
              ? (
                <SidePanel
                  data-tooltip-id="project-imagename"
                  data-tooltip-content={`${selectedSpec.specificationNo}. ${selectedSpec.title}`}
                  size="large"
                  onClose={() => { setSelectedSpec({}); setViewSpec(false); }}
                  showView="true"
                >
                  <div className="side-panel__container bg-white specify-side">
                    <div className="side-panel__content">
                      <SpecList
                        isTSI={isTSI}
                        projectId={id}
                        isTsiDisabled={isTsiDisabled}
                        specDetails={selectedSpec}
                        selectedSpec={selectedSpec.id || ''}
                      // onReload={onReload}
                      />
                    </div>
                  </div>
                </SidePanel>
              ) : ''}
          </>
        )
        : ''}
      <Tooltip id="project-imagename" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default index;
