import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import Loader from '../../../../../common/loader';
import ModalBox from '../../../../../common/ModalBox';
import { notCompletedSpecList } from '../services';
import { statusDot, jobNameMap } from './constant';

const CloseJobs = (props) => {
  const { setShowJobsList, onCloseRepair, onSelectRepair } = props;
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const speclist = () => {
    notCompletedSpecList({ id }).then((res) => {
      if (res.success) {
        setData(res.data);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    speclist();
    setLoading(true);
  }, []);

  return (
    <div className="" data-testid="CloseJobs">
      <ModalBox
        modalClassName="medium modal-scroll"
        className="secondary-btn"
        heading={data.length > 0 ? (
          <div className="d-flex">
            <span>Close Repair Phase </span>
            {' '}
            <span className="ms-3 primary-btn small count-badge">{data.length}</span>
          </div>
        ) : 'Close the Repair phase'}
        closeModal={() => setShowJobsList(false)}
        buttonName="Confirm"
        onSave={() => { onCloseRepair(); }}
        saveDisable={data.length > 0 || loading}
      >
        {loading && <Loader />}
        {data.length > 0
          ? <p>Project cannot be closed as following specs are open.</p>
          : (
            <p>
              The Repair Phase will be closed, and once it is closed,
              data editing will only be allowed for AC codes. Do you want to continue?
            </p>
          )}
        {data.map((item) => {
          return (
            <>
              <div
                onClick={() => onSelectRepair(item)}
                className="cursor-pointer mt-3 py-2 d-flex align-items-center bg-sky-white md-radius"
              >
                <div className="ps-3">
                  <span
                    data-tooltip-id="project-specc"
                    data-tooltip-content={jobNameMap[item.status]}
                  >
                    {statusDot(item.status)}
                  </span>

                </div>
                <div className="ps-4 w-100">
                  {item.specificationNo}
                </div>
                <div className="ms-3">
                  <span
                    data-tooltip-id="project-specc"
                    data-tooltip-content={jobNameMap[item.status]}
                  >
                    {_.truncate(item.title, {
                      length: 60,
                      separator: ' '
                    })}

                  </span>

                </div>
              </div>
            </>
          );
        })}
      </ModalBox>
      <Tooltip id="project-specc" place="top" className="tooltip mt-3" />
    </div>

  );
};
export default CloseJobs;
