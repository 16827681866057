import React from 'react';
import Loader from '../../../common/loader2';
import ManualCard from './manualCard';
import YardCard from './yardCard';

const CardSeletion = (props) => {
  const { loading } = props;
  if (loading) {
    return <Loader />;
  }
  return (
    <div data-testid="yard-cards" className="height-fix-yard">
      <div className="d-flex justify-content-center">
        <div className="mx-3 p-3 round-radius brand-bg white">OR</div>
      </div>
      <div className="d-flex justify-content-center">
        <ManualCard {...props} />
        <YardCard {...props} />
      </div>
    </div>
  );
};
export default CardSeletion;
