/* istanbul ignore file */
import { serviceHandler } from '../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../utils/apiUrlConstants';

const { NonSpecList, NonSpecLine, CurrencyConvertedAmount } = apiEndPoints;

export const getNonSpecList = (id, search) => {
  return serviceHandler({
    url: `${NonSpecList}?projectId=${id}&searchString=${search}`,
    methordType: 'get'
  });
};

export const saveNonSpecLine = (payload) => {
  return serviceHandler({
    url: `${NonSpecLine}`,
    methordType: 'post',
    payload
  });
};

export const currencyConverter = (cId, pId, amount) => {
  return serviceHandler({
    url: `${CurrencyConvertedAmount}?currencyId=${cId}&projectCurrencyId=${pId}&amount=${amount}`,
    methordType: 'get'
  });
};

export const deleteNonSpec = (id) => {
  return serviceHandler({
    url: `${NonSpecLine}?id=${id}`,
    methordType: 'delete'
  });
};
