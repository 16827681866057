import React from 'react';
import InputType1 from '../../../../../common/InputType1';
import { editableFields } from './constants';

const InfoEditPage = (props) => {
  const { yardInfo, setYardInfo } = props;
  const onYardInfoUpdate = (e, field) => {
    const currentYardInfo = { ...yardInfo };
    if (field.type === 'number') {
      currentYardInfo[e.target.name] = e.target.value.replace(/[^0-9 )(+-]/g, '').substring(0, 15);
    } else if (field.type === 'mail') {
      currentYardInfo[e.target.name] = e.target.value.substring(0, 64);
    } else {
      currentYardInfo[e.target.name] = e.target.value;
    }
    setYardInfo(currentYardInfo);
  };
  return (
    <div>
      {
            editableFields.map((item) => {
              return (
                <div className="mt-4">
                  <InputType1
                    label={item.label}
                    id={item.apiKey}
                    placeholder={item.label}
                    name={item.apiKey}
                    value={yardInfo[item.apiKey]}
                    onChange={(e) => onYardInfoUpdate(e, item)}
                  />
                </div>
              );
            })
        }
    </div>
  );
};
export default InfoEditPage;
