import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const UploadCard = (props) => {
  const { file, setFile, onUpload, ondrop } = props;

  return (
    <div className="card p-4 mt-4  border-brand" data-testid="upload-card">
      <div className="row">
        <div className="col-4 mt-4">
          <h3 className="mb-4">Upload New File</h3>
          <p>
            Please Browse to attach new file
          </p>
        </div>
        {!file ? (
          <div id="fileName-div" className="drag-drop-file my-4 file-waiting col-8">
            <input className="p-5 align-self-center" id="fileName" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
            <div className="d-flex row w-full">
              <div className="d-flex flex-column justify-content-center align-items-center col-6">
                <div className="icon-buble mb-2">
                  <span className="icon-upload-cloud" />
                </div>
                <p>Choose a file or drag it here</p>
                <div className="font-10">(File formats: jpeg, jpg, png, tiff, doc, pdf, .xlsx - Max size 5MB)</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-6">
                <div className="div_btn">
                  Browse File
                </div>
              </div>
            </div>
          </div>
        )
          : (
            <div id="fileName-div" className="drag-drop-file my-4 file-added col-8 d-flex align-items-center flex-row">
              <div className="font-18 d-flex col-7 align-items-center">
                <span className="icon-file-excel me-2 font-20"> </span>
                <div>
                  <span
                    data-tooltip-id="project-addRem"
                    data-tooltip-content={file.name}
                  >
                    {_.truncate(file.name, { length: 25 })}
                  </span>

                </div>
                <span className="icon-close ms-2 font-20 red" id="name" onClick={() => { setFile(''); }}> </span>
              </div>
              <div>
                <button type="button" id="upload" className="primary-btn" onClick={onUpload}>
                  Upload
                </button>
              </div>
            </div>
          )}
      </div>
      <Tooltip id="project-addRem" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default UploadCard;
