/* istanbul ignore file */
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PermissionDenied = () => {
  const navigate = useNavigate();
  return (
    <div className="access-denied">
      <div className="img" />
      <h1 className="mt-4">Access Denied</h1>
      <p className="mt-4">
        You don’t have permissions to access this page.
        <span className="d-block">Contact an administrator to get permission or go to the home page and browse other pages</span>
      </p>
      <div className="d-flex justify-content-center">
        <button type="button" className="primary-btn mt-4" onClick={() => navigate('/')}>
          Go to Home
        </button>
      </div>
    </div>
  );
};
export default PermissionDenied;
