import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../TSI/Header';
import UserList from './index';
import helper from '../../../../utils/helper';

const VesselLanding = () => {
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const { vesselId } = useParams();
  const [userCount, setUserCount] = useState(0);

  return (
    <div>
      <div className="header">
        <Header />
      </div>
      <div className="page-wrapper pt-3">
        <div className="sub-header" data-testid="user-permission">
          <div className="d-flex  justify-content-center">
            <div className="d-flex item-set col-gap-2">
              <button
                className="d-flex align-items-center p-2 rnd-btn active"
                type="button"
              >
                <span className="p-2 mt-1">User List</span>
                <span className="ms-1 py-1 count-bar">{userCount}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        <UserList vesselId={vesselId} setUserCount={setUserCount} isVesselUser={isVesselUser} />
      </div>
    </div>
  );
};

export default VesselLanding;
