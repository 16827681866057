import moment from 'moment';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import RecievedMsg from './recievedMsg';
import SentMsg from './sentMsg';
import { sendChatMessage } from './services';

const chatScreen = (props) => {
  const { getChatRecievedMessages,
    recieved, projInfo } = props;
  const { id } = useParams();
  const [send, setSend] = useState('');
  const [formData, setFormData] = useState({});

  const sendChatMessages = (e) => {
    const user = { ...formData };
    user.projectId = id;
    user.userId = localStorage.getItem('USER_ID');
    user.updatedAt = moment();
    if (e.key === 'Enter') {
      sendChatMessage(user).then((res) => {
        if (res.success) {
          setFormData(res.data.item);
          getChatRecievedMessages();
          setSend('');
        }
      });
    }
  };
  const handleChange = (e) => {
    setSend(e?.target?.value);
    const msg = { ...formData };
    msg.message = e?.target?.value?.trim();
    setFormData(msg);
  };

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [recieved]);

  return (
    <div>
      <div className="modal-body">
        {recieved?.map((item) => {
          if (localStorage.getItem('USER_ID') === item?.userId) {
            return <SentMsg send={item} />;
          }
          return <RecievedMsg item={item} />;
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className="modal-footer" data-testid="chat-screen">
        <div className="width-fit">
          <textarea
            id="textarea"
            cols="58"
            onChange={(e) => handleChange(e)}
            onKeyPress={(e) => { sendChatMessages(e); }}
            value={send}
            placeholder="Type Messages here..."
            required
            disabled={projInfo?.isClosed}
            style={{ resize: 'none', padding: '15px' }}
            className="text-box w-full h-text-area"
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            id="send"
            className="primary-btn "
            disabled={!formData?.message}
            onClick={() => {
              sendChatMessages({ key: 'Enter' });
              scrollToBottom();
              // scrollHeight(document.body.scrollHeight, 0);
            }}
          >
            Send

          </button>
        </div>
      </div>
    </div>
  );
};

export default chatScreen;
