/* istanbul ignore file */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import ModalBox from '../../../../common/ModalBox';
import helper from '../../../../../utils/helper';

const AddTags = ({
  tagList,
  state,
  setstate,
  activeModalData,
  saveForm,
  isTadAdding,
  stIsTadAdding,
  deleteData,
  heading,
  closeModal,
  isAddNewTagModal,
  setIsAddNewTagModal,
  isAddNewTagModalDelete,
  setIsAddNewTagModalDelete,
  modalClassName = 'medium'
}) => {
  const { handleFormValidation } = helper;
  const tableTagData = activeModalData?.tagList || [];
  if (isAddNewTagModalDelete) {
    return (
      <ModalBox
        modalClassName="medium"
        heading="Delete"
        closeModal={() => {
          setIsAddNewTagModalDelete('');
        }}
        buttonName="Delete"
        onSave={() => deleteData(isAddNewTagModalDelete)}
      >
        <p>Are you sure you want to delete tag?</p>
      </ModalBox>
    );
  }
  return (
    <div className="back-drop" data-testid="mt-add-tags">
      <div className={`modal ${modalClassName}`}>
        <div className="modal-header">
          <h3 className="">
            {heading}
            <span className="tag-count">{tableTagData?.length}</span>
          </h3>
          <button type="button" className="link-btn" onClick={closeModal}>
            <span className="icon-close font-26"> </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-content p-3 scroll-wrap">
            <div className="row border-down py-2">
              <div className="col-10 t-head">NAME</div>
              <div className="col-2 t-head d-flex justify-content-center">ACTIONS</div>
            </div>
            {tableTagData
                && tableTagData.map((i) => {
                  return (
                    <div className="row border-down py-2">
                      <div className="col-10 text-ellipsis">
                        <span
                          data-tooltip-id="project-kfp"
                          data-tooltip-content={i.name}
                        >
                          {i.name}
                        </span>

                      </div>
                      {/* <div className="col-3">{i.shortName}</div> */}
                      <div className="col-2 d-flex justify-content-center">
                        <span onClick={() => setIsAddNewTagModalDelete(i)} className="icon-delete font-24 brand-color cursor-pointer" />
                      </div>
                    </div>
                  );
                })}
            {!tableTagData || (!tableTagData.length && <div className="d-flex justify-content-center border-down py-2">No rows found</div>)}
          </div>
        </div>

        <div className="modal-footer">
          {isAddNewTagModal ? (
            <div className="bg-blue md-radius py-2 row">
              <div
                className="col-7 ps-4"
                data-tooltip-id="project-kfp"
                data-tooltip-content={state?.name || ''}
              >
                <Select
                  autoFocus
                  menuPosition="fixed"
                  value={state}
                  onChange={(option) => {
                    setstate(option);
                    handleFormValidation('id', option, 'Code');
                  }}
                  className="modal-select"
                  label="Code"
                  options={tagList}
                  id="Code"
                />
              </div>
              <div className="col-3 pt-4">
                <div className="flex-row">
                  <button
                    id="testcancel"
                    className="link-btn me-5"
                    type="button"
                    onClick={() => {
                      setstate({ id: '' });
                      setIsAddNewTagModal(false);
                    }}
                  >
                    <span className="icon-close me-1" />
                    Cancel
                  </button>
                  <button
                    disabled={isTadAdding}
                    onClick={() => {
                      stIsTadAdding(true);
                      saveForm('tag');
                    }}
                    className="link-btn"
                    type="button"
                  >
                    <span className="icon-save me-1" />
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-lg-start py-2">
              <button className="link-btn" type="button" onClick={() => setIsAddNewTagModal(true)}>
                <span className="icon-plus-square me-2" />
                Add New Tag
              </button>
            </div>
          )}
        </div>
        <Tooltip id="project-kfp" place="top" className="tooltip mt-3" />
      </div>
    </div>
  );
};
export default AddTags;
