import React from 'react';
import { cloneDeep } from 'lodash';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const AddTags = ({ formData, setFormData, addTags, removeRow }) => {
  const { charValidation, handleValidation } = helper;
  const handleChange = (e, field, key) => {
    const fd = cloneDeep(formData);
    fd[key][field] = charValidation(e.target.value);
    setFormData(fd);
    handleValidation(['name'], fd, field);
  };
  return (
    <>
      <div className="" data-testid="create-tag">
        {formData.map((cList, key) => {
          return (
            <div className="d-flex mt-4">
              <div className="col-10 label-space">
                <InputType1
                  autoFocus
                  id="name"
                  value={cList?.name}
                  onChange={(e) => handleChange(e, 'name', key)}
                  className="modal-input"
                  label="Name"
                  isMandatory="true"
                />
              </div>
              <div className="col-1 p-2rem">
                <button className="link-btn mt-4" disabled={cList?.id} type="button" onClick={() => { removeRow(key); }}>
                  <span className="icon-delete font-24" />
                </button>
              </div>
            </div>

          );
        })}
        <button className="link-btn mt-5 " type="button" id="add" onClick={addTags}>
          <span className="icon-plus-square me-2" />
          Add More Tags
        </button>
      </div>
    </>
  );
};
export default AddTags;
