import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  GetProjectSpecImages,
  GalaryFolderListing,
  GalaryImageListing,
  SaveGalleryFolderDataUrl

} = apiEndPoints;

export const getProjectSpecImages = (payload) => {
  return serviceHandler({
    url: `${GetProjectSpecImages}`,
    methordType: 'post',
    payload
  });
};

export const getFolderListing = (payload) => {
  return serviceHandler({
    url: `${GalaryFolderListing}?projectId=${payload.projectId}&folderType=${payload.folderType}`,
    methordType: 'get',
    payload
  });
};

export const getGalleryImages = (payload) => {
  return serviceHandler({
    url: `${GalaryImageListing}?projectId=${payload.projectId}&specId=${payload.specId}`,
    methordType: 'get',
    payload
  });
};
export const SaveGalleryFolderData = (payload) => {
  return serviceHandler({
    url: `${SaveGalleryFolderDataUrl}`,
    methordType: 'post',
    payload
  });
};
export const getCustomFolderListing = (projectId, parentId) => {
  return serviceHandler({
    url: `${SaveGalleryFolderDataUrl}?projectId=${projectId}&parentId=${parentId}`,
    methordType: 'get'
  });
};

export const deleteCustomFolderListing = (id) => {
  return serviceHandler({
    url: `${SaveGalleryFolderDataUrl}?id=${id}`,
    methordType: 'delete'
  });
};
