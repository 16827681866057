import React, { useState } from 'react';
import _ from 'lodash';
import { Tooltip } from 'react-tooltip';

const WorkDoneReport = (props) => {
  const { specList } = props;
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  return (
    <div className="spc-map me-2" data-testid="work-report">
      <div className="d-flex border-down align-items-center mb-2 ps-3 py-3">
        {/* <div className="col-10 head-14 font-semibold">Spec Sections</div> */}
        {/* <div className="col-2 head-14 font-semibold d-flex pe-3">UOM</div> */}
      </div>
      {specList.length === 0 && <div className="gray ms-3">No Data</div>}
      {
            specList?.map((sec) => {
              return (
                <div className={(sec.id === mainSec && showsubSec) ? 'highlight-tende' : ''}>
                  <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                    <div className="col-1 d-flex align-items-center">
                      <span
                        id={sec.id}
                        className={sec.id === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                        onClick={() => { onSecOpen(sec.id, true); }}
                      />
                      <Tooltip id="workdone" place="top" className="tooltip mt-3" />
                      <span
                        data-tooltip-id="workdone"
                        data-tooltip-content={sec.number}
                      >
                        {_.truncate(sec.number, { length: 4 })}
                      </span>
                    </div>
                    <Tooltip id="workdonename" place="top" className="tooltip mt-3" />
                    <div
                      className="col-9"
                    >
                      <span
                        data-tooltip-id="workdonename"
                        data-tooltip-content={sec.specName}
                      >
                        {_.truncate(sec.specName, { length: 80 })}
                      </span>

                    </div>
                    {/* <div className="col-2 d-flex pe-2">
                    {formatNumber(sec.yardInvoice) || '-'}</div> */}
                  </div>
                  {
                  sec.id === mainSec && sec.specs.map((subsec) => {
                    return (
                      <>
                        <div className="d-flex border-down py-2 ps-3 h-260 align-items-center">
                          <div className="col-1" />
                          <div className="col-9 d-flex align-items-center">
                            <span
                              id={subsec.id}
                              className="font-14 icon-sort-up brand-color rotate-90 me-2"
                            />
                            <span
                              className="me-4"
                              data-tooltip-id="workdone"
                              data-tooltip-content={subsec.number}
                            >
                              {subsec.number}
                            </span>
                            <Tooltip id="workdonesubname" place="top" className="tooltip mt-3" />
                            <div
                              className=""
                              data-tooltip-id="workdonesubname"
                              data-tooltip-content={subsec.specName}
                            >
                              {_.truncate(subsec.specName, { length: 80 })}

                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                }
                </div>
              );
            })
          }
    </div>
  );
};
export default WorkDoneReport;
