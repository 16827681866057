/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Pagination from '../../../common/pagination';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';
import PolicyFooter from '../../policyFooter';
import CardWrapper from './cardWrapper';
import { yardCostSummary } from './service';

const YardCostHistory = () => {
  const userRole = localStorage.getItem('USER_ROLE');
  const [yardCostSummaryList, setYardCostSummaryList] = useState({});
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [count, setCount] = useOutletContext() || '';
  // const isSearchUpdated = false;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const reloadTable = (page) => {
    yardCostSummary({
      page: page || currentpage,
      pageSize,
      search: searchText }).then((res) => {
      setLoading(false);
      if (res.success) {
        setYardCostSummaryList(res.data);
        setCount(res.data.totalItems || 0);
      } else {
        setCount('');
      }
    });
  };
  useEffect(() => {
    reloadTable();
  }, [pageSize, currentpage]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
      // isSearchUpdated = true;
      // yardCostSummary({ search: isSearchUpdated ? searchText : '' }).then((res) => {
      //   if (res.success && res.data) {
      //     setYardCostSummaryList(res.data);
      //     setCount(res.data.length);
      //   }
      // });
    }
  };

  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };

  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };

  useEffect(() => {
    if (!searchText) {
      // yardCostSummary({ search: isSearchUpdated ? searchText : '' }).then((res) => {
      //   if (res.success && res.data) {
      //     setYardCostSummaryList(res.data);
      //     setCount(res.data.length);
      //   }
      // });
      reloadTable(1);
    }
  }, [searchText]);

  return (
    <>
      <div className="container" data-testid="yard-cost-history">
        <div className="d-flex my-4 justify-content-between align-items-center">
          <div className="d-flex col-gap-1 spec-search">
            <div className="search large">
              <div className="d-flex justify-content-end">
                <span
                  className={searchText ? ' icon-close-line ' : ''}
                  onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                  id="search-icon-close"
                />
                <span
                  className="icon-search"
                  onClick={() => {
                    onSearch({ key: 'Enter' });
                  }}
                  id="search-icon"
                />
              </div>
              <input
                type="text"
                className="text-box large with-close"
                placeholder="Search by Project Name"
                id="search"
                value={searchText}
                onKeyPress={onSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="scorll-body-wrap">

          <CardWrapper
            userRole={userRole}
            yardCostSummaryList={yardCostSummaryList}
            loading={loading}
          />

          {yardCostSummaryList?.totalItems > 10 && (
          <Pagination
            pageClick={pageClick}
            pageCount={yardCostSummaryList.totalPages}
            currentpage={currentpage}
            records={yardCostSummaryList.totalItems}
            size={pageSize}
            onSizeChange={onSizeChange}
          />
          )}
        </div>

        {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
        )}
        {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
        )}
      </div>

      {userRole === 'Admin' ? (
        <div className={yardCostSummaryList?.items?.length === 0 ? 'sticky-footer' : 'non-sticky-footer'}>
          <div className="container">
            <div className="d-flex justify-content-between font-13 mx-0 my-2">
              <PolicyFooter
                setShowPrivacyPolicy={setShowPrivacyPolicy}
                setShowTermsPolicy={setShowTermsPolicy}
              />
            </div>
          </div>

        </div>
      ) : (
        <div className="footer justify-content-between font-13">
          <div className="copy-text m-auto">
            © 2024 ThinkPalm
          </div>

        </div>
      )}
    </>
  );
};
export default YardCostHistory;
