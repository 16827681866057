import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  getFleetListUrl,
  saveFleetListUrl,
  deleteFleetListUrl,
  getManagementListUrl,
  getVesselListUrl
} = apiEndPoints;

export const getFleetList = (payload) => {
  return serviceHandler({
    url: `${getFleetListUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveFleetList = (payload) => {
  return serviceHandler({
    url: `${saveFleetListUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteFleetList = (payload) => {
  return serviceHandler({
    url: `${deleteFleetListUrl}?Id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const getManagementList = (payload) => {
  return serviceHandler({
    url: `${getManagementListUrl}`,
    methordType: 'get',
    payload
  });
};
export const getVesselList = (managementId, fleetId) => {
  return serviceHandler({
    url: `${getVesselListUrl}?managementId=${managementId}&fleetId=${fleetId}`,
    methordType: 'get'
  });
};
