import React from 'react';

const Stepper = (props) => {
  const { view, setView, specList, ratings, projInfo } = props;
  return (
    <div className="d-flex justify-content-end" data-testid="Stepper">
      <div className="progressbar-container">
        <ul className="progressbar">
          {specList.status === 0 && (
          <>
            <li className={view === 1 ? 'is-active step' : 'step'}>
              <span className="d-flex whitespace-pre">Project Cost Collation</span>
            </li>
            <li className="step">
              <span>Yard Evaluation</span>
            </li>
            <li className="step">
              <span>Documents</span>
            </li>
          </>
          )}
          {specList.status === 1 && ratings?.status !== 1 && (
          <>
            <li className="step is-active is-done" onClick={() => { setView(1); }}>
              <span className="d-flex whitespace-pre">Project Cost Collation</span>
            </li>
            <li className={view === 2 ? 'is-active step' : 'step'} onClick={() => { setView(2); }}>
              <span>Yard Evaluation</span>
            </li>
            <li className="step ">
              <span>Documents</span>
            </li>
          </>
          )}
          {ratings?.status === 1 && (
            <>
              <li className="step is-active is-done" id="coll" onClick={() => { setView(1); }}>
                <span className="d-flex whitespace-pre">Project Cost Collation</span>
              </li>
              <li className="step is-active is-done" id="eval" onClick={() => { setView(2); }}>
                <span>Yard Evaluation</span>
              </li>
              {projInfo?.closeApprovalStatus === 2 ? (
                <li className="step is-active is-done " id="docs" onClick={() => { setView(3); }}>
                  <span>Documents</span>
                </li>
              ) : (
                <li className="step is-active " id="docs" onClick={() => { setView(3); }}>
                  <span>Documents</span>
                </li>
              )}
            </>
          )}

        </ul>
      </div>

    </div>
  );
};

export default Stepper;
