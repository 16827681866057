import React from 'react';

const Navbar = (props) => {
  const { onSearch, visibleSpec, searchText,
    changeVisibility, setSearchText, workOrder, showSearchCount } = props;
  return (
    <div>
      <div className="d-flex  align-items-center" data-testid="wodefect-nav">

        <div className="search spec-search large">
          <div className="d-flex justify-content-end">
            <span
              className={searchText ? ' icon-close-line ' : ''}
              onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
            />
            <span className="icon-search" id="Searching" onClick={() => { onSearch({ key: 'Enter' }); }} />
          </div>
          <input
            type="text"
            placeholder="Search by Name, WO/Defect No."
            id="search"
            value={searchText}
            className="text-box large with-close"
            onKeyPress={onSearch}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>

        <div className="ms-5 mt-2 check-box">
          <input type="checkbox" id="Work Orders" name="" checked={visibleSpec.indexOf('0') > -1} onClick={() => { changeVisibility('0'); }} />
          <label htmlFor="Work Orders">Work Orders(WO)</label>
        </div>
        <div className="ms-5 mt-2 check-box">
          <input type="checkbox" id="Defects" name="" checked={visibleSpec.indexOf('1') > -1} onClick={() => { changeVisibility('1'); }} />
          <label htmlFor="Defects">Defects</label>
        </div>

      </div>
      {showSearchCount
      && (
      <div className="d-flex mt-3">
        <div>{workOrder.length}</div>
        <p className="ms-2 font-16">Result(s) Found</p>
      </div>
      )}

    </div>

  );
};

export default Navbar;
