/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Sidebar from './Sidebar';
import ModalBox from '../../../common/ModalBox';
import AddRole from './addRole';
import WorkFLowSetup from './workFLowSetup';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import {
  saveRole, getRoleList, deleteRole, getPermission, getWorkflow, saveWorkflow, savePermission,
  getManagementGroupShortList
} from './services';
import Loader from '../../../common/loader';
import PermissionList from './PermissionList';

const Roles = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { formValidation, ZERO_UUID } = helper;
  const [showModal, setShowModal] = useState(false);
  const [showWFModal, setShowWFModal] = useState(false);
  const [showPModal, setShowPModal] = useState(false);
  const [currentRole, setCurrentRole] = useState('');
  const [roleList, setRoleList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [workFlowList, setWorkFlowList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [managementList, setManagementList] = useState([]);
  const [management, setManagement] = useState(ZERO_UUID);
  const [defaultWorkFlow, setDefaultWorkFlow] = useState([]);

  useEffect(() => {
    setLoading(true);
  }, []);

  const initialFormData = {
    name: '',
    type: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const vesselId = localStorage.getItem('vesselId');
  const getRole = () => {
    getRoleList(vesselId || ZERO_UUID).then((res) => {
      if (res.success) {
        setLoading(false);
        setRoleList(res.data);
        setCurrentRole(res.data[0]?.id);
      }
    });
  };

  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  /* istanbul ignore next */
  const onSave = () => {
    if (formValidation(['name', 'type'], formData)) {
      saveRole(formData).then((res) => {
        if (res.success) {
          setShowModal(false);
          const message = formData?.id ? 'Successfully Updated' : 'Successfully Saved ';
          showAlert(message, 'success');
          getRole();
          setFormData(initialFormData);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const workFlowData = () => {
    getWorkflow(management || ZERO_UUID).then((res) => {
      if (res.success) {
        setWorkFlowList(res.data);
        if (management === ZERO_UUID) setDefaultWorkFlow(res.data);
      } else {
        setWorkFlowList(workFlowList.map((item) => ({
          ...item,
          firstApprover: ZERO_UUID,
          firstApproverName: '',
          secondApprover: ZERO_UUID,
          secondApproverName: '',
          managementGroupId: ZERO_UUID
        })));
      }
    });
  };
  /* istanbul ignore next */
  useEffect(() => {
    getRole();
    workFlowData();
    if (setCount) {
      setCount('');
    }
  }, [management, showWFModal]);

  useEffect(() => {
    getPermission({ id: currentRole || ZERO_UUID }).then((permisn) => {
      if (permisn.data) {
        setPermissions(permisn.data);
      }
    });
  }, [currentRole]);

  const onAddRole = () => {
    setFormData(initialFormData);
    setShowModal(true);
  };

  const showWorkFlow = () => {
    setShowWFModal(true);
  };

  const showPermissions = () => {
    setShowPModal(true);
  };

  const onClose = () => {
    setShowPModal(false);
  };

  const onEditClick = (data) => {
    setShowModal(true);
    setFormData(data);
  };

  const deleteRoleApiCall = () => {
    deleteRole({ id: formData.id }).then((res) => {
      if (res.success) {
        setFormData(initialFormData);
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
        getRole();
      }
    });
  };
  const onSelectRole = (field) => {
    setCurrentRole(field.id);
  };
  const onSavePermissionApiCall = () => {
    savePermission({ roleId: currentRole, data: permissions }).then((res) => {
      if (res.data) {
        showAlert('Successfully Confirmed', 'success');
        setOpenPermissionModal(false);
      }
    });
  };
  const onSaveWorkFLow = () => {
    workFlowList.forEach((item) => item.managementGroupId = management);
    saveWorkflow(workFlowList).then((resp) => {
      if (resp.success) {
        setManagement(ZERO_UUID);
        setShowWFModal(false);
        showAlert('Successfully Saved', 'success');
      }
    });
  };

  useEffect(() => {
    getManagementGroupShortList({
      page: 1,
      pageSize: 500
    }).then((res) => {
      if (res.data) {
        const temp = res.data.map(({ id, name }) => ({ value: id, label: name }));
        setManagementList(temp);
      }
    });
  }, []);

  return (
    <div data-testid="role-listing">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Sidebar
            sidebarType="specification-setup"
            onAddRole={onAddRole}
            currentRole={currentRole}
            setCurrentRole={setCurrentRole}
            onEditClick={onEditClick}
            roleList={roleList}
            deleteRole={deteleData}
            onSelectRole={onSelectRole}
            showWorkFlow={showWorkFlow}
            showPermissions={showPermissions}
          />
          <div>
            <div>
              {showModal && (
                <ModalBox
                  heading={formData?.id ? 'Edit Role' : 'Add New Role'}
                  modalClassName="medium"
                  closeModal={() => {
                    setShowModal(false);
                    setFormData(initialFormData);
                  }}
                  buttonName={formData?.id ? 'Update' : 'Save'}
                  onSave={() => onSave()}
                >
                  <AddRole formData={formData} setFormData={setFormData} />
                </ModalBox>
              )}
              {showWFModal && (
                <div className="modal-height">
                  <ModalBox
                    heading="Workflow Approval Setup"
                    closeModal={() => {
                      setShowWFModal(false);
                      setManagement(ZERO_UUID);
                    }}
                    size="large"
                    buttonName="Save"
                    onSave={() => { onSaveWorkFLow(); }}
                  >
                    <WorkFLowSetup
                      workFlowList={workFlowList}
                      setWorkFlowList={setWorkFlowList}
                      roleList={roleList}
                      managementList={managementList}
                      management={management}
                      setManagement={setManagement}
                      ZERO_UUID={ZERO_UUID}
                      defaultWorkFlow={defaultWorkFlow}
                    />
                  </ModalBox>
                </div>
              )}
              {openDeleteModal ? (
                <ModalBox
                  modalClassName="medium"
                  heading="Delete"
                  onSave={() => deleteRoleApiCall()}
                  closeModal={() => setOpenDeleteModal(false)}
                  buttonName="Delete"
                >
                  <p>Are you sure you want to delete Role ?</p>
                </ModalBox>
              ) : (
                ''
              )}
              {openPermissionModal ? (
                <ModalBox
                  modalClassName="medium"
                  heading="Confirm"
                  onSave={() => onSavePermissionApiCall()}
                  closeModal={() => setOpenPermissionModal(false)}
                  buttonName="Confirm"
                >
                  <div>
                    <div>
                      On confirming, the selected permissions
                      will be applied to the application users.
                    </div>
                    <div>Do you want to save the selections?</div>
                  </div>
                </ModalBox>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
      <PermissionList showPModal={showPModal} onClose={onClose} />
    </div>
  );
};
export default Roles;
