/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useOutletContext, useParams } from 'react-router-dom';
import SidePanel from '../../../../../../../common/sidePanel';
import { importPrevProjSpecs, getSpecLibraryList, ImportExcel } from '../../../services';
import SpecList from '../../../SpecsList';
import AddToMainListModal from './AddToMainListModal';
import JobListing from './jobListing';
import SpecListing from './specListing';
import helper from '../../../../../../../../utils/helper';
import ModalBox from '../../../../../../../common/ModalBox';
import ImportRFQ from '../../../../Tender/ReceivedQuotes/importRFQ';
import showAlert from '../../../../../../../../utils/alert';

const SpecLibrary = (props) => {
  const { roles, MAX_FILE_SIZE } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const { onClose, showView, onSave, onCardSelect, selectedCard,
    isTsiDisabled, getPrevProjectSpecList, prevProjItems, prevListInView, setPrevListInView,
    setPrevProjItems, projectId, onReload, isTSI } = props;
  const [cardDetails, setCardDetails] = useState([]);
  const [eyeView, setEyeView] = useState(false);
  const [back, setBack] = useState(false);
  const [showData, setShowData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [mainList, setMainList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [viewSpec, setViewSpec] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState();
  const [finalFilteredArray, setFinalFilteredArray] = useState([]);
  const isSidePanel = false;
  const [closeModal, setCloseModal] = useState(false);
  const [file, setFile] = useState('');
  const { id } = useParams();

  const updateCardDetails = () => {
    getSpecLibraryList({ id: projectId, search: '' }).then((res) => {
      if (res.data) {
        setCardDetails(res.data);
      }
    });
  };

  /* istanbul ignore next */
  const importExcel = () => {
    const body = new FormData();
    body.append('file', file);
    if (file) {
      ImportExcel(id, body).then((res) => {
        if (res.success) {
          setPrevListInView(res.data);
          showAlert('Successfully Imported', 'success');
          setCloseModal(false);
          setFile('');
          updateCardDetails();
        }
      });
    } else {
      showAlert('Please select a file to upload', 'error');
    }
  };

  let isSearchUpdated = false;
  useEffect(() => {
    getSpecLibraryList({ id: projectId, search: isSearchUpdated ? searchText : '' }).then((res) => {
      setLoading(false);
      if (res.data) {
        setCardDetails(res.data);
      }
    });
  }, []);

  /* istanbul ignore next */
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };

  /* istanbul ignore next */
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearchUpdated = true;
      getSpecLibraryList({ id: projectId, search: isSearchUpdated ? searchText : '' }).then((res) => {
        if (res.data) {
          setCardDetails(res.data);
        }
      });
    }
  };

  const updateView = () => {
    setShowData(!showData);
  };

  const handleEyeView = (items) => {
    setEyeView(true);
    setBack(false);
    setPrevProjItems({
      projectName: items?.projectName,
      fleetName: items?.fleetName,
      dDdate: items?.dDdate,
      projectId: items?.projectId,
      projectFullName: items?.projectFullName
    });
  };

  const addToMainBtn = () => {
    setConfirmModal(true);
  };

  /* istanbul ignore next */
  const AddToMainListBtnChange = () => {
    importPrevProjSpecs({
      currentProjectId: projectId,
      prevProjectSpecIds: finalFilteredArray
    }).then((res) => {
      if (res.success) {
        onReload();
        setFinalFilteredArray([]);
      }
    });
  };

  useEffect(() => {
    if (!searchText) {
      getSpecLibraryList({ id: projectId, search: isSearchUpdated ? searchText : '' }).then((res) => {
        if (res.data) {
          setCardDetails(res.data);
        }
      });
    }
  }, [searchText]);

  const viewMore = (data) => {
    setViewSpec(true);
    setSelectedSpec(data);
  };

  const viewMoreClose = () => {
    setSelectedSpec({});
    setViewSpec(false);
  };

  const {
    projInfo
  } = useOutletContext() || { projInfo: {} };

  /* istanbul ignore next */
  const ondrop = async (e) => {
    const acceptedFiles = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        setFile(first);
        setLoading(false);
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
    e.target.value = '';
  };
  return (
    <div data-testid="speclibrary">
      {closeModal && (
        <ModalBox
          heading="Import Specs"
          closeModal={() => {
            setCloseModal(false);
            setFile('');
          }}
          buttonName="Import"
          modalClassName="medium modal-scroll"
          onSave={() => { importExcel(); }}
        >
          <ImportRFQ ondrop={ondrop} setFile={setFile} file={file} />
        </ModalBox>
      )}
      {(showView || back) && (
        <SidePanel
          title="Import Specs from Library"
          onClose={onClose}
          showView={showView}
          onSave={onSave}
          isTsiDisabled={isTsiDisabled}
          classHeader="header_sidepanel ps-3 side-panel-header-spec-library-prev closeSpec"
          bgColor="#fff"
        >
          <div className="sample-side-panel-content">
            <SpecListing
              onCardSelect={onCardSelect}
              selectedCard={selectedCard}
              projectId={projectId}
              cardDetails={cardDetails}
              setEyeView={setEyeView}
              setBack={setBack}
              handleEyeView={handleEyeView}
              searchText={searchText}
              setSearchText={setSearchText}
              isSearchUpdated={isSearchUpdated}
              onSearch={onSearch}
              isVesselUser={isVesselUser}
              setCloseModal={setCloseModal}
              isTsiDisabled={isTsiDisabled}
            />
          </div>
        </SidePanel>
      )}

      {viewSpec ? (
        <SidePanel
          title={(
            <span className="cursor-pointer" data-tooltip-id="project-more" data-tooltip-content={`${selectedSpec.specificationNo}. ${selectedSpec.title}`}>
              {`${selectedSpec.specificationNo}. ${_.truncate(selectedSpec.title, { length: 40 })}`}
            </span>
          )}
          size="medium"
          onClose={() => viewMoreClose()}
          showView="true"
          backColorReduced
        >
          <Tooltip id="project-more" place="bottom" className="tooltip ms-3" />
          <SpecList
            isSidePanel={isSidePanel}
            isTSI={isTSI}
            projectId={projectId}
            isSidebar
            isTsiDisabled={isTsiDisabled}
            specDetails={selectedSpec}
            selectedSpec={selectedSpec.id || ''}
            fromSpecLibrary
          />
        </SidePanel>
      ) : (eyeView) && !back && (
        <SidePanel
          title={(
            <div className="">
              <div>Import Specs</div>
            </div>
          )}
          onClose={() => { setEyeView(false); setMainList(''); }}
          importSpecBtn="Import Spec"
          showView={eyeView}
          bgColor="#fff"
          onSave={addToMainBtn}
          specJobMainList={finalFilteredArray}
          projInfo={projInfo}
        >
          <JobListing
            setEyeView={setEyeView}
            setBack={setBack}
            updateView={updateView}
            showData={showData}
            data={prevListInView}
            onSecOpen={onSecOpen}
            mainSec={mainSec}
            showsubSec={showsubSec}
            setMainList={setMainList}
            mainList={mainList}
            getPrevProjectSpecList={getPrevProjectSpecList}
            eyeView={eyeView}
            setFlag={setFlag}
            flag={flag}
            id={id}
            prevProjItems={prevProjItems}
            viewMore={viewMore}
            viewMoreClose={viewMoreClose}
            viewSpec={viewSpec}
            setViewSpec={setViewSpec}
            setFinalFilteredArray={setFinalFilteredArray}
            loading={loading}
            finalFilteredArray={finalFilteredArray}
          />
          {confirmModal && (
            <AddToMainListModal
              setConfirmModal={setConfirmModal}
              setEyeView={setEyeView}
              onClose={onClose}
              AddToMainListBtnChange={AddToMainListBtnChange}
            />
          )}
        </SidePanel>
      )}
    </div>
  );
};

export default SpecLibrary;
