import React from 'react';
import { cloneDeep } from 'lodash';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const CreateAcCode = ({ formData, setFormData }) => {
  const { charValidation, handleValidation } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = charValidation(e.target.value);
    setFormData(fd);
    handleValidation(['uomName', 'unit'], fd, field);
  };
  return (
    <>
      <div className="" data-testid="create-uom">
        <div className="row">
          <div className="col-4">
            <InputType1 autoFocus id="uomName" name="uomName" onChange={(e) => handleChange(e, 'uomName')} value={formData.uomName} className="modal-inputs" label="Unit Name" isMandatory="true" />
          </div>
          <div className="col-8">
            <InputType1 id="unit" name="unit" onChange={(e) => handleChange(e, 'unit')} value={formData.unit} className="modal-inputs" label="Units" isMandatory="true" />
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateAcCode;
