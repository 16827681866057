/* eslint-disable max-len */
import React from 'react';
import Loader from '../../../common/loader';
import InnerTable from './InnerTable';

const ComponentDetails = ({ formData, loading }) => {
  console.log(formData);
  return (
    <div className="side-panel__container" data-testid="com-1">
      <div className="side-panel__content">
        {loading
          ? <Loader />
          : (
            <div>
              <>
                {formData && formData.length
                  ? <InnerTable innerTableData={formData} />
                  : <div className="d-flex justify-content-center pt-5">No data found</div>}
              </>
            </div>
          )}
      </div>
    </div>
  );
};
export default ComponentDetails;
