/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectDetailsTSI, getProjectAttachments, sendForApproval, getProjectHasApprover } from '../services';
import ConfirmButton from './ConfirmButton';
import ModalBox from '../../../../common/ModalBox';
import MajorSpecPackagesList from '../../../../Admin/DataConfig/SpecificationSetup/SpecPackages/MajorSpecPackagesList';
import { fileImages } from '../../../../common/mappingConst';
import helper from '../../../../../utils/helper';
import PreConfirm from './PreConfirm';
import Comments from '../../Comments';
import vesselThmd from '../../../../../images/vessel-thmd.jpg';

const Confirm = (props) => {
  const navigate = useNavigate();
  const { handleActiveComponent, projectId, surveyInfo, isTsiDisabled } = props;
  const [projectDetails, setProjectDetails] = useState();
  const [attachments, setAttatchments] = useState();
  const [isOpened, setIsOpened] = useState(false);
  const [isPreOpened, setIsPreOpened] = useState(false);
  const [IsemergencyDryDock, setIsEmergencyDryDock] = useState(false);
  const [isExpand, setIsExpand] = useState('');
  const [loading, setLoading] = useState(false);
  const { formatBytes, projectPhase, formatNumber } = helper;
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  const getName = (resubmit = false) => {
    let n = 'Submit';
    if (resubmit) {
      n = 'Resubmit';
    }
    if (hasApprover) {
      n += ' for Approval';
    }
    return n;
  };
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(projectId, type)?.then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  const backtoProjects = () => {
    navigate('/user/projects');
  };
  const getDetails = () => {
    getProjectDetailsTSI({ id: projectId }).then((response) => {
      if (response?.success) {
        setProjectDetails(response?.data);
        if (response?.data?.projectType?.toLowerCase()?.includes('emergency')) {
          setIsEmergencyDryDock(true);
        } else {
          setIsEmergencyDryDock(false);
        }
      }
    });
  };
  const getAllAttachments = () => {
    getProjectAttachments({ id: projectId, phase: 1, isLifoOrder: false }).then((response) => {
      if (response?.success) {
        setAttatchments(response?.data);
      }
    });
  };
  useEffect(() => {
    if (projectId) {
      getDetails();
      getAllAttachments();
      window.scrollTo(0, 0);
    }
  }, []);
  const confirmModal = () => {
    setLoading(true);
    console.log('CONfirm');
    sendForApproval(projectId, projectPhase.PLAN).then((response) => {
      setLoading(false);
      if (response?.success) {
        setIsOpened(true);
        setIsPreOpened(false);
      }
    });
  };
  return (
    <div className="col-8 card m-auto p-3em mb-5" data-testid="confirmpage">
      <h1 className="main-head">
        <span> Confirm </span>
        <span className="opacity-6" data-tooltip-id="project-page" data-tooltip-content={projectDetails?.name}>
          {projectDetails?.name}
        </span>
        <span> Details</span>
      </h1>
      <div className="border px-5 py-4 lg-radius my-4">
        <h1 className="head-16 mb-3">Vessel Details</h1>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex">
            <img className="thmb-md ship-thumb" src={projectDetails?.imageUrl || vesselThmd} alt="" />
            <div className="d-flex flex-column">
              <h3 className="head-16 opacity-6">{projectDetails?.vesselName}</h3>
              <p className="mt-1">{projectDetails?.vesselSubType}</p>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex">
              <label> DD Due Date :</label>
              <div className="value ms-2">{projectDetails?.ddDudate ? moment(projectDetails?.ddDudate).format('DD-MMM-YYYY') : '-'}</div>
            </div>
            <div className="d-flex mt-2">
              <label> Previous DD Date :</label>
              <div className="value ms-2">{projectDetails?.previousDdDudate ? moment(projectDetails?.previousDdDudate).format('DD-MMM-YYYY') : '-'}</div>
            </div>
          </div>
          <button
            type="button"
            className="secondary-btn mt-2"
            id="details"
            onClick={() => {
              handleActiveComponent('vessel-details');
            }}
          >
            More info
          </button>
        </div>
      </div>
      <div className="border p-5 lg-radius my-4">
        <div className="d-flex justify-content-between">
          <h1 className="head-16">Project Details</h1>
          <div className="d-flex">
            <button
              type="button"
              disabled={isTsiDisabled}
              className="secondary-btn "
              id="edit"
              onClick={() => {
                handleActiveComponent('project-details');
              }}
            >
              <span className="icon-edit me-2" />
              Edit
            </button>
          </div>
        </div>
        <div className="mt-3 d-flex">
          <label className="col-6">Project Type :</label>
          <div className="value">{projectDetails?.projectType}</div>
        </div>
        {projectDetails?.surveyType && (
          <div className="mt-3 d-flex">
            <label className="col-6">Survey Type :</label>
            <div className="value">{projectDetails?.surveyType}</div>
          </div>
        )}
        {projectDetails?.vesselMappedUsers?.map((i) => (
          <div className="mt-3 d-flex">
            <label className="col-6 d-flex">
              {i.roleName}
              <div className="ms-1">:</div>
            </label>
            <div className="value">{i.userName}</div>
          </div>
        ))}

        <div className="mt-3 d-flex">
          <label className="col-6">Estimated Amount :</label>
          <div className="value head-14 d-flex">
            {projectDetails?.currencySymbol}
            {' '}
            {formatNumber(projectDetails?.estimatedBudget)}
          </div>
        </div>
        <div className="mt-3 d-flex">
          <label className="col-6">Drydocking Due Date :</label>
          <div className="value">{projectDetails?.ddDudate ? moment(projectDetails?.ddDudate).format('DD-MMM-YYYY') : '-'}</div>
        </div>
        <div className=" mt-3 d-flex">
          <label className="col-6">Special Survey Due Date :</label>
          <div className="value">{moment(surveyInfo?.specialSurveyDueDate).format(' DD-MMM-YYYY')}</div>
        </div>
        <div className="mt-3 d-flex">
          <label className="col-6">Tailshaft Survey Due Date :</label>
          <div className="value">{moment(surveyInfo?.tailshaftSurveyDueDate).format('DD-MMM-YYYY')}</div>
        </div>
        <div className="mt-3 mb-5 d-flex">
          <label className="col-6">Intermediate Hull Survey Due Date :</label>
          <div className="value">{moment(surveyInfo?.intermediateSurveyDueDate).format('DD-MMM-YYYY')}</div>
        </div>
        {projectDetails?.projectJobList.length > 0 && !IsemergencyDryDock && (
          <div className="mb-5 pt-5">
            <h4 className="head-14 mb-4">List of jobs to be carried out</h4>
            {projectDetails?.projectJobList.map((element) => {
              return (
                <div className="d-flex mt-4" key={element.id}>
                  <span className="icon-check-rnd tick-green font-26" />
                  <p className="ms-4 head-black">{element?.title}</p>
                </div>
              );
            })}
          </div>
        )}
        {projectDetails?.specificationList.length > 0 && IsemergencyDryDock && (
          <div className="mb-3 pt-5">
            <p className="head-14">GT specs - Emergency Dry Docking</p>
            <MajorSpecPackagesList
              isExpand={isExpand}
              formDataToFillList={projectDetails?.specificationList}
              setIsExpand={setIsExpand}
              from="confirm"
            />
          </div>
        )}
      </div>
      <div className="table-responsive mb-5">
        <table className="card-table">
          <thead>
            <tr>
              <th>
                <div className="d-flex justify-content-between ">
                  <h4 className="head-16 mb-3">Vessel Plans and Drawings</h4>
                  <button
                    type="button"
                    disabled={isTsiDisabled}
                    className="secondary-btn"
                    id="delete"
                    onClick={() => {
                      handleActiveComponent('attachments');
                    }}
                  >
                    <span className="icon-edit me-2" />
                    Edit
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <div className="attachment-read-only">
              {attachments?.map((item) => {
                return (
                  <>
                    <div className="border-down">
                      <div className="d-flex flex-column m-4">
                        <span className="head-14 mb-1 text-ellipsis" data-tooltip-id="project-page" data-tooltip-content={item?.documentCategoryName}>
                          {_.truncate(item?.documentCategoryName, {
                            length: 50,
                            separator: ' '
                          })}
                        </span>
                      </div>
                      <div className="ps-5 my-3">
                        {item?.projectAttachments?.map((field) => {
                          return (
                            <div className="ms-4 pt-3 pb-3 pe-3">
                              <div className="d-flex justify-content-between full-width align-items-center">
                                <div className="d-flex col-gap-1">
                                  <div className="thmb-icon-md">
                                    <span className={fileImages[_.toLower(field.fileType)]} />
                                  </div>
                                  <div className="d-flex flex-column justify-content-center">
                                    <a
                                      href={field.physicalFileName}
                                      target="_blank"
                                      download="downloaded_file"
                                      className=""
                                      rel="noreferrer"
                                    >
                                      <span className="head-14 mb-1 text-ellipsis" data-tooltip-id="project-page" data-tooltip-content={field?.name}>
                                        {_.truncate(field.name, {
                                          length: 50,
                                          separator: ' '
                                        })}
                                      </span>
                                    </a>

                                    <div className="font-16">
                                      <label className="me-2">Format :</label>
                                      <span className="value">{field.fileType}</span>
                                      <span className="mx-4">|</span>
                                      <label className="me-2"> Size :</label>
                                      <span className="value">{formatBytes(field.fileSize)}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between font-semibold mt-5">
        {projectId && projectDetails?.planApprovalStatus === 3
          ? <Comments id={projectId} />
          : <div />}
        <div className="d-flex justify-content-end ">
          <button type="button" className="secondary-btn large me-4" onClick={backtoProjects}>
            Cancel
          </button>
          <button
            type="button"
            className="secondary-btn large me-4"
            onClick={() => {
              handleActiveComponent('attachments');
            }}
          >
            Previous
          </button>
          <button
            type="button"
            disabled={isTsiDisabled}
            className="primary-btn large"
            onClick={() => {
              setIsPreOpened(true);
            }}
          >
            {projectDetails?.planApprovalStatus === 3 ? getName(true) : getName()}
          </button>
          {isOpened ? (
            <ModalBox isCloseModal={false} modalClassName="medium modal-scroll">
              <ConfirmButton
                projectId={projectId}
                showCreateMsg="create"
                name={projectDetails?.name}
                nameTooltip={projectDetails?.nameTooltip}
                projectDetails={projectDetails}
              />
            </ModalBox>
          ) : (
            ''
          )}
          {isPreOpened ? (
            <ModalBox isCloseModal={false} modalClassName="medium modal-scroll">
              <PreConfirm
                projectId={projectId}
                name={projectDetails?.name}
                nameTooltip={projectDetails?.nameTooltip}
                confirmModal={confirmModal}
                setIsPreOpened={setIsPreOpened}
                loading={loading}
                projectDetails={projectDetails}
              />
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      </div>
      <Tooltip id="project-page" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Confirm;
