import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../../../common/InputType1';
import ImageUpload from './imageUpload';
import tiff from '../../../../../../images/tiff.png';

const AddImages = (props) => {
  const { newImage, onRemoveImg, setNewImage } = props;
  const onFieldChange = (e) => {
    const currentImage = { ...newImage };
    currentImage[e.target.name] = e.target.value;
    setNewImage(currentImage);
  };
  return (
    <div className="card p-5">
      <InputType1
        autoFocus
        label="Name"
        isMandatory="true"
        id="name"
        name="imageName"
        onChange={(e) => {
          onFieldChange(e);
        }}
        value={newImage.imageName}
      />
      <ImageUpload {...props} />

      {newImage?.imageUrlList?.length > 0 && <label>Selected Images</label>}

      <div className="gimage_wrap pb-4">
        {newImage?.imageUrlList.map((item, key) => {
          let imageNameToDisplay = newImage.imageName;
          if (newImage?.imageUrlList?.length > 1) {
            imageNameToDisplay += '-';
          }
          if (newImage?.imageUrlList.length > 1) {
            imageNameToDisplay += key + 1;
          }
          return (
            <div className="gimage_view mb-5">
              {item.imageUrl.includes('.tiff') || item.imageUrl.includes('.tif')
                ? (
                  <img src={tiff} alt="img" className="" />
                ) : <img src={item.imageUrl} alt="img" className="" />}
              <div className="d-flex">
                <div
                  className="d-flex"
                  data-tooltip-id="project-jobprogress"
                  data-tooltip-content={imageNameToDisplay}
                >
                  {_.truncate(newImage.imageName, {
                    length: 14,
                    separator: ' '
                  })}
                  {newImage?.imageUrlList?.length > 1 && (
                    <div>
                      -
                      {newImage?.imageUrlList.length > 1 ? key + 1 : ''}
                    </div>
                  )}
                </div>
              </div>
              <span
                className="font-22 icon-close brand-color"
                id={key}
                onClick={() => {
                  onRemoveImg(key);
                }}
              />
            </div>
          );
        })}
        <div />
      </div>
      <label>Description</label>
      <span className="text-primaryRed-1">*</span>
      <textarea
        label="Description"
        isMandatory="true"
        id="desc"
        name="imageDescription"
        onChange={(e) => {
          onFieldChange(e);
        }}
        className="text-box py-3 w-full h-text-area"
      >
        {newImage.imageDescription}
      </textarea>
      <Tooltip id="project-jobprogress" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddImages;
