/* istanbul ignore file */
/* eslint-disable */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const SubmitView = (props) => {
  const navigate = useNavigate();
  const { projectDetails, message } = props;
  let x;
  const backtoProjects = () => {
    navigate(`/user/projects`);
  };
  const timer = () => {
    const countDownDate = new Date(moment().add(1, 'minutes')).getTime();
    x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById('countdownTimer').innerHTML = `${minutes}m ${seconds}s `;
      if (distance < 0) {
        clearInterval(x);
        backtoProjects();
      }
    }, 1000);
  };
  useEffect(() => {
    timer();
  }, []);
  useEffect(() => {
    return () => {
      clearInterval(x);
    };
  }, []);
  return (
    <div className="d-flex justify-content-center flex-column align-items-center mt-4" data-testid="sp-submit">
      <div className="capitalize font-semibold text-2xl">{projectDetails?.name}</div>
      <span className="icon-check-rnd tick-green font-50 pb-4 mt-4" />
      <span className="head-18">
        {message === 'revision' ?
          'Revision comment has been Submitted successfully  !!' :
          message === 'approve' ?
            'Approval comment has been Submitted successfully  !!' :
            'Yard Selection has been Sent successfully  !!'}
      </span>
      {message === 'sendforapproval' && <div className="mt-3"><p className="font-15 font-semibold">Yard Selection has been sent for the next level of approval process</p></div>}
      <div className="p-3"><button type="button" className="primary-btn large m-3 p-3" data-testid="back" onClick={backtoProjects}>Back to Projects</button></div>
      <div className="d-flex text-center ms-5">
        <p className="d-flex mt-2 ms-3">
          System will automatically be redirected into home page -within
          <div id="countdownTimer" className="ms-3 font-semibold" />
        </p>
      </div>
    </div>
  );
};
export default SubmitView;
