import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Loader from '../../common/loader2';

const PreConfirm = (props) => {
  const { name,
    nameTooltip,
    isOpened,
    setIsOpened,
    reason,
    setReason,
    confirmModal,
    // loading,
    cLoading
  } = props;
  if (cLoading) return <div data-testid="pre-com"><Loader /></div>;
  return (
    <div className="text-black d-flex flex-column" data-testid="pre-com">
      <div className="d-flex justify-content-center flex-column align-items-center mb-4">
        <span
          className="head-18"
          data-tooltip-id="project-dashboard"
          data-tooltip-content={nameTooltip}
        >
          {name}
        </span>
        <div className="ps-1 font-18 font-semibold">
          Project will be
          {isOpened === 'reject' && ' rejected '}
          {isOpened === 'revision' && ' submitted for revision '}
          {isOpened === 'approve' && ' approved '}
        </div>
      </div>
      <label className="mt-4 mb-2">
        Add Remarks
        {(isOpened === 'reject' || isOpened === 'revision') && <span className="text-primaryRed-1">*</span> }
      </label>
      <textarea
        id="reason"
        onChange={(e) => {
          setReason(e.target.value);
        }}
        type="text"
        name="reason"
        value={reason}
        className="text-box w-full h-text-area"
      >
        {reason}
      </textarea>
      <div className="d-flex justify-content-end">
        <button type="button" className="secondary-btn large m-3 p-3" id="backtoprojects" onClick={() => setIsOpened(false)}>
          Cancel
        </button>
        <button disabled={cLoading} type="button" className="primary-btn large m-3 p-3" id="savetoprojects" onClick={() => confirmModal()}>
          Submit
        </button>
      </div>
      <Tooltip id="project-dashboard" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default PreConfirm;
