/*eslint-disable*/
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import arrowRightG from './images/right-gray.svg';
import arrowDownG from './images/down-gray.svg';
import arrowRightB from './images/right-blue.svg';
import arrowDownB from './images/down-blue.svg';
import arrowRightP from './images/right-purple.svg';
import arrowDownP from './images/down-purple.svg';
import AddServiceLine from './AddServiceLine';

const CheckList = (props) => {
  const {
    checkList,
    setCheckList,
    compareData,
    setCompareData,
    generalData,
    SaveAssignee,
    specCount,
    isTSI,
    disableFields,
    newServiceLine,
    units,
    acCodes,
    newACCode,
    setnewACCode
  } = props;

  const status = [
    {
      name: 'Y',
      value: 1,
      title: 'Yard',
      selected: false,
    },
    {
      name: 'V',
      value: 2,
      title: 'Vessel',
      selected: false,
    },
    {
      name: 'S',
      value: 3,
      title: 'Sub Contractor',
      selected: false,
    },
  ];

  const [showSideBar, setShowSideBar] = useState(false);
  const [specData, setSpecData] = useState('');
  const [newSL, setNewSL] = useState({});

  const onClose = () => {
    setShowSideBar(false);
  };

  const assigneeCursor = (value) => {
    if (value) return ' cursor-pointer';
    else return ' cursor-auto';
  };

  return (
    <>
      <AddServiceLine
        showSideBar={showSideBar}
        onClose={onClose}
        specData={specData}
        newServiceLine={newServiceLine}
        units={units}
        acCodes={acCodes}
        newSL={newSL}
        setNewSL={setNewSL}
        newACCode={newACCode}
        setnewACCode={setnewACCode}
      />
      {generalData && checkList?.length > 0 && (
        <div className="data-container-cq mb-container text-xl w-35 mt-2 mb-3">
          <div className="d-flex">
            <div className="d-flex ms-4 mt-4 p-3 cl-head">
              <div className="font-semibold text-3xl font-navy w-full">Compare Quotation</div>
            </div>
            <div className="d-flex align-items-center mt-4 p-3 ms-auto me-4 cl-head">
              <button type="button" className="link-btn large ms-auto me-2" style={{ cursor: 'auto' }}>
                <span className="icon-Info brand-color font-18" />
              </button>
              <div className="font-blue mb-1">
                {compareData?.length > 0 ? compareData?.length : 0}
                {compareData?.length > 1 ? ' Quotes' : ' Quote'} Received
              </div>
            </div>
          </div>
          <div className="d-flex ms-4 p-3 text-md">
            <div className="font-medium">Project Currency : &nbsp;</div>
            <div className="font-dim">
              {generalData?.currency} ({generalData?.currencySymbol})
            </div>
          </div>
          <div className="horizontal-line-main bg-gray-cq mt-5r">
            <div className="font-bold p-cl-heading ms-1">SPEC SECTIONS</div>
          </div>
          <div>
            {checkList?.map((i, index) => {
              return (
                <div key={index} className={i.specificationNo === 'DEF' ? 'd-none' : ''}>
                  {index === specCount && (
                    <div className="horizontal-line-main font-gray bg-gray-cq">
                      <div className="ms-3 p-cl-heading font-bold">NON SPEC SECTIONS</div>
                    </div>
                  )}
                  <div key={index} className={checkList.length !== index - 1 ? 'horizontal-line-main' : ''}>
                    <div
                      className="d-flex p-cl ms-2 cursor-pointer"
                      data-test="memberClick"
                      onClick={() => {
                        const arr = cloneDeep(checkList);
                        const temp = cloneDeep(compareData);
                        temp.map((h) => {
                          h.specQuoteList.map((k, index1) => {
                            if (index === index1) {
                              if (!k.show) {
                                k.show = true;
                              } else {
                                k.show = false;
                                k?.specChild?.map((l) => {
                                  l.show = false;
                                });
                              }
                            } else {
                              k.show = false;
                              k?.specChild?.map((l) => {
                                l.show = false;
                              });
                            }
                          })
                        });
                        setCompareData(temp);
                        arr.map((k, ind) => {
                          if (index === ind) {
                            if (k.show) {
                              k.show = false;
                            } else {
                              k.show = true;
                            }
                          } else {
                            k.show = false;
                          }
                          if (k?.specDataList?.length > 0 && !k.show) {
                            k?.specDataList?.map((d) => {
                              d.show = false;
                            });
                          }
                        });
                        setCheckList(arr);
                      }}
                    >
                      <div>
                        {i.show ? <img className="icon-sm" src={arrowDownG} alt="flag" /> : <img className="icon-sm" src={arrowRightG} alt="flag" />}
                      </div>
                      <div className="ms-4 timeline-text-wrap w-8">
                        <span
                          data-tooltip-id="project-secName"
                          data-tooltip-content={i?.tempOrder ? i?.tempOrder : i?.specificationNo}
                          className="font-gray"
                        >
                          {i?.tempOrder ? i?.tempOrder : i?.specificationNo}
                        </span>
                        <Tooltip id="project-secName" place="top" className="tooltip  mt-3" />
                      </div>
                      <div className="timeline-text-wrap ms-2 w-3/4 ">
                        <span className="font-gray capitalize" data-tooltip-id="project-specNa" data-tooltip-content={i.title ? i.title : ''}>
                          {i?.title}
                        </span>
                        <Tooltip id="project-specNa" place="top" className="tooltip left-o mt-3" />
                      </div>
                    </div>
                    <div>
                      {i.show && (
                        <div>
                          {i?.specDataList &&
                            i?.specDataList?.map((j, ind) => {
                              return (
                                <div key={ind} className="horizontal-line">
                                  <div className="d-flex ms-2 p-cl">
                                    <div
                                      className="d-flex cursor-pointer w-3/4"
                                      data-test="memberClick1"
                                      onClick={() => {
                                        const arr = cloneDeep(checkList);
                                        const temp = cloneDeep(compareData);
                                        temp?.map((h, c) => {
                                          h.specQuoteList[index].specChild.map((gg, indx) => {
                                            if (ind === indx) {
                                              if (!gg.show) {
                                                gg.show = true;
                                              } else gg.show = false;
                                            } else {
                                              gg.show = false;
                                            }
                                          });
                                        });
                                        setCompareData(temp);
                                        arr[index].specDataList.map((tt, inn) => {
                                          if (ind === inn) {
                                            if (!tt.show) {
                                              tt.show = true;
                                            } else {
                                              tt.show = false;
                                            }
                                          } else {
                                            tt.show = false;
                                          }
                                        });
                                        setCheckList(arr);
                                      }}
                                    >
                                      {j.type !== 3 ? (
                                        <div>
                                          {j.show ? (
                                            j.serviceLineList ? (
                                              <img className="icon-sm ms-4" src={j.id < 200 ? arrowDownB : arrowDownP} alt="flag" />
                                            ) : (
                                              <div className="ml-sub"></div>
                                            )
                                          ) : j.serviceLineList ? (
                                            <img className="icon-sm ms-4" src={j.id < 200 ? arrowRightB : arrowRightP} alt="flag" />
                                          ) : (
                                            <div className="ml-sub"></div>
                                          )}
                                        </div>
                                      ) : (
                                        <div className="ms-4">
                                          <div className="ms-4"></div>
                                        </div>
                                      )}
                                      <div className="font-gray timeline-text-wrap ms-4 w-6">
                                        <span data-tooltip-id="project-secNames" data-tooltip-content={j.specificationNo}>
                                          {j.specificationNo}
                                        </span>
                                        <Tooltip id="project-secNames" place="top" className="tooltip mt-4" />
                                      </div>
                                      <div className="ms-3 timeline-text-wrap w-2/3">
                                        <span data-tooltip-id="project-specName" data-tooltip-content={j.title ? j.title : ''}>
                                          {j.title}
                                        </span>
                                        <Tooltip id="project-specName" place="top" className="tooltip mt-4 spec-o" />
                                      </div>
                                    </div>
                                    {j?.type !== 3 && (
                                      <div className="d-flex gap-2 justify-content-end ms-auto">
                                        {status?.map((g, f) => {
                                          return (
                                            <div
                                              key={f}
                                              className={
                                                g?.value === j.specAssigned
                                                  ? 'assignee-bg-blue' + assigneeCursor(isTSI && !disableFields)
                                                  : 'assignee-bg-gray' + assigneeCursor(isTSI && !disableFields)
                                              }
                                              data-test="memberClick2"
                                              onClick={() => {
                                                if (isTSI && !disableFields && g?.value !== j.specAssigned) {
                                                  const y = cloneDeep(checkList);
                                                  y[index].specDataList[ind].specAssigned = g.value;
                                                  setCheckList(y);
                                                  const data = {
                                                    specId: j.projectSpecId,
                                                    Assign: g.value,
                                                  };
                                                  SaveAssignee(data);
                                                  const l = cloneDeep(compareData);
                                                  l.map((q) => {
                                                    q.specQuoteList.map((r) => {
                                                      r.specChild.map((s) => {
                                                        if (s.id === j.projectSpecId) {
                                                          s.specAssigned = g.value;
                                                          if (g.value === 2 || g.value === 3) {
                                                            q.ownerEstimate = q.ownerEstimate - s.ownerEstimate;
                                                            r.ownerEstimate = r.ownerEstimate - s.ownerEstimate;
                                                            s.ownerEstimate = 0;
                                                          }
                                                          s.specChild.map((t) => {
                                                            if (g.value === 2 || g.value === 3) {
                                                              t.ownerEstimate = 0;
                                                              t.formula = '';
                                                            }
                                                          });
                                                        }
                                                      });
                                                    });
                                                  });
                                                  setCompareData(l);
                                                }
                                              }}
                                            >
                                              {g?.name ? g?.name : ''}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    {j.show && (
                                      <div>
                                        {j.serviceLineList.map((h, l) => {
                                          return (
                                            <>
                                              <div key={l} className="horizontal-line" style={{ background: h.color }}>
                                                <div className="d-flex p-cl ml-10">
                                                  <div className="font-dim timeline-text-wrap ms-4 w-1/3">
                                                    <span
                                                      data-tooltip-id="project-sevname"
                                                      data-tooltip-content={h.number}
                                                    >
                                                      {h.number}
                                                    </span>
                                                    <Tooltip id="project-sevname" place="top" className="tooltip mt-4" />
                                                  </div>
                                                  <div className="ms-3 font-dim timeline-text-wrap capitalize w-full">
                                                    <span
                                                      data-tooltip-id="project-servname"
                                                      data-tooltip-content={h.serviceLineName ? h.serviceLineName : ''}
                                                    >
                                                      {h.serviceLineName}
                                                    </span>
                                                  </div>
                                                  <Tooltip id="project-servname" place="top" className="tooltip mt-4 spec-i" />
                                                </div>
                                              </div>
                                              {j?.serviceLineList?.length === l + 1 && (
                                                <div className="border-t">
                                                  <div className="ms-3 d-flex padding-new-service-line font-semibold">
                                                    <div
                                                      className={isTSI && !disableFields ? 'd-flex ms-auto ms-2' : 'd-flex ms-auto ms-2'}
                                                      data-test="newSLClick"
                                                      onClick={() => {
                                                        if (isTSI && !disableFields) {
                                                          setShowSideBar(true);
                                                          setSpecData(j);
                                                        }
                                                      }}
                                                    >
                                                      <button type="button" className={isTSI && !disableFields ? 'link-btn' : 'link-btn cursor-auto'}>
                                                        Add New Service Line
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="text-uppercase">
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="ms-3 padding-checklist-non-spec font-bold">COST SUMMARY</div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="d-flex">
                <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Yard Cost Estimate Without Discount</div>
                <span
                  className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                  data-tooltip-id="project-Discount"
                  data-tooltip-content="Sum of Yard Estimate Column"
                />
                <Tooltip id="project-Discount" place="top" className="tooltip mt-4 " />
              </div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Overall Discount (%)</div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Additional Discount</div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="d-flex">
                <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Yard Final Bill Estimate</div>
                <span
                  className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                  data-tooltip-id="project-Discount"
                  data-tooltip-content="Yard Cost Estimate Without Discount - Overall Discount(%) - Additional Discount"
                />
              </div>
            </div>
            <div className="horizontal-line-main">
              <div className="padding-blank"></div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="d-flex">
                <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Owner's Cost Estimate</div>
                <span
                  className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                  data-tooltip-id="project-Discount"
                  data-tooltip-content="Sum of Owner's Cost Column"
                />
              </div>
            </div>
            <div className="horizontal-line-main">
              <div className="padding-blank"></div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Deviation Cost</div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Off Hire</div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq">
              <div className="d-flex">
                <div className="ms-3 padding-checklist-non-spec font-semibold cl-head">Deviation & Off Hire Estimate</div>
                <span
                  className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                  data-tooltip-id="project-Discount"
                  data-tooltip-content="Deviation Cost + Off Hire"
                />
              </div>
            </div>
            <div className="horizontal-line-main">
              <div className="padding-blank"></div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq text-xl">
              <div className="d-flex">
                <div className="ms-3 padding-checklist-non-spec font-bold cl-head">Project Cost Estimate(W/O Deviation & Off Hire)</div>
                <span
                  className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                  data-tooltip-id="project-Discount"
                  data-tooltip-content="Yard Final Bill Estimate + Owner's Cost Estimate"
                />
              </div>
            </div>
            <div className="horizontal-line-main font-gray bg-gray-cq text-xl">
              <div className="d-flex">
                <div className="ms-3 padding-checklist-non-spec font-bold cl-head">Project Cost Estimate(W Deviation & Off Hire)</div>
                <span
                  className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                  data-tooltip-id="project-Discount"
                  data-tooltip-content="Yard Final Bill Estimate + Owner's Cost Estimate + Deviation & Off Hire Estimate"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckList;
