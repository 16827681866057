import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { getProjectHasApprover } from '../../../NewProject/services';

const ApproveSendConfirm = (props) => {
  const { id, setSuccessView, projInfo, successView, status } = props;
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">
      <div className="head-18 pb-4">
        <span className="white-space-preserve" data-tooltip-id="project-cost" data-tooltip-content={projInfo?.name}>
          {projInfo?.name}
        </span>

      </div>
      <div>
        <span className="icon-check-rnd tick-green font-50 pb-5" />
      </div>
      <div className="mt-3">
        <p className="head-18 font-semibold">
          {status === 3 ? (
            <p className="font-15 font-semibold">Successfully Resend for Revision</p>
          ) : (
            <>
              {hasApprover ? (
                <p className="font-15 font-semibold">
                  {successView === 3
                    ? 'Successfully Resend for approval'
                    : 'Successfully Sent for approval'}
                </p>
              ) : (
                <p className="font-15 font-semibold">
                  {successView === 3
                    ? 'Successfully Resumbitted'
                    : 'Successfully Submitted'}
                </p>
              )}
            </>
          )}
        </p>
      </div>
      <div className="mt-3">
        {status === 3
          ? (
            <p className="font-15 font-semibold"> Revision comment has been submitted successfully</p>
          ) : (
            <p className="font-15 font-semibold">{hasApprover ? 'Project has been sent to the next level for approval' : ''}</p>
          )}

      </div>
      <div className="d-flex">
        <button type="button" className="primary-btn large m-3 p-3" id="back2projects" onClick={() => setSuccessView(false)}>
          Close
        </button>
      </div>
      <Tooltip id="project-close" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ApproveSendConfirm;
