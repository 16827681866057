import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectRepairData } from '../services';

const NavBar = ({
  projectBasicDetails
}) => {
  const { id } = useParams();
  const initialData = {
    projectId: id,
    lastPort: '',
    lastPortDistance: 0,
    nextPort: '',
    nextPortDistance: 0,
    repairStartDate: '',
    hireRate: 0,
    fuelPrice: 0,
    fuelConsumption: 0,
    fuelCost: 0,
    repairDays: 0,
    deviationDays: 0,
    deviationOffhire: 0,
    offhireDays: 0,
    hireRateLocal: 0,
    fuelPriceLocal: 0,
    fuelConsumptionLocal: 0,
    fuelCostLocal: 0,
    deviationOffhireLocal: 0,
    yardName: '',
    yardLocation: '',
    totalRepairDays: 0
  };
  const [formData, setFormData] = useState(initialData);
  useEffect(() => {
    if (id) {
      getProjectRepairData(id).then((res) => {
        if (res.data) {
          setFormData(res.data);
        }
      });
    }
  }, [id, projectBasicDetails]);
  return (
    <div className="d-flex justify-content-between" data-testid="navbar">
      <div className="d-flex pt-3 pb-3 align-items-center">
        <div className="align-items-center me-3">
          <span className="icon-yard brand-color large  md-radius bg-white p-3 font-35 " />
        </div>
        <div className="ps-3">
          <div className="head-16">{formData?.yardName}</div>
          <div className="d-flex">
            <div>{formData?.yardLocation}</div>
            <div className="align-items-center bg-white d-flex font-13 justify-content-center ms-4 px-3 text-content-grey">
              <span className="">
                Currency
                <span className="ms-2">:</span>
                &nbsp;
              </span>
              <span className="">{projectBasicDetails?.currency}</span>
              <span className="">{` ( ${projectBasicDetails?.currencySymbol} ) `}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-flex align-items-center">
          <div className="head-12 me-3">Total Repair Days</div>
          <div className="bg-green font-14 white px-3 py-1 align-items-center md-radius">
            <div className="d-flex align-items-center">
              <div className="font-22">
                {formData?.totalRepairDays}
                /
              </div>
              <div className="font-14 mt-2">{formData?.repairDays}</div>
            </div>
            <div className="font-12">Days</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
