import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const AddRemarks = (props) => {
  const { name, setRemarks, remarks, approveClick, nameTooltip, buttonText, CompareQuote } = props;
  return (
    <div className="d-flex flex-column" data-testid="remarks">
      <div className="d-flex justify-content-center flex-column align-items-center mb-4">
        <span
          className="head-18 "
          data-tooltip-id="project-addRem"
          data-tooltip-content={nameTooltip}
        >
          {name}
        </span>
        <div className="ps-1 font-18 font-semibold">
          {!CompareQuote ? 'Supplementary specs will be' : 'Yard Selection will be'}
          {buttonText === 'reject' && ' rejected '}
          {buttonText === 'revision' && ' submitted for revision '}
          {buttonText === 'approve' && ' approved '}
        </div>
      </div>
      <div className="p-3">
        <label className="mt-4 mb-2">
          Add Remarks
          {!approveClick && (
          <span className="text-primaryRed-1 m-2">*</span>
          )}
        </label>
        <textarea
          onChange={(e) => { setRemarks(e.target.value); }}
          type="text"
          name="remarks"
          id="remarkstext"
          value={remarks}
          className="text-box w-full h-text-area"
        >
          {remarks}
        </textarea>
      </div>
      <Tooltip id="project-addRem" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddRemarks;
