import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import UploadCard from './uploadCard';
import { yardS3Upload } from '../service';
import helper from '../../../../utils/helper';
// import { fileImages } from '../../../common/mappingConst';
import showAlert from '../../../../utils/alert';

const Attachments = (props) => {
  const { showAttach, specList, setSpecList } = props;
  const [file, setFile] = useState('');
  const { formatBytes } = helper;

  const onUpload = () => {
    const body = new FormData();
    body.append('file', file);
    const specListData = { ...specList };
    const docList = specListData.sectionData[showAttach.key1]
      .specDataList[showAttach.key2].specDocuments;
    let isDuplicate = false;
    docList.map((item) => {
      if (item.fname === file?.name) {
        isDuplicate = true;
      }
      return null;
    });
    if (isDuplicate) {
      showAlert('File already exists', 'error');
    } else {
      yardS3Upload(body).then((res) => {
        if (res.success) {
          showAlert('Successfully uploaded', 'success');
          specListData.sectionData[showAttach.key1]
            .specDataList[showAttach.key2].specDocuments.unshift({ path: res.data,
              fname: file?.name,
              size: file?.size });
          setSpecList(specListData);
        }
      });
    }
    setFile('');
  };

  return (
    <div data-testid="yard-attach">
      {specList?.rfqData?.status !== 4
      && <UploadCard {...props} onUpload={onUpload} file={file} setFile={setFile} />}
      <div>
        {
        specList.sectionData[showAttach.key1]?.specDataList[showAttach.key2]
          ?.specDocuments?.map((item) => {
            return (
              <div className="card p-3 mt-3 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="thmb-icon-md">
                    <span className="icon-file-excel"> </span>
                    {/* <span className={fileImages[_.toLower(item.type)]} /> */}
                  </div>
                  <div className="ps-4">
                    <a
                      href={item.physicalFileName}
                      target="_blank"
                      download="downloaded_file"
                      className=""
                      rel="noreferrer"
                    >
                      <div className="value">
                        <span
                          data-tooltip-id="project-attachment"
                          data-tooltip-content={item.fname || item.name}
                        >
                          {' '}
                          {_.truncate(item?.fname || item.name, {
                            length: 40,
                            separator: ''
                          })}

                        </span>

                      </div>
                    </a>

                    <div className="d-flex text-ellipsis">
                      Size:
                      <p className="value">
                        {' '}
                        {formatBytes(item.size)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
        <Tooltip id="project-attachment" place="top" className="tooltip mt-3" />
      </div>
    </div>
  );
};
export default Attachments;
