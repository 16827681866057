import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const SpecTable = (props) => {
  const { tableData, deteleData, editData } = props;

  const column = [
    {
      Header: 'Package Name',
      accessor: 'packageName',
      sortable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            className="text-ellipsis text-uppercase"
            data-tooltip-id="project-packageName"
            data-tooltip-content={original.packageName}
          >
            {original.packageName}
          </span>
        );
      }
    },
    {
      Header: 'Project Type',
      sortable: false,
      accessor: 'projectTypeName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            className="text-ellipsis text-uppercase"
            data-tooltip-id="project-projectTypeName"
            data-tooltip-content={original.projectTypeName}
          >
            {original.projectTypeName}
          </span>
        );
      }
    },
    {
      Header: 'Vessel Type',
      sortable: false,
      accessor: 'vesselTypeName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            className="text-ellipsis text-uppercase"
            data-tooltip-id="project-projectTypeName"
            data-tooltip-content={original.vesselTypeName}
          >
            {original.vesselTypeName}
          </span>
        );
      }
    },
    {
      Header: 'Age Of The Vessel',
      width: 150,
      sortable: false,
      accessor: 'ageOfVessel',
      Cell: (row) => {
        const { original } = row;
        return (
          <span>
            { `${original.ageOfVessel} Years`}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      sortable: false,
      accessor: 'actions',
      width: 70,
      Cell: (row) => {
        const { original } = row;
        return (
          <div>
            <button
              type="button"
              className="link-btn"
              id="edit"
              onClick={() => {
                editData(original);
              }}
            >
              <span className="icon-edit icon-btn  brand-color cursor-pointer font-24" />
            </button>
            {' '}
            <button
              type="button"
              className="link-btn ms-3"
              onClick={() => {
                deteleData(original);
              }}
            >
              <span className="icon-delete icon-btn  brand-color cursor-pointer ms-3 font-24" />
            </button>
          </div>
        );
      }
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="spec-table">
      <ReactTable
        className="table table-scroll"
        data={tableData?.items}
        columns={column}
        minRows={0}
        showPagination={false}
      />
      <Tooltip id="project-packageName" place="top" className="tooltip m-4" />
      <Tooltip id="project-projectTypeName" place="top" className="tooltip m-4" />
      <Tooltip id="project-vesselTypeName" place="top" className="tooltip m-4" />
    </div>
  );
};
export default SpecTable;
