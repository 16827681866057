/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-autofocus */
/*eslint-disable*/
import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';
import InnerTable1 from './InnerTable1';
import showAlert from '../../../../../utils/alert';

const TableOne = ({
  SpecificationType,
  SpecificationLevel,
  setFormData,
  formData,
  expandConfig,
  showPagination,
  uomList,
  tagList,
  cancelAddNewSection,
  handleSaveScetion,
  cancelEditSection,
  editData,
  setExpandConfig,
  handleTableChange,
  editIndex,
  subElementId,
  setSubElementId,
  reloadTable,
  getEmptySpecificationApi,
  loadingSave
}) => {
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [indexValue, setIndexValue] = useState('');

  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: true,
      resizable: false,
      width: 50,
      Expander: ({ isExpanded, original, index }) => (isExpanded ? (
        <span
          onClick={() => {
            setSubElementId('');
            const quotesCopy = cloneDeep(formData);
            const expandedRows = quotesCopy?.map(() => {
              return false;
            });
            setExpandConfig(expandedRows);
          }}
          id={original.specificationNo}
          className="font-16 icon-arrow-thin-right circle-arrow rotate-90 "
        />
      ) : (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(formData);
            const expandedRows = quotesCopy?.map((data, key) => {
              if (index === key) return true;
              return false;
            });
            setExpandConfig(expandedRows);
            setTimeout(() => {
              const element = document.getElementById(original.specificationNo);
              element?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }, 20);
          }}
          id={original.specificationNo}
          className="font-16 icon-arrow-thin-right circle-arrow"
        />
      ))
    },
    {
      Header: 'SEC #',
      accessor: 'specificationNo',
      sortable: false,
      width: 108,
      Cell: (row) => {
        const { original } = row;
        if (original.isNew) {
          return (
            <input
              id="new-specificationNo"
              data-tooltip-id="project-SEC"
              data-tooltip-content={original.specificationNo}
              type="number"
              min="1"
              className="text-box w-100"
              value={original.specificationNo}
              onChange={(e) => handleTableChange(e, 'specificationNo', original)}
            />
          );
        }
        return (
          <span data-tooltip-id="project-SEC" data-tooltip-content={original.specificationNo}>
            {original.specificationNo}
          </span>
        );
      }
    },
    {
      Header: 'SPEC SECTION',
      accessor: 'title',
      sortable: false,
      Cell: (row) => {
        const { original, index } = row;
        if (original.isNew) {
          return (
            // eslint-disable-next-line jsx-a11y/no-autofocus
            <input
              id="new-title"
              autoFocus
              className="text-box c-w-full"
              value={original.title}
              onChange={(e) => handleTableChange(e, 'title', original)}
            />
          );
        }
        if (editIndex === index) {
          return (
            // eslint-disable-next-line jsx-a11y/no-autofocus
            <input
              id="new-title"
              autoFocus
              className="text-box c-w-full"
              value={original.title}
              onChange={(e) => handleTableChange(e, 'title', original)}
            />
          );
        }
        return (
          <span className="text-ellipsis text-uppercase" data-tooltip-id="project-SPECSECTION" data-tooltip-content={original.title}>
            {original.title}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: 'packageName',
      sortable: false,
      width: 180,
      Cell: (row) => {
        const { original, index } = row;
        if (original.isNew) {
          return (
            <div className="flex-row">
              <button id="testcancel" className="link-btn me-5" type="button" onClick={() => cancelAddNewSection()}>
                <span className="icon-close me-1" />
                Cancel
              </button>
              <button disabled={loadingSave} onClick={() => handleSaveScetion(original)} className="link-btn" type="button">
                <span className="icon-save me-1" />
                Save
              </button>
            </div>
          );

        }
        if (editIndex === index) {
          return (
            <div className="flex-row">
              <button id="testcancel" className="link-btn me-5" type="button" onClick={() => cancelEditSection()}>
                <span className="icon-close me-1" />
                Cancel
              </button>
              <button onClick={() => handleSaveScetion(original)} className="link-btn" type="button">
                <span className="icon-save me-1" />
                Save
              </button>
            </div>
          );
        }
        return (
          <div>
            <button
              type="button"
              className="link-btn"
              onClick={() => {
                editData(row);
              }}
            >
              <span className="icon-edit icon-btn  brand-color cursor-pointer font-24" />
            </button>

            {/* <button
              type="button"
              className="link-btn ms-3"
              onClick={() => {
                setOpenDeleteModal(true);
                setIndexValue(row.index);
              }}
            >
              <span className="icon-delete icon-btn  brand-color cursor-pointer font-24" />
            </button> */}
          </div>
        );
      }
    }
  ];

  return (
    <>
      <div data-testid="mt-tab-1">
        <ReactTable
          className="table-scroll gt-extraheight"
          data={formData}
          columns={columns}
          pageSize={formData && formData.length > 0 ? formData.length : 1}
          showPagination={showPagination}
          expanded={expandConfig}
          SubComponent={(mainProp) => {
            const { specArray, id, specificationNo } = mainProp.original;
            return (
              <InnerTable1
                SpecificationType={SpecificationType}
                SpecificationLevel={SpecificationLevel}
                parentSpecificationNo={specificationNo}
                parentSpecificationId={id}
                setFormData={setFormData}
                formData={formData}
                specArray={specArray}
                mainProp={mainProp}
                expandConfig={expandConfig}
                showPagination={showPagination}
                uomList={uomList}
                tagList={tagList}
                subElementId={subElementId}
                setSubElementId={setSubElementId}
                reloadTable={reloadTable}
                getEmptySpecificationApi={getEmptySpecificationApi}
              />
            );
          }}
        />
        <Tooltip id="project-SEC" place="top" className="tooltip " />
        <Tooltip id="project-SPECSECTION" place="top" className="tooltip  me-5" />
      </div>
    </>
  );
};
export default TableOne;
