import React, { useEffect, useState } from 'react';
import { cloneDeep, groupBy, toNumber } from 'lodash';
import { getMajorSpecs } from './services';
import ServiceLineTable from './ServiceLineTable';
import helper from '../../../../../utils/helper';
import Loader2 from '../../../../common/loader2';

const InnerTable2 = ({
  serviceLines,
  showPagination,
  setFormData,
  formData,
  parentSpecificationId,
  SpecificationType,
  SpecificationLevel,
  cancel,
  handleSaveScetion,
  isRefreshService,
  indexTosave,
  ZERO_UUID,
  uomList,
  setOpenKfpModal,
  setstate,
  charValidation,
  editIndex,
  loadingSave,
  setOpenDltModal
}) => {
  const [loading, setLoading] = useState(false);
  const { handleFormValidation } = helper;
  const groupedKfp = groupBy(serviceLines, (p) => p.specificationLevel);
  let indexToEdit;
  if (editIndex) indexToEdit = true;
  if (editIndex === 0) indexToEdit = true;

  const handleTableChange = (e, field, saveData) => {
    const temp = cloneDeep(serviceLines);
    temp.forEach((i, index) => {
      if (i.specificationNo === saveData.specificationNo) {
        temp[index][field] = charValidation(e.target.value, 150);
        handleFormValidation('title', temp[index], `new-title-${saveData.specificationNo}`);
      }
    });
    const tempArray = cloneDeep(formData);
    tempArray.forEach((element, index) => {
      if (element.id === parentSpecificationId) {
        tempArray[index].serviceLines = temp;
      }
    });
    setFormData(tempArray);
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  const addServiceLine = () => {
    const serviceLinesData = groupedKfp[3]?.length ? groupedKfp[3] : [];
    const temp = cloneDeep(serviceLinesData);
    const tempService = serviceLines && serviceLines.length ? cloneDeep(serviceLines) : [];

    let num;
    let nextNum;
    if (SpecificationType === 2) {
      // For Major Spec
      num = temp.length ? temp[temp.length - 1].specificationNo : `${formData[indexTosave].specificationNo}.0`;
      const decimalStr = num.toString().split('.');
      nextNum = `${decimalStr[0]}.${toNumber(decimalStr[1]) + 1}`;
    } else {
      num = temp.length ? temp[temp.length - 1].specificationNo : `${formData[indexTosave].specificationNo}.0`;
      const decimalStr = num.toString().split('.');
      nextNum = `${decimalStr[0]}.${decimalStr[1]}.${toNumber(decimalStr[2]) + 1}`;
    }

    tempService.push({
      isNew: true,
      parentSpecificationId,
      specificationNo: nextNum,
      title: '',
      description: '',
      specificationLevel: SpecificationLevel.ServiceLine,
      applicableVesselType: [],
      tags: [],
      type: SpecificationType,
      uomName: '',
      quanityEnterBy: '',
      status: 0
    });
    const tempArray = cloneDeep(formData);
    tempArray.forEach((element, index) => {
      if (element.id === parentSpecificationId) {
        tempArray[index].serviceLines = tempService;
      }
    });
    setFormData(tempArray);
  };
  const reloadTableService = () => {
    getMajorSpecs(parentSpecificationId, SpecificationType).then((res) => {
      const tempArray = cloneDeep(formData);
      tempArray[indexTosave].serviceLines = res.data?.length ? res.data : [];
      setFormData(tempArray);
      setLoading(false);
    });
  };
  /* istanbul ignore next */
  useEffect(() => {
    if (parentSpecificationId !== ZERO_UUID) {
      reloadTableService();
    } else {
      const tempArray = cloneDeep(formData);
      tempArray[indexTosave].serviceLines = [];
      setFormData(tempArray);
      setLoading(false);
    }
  }, [parentSpecificationId, isRefreshService]);
  const showHeader = true;
  return (
    <div data-testid="mt-innertable2" className="">
      {loading
        && (
          <Loader2 />
        )}
      <div className={(groupedKfp && groupedKfp[3]?.length) || (groupedKfp && groupedKfp[4]?.length) || indexToEdit ? 'service-sub-table service-sub-table-border' : 'service-sub-table'}>
        <div className={(groupedKfp && groupedKfp[3]?.length) || (groupedKfp && groupedKfp[4]?.length) || indexToEdit ? 'table-wrapper' : ''}>
          {groupedKfp && groupedKfp[3]?.length ? (
            <ServiceLineTable
              SpecificationType={SpecificationType}
              SpecificationLevel={SpecificationLevel}
              showPagination={showPagination}
              uomList={uomList}
              handleTableChange={handleTableChange}
              indexToEdit={indexToEdit}
              ZERO_UUID={ZERO_UUID}
              groupedKfp={groupedKfp[3]}
              showHeader={showHeader}
              setOpenDltModal={setOpenDltModal}
            />
          ) : (
            <div />
          )}

          {groupedKfp && groupedKfp[4]?.length ? (
            <ServiceLineTable
              SpecificationType={SpecificationType}
              SpecificationLevel={SpecificationLevel}
              showPagination={showPagination}
              uomList={uomList}
              handleTableChange={handleTableChange}
              indexToEdit={indexToEdit}
              ZERO_UUID={ZERO_UUID}
              groupedKfp={groupedKfp[4]}
              showHeader={false}
              setOpenDltModal={setOpenDltModal}
            />
          ) : (
            <div />
          )}
          {indexToEdit ? (
            <div className="d-flex justify-content-start spec-btn-wraper">
              <button id="add" className="link-btn me-lg-5" type="button" onClick={() => addServiceLine()}>
                <span className="icon-plus-square me-2" />
                Add More Service Line
              </button>
              {SpecificationType === 1 ? (
                <button
                  onClick={() => {
                    if (parentSpecificationId && parentSpecificationId !== ZERO_UUID) {
                      setOpenKfpModal(true);
                    }
                    setstate({ parentSpecificationId });
                  }}
                  className={parentSpecificationId && parentSpecificationId !== ZERO_UUID ? 'link-btn' : 'link-btn disabled'}
                  type="button"
                  id="kfp-btn"
                >
                  <span className="icon-plus-square me-2" />
                  KFP
                </button>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      {indexToEdit ? (
        <div className="d-flex justify-content-end col-gap-1 mb-4 me-4">
          <button id="cancel" className="secondary-btn" type="button" onClick={() => cancel()}>
            Cancel
          </button>
          <button disabled={loadingSave} id="save" className="primary-btn" type="button" onClick={() => handleSaveScetion(indexTosave)}>
            Save
          </button>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default InnerTable2;
