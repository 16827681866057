/* eslint-disable max-len */
import React from 'react';

function Caption() {
  return (
    <div className="caption">
      <h1>Fast and secure Drydocking Solution</h1>
      <h4>
        We help in digitalizing the dry-docking process and gives our users a higher operational efficiency by automating a major part of the work
        involved, thereby reducing the overall timeline. Our system captures and maintains data intelligently thereby reducing chances of delay
        and deviations.
      </h4>

      {/* <button className="primary-btn xlarge col-4  my-3 icon-disable " type="button">
        View Tour
      </button> */}
    </div>
  );
}
export default Caption;
