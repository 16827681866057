import moment from 'moment';
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { fileImages } from '../../../../../common/mappingConst';

const Card = (props) => {
  const { item, onDeleteAttachment, isTSI } = props;
  return (
    <div className="card p-2 mt-3 d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <div className="thmb-icon-md ">
          <span className={fileImages[_.toLower(item?.type)]} />
        </div>
        <div className="ps-3">
          <div className="head-14">
            <span data-tooltip-id="project-secname" data-tooltip-content={item.name}>
              <a href={item.path} target="_blank" rel="noreferrer">{_.truncate(item.name, { length: 50 })}</a>
            </span>

          </div>
          <div>
            <span data-tooltip-id="project-secname" data-tooltip-content={item.description}>{_.truncate(item.description, { length: 110 })}</span>

          </div>
          <div>{`${item.updatedByName}, ${moment.utc(item.updatedAt).format('DD-MMM-YYYY HH:mm (UTC)')}`}</div>
        </div>
      </div>
      <div className="d-flex brand-color font-20 align-items-center">
        <a href={item.path} target="_blank" rel="noreferrer">
          <span className="icon-eye font-20 me-4 pt-2" />
        </a>
        {/* <span className="icon-edit me-4" /> */}
        <button
          type="button"
          id="deleteContact"
          className="link-btn"
          onClick={() => { if (isTSI) onDeleteAttachment(item.id); }}
        >
          <span className={`icon-delete me-4 icon-btn  brand-color pointer font-18 ${!isTSI ? 'disabled-field' : ''}`} />
        </button>
        {/* <span className={`icon-delete me-4 pointer font-18 ${!isTSI ? 'disabled-field' : ''}`}
         onClick={() => { if (isTSI) onDeleteAttachment(item.id); }} /> */}
      </div>
      <Tooltip id="project-secname" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Card;
