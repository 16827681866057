import React from 'react';
import ModalBox from './ModalBox';

const Confirmation = (props) => {
  const { onCloseConfirm, onSaveConfirm } = props;
  return (
    <div>
      <ModalBox
        confirm
        modalClassName="medium"
        heading="Confirm"
        onSave={() => {
          onSaveConfirm();
        }}
        closeModal={() => {
          onCloseConfirm();
        }}
        buttonName="Yes"
        className="confirm-modal"
      >
        <p>
          Are you sure you want to exit page?
        </p>
      </ModalBox>
    </div>
  );
};

export default Confirmation;
