import React, { useEffect } from 'react';

const SidePanel = (props) => {
  const { title, onClose, children,
    showView, specJobMainList, projInfo,
    importSpecBtn, size = 'medium', bgColor = 'bg-white',
    onSave, hideCloseBtn = true, isTsiDisabled,
    fromGallery, uploadImage, isShowUploadButton, classHeader,
    frompurchase, visibleSpec, changeVisibility, fromdefects, disableBtn } = props;
  useEffect(() => {
    if (showView) { document.body.classList.add('model-open'); }
  }, [showView]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('model-open');
    };
  }, [showView]);
  return (
    <div className={` ${showView ? `side-panel side-panel--from-right side-panel--is-visible ${size}` : `side-panel side-panel--from-right ${size}`}`}>
      {fromGallery ? (
        <header className="side-panel__header d-flex">
          <h1 className="col-8">{title}</h1>
          {isShowUploadButton && (
          <button type="button" className="btn primary-btn m-auto" disabled={disableBtn?.isClosed} data-test="click1" onClick={() => { uploadImage(); }}>Upload Image</button>
          )}
          {hideCloseBtn && <button type="button" className="side-panel__close react-side-close" data-test="click2" onClick={() => { onClose(); }}>Close</button>}
        </header>
      )
        : (
          <header className={`side-panel__header ${classHeader || ''}`}>
            <h1>{title}</h1>
            {hideCloseBtn && <button type="button" className="side-panel__close react-side-close" data-test="click3" onClick={() => { onClose(); }}>Close</button>}
          </header>
        )}
      { frompurchase ? (
        <header className="side-panel__header d-flex">
          <h1 className="col-6">{title}</h1>
          <div className="ms-5 whitespace-pre check-box">
            <input type="checkbox" id="purchase_order" name="" checked={visibleSpec?.indexOf('0') > -1} data-test="click4" onClick={() => { changeVisibility('0'); }} />
            <label htmlFor="purchase_order">Purchase Order(PO)</label>
          </div>
          <div className="ms-5 whitespace-pre check-box">
            <input type="checkbox" id="request" name="" checked={visibleSpec?.indexOf('1') > -1} data-test="click5" onClick={() => { changeVisibility('1'); }} />
            <label htmlFor="request">Purchase Request(PR)</label>
            {hideCloseBtn && <button type="button" className="side-panel__close react-side-close" data-test="click6" onClick={() => { onClose(); }}>Close</button>}
          </div>
        </header>
      ) : ('')}
      { fromdefects ? (
        <header className="side-panel__header d-flex">
          <h1 className="col-7">{title}</h1>
          <div className="d-flex">
            <div className="ms-5 check-box whitespace-pre">
              <input type="checkbox" id="Work_Orders" name="" checked={visibleSpec?.indexOf('0') > -1} data-test="click7" onClick={() => { changeVisibility('0'); }} />
              <label htmlFor="Work_Orders">Work Orders(WO)</label>
            </div>
            <div className="ms-5 check-box whitespace-pre">
              <input type="checkbox" id="Defects" name="" checked={visibleSpec?.indexOf('1') > -1} data-test="click8" onClick={() => { changeVisibility('1'); }} />
              <label htmlFor="Defects">Defects</label>
            </div>
            {hideCloseBtn && <button type="button" className="side-panel__close react-side-close" data-test="click9" onClick={() => { onClose(); }}>Close</button>}
          </div>
        </header>
      ) : ('')}
      <div className={`side-panel__container ${bgColor}`}>
        <div className="side-panel__content">
          {children}
        </div>
      </div>
      {onSave && (
        <footer className="side-panel__footer">
          <button type="button" className="secondary-btn me-4" data-test="click10" onClick={onClose}>Cancel</button>
          <button type="button" className="primary-btn" disabled={isTsiDisabled} onClick={onSave}>Save</button>
        </footer>
      )}
      {importSpecBtn && (
      <footer className="side-panel__footer">
        <button
          type="button"
          className="secondary-btn me-4"
          onClick={onSave}
          disabled={specJobMainList.length === 0 || projInfo.phase > 2}
        >
          Add To Main List
        </button>
        <button type="button" className="primary-btn" disabled={isTsiDisabled} onClick={onClose}>Cancel</button>
      </footer>
      )}
    </div>
  );
};
export default SidePanel;
