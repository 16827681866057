import React from 'react';
import Cards from './Cards';
import Loader from '../../../common/loader';

const CardWrapper = (props) => {
  const { tableData, loading, ...rest } = props;
  if (tableData.totalItems > 0) {
    return (
    // tableData.items?.map((urlData) => {
    //   return (
      <Cards
        tableData={tableData?.items}
        {... rest}
      />
    //   );
    // })
    );
  } if (loading) {
    return <Loader />;
  }
  return (
    <div className="mt-5 p-4 d-flex justify-content-center user-list-policy" data-testid="yard-attach">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">
        No data found
      </div>
    </div>
  );
};
export default CardWrapper;
