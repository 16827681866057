import React from 'react';
import helper from '../../../../../../utils/helper';

const DeviationCost = ({ estimatedValue, actualValue, original,
  index, projectBasicDetails, type }) => {
  const { formatNumber } = helper;

  const isUp = estimatedValue < actualValue;
  if (original?.variance === 0) {
    return (
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content="0"
        className="d-flex align-items-center justify-content-end"
      >
        {type === 'days' ? '' : projectBasicDetails?.currencySymbol}
        {' '}
        0
      </span>
    );
  }
  return (
    <div key={index} id={index} className="d-flex align-items-center justify-content-end" data-testid="deviation">
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content={
          isUp ? (
            `
        ${type === 'days' ? '' : '(-) '.concat(projectBasicDetails?.currencySymbol)} 
        ${' '}
        ${type === 'days' ? Math.abs(original?.variance) : formatNumber(Math.abs(original?.variance))}`
          ) : (
            `
        ${type === 'days' ? '' : projectBasicDetails?.currencySymbol} 
        ${' '}
        ${type === 'days' ? Math.abs(original?.variance) : formatNumber(Math.abs(original?.variance))}`
          )
      }
        id="de-vi-at"
        className={`text-ellipsis ${isUp ? 'red' : ' green'}`}
      >
        <>
          {isUp ? (
            type === 'days' ? (
              <>{`(-) ${Math.abs(original?.variance)}`}</>
            ) : (
              <>
                (-)
                {' '}
                {projectBasicDetails?.currencySymbol}
                {' '}
                {formatNumber(Math.abs(original?.variance))}
              </>
            )
          ) : (
            type === 'days' ? (
              <>{Math.abs(original?.variance)}</>
            ) : (
              <>
                {projectBasicDetails?.currencySymbol}
                {' '}
                {formatNumber(Math.abs(original?.variance))}
              </>
            )
          )}
        </>
        {' '}
      </span>
    </div>
  );
};

export default DeviationCost;
