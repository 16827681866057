/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import _ from 'lodash';
import ReactStars from 'react-rating-stars-component';
// import InputType1 from '../../../../../common/InputType1';
import moment from 'moment';
import { ratingAvg } from './evalHelper';
import { isValid } from './constants';

const Evaluation = (props) => {
  const { ratings, setRatings } = props;
  const [isExpand, setIsExpand] = useState('');
  // const [active, setActive] = useState(false);
  const disableStar = ratings.status === 1 ? false : true;
  const toggleExpand = (key) => {
    if (isExpand === key) {
      setIsExpand('');
    } else {
      setIsExpand(key);
    }
  };
  const handleInput = (e) => {
    const currentRating = { ...ratings };
    currentRating.comments = e.target.value;
    setRatings(currentRating);
  };
  const handleRemarks = (e, key, subKey) => {
    const currentRating = { ...ratings };
    currentRating.evaluations[key].evaluationLines[subKey].remarks = e.target.value;
    setRatings(currentRating);
  };
  const ratingChanged = (e, index, num) => {
    const currentRating = { ...ratings };
    currentRating.evaluations[index].evaluationLines[num].rating = _.round(e);
    currentRating.evaluations[index].rating = ratingAvg(currentRating.evaluations[index]
      .evaluationLines);
    setRatings(currentRating);
  };

  return (
    <div className="card font-13" data-testid="evaluation">
      {ratings?.evaluations?.map((item, index) => {
        return (
          <>
            <div className="ms-3 d-flex align-items-center">
              <div className="head-14 p-4 col-5">
                {item?.label}
                {isValid(item.evaluationLines) ? <span className="green font-16">*</span>
                  : <span className="red font-16">*</span>}
              </div>
              <div className="d-flex col-3 align-items-center">
                <ReactStars
                  size={26}
                  count={5}
                  value={item?.rating}
                  edit={false}
                  isHalf={false}
                />
                <div className="mt-1 ms-3 font-13 font-semibold">
                  {item?.rating}
                  /5
                </div>
              </div>
              <div className="col-4 align-text-end pe-4">
                <span
                  id={item.id}
                  className={isExpand === index ? 'pointer icon-arrow-thin-up mt-4 font-12' : 'pointer icon-arrow-thin-down mt-4 font-12'}
                  onClick={() => {
                    toggleExpand(index);
                  }}
                />
              </div>
            </div>
            <div className=" border-down" />
            <div>
              {isExpand === index
                && item.evaluationLines.map((field, num) => {
                  return (
                    <>
                      {num === 0 && (
                        <div className="ms-3 mt-1 d-flex ">
                          <div className="head-14 p-4 flex-column col-5">SPEC SECTION</div>
                          <div className="flex-column col-3 ">
                            <div className="head-14 py-3">Rating</div>
                          </div>
                          <div className="flex-column col-4 ">
                            <div className="head-14 py-3">Remarks</div>
                          </div>
                        </div>
                      )}
                      <div className="ms-3 my-1 d-flex align-items-center">
                        <div className="font-semibold p-4 flex-column col-5 opacity-8">{field?.label}</div>
                        <div className="flex-column col-3 ">
                          <ReactStars
                            size={26}
                            isHalf={false}
                            id={field.id.concat('star')}
                            edit={disableStar}
                            value={field.rating ? field.rating : 0}
                            onChange={(e) => {
                              ratingChanged(e, index, num);
                            }}
                          />
                        </div>
                        <input
                          type="text"
                          className={field.rating >= 3 || !field.rating || field.remarks ? 'text-box large w-full me-4' : 'text-box large w-full me-4 margin-red'}
                          disabled={ratings?.status > 0}
                          value={field.remarks}
                          id={field.id.concat('input')}
                          onChange={(e) => {
                            handleRemarks(e, index, num);
                          }}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
          </>
        );
      })}
      <>
        <div className=" border-down" />
        <div className="w-full pt-3 mx-3 py-4 ps-4 eval-comment pe-5">
          <label className="mt-4 mb-2">
            Comments
          </label>
          <span className="text-primaryRed-1">*</span>
          <textarea
            id="comment"
            disabled={ratings?.status > 0}
            onChange={(e) => { handleInput(e); }}
            type="text"
            name="comments"
            required
            value={ratings.comments}
            className="text-box w-full h-text-area p-3"
          >
            {ratings.comments}
          </textarea>
        </div>
        {ratings.evaluationDate
        && (
        <div className="mx-3 ps-4 py-3">
          <div>Date</div>
          <div>{moment(ratings.evaluationDate).format(' DD-MMM-YYYY')}</div>
        </div>
        )}
      </>
    </div>
  );
};

export default Evaluation;
