/* istanbul ignore file */
import React from 'react';

const SummeryCard = (props) => {
  const { countList } = props;
  return (
    <div className="card p-4 h-full">
      <h3 className="head-16 mb-3">Task Summary</h3>
      <ul className="d-flex justify-content-around col-gap-2 ">
        {countList?.taskSummary?.map((item) => {
          return (
            <li className="sum-stamp">
              <div className="head-18 mb-3">{item.count}</div>
              <div className="whitespace-pre">{item.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default SummeryCard;
