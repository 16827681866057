import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetVesselDetails,
  GetVesselByUser,
  GetProjectBasicDetailsUrl,
  GetSurveyNotificationsByVessel,
  GetSurveyTypeListUrl,
  GetProjectTypeListUrl,
  GetBackupTsi,
  GetCurrencyListBasedOnStatus,
  GetFleetManagerAndTsiInfo,
  SaveProjectSpecs,
  SaveJobAttacmentList,
  GetJobAttacmentList,
  SaveProjectBasicDetails,
  GetProjectAttachments,
  SaveProjectAttachment,
  S3UploadMultiple,
  DeleteJobAttachment,
  SubmitProjectForApproval,
  GetVesselSurveyNotificationsUrl,
  BrowseAttachments,
  GetProjectSpecList,
  UpdateScreenCompletedStatus,
  GetWorkOrderDefectList,
  GetJobMappedSpecs,
  DeleteProjectAttachments,
  sendForApprovalUrl,
  updateProjectStatusUrl,
  getProjectStatusUrl,
  GetEmployeeList,
  SaveEmployeeContact,
  DeleteEmployeeContactInfo,
  GetContactListByCategory,
  ExcelContact,
  DownloadAllAttachmentsByCategory,
  CheckForMultipleProjects,
  YardS3UploadMul,
  getProjectHasApproverUrl
} = apiEndPoints;

const { downloadFile, currentDate, getExportedFileName } = helper;

export const getVesselDetails = (payload) => {
  return serviceHandler({
    url: `${GetVesselDetails}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const getVesselList = (payload) => {
  return serviceHandler({
    url: `${GetVesselByUser}?projectId=${payload?.projectId}`,
    methordType: 'get',
    payload
  });
};

export const getProjectDetailsTSI = (payload) => {
  return serviceHandler({
    url: `${GetProjectBasicDetailsUrl}?projectId=${payload.id}`,
    methordType: 'post'
  });
};
export const getGeneralTariff = (projectId, vesselId) => {
  return serviceHandler({
    url: `${GetProjectSpecList}?projectId=${projectId}&vesselId=${vesselId}`,
    methordType: 'post'
  });
};

export const getNotification = (payload) => {
  return serviceHandler({
    url: `${GetSurveyNotificationsByVessel}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const GetSurveyTypeList = (payload) => {
  return serviceHandler({
    url: `${GetSurveyTypeListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetProjectTypeList = (payload) => {
  return serviceHandler({
    url: `${GetProjectTypeListUrl}`,
    methordType: 'get',
    payload
  });
};

export const GetAdditionalJobList = (vesselId, projectId, ProjectTypeId) => {
  return serviceHandler({
    url: `${GetJobMappedSpecs}?vesselId=${vesselId}&projectId=${projectId}&ProjectTypeId=${ProjectTypeId}`,
    methordType: 'get'
  });
};

export const getProjectAttachments = (payload) => {
  return serviceHandler({
    url: `${GetProjectAttachments}?projectId=${payload.id}&phase=${payload.phase}&isLifoOrder=${payload.isLifoOrder}`,
    methordType: 'get',
    payload
  });
};

export const saveProjectAttachments = (payload) => {
  return serviceHandler({
    url: `${SaveProjectAttachment}`,
    methordType: 'post',
    payload
  });
};

export const getBackupTSI = (vesselId, projectId) => {
  return serviceHandler({
    url: `${GetBackupTsi}?vesselId=${vesselId}&projectId=${projectId}`,
    methordType: 'post'
  });
};

export const getCurrencyList = () => {
  return serviceHandler({
    url: `${GetCurrencyListBasedOnStatus}?isPublished=true`,
    methordType: 'post'
  });
};

export const getFleetandTSI = (payload) => {
  return serviceHandler({
    url: `${GetFleetManagerAndTsiInfo}`,
    methordType: 'post',
    payload
  });
};

export const s3UploadMutiple = (payload, isFromYard, params) => {
  if (isFromYard) {
    return serviceHandler({
      url: `${YardS3UploadMul}?key=${params.key}`,
      methordType: 'post',
      payload
    });
  }
  return serviceHandler({
    url: `${S3UploadMultiple}`,
    methordType: 'post',
    payload
  });
};
export const saveProjectSpecsTSI = (payload, projectId) => {
  return serviceHandler({
    url: `${SaveProjectSpecs}?projectid=${projectId}`,
    methordType: 'post',
    payload
  });
};

export const saveAttachmentJobList = (payload) => {
  return serviceHandler({
    url: `${SaveJobAttacmentList}`,
    methordType: 'post',
    payload
  });
};

export const getAttachmentJobList = (jobAttachmentId) => {
  return serviceHandler({
    url: `${GetJobAttacmentList}?jobAttachmentId=${jobAttachmentId}`,
    methordType: 'post'
  });
};

export const saveProjectBasicDetailsList = (payload) => {
  return serviceHandler({
    url: `${SaveProjectBasicDetails}`,
    methordType: 'post',
    payload
  });
};

export const getConfirmation = (payload) => {
  return serviceHandler({
    url: `${GetProjectBasicDetailsUrl}?projectId=${payload.id}`,
    methordType: 'post',
    payload
  });
};
export const getVesselSurvey = (payload) => {
  return serviceHandler({
    url: `${GetVesselSurveyNotificationsUrl}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const deleteAttachments = (attachmentId) => {
  return serviceHandler({
    url: `${DeleteJobAttachment}?attachmentId=${attachmentId}`,
    methordType: 'post'
  });
};
export const deleteProjectAttachment = (payload) => {
  return serviceHandler({
    url: `${DeleteProjectAttachments}?id=${payload.id}`,
    methordType: 'put',
    payload
  });
};

export const submitForApproval = (projectId, level) => {
  return serviceHandler({
    url: `${SubmitProjectForApproval}?projectId=${projectId}&level=${level}`,
    methordType: 'post'
  });
};
export const WorkOrderDefectList = (payload) => {
  return serviceHandler({
    url: `${GetWorkOrderDefectList}?projectId=${payload.id}`,
    methordType: 'post'
  });
};
export const browseAttachments = (payload) => {
  return (serviceHandler({
    url: `${BrowseAttachments}`,
    methordType: 'post',
    payload
  }));
};

export const updateStatus = (projectId, screenCompleted) => {
  return serviceHandler({
    url: `${UpdateScreenCompletedStatus}?projectId=${projectId}&screenCompleted=${screenCompleted}`,
    methordType: 'post'
  });
};
export const sendForApproval = (projectId, type) => {
  return serviceHandler({
    url: `${sendForApprovalUrl}?projectId=${projectId}&type=${type}`,
    methordType: 'get'
  });
};
export const getProjectStatus = (projectId, type) => {
  return serviceHandler({
    url: `${getProjectStatusUrl}?projectId=${projectId}&type=${type}`,
    methordType: 'get'
  });
};
export const getProjectHasApprover = (projectId, type) => {
  return serviceHandler({
    url: `${getProjectHasApproverUrl}?projectId=${projectId}&type=${type}`,
    methordType: 'get'
  });
};
export const updateProjectStatus = (payload) => {
  return serviceHandler({
    url: `${updateProjectStatusUrl}`,
    methordType: 'post',
    payload
  });
};
export const GetEmployeeListByProject = (payload) => {
  return serviceHandler({
    url: `${GetEmployeeList}`,
    methordType: 'post',
    payload
  });
};
export const saveEmployeeContact = (payload) => {
  return serviceHandler({
    url: `${SaveEmployeeContact}`,
    methordType: 'post',
    payload
  });
};
export const deleteEmployeeContactInfo = (payload) => {
  return serviceHandler({
    url: `${DeleteEmployeeContactInfo}?EmployeeId=${payload.employeeId}`,
    methordType: 'delete',
    payload
  });
};
export const getContactListByCategory = (payload) => {
  return serviceHandler({
    url: `${GetContactListByCategory}?categoryId=${payload.categoryId}`,
    methordType: 'get'
  });
};
export const exportExcelContact = (payload) => {
  return axios
    .get(`${ExcelContact}?search=${payload.searchText}&projectId=${payload.projectId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response.....');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Contacts_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const downloadAttachmentsByCategory = (payload) => {
  return axios
    .get(`${DownloadAllAttachmentsByCategory}?projectId=${payload.projectId}&categoryId=${payload.categoryId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const checkForMultipleProjects = (payload) => {
  return serviceHandler({
    url: `${CheckForMultipleProjects}?vesselId=${payload.vesselId}`,
    methordType: 'get'
  });
};
