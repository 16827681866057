/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { notificationCount } from './TsiTask/services';
import helper from '../../../utils/helper';
import ChatBox from '../../common/chatBox';
import ChatScreen from './ChatScreen';
import { getChatMessages } from './services';
import SidePanel from '../../common/sidePanel';
import ContactScreen from './ContactScreen';
import Gallery from './Gallery';
import Pagination from '../../common/pagination';
import { getProjectSpecImages } from './Gallery/services';

const Sidebar = ({ setOpenModal, openModal, reload, phase, isTsiDisabled, isTSI,
  isMaster, projectRepair, isSidebarOpen, disableBtn, projInfo }) => {
  const { projectPhase, ZERO_UUID, isValueValidation, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const pathName = window.location.pathname;
  const params = qs.parse(window.location.search);
  const navigate = useNavigate();
  const { id } = useParams();
  const childRef = useRef();
  let x;
  const location = useLocation();
  const [count, setCount] = useState([]);
  const [showView, setShowView] = useState(false);
  const [recieved, setRecieved] = useState([]);
  const [showContact, setShowContact] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [uploadView, setIsUploadView] = useState(false);
  const [folderType, setFolderType] = useState(1);
  const [images, setImages] = useState();
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [nameChange, setNameChange] = useState('');
  const [active, setActive] = useState('');

  const onNameChange = (e) => {
    setNameChange(e.target.value);
  };
  const initialFormData = {
    documentCategory: ZERO_UUID,
    projectId: id,
    phase: 4,
    projectAttachments: [],
    name: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const showContactModal = () => {
    setShowContact(true);
  };

  const uploadImage = () => {
    setIsUploadView(true);
  };
  const getChatRecievedMessages = () => {
    getChatMessages({
      page: 1,
      pageSize: 1000,
      search: '',
      sortProperty: '',
      isDescending: true,
      type: 0,
      id
    }).then((res) => {
      if (res.success) {
        setRecieved(res.data.items);
      }
    });
  };

  const refresh = () => {
    x = setInterval(getChatRecievedMessages, 10000);
  };

  useEffect(() => {
    notificationCount({ id }).then((res) => {
      if (res.success) {
        setCount(res.data);
      }
    });
  }, [reload]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const getCounts = (name) => {
    const selectCount = count.filter((list) => list.name === name);
    return selectCount ? selectCount[0] : '';
  };

  const openChat = () => {
    setShowView(true);
  };

  const onClose = () => {
    setShowView(false);
  };

  useEffect(() => {
    getChatRecievedMessages();
    refresh();
  }, []);
  useEffect(() => {
    return () => {
      clearInterval(x);
    };
  }, []);
  const getImages = () => {
    const payload = {
      page: currentpage,
      pageSize,
      search: '',
      sortProperty: '',
      isDescending: true,
      projectId: id
    };
    getProjectSpecImages(payload).then((res) => {
      setLoading(false);
      setImages(res?.data);
    });
  };
  useEffect(() => {
    getImages();
  }, [uploadView, currentpage, pageSize]);
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
  };
  const nextPageClick = (e) => {
    setCurrentpage(currentpage + 1);
  };
  const previousPageClick = (e) => {
    setCurrentpage(currentpage - 1);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };

  return (
    <>
      <div className={`side-menu-wrap contactColor my-5 ${isSidebarOpen ? 'show' : ''}`} data-testid="admin-sidebar">
        <div className="scroll-area mb-5 pb-5">
          <ul className="menu-item">
            <li
              className={`${phase < projectPhase.SPECIFY && ' disabled'} ${pathName.includes('/task') ? 'relative active' : 'relative'}`}
              data-testid="task"
              onClick={() => {
                if (phase > projectPhase.PLAN) navigate(`/user/project/${id}/task?vesselId=${params?.vesselId}`);
              }}
            >
              {!isVesselUser && (
                <div className="icon">
                  <span className="icon-task " />
                </div>
              )}

              {count[0]?.count && pathName.includes('/task') && !isVesselUser ? <div className="notify-count">{count[0]?.count}</div> : ''}
              {!isVesselUser && <p>Task</p>}
            </li>
            {!isVesselUser && (
              <li
                className={`${!isVesselUser ? (phase < projectPhase.SPECIFY && ' disabled') : (phase <= projectPhase.SPECIFY && ' disabled')} "relative"`}
                data-testid="woha"
                onClick={() => {
                  if (phase > projectPhase.PLAN && !isVesselUser) setOpenModal('woha');
                }}
              >
                <div className="icon" disabled={isVesselUser}>
                  <span className="icon-woha " disabled={isVesselUser} />
                </div>
                {openModal === 'woha' && getCounts('WOHA')?.count ? <div className="notify-count">{getCounts('WOHA')?.count}</div> : ''}
                <p>WOHA</p>
              </li>
            )}
            {!isVesselUser && (
              <li
                className={`${!isVesselUser ? (phase < projectPhase.SPECIFY && ' disabled') : (phase <= projectPhase.SPECIFY && ' disabled')} "relative"`}
                data-testid="poha"
                onClick={() => {
                  if (phase > projectPhase.PLAN && !isVesselUser) setOpenModal('poha');
                }}
              >
                <div className="icon" disabled={isVesselUser}>
                  <span className="icon-poha " disabled={isVesselUser} />
                </div>
                {openModal === 'poha' && getCounts('POHA')?.count ? <div className="notify-count">{getCounts('POHA')?.count}</div> : ''}
                <p>POHA</p>
              </li>
            )}

            {!isVesselUser && (
              <li
                className={`${phase < projectPhase.SPECIFY && ' disabled'} "relative"`}
                data-testid="components"
                onClick={() => {
                  if (phase > projectPhase.PLAN) setOpenModal('components');
                }}
              >
                <div className="icon">
                  <span className="icon-component " />
                </div>
                <p>Component</p>
              </li>
            )}
            {!isVesselUser && (
              <li
                className={`${phase < projectPhase.SPECIFY && ' disabled'} "relative"`}
                data-testid="dms"
                onClick={() => {
                  if (phase > projectPhase.PLAN) setOpenModal('dms');
                }}
              >
                <div className="icon">
                  <span className="icon-dms " />
                </div>
                <p>DMS</p>
              </li>
            )}
            {projectRepair
              && !isMaster
              && (location?.pathname.includes('/repair') || location?.pathname.includes('/report') || location?.pathname.includes('/close')) ? (
                <li
                  onClick={() => {
                    navigate(`/user/project/${id}/report?vesselId=${params?.vesselId}`);
                  }}
                >
                  <div className="icon">
                    <span className="icon-task" />
                  </div>
                  <p>Report</p>
                </li>
              ) : (
                ''
              )}
            {!isVesselUser && (
              <li onClick={() => { showContactModal(); }}>
                <div className="icon">
                  <span className="icon-Frame" />
                </div>
                <p>Contact</p>
              </li>
            )}
            {(location?.pathname?.includes('/repair') || location?.pathname?.includes('/report')) && (
              <li onClick={() => { setShowGallery(true); }}>
                <div className="icon">
                  <span className="icon-image" />
                </div>
                <p>Gallery</p>
              </li>
            )}
            {(phase > projectPhase.PLAN) && (
              <li onClick={() => {
                if (phase > projectPhase.PLAN) navigate(`/user/project/${id}/sp-download?vesselId=${params?.vesselId}`);
              }}
              >
                <div className="icon">
                  <span className="icon-image" />
                </div>
                <p>Specs</p>
              </li>
            )}

          </ul>
        </div>
        <div
          data-testid="chat-click"
          className="chat-btn cursor-pointer"
          onClick={() => {
            openChat();
          }}
        >
          <span className="icon-chat" />

          {/* {showDot && (<span className="dot-indicator" />)} */}
        </div>
      </div>
      {showView && (
        <ChatBox modalClassName="medium modal-scroll" heading="Chat" closeModal={() => onClose()}>
          <ChatScreen
            getChatRecievedMessages={getChatRecievedMessages}
            recieved={recieved}
            projInfo={projInfo}
          />
        </ChatBox>
      )}
      <SidePanel
        title="Contacts"
        showView={showContact}
        bgColor="sky-white"
        onClose={() => { setShowContact(false); }}
      >
        {showContact && (
          <ContactScreen />
        )}
      </SidePanel>
      <div className="scroll-wrap">
        <SidePanel
          title={!(uploadView && active) ? 'Image Gallery' : 'Upload Image'}
          showView={showGallery}
          fromGallery={folderType === 2}
          size="large document-side-panel "
          bgColor="sky-white"
          isTsiDisabled={isTsiDisabled}
          isTSI={isTSI}
          isShowUploadButton={!uploadView && active && (isTSI || isMaster)}
          uploadImage={uploadImage}
          disableBtn={disableBtn}
          onClose={() => { setShowGallery(false); setIsUploadView(false); setFolderType(1); }}
        >
          {showGallery && (
            <Gallery
              nameChange={nameChange}
              setNameChange={setNameChange}
              onNameChange={onNameChange}
              nextPageClick={nextPageClick}
              previousPageClick={previousPageClick}
              getProjectSpecImages={getProjectSpecImages}
              uploadView={uploadView}
              ref={childRef}
              isTsiDisabled={isTsiDisabled}
              setIsUploadView={setIsUploadView}
              images={images}
              getImages={getImages}
              loading={loading}
              initialFormData={initialFormData}
              formData={formData}
              setFormData={setFormData}
              setCurrentpage={setCurrentpage}
              setFolderType={setFolderType}
              folderType={folderType}
              active={active}
              setActive={setActive}
              disableBtn={disableBtn}
            />
          )}
          {uploadView && (
            <footer className="side-panel__footer">
              <button disabled={!formData.projectAttachments?.length > 0 || !isValueValidation(nameChange)} type="button" className="primary-btn me-4 large" onClick={() => childRef.current.saveApiCall()}>
                Save
              </button>
            </footer>

          )}
          {/* {!uploadView && (
          <footer className="side-panel__footer">
            {images?.totalItems > 20 && (
              <Pagination
                pageClick={pageClick}
                pageCount={images.totalPages}
                currentpage={currentpage}
                onSizeChange={onSizeChange}
                size={pageSize}
                fromGallery
              />
            )}
          </footer>

          )} */}
        </SidePanel>
      </div>

      {showContact && (
        <ContactScreen
          projInfo={projInfo}
          showContact={showContact}
          setShowContact={setShowContact}
        />
      )}

    </>
  );
};
export default Sidebar;
