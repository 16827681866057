import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getProjectHasApprover } from '../../../NewProject/services';

const PreConfirm = (props) => {
  const { id, sendForApprove, setShowSubmit, projInfo } = props;
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  /* istanbul ignore next */
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">
      <div className="head-18 pb-4">
        <span className="white-space-preserve" data-tooltip-id="project-cost" data-tooltip-content={projInfo?.name}>
          {projInfo?.name}
        </span>

      </div>
      <div className="mt-3">
        {hasApprover ? <p className="font-15 font-semibold">Project will be sent for next level of approval</p>
          : ''}
      </div>
      <span className="head-18">{`Are you sure you want to ${projInfo?.closeApprovalStatus === 3 ? 're' : ''}submit?`}</span>
      <div className="d-flex">
        <button type="button" className="secondary-btn large m-3 p-3" id="backtoprojects" onClick={() => setShowSubmit(false)}>
          Cancel
        </button>
        <button type="button" className="primary-btn large m-3 p-3" id="save" onClick={() => sendForApprove()}>
          {`${projInfo?.closeApprovalStatus === 3 ? 'Resubmit' : 'Submit'}`}
        </button>
        <Tooltip id="project-close" place="top" className="tooltip mt-3" />
      </div>
    </div>
  );
};
export default PreConfirm;
