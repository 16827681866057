import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  SaveUnitOfMeasure,
  GetUnitOfMeasuresList,
  GetUnitOFMeasureDetail,
  DeleteUnitOfMeasure,
  PublishUnitOfMeasureList,
  GenerateTemplateUOM,
  BulkUploadUOM,
  ExportUomExcel
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getUnitOfMeasuresList = (payload) => {
  return serviceHandler({
    url: `${GetUnitOfMeasuresList}`,
    methordType: 'post',
    payload
  });
};
export const getUnitOFMeasureDetail = (payload) => {
  return serviceHandler({
    url: `${GetUnitOFMeasureDetail}`,
    methordType: 'get',
    payload
  });
};
export const saveUnitOfMeasure = (payload) => {
  return serviceHandler({
    url: `${SaveUnitOfMeasure}`,
    methordType: 'post',
    payload
  });
};
export const deleteUnitOfMeasure = (payload) => {
  return serviceHandler({
    url: `${DeleteUnitOfMeasure}?Uomid=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const publishUnitOfMeasureList = (payload) => {
  return serviceHandler({
    url: `${PublishUnitOfMeasureList}`,
    methordType: 'put',
    payload
  });
};
export const exportExcelUOM = (searchText) => {
  return axios
    .get(`${ExportUomExcel}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `UOM_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const bulkUploadUOM = (payload) => {
  return serviceHandler({
    url: `${BulkUploadUOM}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateUOM}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Unit Of Measure_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
