/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { cloneDeep, toNumber, truncate } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';
import SectionsSubTable from './SectionsSubTable';
import RepairPeriodTable from './RepairPeriodTable';
import YardCostSummary from './YardCostSummary';
import helper from '../../../../../../utils/helper';
import DeviationCost from './DeviationCost';
import Loader2 from '../../../../../common/loader2';
import VariancePercentage from './VariancePercentage';

const Sections = ({ repairCostSummary, projectId, projectBasicDetails, loading }) => {
  const [expanded, setExpanded] = useState([]);
  const [expandedSpec, setExpandedSpec] = useState([]);
  const [activeSect, setActiveSect] = useState();
  const [activeSpec, setActiveSpec] = useState();
  const [isShowPopup, setisShowPopup] = useState();

  const { formatNumber } = helper;

  const renderVarianceNew = (estimate, actual, row, type) => {
    const { index, original } = row;
    return (
      <DeviationCost
        isShowPopup={isShowPopup}
        setisShowPopup={setisShowPopup}
        original={original}
        index={index}
        projectId={projectId}
        projectBasicDetails={projectBasicDetails}
        type={type}
        estimatedValue={estimate}
        actualValue={actual}
      />
    );
  };
  const renderVariance = (estimate, actual, row, type) => {
    const { index, original } = row;
    // const activeIndex = type === 'specification' ? activeSpec : activeSect;
    // if (activeIndex === index && type !== 'serviceline') {
    //   return <div className="white">-</div>;
    // }
    return (
      <DeviationCost
        isShowPopup={isShowPopup}
        setisShowPopup={setisShowPopup}
        original={original}
        index={index}
        projectId={projectId}
        projectBasicDetails={projectBasicDetails}
        estimatedValue={estimate}
        actualValue={actual}
      />
    );
  };
  const renderVariancePercentage = (estimate, actual, row, type) => {
    const { index, original } = row;
    return (
      <VariancePercentage
        isShowPopup={isShowPopup}
        setisShowPopup={setisShowPopup}
        original={original}
        index={index}
        projectId={projectId}
        projectBasicDetails={projectBasicDetails}
        estimatedValue={estimate}
        actualValue={actual}
      />
    );
  };
  const renderCell = (row, type, field) => {
    const { index, original } = row;
    // const activeIndex = type === 'specification' ? activeSpec : activeSect;
    // if (activeIndex === index) {
    //   return <div className="white">-</div>;
    // }
    return (
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content={`${projectBasicDetails?.currencySymbol || ''} ${original[field] ? formatNumber(original[field]) : formatNumber(0)}`}
      >
        {`${projectBasicDetails?.currencySymbol || ''} ${original[field] ? formatNumber(original[field]) : formatNumber(0)}`}
      </span>
    );
  };

  const renderServiceCell = (row, field) => {
    const { original } = row;
    return (
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content={`${projectBasicDetails?.currencySymbol} ${original[field] ? formatNumber(original[field]) : formatNumber(0)}`}
      >
        {`${projectBasicDetails?.currencySymbol} ${original[field] ? formatNumber(original[field]) : formatNumber(0)}`}
      </span>
    );
  };
  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: true,
      resizable: false,
      width: 40,
      Expander: ({ isExpanded, index }) => (isExpanded ? (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(repairCostSummary?.specs);
            const expandedRows = quotesCopy?.map(() => {
              return false;
            });
            setExpanded(expandedRows);
            setActiveSect();
            setExpandedSpec([]);
            setActiveSpec();
          }}
          className="font-13 icon-arrow-thin-right circle-arrow circle-arrow-small rotate-90 "
        />
      ) : (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(repairCostSummary?.specs);
            const expandedRows = quotesCopy?.map((data, key) => {
              if (index === key) {
                setActiveSect(index);
                return true;
              }
              return false;
            });
            setExpanded(expandedRows);
            setExpandedSpec([]);
            setActiveSpec();
          }}
          className="font-13 icon-arrow-thin-right circle-arrow circle-arrow-small"
        />
      ))
    },
    {
      Header: '',
      sortable: false,
      expander: false,
      width: 60,
      resizable: false,
      accessor: 'number',
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specNames" data-tooltip-content={original.number} className="text-ellipsis">
            {original.number}
          </span>
        );
      }
    },
    {
      Header: '',
      sortable: false,
      expander: false,
      resizable: false,
      width: 140,
      accessor: 'specName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specNames" data-tooltip-content={original.specName} className="text-ellipsis">
            {original.specName}
          </span>
        );
      }
    },
    {
      Header: 'Yard Est',
      width: 110,
      accessor: 'yardEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, '', 'yardEstimate');
      }
    },
    {
      Header: 'Owner Est',
      width: 110,
      accessor: 'ownerEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, '', 'ownerEstimate');
      }
    },
    {
      Header: 'Project Est',
      width: 110,
      accessor: 'projectEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, '', 'projectEstimate');
      }
    },
    {
      Header: 'yard Actual',
      width: 110,
      accessor: 'yardActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, '', 'yardActual');
      }
    },
    {
      Header: 'owner Actual',
      width: 140,
      accessor: 'ownerActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, '', 'ownerActual');
      }
    },
    {
      Header: 'project Actual',
      width: 140,
      accessor: 'projectActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, '', 'projectActual');
      }
    },
    {
      Header: 'variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.projectEstimate, original.projectActual, row, '');
      }
    },
    {
      Header: 'variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.projectEstimate, original.projectActual, row);
      }
    }
  ];
  const showPagination = false;
  if (loading) {
    return (
      <div data-testid="sections">
        <Loader2 />
      </div>
    );
  }
  return (
    <div data-testid="sections">
      <div className="card my-3 section-Card-table">
        <div className="d-flex justify-content-between py-4 px-4">
          <div className="head-16">Repair Period</div>
        </div>
        <RepairPeriodTable
          repairCostSummary={repairCostSummary}
          projectBasicDetails={projectBasicDetails}
          renderVariance={renderVarianceNew}
          renderVariancePercentage={renderVariancePercentage}
        />
      </div>
      <div className="card my-3 section-Card-table section-Card-table-rm-border">
        <div className="d-flex justify-content-between py-4 px-4">
          <div className="head-16">Spec Section Cost Details</div>
          <div className="d-flex col-gap-3">
            {/* <div>
              Discount: &nbsp;
              <span className="text-content-grey ">
                {repairCostSummary?.discountPercentage}
                %
              </span>
            </div> */}
            <div>
              TRD : &nbsp;
              <span className="text-content-grey text-box">{repairCostSummary?.repairDays}</span>
            </div>
            <div className="d-flex text-ellipsis c-w-300">
              Payment Terms : &nbsp;
              <span className="text-content-grey text-ellipsis " title={repairCostSummary?.paymentTerms}>
                {repairCostSummary?.paymentTerms}
              </span>
              <span className="icon-doted-horizontal" />
            </div>
          </div>
        </div>
        <ReactTable
          className="text-black"
          data={repairCostSummary?.specs}
          columns={columns}
          pageSize={(repairCostSummary?.specs && repairCostSummary?.specs?.length > 0)
            ? repairCostSummary?.specs?.length : 1}
          showPagination={showPagination}
          expanded={expanded}
          SubComponent={(mainProp) => {
            const { specs } = mainProp.original;
            return (
              <SectionsSubTable
                tableData={specs}
                setActiveSpec={setActiveSpec}
                expanded={expandedSpec}
                setExpanded={setExpandedSpec}
                activeSpec={activeSpec}
                projectId={projectId}
                projectBasicDetails={projectBasicDetails}
                renderVariance={renderVariance}
                renderCell={renderCell}
                renderServiceCell={renderServiceCell}
                renderVariancePercentage={renderVariancePercentage}
              />
            );
          }}
        />
      </div>
      <YardCostSummary
        allData={repairCostSummary}
        repairCostSummary={repairCostSummary?.nonSpecs}
        projectBasicDetails={projectBasicDetails}
        renderVariance={renderVarianceNew}
        renderVariancePercentage={renderVariancePercentage}
      />
      <Tooltip id="project-specNames" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Sections;
