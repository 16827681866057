import React from 'react';

const YardCard = (props) => {
  const { onContiue } = props;
  return (
    <div data-testid="yard-card" className="card p-4 quote_selcard ms-5">
      <div className="mt-4 p-5 bg-sky-blue round-radius"><span className="blue icon-robot font-35" /></div>
      <div className="font-bold mt-3 head">Through application</div>
      <ul className="my-3 px-2 details">
        <li className="pt-2">1. Read and agree to the terms and conditions</li>
        <li className="pt-2">2. View/Download the spec attachment</li>
        <li className="pt-2">3. Update the quote and submit RFQ</li>
      </ul>
      <button type="button" className="primary-btn mt-3 mb-4" onClick={() => { onContiue(2, false); }}>Continue</button>
    </div>
  );
};
export default YardCard;
