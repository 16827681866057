import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const Card2 = (props) => {
  const { data, vesselInfo, setUpdateInfo } = props;
  const [isOpened, setIsOpened] = useState(false);
  return (
    <div className="card mt-3 p-5 mb-3">
      <div className="d-flex align-items-center justify-content-between pointer">
        <h4 className="font-16 font-semibold head-black" onClick={() => { setIsOpened(!isOpened); }}>{data?.tabLabel}</h4>
        <div>
          <button type="button" className="link-btn me-4 d-none" onClick={() => { setUpdateInfo(data); }}>
            <span className="icon-edit font-16 me-2" />
            Edit
          </button>
          <span onClick={() => { setIsOpened(!isOpened); }} className={`font-12 ${!isOpened ? ' icon-arrow-thin-down' : ' icon-arrow-thin-up open'}`} />
        </div>
      </div>
      <div className={`${!isOpened ? 'content-hide' : 'content-show pt-4'}`}>
        <div className="row mb-2 vessel-inf align-items-end">
          {
            data?.items.map((item) => {
              return (
                <>
                  <div
                    className="col-3 mb-2"
                  >
                    <label
                      className=""
                      data-tooltip-id="project-cardname"
                      data-tooltip-content={item?.label === 'VDG' ? 'Vessel Distribution Group mail-id' : ''}
                    >
                      {item.label}

                    </label>

                  </div>
                  <div
                    className="col-3 mb-2"
                  >
                    <span
                      className="value text-ellipsis"
                      data-tooltip-id="project-cardname"
                      data-tooltip-content={vesselInfo[data.tabkey] && (vesselInfo[data.tabkey][item.key] || '')}
                    >
                      {_.truncate(vesselInfo[data.tabkey] && (vesselInfo[data.tabkey][item.key] || ''), {
                        length: 30,
                        separator: ' '
                      })}
                      {}

                    </span>

                  </div>
                </>
              );
            })
          }
          <Tooltip id="project-cardname" place="top" className="tooltip mt-3" />
        </div>
      </div>
    </div>
  );
};
export default Card2;
