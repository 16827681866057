/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import userImg from '../../../../images/user-placeholder.jpg';
import helper from '../../../../utils/helper';

const AddContact = (props) => {
  const { contactCategoryList, contact, setContact,
    handleChange, ondrop, getContactRoleList, contactRoleListCategory } = props;
  const { handleValidation } = helper;
  console.log(contact, 'contact');
  const contactUpdate = (e) => {
    const currentContact = { ...contact };
    currentContact[e.target.name] = e.target.value;
    // currentContact[e.target.role] = e.target.value;
    setContact(currentContact);
  };
  const contactUpdateList = (e, label) => {
    const currentContactList = { ...contact };
    currentContactList[label] = e.value;
    if (label === 'contactCategoryId') {
      currentContactList.roleCategoryId = 123;
    }
    setContact(currentContactList);
    handleValidation(['position', 'contactCategoryId'], contact, label);
  };
  return (
    <div data-testId="contact-cardWrapper">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center flex-column">
            <div className="upload-sec  d-flex justify-content-center align-items-center flex-column mb-5">
              <span
                data-tooltip-id="project-specify"
                data-tooltip-content={contact.images}
              >
                {contact.images ? (
                  <img src={contact.images} alt="" className="thmb-lg" />
                ) : (
                  <div className="contact-img"><img src={userImg} alt="" className="thmb-xlg-card" /></div>
                )}
              </span>
              {console.log(contact?.images, 'dfkjgbksdg')}
              <div className="upload-btn m-auto mt-4">
                <button type="button" className="link-btn">Upload contact image</button>
                <input
                  type="file"
                  id="upload"
                  name="myfile"
                  onChange={(e) => {
                    ondrop(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label className="">Name</label>
          <span className="text-primaryRed-1">*</span>
          <InputType1
            autoFocus
            className="create-contact"
            name="name"
            id="name"
            value={contact?.name}
            data-testid="name"
            onChange={(e) => {
              contactUpdate(e);
              handleChange(e, 'name', '');
            }}
          />
        </div>
        <div className="col-6">
          <label className="">Contact Category</label>
          <span className="text-primaryRed-1">*</span>
          <Select
            name="contactCategory"
            className="mt-2"
            id="contactCategoryId"
            data-testid="contactCategory"
            options={contactCategoryList}
            value={contactCategoryList.filter((val) => val.value === contact.contactCategoryId)[0]}
            onChange={(e) => {
              contactUpdateList(e, 'contactCategoryId', 'select');
              // contactUpdateList({ value: '', label: '' }, 'roleCategoryId', 'select');
              handleChange(e, 'contactCategoryId', 'select');
              // handleChange(e, 'contactCategory', 'select');
              getContactRoleList(e.value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <label className="">Contact Role</label>
          <InputType1
            className="create-contact"
            name="position"
            id="position"
            value={contact?.position}
            data-testid="position"
            onChange={(e) => {
              contactUpdate(e, 'position', '');
              handleChange(e, 'position', '');
            }}
          />
          {/* <span className="text-primaryRed-1">*</span> */}
          {/* <Select
            options={contactRoleListCategory}
            name="roles"
            className="mt-2"
            id="roleCategoryId"
            data-testid="roles"
            value={contactRoleListCategory.filter((val) => val.value === contact.roleCategoryId)[0] || {}}
            onChange={(e) => {
              contactUpdateList(e, 'roleCategoryId', 'select');
              handleChange(e, 'roleCategoryId', 'select');
              // handleChange(e, 'position', 'select');
            }}
          /> */}
        </div>
        <div className="col-6">
          <label className="">Company</label>
          <span className="text-primaryRed-1">*</span>
          <InputType1
            className="create-contact"
            name="company"
            id="company"
            data-testid="company"
            value={contact?.company}
            onChange={(e) => {
              contactUpdate(e, 'company', '');
              handleChange(e, 'company', '');
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 relative">
          <label className="">Mobile</label>
          <span className="text-primaryRed-1">*</span>
          <div className="absolute input-icon"><span className="icon-phone font-22 " /></div>
          <InputType1
            name="mobile"
            className="ps-5 create-contact"
            id="mobile"
            value={contact?.mobile}
            data-testid="mobile"
            onChange={(e) => {
              contactUpdate(e);
              handleChange(e, 'mobile', '');
            }}
          />
        </div>
        <div className="col-6 relative">
          <div className="absolute input-icon"><span className="icon-whatsapp font-22 " /></div>
          <InputType1
            name="whatsapp"
            className="ps-5 create-contact"
            label={<div className="">Whatsapp</div>}
            id="whatsapp"
            value={contact?.whatsapp}
            data-testid="whatsapp"
            onChange={(e) => {
              contactUpdate(e);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6 relative">
          <div className="absolute input-icon"><span className="icon-cell-phone font-22 " /></div>
          <InputType1
            className="ps-5 create-contact"
            name="telephone"
            label={<div className="">Telephone</div>}
            id="telephone"
            value={
        contact?.telephone
}
            data-testid="telephone"
            onChange={(e) => {
              contactUpdate(e);
              handleChange(e, 'telephone', '');
            }}
          />
        </div>
        <div className="col-6 relative">
          <div className="absolute input-icon"><span className="icon-email font-22 " /></div>
          <InputType1
            className="ps-5 create-contact"
            name="email"
            label={<div className="">Email</div>}
            id="email"
            value={contact?.email}
            data-testid="email"
            onChange={(e) => {
              contactUpdate(e);
              handleChange(e, 'email', '');
            }}
          />
        </div>
      </div>
      <Tooltip id="project-dashboard" place="top" className="tooltip" />
    </div>
  );
};

export default AddContact;
