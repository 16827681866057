/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import CreateTemplate from './CreateTemplate';
import ModalBox from '../../../common/ModalBox';
import { deleteTemplate, getReportTemplate, getReportTemplateLists, publishTemplate, saveTemplates } from './services';
import TemplateTable from './TemplateTable';
import helper from '../../../../utils/helper';
import Pagination from '../../../common/pagination';
import showAlert from '../../../../utils/alert';
import ViewTemplate from './viewTemplate';
import Loader from '../../../common/loader';

let isSearched = false;
const Templates = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { formValidation, TemplateTypes } = helper;
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [viewModal, setViewModal] = useState(false);
  const [filterType, setFilterType] = useState('');
  const [loading, setLoading] = useState(false);
  const [siteLoading, setSiteLoading] = useState(false);

  const initialFormData = {
    name: '',
    category: '',
    headerHtml: '',
    footerHtml: '',
    bodyHtml: '',
    isEmail: true,
    status: 0
  };
  const [formData, setFormData] = useState(initialFormData);

  const showPagination = false;
  const [sortData, setSortData] = useState({
    sortProperty: 'Name',
    isDescending: false
  });
  const reloadTable = (page) => {
    setLoading(true);
    getReportTemplateLists({
      page: page || currentpage,
      pageSize,
      search: isSearched ? searchText : '',
      type: filterType?.value || 0,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending
    }).then((res) => {
      setLoading(false);
      setTableData(res.data);
      setCount(res?.data?.totalItems || 0);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize, filterType]);
  /* istanbul ignore next */
  const saveApiCall = (flag = false) => {
    const cnt = formData;
    if (cnt?.bodyHtml.includes('<hr>')) {
      cnt.bodyHtml = cnt?.bodyHtml.replaceAll('<hr>', '<hr style="height: 6px; background-color: rgb(0,175,239); border: none; margin: 0;px">');
    }
    console.log('cnt', cnt);
    if (formValidation(['name', 'category'], cnt)) {
      setSiteLoading(true);
      saveTemplates(cnt).then((res) => {
        setSiteLoading(false);
        if (res.success) {
          if (!flag) {
            showAlert('Successfully Saved', 'success');
            reloadTable();
          } else {
            publishTemplate({ id: formData.id }).then((resp) => {
              if (resp.success) {
                showAlert('Successfully Published', 'success');
                reloadTable();
                setOpenModal(false);
                setOpenPublishModal(false);
              } else {
                setOpenPublishModal(false);
              }
            });
          }
          setFormData(initialFormData);
          setOpenModal(false);
        } else {
          setOpenPublishModal(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const editData = (data) => {
    setSiteLoading(true);
    getReportTemplate(data.id).then((res) => {
      const cnt = res.data;
      if (cnt?.bodyHtml.includes('<hr style="height: 6px; background-color: rgb(0,175,239); border: none; margin: 0;px">')) {
        cnt.bodyHtml = cnt?.bodyHtml.replaceAll('<hr style="height: 6px; background-color: rgb(0,175,239); border: none; margin: 0;px">', '<hr>');
      }
      setFormData(cnt);
      setOpenModal(true);
      setSiteLoading(false);
    });
  };
  const viewData = (data) => {
    setSiteLoading(true);
    getReportTemplate(data.id).then((res) => {
      const cnt = res.data;
      if (cnt?.bodyHtml.includes('<hr style="height: 6px; background-color: rgb(0,175,239); border: none; margin: 0;px">')) {
        cnt.bodyHtml = cnt?.bodyHtml.replaceAll('<hr style="height: 6px; background-color: rgb(0,175,239); border: none; margin: 0;px">', '<hr>');
      }
      setFormData(cnt);
      setViewModal(true);
      setSiteLoading(false);
    });
  };
  const deteleData = async (data) => {
    await setSiteLoading(true);
    await setFormData(data);
    await setOpenDeleteModal(true);
    await setSiteLoading(false);
  };

  const deleteApiCall = () => {
    setSiteLoading(true);
    deleteTemplate(formData.id).then((res) => {
      setSiteLoading(false);
      if (res.success) {
        setFormData(initialFormData);
        reloadTable();
        setOpenDeleteModal(false);
        showAlert('Deleted successfully', 'success');
      }
    });
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearched = true;
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const onCloseWindow = () => {
    setOpenModal(false);
    setViewModal(false);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const publish = () => {
    saveApiCall(true);
  };
  const publishConfirm = () => {
    setOpenPublishModal(true);
  };
  const newTemplate = () => {
    setOpenModal(true);
    setFormData(initialFormData);
  };
  const showEdit = () => {
    setOpenModal(true);
    setViewModal(false);
  };
  const onFilterChange = (e) => {
    setCurrentpage(1);
    setFilterType(e);
    isSearched = true;
  };
  useEffect(() => {
    reloadTable();
  }, [sortData]);

  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  return (
    <div data-testid="templates">
      {loading ? <Loader /> : (
        <div className="card p-5  height-fix">
          <div className="d-flex mb-3 col-gap-1 spec-search">
            <div className="search large">
              <div className="d-flex justify-content-end">
                <span
                  className={searchText ? ' icon-close-line ' : ''}
                  onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                />
                <span
                  id="lenssearch"
                  className="icon-search"
                  onClick={() => {
                    onSearch({ key: 'Enter' });
                  }}
                />
              </div>

              <input
                id="search"
                type="text"
                value={searchText}
                className="text-box large with-close"
                placeholder="Search by Template Name"
                onKeyPress={onSearch}
                onChange={(e) => {
                  isSearched = false;
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className="search ">
              <Select
                options={TemplateTypes}
                className="modal-select"
                onChange={onFilterChange}
                placeholder="Select Category"
                value={filterType}
                isClearable
              />
            </div>
            <button
              id="add-temp"
              type="button"
              className="primary-btn large"
              onClick={() => {
                newTemplate();
              }}
            >
              Add Template
            </button>
          </div>
          {siteLoading ? (
            <Loader />
          ) : (
            <div>
              <TemplateTable
                tableData={tableData}
                pageClick={pageClick}
                currentpage={currentpage}
                showPagination={showPagination}
                editData={editData}
                deteleData={deteleData}
                onSizeChange={onSizeChange}
                viewData={viewData}
                handleSort={handleSort}
                sortData={sortData}
                loading={loading}
                setFormData={setFormData}
              />
              {tableData?.totalItems > 10 && (
              <Pagination
                pageClick={pageClick}
                pageCount={tableData.totalPages}
                currentpage={currentpage}
                onSizeChange={onSizeChange}
                size={pageSize}
              />
              )}
              {openModal && (
              <CreateTemplate
                formData={formData}
                setFormData={setFormData}
                TemplateTypes={TemplateTypes}
                onSave={saveApiCall}
                publish={publishConfirm}
                onCloseWindow={onCloseWindow}
                showView={openModal}
              />
              )}
              {viewModal && (
              <ViewTemplate
                formData={formData}
                setFormData={setFormData}
                TemplateTypes={TemplateTypes}
                onCloseWindow={onCloseWindow}
                showView={viewModal}
                showEdit={showEdit}
              />
              )}
              {openDeleteModal ? (
                <ModalBox
                  modalClassName="medium"
                  heading="Delete"
                  onSave={() => deleteApiCall()}
                  closeModal={() => setOpenDeleteModal(false)}
                  buttonName="Delete"
                >
                  <p>Are you sure you want to delete Template?</p>
                </ModalBox>
              ) : (
                ''
              )}
              {openPublishModal ? (
                <ModalBox
                  modalClassName="medium"
                  heading="Publish"
                  onSave={() => publish()}
                  closeModal={() => setOpenPublishModal(false)}
                  buttonName="Publish"
                >
                  <p>Are you sure you want to publish Template?</p>
                </ModalBox>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      )}

    </div>
  );
};
export default Templates;
