/* istanbul ignore file */
import React from 'react';

const InputType1 = ({ type, label = '', className = '', maxLength = '', isMandatory = 'false', noMargin = false, contactheight, ...rest }) => {
  return (

    <div className="input1-wrap">

      <label className={noMargin ? '' : 'mb-2'}>

        {label}

        {isMandatory === 'true' ? <span className="text-primaryRed-1">*</span> : ''}

      </label>

      <input type={type} {...rest} className={`text-box ${className}`} maxLength={maxLength} onWheel={(e) => e.target.blur()} />

    </div>

  );
};

export default InputType1;
