/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import SidePanel from '../../../common/sidePanelCustom';
import DocumentContainer from './DocumentContainer';
import DocumentSidebar from './DocumentSidebar';
import SearchResults from './SearchResults';
import { getFolders, SaveData, GetSystemFiles, GetData, GetSearchData } from './services';
import SystemDocumentContainer from './SystemDocumentContainer';

const DmsContainer = ({ projInfo, onCloseWindow, openModal, setOpenDeleteModal, isTsiDisabled }) => {
  const { id } = useParams();
  const { ZERO_UUID, formValidation } = helper;
  const projectFromId = id;

  const [parentFromId, setParentFromId] = useState(ZERO_UUID);
  const [folders, setFolders] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingContainer, setLoadingContainer] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [isSearchTextUpdated, setIsSearchTextUpdated] = useState(false);
  const [folderActiveData, setFolderActiveData] = useState('');

  const initialValue = {
    name: '',
    parentId: ZERO_UUID,
    projectId: projectFromId,
    isFolder: true,
    document: {
      fileName: '',
      fileType: '',
      size: 0,
      path: ''
    }
  };
  const [formData, setFormData] = useState(initialValue);
  const [isAddNewShow, setIsAddNewShow] = useState(false);
  const [folderType, setFolderType] = useState(1);
  const reloadTable = () => {
    getFolders(projectFromId).then((res) => {
      console.log('folder', res);
      setFolders(res.data);
      setLoading(false);
    });
  };
  const handleCreateNew = () => {
    console.log(formData);
    if (formValidation(['name'], formData)) {
      SaveData(formData).then((res) => {
        if (res.success) {
          reloadTable();
          setIsAddNewShow(false);
          setFormData(initialValue);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const cancel = () => {
    setIsAddNewShow(false);
    setFormData(initialValue);
  };

  const handleFolderData = (item, type, sortProperty = 'UpdatedAt', isDescending = true) => {
    setLoadingContainer(true);
    if (item) {
      setFolderActiveData(item);
      if (folderType === 1) {
        // Systen Files
        const inPut = {
          projectId: projectFromId,
          parentId: item.id,
          phase: item.phase,
          sortProperty,
          isDescending
        };
        GetSystemFiles(inPut).then((res) => {
          console.log('folder', res);
          setSubFolders(res.data);
          setParentFromId(ZERO_UUID);
          setLoadingContainer(false);
        });
      } else if (folderType === 2 && item.id !== ZERO_UUID) {
        // Custom Files
        const inPut = {
          projectId: projectFromId,
          parentId: item.id,
          phase: 0,
          sortProperty,
          isDescending
        };
        GetData(inPut).then((res) => {
          setSubFolders(res.data);
          setParentFromId(item.id);
          setLoadingContainer(false);
        });
      } else {
        setLoadingContainer(false);
      }
    } else {
      setSubFolders([]);
      setLoadingContainer(false);
    }
  };

  useEffect(() => {
    reloadTable();
  }, [openModal]);

  const reloadSearch = (page, pageZ, folderTypeChanged = '') => {
    GetSearchData({
      page: page || currentpage,
      pageSize: pageZ || pageSize,
      search: searchText,
      sortProperty: 'Name',
      isDescending: false,
      type: folderTypeChanged || folderType,
      id: projectFromId
    }).then((res) => {
      setTableData(res.data);
    });
  };
  const clearContainer = (folderTypeChanged) => {
    reloadSearch(1, '', folderTypeChanged);
    setCurrentpage(1);
    setSubFolders([]);
    setParentFromId(ZERO_UUID);
    if (searchText) {
      setIsSearchTextUpdated(true);
    } else {
      setIsSearchTextUpdated(false);
    }
  };
  const clearSearch = () => {
    setSearchText('');
    document.getElementById('search')?.focus();
    setCurrentpage(1);
    setPageSize(10);
    setTableData();
    reloadTable();
    setIsSearchTextUpdated(false);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      if (searchText) {
        setCurrentpage(1);
        setIsSearchTextUpdated(true);
        reloadSearch();
      } else {
        clearSearch();
      }
    }
  };
  // useEffect(() => {
  //   if (!searchText) {
  //     reloadTable();
  //   }
  // }, [searchText]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const pageClick = (e) => {
    reloadSearch(e.selected + 1, pageSize, folderType);
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    reloadSearch(1, e.target.value, folderType);
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const showView = openModal === 'dms';
  return (
    <div className="scroll-wrap" data-testid="dms-5">
      <SidePanel title="Document Repository" size="large document-side-panel" onClose={onCloseWindow} showView={showView}>
        <div className="side-panel__container bg-white">
          <div className="side-panel__content">
            <div className="doc-shadow doc-subhead">
              <div className="col-gap-1 d-flex ">
                <div className="search large spec-search">
                  <div className="d-flex justify-content-end">
                    <span className="icon-search" id="searchEnter" onClick={() => { onSearch({ key: 'Enter' }); }} />
                    <span
                      className={searchText ? ' icon-close-line ' : ''}
                      onClick={() => { clearSearch(); }}
                    />
                  </div>

                  <input
                    type="text"
                    id="search"
                    className="text-box large with-close"
                    placeholder="Search by Filename"
                    onKeyPress={onSearch}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
                {isSearchTextUpdated ? (
                  <div className="mt-4">
                    Showing search results in
                    <b>{` ${folderType === 1 ? 'System Files' : 'Custom Files'}`}</b>
                  </div>
                ) : ''}
              </div>
            </div>
            <div className="d-flex">
              <div className="left-wrap">
                {loading && (
                  <div className="content-loader">
                    <div className="loader" />
                    <span className="text">Loading, Please Wait...</span>
                  </div>
                )}
                {!loading && (
                <DocumentSidebar
                  clearContainer={clearContainer}
                  handleFolderData={handleFolderData}
                  isAddNewShow={isAddNewShow}
                  setIsAddNewShow={setIsAddNewShow}
                  isTsiDisabled={isTsiDisabled}
                  folders={folders}
                  handleCreateNew={handleCreateNew}
                  formData={formData}
                  setFormData={setFormData}
                  cancel={cancel}
                  reloadTable={reloadTable}
                  folderType={folderType}
                  setFolderType={setFolderType}
                  isSearchTextUpdated={isSearchTextUpdated}
                  setParentFromId={setParentFromId}
                  parentFromId={parentFromId}
                  projInfo={projInfo}
                />
                )}
              </div>
              {isSearchTextUpdated
                ? (
                  <div className="right-wrap">
                    <SearchResults
                      subFolders={tableData}
                      pageClick={pageClick}
                      currentpage={currentpage}
                      onSizeChange={onSizeChange}
                      pageSize={pageSize}
                    />
                  </div>
                )
                : (
                  <div className="right-wrap">
                    {loadingContainer && (
                    <div className="content-loader">
                      <div className="loader" />
                      <span className="text">Loading, Please Wait...</span>
                    </div>
                    )}
                    {folderType === 1 && !loadingContainer
                      ? (
                        <SystemDocumentContainer
                          folderActiveData={folderActiveData}
                          folderType={folderType}
                          handleFolderData={handleFolderData}
                          projectFromId={projectFromId}
                          parentFromId={parentFromId}
                          subFolders={subFolders}
                          setOpenDeleteModal={setOpenDeleteModal}
                        />
                      ) : (
                        <>
                          {!loadingContainer && (
                          <DocumentContainer
                            folderType={folderType}
                            isTsiDisabled={isTsiDisabled}
                            handleFolderData={handleFolderData}
                            projectFromId={projectFromId}
                            parentFromId={parentFromId}
                            subFolders={subFolders}
                            setOpenDeleteModal={setOpenDeleteModal}
                            projInfo={projInfo}
                          />
                          )}
                        </>
                      )}
                  </div>
                )}
            </div>
          </div>
        </div>

      </SidePanel>
    </div>
  );
};
export default DmsContainer;
