/* eslint-disable no-unused-vars */
import axios from 'axios';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import { serviceHandler } from '../../../../utils/serviceHandler';
import API_END_POINTS from '../../../../utils/apiUrlConstants';

const {
  getPhotoUrl,
  savePhotoUrl,
  JobProgressReportUrl,
  VariationOrderReportUrl,
  CostUpdationReportUrl,
  SofReportUrl,
  DailyReportDataListUrl,
  SaveDailyReportDataListUrl,
  downloadDailyReportUrl,
  downloadPhotoReportUrl,
  PhotoReportHistoryUrl,
  DailyReportHistoryUrl,
  jpReportDownloadUrl,
  sofReportDownloadUrl,
  vopReportDownloadUrl,
  cupReportDownloadUrl,
  PoReportUrl,
  WoReportUrl,
  poReportDownloadUrl,
  woReportDownloadUrl,
  VarianceReport,
  GetProjectBasicDetailsUrl,
  DownloadVarianceReport,
  ShareReport,
  SendMailLink,
  getAcCodeReportUrl,
  DownloadAcCodeReportUrl
} = API_END_POINTS;
const { downloadFile, getExportedFileName } = helper;

const handleError = (error) => {
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(JSON.parse(reader.result));
    };

    reader.onerror = () => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  })
    .then((data) => showAlert(data?.Message || 'Something went wrong', 'error'))
    .catch(() => showAlert('Something went wrong', 'error'));
};

export const getPhoto = (payload) => {
  return serviceHandler({
    url: `${getPhotoUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const getPhotoHistory = (payload) => {
  return serviceHandler({
    url: `${PhotoReportHistoryUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const getDailyHistory = (payload) => {
  return serviceHandler({
    url: `${DailyReportHistoryUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const savePhoto = (payload) => {
  return serviceHandler({
    url: `${savePhotoUrl}`,
    methordType: 'post',
    payload
  });
};
export const getJobProgressReport = (payload) => {
  return serviceHandler({
    url: `${JobProgressReportUrl}`,
    methordType: 'post',
    payload
  });
};

export const getVariationOrderReport = (payload) => {
  return serviceHandler({
    url: `${VariationOrderReportUrl}`,
    methordType: 'post',
    payload
  });
};
export const getCostUpdationReport = (payload) => {
  return serviceHandler({
    url: `${CostUpdationReportUrl}`,
    methordType: 'post',
    payload
  });
};
export const getSofReport = (payload) => {
  return serviceHandler({
    url: `${SofReportUrl}`,
    methordType: 'post',
    payload
  });
};
export const getDailyReportDataList = (payload) => {
  return serviceHandler({
    url: `${DailyReportDataListUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const saveDailyReportDataList = (payload) => {
  return serviceHandler({
    url: `${SaveDailyReportDataListUrl}`,
    methordType: 'post',
    payload
  });
};
export const downloadDailyReport = (payload) => {
  return axios
    .get(`${downloadDailyReportUrl}?projectId=${payload}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};

export const downloadPhotoReport = (projectId, id) => {
  return axios
    .get(`${downloadPhotoReportUrl}?projectId=${projectId}&&reportId=${id}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};
export const downloadVarianceReport = (payload) => {
  return axios
    .get(`${DownloadVarianceReport}?projectId=${payload.id}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};

export const jpReportDownload = (payload) => {
  return axios
    .post(`${jpReportDownloadUrl}`, payload, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};

export const sofReportDownload = (payload) => {
  return axios
    .post(`${sofReportDownloadUrl}`, payload, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};

export const cupReportDownload = (payload) => {
  return axios
    .post(`${cupReportDownloadUrl}?projectId=${payload.projectId}`, {}, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};

export const vopReportDownload = (payload) => {
  return axios
    .post(`${vopReportDownloadUrl}`, payload, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};
export const getPOReport = (payload) => {
  return serviceHandler({
    url: `${PoReportUrl}`,
    methordType: 'post',
    payload
  });
};
export const getWOReport = (payload) => {
  return serviceHandler({
    url: `${WoReportUrl}`,
    methordType: 'post',
    payload
  });
};
export const pOReportDownload = (payload) => {
  return axios
    .get(`${poReportDownloadUrl}?projectId=${payload?.projectId}&Status=${payload.status}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};
export const wOReportDownload = (payload) => {
  return axios
    .get(`${woReportDownloadUrl}?projectId=${payload?.projectId}&Status=${payload.status}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};
export const getShareReport = (payload) => {
  return serviceHandler({
    url: `${ShareReport}`,
    methordType: 'post',
    payload
  });
};

export const getVarianceReport = (payload) => {
  return serviceHandler({
    url: `${VarianceReport}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const SendMail = (payload) => {
  return serviceHandler({
    url: `${SendMailLink}`,
    methordType: 'post',
    payload
  });
};
export const getAcCodeReport = (payload) => {
  return serviceHandler({
    url: `${getAcCodeReportUrl}`,
    methordType: 'post',
    payload
  });
};
export const DownloadAcCodeReport = (payload) => {
  return axios
    .post(`${DownloadAcCodeReportUrl}?projectId=${payload}`, {}, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};
