/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../../common/pagination';

const AcCodeReportComponent = ({ tableData, downloadReport,
  setPage, setPageSize, page, pageSize, projInfo }) => {
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  return (
    <div className="p-4 mb-4" data-testid="AcCodeReportComponent">
      {tableData && tableData?.items?.length ? (
        <>
          <div>
            <div className="scroll-wrap">
              {tableData?.items.map((i) => {
                return (
                  <div className="border p-3 my-4">
                    <div className="font-16 font-bold row py-3">
                      <div className=" col-6">
                        <div className="text-ellipsis " data-tooltip-id="project-job" data-tooltip-content={i.acCodeName || ''}>
                          AC Code
                          {' - '}
                          {i.acCodeName}
                        </div>
                      </div>
                      <div className=" col-6">
                        <div className="text-ellipsis ">
                          {projInfo?.currencySymbol}
                          {' '}
                          {i.totalAmount || 0}
                        </div>
                      </div>
                    </div>
                    <div className="bg-blue font-16 font-bold py-2 row">
                      <div className="col-6 font-semibold px-3">Service Line</div>
                      <div className="col-6 font-semibold">Amount</div>
                    </div>
                    {i?.servLines.map((item) => {
                      return (
                        <div className=" row py-3">
                          <div className="col-6 px-3 ">
                            <div className="d-flex">
                              <div className="text-ellipsis me-3" data-tooltip-id="project-job" data-tooltip-content={item.serviceLinesNumber || ''}>
                                {item.serviceLinesNumber}
                              </div>
                              <div className="text-ellipsis" data-tooltip-id="project-job" data-tooltip-content={item.serviceLinesName || ''}>
                                {item.serviceLinesName}
                              </div>
                            </div>
                          </div>
                          <div className=" col-6">
                            <div className=" text-ellipsis ">
                              {projInfo?.currencySymbol}
                              {' '}
                              {item.amount || 0}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

          {tableData?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className=" footer-jobprogress">© 2024 ThinkPalm</div>

            </div>
            <button data-test="click1" onClick={() => downloadReport()} type="button" className="primary-btn large mx-3">
              Download Report
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="fixed-footer card d-flex justify-content-end">

            <div className="text-black font-12 col-6">
              <div className="mt-2 mb-2">© 2024 ThinkPalm</div>

            </div>
          </div>
        </>
      )}
      <Tooltip id="project-job" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default AcCodeReportComponent;
