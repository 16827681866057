import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import ModalBox from '../../../../common/ModalBox';

const ServiceLineModal = (props) => {
  const { setShowServiceLineModal, emptyAcCode,
    setContinueServs, setSendForApproval } = props;
  const handleContinue = () => {
    setContinueServs(true);
    if (emptyAcCode?.length > 0) {
      setShowServiceLineModal(false);
      setSendForApproval(false);
    }
  };

  return (
    <div data-testid="ServiceLine">
      {emptyAcCode?.length > 0 && (
        <ModalBox
          modalClassName="large modal-scroll"
          className="secondary-btn"
          heading="Submit Specs for Approval"
          buttonName="Update"
          removeCancelBtn
          onSave={handleContinue}
          closeModal={() => setShowServiceLineModal(false)}
          text="Submitted for Approval"
        >
          <div className="py-3">
            {emptyAcCode?.length > 0
              ? 'Specs cannot be Submitted for Approval as there are service lines added without AC code for the following list'
              : ''}
          </div>
          {emptyAcCode?.map((items) => {
            return (
              <div className="mt-3 py-2 d-flex align-items-center bg-sky-white md-radius">

                <div className="ps-4 w-100">
                  <span data-tooltip-id="project-line" data-tooltip-content={items?.number}>
                    {_.truncate(items?.number, {
                      length: 20
                    })}
                  </span>
                </div>
                <div className="ms-5">
                  <span data-tooltip-id="project-line" data-tooltip-content={items?.title}>
                    {_.truncate(items?.title, {
                      length: 50
                    })}
                  </span>
                </div>
              </div>
            );
          })}
        </ModalBox>
      )}
      <Tooltip id="project-line" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ServiceLineModal;
