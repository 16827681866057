/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import ModalBox from '../../../common/ModalBox';
import AddSubTypeVesselData from './AddSubTypeVesselData';
import AddVesselData from './AddVesselData';
import CardWrapper from './cardWrapper';
import { deleteVesselType, getVesselType, saveVesselSubType, saveVesselType, deleteVesselSubType, exportExcelVesselType } from './services';
import Pagination from '../../../common/pagination';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import { GetVesselCategoryList } from '../GeneralConfig/services';

const VesselDataManagement = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { generateOptions, formValidation, ZERO_UUID, addColor } = helper;
  const [searchText, setSearchText] = useState('');
  const [isSearchTextUpdated, setIsSearchTextUpdated] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const [vesselData, setVesselData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openSubTypeModal, setOpenSubTypeModal] = useState(false);
  const [openSubTypeDeleteModal, setOpenSubTypeDeleteModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const initialFormData = {
    name: '',
    shortName: '',
    description: '',
    status: 0
  };
  const [formData, setFormData] = useState(initialFormData);
  const initialFormDataSubType = {
    name: '',
    vesselType: '',
    description: '',
    status: 0
  };
  const [formDataSubType, setFormDataSubType] = useState(initialFormDataSubType);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);

  const reloadTable = (page) => {
    getVesselType({
      page: page || currentpage,
      pageSize,
      search: searchText
    }).then((res) => {
      setLoading(false);
      if (res.data) setVesselData(res.data);
      setCount(res?.data?.totalItems || 0);
      console.log(res.data, 'vesselData');
    });
  };

  useEffect(() => {
    const topPos = document.getElementById('header-id').offsetTop;
    document.getElementById('container').scrollTop = topPos - 10;
    reloadTable();
  }, [currentpage, pageSize]);

  useEffect(() => {
    GetVesselCategoryList().then((res) => {
      if (res.data && res.data.length) {
        setCategoryList(generateOptions(res.data, 'id', 'name'));
      }
    });
  }, []);
  useEffect(() => {
    setLoading(true);
  }, []);
  const editData = (data) => {
    setFormData(data);
    setOpenEditModal(true);
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    deleteVesselType(formData.id).then((res) => {
      if (res.success) {
        setFormData(initialFormData);
        reloadTable();
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const deleteSubApiCall = () => {
    deleteVesselSubType(formDataSubType.id).then((res) => {
      if (res.success) {
        setFormDataSubType(initialFormDataSubType);
        reloadTable();
        setOpenSubTypeDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const saveApiCall = () => {
    if (!formData.categoryId
      || formData.categoryId === ZERO_UUID) {
      addColor('categoryId', 'add');
    }
    if (formValidation(['name'], formData)) {
      addColor('name', 'add');
    }
    if (formData.categoryId
      && formData.categoryId !== ZERO_UUID
      && formValidation(['name'], formData)) {
      saveVesselType(formData).then((res) => {
        if (res.success) {
          setOpenModal(false);
          setOpenEditModal(false);
          reloadTable();
          setFormData(initialFormData);
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const saveSubTypeApiCall = () => {
    if (formValidation(['name'], formDataSubType)) {
      saveVesselSubType(formDataSubType).then((res) => {
        if (res.success) {
          reloadTable();
          setFormDataSubType(initialFormDataSubType);
          setOpenSubTypeModal(false);
          showAlert('Successfully Saved', 'success');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
    setOpenModal(false);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const openSubTModal = () => {
    setOpenSubTypeDeleteModal(true);
  };
  const handleExcelExportVessels = () => {
    if (vesselData.items.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelVesselType(searchText);
    }
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  return (
    <div className="card p-5 height-fix" data-testid="vessel-data">
      <div>
        {openModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Add Vessel Type"
            closeModal={() => setOpenModal(false)}
            buttonName="Save"
            onSave={() => saveApiCall()}
          >
            <AddVesselData
              formData={formData}
              setFormData={setFormData}
              categoryList={categoryList}
            />
          </ModalBox>
        ) : (
          ''
        )}

        {openEditModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Update Vessel Type"
            onSave={() => saveApiCall()}
            closeModal={() => setOpenEditModal(false)}
            buttonName="Update"
          >
            <AddVesselData
              formData={formData}
              setFormData={setFormData}
              categoryList={categoryList}
            />
          </ModalBox>
        ) : (
          ''
        )}
        {openDeleteModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Delete"
            onSave={() => deleteApiCall()}
            closeModal={() => setOpenDeleteModal(false)}
            buttonName="Delete"
          >
            <p>Are you sure you want to delete Vessel Type ?</p>
          </ModalBox>
        ) : (
          ''
        )}
        {openSubTypeModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Add Vessel Sub Type"
            closeModal={() => setOpenSubTypeModal(false)}
            buttonName="Save"
            onSave={() => saveSubTypeApiCall()}
          >
            <AddSubTypeVesselData formData={formDataSubType} setFormData={setFormDataSubType} />
          </ModalBox>
        ) : (
          ''
        )}
        {openSubTypeDeleteModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Delete"
            onSave={() => deleteSubApiCall()}
            closeModal={() => setOpenSubTypeDeleteModal(false)}
            buttonName="Delete"
          >
            <p>Are you sure you want to delete Vessel Sub Type ?</p>
          </ModalBox>
        ) : (
          ''
        )}
      </div>
      <div className="d-flex mb-3 justify-content-between">
        <div className="d-flex col-gap-1">
          <div className="search large spec-search">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              className="text-box large with-close"
              value={searchText}
              placeholder="Search by Vessel Type "
              onKeyPress={onSearch}
              id="search"
              onChange={(e) => {
                setSearchText(e.target.value);
                setIsSearchTextUpdated(false);
              }}
            />
          </div>
          <button
            type="button"
            id="add-vessel"
            onClick={() => {
              setFormData(initialFormData);
              setOpenModal(true);
            }}
            className="primary-btn large"
          >
            Add Vessel Type
          </button>

        </div>
        <div className="d-flex col-gap-1 justify-content-end">
          <button
            type="button"
            className="link-btn large "
            id="excel"
            onClick={handleExcelExportVessels}
          >

            <span className="icon-download me-4" />

            Excel Download
          </button>
        </div>
      </div>
      <div id="container" className="scroll-wrap-v">
        <div id="header-id" />
        <CardWrapper
          vesselData={vesselData}
          setOpenSubTypeModal={setOpenSubTypeModal}
          setOpenSubTypeDeleteModal={openSubTModal}
          formDataSubType={formDataSubType}
          setFormDataSubType={setFormDataSubType}
          editData={editData}
          deteleData={deteleData}
          onSearch={onSearch}
          loading={loading}
          pageClick={pageClick}
          onSizeChange={onSizeChange}
        />
        {vesselData?.totalItems > 10 && (
          <Pagination
            pageClick={pageClick}
            pageCount={vesselData.totalPages}
            currentpage={currentpage}
            onSizeChange={onSizeChange}
            size={pageSize}
          />
        )}
      </div>
    </div>
  );
};

export default VesselDataManagement;
