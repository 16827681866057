import React, { useState } from 'react';

const VesselAttachments = (props) => {
  const { attachments, data } = props;
  const [expand, setExpand] = useState(false);
  return (
    <div className="border md-radius" data-testid="VesselAttachments">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer'}
            onClick={() => { setExpand(!expand); }}
            data-testid="expand-arrow-right"
          />
        </div>
      </div>
      {expand && (
      <div className="ms-3 col-11">
        {attachments?.map((item) => {
          return (
            <div className=" d-flex py-2">
              <div className="">
                <span className="icon-file-text2 brand-color font-22 me-3" />
              </div>
              <div className="">
                <div className="label">{item?.documentCategoryName}</div>
                {
                item?.projectAttachments?.map((field) => {
                  return (
                    <div className="d-flex align-items-center ms-3 ps-2">
                      <div><a href={field.physicalFileName} target="_blank" rel="noreferrer">{field.name}</a></div>
                      <div className="pt-2">
                        <a href={field.physicalFileName} target="_blank" rel="noreferrer" className="ms-4">
                          <span className="icon-eye font-20 brand-color pointer" />
                        </a>
                      </div>
                    </div>
                  );
                })
            }
              </div>
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
};
export default VesselAttachments;
