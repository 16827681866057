/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { toLower } from 'lodash';
import Pagination from '../../../common/pagination';
import { fileImages } from '../../../common/mappingConst';

const SearchResults = ({
  subFolders,
  pageClick,
  currentpage,
  onSizeChange,
  pageSize
}) => {
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };
  return (
    <div className="" data-testid="dms-6">
      {subFolders && subFolders?.items?.length ? (
        <div className="scroll-pannel-search mb-5">
          {subFolders.totalItems > 1 ? <div className="ms-5">{`${subFolders.totalItems} records found`}</div>
            : <div className="ms-5">{`${subFolders.totalItems} record found`}</div>}
          {subFolders
            && subFolders?.items?.map((c) => {
              // if (c.isFolder) return '';
              return (
                <div className="ms-5 mt-3 p-4 bg-white d-flex justify-content-between ">
                  <div className="d-flex justify-content-start ">
                    {c.isFolder
                      ? <span className="font-26 icon-folder ms-4 text-yellow" />
                      : (
                        <div className="thmb-icon-md">
                          <span className={fileImages[toLower(c?.type)]} />
                          {/* <img src={fileImages[c?.type]} alt="" style={{ height: 24 }} /> */}
                        </div>
                      )}
                    <div className="c-w-300 text-ellipsis">
                      <span
                        data-tooltip-id="project-dms"
                        data-tooltip-content={c.name}
                        className="ms-3 text-ellipsis"
                      >
                        {c.name}
                      </span>
                      <div className="d-flex mt-1 ms-3">
                        <div className="border-right pe-3">{moment(c.updatedAt).format('DD-MMM-YYYY HH:mm')}</div>
                        <div className="border-right px-3">{formatBytes(c.size)}</div>
                        <div className="px-3 text-uppercase text-ellipsis">
                          <span
                            data-tooltip-id="project-dms"
                            data-tooltip-content={c.createdBy}
                          >
                            {c.createdBy}
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <a
                      href={c?.path}
                      target="_blank"
                      download="downloaded_file"
                      className=""
                      rel="noreferrer"
                    >
                      <span className="icon-download font-20 me-2 text-blue cursor-pointer" />
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="d-flex justify-content-center p-5">No records found</div>
      )}
      {subFolders?.totalItems > 10 && (
      <Pagination
        pageClick={pageClick}
        pageCount={subFolders.totalPages}
        currentpage={currentpage}
        onSizeChange={onSizeChange}
        size={pageSize}
      />
      )}
      <Tooltip id="project-dms" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default SearchResults;
