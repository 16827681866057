/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import CardWrapper from './cardWrapper';
import { getVesselList, bulkUploadVessel, generateTemplate } from './services';
import BulkUploadModal from '../../DataConfig/CurrencyContainer/bulkUploadModal';
import ModalBox from '../../../common/ModalBox';
import showAlert from '../../../../utils/alert';
import Pagination from '../../../common/pagination';
import helper from '../../../../utils/helper';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';
import Loader from '../../../common/loader';

const Vessel = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const [loading, setLoading] = useState(false);
  const [vessels, setVessels] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isUpload, setIsUpload] = useState(true);
  const [file, setFile] = useState();
  const [uploadModal, setUploadModal] = useState(false);
  const [sortData, setSortData] = useState({
    sortProperty: 'name',
    isDescending: false
  });
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const { MAX_FILE_SIZE } = helper;
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const reloadTable = (page) => {
    getVesselList({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        setVessels(res.data);
        setCount(res?.data?.totalItems || 0);
      }
    });
  };
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }

    e.target.files = [];

    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('vesselDataFile', file);

    bulkUploadVessel(body).then((res) => {
      setLoading(false);
      if (res.success) {
        reloadTable();
        // showAlert(res.error.response.data.Message, res.success ? 'success' : 'error');
        showAlert(res.success ? 'Successfully Uploaded' : res.error?.response?.data?.message, res.success ? 'success' : 'error');
        setUploadModal(false);
        setFile();
      } else {
        setUploadModal(false);
        setFile('');
        setIsUpload(true);
      }
    });
  };

  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const onSearchText = (e) => {
    setSearchText(e.target.value);
  };
  const onSizeChange = (e) => {
    setLoading(true);
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const pageClick = (e) => {
    setLoading(true);
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    reloadTable();
  }, [sortData]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };
  const generatTemplateForExcel = () => {
    generateTemplate();
  };
  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  return (
    <div className="container" data-testid="v-vessel">
      {openModal ? (
        <ModalBox
          heading="Upload Excel"
          closeModal={() => {
            setOpenModal(false);
          }}
          buttonName="Save"
          modalClassName="medium modal-scroll"
        />
      ) : (
        ''
      )}
      {openDeleteModal ? <ModalBox modalClassName="medium" heading="Delete" closeModal={() => setOpenDeleteModal(false)} buttonName="Delete" /> : ''}
      <div className="d-flex my-4 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search">
          <div className="search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
              />
              <span
                id="lens"
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              id="search"
              className="text-box large with-close"
              value={searchText}
              placeholder="Search by Name, Management"
              onKeyPress={onSearch}
              onChange={onSearchText}
            />
          </div>
          <button type="button" className="primary-btn large" disabled>
            Add Vessel
          </button>
          <button
            type="button"
            className="secondary-btn large"
            id="add-name"
            onClick={() => {
              setUploadModal(true);
            }}
          >
            Bulk Upload
          </button>
          {uploadModal && (
            <ModalBox
              modalClassName="medium"
              heading="Upload Excel"
              onSave={() => {
                bulkUpload();
              }}
              closeModal={() => onCloseModal()}
              buttonName="Upload"
              saveDisable={isUpload}
            >
              <BulkUploadModal
                file={file}
                ondrop={ondrop}
                name="Vessel"
                setFile={setFile}
                generatTemplateForExcel={generatTemplateForExcel}
                vessels={vessels}
              />
            </ModalBox>
          )}
        </div>
        {/* <div className="d-flex col-gap-1">
          <button type="button" id="excel" className="link-btn large ">
            <span className="icon-download me-4" />
            Excel Download
          </button>
        </div> */}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CardWrapper
            vessels={vessels}
            handleSort={handleSort}
            sortData={sortData}
            loading={loading}
          />
          {vessels?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={vessels.totalPages}
              currentpage={currentpage}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
        </>
      )}
      <div className="footer justify-content-between font-13 m-0">
        <PolicyFooter setShowPrivacyPolicy={setShowPrivacyPolicy} setShowTermsPolicy={setShowTermsPolicy} />
      </div>
      {showPrivacyPolicy && <PrivacyPolicy setShowPrivacyPolicy={setShowPrivacyPolicy} showPrivacyPolicy={showPrivacyPolicy} />}
      {showTermsPolicy && <TermsOfUse setShowTermsPolicy={setShowTermsPolicy} showTermsPolicy={showTermsPolicy} />}
    </div>
  );
};
export default Vessel;
