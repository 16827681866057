import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import TableSortHeader from '../../../common/TableSortHeader';
import TableLoader from '../../../common/TableLoader';

const UomTable = (props) => {
  const { tableData, deteleData, handleSort, sortData } = props;
  const column = [
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Unit Name"
            headingProperty="uomName"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      accessor: 'uomName',
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-uomName"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Units"
            headingProperty="unit"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      accessor: 'unit',
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-uomName"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: 'packageName',
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <>
            <div
              data-tooltip-id="project-uomName"
              data-tooltip-content={original?.mappedMessage}
            >
              <button
                id="delete"
                type="button"
                disabled={original.isMapped}
                onClick={() => deteleData(original)}
              >
                <span className="icon-delete font-24 brand-color ms-3" />
              </button>
            </div>
            <Tooltip id="project-uomName" place="top" className="tooltip mt-4" />
          </>

        );
      }
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="table-uom">
      <ReactTable
        className="table table-scroll"
        data={tableData?.items}
        pageSize={tableData?.items?.length}
        columns={column}
        minRows={0}
        showPagination={false}
        LoadingComponent={TableLoader}
      />
      <Tooltip id="project-value1" place="top" className="tooltip" />

    </div>
  );
};
export default UomTable;
