/* eslint-disable max-len */
import React from 'react';

const QuoteFooter = (props) => {
  const { onSave, onCancel, specList } = props;
  return (
    <div className="fixed-footer card d-flex justify-content-end me-3">
      <button
        type="button"
        className="secondary-btn large mr-10"
        id="spec-cancel"
        onClick={onCancel}
        disabled={specList?.rfqData?.status === 4}
      >
        Cancel
      </button>
      <button
        type="button"
        className="secondary-btn large mr-10"
        id="spec-save"
        onClick={() => {
          onSave(false);
        }}
        disabled={specList?.rfqData?.status === 4}
      >
        Save as Draft
      </button>
      <button
        type="button"
        className="primary-btn large mr-10"
        id="spec-submit"
        onClick={() => {
          onSave(true);
        }}
        disabled={specList?.rfqData?.status === 4}
      >
        Submit
      </button>
    </div>
  );
};
export default QuoteFooter;
