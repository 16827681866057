/* istanbul ignore file */
import React from 'react';
import ModalBox from '../../../../../common/ModalBox';

const SupplementarySendSuccessModal = (props) => {
  const { setSupplementarySended, setConfirmationModalForYard,
    setShowYardConfirmModal, setShowSideBar, supplementarySpecNewList } = props;
  const handleCloseAll = () => {
    setSupplementarySended(false);
    setConfirmationModalForYard(false);
    setShowYardConfirmModal(false);
    setShowSideBar(false);
    supplementarySpecNewList();
  };
  return (
    <div>

      <ModalBox
        modalClassName="medium"
        // onSave={() => onSubmit()}
        isCloseModal={false}
      >
        <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">

          <span className="icon-check-rnd tick-green font-40 " />
          <div className="mt-3">
            <p className="font-15 font-semibold text-black mb-4">Supplementary Specs Email has been sent successfully!!</p>
          </div>
          <button
            type="button"
            className="primary-btn medium "
            id="backtoprojects"
            onClick={handleCloseAll}
          >
            Close
          </button>
        </div>
      </ModalBox>
    </div>
  );
};
export default SupplementarySendSuccessModal;
