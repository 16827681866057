import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import SidePanel from '../../../common/sidePanelCustom';
import ComponentDetails from './ComponentDetails';
import { getComponentList } from './services';

const VesselComponents = ({ onCloseWindow, openModal }) => {
  const { id } = useParams();
  const params = qs.parse(window.location.search);
  const vesselId = params?.vesselId || '';
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  const reloadTable = () => {
    getComponentList(vesselId, id).then((res) => {
      setFormData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    reloadTable();
  }, [openModal]);
  const showView = openModal === 'components';
  return (
    <div className="scroll-wrap" data-testid="com-2">
      <SidePanel title="Components" size="large" onClose={onCloseWindow} showView={showView}>
        <ComponentDetails
          formData={formData}
          loading={loading}
        />
        <footer className="side-panel__footer">
          <button type="button" className="primary-btn me-4" onClick={onCloseWindow}>
            Close
          </button>
        </footer>
      </SidePanel>
    </div>
  );
};
export default VesselComponents;
