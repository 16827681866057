import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const { GetAllTaskList, GetOverAllTaskCount, GetMicroTaskDetails,
  DeleteMicroTask, UpdateTaskStatus, SaveMicroTask,
  GetNotificationCount, GetProjectDetails } = apiEndPoints;

export const getAllTaskList = (payload) => {
  return serviceHandler({
    url: `${GetAllTaskList}`,
    methordType: 'post',
    payload
  });
};
export const getTaskCount = (payload) => {
  return serviceHandler({
    url: `${GetOverAllTaskCount}`,
    methordType: 'post',
    payload
  });
};
export const getMicroTaskDetails = (payload) => {
  return serviceHandler({
    url: `${GetMicroTaskDetails}`,
    methordType: 'get',
    payload
  });
};
export const saveMicroTask = (payload) => {
  return serviceHandler({
    url: `${SaveMicroTask}`,
    methordType: 'post',
    payload
  });
};
export const updateTaskStatus = (payload) => {
  return serviceHandler({
    url: `${UpdateTaskStatus}`,
    methordType: 'post',
    payload
  });
};
export const deleteMicroTask = (payload) => {
  return serviceHandler({
    url: `${DeleteMicroTask}?microTaskId=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const notificationCount = (payload) => {
  return serviceHandler({
    url: `${GetNotificationCount}?projectId=${payload.id}`,
    methordType: 'post',
    payload
  });
};
export const getProjectDetails = (payload) => {
  return serviceHandler({
    url: `${GetProjectDetails}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
