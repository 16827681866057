import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import vesselThmd from '../../../../../images/vessel-thmd.jpg';
import AreaLoader from '../../../../common/areaLoader';
// import Loader from '../../../../common/loader';
import { GetDDvessel } from './Services';
import helper from '../../../../../utils/helper';

const ProjCard = (props) => {
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const navigate = useNavigate();
  const { onViewOpen, projInfo, isVesselUser } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [isMobileOpen, setMobileOpen] = useState(false);
  const toggleMobileDropdown = () => {
    setMobileOpen(!isMobileOpen);
    setIsOpen(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /* istanbul ignore next */
  const DDvessel = () => {
    GetDDvessel({ projectId: id }).then((res) => {
      if (res.success) {
        setLoading(false);
        setData(res.data);
      }
    });
  };

  function useMediaQuery(query) {
    const [matches, setMatches] = useState(window?.matchMedia(query)?.matches);

    useEffect(() => {
      const mediaQueryList = window.matchMedia(query);
      const documentChangeHandler = () => setMatches(mediaQueryList.matches);

      mediaQueryList.addListener(documentChangeHandler);

      return () => {
        mediaQueryList.removeListener(documentChangeHandler);
      };
    }, [query]);

    return matches;
  }
  const isPageWide = useMediaQuery('(min-width: 992px)');
  useEffect(() => {
    DDvessel();
    setLoading(true);
  }, []);

  return (
    <div data-testid="projcard">
      {loading ? (
        <div className=" header-loader">
          <AreaLoader />
        </div>
      ) : (
        <div className="card xxl-radius bg-sky-white pe-4 pt-1  pb-1 ps-3">
          <div className="d-flex justify-content-between align-items-center">
            <img className="thmb-sm ship-thumb" src={projInfo?.imageUrl || vesselThmd} alt="" />
            <div>
              <label
                className="font-15 pt-1 pt-3 me-5  value font-semibold"
              >
                <span
                  data-tooltip-id="project-management"
                  data-tooltip-content={projInfo?.nameTooltip}
                  className="white-space-preserve"
                >
                  {_.truncate(projInfo?.name, {
                    length: 40,
                    separator: ''
                  })}
                </span>

                <Tooltip id="project-management" place="left" className="tooltip mt-5" />
              </label>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <span className="icon-disc inprogress font-10 brand-color" />
                  <p className="value font-13 ps-1 ">{projInfo?.projectStatus}</p>
                </div>
              </div>
            </div>
            <div className="d-flex ps-5 align-items-center">
              <button type="button" className="secondary-btn small p-3 sm-hide" onClick={onViewOpen}>
                More info
              </button>

              <div id="drop-down4" className="dropdown down-animate">

                {!isVesselUser && (
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="secondary-btn small ms-3 me-4 p-3 sm-hide "
                  disabled={data?.length === 0}
                >
                  DD History
                  <span className="icon icon-sort-down ms-1 font-10" />
                </button>
                )}
                {isOpen && (
                <ul className="dropdown_menu dropdown_menu--animated down-animate anim-bord dd-history-bg" style={{ width: '300px' }}>
                  <p className="ms-4">
                    <b>DD History</b>
                  </p>
                  {data?.map((item) => {
                    return (
                      <div>
                        <li className="dddropdown font-13 mb-2">
                          <div
                            onClick={() => {
                              if (item && item?.projId) {
                                navigate('/user/projects/ClosedVessel?id='.concat(item?.projId), { state: { projectDetails: item } });
                              }
                            }}
                          >
                            {item?.projName}
                            <p className="d-flex font-12">
                              Repair Start Date :
                              <div className="ms-2">{item?.dDDate ? moment(item?.dDDate)?.format('DD-MMM-YYYY') : ' '}</div>
                            </p>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                  {
                    data?.items?.length > 6
                    && <li style={{ textAlign: 'center' }}>View More</li>
                  }
                  <div className="border-down " />
                </ul>
                )}
              </div>
              <div className="dropdown down-animate sm-show">
                <button type="button" className="show-sm px-4 brand-color" onClick={toggleMobileDropdown}>
                  <span className="icon-dotted-menu-vertical" />
                </button>

                {isMobileOpen && (
                <>

                  <div>
                    {isPageWide ? '' : (
                      <ul className="dropdown_menu drop_down_more dropdown_menu--animated down-animate">
                        <li onClick={onViewOpen}>More info</li>
                        <li onClick={toggleDropdown} disabled={data?.length === 0}>DD History</li>
                        {isOpen && (
                        <ul className="dropdown_menu dropdown_menu--animated down-animate anim-bord dd-history-bg" style={{ width: '300px' }}>
                          <p className="ms-5">
                            <b>
                              DD History

                            </b>
                          </p>
                          {data?.map((item) => {
                            return (
                              <div>
                                <li className="dddropdown">
                                  <div
                                    onClick={() => {
                                      if (item && item?.projId) {
                                        navigate('/user/projects/ClosedVessel?id='.concat(item?.projId), { state: { projectDetails: item } });
                                      }
                                    }}
                                  >
                                    {item?.projName}
                                    <p className="d-flex">
                                      Repair Start Date :
                                      <div className="ms-2">{item?.dDDate ? moment(item?.dDDate)?.format('DD-MMM-YYYY') : ' '}</div>
                                    </p>
                                  </div>
                                </li>
                              </div>
                            );
                          })}
                          {
                    data?.items?.length > 6
                    && <li style={{ textAlign: 'center' }}>View More</li>
                  }
                          <div className="border-down " />
                        </ul>
                        )}
                      </ul>
                    )}
                  </div>
                </>
                )}
              </div>
              {!isVesselUser && (
              <button type="button" className="font-26 link-btn">
                <span
                  data-tooltip-id="project-close"
                  data-tooltip-content="Exit From Project"
                  className="icon-close "
                  onClick={() => {
                    if (isAdmin) {
                      navigate('/admin/projectList', { state: { from: 'data' } });
                    } else {
                      navigate('/user/projects', { state: { from: 'data' } });
                    }
                  }}
                />
                {/* {da} */}
              </button>
              )}
              <Tooltip id="project-close" place="bottom" className="tooltip" />
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default ProjCard;
