/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Cards from './cards';
import ModalBox from '../../../common/ModalBox';
import AddGroup from './addGroup';
import {
  getManagementGroupList,
  deleteManagementItem,
  saveManagementGroupList,
  s3Upload,
  bulkUploadManagementGroup,
  generatTemplate,
  exportExcelManagementGroup
} from './services';
import Pagination from '../../../common/pagination';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import BulkUploadModal from '../../DataConfig/CurrencyContainer/bulkUploadModal';
import Confirmation from '../../../common/confirmation';
import Loader from '../../../common/loader';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const Management = () => {
  // eslint-disable-next-line no-unused-vars
  const { charValidation, handleValidation, fieldValidation } = helper;
  const [count, setCount] = useOutletContext() || '';
  const [listData, setListData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState('');
  const [itemId, setItemId] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const { ValidateEmail, addColor, MAX_FILE_SIZE } = helper;
  const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
  const acceptedFiles = ['image/jpeg', 'image/jpg', 'image/png'];
  const initialFormData = {
    id: '',
    name: '',
    location: '',
    address: '',
    emailAddress: '',
    mobileNo: '',
    phoneNo: '',
    fax: '',
    logoFileName: '',
    physicalLogoFileName: '',
    logoFile: '',
    status: 0,
    mangementGroupStatus: '',
    logoURL: '',
    surveyNotificationDays: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [fileName, setFileName] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [files, setFiles] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const acceptedFile = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const reloadList = (page) => {
    getManagementGroupList({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: 'Name'
    }).then((res) => {
      if (res.data) setListData(res?.data);
      if (setCount) {
        setCount(res?.data?.totalItems || 0);
      }
      setLoading(false);
      window.scrollTo(0, 0);
    });
  };
  /* istanbul ignore next */
  const reloadListData = (page) => {
    getManagementGroupList({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: 'Name'
    }).then((res) => {
      if (res.data) setListData(res?.data);
      if (setCount) {
        setCount(res?.data?.totalItems || 0);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    reloadList();
  }, [currentpage, pageSize]);
  const uploadSingleFile = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE}MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        setFileName(e.target.files[0].name);
        const res = await s3Upload(body);
        if (res && res.data) {
          const Attach = res.data;
          setFile(Attach);
          showAlert('Successfully Uploaded ', 'success');
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
  };
  const deleteApiCall = () => {
    deleteManagementItem(itemId).then((res) => {
      if (res.success) {
        setFormData(initialFormData);
        showAlert('Successfully Deleted', 'success');
        reloadList();
      }
    });
    setOpenDeleteModal(false);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadList(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setLoading(true);
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const setItem = (e) => {
    setItemId(e);
  };
  /* istanbul ignore next */
  const saveApiCall = () => {
    const temp = cloneDeep(formData);
    temp.id = formData?.id ? formData?.id : ZERO_UUID;
    temp.logoURL = file;
    temp.logoFileName = fileName;
    temp.physicalLogoFileName = file;
    temp.surveyNotificationDays = formData.surveyNotificationDays || 0;
    let isValid = true;

    ['name', 'location', 'address', 'emailAddress'].forEach((item) => {
      if (temp[item] === null || temp[item] === '' || temp[item] === ' ') {
        isValid = false;
        addColor(item, 'add');
      } else {
        addColor(item, '');
      }
    });
    if (!temp.surveyNotificationDays || temp.surveyNotificationDays === 0 || temp.surveyNotificationDays === '0') {
      isValid = false;
    }
    const isValidEmail = ValidateEmail(temp?.emailAddress?.toLowerCase());
    if (isValid && temp?.name?.trim() !== '' && temp?.location?.trim() !== '' && temp?.address?.trim() !== '' && temp?.emailAddress?.trim() !== '') {
      if (isValidEmail) {
        addColor('emailAddress', '');
        saveManagementGroupList(temp).then((res) => {
          if (res?.success) {
            if (isAddClicked) {
              showAlert('Successfully Saved', 'success');
            } else {
              showAlert('Successfully Updated', 'success');
            }
            setFile('');
            setFileName('');
            reloadListData();
            setFormData(initialFormData);
            setShowModal(false);
            setIsAddClicked(false);
            // setLoading(false);
          }
        });
      } else {
        showAlert('Please enter a valid email address', 'error');
        addColor('emailAddress', 'add');
      }
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const handleExcelExportManagement = () => {
    exportExcelManagementGroup(searchText);
  };
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFile.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFiles(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFiles();
  };
  const addManagementClose = () => {
    if (isChanged) {
      setConfirm(true);
    } else {
      setShowModal(false);
      setFormData(initialFormData);
      setIsAddClicked(false);
      setFile('');
      setFileName('');
    }
  };
  const onSaveConfirm = () => {
    setShowModal(false);
    setFormData(initialFormData);
    setIsAddClicked(false);
    setFile('');
    setFileName('');
    setConfirm(false);
    setIsChanged(false);
  };
  const onCloseConfirm = () => {
    setConfirm(false);
  };
  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', files);
    bulkUploadManagementGroup(body).then((res) => {
      if (res.success) {
        reloadList();
        setLoading(false);
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFiles();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFiles();
        setIsUpload(true);
      }
    });
  };

  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    if (field === 'name' || field === 'address') {
      fd[field] = charValidation(e.target.value, 100);
    } else if (field === 'phoneNo' || field === 'mobileNo' || field === 'fax') {
      fd[field] = charValidation(e.target.value, 20);
    } else if (field === 'surveyNotificationDays') {
      fd[field] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 3);
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setIsChanged(true);
    setFormData(fd);
    handleValidation(['name', 'location', 'address', 'emailAddress'], fd, field);
  };
  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFiles();
    setIsUpload(true);
  };
  useEffect(() => {
    if (!searchText) {
      reloadList(1);
    }
  }, [searchText]);

  const onSizeChange = (e) => {
    setLoading(true);
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div className="container" data-testid="v-management">
      <div className="d-flex my-4 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search">
          <div className="search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
              />
              <span
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              value={searchText}
              id="search"
              className="text-box large with-close"
              placeholder="Search by Name"
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            className="primary-btn large"
            id="add-management"
            onClick={() => {
              setShowModal(true);
              setFile('');
              setFileName('');
              setIsAddClicked(true);
            }}
          >
            Add Management
          </button>
          <button
            type="button"
            id="add-name"
            className="secondary-btn large"
            onClick={() => {
              setUploadModal(true);
            }}
          >
            Bulk Upload
          </button>
          {uploadModal && (
            <ModalBox
              modalClassName="medium"
              heading="Upload Excel"
              onSave={() => {
                bulkUpload();
              }}
              closeModal={() => onCloseModal()}
              buttonName="Upload"
              saveDisable={isUpload}
            >
              <BulkUploadModal
                file={files}
                ondrop={ondrop}
                setFile={setFiles}
                generatTemplateForExcel={generatTemplateForExcel}
                name="Management Group"
              />
            </ModalBox>
          )}
        </div>
        <div className="d-flex col-gap-1">
          <button type="button" id="excel" className="link-btn large " onClick={handleExcelExportManagement}>
            <span className="icon-download me-4" />
            Excel Download
          </button>
        </div>
      </div>
      {loading ? <Loader /> : (
        <>
          {listData && listData?.totalItems > 0 ? (
            <>
              {listData?.items?.map((item) => {
                return (
                  <Cards
                    data={item}
                    deleteApiCall={deleteApiCall}
                    setOpenDeleteModal={setOpenDeleteModal}
                    openDeleteModal={openDeleteModal}
                    setShowModal={setShowModal}
                    setFormData={setFormData}
                    setFile={setFile}
                    setFileName={setFileName}
                    setItem={setItem}
                  />
                );
              })}
              {listData?.totalItems > 10 && (
              <Pagination
                pageClick={pageClick}
                pageCount={listData?.totalPages}
                currentpage={currentpage}
                loading={loading}
                onSizeChange={onSizeChange}
                size={pageSize}
              />
              )}
            </>
          ) : (
            <div className="mt-5 p-4 d-flex justify-content-center user-list-policy" data-testid="yard-attach">
              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
              <div className="font-18 animate__animated animate__headShake">No data found</div>
            </div>
          )}
        </>
      )}
      {showModal && (
        <ModalBox
          heading={isAddClicked ? 'Add Management Group' : 'Edit Management Group'}
          closeModal={() => {
            addManagementClose();
          }}
          onSave={() => saveApiCall()}
          buttonName="Save"
        >
          <AddGroup
            handleChange={handleChange}
            confirm={confirm}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            formData={formData}
            setFormData={setFormData}
            file={file}
            uploadSingleFile={uploadSingleFile}
            deleteApiCall={deleteApiCall}
          />
        </ModalBox>
      )}
      {openDeleteModal && (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete this Management?</p>
        </ModalBox>
      )}

      {confirm && (
      <Confirmation
        onSaveConfirm={onSaveConfirm}
        onCloseConfirm={onCloseConfirm}
      />
      )}
      <div className="footer justify-content-between font-13">
        <PolicyFooter
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          setShowTermsPolicy={setShowTermsPolicy}
        />
      </div>
      {showPrivacyPolicy && (
      <PrivacyPolicy
        setShowPrivacyPolicy={setShowPrivacyPolicy}
        showPrivacyPolicy={showPrivacyPolicy}
      />
      )}
      {showTermsPolicy && (
      <TermsOfUse
        setShowTermsPolicy={setShowTermsPolicy}
        showTermsPolicy={showTermsPolicy}
      />
      )}
    </div>
  );
};
export default Management;
