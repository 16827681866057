import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ProjCard from './ProjCard';
import SidePanelDeets from './SidePanelDeets';
import { getProjectDetailsTSI } from '../../NewProject/services';

const Card1 = (props) => {
  const { isVesselUser } = props;
  const { id } = useParams();
  const [showView, setShowView] = useState(false);
  const [projInfo, setProjInfo] = useState({});
  const [IsemergencyDryDock, setIsEmergencyDryDock] = useState(false);

  const getProjDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      if (res?.success) {
        setProjInfo(res?.data);
        if (res?.data?.projectType?.toLowerCase()?.includes('emergency')) {
          setIsEmergencyDryDock(true);
        } else {
          setIsEmergencyDryDock(false);
        }
      }
    });
  };
  useEffect(() => {
    getProjDetails();
  }, []);

  const onViewOpen = () => {
    setShowView(true);
  };

  return (
    <div data-testid="card-details">
      <ProjCard
        onViewOpen={onViewOpen}
        projInfo={projInfo}
        isVesselUser={isVesselUser}
      />
      {showView && (
        <SidePanelDeets
          showView={showView}
          projInfo={projInfo}
          IsemergencyDryDock={IsemergencyDryDock}
          setShowView={setShowView}
        />
      )}
    </div>

  );
};

export default Card1;
