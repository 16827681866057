import _ from 'lodash';
import React from 'react';

const ExportExcelModal = (props) => {
  const { compareData, onYardSelect, selectedExcelYard, selectAll } = props;

  return (
    <div data-testid="exportQuatiation">
      {compareData.length > 0 ? (
        <div>
          <div
            className="check-box pt-3"
            onClick={() => { onYardSelect('selectAll'); }}
            data-test="click1"
          >
            <input
              checked={selectAll}
              type="checkbox"
            />
            <label className="brand-color font-bold">
              Select All

            </label>
          </div>

          {compareData.map((item) => {
            return (
              <div
                className="check-box pt-3"
                id={item.id}
                onClick={() => { onYardSelect(item.id); }}
                data-test="click2"
              >
                <input
                  type="checkbox"
                  value={item.id}
                  checked={selectedExcelYard.indexOf(item.id) > -1}
                />
                <label
                  data-tooltip-id="project-line"
                  data-tooltip-content={item.yardName}
                >
                  {_.truncate(item.yardName, { length: 60 })}

                </label>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="d-flex value justify-content-center p-5 mt-5">
          No Quote to Export
        </div>
      )}

    </div>
  );
};

export default ExportExcelModal;
