import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const Components = (props) => {
  const { specData } = props;
  return (
    <div className="scroll-area" data-testid="com-2">
      <table className="w-full html-table">
        <thead>
          <tr>
            <th>
              Component
            </th>
            <th>
              Maker
            </th>
            <th>
              Model
            </th>
          </tr>
        </thead>
        <tbody>

          {specData && specData.specComponents?.length ? (
            <>
              {specData.specComponents?.map((item) => {
                return (
                  <tr>
                    <td>
                      <span
                        className="mb-2 text-ellipsis"
                        data-tooltip-id="project-head"
                        data-tooltip-content={item.title}
                      >
                        {_.truncate(item.title, { length: 20 })}
                      </span>
                    </td>
                    <td>
                      <span
                        className="text-ellipsis"
                        data-tooltip-id="project-head"
                        data-tooltip-content={item.makerName}
                      >
                        {_.truncate(item.makerName, { length: 20 })}
                      </span>
                    </td>
                    <td>
                      <span
                        className="text-ellipsis text-uppercase"
                        data-tooltip-id="project-head"
                        data-tooltip-content={item.model}
                      >
                        {_.truncate(item.model, { length: 20 })}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td colSpan="4">
                <div className="mt-2 p-4 d-flex justify-content-center align-items-center ">
                  <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                  <div className="font-18 animate__animated animate__headShake">
                    No data found
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Tooltip id="project-head" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Components;
