import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

let isSearched = '';
const ListAcCodes = (props) => {
  const { acCodeList, onMapAcCode, mappedAccode } = props;
  const [searchText, setSearchText] = useState('');

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      setSearchText(isSearched);
    }
  };
  return (
    <div>
      <div className="search mb-3 w-33">
        <span className="icon-search" id="search-click" onClick={() => { onSearch({ key: 'Enter' }); }} />
        <input type="text" className="text-box" placeholder="Search by Code" id="search" onKeyPress={onSearch} onChange={(e) => { isSearched = e.target.value; }} />
      </div>
      <div className="d-flex bg-sky-blue p-3">
        <h3 className="w-33 font-semibold">Code</h3>
        <h3 className="font-semibold">Name</h3>
      </div>
      {acCodeList.filter((fields) => fields.code?.includes(searchText))
        .map((item) => {
          return (
            <div className="d-flex border-down p-3">
              <span className="w-33">
                <div className="check-box align-items-center">
                  <input type="checkbox" id={item.code} checked={item.id === mappedAccode} onClick={() => { onMapAcCode(item.id); }} />
                  <label
                    htmlFor={item.code}
                    data-tooltip-id="project-list"
                    data-tooltip-content={item.code}

                  >
                    {_.truncate(item.code, { length: 15 })}
                  </label>
                </div>
              </span>
              <span
                data-tooltip-id="project-list"
                data-tooltip-content={item.name}
              >
                {_.truncate(item.name, { length: 30 })}

              </span>
            </div>
          );
        })}
      <>
        {acCodeList.filter((fields) => fields.code?.includes(searchText)).length < 1
          && <div className="card mt-3 p-4 bg-white d-flex justify-content-center">No data found</div>}
      </>
      <Tooltip id="project-list" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ListAcCodes;
