import React from 'react';
import ModalBox from '../../common/ModalBox';

const DownloadConfirmModal = (props) => {
  const { setDownloadConfirmModal, handleContinueInDownload } = props;

  return (
    <div data-testid="DownloadConfirmModal">
      <ModalBox
        heading="Download Specs"
        // isCloseModal={!isDownload}
        closeModal={() => { setDownloadConfirmModal(false); }}
        buttonName="Ok"
        onSave={() => { handleContinueInDownload(); }}
      >

        <div>
          A link to download the pdf report and the
          excel template will be send to the registered email in few minutes.
        </div>

      </ModalBox>

    </div>
  );
};
export default DownloadConfirmModal;
