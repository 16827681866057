/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { getSOFData } from '../../../services';
import { getProjectDetailsTSI } from '../../../NewProject/services';
import AddNewSOF from './addNewSOF';

const SOF = (props) => {
  const { setAddNewSOF, onEdit, onDelete, sofData,
    setSofData, id, isReload, setIsReload,
    formData, handleChange, setFormData, initialFormData, setsofId,
    addNewSOF, setIsEditClicked, setIsAddClicked, isTSI } = props;

  const [projectBasicDetails, setProjectBasicDetails] = useState();

  const onReloadSpecData = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectBasicDetails(res.data);
    });
  };
  useEffect(() => {
    onReloadSpecData();
  }, []);
  useEffect(() => {
    if (formData?.sofTimeStamp === '') {
      setFormData(initialFormData);
      setsofId('');
      setAddNewSOF(false);
      setIsEditClicked(false);
      setIsAddClicked(false);
    }
  }, []);
  const getSOF = () => {
    getSOFData(id).then((res) => {
      setSofData(res?.data);
      setIsReload(false);
    });
  };
  useEffect(() => {
    getSOF();
  }, [isReload]);
  return (
    <div data-testid="SOF">

      {addNewSOF ? (
        <>
          <div className="d-flex col-gap-1 pb-4">

            <button id="back" onClick={() => { setAddNewSOF(false); setIsEditClicked(false); setIsAddClicked(false); }} type="button" className="link-btn">
              <span className="icon-arrow-left2 me-2" />
              Back
            </button>
          </div>
          <AddNewSOF
            handleChange={handleChange}
            formData={formData}
            initialFormData={initialFormData}
            setFormData={setFormData}
            setsofId={setsofId}
          />
        </>
      )
        : (
          <div>
            <div className="d-flex col-gap-1 pb-3">
              {isTSI && (
              <button
                id="sof-add"
                type="button"
                className="secondary-btn large"
                disabled={projectBasicDetails?.isClosed}
                onClick={() => {
                  setAddNewSOF(true);
                  setIsAddClicked(true); setFormData(initialFormData);
                }}
              >
                Add New
              </button>
              )}

            </div>
            <div>
              {sofData && sofData?.length > 0 ? (
                <div className="scrollable-modal">
                  {sofData.map((data) => {
                    return (
                      <div className="card d-flex justify-content-between align-items-center pb-4 pt-4">
                        <div className="ps-2 font-16">
                          <div className="pb-2 value w-36em text-ellipsis">
                            <span data-tooltip-id="project-statement" data-tooltip-content={data?.statement}>
                              {data?.statement}
                            </span>

                          </div>
                          <p className="opacity-6">{`${moment.utc(data?.sofTimeStamp).format('DD-MMM-YYYY - HH:mm')} (UTC)`}</p>
                        </div>
                        {isTSI && (

                        <div className="pe-5 d-flex">
                          <button type="button" id="edit" className="link-btn me-3" onClick={() => onEdit(data)} disabled={projectBasicDetails?.isClosed}>
                            <span className="icon-edit font-20" />
                          </button>
                          <button type="button" id="delete" className="link-btn" onClick={() => { onDelete(data); }} disabled={projectBasicDetails?.isClosed}>
                            <span className="icon-delete font-20" />
                          </button>
                        </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className=" p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
                  <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                  <div className="font-18 animate__animated animate__headShake">
                    No data found
                  </div>
                </div>
              )}

            </div>
          </div>
        )}
      <Tooltip id="project-statement" place="top" className="tooltip " />
    </div>
  );
};

export default SOF;
