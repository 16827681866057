/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import 'react-tooltip/dist/react-tooltip.css';
import CryptoJS from 'crypto-js';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import InputType1 from '../../../../common/InputType1';
import SidePanel from '../../../../common/sidePanel';
import { updatePassword } from '../services';
import UpdatePasswordSuccess from './updatePasswordSuccess';
import { HandleErrors } from './handleError';

const UpdatePassword = (props) => {
  const [existingPassword, setExistingPassword] = useState('');
  const { showUpdatePasswordPage, onUpdatePasswordPageClose, data } = props;
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showExistingPassword, setShowExistingPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({ newPassword: '', confirmPassword: '', existingPassword: '' });
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const initialState = {
    userId: '',
    existingPasswordd: '',
    newPassword: ''
  };
  const [updatePwdState, setUpdatePwdState] = useState(initialState);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === 'existingPassword') {
      setExistingPassword(value);
      setErrors({ existingPassword: '' });
    }
    if (name === 'newPassword') {
      setNewPassword(value);
      setErrors({ newPassword: '' });
    }
    if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setErrors({ confirmPassword: '' });
    }
  };

  function encrypt(input) {
    const salt = 'DryDock';
    const hmac = CryptoJS.HmacSHA256(input, salt);
    return hmac.toString(CryptoJS.enc.Hex);
  }

  useEffect(() => {
    const userId = localStorage.getItem('USER_ID');
    setUpdatePwdState({
      userId,
      existingPasswordHash: encrypt(existingPassword),
      newPasswordHash: encrypt(newPassword)
    });
  }, [existingPassword, newPassword]);

  const handleUpdate = () => {
    if (
      newPassword === confirmPassword
      && newPassword.length >= 8
      && confirmPassword.length >= 8
      && existingPassword
      && existingPassword !== newPassword
      && newPassword.search(/[0-9]/) !== -1
      && (newPassword.search(/[a-z]/) !== -1 || newPassword.search(/[a-z]/) === -1)
      && newPassword.search(/[!\@\#\$\%\^\&\()\_\:\.]/) !== -1

    ) {
      updatePassword(updatePwdState).then((res) => {
        if (res.success) {
          setUpdateSuccess(true);
          localStorage.clear();
        }
      });
    } else {
      HandleErrors(existingPassword, newPassword, confirmPassword, setErrors);
    }
  };

  const onSuccessPageClose = () => {
    setUpdateSuccess(false);
  };

  return (
    <div data-testid="passwordupdate">
      <SidePanel title="Update Password" size="small" showView={showUpdatePasswordPage} onClose={onUpdatePasswordPageClose}>
        {showUpdatePasswordPage && (
          <div className="ps-5 pe-5">
            <div
              className="mb-3 head-14 d-flex"
            >
              <span className="icon-user font-24 me-2" />
              <span
                data-tooltip-id="view-details"
                data-tooltip-content={data?.email}
              >
                {_.truncate(data?.email, {
                  length: 35,
                  separator: ' '
                })}
              </span>

            </div>
            <div className="mb-5 mt-5 d-flex flex-column">
              <div className="relative">
                <InputType1
                  autoFocus
                  type={showExistingPassword ? 'text' : 'password'}
                  className={errors.existingPassword ? 'text-box xlarge w-full error-field' : 'text-box xlarge w-full'}
                  value={existingPassword}
                  onChange={handleChange}
                  maxLength={16}
                  isMandatory="true"
                  label="Existing Password"
                  id="confirmpassword"
                  name="existingPassword"
                />
                <button
                  type="button"
                  onClick={() => setShowExistingPassword(!showExistingPassword)}
                  className="link-btn visible-btn"
                  id="showexistingpassword"
                >
                  {showExistingPassword ? <span className="icon-eye-close font-24" /> : <span className="icon-eye font-24" />}
                </button>
              </div>

              <p style={{ color: 'red' }}>{errors.existingPassword}</p>
            </div>
            <div className="d-flex flex-column">
              <div className="relative">
                <InputType1
                  type={showPassword ? 'text' : 'password'}
                  className={errors.newPassword ? 'text-box xlarge w-full error-field' : 'text-box xlarge w-full'}
                  value={newPassword}
                  maxLength={16}
                  onChange={handleChange}
                  isMandatory="true"
                  label="New Password"
                  data-testid="newpassword"
                  name="newPassword"
                />
                <button type="button" onClick={handleShowPassword} className="link-btn visible-btn" id="shownewpassword">
                  {showPassword ? <span className="icon-eye-close font-24" /> : <span className="icon-eye font-24" />}
                </button>
              </div>

              <PasswordStrengthBar password={newPassword} minLength={8} shortScoreWord=" " scoreWords={['weak', 'weak', 'average', 'good', 'strong']} />
              <p style={{ color: 'red' }}>{errors.newPassword}</p>
            </div>
            <div className="mb-5 mt-5 d-flex flex-column">
              <div className="relative">
                <InputType1
                  type={showConfirmPassword ? 'text' : 'password'}
                  className={errors.confirmPassword ? 'text-box xlarge w-full error-field' : 'text-box xlarge w-full'}
                  value={confirmPassword}
                  onChange={handleChange}
                  maxLength={16}
                  isMandatory="true"
                  label="Re-Enter New Password"
                  data-testid="confirmpassword"
                  name="confirmPassword"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="link-btn visible-btn"
                  id="shownewpassword2"
                >
                  {showConfirmPassword ? <span className="icon-eye-close font-24" /> : <span className="icon-eye font-24" />}
                </button>
              </div>

              <p style={{ color: 'red' }}>{errors.confirmPassword}</p>
            </div>
            <div className="pt-1">
              <button id="resetbutton" onClick={handleUpdate} className="primary-btn xlarge full-width my-3 ps-5 pe-5" type="button">
                Update Password
              </button>
            </div>

          </div>
        )}
      </SidePanel>

      {updateSuccess
      && (
      <UpdatePasswordSuccess
        updateSuccess={updateSuccess}
        onSuccessPageClose={onSuccessPageClose}
      />
      )}
      <Tooltip id="view-details" place="top" className="tooltip" />
    </div>
  );
};
export default UpdatePassword;
