import React from 'react';
import InputType1 from '../../../common/InputType1';

const ContentHeader = (props) => {
  const { specList } = props;
  // const onUpdateHeader = (e, type) => {
  //   const currentSpecList = { ...specList };
  //   if (type === 'float') {
  //     currentSpecList.rfqData[e.target.name] = e.target.value.replace(/[^0-9.]/g, '');
  //   } else if (type === 'int') {
  //     currentSpecList.rfqData[e.target.name] = e.target.value.replace(/[^0-9]/g, '');
  //   } else {
  //     currentSpecList.rfqData[e.target.name] = e.target.value;
  //   }
  //   const discountper = (100 - (currentSpecList.rfqData.discountPercentage || 0)) / 100;
  //   currentSpecList.rfqData.yardFinalEstimate = ((currentSpecList.rfqData
  //     .total - currentSpecList.rfqData.discount) * discountper).toFixed(2).toString();
  //   currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList.rfqData
  //     .total - currentSpecList.rfqData.discount).toFixed(2).toString();
  //   setSpecList(currentSpecList);
  // };
  return (
    <>
      <div className="bg-sky-white p-3 d-flex flex-wrap sm-radius">
        <div className="col-6 pe-4 mb-4">
          <InputType1 label="Yard Name" className="cursor-disable" placeholder="Yard Name" value={specList?.rfqData?.yardName || ''} />
        </div>
        <div className="col-2 pe-4">
          <InputType1 label="In Dock Days" className="cursor-disable" placeholder="In Dock Days" id="dockdays" name="inDockDays" value={specList?.rfqData?.inDockDays || ''} />
        </div>
        <div className="col-2 pe-4">
          <InputType1 label="Repair Days" className="cursor-disable" placeholder="Repair Days" id="repairday" name="repairDays" value={specList?.rfqData?.repairDays || ''} />
        </div>
        <div className="col-2 pe-4">
          <InputType1 label="Discount [%]" className="cursor-disable" placeholder="Discount [%]" id="discoutp" name="discountPercentage" value={specList?.rfqData?.discountPercentage || ''} />
        </div>
        <div className="col-6 pe-4">
          <InputType1 label="Payment Terms" className="cursor-disable" placeholder="Payment Terms" id="term" name="paymentTerms" value={specList?.rfqData?.paymentTerms || ''} />
        </div>
        <div className="col-6 pe-4">
          <InputType1 label="Yard Comments" id="comment" className="cursor-disable" placeholder="Yard Comments" value={specList?.rfqData?.comment || ''} />
        </div>
      </div>
      <div className="border-down my-3" />
    </>
  );
};
export default ContentHeader;
