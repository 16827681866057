/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import AdminHeader from '../../../AdminHeader';
import SidePanel from '../../../../common/sidePanel';
import { getAllSpecList, getProjectDetails } from './services';
import ViewMore from '../../../../TSI/Projects/PhasePage/Repair/viewMore';
import Loader from '../../../../common/loader2';

const VesselCard = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [isExpand, setIsExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Expanded, setExpanded] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [expandedSidebar, setExpandedSidebar] = useState(true);
  const [projName, setProjName] = useState([]);

  const params = qs.parse(window.location.search);
  const { id } = useParams();
  const { state } = useLocation();
  console.log(state?.projectDetails?.projId, id, useParams(), 'hello');
  /* istanbul ignore next */
  const showMoreSpec = (spec) => {
    setViewMore(spec);
  };
  /* istanbul ignore next */
  const onCloseViewMore = () => {
    setViewMore(false);
  };
  /* istanbul ignore next */
  const toggleclick = (e) => {
    if (Expanded === e) {
      setExpanded('');
    } else {
      setExpanded(e);
    }
  };
  /* istanbul ignore next */
  const handleClick = (key) => {
    if (isExpand === key) {
      setIsExpand('');
      setExpanded(false);
    } else {
      setIsExpand(key);
    }
  };

  const getSpecListData = () => {
    getAllSpecList({ projectId: params.id || id, searchString: searchText }).then((resp) => {
      if (resp.success) {
        setData(resp.data);
        setLoading(false);
      }
    });
  };
  /* istanbul ignore next */
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getSpecListData();
    }
  };
  useEffect(() => {
    if (!searchText) {
      getSpecListData();
    }
  }, [searchText]);
  useEffect(() => {
    setLoading(true);
    getProjectDetails(id || state?.projectDetails?.projId)
      .then((response) => {
        setProjName(response.data);
        console.log('hello', response);
      })
      .catch(() => {});
  }, []);

  const navigate = useNavigate();
  return (
    <div className="page-wrapper" data-testid="VesselCard">
      <div className="header">
        <AdminHeader headerType="data-config" subHeaderType="general-tariff" heading="Specification Setup" />
      </div>
      <div className="sub-header">
        <div className="d-flex  col-gap-3">
          <div className="d-flex justify-content-start col-10  ">
            <button
              onClick={/* istanbul ignore next */ () => setExpandedSidebar(!expandedSidebar)}
              className="link-btn ms-5 me-5 hamb-menu col-gap-6"
              type="button"
            >
              {expandedSidebar ? <span className="icon-menu" /> : <span className="icon-arrow-thin-right" />}
            </button>
            <h6 className="main-head ms-5">{state?.projectDetails?.projName || projName.name}</h6>
          </div>
          <div className="ms-5">
            <button
              onClick={/* istanbul ignore next */() => {
                navigate('/admin/data/spec/DDHistory');
              }}
              type="button"
              className="rnd-btn ms-5"
            >
              Back
            </button>
          </div>
        </div>
      </div>
      {loading || data.length > 0 ? (
        <Loader />
      ) : (
        <div>
          <div className="card  mx-5 ">
            <div className="pt-5 ps-5">
              <div className="d-flex mb-3 col-gap-1 spec-search">
                <div className="search large search-large spec-search ms-4">
                  <div className="d-flex justify-content-end">
                    <span className={searchText ? ' icon-close-line ' : ''} onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }} />
                    <span
                      className="icon-search"
                      id="lens"
                      onClick={() => {
                        /* istanbul ignore next */
                        onSearch({ key: 'Enter' });
                      }}
                    />
                  </div>
                  <input
                    type="text"
                    value={searchText}
                    className="text-box large with-close"
                    placeholder="Search by Sec No.,Sec Name, Spec No., Spec Name, ServiceLine No., ServiceLine Name "
                    id="search"
                    onKeyPress={onSearch}
                    onChange={(e) => {
                      /* istanbul ignore next */
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
              {data?.specQuoteList?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6 p-4">No data found</div>}
              {data?.specQuoteList?.length > 0 && (
                <div className="px-4">
                  <div className="d-flex ms-2 border-down py-1 ps-2">
                    <div className="col-1 font-bold">SEC#</div>
                    <div className="font-bold">SPEC SECTION</div>
                  </div>
                  {data?.specQuoteList?.map((field, index) => {
                    return (
                      <>
                        <div className="d-flex mt-2">
                          <span
                            className={
                              isExpand === index
                                ? 'font-14 icon-sort-up  brand-color pointer rotate-180'
                                : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                            }
                            onClick={() => {
                              handleClick(index);
                            }}
                          />
                          <div className="ms-4">{field.specificationNo}</div>
                          <div className="section-mr" data-tooltip-id="project-sec" data-tooltip-content={field.title}>

                            {_.truncate(field?.title, {
                              length: 40,
                              separator: ' '
                            })}

                            {/* {field.title} */}
                          </div>
                        </div>
                        <div className="border-down py-1 ps-3" />
                        {isExpand === index
                          && field?.specDataList?.map((item, key) => {
                            return (
                              <>
                                <div className="dd-margin mt-3 d-flex">
                                  <span
                                    onClick={/* istanbul ignore next */ () => toggleclick(key)}
                                    className={
                                      Expanded === key
                                        ? 'font-14 icon-sort-down rotate-120 brand-color pointer'
                                        : 'font-14 icon-sort-up rotate-90 brand-color pointer'
                                    }
                                  />
                                  <div className="ms-4">{item.specificationNo}</div>
                                  <div className="ms-5" data-tooltip-id="project-sec" data-tooltip-content={item.title}>
                                    {_.truncate(item?.title, {
                                      length: 45,
                                      separator: ' '
                                    })}
                                  </div>
                                  <div className="">
                                    {key === 0 && (
                                      <button type="button" id="viewmore" className="primary-btn small ms-5 " onClick={() => showMoreSpec(item)}>
                                        View More
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div className="">
                                  {Expanded === key
                                    && item?.serviceLineList?.map((subitem, subnum) => {
                                      return (
                                        <div>
                                          {subnum === 0 && (
                                            <div className="d-flex ps-3 align-items-center font-semibold mt-3">
                                              <div className="col-1" />
                                              <div className="col-2 ps-3 bg-sky-white border-down pb-2">Service LN#</div>
                                              <div className="col-5 ps-3 bg-sky-white border-down pb-2">Service Lines</div>
                                              <div className="col-2 ps-3 bg-sky-white border-down pb-2">UOM</div>
                                              <div className="col-2 ps-3 bg-sky-white border-down pb-2">Quantity</div>
                                            </div>
                                          )}
                                          <div className="d-flex pb-2 ps-3 py-1">
                                            <div className="col-1" />
                                            <div className="col-2 ps-3 border-down">{subitem.number}</div>
                                            <div className="col-5 ps-3 border-down">
                                              <span data-tooltip-id="project-sec" data-tooltip-content={subitem.serviceLineName}>
                                                {_.truncate(subitem.serviceLineName,
                                                  { length: 30 })}
                                              </span>

                                            </div>
                                            <div className="col-2 ps-3 border-down">
                                              <span data-tooltip-id="project-sec" data-tooltip-content={subitem.uomName}>
                                                {_.truncate(subitem.uomName, { length: 30 })}
                                              </span>

                                            </div>
                                            <div className="col-2 ps-3 border-down">
                                              <span data-tooltip-id="project-sec" data-tooltip-content={subitem.quantity}>
                                                {subitem.quantity}
                                              </span>

                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                  {viewMore && (
                    <SidePanel
                      className="side-panel-viewmore"
                      size="large"
                      bgColor="bg-white"
                      showView={viewMore}
                      onClose={onCloseViewMore}
                      title={<span data-tooltip-id="project-more" data-tooltip-content={viewMore.title}>{`${viewMore.specificationNo}. ${_.truncate(viewMore.title, { length: 55 })}`}</span>}
                    >
                      <Tooltip id="project-more" place="bottom" className="tooltip ms-5" />
                      {viewMore && <ViewMore showMore={viewMore} projectId={id} />}
                    </SidePanel>
                  )}
                </div>
              )}
              <Tooltip id="project-sec" place="top" className="tooltip mt-3" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VesselCard;
