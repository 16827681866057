/* eslint-disable react/button-has-type */

import React from 'react';
import InputType1 from '../../../common/InputType1';
import image2 from '../../../../images/management-placeholder.jpg';

const AddGroup = (props) => {
  const { formData, uploadSingleFile,
    file, handleChange } = props;

  return (
    <div className="row add-group my-4" data-testid="v-addGroup">
      <div className="col-4">
        <div className="d-flex align-items-center flex-column">
          <div className="upload-sec">
            {file || formData?.logoURL
              ? <img src={file || formData?.logoURL} alt="" className="thmb-xlg" />
              : <img src={image2} alt="" className="thmb-xlg" />}
            <div className="upload-btn m-auto mt-4 cursor-pointer">
              <div className="cursor-pointer">
                <button className="link-btn cursor-pointer">Upload Brand Logo</button>
                <input type="file" id="upload" name="myfile" className="cursor-pointer" onChange={(e) => { uploadSingleFile(e); }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-8">
        <div className="row mb-3">
          <div className="col-6">
            <InputType1 autoFocus value={formData?.name} id="name" onChange={(e) => handleChange(e, 'name')} className="modal-input" label="Management Name" isMandatory="true" />
          </div>
          <div className="col-6">
            <InputType1 value={formData?.location} id="location" onChange={(e) => handleChange(e, 'location')} className="modal-input" label="Location" isMandatory="true" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <InputType1 value={formData?.address} id="address" onChange={(e) => handleChange(e, 'address')} className="modal-input" label="Address" isMandatory="true" />
          </div>
        </div>
        <h4 className="mt-5 mb-3 brand-color font-semibold">Contact Details</h4>
        <div className="row mb-3">
          <div className="col-6">
            <InputType1 className="modal-input" id="emailAddress" value={formData?.emailAddress} onChange={(e) => handleChange(e, 'emailAddress')} label="Email" isMandatory="true" />
          </div>
          <div className="col-6">
            <InputType1 className="modal-input" id="phoneNo" value={formData?.phoneNo} onChange={(e) => handleChange(e, 'phoneNo')} label="Phone" />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <InputType1 className="modal-input" id="mobileNo" value={formData?.mobileNo} onChange={(e) => handleChange(e, 'mobileNo')} label="Mobile" />
          </div>
          <div className="col-6">
            <InputType1 className="modal-input" id="fax" value={formData?.fax} onChange={(e) => handleChange(e, 'fax')} label="Fax" />
          </div>
        </div>
        <div className="mt-4">
          <span>
            Notify users on impending surveys for mapped vessels before
          </span>
          <span>
            <input
              className="mx-2 small small-input-box text-box"
              id="surveyNotificationDays"
              value={formData?.surveyNotificationDays || ''}
              onChange={(e) => handleChange(e, 'surveyNotificationDays')}
            />
          </span>
          <span>
            day(s)
            <span className="text-primaryRed-1">*</span>
          </span>
        </div>
      </div>

    </div>
  );
};
export default AddGroup;
