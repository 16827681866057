/* eslint-disable max-len */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import _ from 'lodash';
import { statusDot, jobNameMap } from './constant';

const AddRole = (props) => {
  const { specList, addRole, setSpecList, isCompleted, supervisorUsers } = props;
  const onRoleChange = (e) => {
    const currentSpec = { ...specList };
    currentSpec.specQuoteList[addRole.mainKey]
      .specDataList[addRole.subKey].specAssignedStaff = e.value;
    setSpecList(currentSpec);
  };
  const specData = specList.specQuoteList[addRole.mainKey].specDataList[addRole.subKey];
  return (
    <div>
      <div className="d-flex bg-sky-white align-items-center p-3 justify-content-between">
        <div className="d-flex align-items-center">
          <div className="d-flex head-14 ">{specList.specQuoteList[addRole.mainKey].specDataList[addRole.subKey].specificationNo}</div>
          <div className="  ms-4 head-14">
            <span
              data-tooltip-id="project-phrep"
              data-tooltip-content={specList.specQuoteList[addRole.mainKey]
                .specDataList[addRole.subKey].title}
            >
              {_.truncate(specList.specQuoteList[addRole.mainKey]
                .specDataList[addRole.subKey].title, { length: 20 })}
            </span>

          </div>
          <span className="icon-woha ms-3" />
          <div className="couter-bubble p-2 ms-3 width-fit bg-white">{`${specData.workOrderClosedCount}/${specData.workOrderTotalCount}`}</div>
        </div>
        <div className="d-flex align-items-center ">
          {statusDot(specData.jobStatus)}
          {jobNameMap[specData.jobStatus]}
        </div>
      </div>
      <div className="mt-5">
        <div className="opacity-6 mb-2">Name</div>
        <Select
          className="modal-select"
          id="rolename"
          isDisabled={isCompleted}
          options={supervisorUsers}
          onChange={(e) => onRoleChange(e)}
          value={supervisorUsers?.filter((roleItem) => roleItem.value === specData.specAssignedStaff)[0]}
        />
      </div>
      <Tooltip id="project-phrep" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddRole;
