/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import SpecCard from './specCard';
import DependentSpecCard from './dependentSpecCard';

const SpecMainCard = (props) => {
  const { onArrowClick, specDetails, setSelectedSpecs } = props;
  return (
    <div data-testid="dependent-main">
      <div className=" sub-heading break-all  mb-4 mx-3 no-shadow font-semibold l bg-sky-white p-3 card text-ellipsis">
        <span
          data-tooltip-id="project-dep"
          data-tooltip-content={`${specDetails?.specificationNo}. ${specDetails?.title}`}
        >
          {`${specDetails?.specificationNo}. ${specDetails?.title}`}
        </span>

      </div>
      <div className="">
        <div className="left-content-spec-wrap">
          <div className="list-tab-head d-flex justify-content-around">
            <div className="col-6 ">
              <SpecCard {...props} />

            </div>
            <div className="align-self-lg-center pointer">
              <div
                className="spec-arrow-width bg-sky-white"
                id="add"
                title="Add Items"
                onClick={() => {
                  setSelectedSpecs({});
                  onArrowClick('right');
                }}
              >
                <div className="icon-arrow-thin-right font-16 brand-color p-3" />
              </div>
              <div
                className="spec-arrow-width bg-sky-white mt-5"
                title="Remove Items"
                id="remove"
                onClick={() => {
                  setSelectedSpecs({});
                  onArrowClick('left');
                }}
              >
                <div className="icon-arrow-thin-left font-16 brand-color p-3" />
              </div>
            </div>
            <div className="col-5 ">

              <DependentSpecCard {...props} />
            </div>
          </div>
        </div>
      </div>
      <Tooltip id="project-dep" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default SpecMainCard;
