/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import _, { cloneDeep } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../../../common/InputType1';
import UploadDoc from './uploadDoc';
import helper from '../../../../../../utils/helper';

const AddNewSpecLine = (props) => {
  const { formData, ondrop, setFormData, acCodeList,
    handleChange, projectCurrency, secItem, newACCode, setnewACCode } = props;
  const { restrictSpecialCharacters, formatNumber, ZERO_UUID } = helper;
  useEffect(() => {
    if ((formData.accodeId === ZERO_UUID || !formData.accodeId) && formData.accode) {
      setnewACCode(true);
    }
  }, []);
  return (
    <div className="container-fluid" data-testid="spec-line">
      <div className="row mb-3 align-items-center ">
        <div className="col-6">
          <InputType1
            id="title"
            name="title"
            isMandatory="true"
            onChange={(e) => handleChange(e, 'title', '')}
            value={formData?.title}
            maxLength={200}
            className="modal-inputs"
            label="Non Spec Line"
          />
        </div>
        <div className="col-6">
          <InputType1
            id="prPoNo"
            name="prPoNo"
            value={formData.prPoNo}
            onChange={(e) => handleChange(e, 'prPoNo', '')}
            className="modal-input"
            label="PO/PR Number"
          />
        </div>
        <div className="col-6 mt-4">
          <InputType1
            id="item"
            name="item"
            value={formData.item}
            onChange={(e) => handleChange(e, 'item', '')}
            className="modal-input"
            label="Item"
          />
        </div>
        {newACCode
          ? (
            <div className="col-6 mt-4">
              <InputType1
                id="accode"
                name="accode"
                maxLength="50"
                value={formData.accode ? formData.accode : ''}
                onChange={(e) => handleChange(e, 'accode')}
                className="modal-input"
                label="AC Code"
              />
            </div>
          ) : (
            <div className="col-6 mt-4">
              <label>
                AC Code
              </label>
              <Select
                placeholder="AC code"
                options={acCodeList}
                id="accodeId"
                classNamePrefix="react-tag"
                value={acCodeList?.filter((val) => val.value === formData.accodeId)}
                onChange={(e) => {
                  handleChange(e, 'accodeId', 'select');
                }}
              />
            </div>
          )}
        <div className="check-box mt-3 ac-check">
          <input
            type="checkbox"
            id="newACCode"
            name="newACCode"
            checked={newACCode}
            onChange={() => {
              if (!newACCode) {
                const temp = cloneDeep(formData);
                temp.accodeId = ZERO_UUID;
                temp.accode = '';
                setFormData(temp);
              }
              setnewACCode(!newACCode);
            }}
          />
          <label htmlFor="newACCode">Input AC Code Manually</label>
        </div>
        <div className="col-4 mt-4">
          <InputType1
            value={formData?.amount === 0 ? '' : formData.amount}
            name="amount"
            onChange={(e) => handleChange(e, 'amount', '')}
            onKeyPress={(e) => restrictSpecialCharacters(e) && e.preventDefault()}
            label="Amount"
            type="number"
            id="amount"
            className="modal-inputs hide-arrows"
            isMandatory="true"
          />
        </div>
        <div className="col-4 mt-4">
          <label>
            Currency
            <span className="text-primaryRed-1">*</span>
          </label>
          <Select
            className="modal-select"
            label="Currency"
            id="currencyId"
            placeholder="Select"
            isDisabled={formData.amount === ''}
            options={projectCurrency}
            value={projectCurrency?.filter((e) => e.value === formData?.currencyId)}
            onChange={(e) => handleChange(e, 'currencyId', 'select')}
          />
        </div>
        <div className="col-4 mt-4">
          <InputType1
            value={formData?.ownerCost === 0 || formData?.amount === '' ? '' : formatNumber(formData?.ownerCost)}
            name="owner"
            label={`Owner's Cost (${secItem?.projectCurrencySymbol})`}
            disabled
            id="owner"
            className="modal-inputs"
          />
        </div>
        <UploadDoc ondrop={ondrop} formData={formData} setFormData={setFormData} />
      </div>
    </div>
  );
};
export default AddNewSpecLine;
