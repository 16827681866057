/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import helper from '../../../../utils/helper';
import ModalBox from '../../../common/ModalBox';
import SOF from '../PhasePage/Repair/Children/SOF';
import { deleteSOF, saveSOF } from '../services';
import showAlert from '../../../../utils/alert';
import Audit from './Audit';

const SubHead = (props) => {
  const { projectPhase, handleValidation, formValidation,
    charValidation, ZERO_UUID, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isMaster = userRole === roles.VESSEL_USER;
  const [showSOF, setShowSOF] = useState(false);
  const [AuditLog, setAuditLog] = useState(false);
  const [addNewSOF, setAddNewSOF] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [sofId, setsofId] = useState('');
  const [editCliked, setIsEditClicked] = useState(false);
  const [addCliked, setIsAddClicked] = useState(false);
  const { location, id, phase, isTSI, handleSidebarToggle, tenderStatus } = props;
  const initialFormData = {
    id: ZERO_UUID,
    projectId: id,
    statement: '',
    isDeleted: false,
    sofTimeStamp: '',
    updatedAt: '2024-04-11T09:09:18.292Z'
  };
  const [formData, setFormData] = useState(initialFormData);
  const [sofData, setSofData] = useState();

  const showSOFModal = () => {
    setShowSOF(true);
  };

  const closeSOFModal = () => {
    setShowSOF(false);
  };
  const onDelete = (data) => {
    setOpenDeleteModal(true);
    setsofId(data?.id);
  };
  const onEdit = (data) => {
    setAddNewSOF(true);
    setIsEditClicked(true);
    setFormData(data);
    setsofId(data?.id);
    setIsAddClicked(false);
  };
  const deleteApiCall = () => {
    deleteSOF(sofId).then((res) => {
      if (res.success) {
        showAlert('Deleted Successfully', 'success');
        setOpenDeleteModal(false);
        setsofId('');
        setIsReload(true);
      }
    });
  };
  const handleChange = (field, e) => {
    const data = cloneDeep(formData);
    if (field === 'sofTimeStamp') {
      data[field] = charValidation(e);
    } else {
      data[field] = charValidation(e.target.value, 200);
    }
    setFormData(data);
    handleValidation(['statement', 'sofTimeStamp'], data, field);
  };
  const saveApiCall = () => {
    if (formValidation(['statement', 'sofTimeStamp'], formData)) {
      formData.id = sofId || ZERO_UUID;
      saveSOF(formData).then((res) => {
        if (res.success) {
          setIsReload(true);
          setFormData(initialFormData);
          setAddNewSOF(false);
          setIsEditClicked(false);
          setIsAddClicked(false);
          if (editCliked) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const navigate = useNavigate();
  const params = qs.parse(window.location.search);
  useEffect(() => {
    if (params.vesselId) localStorage?.setItem('vesselId', params.vesselId);
  }, [params.vesselId]);
  if (
    location?.pathname.includes('/specify')
    || location?.pathname.includes('/plan')
    || location?.pathname.includes('/tender')
    || location?.pathname.includes('/repair')
    || location?.pathname.includes('/close')
  ) {
    return (
      <>
        <div className="sub-header d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <button type="button" className="show-sm pe-4 me-4 brand-color respo-menu-btn" onClick={handleSidebarToggle}>
              <span className="icon-menu" />
            </button>
            {params?.isfromddhistory === 'ddhistory' ? (
              <button
                onClick={() => navigate('/admin/data/spec/DDHistory')}
                type="button"
                className="link-btn"
              >
                <span className="icon-arrow-left2 me-2" />
                Back
              </button>
            ) : (
              <button
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  userRole === 'admin' ? navigate('/admin/projectList') : navigate('/user/projects');
                }}
                type="button"
                className="link-btn"
              >
                <span className="icon-arrow-left2 me-2" />
                Back to projects
              </button>
            )}
          </div>
          <div className="d-flex item-set col-gap-2">
            <button
              type="button"
              className={`rnd-btn ${location?.pathname.includes('/plan') ? 'active' : ''}`}
              onClick={() => {
                navigate(`/user/project/${id}/plan?vesselId=${params.vesselId}`);
              }}
            >
              Plan
            </button>
            <button
              disabled={phase < projectPhase.SPECIFY}
              type="button"
              className={`rnd-btn ${location?.pathname.includes('/specify') ? 'active' : ''} ${phase < projectPhase.SPECIFY && ' disabled'}`}
              onClick={() => {
                navigate(`/user/project/${id}/specify?vesselId=${params.vesselId}`);
              }}
            >
              Specify
            </button>
            {/* {isMaster ? (
              ''
            ) : ( */}
            <button
              disabled={phase < projectPhase.TENDER}
              type="button"
              // className={`rnd-btn ${location?.pathname.includes('/tender') ? 'active' : ''}
              // `}
              className={`rnd-btn  ${location?.pathname.includes('/tender') ? 'active' : ''} ${phase < projectPhase.TENDER && ' disabled'}`}
              onClick={() => {
                navigate(`/user/project/${id}/tender?vesselId=${params.vesselId}`);
              }}
            >
              Tender
            </button>
            {/* )} */}
            <button
              disabled={phase < projectPhase.REPAIR && !(tenderStatus === 2)}
              type="button"
              className={`rnd-btn ${location?.pathname.includes('/repair') ? 'active' : ''} ${(phase < projectPhase.REPAIR && !(tenderStatus === 2)) && 'disabled'}`}
              onClick={() => {
                navigate(`/user/project/${id}/repair?vesselId=${params.vesselId}`);
              }}
            >
              Repair
            </button>
            {isMaster ? (
              ''
            ) : (
              <button
                type="button"
                disabled={phase < projectPhase.CLOSE}
                className={`rnd-btn ${location?.pathname.includes('/close') ? 'active' : ''} ${phase < projectPhase.CLOSE && 'disabled'}`}
                onClick={() => {
                  navigate(`/user/project/${id}/close?vesselId=${params.vesselId}`);
                }}
              >
                Close
              </button>
            )}
          </div>
          <div className="d-flex col-gap-1">
            {location?.pathname?.includes('/specify') && (
            <button type="button" className="rnd-btn" onClick={() => setAuditLog(true)}>
              Audit Log
            </button>
            )}
            {location?.pathname?.includes('/repair') && (
            <button
              type="button"
              className="rnd-btn"
              onClick={() => {
                showSOFModal();
              }}
            >
              {' '}
              SoF
            </button>
            )}
            {showSOF && (
            <ModalBox
              modalClassName="medium"
              sofData={sofData}
              fromSof
              addClikedSof={addCliked}
              editClikedSof={editCliked}
              closeModal={() => {
                if (addNewSOF) {
                  setAddNewSOF(false);
                  setFormData(initialFormData);
                  setIsEditClicked(false);
                  setIsAddClicked(false);
                } else {
                  closeSOFModal();
                }
              }}
              heading={addCliked ? 'Add New SoF' : editCliked ? 'Edit SoF' : 'SoF-Statement of Facts'}
              buttonName={addNewSOF ? 'Save' : ''}
              onSave={() => saveApiCall()}
            >
              <SOF
                setAddNewSOF={setAddNewSOF}
                onDelete={onDelete}
                setSofData={setSofData}
                id={id}
                onEdit={onEdit}
                sofData={sofData}
                isReload={isReload}
                setIsReload={setIsReload}
                addNewSOF={addNewSOF}
                handleChange={handleChange}
                formData={formData}
                initialFormData={initialFormData}
                setFormData={setFormData}
                setsofId={setsofId}
                setIsEditClicked={setIsEditClicked}
                setIsAddClicked={setIsAddClicked}
                isTSI={isTSI}
              />
            </ModalBox>
            )}
            {isMaster ? (
              ''
            ) : (
              <>
                {!location?.pathname?.includes('/close') ? (
                  <>

                    <button onClick={() => navigate(`/user/project/${id}/paint-cost?vesselId=${params.vesselId}`)} type="button" className="rnd-btn">
                      Paint Cost
                    </button>

                  </>
                ) : (
                  <button type="button" className="rnd-btn opacity-0">
                    {' '}
                    Paint Cost
                    {' '}
                  </button>
                )}
              </>
            )}
            {/* {addNewSOF && (
    <ModalBox
      modalClassName="medium"
      closeModal={() => { setAddNewSOF(false); setFormData(initialFormData); }}
      heading="Add New SoF"
      buttonName="Save"
      onSave={() => saveApiCall()}
    >
      <AddNewSOF
        handleChange={handleChange}
        formData={formData}
        initialFormData={initialFormData}
        setFormData={setFormData}
        setsofId={setsofId}
      />
    </ModalBox>
    )} */}
            {openDeleteModal ? (
              <ModalBox
                openDeleteModal={openDeleteModal}
                modalClassName="small"
                heading="Delete"
                onSave={() => deleteApiCall()}
                closeModal={() => setOpenDeleteModal(false)}
                buttonName="Delete"
              >
                <p>Are you sure you want to delete ?</p>
              </ModalBox>
            ) : (
              ''
            )}
          </div>
        </div>
        <div>
          {AuditLog && <Audit setAuditLog={setAuditLog} AuditLog={AuditLog} />}
        </div>

      </>

    );
  }
  return null;
};
export default SubHead;
