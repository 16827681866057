export const mockData = {

};
export const Documents = [
  {
    label: 'Vessel Particulars :',
    key: 'vesselParticulars',
    isListing: 1
  },
  {
    label: 'Vessel Plans and Drawings :',
    key: 'vesselAttachments',
    type: 'vessel'
  },
  {
    label: 'DD summary report by the TSI :',
    key: 'summaryReport',
    id: 1,
    canAttach: true
  },
  {
    label: 'Yard Schedule :',
    key: 'yardSchedule',
    id: 2,
    canAttach: true
  },
  {
    label: 'Daily Reports :',
    key: 'dailyReports',
    type: 'photoreport'
  },
  {
    label: 'DD-Repair specification :',
    key: 'yardInvoice',
    type: 'repairinvoice'
  },
  {
    label: 'Class Certificate :',
    key: 'classCertificate',
    id: 3,
    canAttach: true
  },
  {
    label: 'Work done report :',
    key: 'yardInvoice',
    type: 'wreport'
  },
  {
    label: 'Yard Invoice :',
    key: 'yardInvoice',
    type: 'invoice'
  },
  {
    label: 'Paint Cost Report :',
    key: '',
    type: 'paintcost'
  },
  {
    label: 'Service Engineer Report :',
    key: 'serviceEngineerReport',
    id: 4,
    canAttach: true
  },
  {
    label: 'Photo Report :',
    key: 'photoReport',
    type: 'photoreport'
  },
  {
    label: 'Yard Evaluation Report :',
    key: 'yardEvaluation',
    type: 'evaluation'
  },
  {
    label: 'Miscellaneous :',
    key: 'miscellaneousData',
    id: 5,
    canAttach: true
  }
];
export const particulars = [
  {
    label: 'Vessel Name :',
    key: 'name'
  },
  {
    label: 'Type of vessel : ',
    key: 'vesselType'
  },
  {
    label: 'Yard built in : ',
    key: 'yardBuiltIn'
  },
  {
    label: 'Hull No : ',
    key: 'hullNo'
  },
  {
    label: 'Port of Registry :',
    key: 'portOfRegistry'
  },
  {
    label: 'Call Sign :',
    key: 'callSign'
  },
  {
    label: 'IMO no : ',
    key: 'imoNo'
  },
  {
    label: 'Telephone : ',
    key: 'phone'
  },
  {
    label: 'E-mail :  ',
    key: 'email'
  },
  {
    label: 'Classification :  ',
    key: 'vesselClass'
  },
  {
    label: 'Class Notation :  ',
    key: 'classNotation'
  },
  {
    label: 'Main Engine : ',
    key: 'mainEngineType'
  },
  {
    label: 'Aux Engine :  ',
    key: 'auxEngineType'
  },
  {
    label: 'Dead Weight : ',
    key: 'deadWeight'
  },
  {
    label: 'Depth : ',
    key: 'depth'
  }
];
