import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import ModalBox from '../../../../../common/ModalBox';
import ImportReceivedQuote from '../../Repair/AddVariation/ReceivedQuotes/ImportReceived';
import ReceivedSpecSupplemetaryTable from './receivedSpecSupplementaryTable';
import { ImportReceivedQuoteSupplementary, getReceivedQuoteYardList, getSupplementaryReceivedQuotes, UpdateSupplementaryStatus } from './services';
import StatusConfirmModal from './statusConfirmModal';

const ReceivedSpecs = (props) => {
  const { onSecOpen, mainSec, showsubSec, projectId,
    viewMore, viewMoreAttachments, viewMoreComments, setShowSideBar,
    setIsComponentClicked, setRecSpecList, projectDetails,
    recSpecList } = props;

  // const [recSpecList, setRecSpecList] = useState([]);
  const [indexs, setIndex] = useState(0);
  const [rfqId, setRfqId] = useState();
  const { MAX_FILE_SIZE } = helper;
  const [file, setFile] = useState('');
  // const [fileUploaded, setFileUploaded] = useState(false);
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const [openYardQuote, setOpenYardQuote] = useState(false);
  // const [filteredData, setFilteredData] = useState([]);
  const [showStatusConfirmModal, setShowStatusConfirmModal] = useState(false);
  const [yardSpec, setYardSpec] = useState([]);
  useEffect(() => {
    getReceivedQuoteYardList(projectId).then((res) => {
      if (res.success) {
        setRecSpecList(res.data);
      }
    });
  }, []);
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE}`, 'error');
      } else {
        setFile(first);
      }
    } else if (first) {
      showAlert('Please upload a excel file type', 'error');
    }
  };

  const goToNext = (e) => {
    if (e === 'next') {
      if (recSpecList?.length !== indexs + 1) {
        setIndex(indexs + 1);
        setRfqId(recSpecList[indexs + 1]?.yardId);
      }
    }
    if (e === 'prev') {
      if (indexs - 1 > -1) {
        setIndex(indexs - 1);
        setRfqId(recSpecList[indexs - 1]?.yardId);
      }
    }
    // setIsViewMoreClose(false);
  };

  useEffect(() => {
    setRfqId(recSpecList[0]?.yardId);
  }, [recSpecList]);

  const getList = (yardId) => {
    getSupplementaryReceivedQuotes({ projectId, yardId }).then((res) => {
      if (res.success) {
        setYardSpec(res.data);
      }
    });
  };

  const handleYard = (yardId) => {
    getList(yardId);
  };

  const handleStatusConfirm = () => {
    const status = [];
    yardSpec?.forEach((item) => {
      item?.childData?.forEach((items) => {
        status.push({ specId: items.id,
          status: items.supplementaryStatus,
          comments: items.comments,
          yardId: rfqId });
      });
    });
    UpdateSupplementaryStatus(status).then((res) => {
      if (res.success) {
        setShowStatusConfirmModal(true);
        getList();
      }
    });
  };
  useEffect(() => {
    // if (rfqId) {
    handleYard(rfqId);
    // }
  }, [rfqId]);

  const saveApiCall = () => {
    const body = new FormData();
    body.append('file', file);
    const id = projectId;
    if (file) {
      ImportReceivedQuoteSupplementary(id, body)?.then((res) => {
        if (res?.data?.importSuccess) {
          // setOpenYardQuote(false);
          getReceivedQuoteYardList(id).then((resp) => {
            setRecSpecList(resp.data);
          });
          showAlert('File has been successfully Imported', 'success');
        } else {
          showAlert(res?.data?.message, 'error');
        }
        setFile('');
        setOpenYardQuote(false);
      });
    } else {
      showAlert('Please choose a file', 'error');
    }
  };

  return (
    <>
      <div data-testid="received-supplementary" className="">
        <div className="mt-3">
          {/* {isTSI && ( */}
          <button
            type="button"
            className="secondary-btn large"
            id="yard-quote"
            data-test="click4"
            disabled={projectDetails?.phase > 3}
            onClick={() => {
              setOpenYardQuote(true);
            }}
          >
            Import Received Quotes
          </button>
          {/* )} */}

        </div>
        {recSpecList?.length > 0 && (
        <div className="d-flex">
          <button
            className="link-btn mt-5"
            type="button"
            data-test="click1"
            onClick={() => goToNext('prev')}
          >
            <span className="icon-arrow-thin-left font-18 me-2" />
          </button>
          <div className="d-flex sub-header-quote ms-2 me-2 mt-5">
            <>
              {recSpecList && (

              <div className="d-flex item-set col-gap-1 me-3">
                {recSpecList?.map((items, index) => {
                  return (

                    <button
                      data-test="click2"
                      onClick={() => {
                        handleYard(items.yardId);
                        setRfqId(items?.yardId);
                        setIndex(index);
                        // setRecSpecList(filteredDataById);
                        // setIsViewMoreClose(false);
                      }}
                      className={items?.yardId === rfqId ? 'rnd-btn active w-150' : 'text-ellipsis rnd-btn w-150'}
                      type="button"
                    >
                      <span
                        className="text-ellipsis w-150"
                        data-tooltip-id="project-addRem"
                        data-tooltip-content={items?.yardName}
                      >
                        {items?.yardName}
                      </span>
                    </button>

                  );
                })}
              </div>

              )}

            </>
          </div>
          <button
            className="link-btn mt-5"
            type="button"
            data-test="click3"
            onClick={() => goToNext('next')}
          >
            <span className="icon-arrow-thin-right font-18 me-2" />
          </button>
        </div>
        )}

        <Tooltip id="project-addRem" place="bottom" className="tooltip mt-2" />
      </div>
      {recSpecList.length > 0 && (
        <div className="mt-5">
          <ReceivedSpecSupplemetaryTable
            specList={yardSpec}
            setFilteredData={setYardSpec}
            onSecOpen={onSecOpen}
            mainSec={mainSec}
            showsubSec={showsubSec}
            // filteredDataById={filteredDataById}
            viewMore={viewMore}
            viewMoreAttachments={viewMoreAttachments}
            viewMoreComments={viewMoreComments}
            setRecSpecList={setRecSpecList}
            setYardSpec={setYardSpec}
            handleStatusConfirm={handleStatusConfirm}
            ondrop={ondrop}
            saveApiCall={saveApiCall}
          />
        </div>
      )}

      {openYardQuote && (
      <ModalBox
        heading="Import Received Quotes"
        closeModal={() => {
          setOpenYardQuote(false);
          setFile('');
        }}
        onSave={() => saveApiCall()}
        buttonName="Import"
        modalClassName="medium modal-scroll"
      >
        <ImportReceivedQuote ondrop={ondrop} setFile={setFile} file={file} />
      </ModalBox>
      )}

      {showStatusConfirmModal && (
        <StatusConfirmModal
          setShowStatusConfirmModal={setShowStatusConfirmModal}
          setShowSideBar={setShowSideBar}
          setIsComponentClicked={setIsComponentClicked}
        />
      )}

      {recSpecList.length === 0 && (
      <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
        <div className="font-18 animate__animated animate__headShake">
          No data found
        </div>
      </div>
      )}
      <footer className="side-panel__footer">
        {/* {statusState.new && ( */}
        {/* <button
          disabled={projectDetails?.phase > 3 || recSpecList.length === 0}
          type="button"
          data-test="click4"
          className="primary-btn me-4 large"
          onClick={() => handleStatusConfirm()}
        >
          Confirm
        </button> */}
        {/* )} */}
      </footer>
    </>
  );
};
export default ReceivedSpecs;
