/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import _ from 'lodash';
import { classMap } from './progressCard';
import { getMonthList, statusColor, customDropDown } from './constant';

const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const monthwise = [...monthList.slice(moment()._d.getMonth(), 12),
  ...monthList, ...monthList, ...monthList.slice(0, moment()._d.getMonth())];
const monthInYear = 12 - moment()._d.getMonth();
const currentYear = moment()._d.getFullYear() - 1;
const getYear = (key) => {
  if (key < monthInYear) {
    return currentYear;
  } if (key < monthInYear + 12) {
    return currentYear + 1;
  } if (key < monthInYear + 24) {
    return currentYear + 2;
  } return currentYear + 3;
};
export const phaseNotify = (key) => {
  if (key === 'Plan') {
    return <div className="plan">P</div>;
  } if (key === 'Specify') {
    return <div className="specify">S</div>;
  } if (key === 'Tender') {
    return <div className="tender">T</div>;
  } if (key === 'Repair') {
    return <div className="repair">R</div>;
  } if (key === 'Close') {
    return <div className="close">C</div>;
  }
  return null;
};
const TableView = (props) => {
  const { taskList, openedList, onOpenTask, statusChange, editMicro, deleteMicro,
    projDetails } = props;

  const multipleSurveys = (survey, month, year, task) => {
    const currentMont = moment(survey)._d.getMonth() + 1;
    const currentYearVal = moment(survey)._d.getFullYear();
    const endYearVal = moment(task.startDate).add(task.offset, 'days')._d.getFullYear();
    const endMonth = task.endDate ? moment(task.endDate)._d.getMonth() + 1 : moment(task.startDate).add(task.offset, 'days')._d.getMonth() + 1;
    if ((currentMont <= month && endMonth >= month)
    && (currentYearVal <= year && endYearVal >= year)) {
      return 'y';
    }
    return null;
  };
  const multipleSurveyDate = (date) => {
    return `${moment(date.startDate).format('MMM Do')} to ${moment(date.startDate).add(date.offset, 'days').format('MMM Do')}`;
  };

  const surveyMonths = (task) => {
    return (
      <>
        {
          monthwise.map((num, key) => {
            return (
              <td className="whitespace-pre white w-full p-0">
                {multipleSurveys(task.startDate, getMonthList[num], getYear(key), task) && (
                <div
                  className={`${classMap[task.statusName]} w-full`}
                  data-tooltip-id="project-utility"
                  data-tooltip-content={multipleSurveyDate(task)}
                >
                  {multipleSurveys(task.startDate, getMonthList[num], getYear(key), task) ? ' ' : ''}
                </div>
                )}
              </td>
            );
          })
        }
      </>

    );
  };
  return (
    <div className="tsitask-wrapper" data-testid="tsi-tasktable">
      <div className="container-fluid">
        <div className="relative d-flex gant-tabl" style={{ overflow: 'auto', scrollbarGutter: 'stable' }}>
          <table className="card-table over relative">
            <thead className="v-thead-fixed z-index-1100">
              <tr>
                <th className="fixed-gnt whitespace-pre">Tasks</th>
                {monthwise.map((items, key) => {
                  return (
                    <th>
                      {items}
                      <div className="font-12">{getYear(key)}</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              { taskList.map((item, key) => {
                return (
                  <>
                    <tr className="5">
                      <td className={item?.isCompleted
                        ? 'whitespace-pre fixed-gnt font-14 ps-2 bg-white'
                        : 'whitespace-pre fixed-gnt font-14 ps-2 bg-pending-task'}
                      >
                        <div className="ms-1 d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center text-uppercase font-bold" data-test="click1" id={item.id} onClick={() => { onOpenTask(item); }}>
                            {openedList.indexOf(item.id) > -1
                              ? <span className="pointer icon-arrow-thin-down font-8 me-3 font-semibold" />
                              : <span className="pointer icon-arrow-thin-right font-12 me-2 font-semibold" />}
                            <div className="t-statusdot ms-1" style={{ backgroundColor: statusColor(item.status) }} />
                            <span
                              data-tooltip-id="project-utility"
                              data-tooltip-content={item?.taskName}
                            >
                              {_.truncate(item?.taskName, {
                                length: 20,
                                separator: ' '
                              })}
                            </span>
                          </div>
                          <div className="d-flex align-items-center ps-3">
                            <div className="phase-ind me-2">{phaseNotify(item.phase)}</div>
                            {customDropDown(statusChange, item, 'main', item.id, key, projDetails)}
                          </div>
                        </div>
                      </td>
                      {surveyMonths(item, item)}
                    </tr>
                    { openedList.indexOf(item.id) > -1 && item?.taskCategoryList?.map((sub) => {
                      return (
                        <>
                          <tr className="sub">
                            <td
                              className={sub?.isCompleted
                                ? 'whitespace-pre fixed-gnt font-13 ps-5 font-semibold bg-white'
                                : 'whitespace-pre fixed-gnt font-13 ps-5 font-semibold bg-pending-task'}
                            >
                              <div className="ps-2 ms-3 d-flex justify-content-between align-items-center">
                                <div className="pointer value d-flex align-items-center" data-test="click2" id={sub.id} onClick={() => { onOpenTask(sub); }}>
                                  {openedList.indexOf(sub.id) > -1
                                    ? <span className="pointer icon-arrow-thin-down font-8 me-3" />
                                    : <span className="pointer icon-arrow-thin-right font-12 me-2" />}
                                  <span className="t-statusdot ms-1" style={{ backgroundColor: statusColor(sub.status) }} />
                                  <span data-tooltip-id="project-utility" data-tooltip-content={sub.taskCategoryName}>
                                    {_.truncate(sub.taskCategoryName, { length: 27 })}
                                  </span>
                                </div>
                                {!projDetails?.isClosed && (
                                <div className="d-flex align-items-center">
                                  <span className="icon-plus-square me-3 ms-2 font-22 pointer brand-color" data-test="click3" onClick={() => editMicro(sub, true)}> </span>
                                  {customDropDown(statusChange, sub, 'sub', item.id)}
                                </div>
                                )}

                              </div>
                            </td>
                            {surveyMonths(sub, item)}
                          </tr>
                          {openedList.indexOf(sub.id) > -1 && sub.microTaskList?.map((micro) => {
                            return (
                              <tr>
                                <td className={micro?.isCompleted
                                  ? 'whitespace-pre fixed-gnt font-13 font-semibold ps-5 bg-white'
                                  : 'whitespace-pre fixed-gnt font-13 font-semibold ps-5 bg-pending-task'}
                                >
                                  <div className="ps-4 ms-5 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                      <span className="t-statusdot ms-2" style={{ backgroundColor: statusColor(micro.status) }} />
                                      <span data-tooltip-id="project-utility" data-tooltip-content={micro.microTaskName}>
                                        {_.truncate(micro.microTaskName, { length: 24 })}
                                      </span>
                                    </div>
                                    {/* {details.tsiId === localStorage.getItem('USER_ID') */}
                                    {!projDetails.isClosed
                                    && (
                                    <div className="d-flex align-items-center ms-3">
                                      <button disabled={micro.status === 3} className="link-btn me-3" data-test="click4" type="button" onClick={() => editMicro(micro, false)}>
                                        <span className="icon-edit font-20 brand-color" />
                                      </button>
                                      <button disabled={micro.status === 3} className="link-btn me-3" data-test="click5" type="button" onClick={() => deleteMicro(micro)}>
                                        <span className="icon-delete font-20 brand-color" />
                                      </button>
                                      {customDropDown(statusChange, micro, 'micro', item.id)}
                                    </div>
                                    )}
                                  </div>
                                </td>
                                {surveyMonths(micro, '1111')}
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}

                  </>
                );
              })}
            </tbody>
          </table>
          <Tooltip id="project-utility" place="top" className="tooltip mt-3" />
        </div>

      </div>
      <div style={{ height: '3em' }} />
    </div>
  );
};
export default TableView;
