import React, { useState } from 'react';
import moment from 'moment';
import { fileImages } from '../../../../../common/mappingConst';

const PhotoReports = (props) => {
  const { reports, data } = props;
  const [expand, setExpand] = useState(false);
  return (
    <div className="border md-radius">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer'}
            onClick={() => { setExpand(!expand); }}
          />
        </div>
      </div>
      {expand
      && (
      <div className="col-11 ps-3">
        {reports?.map((field) => {
          return (
            <div className="head-15 d-flex align-items-center my-2">
              {/* <span className="icon-file-text2 brand-color font-22 me-3" /> */}
              <div className="thmb-icon-sm1 me-2 brand-color font-22">
                <span className={fileImages.pdf} />
              </div>
              <div className="head-10 opacity-6 pointer">
                <a target="_blank" href={field?.reportFile?.path} rel="noreferrer">
                  {field?.heading}
                  { field.reportDate ? (
                    <span>
                      -
                      {moment.utc(field?.reportDate).format('DD-MMM-YYYY')}
                    </span>
                  ) : ''}

                </a>
              </div>
              <div className="mt-2 red d-flex align-items-center">
                <a href={field?.reportFile?.path} className="" target="_blank" rel="noreferrer">
                  <span className="icon-eye ms-4 font-20 brand-color pointer" />
                </a>
              </div>
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
};
export default PhotoReports;
