import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { truncate } from 'lodash';

const Table1Container = (props) => {
  const { specList, mainSec, showsubSec, onSecOpen, viewMore } = props;
  return (
    <div className="card spc-map mt-3 p-3 bottom-mrn" data-testid="a-3">
      <div className="d-flex border-down py-2 ps-3">
        <div className="col-1 font-bold">SEC#</div>
        <div className="col-8 font-bold">SECTION</div>
        <div className="col-3 font-bold" />
      </div>
      {specList?.sectionData?.map((sec) => {
        return (
          <div className={sec.projectSpecId === mainSec && showsubSec ? 'highlight-tender' : ''}>
            <div className="d-flex border-down py-2 ps-3">
              <div className="col-1 d-flex align-items-center">
                <span
                  id={sec.projectSpecId}
                  className={
                    sec.projectSpecId === mainSec
                      ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                      : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                  }
                  data-test="click1"
                  onClick={() => {
                    onSecOpen(sec.projectSpecId, true);
                  }}
                />
                {/* <span>{sec.specificationNo}</span> */}
                <span
                  data-tooltip-id="project-manage"
                  data-tooltip-content={sec.specificationNo}
                >
                  {truncate(sec.specificationNo, { length: 7 })}
                </span>
              </div>
              <div
                className="col-8"
              >
                <span
                  data-tooltip-id="project-manage"
                  data-tooltip-content={sec.title}
                >
                  {truncate(sec.title, { length: 80 })}
                </span>

              </div>
              <Tooltip id="project-manage" place="top" className="tooltip mt-3" />
              <div className="col-3" />
            </div>
            {sec.projectSpecId === mainSec
              && sec.specDataList.map((subsec) => {
                return (
                  <>
                    <div className="d-flex mb-2 py-2 ps-3">
                      <div className="col-1" />
                      <div className="col-9 d-flex align-items-center">
                        <span
                          id={subsec.projectSpecId}
                          className={
                            subsec.projectSpecId === showsubSec
                              ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180'
                              : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                          }
                          data-test="click2"
                          onClick={() => {
                            onSecOpen(subsec.projectSpecId, false);
                          }}
                        />
                        <span className="me-4">{subsec.specificationNo}</span>
                        <div
                          className=""
                          data-tooltip-id="project-manage"
                          data-tooltip-content={subsec.title}
                        >
                          {truncate(subsec.title, { length: 80 })}

                        </div>
                      </div>
                      <div className="col-2">
                        {subsec.projectSpecId === showsubSec ? (
                          <button
                            className="primary-btn small"
                            type="button"
                            data-test="click3"
                            onClick={() => {
                              viewMore(subsec);
                            }}
                          >
                            View More
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {subsec.projectSpecId === showsubSec
                      && subsec.serviceLineList.map((serv, num) => {
                        return (
                          <>
                            {num === 0 && (
                              <div className="d-flex ps-3 align-items-center">
                                <div className="col-1" />
                                <div className="col-2 bg-sky-white ps-3 border-down py-2">
                                  Service LN#
                                </div>
                                <div className="col-7 bg-sky-white ps-3 border-down py-2">
                                  Service Lines
                                </div>
                                <div className="col-1 bg-sky-white ps-3 border-down py-2">
                                  UOM
                                </div>
                                <div className="col-1 bg-sky-white ps-3 border-down py-2">
                                  Quantity
                                </div>
                              </div>
                            )}
                            <div className="d-flex pb-2 ps-3 py-1">
                              <div className="col-1" />
                              <div className="col-2 ps-3 border-down">
                                {truncate(serv.number, { length: 30 })}
                              </div>
                              <div
                                className="col-7 ps-3 border-down"

                              >
                                <span
                                  data-tooltip-id="project-manage"
                                  data-tooltip-content={serv.serviceLineName}
                                >
                                  {truncate(serv.serviceLineName, { length: 80 })}
                                </span>

                              </div>
                              <div className="col-1 ps-3 border-down">
                                {truncate(serv.uomName, { length: 30 })}
                              </div>
                              <div className="col-1 ps-3 border-down">
                                {serv.quantity}
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
export default Table1Container;
