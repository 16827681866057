import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetWorkOrderDefectListUrl,
  SaveSpecToWorkOrderDefect,
  GetSpecificationListByProjectId,
  GetTotalWohaCount,
  ImportDefectData,
  ImportWohaData,
  DownloadWohaExcelTemplate,
  DownloadPohaExcelTemplate,
  SaveWohaStatus,
  PaintCostTemplateUrl,
  exportExcelWOHAUrl,
  DeleteWoha
} = apiEndPoints;

const { downloadFile, getExportedFileName } = helper;

export const WorkOrderDefectList = (payload) => {
  return serviceHandler({
    url: `${GetWorkOrderDefectListUrl}`,
    methordType: 'post',
    payload
  });
};
export const SaveSpec = (payload) => {
  return serviceHandler({
    url: `${SaveSpecToWorkOrderDefect}?id=${payload.id}&projectSpecId=${payload.projectSpecId}`,
    methordType: 'post'
  });
};
export const SpecificationbyProject = (payload) => {
  return serviceHandler({
    url: `${GetSpecificationListByProjectId}?&projectId=${payload.id}&listWithoutNonSpec=${payload.listWithoutNonSpec}`,
    methordType: 'get'
  });
};
export const TotalCount = (payload) => {
  return serviceHandler({
    url: `${GetTotalWohaCount}?&projectId=${payload.id}`,
    methordType: 'get'
  });
};
export const importDefectData = (payload) => {
  return serviceHandler({
    url: `${ImportDefectData}`,
    methordType: 'post',
    payload
  });
};
export const importWohaData = (payload, projectId, vesselId) => {
  return serviceHandler({
    url: `${ImportWohaData}?projectId=${projectId}&vesselId=${vesselId}`,
    methordType: 'post',
    payload
  });
};
export const saveWohaStatus = (payload) => {
  return serviceHandler({
    url: `${SaveWohaStatus}?projectId=${payload.projectId}`,
    methordType: 'post',
    payload
  });
};
// export const wohaTemplate = (payload) => {
//   return serviceHandler({
//     url: `${DownloadWohaExcelTemplate}`,
//     methordType: 'get',
//     payload
//   });
// };
// export const pohaTemplate = (payload) => {
//   return serviceHandler({
//     url: `${DownloadPohaExcelTemplate}`,
//     methordType: 'get',
//     payload
//   });
// };
export const pohaTemplate = () => {
  return axios
    .get(`${DownloadPohaExcelTemplate}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    });
};
export const wohaTemplate = () => {
  return axios
    .get(`${DownloadWohaExcelTemplate}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    });
};
export const paintTemplate = () => {
  return axios
    .get(`${PaintCostTemplateUrl}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    });
};
export const exportExcelWOHA = (payload) => {
  return axios
    .post(`${exportExcelWOHAUrl}`, payload, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })

    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const deleteWohaApi = (payload) => {
  return serviceHandler({
    url: `${DeleteWoha}?wohaId=${payload.id}`,
    methordType: 'put',
    payload
  });
};
