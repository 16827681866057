import React from 'react';

const ListofJobs = (props) => {
  const { projectDetails } = props;
  if (projectDetails?.projectJobList.length > 0) {
    return (
      <div className="mb-5 ps-5 pt-5">
        <p className="font-20 head-black">List of jobs to be carried out</p>
        {
            projectDetails?.projectJobList.map((element) => {
              return (
                <div className="d-flex mt-4" key={element.id}>
                  <span className="icon-check-rnd tick-green font-26" />
                  <p className="ms-4 head-black">
                    {element.title}
                  </p>
                </div>
              );
            })
        }
      </div>
    );
  }
  return null;
};
export default ListofJobs;
