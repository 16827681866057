import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SpecList from '../SpecsList';
import { GetProjectSpecList } from '../services';
import SidePanel from '../../../../../common/sidePanelCustom';
import SpecifyApproval from './SpecifyApproval';
// import Loader2 from '../../../../../common/loader2';

const ApprovalSpecify = ({ isTSI }) => {
  const isTsiDisabled = true;
  const isSidePanel = false;
  const { id } = useParams();
  const [viewSpec, setViewSpec] = useState(false);
  const [specList, setSpecList] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState({});
  const [selectedSpecType, setSelectedSpecType] = useState('');
  const [searchText, setSearchText] = useState('');
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [currency, setCurrency] = useState('');
  const [loading, setLoading] = useState(false);

  const getGSpecData = (search = '') => {
    setSelectedSpecType('gt');
    const payload = {
      projectId: id,
      searchString: search
    };
    GetProjectSpecList(payload).then((res) => {
      if (res.success) {
        setLoading(false);
        setCurrency(res?.data?.currencySymbol);
        setSpecList(res.data);
      }
    });
  };
  useEffect(() => {
    getGSpecData();
    setLoading(true);
  }, []);
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getGSpecData(searchText);
    }
  };
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  const viewMore = (data) => {
    const d = {
      ...data,
      id: data.projectSpecId
    };
    setSelectedSpec(d);
    setViewSpec(true);
  };
  const viewMoreClose = () => {
    setSelectedSpec({});
    setViewSpec(false);
  };
  const onReload = () => {
    getGSpecData();
  };
  useEffect(() => {
    if (!searchText) {
      onReload();
    }
  }, [searchText]);
  return (
    <div className="content-wrap  mb-5" data-testid="a-1">
      <div className="phase-wrapper">
        <div>
          <div className="search large col-4 search-large spec-search">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                data-test="click1"
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span className="icon-search" data-test="click2" onClick={() => { onSearch({ key: 'Enter' }); }} />
            </div>
            <input
              value={searchText}
              type="text"
              id="search"
              className="text-box large"
              placeholder="Search by Sec No.,Sec Name, Spec No., Spec Name, ServiceLine No., ServiceLine Name"
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div className="row">
            {specList?.sectionData?.length ? (
              <SpecifyApproval
                specList={specList}
                showModal={showModal}
                setShowModal={setShowModal}
                setViewSpec={setViewSpec}
                mainSec={mainSec}
                showsubSec={showsubSec}
                onSecOpen={onSecOpen}
                viewMore={viewMore}
                viewMoreClose={viewMoreClose}
                onReload={onReload}
              />
            ) : (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">
                  No data found
                </div>
              </div>
            )}
            {viewSpec
              ? (
                <SidePanel
                  title={`${selectedSpec.specificationNo}. ${selectedSpec.title}`}
                  size="large"
                  onClose={() => viewMoreClose()}
                  showView="true"
                >
                  <div className="side-panel__container bg-white specify-side">
                    <div className="side-panel__content">
                      <SpecList
                        isSidePanel={isSidePanel}
                        isTSI={isTSI}
                        projectId={id}
                        isTsiDisabled={isTsiDisabled}
                        specDetails={selectedSpec}
                        selectedSpec={selectedSpec.id || ''}
                        selectedSpecType={selectedSpecType}
                        onReload={onReload}
                        fromViewMore
                        loading={loading}
                        currency={currency}
                        setLoading={setLoading}
                      />
                    </div>
                  </div>
                </SidePanel>
              ) : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApprovalSpecify;
