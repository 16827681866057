/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import qs from 'query-string';
import _, { cloneDeep } from 'lodash';
import Select from 'react-select';
import showAlert from '../../../../../utils/alert';
import ModalBox from '../../../../common/ModalBox';
import { s3Upload, saveAttachments, deleteAttachment, getDocumentCategryList, getVesselAttachmentList } from './services';
import helper from '../../../../../utils/helper';
import Listing from './listing';
import { useStatus } from '../index';

const acceptedFiles = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];

const Attachment = () => {
  const params = qs.parse(window.location.search);
  const { formValidation, MAX_FILE_SIZE, generateOptions, handleValidation } = helper;
  const [addModal, setAddModal] = useState(false);
  const [allAttachs, setAllAttachs] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState(allAttachs);
  const [fileLoading, setFileLoading] = useState(false);
  const { status } = useStatus() || {};
  const [documentCategoryList, setDocumentCategoryList] = useState([]);
  const [isExpand, setIsExpand] = useState('');
  const initialFormData = {
    name: '',
    fileName: '',
    vesselId: params.id,
    documentCategory: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const getAttachs = () => {
    getVesselAttachmentList(formData.vesselId).then((res) => {
      if (res.success) {
        setAllAttachs(res.data);
      }
    });
  };
  const saveApi = () => {
    saveAttachments(formData).then((res) => {
      if (res.success) {
        setAddModal(false);
        getAttachs();
        // setFormData(initialFormData);
        setFormData({ name: '', fileName: '', vesselId: params.id, documentCategory: '' });
        showAlert('Successfully Saved', 'success');
      }
    });
  };
  const saveApiCall = () => {
    if (formValidation(['name', 'documentCategory'], formData)) {
      saveApi();
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const deleteData = (data) => {
    setSelectedData(data);
    setDeleteModal(true);
  };
  const deleteFileApi = () => {
    const vesselsIds = selectedData?.id;
    console.log(vesselsIds, 'vesselsIds');
    deleteAttachment({ id: vesselsIds }).then((res) => {
      if (res.success) {
        setDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
        getAttachs();
      }
    });
  };

  useEffect(() => {
    getAttachs();
  }, []);

  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFileLoading(true);
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          setFileLoading(false);
          const Attach = { ...formData };
          Attach.fileName = res.data;
          Attach.fileSize = first.size;
          Attach.fileExtension = e.target.files[0].type;
          Attach.name = first.name;
          // Attach.fileType = e.target.files[0].type;
          setFormData(Attach);
          showAlert('Successfully Uploaded', 'success');
        } else {
          setFileLoading(false);
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
      // const element = document.getElementById('fileName-div');
      // if (element) {
      //   element.classList.add('file-not-added');
      //   element.classList.remove('file-added');
      // }
    }
    e.target.value = '';
  };

  const showAddModal = () => {
    setAddModal(true);
  };
  const removeAttachment = () => {
    const Attachs = { ...formData };
    Attachs.fileName = '';
    Attachs.name = '';
    Attachs.fileSize = '';
    Attachs.fileExtension = '';
    setFormData(Attachs);
  };
  useEffect(() => {
    getDocumentCategryList(2).then((res) => {
      if (res.success) {
        setDocumentCategoryList(generateOptions(res?.data, 'id', 'name'));
      }
    });
  }, []);

  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['name', 'documentCategory'], fd, field);
  };

  return (
    <div className="pt-4" data-testid="v-attachment">
      <button
        className="primary-btn large mb-4"
        id="add-attachment"
        type="button"
        disabled={status !== 'Active'}
        onClick={() => {
          showAddModal();
          setFormData(initialFormData);
        }}
      >
        Add Document
      </button>
      <Listing
        allAttachs={allAttachs}
        deleteData={deleteData}
        removeAttachment={removeAttachment}
        saveApi={saveApi}
        documentCategoryList={documentCategoryList}
        isExpand={isExpand}
        setIsExpand={setIsExpand}
      />
      {addModal && (
        <ModalBox
          modalClassName="medium"
          heading="Add Document"
          onSave={saveApiCall}
          closeModal={() => setAddModal(false)}
          buttonName="Save"
        >
          <div className="col-12">
            <label>
              Document Category
              <span className="text-primaryRed-1 m-2">*</span>
            </label>
            <Select
              autoFocus
              menuPosition="fixed"
              value={documentCategoryList
                ?.filter((option) => option.value === formData.documentCategory)}
              // onChange={handleDocumentCategoryList}
              onChange={(e) => handleChange(e, 'documentCategory', 'select')}
              className="modal-select z-index-99"
              label="Document Category"
              options={documentCategoryList}
              id="documentCategory"
            // id="Code"
            />
            {/* <InputType1 autoFocus className="modal-input" label="Attachment Name" name="name"
           value={formData.name} id="attachment-name"
           onChange={(e) => handleChange(e)} isMandatory="true" /> */}
            <div id="fileName-div" className="drag-drop-file my-4 file-added">
              <div className="icon-buble mb-5">
                <span className="icon-file-text2" />
              </div>
              <input className="p-5 align-self-center" id="file-input" title="" type="file" onChange={(e) => { ondrop(e); }} />
              {!formData.fileName && (
                <p>Choose a file or drag and drop it here(pdf, jpg, jpeg, png, doc, bmp, tiff)</p>)}
              {formData.fileName && (
                <div className="font-18 d-flex attached-file">
                  <span className="icon-check-rnd me-2 font-20"> </span>
                  <div>
                    <span data-tooltip-id="project-addattach" data-tooltip-content={formData?.fileName?.split('?')[0].split('UploadedFiles/')[1]}>
                      {_.truncate(formData?.fileName?.split('?')[0].split('UploadedFiles/')[1], {
                        length: 20,
                        separator: ' '
                      })}
                    </span>

                  </div>
                  <button type="button" className="link-btn clear-file" onClick={() => { removeAttachment(); }}>
                    <span className="icon-delete ms-4 font-20"> </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </ModalBox>
      )}
      {deleteModal ? (
        <ModalBox
          openDeleteModal={deleteModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteFileApi()}
          closeModal={() => setDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete this document?</p>
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="project-addattach" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Attachment;
