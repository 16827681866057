/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { projectSpecDetailsList, projectSpecDetails } from '../service';
import Components from './children/Components';
import Description from './children/Description';
import Tags from './children/Tags';
import Attachments from './children/Attachments';
import WoDefect from './children/WoDefect';
import Loader from '../../../common/loader';

const ViewMore = (props) => {
  const { showMore, isYard = true } = props;
  const [loading, setLoading] = useState(false);
  const [specData, setSpecData] = useState({});
  const [active, setActive] = useState('');

  useEffect(() => {
    if (isYard) {
      projectSpecDetailsList({ projectId: showMore.projectSpecId }).then((res) => {
        if (res.success) {
          setLoading(false);
          setSpecData(res.data);
          setActive('Description');
        }
      });
    } else {
      projectSpecDetails({ id: showMore.projectSpecId }).then((res) => {
        if (res.success) {
          setLoading(false);
          setSpecData(res.data);
          setActive('Description');
        }
      });
    }
  }, []);
  const renderComponent = (type) => {
    switch (type) {
      case 'Description':
        return <div><Description specData={specData} /></div>;
      case 'Components':
        return <div><Components specData={specData} /></div>;
      case 'Tags':
        return <div><Tags specData={specData} /></div>;
      case 'Attachments':
        return <div><Attachments specData={specData} /></div>;
      case 'WODefectList':
        return <div><WoDefect specData={specData} /></div>;
      default:
        return <Loader />;
    }
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div data-testid="yard-viewmore">
      <div className="list-tab-head d-flex justify-content-between border-down ">
        <div id="desc" onClick={() => setActive('Description')} className={`tab-items ${active === 'Description' && 'active'}`}>
          Description
        </div>
        <div id="comp" onClick={() => setActive('Components')} className={`tab-items ${active === 'Components' && 'active'}`}>
          Components
        </div>
        <div id="tag" onClick={() => setActive('Tags')} className={`tab-items ${active === 'Tags' && 'active'}`}>
          Tags
        </div>
        <div id="attach" onClick={() => setActive('Attachments')} className={`tab-items ${active === 'Attachments' && 'active'}`}>
          Attachments
        </div>
      </div>
      <div>{renderComponent(active)}</div>
    </div>
  );
};
export default ViewMore;
