import React from 'react';

const Comments = (props) => {
  const { comments, fromVariation, fromSupplementary } = props;
  return (
    <div className={fromVariation || fromSupplementary ? 'variation-comment-section p-2rem mb-4 card break-all bg-sky-white' : 'p-2rem mb-4 card break-all bg-sky-white'} data-testid="comments">
      <div>{comments}</div>
    </div>
  );
};
export default Comments;
