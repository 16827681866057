import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const Cards = (prop) => {
  const { item, setShowDelete, isTsiDisabled, isTSI, fromSpecLibrary, projInfo } = prop;
  return (
    <div className="px-4 py-3 border my-4 md-radius d-flex me-4">
      <div
        style={{ width: '624px' }}
      >
        <span
          className="font-semibold text-ellipsis"
          data-tooltip-id="project-comp"
          data-tooltip-content={item.name}
        >
          {/* {item.code}
          -
          {' '} */}
          {item.name}
        </span>
      </div>
      <div className="d-flex ml-auto">
        {isTSI ? (
          fromSpecLibrary ? ''
            : (
              <button type="button" className="link-btn" id="delete" disabled={(item.isFromAdmin && item.type === 1) || isTsiDisabled || projInfo?.isClosed} onClick={() => { setShowDelete(item.tagId); }}>
                <span className="icon-delete font-24" />
              </button>
            )

        ) : ''}
      </div>
      <Tooltip id="project-comp" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Cards;
