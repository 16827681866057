export const statusWODefectColor = (status) => {
  if (status === 0) {
    return '#2269D5';
  } if (status === 1) {
    return '#36AC79';
  } if (status === 2) {
    return '#fa7900';
  } if (status === 3) {
    return '#facc00';
  }
  return '';
};
export const statusCancelledColor = (statusName) => {
  if (statusName === 'Cancelled') {
    return '#D92700';
  }
  if (statusName === 'Deferred To Next DD' || statusName === 'DeferredToNextDD') {
    return '#facc00';
  }
  return '';
};
