/* istanbul ignore file */
/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';

const Navbar = (props) => {
  const { setIsSupplementaryClicked, setSpecDetails, setIsSaved,
    initialFormData, setFormData, setSpecId,
    fromVariation, setIsAddClicked, isTSI, projInfo } = props;
  const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
  return (
    <div>
      <div className={fromVariation ? ' d-flex align-items-center' : ''} data-testid="sp-nav">
        <div>
          {console.log(isTSI, 'isTsi')}
          {isTSI && (
            fromVariation
              ? (
                <button
                  data-testid="add"
                  autoFocus
                  type="button"
                  className="secondary-btn large"
                  disabled={projInfo.phase > 4}
                  onClick={() => {
                    setIsSupplementaryClicked(true);
                    setIsAddClicked(true);
                    setSpecDetails({});
                    setFormData(initialFormData);
                    setIsSaved(false);
                    setSpecId(ZERO_UUID);
                  }}
                >
                  Add Variation
                </button>
              )
              : ''
          )}

        </div>
      </div>
    </div>

  );
};

export default Navbar;
