import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import TableLoader from '../../../common/TableLoader';
import TableSortHeader from '../../../common/TableSortHeader';

const KfpTable = (props) => {
  const { tableData, deteleData, loading, editData, handleSort, sortData } = props;
  const column = [
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="KP Category"
            headingProperty="kpCategory"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      resizable: false,
      sortable: false,
      accessor: 'kpCategory',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-kfpvalue"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Code"
            headingProperty="code"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      resizable: false,
      sortable: false,
      accessor: 'code',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-kfpvalue"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="UOM"
            headingProperty="uom"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      resizable: false,
      sortable: false,
      accessor: 'uom',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-kfpvalue"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Description"
            headingProperty="Description"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      // Header: 'Description',
      resizable: false,
      sortable: false,
      accessor: 'description',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-kfpvalue"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      resizable: false,
      sortable: false,
      accessor: 'action',
      Cell: ({ original }) => (
        <div className="action-column">
          <div>
            <button
              type="button"
              className="link-btn"
              onClick={() => {
                editData(original);
              }}
            >
              <span className="icon-edit font-22" />
            </button>
          </div>
          <div
            data-tooltip-id="project-kfpvaluedlt"
            data-tooltip-content={original?.mappedMessage}
          >
            <button
              type="button"
              className="link-btn"
              disabled={original.isMapped}
              onClick={() => {
                deteleData(original);
              }}
            >
              <span className="icon-delete font-22" />
            </button>
          </div>
          <Tooltip id="project-kfpvaluedlt" place="top" className="tooltip mt-4" />
          <Tooltip id="project-kfpvalue" place="top" className="tooltip mt-4" />

        </div>
      )
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="table-kfp">
      <ReactTable
        className="table table-scroll"
        data={tableData?.items}
        pageSize={tableData?.items?.length}
        columns={column}
        minRows={0}
        showPagination={false}
        loading={loading}
        LoadingComponent={TableLoader}
      />
      <Tooltip id="project-value1" place="top" className="tooltip" />

    </div>
  );
};
export default KfpTable;
