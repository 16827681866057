/* eslint-disable no-unused-vars */
import React from 'react';
import qs from 'query-string';
import { downloadExcel, updateQuoteStatusVariation } from '../service';

const ManualCard = (props) => {
  // const { isManual, setIsmanual } = props;
  const params = qs.parse(window.location.search);
  const { onContiue } = props;

  const handleDownloadExcel = () => {
    updateQuoteStatusVariation({ key: params.key, status: 2, isManual: true })?.then((res) => {
      if (res.success) {
        // onContiue(true);
        downloadExcel({ key: params.key });
      }
    });
  };
  return (
    <div className="card p-4 quote_selcard me-5" data-testid="manual">
      <div className="mt-4 p-5 bg-sky-white round-radius"><span className="blue icon-rfq-manual font-35" /></div>
      <div className="font-bold mt-3 head">Variation RFQ - Excel</div>
      <div className="my-3 px-2 details">
        Download the DD attachments and enter the
        quotes in excel, send it to the Email provided in the instructions.
      </div>
      <button
        type="button"
        className="primary-btn mt-3 mb-4"
      // onClick={() => { onContiue(2, true); }}
        onClick={handleDownloadExcel}
        id="download-excel"
      >
        Download Excel
      </button>
    </div>
  );
};
export default ManualCard;
