import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const Cards = (props) => {
  const { editData, deteleData, userRole, tableData } = props;
  return (
    <table className="card-table" data-testid="card-rlink">
      <thead>
        <tr>
          <th>URL</th>
          <th>Description</th>
          {userRole === 'Admin' ? <th>Actions</th> : <></>}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((items) => {
          return (
            <tr>
              <td style={{ width: '50%', verticalAlign: 'top' }}>
                <div className="d-flex align-items-start">

                  <span className="icon-disc me-2 mt-2 second-brand-color" />
                  <a href={items?.url} target="_blank" className="link-btn link-style" rel="noreferrer">

                    <span data-tooltip-id="project-urlData" data-tooltip-content={items?.url}>
                      {_.truncate(items?.url, {
                        length: 100,
                        separator: ' '
                      })}
                    </span>
                    <Tooltip id="project-urlData" place="top" className="tooltip mt-3" />
                  </a>
                </div>

              </td>
              <td>
                <span>{items.description}</span>
              </td>

              {userRole === 'Admin' ? (
                <td style={{ width: '150px' }}>
                  <div className="d-flex col-gap-2">

                    <button
                      id="edit"
                      type="button"
                      className="link-btn"
                      // data-tooltip-id="project-urlData"
                      // data-tooltip-content={items?.mappedMessage}
                      // disabled={items.isMapped}
                      onClick={() => {
                        editData(items);
                      }}
                    >
                      <span className="icon-edit font-24" />
                    </button>
                    <button
                      id="delete"
                      type="button"
                      className="link-btn"
                      data-tooltip-id="project-urlData"
                      data-tooltip-content={items?.mappedMessage}
                      disabled={items.isMapped}
                      onClick={() => {
                        deteleData(items);
                      }}
                    >
                      <span className="icon-delete font-24" />
                    </button>
                  </div>
                </td>

              ) : (
                ''
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Cards;
