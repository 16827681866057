export const type = [
  { value: 'Item', label: 'Item' },
  { value: 'Service', label: 'Service' },
  { value: 'Item and Service', label: 'Item and Service' }
];
export const category = [
  { value: 'ATPIRY', label: 'ATPIRY' },
  { value: 'CHAPUB', label: 'CHAPUB' },
  { value: 'FLAGDOC', label: 'FLAGDOC' },
  { value: 'FUELAS', label: 'FUELAS' },
  { value: 'GASMEQ', label: 'GASMEQ' },
  { value: 'GASWEL', label: 'GASWEL' },
  { value: 'GSTORE', label: 'GSTORE' },
  { value: 'LUBGRS', label: 'LUBGRS' },
  { value: 'MTCHEM', label: 'MTCHEM' },
  { value: 'PAINTS', label: 'PAINTS' },
  { value: 'SCCHEM', label: 'SCCHEM' },
  { value: 'SERVCE', label: 'SERVCE' },
  { value: 'SPARES', label: 'SPARES' },
  { value: 'STATKT', label: 'STATKT' }
];

export const status = [
  { value: 0, label: 'Draft' },
  { value: 1, label: 'Pending Approval' },
  { value: 2, label: 'Approved ' },
  { value: 3, label: 'Ordered  ' },
  { value: 4, label: 'Cancelled   ' },
  { value: 5, label: 'Open  ' },
  { value: 6, label: 'Under Amendment  ' },
  { value: 7, label: 'GRN Received  ' },
  { value: 8, label: 'Short Closed  ' },
  { value: 9, label: 'Closed  ' }
];

export const Postatus = [
  { key: 0, label: 'Draft', color: 'rgb(250, 204, 0)' },
  { key: 1, label: 'Pending Approval', color: 'rgb(34, 105, 213)' },
  { key: 2, label: 'Approved', color: 'rgb(54, 172, 121)' },
  { key: 3, label: 'Ordered', color: 'rgb(250, 121, 0)' },
  { key: 4, label: 'Cancelled', color: 'rgb(209, 65, 75)' }
];
