import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  SaveResourceWebLink,
  GetResourceWebLink,
  DeleteResourceWebLink,
  GenerateTemplateResource,
  BulkUploadResource,
  ExportResourceWebLinkExcel
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const saveResourceWebLink = (payload) => {
  return serviceHandler({
    url: `${SaveResourceWebLink}`,
    methordType: 'post',
    payload
  });
};
export const getResourceWebLink = (payload) => {
  return serviceHandler({
    url: `${GetResourceWebLink}`,
    methordType: 'post',
    payload
  });
};
export const deleteResourceWebLink = (payload) => {
  return serviceHandler({
    url: `${DeleteResourceWebLink}?id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const exportExcelResourceLink = (searchText) => {
  return axios
    .get(`${ExportResourceWebLinkExcel}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Resource_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const bulkUploadResource = (payload) => {
  return serviceHandler({
    url: `${BulkUploadResource}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateResource}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Resource Web Link_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
