/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import SidePanel from '../../common/sidePanel';

const PrivacyTerms = (props) => {
  const { showPrivacyPolicy, setShowPrivacyPolicy } = props;
  return (
    <div>
      <SidePanel title="Privacy & Terms" size="medium" showView={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)}>
        <div className="ms-3">
          <div className="head-18 mb-3 ">Privacy Policy</div>
          <div className="font-13">
            ThinkPalm Technologies Pvt. Ltd., its affiliates and subsidiaries
            and each of their directors, officers, employees, and authorized
            representatives (collectively, “ThinkPalm”, “we”, “us” or “our”)
            respect your privacy and
            are committed to protecting it through compliance
            with this Privacy Policy (this “policy”, “document”).
            This document describes our policies and practices for collecting, using, maintaining,
            protecting, and disclosing information that we collect from you or that you provide
            to us when you use the Maridock App.
          </div>
          <div className="head-14 mb-3 mt-3">Your Consent</div>
          <div className="font-13">
            Please read this document carefully to understand our policies and
            practices regarding your information and how we will treat it.
            By accessing or using the Maridock App,
            you agree that this Policy governs our collection, use, maintenance, protection,
            and disclosure of any information submitted to us on or through the application.
            If you do not agree to be bound by this Policy, you may not access
            or use the Maridock App.
          </div>
          <div className="head-14 mb-3 mt-3">Information We Collect</div>
          <div className="font-13">
            The Maridock App collects information about you during the application operation.
            We collect your personal details and information related to the business
            you represent when you request information from us, order or register the software,
            or otherwise voluntarily send us such information.
          </div>
          <div className="font-13">We may collect the following types of personal information:</div>
          <div className="m-5 font-13">
            <ul style={{ listStyle: 'disc' }}>

              <li>
                Contact Information: Name, Email address,
                phone number, and other relevant contact details.
              </li>
              <li>
                Vessel Information: Details about the vessel,
                including its name, registration number, dimensions, and specifications.
              </li>
              <li>
                Yard Information: Details about the yard, including its name,
                country, phone number, and Email address.
              </li>
              <li>
                Location Information: GPS data and location
                details when using the application.
              </li>
              <li>
                Log Data: Information about your usage of the application,
                such as IP addresses, browser type, and operating system.
              </li>
            </ul>
          </div>

          <div className="head-14 mb-3 mt-3">How We Use Information</div>
          <div className="font-13">We use your personal information for the following purposes:</div>
          <div className="m-5 font-13">
            <ul style={{ listStyle: 'disc' }}>
              <li>
                Providing Services: To offer Maridock management services,
                facilitate communication between users, and provide
                relevant information and updates.
              </li>
              <li>
                Application Improvement: To enhance and optimize our application performance,
                features, and user experience.
              </li>
              <li>
                Customer Support: To respond to inquiries,
                provide technical assistance, and address user concerns.
              </li>
              <li>
                Legal Compliance: To comply with applicable laws,
                regulations, or legal processes.
              </li>
            </ul>
          </div>
          <div className="head-14 mb-3 mt-3">How We Share Information</div>
          <div className="font-13">We may share your personal information in the following circumstances:</div>
          <div className="m-5 font-13">
            <ul style={{ listStyle: 'disc' }}>
              <li>
                Service Providers: We may engage trusted third-party service
                providers to assist us in delivering our services.
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition,
                or sale of our business or assets,
                your personal information may be transferred to the acquiring entity.
              </li>
              <li>
                Legal Requirements: We may disclose your personal information
                if required by law, regulation, legal or regulatory process,
                statutory disclosures, or to protect our rights, property, or safety.
              </li>
              <li>
                Legal Compliance: To comply with applicable laws,
                regulations, or legal processes.
              </li>
            </ul>

          </div>
          <div className="head-14 mb-3">How We Store and Secure Information</div>
          <div className="font-13">
            We endeavor to use reasonable security safeguards, practices, and procedures,
            and we commit to the highest level of care to protect the privacy
            and integrity of personal information, throughout its useful life-cycle,
            from collection until destruction. Your information is stored securely
            and accessed only by authorized personnel.
          </div>
          <div className="head-14 mb-3 mt-3">Data Retention</div>
          <div className="font-13">
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this policy, or as required by law.
            We will securely delete or anonymize your data when it is no longer needed.
          </div>
          <div className="head-14 mb-3 mt-3">Links to Other Websites</div>
          <div className="font-13">
            Our Maridock Application may contain links to other websites that
            are not operated by us. We are not responsible for the privacy
            practices of these third-party websites. We encourage you to review
            their privacy policies when visiting their sites.
          </div>
          <div className="head-14 mb-3 mt-3">Cookies and Web Beacons</div>
          <div className="font-13">
            Like any other website, Maridock App uses cookies.
            These cookies are used to store information including visitors’ preferences,
            and the pages on the website that the visitor accessed or visited.
            The information is used to optimize the users’ experience by customizing our
            web page content based on visitors’ browser type and/or other information.
          </div>
          <div className="head-14 mb-3 mt-3">Children's Privacy</div>
          <div className="font-13">
            Our Maridock Application is not intended for children under the age of 15.
            We do not knowingly collect personal information from individuals in this age group.
            If you are a parent or guardian and believe that your child has provided us
            with personal information, please contact us immediately.
          </div>
          <div className="head-14 mb-3 mt-3">Policy Changes</div>
          <div className="font-13">
            We may occasionally update this Policy and such changes will be updated on this page.
            If we make any significant changes to this Policy we will endeavor to provide you with
            reasonable notice of such changes, such as to your Email address on record,
            and we will obtain your consent. To the extent permitted under the applicable law,
            your continued use of our Services after we publish or send a notice about our
            changes to this Policy shall constitute your consent to the updated Policy.
          </div>
          <div className="head-14 mb-3 mt-3">Contact Information</div>
          <div className="font-13">
            If you have questions or complaints regarding this Notice or you have a request
            based on the above rights, you may reach out to us at:
          </div>

          <div className="head-18 mb-3 mt-5 ">Terms of Use</div>
          <div className="font-13">
            These terms and conditions outline the rules and regulations
            for the use of the Maridock App. By accessing the Maridock App
            we assume you accept these terms and conditions. Do not continue to
            use Maridock App if you do not agree to any of the terms and conditions
            stated on this page.
          </div>
          <div className="font-13">
            The following terminology applies to these Terms and Conditions:
            "Client", "You" and "Your" refers to you, the person who logs
            on to this website and is compliant with the Company's terms and conditions.
            "The Company", "Ourselves", "We", "Our" and "Us",
            refers to our Company – ThinkPalm Technologies Pvt Ltd.
            "Party", "Parties", or "Us", refers to both the Client and ourselves.
            Any use of the above terminology or other words in the singular, plural,
            capitalization, and/or he/she or they, are taken as interchangeable
            and therefore as referring to same.
          </div>
          <div className="head-14 mb-3 mt-3">Cookies</div>
          <div className="font-13">
            We employ the use of cookies. By accessing Maridock App,
            you agreed to use cookies in agreement with the ThinkPalm
            Technologies Pvt Ltd's Privacy Policy.
          </div>
          <div className="font-13">
            Most interactive websites use cookies to let us retrieve
            the user's details for each visit. Cookies are used by our
            website to enable the functionality of certain areas to make
            it easier for people visiting our website.
          </div>
          <div className="head-14 mb-3 mt-3">Intellectual Property Rights</div>
          <div className="font-13">
            You agree that all intellectual property of any sort associated
            with the Maridock App, including all code, libraries, programs,
            documentation, content, database, systems, logos, and trademarks
            are owned by ThinkPalm. You bear no right to imitate or alter the
            aforementioned and other sole properties registered under ThinkPalm
            without proper authorization. Sovereignty over these intellectual
            properties rests solely on ThinkPalm and cannot be questioned or challenged.
          </div>
          <div className="font-13">
            Please note that any kind of unauthorized imitation and replication
            of the software is strictly prohibited by copyright and trademark laws, and those

            who attempt such activities will be prosecuted under laws that prevail
            in the region where the felony is committed. Further, if the unauthorized
            use of the software is likely to become the subject of a claim due to
            infringement of intellectual property rights, you are liable to indemnify
            and hold harmless ThinkPalm against such claims.
          </div>
          <div className="head-14 mb-3 mt-3">Confidentiality and Privacy</div>
          <div className="font-13">
            ThinkPalm is bound to uphold the privacy and security of our clients
            and hence shall not surrender to any situations that could jeopardize
            the security of our customers’ information. We shall collect and store
            the necessary data from you during various stages of the Maridock App’s
            registration and usage, and the said information will be safeguarded
            with the utmost importance and extreme vigilance.
            ThinkPalm may use customer information with permitted users in
            connection with the performance and maintenance of the services.
            We, at the same time, take all reasonable measures to protect the
            confidentiality of the customer information and prevent any
            unauthorized use of the confidential information.
            The confidential information shall not include information that is or
            becomes generally available to the public other than from disclosure by the Client.
            Please go through Privacy Policy for an in-depth detailing
            regarding confidentiality and privacy terms and conditions.
          </div>
          <div className="head-14 mb-3 mt-3">Member Account, Password, and Security</div>
          <div className="font-13">
            You may be required to register to use the Services.
            You agree to keep your password confidential and will be
            responsible for all use of your account and password.
            We reserve the right to remove, reclaim, or change a username
            you select if we determine, in our sole discretion, that such
            username is inappropriate, obscene, or otherwise objectionable.
            You agree to notify us immediately of any unauthorized use of your
            account or any other breach of security. ThinkPalm will not be liable
            for any loss that you may incur as a result of someone else using your
            password or account, either with or without your knowledge.
            However, you could be held liable for losses incurred by ThinkPalm or
            another party due to someone else using your account or password.
            You may not use anyone else's account at any time, without the permission
            of the account holder.
          </div>
          <div className="head-14 mb-3 mt-3">Terms and Termination</div>
          <div className="font-13">
            These Legal Terms shall remain in full force and effect
            while you use the services. Without limiting any other
            provision of these legal terms, we reserve the right to,
            in our sole discretion and without notice or liability,
            deny access to and use of the services, to any person for
            any reason or no reason, including without limitation for breach of any
            representation, warranty, or covenant contained in these legal terms or of any
            applicable law or regulation. We may terminate your use or participation in the
            services or delete your account and any content or information that you posted
            at any time, without warning, at our sole discretion.
          </div>
          <div className="font-13">
            If we terminate or suspend your account for any reason,
            you are prohibited from registering and creating a new account under your name,
            a fake or borrowed name, or the name of any third party,
            even if you may be acting on behalf of the third party.
            In addition to terminating or suspending your account,
            we reserve the right to take appropriate legal action,
            including without limitation pursuing civil, criminal,
            and injunctive redress.
          </div>
          <div className="head-14 mb-3 mt-3">Limitations of liability</div>
          <div className="font-13">
            ThinkPalm’s total liability for any claims of any kind,
            regardless of cause or action (including negligence)
            arising out of or related to the use of software or its
            performance or breach, including without limitation warranty
            and termination, shall not under any circumstances exceed the Software Price.
            ThinkPalm shall have no liability for advice or assistance gratuitously
            provided to the Client. In no event, we or our directors, employees, or
            agents will be liable to you or any third party for any direct, indirect,
            consequential, exemplary, incidental, special, or punitive damages,
            including lost profit, lost revenue, loss of data, or other damages
            arising from your use of the services, even if we have been advised
            of the possibility of such damages.
          </div>
          <div className="head-14 mb-3 mt-3">Disclaimer</div>
          <div className="font-13">
            Except as expressly stated in this agreement,
            we do not make any representation or warranty
            (express or implied) in respect of the software
            and associated services provided by ThinkPalm to you,
            including, without limitation, any implied warranty:
            (i) of merchantability; (ii) of fitness for a particular purpose;
            (iii) arising from a course of performance, course of dealing,
            or usage of trade; (iv) of non-infringement of third party rights;
            or (v) against hidden defects. We make no warranty that the
            operation of the software will be uninterrupted or error-free
            or that all defects will be corrected.
          </div>
          <div className="font-13">
            To the maximum extent permitted,
            we exclude completely all liability
            whatsoever for any claims, liability, loss, or damage
            of any kind however caused (including negligence)
            arising out of or in connection with any services provided by us,
            use or performance, including, without limitation, we are not liable for:
            (a) misuse of the software ; (b) use of the software with third-party data,
            software or hardware which is incompatible and/or not recommended by us;
            (c) reduced performance of the software as a result of network connections;
            or (d) errors in the software operation resulting from your configuration or
            manipulation, in each case not, specifically recommended in writing by us.
          </div>
          <div className="head-14 mb-3 mt-3">Amendments</div>
          <div className="font-13">
            This agreement may be modified to suit the changing
            conditions from time to time, and the authority
            to do so solely rests with ThinkPalm.
            We shall intimate you of any changes in the agreement,
            and in return, we expect your cooperation and care to proceed according
            to the new amendments we have made.
          </div>
          <div className="head-14 mb-3 mt-3">Contact Information</div>
          <div className="font-13">
            To resolve a complaint regarding the services or to
            receive further information regarding the use of the services,
            please contact us at:
          </div>
          <br />
          <div className="body-gray border p-3">
            <div className="head-14">
              ThinkPalm Technologies Pvt. Ltd.,
            </div>
            <div className="font-13">
              B-1, 1st Floor, Athulya Building,
            </div>
            <div className="font-13">
              Infopark SEZ, Infopark Kochi P.O.,
            </div>
            <div className="font-13">
              Kochi – 682042, Kerala, India
            </div>
            <div className="font-13">
              <span className="icon-phone font-20 me-3"> Tel: +91 484 4104100</span>

            </div>
          </div>

        </div>

      </SidePanel>

    </div>
  );
};
export default PrivacyTerms;
