import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  YardCostSummary
} = apiEndPoints;

export const yardCostSummary = (payload) => {
  return serviceHandler({
    url: `${YardCostSummary}`,
    methordType: 'post',
    payload
  });
};
