import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const { SendChatMsgs } = apiEndPoints;

export const sendChatMessage = (payload) => {
  return serviceHandler({
    url: `${SendChatMsgs}`,
    methordType: 'post',
    payload
  });
};
