import React, { useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ModalBox from '../../../../../common/ModalBox';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import { fileImages } from '../../../../../common/mappingConst';
import { s3UploadMutiple } from '../../../NewProject/services';
import { getProjectCollation } from '../services';

const UploadWindow = (props) => {
  const { setShowUpload, setSpecList, specList, onSaveAttach, isDisabled, projInfo } = props;
  console.log('specList', specList);
  const { MAX_FILE_SIZE, formatBytes } = helper;
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileIndex, setFileIndex] = useState(0);
  const acceptedFiles = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/tiff'];
  const onDelete = (key) => {
    setDeleteModal(true);
    setFileIndex(key);
  };
  const deleteFile = () => {
    const attachments = { ...specList };
    attachments.attachment.splice(fileIndex, 1);
    setSpecList(attachments);
    setDeleteModal(false);
  };
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const attachments = Array.isArray(specList?.attachment)
        ? specList.attachment : [specList.attachment];

      const name = attachments.filter((item) => item && item.name !== null)
        .map((item) => item.name || []);

      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;

        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');

        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = { ...specList };
          if (!Array.isArray(attach.attachment)) {
            attach.attachment = [];
          }
          console.log(specList, 'spec');
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              path: item.path,
              documentId: item.documentId,
              type: item.type,
              size: item.size
            };

            return (

              attach.attachment.push(newItem)
            );
          });
          setSpecList(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };
  const cancelForm = () => {
    getProjectCollation({ id: projInfo?.id }).then((res) => {
      if (res.success) {
        const newFilesUploaded = specList?.attachment?.length !== res.data?.attachment?.length;
        setSpecList(res.data);

        if (newFilesUploaded) {
          showAlert('Successfully cleared the unsaved data', 'success');
        }

        setShowUpload(false);
      }
    });
  };

  return (
    <>
      <ModalBox
        modalClassName="medium"
        heading={specList.status === 1 ? 'View Attachment' : 'Add/View Attachment'}
        closeModal={() => { cancelForm(); }}
        buttonName="Save"
        onSave={() => { onSaveAttach(); }}
        saveDisable={isDisabled}
      >
        <div>
          <div id="fileName-div" className="drag-drop-file my-4 file-waiting col-12">
            <input className="p-5 align-self-center" disabled={isDisabled} id="fileName" title="" type="file" name="fileName" multiple onChange={(e) => { ondrop(e); }} isMandatory="true" />
            <div className="d-flex row w-full">
              <div className="d-flex flex-column justify-content-center align-items-center col-8">
                <div className="icon-buble mb-2">
                  <span className="icon-upload-cloud" />
                </div>
                <div>Choose a file or drag it here</div>
                <div className="font-12">(File formats: jpeg, jpg, png, tiff, doc, pdf - Max size 5MB)</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-4">
                <div className="div_btn">
                  Upload
                </div>
              </div>
            </div>
          </div>
          <div className="scrollbar-speclist-attachments">
            {Array.isArray(specList?.attachment)
              && specList?.attachment.map((item, key) => {
                return (
                  <>
                    <div className="p-1 d-flex justify-content-between align-items-center col-sm-7 overflow-auto set-width">
                      <div className="thmb-icon-md">
                        <span className={fileImages[_.toLower(item.type)]} />
                      </div>

                      <div className="d-block set-name-width">
                        <a
                          href={item?.path}
                          target="_blank"
                          download="downloaded_file"
                          className=""
                          rel="noreferrer"
                        >
                          <div className="value">
                            <span
                              data-tooltip-id="file-name"
                              data-tooltip-content={item?.name}
                            >
                              {_.truncate(item?.name, {
                                length: 25,
                                separator: ''
                              })}

                            </span>
                            <Tooltip id="file-name" place="top" className="tooltip m-4" />
                          </div>
                        </a>
                        <div className="d-flex text-ellipsis">
                          Size :
                          <p className="value">
                            {' '}
                            {formatBytes(item?.size)}
                          </p>

                        </div>
                      </div>
                      <div className="d-flex align-items-center ms-4">
                        <a href={item?.path} target="_blank" rel="noreferrer" className="ms-4">
                          <span className="icon-eye font-24" />
                        </a>
                        <button type="button" disabled={isDisabled} id="delete" className="link-btn clear-file" onClick={() => { onDelete(key); }}>
                          <span className="icon-delete delete-icon-multi ms-4 font-20 "> </span>
                        </button>
                      </div>
                    </div>

                  </>
                );
              })}
          </div>
          <Tooltip id="project-upload" place="top" className="tooltip mt-3" />
        </div>
      </ModalBox>
      {deleteModal ? (
        <ModalBox
          openDeleteModal={deleteModal}
          modalClassName="small"
          heading="Delete"
          onSave={() => deleteFile()}
          closeModal={() => setDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete this document?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </>
  );
};
export default UploadWindow;
