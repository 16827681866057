/* istanbul ignore file */
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavigateButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between" data-testid="navigate-btn-innertable1">
        <h6 className="head-18 mb-4">Map General Tariff To Vessel Category</h6>
        <button
          onClick={() => {
            navigate('/admin/data/spec/general');
          }}
          className="rnd-btn"
          type="button"
        >
          Back To Spec
        </button>
      </div>
    </>
  );
};
export default NavigateButton;
