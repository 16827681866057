import React from 'react';

const NewSpecCard = (props) => {
  const { onCardSelect, selectedCard } = props;
  return (
    <div data-testid="newspeccard" className="card border-dot mb-3 p-3 bg-sky-white d-flex align-items-center shadow-medium md-radius">
      <div className="px-1"><input type="radio" id="html" data-test="change1" checked={selectedCard === 0} onChange={() => onCardSelect(0)} /></div>
      <div className="px-3">
        <span className="icon-plus-square font-22 brand-color me-2" />
      </div>
      <div>
        <h3 className="brand-color font-semibold font-14">Create New Major Spec </h3>
        <p className="font-13">You can choose this and create specification manually</p>
      </div>
    </div>
  );
};
export default NewSpecCard;
