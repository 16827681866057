/*eslint-disable*/
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import helper from '../../../../../utils/helper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const RepairPeriodChart = ({ graphData, projectBasicDetails, variance }) => {
  console.log(graphData, 'graphData');
  // const { formatNumber } = helper;
  const options = {
    
    scales: {
      y: {
        title: {
          display: true,
          text: "Days"
        }
      }
    },     
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      }
    }
  }
  const labels = ['Total Repair Days'];
  const chart = {
    labels,
    datasets: [
      {
        label: 'Project Estimate',
        data: [graphData?.totalRepairDaysEstimate],
        backgroundColor: '#f5b1a5',
        // barPercentage: 2,
        barThickness: 45,
        // maxBarThickness: 50,
        // minBarLength: 10,
      },
      {
        label: 'Project Actual',
        data: [graphData?.totalRepairDaysActual],
        backgroundColor: '#3498db',
        // barPercentage: 2,
        // barThickness: 45,
        maxBarThickness: 50,
        // minBarLength: 10,
      }
    ]
  };

  return (
    <div className="repaircard-width card" style={{ height: '362px' }}>
      <div className="head-16 pb-4 border-down p-4">Repair Period</div>
      <div className="row " data-testid="bar-chart">
        <div className="col-8 card-vertical p-5">
          <Bar options={options} data={chart} />
        </div>
      </div>
      <Tooltip id="project-phrepair" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default RepairPeriodChart;
