/* istanbul ignore file */
import React, { useState } from 'react';
import ModalBox from '../../../../../common/ModalBox';
import PrivacyPolicy from '../../../../../Policies/privacy';
import TermsOfUse from '../../../../../Policies/TermsOfUse';
import Comments from '../../../Comments';
import { specSupplimentoryExport } from '../../../PaintCost/services';
import helper from '../../../../../../utils/helper';
import showAlert from '../../../../../../utils/alert';

const PreviewFooter = (props) => {
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = userRole === roles.VESSEL_USER;
  const [downloadConfirmModal, setDownloadConfirmModal] = useState(false);
  const { onSelectYard, setShowSideBar, setIsSupplementaryClicked, id } = props;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const downloadExcel = () => {
    setDownloadConfirmModal(false);
    specSupplimentoryExport(id).then((res) => {
      showAlert(`${res.data}`, 'success');
    }).catch(() => { });
  };
  return (
    <>
      <div className="fixed-footer card d-flex">
        {!isVesselUser && (
        <>
          <Comments id={id} appendClassName="comments-btn" />
          <button
            type="button"
            className="secondary-btn large ms-3"
            id="spec-download"
            onClick={() => {
              setDownloadConfirmModal(true);
            }}
          >
            Download Specs (Pdf)
          </button>
        </>
        )}

        <div className={isVesselUser ? 'text-black font-12 col-5 d-flex justify-content-end' : 'text-black font-12 col-5 '}>
          <div className=" footer-tender">© 2024 ThinkPalm</div>

        </div>

        <div className={isVesselUser ? 'd-flex justify-content-end ms50p ms-auto me-4' : 'd-flex col-gap-1 ms-auto me-4'}>
          <button
            type="button"
            onClick={() => {
              setShowSideBar(true);
              setIsSupplementaryClicked(false);
            }}
            className="secondary-btn large"
            id="add-supspec"
          >
            Add Supplementary Spec
          </button>
          {!isVesselUser && (
          <button
            type="button"
            className="primary-btn large "
            id="spec-download"
            onClick={onSelectYard}
          >
            Select Yard
          </button>
          )}

        </div>
        {downloadConfirmModal ? (
          <ModalBox
            heading="Download Specs"
            closeModal={() => { setDownloadConfirmModal(false); }}
            buttonName="Ok"
            onSave={() => { downloadExcel(); }}
          >

            <div className="text-content-grey">
              A link to download the Pdf report will be emailed to you in few minutes.
            </div>

          </ModalBox>
        ) : ''}
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </>
  );
};
export default PreviewFooter;
