/* eslint-disable max-len */
import _ from 'lodash';
import React from 'react';
import InputType1 from '../../../common/InputType1';
import tiff from '../../../../images/tiff.png';

function uploadImage(props) {
  const { ondrop, goBackToCustom, formData,
    onRemoveImg, onNameChange, nameChange } = props;
  return (
    <div data-testid="upload-card" className="ms-4 me-4">
      <button
        id="back"
        onClick={() => {
          goBackToCustom();
        }}
        type="button"
        className="link-btn my-3"
      >
        <span className="icon-arrow-left2 me-2" />
        Back
      </button>
      <div className="p-5  card">
        <InputType1
          autoFocus
          label="Name"
          isMandatory="true"
          id="name"
          name="imageName"
          onChange={(e) => {
            onNameChange(e);
          }}
          value={nameChange}
        />
        <div className="row col-12 m-auto mt-5 mb-5">
          <div id="fileName-div" className="drag-drop-file file-waiting h-12">
            <input
              className="p-5 align-self-center"
              id="fileName"
              title=""
              type="file"
              name="fileName"
              multiple
              onChange={(e) => {
                ondrop(e);
              }}
              isMandatory="true"
            />
            <div className="d-flex row w-full">
              <div className="d-flex flex-column justify-content-center align-items-center col-6">
                <div className="icon-buble mb-2">
                  <span className="icon-upload-cloud" />
                </div>
                <p>Choose a file or drag it here</p>
                <div className="font-12">(File formats: jpeg, jpg, png, tiff - Max size 5MB)</div>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-6">
                <div className="div_btn-gallery">Browse File</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">{formData?.projectAttachments?.length > 0 && <label>Selected Images</label>}</div>
        <div className="selected-parent-gallery my-3 pb-5">
          {formData?.projectAttachments.map((item, key) => {
            return (
              <div>
                <div className="child-gallery pb-5">

                  {item?.fileName.includes('.tiff') || item?.fileName.includes('.tif')
                    ? (
                      <img src={tiff} alt="" className="selected-image-dimension" />

                    )
                    : <img src={item?.fileName} alt="img" className="selected-image-dimension" />}

                  <div className="d-flex">
                    <div className="d-flex">
                      {_.truncate(nameChange, {
                        length: 10
                      })}
                      {formData?.projectAttachments.length > 1 && (
                        <div>
                          -
                          {formData?.projectAttachments.length > 1 ? key + 1 : ''}
                        </div>
                      )}
                    </div>
                  </div>
                  <span
                    id="remove-click"
                    className="font-22 icon-close brand-color"
                    onClick={() => {
                      onRemoveImg(key);
                    }}
                  />
                </div>
              </div>
            );
          })}
          <div />
        </div>
      </div>
    </div>
  );
}
export default uploadImage;
