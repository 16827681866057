import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import helper from '../../../../../../utils/helper';
import { fileImages } from '../../../../../common/mappingConst';

const Attachments = (props) => {
  const { specData } = props;
  const { formatBytes } = helper;
  if (specData?.specAttachmentData?.specDocuments?.length < 1) {
    return (
      <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
        <div className="font-18 animate__animated animate__headShake">
          No data found
        </div>
      </div>
    );
  }
  return (
    <div data-testid="yard-attach">
      {specData?.specAttachmentData?.specDocuments.map((item) => {
        return (
          <div className="card p-3 mt-3 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="thmb-icon-md">
                <span className={fileImages[_.toLower(item.type)]} />
              </div>
              <div className="ps-4">
                <a
                  href={item.path}
                  target="_blank"
                  download="downloaded_file"
                  className=""
                  rel="noreferrer"
                >
                  <div className="value">
                    <span
                      data-tooltip-id="project-head"
                      data-tooltip-content={item.fname}
                    >
                      {' '}
                      {_.truncate(item?.fname, {
                        length: 30,
                        separator: ''
                      })}

                    </span>

                  </div>
                </a>

                <div className="d-flex text-ellipsis">
                  Size
                  <span className="ms-2">:</span>
                  <p className="value">
                    {' '}
                    {formatBytes(item.size)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Tooltip id="project-head" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Attachments;
