/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
import React from 'react';
// import { useNavigate } from 'react-router';
import helper from '../../../../../utils/helper';
import SidePanel from '../../../../common/sidePanel';

const UpdatePasswordSuccess = ({ updateSuccess }) => {
  // const navigate = useNavigate();
  const location = window.location.pathname;
  const { logOut } = helper;
  const handleBackToLogin = () => {
    logOut(location);
  };
  return (
    <>
      <SidePanel title="Update Password" size="small" showView={updateSuccess} hideCloseBtn={false}>
        {updateSuccess && (
        <div className="d-flex flex-column align-items-center  mt-5">
          <span className="icon-check-rnd tick-green font-50 pb-5" />
          <h4 className="main-head">Password Reset</h4>
          <p className="mt-5 mt-2 font-18">Your password has been successfully reset </p>
        </div>
        )}

        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <button data-testid="back" className="primary-btn xlarge full-width mb-5 mt-5" type="button" onClick={handleBackToLogin}>
          Continue to Login
        </button>
      </SidePanel>
    </>
  );
};
export default UpdatePasswordSuccess;
