/* eslint-disable react/jsx-no-undef */
/* eslint-disable import/extensions */
import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Login from './components/Login/login';
import GeneralTariff from './components/Admin/DataConfig/SpecificationSetup/MapGtToVessel';
import MajorSpecs from './components/Admin/DataConfig/SpecificationSetup/MajorSpecs';
import SpecPackages from './components/Admin/DataConfig/SpecificationSetup/SpecPackages';
import NonSpec from './components/Admin/DataConfig/SpecificationSetup/NonSpec';
import VesselDataManagement from './components/Admin/DataConfig/VesselDataManagement/VesselDataManagement';
import Admin from './components/Admin';
import DataConfig from './components/Admin/DataConfig';
import Dashboard from './components/Admin/Dashboard';
import AdminProject from './components/Admin/Projects';
import Vessels from './components/Admin/Vessels';
import Users from './components/Admin/Users';
import Resource from './components/Admin/Resource';
import Tags from './components/Admin/DataConfig/Tags';
import Kfp from './components/Admin/DataConfig/Kfp';
import Task from './components/Admin/DataConfig/Task';
import Currency from './components/Admin/DataConfig/CurrencyContainer';
import AcCode from './components/Admin/DataConfig/AcCode';
import Uom from './components/Admin/DataConfig/UOM';
import GeneralConfig from './components/Admin/DataConfig/GeneralConfig';
import Yard from './components/Admin/DataConfig/Yard';
import Templates from './components/Admin/DataConfig/Templates';
import Management from './components/Admin/Vessels/Management';
import Fleets from './components/Admin/Vessels/Fleets';
import Vessel from './components/Admin/Vessels/Vessel';
import VesselDetails from './components/Admin/Vessels/VesselDetails';
import ResourceWebLinks from './components/Admin/Resource/ResourceWebLinks';
import YardCostHistory from './components/Admin/Resource/YardCostHistory';
import UserManual from './components/Admin/Resource/UserManual';
import ReferenceDoc from './components/Admin/Resource/ReferenceDoc';
import Roles from './components/Admin/Users/Roles';
import UserList from './components/Admin/Users/UserList';
import Informations from './components/Admin/Vessels/VesselDetails/Informations';
import Attachment from './components/Admin/Vessels/VesselDetails/Attachment';
import Components from './components/Admin/Vessels/VesselDetails/Components';
import Projects from './components/TSI/Projects/index';
import ListProject from './components/TSI/Projects/ListProject';
import NewProject from './components/TSI/Projects/NewProject/index';
import PermissionDenied from './PermissionDenied';
import PageNotFound from './PageNotFound';
import TsiTask from './components/TSI/Projects/TsiTask';
import ForgotPassword from './components/Login/PasswordForget/forgotPassword';
import PasswordRecovery from './components/Login/PasswordForget/passwordRecovery';
import SetNewPassword from './components/Login/PasswordForget/setNewPassword';
import ResetSuccess from './components/Login/PasswordForget/resetSuccess';
import Specify from './components/TSI/Projects/PhasePage/Specify/SpecifyWrapper';
import Plan from './components/TSI/Projects/PhasePage/Plan';
import Tender from './components/TSI/Projects/PhasePage/Tender/TenderWrapper';
import Repair from './components/TSI/Projects/PhasePage/Repair';
import Close from './components/TSI/Projects/PhasePage/Close';
import PaintCost from './components/TSI/Projects/PaintCost';
import ResourceTSI from './components/Admin/Resource/indexTSI';
import DashBoard from './components/TSI/DashBoard';
import YardQuotation from './components/Quotations/YardQuotation';
import helper from './utils/helper';
import MoreInfo from './components/TSI/Projects/PhasePage/Repair/MoreInfo';
import ReportModal from './components/TSI/Projects/Report';
import VariationReport from './components/Quotations/RepairVariationReport';
// import CardSeletion from './components/Quotations/YardQuotation/cardSelection';
import CompareQuotesHome from './components/TSI/Projects/PhasePage/Tender/CompareQuotes/CompareQuotesHome';
import VesselCard from './components/Admin/DataConfig/SpecificationSetup/DDHistory/VesselCard';
import DDHistory from './components/Admin/DataConfig/SpecificationSetup/DDHistory';
import ClosedVessel from './components/TSI/Projects/ProjectHead/Children/ClosedVessel';
import ExitPage from './components/Quotations/YardQuotation/Agreement/exitPage';
import Terms from './components/Terms';
import SupplementaryQuatation from './components/Quotations/SupplementaryQuotation';
import DeferredToNext from './components/Admin/Resource/DeferredToNext';
import SpecDownload from './components/TSI/Projects/SpecDownload';
import VesselLanding from './components/Admin/Users/UserList/VesselUsersLanding';
import SSOLoader from './components/Login/SSOLoader';

function PrivateRoute(props) {
  const { accessedRoles } = props;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  if (accessedRoles.includes(userRole)) return <Outlet />;
  return <Navigate to="/permission-denied" />;
}

const Rpoutes = () => {
  const { roles } = helper;

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/auth/microsoft/callback" element={<SSOLoader />} />
      <Route path="/usermanual" element={<UserManual />} />
      <Route path="/yardquotation" element={<YardQuotation />} />
      <Route path="/yardsupplementaryquotation" element={<SupplementaryQuatation />} />
      <Route path="/ExitPage" element={<ExitPage />} />
      <Route path="/yardvariationquotation" element={<VariationReport />} />
      <Route path="/DDHistory/VesselCard/:id" element={<VesselCard />} />
      <Route path="/users/list/:vesselId" element={<VesselLanding />} />
      <Route path="/admin" element={<PrivateRoute accessedRoles={[roles.ADMIN]} />}>
        <Route path="/admin" element={<Admin />}>
          <Route path="/admin/data" element={<DataConfig />}>
            <Route path="/admin/data/spec/general" element={<MajorSpecs />} />
            <Route path="/admin/data/spec/major" element={<MajorSpecs />} />
            <Route path="/admin/data/spec/mapping-to-vessel/general" element={<GeneralTariff />} />
            <Route path="/admin/data/spec/packages" element={<SpecPackages />} />
            <Route path="/admin/data/spec/nonspec" element={<NonSpec />} />
            <Route path="/admin/data/spec/DDHistory" element={<DDHistory />} />
            <Route path="/admin/data/vesseldata" element={<VesselDataManagement />} />
            <Route path="/admin/data/tags" element={<Tags />} />
            <Route path="/admin/data/kfp" element={<Kfp />} />
            <Route path="/admin/data/task" element={<Task />} />
            <Route path="/admin/data/general-config" element={<GeneralConfig />} />
            <Route path="/admin/data/currency" element={<Currency />} />
            <Route path="/admin/data/ac-code" element={<AcCode />} />
            <Route path="/admin/data/Uom" element={<Uom />} />
            <Route path="/admin/data/yard" element={<Yard />} />
            <Route path="/admin/data/templates" element={<Templates />} />
          </Route>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/projectList" element={<AdminProject />} />
          <Route path="/admin/users" element={<Users />}>
            <Route path="/admin/users/roles" element={<Roles />} />
            <Route path="/admin/users/list" element={<UserList />} />
          </Route>
          <Route path="/admin/resource" element={<Resource />}>
            <Route path="/admin/resource/web" element={<ResourceWebLinks />} />
            <Route path="/admin/resource/yard" element={<YardCostHistory />} />
            <Route path="/admin/resource/reference" element={<ReferenceDoc />} />
            <Route path="/admin/resource/deferredtonext" element={<DeferredToNext />} />
          </Route>
          <Route path="/admin/vessel" element={<Vessels />}>
            <Route path="/admin/vessel/management" element={<Management />} />
            <Route path="/admin/vessel/fleet" element={<Fleets />} />
            <Route path="/admin/vessel/vessel" element={<Vessel />} />
          </Route>
          <Route path="/admin/details" element={<VesselDetails />}>
            <Route path="/admin/details/info" element={<Informations />} />
            <Route path="/admin/details/attach" element={<Attachment />} />
            <Route path="/admin/details/comp" element={<Components />} />
          </Route>
        </Route>
      </Route>
      <Route path="/user/projects/ClosedVessel" element={<ClosedVessel />} />
      <Route path="/user/projects" element={<PrivateRoute accessedRoles={[roles.VESSEL_USER, roles.AUTHOR, roles.EDITOR, roles.VIEWER]} />}>
        <Route path="/user/projects" element={<ListProject />} />
        <Route path="/user/projects/create" element={<PrivateRoute accessedRoles={[roles.AUTHOR, roles.EDITOR]} />}>
          <Route path="/user/projects/create" element={<NewProject />} />
        </Route>
      </Route>
      <Route path="/user/project/:id" element={<PrivateRoute accessedRoles={[roles.AUTHOR, roles.EDITOR, roles.VIEWER, roles.ADMIN]} />}>
        <Route path="/user/project/:id/repair/projcost" element={<MoreInfo />} />
      </Route>
      <Route path="/user/dashboard" element={<PrivateRoute accessedRoles={[roles.VESSEL_USER, roles.AUTHOR, roles.EDITOR, roles.VIEWER]} />}>
        <Route path="/user/dashboard" element={<DashBoard />} />
      </Route>
      <Route path="/user/project/:id" element={<PrivateRoute accessedRoles={[roles.VESSEL_USER, roles.AUTHOR, roles.EDITOR, roles.VIEWER, roles.ADMIN]} />}>
        <Route path="/user/project/:id" element={<Projects />}>
          <Route path="/user/project/:id/task" element={<TsiTask />} />
          <Route path="/user/project/:id/sp-download" element={<SpecDownload />} />
          <Route path="/user/project/:id/plan" element={<Plan />} />
          <Route path="/user/project/:id/specify" element={<Specify />} />
          <Route path="/user/project/:id/tender" element={<Tender />} />
          <Route path="/user/project/:id/repair" element={<Repair />} />
          <Route path="/user/project/:id/paint-cost" element={<PaintCost />} />
        </Route>
      </Route>
      <Route path="/user/project/:id" element={<PrivateRoute accessedRoles={[roles.AUTHOR, roles.EDITOR, roles.VIEWER, roles.ADMIN]} />}>
        <Route path="/user/project/:id" element={<Projects />}>
          <Route path="/user/project/:id/tender" element={<Tender />} />
          <Route path="/user/project/:id/close" element={<Close />} />
          <Route path="/user/project/:id/tender/compare-quotes" element={<CompareQuotesHome />} />
          <Route path="/user/project/:id/report" element={<ReportModal />} />
        </Route>
      </Route>
      <Route path="/user/resource" element={<PrivateRoute accessedRoles={[roles.VESSEL_USER, roles.AUTHOR, roles.EDITOR, roles.VIEWER]} />}>
        <Route path="/user/resource" element={<ResourceTSI />}>
          <Route path="/user/resource/web" element={<ResourceWebLinks />} />
          <Route path="/user/resource/reference" element={<ReferenceDoc />} />
          <Route path="/user/resource/usermanual" element={<UserManual />} />

        </Route>
      </Route>
      <Route path="/user/resource" element={<PrivateRoute accessedRoles={[roles.AUTHOR, roles.EDITOR]} />}>
        <Route path="/user/resource" element={<ResourceTSI />}>

          <Route path="/user/resource/yard" element={<YardCostHistory />} />

        </Route>
      </Route>
      <Route path="/permission-denied" element={<PermissionDenied />} />
      <Route path="/*" element={<PageNotFound />} />
      <Route path="/forget-password" element={<ForgotPassword />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/resetpassword" element={<SetNewPassword />} />
      <Route path="/reset-success" element={<ResetSuccess />} />
    </Routes>
  );
};
export default Rpoutes;
