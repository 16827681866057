/* eslint-disable jsx-a11y/no-autofocus */
/*eslint-disable*/
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import ModalBox from '../../../common/ModalBox';
import AddContact from './AddContact';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import {
  GetEmployeeListByProject,
  saveEmployeeContact,
  deleteEmployeeContactInfo,
  // getContactListByCategory,
  exportExcelContact
} from '../NewProject/services';
import { GetContactCategoryList } from '../../../Admin/DataConfig/GeneralConfig/services';
import { s3Upload, contactGenerateTemplate, contactImportExcel } from '../../../Admin/Vessels/VesselDetails/Attachment/services';
import CardWrapper from './CardWrapper';
import SidePanel from '../../../common/sidePanel';
import BulkUploadModal from '../../../Admin/DataConfig/CurrencyContainer/bulkUploadModal';
import Pagination from '../../../common/pagination';

const ContactScreen = (props) => {
  const { setShowContact, showContact, projInfo } = props;
  const { id } = useParams();
  const [employeeList, setEmployeeList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({
    projectId: id,
    images: '',
    name: '',
    position: '',
    contactCategory: '',
    // roleCategoryId: '',
    contactCategoryId: '',
    company: '',
    mobile: '',
    whatsapp: '',
    telephone: '',
    email: ''
  });
  const searchInputRef = useRef(null);
  const { generateOptions, formValidation, handleValidation,
    charValidation, ValidateEmail, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = (userRole === roles.AUTHOR)
  || (userRole === roles.EDITOR)
  || (userRole === roles.ADMIN)
  || (userRole === roles.VESSEL_USER);
  // const [contactRoleList, setContactRoleList] = useState([]);
  const [contactRoleListCategory, setContactRoleListCategory] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [contactCategoryList, setContactCategoryList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const initialContactData = {
    projectId: id,
    images: '',
    name: '',
    // roleCategoryId: '',
    contactCategoryId: '',
    company: '',
    mobile: '',
    whatsapp: '',
    telephone: '',
    email: '',
    position: '',
    contactCategory: ''
  };
  const getEmployeeList = (cPpage) => {
    const ipData = {
      page: cPpage || currentpage,
      pageSize,
      projectId: id,
      search: searchText
    };
    GetEmployeeListByProject(ipData).then((response) => {
      if (response?.success) {
        setLoading(false);
        setEmployeeList(response?.data);
      }
    });
  };
  const clearSearch = () => {
    const ipData = {
      page: 1,
      pageSize,
      projectId: id,
      search: ''
    };
    GetEmployeeListByProject(ipData).then((response) => {
      if (response?.success) {
        setLoading(false);
        setEmployeeList(response?.data);
      }
    });
  };
  useEffect(() => {
    getEmployeeList();
  }, [currentpage, pageSize]);

  useEffect(() => {
    setLoading(true);
  }, []);
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(contact);
    if (type === 'select') {
      fd[field] = e.value;
    } else if (field === 'mobile' || field === 'telephone') {
      fd[field] = e.target.value.replace(/[^0-9 )(+-]/g, '').substring(0, 15);
    }
    else if (field === 'position')
    {
      fd[field] = e.target.value.substring(0, 150);
    }
    else if (field === 'name') {
      fd[field] = charValidation(e.target.value.replace(/[^a-zA-Z\s-.]/g, ''));
    } else if (field === 'mobile') {
      fd[field] = charValidation(e.target.value);
    } else fd[field] = charValidation(e.target.value);
    setContact(fd);
    handleValidation(['name', 'contactCategoryId', 'company', 'mobile'], fd, field);
  };
  const acceptedFiles = ['image/jpeg', 'image/png', 'image/jpg'];
  const accepteduploadFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const { MAX_FILE_SIZE } = helper;
  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file || '');
    contactImportExcel(body, id).then((res) => {
      if (res.success) {
        getEmployeeList();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
      setTimeout(() => {
        document.body.classList.add('model-open');
      }, 30);
    });
  };

  const generatTemplateForExcel = () => {
    contactGenerateTemplate({ id });
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
    setTimeout(() => {
      document.body.classList.add('model-open');
    }, 30);
  };

  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          const currentImage = { ...contact };
          currentImage.images = res.data;
          setContact(currentImage);
          showAlert('Successfully Uploaded', 'success');
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
  };
  const ondropbulk = (e) => {
    const [first] = e.target.files;
    if (first && accepteduploadFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile({});
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getEmployeeList(1);
      setCurrentpage(1);
    }
  };
  // const getContactRoleListApi = () => {
  //   getContactRoleList().then((res) => {
  //     if (res?.success) {
  //       setContactRoleList(generateOptions(res?.data, 'id', 'name'));
  //     }
  //   });
  // };
  // const getContactListByCategoryApi = (categoryId) => {
  //   getContactListByCategory({ categoryId }).then((res) => {
  //     if (res?.success) {
  //       setLoading(false);
  //       setContactRoleListCategory(generateOptions(res?.data, 'id', 'name'));
  //     }
  //   });
  // };
  const handleAddContactRole = () => {
    GetContactCategoryList()?.then((res) => {
      if (res?.success) {
        setLoading(false);
        setContactCategoryList(generateOptions(res?.data, 'id', 'name'));
      }
    });
  };
  const saveApiCall = () => {
    setSearchText('');
    if (contact.email && !ValidateEmail(contact.email.toLowerCase())) {
      showAlert('Please enter valid Email', 'error');
    } else if (formValidation(['name', 'contactCategoryId', 'company', 'mobile'], contact)) {
      saveEmployeeContact(contact).then((response) => {
        if (response?.success) {
          setShowAddModal(false);
          setContact(initialContactData);
          getEmployeeList();
          showAlert('Successfully Saved', 'success');
        }
        setTimeout(() => {
          document.body.classList.add('model-open');
        }, 30);
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const deleteContact = (item) => {
    setOpenDeleteModal(item);
  };
  const deleteApiCall = (item) => {
    deleteEmployeeContactInfo({ employeeId: item.employeeId }).then((response) => {
      if (response?.success) {
        getEmployeeList();
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
      }
      setTimeout(() => {
        document.body.classList.add('model-open');
      }, 30);
    });
  };
  useEffect(() => {
    handleAddContactRole();
  }, []);

  const showAddContactModal = (item) => {
    // if (item?.contactCategory) {
    //   getContactListByCategoryApi(item?.contactCategoryId);
    // }
    setSearchText('');
    setShowAddModal(true);
    setContact(item);
  };
  const closeAddContactModal = () => {
    setShowAddModal(false);
  };
  const handleExcelContact = () => {
    exportExcelContact({ projectId: id, searchText });
  };

  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  return (
    <>
      <SidePanel
        title={(
          <div className="d-flex align-items-center sidepanel-contact">
            <div>Contacts</div>
            <div className="couter-bubbles status-due ms-3">{employeeList?.totalItems}</div>
          </div>
        )}
        showView={showContact}
        classHeader="bg-white"
        // bgColor="sky-white"
        onClose={() => {
          setShowContact(false);
        }}
      >
        <div className="d-flex justify-content-between mt-3 font-14" data-testId="contact-screen">
          <div className="d-flex col-gap-1 half-width spec-search">
            <div className="search col-3 large">
              <div className="d-flex justify-content-end">
                <span
                  className={searchText ? ' icon-close-line ' : ''}
                  onClick={() => {
                    setSearchText('');
                    searchInputRef.current.focus();
                    clearSearch();
                  }}
                />
                <span
                  className="icon-search"
                  id="searchEnter"
                  onClick={() => {
                    onSearch({ key: 'Enter' });
                  }}
                />
              </div>
              <input
                type="text"
                id="search"
                className="text-box large  with-close"
                value={searchText}
                ref={searchInputRef}
                placeholder="Search by Contact Name"
                onKeyPress={onSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            {openDeleteModal ? (
              <ModalBox
                openDeleteModal={openDeleteModal}
                modalClassName="medium"
                heading="Delete"
                id="ModalBox"
                onSave={() => {
                  deleteApiCall(openDeleteModal);
                }}
                closeModal={() => {
                  setOpenDeleteModal(false);
                }}
                buttonName="Delete"
              >
                <p>Are you sure you want to delete contact ?</p>
              </ModalBox>
            ) : (
              ''
            )}
          </div>
          <button
            type="button"
            id="add-name"
            className="secondary-btn large mx-3"
            onClick={() => {
              setUploadModal(true);
            }}
            disabled={projInfo?.isClosed}
          >
            Bulk Upload
          </button>
          {uploadModal && (
          <ModalBox
            modalClassName="large"
            heading="Upload Excel"
            onSave={() => { bulkUpload(); }}
            closeModal={() => {
              onCloseModal();
              document.body.classList.add('model-open');
            }}
            buttonName="Upload"
            saveDisable={isUpload}
          >
            <BulkUploadModal
              file={file}
              ondrop={ondropbulk}
              setFile={setFile}
              generatTemplateForExcel={generatTemplateForExcel}
              name="Contacts"
              setIsUpload={setIsUpload}
              existingData
            />
          </ModalBox>
          )}
          <div className="d-flex col-gap-1">
            {isTSI && (
              <button
                type="button"
                className="btn primary-btn large whitespace-pre"
                id="newContact"
                onClick={() => {
                  showAddContactModal(initialContactData);
                }}
                disabled={projInfo?.isClosed}
              >
                Create New Contact
              </button>
            )}

          </div>
          <button type="button" id="excel" className="link-btn large ms-2" onClick={() => handleExcelContact()}>
            <span className="icon-download me-2" />
            Excel Download
          </button>

        </div>
        <CardWrapper
          projInfo={projInfo}
          loading={loading}
          employeeList={employeeList?.items}
          handleChange={handleChange}
          deleteContact={deleteContact}
          showAddContactModal={showAddContactModal}
        />
        {employeeList?.totalItems > 10 && (
        <Pagination
          pageClick={pageClick}
          pageCount={employeeList?.totalPages}
          currentpage={currentpage}
          onSizeChange={onSizeChange}
          size={pageSize}
        />
        )}
        {showAddModal && (
          <ModalBox
            modalClassName="large"
            onSave={() => {
              saveApiCall();
            }}
            closeModal={() => {
              closeAddContactModal();
              getEmployeeList();
              setTimeout(() => {
                document.body.classList.add('model-open');
              }, 30);
            }}
            heading={contact.contactCategory ? 'Edit Contact' : 'Add New Contact'}
            buttonName="Save"
          >
            <AddContact
              contactRoleListCategory={contactRoleListCategory}
              // getContactRoleList={getContactListByCategoryApi}
              ondrop={ondrop}
              contactCategoryList={contactCategoryList}
              setContact={setContact}
              handleChange={handleChange}
              contact={contact}
            />
          </ModalBox>
        )}
      </SidePanel>
    </>
  );
};

export default ContactScreen;
