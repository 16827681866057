import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import ModalBox from '../../../common/ModalBox';

const RegionCard = ({
  regionList,
  handleRegionHide,
  setOpenModalRegionDelete,
  setOpenModal,
  addRegion,
  openModal,
  setSelectedRegion,
  initialRegions,
  openModalRegionDelete,
  removeRegion,
  isTSI
}) => {
  return (
    <div data-testid="region-card" className="col-7 overflow-auto">
      <div>Selected Region</div>
      <div className=" d-flex ">
        {regionList.map((i) => (
          <div className="bg-sky-white d-flex mt-1 py-2 me-1">
            <span className="icon-location font-18 me-1 pt-1" />
            <div className="text-ellipsis me-4">
              <span className=" " data-tooltip-id="project-cost" data-tooltip-content={i.label}>{i.label}</span>
            </div>
            <button
              type="button"
              id="hide-region"
              onClick={() => handleRegionHide(i)}
            >
              <span className={`icon-eye font-22 me-2 cursor-pointer ${i.isShow ? 'text-blue' : 'grey '}`} />
            </button>
            <div
              data-tooltip-id="project-cost"
              data-tooltip-content={(!isTSI || regionList.length < 2) ? 'At least one region is mandatory' : ''}
            >
              <button
                disabled={!isTSI || regionList.length < 2}
                type="button"
                id="delete-region"
                onClick={() => setOpenModalRegionDelete(i)}
              >
                <span className="text-blue icon-close-line font-14 me-2 pt-2 cursor-pointer" />
              </button>
            </div>
          </div>
        ))}
        <div className="col-2 pt-2">
          <button
            id="add-region"
            disabled={!isTSI}
            onClick={() => {
              setSelectedRegion(); setOpenModal(true);
            }}
            type="button"
            className="link-btn"
          >
            Add Region
          </button>
        </div>
      </div>

      {openModalRegionDelete ? (
        <ModalBox
          modalClassName="medium"
          onSave={() => removeRegion()}
          closeModal={() => setOpenModalRegionDelete(false)}
          heading="Delete Region"
          buttonName="Delete"
        >
          <p>Are you sure you want to delete region?</p>
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="project-cost" className="tooltip " />
      {openModal ? (
        <ModalBox modalClassName="medium" onSave={() => addRegion()} closeModal={() => setOpenModal(false)} heading="Add Region" buttonName="Add">
          <div className="">
            <label>
              Region
              <span className="text-primaryRed-1 m-2">*</span>
            </label>
            <Select
              onChange={(option) => setSelectedRegion(option)}
              className="modal-select"
              label="Region"
              placeholder="Select"
              options={initialRegions}
              id="Region"
            />
          </div>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default RegionCard;
