/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import _, { cloneDeep, concat } from 'lodash';
import ModalBox from '../../../common/ModalBox';
import {
  getUserList,
  saveUser,
  deleteUser,
  disableUser,
  GetNonMappedFleets,
  GetNonMappedVessels,
  generatTemplate,
  bulkUploadUser,
  exportExcelUserList,
  UserListByVessel
} from './services';
import { getRoleList } from '../Roles/services';
import helper from '../../../../utils/helper';
import { getVesselList } from '../../Vessels/Vessel/services';
import { getManagementGroupList } from '../../Vessels/Management/services';
import showAlert from '../../../../utils/alert';
import CardWrapper from './cardWrapper';
import ViewDetails from './viewDetails';
import Pagination from '../../../common/pagination';
import SidePanel from '../../../common/sidePanelCustom';
// import CopyRight from '../../../common/copyRight';
import AddUser from './addUser';
import BulkUploadModal from '../../DataConfig/CurrencyContainer/bulkUploadModal';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';
import Loader from '../../../common/loader';

const initialData = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  externalId: '',
  employeeId: '',
  userName: '',
  pwdHash: '',
  phone: '',
  mobile: '',
  address: 'string',
  profileImage: '',
  status: 1,
  roleData: [
    {
      managementId: '',
      management: '',
      roleId: '',
      role: '',
      vessels: []
    }
  ],
  tenantId: '00000000-0000-0000-0000-000000000000',
  isDisabled: false
};
let isSearched = false;
const UserList = ({ vesselId, setUserCount, isVesselUser }) => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const [showModal, setShowModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [management, setManagement] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [currentData, setCurrentData] = useState(initialData);
  const [currentDataFixed, setCurrentDataFixed] = useState(initialData);
  const [showView, setShowView] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [saveTrigger, setSaveTrigger] = useState(false);
  const { generateOptions, formValidation, ValidateEmail,
    addColor, MAX_FILE_SIZE, ZERO_UUID } = helper;
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const [errors, setErrors] = useState({ newPassword: '', confirmPassword: '', existingPassword: '' });
  const [password, setPassword] = useState('');

  const setInitailDataInForm = () => {
    const iData = {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      externalId: '',
      employeeId: '',
      userName: '',
      pwdHash: '',
      phone: '',
      mobile: '',
      address: 'string',
      profileImage: '',
      status: 1,
      roleData: [
        {
          managementId: '',
          management: '',
          roleId: '',
          role: '',
          vessels: []
        }
      ],
      tenantId: '00000000-0000-0000-0000-000000000000',
      isDisabled: false
    };
    setCurrentData(iData);
  };
  const getUsers = (page) => {
    setLoading(true);
    getUserList({ page: page || currentpage, pageSize, search: isSearched ? searchText : '' }).then((res) => {
      setLoading(false);
      if (res.data) {
        setUserList(res.data);
        if (setCount) {
          setCount(res?.data?.totalItems || 0);
        }
      }
    });
  };

  const getVesselUsers = () => {
    setLoading(true);
    const payload = {
      page: currentpage,
      pageSize: isVesselUser ? 100 : pageSize,
      search: isSearched ? searchText : '',
      sortProperty: '',
      isDescending: true,
      vesselId
    };
    UserListByVessel(payload).then((res) => {
      setLoading(false);
      if (res.data) {
        setUserList(res.data);
        setUserCount(res.data.items.length);
      }
    });
  };

  useEffect(() => {
    if (vesselId) {
      getVesselUsers();
    } else {
      getUsers();
    }
  }, [pageSize, currentpage]);

  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  useEffect(() => {
    if (userDetails !== null) {
      const vesselName = userDetails[0]?.vessels[0]?.name;
      sessionStorage.setItem('vesselName', vesselName);
    }
  }, []);

  useEffect(() => {
    getManagementGroupList({
      page: 1,
      pageSize: 500
    }).then((res) => {
      if (res.data) setManagement(generateOptions(res.data.items, 'id', 'name', 'location'));
    });
    getRoleList(vesselId || ZERO_UUID).then((res) => {
      if (res.data) {
        if (vesselId) {
          const vesselRoles = res.data.filter((items) => items.type === 5 && items.isMapped === false);
          setRoles(generateOptions(vesselRoles, 'id', 'name'));
        } else {
          setRoles(generateOptions(res.data, 'id', 'name'));
        }
      }
    });
    getVesselList({ page: 1, pageSize: 500 }).then((res) => {
      if (res.data) {
        setVessels(generateOptions(res.data.items, 'id', 'name'));
      }
    });
  }, [userList]);

  const getRoleCategory = (role) => {
    const f = roles.filter((i) => i.id === role);
    let found = false;
    if (f && f.length) {
      if (f[0].type === 4) { // type of Viewer
        found = true;
      }
    }
    return found;
  };

  const onClose = () => {
    setShowModal(false);
    setCurrentData(initialData);
    setCurrentDataFixed(initialData);
    setPassword('');
    setErrors({ password: '' });
  };

  function encrypt(input) {
    const salt = 'DryDock';
    const hmac = CryptoJS.HmacSHA256(input, salt);
    return hmac.toString(CryptoJS.enc.Hex);
  }

  const onSave = () => {
    const formDatas = cloneDeep(currentData);
    if (currentData.isLocalUser) {
      formDatas.email = '';
    }
    setSaveTrigger(true);
    let isFilled = true;
    let isFilledPass = true;
    if (formDatas.isAdmin) {
      isFilled = true;
      formDatas.roleData = [];
      formDatas.isLocalUser = false;
      formDatas.isVesselUser = false;
      formDatas.isLocalAdmin = false;
    } else {
      if (!formDatas.roleData || !formDatas.roleData?.length) {
        isFilled = false;
      }

      formDatas.roleData.map((valid, key) => {
        ['management', 'role'].forEach((item) => {
          const mId = item + key;
          if (valid[item] === null || valid[item] === '' || valid[item] === ' ') {
            isFilled = false;
            addColor(mId, 'add');
          } else {
            addColor(mId, '');
          }
        });
        if (getRoleCategory(valid.roleId)) {
          const vId = `fleets${key}`;
          if (valid.fleets?.length < 1) {
            addColor(vId, 'add');
            isFilled = false;
          }
        } else {
          const vId = `vessel${key}`;
          if (valid.vessels.length < 1) {
            addColor(vId, 'add');
            isFilled = false;
          }
        }
        return null;
      });
    }
    let arr1 = [];
    if (formDatas.isLocalUser) {
      arr1 = ['firstName', 'userName', 'pwdHash'];
      addColor('email', '');
      addColor('employeeId', '');
      if (!password) {
        if (!formDatas?.id || formDatas?.id === ZERO_UUID) {
          isFilledPass = false;
          setErrors({ password: 'Please Enter the New Password' });
        }
      } else if (
        password.length < 8
        || formDatas.userName.toLowerCase() === password.toLowerCase()
        || password.search(/[0-9]/) === -1
        || (password.search(/[A-Z]/) === -1 && password.search(/[a-z]/) === -1)
        || password.search(/[!\@\#\$\%\^\&\()\_\:\.]/) === -1
      ) {
        isFilledPass = false;
        setErrors({
          password: 'Password must be at least 8 characters, include a number, a letter, a symbol, and differ from your username.'
        });
      } else if (formDatas?.id && formDatas?.id !== ZERO_UUID) {
        isFilledPass = true;
        setErrors({ password: '' });
      } else {
        setErrors({ password: '' });
      }
    } else {
      arr1 = ['email', 'employeeId', 'firstName'];
      addColor('userName', '');
      addColor('pwdHash', '');
    }
    if (formDatas.email && !ValidateEmail(formDatas.email?.toLowerCase())) {
      showAlert('Please enter valid Email', 'error');
    } else if (formValidation(arr1, formDatas) && isFilled && isFilledPass) {
      if (formDatas.pwdHash) formDatas.pwdHash = encrypt(formDatas.pwdHash);
      saveUser(formDatas).then((res) => {
        if (res.success) {
          if (document.getElementById('pwdHash')) {
            document.getElementById('pwdHash').value = '';
          }
          showAlert(formDatas.id ? 'Successfully Updated' : 'Successfully Saved', 'success');
          setShowModal(false);
          setInitailDataInForm();
          if (vesselId) {
            getVesselUsers();
          } else {
            getUsers();
          }
          onClose();
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const deleteApiCall = () => {
    setOpenDeleteModal(false);
    deleteUser({ id: currentData.id }).then((res) => {
      if (res.success) {
        showAlert('Deleted successfully', 'success');
        if (vesselId) {
          getVesselUsers();
        } else {
          getUsers();
        }
        setInitailDataInForm();
      }
    });
  };
  const onDeleteUser = (data) => {
    setOpenDeleteModal(true);
    setCurrentData(data);
  };
  const onDisable = (data) => {
    setOpenDisableModal(true);
    setCurrentData(data);
  };
  const onDisableUser = (data) => {
    disableUser({ isDisabled: !data.isDisabled, id: data.id }).then((res) => {
      if (res.success) {
        showAlert('User Status updated', 'success');
        setOpenDisableModal(false);
        if (vesselId) {
          getVesselUsers();
        } else {
          getUsers();
        }
      }
    });
  };
  const handleManagementChange = async (data) => {
    const d = cloneDeep(data);
    await d.roleData?.forEach((i, key) => {
      const mId = i.managementId;
      if (mId) {
        setLoadingEdit(true);
        GetNonMappedFleets(mId).then((res) => {
          if (res.success) {
            d.roleData[key].fleetList = generateOptions(res.data, 'id', 'name');
            // d.roleData[key].fleetList = concat(generateOptions(res.data, 'id', 'name'),
            // generateOptions(d.roleData[key].fleets, 'id', 'name'));
          }
          GetNonMappedVessels(mId, i.roleId).then((res1) => {
            if (res.success) {
              d.roleData[key].vesselList = concat(generateOptions(res1.data, 'id', 'name'), generateOptions(d.roleData[key].vessels, 'id', 'name'));
            }
            setCurrentData(d);
            setLoadingEdit(false);
          });
        });
      } else {
        setCurrentData(data);
      }
    });
    if (!d.roleData || !d.roleData?.length) {
      setCurrentData(d);
    }
  };
  const onEditUser = (data) => {
    handleManagementChange(data);
    setShowModal(true);
    setCurrentDataFixed(data);
  };

  const onViewClose = () => {
    setShowView(false);
    setInitailDataInForm();
  };
  const showDetails = (data) => {
    setShowView(true);
    setCurrentData(_.cloneDeep(data));
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearched = true;
      if (!vesselId) {
        getUsers(1);
      } else {
        getVesselUsers();
      }
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const onEditClick = () => {
    setShowView(false);
    setShowModal(true);
    handleManagementChange(currentData);
    setCurrentDataFixed(currentData);
  };
  const handleExcelExportUser = () => {
    if (userList?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelUserList(searchText, vesselId || ZERO_UUID);
    }
  };
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file);
    setIsUploading(true);
    bulkUploadUser(body).then((res) => {
      setIsUploading(false);
      if (res.success) {
        getUsers();
        setLoading(false);
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };
  useEffect(() => {
    if (!searchText && !vesselId) {
      getUsers(1);
    } else if (!searchText) {
      getVesselUsers();
    }
  }, [searchText]);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div className="container" data-testid="userlist">
      <div className="d-flex justify-content-between mb-3 mt-3">
        <div className="d-flex col-gap-1">
          <div className="search large spec-search">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                id="search-click"
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
              />
              <span
                className="icon-search"
                id="lens"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <div className="searchbar-userlist-width-increase">
              <input
                id="search"
                type="text"
                value={searchText}
                className="text-box large with-close"
                placeholder={vesselId ? 'Search by First Name, Role' : 'Search by First Name, Management, Role, Email, Vessel'}
                onKeyPress={onSearch}
                onChange={(e) => {
                  isSearched = false;
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            id="add-user"
            type="button"
            className="primary-btn large"
            onClick={() => {
              const ini = cloneDeep(initialData);
              ini.roleData = [
                {
                  managementId: '',
                  management: '',
                  roleId: '',
                  role: '',
                  vessels: []
                }
              ];
              if (vesselId) {
                ini.isLocalUser = true;
                ini.roleData[0].managementId = userDetails[0]?.managementId;
                ini.roleData[0].management = userDetails[0]?.management;
                ini.roleData[0].vessels = userDetails[0]?.vessels;
              }
              setCurrentDataFixed(ini);
              setCurrentData(ini);
              setShowModal(true);
            }}
          >
            Add User
          </button>
          {!vesselId
            && (
              <div>
                <button
                  type="button"
                  id="add-name"
                  className="secondary-btn large"
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  Bulk Upload
                </button>
              </div>
            )}
        </div>
        {!vesselId
          && (
            <div className="d-flex justify-content-end">
              <button type="button" className="link-btn large " id="excel" onClick={handleExcelExportUser}>
                <span className="icon-download me-4" />
                Excel Download
              </button>
            </div>
          )}
        {uploadModal && (
          <ModalBox
            modalClassName="medium"
            heading="Upload Excel"
            onSave={() => {
              bulkUpload();
            }}
            closeModal={() => onCloseModal()}
            buttonName="Upload"
            saveDisable={isUpload || isUploading}
          >
            <BulkUploadModal
              file={file}
              ondrop={ondrop}
              setFile={setFile}
              generatTemplateForExcel={generatTemplateForExcel}
              name="User List"
              setIsUpload={setIsUpload}
              isUploading={isUploading}
            />
          </ModalBox>
        )}
      </div>
      {loading ? <Loader /> : (
        <div>
          <CardWrapper
            userList={userList}
            onDeleteUser={onDeleteUser}
            onDisable={onDisable}
            onEditUser={onEditUser}
            showDetails={showDetails}
            loading={loading}
            vesselId={vesselId}
          />
          {userList?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={userList.totalPages}
              currentpage={currentpage}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}

        </div>
      )}
      {showModal ? (
        <SidePanel
          title={currentData.id ? 'Update User' : 'Add User'}
          onClose={onClose}
          buttonName="save"
          showView={showModal}
          onSave={onSave}
        >
          <div className="side-panel__container bg-white">
            <div className="side-panel__content">
              <AddUser
                currentUser={currentData}
                setCurrentData={setCurrentData}
                roles={roles}
                vessels={vessels}
                management={management}
                handleManagementChange={handleManagementChange}
                saveTrigger={saveTrigger}
                currentDataFixed={currentDataFixed}
                loading={loadingEdit}
                setErrors={setErrors}
                setPassword={setPassword}
                password={password}
                errors={errors}
                vesselId={vesselId}
              />
            </div>
          </div>
          <footer className="side-panel__footer">
            <button type="button" className="secondary-btn me-4" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="primary-btn me-4" onClick={onSave}>
              Save
            </button>

          </footer>
        </SidePanel>
      ) : ''}

      {openDeleteModal && (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete user ?</p>
        </ModalBox>
      )}
      {openDisableModal && (
        <ModalBox
          modalClassName="medium"
          heading={currentData.isDisabled ? 'Are you sure you want to enable the user?' : 'Are you sure you want to disable the user?'}
          onSave={() => onDisableUser(currentData)}
          closeModal={() => setOpenDisableModal(false)}
          buttonName={currentData.isDisabled ? 'Enable' : 'Disable'}
        >
          {currentData.isDisabled ? (
            <p>Once enabled, the user will have access to the application screens.</p>
          ) : (
            <p>Once disabled, the user will not have access to the application screens.</p>
          )}
        </ModalBox>
      )}
      <ViewDetails
        data={currentData}
        onClose={onViewClose}
        showView={showView}
        onEditClick={onEditClick}
      />
      <div className="">
        <div className="">
          <div className="footer d-flex justify-content-between font-13 mx-0 my-2">
            <PolicyFooter
              setShowPrivacyPolicy={setShowPrivacyPolicy}
              setShowTermsPolicy={setShowTermsPolicy}
              vesselId={vesselId}
            />
          </div>
        </div>

      </div>
      {' '}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </div>
  );
};
export default UserList;
