/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import CustomButton from './CustomButton';

const ModalBox = ({
  heading,
  children,
  buttonName,
  closeModal,
  modalClassName = 'medium',
  onSave,
  saveDisable = false,
  isCloseModal = true,
  extraButton,
  onClickExtra,
  closeIcon,
  time = 5000,
  comment = false,
  removeClass = true,
  extraClassName = false,
  removeCancelBtn,
  serviceLineMandatoryText,
  text,
  confirm,
  openDeleteModal,
  isNoLabel,
  sofData,
  fromSof,
  addClikedSof,
  editClikedSof,
  removeTopMargin
}) => {
  const isFocusing = openDeleteModal || confirm;
  useEffect(() => {
    document.body.classList.add('model-open');
    document.getElementById('drop-down1')?.classList?.remove('dropdown');
    document.getElementById('drop-down2')?.classList?.remove('dropdown');
    document.getElementById('drop-down3')?.classList?.remove('dropdown');
    document.getElementById('drop-down4')?.classList?.remove('dropdown');
  }, [heading]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('model-open');
      document.getElementById('drop-down1')?.classList?.add('dropdown');
      document.getElementById('drop-down2')?.classList?.add('dropdown');
      document.getElementById('drop-down3')?.classList?.add('dropdown');
      document.getElementById('drop-down4')?.classList?.add('dropdown');
    };
  }, []);
  return (
    <div className="back-drop mt-2">
      <div
        className={comment ? `comment ${removeTopMargin ? '' : 'mt-5'} animate__animated animate__bounceIn ${modalClassName}` : `modal ${removeTopMargin ? '' : 'mt-5'} animate__animated animate__bounceIn ${modalClassName} `}
      >
        <div className="modal-header">
          <h3 className="d-flex">
            {heading}
            {fromSof && !editClikedSof && !addClikedSof && (
              <button type="button" className="primary-btn small count-badge ml-12">{sofData?.length}</button>
            )}
          </h3>
          {isCloseModal && (
            <button type="button" className="link-btn" data-test="click1" onClick={closeIcon || closeModal}>
              <span className="icon-close font-26"> </span>
            </button>
          )}
        </div>
        {serviceLineMandatoryText && (
          <p className="mb-3">
            Specs cannot be
            {' '}
            {text}
            {' '}
            as there are service lines
            with no ac code added for the
            following specs.
          </p>
        )}
        <div className={`${extraClassName ? 'modal-empty-spec' : 'modal-body'}`}>
          {children}
        </div>

        {buttonName ? (
          <div className="modal-footer">
            <div className="d-flex justify-content-end">
              {removeCancelBtn ? '' : (
                <button
                  className="secondary-btn large me-4 text-capitalize"
                  data-testid="discard"
                  data-test="click2"
                  onClick={closeModal}
                  type="submit"
                  style={{ transition: 'all .15s ease' }}
                >
                  {isNoLabel ? 'No' : 'Cancel'}
                </button>
              )}
              <CustomButton
                autoFocus={isFocusing}
                className="primary-btn large text-capitalize"
                disabled={saveDisable}
                data-testid="submit"
                data-test="click3"
                onClick={() => onSave()}
                type="submit"
                time={time}
                style={{ transition: 'all .15s ease' }}
                buttonName={buttonName}
              />
              {extraButton && (
                <button
                  autoFocus={isFocusing}
                  className="primary-btn large ms-4 text-capitalize"
                  data-testid="submit"
                  data-test="click4"
                  onClick={() => onClickExtra()}
                  type="submit"
                  style={{ transition: 'all .15s ease' }}
                >
                  {extraButton}
                </button>
              )}
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default ModalBox;
