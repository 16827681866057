import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { cloneDeep, truncate, toNumber } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';

const SectionCard = ({
  section,
  index,
  handleAddNew,
  isOpenRow,
  setIsOpenRow,
  isTSI,
  // eslint-disable-next-line no-unused-vars
  setisOpenModal,
  handleChangeSection }) => {
  const tuFc = [
    { value: 'tu', label: 'T/U' },
    { value: 'fc', label: 'F/C' }
  ];
  const updateOpen = () => {
    let d = cloneDeep(isOpenRow);
    if (d.includes(section.id)) {
      d = isOpenRow.filter((option) => option !== section.id);
    } else {
      d.push(section.id);
    }
    setIsOpenRow(d);
  };
  return (
    <div data-testid="p-2" className="header-top1 border-down">
      <div
        className={`d-flex justify-content-between me-3 pt-4
      ${!isOpenRow.includes(section.id) ? 'hull-height' : ''}`}
      >
        <div>
          <span
            id="update-open"
            className={isOpenRow.includes(section.id) ? 'font-10 icon-arrow-thin-down me-3 pointer' : ' icon-arrow-thin-right pointer me-2'}
            onClick={() => {
              updateOpen();
            }}
          />
          <span
            className={`text-ellipsis px-2 ${section.type >= 5 ? 'font-bold font-18' : ''}`}
            data-tooltip-id="project-card"
            data-tooltip-content={section.sectionName}
          >
            {truncate(section.sectionName, { length: 35 })}
          </span>
        </div>
        <div className="d-flex col-gap-1 align-items-center input-700 ">
          <div>Total Area</div>
          <InputType1
            value={section.totalArea}
            disabled={!isTSI}
            onChange={(e) => handleChangeSection('totalArea', e.target.value, index, 0)}
            id="areaPercentage"
            className="medium w-120"
            label=""
          />
          <div className="d-flex me-1 font-22">
            &#13217;
          </div>
        </div>
      </div>
      { isOpenRow.includes(section.id)
        ? (
          <div>
            <div className="align-items-center bg-sky-blue d-flex p-2 me-4 mt-4 text-black">
              <div className="w-100">T/U or F/C</div>
              <div className="w-150 ml-8">Paint Spec</div>
              <div className="w-100 ml-8">Area %</div>
              <div className="w-100 ml-8">Surface Area</div>
              <div className="w-100 ml-8">
                Min dft
                <br />
                reqd
              </div>
              <div className="w-100 ml-8" title="Estimated Loss % per layer">Est. Loss %</div>
              <div className="w-100 ml-8">Action</div>
            </div>
            {section.rows.map((i, rowIndex) => (
              <div className="align-items-center d-flex me-4 text-black">
                <div className="paint-cost-select">
                  {/* <InputType1
                    onChange={(e) => {
                      if (toNumber(e.target.value) <= 100) {
                        console.log('ee', e.target.value);
                        handleChangeSection('tuFc', e.target.value, index, rowIndex);
                      }
                    }}
                    value={i.tuFc}
                    disabled={!isTSI}
                    className="me-1 modal-inputs small"
                    label=""
                  /> */}
                  <Select
                    className=" w-100 mx-1"
                    placeholder="Select"
                    options={tuFc}
                    // value={i.tuFc}
                    value={tuFc.filter((list) => list.value === i.tuFc)}
                    isDisabled={!isTSI}
                    onChange={(e) => {
                      handleChangeSection('tuFc', e, index, rowIndex);
                    }}
                  />
                </div>
                <div
                  className="py-3 w-150"
                  data-tooltip-id="project-card"
                  data-tooltip-content={i.paintSpecification}
                >
                  <InputType1
                    onChange={(e) => handleChangeSection('paintSpecification', e.target.value, index, rowIndex)}
                    value={i.paintSpecification}
                    className="mx-1 modal-inputs small"
                    label=""
                    disabled={!isTSI}
                  />
                </div>
                <div className="py-3 w-100">
                  <InputType1
                    onChange={(e) => {
                      if (toNumber(e.target.value) <= 100) {
                        handleChangeSection('areaPercentage', e.target.value, index, rowIndex);
                      }
                    }}
                    value={i?.areaPercentage || ''}
                    disabled={!isTSI || i?.tuFc === 'fc'}
                    className="me-1 modal-inputs small"
                    label=""
                  />
                </div>
                <div
                  className="py-3 w-100"
                  data-tooltip-id="project-card"
                  data-tooltip-content={i.surfaceArea}
                >
                  <InputType1
                    value={i.surfaceArea || ''}
                    className="mx-1 modal-inputs small"
                    label=""
                    disabled
                  />
                </div>
                <div
                  className="py-3 w-100"
                  data-tooltip-id="project-card"
                  data-tooltip-content={i.minDft}
                >
                  <InputType1
                    onChange={(e) => handleChangeSection('minDft', e.target.value, index, rowIndex)}
                    value={i.minDft || ''}
                    className="mx-1 modal-inputs small"
                    disabled={!isTSI}
                    label=""
                  />
                </div>
                <div
                  className="py-3 w-100"
                  data-tooltip-id="project-card"
                  data-tooltip-content={i.estimatedLoss}
                >
                  <InputType1
                    onChange={(e) => handleChangeSection('estimatedLoss', e.target.value, index, rowIndex)}
                    value={i.estimatedLoss || ''}
                    className="ms-1 modal-inputs small"
                    disabled={!isTSI}
                    label=""
                  />
                </div>
                <div
                  className="ms-3 mt-3 py-3 w-100"
                >
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => {
                      setisOpenModal({ index, indexRemoved: rowIndex });
                    }}
                  >
                    <span className="icon-delete icon-btn  brand-color cursor-pointer font-24" />
                  </button>
                </div>
              </div>
            ))}
            {isTSI && (
              <div className="d-flex justify-content-end me-4">
                <button
                  onClick={() => handleAddNew(index)}
                  type="button"
                  className="link-btn add-new-btn"
                  id="add_new_section_row"
                >
                  Add New Row
                </button>
              </div>
            )}

          </div>
        )
        : <div />}
      <Tooltip id="project-card" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default SectionCard;
