import React, { useState, useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import JoditEditor from 'jodit-react';
import Loader2 from '../../../../common/loader2';
import { GetDescriptions, SaveDescriptions } from './services';
import showAlert from '../../../../../utils/alert';

const AddDescription = (props) => {
  const [content, setContent] = useState('');
  const [formData, setFormData] = useState({ bodyHtml: '' });
  const [showEdit, setShowEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [cancelData, setCancelData] = useState('');
  const [isEditClick, setIsEditClick] = useState(true);
  const { projectSpecificationId, activeModalData } = props;
  const buttons = [
    // 'font',
    'fontsize',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'ul',
    'ol',
    'indent',
    'video',
    'align',
    'brush',
    'image',
    'table'
  ];
  const config = useMemo(
    () => ({
      readonly: isEditClick,
      toolbarAdaptive: false,
      tabIndex: -1,
      buttons,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: 'insert_as_html',
      uploader: {
        insertImageAsBase64URI: true,
        isMultiple: true
      },
      spellcheck: true
    }),
    [isEditClick]
  );
  const getDescription = () => {
    GetDescriptions(
      activeModalData.id
    ).then((res) => {
      if (res.success) {
        setCancelData(res?.data?.description);
        setFormData(res?.data);
        setLoading(false);
      }
    });
  };

  const cancel = () => {
    const fd = cloneDeep(formData);
    fd.description = cancelData;
    setFormData(fd);
    setShowEdit(true);
    getDescription();
  };
  const saveDescription = () => {
    const payload = {
      projectSpecificationId: activeModalData.id,
      description: content || cancelData
    };
    SaveDescriptions(payload).then((res) => {
      setLoading(false);
      if (res.success) {
        showAlert('Successfully Updated', 'success');
        setShowEdit(true);
        setIsEditClick(true);
        setCancelData('');
        setFormData({});
        getDescription();
      }
    });
  };
  useEffect(() => {
    if (projectSpecificationId) {
      getDescription();
    }
  }, [projectSpecificationId]);
  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div data-testid="mt-add-description" className="description-main">
      {loading ? (
        <div className="drag-drop-file my-4">
          {' '}
          <Loader2 />
          {' '}
        </div>
      ) : (
        <>
          <div className="d-flex my-2 justify-content-end">
            <div className="d-flex justify-content-end pe-2">
              {showEdit && (
                <button type="button" className="primary-btn m-auto" id="edit" onClick={() => { setShowEdit(false); setIsEditClick(false); }}>Edit</button>
              )}
            </div>
          </div>
          <div className="row jodit-bg jodit-temp-height">
            <div className={showEdit ? 'editor-wrap ' : ''}>
              <div className="fixwidth">
                <div className="jj">
                  <JoditEditor
                    value={formData.description}
                    config={config}
                    onBlur={(newContent) => setContent(newContent)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex my-2 justify-content-end">
              {!showEdit && (
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    id="cancel"
                    className="secondary-btn"
                    onClick={() => {
                      cancel();
                      setIsEditClick(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    id="saveDescription"
                    className="primary-btn ml-12"
                    onClick={() => {
                      saveDescription();
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>

        </>
      )}

    </div>
  );
};
export default AddDescription;
