/* istanbul ignore file */
import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const SupplementaryTable = (props) => {
  const { specList, mainSec, showsubSec, onSecOpen, editData,
    deteleData, setIsAddClicked, fromVariation, isTSI, projInfo } = props;
  return (
    <div>

      <div className="spc-map ps-3 supplementary-table" data-testid="sp-table">
        <div className="d-flex border-down mb-2 ps-3">
          <div className="col-2 font-bold">SEC#</div>
          <div className="col-8 font-bold">SPEC SECTION</div>
        </div>
        {
        specList?.map((sec) => {
          return (
            <div>
              <div className="d-flex border-down py-2 ps-3">
                <div className="col-2 d-flex align-items-center">
                  <span
                    data-testid="open"
                    className={sec?.id === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec?.id, true); }}
                  />
                  <div
                    className="ml-12"
                  >
                    <span
                      data-tooltip-id="project-supplementary"
                      data-tooltip-content={sec?.specificationNo}
                    >
                      {_.truncate(sec?.specificationNo, { length: 10 })}
                    </span>
                  </div>
                </div>
                <div
                  className="col-8 text-ellipsis"
                >
                  <span
                    data-tooltip-id="project-supplementary"
                    data-tooltip-content={sec.title}
                  >
                    {_.truncate(sec?.title, { length: 50 })}
                  </span>
                </div>
              </div>
              <Tooltip id="project-supplementary" place="top" className="tooltip mt-3" />
              {
                sec?.id === mainSec && sec?.childData?.map((subsec) => {
                  return (
                    <>
                      <div className="d-flex mb-2 pb-2 ps-3 mt-3 border-down">
                        <div className="col-1" />
                        <div className="col-11 d-flex align-items-center">
                          <span
                            data-testid="open"
                            className={subsec?.id === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                            onClick={() => { onSecOpen(subsec?.id, false); }}
                          />
                          <span className="me-4 ml-12">{subsec?.specificationNo}</span>
                          <div
                            className="col-8 text-ellipsis"
                          >
                            <span
                              data-tooltip-id="project-supplementary"
                              data-tooltip-content={subsec.title}
                            >
                              {_.truncate(subsec?.title, { length: 80 })}
                            </span>

                          </div>
                          <div className="editDeleteSpec">
                            <div className="d-flex ml-8">
                              <button
                                type="button"
                                id="edit"
                                className="link-btn"
                                onClick={() => {
                                  if (subsec?.isEditable) editData(subsec);
                                  setIsAddClicked(false);
                                }}
                              >
                                <span id="edit" className={!subsec?.isEditable ? 'icon-edit font-24 brand-color disabled-field' : 'icon-edit font-24 brand-color cursor-pointer'} />
                              </button>
                              {/* <span id="edit" onClick={() => { if (subsec?.isEditable)
                               editData(subsec);
                                 setIsAddClicked(false); }} className={!subsec?.isEditable ?
                                   'icon-edit font-24 brand-color disabled-field' :
                                  'icon-edit font-24 brand-color cursor-pointer'} /> */}
                              {isTSI && (
                                <>
                                  <button
                                    type="button"
                                    id="delete"
                                    className="link-btn"
                                    disabled={projInfo?.isClosed}
                                    onClick={() => {
                                      if (subsec?.isEditable) deteleData(subsec);
                                    }}
                                  >
                                    <span
                                      className={!subsec?.isEditable ? 'icon-delete font-24 brand-color disabled-field ml-12' : 'icon-delete font-24 brand-color ms-3 cursor-pointer'}
                                    />
                                  </button>
                                </>
                              )}

                            </div>

                          </div>

                        </div>
                      </div>
                      <div className="">
                        {subsec?.id === showsubSec
                            && subsec.specServiceLine.map((serv, num) => {
                              return (
                                <div>
                                  {num === 0 && (
                                    <div className="d-flex ps-3 align-items-center">
                                      <div className="col-1" />
                                      <div className="col-001 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                                      <div className="col-8 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                                      <div className="col-1 bg-sky-white ps-3 border-down py-2">UOM</div>
                                      <div className="col-0 bg-sky-white ps-3 border-down py-2">Qty</div>

                                    </div>
                                  )}
                                  <div className="d-flex ">
                                    <div className="col-1" />
                                    <div className="border-down col-001 p-2 ml-12">{serv.number}</div>
                                    <div
                                      className="col-8 text-justify text-break-all p-2 border-down"
                                    >
                                      <span
                                        data-tooltip-id="project-supplementary"
                                        data-tooltip-content={serv.serviceLineName}
                                      >
                                        {serv.serviceLineName}
                                      </span>

                                    </div>
                                    <div className="col-1 ps-2 border-down">
                                      <span
                                        data-tooltip-id="project-supplementary"
                                        data-tooltip-content={fromVariation ? (serv.uomName || '-') : (serv.uom || '-')}
                                      >
                                        {_.truncate(fromVariation ? serv.uomName : serv.uom,
                                          { length: 8 })}
                                      </span>

                                    </div>
                                    <div className="col-0 p-2 border-down ">{serv.quantity || '-'}</div>
                                  </div>
                                </div>
                              );
                            })}
                      </div>
                    </>
                  );
                })
              }
            </div>
          );
        })
      }
      </div>

    </div>
  );
};
export default SupplementaryTable;
