import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import Select from 'react-select';
import { statusWODefectColor } from '../../../Specify/SpecsList/WODefectList/Children/utilities';

const WoDefect = (props) => {
  const { specData, onStatusChange, isTSI, projectBasicDetails } = props;
  const value = [{ label: 'Component :', key: 'component' },
    { label: 'Raised By :', key: 'raisedBy' },
    { label: 'Interval :', key: 'interval' },
    { label: 'Description :', key: 'description' }
  ];
  const StatusList = [
    { label: 'Open', value: 0 },
    { label: 'Completed', value: 1 },
    { label: 'On Hold', value: 2 },
    { label: 'Deferred To Next DD', value: 3 }
  ];
  if (specData.specWohaData?.length < 1) {
    return (
      <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="repair-wodefect">
        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
        <div className="font-18 animate__animated animate__headShake">
          No data found
        </div>
      </div>
    );
  }
  return (
    <div data-testid="repair-wodefect">
      {specData?.specWohaData?.map((item2, key) => {
        return (
          <div className="card mt-3 p-4" data-testid="wo-defectlist">
            <div className="border-down">
              <div className="d-flex mb-3 justify-content-between">
                <div className="d-flex align-items-center ">
                  <div className="color-light-blue font-semibold">
                    <span
                      data-tooltip-id="project-phase"
                      data-tooltip-content={item2.externalId}
                    >
                      {_.truncate(item2.externalId, { length: 15 })}
                    </span>

                  </div>
                  <div className="font-bold ms-2">-</div>
                  <h1
                    className="head-14 ms-2"
                    data-tooltip-id="project-phase"
                    data-tooltip-content={item2.title}
                  >
                    {_.truncate(item2.title, { length: 20 })}

                  </h1>
                  <div className="docTypeName white width-fit ms-3" style={{ background: statusWODefectColor(item2.status) }}>{item2.statusName}</div>
                </div>
                <div className="d-flex me-3 align-items-center">
                  <div className=" opacity-6 me-3">{item2.typeName}</div>
                  <Select
                    options={StatusList}
                    className="w-150"
                    isDisabled={!isTSI || projectBasicDetails?.isClosed}
                    value={StatusList.filter((field) => field.value === item2.status)}
                    onChange={(e) => { onStatusChange(e, item2, key); }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              {value.map((item3) => {
                return (
                  <div className="d-flex mt-3 col-6">
                    <label>{item3.label}</label>
                    <div className="ms-3 gray opacity-6">
                      <span
                        data-tooltip-id="project-phase"
                        data-tooltip-content={item2[item3.key]}
                      >
                        {_.truncate(item2[item3.key], { length: 25 })}
                      </span>

                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <Tooltip id="project-phase" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default WoDefect;
