import React from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';

const TRDHistory = ({ history }) => {
  return (
    <div>
      <table className="card-table">
        <tr>
          <th>Events</th>
          <th>Day(s)</th>
          <th>Time Stamp</th>
          <th>Updated By</th>
        </tr>
        {history?.map((item) => {
          return (
            <tr>
              <td>{item.event}</td>
              <td>{item.repairDays}</td>
              <td>{moment.utc(item.updatedAt).format('DD-MMM-YYYY - HH:mm (UTC)')}</td>
              <td className="d-flex">
                {item.userName}
                <span
                  className="ps-2 truncate"
                  data-tooltip-id="project-TRD"
                  data-tooltip-content={item.updatedBy}
                >
                  (
                  {item.updatedBy}
                  )
                </span>
                <Tooltip id="project-TRD" place="top" className="tooltip mg-left" />
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default TRDHistory;
