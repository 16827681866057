export const AccordList = [{
  tabkey: 'communicationInfo',
  tabLabel: 'Communication',
  items: [
    { key: 'callSign', label: 'Call Sign :' },
    { key: 'ccr', label: 'CCR :' },
    { key: 'satNo', label: 'Sat-C No :' },
    { key: 'satEmail', label: 'Sat-C Mail ID :' },
    { key: 'phone', label: 'Phone Number :', type: 'number' },
    { key: 'nbdp', label: 'NBDP :' },
    { key: 'mobile', label: 'Mobile No :' },
    { key: 'mmsiNo', label: 'MMSI No :' },
    { key: 'fax', label: 'Fax No :' },
    { key: 'email', label: 'E-Mail ID :' },
    { key: 'vdGmailId', label: 'VDG :' }

  ]
},
{
  tabkey: 'dimension',
  tabLabel: 'Dimension',
  items: [
    { key: 'bootTopArea', label: 'Boot Top Area :' },
    { key: 'depth', label: 'Depth :' },
    { key: 'breadth', label: 'Breadth(Moulded) :' },
    { key: 'designDraft', label: 'Design Draft :' },
    { key: 'flatBottomArea', label: 'Flat Bottom Area :' },
    { key: 'lba', label: 'LBP :' },
    { key: 'length', label: 'Length :' },
    { key: 'loa', label: 'LOA :' },
    { key: 'topSideArea', label: 'TopSide Area :' },
    { key: 'verticalArea', label: 'Vertical Area :' }
  ]
},
{
  tabkey: 'tonnage',
  tabLabel: 'Tonnage',
  items: [
    { key: 'designDraft', label: 'Design Draft :' },
    { key: 'grossTonnage', label: 'Gross Tonnage :' },
    { key: 'netTonnage', label: 'Net Tonnage :' },
    { key: 'panamaCanalNetTonnage', label: 'Panama Canal Net Tonnage :' },
    { key: 'suezCanalTonnage', label: 'Suez Canal Tonnage :' },
    { key: 'summerDeadWeight', label: 'Summer Dead Weight :' }
  ]
},
{
  tabkey: 'loadlineInfo',
  tabLabel: 'Loadline Information',
  items: [
    { key: 'sumerDraft', label: 'Summer Draft :' },
    { key: 'winterDraft', label: 'Winter Draft :' },
    { key: 'tropicalDraft', label: 'Tropical Draft :' },
    { key: 'winterNorthAtlanticDraft', label: 'Winter North Atlantic Draft :' }
  ]
},
{
  tabkey: 'machineryAndEms',
  tabLabel: 'Machinery And EMS',
  items: [
    { key: 'ecdisMaker', label: 'ECDIS Maker :' },
    { key: 'ecdisModel', label: 'ECDIS Model :' },
    { key: 'meMaker', label: 'Main Engine - Maker :' },
    { key: 'meModel', label: 'Main Engine - Model :' },
    { key: 'meRpm', label: 'ME Rpm (MCR) :' },
    { key: 'mePowerKw', label: 'ME Power (MCR) (KW) :' },
    { key: 'mePowerHp', label: 'ME Power (MCR) (HP) :' },
    { key: 'mePowerBhp', label: 'ME Power (MCR) (BHP) :' },
    { key: 'areAllAuxEnginesSameMakerAndModel', label: 'Are all Aux. Engines are same Maker and Model :' },
    { key: 'meSfoc', label: 'ME SFOC :' },
    { key: 'meSumpCapacity', label: 'ME sump Capacity (100%) :' },
    { key: 'aeMaker', label: 'Aux. Engine - Maker :' },
    { key: 'aeModel', label: 'Aux. Engine - Model :' },
    { key: 'aeSfoc', label: 'AE SFOC @ 50% load :' },
    { key: 'aeSump', label: 'Aux. Engine Sump Capacity (100%) :' },
    { key: 'applicableAeNOxTier', label: 'Applicable AE NOx Tier (I, II, III, I&II, II&III) :' },
    // { key: 'auxModel', label: 'auxModel' },
    { key: 'boilerSfoc', label: 'Boiler SFOC :' },
    { key: 'bwtsMake', label: 'BWTS Make :' },
    { key: 'bwtsModel', label: 'BWTS Model :' },
    { key: 'eediNumber', label: 'EEDI Number :' },
    { key: 'eexiNumber', label: 'EEXI Number :' },
    { key: 'egcsApplicableTo', label: 'EGCS Applicable To (ME, AE, ME & AE, ME & AE & Boiler) :' },
    { key: 'egcsType', label: 'EGCS Type (open/closed or hybrid) :' },
    { key: 'equipmentForNOxIIIControl', label: 'Equipment for NOx III control (SCR, EGR, others) :' },
    { key: 'frammoEnginePowerKw', label: 'Frammo Engine Power :' },
    { key: 'frammoEngineSfoc', label: 'Frammo Engine SFOC :' },
    { key: 'iceClass', label: 'Ice class (NA, 1A super, 1A, 1B, 1C, other) :' },
    { key: 'igGeneratorSfoc', label: 'SFOC of IG generator :' },
    { key: 'isBwtsPresent', label: 'BWTS present (Yes/No) :', comp: 'dropdown', optKey: 'bool' },
    { key: 'isEgcsPresent', label: 'EGCS Present (Yes/No) :', comp: 'dropdown', optKey: 'bool' },
    { key: 'isEplApplicable', label: 'EPL applicable (Yes/No) :', comp: 'dropdown', optKey: 'bool' },
    { key: 'isFrammoEnginesPreset', label: 'Frammo engines preset  (Yes/No) :', comp: 'dropdown', optKey: 'bool' },
    { key: 'isInertGasGeneratorPresent', label: 'Inert Gas Generator present (Yes/No) :', comp: 'dropdown', optKey: 'bool' },
    { key: 'isShorePowerColdIroninigApplicable', label: 'Shore power/Cold Ironinig applicable (Yes/No) :', comp: 'dropdown', optKey: 'bool' },
    { key: 'meApplicableNOxTier', label: 'Applicable ME NOx Tier (I, II, III, I&II, II&III) :' },
    { key: 'meEquipmentForNOxIIIControl', label: 'Equipment For NOx III Control (SCR, EGR, Others) :' },
    { key: 'noOfAuxEngines', label: 'No. of Aux Engines :' },
    { key: 'numberOfAuxiliaryBoiler', label: 'Number Of Auxiliary Boiler :' },
    { key: 'numberOfBwts', label: 'Number Of BWTS :' },
    { key: 'numberOfFrammoEnignes', label: 'Number Of Frammo Enignes :' },
    { key: 'reducedMePowerKw', label: 'Reduced ME Power(KW) :' },
    { key: 'reducedMeSfoc', label: 'Reduced ME SFOC :' },
    { key: 'scocOfCylinderOil', label: 'SCOC Of Cylinder Oil(g/kwh) :' }
  ]
},
{
  tabkey: 'ownershipInfo',
  tabLabel: 'Ownership & Operators',
  items: [
    { key: 'charter', label: 'Charterer :', comp: 'dropdown', optKey: 'charter' },
    { key: 'docCompany', label: 'DOC Company :', comp: 'dropdown', optKey: 'docCompany' },
    { key: 'groupOwner', label: 'Group Owner :' },
    { key: 'registeredOwner', label: 'Registered Owner :', comp: 'dropdown', optKey: 'registeredOwner' },
    { key: 'subCharter', label: 'Sub Charterer :', comp: 'dropdown', optKey: 'charter' },
    { key: 'subSubCharter', label: 'Sub Sub Charterer :', comp: 'dropdown', optKey: 'charter' },
    { key: 'tradingArea', label: 'Trading Area :', comp: 'dropdown', optKey: 'tradingArea' },
    { key: 'docAddress', label: 'DOC Address :' }
  ]
},
{
  tabkey: 'cargoBallast',
  tabLabel: 'Cargo & Ballast',
  items: [
    { key: 'baleCapacity', label: 'Bale Capacity :' },
    { key: 'ballastCapacity', label: 'Ballast Capacity :' },
    { key: 'ceuCapacity', label: 'CEU Capacity :' },
    { key: 'gasCapacity', label: 'Gas Capacity:' },
    { key: 'grainCapacity', label: 'Grain Capacity :' },
    { key: 'liquidCapacity', label: 'Liquid Capacity :' },
    { key: 'teuCapacity', label: 'TEU Capacity :' }
  ]
},
{
  tabkey: 'manningSection',
  tabLabel: 'Manning Section',
  items: [
    { key: 'minimumManning', label: 'Minimum Manning :' },
    { key: 'nationalityOfficers', label: 'Nationality (Officers) :' },
    { key: 'nationalityRating', label: 'Nationality (Ratings) :' },
    { key: 'manningDesignComplement', label: 'Manning Design Complement :' },
    { key: 'lifeBoatCapacity', label: 'Life Boat Capacity :' }
  ]
}
];
export const generalInfo = [{
  label: 'IMO NO :',
  key: 'imoNo'
},
{
  label: 'Keel laid Date :',
  type: 'date',
  key: 'keelLaidDate'
},
{
  label: 'Vessel Code :',
  key: 'vesselCode'
},
{
  label: 'Launched Date :',
  type: 'date',
  key: 'launchedDate'
},
{
  label: 'Management :',
  key: 'managementGroup'
},
{
  label: 'Delivery Date :',
  type: 'date',
  key: 'deliveryDate'
},
{
  label: 'Port of Registry :',
  key: 'portOfRegistry',
  type: 'flag',
  comp: 'dropdown',
  optKey: 'port'
},
{
  label: 'Takeover Date :',
  type: 'date',
  key: 'takeoverDate'
},
{
  label: 'Group Owner :',
  key: 'groupOwner'
},
{
  label: 'Handedover Date :',
  type: 'date',
  key: 'handoverDate'
},
{
  label: 'Vessel Type :',
  key: 'vesselType',
  comp: 'dropdown',
  optKey: 'vesselType'
},
{
  label: 'Vessel Previous Name :',
  key: 'previousName'
},
{
  label: 'Vessel Subtype :',
  key: 'vesselSubType',
  comp: 'dropdown',
  optKey: 'port'
},
{
  label: 'Hull No :',
  key: 'hullNo'
},
{
  label: 'Date of Name Change :',
  type: 'date',
  key: 'dateOfNameChange'
},
{
  label: 'Anniversary Date :',
  type: 'date',
  key: 'anniversaryDate'
},
{
  label: 'Official No :',
  key: 'officialNo'
},
{
  label: 'Last Docking Date :',
  type: 'date',
  key: 'previousDdDate'
},
{
  label: 'Built Date :',
  type: 'date',
  key: 'builtDate'
},
{
  label: 'Status Change Date :',
  type: 'date',
  key: 'statusChangedDate'
}
];
export const classfication = {
  tabkey: 'classification',
  tabLabel: 'Classification',
  items: [
    { key: 'vesselClass', label: 'Class:' },
    { key: 'classNo', label: 'Class No:' },
    { key: 'classNotation', label: 'Class Notation:' }
  ]
};
export const ddList = {
  bool: ['Yes', 'No'],
  port: ['chennai'],
  tradingArea: ['Japan'],
  registeredOwner: ['Japan'],
  docCompany: ['Tata'],
  flag: ['78', '9'],
  charter: [
    'Tata Charter',
    'Sub Sub',
    'Sub',
    'Head'
  ]
};
