/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import userImg from '../../../../images/user-placeholder.jpg';
import SidePanel from '../../../common/sidePanel';
import { GetAuditLogUrl } from '../services';
import Loader from '../../../common/loader';
import Pagination from '../../../common/pagination';

const Audit = (props) => {
  const { setAuditLog, AuditLog } = props;
  const { id } = useParams();
  const [AuditData, setAuditData] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const searchInputRef = useRef(null);

  const AuditLogData = (page, searchData = '') => {
    GetAuditLogUrl({
      page: page || currentpage,
      pageSize,
      id,
      search: searchData === 'search' ? '' : searchText,
    }).then((res) => {
      if (res.success) {
        setAuditData(res.data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    AuditLogData();
    setLoading(true);
  }, [currentpage, pageSize]);

  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };

  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      AuditLogData(1);
      setCurrentpage(1);
    }
  };

  return (
    <div data-testid="audit-logs">
      <SidePanel
        onClose={() => {
          setAuditLog(false);
        }}
        title={
          <div className="d-flex align-items-center sidepanel-contact">
            <div>Audit Log</div>
            <div className="couter-bubbles status-due ms-3">{AuditData?.totalItems}</div>
          </div>
        }
        size="medium"
        showView={AuditLog}
        className="sidebar"
      >
        <div className="d-flex col-gap-1 half-width spec-search mb-5">
          <div className="search col-3 large">
            <div className="search col-3 large">
              <div className="d-flex justify-content-end">
                <span
                  className={searchText ? ' icon-close-line ' : ''}
                  onClick={() => {
                    setSearchText('');
                    searchInputRef.current.focus();
                    AuditLogData(currentpage, 'search');
                  }}
                />
                <span
                  className="icon-search"
                  data-test="click2"
                  id="searchEnter"
                  onClick={() => {
                    onSearch({ key: 'Enter' });
                  }}
                />
              </div>
              <input
                type="text"
                id="search"
                className="text-box large  with-close"
                value={searchText}
                ref={searchInputRef}
                placeholder="Search by Spec Number, Spec Name"
                onKeyPress={onSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="scroll-wrap mt-2">
            {AuditData?.items?.length < 1 ? (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">No data found</div>
              </div>
            ) : (
              <div>
                {AuditData?.items?.map((item) => {
                  let btnClass;
                  switch (item.action) {
                    case 'CREATE':
                      btnClass = 'audit-status create';
                      break;
                    case 'UPDATE':
                      btnClass = 'audit-status update';
                      break;
                    case 'DELETE':
                      btnClass = 'audit-status delete';
                      break;
                    default:
                      btnClass = 'hello';
                  }
                  return (
                    <div className="mb-3 audit-row">
                      <div className="profile-pic">
                        <div className="auidt-img">{item.userImage ? <img src={item.userImage} alt="" /> : <img src={userImg} alt="" />}</div>
                      </div>
                      <div className="content">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex col-gap-1">
                            <h3 className="head-14">{item.userName}</h3>
                            <div className={btnClass}>{item.action}</div>
                          </div>
                          <div className="">{moment.utc(item?.actionDate.split('.')[0]).format('DD-MMM-YYYY HH:mm')} (UTC)</div>
                        </div>
                        <div className="audit-msg mt-2">
                          <div className="head-12 audit-wrap">{item.message}</div>
                          <div className="d-flex mt-3 audit-wrap">{item.action === 'DELETE' ? item.oldData : item.newData}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="fixed-footer card">
                  <div className="container">
                    {AuditData?.totalItems > 10 && (
                      <Pagination
                        pageClick={pageClick}
                        pageCount={AuditData.totalPages}
                        currentpage={currentpage}
                        onSizeChange={onSizeChange}
                        size={pageSize}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </SidePanel>
    </div>
  );
};

export default Audit;
