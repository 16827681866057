/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { CloseShareDocument } from '../services';
import { useParams } from 'react-router-dom';
import SidePanel from '../../../../../common/sidePanel';
import { SendMail } from '../../../Report/services';
import showAlert from '../../../../../../utils/alert';
import Loader from '../../../../../common/loader';
import { ReactMultiEmail } from 'react-multi-email';
import helper from '../../../../../../utils/helper';

const ShareProject = ({ shareReport, setShareReport }) => {
  const [data, setData] = useState('');
  const {  ValidateEmail } = helper;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // const [emailInput, setEmailInput] = useState([]);
  // const [isValidEmailType, setIsValidEmailType] = useState(false);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);

  const ShareCloseReports = () => {
    CloseShareDocument({ projectId: id }).then((res) => {
      if (res.success) {
        setData(res.data);
        setLoading(false);
      }
      if (res.data && res.data.reportLink) {
        res.data.reportLink = res.data.reportLink.replace('http://', 'https://');
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    ShareCloseReports();
  }, []);

  
  /* istanbul ignore next */
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };
  
  /* istanbul ignore next */
  const mailSend = () => {
    if (!emails.length || !emails[0]) {
      showAlert('Please provide an email address.', 'error');
      return;
    }
    if (!emails.every(isValidEmail)) {
      showAlert('Please enter a valid email format', 'error');
      return;
    }
    const payload = {
      email: emails,
      mailbody: data.mailHeader + '<br>' + data.reportLink + '<br><br>' + data.mailFooter,
      subject: data.subject,
    };
    showAlert('Successfully sent completion report', 'success');
    SendMail(payload)
      .then((res) => {
        if (res.success) {
        }
      })
      .catch((error) => {
        showAlert('Error sending message', 'error');
      });
    if (!data) {
      showAlert('Data is not available', 'error');
      return;
    }
  };
  const checkEmail = (e) => {
    if (ValidateEmail(e)) {
      return true;
    }
    showAlert('Invalid email Id will be discarded', 'error');
    return false;
  };
  return (
    <div>
      <div data-testid="PhotoReportHistory" className="row">
        <SidePanel
          title="Send Completion Report"
          showView={shareReport}
          disabled
          size="large"
          bgColor="sky-white"
          onClose={() => {
            setShareReport(false);
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="side-panel__container bg-white specify-side">
              <div className="side-panel__content">
                <div className="mb-3">
                  <div className="head-14 d-flex pb-3">
                    <span className='mt-2'>To</span>
                    <span className="ms-1 mt-2">:</span>
                    <div className="share-multi-email share-multi">
                      <ReactMultiEmail
                        placeholder="Enter email here..."
                        className=""
                        emails={emails}
                        validateEmail={(e) => checkEmail(e)}
                        onChange={(_emails) => {
                          setEmails(_emails);
                        }}
                        autoFocus={true}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        onKeyDown={(evt) => {
                          console.log(evt);
                        }}
                        onKeyUp={(evt) => {
                          console.log(evt);
                        }}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              <div data-tag-item>{email}</div>
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                        onChangeInput={(value) => {
                          console.log(value);
                        }}
                      />
                    </div>

                    {/* <input
                      type="email"
                      className="bottom-line-input"
                      placeholder="Enter email here..."
                      value={emailInput.join(',')}
                      onChange={(e) => {setEmailInput(e.target.value.split(/[,; ]+/))}
                    }
                      // title=""
                    /> */}
                  </div>
                  <div className="head-12 mt-2">
                    <ul className="subject-margin">
                      Subject : <span className="custom-underline">{data?.subject}</span>
                    </ul>
                  </div>
                  <div className="card mt-3 py-5 px-5 card-share bg-sky-white">
                    <div className="head-12" dangerouslySetInnerHTML={{ __html: data?.mailHeader }}></div>
                    <div className="head-12 report-link break-all brand-color">
                      <a href={data.reportLink}>{data.reportLink}</a>
                    </div>
                    <div className="head-12 mt-4 " dangerouslySetInnerHTML={{ __html: data?.mailFooter }}></div>
                  </div>
                </div>
                <footer className="side-panel__footer side-wid" data-testid="EmailPreview">
                  <button type="button" className="secondary-btn me-4 large" onClick={() => setShareReport(false)}>
                    Close
                  </button>
                  <button
                    type="button"
                    className="primary-btn me-4 large"
                    onClick={() => {
                      mailSend();
                      if (emails.length && emails.every(isValidEmail)) {
                        setShareReport(false);
                      }
                    }}
                  >
                    Send
                  </button>
                </footer>
              </div>
            </div>
          )}
        </SidePanel>
      </div>
    </div>
  );
};

export default ShareProject;
