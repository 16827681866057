import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import GoogleMapReact from 'google-map-react';
import { truncate } from 'lodash';
import image from '../../../../../../images/loc-marker.svg';
import showAlert from '../../../../../../utils/alert';
import YardConfirm from './yardConfirm';
import helper from '../../../../../../utils/helper';

const SelectYard = (props) => {
  const { yardList, onSave, saveDisable, closeModal, shortListedYard = [], setShortListedYard,
    setSelectedYard, selectedYard, sendedYardList, yardPage, emailDetails, days, setDays,
    setYardPage, listLoading, buttonName, closeModalBtn, slotDate, setSlotDate,
    confirmYard } = props;
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const isTSI = (userRole === roles.AUTHOR) || (userRole === roles.EDITOR);
  const [yardSearch, setYardSearch] = useState('');
  const markerClick = (e) => {
    const shortListYard = [...shortListedYard];
    if (selectedYard.indexOf(e.data.id) > -1) {
      showAlert('Already selected', 'warning');
    } else if (sendedYardList.indexOf(e.data.id) > -1) {
      showAlert('Already sent', 'warning');
    } else {
      shortListYard.push(e.data);
      const selYard = selectedYard;
      selYard.push(e.data.id);
      setSelectedYard(selYard);
      showAlert(`${e.data.yardName} Selected`, 'success');
    }
    setShortListedYard(shortListYard);
  };
  const onYardSelect = (id) => {
    const selYard = selectedYard;
    if (selectedYard.indexOf(id) > -1) {
      selYard.splice(selectedYard.indexOf(id), 1);
    } else {
      selYard.push(id);
    }
    setSelectedYard(selYard);
    const shortListYard = [...shortListedYard];
    setShortListedYard(shortListYard);
  };
  const createToolTip = (e) => {
    const { text } = e;
    const cross = ' x ';
    const start = '(';
    const end = ')';
    const aaa = e?.data?.dockTypeInfo?.map((hov) => {
      return hov.dockTypeName + start + hov.length + cross + hov.breadth + end;
    });
    return `${text} ${aaa?.length ? '-' : ''} ${aaa?.join(', ')}`;
  };
  const MarkerComponent = (e) => {
    const { data } = e;
    return (
      <div className="bg-red" id={data.id} onClick={() => { markerClick(e); }} title={createToolTip(e)}>
        <div><img alt="" src={image} /></div>
      </div>
    );
  };
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 1
  };
  const sendYardName = () => {
    const nameObject = yardList?.filter((names) => sendedYardList.indexOf(names.id) > -1);
    const nameArray = nameObject?.map((item) => item.yardName);
    return nameArray.join(', ');
  };

  if (yardPage === 1) {
    return (
      <div data-testid="gmap-yard">
        <div className="d-flex">
          <div style={{ height: '450px', width: '50%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyDrPH1j0YYeOXG9FJSQs1EjeU7shoQtJXE' }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              {yardList.map((item) => {
                return (
                  <MarkerComponent
                    lat={item.locationInfo.latitude}
                    lng={item.locationInfo.longitude}
                    text={item.yardName}
                    data={item}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
          <div className="ms-5" style={{ width: '50%' }}>
            <div className="border-up border-down py-2 head-12 my-3 d-flex justify-content-between align-items-center">
              <div className="label me-5">Yard List</div>
              <div className="ms-5">
                RFQ sent to
                <span className="bg-brand-color mx-2 px-2 white md-radius" title={sendYardName()}>{sendedYardList.length}</span>
                Yard(s)
              </div>
            </div>
            <div className="mb-3 w-full">
              <div className="search search-large spec-search">
                <div className="d-flex justify-content-end">
                  <span
                    id="rfqSent"
                    className={yardSearch ? ' icon-close-line top-0' : ''}
                    onClick={() => setYardSearch('')}
                  />
                  <span
                    className="icon-search"
                    id="searchEnter"
                  />
                </div>
                <input
                  type="text"
                  id="search"
                  value={yardSearch}
                  className="text-box medium pe-5"
                  placeholder="Search by Yard Name"
                  onChange={(e) => { setYardSearch(e.target.value); }}
                />
              </div>
            </div>
            {isTSI && <div className="red">Note: User can send RFQ to max 5 yards only.</div>}
            <div className="mt-4 scroll-wrap" style={{ height: '325px' }}>
              {yardList.filter((singleItem) => singleItem.yardName.toLowerCase()
                .includes(yardSearch.toLowerCase())).map((yard) => {
                if (sendedYardList.indexOf(yard.id) === -1) {
                  const isDisabled = (sendedYardList.length + selectedYard.length) > 4;
                  return (
                    <div className={selectedYard?.indexOf(yard.id) > -1 && isDisabled ? 'border p-3 my-2 bg-sky-blue sm-radius d-flex justify-content-between'
                      : 'border p-3 my-2 sm-radius d-flex justify-content-between'}
                    >
                      <div
                        className={isDisabled && selectedYard.indexOf(yard.id) === -1 && !isAdmin ? 'check-box ps-1 opacity-4 disabled' : 'check-box ps-1'}
                        id={yard.id}
                        onClick={() => {
                          if (isDisabled && selectedYard.indexOf(yard.id) === -1 && !isAdmin) {
                            //
                          } else {
                            onYardSelect(yard.id);
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          value={yard.id}
                          checked={selectedYard.indexOf(yard.id) > -1 ? yard.id : false}
                        />
                        <label
                          data-tooltip-id="project-line"
                          data-tooltip-content={yard.yardName}
                        >
                          {truncate(yard.yardName, { length: 60 })}

                        </label>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
              {
                yardList.map((sended) => {
                  if (sendedYardList.indexOf(sended.id) > -1) {
                    return (
                      <div className="border p-3 my-2 body-gray sm-radius d-flex justify-content-between">
                        <div className="check-box ps-1 viewmode" id={sended.id}>
                          <input type="checkbox" value={sended.id} checked />
                          <label>{sended.yardName}</label>
                        </div>
                        <div className="d-flex align-items-center green">
                          <span className="icon-check-rnd tick-green font-22 me-2" />
                          <span>Sent</span>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })
              }
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                className="secondary-btn large me-4"
                data-testid="cancel"
                onClick={closeModalBtn}
                type="submit"
                style={{ transition: 'all .15s ease' }}
              >
                Cancel
              </button>
              <button
                className="secondary-btn large me-4"
                data-testid="reset"
                onClick={() => { closeModal(); setYardSearch(''); }}
                type="submit"
                style={{ transition: 'all .15s ease' }}
              >
                Reset
              </button>
              <button
                className="primary-btn large"
                data-testid="discard"
                onClick={() => onSave()}
                disabled={saveDisable}
                type="submit"
              >
                {buttonName}
              </button>

            </div>

          </div>
          <Tooltip id="project-line" place="top" className="tooltip mt-3" />
        </div>
      </div>
    );
  }
  return (
    <YardConfirm
      shortListedYard={yardList}
      selectedYard={selectedYard}
      onYardSelect={onYardSelect}
      emailDetails={emailDetails}
      days={days}
      setDays={setDays}
      setYardPage={setYardPage}
      markerClick={markerClick}
      listLoading={listLoading}
      slotDate={slotDate}
      setSlotDate={setSlotDate}
      confirmYard={confirmYard}
    />
  );
};
export default SelectYard;
