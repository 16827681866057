import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ModalBox from '../../../common/ModalBox';
import AddReference from './AddReference';
import { deleteResourceWebLink, saveResourceWebLink, getResourceWebLink, bulkUploadResource, generatTemplate, exportExcelResourceLink } from './services';
import Pagination from '../../../common/pagination';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import CardWrapper from './cardWrapper';
import BulkUploadModal from '../../DataConfig/CurrencyContainer/bulkUploadModal';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const ResourceWebLinks = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const userRole = localStorage.getItem('USER_ROLE');
  const { formValidation, handleValidation, MAX_FILE_SIZE } = helper;
  const [showModal, setShowModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formData, setFormData] = useState({ url: '', description: '' });
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const reloadTable = (page) => {
    getResourceWebLink({
      page: page || currentpage,
      pageSize,
      search: searchText
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        setTableData(res.data);
        if (setCount) {
          setCount(res?.data?.totalItems || 0);
        }
      }
    });
  };
  useEffect(() => {
    reloadTable();
  }, [pageSize, currentpage]);
  const handleChange = (e, field) => {
    const resourceLink = { ...formData };
    resourceLink[e.target.name] = e.target.value;
    setFormData(resourceLink);
    handleValidation(['url', 'description'], resourceLink, field);
  };
  const editData = (data) => {
    setFormData(data);
    setShowModal(true);
  };
  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    deleteResourceWebLink({ id: formData.id }).then((res) => {
      if (res.success) {
        setFormData({ url: '', description: '' });
        reloadTable();
        showAlert('Successfully Deleted', 'success');
      }
    });
    setOpenDeleteModal(false);
  };
  /* istanbul ignore next */
  const saveApiCall = () => {
    if (formValidation(['description', 'url'], formData)) {
      saveResourceWebLink(formData).then((res) => {
        if (res.success) {
          setShowModal(false);
          reloadTable();
          setFormData({ url: '', description: '' });
          if (formData.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const handleExportExcelResource = () => {
    if (tableData?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelResourceLink(searchText);
    }
  };
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadResource(body).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setIsUpload(true);
    setFile();
  };
  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div className="container" data-testid="resource-link">
      <div className="d-flex my-4 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search">
          <div className="search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span className="icon-search" onClick={() => { onSearch({ key: 'Enter' }); }} />
            </div>
            <input
              value={searchText}
              type="text"
              className="text-box large with-close"
              placeholder="Search by Url, Description"
              onKeyPress={onSearch}
              id="search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          {userRole === 'Admin' ? <button type="button" id="add-link" className="primary-btn large" onClick={() => { setShowModal(true); }}>Add Web Links </button> : ''}
          <button
            type="button"
            id="add-name"
            className="secondary-btn large"
            onClick={() => {
              setUploadModal(true);
            }}
          >
            Bulk Upload
          </button>
          {uploadModal && (
          <ModalBox
            modalClassName="medium"
            heading="Upload Excel"
            onSave={() => { bulkUpload(); }}
            closeModal={() => onCloseModal()}
            buttonName="Upload"
            saveDisable={isUpload}
          >
            <BulkUploadModal
              file={file}
              ondrop={ondrop}
              setFile={setFile}
              generatTemplateForExcel={generatTemplateForExcel}
              name="Resource Web Link"
              setIsUpload={setIsUpload}
            />
          </ModalBox>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <button type="button" id="excel" className="link-btn large " onClick={handleExportExcelResource}>
            <span className="icon-download me-4" />
            Excel Download
          </button>
        </div>

      </div>
      <div className="scorll-body-wrap">
        <CardWrapper
          tableData={tableData}
          editData={editData}
          deteleData={deteleData}
          onSearch={onSearch}
          userRole={userRole}
          loading={loading}
        />
        {tableData?.totalItems > 10 && (
        <Pagination
          pageClick={pageClick}
          pageCount={tableData.totalPages}
          currentpage={currentpage}
          records={tableData.totalItems}
          size={pageSize}
          onSizeChange={onSizeChange}
        />
        )}
      </div>

      {showModal
        && (
        <ModalBox
          heading={formData?.id ? 'Update Reference Web Links' : 'Add Reference Web Links'}
          closeModal={() => { setShowModal(false); setFormData({ url: '', description: '' }); }}
          onSave={saveApiCall}
          buttonName="Save"
        >
          <AddReference handleChange={handleChange} formData={formData} />
        </ModalBox>
        )}
      {openDeleteModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete Reference Web Link details ?</p>
        </ModalBox>
      ) : (
        ''
      )}
      {userRole === 'Admin' ? (
        <div className="non-sticky-footer">
          <div className="container">
            <div className="d-flex justify-content-between font-13 mx-0 my-2">
              <PolicyFooter
                setShowPrivacyPolicy={setShowPrivacyPolicy}
                setShowTermsPolicy={setShowTermsPolicy}
              />
            </div>
          </div>

        </div>
      ) : (
        <div className="footer justify-content-between font-13">
          <div className="copy-text m-auto">
            © 2024 ThinkPalm
          </div>

        </div>
      )}

      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </div>
  );
};
export default ResourceWebLinks;
