import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import { s3UploadMutiple } from '../../../NewProject/services';
// import InputType1 from '../../../../../common/InputType1';

const Addattachment = (props) => {
  const { specList, setSpecList, isFromYard, params } = props;
  const { MAX_FILE_SIZE } = helper;
  const [list, setList] = useState([]);
  const isYard = window.location.pathname.includes('yardquotation');
  const acceptedFiles = ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff',
    '.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  useEffect(() => {
    const attach = _.cloneDeep(specList);
    if (attach.rfqData?.attachments && attach.rfqData?.attachments.length) {
      //
    } else {
      attach.rfqData?.attachments.push({
        docType: 1,
        documents: []
      });
      attach.rfqData?.attachments.push({
        docType: 2,
        documents: []
      });
      setSpecList(attach);
    }
    let lists = [];
    if (isYard) {
      lists = attach.rfqData?.attachments[0]?.documents || [];
    } else {
      lists = attach.rfqData?.attachments[1]?.documents || [];
    }
    setList(lists);
  }, [specList]);
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = specList?.rfqData?.attachments?.map((items) => { return items.name; }) || [];
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body, isFromYard, params);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(specList);
          console.log('attach', attach);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fileName: item.name,
              path: item.path,
              fileType: item.type,
              type: item.type,
              fileSize: item.size
            };
            if (isYard) {
              attach.rfqData?.attachments[0].documents.push(newItem);
            } else {
              attach.rfqData?.attachments[1].documents.push(newItem);
            }

            // if (attach.rfqData.attachments) {
            // } else {
            //   attach.rfqData?.attachments = [newItem];
            // }
            return null;
          });
          setSpecList(attach);
          let lists = [];
          if (isYard) {
            lists = attach.rfqData?.attachments[0]?.documents || [];
          } else {
            lists = attach.rfqData?.attachments[1]?.documents || [];
          }
          setList(lists);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };

  const deleteFile = (key) => {
    const attachment = { ...specList };
    if (isYard) {
      attachment.rfqData?.attachments[0].documents.splice(key, 1);
    } else {
      attachment.rfqData?.attachments[1].documents.splice(key, 1);
    }
    setSpecList(attachment);
  };
  // eslint-disable-next-line no-unused-vars
  const setType = (value) => {
    // const attach = _.cloneDeep(specList);
    // attach.rfqData?.attachments.push(newItem);
    // setSpecList(attach);
  };

  return (
    <>
      <div data-testid="Add-attachments">
        {/* <InputType1 onChange={(e) => setType(e.target.value)} /> */}
        <div className="drag-drop-file my-4 file-added">
          <div className="icon-buble mb-5">
            <span className="icon-file-text2" />
          </div>
          <input className="p-5 align-self-center" id="upload" type="file" name="name" multiple onChange={(e) => { ondrop(e); }} isMandatory="true" />
          <p>
            Choose a file or drag it here(jpeg, jpg, png, tiff, .jfif,
            pdf, doc, .xlsx - max size(5MB))
          </p>
        </div>
        <div className="attached-file-multi">
          {list.map((item, key) => {
            return (
              <>
                <div className="d-flex item">
                  <span className="icon-check-rnd me-2 file-added-multi font-20"> </span>
                  <div
                    data-tooltip-id="project-edit"
                    data-tooltip-content={item.name}
                  >
                    {_.truncate(item.name, {
                      length: 50,
                      separator: ' '
                    })}
                  </div>
                  <button type="button" id="delete" className="link-btn clear-file" onClick={() => { deleteFile(key); }}>
                    <span className="icon-delete delete-icon-multi ms-4 font-20 "> </span>
                  </button>
                </div>
                <Tooltip id="project-edit" place="top" className="tooltip mt-3" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Addattachment;
