/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { cloneDeep, toNumber } from 'lodash';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { saveMajorSpecs, getMajorSpecs, getUomList, getTags, publishList, getSearchResults, bulkUploadgeneralTarriff, bulkUploadMajorSpec, generatTemplate, exportExcelGeneralTariff, getEmptySpecListAdmin } from './services';
import showAlert from '../../../../../utils/alert';
import helper from '../../../../../utils/helper';
import ModalBox from '../../../../common/ModalBox';
import TableOne from './TableOne';
import { getSpecificationStatus } from '../GeneralTariff/services';
import EmptyTable from './EmptyTable';
import BulkUploadModal from '../../CurrencyContainer/bulkUploadModal';
import ServiceLineModal from './ServiceLineModal';
import Loader from '../../../../common/loader';

const MajorSpecs = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';

  const navigate = useNavigate();
  const location = window.location.pathname;
  const { SpecificationType, SpecificationLevel, ZERO_UUID, generateOptions,
    charValidation,
    formValidation,
    handleFormValidation,
    MAX_FILE_SIZE
  } = helper;
  let SpecificationTypeNumber = SpecificationType.MajorSpecs;
  if (location.includes('/admin/data/spec/general')) {
    SpecificationTypeNumber = SpecificationType.GeneralTariff;
  }
  const [expandConfig, setExpandConfig] = useState();
  const [isAddNewExist, setIsAddNewExist] = useState(false);
  const [uomList, setUomList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [editIndex, setEditIndex] = useState();
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [specificationStatus, setSpecificationStatus] = useState({
    isPublished: false,
    publishedDate: ''
  });
  const [subElementId, setSubElementId] = useState('');
  const [searchTextEnter, setSearchTextEnter] = useState('');
  const [searchText, setSearchText] = useState('');
  const [searchRData, setSearchRData] = useState([]);
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceLineShowModalGT, setServiceLineShowModalGT] = useState(false);
  const [serviceLineShowModalMT, setServiceLineShowModalMT] = useState(false);
  const [emptySpecList, setEmptySpecList] = useState();
  const [emptyMajorSpecList, setEmptyMajorSpecList] = useState();
  const [continuebtn, setContinueBtn] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const initialData = [
    {
      isNew: true,
      specificationNo: '',
      title: '',
      description: '',
      specificationLevel: SpecificationLevel.Section,
      applicableVesselType: [],
      tags: [],
      type: SpecificationTypeNumber,
      uomName: '',
      quanityEnterBy: '',
      status: 0
    }
  ];

  const [formData, setFormData] = useState(initialData);

  const getEmptySpecificationApi = () => {
    getEmptySpecListAdmin(1).then((res) => {
      if (res.success) {
        setLoading(false);
        setEmptySpecList(res.data);
      }
    });
  };

  const reloadTable = (resetCollapse = '') => {
    getMajorSpecs(ZERO_UUID, SpecificationTypeNumber).then((res) => {
      setLoading(false);
      const c = res.data?.map((i) => {
        return {
          ...i,
          specificationNo: toNumber(i.specificationNo)
        };
      });
      setFormData(c);
      setCount(c?.length);
      if (resetCollapse === 'reset') {
        const expandedRows = c?.map((data, key) => {
          return false;
        });
        setExpandConfig(expandedRows);
      }
    });
  };

  const handleSaveScetion = (saveData) => {
    const formSave = cloneDeep(saveData);
    const spNo = String(saveData.specificationNo);
    formSave.specificationNo = (!spNo || spNo === '' || spNo === 'null' || spNo === 'NaN' || spNo === 'e') ? '' : spNo;
    if (formValidation(['specificationNo', 'title'], formSave)) {
      setLoadingSave(true);
      saveMajorSpecs(formSave).then((res) => {
        setLoadingSave(false);
        if (res.success) {
          getEmptySpecificationApi();
          reloadTable();
          if (formSave.id) {
            showAlert('Successfully Updated', 'success');
          } else {
            showAlert('Successfully Saved', 'success');
          }
          setIsAddNewExist(false);
          setEditIndex();
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
      handleFormValidation('specificationNo', formSave);
      handleFormValidation('title', formSave);
    }
  };
  const addSection = () => {
    setEditIndex();
    const temp = formData && formData.length ? cloneDeep(formData) : [];
    let num;
    let nextNum = null;
    if (SpecificationTypeNumber === SpecificationType.MajorSpecs) {
      num = temp.length ? temp[temp.length - 1].specificationNo : 100;
      if (temp.length) nextNum = num + 100;
    } else {
      num = temp.length ? temp[temp.length - 1].specificationNo : 99;
      if (temp.length) nextNum = num + 1;
    }
    temp.push({
      specificationNo: nextNum,
      isNew: true,
      title: '',
      description: '',
      specificationLevel: SpecificationLevel.Section,
      applicableVesselType: [],
      tags: [],
      type: SpecificationTypeNumber,
      uomName: '',
      quanityEnterBy: '',
      status: 0
    });
    setFormData(temp);
    setIsAddNewExist(true);
    const expandedRows = temp?.map((data, key) => {
      // if (data.isNew) return true;
      return false;
    });
    setExpandConfig(expandedRows);
  };
  const cancelAddNewSection = () => {
    const temp = cloneDeep(formData);
    temp.pop();
    setFormData(temp);
    setIsAddNewExist(false);
  };

  const handleTableChange = (e, field, saveData) => {
    const temp = cloneDeep(formData);
    temp.forEach((i, index) => {
      if (i.specificationNo === saveData.specificationNo) {
        if (field === 'specificationNo') {
          if (e.target.value > 0) {
            temp[index][field] = charValidation(e.target.value, 10);
          } else {
            temp[index][field] = '';
          }
        } else {
          temp[index][field] = charValidation(e.target.value, 500);
        }
      }
      handleFormValidation('specificationNo', temp[index]);
      handleFormValidation('title', temp[index]);
      handleFormValidation('quanityEnterBy', temp[index]);
    });
    setFormData(temp);
  };
  const editData = (row) => {
    setEditIndex(row.index);
  };
  const cancelEditSection = () => {
    reloadTable('reset');
    setEditIndex();
  };

  const showPagination = false;
  const setIsAddNewExistChange = (val) => {
    setIsAddNewExist(val);
  };
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile();
  };

  const bulkUploadGT = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadgeneralTarriff(body).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const bulkUploadMS = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadMajorSpec(body).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate({ type: SpecificationTypeNumber });
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };
  useEffect(() => {
    setLoading(true);
  }, []);
  useEffect(() => {
    reloadTable('reset');
    setEditIndex();
    setIsAddNewExist(false);
    getUomList({
      page: 1,
      pageSize: 100
    }).then((uom) => {
      if (uom.data) {
        setUomList(generateOptions(uom.data.items, 'uomId', 'uomName'));
      }
    });
    getTags().then((res) => {
      if (res.success) {
        setLoading(false);
        setTagList(generateOptions(res.data, 'id', 'name'));
      }
    });
    getSpecificationStatus(2).then((res) => {
      if (res?.data) setLoading(false);
      setSpecificationStatus(res?.data);
    });
    setSubElementId('');
    setSearchTextEnter('');
    setSearchText('');
    setSearchRData([]);
  }, [location]);

  const publishData = () => {
    getEmptySpecListAdmin(2).then((resp) => {
      if (resp.success) {
        setLoading(false);
        if (resp.data && resp.data.length) {
          setEmptyMajorSpecList(resp.data);
          setServiceLineShowModalGT(true);
        } else {
          setServiceLineShowModalGT(false);
          setOpenPublishModal(true);
        }
      }
    });
  };

  const publishApiCall = () => {
    publishList(2).then((res) => {
      if (res.success) {
        setLoading(false);
        getSpecificationStatus(2).then((specRes) => {
          if (specRes?.data) setSpecificationStatus(specRes?.data);
        });
        setOpenPublishModal(false);
        reloadTable();
        if (specificationStatus.isPublished) {
          showAlert('Successfully Republished', 'success');
        } else {
          showAlert('Successfully Published', 'success');
        }
      }
    });
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      const payload = {
        search: searchText,
        type: SpecificationTypeNumber
      };
      if (!searchText || searchText === null || searchText === '' || searchText === ' ') {
        setSearchRData([]);
        setSearchTextEnter('');
      } else {
        getSearchResults(payload).then((res) => {
          setLoading(false);
          setSearchRData(res.data);
          setSearchTextEnter('clicked');
        });
      }
      const quotesCopy = cloneDeep(formData);
      const expandedRows = quotesCopy?.map(() => {
        return false;
      });
      setExpandConfig(expandedRows);
      setSubElementId('');
    }
  };
  const handleSearchAfter = (a) => {
    setSearchTextEnter('');
    if (a && a.length && a[0]) {
      const element = document.getElementById(a[0]);
      if (element) {
        element.click();
        element?.scrollIntoView();
        setSearchRData([]);
        setSearchText('');
        if (a && a.length && a[1]) {
          setSubElementId(a[1]);
        }
      }
    }
  };
  const onClearSearch = () => {
    setSearchRData([]);
    setSearchText('');
    setSearchTextEnter('');
  };
  const handleExcelExportMajorSpec = () => {
    exportExcelGeneralTariff({ type: SpecificationTypeNumber });
  };

  const navigateFn = () => {
    if (SpecificationTypeNumber === SpecificationType.GeneralTariff) {
      navigate('/admin/data/spec/mapping-to-vessel/general');
    } else {
      publishData();
    }
  };
  useEffect(() => {
    getEmptySpecificationApi();
  }, []);
  const handleMapToVessel = () => {
    getEmptySpecListAdmin(1).then((res) => {
      if (res.success) {
        setLoading(false);
        setEmptySpecList(res.data);
        if (continuebtn || res.data?.length === 0) {
          navigateFn();
        } else {
          setServiceLineShowModalGT(true);
          setSearchRData([]);
          setSearchText('');
          setSearchTextEnter('');
          const quotesCopy = cloneDeep(formData);
          const expandedRows = quotesCopy?.map(() => {
            return false;
          });
          setExpandConfig(expandedRows);
          setSubElementId('');
        }
      }
    });
  };
  return (
    <div className="card-table-sec" data-testid="mt">
      {loading ? <Loader /> : (
        <div>
          <div className="d-flex justify-content-between">
            <div className="half-width d-flex col-gap-1 spec-search">
              <div className="col-9 large search spec-search">
                <div className="d-flex justify-content-end">
                  <span
                    className={searchText ? ' icon-close-line ' : ''}
                    onClick={() => {
                      onClearSearch();
                      document.getElementById('search')?.focus();
                    }}
                  />
                  <span
                    className="icon-search"
                    onClick={() => {
                      onSearch({ key: 'Enter' });
                    }}
                  />
                </div>
                <input
                  value={searchText}
                  type="text"
                  id="search"
                  className="text-box large with-close"
                  placeholder="Search by Sections, Specification, Service Lines"
                  onKeyPress={onSearch}
                  onChange={(e) => {
                    const v = e.target.value.replace(/\s\s+/g, ' ');
                    if (!v || v === null || v === '' || v === ' ') {
                      setSearchText('');
                    } else {
                      setSearchText(v);
                    }
                  }}
                />
              </div>
              <div>
                <button
                  type="button"
                  id="add-name"
                  className="secondary-btn large"
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  Bulk Upload
                </button>
                {uploadModal && (
                <ModalBox
                  modalClassName="medium"
                  heading="Upload Excel"
                  onSave={() => {
                    if (SpecificationTypeNumber === 1) {
                      bulkUploadGT();
                    } else { bulkUploadMS(); }
                  }}
                  closeModal={() => onCloseModal()}
                  buttonName="Upload"
                  saveDisable={isUpload}
                >
                  <BulkUploadModal
                    file={file}
                    ondrop={ondrop}
                    setFile={setFile}
                    generatTemplateForExcel={generatTemplateForExcel}
                    name={`${SpecificationTypeNumber === 1 ? 'General Tariff' : ' Major Spec'} `}
                    type="gtMt"
                    existingData
                  />
                </ModalBox>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                id="excel"
                className="link-btn large "
                onClick={handleExcelExportMajorSpec}
              >
                <span className="icon-download me-4" />
                Excel Download
              </button>
            </div>
          </div>

          <div className="row ">
            {searchTextEnter ? (
              <div className="col-6 spec-search-content">
                <div className="border-down p-2 font-bold">
                  <div>Search Results</div>
                </div>
                {searchRData?.length
                  ? (
                    <>
                      {searchRData.map((i) => {
                        const pSpec = i.parentSpecification || '';
                        const pSpecArray = pSpec.split('→');
                        const pSpecStr = i.parentTitle || '';
                        const pSpecStrArray = pSpecStr.split('→');
                        return (
                          <div
                            onClick={() => {
                              if (pSpecArray && pSpecArray.length && pSpecArray[0]) {
                                handleSearchAfter(pSpecArray);
                              } else if (i.specificationNo) {
                                handleSearchAfter([i.specificationNo]);
                              }
                            }}
                            className="border-down p-2 spec-search-list"
                          >
                            <div className="font-bold text-uppercase">{pSpecStrArray.length && pSpecStrArray[0] ? pSpecStrArray[0] : ''}</div>
                            <div className="ms-2">{pSpecStrArray.length && pSpecStrArray[1] ? pSpecStrArray[1] : ''}</div>
                            <div className="ms-4"><span className="spec-highlight p-1">{`${i.specificationNo} : ${i.title}`}</span></div>
                          </div>
                        );
                      })}
                    </>
                  )
                  : (
                    <div className="border-down p-2 spec-search-list d-flex align-items-lg-start">
                      <span className="font-18 font-bolds icon-Info me-2 rotate-180 text-black" />
                      <p>No records found</p>
                    </div>
                  )}

              </div>
            ) : <div />}
          </div>
          {formData?.length ? (
            <TableOne
              SpecificationType={SpecificationType}
              SpecificationLevel={SpecificationLevel}
              setFormData={setFormData}
              formData={formData}
              expandConfig={expandConfig}
              showPagination={showPagination}
              uomList={uomList}
              tagList={tagList}
              cancelAddNewSection={cancelAddNewSection}
              handleSaveScetion={handleSaveScetion}
              cancelEditSection={cancelEditSection}
              editData={editData}
              setExpandConfig={setExpandConfig}
              handleTableChange={handleTableChange}
              editIndex={editIndex}
              setIsAddNewExistChange={setIsAddNewExistChange}
              subElementId={subElementId}
              setSubElementId={setSubElementId}
              loading={loading}
              reloadTable={reloadTable}
              getEmptySpecificationApi={getEmptySpecificationApi}
              // deleteApiCall={deleteApiCall}
              loadingSave={loadingSave}
            />
          ) : (
            <EmptyTable handleSaveScetion={handleSaveScetion} />
          )}
          <div className="d-flex justify-content-between mt-4 align-items-center">
            {isAddNewExist ? (
              <button className="secondary-btn  disabled-field" type="button">
                <span className="icon-plus-square me-2" />
                Add New Section
              </button>
            ) : (
              <button id="add-section" className="secondary-btn" type="button" onClick={() => addSection()}>
                <span className="icon-plus-square me-2" />
                Add New Section
              </button>
            )}
            {SpecificationTypeNumber === SpecificationType.GeneralTariff && formData?.length ? (
              <div className="d-flex justify-content-end">
                <button onClick={() => handleMapToVessel()} className="primary-btn  mt-2 mb-2" type="button">
                  Map to Vessel Category
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-between mt-4 align-items-center">
                {specificationStatus.isPublished && SpecificationTypeNumber === 2 ? (
                  <div className="footer-btn d-flex justify-content-end">
                    <div className="value font-14 px-2">{`Last published date : ${moment(specificationStatus.publishedDate).format('DD-MMM-YYYY')}`}</div>
                  </div>
                ) : (
                  ''
                )}
                <div className="footer-btn d-flex justify-content-end">
                  {formData?.length ? (
                    <button onClick={() => publishData()} type="button" className="primary-btn ">
                      {specificationStatus.isPublished ? 'Republish' : 'Publish'}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </div>
          {openPublishModal ? (
            <ModalBox
              modalClassName="medium"
              onSave={() => publishApiCall()}
              closeModal={() => setOpenPublishModal(false)}
              heading={specificationStatus.isPublished ? 'Republish' : 'Publish'}
              buttonName={specificationStatus.isPublished ? 'Republish' : 'Publish'}
            >
              {specificationStatus.isPublished ? (
                <p>Are you sure you want to republish? Once republished, the mapped data will be available for application users.</p>
              ) : (
                <p>Are you sure you want to publish? Once published, the mapped data will be available for application users.</p>
              )}
            </ModalBox>
          ) : (
            ''
          )}
          {serviceLineShowModalGT && (
          <ServiceLineModal
            setServiceLineShowModalGT={setServiceLineShowModalGT}
            navigateFn={navigateFn}
            emptySpecList={emptySpecList}
            emptyMajorSpecList={emptyMajorSpecList}
            setContinueBtn={setContinueBtn}
            handleSearchAfter={handleSearchAfter}
            SpecificationTypeNumber={SpecificationTypeNumber}
          />
          )}
        </div>
      )}

    </div>
  );
};
export default MajorSpecs;
