/* istanbul ignore file */
/* eslint-disable max-len */
/* istanbul ignore file */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import helper from '../../../../utils/helper';
import 'react-tooltip/dist/react-tooltip.css';
import Pagination from '../../../common/pagination';
import Loader2 from '../../../common/loader2';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const ProjectList = (props) => {
  const { projectPhase, roles, formatNumber } = helper;
  const { projects, savePin, setCurrentpage, setPageSize, currentpage, pageSize, loading } = props;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isMaster = userRole === roles.VESSEL_USER;
  const isCreater = (userRole === roles.AUTHOR) || (userRole === roles.EDITOR);
  const navigate = useNavigate();
  const handleProjectDetailUrl = (item) => {
    // else if (!isMaster && (item?.phaseId === projectPhase.PLAN || item?.projectStatus === 'Closed')) {
    //   if (!isCreater) {
    //     navigate(`/user/project/${item.id}/plan?vesselId=${item.vesselId}`);
    //   } else if (item?.planApprovalStatus !== 0 && item?.planApprovalStatus !== 3) {
    //     navigate(`/user/project/${item.id}/plan?vesselId=${item.vesselId}`);
    //   } else {
    //     navigate(`/user/projects/create?projectId=${item?.id}&vesselId=${item.vesselId}`, { state: { screenCompleted: item?.screenCompleted } });
    //   }
    // }
    if (item?.projectStatus === 'Closed' || item?.phaseId === projectPhase.PLAN) {
      if (!isCreater) {
        navigate(`/user/project/${item.id}/plan?vesselId=${item.vesselId}`);
      } else if (item?.planApprovalStatus !== 0 && item?.planApprovalStatus !== 3) {
        navigate(`/user/project/${item.id}/plan?vesselId=${item.vesselId}`);
      } else {
        navigate(`/user/projects/create?projectId=${item?.id}&vesselId=${item.vesselId}`, { state: { screenCompleted: item?.screenCompleted } });
      }
    } else if (item?.phaseId === projectPhase.SPECIFY) {
      navigate(`/user/project/${item.id}/specify?vesselId=${item.vesselId}`);
    } else if (item?.phaseId === projectPhase.TENDER && item?.tenderApprovalStatus !== 2) {
      if (isMaster) {
        navigate(`/user/project/${item.id}/tender?vesselId=${item.vesselId}`);
      } else if (item?.isCompareQuoteApproval && item?.isPendingApproval) {
        navigate(`/user/project/${item.id}/tender/compare-quotes?vesselId=${item.vesselId}`);
      } else if (userRole !== isCreater && item?.isCompareQuoteApproval && !item?.isPendingApproval) {
        navigate(`/user/project/${item.id}/tender?vesselId=${item.vesselId}`, { state: { from: 'Comparequotes' } });
      } else {
        navigate(`/user/project/${item.id}/tender?vesselId=${item.vesselId}`);
      }
    } else if (item?.phaseId === projectPhase.TENDER && item?.tenderApprovalStatus === 2) {
      navigate(`/user/project/${item.id}/repair?vesselId=${item.vesselId}`);
    } else if (item?.phaseId === projectPhase.REPAIR) {
      navigate(`/user/project/${item.id}/repair?vesselId=${item.vesselId}`);
    } else if (item?.phaseId === projectPhase.CLOSE) {
      if (isMaster) {
        navigate(`/user/project/${item.id}/repair?vesselId=${item.vesselId}`);
      } else {
        navigate(`/user/project/${item.id}/close?vesselId=${item.vesselId}`);
      }
    } else {
      navigate(`/user/project/${item.id}/plan?vesselId=${item.vesselId}`);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  return (
    <div className="projectlist-wrapper project-top-8" data-testid="tsi-project-list">
      {loading ? <Loader2 /> : (
        <div>
          <div className="container">
            <div className="list-view scorll-body-wrap">
              {projects && projects?.items?.length > 0 ? (
                projects?.items
            && projects?.items.map((project) => {
              return (
                <div className="card v-list-lg ">
                  {project?.vesselImageUrl ? (
                    <img src={project?.vesselImageUrl} alt="" className="thmb-lg ship-thumb" />
                  ) : (
                    <img src="/static/media/vessel-thmd.304ab3bca4863ea93cc6.jpg" alt="" className="thmb-lg ship-thumb" />
                  )}
                  {/* <div className="thmb-lg ship-thumb"> */}
                  {/* <img src={project?.vesselImageUrl} alt="" className="thmb-lg ship-thumb" /> */}
                  {/* </div> */}
                  <button
                    type="button"
                    data-testid="pin"
                    className={project?.isPinned ? 'pin link-btn pinned' : 'pin link-btn'}
                    onClick={() => {
                      const body = {
                        projectId: project?.id,
                        isPinned: !project?.isPinned
                      };
                      savePin(body);
                    }}
                  >
                    <span className="icon-pin" />
                  </button>
                  {project?.isPendingApproval ? <div className="pin-approval">Pending for approval</div> : ''}
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleProjectDetailUrl(project);
                    }}
                  >
                    <div className="pro-indicators d-flex">
                      <div className="priority-ind ">
                        <span
                          className={
                            project?.priority === 1
                              ? 'icon-disc low m-3'
                              : project?.priority === 2
                                ? 'icon-disc medium m-3'
                                : project?.priority === 3
                                  ? 'icon-disc high m-3'
                                  : ''
                          }
                        />
                        {project?.priorityName}
                      </div>
                      <div className="phase-ind ms-4">
                        {project?.phaseId === projectPhase.PLAN ? (
                          <div className="plan">P</div>
                        ) : project?.phaseId === projectPhase.SPECIFY ? (
                          <div className="specify">S</div>
                        ) : project?.phaseId === projectPhase.TENDER ? (
                          <div className="tender">T</div>
                        ) : project?.phaseId === projectPhase.REPAIR ? (
                          <div className="repair">R</div>
                        ) : project?.phaseId === projectPhase.CLOSE ? (
                          <div className="close">C</div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <span
                          data-tooltip-id="project-name"
                          data-tooltip-content={project?.nameTooltip}
                          className="mb-2 white-space-preserve"
                        >
                          {project?.name}
                        </span>
                        <p>{project?.vesselName}</p>
                        <div className="font-14 my-2 ">
                          <div className="justify-content-start">
                            <span className="icon-disc inprogress font-16"> </span>
                            <span className="">{project?.projectStatus}</span>
                          </div>
                          {project?.planApprovalStatus && project?.approvalText ? (
                            <div className="mt-1  bral-all">
                              <span
                                data-tooltip-id="project-name"
                                data-tooltip-content={project?.approvalComment ? project.approvalComment : 'No comments'}
                                className="icon-message-square me-2"
                              />
                              {project?.approvalText}
                              {/* <span className="ra-border-15 bg-gray icon-dotted-menu-horizontal ms-2 px-1 white"> </span> */}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 gx-5 respo-fix">
                        <table className="project-list-table">
                          <tr>
                            <td>
                              <label>DD Due Date :</label>
                            </td>
                            <td>
                              <div className="value">{project?.ddDueDate ? moment(project?.ddDueDate).format('DD-MMM-YYYY') : '-'}</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label> Specs :</label>
                            </td>
                            <td>
                              <div className="value">{project?.specCount}</div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label> Estimated Amount :</label>
                            </td>
                            <td>
                              <div
                                className="value"
                              >
                                <span
                                  data-tooltip-id="project-name"
                                  data-tooltip-content={project?.currencySymbol + formatNumber(project?.estimatedBudget)}
                                >
                                  {' '}
                                  {project?.currencySymbol}
                                  {_.truncate(formatNumber(project?.estimatedBudget), {
                                    length: 12,
                                    separator: ' '
                                  })}

                                </span>

                              </div>

                              {' '}
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-4 col-md-6 gx-5 respo-fix">
                        {/* <table className="project-list-table"> */}
                        {/* <tr>
                            <td> */}
                        <div className="d-flex">
                          <div className="col-6">
                            <label>Off Hire Days :</label>
                          </div>
                          {/* </td>
                            <td> */}
                          <div className="col-6 ">
                            <span
                              className="value text-ellipsis"
                            >
                              {project?.offhireDays}
                            </span>
                          </div>
                        </div>
                        {/* </td>
                          </tr> */}
                        {/* <tr>
                          <td> */}
                        <div className="d-flex mt-1">
                          <div className="col-6">
                            <label> Yard :</label>
                          </div>
                          {/* </td>
                          <td> */}
                          <div className="col-6 ">
                            <div
                              data-tooltip-id="project-yard"
                              data-tooltip-content={project?.yard}
                              className="value capitalize timeline-text-wrap text-ellipsis"
                            >
                              {project?.yard || '-'}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex mt-1">
                          <div className="col-6">
                            <label> TRD :</label>
                          </div>
                          {/* </td>
                          <td> */}
                          <div className="col-6 ">
                            <div
                              className="value capitalize timeline-text-wrap text-ellipsis"
                            >
                              {project?.totalRepairDays}
                              {' '}
                              day(s)
                            </div>
                          </div>
                        </div>
                        {/* </td>
                        </tr> */}
                        {/* </table> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
              ) : (
                <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                  <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                  <div className="font-18 animate__animated animate__headShake">No data found</div>
                </div>
              )}
              <Tooltip id="project-name" place="top" className="tooltip m-4" />
              <Tooltip id="project-yard" place="left" className="tooltip ms-4" />
            </div>

            <div className="fixed-footer card">
              <div className="container">
                {projects?.totalItems > 10 && (
                <Pagination
                  pageClick={pageClick}
                  pageCount={projects.totalPages}
                  currentpage={currentpage}
                  onSizeChange={onSizeChange}
                  size={pageSize}
                />
                )}
              </div>
            </div>
          </div>
          {userRole === roles.ADMIN ? (
            <div className="footer">
              <div className="container pb-5">
                <div className="d-flex justify-content-between font-13 pb-5">
                  <div className="copy-text">© 2024 ThinkPalm</div>
                  <div className="d-flex col-gap-1">
                    <button onClick={() => setShowPrivacyPolicy(true)} className="link-btn" type="button">
                      Privacy Policy
                    </button>

                    <button className="link-btn" onClick={() => setShowTermsPolicy(true)} type="button">
                      Terms of Use
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="footer collapsed-tsi justify-content-between font-13">
              <div className="copy-text copy-right footer-proj-list-margin">© 2024 ThinkPalm</div>

            </div>

          )}
        </div>
      )}
      {showPrivacyPolicy && (
      <PrivacyPolicy
        setShowPrivacyPolicy={setShowPrivacyPolicy}
        showPrivacyPolicy={showPrivacyPolicy}
      />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </div>
  );
};
export default ProjectList;
