import React from 'react';

const PaintCost = (props) => {
  const { data, downloadPaintReport, allDocs } = props;
  return (
    <div className="border md-radius">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        {allDocs?.hasPaintCost ? (
          <div>
            <span
              className="icon-download pointer"
              onClick={downloadPaintReport}
            />
          </div>
        )
          : (
            <div className="cursor-not-allowed opacity-6">
              <span
                className="icon-download disabled"
              />
            </div>
          )}
      </div>
    </div>
  );
};
export default PaintCost;
