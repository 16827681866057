import React from 'react';

const PackageCard = (props) => {
  const { onCardSelect, selectedCard, cardDetails = {} } = props;

  return (
    <div data-testid="packageCard" className="card border-dot my-2 px-3 py-4 bg-lightorange shadow-medium md-radius d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <div className="ps-1 pe-3">
          <input type="radio" id="html" checked={selectedCard === 1} onChange={() => onCardSelect(1)} />
        </div>
        <span className="icon-dms font-20 me-4" />
        <h3 className="font-semibold font-14">{cardDetails.specLibraryTypeName || ''}</h3>
      </div>
      {cardDetails.specLibraryTypeName !== 'All Major Specs'
      && <label className="font-13 me-3">Package</label>}
    </div>
  );
};
export default PackageCard;
