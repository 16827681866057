/* istanbul ignore file */
import React, { useEffect } from 'react';
import helper from '../../utils/helper';
import loader2 from '../../images/loader2.gif';
import { microsoftTokenValidation } from './services';
import showAlert from '../../utils/alert';

const SSOLoader = () => {
  useEffect(() => {
    const url = window.location.href;
    const startIndex = url.indexOf('code=') + 'code='.length;
    const endIndex = url.indexOf('&session_state');
    const token = url.substring(startIndex, endIndex);
    const { roles } = helper;
    if (token) {
      microsoftTokenValidation(token).then((res) => {
        if (res.success) {
          localStorage.setItem('USER_TOKEN', res?.data?.token);
          localStorage.setItem('USER_ROLE_NAME', res?.data?.roleName);
          localStorage.setItem('USER_ID', res?.data?.userId);
          localStorage.setItem('USER_ROLE', res?.data?.role);
          const userRole = res?.data?.role?.toLowerCase();
          if (userRole === roles.AUTHOR
            || userRole === roles.EDITOR
            || userRole === roles.VIEWER
            || userRole === roles.VESSEL_USER) {
            localStorage.setItem('isFirst', 'true');
          }
          localStorage.setItem('LICENCE_MESSAGE', res?.data?.licenceMessage);
          if (res?.data?.role?.toLowerCase() === 'admin') {
            window.location.assign('/admin/dashboard');
          } else {
            window.location.assign('/user/dashboard');
          }
        } else {
          showAlert('User does not exist.', 'error');
          setTimeout(() => window.location.assign('/login'), 2000);
        }
      });
    }
  }, []);
  return (
    <div className="content-loader" data-testid="loader">
      <span className="text color ">
        <img className="" src={loader2} alt="" />
      </span>
      <div className="font-bolder font-18 head-black">
        Loading...
      </div>
      <div className="head-black">
        <p className="opacity-4">Please wait, while we set things up for you...</p>
        <p className="opacity-4">This might take a few seconds.</p>
      </div>
    </div>
  );
};
export default SSOLoader;
