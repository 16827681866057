/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import VendorCard from './VendorCard';
import VendorCardCollapsed from './VendorCardCollapsed';
import Attachments from './Attachments';
import ModalBox from '../../../common/ModalBox';
import helper from '../../../../utils/helper';

const VendorContainer = (props) => {
  const { formatNumber } = helper;
  const { vendorList, regions, handleSelectVendor,
    collapseVendor, allRegionsHidden, isTSI,
    ondrop,
    formDataAttachment,
    setAttachFormData,
    saveAttachments,
    showAttachments,
    initialFormData,
    setShowAttachments, addCliked, vendorItem, setIsAddClicked,
    setVendorItem, saveVendorItem } = props;
  const getTooltip = (va) => {
    let t = '$ ';
    t = t.concat('', formatNumber(va || 0));
    return t;
  };
  useEffect(() => {
    if (vendorItem?.vendor) { setShowAttachments(true); }
  }, [vendorItem]);

  const [scrollPosition, setScrollPosition] = useState(0);

  /* istanbul ignore next */
  const handleScroll = (direction) => {
    const scrollingBox = document.getElementById('scrolling-box');
    const containerWidth = scrollingBox.offsetWidth;
    const scrollWidth = scrollingBox.scrollWidth;
    const maxScrollPosition = scrollWidth - containerWidth;
    const scrollIncrement = containerWidth / 2;

    if (direction === 'right') {
      const newPosition = Math.min(scrollPosition + scrollIncrement, maxScrollPosition);
      setScrollPosition(newPosition);
      scrollingBox.scrollTo(newPosition, 0);
    } else {
      const newPosition = Math.max(scrollPosition - scrollIncrement, 0);
      setScrollPosition(newPosition);
      scrollingBox.scrollTo(newPosition, 0);
    }
  };

  return (
    <>
      {vendorList?.length > 1 && (
        <div className="compare-quote-classes">
          <button type="button" id="excel" className="link-btn large left-icon-cq vendor" data-test="scroll1" onClick={() => handleScroll('left')}>
            <span className="icon-arrow-thin-left font-22" />
          </button>
        </div>
      )}
      {vendorList && vendorList.length && regions && regions.length && !allRegionsHidden ? (
        <div id="scrolling-box" data-testid="p-6" className="items">
          {vendorList.map((vendor, vendorIndex) => {
            return (
              <div
                className={`item card-inner-table ${vendor.isSelected ? 'border-green' : ''} ${collapseVendor.includes(vendor.vendor) ? 'item-collapsed' : 'item-expanded'
                }`}
              >
                {collapseVendor.includes(vendor.vendor) ? (
                  <VendorCardCollapsed
                    vendorData={vendor}
                    {...props}
                    vendorIndex={vendorIndex}
                  />
                ) : (
                  <VendorCard
                    isTSI={isTSI}
                    vendorData={vendor}
                    {...props}
                    vendorIndex={vendorIndex}
                    setVendorItem={setVendorItem}
                  />
                )}
                <div className={`font-18 pl-1 ${collapseVendor.includes(vendor.vendor) ? 'ms-4' : ''}`}>
                  <div className="region-div-total">
                    {collapseVendor.includes(vendor.vendor) ? (
                      ''
                    ) : (
                      <div className="p-5">
                        {isTSI && (
                          <button
                            className={`${vendor.isSelected ? 'ml-venvor green-btn' : 'ms-4 secondary-btn'}`}
                            type="button"
                            onClick={() => {
                              handleSelectVendor(vendorIndex);
                            }}
                          >
                            {`${vendor.isSelected ? 'Selected' : 'Select Vendor'}`}
                          </button>
                        )}

                      </div>
                    )}
                    {regions.map((r, regionIndex) => {
                      if (r.isShow) {
                        return (
                          <div
                            className={`region-div-inner  h-total text-right ${collapseVendor.includes(vendor.vendor)
                              ? regions.length > 1
                                ? 'paint-r-border' : ''
                              : 'paint-l-border'
                            }`}
                          >
                            <div>
                              <div
                                className="font-bold text-content-black"
                                data-tooltip-id="view-prof123"
                                data-tooltip-content={
                                  getTooltip(vendor.regionGrandTotal[regionIndex]?.grandTotal)
                                }
                              >
                                $
                                {' '}
                                {_.truncate(
                                  formatNumber(vendor.regionGrandTotal[regionIndex]?.grandTotal)
                                  || 0, {
                                    length: 17,
                                    separator: ' '
                                  }
                                )}
                              </div>
                              <div>Total</div>
                            </div>
                          </div>
                        );
                      }
                      return '';
                    })}
                    <Tooltip id="view-prof123" place="top" className="tooltip " />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div data-testid="p-6" className="items" style={{ overflowX: 'hidden' }}>
          {!vendorList.length || !regions.length ? (
            <div className="align-items-center card-inner-table d-flex item no-data-height">
              <div className="mx-auto">Add vendor and region to view comparison</div>
            </div>
          ) : (
            <div />
          )}
          {allRegionsHidden
            ? (
              <div className="align-items-center card-inner-table d-flex item no-data-height">
                <div className="mx-auto">Enable regions to view comparison </div>
              </div>
            )
            : <div />}
        </div>
      )}
      {showAttachments && (
        <ModalBox
          modalClassName="medium"
          closeModal={() => {
            // if (addCliked) {
            setIsAddClicked(false);
            // } else {
            setShowAttachments(false);
            setVendorItem({});
            // }
          }}
          heading={<span className="text-uppercase">{vendorItem?.vendor}</span>}
          // heading={addCliked ? 'Add Attachments' : 'Attachments'}
          buttonName={addCliked ? 'Save' : ''}
          onSave={() => saveAttachments(vendorItem?.vendor)}
        >
          <Attachments
            ondrop={ondrop}
            vendorData={vendorItem}
            isTSI={isTSI}
            addCliked={addCliked}
            setIsAddClicked={setIsAddClicked}
            setAttachFormData={setAttachFormData}
            formDataAttachment={formDataAttachment}
            initialFormData={initialFormData}
            saveVendorItem={saveVendorItem}
          />

        </ModalBox>
      )}
      {vendorList?.length > 1 && (
        <div className="compare-quote-classes">
          <button type="button" id="excel" className="link-btn large right-icon-cq vendor" data-test="scroll1" onClick={() => handleScroll('right')}>
            <span className="icon-arrow-thin-right font-22" />
          </button>
        </div>
      )}
    </>
  );
};
export default VendorContainer;
