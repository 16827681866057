import React from 'react';
import helper from '../../../../../utils/helper';
import InputType1 from '../../../../common/InputType1';

const CreateSpecPackages = ({ formData, setFormData }) => {
  const { handleValidation, charValidation } = helper;
  const handleChange = (e, field) => {
    const d = { ...formData };
    d[field] = field === 'specificationNo' ? charValidation(e.target.value, 10) : charValidation(e.target.value);
    setFormData(d);
    handleValidation(['specificationNo', 'specificationNo'], d, field);
  };
  return (
    <div data-testid="nonspec-create">
      <div className="">
        {/* <InputType1
          type="text"
          className="modal-inputs disabled-field"
          readOnly
          label="NonSpec Number"
          value={formData.specificationNo}
        /> */}
        <InputType1
          type="text"
          className="modal-inputs"
          label="Non Spec Section"
          value={formData.title}
          onChange={(e) => {
            handleChange(e, 'title');
          }}
          id="title"
          isMandatory="true"
        />
      </div>
    </div>
  );
};
export default CreateSpecPackages;
