import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../utils/helper';
import { fileImages } from '../../../../common/mappingConst';

const Attachments = (props) => {
  const { formatBytes } = helper;
  const { attachments, download, isDownloading } = props;
  return (
    <div className="">
      <div className="table-responsive mb-5">
        <table className="card-table">
          <thead>
            <tr>
              <th>
                {/* <div className=" "> */}
                <h4 className="head-16 ms-4">Vessel Plans and Drawings</h4>
                {/* </div> */}
              </th>
            </tr>
          </thead>
          <tbody>
            <div className="attachment-read-only">
              {attachments?.map((item) => {
                return (
                  <>
                    {/* <tr>
                      <td> */}
                    <div className="border-down">
                      <div className="d-flex m-4">
                        <div className="head-14 text-ellipsis ms-4 col">
                          <span
                            data-tooltip-id="project-upload"
                            data-tooltip-content={item?.documentCategoryName}
                          >
                            {_.truncate(item?.documentCategoryName, {
                              length: 50,
                              separator: ' '
                            })}
                          </span>

                        </div>
                        <div>
                          <button
                            type="button"
                            disabled={isDownloading}
                            className="link-btn ml-8"
                            onClick={() => {
                              download(item?.documentCategory);
                            }}
                          >
                            <span className="icon-download me-3" />
                            Download All
                          </button>

                        </div>
                      </div>
                      <div className="ps-4 ">
                        {item?.projectAttachments?.map((field) => {
                          return (
                            <div className="ms-4 pt-3 pb-3 pe-3">
                              <div className="d-flex justify-content-between full-width align-items-center">
                                <div className="d-flex col-gap-1">
                                  <div className="thmb-icon-md">
                                    <span className={fileImages[_.toLower(field.fileType)]} />
                                  </div>
                                  <div className="d-flex flex-column justify-content-center">
                                    <a
                                      href={field.physicalFileName}
                                      target="_blank"
                                      download="downloaded_file"
                                      className=""
                                      rel="noreferrer"
                                    >
                                      <span
                                        className="head-14 mb-2  text-ellipsis"
                                        data-tooltip-id="project-upload"
                                        data-tooltip-content={field.name}
                                      >
                                        {_.truncate(field.name, {
                                          length: 50,
                                          separator: ' '
                                        })}
                                      </span>
                                    </a>
                                    {/* <span className="head-14 mb-2  text-ellipsis"
                                      {_.truncate(field.name, {
                                        length: 50,
                                        separator: ' '
                                      })}
                                    </span> */}
                                    <div className="font-14">
                                      <label className="me-2">Format :</label>
                                      <span className="value">{field.fileType}</span>
                                      <span className="mx-4">|</span>
                                      <label className="me-2"> Size :</label>
                                      <span className="value">{formatBytes(field.fileSize)}</span>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="d-flex col-gap-2">
                                  <button className="link-btn" type="button">
                                  <a
                                    href={field.physicalFileName}
                                    target="_blank"
                                    download="downloaded_file"
                                    className=""
                                    rel="noreferrer"
                                  >
                                    <span className="icon-download font-20" />
                                  </a>
                                  </button>
                                  <button id="delete" className="link-btn"
                                      type="button" onClick={() => { deleteModal(index, key); }}>
                                        <span className="icon-delete font-20" />
                                      </button>
                                </div> */}

                              </div>

                            </div>
                          );
                        })}

                      </div>
                      {/* {item.id !== '00000000-0000-0000-0000-000000000000' ? (
                  <button type="button" className="brand-color font-bold pt-2"
                  onClick={() => { openUpdateModal(item.id, item.projectPlanDocs.documents); }}>
                  Browse Attachments</button>
                ) : ('')} */}
                      {/* </div> */}
                    </div>
                    {/* </td>
                    </tr> */}

                  </>
                );
              })}
            </div>
          </tbody>
        </table>

      </div>
      <Tooltip id="project-upload" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Attachments;
