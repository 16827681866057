import React, { useEffect, useState } from 'react';
import helper from '../../../../../../utils/helper';
import { jobDoneAttachments, jobDoneAttachment, deletejobAttachment } from '../services';
import ReportWrapper from './reportWrapper';
import { s3Upload } from '../../../../../Admin/Vessels/Vessel/services';
import ModalBox from '../../../../../common/ModalBox';
import showAlert from '../../../../../../utils/alert';

const JobDoneReport = (props) => {
  const { jobModal, setJobModal, isTSI, projectBasicDetails, isVesselUser } = props;
  const { MAX_FILE_SIZE } = helper;
  const acceptedFiles = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/tiff'];
  const [showReport, setShowReport] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [allData, setAllData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [newJob, setNewJob] = useState({ projectSpecId: jobModal.projectSpecId, name: '', description: '', path: '' });

  const getAllReports = () => {
    jobDoneAttachments({ specId: jobModal.projectSpecId, searchedText: searchText }).then((res) => {
      if (res.success) {
        setAllData(res.data);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    getAllReports();
  }, []);
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getAllReports();
    }
  };
  const onAddNew = () => {
    setShowReport(false);
  };
  const onCancelAdd = () => {
    setShowReport(true);
    setNewJob({ projectSpecId: jobModal.projectSpecId, name: '', description: '', path: '' });
  };
  const onSaveNewJob = () => {
    jobDoneAttachment(newJob).then((res) => {
      if (res.success) {
        showAlert('Successfully added', 'success');
        onCancelAdd();
        getAllReports();
      }
    });
  };
  const onDeleteAttachment = (ids) => {
    setDeleteConfirm(ids);
  };
  const onDeleteData = (id1) => {
    deletejobAttachment({ specId: jobModal.projectSpecId, id1 }).then((resp) => {
      if (resp.success) {
        setDeleteConfirm(false);
        getAllReports();
        showAlert('Successfully deleted', 'success');
      }
    });
  };
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          const currentImage = { ...newJob };
          currentImage.path = res.data;
          currentImage.fileName = first.name;
          currentImage.type = first.type;
          setNewJob(currentImage);
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
  };
  useEffect(() => {
    if (!searchText) {
      getAllReports();
    }
  }, [searchText]);
  return (
    <div data-testid="jobmodal">
      <ModalBox
        modalClassName="medium modal-scroll"
        heading={(
          <div className="d-flex">
            <span>Job Done Report </span>
            {' '}
            {showReport && allData.length ? <span className="ms-3 primary-btn small count-badge">{allData.length}</span> : ''}
          </div>
        )}
        closeModal={() => setJobModal(false)}
        isCloseModal
        buttonName=""
      >
        <ReportWrapper
          onAddNew={onAddNew}
          showReport={showReport}
          setNewJob={setNewJob}
          newJob={newJob}
          onCancelAdd={onCancelAdd}
          setSearchText={setSearchText}
          searchText={searchText}
          onSearch={onSearch}
          onSaveNewJob={onSaveNewJob}
          ondrop={ondrop}
          allData={allData}
          isTSI={isTSI}
          loader={loader}
          onDeleteAttachment={onDeleteAttachment}
          projectBasicDetails={projectBasicDetails}
          isVesselUser={isVesselUser}
        />
      </ModalBox>
      {deleteConfirm
        && (
          <ModalBox
            openDeleteModal={deleteConfirm}
            modalClassName="medium"
            heading="Delete"
            isCloseModal
            closeModal={() => setDeleteConfirm(false)}
            buttonName="Delete"
            onSave={() => { onDeleteData(deleteConfirm); }}
          >
            <p>Are you sure you want to delete ?</p>
          </ModalBox>
        )}
    </div>
  );
};

export default JobDoneReport;
