/* istanbul ignore file */
import JoditEditor from 'jodit-react';
import React, { useMemo } from 'react';

const SendSupplementarySpecs = (props) => {
  const { yardMailList, previewList } = props;
  const config = useMemo(
    () => ({
      readonly: true
    })
  );
  const email = previewList?.email.join().replaceAll(',', ', ');
  return (
    <div className="p-5">
      <div className="row mb-3">
        <div className=" col-6 head-14">
          To,
          <p className="d-flex">{email}</p>
        </div>
        <div className="head-12 mt-3">
          {`Subject: ${yardMailList?.subject} `}
        </div>
      </div>
      <div className="d-flex align-text-justify mt-5">
        <div className="editor-wrap-description view-jodit">
          <JoditEditor
            config={config}
            value={yardMailList?.mailContent}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};
export default SendSupplementarySpecs;
