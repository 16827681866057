/* eslint-disable */

import { toNumber } from "lodash";
import moment from "moment";

/** Constants* */
const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
const Priority = [
  { label: 'Low', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'High', value: 3 }
];
const Phase = [
  { label: 'Plan', value: 1 },
  { label: 'Specify', value: 2 },
  { label: 'Tender', value: 3 },
  { label: 'Repair', value: 4 },
  { label: 'Close', value: 5 }
];
const KpCategory = [
  { label: 'Days', value: 1 },
  { label: 'Treatment', value: 2 },
  { label: 'Piping', value: 3 },
  { label: 'Valve', value: 4 },
  { label: 'Motor', value: 5 },
  { label: 'Overtime', value: 6 },
  { label: 'SteelStaging', value: 7 },
  { label: 'UserDefined', value: 8 }
];
const DockType = [
  { label: 'Small', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'Large', value: 3 }
];
const YardType = [
  { label: 'Floating', value: 1 },
  { label: 'Graving', value: 2 }
];
const TemplateTypes = [
  { label: 'Daily Report', value: 1 },
  { label: 'Statement of Facts Report', value: 2 },
  { label: 'Job Progress Report', value: 3 },
  { label: 'Variations Orders Report', value: 4 },
  { label: 'Cost Update Report', value: 5 },
  // { label: 'Yard Stay Report', value: 6 },
  { label: 'Photo Report', value: 7 },
  // { label: 'DD Project Report', value: 8 },
  { label: 'RFQ Email Template', value: 9 },
  { label: 'PO Report', value: 10 },
  { label: 'Terms and Conditions', value: 11 },
  { label: 'Variation Template', value: 12 },
  { label: 'WO Report', value: 13 },
  { label: 'Supplementary Spec Email Template', value: 14},
  { label: 'Variance Report', value:16},
  { label: 'AC Code Report', value:17},
  // { label: 'Share Report Template', value:17}
];
 const woReportConstants = [
  {
    key: 'openCount',
    color: 'rgb(34, 105, 213)', // green
    label: 'Open'
  },
  {
    key: 'completedCount',
    color: '#36AC79', // green
    label: 'Completed'
  },
  {
    key: 'onHoldCount',
    color: '#ff9950',
    label: 'On Hold'
  },
  {
    key: 'deferredToNextDD',
    color: '#facc00',
    label: 'Deferred To Next DD'
  },

];

const ReceivedQuoteStatus = [
  { label: 'Select Status...', value: 0 },
  { label: 'Add to Spec list', value: 1 },
  { label: 'Cancelled', value: 2 },
  { label: 'Deferred To Next DD', value: 3 },
];
const setTypes = [
  { value: 1, label: 'IS1' },
  { value: 2, label: 'IS2' },
  { value: 3, label: 'IS3' },
  { value: 4, label: 'IS4' },
  { value: 5, label: 'IS5' },
  { value: 6, label: 'SS1' },
  { value: 7, label: 'SS2' },
  { value: 8, label: 'SS3' },
  { value: 9, label: 'SS4' },
  { value: 10, label: 'SS5' },
  { value: 11, label: 'ADH' }
];
const SpecificationLevel = {
  Section: 1,
  Specification: 2,
  ServiceLine: 3,
  KFP: 4
};
const SpecificationType = {
  GeneralTariff: 1,
  MajorSpecs: 2,
  NonSpec: 3
};
const MAX_FILE_SIZE = 5;
/** Functions */
const getOptions = (List, v, label, type) => {
  if (type) {
    return List.map((item) => {
      return {
        value: item[v],

        label: `${item[label]} (${item[type]})`,

        ...item
      };
    });
  }
  return List.map((item) => {
    return {
      value: item[v],

      label: item[label],

      ...item
    };
  });
};
const getDropDownOptions = (List, v, label, type) => {
  if (type) {
    return List.map((item) => {
      return {
        value: item[v],

        label: `${item[label]} ${item[type]}`,

        ...item
      };
    });
  }
  return List.map((item) => {
    return {
      value: item[v],

      label: item[label],

      ...item
    };
  });
};
const generateOptions = (list, v, label, type) => {
  if (list) {
    if (type) {
      return Object.keys(list).length !== 0 ? getOptions(list, v, label, type) : [];
    }
    return Object.keys(list).length !== 0 ? getOptions(list, v, label) : [];
  }
  return [];
};
const charValidation = (data, validLength = 50) => {
  let output = data;
  // let isInvalid = true;
  if (data?.length > validLength) {
    // isInvalid = false;
    output = data.substr(0, validLength);
  }
  return output;
};
const addColor = (item, valid) => {
  const element = document.getElementById(item);

  if (element) {
    if (valid === 'add') {
      element.classList.add('error-field');
    } else {
      element.classList.remove('error-field');
    }
  }
};

const formValidation = (fields, dataForm) => {
  let isValid = true;

  fields.forEach((item) => {
    const v = String(dataForm[item])?.trim();

    if (!v || v === null || v === '' || v === ' ') {
      isValid = false;
      // alert('hloo');
      addColor(item, 'add');
    } else {
      addColor(item, '');
    }
  });

  return isValid;
};

const handleValidation = (fields, dataForm, field) => {
  let isValid = true;

  if (fields.includes(field)) {
    const v = String(dataForm[field])?.trim();

    if (!v || v === null || v === '' || v === ' ') {
      isValid = false;

      addColor(field, 'add');
    } else {
      addColor(field, '');
    }
  }

  return isValid;
};

const formValidationInArray = (fields, dataForm = []) => {
  let isValid = true;
  dataForm.forEach((data) => {
    fields.forEach((item) => {
      const v = String(data[item])?.trim();
      if (!v || v === null || v === '' || v === ' ') {
        isValid = false;
      }
    });
  });
  return isValid;
};
const isValueValidation = (item) => {
  let isValid = true;
  const v = String(item)?.trim();
  if (!v || v === null || v === '' || v === ' ') {
    isValid = false;
  }
  return isValid;
};
const ValidateEmail = (mail) => {
  let isValid = true;

  // eslint-disable-next-line no-useless-escape
  if (/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,45}/g.test(mail)) {
    isValid = true;
  } else {
    isValid = false;
  }
  return isValid;
};
const logOut = (redirectUrl) => {
  const lastUserLoginRole = localStorage.getItem('USER_ROLE');
  const uName = localStorage.getItem('USERNAME');
  const uPassword = localStorage.getItem('PASSWORD');
  const REMEMBER = localStorage.getItem('REMEMBER');
  localStorage.clear();
  localStorage.setItem('REDIRECT_URL', redirectUrl);
  localStorage.setItem('LAST_USER_ROLE', lastUserLoginRole);
  if (uName && uPassword) {
    localStorage.setItem('USERNAME', uName);
    localStorage.setItem('PASSWORD', uPassword);
    if (REMEMBER === 'REMEMBER') {
      localStorage.setItem('REMEMBER', REMEMBER);
    }
  }
  window.location.assign('/login');
};
const loginSetUP = (user) => {
  if (user) {
    localStorage.setItem('USER_ID', user.userId);
    localStorage.setItem('USER_ROLE', user.role);
    localStorage.setItem('USER_ROLE_NAME', user.roleName);
    localStorage.setItem('USER_TOKEN', user.token);
    localStorage.setItem('LICENCE_MESSAGE', user.licenceMessage);
  }
};
// const roles = {
//   ADMIN: 'admin',
//   TSI: 'tsi',
//   DIRECTOR: 'director',
//   FLEET_MANAGER: 'fleetmanager',
//   VESSEL_USER: 'vesseluser',
//   VESSEL_MASTER: 'master',
//   VESSEL_CHIEF_ENGINEER: 'chiefengineer'
// };
const roles = {
  ADMIN: 'admin',
  AUTHOR: 'author',
  EDITOR: 'editor',
  VIEWER: 'viewer',
  VESSEL_USER: 'vesseluser'
};
const roleNumer = {
  Admin: 1,
  TSI: 2,
  FleetManager: 3,
  Director: 4,
  VesselUser: 5,
  CustomRole: 6,
  MasterAdmin: 7,
  MasterSupport: 8
};
const restrictDecimal = (value) => {
  // eslint-disable-next-line no-useless-escape
  if(value === 0) {
    return 0;
  }
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return value?.match(regex)[0];
};
const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
const handleFormValidation = (field, arr, id = '') => {
  const v = String(arr[field])?.trim();
  const goTO = id || `new-${field}`;
  if (!v || v === null || v === '' || v === ' ') {
    addColor(goTO, 'add');
  } else {
    addColor(goTO, '');
  }
};
const downloadFile = (fileUrl, filename) => {
  const element = document.createElement('a');
  if (typeof element.download !== 'undefined') {
    element.setAttribute('href', fileUrl);
    element.setAttribute('download', filename);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
  } else {
    window.location.assign(
      window.location.href.split(window.location.hash)[0] + fileUrl
    );
  }
  document.body.removeChild(element);
};

// eslint-disable-next-line consistent-return
const restrictSpecialCharacters = (e) => {
  const x = e.which || e.keyCode;
  let specialCharacters = [];
  specialCharacters = [
    33, // "!",
    35, // "#",
    37, // "%",
    46, // "."
    94, // "^",
    38, // "&",
    95, // "_",
    43, // "+",
    45, // "-"
    124, // "|",
    125, // "}",
    123, // "{",
    34, // '"',
    58, // ":",
    62, // ">",
    60, // "<",
    101, // "e",
    69
  ];
  if (specialCharacters.indexOf(x) !== -1) return true;
};
const currentDate = () => {
  const date = new Date();
  // const day = date.getDate();
  // const month = date.getMonth() + 1;
  // const year = date.getFullYear();
  return  moment(date).format(' DD-MMM-YYYY');

};
const getExportedFileName = (response) => {
  const headerval = response.headers['content-disposition'];
  return headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
};

const projectPhase = {
  PLAN: 1,
  SPECIFY: 2,
  TENDER: 3,
  REPAIR: 4,
  CLOSE: 5
};

const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
};
const multiRole = {
  Admin: 'Admin',
  TSI: 'TSI',
  'fleet manager': 'FleetManager',
  'fleetmanager': 'FleetManager',
  'FleetManager': 'FleetManager',
  Director: 'Director',
  VesselUser: 5,
  CustomRole: 6,
  MasterAdmin: 7,
  MasterSupport: 8
};

function separateComma(val) {
  // remove sign if negative
  var sign = 1;
  if (val < 0) {
    sign = -1;
    val = -val;
  }
  // trim the number decimal point if it exists
  let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
  let len = num.toString().length;
  let result = '';
  let count = 1;
  for (let i = len - 1; i >= 0; i--) {
    result = num.toString()[i] + result;
    if (count % 3 === 0 && count !== 0 && i !== 0) {
      result = ',' + result;
    }
    count++;
  }
  // add number after decimal point
  if (val.toString().includes('.')) {
    result = result + '.' + val.toString().split('.')[1];
  }
  // return result with - sign if negative
  return sign < 0 ? '-' + result : result;
}
const numberWithCommas = (x) => {
  if (x) {
    return x.toString().split('.')[0].length > 3 ? `${x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${x.toString().substring(x.toString().split('.')[0].length - 3)}` : x.toString();
  }
  return '';

};
const formatNumber = (number) => {
  if (number) {
    let parseNum = parseFloat(number);
    if (parseNum == null) {
      return '';
    }
    return parseNum.toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  } else {
    return '0.00';
  }
};
const PhaseTypes = [
  { label: 'Plan', value: 1 },
  { label: 'Specify', value: 2 },
  { label: 'Tender', value: 3 },
  { label: 'Repair', value: 4 },
  { label: 'Close', value: 5 },
];
const fieldValidation = (value, digit, returnString) => {
  if (value) {
    if (digit === 3) {
      if (toNumber(value) < 1000) return restrictDecimal(value);
      else {
        let a = 0;
        if (value.split('.')[1]) {
          a = value.split('.')[1].length + 1;
        }
        if (a) return value?.substr(0, 3) + '.' + value.split('.')[1]?.substr(0, 2);
        else return value?.substr(0, 3);
      }
    }
    if (digit === 5) {
      if (toNumber(value) < 100000) return restrictDecimal(value);
      else {
        let a = 0;
        if (value.split('.')[1]) {
          a = value.split('.')[1].length + 1;
        }
        if (a) return value?.substr(0, 5) + '.' + value.split('.')[1]?.substr(0, 2);
        else return value?.substr(0, 5);
      }
    }
    if (digit === 6) {
      if (toNumber(value) < 1000000) return restrictDecimal(value);
      else {
        let a = 0;
        if (value.split('.')[1]) {
          a = value.split('.')[1].length + 1;
        }
        if (a) return value?.substr(0, 6) + '.' + value.split('.')[1]?.substr(0, 2);
        else return value?.substr(0, 6);
      }
    }
  } else if (returnString) {
    return '0';
  } else return 0;

};
export default {
  fieldValidation,
  formatNumber,
  createUUID,
  MAX_FILE_SIZE,
  handleFormValidation,
  roles,
  // rolesCategory,
  roleNumer,
  SpecificationType,
  SpecificationLevel,
  ValidateEmail,
  formValidation,
  charValidation,
  ZERO_UUID,
  Priority,
  Phase,
  KpCategory,
  DockType,
  YardType,
  generateOptions,
  TemplateTypes,
  formValidationInArray,
  logOut,
  loginSetUP,
  setTypes,
  restrictDecimal,
  handleValidation,
  formatBytes,
  addColor,
  getDropDownOptions,
  getExportedFileName,
  downloadFile,
  currentDate,
  restrictSpecialCharacters,
  projectPhase,
  ReceivedQuoteStatus,
  multiRole,
  woReportConstants,
  PhaseTypes,
  isValueValidation
};
