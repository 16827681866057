/* istanbul ignore file */
import React from 'react';
import loader2 from '../../images/loader2.gif';

const Loader2 = () => {
  return (
    <div className="content-loader" data-testid="loader">
      <span className="text color ">
        <img className="" src={loader2} alt="" />
      </span>
      <div className="font-bolder font-18 head-black">
        Loading...
      </div>
      <div className="head-black">
        <p className="opacity-4">Please wait, while we set things up for you...</p>
        <p className="opacity-4">This might take a few seconds.</p>
      </div>
    </div>
  );
};
export default Loader2;
