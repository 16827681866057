/* istanbul ignore file */
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PrivacyPolicy from '../../Policies/privacy';
import TermsOfUse from '../../Policies/TermsOfUse';
import PolicyFooter from '../policyFooter';
import Sidebar from '../Sidebar';
import { HeadingLabel } from './headerConfig';

const DataConfig = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const subHeaderList = [
    { name: 'General Tariff', url: 'general' },
    { name: 'Major specs ', url: 'major' },
    { name: 'Spec packages', url: 'packages' },
    { name: 'Non spec', url: 'nonspec' },
    { name: 'DD History', url: 'DDHistory' }
  ];
  const [count, setCount] = useState('');
  const [expandedSidebar, setExpandedSidebar] = useState(true);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);

  return (
    <div className="page-wrapper">
      <div className="sub-header" data-testid="data-config">
        <div className="d-flex  col-gap-3">
          <div className="d-flex justify-content-start ">
            <button
              onClick={() => setExpandedSidebar(!expandedSidebar)}
              className="link-btn ms-3 me-5 hamb-menu"
              type="button"
            >
              {expandedSidebar
                ? <span className="icon-menu" />
                : <span className="icon-arrow-thin-right" />}
            </button>
            <h2 className="main-head me-5">{HeadingLabel(location)}</h2>
            {count === '' ? <span /> : <button type="button" className="mt-1 primary-btn small count-badge">{count}</button>}
          </div>
          <>
            {subHeaderList && location.includes('/admin/data/spec') ? (
              <div className="d-flex item-set col-gap-2">
                {subHeaderList.map((i) => {
                  return (
                    <button
                      onClick={() => {
                        if (!location.includes(i.url)) {
                          setCount(0);
                        }
                        navigate(`/admin/data/spec/${i.url}`);
                      }}
                      className={location.includes(i.url) ? 'rnd-btn active' : 'rnd-btn'}
                      type="button"
                    >
                      {i.name}
                    </button>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </>
        </div>
      </div>
      <Sidebar expandedSidebar={expandedSidebar} setCount={setCount} sidebarType="specification-setup" />
      <div className={`content-wrap mt-4 ${expandedSidebar && 'collapsed'}`}>
        <div className="container-fluid ">
          <Outlet context={[count, setCount]} />
        </div>
      </div>
      <div className={`footer justify-content-between font-13 ${expandedSidebar && 'collapsed'}`}>
        <PolicyFooter
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          setShowTermsPolicy={setShowTermsPolicy}
        />
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </div>
  );
};
export default DataConfig;
