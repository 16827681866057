import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

function ImportReceivedQuote(props) {
  const { ondrop, setFile, file } = props;
  return (
    <>
      <div className=" p-4 mt-4" data-testid="upload-card">
        <div className="row">
          {!file ? (
            <div id="fileName-div" className="drag-drop-file file-waiting">
              <input className="p-5 align-self-center" id="fileN" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
              <div className="d-flex row w-full">
                <div className="d-flex flex-column justify-content-center align-items-center col-6">
                  <div className="icon-buble mb-2">
                    <span className="icon-upload-cloud" />
                  </div>
                  <p>Choose a file or drag it here</p>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center col-6">
                  <div className="div_btn">
                    Browse File
                  </div>
                </div>
              </div>
            </div>
          )
            : (
              <div id="fileName-div" className="drag-drop-file file-added d-flex align-items-center flex-row">
                <div className="font-18 d-flex align-items-center">
                  <span className="icon-file-excel me-2 font-20"> </span>
                  <div>
                    <span
                      data-tooltip-id="project-import"
                      data-tooltip-content={file?.name}
                    >
                      {' '}
                      {_.truncate(file.name, { length: 25 })}
                    </span>
                  </div>
                  <span id="close" className="icon-close ms-2 font-20 red" onClick={() => { setFile(''); }}> </span>
                </div>
              </div>
            )}
        </div>
        <Tooltip id="project-import" place="top" className="tooltip mt-3" />
      </div>
    </>

  );
}
export default ImportReceivedQuote;
