/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import Loader from '../../../../../common/loader';
import { getAllSpecList, quotesUpdate, exportYardQuote } from '../services';
import ReceivedQuotesFooter from './receivedQuotesFooter';
import ReceivedQuotesHeader from './receivedQuotesHeader';
import ReceivedQuotesTable from './receivedQuotesTable';
import SubHeader from './subHeader';
import SpecList from '../../Specify/SpecsList';
import SidePanel from '../../../../../common/sidePanel';
import { receivedQuote, receivedQuoteData, getAttachments, saveAttachments, deleteAttachment } from './services';
import Attachments from './attachments';
import Comments from './comments';
import UploadCard from './uploadCard';
import helper from '../../../../../../utils/helper';
import showAlert from '../../../../../../utils/alert';
import { s3Upload } from '../../../../../Admin/Resource/ReferenceDoc/services';
import ModalBox from '../../../../../common/ModalBox';
import ViewMore from '../../../../../Quotations/YardQuotation/ViewMore';
import EditQuote from '../editQuote';
import { formatSave } from '../editQuote/supportFunc';
import YardDocuments from './YardDocuments';

const ReceivedQuotes = (props) => {
  const navigate = useNavigate();
  const [rfqId, setRfqId] = useState();
  const [showsubSec, setShowSubSec] = useState('');
  const [file, setFile] = useState('');
  const [parentSpecId, setParentSpecId] = useState('');
  const [mainSec, setMainSec] = useState('');
  const [specList, setSpecList] = useState({});
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);

  const [searchText, setSearchText] = useState('');
  const [comments, setComments] = useState('');
  const [subHeaderList, setSubHeaderList] = useState();
  const [index, setIndex] = useState(0);
  const [specId, setSpecId] = useState();
  const [quoteId, setQuoteId] = useState();
  const [isiViewMoreClose, setIsViewMoreClose] = useState();
  const [documentId, setDocumentId] = useState('');
  const { id } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [viewComments, setViewComments] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [allFiles, setAllFiles] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingYard, setLoadingYard] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showUpload2, setShowUpload2] = useState(true);
  const { isTsiDisabled, isTSI, fileUploaded,
    setFileUploaded, setQuoteLength, currency,
    activeTab, projectId, quoteLength, projectDetails, state } = props;
  const acceptedFiles = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/bmp',
    'image/tiff',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  const { MAX_FILE_SIZE, ZERO_UUID, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;

  const isSidePanel = false;
  const initialFormData = {
    specId: '',
    projectId: id,
    specDocuments: {
      documentId: ZERO_UUID,
      name: '',
      fname: '',
      type: '',
      size: 0,
      path: ''
    }
  };
  const [formData, setFormData] = useState(initialFormData);
  /* istanbul ignore next */

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  /* istanbul ignore next */

  const getSpecListData = () => {
    setLoadingYard(true);
    setSpecList({});
    if (rfqId) {
      receivedQuoteData(rfqId, searchText).then((resp) => {
        setLoadingYard(false);
        if (resp.success) {
          setSpecList(resp.data);
          setReloadData(false);
        }
      });
    }
  };
  /* istanbul ignore next */

  const getAllAttachments = (quotesId) => {
    setViewAttachments(true);
    // setIsLoading(true);
    getAttachments(quotesId, rfqId).then((resp) => {
      if (resp.success) {
        setAllFiles(resp?.data?.specDocuments);
        setFile('');
        setIsLoading(false);
      }
    });
  };
  /* istanbul ignore next */

  const onUpload = () => {
    const payload = {
      specId,
      projectId: id,
      specDocuments: [formData.specDocuments],
      yardId: specList?.rfqData?.yardId,
      projectRfqId: rfqId,
      quoteId
    };
    saveAttachments(payload).then((resp) => {
      if (resp.success) {
        showAlert('Successfully Uploaded', 'success');
        getAllAttachments(quoteId);
      }
    });
  };
  const getQuotes = () => {
    receivedQuote(id).then((resp) => {
      if (resp.success) {
        setSubHeaderList(resp?.data);
        setLoading(false);
        setFileUploaded(false);
        setQuoteLength(resp?.data?.length);
      }
    });
  };

  useEffect(() => {
    if (rfqId) {
      getSpecListData();
      if (!isiViewMoreClose) {
        setMainSec('');
        setShowSubSec('');
      }
    }
  }, [rfqId, reloadData]);
  useEffect(() => {
    getQuotes();
  }, [fileUploaded]);
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getSpecListData();
    }
  };
  const goToNext = (e) => {
    if (e === 'next') {
      if (subHeaderList?.length !== index + 1) {
        setIndex(index + 1);
        setRfqId(subHeaderList[index + 1]?.id);
      }
    }
    if (e === 'prev') {
      if (index - 1 > -1) {
        setIndex(index - 1);
        setRfqId(subHeaderList[index - 1]?.id);
      }
    }
    setIsViewMoreClose(false);
  };
  /* istanbul ignore next */

  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        setFile(first);
        // setIsLoading(true);
        const res = await s3Upload(body);
        if (res && res.data) {
          setIsLoading(false);
          const Attach = { ...formData };
          Attach.specDocuments.path = res.data;
          Attach.specDocuments.size = first.size;
          Attach.specDocuments.name = first.name;
          console.log('atta', Attach);
          setFormData(Attach);
          // showAlert('Successfully Uploaded', 'success');
        }
        setIsLoading(false);
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
    e.target.value = '';
  };

  /* istanbul ignore next */

  const viewMoreAttachments = (subsec) => {
    setSpecId(subsec?.projectSpecId);
    setQuoteId(subsec?.projectReceivedQuoteId);
    getAllAttachments(subsec?.projectReceivedQuoteId);
  };
  /* istanbul ignore next */

  const viewMoreComments = (commnt) => {
    setViewComments(true);
    setComments(commnt);
  };
  /* istanbul ignore next */
  const viewMoreAttachmentClose = () => {
    setReloadData(true);
    setViewAttachments(false);
    setIsViewMoreClose(true);
    // onSecOpen(specId, false);
  };
  /* istanbul ignore next */
  const deleteModal = (data) => {
    setOpenDeleteModal(true);
    setDocumentId(data?.documentId);
    console.log(data);
    // setRemoveFile(data);
  };
  /* istanbul ignore next */
  const removeAttach = () => {
    deleteAttachment(quoteId, documentId)?.then((res) => {
      if (res.success) {
        setOpenDeleteModal(false);
        setDocumentId('');
        showAlert('Successfully Deleted', 'success');
        getAllAttachments(quoteId);
      }
    });
  };
  useEffect(() => {
    if (!searchText) {
      getSpecListData();
    }
  }, [searchText]);
  useEffect(() => {
    setLoading(true);
    setIsLoading(true);
  }, []);
  const closeAddModal = () => {
    setShowUpload(false);
    setShowUpload2(true);
  };
  const saveQuote = () => {
    quotesUpdate(formatSave(specList)).then((res) => {
      if (res.success) {
        showAlert('Updated successfully', 'success');
        getSpecListData();
        setShowUpload2(true);
      }
    });
  };
  const exportYard = () => {
    exportYardQuote(id, rfqId).then((res) => {
      if (res.success) {
        showAlert('Downloaded successfully', 'success');
      }
    });
  };
  return (
    <div data-testid="rq-main" className="p-4">
      {loading ? (
        <Loader />
      ) : (
        <div>
          {subHeaderList && subHeaderList?.length > 0 ? (
            <>
              {!editable && (
                <SubHeader
                  setRfqId={setRfqId}
                  rfqId={rfqId}
                  goToNext={goToNext}
                  subHeaderList={subHeaderList}
                  setIndex={setIndex}
                  fileUploaded={fileUploaded}
                  setIsViewMoreClose={setIsViewMoreClose}
                />
              )}
              <SidePanel
                title={(
                  <span data-tooltip-id="project-more" data-tooltip-content={showMore.title}>
                    {`${showMore.specificationNo}. ${_.truncate(
                      showMore.title,
                      { length: 55 }
                    )}`}
                  </span>
                )}
                size="large"
                onClose={() => {
                  setShowMore(false);
                }}
                showView={showMore}
              >
                <Tooltip id="project-more" place="bottom" className="tooltip ms-5" />
                {showMore && <ViewMore showMore={showMore} isYard={false} />}
              </SidePanel>
              {viewAttachments ? (
                <SidePanel
                  title="Attachments"
                  size="large"
                  onClose={() => {
                    viewMoreAttachmentClose();
                  }}
                  showView="true"
                >
                  <div className="side-panel__container bg-white specify-side">
                    <div className="side-panel__content">
                      {isLoading ? (
                        <div>
                          <Loader />
                        </div>
                      ) : (
                        <>
                          {(isTSI && !projectDetails?.isClosed) && (
                          <UploadCard
                            ondrop={ondrop}
                            onUpload={onUpload}
                            file={file}
                            setFile={setFile}
                            projectDetails={projectDetails}
                          />
                          )}
                          <Attachments
                            allFiles={allFiles}
                            deleteModal={deleteModal}
                            setOpenDeleteModal={setOpenDeleteModal}
                            openDeleteModal={openDeleteModal}
                            removeAttach={removeAttach}
                            isTSI={isTSI}
                            projectDetails={projectDetails}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </SidePanel>
              ) : (
                ''
              )}
              {viewComments ? (
                <SidePanel title="Comments" size="medium" onClose={() => setViewComments(false)} showView="true">
                  <div className=" bg-white specify-side">
                    <div className="">
                      <Comments comments={comments} />
                    </div>
                  </div>
                </SidePanel>
              ) : (
                ''
              )}
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <>
                  {!editable ? (
                    <>
                      {loadingYard ? <Loader /> : (
                        <>
                          <div className="d-flex justify-content-between mt-3 mb-2 px-2 d-none1">
                            <div className="head-16">{specList?.rfqData?.yardName}</div>
                            <button
                              type="button"
                              className="primary-btn ms-auto me-4"
                              onClick={() => {
                                exportYard();
                              }}
                            >
                              Download Quote
                            </button>
                            <button
                              type="button"
                              className="primary-btn"
                              onClick={() => {
                                setShowUpload(true);
                              }}
                            >
                              Attachment
                            </button>

                          </div>

                          <ReceivedQuotesHeader
                            onSearch={onSearch}
                            searchText={searchText}
                            specList={specList}
                            setSearchText={setSearchText}
                          />
                          {specList?.sectionData?.length > 0 ? (
                            <ReceivedQuotesTable
                              specList={specList}
                              mainSec={mainSec}
                              showsubSec={showsubSec}
                              setParentSpecId={setParentSpecId}
                              onSecOpen={onSecOpen}
                              setShowMore={setShowMore}
                              viewMoreComments={viewMoreComments}
                              viewMoreAttachments={viewMoreAttachments}
                            />
                          ) : (
                            <div className={loading ? 'opacity-0' : 'mt-5 p-4 d-flex justify-content-center align-items-center'}>
                              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                              <div className="font-18 animate__animated animate__headShake">No data found</div>
                            </div>
                          )}

                          <ReceivedQuotesFooter specList={specList} />
                        </>
                      )}
                    </>
                  ) : (
                    <EditQuote
                      specList={specList}
                      setSpecList={setSpecList}
                      setEditable={setEditable}
                      getSpecListData={getSpecListData}
                      showUpload={showUpload}
                      setShowUpload={setShowUpload}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <div className={loading ? 'opacity-0' : 'mt-5 p-4 d-flex justify-content-center align-items-center'}>
              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
              <div className="font-18 animate__animated animate__headShake">No data found</div>
            </div>
          )}
        </div>
      )}
      {!editable && (
        <div className="fixed-footer card d-flex justify-content-end">
          <div className="text-black font-12 col-6">
            <div className="footer-jobprogress">© 2024 ThinkPalm</div>

          </div>
          {isAdmin && subHeaderList && subHeaderList?.length > 0
          && !projectDetails?.isRepairStarted ? (
            <button
              type="button"
              onClick={() => {
                setEditable(true);
              }}
              className="secondary-btn large me-4"
              data-tooltip-id="project-heade"
              data-tooltip-content="Edit quote on behalf of yard"
            >
              <span className="icon-edit font-16 me-1" />
              <span>Edit</span>
            </button>
            ) : (
              ''
            )}
          <button
            type="button"
            className={quoteLength > 0 ? 'me-4 primary-btn large' : 'me-4 primary-btn large disabled'}
            onClick={() => {
              if (quoteLength > 0) {
                navigate(`/user/project/${projectId}/tender/compare-quotes?vesselId=${projectDetails?.vesselId}`);
              }
            }}
          >
            {!state?.redirect && projectDetails?.tenderApprovalStatus === 0 ? 'Compare Quote' : 'Continue Comparison'}
          </button>
        </div>
      )}
      {showUpload ? (
        <SidePanel title="Tender Documents" showView={showUpload} onClose={closeAddModal} bgColor="bg-sky-white">
          <YardDocuments
            isShow={showUpload2}
            setIsShow={setShowUpload2}
            setSpecList={setSpecList}
            specList={specList}
            saveQuote={saveQuote}
            isView={projectDetails?.isRepairStarted ? 'view' : ''}
          />
        </SidePanel>
      ) : (
        ''
      )}
      <Tooltip id="project-heade" place="top" className="tooltip" />
    </div>
  );
};
export default ReceivedQuotes;
