/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectHasApprover } from '../../../NewProject/services';

const SubmitView = (props) => {
  const { projectId, setIsSubmitted, specList, setListOfSpecs,
    getEmptySpecListSupplementary, setIsSupplementaryClicked, backtoList } = props;
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(projectId, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  const navigate = useNavigate();
  let x;
  const backtoProjectListing = () => {
    clearInterval(x);
    navigate('/user/projects');
  };

  const backtoProjects = () => {
    // supplemetarySpecLists({ projectId: id, status: 2 }).then((res) => {
    //   if (res.success) {
    //     setListOfSpecs(res.data);
    //   }
    // });
    // backtoList();
    // clearInterval(x);
    setIsSubmitted(false);
    // supplemetarySpecLists({ projectId: id, status: 2 }).then((res) => {
    //   if (res.success) {
    //     setListOfSpecs(res.data);
    //   }
    // });
    // setIsSupplementaryClicked(false);
    getEmptySpecListSupplementary();
  };
  const timer = () => {
    const countDownDate = new Date(moment().add(1, 'minutes')).getTime();
    x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById('countdownTimer').innerHTML = `${minutes}m ${seconds}s `;
      if (distance < 0) {
        clearInterval(x);
        backtoProjectListing();
        // backtoProjects();
      }
    }, 1000);
  };
  useEffect(() => {
    timer();
  }, []);
  useEffect(() => {
    return () => {
      clearInterval(x);
    };
  }, []);
  return (
    <div className="d-flex justify-content-center flex-column align-items-center mt-110" data-testid="sp-submit">
      <span className="icon-check-rnd tick-green font-50 pb-4" />
      <span className="head-18">
        {`Supplementary spec has been
        ${specList && specList?.length > 0 && specList[0]?.isRevision ? 're' : ''}
        submit`}
        ted successfully !!
      </span>
      <div className="mt-3">{hasApprover ? <p className="font-15 font-semibold">Supplementary spec has been sent for the next level of approval process</p> : ''}</div>
      <div className="p-3"><button type="button" className="primary-btn large m-3 p-3" data-testid="back" onClick={() => { backtoProjects(); }}>Back to Supplementary Specs</button></div>
      <div className="d-flex text-center ms-5">
        <p className="d-flex mt-2 ms-3">
          System will automatically be redirected into home page -within
          <div id="countdownTimer" className="ms-3 font-semibold" />
        </p>
      </div>
    </div>
  );
};
export default SubmitView;
