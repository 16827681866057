/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';

const Navbar = (props) => {
  const { changeVisibilitymap, visiblesubSpec, onSearch, searchText, downloadExcel, workOrder,
    setSearchText, showUpload, isShowImport, isVesselUser, projectBasicDetails, loading } = props;

  return (
    <div className="doc-shadow  doc-subhead d-flex  align-items-center" data-testid="woha-nav">
      <div className="d-flex col-12 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 half-width spec-search">
          <div className="search  col-9  large">
            <div className="d-flex justify-content-end">
              <span
                className="icon-search"
                id="Searching"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
              <span
                className={searchText ? ' icon-close-line ' : ''}
                id="click1"
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
            </div>
            <input
              type="text"
              placeholder="Search by Name, WO/Defect No."
              id="search"
              className="text-box large with-close"
              value={searchText}
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <button type="button" disabled={!isShowImport || isVesselUser || projectBasicDetails?.isClosed || loading || projectBasicDetails?.phase > 4} className="primary-btn mt-1 whitespace-pre" onClick={showUpload}>
            Import Excel
          </button>
          <button type="button" className="primary-btn mt-1 whitespace-pre" disabled={loading || workOrder?.items?.length === 0} onClick={downloadExcel}>Download Excel</button>
          <div className="d-flex">
            <div
              className="ms-5 mt-2 check-box whitespace-pre"
            >
              <input
                type="checkbox"
                name="11"
                id="mapped"
                checked={visiblesubSpec?.indexOf('0') > -1}
                onClick={() => {
                  changeVisibilitymap('0');
                }}
              />
              <label htmlFor="mapped">Mapped</label>
            </div>
            <div className="ms-4 me-2 mt-2 check-box whitespace-pre">
              <input
                type="checkbox"
                id="unmapped"
                name="22"
                checked={visiblesubSpec?.indexOf('1') > -1}
                onClick={() => {
                  changeVisibilitymap('1');
                }}
              />
              <label htmlFor="unmapped">UnMapped</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
