/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Select from 'react-select';
import { getOverAllTaskCounts } from '../../Projects/ListProject/services';

const chart = {
  datasets: [
    {
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: ['#3398dc', '#dd2412', '#9c59b8', '#25ba9c', ' #a6c1cc', '#FF9950'],
      borderColor: ['rgba(255, 255, 255)', 'rgba(255, 255, 255)', 'rgba(255, 255, 255)'],
      borderWidth: 3,
      cutout: 98,
      radius: 100,
      width: 50

    }
  ]
};
ChartJS.register(ArcElement, Tooltip, Legend);
const statusColor = {
  'To Do': '#3398dc',
  Overdue: '#dd2412',
  'In Progress': '#9c59b8',
  Completed: '#25ba9c',
  'Not Applicable': ' #a6c1cc',
  'On Hold': '#FF9950'
};
const Task = (props) => {
  const { projectNameList, selectedproject, setSelectedProject } = props;
  const [taskCounts, setTaskCounts] = useState([]);
  const [chartData, setChartData] = useState(chart);
  const [loader, setLoader] = useState(true);
  const [msg, setMsg] = useState('');
  const [totalSum, setTotalSum] = useState(0);

  const colors = [];

  const overAllTaskCounts = () => {
    setLoader(true);
    getOverAllTaskCounts({
      phaseId: '0',
      projectId: selectedproject
    }).then((res) => {
      setTaskCounts(res?.data?.taskStatus);
      res?.data?.taskStatus?.map((number) => {
        colors.push(number.count);
        return null;
      });
      const currentChartData = { ...chartData };
      currentChartData.datasets[0].data = colors;
      setChartData(currentChartData);
      setLoader(false);
    });
  };

  const handleChange = (e) => {
    console.log(e, 'e');
    setSelectedProject(e.id);
  };
  useEffect(() => {
    if (selectedproject) {
      overAllTaskCounts();
    }
  }, [selectedproject]);
  let hasData = false;
  chartData?.datasets.forEach((items) => items.data.forEach((item) => {
    if (item !== 0) {
      hasData = true;
    }
  }));
  useEffect(() => {
    console.log(taskCounts, 'taskCounts');
    if (taskCounts?.length > 0) {
      const totalStatusCount = taskCounts?.map((items) => items.count);
      const totals = totalStatusCount?.reduce((total, item) => total + item);
      setTotalSum(totals);
    }
  }, [taskCounts]);

  return (
    <div className="widget-card">

      <div className="head">
        <h4 className="head-16 ">Task</h4>
        {/* <div className="couter-bubble status-due">05</div> */}
      </div>

      <div className="body">
        <div data-testid="task-dashboard">
          <div className="">
            <Select
              value={projectNameList.filter((options) => options.id === selectedproject)}
              onChange={(e) => handleChange(e)}
              className="modal-select"
              label="Project Name"
              // menuPosition="fixed"
              options={projectNameList}
              id="projectNameList"
            />
          </div>
          <div className="doughnut">
            {!loader && (hasData) ? (
              <Doughnut data={chartData} />
            ) : (
              <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="card-wrapper">
                <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                <div className="font-18 animate__animated animate__headShake">
                  No record found!!
                </div>
              </div>
            )}
          </div>
          {totalSum !== 0 && (
          <ul className="d-inline-flex flex-wrap">
            {taskCounts?.map((status) => {
              return (
                <li className="d-flex align-items-center half-width mb-4">
                  <span className="icon-disc me-3" style={{ color: statusColor[status.name] }} />
                  <div>{status.name}</div>
                  <div className="ms-2 couter-bubble status due">{status.count}</div>
                </li>
              );
            })}
          </ul>
          )}

        </div>
      </div>
    </div>
  );
};
export default Task;
