/* istanbul ignore file */
import React, { useState } from 'react';

const CustomButton = (props) => {
  const { buttonName, onClick, disabled, time = 5000 } = props;
  const [state, setstate] = useState(false);
  const handleClick = () => {
    setstate(true);
    onClick();
    setTimeout(() => {
      setstate(false);
    }, time);
  };
  return (
    <button
      type="button"
      {...props}
      onClick={() => handleClick()}
      disabled={state || disabled}
    >
      {buttonName}
    </button>
  );
};
export default CustomButton;
