import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
// import Loader from '../../../common/loader2';

function Addattachments(props) {
  const { formData, ondrop, setFormData, initialValue, loading } = props;
  const deleteFile = () => {
    setFormData(initialValue);
    if (document?.getElementById('upload')) {
      document.getElementById('upload').value = '';
    }
  };
  if (loading) {
    return (
      <div data-testid="dms-8" className={`drag-drop-file my-4 ${formData.name && 'file-added '}`}>
        {/* <Loader /> */}
      </div>
    );
  }
  return (
    <>
      <div data-testid="dms-8" className={`drag-drop-file my-4 ${formData.name && 'file-added '}`}>
        <div className="icon-buble mb-2">
          <span className="icon-file-text2" />
        </div>
        <input className="p-5 align-self-center" id="upload" title="" type="file" onChange={(e) => { ondrop(e); }} />
        {!formData.name && (
          <p>Choose a file or drag it here(jpeg, jpg, png, tiff, pdf, doc)</p>
        )}
        {formData.name && (
          <div className="font-18 d-flex attached-file">
            <span className="icon-check-rnd me-2 font-20"> </span>
            <div className="text-ellipsis">
              <span
                data-tooltip-id="project-dms"
                data-tooltip-content={`${formData?.name}`}
              >
                {_.truncate(formData?.name, {
                  length: 20,
                  separator: '..'
                })}

              </span>

            </div>
            <button type="button" id="delete" className="link-btn clear-file" onClick={() => { deleteFile(); }}>
              <span className="icon-delete ms-4 font-20 "> </span>
            </button>
            <Tooltip id="project-dms" place="top" className="tooltip mt-3" />
          </div>
        )}

      </div>
    </>

  );
}
export default Addattachments;
