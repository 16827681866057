import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { fileImages } from '../../../common/mappingConst';

const Cards = (props) => {
  const { deleteData, userRole, allFiles } = props;
  return (
    <table className="card-table" data-testid="card-rdoc">
      <thead>
        <tr>
          <th>Documents</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>

        <>
          {allFiles?.map((items) => {
            return (
              <tr>

                <td>
                  <div className="d-flex align-items-center">

                    <div className="thmb-icon-md me-3">
                      <span className={fileImages[_.toLower(items?.fileExtension)]} />
                      {/* <img src={fileImages[urlData?.fileExtension]}
                    alt="" style={{ height: 24 }} /> */}
                    </div>
                    <a
                      href={items.physicalFileName}
                      target="_blank"
                      download="downloaded_file"
                      className=""
                      rel="noreferrer"
                    >
                      <p
                        className="text-ellipsis"
                        data-tooltip-id="project-urlData"
                        data-tooltip-content={items?.name}
                      >
                        {_.truncate(items?.name, {
                          length: 100,
                          separator: ' '
                        })}
                      </p>
                      <Tooltip id="project-urlData" place="top" className="tooltip mt-3" />
                    </a>
                  </div>
                </td>
                <td style={{ width: '150px' }}>

                  <button type="button" className="link-btn large">
                    <a href={items?.physicalFileName} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                      <span className="icon-download font-22" />
                    </a>
                  </button>
                  {userRole === 'Admin' ? (

                    <button
                      id="delete"
                      type="button"
                      data-tooltip-id="project-urlData"
                      data-tooltip-content={items?.mappedMessage}
                      disabled={items.isMapped}
                      className="link-btn large ms-4"
                      onClick={() => { deleteData(items); }}
                    >
                      <span className="icon-delete font-22" />
                    </button>

                  ) : ''}
                </td>

              </tr>

            );
          })}
        </>

      </tbody>
    </table>

  );
};

export default Cards;
