import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import helper from '../../../../utils/helper';
import InputType1 from '../../../common/InputType1';

const AddFleets = (props) => {
  const { formData, setFormData, managementList, vesselList,
    getVesselLists, selectedVessels, setSelectedVessels, managementId } = props;
  const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
  const [managementName, setManagementName] = useState(formData?.managementName);
  const { handleValidation } = helper;
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;
      setManagementName(e?.label);
      getVesselLists(e?.value, fd?.id || ZERO_UUID);
      if (managementId === e?.value) {
        setSelectedVessels(fd?.vesselList);
      } else {
        setSelectedVessels([]);
      }
    } else {
      fd[field] = e?.target?.value;
    }

    setFormData(fd);
    handleValidation(['name', 'managementId'], fd, field);
  };
  const handleCheck = (event, item) => {
    let updatedList = [...selectedVessels];
    if (event.target.checked) {
      updatedList = [...selectedVessels, item];
    } else {
      updatedList.splice(selectedVessels.indexOf(item), 1);
    }
    setSelectedVessels(updatedList);
  };

  const onDeleteClick = (item) => {
    const updatedList = [...selectedVessels];
    updatedList.splice(selectedVessels.indexOf(item), 1);
    setSelectedVessels(updatedList);
  };
  return (
    <div className="add-fleet scroll-wrap p-3" data-testid="v-addFleets">
      <div className="row">
        <div className="col-6">
          <InputType1
            autoFocus
            className="modal-input"
            maxLength="100"
            id="name"
            isMandatory="true"
            value={formData?.name}
            label="Fleet Name"
            onChange={(e) => handleChange(e, 'name', '')}
          />
        </div>
        <div className="col-6">
          <span data-tooltip-id="project-fleet" data-tooltip-content={managementName}>
            <label>
              Management Name
              <span className="text-primaryRed-1">*</span>
            </label>
          </span>

          <Select
            className="modal-select mt-2"
            label="code"
            id="managementId"
            menuPosition="fixed"
            placeholder="Select"
            options={managementList}
            value={managementList.filter((option) => option.value === formData?.managementId)}
            onChange={(option) => handleChange(option, 'managementId', 'select')}
          />
        </div>
      </div>
      {vesselList && vesselList?.length > 0 && (
        <div>
          <p className="mt-3">Choose Vessels</p>
          <div className="vessel-table">
            <>
              {selectedVessels && selectedVessels?.length > 0 && (
                <ul className="list">
                  {selectedVessels.map((item) => {
                    return (
                      <li className="selected-item">
                        <p>{item?.name}</p>
                        <span id="click1" className="icon-delete font-18 ms-3 me-2" onClick={() => onDeleteClick(item)} />
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
            <ul className="list">
              {vesselList
                && vesselList?.length > 0
                && vesselList.map((item) => {
                  return (
                    <li className="item-options">
                      <input
                        type="checkbox"
                        value={item?.id}
                        id="checkbox"
                        checked={selectedVessels.some((e) => e.id === item.id)}
                        onChange={(event) => handleCheck(event, item)}
                      />
                      <label htmlFor="11">{item?.name}</label>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )}
      <Tooltip id="project-fleet" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddFleets;
