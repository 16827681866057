import React from 'react';

export const statusDot = (status) => {
  if (status === 0) {
    return <span className="icon-disc font-12 pe-2 red" />;
  } if (status === 1) {
    return <span className="icon-disc font-12 pe-2" style={{ color: '#2269D5' }} />;
  } if (status === 2) {
    return <span className="icon-disc font-12 pe-2" style={{ color: 'rgb(22, 181, 249)' }} />;
  } if (status === 3) {
    return <span className="icon-disc font-12 pe-2" style={{ color: '#e5e1e6' }} />;
  } if (status === 4) {
    return <span className="icon-disc font-12 pe-2 green" />;
  } if (status === 5) {
    return <span className="icon-disc font-12 pe-2" style={{ color: '#ff9950' }} />;
  } if (status === 6) {
    return <span className="icon-disc font-12 pe-2" style={{ color: '#facc00' }} />;
  }
  return <span className="icon-disc font-12 pe-2" />;
};

export const SelectDropDown = [
  { label: 'Not Started', value: 0 },
  { label: 'In Progress', value: 1 },
  { label: 'Completed', value: 4 },
  { label: 'On Hold', value: 5 },
  { label: 'Deferred to next DD', value: 6 },
  { label: 'Cancelled', value: 3 },
  { label: 'Delayed', value: 2 }
];
export const SelectDropDownVesselUser = [
  { label: 'Not Started', value: 0 },
  { label: 'In Progress', value: 1 }
];
export const assigneeCode = {
  1: 'Y',
  2: 'V',
  3: 'S'
};
export const jobNameMap = {
  0: 'Not Started',
  1: 'In Progress',
  2: 'Delayed',
  3: 'Cancelled',
  4: 'Completed',
  5: 'On Hold',
  6: 'Deferred to next DD'
};
export const pieChartConfig = [
  {
    key: 'notStartedCount',
    color: '#D1414B', // red
    label: 'Not Started'
  },
  {
    key: 'inProgressCount',
    color: '#2269D5',
    label: 'In Progress'
  },
  {
    key: 'completedCount',
    color: '#36AC79', // green
    label: 'Completed'
  },
  {
    key: 'onHoldCount',
    color: '#ff9950',
    label: 'On Hold'
  },
  {
    key: 'deferredToNextDD',
    color: '#facc00',
    label: 'Deferred To Next DD'
  },
  {
    key: 'cancelledCount',
    color: '#e5e1e6',
    label: 'Cancelled'
  },
  {
    key: 'delayedCount',
    color: 'rgb(22, 181, 249)',
    label: 'Delayed'
  }

];
export const chartColors = [
  '#D1414B',
  '#2269D5',
  '#36AC79',
  '#ff9950',
  '#facc00',
  '#e5e1e6',
  'rgb(22, 181, 249)'

];
export const chartValues = (field) => {
  return [
    field?.notStartedCount,
    field?.inProgressCount,
    field?.completedCount,
    field?.onHoldCount,
    field?.deferredToNextDD,
    field?.cancelledCount,
    field?.delayedCount
  ];
};
