import React, { useState } from 'react';
import WorkDoneReport from './workDoneReport';

const WorkDoneReportsWrap = (props) => {
  const { specList, data } = props;
  const [expand, setExpand] = useState(false);
  const filterBySpec = (list, assignees) => {
    const sectionList = [];
    list?.map((item1) => {
      const assignedSpec = item1?.specs
        ?.filter((specs) => assignees?.includes(specs?.specAssigned));
      if (assignedSpec?.length > 0) {
        sectionList?.push(item1);
        sectionList[sectionList.length - 1].specs = assignedSpec;
      }
      return null;
    });
    return sectionList;
  };
  return (
    <div className="border md-radius" data-testid="w_report">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer'}
            onClick={() => { setExpand(!expand); }}
            id="w_expand"
          />
        </div>
      </div>
      {expand
      && (
      <div className="">
        {filterBySpec(specList, [0, 1]).length > 0 && (
        <>
          <div className="mt-4 mb-1 ms-3 font-semibold">Work Done - Yard</div>
          <WorkDoneReport specList={filterBySpec(specList, [0, 1])} />
        </>
        )}
        {filterBySpec(specList, [2]).length > 0 && (
        <>
          <div className="mt-4 mb-1 ms-3 font-semibold">Work Done - Vessel</div>
          <WorkDoneReport specList={filterBySpec(specList, [2])} />
        </>
        )}
        {filterBySpec(specList, [3]).length > 0 && (
        <>
          <div className="mt-4 mb-1 ms-3 font-semibold">Work Done - Sub Contractor</div>
          <WorkDoneReport specList={filterBySpec(specList, [3])} />
        </>
        )}
      </div>
      )}
    </div>
  );
};
export default WorkDoneReportsWrap;
