/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../../common/pagination';

const WoReportTable = ({ tableData, downloadReport,
  setPage, setPageSize, page, pageSize, getColor }) => {
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  return (
    <div className="p-4" data-testid="woProgressReportComponent">
      {tableData && tableData.items && tableData?.items?.length ? (
        <>
          <div>
            <div className="border-down row py-3">
              <div className="col-3 font-semibold">WO/Defect No.</div>
              <div className="col-4 font-semibold">WO/Defect Name</div>
              <div className="col-2 font-semibold p-0">Spec/NonSpec</div>
              <div className="col-2 font-semibold p-0">Status</div>
            </div>
            <div className="scroll-wrap">
              {tableData?.items.map((i) => {
                return (
                  <div className="border-down row py-3">
                    <div className="col-3 text-ellipsis ">
                      <div className="text-ellipsis">
                        <span data-tooltip-id="project-job" data-tooltip-content={i?.woNo}>
                          {i?.woNo}
                        </span>

                      </div>
                    </div>
                    <div className="col-4 text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i.woName}>
                        {i?.woName || '-'}
                      </span>

                    </div>
                    <div className="col-2 text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i?.specificationNo ? i?.specificationNo + '-' + i?.description || '-' : i?.description}>
                        {i?.specificationNo ? i?.specificationNo + '-' + i?.description : i?.description}
                      </span>
                    </div>
                    <div className="col-2 text-ellipsis">
                      <div className="d-flex align-items-center">
                        <span
                          className="icon-disc"
                          style={{ color: getColor(i?.status) }}
                        />
                        <div className="ms-2 opacity-6">
                          <span data-tooltip-id="project-job" data-tooltip-content={i?.status}>
                            {i?.status}
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {tableData?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-5">
              <div className="footer-sof">© 2024 ThinkPalm</div>

            </div>
            <button
              onClick={() => downloadReport()}
              type="button"
              className="primary-btn large mx-3"
            >
              Download Report
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="card d-flex fixed-footer justify-content-center">
            <div className="text-black font-12">
              <div className="footer-sof">© 2024 ThinkPalm</div>
            </div>
          </div>
        </>
      )}
      <Tooltip id="project-job" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default WoReportTable;
