import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Addattachment from '../editQuote/Addattachment';
import FileDownload from '../../../../../common/FileDownload';
import helper from '../../../../../../utils/helper';

const YardDocuments = (props) => {
  const { specList, saveQuote, isShow, setIsShow, isView } = props;
  const { roles } = helper;

  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = (userRole === roles.AUTHOR)
  || (userRole === roles.EDITOR)
  || (userRole === roles.ADMIN);
  return (
    <div data-testid="yard-do">
      {isShow ? (
        <div>
          <div className="card">
            {specList.rfqData?.attachments[0]?.documents?.length ? (
              <>
                <div className="p-4 font-semibold">Yard Terms And Conditions Documents </div>
                {specList.rfqData?.attachments[0]?.documents?.map((item) => {
                  return (
                    <>
                      <div className="d-flex item  p-4">
                        <span className="icon-check-rnd me-2 file-added-multi font-20"> </span>
                        <div className="px-3" data-tooltip-id="project-quote" data-tooltip-content={item.name}>
                          {_.truncate(item.name, {
                            length: 50,
                            separator: ''
                          })}
                        </div>
                        <FileDownload id={item?.path} name={item.name} />
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              ''
            )}
            {specList.rfqData?.attachments[1]?.documents?.length ? (
              <>
                <div className="p-4 font-semibold">Tender Documents </div>
                {specList.rfqData?.attachments[1]?.documents?.map((item) => {
                  return (
                    <>
                      <div className="d-flex item  p-4">
                        <span className="icon-check-rnd me-2 file-added-multi font-20"> </span>
                        <div className="px-3" data-tooltip-id="project-quote" data-tooltip-content={item.name}>
                          {_.truncate(item.name, {
                            length: 50,
                            separator: ''
                          })}
                        </div>
                        <FileDownload id={item?.path} name={item.name} />
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              ''
            )}
          </div>
          {specList.rfqData?.attachments[0]?.documents?.length
          || specList.rfqData?.attachments[1]?.documents?.length ? (
            <div />
            ) : (
              <div className="border d-flex justify-content-center p-5">No data found</div>
            )}
          {isView === 'view' || !isTSI ? (
            ''
          ) : (
            <div
              className="font-28 mt-2 round-btn"
              id="addnew"
              onClick={() => {
                setIsShow(false);
              }}
            >
              +
            </div>
          )}
        </div>
      ) : (
        <div className="card p-4 no-h">
          <Addattachment {...props} />
          <div className="col-gap-1 d-flex justify-content-end">
            <button type="button" className="secondary-btn" onClick={() => setIsShow(true)}>
              Back
            </button>
            <button type="button" className="primary-btn" onClick={() => saveQuote()}>
              Save
            </button>
          </div>
        </div>
      )}
      <Tooltip id="project-quote" place="top" className="tooltip mt-2" />
    </div>
  );
};
export default YardDocuments;
