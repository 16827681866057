import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const Sidebar = (props) => {
  const { onAddRole, currentRole,
    onEditClick, roleList, deleteRole, onSelectRole, showWorkFlow, showPermissions } = props;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  return (
    <div className=" container" data-testid="role-sidebar">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <button id="add" className="primary-btn " type="button" onClick={() => { onAddRole(); }}>
            <span className="icon-add-user font-20 me-2" />
            Add User Role
          </button>

        </div>
        <div className="d-flex">
          <button id="show-wf" className="secondary-btn me-4" type="button" onClick={() => { showWorkFlow(); }}>Workflow Approval </button>
          <button id="show-permissions" className="secondary-btn  " type="button" onClick={() => { showPermissions(); }}>Permissions </button>
        </div>
      </div>
      <div className="role-scroll">
        <ul className="menu-item mt-3 role-list">
          {roleList.map((item) => {
            return (
              <li className={`card mt-2 p-4 d-flex justify-content-between align-items-center ${currentRole === item.id ? '' : ''}`} key={item.id}>
                <div className="d-flex align-items-center justify-content-start" onClick={() => onSelectRole(item)}>
                  <div className="icon"><span className="icon-user-filled" /></div>
                  <span
                    className="text-ellipsis ps-4"
                    data-tooltip-id="project-sidename"
                    data-tooltip-content={`${item.name}`}
                  >
                    {item.name}
                  </span>
                </div>
                <div className="d-flex">
                  <div>
                    {' '}
                    <button
                      id="edit"
                      type="button"
                      className="link-btn me-2"
                      onClick={() => { onEditClick(item); }}
                    >
                      <span className="icon-edit font-22" />
                    </button>

                  </div>
                  <div
                    data-tooltip-id="project-sidename2"
                    data-tooltip-content={item?.mappedMessage}
                  >
                    {' '}
                    <button
                      id="delete"
                      type="button"
                      className="link-btn"
                      disabled={item.isMapped}
                      onClick={() => { deleteRole(item); }}
                    >
                      <span className="icon-delete font-22" />
                    </button>

                  </div>
                  <Tooltip id="project-sidename2" place="top" className="tooltip mt-3" />

                </div>
              </li>
            );
          })}
          <Tooltip id="project-sidename" place="top" className="tooltip mt-3" />
        </ul>
      </div>
      <div className="footer justify-content-between font-13">
        <PolicyFooter
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          setShowTermsPolicy={setShowTermsPolicy}
        />
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
      <TermsOfUse
        setShowTermsPolicy={setShowTermsPolicy}
        showTermsPolicy={showTermsPolicy}
      />
      )}
    </div>
  );
};
export default Sidebar;
