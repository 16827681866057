import React from 'react';
import InnerTable from './InnerTable';
import Loader from '../../../../../../common/loader';

const ComponentDetails = (props) => {
  const { formData, loading } = props;
  return (
    <div className="side-panel__container" data-testid="com-1">
      {loading ? <Loader /> : (
        <div className="side-panel__content bg-white">
          {formData && formData.length
            ? <InnerTable {...props} />
            : <div className="d-flex justify-content-center pt-5">No data found</div>}
        </div>
      )}
    </div>
  );
};
export default ComponentDetails;
