import React, { useState } from 'react';
import moment from 'moment';
import { projList } from './Constants';
import MajorSpecPackagesList from '../../../../Admin/DataConfig/SpecificationSetup/SpecPackages/MajorSpecPackagesList';
import helper from '../../../../../utils/helper';
// import showAlert from '../../../../../utils/alert';

const ProjectDetails = (props) => {
  const { info, projInfo, IsemergencyDryDock } = props;
  const { formatNumber } = helper;
  const [isExpand, setIsExpand] = useState('');

  return (
    <div className="card no-shadow" data-testid="projdeets">
      <div className="d-flex flex-wrap">
        {projList.map((item) => {
          return (
            <div key={item}>
              {item.isDate ? (
                <div className="d-flex col-12 mt-2">
                  <label className="col-6">{item.label}</label>
                  <div className="value col-6">{info && info[item.key] && moment(info[item.key]).format('DD-MMM-YYYY')}</div>
                </div>
              ) : item.isAmount ? (
                <div className="d-flex col-12 mt-2">
                  <label className="col-6">{item.label}</label>
                  <div className="value col-6">
                    {info.currencySymbol}
                    {' '}
                    {info && formatNumber(info[item.key])}
                  </div>
                </div>
              ) : item.isPlanned ? (
                projInfo.projectType.toLowerCase().includes('plan') && (
                  <div className="d-flex col-12 mt-2">
                    <label className="col-6">{item.label}</label>
                    <div className="value col-6">{projInfo && projInfo[item.key]}</div>
                  </div>
                )
              ) : (
                <div className="d-flex col-12 mt-2">
                  <label className="col-6">{item.label}</label>
                  <div className="value col-6">{info && info[item.key]}</div>
                </div>
              )}
            </div>
          );
        })}
        {projInfo?.vesselMappedUsers?.map((i) => (
          <div className="d-flex col-12 mt-2" key={i}>
            <label className="col-6 d-flex">
              {i.roleName}
              <div className="ms-1">:</div>
            </label>
            <div className="value col-6">{i.userName}</div>
          </div>
        ))}
      </div>
      {projInfo?.projectJobList.length > 0 && !IsemergencyDryDock && (
        <div className="mb-5 pt-5">
          <h4 className="head-14 mb-4">List of jobs to be carried out</h4>
          {projInfo?.projectJobList.map((element) => {
            return (
              <div className="d-flex mt-4" key={element.id}>
                <span className="icon-check-rnd tick-green font-26" />
                <p className="ms-4 head-black">{element?.title}</p>
              </div>
            );
          })}
        </div>
      )}
      {projInfo?.specificationList.length > 0 && IsemergencyDryDock && (
        <div className="mb-5 pt-5">
          <p className="font-20 head-black">GT specs - Emergency Dry Docking</p>
          <MajorSpecPackagesList isExpand={isExpand} formDataToFillList={projInfo?.specificationList} setIsExpand={setIsExpand} from="confirm" />
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
