import React from 'react';
import ModalBox from '../../../../../../common/ModalBox';

const ConfirmReceivedModal = (props) => {
  const { isCloseModal, handleCloseAll } = props;
  return (
    <div>

      <ModalBox
        modalClassName="medium"
        // onSave={() => onSubmit()}
        isCloseModal={isCloseModal}
      >
        <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">

          <span className="icon-check-rnd tick-green font-40 pb-3" />

          <div className="mt-3">
            <p className="font-15 font-semibold text-black mb-4">Status has been updated successfully!!</p>
          </div>
          <button type="button" className="primary-btn medium " id="backtoprojects" onClick={handleCloseAll}>
            Close
          </button>
        </div>
      </ModalBox>
    </div>
  );
};
export default ConfirmReceivedModal;
