/*eslint-disable*/
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import helper from '../../../../../utils/helper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const DeviationChart = ({ graphData, variance }) => {
  console.log(graphData, 'graphData');
  const { formatNumber } = helper;
  const options = {
      scales: {
        y: {
          title: {
            display: true,
            text: variance?.currency
          }
        }
      },     
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      }
      
    }
  };

  const labels = ['Specs', 'Non Specs', 'Deviation & offHire'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Project Estimate',
        data: [graphData?.specsProjectEstimate, graphData?.nonSpecsProjectEstimate,
          graphData?.deviationOffhireEstimate],
        backgroundColor: '#f5b1a5'
      },
      {
        label: 'Project Actual',
        data: [graphData?.specsProjectActual, graphData?.nonSpecsProjectActual,
          graphData?.deviationOffhireActual],
        backgroundColor: '#3498db'
      }
    ]
  };


  return (
    <div className="card-width card" style={{ height: '362px' }}>
      <div className="head-16 pb-4 border-down p-4">Deviation and Off Hire</div>
      <div className="row " data-testid="bar-chart">
        <div className="col-8 card-vertical p-5 bar-scale">
          <Bar options={options} data={data} />
        </div>
      </div>
      <Tooltip id="project-phrepair" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default DeviationChart;
