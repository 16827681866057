export const projList = [
  {
    label: 'Project Type :', key: 'projectType'
  },
  {
    label: 'Survey Type :', key: 'surveyType', isPlanned: true
  },
  // {
  //   label: 'Fleet Manager:', key: 'fleetManager'
  // },
  // {
  //   label: 'TSI:', key: 'tsi'
  // },
  // {
  //   label: 'Backup TSI:', key: 'backupTsi'
  // },
  {
    label: 'Estimated Amount :', key: 'estimatedBudget', isAmount: true
  },
  {
    label: 'Drydocking Due Date :', key: 'ddDudate', isDate: true
  },
  {
    label: 'Special Survey Due Date :', key: 'specialSurveyDueDate', isDate: true
  },
  {
    label: 'Tailshaft Survey Due Date :', key: 'tailshaftSurveyDueDate', isDate: true
  },
  {
    label: 'Intermediate Hull Survey Due Date :', key: 'intermediateSurveyDueDate', isDate: true
  }
];
