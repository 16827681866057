import React from 'react';

const ReportModal = ({
  activeComp,
  setActiveComp
}) => {
  return (
    <>
      <div className="" data-testid="report-modal">
        <div className="list-tab-head d-flex pt-2 ps-3 justify-content-start">
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '0' ? ('active') : ('')}`} id="dailyreport" onClick={() => { setActiveComp('0'); }}>
            Daily Report
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '7' ? ('active') : ('')}`} id="photoreport" onClick={() => { setActiveComp('7'); }}>
            Photo Report
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '1' ? ('active') : ('')}`} id="sofreport" onClick={() => { setActiveComp('1'); }}>
            SoF
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '2' ? ('active') : ('')}`} id="jobreport" onClick={() => { setActiveComp('2'); }}>
            Job Progress
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '5' ? ('active') : ('')}`} id="variationreport" onClick={() => { setActiveComp('5'); }}>
            Variation Order
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '6' ? ('active') : ('')}`} id="costreport" onClick={() => { setActiveComp('6'); }}>
            Cost Update
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '3' ? ('active') : ('')}`} id="woreport" onClick={() => { setActiveComp('3'); }}>
            WO
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '4' ? ('active') : ('')}`} id="poreport" onClick={() => { setActiveComp('4'); }}>
            PO
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '8' ? ('active') : ('')}`} id="VarianceReport" onClick={() => { setActiveComp('8'); }}>
            Variance Report
          </div>
          <div className={`py-2 me-4 px-2 tab-items ${activeComp === '9' ? ('active') : ('')}`} id="AcCodeReport" onClick={() => { setActiveComp('9'); }}>
            AC Code Report
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportModal;
