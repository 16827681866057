import React, { useEffect, useState } from 'react';
import { repairSpecDetails, saveWorkOrderStatus } from '../services';
import Components from '../../../../../Quotations/YardQuotation/ViewMore/children/Components';
import Description from '../../../../../Quotations/YardQuotation/ViewMore/children/Description';
import Tags from '../../../../../Quotations/YardQuotation/ViewMore/children/Tags';
import Attachments from '../../../../../Quotations/YardQuotation/ViewMore/children/Attachments';
import Loader from '../../../../../common/loader';
import WoDefect from './childrens/woDefect';
// import PoPr from './childrens/poPr';
import POPR from '../../Specify/SpecsList/POPR';
// import helper from '../../../../../../utils/helper';
import { getProjectDetailsTSI } from '../../../NewProject/services';

const ViewMore = (props) => {
  const { showMore, isTSI, id, isFromDownloadSpec } = props;
  const [active, setActive] = useState('Description');
  const [specData, setSpecData] = useState({});
  const [isTsiDisabled, setIsTsiDisabled] = useState(false);
  const [currency, setCurrency] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  // const { formatNumber } = helper;

  const getProjDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      if (res?.success) {
        setProjectBasicDetails(res.data);
        // setLoading(false);
        // const projInfo = res?.data;
        setCurrency(res?.data?.currencySymbol);
        setCurrencyId(res?.data?.currencyId);
        let disabled = false;
        if (!isTSI || res?.data?.phase > 4) {
          disabled = true;
        }
        // if (localStorage.getItem('USER_ID') === projInfo?.backupTsiId) {
        //   disabled = true;
        // }
        setIsTsiDisabled(disabled);
        if (isFromDownloadSpec) {
          setIsTsiDisabled(isFromDownloadSpec);
        }
      }
    });
  };
  const getRepairSpecDetails = (isRefresh = true) => {
    repairSpecDetails({ id: showMore.projectSpecId }).then((res) => {
      if (res.success) {
        setSpecData(res.data);
        if (isRefresh)setActive('Description');
      }
    });
  };
  const onReload = () => {
    getRepairSpecDetails(false);
  };
  useEffect(() => {
    getRepairSpecDetails();
    getProjDetails();
  }, []);
  /* istanbul ignore next */
  const onStatusChange = (e, item, position) => {
    const currentSpecData = { ...specData };
    currentSpecData.specWohaData[position].status = e.value;
    currentSpecData.specWohaData[position].statusName = e.label;
    setSpecData(currentSpecData);
    saveWorkOrderStatus({
      id: item.id,
      status: e.value
    });
  };
  const renderComponent = (type) => {
    switch (type) {
      case 'Description':
        return (
          <div>
            <Description
              specData={specData}
              isFromDownloadSpec={isFromDownloadSpec}
            />
          </div>
        );
      case 'Components':
        return (
          <div>
            <Components
              specData={specData}
              onStatusChange={onStatusChange}
              onReload={onReload}
            />
          </div>
        );
      case 'Tags':
        return <div><Tags specData={specData} /></div>;
      case 'Attachments':
        return <div><Attachments specData={specData} /></div>;
      case 'WODefectList':
        return (
          <div>
            <WoDefect
              isTSI={isTSI}
              specData={specData}
              onStatusChange={onStatusChange}
              projectBasicDetails={projectBasicDetails}
            />
          </div>
        );
      case 'PoPrList':
        return (
          <div>
            <POPR
              currencyId={currencyId}
              fromSpecify
              currency={currency}
              projectSpecificationId={showMore.projectSpecId}
              isTSI={isTSI}
              isTsiDisabled={isTsiDisabled}
              projectId={id}
              onReload={onReload}
              fromVariation={!isTsiDisabled}
              fullHeight
            />
          </div>
        );
      default:
        return <Loader />;
    }
  };
  return (
    <div data-testid="repair-viewmore">
      <div className="list-tab-head d-flex justify-content-between border-down ">
        <div id="desc" onClick={() => setActive('Description')} className={`tab-items ${active === 'Description' && 'active'}`}>
          Description
        </div>
        <div id="comp" onClick={() => setActive('Components')} className={`tab-items ${active === 'Components' && 'active'}`}>
          Components
        </div>
        <div id="tag" onClick={() => setActive('Tags')} className={`tab-items ${active === 'Tags' && 'active'}`}>
          Tags
        </div>
        <div id="attach" onClick={() => setActive('Attachments')} className={`tab-items ${active === 'Attachments' && 'active'}`}>
          Attachments
        </div>
        <div id="popr" onClick={() => setActive('PoPrList')} className={`tab-items ${active === 'PoPrList' && 'active'}`}>
          PO/PR
        </div>
        <div id="wodefect" onClick={() => setActive('WODefectList')} className={`tab-items ${active === 'WODefectList' && 'active'}`}>
          WO/Defect List
        </div>
      </div>
      <div>{renderComponent(active)}</div>
    </div>
  );
};
export default ViewMore;
