/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import helper from '../../../../../../../utils/helper';

const DependentSpecCard = (props) => {
  const { dependentList, selectedSpec, onSpecSelect, handleInput } = props;
  const { restrictSpecialCharacters } = helper;
  return (
    <div className="border md-radius dependent-height" data-testid="dependent-spec">
      <div className="d-flex border-down col-gap-1  p-4">
        <div className="value">Dependent specs</div>
      </div>
      {dependentList && dependentList?.length > 0 && (

        <div className="d-flex border-down dependent-item ">
          <div className=" value">Priority</div>
          <div className="value ml-12">Specs</div>
        </div>
      )}
      <div className="scrollable-modal-dependent">
        {dependentList && dependentList?.length > 0 ? (

          dependentList?.map((item, index) => {
            return (
              <div key={item.id}>
                <div className="d-flex  justify-content-between mt-1 border-down">
                  <div
                    id={item.projectSpecId}
                    data-testid="spec"
                    className={item.id === selectedSpec.id
                      ? ' d-flex align-items-center pointer  spec-item spec-item-dependant active pointer w-full '
                      : ' d-flex align-items-center pointer  spec-item spec-item-dependant pointer w-full'}
                    onClick={() => { onSpecSelect(item); }}
                  >
                    <input
                      value={item?.order || ''}
                      type="number"
                      id="search"
                      min="1"
                      onKeyPress={(e) => restrictSpecialCharacters(e) && e.preventDefault()}
                      className="text-box me-4 xxsmall text-box-dependant hide-arrows text-center"
                      onChange={(e) => {
                        handleInput(e?.target?.value, index);
                      }}
                    />
                    <span
                      className="count ml-8"
                      data-tooltip-id="project-dep"
                      data-tooltip-content={item.specificationNo}
                    >
                      {_.truncate(item.specificationNo, { length: 8 })}

                    </span>
                    <span
                      className="ml-8"
                      data-tooltip-id="project-dep"
                      data-tooltip-content={item.title}
                    >
                      {_.truncate(item.title, { length: 25 })}

                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : <div className="d-flex justify-content-center pt-5">No data found</div>}
      </div>
      <Tooltip id="project-dep" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default DependentSpecCard;
