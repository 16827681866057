/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { particulars } from './constants';

const Particulars = (props) => {
  const [expand, setExpand] = useState(false);
  const { allDocs, item } = props;
  return (
    <div className="border md-radius">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{item.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer rotate-90' : 'icon-arrow-thin-right font-22 pointer rotate-90'}
            onClick={() => { setExpand(!expand); }}
          />
        </div>
      </div>
      {expand && (
      <div className="row mx-4 my-3">
        {particulars.map((partic) => {
          return (
            <div className="col-6 row">
              <div className="col-6 mb-1">{partic.label}</div>
              <div className="col-6 mb-2 value">
                <span
                  className="text-ellipsis pe-2"
                  data-tooltip-id="project-particularname"
                  data-tooltip-content={allDocs[item.key] ? allDocs[item.key][partic.key] : '-'}
                >
                  {_.truncate((allDocs[item.key] && allDocs[item.key][partic.key]) || '-', {
                    length: 15,
                    separator: ' '
                  })}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      )}
      <Tooltip id="project-particularname" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Particulars;
