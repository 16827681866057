/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _, { cloneDeep } from 'lodash';
import { statusPohaColor } from '../PhasePage/Specify/SpecsList/POPR/utilities';
import ViewMappedSpec from './children/viewMappedSpec';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
import { updatePohaStatus, deletePohaApi } from './services';
import ModalBox from '../../../common/ModalBox';


const PohaCard = (props) => {
  const {
    specList,
    item,
    acCodeList,
    saveSpecOrder,
    selectedCard,
    onSelected,
    onCancel,
    setSelectedCard,
    currency,
    isTSI,
    projInfo,
    isVesselUser,
    projectBasicDetails,
    getPurchaseOrder
  } = props;
  const { formatNumber } = helper;
  const options = [
    { label: 'Draft', value: 0 },
    { label: 'Pending Approval', value: 1 },
    { label: 'Approved', value: 2 },
    { label: 'Ordered', value: 3 },
    { label: 'Cancelled', value: 4 },
    { label: 'Open', value: 5 },
    { label: 'Under Amendment', value: 6 },
    { label: 'GRN Received', value: 7 },
    { label: 'Short Closed', value: 8 },
    { label: 'Closed', value: 9 }
  ];
  const handleSelectChange = (e, key) => {
    const po = cloneDeep(selectedCard);
    if (key !== undefined) {
      let existingId = po.specificationList.map((idItem) => {
        return idItem.specId;
      });
      if (existingId.indexOf(e.value) > -1) {
        showAlert('Already selected', 'warning');
      } else {
        po.specificationList[key].specId = e.value;
      }
    } else {
      po.acCodeId = e.value;
    }
    setSelectedCard(po);
  };

  const addNewSpec = () => {
    const po = cloneDeep(selectedCard);
    po.specificationList.push({ specId: '', isAcCodeMapped: false });
    setSelectedCard(po);
  };
  const onDelete = (key) => {
    const po = cloneDeep(selectedCard);
    if (po.specificationList[key].isAcCodeMapped) {
      po.acCodeId = '00000000-0000-0000-0000-000000000000';
      po.isAcCodeMapped = false;
    }
    po.specificationList.splice(key, 1);
    setSelectedCard(po);
  };
  const onAcCodeMap = (key) => {
    const po = cloneDeep(selectedCard);
    po.specificationList.map((h, index) => {
      if (index === key) {
        po.specificationList[key].isAcCodeMapped = true;
      } else {
        po.specificationList[index].isAcCodeMapped = false;
      }
      return null;
    });
    setSelectedCard(po);
  };

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleSelectStatus = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const updateStatus = () => {
    if (selectedStatus !== null) {
      updatePohaStatus({ id: item.id, status: selectedStatus.value })?.then((res) => {
        if (res.success) {
        } else {
          showAlert('Failed to update status', 'error');
        }
      });
    }
  };

  useEffect(() => {
    updateStatus();
  }, [selectedStatus]);

  const saveChanges = () => {
    if (selectedStatus !== null) {
      updateStatus();
    }
  };

  const deleteApiCall = () => {
    deletePohaApi({ id: item.id }).then((res) => {
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
        setOpenDeleteModal(false);
        getPurchaseOrder();
      }
    });
  };
  return (
    <div className="card p-4  mt-4" data-testid="poha-card">
      <div className="d-flex  col-gap-1  align-items-center mb-2">
        <div className="color-light-blue font-semibold col-2">
          <span data-tooltip-id="project-poha" data-tooltip-content={item.prPoNo}>
            {_.truncate(item?.prPoNo, {
              length: 30,
              separator: '',
            })}
          </span>
        </div>
        <h4 className="head-14 col-6" data-tooltip-id="project-poha" data-tooltip-content={item.description}>
          {_.truncate(item?.description, {
            length: 68,
            separator: '',
          })}
        </h4>
        {selectedCard.id === item.id ? (
          <div className="d-flex col-gap-1 me-2 status-select">
            <Select
              className="modal-select w-full"
              placeholder="Select Status"
              value={selectedStatus ? selectedStatus : options?.filter((i) => i.value === item?.status)}
              onChange={handleSelectStatus}
              options={options}
            />
          </div>
        ) : (
          <div className="">
            <div className="docTypeName status font-bold width-fit" style={{ background: statusPohaColor() }}>
              {item.statusName}
            </div>
          </div>
        )}
        <div className="d-flex align-items-center ms-auto">
          {selectedCard.id !== item.id && item.specificationList.length > 0 && (
            <div className="d-flex justify-content-center col-gap-1 me-2">
              <button
                onClick={() => {
                  onSelected(item); // req
                }}
                disabled={projectBasicDetails?.isClosed}
                className="brand-color font-22"
                type="button"
                id="edit"
              >
                <span className="icon-edit me-1 " />
              </button>
            </div>
          )}
          <button
            className="d-flex w-full"
            type="button"
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            <span className="icon-delete font-24 ms-auto link-btn" />
          </button>
        </div>
      </div>
      <div className="d-flex flex-wrap col-gap-3 mt-2">
        <div className="d-flex">
          <label className="pe-3">
            Category<span className="ms-2">:</span>
          </label>
          <div className="value">{item.category}</div>
        </div>
        <div className="d-flex">
          <label className="pe-3">
            Type<span className="ms-2">:</span>
          </label>
          <div className="value">{item.purchaseType}</div>
        </div>
        <div className="d-flex">
          <label className="pe-3">
            Amount<span className="ms-2">:</span>
          </label>
          <div className="value">{`${item?.currencySymbol ? item?.currencySymbol : ''} ${item?.amount ? formatNumber(item?.amount) : 0}`}</div>
        </div>
        <div className="d-flex">
          <label className="pe-3">
            Owner's Cost<span className="ms-2">:</span>
          </label>
          <div className="value">{`${currency} ${item?.ownerCost ? formatNumber(item?.ownerCost) : 0}`}</div>
        </div>
      </div>
      <div className="border-down my-2" />
      <div className="mb-2 head-14 mt-2">Mapped Specs</div>
      {selectedCard.id === item.id && (
        <>
          <div className="d-flex align-items-center border-down mt-2 pb-2">
            <label className="col-3">Spec Name</label>
          </div>
          {selectedCard.specificationList.map((specItem, key) => {
            return (
              <div className="mt-3 ms-2 d-flex align-items-center">
                <div className="col-6">
                  <Select
                    className="modal-select w-full"
                    placeholder="Select Spec"
                    options={specList}
                    onChange={(e) => {
                      handleSelectChange(e, key);
                    }}
                    value={specItem.specId ? specList.filter((specL) => specL.value === specItem.specId)[0] : null}
                    id="category"
                  />
                </div>
                <button
                  type="button"
                  id="delete"
                  className="link-btn ms-3"
                  onClick={() => {
                    onDelete(key);
                  }}
                >
                  <span className="icon-close font-26"> </span>
                </button>
              </div>
            );
          })}
          <div className="d-flex mt-3">
            <button
              type="button"
              id="add-more"
              className="link-btn ms-3"
              onClick={() => {
                addNewSpec();
              }}
            >
              {' '}
              <span className="icon-plus-square me-2" /> Add More
            </button>
          </div>
          <div className="d-flex justify-content-end  mt-3">
            <button
              type="button"
              id="cancel"
              className="secondary-btn  ms-5"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              id="save"
              className="primary-btn  ms-4"
              onClick={() => {
                saveSpecOrder(item);
                saveChanges();
              }}
            >
              Save
            </button>
          </div>
        </>
      )}
      {selectedCard.id !== item.id && <ViewMappedSpec item={item} />}
      {selectedCard.id !== item.id && item.specificationList.length < 1 && (
        <button
          disabled={!isTSI || projInfo.phase > 4 || projectBasicDetails?.isClosed}
          type="button"
          id="button"
          data-test="click1"
          className="secondary-btn small mt-4"
          onClick={() => {
            onSelected(item, true);
          }}
        >
          Map To Spec
        </button>
      )}
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => { deleteApiCall(); setOpenDeleteModal(false); }}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete the Poha ?</p>
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="project-poha" place="bottom" className="tooltip" />
    </div>
  );
};

export default PohaCard;
