/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import EditModal from '../Children2/EditModal';
import SidePanel from '../../../../../common/sidePanel';
import { getProjectRepair, saveProjectRepair, getTRDChanges } from '../services';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import ModalBox from '../../../../../common/ModalBox';
import Loader from '../../../../../common/loader';
import TRDHistory from './TRDHIstory';
import { GetFuelData } from '../../../../../Admin/DataConfig/GeneralConfig/services';

const ProjectCard = (props) => {
  const { onReloadSpecData, setReloadAfterStart,
    projectBasicDetails, projectRepair,
    startRepairClicked, formData, setFormData,
    editModal, setEditModal, showEditModal,
    hasChange, setHasChange, fuelPriceType1Value, setFuelPriceType1Value,
    fuelPriceType2Value, setFuelPriceType2Value } = props;

  const navigate = useNavigate();
  const { isTSI } = props;
  const { formValidation, formatNumber, roles, ZERO_UUID } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isMaster = userRole === roles.VESSEL_USER;
  const { id } = useParams();
  const params = qs.parse(window.location.search);
  const [confirm, setConfirm] = useState('');
  const [history, setHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const systemCurrency = '$';
  let count = 1;
  const [tableData, setTableData] = useState([]);
  const fuelData = () => {
    GetFuelData({}).then((res) => {
      setTableData(res.data);
    });
  };
  const options = tableData?.map((opt) => {
    return ({ value: opt.id });
  });
  useEffect(() => {
    fuelData();
  }, []);
  const reloadTable = () => {
    const selectedId = options?.value;
    setLoading(true);
    getProjectRepair(id).then((res) => {
      setLoading(false);
      count += 1;
      if (res.data) {
        setFormData(res.data);
        setReloadAfterStart(res.data.isRepairStarted);
      }
    });
  };

  useEffect(() => {
    reloadTable();
  }, []);
  useEffect(() => {
    if (projectRepair) {
      reloadTable();
    }
  }, [projectRepair]);
  const closeEditModal = () => {
    if (hasChange) {
      setConfirm('exit');
    } else {
      setEditModal(false);
    }
  };

  /* istanbul ignore next */
  const save = () => {
    let isValidFuel = true;
    if (!editModal?.fuelTypeOne || editModal?.fuelTypeOne === ZERO_UUID) {
      isValidFuel = false;
    }
    if (!editModal?.fuelTypeTwo || editModal?.fuelTypeTwo === ZERO_UUID) {
      isValidFuel = false;
    }
    if (
      isValidFuel
      && formValidation(
        [
          'lastPort',
          'lastPortDistance',
          'nextPort',
          'nextPortDistance',
          'hireRate',
          'fuelPrice',
          'fuelConsumption',
          'fuelCost',
          'repairDays',
          'deviationDays',
          'offhireDays',
          'deviationCost',
          'offhireCost',
          'dockingDate',
          'etd',
          'repairCompletionDate',
          'arrivalAtYardDate',
          'fuelPriceType2',
          'fuelConsumptionType2',
          'miscCost',
          'repairStartDate',
          'fuelTypeOne',
          'fuelTypeOneName',
          'fuelTypeTwo',
          'fuelTypeTwoName'
        ],
        editModal
      )
    ) {
      const f = cloneDeep(editModal);
      f.dockingDate = new Date(editModal.dockingDate);
      f.repairCompletionDate = new Date(editModal.repairCompletionDate);
      f.arrivalAtYardDate = new Date(editModal.arrivalAtYardDate);
      f.etd = new Date(editModal.etd);
      f.repairStartDate = new Date(editModal.repairStartDate);
      f.isRepairStarted = startRepairClicked ? true : editModal.isRepairStarted;
      f.fuelTypeOne = editModal.fuelTypeOne;
      f.fuelTypeTwo = editModal.fuelTypeTwo;
      f.fuelTypeOneName = editModal.fuelTypeOneName;
      f.fuelTypeTwoName = editModal.fuelTypeTwoName;
      saveProjectRepair(f).then((res) => {
        if (res.success) {
          showAlert('Successfully Updated', 'success');
          reloadTable();
          setEditModal(false);
          onReloadSpecData();
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const handleOnSave = () => {
    const repairStartDate = moment(new Date(editModal.repairStartDate));
    const arrivalAtYardDate = moment(new Date(editModal.arrivalAtYardDate));
    const etd = moment(new Date(editModal.etd));
    const dockingDate = moment(new Date(editModal.dockingDate));
    const repairCompletionDate = moment(new Date(editModal.repairCompletionDate));
    let isValid = '';
    const total = editModal.daysAfloat + editModal?.inDockDays;
    if (etd < arrivalAtYardDate) {
      isValid = 'ETD should be greater than Date Of Arrival At Yard';
    } else if (repairCompletionDate < dockingDate) {
      isValid = 'Un Docking Date should be greater than Docking Date';
    } else if (total !== editModal?.repairDays) {
      isValid = 'TRD should be equal to sum of Days Afloat and Days In Dock';
    } else if (arrivalAtYardDate.diff(repairStartDate, 'days') > 0) {
      isValid = 'Invalid Date Of Arrival At Yard';
    }
    if (editModal.repairStartDate === '') {
      isValid = 'Please enter start repair date';
    }
    const v = String(editModal.lastPort)?.trim();
    if (!v || v === null || v === '' || v === ' ') {
      isValid = 'Please enter Last Porting Location';
    }
    const w = String(editModal.nextPort)?.trim();
    if (!w || w === null || w === '' || w === ' ') {
      isValid = 'Please enter Next Porting Location';
    }
    if (!editModal?.fuelTypeOne || editModal?.fuelTypeOne === ZERO_UUID) {
      isValid = 'Please enter the mandatory(*) fields';
    }
    if (!editModal?.fuelTypeTwo || editModal?.fuelTypeTwo === ZERO_UUID) {
      isValid = 'Please enter the mandatory(*) fields';
    }
    if (isValid === '') {
      setConfirm('save');
    } else {
      showAlert(isValid, 'error');
    }
  };
  const getTRDHistory = () => {
    getTRDChanges({ id }).then((res) => {
      if (res.success) {
        setHistory(res.data);
      }
    });
  };
  useEffect(() => {
    if (showHistory) {
      getTRDHistory();
    }
  }, [showHistory]);
  return (
    <div className="card mb-2 font-13" data-testid="projectCard">
      <div className="align-items-center d-flex justify-content-between px-4 py-3">
        <div className="head-16">Project Cost Summary</div>
        {isMaster ? (
          ''
        ) : (
          <div className="d-flex col-gap-1">
            <button
              type="button"
              disabled={!formData?.isRepairStarted}
              className="secondary-btn secondary-btn-border "
              onClick={() => {
                navigate(`/user/project/${id}/repair/projcost?vesselId=${params.vesselId}`);
              }}
            >
              Cost Summary
            </button>
            <button
              id="edit-cost"
              disabled={!isTSI}
              type="button"
              className="secondary-btn secondary-btn-border "
              onClick={() => {
                showEditModal('');
                setHasChange(false);
              }}
            >
              {projectBasicDetails?.phase > 4 ? 'View' : (
                <>
                  {formData?.lastPort && formData?.nextPort ? 'Edit' : 'Add'}
                </>
              )}
            </button>
          </div>
        )}
      </div>
      <div className="pt-2 border-down" />
      {!loading ? (
        <div className="px-4 py-2 my-2 me-3" style={{ height: '294px', overflow: 'auto' }}>
          <table className="pcs-table">
            <tr>
              <td className="width-300">
                <div className="text-content-grey ">Last Porting Location :</div>
              </td>
              <td>
                <div className="value break-all ">{formData?.lastPort}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-content-grey ">Next Porting Location :</div>
              </td>
              <td>
                <div className="value break-all ">{formData?.nextPort}</div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-content-grey ">Repair Start Date :</div>
              </td>
              <td>
                <div className="align-items-center d-flex justify-content-start value break-all">
                  {formData.repairStartDate ? <span className="brand-color font-20 icon-calander icon-date me-2" /> : ''}
                  <span className="mt-1">{formData.repairStartDate ? moment(formData.repairStartDate).format('DD-MMM-YYYY') : ''}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-content-grey ">Docking Date :</div>
              </td>
              <td>
                <div className="align-items-center d-flex justify-content-start value break-all">
                  {formData.dockingDate ? <span className="brand-color font-20 icon-calander icon-date me-2" /> : ''}
                  <span className="mt-1">{formData.dockingDate ? moment(formData.dockingDate).format('DD-MMM-YYYY') : ''}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-content-grey ">Undock Date :</div>
              </td>
              <td>
                <div className="align-items-center d-flex justify-content-start value break-all">
                  {formData.repairCompletionDate ? <span className="brand-color font-20 icon-calander icon-date me-2" /> : ''}
                  <span className="mt-1">{formData.repairCompletionDate ? moment(formData.repairCompletionDate).format('DD-MMM-YYYY') : ''}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-content-grey ">ETD :</div>
              </td>
              <td>
                <div className="align-items-center d-flex justify-content-start value break-all">
                  {formData.etd ? <span className="brand-color font-20 icon-calander icon-date me-2" /> : ''}
                  <span className="mt-1">{formData.etd ? moment(formData.etd).format('DD-MMM-YYYY') : ''}</span>
                </div>
              </td>
            </tr>
          </table>
          <div className="my-2 border-down" />
          <table className="pcs-table">
            <tr>
              <td className="width-300">
                <div className="text-content-grey ">Total Deviation Days : </div>
              </td>
              <td>
                <div className="value break-all">
                  {formData.deviationDays}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=" text-content-grey ">Total Off Hire Days : </div>
              </td>
              <td>
                <div className="value break-all">
                  {formData.offhireDays}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=" text-content-grey ">Total Repair Days : </div>
              </td>
              <td>

                <div className=" value break-all">
                  {formData.repairDays}
                  <span className="link-btn font-bold ps-4" onClick={() => { setShowHistory(true); }}>View History </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="text-content-grey ">Fuel Cost : </div>
              </td>
              <td>

                <div className="value break-all">
                  {systemCurrency}
                  {' '}
                  {formatNumber(formData?.fuelCost)}
                  {systemCurrency !== projectBasicDetails?.currencySymbol ? (
                    <span>
                      (
                      {projectBasicDetails?.currencySymbol}
                      {' '}
                      {formatNumber(formData?.fuelCostLocal)}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=" text-content-grey ">Off Hire Cost : </div>
              </td>
              <td>

                <div className="value break-all">
                  {systemCurrency}
                  {' '}
                  {formatNumber(formData?.offhireCost)}
                  {systemCurrency !== projectBasicDetails?.currencySymbol ? (
                    <span>
                      (
                      {projectBasicDetails?.currencySymbol}
                      {' '}
                      {formatNumber(formData?.offhireCostLocal)}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className=" text-content-grey ">Deviation Cost : </div>
              </td>
              <td>

                <div className="value break-all">
                  {systemCurrency}
                  {' '}
                  {formatNumber(formData?.deviationCost)}
                  {systemCurrency !== projectBasicDetails?.currencySymbol ? (
                    <span>
                      (
                      {projectBasicDetails?.currencySymbol}
                      {' '}
                      {formatNumber(formData?.deviationCostLocal)}
                      )
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </td>
            </tr>
          </table>
        </div>
      ) : <Loader />}
      <div className="pb-2" />

      <SidePanel
        title={`${formData?.lastPort ? 'Editing' : 'Adding'} Deviation and Off Hire`}
        showView={editModal}
        onClose={closeEditModal}
        onSave={() => {
          handleOnSave();
        }}
        isTsiDisabled={projectBasicDetails?.phase > 4}
      >
        {editModal && !confirm
          && (
            <EditModal
              formData={editModal}
              setFormData={setEditModal}
              setHasChange={setHasChange}
              projectBasicDetails={projectBasicDetails}
              tableData={tableData}
              options={options}
              fuelPriceType1Value={fuelPriceType1Value}
              fuelPriceType2Value={fuelPriceType2Value}
              setFuelPriceType1Value={setFuelPriceType1Value}
              setFuelPriceType2Value={setFuelPriceType2Value}
            />
          )}
      </SidePanel>
      {showHistory && (
        <ModalBox
          modalClassName="medium"
          heading="TRD Change History"
          closeModal={() => { setShowHistory(false); }}
        >
          <div className="scroll-wrap scroll-wrap-comments">
            <TRDHistory history={history} />
          </div>
        </ModalBox>
      )}
      {confirm ? (
        <ModalBox
          confirm={confirm}
          modalClassName="medium"
          heading="Confirm"
          onSave={() => {
            if (confirm === 'save') {
              save();
              setConfirm('');
            } else {
              setEditModal(false);
              setConfirm('');
            }
            setTimeout(() => {
              document.body.classList.add('model-open');
            }, 30);
          }}
          closeModal={() => {
            setConfirm('');
            setTimeout(() => {
              document.body.classList.add('model-open');
            }, 30);
          }}
          buttonName="Yes"
        >
          <p>{confirm === 'save' ? 'Are you sure you want to save changes?' : 'Are you sure you want to exit page?'}</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProjectCard;
