import React from 'react';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';
import SidePanel from '../../../../../common/sidePanel';
import { listingConfig } from './constants';
import InfoEditPage from './infoEditPage';

const YardInfo = (props) => {
  const { yardInfo, onSaveYardInfo, evaluationInfo,
    setYardInfo, showView, setShowView, viewYardInfo, ratings, projInfo, showEdit = true } = props;
  const { formatNumber } = helper;
  const onClose = () => {
    setShowView(false);
    evaluationInfo();
  };
  return (
    <div className="card p-4 font-14 mt-1 me-3" data-testid="yard-info">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div className="align-items-center">
            <span className="icon-yard large brand-color bg-white font-35 " />
          </div>
          <div className="ms-3 mt-1">
            <div className="head-14">{viewYardInfo.yardName}</div>
            <div className="opacity-6">{viewYardInfo.yardLocation}</div>
          </div>
        </div>
        {showEdit && (
          <button
            id="edit-cost"
            type="button"
            className="secondary-btn secondary-btn-border small mt-3 "
            onClick={() => {
              setShowView(true);
            }}
            disabled={ratings?.status > 0}
          >
            Edit
          </button>
        )}
      </div>
      <div className="pt-3 border-down" />
      <div className=" ms-2 my-3 close-info font-13">
        {listingConfig.map((item) => {
          if (item.type === 'heading') {
            return <h3 className="head-14 mt-4">{item.label}</h3>;
          }
          if (item.type === 'amount') {
            return (
              <div className="d-flex align-items-center mt-3">
                <div className="col-6  pe-3">
                  <label className="label">{item.label}</label>
                </div>
                <div className="col-6 value truncate">
                  <span data-tooltip-id="project-upload" data-tooltip-content={formatNumber(viewYardInfo[item.apiKey])}>
                    {projInfo.currencySymbol}
                    {' '}
                    {formatNumber(viewYardInfo[item.apiKey])}
                  </span>
                </div>
              </div>
            );
          }
          if (item.type === 'percent') {
            return (
              <div className="d-flex align-items-center mt-3">
                <div className="col-6  pe-3">
                  <label className="label">{item.label}</label>
                </div>
                <div className="col-6 value truncate">
                  <span data-tooltip-id="project-upload" data-tooltip-content={formatNumber(viewYardInfo[item.apiKey])}>
                    {viewYardInfo[item.apiKey]}
                    %
                  </span>
                </div>
              </div>
            );
          }
          return (
            <div className="d-flex align-items-center mt-3">
              <div className="col-6 6 pe-3">
                <label className="label">{item.label}</label>
              </div>
              {item.type === 'date' ? (
                <div className="col-6 value truncate">{viewYardInfo[item.apiKey] && moment(viewYardInfo[item.apiKey]).format(' DD-MMM-YYYY')}</div>
              ) : (
                <div className="col-6 value truncate">
                  <span data-tooltip-id="project-upload" data-tooltip-content={viewYardInfo[item.apiKey]}>
                    {' '}
                    {viewYardInfo[item.apiKey]}
                  </span>
                </div>
              )}
            </div>
          );
        })}
        {projInfo?.vesselMappedUsers?.map((i) => (
          <div className="d-flex align-items-center mt-3">
            <label className="col-6  pe-3">
              {i.roleName}
              <span className="ms-2">:</span>
            </label>
            <div className="col-6 value truncate">
              <span data-tooltip-id="project-upload" data-tooltip-content={i.userName}>
                {i.userName}
              </span>

            </div>
          </div>
        ))}
      </div>
      {showView && (
        <SidePanel title="Yard Information" size="small" showView={showView} onSave={onSaveYardInfo} onClose={onClose}>
          <InfoEditPage yardInfo={yardInfo} setYardInfo={setYardInfo} />
        </SidePanel>
      )}
      <Tooltip id="project-upload" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default YardInfo;
