/* istanbul ignore file */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import qs from 'query-string';
import _, { cloneDeep } from 'lodash';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import ShipDueView from './shipDueView';
import ShipDueCard from './shipDueCard';
import helper from '../../../../utils/helper';
import { s3Upload, getNotification, saveNotification, getIntervals, toggleVesselStatus, prevVessel, changeVesselImage } from '../Vessel/services';
import showAlert from '../../../../utils/alert';
import ModalBox from '../../../common/ModalBox';
import UploadBg from './children/uploadBg';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const acceptedFiles = ['image/jpeg', 'image/png', 'image/jpg'];

const VesselDetails = () => {
  const params = qs.parse(window.location.search);
  const navigate = useNavigate();
  const { generateOptions, MAX_FILE_SIZE } = helper;
  const location = window.location.pathname;
  const [isEditDue, setIsEditDue] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState({});
  const [intervals, setIntervals] = useState([]);
  const [changeModal, setChangeModal] = useState(false);
  const [vesselList, setVesselList] = useState({});
  const [bgImg, setBgImg] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [statusConfirm, setStatusConfirm] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const getSurvey = () => {
    getNotification({ id: params.id }).then((res) => {
      if (res.data) {
        const i = cloneDeep(res.data);
        i.dryDockingSurveyDueDate = moment(res.data.dryDockingSurveyDueDate).format('YYYY-MM-DD');
        i.tailshaftSurveyDueDate = moment(res.data.tailshaftSurveyDueDate).format('YYYY-MM-DD');
        i.intermediateSurveyDueDate = moment(res.data.intermediateSurveyDueDate).format('YYYY-MM-DD');
        i.specialSurveyDueDate = moment(res.data.specialSurveyDueDate).format('YYYY-MM-DD');
        setSurveyInfo(i);
        if (imageUrl.split('?')[0] !== res.data.vesselImageUrl?.split('?')[0]) {
          setImageUrl(_.cloneDeep(res.data.vesselImageUrl));
        }
      }
    });
  };
  const getPreviouData = () => {
    prevVessel({ id: params.id }).then((res) => {
      if (res.data) {
        setVesselList(res.data);
      }
    });
  };
  useEffect(() => {
    getSurvey();
    getIntervals().then((intr) => {
      if (intr) {
        setIntervals(generateOptions(intr.data, 'id', 'name'));
      }
    });
    getPreviouData();
  }, [params?.id]);
  const updateInfo = () => {
    const i = cloneDeep(surveyInfo);
    i.dryDockingSurveyDueDate = moment(surveyInfo.dryDockingSurveyDueDate).isValid() ? `${surveyInfo.dryDockingSurveyDueDate}T00:00:00` : '';
    i.tailshaftSurveyDueDate = moment(surveyInfo.tailshaftSurveyDueDate).isValid() ? `${surveyInfo.tailshaftSurveyDueDate}T00:00:00` : '';
    i.intermediateSurveyDueDate = moment(surveyInfo.intermediateSurveyDueDate).isValid() ? `${surveyInfo.intermediateSurveyDueDate}T00:00:00` : '';
    i.specialSurveyDueDate = moment(surveyInfo.specialSurveyDueDate).isValid() ? `${surveyInfo.specialSurveyDueDate}T00:00:00` : '';
    console.log(i);
    if (i.dryDockingSurveyDueDate
      && i.tailshaftSurveyDueDate
      && i.intermediateSurveyDueDate
      && i.specialSurveyDueDate) {
      saveNotification(i).then((res) => {
        if (res.success) {
          showAlert('Successfully Updated', 'success');
          setIsEditDue(false);
          getSurvey();
        }
      });
    } else {
      showAlert('Please enter valid date', 'error');
    }
  };
  const updateStatus = () => {
    toggleVesselStatus({ id: params.id }).then((res) => {
      if (res.success) {
        getSurvey();
        setStatusConfirm(false);
        showAlert('Successfully Saved', 'success');
      }
    });
  };
  const showChangeModal = () => {
    setChangeModal(true);
  };
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          setBgImg(res.data);
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
  };
  const nextVessel = () => {
    navigate(`/admin/details/info?id=${vesselList.nextVesselId}`);
  };
  const preVessel = () => {
    navigate(`/admin/details/info?id=${vesselList.previousVesselId}`);
  };
  const onUploadImg = () => {
    changeVesselImage({
      vesselId: params.id,
      imageFileUrl: bgImg
    }).then((res) => {
      if (res.success) {
        getSurvey();
        setChangeModal(false);
        setBgImg('');
        getPreviouData();
      }
    });
  };
  const onCancelUpload = () => {
    setChangeModal(false);
    setBgImg('');
  };
  const onConfirmStatus = () => {
    setStatusConfirm(true);
  };
  return (
    <div data-testid="vessel-details" className="page-wrapper">
      <div className="sub-header d-flex justify-content-between px-5 align-items-center">
        <div className="d-flex col-gap-2 align-items-center">
          {vesselList.hasPrev ? (
            <button
              type="button"
              disabled={!vesselList.hasPrev}
              className="arrow-btn secondary-btn"
              onClick={() => {
                preVessel();
              }}
            >
              <span className="icon-arrow-thin-left" />
            </button>
          ) : (
            <span className="me-5"> </span>
          )}
          <div className="d-flex">
            <div className="thmb-md">
              {vesselList.currentVesselImage ? (
                <img src={vesselList.currentVesselImage} alt="" className="thmb-md" />
              ) : (
                <img src="/static/media/vessel-thmd.304ab3bca4863ea93cc6.jpg" alt="" className="thmb-md" />
              )}
            </div>
            <div className="vessel-thmb">
              <h4
                className="head-16 mb-2 text-ellipsis"
                data-tooltip-id="project-currentVesselName"
                data-tooltip-content={vesselList.currentVesselName}
              >
                {_.truncate(vesselList.currentVesselName, { length: 25 })}
              </h4>
              <div className="text-ellipsis" data-tooltip-id="project-currentVesselName" data-tooltip-content={vesselList.currentVesselType}>
                {_.truncate(vesselList.currentVesselType, { length: 25 })}
              </div>
            </div>
          </div>
          {vesselList.hasNext ? (
            <button
              disabled={!vesselList.hasNext}
              type="button"
              className="arrow-btn secondary-btn"
              onClick={() => {
                nextVessel();
              }}
            >
              <span className="icon-arrow-thin-right" />
            </button>
          ) : (
            <span className="me-5"> </span>
          )}
          <div className="toggle-switch ms-5">
            <input type="checkbox" id="switch" checked={surveyInfo.vesselStatus !== 'Inactive'} onChange={onConfirmStatus} />
            <label htmlFor="switch">{`${surveyInfo.vesselStatus === 'Inactive' ? 'Vessel is Inactive' : 'Vessel is Active'}`}</label>
          </div>
        </div>
        <button className="rnd-btn" type="button" onClick={() => navigate('/admin/vessel/vessel')}>
          Back
        </button>
      </div>
      <div className="vessel-banner relative d-flex justify-content-center align-items-end mb-4" style={{ backgroundImage: `url(${imageUrl})` }}>
        {changeModal && (
          <ModalBox
            modalClassName="medium"
            heading="Change Vessel Image"
            onSave={() => {
              onUploadImg();
            }}
            closeModal={() => {
              onCancelUpload();
            }}
            buttonName="Save"
            saveDisable={!bgImg}
          >
            <UploadBg bgImg={bgImg} ondrop={ondrop} vesselList={vesselList} setBgImg={setBgImg} />
          </ModalBox>
        )}
        {statusConfirm && (
          <ModalBox
            modalClassName="medium"
            heading={surveyInfo.vesselStatus !== 'Inactive' ? 'Mark Vessel as Inactive' : 'Mark Vessel as Active'}
            onSave={() => {
              updateStatus();
            }}
            closeModal={() => {
              setStatusConfirm(false);
            }}
            buttonName="Continue"
          >
            {surveyInfo.vesselStatus !== 'Inactive'
              ? 'When marked as Inactive, the vessel will not be available for mapped users in the application screen. Do you want to continue?'
              : 'When marked as active, the vessel and its details will be available for the mapped application users. Do you want to continue?'}
          </ModalBox>
        )}
        {isEditDue ? (
          <ShipDueCard
            updateInfo={updateInfo}
            setIsEditDue={setIsEditDue}
            surveyInfo={surveyInfo}
            intervals={intervals}
            setSurveyInfo={setSurveyInfo}
            setChangeModal={setChangeModal}
            showChangeModal={showChangeModal}
            resetForm={getSurvey}
          />
        ) : (
          <ShipDueView
            setIsEditDue={setIsEditDue}
            surveyInfo={surveyInfo}
            setSurveyInfo={setSurveyInfo}
            intervals={intervals}
            isEditable={surveyInfo.vesselStatus === 'Inactive'}
            showChangeModal={showChangeModal}
          />
        )}
      </div>
      <div className="container">
        <div className="header-main tab-head">
          <div className="main-nav ">
            <ul className="d-flex nav-item">
              <li
                className={location.includes('/admin/details/info') ? 'active' : ''}
                onClick={() => {
                  navigate(`/admin/details/info?id=${params?.id}`);
                }}
              >
                <a className="">Vessel Information</a>
              </li>
              <li
                className={location.includes('/admin/details/comp') ? 'active' : ''}
                onClick={() => {
                  navigate(`/admin/details/comp?id=${params?.id}`);
                }}
              >
                <a className="">Components</a>
              </li>
              <li
                className={location.includes('/admin/details/attach') ? 'active' : ''}
                onClick={() => {
                  navigate(`/admin/details/attach?id=${params?.id}`);
                }}
              >
                <a className="">Vessel Plans and Drawings</a>
              </li>
            </ul>
          </div>
        </div>
        <Outlet context={{ status: surveyInfo.vesselStatus }} />
        <div className="footer justify-content-between font-13">
          <PolicyFooter
            setShowPrivacyPolicy={setShowPrivacyPolicy}
            setShowTermsPolicy={setShowTermsPolicy}
          />
        </div>
        {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
        )}
        {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
        )}
      </div>
      <Tooltip id="project-currentVesselName" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default VesselDetails;
export const useStatus = () => {
  return useOutletContext();
};
