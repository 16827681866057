import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const VendorSectionCard = (props) => {
  // eslint-disable-next-line max-len
  const { formatNumber } = helper;
  const { regions, i, ikey, isOpenRow } = props;
  const getTooltip = (va) => {
    let t = '$ ';
    t = t.concat('', formatNumber(va || 0));
    return t;
  };
  if (isOpenRow.includes(i.sectionId)) {
    return (
      <div data-testid="p-8" className="ps-4  border-down">
        <div className={`${ikey ? 'header-top-h' : 'firstvendor-h-h'}`}>
          {regions?.map((r) => {
            if (r.isShow) {
              return (
                <div className="region-div-inner-h paint-l-border white">
                  <span className="icon-location font-18 me-1 pt-1 white" />
                  {r.label}
                </div>
              );
            }
            return '';
          })}
        </div>
        <div className="align-items-center bg-sky-blue d-flex me-4  text-content-black">
          {regions?.map((r) => {
            if (r.isShow) {
              return (
                <>
                  <div className="p-2 p-w-10 ms-3">
                    <div>
                      Total price
                      <br />
                      <span className="transp">price.</span>
                    </div>
                  </div>
                </>
              );
            }
            return '';
          })}
        </div>
        {!i.vendorSectionRow || !i.vendorSectionRow.length ? <div className="no-data-paint">No data</div> : ''}
        {i.vendorSectionRow?.map((iData, iKsy) => (
          <div className="d-flex me-4 text-content-black">
            {iData.regionPrices.map((region, regionIndex) => {
              if (regions[regionIndex].isShow) {
                return (
                  <>
                    <div className={regions.length > 1 ? 'pe-3 py-3 w-12em paint-r-border' : 'pe-3 py-3 w-12em'}>
                      <InputType1 disabled value={region.totalPrice} className="ms-1 modal-inputs small" label="" />
                      {iKsy === i.vendorSectionRow.length - 1 ? (
                        <div className={ikey === 0 ? 't-top1 text-right' : 't-top text-right'}>
                          <div
                            className="font-bold text-content-black"
                            data-tooltip-id="view-prof"
                            data-tooltip-content={
                              getTooltip(i.regionGrandTotal[regionIndex]?.grandTotal)
                            }
                          >
                            $
                            {' '}
                            {_.truncate(
                              formatNumber(i.regionGrandTotal[regionIndex]?.grandTotal) || 0, {
                                length: 11,
                                separator: ' '
                              }
                            )}
                          </div>
                          <div>Total</div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </>
                );
              }
              return '';
            })}
            <Tooltip id="view-prof" place="top" className="tooltip " />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div data-testid="p-8" className={`ps-4 border-down ${ikey ? '' : ''}`}>
      <div className="d-flex">
        {regions?.map((r, regionIndex) => {
          if (r.isShow) {
            return (
              <div className={regions.length > 1 ? 'region-div-innerr text-right paint-r-border' : 'region-div-innerr text-right'}>
                <div
                  className="font-bold text-content-black ps-2"
                  data-tooltip-id="view-prof123"
                  data-tooltip-content={
                    getTooltip(i?.regionGrandTotal[regionIndex]?.grandTotal)
                  }
                >
                  $
                  {' '}
                  {_.truncate(
                    formatNumber(i?.regionGrandTotal[regionIndex]?.grandTotal) || 0, {
                      length: 30,
                      separator: ' '
                    }
                  )}
                </div>
              </div>
            );
          }
          return '';
        })}
        <Tooltip id="view-prof123" place="top" className="tooltip " />
      </div>
    </div>
  );
};
export default VendorSectionCard;
