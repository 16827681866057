import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetTags,
  SaveTag,
  DeleteTag,
  GenerateTemplateTag,
  BulkUploadTag,
  ExportTagExcel
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getTags = (payload) => {
  return serviceHandler({
    url: `${GetTags}`,
    methordType: 'post',
    payload
  });
};
export const saveTag = (payload) => {
  return serviceHandler({
    url: `${SaveTag}`,
    methordType: 'post',
    payload
  });
};
export const deleteTag = (payload) => {
  return serviceHandler({
    url: `${DeleteTag}?id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};

export const exportExcelTag = (searchText) => {
  return axios
    .get(`${ExportTagExcel}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Tags_${currentDate()}.xlsx`);
    });
};

export const bulkUploadTag = (payload) => {
  return serviceHandler({
    url: `${BulkUploadTag}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateTag}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Tags_${currentDate()}.xlsx`);
    });
};
