import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ pageClick, pageCount = 1, currentpage,
  onSizeChange, size = 10, noRowsDisplay, fromGallery, height = '' }) => {
  return (
    <>
      <div className={fromGallery ? ' mt-2 d-flex justify-content-between align-items-center w-full' : `mt-2 d-flex justify-content-between align-items-center ${height}`}>
        <ReactPaginate
          breakLabel="..."
          className="d-flex pagination"
          pageClassName="page-item"
          nextLabel={currentpage < pageCount ? '>' : ''}
          onPageChange={pageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel={currentpage === 1 ? '' : '<'}
          renderOnZeroPageCount={null}
          forcePage={currentpage - 1}
        />
        {!noRowsDisplay ? (
          <div className="d-flex align-items-center">
            <label htmlFor="cars" className="table-view__showing-text">{fromGallery ? 'Images to display' : 'Rows to display'}</label>
            <select name="" className={fromGallery ? 'select small ms-3 mt-4' : 'select small ms-3'} id="" onChange={onSizeChange} value={size}>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>
          </div>
        ) : ''}

      </div>
    </>
  );
};
export default Pagination;
