import React from 'react';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import vesselThmd from '../../../../../images/vessel-thmd.jpg';
import 'react-tooltip/dist/react-tooltip.css';

const Card = (props) => {
  const { projectDetails } = props;
  return (
    <>
      <>
        <span
          className="main-head"
          data-tooltip-id="project-nameTooltip"
          data-tooltip-content={projectDetails?.nameTooltip}
        >
          {projectDetails?.name}
        </span>
        <Tooltip id="project-nameTooltip" place="top" className="tooltip mt-3" />
        <div className="card bg-sky-white p-4 my-4">
          <div className="d-flex justify-content-between align-items-center col-gap-1">
            <div className="d-flex col-6">
              <img className="thmb-md ship-thumb" src={projectDetails?.imageUrl || vesselThmd} alt="" />
              <div className="d-flex flex-column  ">
                <h3 className="head-16">{projectDetails?.vesselName}</h3>
                <p className="mt-1">{projectDetails?.vesselSubType}</p>
              </div>
            </div>
            <div className="d-flex flex-column col-5">
              <div className="d-flex">
                <label> DD Due Date :</label>
                <div className="value ms-2">{projectDetails?.ddDudate ? moment(projectDetails?.ddDudate).format('DD-MMM-YYYY') : '-'}</div>
              </div>
              <div className="d-flex mt-2">
                <label> Previous DD Date :</label>
                <div className="value ms-2">{projectDetails?.previousDdDudate ? moment(projectDetails?.previousDdDudate).format('DD-MMM-YYYY') : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      </>

    </>
  );
};
export default Card;
