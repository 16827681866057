import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import AreaLoader from '../../../../../common/areaLoader';

const Table1Container = (props) => {
  const { specList, mainSec, showsubSec, onSecOpen, setShowMore } = props;
  const [areaLoaders, setAreaLoader] = useState(false);
  useEffect(() => {
    setAreaLoader(true);
    setTimeout(() => {
      setAreaLoader(false);
    }, 1000);
  }, []);
  const handleAssignedTo = (subsec) => {
    if (subsec.specAssigned === 1) {
      return 'Y';
    }
    if (subsec.specAssigned === 2) {
      return 'V';
    }
    if (subsec.specAssigned === 3) {
      return 'S';
    }
    return '';
  };

  return (
    <div className="spc-map ps-3">
      <div className="d-flex border-down py-2 ps-3">
        <div className="col-1 font-bold">SEC#</div>
        <div className="col-8 font-bold">SPEC SECTION</div>
        <div className="col-3 font-bold text-left-align">TOTAL</div>
      </div>
      {areaLoaders ? (
        <div className="d-flex justify-content-center mt-4">
          <AreaLoader />
        </div>
      ) : (
        <>
          {specList?.sectionData?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6">No data found</div>}
          {specList.sectionData?.map((sec) => {
            return (
              <div className={sec.specificationNo === 'DEF' ? 'd-none' : ''}>
                <div key={sec} className={sec.projectSpecId === mainSec && showsubSec ? 'highlight-tender' : ''}>
                  <div className="d-flex border-down py-2 ps-3">
                    <div className="col-1 d-flex align-items-center">
                      <span
                        id={sec.projectSpecId}
                        data-test="secOpen"
                        className={
                          sec.projectSpecId === mainSec
                            ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                            : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                        }
                        onClick={() => {
                          onSecOpen(sec.projectSpecId, true);
                        }}
                      />
                      <span data-tooltip-id="project-sec" data-tooltip-content={sec.specificationNo}>
                        {_.truncate(sec.specificationNo, { length: 7 })}
                      </span>
                    </div>
                    <div className="col-8">
                      <span data-tooltip-id="project-sec" data-tooltip-content={sec.title}>
                        {_.truncate(sec.title, { length: 40 })}
                      </span>
                    </div>

                    <Tooltip id="project-sec" place="top" className="tooltip mt-3" />
                    <div className="col-3 text-left-align">
                      {specList.currencySymbol}
                      {' '}
                      0
                    </div>
                  </div>
                  {sec.projectSpecId === mainSec
                    && sec.specDataList.map((subsec) => {
                      return (
                        <>
                          <div key={subsec} className="d-flex mb-2 py-2 ps-3">
                            <div className="col-1" />
                            <div className="col-11 d-flex align-items-center">
                              <span
                                id={subsec.projectSpecId}
                                data-test="subSecOpen"
                                className={
                                  subsec.projectSpecId === showsubSec
                                    ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180'
                                    : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                                }
                                onClick={() => {
                                  onSecOpen(subsec.projectSpecId, false);
                                }}
                              />
                              <div className="d-flex col-8">
                                <span className="me-4" data-tooltip-id="project-sec" data-tooltip-content={subsec.specificationNo}>
                                  {_.truncate(subsec.specificationNo, { length: 6 })}
                                </span>
                                <div className="" data-tooltip-id="project-sec" data-tooltip-content={subsec.title}>
                                  {_.truncate(subsec.title, { length: 80 })}
                                </div>
                              </div>

                              <div
                                className={
                                  subsec.projectSpecId === showsubSec
                                    ? 'bg-sky-blue margin-yard-show px-2 cursor-auto'
                                    : 'bg-sky-blue px-2 ms-5 cursor-auto'
                                }
                              >
                                {handleAssignedTo(subsec)}
                              </div>
                              {subsec.projectSpecId === showsubSec && (
                                <>
                                  <button
                                    type="button"
                                    id="viewmore"
                                    className="primary-btn small ms-5"
                                    onClick={() => {
                                      setShowMore(subsec);
                                    }}
                                  >
                                    View More
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                          {subsec.projectSpecId === showsubSec
                            && subsec.serviceLineList.map((serv, num) => {
                              return (
                                <>
                                  {num === 0 && (
                                    <div key={serv} className="d-flex ps-3 align-items-center">
                                      <div className="col-1" />
                                      <div className="col-2 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                                      <div className="col-3 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                                      <div className="col-1 bg-sky-white ps-3 border-down py-2">AC Code</div>
                                      <div className="col-0 bg-sky-white ps-3 border-down py-2 text-center">Qty</div>
                                      <div className="col-1 bg-sky-white ps-3 border-down py-2">UOM</div>
                                      <div className="col-0 bg-sky-white ps-3 border-down py-2 text-center">Rate</div>
                                      <div className="col-1 bg-sky-white ps-1 border-down py-2 text-center">Disc[%]</div>
                                      <div className="col-0 bg-sky-white ps-2 border-down py-2 text-left-align">Amount</div>
                                      <div className="col-1 bg-sky-white ps-3 border-down py-2">Comment</div>
                                    </div>
                                  )}
                                  <div className="d-flex pb-2 ps-3 py-1">
                                    <div className="col-1" />
                                    <div className="col-2 ps-3 border-down" data-tooltip-id="project-sec" data-tooltip-content={serv.number}>
                                      {serv.number}
                                    </div>
                                    <div className="col-3 ps-3 text-justify text-break-all border-down ">{serv.serviceLineName}</div>
                                    <div className="col-1 ps-3 border-down">
                                      <span data-tooltip-id="project-sec" data-tooltip-content={serv.acCode}>
                                        {serv.acCode}
                                      </span>
                                    </div>
                                    <div className="col-0 ps-3 border-down text-center">
                                      <span data-tooltip-id="project-sec" data-tooltip-content={serv.quantity}>
                                        {serv.quantity}
                                      </span>
                                    </div>
                                    <div className="col-1 ps-3 border-down">
                                      <span data-tooltip-id="project-sec" data-tooltip-content={serv.uomName}>
                                        {serv.uomName}
                                      </span>
                                    </div>
                                    <div className="col-0 ps-3 border-down text-center">
                                      <span data-tooltip-id="project-sec" data-tooltip-content={serv.rate} />
                                      {serv.rate}
                                    </div>
                                    <div className="col-1 ps-1 border-down text-center">
                                      <span data-tooltip-id="project-sec" data-tooltip-content={serv.discount}>
                                        {serv.discount}
                                      </span>
                                    </div>
                                    <div className="col-0 ps-2 border-down text-left-align">
                                      <span data-tooltip-id="project-sec" data-tooltip-content={serv.amount}>
                                        {serv.amount}
                                      </span>
                                    </div>
                                    <div className="col-1 ps-3 border-down">
                                      <span className="font-22 icon-add-comment brand-color" />
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </>
      )}
      <Tooltip id="project-serviceLineName" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Table1Container;
