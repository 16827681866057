import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactStars from 'react-rating-stars-component';
import moment from 'moment';
import _ from 'lodash';

const Evaluation = (props) => {
  const { ratings, data } = props;
  const [isExpand, setIsExpand] = useState('');
  const [expand, setExpand] = useState(false);
  // const [active, setActive] = useState(false);
  const toggleExpand = (key) => {
    if (isExpand === key) {
      setIsExpand('');
    } else {
      setIsExpand(key);
    }
  };

  return (
    <div className="border md-radius" data-testid="evaluation">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{data?.label}</h2>
        <div className={expand ? 'rotate-90' : 'rotate-0'}>
          <span
            className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer rotate-90'}
            onClick={() => { setExpand(!expand); }}
            id="expandeval"
          />
        </div>
      </div>
      {expand
      && (
      <div className="col-11 font-13">
        {ratings?.evaluations?.map((item, index) => {
          return (
            <>
              <div className="ms-3 d-flex align-items-center">
                <div className="font-14 p-4 col-5">
                  {item?.label}
                </div>
                <div className="d-flex col-4 align-items-center">
                  <ReactStars
                    size={30}
                    count={5}
                    value={item?.rating}
                    edit={false}
                    isHalf={false}
                  />
                  <div className="mt-1 ms-3 font-14">
                    {item?.rating}
                    /5
                  </div>
                </div>
                <div className="col-3 align-text-end pe-4">
                  <span
                    id={item.id}
                    className={isExpand === index ? ' pointer icon-arrow-thin-up mt-4' : 'pointer icon-arrow-thin-down mt-4'}
                    onClick={() => {
                      toggleExpand(index);
                    }}
                  />
                </div>
              </div>
              <div className=" border-down" />
              <div>
                {isExpand === index
                && item.evaluationLines.map((field, num) => {
                  return (
                    <>
                      {num === 0 && (
                        <div className="ms-3 mt-1 d-flex ">
                          <div className="head-14 p-4 flex-column col-5">Sections</div>
                          <div className="flex-column col-4 ">
                            <div className="head-14 py-3">Rating</div>
                          </div>
                          <div className="flex-column col-3 ">
                            <div className="head-14 py-3">Remarks</div>
                          </div>
                        </div>
                      )}
                      <div className="ms-3 my-1 d-flex align-items-center">
                        <div className="font-14 p-4 flex-column col-5 opacity-6">{field?.label}</div>
                        <div className="flex-column col-4 ">
                          <ReactStars
                            size={30}
                            isHalf={false}
                            edit={false}
                            value={field.rating ? field.rating : 0}
                          />
                        </div>
                        <div>
                          <span
                            data-tooltip-id="project-specList"
                            data-tooltip-content={field.remarks}
                          >
                            {_.truncate(field.remarks, { length: 10 }) || '-'}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
        <>
          <div className=" border-down" />
          <div className="w-full pt-3 mx-3 pb-2 ps-4 eval-comment pe-5">
            <label className="mt-4 mb-2 font-semibold">Comments</label>
            <div>{ratings?.comments}</div>
          </div>
          <div className="mx-3 ps-4 py-3">
            <div className="font-semibold">Date</div>
            <div>{moment(ratings?.evaluationDate).format(' DD-MMM-YYYY')}</div>
          </div>
        </>
      </div>
      )}
      <Tooltip id="project-specList" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Evaluation;
