import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import Loader from '../../../../common/loader';
import { getSofReport, sofReportDownload } from '../services';
import InputType1 from '../../../../common/InputType1';
import showAlert from '../../../../../utils/alert';
import SofReportComponent from './SofReportComponent';

const SofReport = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState({
    projectId: '',
    repairStartDate: '',
    repairEndDate: '',
    sofDataList: []
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const today = moment(new Date()).format('yyyy-MM-DD hh:mm');
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const reloadTable = () => {
    const fromDateNew = new Date(fromDate);
    const toDateNew = new Date(toDate);
    console.log(fromDate, toDate);
    const payload = {
      page,
      pageSize,
      search: '',
      sortProperty: '',
      isDescending: true,
      projectId: id,
      fromDate: fromDateNew,
      toDate: toDateNew
    };
    if (moment(fromDate) <= moment(toDate)) {
      getSofReport(payload).then((res) => {
        console.log(res);
        setTableData(res.data);
        setLoading(false);
      });
    } else {
      showAlert('Please select valid date range', 'error');
    }
  };

  useEffect(() => {
    reloadTable();
  }, []);

  useEffect(() => {
    reloadTable();
  }, [fromDate, toDate, page, pageSize]);
  const downloadReport = () => {
    const fromDateNew = new Date(fromDate);
    const toDateNew = new Date(toDate);
    const payload = {
      projectId: id,
      fromDate: fromDateNew,
      toDate: toDateNew
    };
    sofReportDownload(payload);
  };

  return (
    <div data-testid="SofReport">
      <div className="align-items-center d-flex justify-content-between report-ctrl">
        <div className="d-flex align-items-center">
          <div className="d-flex ">
            <div className="d-flex align-items-center me-4">
              <div className="font-20 font-bold">SoF Report</div>
              <div className="ms-3 couter-bubbles status-due ">{tableData.sofDataList.totalItems}</div>
            </div>
            <div className="d-flex align-items-center me-5">
              <label>
                Repair Period
                <span className="ms-2">:</span>
              </label>
              &nbsp;
              <div className="value">{tableData?.repairStartDate ? moment(tableData?.repairStartDate).format('DD-MMM-yyyy') : ''}</div>
              <div className="mx-4 value"> - </div>
              <div className="value">{tableData?.repairEndDate ? moment(tableData?.repairEndDate).format('DD-MMM-yyyy') : ''}</div>
            </div>
          </div>

          <div className="mx-4 d-flex align-items-center">
            <div className="opacity-6 me-3">From Date</div>
            <InputType1
              value={fromDate}
              type="datetime-local"
              className="cursor-pointer"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="mx-4 d-flex align-items-center">
            <div className="opacity-6 me-3">To Date</div>
            <InputType1
              value={toDate}
              type="datetime-local"
              className="cursor-pointer"
              onChange={(e) => setToDate(e.target.value)}
              noMargin
            />
          </div>

        </div>
        {/* <div>
          <button type="button" className="secondary-btn">
            Print/Share
          </button>
        </div> */}
      </div>
      <div className="report-canvas mt-4">
        {loading ? (
          <Loader />
        ) : (
          <SofReportComponent
            downloadReport={downloadReport}
            tableData={tableData}
            setPage={setPage}
            setPageSize={setPageSize}
            page={page}
            pageSize={pageSize}
          />
        )}
      </div>

    </div>
  );
};

export default SofReport;
