import React from 'react';
import Card from './card';
import Loader from '../../../common/loader';

const CardWrapper = (props) => {
  const { employeeList, handleChange, deleteContact,
    showAddContactModal, loading, projInfo } = props;
  if (loading) {
    return <Loader />;
  } if (employeeList?.length > 0) {
    return (
      <div data-testid="contact-cardWrapper" className="scroll-wrap mt-2">
        {employeeList?.map((item) => {
          return (
            <div>
              <Card
                isClosed={projInfo?.isClosed}
                item={item}
                handleChange={handleChange}
                deleteContact={deleteContact}
                showAddContactModal={showAddContactModal}
              />
            </div>

          );
        })}
      </div>
    );
  }
  return (
    <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="attachment">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">
        No data found
      </div>
    </div>
  );
};

export default CardWrapper;
