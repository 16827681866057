import React, { useState } from 'react';
import _ from 'lodash';
import { Documents } from './constants';
import ModalBox from '../../../../../common/ModalBox';
import Addattachment from './Addattachment';
import showAlert from '../../../../../../utils/alert';
import InvoiceList from './invoiceList';
import Evaluation from './evaluation';
import UploadedFiles from './uploadedFiles';
import { saveCloseDocument, exportPaintCost, exportCloseReport } from '../services';
import VesselAttachments from './vesselAttachments';
import RepairInvoice from './repairInvoice';
import WorkDoneReportsWrap from './workDoneReportsWrap';
import PhotoReports from './photoReports';
import Particulars from './particulars';
import PaintCost from './paintCost';
import helper from '../../../../../../utils/helper';
import ShareProject from './ShareProject';

const SectionContent = (props) => {
  const { allDocs, id, getDocuments, projInfo } = props;
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE').toLowerCase();
  const isTSI = (userRole === roles.AUTHOR
    || userRole === roles.EDITOR
     || userRole === roles.ADMIN);
  // const [openModal, setOpenModal] = useState(false);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [addModal, setaddModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [shareReport, setShareReport] = useState(false);
  const downloadPaintReport = () => {
    exportPaintCost({ id });
  };

  console.log(allDocs, 'data');
  const openAddModal = (docData, phaseId) => {
    setFormData({
      phase: phaseId,
      projectAttachments: []
    });
    setaddModal(docData);
  };
  const saveApi = (data, isDelete) => {
    saveCloseDocument(data)?.then((res) => {
      if (res.success) {
        if (isDelete) {
          showAlert('Successfully deleted', 'success');
        } else {
          showAlert('Successfully Saved', 'success');
        }
        getDocuments();
        setaddModal(false);
        setFormData({});
      }
    });
  };
  const onSaveUpdate = () => {
    const apiData = {
      projectId: id,
      type: formData.phase,
      data: '',
      reports: [...formData.projectAttachments, ...addModal.docs]
    };
    saveApi(apiData);
  };
  const closeAddModal = () => {
    setaddModal(false);
  };
  const removeAttach = (data) => {
    const docList = [...data.docs.docs];
    docList.splice(data.key, 1);
    const apiData = {
      projectId: id,
      type: data.id,
      data: data.docs.data,
      reports: docList
    };
    saveApi(apiData, true);
    setOpenDeleteModal(false);
  };
  const onDownload = () => {
    exportCloseReport({ id });
  };
  return (
    <div className="card pb-4" data-testid="sec-content">
      <div className="d-flex justify-content-between align-items-center p-5">
        <div className="head-16">Documents </div>
        <div className="d-flex align-items-center font-16">
          {userRole === roles.AUTHOR || userRole === roles.ADMIN
            ? (
              <button
                type="button"
                className="secondary-btn small ms-5 "
                id="report"
                onClick={() => {
                  // ShareCloseReports();
                  setShareReport(true);
                }}
              >
                Send Report

              </button>
            ) : ('')}
          <button type="button" className="secondary-btn small ms-3" id="report" onClick={onDownload}>Completion Report</button>
        </div>
      </div>
      <div className="close-infodoc ps-5 pe-3 me-3">
        {_.map(Documents, (item, key) => {
          return (
            <div className=" my-1 d-flex justify-content-center pb-2" key={key}>
              <div className="w-full">
                {item.isListing && <Particulars allDocs={allDocs} item={item} />}
                {
                  item.type === 'repairinvoice' && <RepairInvoice specList={allDocs[item.key]} projInfo={projInfo} disabled={projInfo.isClosed} data={item} />
                }
                {
                  item.type === 'invoice' && <InvoiceList specList={allDocs[item.key]} projInfo={projInfo} disabled={projInfo.isClosed} data={item} allDocs={allDocs} />
                }
                {
                  item.type === 'evaluation' && <Evaluation ratings={allDocs[item.key]} projInfo={projInfo} disabled={projInfo.isClosed} data={item} />
                }
                {
                  item.type === 'vessel' && <VesselAttachments attachments={allDocs[item.key]} projInfo={projInfo} disabled={projInfo.isClosed} data={item} />
                }
                {
                  item.type === 'wreport' && <WorkDoneReportsWrap specList={allDocs[item.key]} projInfo={projInfo} disabled={projInfo.isClosed} data={item} />
                }
                {
                  item.type === 'photoreport' && <PhotoReports reports={allDocs[item.key]} projInfo={projInfo} disabled={projInfo.isClosed} data={item} />
                }
                {
                  item.type === 'paintcost' && <PaintCost allDocs={allDocs} data={item} downloadPaintReport={downloadPaintReport} />
                }
                {
                  item.canAttach && (
                  <UploadedFiles
                    openAddModal={openAddModal}
                    docs={allDocs[item.key]}
                    phase={item}
                    setOpenDeleteModal={setOpenDeleteModal}
                    disabled={projInfo.isClosed || !isTSI}
                  />
                  )
                }
              </div>
            </div>
          );
        })}
      </div>

      {addModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Add Document"
          onSave={onSaveUpdate}
          closeModal={() => {
            closeAddModal();
          }}
          buttonName="Save"
          saveDisable={formData?.projectAttachments?.length < 1}
        >
          <Addattachment
            setFormData={setFormData}
            formData={formData}
          />
        </ModalBox>
      ) : ('')}
      {openDeleteModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Remove Attachment"
          // onSave={() => { removeAttach(removeFile); }}
          onSave={() => { removeAttach(openDeleteModal); }}
          closeModal={() => {
            setOpenDeleteModal(false);
          }}
          buttonName="Delete"
        >
          <p>Are you sure to delete the attachment?</p>
        </ModalBox>
      ) : ('')}
      {shareReport ? <ShareProject shareReport={shareReport} setShareReport={setShareReport} /> : ''}
    </div>
  );
};

export default SectionContent;
