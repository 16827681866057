/* istanbul ignore file */
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { cloneDeep, findIndex, concat, truncate } from 'lodash';
import moment from 'moment';
import ReactTable from 'react-table-v6';
import { getGeneralTariff, getSpecificationStatus, getVesselType, publishList, saveGeneralTariff, GetVesselCategoryListNew } from './services';
import ModalBox from '../../../../common/ModalBox';
import showAlert from '../../../../../utils/alert';
import Table1Container from './Table1Container';
import { GetVesselCategoryList } from '../../GeneralConfig/services';
import NavigateButton from './NavigateButton';

const GeneralTariff = () => {
  const [count, setCount] = useOutletContext() || '';

  const defaultExpanded = false;
  const [loading, setLoading] = useState(false);
  const [expandConfig, setExpandConfig] = useState([]);
  const [expandSubConfig, setExpandSubConfig] = useState([]);
  const [activeParent, setActiveParent] = useState();
  const [formData, setFormData] = useState([]);
  const [formDataVesselType, setFormDataVesselType] = useState([]);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [specificationStatus, setSpecificationStatus] = useState({
    isPublished: false,
    publishedDate: ''
  });
  const reloadTable = () => {
    setLoading(true);
    getGeneralTariff().then((res) => {
      setFormData(res.data);
      setCount(res?.data?.length);
      setLoading(false);
    });
  };
  useEffect(() => {
    reloadTable();
    GetVesselCategoryListNew().then((res) => {
      const generalConfig = res?.data || [];
      setFormDataVesselType(generalConfig);
    });
    getSpecificationStatus(1).then((res) => {
      if (res?.data) setSpecificationStatus(res?.data);
    });
  }, []);
  const publishData = () => {
    setOpenPublishModal(true);
  };

  const publishApiCall = () => {
    saveGeneralTariff(formData).then((resSaved) => {
      if (resSaved.success) {
        publishList(1).then((res) => {
          if (res.success) {
            getSpecificationStatus(1).then((specRes) => {
              if (specRes?.data) setSpecificationStatus(specRes?.data);
            });
            setOpenPublishModal(false);
            reloadTable();
            if (specificationStatus.isPublished) {
              showAlert('Successfully Republished', 'success');
            } else {
              showAlert('Successfully Published', 'success');
            }
          }
        });
      }
    });
  };
  const saveAsDraftDetails = () => {
    saveGeneralTariff(formData).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert('Successfully Saved as draft', 'success');
      }
    });
  };
  const cancel = () => {
    reloadTable();
  };
  const onOpenTask = (data, orIndex) => {
    const currentKeys = [];
    if (expandConfig.indexOf(data.id) > -1) {
      const index = expandConfig.indexOf(data.id);
      currentKeys.splice(index, 1);
      setActiveParent();
    } else {
      currentKeys.push(data.id);
      setActiveParent(orIndex);
    }
    setExpandConfig(currentKeys);
  };
  const onOpenTaskSub = (data) => {
    const currentKeys = [];
    if (expandSubConfig.indexOf(data.id) > -1) {
      const index = expandSubConfig.indexOf(data.id);
      currentKeys.splice(index, 1);
    } else {
      currentKeys.push(data.id);
    }
    setExpandSubConfig(currentKeys);
  };
  const checkBoxStatus = (type, ele, row, data) => {
    //   console.log(type, ele, row, data);
    const fData = data || formData;
    const temp = cloneDeep(fData);
    let status = false;
    if (type === 'main') {
      const newProjectType = temp[row.index]?.applicableVesselType;
      const idx = newProjectType?.indexOf(ele.id);
      if (idx > -1) {
        status = true;
      }
    } else if (type === 'sub1') {
      const newProjectType1 = temp[activeParent || 0]?.childData || [];
      newProjectType1.forEach((i, k) => {
        if (row.id === i.id) {
          const newProjectType = i.applicableVesselType;
          const idx = newProjectType?.indexOf(ele.id);
          if (idx > -1) {
            status = true;
          }
        }
      });
    } else {
      const newProjectType1 = temp[activeParent || 0]?.childData || [];
      newProjectType1.forEach((i) => {
        if (row.parentSpecificationId === i.id) {
          const newProjectType = i.childData[row.index].applicableVesselType;
          const idx = newProjectType?.indexOf(ele.id);
          if (idx > -1) {
            status = true;
          }
        }
      });
    }
    return status;
  };
  
  const updateMainTask = (tempp, parentID, ele, addRemove) => {
    const temp = [...tempp];
    const index = findIndex(temp, (i) => { return i.id === parentID; });
    if (index > -1) {
      const tempTask = temp[index];
      const newProjectType = tempTask.applicableVesselType;
      const idx = newProjectType?.indexOf(ele.id);
      let found = true;
      tempTask.childData.forEach((i) => {
        if (i.applicableVesselType.indexOf(ele.id) === -1) {
          found = false;
        }
      });
      if (idx > -1) {
        if (found) {
          //
        } else {
          // eslint-disable-next-line no-lonely-if
          if (addRemove === 'remove') {
            newProjectType.splice(idx, 1);
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (addRemove === 'add' && found) newProjectType.push(ele.id);
      }
      temp[index].applicableVesselType = newProjectType;
    }
    return temp;
  };

  const updateAllMainTask = (tempp, parentIDIndex, ele, addRemove) => {
    const temp = [...tempp];
    const actParent = activeParent || 0;
    temp.forEach((element, index) => {
      if (index === actParent) {
        element.childData.forEach((u, uk) => {
          if (parentIDIndex === uk) {
            const newProjectType1 = u.applicableVesselType;
            const idx1 = newProjectType1?.indexOf(ele.id);
            let found1 = true;
            u.childData.forEach((i) => {
              if (i.applicableVesselType.indexOf(ele.id) === -1) {
                found1 = false;
              }
            });
            if (idx1 > -1) {
              if (found1) {
                //
              } else {
                // eslint-disable-next-line no-lonely-if
                if (addRemove === 'remove') newProjectType1.splice(idx1, 1);
              }
            } else {
            // eslint-disable-next-line no-lonely-if
              if (addRemove === 'add' && found1) newProjectType1.push(ele.id);
            }
            temp[actParent].childData[uk].applicableVesselType = newProjectType1;
          }
        });

        const newProjectType = element.applicableVesselType;
        const idx = newProjectType?.indexOf(ele.id);
        let found = true;
        element.childData.forEach((i) => {
          if (i.applicableVesselType.indexOf(ele.id) === -1) {
            found = false;
          }
        });
        if (idx > -1) {
          if (found) {
            //
          } else {
            // eslint-disable-next-line no-lonely-if
            if (addRemove === 'remove') {
              newProjectType.splice(idx, 1);
            }
          }
        } else {
        // eslint-disable-next-line no-lonely-if
          if (addRemove === 'add' && found) newProjectType.push(ele.id);
        }
        temp[index].applicableVesselType = newProjectType;
      }
    });

    return temp;
  };

  const updateSubTask = (tempp, pId, ele, addRemove, mainPIdIndex = '') => {
    const temp = cloneDeep(tempp);
    const newProjectType1 = mainPIdIndex === '' ? temp[activeParent || 0].childData : temp[mainPIdIndex].childData;
    newProjectType1.forEach((i, k) => {
      if (pId === i.id) {
        i.childData.forEach((u, uk) => {
          const newProjectType = u.applicableVesselType;
          const idx = newProjectType?.indexOf(ele.id);
          if (idx > -1) {
            if (addRemove === 'remove') newProjectType.splice(idx, 1);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (addRemove === 'add') newProjectType.push(ele.id);
          }
          if (mainPIdIndex === '') {
            temp[activeParent || 0].childData[k].childData[uk].applicableVesselType = newProjectType;
            // eslint-disable-next-line max-len
          } else temp[mainPIdIndex].childData[k].childData[uk].applicableVesselType = newProjectType;
        });
      }
    });
    return temp;
  };
  const updateAllSubTask = (tempp, pIdIndex, ele, addRemove) => {
    let temp = cloneDeep(tempp);
    const newProjectType1 = temp[pIdIndex]?.childData || [];
    newProjectType1.forEach((i, k) => {
      const newProjectType = i.applicableVesselType;
      const idx = newProjectType?.indexOf(ele.id);
      if (idx > -1) {
        if (addRemove === 'remove') {
          newProjectType.splice(idx, 1);
          // eslint-disable-next-line max-len
          temp[pIdIndex].childData[k].applicableVesselType = newProjectType;
          temp = updateSubTask(temp, i.id, ele, addRemove, pIdIndex);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (addRemove === 'add') {
          newProjectType.push(ele.id);
          // eslint-disable-next-line max-len
          temp[pIdIndex].childData[k].applicableVesselType = newProjectType;
          temp = updateSubTask(temp, i.id, ele, addRemove, pIdIndex);
        }
      }
    });
    return temp;
  };
  const handleChangeType = (ele, row, type, data) => {
      console.log('------------------', ele, row, type, data);
      const fData = data || formData;
    let temp = cloneDeep(fData);
    if (type === 'main') {
      const newProjectType = temp[row.index]?.applicableVesselType || [];
      const idx = newProjectType?.indexOf(ele.id);
      if (idx > -1) {
        newProjectType.splice(idx, 1);
        temp[row.index].applicableVesselType = newProjectType;
        temp = updateAllSubTask(temp, row.index, ele, 'remove');
      } else {
        newProjectType.push(ele.id);
        if (temp[row.index]) { temp[row.index].applicableVesselType = newProjectType; }
        temp = updateAllSubTask(temp, row.index, ele, 'add');
      }
    } else if (type === 'sub1') {
      const newProjectType1 = temp[activeParent || 0]?.childData || [];
      newProjectType1.forEach((i, k) => {
        if (row.id === i.id) {
          const newProjectType = i.applicableVesselType;
          const idx = newProjectType?.indexOf(ele.id);
          if (idx > -1) {
            newProjectType.splice(idx, 1);
            // eslint-disable-next-line max-len
            temp[activeParent || 0].childData[k].applicableVesselType = newProjectType;
            temp = updateSubTask(temp, row.id, ele, 'remove');
            temp = updateMainTask(temp, row.parentSpecificationId, ele, 'remove');
          } else {
            newProjectType.push(ele.id);
            // eslint-disable-next-line max-len
            temp[activeParent || 0].childData[k].applicableVesselType = newProjectType;
            temp = updateSubTask(temp, row.id, ele, 'add');
            temp = updateMainTask(temp, row.parentSpecificationId, ele, 'add');
          }
        }
      });
    } else {
      const newProjectType1 = temp[activeParent || 0]?.childData || [];
      newProjectType1.forEach((i, k) => {
        if (row.parentSpecificationId === i.id) {
          const newProjectType = i.childData[row.index].applicableVesselType;
          const idx = newProjectType?.indexOf(ele.id);
          if (idx > -1) {
            newProjectType.splice(idx, 1);
            // eslint-disable-next-line max-len
            temp[activeParent || 0].childData[k].childData[row.index].applicableVesselType = newProjectType;
            temp = updateAllMainTask(temp, k, ele, 'remove');
          } else {
            newProjectType.push(ele.id);
            // eslint-disable-next-line max-len
            temp[activeParent || 0].childData[k].childData[row.index].applicableVesselType = newProjectType;
            temp = updateAllMainTask(temp, k, ele, 'add');
          }
        }
      });
    }
    setFormData(temp);
    setTimeout(() => {
      setLoading(false);
    }, 20);
  };
  return (
    <div data-testid="gt" className="tb-gt-1 card p-5 mb-4 height-fix">
      <NavigateButton />
      <Table1Container
        formData={formData}
        formDataVesselType={formDataVesselType}
        onOpenTask={onOpenTask}
        expandConfig={expandConfig}
        onOpenTaskSub={onOpenTaskSub}
        expandSubConfig={expandSubConfig}
        setLoading={setLoading}
        checkBoxStatus={checkBoxStatus}
        handleChangeType={handleChangeType}
      />
      <div className="d-flex justify-content-between mt-4 align-items-center">
        {specificationStatus.isPublished ? (
          <div className="footer-btn  d-flex justify-content-end">
            <div className="value font-14 px-2">{`Last published date : ${moment(specificationStatus.publishedDate).format('DD-MMM-YYYY')}`}</div>
          </div>
        ) : (
          ''
        )}
        <div className="footer-btn  d-flex justify-content-end">
          <button id="cancel" onClick={() => cancel()} type="button" className="secondary-btn large me-4">
            Cancel
          </button>
          <button id="save" onClick={() => saveAsDraftDetails()} type="button" className="primary-btn large me-4">
            Save As Draft
          </button>
          <button id="publish" onClick={() => publishData()} type="button" className="primary-btn large">
            {specificationStatus.isPublished ? 'Republish' : 'Publish'}
          </button>
        </div>
      </div>
      {openPublishModal ? (
        <ModalBox
          modalClassName="medium"
          onSave={() => publishApiCall()}
          closeModal={() => setOpenPublishModal(false)}
          heading={specificationStatus.isPublished ? 'Republish' : 'Publish'}
          buttonName={specificationStatus.isPublished ? 'Republish' : 'Publish'}
        >
          {specificationStatus.isPublished ? (
            <p>Are you sure you want to republish? Once republished, the mapped data will be available for application users.</p>
          ) : (
            <p>Are you sure you want to publish? Once published, the mapped data will be available for application users.</p>
          )}
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="view-dataconfig" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default GeneralTariff;
