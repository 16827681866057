import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import ModalBox from '../../../../../common/ModalBox';

const ServiceLineModal = (props) => {
  const { setShowServiceLineModal, emptySpecListVariation,
    setContinueServ, setShowVariationPreview } = props;

  const handleContinue = () => {
    if (emptySpecListVariation?.length > 0) {
      setShowServiceLineModal(false);
      setShowVariationPreview(false);
    } else {
      setContinueServ(true);
    }
  };
  return (
    <div>
      {emptySpecListVariation?.length > 0 && (
        <>
          <div className="side-panel__content">
            <ModalBox
              modalClassName="medium  modal-scroll "
              className="secondary-btn"
              heading="Submit Specs to Send Variation"
              buttonName="Update"
              onSave={handleContinue}
              closeModal={() => setShowServiceLineModal(false)}
              extraClassName
              removeCancelBtn
              serviceLineMandatoryText
              text="Send to Yard"
            >
              {emptySpecListVariation?.map((items) => {
                return (
                  <div>
                    <div className="mt-3 py-2 d-flex align-items-center bg-sky-white md-radius">
                      <div className="ps-4 w-100">
                        {items?.specificationNo}
                      </div>
                      <div className="ms-3">
                        <span
                          data-tooltip-id="project-service"
                          data-tooltip-content={items?.title}
                        >
                          {_.truncate(items?.title, {
                            length: 45,
                            separator: ' '
                          })}

                        </span>

                      </div>
                    </div>
                  </div>
                );
              })}

            </ModalBox>

          </div>
        </>
      )}
      <Tooltip id="project-service" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ServiceLineModal;
