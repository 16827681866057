import apiEndPoints from '../../../../../utils/apiUrlConstants';
import { serviceHandler } from '../../../../../utils/serviceHandler';

const {
  RepairSpecList,
  ProjectSpecDetailsList,
  GetCancelledJob,
  GetGalleryImages,
  SaveGalleryImages,
  GetDDVessel
} = apiEndPoints;

export const GetDDvessel = (payload) => {
  return serviceHandler({
    url: `${GetDDVessel}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const getAllSpecList = (payload) => {
  return serviceHandler({
    url: `${RepairSpecList}?projectId=${payload.projectId}&searchString=${payload.searchString}`,
    methordType: 'get',
    payload
  });
};
export const projectSpecDetailsList = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecDetailsList}?projectSpecId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const getCancelledJob = (payload) => {
  return serviceHandler({
    url: `${GetCancelledJob}`,
    methordType: 'post',
    payload
  });
};

export const getGalleryImages = (payload) => {
  return serviceHandler({
    url: `${GetGalleryImages}?Id=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const deleteGalleryImages = (payload) => {
  return serviceHandler({
    url: `${GetGalleryImages}?specId=${payload.id}&imageId=${payload.imageId}`,
    methordType: 'delete',
    payload
  });
};
export const saveGalleryImages = (payload) => {
  return serviceHandler({
    url: `${SaveGalleryImages}`,
    methordType: 'post',
    payload
  });
};
