import React, { useEffect, useState } from 'react';
import ModalBox from '../../common/ModalBox';
import CommentModal from './commentModal';
import { getCommentsOnPhase } from './services';

const PreConfirm = ({ id, appendClassName, buttonClicked, setButtonClicked, locationPhase }) => {
  const [showComment, setShowComment] = useState(false);
  const [comments, setComments] = useState([]);
  const { location } = window;

  const openCommentModal = () => {
    setShowComment(true);
  };

  const getCommentsFromUsers = () => {
    let type = '';
    if (location?.pathname.includes('projects/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/close')) {
      type = 5;
    }
    getCommentsOnPhase({ id, type }).then((res) => {
      if (res.success) {
        setComments(res.data);
        if (setButtonClicked) { setButtonClicked(false); }
      }
    });
  };
  useEffect(() => {
    getCommentsFromUsers();
  }, [location, locationPhase]);

  useEffect(() => {
    if (buttonClicked) { getCommentsFromUsers(); }
  }, [buttonClicked]);

  return (
    <div data-testid="comm">
      <div className="d-flex">
        {comments.length > 0 && <span className="dot-indicator" />}
        <button
          type="button"
          className={`secondary-btn large button ${appendClassName && appendClassName}`}
          id="comments"
          onClick={() => {
            openCommentModal();
            setButtonClicked(true);
          }}
        >
          Comments
        </button>
      </div>
      {showComment && (
        <ModalBox saveDisable comment closeModal={() => setShowComment(false)} heading="Comments">
          <div className="scroll-wrap scroll-wrap-comments p-4">
            {comments.length > 0 ? (
              comments?.map((comment) => {
                return <CommentModal comment={comment} />;
              })
            ) : (
              <CommentModal comment={0} />
            )}
          </div>
        </ModalBox>
      )}
    </div>
  );
};
export default PreConfirm;
