/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useOutletContext } from 'react-router-dom';
// eslint-disable-next-line import/no-named-as-default
import helper from '../../../../utils/helper';
import ModalBox from '../../../common/ModalBox';
import CreateCurrency from './CreateCurrency';
import CurrencyTable from './currencyTable';
import Pagination from '../../../common/pagination';
import { deleteCurrency, exportExcelCurrency, getCountryList, getCurrency, saveCurrency, setBaseCurrency, generatTemplate, bulkUploadCurrency } from './services';
import showAlert from '../../../../utils/alert';
import BaseCurrency from './baseCurrency';
import BulkUploadModal from './bulkUploadModal';
import Loader from '../../../common/loader';

const CurrencyContainer = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const { formValidation, handleValidation, MAX_FILE_SIZE } = helper;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formData, setFormData] = useState([{ countryId: '', currencyName: '', rate: '' }]);
  const [countryList, setCountryList] = useState([]);
  const [tableData, setTableData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [allCurrency, setAllCurrency] = useState([]);
  const [bCurrency, setBCurrency] = useState({});
  const [file, setFile] = useState();
  const [isUpload, setIsUpload] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openCurrencyModal, setOpenCurrencyModal] = useState(false);
  const [currencyChange, setCurrencyChange] = useState(false);

  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const [sortData, setSortData] = useState({
    sortProperty: 'CountryName',
    isDescending: false
  });
  const reloadTable = (page) => {
    setLoading(true);
    getCurrency({
      page: page || currentpage,
      pageSize,
      search: searchText,
      sortProperty: sortData.sortProperty,
      isDescending: sortData.isDescending
    }).then((res) => {
      setLoading(false);
      if (res.data) {
        setTableData(res.data);
        if (setCount) {
          setCount(res?.data?.totalItems || 0);
        }
        setBCurrency(res.data.additionalData);
        getCurrency({ page: 1, pageSize: 500, search: '' }).then((res2) => {
          if (res2.data) {
            const list = _.sortBy(res2.data.items, 'currencyName').map((opt) => {
              return ({ label: <div className="d-flex align-items-center">
                <img src={`data:image/jpeg;base64,${opt.flagImage}`} className="me-4" style={{ height: 25 }} />
                <span>{opt.currencyName}</span>
              </div>,
              value: opt.id });
            });
            setAllCurrency(list);
          }
        });
      }
    });
  };

  useEffect(() => {
    getCountryList().then((res) => {
      if (res.data) {
        const options = res.data.map((opt) => {
          return ({ label: opt.countryName, value: opt.id, name: opt.currency });
        });
        setCountryList(options);
      }
    });
  }, []);
  const editData = (data) => {
    setOpenModal(true);
    setFormData([data]);
  };
  const deteleData = (data) => {
    setOpenDeleteModal(data.id);
  };

  const deleteApiCall = () => {
    deleteCurrency({ id: openDeleteModal }).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert('Successfully Deleted', 'success');
      }
    });
    setOpenDeleteModal(false);
  };

  /* istanbul ignore next */
  const saveApiCall = () => {
    let isFilled = true;
    // eslint-disable-next-line array-callback-return
    formData.map((valid) => {
      if (!formValidation(['countryId', 'rate'], valid)) {
        isFilled = false;
      }
    });
    if (isFilled) {
      const ii = formData[0]?.rate;
      const fd = [
        {
          id: formData[0]?.id,
          countryId: formData[0]?.countryId,
          currencyName: formData[0]?.currencyName,
          rate: String(ii),
          status: formData[0]?.status
        }
      ];
      saveCurrency(fd).then((res) => {
        if (res.success) {
          reloadTable();
          if (isAddClicked) {
            showAlert('Successfully Saved', 'success');
          } else {
            showAlert('Successfully Updated', 'success');
          }
          setFormData([{ countryId: '', currencyName: '', rate: '' }]);
          setOpenModal(false);
          setIsAddClicked(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const handleChange = (key, e, isSelect, field) => {
    const countryData = [...formData];
    if (isSelect) {
      let isExist = false;
      countryData.map((selected) => {
        if (selected.countryId === e.value) {
          isExist = true;
        }
        return null;
      });
      if (isExist) {
        showAlert('Already added', 'error');
      } else {
        countryData[key].countryId = e.value;
        countryData[key].currencyName = e.name;
      }
    } else {
      const perVal = e.target.value.replace(/[^0-9.]/g, '');
      if (/^[0-9]*(\.[0-9]{0,2})?$/.test(perVal)) {
        countryData[key][e.target.name] = perVal;
      } else {
        countryData[key][e.target.name] = String(_.round(perVal, 2));
      }
    }
    setFormData(countryData);
    handleValidation(['countryId', 'rate'], countryData, field);
  };
  const addCurrency = () => {
    const newCurrency = [...formData];
    newCurrency.push({ countryId: '', currencyName: '', rate: '' });
    setFormData(newCurrency);
  };
    /* istanbul ignore next */
  const removeRow = (key) => {
    const existingCurrency = [...formData];
    existingCurrency.splice(key, 1);
    setFormData(existingCurrency);
  };
  const onSearch = (e) => {
    if (!e || e.key === 'Enter') {
      reloadTable(1);
      setCurrentpage(1);
    }
  };
  useEffect(() => {
    reloadTable();
  }, [currentpage, pageSize]);

  /* istanbul ignore next */
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const addNewCurrency = () => {
    setOpenModal(true);
    setFormData([{ countryId: '', currencyName: '', rate: '' }]);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const onBaseChange = (e) => {
    setOpenCurrencyModal(true);
    setCurrencyChange(e);
  };
    /* istanbul ignore next */
  const saveCurrencyApiCall = () => {
    setOpenCurrencyModal(false);
    setBaseCurrency({ id: currencyChange.value }).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert('Base currency updated', 'success');
        setCurrencyChange('');
      }
    });
  };
    /* istanbul ignore next */
  const ondrop = (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        setFile(first);
        setIsUpload(false);
      }
    } else if (first) {
      showAlert('Please upload template excel format file', 'error');
    }
    e.target.files = [];
    setFile({});
  };

  const bulkUpload = () => {
    const body = new FormData();
    body.append('file', file);
    bulkUploadCurrency(body).then((res) => {
      if (res.success) {
        reloadTable();
        showAlert(`Successfully Uploaded ${res.data} records`, 'success');
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      } else {
        setUploadModal(false);
        setFile();
        setIsUpload(true);
      }
    });
  };

  const generatTemplateForExcel = () => {
    generatTemplate();
  };

  const onCloseModal = () => {
    setUploadModal(false);
    setFile();
    setIsUpload(true);
  };

  useEffect(() => {
    reloadTable();
  }, [sortData]);

  const handleSort = (sort, isDesc) => {
    setSortData({
      sortProperty: sort,
      isDescending: isDesc
    });
  };
  const handleCurrencyExportExcel = () => {
    if (tableData?.items?.length === 0) {
      showAlert('No Rows Found', 'error');
    } else {
      exportExcelCurrency(searchText);
    }
  };

  useEffect(() => {
    if (!searchText) {
      reloadTable(1);
    }
  }, [searchText]);

  return (
    <div data-testid="currency">
      {loading ? <Loader /> : (
        <div>
          {openModal ? (
            <ModalBox
              heading={isAddClicked ? 'Add Currency' : 'Update Currency'}
              closeModal={() => {
                setOpenModal(false);
                setIsAddClicked(false);
              }}
              buttonName="Save"
              modalClassName="medium modal-scroll"
              onSave={() => saveApiCall()}
            >
              <CreateCurrency
                formData={formData}
                handleChange={handleChange}
                countryList={countryList}
                AddCurrency={addCurrency}
                removeRow={removeRow}
              />
            </ModalBox>
          ) : (
            ''
          )}
          {
        openDeleteModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Delete"
            onSave={() => deleteApiCall()}
            closeModal={() => setOpenDeleteModal(false)}
            buttonName="Delete"
          >
            <p>Are you sure you want to delete currency ?</p>
          </ModalBox>
        ) : (
          ''
        )
      }
          <div className="card p-5 mb-4 height-fix">
            <div className="d-flex mb-4 justify-content-between">
              <div className="d-flex col-gap-1 spec-search">
                <div className="search large">
                  <div className="d-flex justify-content-end">
                    <span
                      className={searchText ? ' icon-close-line ' : ''}
                      onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                    />
                    <span className="icon-search" onClick={() => { onSearch(); }} />
                  </div>

                  <input
                    type="text"
                    id="search"
                    value={searchText}
                    className="text-box large with-close"
                    placeholder="Search by Country, Currency"
                    onKeyPress={onSearch}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
                <button type="button" id="add-currency" onClick={() => { addNewCurrency(); setIsAddClicked(true); }} className="primary-btn large">
                  Add Currency
                </button>
                <button
                  type="button"
                  id="add-name"
                  className="secondary-btn large"
                  onClick={() => {
                    setUploadModal(true);
                  }}
                >
                  Bulk Upload
                </button>
                {uploadModal && (
                <ModalBox
                  modalClassName="medium"
                  heading="Upload Excel"
                  onSave={() => { bulkUpload(); }}
                  closeModal={() => onCloseModal()}
                  buttonName="Upload"
                  saveDisable={isUpload}
                >
                  <BulkUploadModal
                    file={file}
                    ondrop={ondrop}
                    setFile={setFile}
                    generatTemplateForExcel={generatTemplateForExcel}
                    name="Currency"
                    setIsUpload={setIsUpload}
                  />
                </ModalBox>
                )}
              </div>
              <div className="d-flex col-gap-1">
                <button type="button" id="excel" className="link-btn large " onClick={handleCurrencyExportExcel}>
                  <span className="icon-download me-4" />
                  Excel Download
                </button>
              </div>
            </div>
            <BaseCurrency
              allCurrency={allCurrency}
              onBaseChange={onBaseChange}
              bCurrency={bCurrency}
              tableData={tableData}
              openCurrencyModal={openCurrencyModal}
              setOpenCurrencyModal={setOpenCurrencyModal}
            />
            <div className="currency-page currency-scroll-wrap">
              <CurrencyTable
                tableData={tableData}
                deteleData={deteleData}
                editData={editData}
                onSearch={onSearch}
                handleChange={handleChange}
                onSizeChange={onSizeChange}
                handleSort={handleSort}
                sortData={sortData}
                loading={loading}
              />
              {tableData?.totalItems > 10 && (
              <Pagination
                pageClick={pageClick}
                pageCount={tableData.totalPages}
                currentpage={currentpage}
                onSizeChange={onSizeChange}
                size={pageSize}
              />
              )}
            </div>
          </div>
          {openCurrencyModal && (
          <ModalBox
            modalClassName="medium"
            onSave={() => saveCurrencyApiCall()}
            closeModal={() => setOpenCurrencyModal(false)}
            heading="Base Currency"
            buttonName="Update"
          >
            <div>Are you sure, you want to change the Base Currency?</div>
          </ModalBox>
          )}
        </div>
      )}

    </div>
  );
};
export default CurrencyContainer;
