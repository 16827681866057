/* eslint-disable */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { concat } from 'lodash';
import Select from 'react-select';
import showAlert from '../../../../utils/alert';
import InputType1 from '../../../common/InputType1';
import { s3Upload } from '../../Resource/ReferenceDoc/services';
import helper from '../../../../utils/helper';
import { GetNonMappedFleets, GetNonMappedVessels } from './services';
import AddVesselUser from './addVesselUser';
import Loader from '../../../common/loader';

const acceptedFiles = ['image/jpeg', 'image/jpg', 'image/png'];

const AddUser = (props) => {
  const [managementList, setmanagementList] = useState([]);
  const { handleValidation, addColor, generateOptions, MAX_FILE_SIZE } = helper;
  const {
    currentUser,
    roles,
    vessels,
    setCurrentData,
    management,
    saveTrigger,
    currentDataFixed,
    loading,
    setPassword,
    password,
    errors,
    setErrors,
    vesselId
  } = props;

  const vesselName = sessionStorage.getItem('vesselName');
  const [tempUserName, setTempUserName] = useState('');

  const getRoleCategory = (role) => {
    const f = roles.filter((i) => i.id === role);
    let found = false;
    if (f && f.length) {
      if (f[0].type === 4) {
        // type of Viewer
        found = true;
      }
    }
    return found;
  };
  const validateFieldsInForm = (currentDataV) => {
    if (saveTrigger) {
      currentDataV.roleData.map((valid, key) => {
        ['management', 'role'].forEach((item) => {
          const mId = item + key;
          if (valid[item] === null || valid[item] === '' || valid[item] === ' ') {
            addColor(mId, 'add');
          } else {
            addColor(mId, '');
          }
        });
        if (getRoleCategory(valid.roleId)) {
          const vId = `fleets${key}`;
          if (valid.fleets?.length < 1) {
            addColor(vId, 'add');
          } else {
            addColor(vId, '');
          }
        } else {
          const vId = `vessel${key}`;
          if (valid.vessels.length < 1) {
            addColor(vId, 'add');
          } else {
            addColor(vId, '');
          }
        }
        return null;
      });
    }
  };
  const getOptions = (List, v, label, type) => {
    const managementSelectedList = [];
    currentUser.roleData.forEach((i) => {
      managementSelectedList.push(i.managementId);
    });
    if (type) {
      return List.map((item) => {
        return {
          value: item[v],
          label: `${item[label]} (${item[type]})`,
          // isDisabled: managementSelectedList.includes(item.id),
          ...item,
        };
      });
    }
    return List.map((item) => {
      return {
        value: item[v],
        label: item[label],
        isDisabled: managementSelectedList.includes(item.id),
        ...item,
      };
    });
  };
  useEffect(() => {
    setmanagementList(getOptions(management, 'id', 'name', 'location'));
  }, [currentUser]);
  const handleChange = (e, field) => {
    // setErrors('');
    const currentData = { ...currentUser };
    if (field === 'isAdmin') {
      currentData.isAdmin = !currentData.isAdmin;
    } else if (field === 'isLocalUser') {
      addColor('employeeId', '');
      currentData.isLocalUser = !currentData.isLocalUser;
    } else if (field === 'isLocalAdmin') {
      currentData.isLocalAdmin = !currentData.isLocalAdmin;
    } else if (e.target.name === 'phone' || e.target.name === 'mobile') {
      currentData[e.target.name] = e.target.value.replace(/[^0-9 )(+-]/g, '').substring(0, 15);
    } else if (e.target.name === 'firstName' || e.target.name === 'lastName' || e.target.name === 'middleName') {
      currentData[e.target.name] = e.target.value.substring(0, 25);
    } else if (e.target.name === 'email') {
      const emailId = e.target.value.replace(/\s/g, '');
      currentData[e.target.name] = emailId.substring(0, 64);
      currentData.userName = e.target.value.substring(0, 64);
    } else if (e.target.name === 'userName') {
      const username = e.target.value.replace(/\s/g, '');
      currentData[e.target.name] = username.substring(0, 64);
    } else if (e.target.name === 'pwdHash') {
      setPassword(e.target.value.substring(0, 64));
      currentData[e.target.name] = e.target.value.substring(0, 64);
      setErrors({ password: '' });
    } else {
      currentData[e.target.name] = e.target.value;
    }
    setCurrentData(currentData);
    let arr1 = [];
    if (currentData?.isLocalUser) {
      arr1 = ['firstName', 'userName', 'pwdHash'];
      addColor('email', '');
      addColor('employeeId', '');
    } else {
      arr1 = ['email', 'employeeId', 'firstName'];
      addColor('userName', '');
      addColor('pwdHash', '');
    }
    handleValidation(arr1, currentData, field);
  };
  /* istanbul ignore next */
  const selectChange = (key, e, val, label) => {
    const currentData = { ...currentUser };
    // currentData.isLocalUser = currentUser.isLocalUser;
    currentData.roleData[key][val] = e.value;
    currentData.roleData[key][label] = e.label;
    if (val === 'roleId') {
      if (e.type === 5) {
        currentData.isVesselUser = true;
      } else {
        currentData.isVesselUser = false;
        currentData.isLocalUser = false;
      }
      if (!vesselId) currentData.roleData[key].vessels = [];
      currentData.roleData[key].fleets = [];
    }
    if (val === 'managementId') {
      const mId = e.value;
      if (mId) {
        GetNonMappedFleets(mId).then((res) => {
          // if (currentDataFixed?.roleData && currentDataFixed.roleData[key]
          //   && currentDataFixed?.roleData.length
          //   && currentData?.roleData[key][val] === currentDataFixed?.roleData[key][val]) {
          //   currentData.roleData[key].fleetList = concat(
          //     generateOptions(res.data, 'id', 'name'),
          //     generateOptions(currentDataFixed.roleData[key].fleets,
          //       'id', 'name')
          //   );
          // } else {
          currentData.roleData[key].fleetList = generateOptions(res.data, 'id', 'name');
          // }
          GetNonMappedVessels(currentData.roleData[key].managementId, currentData.roleData[key].roleId).then((res1) => {
            if (
              currentDataFixed?.roleData &&
              currentDataFixed?.roleData[key] &&
              currentDataFixed?.roleData.length &&
              currentData.roleData[key][val] === currentDataFixed.roleData[key][val]
            ) {
              currentData.roleData[key].vesselList = concat(
                generateOptions(res1.data, 'id', 'name'),
                generateOptions(currentDataFixed.roleData[key].vessels, 'id', 'name')
              );
            } else {
              currentData.roleData[key].vesselList = generateOptions(res1.data, 'id', 'name');
            }
            currentData.roleData[key].vessels = [];
            currentData.roleData[key].fleets = [];
            setCurrentData(currentData);
            validateFieldsInForm(currentData);
          });
        });
      } else {
        currentData.roleData[key].vessels = [];
        currentData.roleData[key].fleets = [];
        setCurrentData(currentData);
        validateFieldsInForm(currentData);
      }
    } else if (val === 'roleId' && !vesselId) {
      GetNonMappedVessels(currentData.roleData[key].managementId, currentData.roleData[key].roleId).then((res1) => {
        if (
          currentDataFixed?.roleData &&
          currentDataFixed?.roleData[key] &&
          currentDataFixed?.roleData.length &&
          currentData.roleData[key][val] === currentDataFixed.roleData[key][val]
        ) {
          currentData.roleData[key].vesselList = concat(
            generateOptions(res1.data, 'id', 'name'),
            generateOptions(currentDataFixed.roleData[key].vessels, 'id', 'name')
          );
        } else {
          currentData.roleData[key].vesselList = generateOptions(res1.data, 'id', 'name');
        }
        currentData.roleData[key].vessels = [];
        currentData.roleData[key].fleets = [];
        setCurrentData(currentData);
        validateFieldsInForm(currentData);
      });
    } else {
      setCurrentData(currentData);
      validateFieldsInForm(currentData);
    }
  };
  const allSelect = (item) => {
    let optionList = [];
    if (vessels) {
      optionList = vessels.filter((fil) => fil.managementGroupId === item.managementId);
    }
    return optionList;
  };
  const vesselChange = (key, e, type) => {
    if (type === 'vessels') {
      const currentData = { ...currentUser };
      if (e.length && e[e.length - 1].value === 'All') {
        currentData.roleData[key].vessels = allSelect({
          managementId: currentData.roleData[key].managementId,
        }).map((v) => ({
          id: v.value,
          name: v.label,
        }));
      } else if (currentData?.isVesselUser) {
        currentData.roleData[key].vessels = [{ id: e.value, name: e.label }];
      } else {
        currentData.roleData[key].vessels = e.map((v) => ({ id: v.value, name: v.label }));
      }
      setCurrentData(currentData);
      validateFieldsInForm(currentData);
    } else {
      const currentData = { ...currentUser };
      if (e.length && e[e.length - 1].value === 'All') {
        currentData.roleData[key].fleets = allSelect({
          managementId: currentData.roleData[key].managementId,
        }).map((v) => ({
          id: v.value,
          name: v.label,
        }));
      } else {
        currentData.roleData[key].fleets = e.map((v) => ({ id: v.value, name: v.label }));
      }
      setCurrentData(currentData);
      validateFieldsInForm(currentData);
    }
  };
  const addManagement = () => {
    const currentData = { ...currentUser };
    currentData.roleData.push({ vessels: [], management: '', role: '' });
    setCurrentData(currentData);
  };
  const removeManagement = (key) => {
    const currentData = { ...currentUser };
    currentData.roleData.splice(key, 1);
    setCurrentData(currentData);
  };
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
      } else {
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          const currentData = { ...currentUser };
          currentData.profileImage = res.data;
          setCurrentData(currentData);
          showAlert('Successfully Uploaded', 'success');
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
    }
  };
  // const getVOptions = (item) => {
  //   let optionList = [];
  //   if (vessels) {
  //     optionList = vessels.filter((fil) => fil.managementGroupId === item.managementId);
  //   }
  //   if (optionList.length > 1) {
  //     optionList.unshift({ label: 'All', value: 'All' });
  //   }
  //   return optionList;
  // };
  const newRoles = roles.filter((adminFilter) => {
    if (adminFilter.label !== 'Admin') {
      if (!currentUser?.isVesselUser && adminFilter.type === 5 && currentUser?.roleData?.length > 1) {
        // eslint-disable-next-line no-param-reassign
        adminFilter.isDisabled = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        adminFilter.isDisabled = false;
      }
      return adminFilter;
    }
  });
  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <div className="row add-user me-2" data-testid="add-user">
      <div className="col-4">
        <div className="d-flex align-items-center flex-column">
          <div className="upload-sec">
            {currentUser?.profileImage ? (
              <img src={currentUser.profileImage} alt="" className="thmb-xlg" />
            ) : (
              <div className="thmb-xlg user-thmb m-auto" />
            )}
            <div className="upload-btn m-auto mt-4">
              <button className="link-btn">Upload profile image</button>
              <input
                type="file"
                id="upload"
                name="myfile"
                onChange={(e) => {
                  ondrop(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-8">
        {!vesselId &&
          <div className="check-box">
            <input
              type="checkbox"
              id="isAdmin"
              data-test="isAdmin"
              value="isAdmin"
              className={currentUser?.isLocalUser ? "cursor-disable" : ""}
              disabled={currentUser?.isLocalUser}
              checked={currentUser?.isAdmin}
              onClick={() => {
                handleChange('', 'isAdmin');
              }}
            />
            <label className={currentUser?.isLocalUser ? "cursor-disable" : ""} htmlFor="isAdmin"> This user is an Admin user</label>
          </div>
        }
        {currentUser?.isAdmin ? (
          ''
        ) : (
          <div>
            <h4 className="mt-3 mb-3  head-16">Management & Role</h4>
            {currentUser.roleData?.map((item, key) => {
              const mId = 'management' + key;
              const rId = 'role' + key;
              const vId = 'vessel' + key;
              const fId = 'fleets' + key;
              return (
                <div className="skywhite-bg p-4 my-3 md-radius relative">
                  <div className="row mb-3">
                    <div className="col-6" data-tooltip-id="project-managementname" data-tooltip-content={item.management}>
                      <label>
                        Management
                        <span className="text-primaryRed-1">*</span>
                      </label>
                      <Select
                        className=""
                        placeholder="Management"
                        options={managementList}
                        isDisabled={!!vesselId}
                        id={mId}
                        value={item.managementId ? managementList?.filter((opt) => opt.value === item.managementId) : ''}
                        onChange={(e) => selectChange(key, e, 'managementId', 'management')}
                      />
                    </div>
                    <div className="col-6" data-tooltip-id="project-managementrole" data-tooltip-content={item.role}>
                      <label>
                        Role
                        <span className="text-primaryRed-1">*</span>
                      </label>
                      <Select
                        className=""
                        placeholder="Role"
                        id={rId}
                        options={newRoles}
                        isDisabled={!item.managementId}
                        value={item.roleId ? { label: item.role, value: item.roleId } : ''}
                        onChange={(e) => selectChange(key, e, 'roleId', 'role')}
                      />
                    </div>
                  </div>
                  {getRoleCategory(item.roleId) ? (
                    <div className="row mb-3">
                      <div className="col-12">
                        <label>
                          Fleet
                          <span className="text-primaryRed-1">*</span>
                        </label>
                        <Select
                          className=""
                          placeholder="Fleet"
                          id={fId}
                          isDisabled={!item.managementId}
                          options={item.fleetList || []}
                          value={item.fleets ? item.fleets.map((v) => ({ label: v.name, value: v.id })) : ''}
                          isMulti
                          onChange={(e) => vesselChange(key, e, 'fleets')}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row mb-3">
                      <div className="col-12">
                        <label>
                          Vessel
                          <span className="text-primaryRed-1">*</span>
                        </label>
                        <Select
                          className=""
                          placeholder="Vessel"
                          id={vId}
                          isDisabled={!item.managementId || !!vesselId}
                          options={item.vesselList || []}
                          value={item?.vessels?.map((v) => ({ label: v.name, value: v.id }))}
                          isMulti={!currentUser?.isVesselUser && !currentUser?.isLocalUser}
                          onChange={(e) => vesselChange(key, e, 'vessels')}
                        />
                      </div>
                    </div>
                  )}
                  {!vesselId &&
                    <button
                      type="button"
                      onClick={() => {
                        removeManagement(key);
                      }}
                      className="link-btn absolute delete-card"
                      id="remove"
                    >
                      <span className="icon-close font-22"> </span>
                    </button>
                  }
                </div>
              );
            })}
            {(!currentUser?.isVesselUser || currentUser.roleData?.length < 1) && !vesselId ? (
              <button id="add" className="link-btn" type="button" onClick={addManagement}>
                <span className="icon-plus-square me-2" />
                Add More Roles
              </button>
            ) : (
              <div>
                <div className="check-box mb-2">
                  <input
                    type="checkbox"
                    id="isLocalUser"
                    data-test="localUser"
                    name=""
                    className={vesselId ? "cursor-disable" : ""}
                    value="isLocalUser"
                    checked={currentUser.isLocalUser}
                    onClick={() => {
                      if (!vesselId) handleChange('', 'isLocalUser');
                    }}
                  />
                  <label className={vesselId ? "cursor-disable" : ""} htmlFor="isLocalUser"> This user is a local vessel user</label>
                </div>
                <div className="check-box mb-2">
                  <input
                    type="checkbox"
                    id="isLocalAdmin"
                    data-test="isLocalAdmin"
                    name=""
                    value="isLocalAdmin"
                    checked={currentUser.isLocalAdmin}
                    onClick={() => {
                      handleChange('', 'isLocalAdmin');
                    }}
                  />
                  <label htmlFor="isLocalAdmin"> This user is an approver</label>
                </div>
              </div>
            )}
          </div>
        )}

        <h4 className="mb-3  head-16">General Details</h4>
        <div className="row mb-3">
          <div className="col-5">
            <InputType1
              autoFocus
              className="modal-input"
              id="firstName"
              label="First Name"
              name="firstName"
              value={currentUser.firstName}
              onChange={(e) => handleChange(e, 'firstName')}
              isMandatory="true"
            />
          </div>
          <div className="col-3">
            <InputType1 className="modal-input" label="Middle" name="middleName" value={currentUser.middleName} onChange={handleChange} />
          </div>
          <div className="col-4">
            <InputType1 className="modal-input" label="Last Name" name="lastName" value={currentUser.lastName} onChange={handleChange} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-5">
            {currentUser?.isLocalUser ? (
              <InputType1
                className="modal-input"
                label="Employee ID"
                name="employeeId"
                value={currentUser?.employeeId || ''}
                onChange={(e) => handleChange(e, 'employeeId')}
                id="employeeId"
              />
            ) : (
              <InputType1
                className="modal-input"
                label="Employee ID"
                name="employeeId"
                value={currentUser?.employeeId || ''}
                onChange={(e) => handleChange(e, 'employeeId')}
                isMandatory="true"
                id="employeeId"
              />
            )}
          </div>
        </div>
        {currentUser?.isLocalUser && !currentUser.isAdmin ? (
          <AddVesselUser
            handleChange={handleChange}
            currentUser={currentUser}
            setCurrentData={setCurrentData}
            setErrors={setErrors}
            setPassword={setPassword}
            password={password}
            errors={errors}
            vesselId={vesselId}
            vesselName={vesselName}
            tempUserName={tempUserName}
            setTempUserName={setTempUserName}
          />
        ) : (
          <>
            <h4 className="mt-5 mb-3  head-16">Contact Details</h4>
            <div className="row mb-3">
              <div className="col-6">
                <InputType1
                  className="modal-input"
                  label="Email"
                  name="email"
                  value={currentUser?.email || ''}
                  onChange={(e) => handleChange(e, 'email')}
                  isMandatory="true"
                  id="email"
                />
              </div>
              <div className="col-6">
                <InputType1
                  className="modal-input"
                  label="Phone"
                  name="phone"
                  value={currentUser?.phone}
                  onChange={(e) => handleChange(e, 'phone')}
                  id="phone"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <InputType1
                  className="modal-input"
                  label="Mobile"
                  name="mobile"
                  value={currentUser?.mobile}
                  onChange={(e) => handleChange(e, 'mobile')}
                  id="mobile"
                />
              </div>
              <div className="col-6">
                <InputType1
                  className="modal-input"
                  title={currentUser?.email}
                  label="Username"
                  name="userName"
                  disabled
                  value={currentUser?.email}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        )}
        {currentDataFixed?.firstName ? (
          <div className="my-4">
            <span className="icon-Info red" />
            <span className="font-13 text-content-gray ms-2">
              Please note: Once the role change is saved, the corresponding user has to logout of the application and login again for the changes to
              reflect.
            </span>
          </div>
        ) : (
          ''
        )}
      </div>

      <Tooltip id="project-managementname" place="top" className="tooltip mt-4" />
      <Tooltip id="project-managementrole" place="top" className="tooltip mt-5" />
    </div>
  );
};
export default AddUser;
