import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  SaveTemplate,
  PublishTemplate,
  DeleteTemplate,
  GetReportTemplate,
  getReportTemplateListUrl,
  TemplateKeywordUrl
} = apiEndPoints;

export const getReportTemplateLists = (payload) => {
  return serviceHandler({
    url: `${getReportTemplateListUrl}`,
    methordType: 'post',
    payload
  });
};
export const getReportTemplate = (payload) => {
  return serviceHandler({
    url: `${GetReportTemplate}?Id=${payload}`,
    methordType: 'post',
    payload
  });
};
export const saveTemplates = (payload) => {
  return serviceHandler({
    url: `${SaveTemplate}`,
    methordType: 'post',
    payload
  });
};
export const deleteTemplate = (payload) => {
  return serviceHandler({
    url: `${DeleteTemplate}?Id=${payload}`,
    methordType: 'post',
    payload
  });
};
export const publishTemplate = (payload) => {
  return serviceHandler({
    url: `${PublishTemplate}?id=${payload.id}`,
    methordType: 'post',
    payload
  });
};
export const getTemplateKeyword = (payload) => {
  return serviceHandler({
    url: `${TemplateKeywordUrl}?category=${payload}`,
    methordType: 'get',
    payload
  });
};
