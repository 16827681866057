/* eslint-disable no-useless-escape */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import qs from 'query-string';
import CryptoJS from 'crypto-js';
import PasswordStrengthBar from 'react-password-strength-bar';
import brandLogo from '../../../images/brand-logo.svg';
import Caption from '../common/caption';
import Footer from '../common/footer';
import { resetingPassword, verifyEmailExpiry } from './services';
import InputType1 from '../../common/InputType1';
import ModalBox from '../../common/ModalBox';
import ExpiredPage from './expiredPage';

const SetNewPassword = () => {
  const [expiredPassword, setExpiredPassword] = useState(false);
  const params = qs.parse(window.location.search);
  const navigate = useNavigate();
  const handleBackToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    verifyEmailExpiry({ token: params.token })?.then((res) => {
      if (!res.data.isValid) {
        setExpiredPassword(true);
      }
    });
  }, []);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({ newPassword: '', confirmPassword: '' });

  // eslint-disable-next-line consistent-return
  const handleErrors = () => {
    if (!newPassword) {
      setErrors({ newPassword: 'Please Enter the New Password' });
    } else if (
      newPassword.length < 8
      || newPassword.search(/[0-9]/) === -1
      || (newPassword.search(/[A-Z]/) === -1 && newPassword.search(/[a-z]/) === -1)
      || newPassword.search(/[!\@\#\$\%\^\&\()\_\:\.]/) === -1
    ) {
      setErrors({
        newPassword: 'Password must be at least 8 characters, include a number, a letter and a symbol.'
      });
    } else if (!confirmPassword) {
      setErrors({ confirmPassword: 'Please Enter the Confirm Password' });
    } else if (newPassword === confirmPassword) {
      if (newPassword.length < 8) {
        setErrors({ newPassword: 'Password must be at least 8 characters, include a number, a letter and a symbol.' });
      } else if (confirmPassword.length < 8) {
        setErrors({ confirmPassword: 'Password must be at least 8 characters, include a number, a letter and a symbol.' });
      }
    } else if (newPassword && !confirmPassword) {
      setErrors({ confirmPassword: 'Please Enter confirm password' });
    } else if (!newPassword && confirmPassword) {
      setErrors({ newPassword: 'Please Enter New Password' });
    } else if (newPassword !== confirmPassword) {
      setErrors({ newPassword: 'Password does not match' });
    } else {
      return '';
    }
    return null;
  };

  function encrypt(input) {
    const salt = 'DryDock';
    const hmac = CryptoJS.HmacSHA256(input, salt);
    return hmac.toString(CryptoJS.enc.Hex);
  }

  // eslint-disable-next-line consistent-return
  const handleResetPassword = () => {
    if (newPassword === confirmPassword && newPassword.length >= 8 && confirmPassword.length >= 8) {
      resetingPassword({ token: params.token, passWordHash: encrypt(newPassword) })?.then((res) => {
        if (res.success) {
          navigate('/reset-success');
        }
      });
    } else {
      handleErrors();
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleNewPassword = (e) => {
    const { value } = e.target;
    setNewPassword(value);
    setErrors({ newPassword: '' });
  };

  const handleConfirmPassword = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setErrors({ confirmPassword: '' });
  };

  return (
    <div data-testid="setnewpassword">
      <div className="wrapper login-wrap">
        <Caption />
        <div className="card login-card">
          <div className="brand-logo mb-5">
            <a href="#">
              <img src={brandLogo} alt="Maridock Logo" className="brand-img" />
            </a>
          </div>

          <h4 className="main-head mt-5">Set New Password</h4>
          <p className="mb-5 mt-2 font-18">Your new password must be different from previously used passwords.</p>

          <div className="mb-5 d-flex flex-column">
            <div className="relative">
              <InputType1
                autoFocus
                type={showPassword ? 'text' : 'password'}
                className={errors.newPassword ? 'text-box xlarge w-full error-field' : 'text-box xlarge w-full'}
                value={newPassword}
                maxLength={16}
                onChange={handleNewPassword}
                isMandatory="true"
                label="New Password"
                data-testid="newpassword"
                name="newPassword"
              />
              <button type="button" className="link-btn visible-btn" data-testid="shownewpassword" onClick={handleShowPassword} id="shownewpassword">
                {showPassword ? <span className="icon-eye-close font-24" />
                  : <span className="icon-eye font-24" />}
              </button>
            </div>

            <PasswordStrengthBar password={newPassword} minLength={8} shortScoreWord=" " scoreWords={['weak', 'weak', 'average', 'good', 'strong']} />
            <p style={{ color: 'red' }}>{errors.newPassword}</p>
          </div>

          <div className="mb-3 d-flex flex-column">
            <div className="relative">
              <InputType1
                type={showConfirmPassword ? 'text' : 'password'}
                className={errors.confirmPassword ? 'text-box xlarge w-full error-field' : 'text-box xlarge w-full'}
                value={confirmPassword}
                onChange={handleConfirmPassword}
                maxLength={16}
                isMandatory="true"
                label="Re-Enter New Password"
                data-testid="confirmpassword"
                name="confirmPassword"
              />
              <button
                type="button"
                className="link-btn visible-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                data-testid="showconfirm"
                id="shownewpassword2"
              >
                {showConfirmPassword ? <span className="icon-eye-close font-24" />
                  : <span className="icon-eye font-24" />}
              </button>
            </div>
            <p style={{ color: 'red' }}>{errors.confirmPassword}</p>
          </div>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <div className="d-flex flex-row justify-content-between mb-4" />

          <button data-testid="resetbutton" className="primary-btn xlarge full-width my-3" type="button" onClick={handleResetPassword}>
            Reset Password
          </button>
          <button type="button" data-testid="back" className="link-btn mb-2 mt-5" onClick={handleBackToLogin}>
            <span className="icon-arrow-left2 pe-2 me-2" />
            Back to Login
          </button>
        </div>
      </div>
      {expiredPassword && (
      <ModalBox
        modalClassName="medium"
        isCloseModal={false}
      >
        <ExpiredPage />
      </ModalBox>
      )}
      <Footer />
    </div>
  );
};
export default SetNewPassword;
