import { serviceHandler } from '../../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../../utils/apiUrlConstants';

const {
  GetProjectSpecDescription,
  SaveProjectSpecDescription
} = apiEndPoints;

export const getProjectDescription = (pSId) => {
  return serviceHandler({
    url: `${GetProjectSpecDescription}?projectSpecificationId=${pSId}`,
    methordType: 'get'
  });
};
export const saveProjectDescription = (payload) => {
  return serviceHandler({
    url: `${SaveProjectSpecDescription}`,
    methordType: 'post',
    payload
  });
};
