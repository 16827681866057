/* eslint-disable jsx-a11y/anchor-is-valid */
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import showAlert from '../../../../utils/alert';
import ModalBox from '../../../common/ModalBox';
import CreateFolder from './CreateFolder';
import { DeleteData } from './services';

const DocumentSidebar = ({
  folders,
  cancel,
  handleCreateNew,
  formData,
  setFormData,
  isAddNewShow,
  setIsAddNewShow,
  handleFolderData,
  clearContainer,
  reloadTable,
  folderType,
  setFolderType,
  isSearchTextUpdated,
  parentFromId,
  setParentFromId,
  projInfo
}) => {
  const [active, setActive] = useState('');
  const [list, setList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (folderType === 1) {
      const newFolder = folders?.filter((i) => i.isSystem === true);
      setList(newFolder);
      if (newFolder?.length) {
        const i = newFolder[0];
        const activeItem = `${i.id}-${i.name}`;
        setActive(activeItem);
        handleFolderData(i);
      }
    }
    if (folderType === 2) {
      const newFolder = folders?.filter((i) => i.isSystem === false);
      setList(newFolder);
      if (newFolder?.length) {
        const i = newFolder[0];
        const activeItem = `${i.id}-${i.name}`;
        setActive(activeItem);
        handleFolderData(i);
      }
    }
  }, [folders, folderType]);
  const handleRename = (item) => {
    const i = cloneDeep(formData);
    i.name = item.name;
    i.id = item.id;
    setFormData(i);
    setIsAddNewShow(false);
  };
  const handleDelete = (data) => {
    setOpenDeleteModal(data);
  };
  const deleteApiCall = () => {
    const i = cloneDeep(openDeleteModal);
    DeleteData(i?.id).then((res) => {
      if (res.success) {
        if (i.id === parentFromId) {
          setParentFromId('');
          clearContainer(2);
          setActive('');
        }
        reloadTable();
        showAlert('Successfully Deleted', 'success');
      }
    });
  };

  return (
    <div className="" data-testid="dms-4">
      <div className="tab-set d-flex">
        <div
          className={`half-width ${folderType === 1 && 'active'}`}
          onClick={() => {
            setFolderType(1);
            clearContainer(1);
            setActive('');
          }}
          id="1"
          data-testid="click1"
        >
          System Files
        </div>
        <div
          className={`half-width ${folderType === 2 && 'active'}`}
          onClick={() => {
            setFolderType(2);
            clearContainer(2);
            setActive('');
          }}
          id="2"
          data-testid="click2"
        >
          Custom Files
        </div>
      </div>
      <div>
        <div className="relative">
          {folderType === 2 ? (
            <div className="d-flex justify-content-end pb-4">
              <button disabled={projInfo?.isClosed} className="link-btn" type="button" data-testid="addNewClick" onClick={() => setIsAddNewShow(true)}>
                <span className="font-20 icon-add-folder me-2 " />
                Create New Folder
              </button>
            </div>
          ) : (
            ''
          )}
          {folderType === 2 && isAddNewShow ? (
            <CreateFolder
              handleCreateNew={handleCreateNew}
              handleRename={handleRename}
              handleDelete={handleDelete}
              deleteApiCall={deleteApiCall}
              cancel={cancel}
              formData={formData}
              setFormData={setFormData}
            />
          ) : (
            ''
          )}
        </div>
        <div className="scroll-pannel">
          {list
          && list.map((i) => {
            const activeItem = `${i.id}-${i.name}`;
            if (i.id === formData.id) {
              return (
                <CreateFolder
                  handleCreateNew={handleCreateNew}
                  cancel={cancel}
                  formData={formData}
                  setFormData={setFormData}
                />
              );
            }
            return (
              <div
                className={`px-4 py-2 ${isSearchTextUpdated ? 'folder-list-disabled' : 'folder-list'} ${active === activeItem ? 'active' : ''}`}
              >
                <div className="row">
                  <div
                    data-testid="doc-side-click"
                    className="text-ellipsis folder col-11"
                    onClick={() => {
                      if (active !== activeItem && !isSearchTextUpdated) {
                        setActive(activeItem);
                        handleFolderData(i);
                      }
                    }}
                  >
                    {active === activeItem && !isSearchTextUpdated ? (
                      <span className="font-26 icon-folder-open me-2 icon-ind" />
                    ) : (
                      <span className="font-26 icon-folder me-2 icon-ind" />
                    )}
                    <span
                      data-tooltip-id="project-dms"
                      data-tooltip-content={i.name}
                      className="ms-3 text-ellipsis"
                    >
                      {i.name}
                    </span>
                  </div>
                  <div className="col-1">
                    {folderType === 2 && !projInfo?.isClosed ? (
                      <div className="menudropdown mt-2">
                        <span className="dropbtn font-18 icon-dotted-menu-vertical  text-blue cursor-pointer" />
                        <div className="menudropdown-content animate__animated animate__bounceIn md-radius">
                          <a
                            data-testid="add-custom-rename"
                            href="#"
                            onClick={() => {
                              handleRename(i);
                            }}
                          >
                            Rename
                          </a>
                          <a
                            data-testid="add-custom-delete"
                            href="#"
                            onClick={() => {
                              handleDelete(i);
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {openDeleteModal ? (
        <div className="side-pannel-modal">
          <ModalBox
            modalClassName="small"
            heading="Delete"
            onSave={() => deleteApiCall()}
            closeModal={() => setOpenDeleteModal(false)}
            buttonName="Delete"
          >
            <p>
              On deleting, all folders and files under this folder
              will be deleted. Do you want to continue?
            </p>
          </ModalBox>
        </div>
      ) : (
        ''
      )}
      <Tooltip id="project-dms" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default DocumentSidebar;
