import React from 'react';
import ReactTable from 'react-table-v6';
import { Tooltip } from 'react-tooltip';

const RepairPeriodTable = ({
  repairCostSummary,
  renderVariance,
  renderVariancePercentage
}) => {
  const data = [
    'Days Afloat',
    'Days in Dock',
    'Total Repair Days'
  ];
  const tableData = [];
  data.forEach((i, index) => {
    if (index === 0) {
      tableData.push(
        {
          name: i,
          estimated: repairCostSummary?.repairData?.estimatedDaysAfloat,
          actual: repairCostSummary?.repairData?.actualDaysAfloat,
          variance: repairCostSummary?.repairData?.varianceDaysAfloat,
          variancepercent: repairCostSummary?.repairData?.varianceDaysAfloatPercent
        }
      );
    } else if (index === 1) {
      tableData.push(
        {
          name: i,
          estimated: repairCostSummary?.repairData?.estimatedInDockDays,
          actual: repairCostSummary?.repairData?.actualInDockDays,
          variance: repairCostSummary?.repairData?.varianceInDockDays,
          variancepercent: repairCostSummary?.repairData?.varianceInDockDaysPercent

        }
      );
    } else {
      tableData.push(
        {
          name: i,
          estimated: repairCostSummary?.repairData?.estimatedRepairDays,
          actual: repairCostSummary?.repairData?.actualRepairDays,
          variance: repairCostSummary?.repairData?.varianceRepairDays,
          variancepercent: repairCostSummary?.repairData?.varianceRepairDaysPercent

        }
      );
    }
  });

  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: false,
      resizable: false,
      accessor: 'number',
      width: 310,
      sortable: false,
      Cell: (row) => {
        const { index } = row;
        return <div className="p2-4">{data[index]}</div>;
      }
    },
    {
      Header: 'Estimated',
      accessor: 'estimated',
      sortable: false,
      expander: false,
      width: 300,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false
    },
    {
      Header: 'Actual',
      width: 300,
      accessor: 'actual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false
    },
    {
      Header: 'Variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.estimated, original.actual, row, 'days');
      }
    },
    {
      Header: 'Variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.estimated, original.actual, row, 'days');
      }
    }
  ];
  const showPagination = false;
  return (
    <div className="" data-testid="repair-period">
      <ReactTable
        className=""
        data={tableData}
        columns={columns}
        pageSize={tableData && tableData.length > 0 ? tableData.length : 1}
        showPagination={showPagination}
      />
      <Tooltip
        id="project-specName"
        place="top"
        className="tooltip mt-3"
      />
    </div>
  );
};

export default RepairPeriodTable;
