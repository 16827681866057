import moment from 'moment';
import React from 'react';
import helper from '../../../../../../utils/helper';

const CostSummary = ({ formData, projectBasicDetails }) => {
  const { formatNumber } = helper;
  const systemCurrency = '$';

  const getData = (value, type, local) => {
    if (type === 'days') {
      return (
        <span>
          {value}
          {' '}
          Day(s)
        </span>
      );
    }
    if (type === 'date') {
      return (
        <span>
          {value ? moment(value).format('DD-MMM-YYYY') : ''}
        </span>
      );
    }
    if (type === 'price') {
      return (
        <span>
          {systemCurrency}
          {' '}
          {formatNumber(value)}
          {systemCurrency !== projectBasicDetails?.currencySymbol
            ? (
              <>
                &nbsp;
                (
                {projectBasicDetails?.currencySymbol}
                {' '}
                {formatNumber(local)}
                )
              </>
            ) : ''}
        </span>
      );
    }
    return value;
  };
  return (
    <div className="col-lg-5 col-md-12 respo-fixr" data-testid="cost-summary">
      <div className="card pb-2">
        <div className="head-16  border-down p-4 ms-2 ">Deviation and Off Hire</div>
        <div className="font-13 sum-scroll">
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Repair Start Date : </span>
            <div className="value col-7">{getData(formData.repairStartDate, 'date')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Docking Date : </span>
            <div className="value col-7">{getData(formData.dockingDate, 'date')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Date of arrival at yard : </span>
            <div className="value col-7">{getData(formData.arrivalAtYardDate, 'date')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Undocking Date : </span>
            <div className="value col-7">{getData(formData.repairCompletionDate, 'date')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">ETD : </span>
            <div className="value col-7">{getData(formData.etd, 'date')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Days In Dock : </span>
            <div className="value col-7">{getData(formData.inDockDays, 'days')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Days Afloat : </span>
            <div className="value col-7">{getData(formData.daysAfloat, 'days')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Total Deviation Days : </span>
            <div className="value col-7">{getData(formData.deviationDays, 'days')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Total Repair Days : </span>
            <div className="value col-7">{getData(formData.repairDays, 'days')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Total Off Hire Days : </span>
            <div className="value col-7">{getData(formData.offhireDays, 'days')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Hire Rate (Per Day) : </span>
            <div className="value col-7">{getData(formData.hireRate, '')}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel Type1 : </span>
            <div className="value col-7">{getData(formData.fuelTypeOneName, '', formData.fuelTypeOneName)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel Type1 - Price : </span>
            <div className="value col-7">{getData(formData.fuelPrice, 'price', formData.fuelPriceLocal)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel Type1 - Consumption : </span>
            <div className="value col-7">{getData(formData.fuelConsumption, 'price', formData.fuelConsumptionLocal)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel Type2 : </span>
            <div className="value col-7">{getData(formData.fuelTypeTwoName, '', formData.fuelTypeTwoName)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel Type2 - Price : </span>
            <div className="value col-7">{getData(formData.fuelPriceType2, 'price', formData.fuelPriceType2Local)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel Type2 - Consumption : </span>
            <div className="value col-7">{getData(formData.fuelConsumptionType2, 'price', formData.fuelConsumptionType2Local)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Misc Cost : </span>
            <div className="value col-7">{getData(formData.miscCost, 'price', formData.miscCostLocal)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Off Hire cost : </span>
            <div className="value col-7">{getData(formData.offhireCost, 'price', formData.offhireCostLocal)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Fuel cost : </span>
            <div className="value col-7">{getData(formData.fuelCost, 'price', formData.fuelCostLocal)}</div>
          </div>
          <div className="row mt-3">
            <span className="col-5 text-content-grey text-capitalize">Deviation cost : </span>
            <div className="value col-7">{getData(formData.deviationCost, 'price', formData.deviationCostLocal)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostSummary;
