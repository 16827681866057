/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import _ from 'lodash';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import { fileImages } from '../../../../../common/mappingConst';
import { s3UploadMutiple } from '../../../NewProject/services';
import { deleteRepairAttachment } from '../services';
// import { Tooltip } from 'react-tooltip';
// import Select from 'react-select';

const Attachments = (props) => {
  const { specList, setSpecList, projectId, getSpecListData, setNewDocument, newDocument, projectBasicDetails } = props;
  const { MAX_FILE_SIZE } = helper;
  const acceptedFiles = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];

  // useEffect(() => {
  //   getSpecListData();
  // }, []);
  const deleteFile = (docId, key) => {
    if (docId === '00000000-0000-0000-0000-000000000000') {
      const a = { ...specList };
      a.quoteDocuments.splice(key, 1);
      setSpecList(a);
    } else {
      deleteRepairAttachment({ projectId, documentId: docId }).then((res) => {
        if (res.success) {
          const a = { ...specList };
          a.quoteDocuments.splice(key, 1);
          setSpecList(a);
        }
      });
    }
  };
  /* istanbul ignore next */

  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = specList?.quoteDocuments.map((items) => { return items.name; });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = { ...specList };
          const arr = newDocument;
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              path: item.path,
              documentId: item.documentId,
              type: item.type,
              size: item.size
            };
            arr.push(newItem);
            setNewDocument(arr);

            return (

              attach.quoteDocuments.push(newItem)
            );
          });
          setSpecList(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };

  return (
    <>
      <div data-testid="attachment">

        <div className="drag-drop-file my-4 file-added">
          <div className="icon-buble mb-5">
            <span className="icon-file-text2" />
          </div>

          <input
            className="p-5 align-self-center"
            id="upload"
            type="file"
            name="name"
            multiple
            onChange={(e) => {
              ondrop(e);
            }}
            isMandatory="true"
            disabled={projectBasicDetails?.isClosed}
          />

          <p>Choose a file or drag it here(jpeg, jpg, png, tiff, pdf, xlsx, jfif, doc - max size(5MB))</p>
        </div>
        <div className="attachment-repair">
          {specList?.quoteDocuments?.map((item, key) => {
            return (
              <div className="">
                <div className=" card-bar d-flex item mb-2">
                  {/* <span className="icon-check-rnd me-2 file-added-multi font-20"> </span> */}
                  <div className="thmb-icon-md ">
                    <span className={fileImages[_.toLower(item?.type)]} />

                  </div>
                  <div className="ms-3">
                    <span data-tooltip-id="project-addattach" data-tooltip-content={item?.name}>
                      {_.truncate(item.name, {
                        length: 25,
                        separator: ' '
                      })}
                    </span>
                  </div>
                  <div className="d-flex brand-color font-18">

                    <a href={item.path} target="_blank" rel="noreferrer">
                      <span className="icon-eye font-18 ms-4 pt-2" />
                    </a>
                    <button
                      type="button"
                      id="delete"
                      className="link-btn clear-file "
                      onClick={() => {
                        deleteFile(item.documentId, key);
                      }}
                      disabled={projectBasicDetails?.isClosed}
                    >
                      <span className="icon-delete brand-color ms-4 font-18 "> </span>
                    </button>
                  </div>

                </div>
                <Tooltip id="project-addattach" place="top" className="tooltip mt-3" />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Attachments;
