/*eslint-disable*/
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const VariaianceNonSpec = ({ variance }) => {
   /* istanbul ignore next */
  const getClass = (value1, value2, type) => {
    if (type === 'color') {
      if (_.toNumber(value1) < _.toNumber(value2)) {
        return 'red';
      } else {
        return 'green';
      }
    } else {
      if (_.toNumber (value1 < value2)) {
        return 'icon-arrow-up2 red';
      } else {
        return 'icon-arrow-down2 green';
      }
    }
  };
  // const spec = Math.abs(c.variancePercentage)
  return (
    <div data-testid="Variance-NonSpec">
      {(variance && variance?.costSummaryData && variance?.costSummaryData.specs?.length) || variance?.costSummaryData?.nonSpecs?.length ? (
        <div className="">
          <div className="border p-3 mt-4">
            <div>
              <div className="d-flex">
                <div className="head-14">Non Specs</div>
              </div>
              <div className="bg-blue font-16 font-bold mt-2 py-2 row">
                <div className="d-flex  col-1">
                  <div className="ms-2 ">Spec #</div>
                </div>
                <div className="d-flex col-5 ">
                  <div className="ms-2 ">Spec Name</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">Project Est</div>
                </div>
                <div className="d-flex col-2 ">
                  <div className="ms-2 ">Project Actual</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">
                    <span data-tooltip-id="project-specName" data-tooltip-content="(estimate-actual)/estimate*100">
                      Variance (%)
                    </span>
                  </div>
                </div>
              </div>

              {variance?.costSummaryData?.nonSpecs.map((c) => {
                return (
                  <div className="pt-3 row">
                    <div className="d-flex  col-1">
                      <div className="">{c.number}</div>
                    </div>
                    <div className="d-flex col-5">
                      <div className="ms-2">
                        <span className="text-ellipsis pe-2" data-tooltip-id="project-specName" data-tooltip-content={c?.specName}>
                          {_.truncate(c.specName, {
                            length: 40,
                            separator: ' ',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex  col-2">
                      <div className="ms-2">
                        {variance.currencySymbol}
                        {' '}
                        <span className="text-ellipsis pe-2 " data-tooltip-id="project-specName"
                         data-tooltip-content={variance.currencySymbol + c?.projectEstimate.toFixed(2)}>
                          {_.truncate(Math.abs(c.projectEstimate).toFixed(2), {
                            length: 15,
                            separator: ' ',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex col-2 ">
                      <div className="ms-2">
                        {variance.currencySymbol}
                        {' '}
                        <span className="text-ellipsis pe-2" data-tooltip-id="project-specName" 
                        data-tooltip-content={variance.currencySymbol + c.projectActual.toFixed(2)}>
                          {_.truncate(Math.abs(c.projectActual).toFixed(2), {
                            length: 15,
                            separator: ' ',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex  col-2">
                      <div className="ms-2 d-flex">
                        <span
                          className={
                            c?.variancePercentage
                              ? 'text-ellipsis ' +
                                getClass(
                                  c.projectEstimate,
                                  c.projectActual,
                                  'color'
                                )
                              : 'text-ellipsis'
                          }
                          id="de-vi-at"
                        >
                          <span
                            className="text-ellipsis pe-2"
                            data-tooltip-id="project-specName"
                            data-tooltip-content={Math.abs(c.variancePercentage).toFixed(2)}
                          >
                            {_.truncate(Math.abs(c.variancePercentage).toFixed(2), {
                              length: 15,
                              separator: ' ',
                            })}
                          </span>
                          {/* 
                          {Math.abs(c.variancePercentage)} */}
                        </span>
                        <span
                          id="de-vi"
                          className={
                            c.variancePercentage
                              ? 'font-18 ms-2 mt-1 ' +
                                getClass(
                                  c.projectEstimate,
                                  c.projectActual,
                                  'icon'
                                )
                              : 'font-18 ms-2 mt-1'
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="d-flex col-2 ">
                            <div className="ms-2 text-ellipsis" data-tooltip-id="project-variation" data-tooltip-content={c.description}>
                              {c.description}
                            </div>
                          </div> */}
                  </div>
                );
              })}
              <div className="d-flex">
                <div className="d-flex col-1 ms-1">
                  <div className=""></div>
                </div>
                <div className="d-flex  mt-4 ms-3 col-5 ">
                  <div className="Head-16 font-16 font-bold">Total</div>
                </div>
                <div className="d-flex mt-4  col-2">
                  <div className="Head-16 font-16 font-bold">
                    <span data-tooltip-id="project-specName" 
                    data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.graphData?.nonSpecsProjectEstimate.toFixed(2)}>
                      {variance.currencySymbol}
                      {Math.abs(variance?.costSummaryData?.graphData?.nonSpecsProjectEstimate.toFixed(2))}
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-4 ">
                  <div className="Head-16 font-16 font-bold">
                    <span data-tooltip-id="project-specName" data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.graphData?.nonSpecsProjectActual}>
                      {variance.currencySymbol}
                      {' '}
                      {Math.abs(variance?.costSummaryData?.graphData?.nonSpecsProjectActual)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border p-3 mt-4">
            <div>
              <div className="d-flex">
                <div className="head-14">Deviation & Offhire</div>
              </div>
              <div className="bg-blue font-16 font-bold mt-2 py-2 row">
                <div className="d-flex  col-6">
                  <div className="ms-2 ">Item</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">Project Est</div>
                </div>
                <div className="d-flex col-2 ">
                  <div className="ms-2 ">Project Actual</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">
                    <span data-tooltip-id="project-specName" data-tooltip-content="(estimate-actual)/estimate*100">
                      Variance (%)
                    </span>
                  </div>
                </div>
              </div>
              <div className="pt-3 row">
                <div className="d-block  col-6">
                  <div className="">Deviation Cost</div>
                  <div className="mt-3">Offhire Cost</div>
                </div>
                <div className="d-block  col-2">
                  <div className="ms-3">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.otherCostData?.estimatedDeviationCost.toFixed(2)}
                    >
                      {variance.currencySymbol}
                      {' '}
                      {_.truncate(variance?.costSummaryData?.otherCostData?.estimatedDeviationCost.toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                  <div className="mt-3 ms-3">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.otherCostData?.estimatedOffHireCost.toFixed(2)}
                    >
                      {variance.currencySymbol}
                      {' '}
                      {_.truncate(variance?.costSummaryData?.otherCostData?.estimatedOffHireCost.toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                </div>
                <div className="d-block  col-2">
                  <div className="ms-2">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.otherCostData?.actualDeviationCost.toFixed(2)}
                    >
                      {variance.currencySymbol}
                      {' '}
                      {_.truncate(variance?.costSummaryData?.otherCostData?.actualDeviationCost.toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                  <div className="mt-3 ms-2">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.otherCostData?.actualOffHireCost.toFixed(2)}
                    >
                      {variance.currencySymbol}
                      {' '}
                      {_.truncate(variance?.costSummaryData?.otherCostData?.actualOffHireCost.toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                </div>
                <div className="d-block  col-2">
                  <div className=" ms-2">
                    <span
                      className={
                        variance?.costSummaryData?.otherCostData?.varianceDeviationCostPercentage
                          ? 'text-ellipsis ' +
                            getClass(
                              variance?.costSummaryData?.otherCostData?.estimatedDeviationCost,
                              variance?.costSummaryData?.otherCostData?.actualDeviationCost,
                              'color'
                            )
                          : 'text-ellipsis'
                      }
                    >
                      <span
                        className="text-ellipsis pe-2"
                        data-tooltip-id="project-specName"
                        data-tooltip-content={Math.abs(variance?.costSummaryData?.otherCostData?.varianceDeviationCostPercentage)}
                      >
                        {_.truncate(Math.abs(variance?.costSummaryData?.otherCostData?.varianceDeviationCostPercentage), {
                          length: 15,
                          separator: ' ',
                        })}
                      </span>
                    </span>
                    <span
                      id="de-vi"
                      className={
                        variance?.costSummaryData?.otherCostData?.varianceDeviationCostPercentage
                          ? 'font-18 ms-2 mt-1 ' +
                            getClass(
                              variance?.costSummaryData?.otherCostData?.estimatedDeviationCost,
                              variance?.costSummaryData?.otherCostData?.actualDeviationCost,
                              'icon'
                            )
                          : 'font-18 ms-2 mt-1 '
                      }
                    />
                  </div>
                  <div className="mt-3 ms-2">
                    <span
                      className={
                        variance?.costSummaryData?.otherCostData?.varianceOffHirePercentage
                          ? 'text-ellipsis ' +
                            getClass(
                              variance?.costSummaryData?.otherCostData?.estimatedOffHireCost,
                              variance?.costSummaryData?.otherCostData?.actualOffHireCost,
                              'color'
                            )
                          : 'text-ellipsis'
                      }
                    >
                      <span
                        className="text-ellipsis pe-2"
                        data-tooltip-id="project-specName"
                        data-tooltip-content={Math.abs(variance?.costSummaryData?.otherCostData?.varianceOffHirePercentage)}
                      >
                        {_.truncate(Math.abs(variance?.costSummaryData?.otherCostData?.varianceOffHirePercentage), {
                          length: 15,
                          separator: ' ',
                        })}
                      </span>
                    </span>
                    <span
                      id="de-vi"
                      className={
                        variance?.costSummaryData?.otherCostData?.varianceOffHirePercentage
                          ? 'font-18 ms-2 mt-1 ' +
                            getClass(
                              variance?.costSummaryData?.otherCostData?.estimatedOffHireCost,
                              variance?.costSummaryData?.otherCostData?.actualOffHireCost,
                              'icon'
                            )
                          : 'font-18 ms-2 mt-1'
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="d-flex col-1 ms-1">
                  <div className=""></div>
                </div>
                <div className="d-flex  mt-4  col-5 ">
                  <div className="Head-16 font-16 font-bold">Total</div>
                </div>
                <div className="d-flex mt-4 ms-3 col-2">
                  <div className="Head-16 font-16 font-bold">
                    <span data-tooltip-id="project-specName" 
                    data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.graphData?.deviationOffhireEstimate.toFixed(2)}>
                      {variance.currencySymbol}
                      {' '}
                      {variance?.costSummaryData?.graphData?.deviationOffhireEstimate.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="d-flex mt-4 ">
                  <div className="Head-16 font-16 font-bold">
                    <span data-tooltip-id="project-specName" data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.graphData?.deviationOffhireActual.toFixed(2)}>
                      {variance.currencySymbol}
                      {' '}
                      {variance?.costSummaryData?.graphData?.deviationOffhireActual.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border p-3 mt-4">
            <div>
              <div className="d-flex">
                <div className="head-14">Total Repair Days</div>
              </div>
              <div className="bg-blue font-16 font-bold mt-2 py-2 row">
                <div className="d-flex  col-6">
                  <div className="ms-2 ">Item</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">Project Est</div>
                </div>
                <div className="d-flex col-2 ">
                  <div className="ms-2 ">Project Actual</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">
                    {' '}
                    <span data-tooltip-id="project-specName" data-tooltip-content="(estimate-actual)/estimate*100">
                      Variance (%)
                    </span>
                  </div>
                </div>
              </div>
              <div className="pt-3 row">
                <div className="d-block  col-6">
                  <div className="">Days In Dock </div>
                  <div className="mt-3">Days A float</div>
                  <div className="mt-3">Total Repair Days</div>
                </div>
                <div className="d-block  col-2">
                  <div className="ms-3">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.estimatedInDockDays).toFixed(2)}
                    >
                      {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.estimatedInDockDays).toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                  <div className="mt-3 ms-3">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.estimatedDaysAfloat).toFixed(2)}
                    >
                      {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.estimatedDaysAfloat).toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                  <div className="mt-3 ms-3">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.estimatedRepairDays).toFixed(2)}
                    >
                      {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.estimatedRepairDays).toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                </div>
                <div className="d-block  col-2">
                  <div className="ms-2">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.actualInDockDays).toFixed(2)}
                    >
                      {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.actualInDockDays).toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                  <div className="mt-3 ms-2">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.actualDaysAfloat).toFixed(2)}
                    >
                      {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.actualDaysAfloat).toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                  <div className="mt-3 ms-2">
                    <span
                      className="text-ellipsis pe-2"
                      data-tooltip-id="project-specName"
                      data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.actualRepairDays).toFixed(2)}
                    >
                      {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.actualRepairDays).toFixed(2), {
                        length: 15,
                        separator: ' ',
                      })}
                    </span>
                  </div>
                </div>
                <div className="d-block  col-2">
                  <div className=" ms-2">
                    <span
                      className={
                        variance?.costSummaryData?.repairData?.varianceInDockDaysPercent
                          ? 'text-ellipsis ' +
                            getClass(
                              variance?.costSummaryData?.repairData?.estimatedInDockDays,
                              variance?.costSummaryData?.repairData?.actualInDockDays,
                              'color'
                            )
                          : 'text-ellipsis '
                      }
                    >
                      <span
                        className="text-ellipsis pe-2"
                        data-tooltip-id="project-specName"
                        data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.varianceInDockDaysPercent)}
                      >
                        {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.varianceInDockDaysPercent), {
                          length: 15,
                          separator: ' ',
                        })}
                      </span>
                    </span>
                    <span
                      id="de-vi"
                      className={
                        variance?.costSummaryData?.repairData?.varianceInDockDaysPercent
                          ? 'font-18 ms-2 mt-1 ' +
                            getClass(
                              variance?.costSummaryData?.repairData?.estimatedInDockDays,
                              variance?.costSummaryData?.repairData?.actualInDockDays,
                              'icon'
                            )
                          : 'font-18 ms-2 mt-1'
                      }
                    />
                  </div>
                  <div className="mt-3 ms-2">
                    <span
                      className={
                        variance?.costSummaryData?.repairData?.varianceDaysAfloatPercent
                          ? 'text-ellipsis ' +
                            getClass(
                              variance?.costSummaryData?.repairData?.estimatedDaysAfloat,
                              variance?.costSummaryData?.repairData?.actualInDockDays,
                              'color'
                            )
                          : 'text-ellipsis '
                      }
                    >
                      <span
                        className="text-ellipsis pe-2"
                        data-tooltip-id="project-specName"
                        data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.varianceDaysAfloatPercent)}
                      >
                        {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.varianceDaysAfloatPercent), {
                          length: 15,
                          separator: ' ',
                        })}
                      </span>
                    </span>
                    <span
                      id="de-vi"
                      className={
                        variance?.costSummaryData?.repairData?.varianceDaysAfloatPercent
                          ? 'font-18 ms-2 mt-1 ' +
                            getClass(
                              variance?.costSummaryData?.repairData?.estimatedDaysAfloat,
                              variance?.costSummaryData?.repairData?.actualInDockDays,
                              'icon'
                            )
                          : 'font-18 ms-2 mt-1'
                      }
                    />
                  </div>
                  <div className="mt-3 ms-2">
                    <span
                      className={
                        variance?.costSummaryData?.repairData?.varianceInDockDaysPercent
                          ? 'text-ellipsis ' +
                            getClass(
                              variance?.costSummaryData?.repairData?.estimatedRepairDays,
                              variance?.costSummaryData?.repairData?.actualRepairDays,
                              'color'
                            )
                          : 'text-ellipsis'
                      }
                    >
                      <span
                        className="text-ellipsis pe-2"
                        data-tooltip-id="project-specName"
                        data-tooltip-content={Math.abs(variance?.costSummaryData?.repairData?.varianceRepairDaysPercent)}
                      >
                        {_.truncate(Math.abs(variance?.costSummaryData?.repairData?.varianceRepairDaysPercent), {
                          length: 15,
                          separator: ' ',
                        })}
                      </span>
                    </span>
                    <span
                      id="de-vi"
                      className={
                        variance?.costSummaryData?.repairData?.varianceInDockDaysPercent
                          ? 'font-18 ms-2 mt-1 ' +
                            getClass(
                              variance?.costSummaryData?.repairData?.estimatedRepairDays,
                              variance?.costSummaryData?.repairData?.actualRepairDays,
                              'icon'
                            )
                          : 'font-18 ms-2 mt-1 '
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">No data found</div>
        </div>
      )}
      <Tooltip id="project-variation" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default VariaianceNonSpec;
