import _ from 'lodash';
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { statusWODefectColor } from './utilities';
import helper from '../../../../../../../../utils/helper';

const Card = (props) => {
  const { item2, onDelete, fromSupplementary, isTsiDisabled, isTSI, fromVariation } = props;
  const value = [{ label: 'Component :', key: 'component' },
    { label: 'Raised By :', key: 'raisedBy' },
    { label: 'Interval :', key: 'interval' },
    { label: 'Description :', key: 'description' }
  ];
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  return (
    <div className="card-inner-table mt-3 p-4" data-testid="wo-defectlist">
      <div className="border-down">
        <div className="d-flex mb-3 justify-content-between">
          <div className="d-flex align-items-center ">
            <div className="color-light-blue font-semibold">
              <span
                data-tooltip-id="project-list"
                data-tooltip-content={item2.externalId}
              >
                {_.truncate(item2.externalId, { length: 20 })}

              </span>

            </div>
            <div className="font-bold ms-2">-</div>
            <h1
              className="head-14 ms-2"
              data-tooltip-id="project-list"
              data-tooltip-content={item2.title}
            >
              {_.truncate(item2.title, { length: 55 })}

            </h1>
            <div className="docTypeName white width-fit ms-3" style={{ background: statusWODefectColor(item2.status) }}>{item2.statusName}</div>
          </div>
          <div className="d-flex me-3">
            <div className=" opacity-6">{item2.typeName}</div>

            {isTSI && !isVesselUser ? (
              <button disabled={fromVariation || fromSupplementary ? false : isTsiDisabled} type="button" className="link-btn" id="delete" onClick={() => onDelete(item2.id)}>
                <span className="icon-delete font-24 ms-3" />
              </button>
            ) : ''}

          </div>
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap">
        {value.map((item3) => {
          return (
            <div className="d-flex mt-3 col-6">
              <label>{item3.label}</label>
              <div className="ms-3 gray opacity-6">
                <span
                  data-tooltip-id="project-list"
                  data-tooltip-content={item2[item3.key]}
                >
                  {_.truncate(item2[item3.key], { length: 25 })}
                </span>

              </div>
            </div>
          );
        })}
      </div>
      <Tooltip id="project-list" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Card;
