/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';

const Comments = (props) => {
  const { comments } = props;
  return (
    <div className="p-2rem mb-4 card break-all bg-sky-white" data-testid="comments">
      <div>{comments}</div>
    </div>
  );
};
export default Comments;
