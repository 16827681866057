import React, { useState, useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import qs from 'query-string';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import ModalBox from '../../../../common/ModalBox';
import {
  getProjectDetailsTSI,
  s3UploadMutiple,
  getProjectAttachments,
  updateStatus,
  saveProjectAttachments,
  deleteProjectAttachment
} from '../services';
import AddAttachments from './AddAttachments';
import showAlert from '../../../../../utils/alert';
import { getDocumentCategryList } from '../../../../Admin/Vessels/VesselDetails/Attachment/services';
import helper from '../../../../../utils/helper';
import { fileImages } from '../../../../common/mappingConst';
import Comments from '../../Comments';
import vesselThmd from '../../../../../images/vessel-thmd.jpg';

const acceptedFiles = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png', 'image/jpg', 'image/bmp', 'image/tiff'];

const params = qs.parse(window.location.search);

const Attachments = (props) => {
  const { formatNumber } = helper;

  const { handleActiveComponent, vesselInfo, projectId,
    setAttachments, stepperList, setStepperList, isTsiDisabled } = props;

  const initialFormData = {
    // id: params.id,
    documentCategory: '',
    projectId: '',
    phase: 1,
    projectAttachments: []

  };

  const [allFiles, setAllFiles] = useState([]);
  const { generateOptions, charValidation, formatBytes,
    formValidation, handleValidation, MAX_FILE_SIZE } = helper;
  const [documentCategoryList, setDocumentCategoryList] = useState([]);
  const [addModal, setaddModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  // const [uploadModal, setUploadModel] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [projDetails, setProjectDetails] = useState({});
  const [removeFile, setRemoveFile] = useState(allFiles);
  useEffect(() => {
    getDocumentCategryList(2)?.then((res) => {
      if (res.success) {
        setDocumentCategoryList(generateOptions(res?.data, 'id', 'name'));
      }
    });
  }, []);
  const getAllAttachments = () => {
    getProjectAttachments({ id: projectId, phase: 1, isLifoOrder: true })?.then((res) => {
      if (res?.success) {
        setAllFiles(res?.data);
      }
    });
  };
  const updateScreenStatus = () => {
    updateStatus(projectId, 2).then(() => {
    });
  };

  const saveApi = (data) => {
    saveProjectAttachments(data)?.then((res) => {
      // saveProjectsAttachment(data).then((res) => {
      if (res.success) {
        showAlert('Successfully Saved', 'success');

        setaddModal(false);
        getAllAttachments();
        setFormData(initialFormData);
      }
    });
  };

  const getProjDetails = () => {
    getProjectDetailsTSI({ id: projectId })?.then((response) => {
      if (response?.success) {
        setProjectDetails(response?.data);
      }
    });
  };

  const saveApiCall = () => {
    const inputObj = formData;
    inputObj.projectId = projectId;
    formData.projectAttachments.forEach((item, key) => {
      inputObj.projectAttachments[key].documentCategory = formData.documentCategory;
    });
    if (formData.projectAttachments.length > 0 && formValidation(['name', 'documentCategory'], inputObj)) {
      saveApi(inputObj);
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const saveButton = () => {
    showAlert('Successfully saved as draft', 'success');
    updateScreenStatus();
    setAttachments();
  };

  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = formData.projectAttachments.map((items) => { return items.name; });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formData);
          // const addDocs = _.cloneDeep(addAttachs);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fileName: item.path,
              projectId,
              fileType: item.fileType,
              fileSize: item.size,
              documentCategory: formData.documentCategory,
              phase: 1
            };

            return (
              attach.projectAttachments.push(newItem)
            );
          });
          setFormData(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };

  // const browseAttachs = () => {
  //   browseAttachments(addAttachs).then((res) => {
  //     if (res?.success) {
  //       const additonalDocs = { ...addAttachs };
  //       setFormData(additonalDocs);
  //       showAlert('Successfully Saved', 'success');
  //       setUploadModel(false);
  //       getAllAttachments();
  //     }
  //   });
  // };
  // const handleChange = (e) => {
  //   const attachments = { ...formData };
  //   attachments.projectPlanDocs.title = e.target.value;
  //   setFormData(attachments);
  // };

  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['name', 'documentCategory'], fd, field);
  };

  const openAddModal = () => {
    setFormData(
      {
        id: params.id,
        documentCategory: '',
        projectId: '',
        phase: 1,
        projectAttachments: [
        ]
      }

    );
    setaddModal(true);
  };

  const closeAddModal = () => {
    setaddModal(false);
  };

  // const openUpdateModal = (selectedid) => {
  //   setUploadModel(true);
  //   const selecteditem = { ...addAttachs };
  //   selecteditem.id = selectedid;
  //   selecteditem.projectPlanDocs.documents = [];
  //   setAddAttachs(selecteditem);
  // };

  // const closeUpdateModal = () => {
  //   setUploadModel(false);
  // };

  const deleteModal = (data) => {
    setOpenDeleteModal(true);
    setRemoveFile(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllAttachments();
    getProjDetails();
  }, []);

  const removeAttach = () => {
    const vesselsIds = removeFile?.id;
    deleteProjectAttachment({ id: vesselsIds })?.then((res) => {
      if (res.success) {
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
        getAllAttachments();
      }
    });
  };
  return (
    <div className="card p-3em col-8 m-auto mb-5 " id="container" data-testid="attachments-proj">
      <div className="mb-4">
        <p className="head-18 mb-5 t-5"> Vessel Plans and Drawings</p>
        <p>Choose and upload the document</p>
        <div className="card bg-sky-white mt-4 p-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center col-6">
              <img className="thmb-md ship-thumb" src={vesselInfo?.generalInfo?.imageUrl || vesselThmd} alt="" />
              <div className="d-flex flex-column">
                <p className="head-14 pb-1 ">
                  {_.truncate(vesselInfo?.generalInfo?.name, {
                    length: 15,
                    separator: ''
                  })}

                </p>
                <p className="">{vesselInfo && vesselInfo?.generalInfo?.vesselSubType}</p>
              </div>
            </div>
            <div className="d-flex flex-column col-4">
              <div className="d-flex ">
                <label> DD Due Date :</label>
                <div className="value ms-2">
                  {projDetails?.ddDudate ? moment(projDetails?.ddDudate).format('DD-MMM-YYYY') : '-'}
                </div>
              </div>
              <div className="d-flex mt-2">
                <label>Previous DD Date :</label>
                <div className="value ms-2">
                  {vesselInfo?.generalInfo?.previousDdDate ? moment(vesselInfo?.generalInfo?.previousDdDate).format('DD-MMM-YYYY') : '-'}
                </div>
              </div>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <button
                type="button"
                className="secondary-btn small mt-4"
                id="more-info-details"
                onClick={() => {
                  handleActiveComponent('vessel-details');
                }}
              >
                More info

              </button>
            </div>
          </div>
        </div>
        <div className="card bg-sky-white mt-4 p-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column col-6">
              <div className="d-flex mb-2">
                <label className="whitespace-pre">
                  Project Name :
                </label>
                <div className="break-all font-bold px-2 value">
                  <span data-tooltip-id="project-gantt" data-tooltip-content={projDetails?.name}>
                    {_.truncate(projDetails?.name, {
                      length: 50,
                      separator: ''
                    })}

                  </span>

                </div>
              </div>
              <div className="d-flex">
                <label>
                  Period :

                </label>
                <div className="d-flex value ps-2">
                  <p>
                    {' '}
                    {projDetails && moment(projDetails?.createdDate).format('DD-MMM-YY')}
                  </p>
                  <p className="ps-2 pe-2">to</p>
                  <p>
                    {' '}
                    {projDetails && moment(projDetails?.ddDudate).format('DD-MMM-YY')}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column col-4">
              <div className="brand-color font-bold font-20">
                {projDetails.currencySymbol}
                {' '}
                {formatNumber(projDetails.estimatedBudget)}
              </div>
              <label>Indicative Budget</label>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <button
                type="button"
                className="secondary-btn small mt-3"
                id="more-info-pjt"
                onClick={() => {
                  handleActiveComponent('project-details');
                }}
              >
                More Info

              </button>
            </div>
          </div>
        </div>
        <div className="border-down my-5" />
        <div className="table-responsive mb-5" data-testid="listing">
          <table className="card-table">
            <thead>
              <tr>
                <th>Vessel Plans and Drawings</th>
              </tr>
            </thead>
            <tbody>
              {allFiles && allFiles?.length > 0 && (
                allFiles?.map((item) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <div className="">
                            <div className="d-flex flex-column justify-content-center">
                              <span className="head-14 mb-1 text-ellipsis" data-tooltip-id="project-gantt" data-tooltip-content={item?.documentCategoryName}>
                                {_.truncate(item?.documentCategoryName, {
                                  length: 50,
                                  separator: ' '
                                })}
                              </span>

                            </div>
                            <div className="ps-5 my-3">
                              {item?.projectAttachments?.map((doc) => {
                                return (
                                  <div className="ms-4 pt-3 pb-3 pe-3">
                                    <div className="d-flex justify-content-between full-width align-items-center">
                                      <div className="d-flex col-gap-1">
                                        <div className="thmb-icon-md">
                                          <span className={fileImages[_.toLower(doc.fileType)]} />
                                          {/* <img src={fileImages[urlData?.fileExtension]}
                               alt="" style={{ height: 24 }} /> */}
                                        </div>
                                        <div className="d-flex flex-column justify-content-center">
                                          <a
                                            href={doc.physicalFileName}
                                            target="_blank"
                                            download="downloaded_file"
                                            className=""
                                            rel="noreferrer"
                                          >
                                            <span className="head-14 mb-1 text-ellipsis" data-tooltip-id="project-gantt" data-tooltip-content={doc.name}>
                                              {_.truncate(doc.name, {
                                                length: 50,
                                                separator: ' '
                                              })}
                                            </span>
                                          </a>

                                          <div className="font-16">
                                            <label className="me-2">Format :</label>
                                            <span className="value">{doc.fileType}</span>
                                            <span className="mx-4">|</span>
                                            <label className="me-2"> Size :</label>
                                            <span className="value">{formatBytes(doc.fileSize)}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="d-flex col-gap-2">
                                        <button className="link-btn" type="button">
                                          <a href={doc.physicalFileName} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                                            <span className="icon-download font-20" />
                                          </a>
                                        </button>
                                        {doc.isEditable === true ? (
                                          <button
                                            id="delete"
                                            className="link-btn"
                                            type="button"
                                            onClick={() => { deleteModal(doc); }}
                                          >
                                            <span className="icon-delete font-20" />
                                          </button>
                                        ) : (
                                          <button id="delete" disabled className="link-btn" type="button">
                                            <span className="icon-delete font-20" />
                                          </button>
                                        )}

                                      </div>

                                    </div>

                                  </div>
                                );
                              })}

                            </div>
                            {/* {item.id !== '00000000-0000-0000-0000-000000000000' ? (
                      <button type="button" className="brand-color font-bold pt-2"
                      onClick={() => { openUpdateModal(item.id, item.projectPlanDocs.documents); }}>
                      Browse Attachments</button>
                    ) : ('')} */}
                            {/* </div> */}
                          </div>
                        </td>
                      </tr>

                    </>
                  );
                })
              )}
            </tbody>
          </table>

        </div>

        <div className="d-flex mt-3 justify-content-end pb-4 ">
          <button type="button" id="add-attachment" disabled={isTsiDisabled} className="secondary-btn  p-3 " onClick={() => { openAddModal(); }}>Add vessel plans and drawings</button>
          {addModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Add Documents"
              onSave={saveApiCall}
              closeModal={() => {
                closeAddModal();
              }}
              buttonName="Save"
            >
              <AddAttachments
                handleChange={handleChange}
                formData={formData}
                ondrop={ondrop}
                setFormData={setFormData}
                removeAttach={removeAttach}
                // openUpdateModal={openUpdateModal}
                // browseAttachs={browseAttachs}
                documentCategoryList={documentCategoryList}
              />
            </ModalBox>
          ) : ('')}
          {openDeleteModal ? (
            <ModalBox
              modalClassName="medium"
              heading="Remove Attachment"
              // onSave={() => { removeAttach(removeFile); }}
              onSave={() => { removeAttach(); }}
              closeModal={() => {
                setOpenDeleteModal(false);
              }}
              buttonName="Delete"
            >
              <p>Are you sure to delete the attachment?</p>
            </ModalBox>
          ) : ('')}
        </div>
        <div className="d-flex justify-content-between mt-5">
          {projectId && projDetails?.planApprovalStatus === 3
            ? <Comments id={projectId} /> : <div />}
          <div className="d-flex justify-content-end">
            <button type="button" id="prev" className="secondary-btn large ms-4" onClick={() => { handleActiveComponent('project-details'); }}>Previous</button>
            <button type="button" id="save" disabled={isTsiDisabled} className="secondary-btn large ms-4" onClick={() => { saveButton(); }}>Save as draft</button>
            <button
              type="button"
              id="continue"
              className="primary-btn large ms-4"
              onClick={() => {
                if (isTsiDisabled) {
                  handleActiveComponent('confirm');
                } else {
                  stepperList[2].isDone = true;
                  setStepperList(stepperList);
                  updateScreenStatus();
                  handleActiveComponent('confirm');
                }
              }}
            >
              Continue

            </button>
          </div>
        </div>
        {/* {uploadModal ? (
          <ModalBox
            modalClassName="medium"
            heading="Add Additional Attachments"
            onSave={browseAttachs}
            closeModal={() => {
              closeUpdateModal();
            }}
            buttonName="Save"
          >
            <UpdateAttachModal
              handleChange={handleChange}
              addAttachs={addAttachs}
              ondrop={ondrop}
              // setAddAttachs={setAddAttachs}
            />
          </ModalBox>
        ) : ('')} */}
        <Tooltip id="project-gantt" place="top" className="tooltip mt-3" />
      </div>
    </div>
  );
};

export default Attachments;
