import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import InputType1 from '../../../common/InputType1';
import ModalBox from '../../../common/ModalBox';
import { DeleteDocumentCategory, SaveDocumentCategory } from './services';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import { getDocumentCategryList } from '../../Vessels/VesselDetails/Attachment/services';
import AreaLoader from '../../../common/areaLoader';

const DocumentCategory = () => {
  const { charValidation, formValidation, handleValidation } = helper;
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialFormData = {
    name: '',
    shortName: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const reloadTable = () => {
    getDocumentCategryList(2).then((res) => {
      setLoading(false);
      setTableData(res.data);
    });
  };

  useEffect(() => {
    reloadTable();
    setLoading(true);
  }, []);

  const deteleData = (data) => {
    setFormData(data);
    setOpenDeleteModal(true);
  };

  const deleteApiCall = () => {
    DeleteDocumentCategory(formData.id).then((res) => {
      if (res.success) {
        setLoading(false);
        setFormData(initialFormData);
        reloadTable();
        setOpenDeleteModal(false);
        setOpenModal(false);
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const saveApiCall = () => {
    if (formValidation(['name'], formData)) {
      SaveDocumentCategory(formData).then((res) => {
        if (res.success) {
          setLoading(false);
          reloadTable();
          setFormData(initialFormData);
          setOpenModal(false);
          showAlert('Successfully Saved', 'success');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = charValidation(e.target.value);
    setFormData(fd);
    handleValidation(['name'], formData, field);
  };
  return (
    <div className="border md-radius p-5 mb-5 half-width-gap-1" data-testid="type-document-category">
      {loading
        ? (
          <div style={{ height: '100px', width: '150px', margin: '20% auto' }}>
            <AreaLoader />
          </div>
        ) : (
          <div>
            <h4 className="head-16 mb-4">Document Category</h4>
            <div>
              {tableData && tableData.map((original) => (
                <div className="md-radius mt-2 p-2 row row-hover">
                  <div className="col-11 text-break-all">
                    {original.name}
                  </div>
                  <div
                    className="btbtn-set col-1 align-self-center"
                    data-tooltip-content={original?.mappedMessage}
                    data-tooltip-id="doc-tooltip"
                  >
                    <button
                      data-testid="delete"
                      disabled={original.isMapped}
                      onClick={() => deteleData(original)}
                      className="link-btn"
                      type="button"
                    >
                      <span className="icon-delete font-20" />
                    </button>
                    <Tooltip id="doc-tooltip" place="top" className="tooltip mt-2 me-5" />

                  </div>
                </div>
              ))}
            </div>
            <button
              id="add-new"
              onClick={() => { setFormData(initialFormData); setOpenModal(true); }}
              type="button"
              className="link-btn mt-4"
            >
              <span className="icon-plus-square me-2" />
              Add New
            </button>
            {openModal ? (
              <ModalBox
                modalClassName="medium"
                heading="Document Category"
                onSave={() => saveApiCall()}
                closeModal={() => setOpenModal(false)}
                buttonName="Save"
              >
                <div>
                  <InputType1 autoFocus id="name" onChange={(e) => handleChange(e, 'name')} value={formData.name} className="modal-iput" label="Name" isMandatory="true" />
                </div>
              </ModalBox>
            ) : (
              ''
            )}
            {openDeleteModal ? (
              <ModalBox
                modalClassName="medium"
                heading="Delete"
                onSave={() => deleteApiCall()}
                closeModal={() => { setOpenDeleteModal(false); setOpenModal(false); }}
                buttonName="Delete"
              >
                <p>Are you sure you want to delete Document category ?</p>
              </ModalBox>
            ) : (
              ''
            )}
          </div>
        )}

    </div>
  );
};

export default DocumentCategory;
