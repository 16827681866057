import React from 'react';
import ModalBox from '../../../../common/ModalBox';

const DownloadConfirmModal = (props) => {
  const { setDownloadConfirmModal, handleContinueInDownload,
    handleWorkOrdersCheckboxChange,
    handlePoCheckboxChange, isChecked, isCheckedWo } = props;

  return (
    <div data-testid="DownloadConfirmModal">
      <ModalBox
        heading="Download Specs"
        closeModal={() => { setDownloadConfirmModal(false); }}
        buttonName="Ok"
        onSave={() => { handleContinueInDownload(); }}
      >

        <div>
          A link to download the Pdf report and the excel template
          will be emailed to you in few minutes.
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="PO Orders" name="PO" onChange={handlePoCheckboxChange} checked={isChecked} />
          <label htmlFor="PO Orders">Include POs mapped</label>
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="Work Orders" name="WO" onChange={handleWorkOrdersCheckboxChange} checked={isCheckedWo} />
          <label htmlFor="Work Orders">Include WOs mapped</label>
        </div>
      </ModalBox>

    </div>
  );
};
export default DownloadConfirmModal;
