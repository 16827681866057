/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Cards from './cards';
import ModalBox from '../../../common/ModalBox';
import AddFleets from './addFleets';
import './style.scss';
import Pagination from '../../../common/pagination';
import { getFleetList, getManagementList, getVesselList, deleteFleetList, saveFleetList } from './services';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';
import Loader from '../../../common/loader';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const Fleets = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const [showModal, setShowModal] = useState(false);
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemId, setItemId] = useState('');
  const [listData, setListData] = useState([]);
  const [managementList, setManagementList] = useState([]);
  const [managementId, setManagementId] = useState('');
  const [vesselList, setVesselList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentpage, setCurrentpage] = useState(1);
  const [selectedVessels, setSelectedVessels] = useState([]);
  const { generateOptions, addColor } = helper;
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
  const initialFormData = {
    id: '',
    name: '',
    managementId: '',
    tenantId: '',
    description: '',
    vesselList: [],
    status: 0
  };
  const [formData, setFormData] = useState(initialFormData);
  const [searchText, setSearchText] = useState('');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const reloadList = (page) => {
    getFleetList({
      page: page || currentpage,
      pageSize: 10,
      search: searchText
    }).then((res) => {
      if (res.success) setListData(res?.data);
      setLoading(false);
      setCount(res?.data?.totalItems || 0);
    });
  };
  const getManagementLists = () => {
    getManagementList().then((res) => {
      setManagementList(generateOptions(res?.data, 'id', 'name'));
      setLoading(false);
    });
  };

  const getVesselLists = (mId, fId) => {
    getVesselList(mId, fId).then((res) => {
      setVesselList(generateOptions(res?.data, 'id', 'name'));
      setLoading(false);
    });
  };
  const deleteApiCall = () => {
    deleteFleetList(itemId).then(() => {
      setFormData(initialFormData);
      showAlert('Successfully Deleted', 'success');
      reloadList();
      setLoading(false);
    });
    setOpenDeleteModal(false);
  };
  useEffect(() => {
    setLoading(true);
    getManagementLists();
  }, []);
  useEffect(() => {
    if (formData?.managementId && formData?.id) {
      getVesselLists(formData?.managementId, formData?.id);
    }
  }, [showModal]);

  useEffect(() => {
    reloadList();
  }, [currentpage]);

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadList(1);
      setCurrentpage(1);
    }
  };
  const onSizeChange = (e) => {
    setLoading(true);
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const setItem = (e) => {
    setItemId(e);
  };

  const pageClick = (e) => {
    setLoading(true);
    setCurrentpage(e?.selected + 1);
    window.scrollTo(0, 0);
  };
  const saveApiCall = () => {
    const temp = cloneDeep(formData);
    temp.id = formData?.id ? formData?.id : ZERO_UUID;
    temp.tenantId = ZERO_UUID;
    if (vesselList && vesselList?.length > 0) {
      temp.vesselList = selectedVessels;
    } else {
      temp.vesselList = [];
    }
    let isValid = true;
    ['name', 'managementId'].forEach((item) => {
      if (temp[item] === null || temp[item] === '' || temp[item] === ' ') {
        isValid = false;
        addColor(item, 'add');
      } else {
        addColor(item, '');
      }
    });
    if (isValid && temp?.name?.trim() !== '') {
      saveFleetList(temp).then((res) => {
        if (res?.success) {
          showAlert('Successfully Saved', 'success');
          reloadList(currentpage);
          // setCurrentpage(1);
          setFormData(initialFormData);
          setShowModal(false);
          setIsAddClicked(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  useEffect(() => {
    if (!searchText) {
      reloadList(1);
    }
  }, [searchText]);
  return (
    <div className="container" data-testid="v-fleets">
      <div className="d-flex my-4 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search">
          <div className="search large">
            <div className="d-flex justify-content-end">
              <span
                id="click1"
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchText('');
                  document.getElementById('search')?.focus();
                }}
              />
              <span
                id="click2"
                className="icon-search"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              className="text-box large with-close"
              value={searchText}
              id="search"
              placeholder="Search by Fleet Name"
              onKeyPress={onSearch}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            id="add-fleets"
            className="primary-btn large"
            onClick={() => {
              setShowModal(true);
              setIsAddClicked(true);
              setSelectedVessels([]);
              setVesselList([]);
            }}
          >
            Add Fleet
          </button>
        </div>
        {/* <div className="d-flex col-gap-1">
          <button type="button" className="link-btn large ">
            <span className="icon-sort me-2" />
            Sort
          </button>
        </div> */}
      </div>

      {showModal && (
        <ModalBox
          heading={isAddClicked ? 'Add Fleets' : 'Edit Fleets'}
          closeModal={() => {
            setShowModal(false);
            setFormData(initialFormData);
            setIsAddClicked(false);
            setSelectedVessels([]);
            setVesselList([]);
          }}
          onSave={() => saveApiCall()}
          buttonName="Save"
        >
          <AddFleets
            formData={formData}
            setFormData={setFormData}
            managementList={managementList}
            managementId={managementId}
            vesselList={vesselList}
            getVesselLists={getVesselLists}
            selectedVessels={selectedVessels}
            setSelectedVessels={setSelectedVessels}
            deleteApiCall={deleteApiCall}
          />
        </ModalBox>
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          {listData && listData?.totalItems > 0 ? (
            listData?.items?.map((item) => {
              return (
                <Cards
                  data={item}
                  setFormData={setFormData}
                  setOpenDeleteModal={setOpenDeleteModal}
                  openDeleteModal={openDeleteModal}
                  setItem={setItem}
                  deleteApiCall={deleteApiCall}
                  setListData={setListData}
                  setManagementId={setManagementId}
                  setShowModal={setShowModal}
                  setSelectedVessels={setSelectedVessels}
                />
              );
            })
          ) : (
            <div className="mt-5 p-4 d-flex justify-content-center user-list-policy" data-testid="yard-attach">
              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
              <div className="font-18 animate__animated animate__headShake">No data found</div>
            </div>
          )}

          {listData?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={listData?.totalPages}
              currentpage={currentpage}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
        </>
      )}
      <div className="footer justify-content-between font-13 m-0">
        <PolicyFooter setShowPrivacyPolicy={setShowPrivacyPolicy} setShowTermsPolicy={setShowTermsPolicy} />
      </div>
      {showPrivacyPolicy && <PrivacyPolicy setShowPrivacyPolicy={setShowPrivacyPolicy} showPrivacyPolicy={showPrivacyPolicy} />}
      {showTermsPolicy && <TermsOfUse setShowTermsPolicy={setShowTermsPolicy} showTermsPolicy={showTermsPolicy} />}
    </div>
  );
};
export default Fleets;
