const API_END_POINTS = {
  GetVesselTypeDetailsUrl: 'admin/GetVesselTypeDetails',
  GetVesselTypesListUrl: 'admin/GetVesselTypesList',
  GetVesselTypeListSaveUrl: 'admin/GetVesselTypeListSaveUrl',
  GetMgmtAcCodesUrl: 'AcCode/GetManagementwiseAcCodes',
  getAcCodeUrl: 'AcCode/GetAcCodes',
  deleteAcCodeUrl: 'AcCode/DeleteAcCode',
  saveAcCodeUrl: 'AcCode/SaveAcCode',
  getUomUrl: '',
  deleteUomUrl: '',
  saveUomUrl: '',
  getYardUrl: '',
  deleteYardUrl: '',
  saveYardUrl: '',
  GetKfpList: 'kfp/GetKfpList',
  SaveKfp: 'kfp/SaveKfp',
  GetKfpDetails: 'kfp/GetKfpDetails',
  DeleteKfp: 'kfp/DeleteKfp',
  PublishKfpList: 'kfp/PublishKfpList',
  SaveTag: 'Tag/SaveTags',
  GetTags: 'Tag/GetTags',
  DeleteTag: 'Tag/DeleteTag',
  GetTagsListUrl: 'Tag/GetTagList',
  GetYardList: 'Yard/GetYardList',
  SaveYard: 'Yard/SaveYard',
  GetYardDetails: 'Yard/GetYardDetails',
  DeleteYard: 'Yard/DeleteYard',
  PublishYardList: 'Yard/PublishYardList',
  getYardDetailUrl: 'Yard/GetYardDetails',
  SaveUnitOfMeasure: 'UnitOfMeasure/SaveUnitOfMeasure',
  GetUnitOfMeasuresList: 'UnitOfMeasure/GetUnitOfMeasuresList',
  GetUnitOFMeasureDetail: 'UnitOfMeasure/GetUnitOFMeasureDetail',
  DeleteUnitOfMeasure: 'UnitOfMeasure/DeleteUnitOfMeasure',
  PublishUnitOfMeasureList: 'UnitOfMeasure/PublishUnitOfMeasureList',
  GetPublishUnitOfMeasuresList: 'UnitOfMeasure/GetPublishedUnitOfMeasuresList',
  SaveTemplate: 'ReportTemplate/SaveTemplate',
  PublishTemplate: 'ReportTemplate/PublishTemplate',
  GetReportTemplate: 'ReportTemplate/GetReportTemplate',
  getReportTemplateListUrl: 'ReportTemplate/GetReportTemplateList',
  DeleteTemplate: 'ReportTemplate/DeleteTemplate',
  getCurrencyUrl: 'Currency/GetCurrencyList',
  saveCurrencyUrl: 'Currency/SaveCurrency',
  deleteCurrencyUrl: 'Currency/DeleteCurrency',
  countryListUrl: 'Currency/GetCountryList',
  SetBaseCurrency: 'Currency/SetBaseCurrency',
  getTaskUrl: 'Task/GetTaskList',
  getRoleListUrl: 'User/GetRoleList',
  saveTaskUrl: 'Task/SaveTask',
  saveTaskDetailsUrl: 'Task/SaveTaskDetails',
  PublishTaskListUrl: 'Task/PublishTaskList',
  deleteTaskUrl: 'Task/DeleteTask',
  deleteSubTaskUrl: 'Task/DeleteTaskCategory',
  getTaskDetailUrl: 'Task/GetTaskDetails',
  GetAllTaskList: 'Task/GetAllTaskList',
  GetOverAllTaskCount: 'Task/GetAllTaskCountByPhase',
  GetMicroTaskDetails: 'Task/GetMicroTaskDetails',
  DeleteMicroTask: 'Task/DeleteMicroTask',
  UpdateTaskStatus: 'Task/UpdateTaskStatus',
  SaveMicroTask: 'Task/SaveMicroTask',
  SaveSurveyTypeUrl: 'GeneralConfig/SaveSurveyType',
  GetSurveyTypeListUrl: 'GeneralConfig/GetSurveyTypeList',
  GetSurveyTypeDetailUrl: 'GeneralConfig/GetSurveyTypeDetail',
  DeleteSurveyTypeUrl: 'GeneralConfig/DeleteSurveyType',
  SaveContactRoleUrl: 'GeneralConfig/SaveContactRole',
  GetContactRoleListUrl: 'GeneralConfig/GetContactRoleList',
  GetContactRoleDetailUrl: 'GeneralConfig/GetContactRoleDetail',
  DeleteContactRoleUrl: 'GeneralConfig/DeleteContactRole',
  SaveJobAssignedUrl: 'GeneralConfig/SaveJobAssigned',
  GetJobAssignedListUrl: 'GeneralConfig/GetJobAssignedList',
  GetJobAssignedDetailUrl: 'GeneralConfig/GetJobAssignedDetail',
  DeleteJobAssignedUrl: 'GeneralConfig/DeleteJobAssigned',
  SaveContactCategoryUrl: 'GeneralConfig/SaveContactCategory',
  GetContactCategoryListUrl: 'GeneralConfig/GetContactCategoryList',
  GetContactCategoryDetailUrl: 'GeneralConfig/GetContactCategoryDetail',
  DeleteContactCategoryUrl: 'GeneralConfig/DeleteContactCategory',
  SaveProjectTypeUrl: 'GeneralConfig/SaveProjectType',
  GetProjectTypeListUrl: 'GeneralConfig/GetProjectTypeList',
  GetProjectTypeDetailUrl: 'GeneralConfig/GetProjectTypeDetail',
  DeleteProjectTypeUrl: 'GeneralConfig/DeleteProjectType',
  PublishGeneralConfigUrl: 'GeneralConfig/PublishGeneralConfig',
  saveDocumentCategory: 'GeneralConfig/SaveDocumentCategory',
  deleteDocumentCategory: 'GeneralConfig/DeleteDocumentCategory',
  SaveVesselCategoryUrl: 'VesselType/SaveVesselCategory',
  GetVesselCategoryListUrl: 'VesselType/GetVesselCategories',
  DeleteVesselCategoryUrl: 'VesselType/DeleteVesselCategory',
  getSpecPackagesUrl: 'SpecificationPackage/ListSpecificationPackage',
  GetSpecificationPackageUrl: 'SpecificationPackage/GetSpecificationPackage',
  saveSpecPackagesUrl: 'SpecificationPackage/SaveSpecificationPackage',
  deleteSpecPackagesUrl: 'SpecificationPackage/DeleteSpecificationPackage',
  GetSpecificationbyProjectId: 'SpecificationPackage/GetSpecificationbyProjectId',
  AddManualSpecification: 'ProjectSpecification/AddManualSpecification',
  DeleteManualSpecification: 'ProjectSpecification/DeleteManualSpecification',
  SaveProjectSpecification: 'SpecificationPackage/SaveProjectSpecification',
  GetSpecLibraryList: 'SpecificationPackage/GetSpecLibraryList',
  DeleteExistingMajorSpecs: 'SpecificationPackage/DeleteExistingMajorSpecs',
  GetSpecificationList: 'SpecificationMaster/GetSpecificationList',
  SaveNonSpec: 'SpecificationMaster/SaveNonSpec',
  DeletSpecification: 'SpecificationMaster/DeleteSpecification',
  getMajorSpecsUrl: 'SpecificationMaster/GetSpecificationData',
  saveMajorSpecsUrl: 'SpecificationMaster/SaveSpecificationMaster',
  saveMajorSpecsificationUrl: 'SpecificationMaster/SaveSpecificationData',
  deleteMajorSpecsUrl: '',
  getGeneralTariffUrl: 'SpecificationMaster/GetAllSpecificationData',
  saveGeneralTariffUrl: 'SpecificationMaster/SaveVesselType',
  publishSpecificationDataUrl: 'SpecificationMaster/PublishSpecificationData',
  getSearchResultsUrl: 'SpecificationMaster/SearchSpecification',
  getSpecificationStatusUrl: 'SpecificationMaster/GetSpecificationStatus',
  GetVesselCategoryListNewUrl: 'VesselType/GetLinkedVesselCategories',
  deleteGeneralTariffUrl: '',
  getVesselTypeUrl: 'VesselType/GetVesselTypeList',
  getAllVesselTypeUrl: 'VesselType/GetVesselTypes',
  saveVesselTypeUrl: 'VesselType/SaveVesselType',
  deleteVesselTypeUrl: 'VesselType/DeleteVesselType',
  saveVesselSubTypeUrl: 'VesselType/SaveVesselSubType',
  deleteVesselSubTypeUrl: 'VesselType/DeleteVesselSubType',
  getManagementGroupListUrl: 'ManagementGroup/GetManagementGroupList',
  saveManagementGroupUrl: 'ManagementGroup/SaveManagementGroup',
  deleteManagementGroupUrl: 'ManagementGroup/DeleteManagementGroup',
  s3fileUploadUrl: 'FileUpload/S3Upload',
  getFleetListUrl: 'Fleet/GetFleetList',
  saveFleetListUrl: 'Fleet/SaveFleet',
  deleteFleetListUrl: 'Fleet/DeleteFleet',
  SaveRole: 'User/SaveRole',
  SaveResourceWebLink: 'ResourceWebLink/SaveResourceWebLink',
  GetResourceWebLink: 'ResourceWebLink/GetResourceWebLinkList',
  DeleteResourceWebLink: 'ResourceWebLink/DeleteResourceWebLink',
  SaveReferenceDoc: 'ReferenceDoc/SaveReferenceDoc',
  GetReferenceDoc: 'ReferenceDoc/GetReferenceDocList',
  DeleteReferenceDoc: 'ReferenceDoc/DeleteReferenceDoc',
  getManagementListUrl: 'ManagementGroup/GetManagementList',
  getVesselListUrl: 'Fleet/GetVesselListByManagement',
  GetRoleList: 'User/GetRoleList',
  DeleteRole: 'User/DeleteRole',
  SaveVesselDetails: 'VesselManagement/SaveVesselDetails',
  SaveVesselInfo: 'VesselManagement/SaveVessel',
  GetNamesList: 'VesselManagement/GetNamesList',
  GetVesselList: 'VesselManagement/GetVesselList',
  GetVesselDetails: 'VesselManagement/GetVesselDetails',
  GetVesselGeneralInfo: 'VesselManagement/GetVesselGeneralInfo',
  UpdateVesselGeneralInfo: 'VesselManagement/UpdateVesselGeneralInfo',
  ChangeVesselImage: 'VesselManagement/ChangeVesselImage',
  ToggleVesselStatus: 'VesselManagement/ToggleVesselStatus',
  GetSurveyNotificationsByVessel: 'Survey/GetVesselSurveyNotifications',
  SaveSurveyNotificationsByVessel: 'Survey/SaveVesselSurveyNotifications',
  GetSurveyIntervalScaleList: 'Survey/GetSurveyIntervalScaleList',
  GetUserList: 'User/GetUserList',
  SaveUser: 'User/SaveUser',
  DeleteUser: 'User/DeleteUser',
  GetPermission: 'User/GetRolePermissions',
  SavePermission: 'User/SaveRolePermissions',
  S3Upload: 'FileUpload/S3Upload',
  S3UploadMultiple: '/FileUpload/S3UploadMultiple',
  GetWorkFlow: 'User/GetWorkFlow',
  SaveWorkFlow: 'User/SaveWorkFlow',
  GetAttachments: 'VesselManagement/GetVesselAttachments',
  SaveAttachment: 'VesselManagement/SaveVesselAttachment',
  GetPreviousNextVessel: 'VesselManagement/GetPreviousNextVessel',
  DeleteAttachment: 'VesselManagement/DeleteVesselAttachment',
  GetComponentListByVessel: 'Component/GetComponentListByVessel',
  loginUrl: 'Login/Login',
  GetVesselOwnerList: 'ManagementGroup/GetVesselOwnerList',
  GetPortList: 'ManagementGroup/GetPortList',
  getProfileDataUrl: 'User/GetUser',
  DisableUser: 'User/DisableUser',
  GetProjectAttachments: '/Project/GetAttachments',
  GetProjectDetails: '/Project/GetProjectDetails',
  SaveProjectAttachment: '/Project/SaveAttachments',
  GetProjectBasicDetailsUrl: 'Project/GetProjectBasicDetails',
  GetAdditionalJobListUrl: 'Project/GetProjectJobs',
  GetBackupTsi: 'User/GetBackupTsi',
  GetCurrencyListBasedOnStatus: 'Currency/GetCurrencyListBasedOnStatus',
  GetFleetManagerAndTsiInfo: 'User/GetFleetManagerAndTsiInfo',
  SaveProjectSpecs: 'Project/SaveProjectSpecs',
  SaveJobAttacmentList: 'Project/SaveJobAttacmentList',
  GetJobAttacmentList: 'Project/GetJobAttacmentList',
  SaveProjectBasicDetails: 'Project/SaveProjectBasicDetails',
  SaveJobs: 'Project/SaveJobs',
  getConfirmPage: 'Project/GetProjectBasicDetails',
  GetPublishedStatusDetailsUrl: 'Task/GetPublishedStatusDetails',
  GetVesselDetailsUrl: 'VesselManagement/GetVesselDetails',
  DeleteJobAttachment: 'Project/DeleteJobAttachment',
  getFoldersUrl: 'Dms/GetFolders',
  GetDataUrl: 'Dms/GetData',
  GetSystemFilesUrl: 'Dms/GetSystemFiles',
  SaveDataUrl: 'Dms/SaveData',
  DeleteDataUrl: 'Dms/DeleteData',
  GetVesselSurveyNotificationsUrl: 'Survey/GetVesselSurveyNotifications',
  BrowseAttachments: 'Project/BrowseAttachments',
  GetProjectSpecList: 'Project/GetProjectSpecList',
  getProjectsUrl: 'Project/GetProjectList',
  SearchDmsUrl: 'Dms/SearchDms',
  GetNotificationCount: 'Notification/GetNotificationCount',
  SubmitProjectForApproval: 'Project/SubmitProjectForApproval',
  forgotPassword: 'Login/ForgotPassword',
  resetPassword: 'Login/ResetPassword',
  SavePinProjects: 'Project/SavePinProjects',
  GetComponentListByVesselProjectSpecUrl: 'Component/GetComponentListByVesselProjectSpec',
  GetComponentListByProjectSpecUrl: 'Component/GetComponentListByProjectSpec',
  SaveProjrctSpecComponentUrl: 'Component/SaveProjrctSpecComponent',
  deleteSpecComponentUrl: 'Component/DeleteSpecComponent',
  GetSelectedComponentstByProjectSpecUrl: 'Component/GetSelectedComponentstByProjectSpec',
  GetProjectSpecDescription: 'ProjectSpecification/GetProjectSpecDescription',
  SaveProjectSpecDescription: 'ProjectSpecification/SaveProjectSpecDescription',
  GetVesselByUser: 'VesselManagement/GetVesselListByUser',
  GetProjSpecAttach: '/ProjectSpecification/GetAttachments',
  SaveProjSpecAttach: '/ProjectSpecification/SaveAttachment',
  SaveProjSpecAttachMulti: '/ProjectSpecification/SaveAttachmentList',
  DeleteProjSpecAttach: '/ProjectSpecification/DeleteAttachment',
  GetActiveProjectCount: 'Project/GetActiveProjectCount',
  SaveSpecServiceLine: 'ProjectSpecification/SaveSpecServiceLine',
  DeleteSpecServiceLine: 'ProjectSpecification/DeleteSpecServiceLines',
  UpdateSpecServiceLines: 'ProjectSpecification/UpdateSpecServiceLine',
  UpdateAcCode: 'ProjectSpecification/UpdateAcCode',
  GetSpecServiceLine: 'ProjectSpecification/GetSpecServiceLines',
  SaveProjectSpecTags: 'ProjectSpecification/SaveProjectSpecTags',
  GetProjectSpecTags: 'ProjectSpecification/GetProjectSpecTags',
  DeleteProjectSpecTags: 'ProjectSpecification/DeleteProjectSpecTags',
  UpdatePassword: 'User/UpdatePassword',
  UpdateScreenCompletedStatus: 'Project/UpdateScreenCompletedStatus',
  GetWorkOrderDefectList: 'Woha/GetWorkOrderDefectList',
  GetWorkOrderDefectListUrl: 'Woha/GetWorkOrderDefectListPaged',
  SaveSpecToWorkOrderDefect: 'Woha/SaveSpecToWorkOrderDefect',
  DeleteWoha: 'Woha/DeleteWoha',
  GetProjectSpecWorkOrderDefectList: 'ProjectSpecification/GetProjectSpecWorkOrderDefectList',
  GetSpecificationListByProjectId: 'SpecificationPackage/GetSpecificationListByProjectId',
  GetPurchaseOrder: 'Poha/GetPurchaseOrderRequestsbySpec',
  GetProjectListForGantt: 'Project/GetProjectListForGantt',
  GetPurchaseOrdeRequests: 'Poha/GetPurchaseOrderRequestsPaged',
  SaveSpecPurchaseOrder: 'Poha/SaveSpecPurchaseOrder',
  MapPoPrToSpec: '/Poha/MapPrPoToSpec',
  GetJobMappedSpecs: 'Project/GetJobMappedSpecs',
  DeleteProjectSpecWorkOrderDefect: 'ProjectSpecification/DeleteProjectSpecWorkOrderDefect',
  GetTotalWohaCount: 'Woha/GetTotalWohaCount',
  GetTotalPohaCount: 'Poha/GetTotalPohaCount',
  SavePohaStatus: 'Poha/SavePohaStatus',
  SaveWohaStatus: 'Woha/SaveWohaStatus',
  verifyUserToken: 'Login/VerifyUserToken',
  GetPaintCostUrl: 'PaintCost/PaintCost',
  SaveSectionUrl: 'PaintCost/Section',
  PaintCostRegionUrl: 'PaintCost/PaintCostRegion',
  GetDependentSpecMaster: 'ProjectSpecification/GetDependentSpecMaster',
  GetDependentSpecs: 'ProjectSpecification/GetDependentSpecs',
  SaveDependentSpecs: 'ProjectSpecification/SaveDependentSpecs',
  SpecPdfExport: 'ProjectSpecification/Export',
  DeletePoPr: 'Poha/DeleteSpecPrPo',
  DeletePoha: 'Poha/DeletePoha',
  PublishSpecPackage: 'SpecificationPackage/PublishSpecPackage',
  ImportPohaData: 'DataImport/ImportPohaData',
  ImportDefectData: 'DataImport/ImportDefectData',
  ImportWohaData: 'DataImport/ImportWohaData',
  DownloadWohaExcelTemplate: 'DataImport/DownloadWohaExcelTemplate',
  DownloadPohaExcelTemplate: 'DataImport/DownloadPohaExcelTemplate',
  GetVesselAttachmentList: 'VesselManagement/GetVesselAttachmentsList',
  getDocumentCategoryList: 'GeneralConfig/GetDocumentCategoryList',
  SaveVesselAttachment: 'VesselManagement/SaveVesselAttachment',
  GetNonMappedFleetsUrl: 'Fleet/GetNonMappedFleets',
  GetNonMappedVesselsUrl: 'VesselManagement/GetNonMappedVessels',
  exportAcCodeExcel: 'AcCode/ExportExcel',
  ExportTagExcel: 'Tag/ExportExcel',
  exportKfpExcel: 'kfp/ExportExcel',
  ExportExcelYard: 'Yard/ExportExcel',
  ExportUomExcel: 'UnitOfMeasure/ExportExcel',
  exportCurrencyExcel: 'Currency/ExportExcel',
  ExportExcelTask: 'Task/ExportExcel',
  ExportExcelVesselType: 'VesselType/ExportExcel',
  ExportExcelSpecificationMaster: 'SpecificationMaster/ExportExcel',
  ExportExcelManagement: 'ManagementGroup/ExportExcel',
  ExportResourceWebLinkExcel: 'ResourceWebLink/ExportExcel',
  ExportExcelUserList: 'User/ExportExcel',

  BulkUploadCurrency: 'Currency/ImportExcel',
  GenerateTemplateCurrency: 'Currency/GenerateTemplate',
  ProjSpecificationExportExcel: 'ProjectSpecification/ExportExcel',
  BulkUploadVessel: 'VesselManagement/import',
  GenerateTemplateVessel: 'VesselManagement/Export/Template',
  BulkUploadComponent: 'Component/import',
  GenerateTemplateComponent: 'Component/Export/Template',
  GenerateTemplateAcCode: 'AcCode/GenerateTemplate',
  BulkUploadAcCode: 'AcCode/ImportExcel',
  GenerateTemplateUOM: 'UnitOfMeasure/GenerateExcelTemplates',
  BulkUploadUOM: 'UnitOfMeasure/ImportExcel',
  GenerateTemplateKFP: 'Kfp/GenerateTemplate',
  BulkUploadKFP: 'Kfp/ImportExcel',
  GenerateTemplateTag: 'Tag/GenerateExcelTemplates',
  BulkUploadTag: 'Tag/ImportExcel',
  GenerateTemplateTask: 'Task/Template',
  BulkUploadTask: 'Task/ImportExcel',
  GenerateTemplateUser: 'User/GenerateTemplate',
  BulkUploadUser: 'User/ImportExcel',
  GenerateTemplateManagementGroup: 'ManagementGroup/GenerateTemplate',
  BulkUploadManagementGroup: 'ManagementGroup/ImportExcel',
  GenerateTemplateResource: 'ResourceWebLink/GenerateExcelTemplates',
  BulkUploadResource: 'ResourceWebLink/ImportExcel',
  GenerateTemplateGenralTariff: 'SpecificationMaster/Template',
  BulkUploadGenralTariff: 'SpecificationMaster/GeneralTariff/Import',
  BulkUploadMajorspec: '/SpecificationMaster/MajorSpec/Import',
  GenerateTemplateYard: 'yard/Template',
  BulkUploadYard: 'yard/ImportExcel',
  GetAllYardList: 'yard/GetAllYardList',
  SaveProjectRfq: 'ProjectRfq/SaveProjectRfq',
  GetSendRfqYardList: 'ProjectRfq/GetSendRfqYardList',
  GetAllProjectSpecList: 'ProjectSpecification/GetProjectSpecList',
  NonSpecTsiUrl: 'ProjectSpecification/NonSpec',
  AcCodeMapping: 'ProjectSpecification/NonSpec/AcCode',
  DeleteProjectAttachments: 'Project/DeleteAttachment',
  sendForApprovalUrl: 'Project/Approval/Submit',
  updateProjectStatusUrl: 'Project/Approval',
  getProjectStatusUrl: 'Project/Approval/Status',
  GetSectionsByProject: 'SupplementarySpec/GetSectionsByProject',
  SaveSupplementorySpec: 'SupplementarySpec/SaveSupplementorySpec',
  GetSupplementorySpeList: 'SupplementarySpec/GetSupplementorySpeList',
  DeleteSupplementorySpec: 'SupplementarySpec/DeleteSupplementorySpec',
  GetChatMsgs: 'Project/ProjectMessage/List',
  SendChatMsgs: 'Project/ProjectMessage',
  GetspecCounts: 'ProjectSpecification/Dashboard/SpecCounts',
  GetOverAllTaskCounts: 'Task/getOverallTaskCount',
  ProjectApprovals: 'Project/Dashboard/Approvals',
  GetProjects: 'Project/Dashboard/Projects',
  GetSurveyData: 'Survey/Dashboard/Calendar',
  GetComments: 'Project/Approval/Comments',
  SubmitSpecsForApproval: 'SupplementarySpec/SubmitSpecsForApproval',
  DashBoard: 'VesselManagement/DashBoard/List',
  GetProjectSpecListUrl: 'ProjectSpecification/GetProjectSpecList',
  ProjectSpecDetails: 'ProjectSpecification/ProjectSpecDetailsList',
  RejectSpecs: 'SupplementarySpec/RejectSpecs',
  SubmitForRevision: 'SupplementarySpec/SubmitForRevision',
  GetSupplementorySpecListForApproval: 'SupplementarySpec/GetSupplementorySpecListForApproval',
  ProjectSpecList: 'YardQuotation/ProjectSpecList',
  UpdateQuoteStatus: 'YardQuotation/UpdateQuoteStatus',
  YardUom: 'YardQuotation/Uom',
  YardS3Upload: 'YardFileUpload/S3Upload',
  YardS3UploadMul: 'YardQuotation/UploadFiles',
  ProjectSpecDetailsList: 'YardQuotation/ProjectSpecDetailsList',
  CurrencyList: 'YardQuotation/CurrencyList',
  SaveQuote: 'YardQuotation/SaveQuote',
  GetDescription: 'SpecificationMaster/GetSpecDescription',
  SaveDescription: 'SpecificationMaster/SaveSpecDescription',
  DownloadDocuments: 'YardQuotation/DownloadDocuments',
  TermsAndCondition: 'YardQuotation/TermsAndCondition',
  ProjectApprovalStatus: '/Project/Approval/Status',
  getWidgets: '/User/Widgets',
  RfqConfirmYardList: 'ProjectRfq/RfqConfirmYardList',
  ReceivedQuoteYardList: 'YardQuotes/ReceivedQuoteYardList',
  ReceivedQuoteData: 'YardQuotes/ReceivedQuoteData',
  GetEmployeeList: 'Project/GetEmployeeListByProject',
  ImportYardQuoteData: 'YardQuotes/ImportYardQuoteData',
  SaveEmployeeContact: 'Project/SaveEmployeeContactInfo',
  GetSectionsByProjectVariation: 'VariationSpec/GetSectionsByProject',
  SaveSupplementorySpecVariation: 'VariationSpec/SaveVariationSpec',
  GetSupplementorySpeListVariation: 'VariationSpec/GetVariationSpecList',
  DeleteVariations: 'VariationSpec/DeleteVariationSpec',
  VariationPreview: 'VariationSpec/MailPreview',
  RepairSpecList: 'RepairSpec/GetRepairPhaseSpecList',
  DeleteEmployeeContactInfo: 'Project/DeleteEmployeeContactInfo',
  VariationQuoteList: 'VariationSpec/ReceivedVariationQuoteList',
  SendVariationPreview: 'VariationSpec/SendVariation',
  UpdateVariationStatus: 'VariationSpec/UpdateVariationSpecStatus',
  ImportReceivedQuote: 'VariationSpec/ImportVariationQuoteData',
  SaveWatchSatus: 'VariationSpec/SaveVariationWatchStatus',
  GetReceivedNotificationCount: 'VariationSpec/ReceivedNotificationCount',
  ProjectVariationSpecList: 'YardVariationQuote/ProjectVariationSpecList',
  SaveVariationQuote: 'YardVariationQuote/SaveVariationQuote',
  downloadExcelVariation: 'YardVariationQuote/DownloadExcelTemplate',
  UpdateQuoteStatusVariation: 'YardVariationQuote/UpdateQuoteStatus',
  DownloadExcelYard: 'YardVariationQuote/ExportExcel',
  getProjectRepairUrl: 'ProjectRepair/Get',
  saveProjectRepairUrl: 'ProjectRepair/Save',
  RepairSpecDetails: 'RepairSpec/ProjectSpecDetails',
  SaveWorkOrderStatus: 'RepairSpec/WorkOrder',
  RepairSpecUsers: 'RepairSpec/Vessel/Users',
  SaveGalleryImages: 'ProjectSpecification/Images',
  NotCompletedSpecList: 'ProjectSpecification/NotCompletedSpecList',
  JobDoneAttachment: 'RepairSpec/JobDoneAttachment',
  ProjectSpecStatusData: 'RepairSpec/ProjectSpecStatusData',
  JobDoneAttachments: 'RepairSpec/Spec/JobDoneAttachments',
  GetGalleryImages: 'ProjectSpecification/Images',
  SaveSectionDetails: 'RepairSpec/UpdateSectionDetails',
  GetProjectSpecImages: 'Gallery/GetProjectSpecImages',
  UpdateSpecIsComplete: 'SpecificationPackage/UpdateSpecIsComplete',
  saveProjectRepairCostUrl: 'ProjectRepair/Cost',
  getProjectRepairCostUrl: 'ProjectRepair/Cost/History',
  getProjectRepairCostSummaryUrl: 'ProjectRepair/Cost/Summary',
  YardGetAttachments: 'YardQuotes/GetAttachments',
  YardSaveAttachments: 'YardQuotes/SaveAttachments',
  DeleteYardAttachment: 'YardQuotes/DeleteAttachment',
  GetCancelledJob: 'ProjectSpecification/GetCancelledJobList',
  CompareQuoteList: 'CompareQuote/List',
  SaveCompareQuotes: 'CompareQuote/Spec/Save',
  CompareQuoteCommentSave: 'CompareQuote/Yard/Save',
  CompareQuoteKFP: 'CompareQuote/Kfp',
  CompareQuoteAssignee: 'CompareQuote/Assignee',
  GetContactListByCategory: 'GeneralConfig/GetContactRoleListByCategory',
  DownloadQuoteAsExcel: 'YardQuotation/DownloadQuoteAsExcel',
  NonSpecList: 'RepairNonSpec/NonSpecList',
  NonSpecLine: 'RepairNonSpec/NonSpecLine',
  CurrencyConvertedAmount: 'RepairNonSpec/CurrencyConvertedAmount',
  getPhotoUrl: 'PhotoReport/PhotoList',
  savePhotoUrl: 'PhotoReport/Photo',
  ExcelContact: 'Project/ExportExcel',
  DownloadAllAttachmentsByCategory: 'Project/DownloadAllAttachmentsByCategory',
  ProjectCollation: '/ProjectClose/Collation',
  ProjectCloseYard: '/ProjectClose/Yard',
  ProjectCloseProject: '/ProjectClose/Project',
  ExportPaintCost: '/PaintCost/ExportExcel',
  CloseProject: '/Project/Close',
  ProjectCloseDocument: '/ProjectClose/Document',
  ProjectEvaluation: '/ProjectClose/Evaluation',
  DownloadYardEvaluation: '/ProjectClose/DownloadYardEvaluation',
  ProjectReport: '/ProjectClose/Report',
  GetEmptySpecList: '/ProjectSpecification/GetEmptySpecList',
  GetEmptyAcCodeURL: 'SupplementarySpec/EmptyAcCode',
  JobProgressReportUrl: 'Report/JobProgressReport',
  ProjectRepairClose: '/ProjectRepair/Close',
  GetPrevProjectSpecs: '/ProjectSpecification/GetPreviousProjSpecs',
  ImportPrevProjSpec: '/ProjectSpecification/ImportPreviousProjSpecs',
  GetEmptySpecListAdmin: '/ProjectSpecification/GetEmptyAdminSpecList',
  VariationOrderReportUrl: 'Report/VariationOrderReport',
  CostUpdationReportUrl: 'Report/CostUpdationReport',
  YardCostSummary: 'Resources/YardCostSummary',
  SaveSofs: 'Sof/Save',
  GetSofs: 'Sof/GetSofs',
  DeleteSofs: 'Sof/Delete',
  SofReportUrl: 'Report/SofReport',
  DailyReportDataListUrl: 'DailyReport/DailyReportDataList',
  SaveDailyReportDataListUrl: 'DailyReport/DailyReportData',
  UserRolesListing: 'User/Roles',
  QuotesUpdate: 'YardQuotes/Update',
  downloadDailyReportUrl: 'DailyReport/DownloadReport',
  downloadPhotoReportUrl: 'PhotoReport/DownloadReport',
  PhotoReportHistoryUrl: 'PhotoReport/PhotoReportHistory',
  DailyReportHistoryUrl: 'DailyReport/DailyReportHistory',
  jpReportDownloadUrl: 'Report/DownloadJobProgressReport',
  vopReportDownloadUrl: 'Report/DownloadVariationOrderReport',
  sofReportDownloadUrl: 'Report/DownloadSofReport',
  cupReportDownloadUrl: 'Report/DownloadCostUpdateReport',
  QuoteStatusList: 'ProjectRfq/QuoteStatusList',
  GetProjectDDHistory: 'Project/DDHistory/Projects',
  GetDDVessel: 'Project/DDHistory/Vessel',
  CheckForMultipleProjects: 'Project/CheckForMultipleProjects',
  yardNotification: 'YardNotification/GetAll',
  saveYardNotification: 'YardNotification/Save',
  PoReportUrl: 'Poha/PoReport',
  WoReportUrl: 'Woha/WoReport',
  startProjectRepairUrl: 'ProjectRepair/Start',
  poReportDownloadUrl: 'Report/DownloadPoReport',
  woReportDownloadUrl: 'Report/DownloadWoReport',
  DeviationUrl: 'CompareQuote/Deviation',
  GetSupplementarySpec: 'SupplementarySpec/GetSupplementorySpes',
  getProjectRepairDataUrl: 'ProjectRepair/Data',
  RepairDatesUrl: 'ProjectRepair/RepairDates',
  PaintCostExportUrl: 'PaintCost/ExportExcel',
  PaintCostTemplateUrl: 'PaintCost/Template',
  PaintCostImportUrl: 'PaintCost/ImportExcel',
  specSupplimentoryExportUrl: 'ProjectSpecification/ExportAllSpecs',
  GetSupplementaryYardMailList: 'SupplementarySpec/YardMailList',
  cancelledDefferredJobCount: 'ProjectSpecification/CancelledJobCount',
  DueNotification: 'YardNotification/Vessel/Push',
  getSnooze: 'YardNotification/Snooze',
  TermsAndConditions: 'User/Terms/Agree',
  ShareReport: '/Report/ShareReport',
  SendMailLink: '/Report/SendMail',
  fileDownloadFromS3BucketUrl: 'FileUpload/Download',
  TermsContentApi: 'Login/Terms/Conditions',
  GetSupplementarySpecSendList: 'SupplementarySpec/SupplementarySpecSentList',
  VarianceReport: 'Report/VarianceReport',
  VesselManagementUsersUrl: 'VesselManagement/Users',
  ServiceLineNew: 'CompareQuote/ServiceLine/Save',
  ListKfpBySpec: 'ProjectSpecification/ListKfpBySpec',
  UpdateKfp: 'ProjectSpecification/List/UpdateSpecServiceLine',
  DownloadSupplementaryExcel: 'SupplementarySpec/DownloadExcelTemplate',
  DownloadSupplemenetaryQuotation: 'SupplementarySpec/ExportExcel',
  updateSupplementaryStatus: 'SupplementarySpec/UpdateSupplementarySpecStatus',
  DownloadVarianceReport: 'Report/DownloadVarianceReport',
  YardExportList: 'YardQuotes/NonSubmitted',
  supplementaryReceivedQuotes: 'SupplementarySpec/Yard/ReceivedQuotes',
  NotCompletedTask: 'Task/NotCompletedTasks',
  GetReceivedQuoteYardList: 'SupplementarySpec/GetReceivedQuoteYardList',
  getUpdateQuoteStatus: 'SupplementarySpec/UpdateQuoteStatus',
  SaveSupplementarySpecQuote: 'SupplementarySpec/SaveSupplementarySpecQuote',
  TRDHistory: 'ProjectRepair/GetTrdHistory',
  UpdateSpecAssigne: 'SpecificationPackage/UpdateSpecAssigned',
  ExportExcelQuote: 'CompareQuote/Export',
  CostSummaryExportUrl: 'ProjectRepair/CostSummary/ExportExcel',
  GalaryFolderListing: '/Gallery/SpecFolders',
  GalaryImageListing: '/Gallery/Spec/Images',
  DeleteSpecification: 'SpecificationMaster/Delete',
  DashboardGantt: 'Survey/Dashboard/Gantt',
  SaveRepairSpecAttachment: 'ProjectRepair/SaveQuoteAttachment',
  DeleteRepairAttachment: 'ProjectRepair/DeleteQuoteAttachment',
  importReceivedQuoteSupplementary: 'SupplementarySpec/ImportSupplementaryQuoteData',
  ContactGenerateTemplate: 'Project/Contact/Template',
  ContactImportExcel: 'Project/Contact/ImportExcel',
  ShareDocument: 'ProjectClose/ShareProjectDocument',
  GetUpdationNeededSpec: 'SupplementarySpec/GetUpdationNeededSpecs',
  TemplateKeywordUrl: 'ReportTemplate/TemplateKeyword',
  ClosedProjectDeferredSpecList: 'Resources/ClosedProjectDeferredSpecList',
  MajorSpecsSectionList: 'SpecificationPackage/MajorSpecsSectionList',
  MoveDeferredSpecs: 'SpecificationPackage/MoveDeferredSpecs',
  UnmappedDeferredSpecList: 'ProjectSpecification/UnmappedDeferredSpecList',
  getFuelType: '/GeneralConfig/FuelType',
  getFuelAdd: '/GeneralConfig/FuelType',
  DeletegetFuel: '/GeneralConfig/FuelType',
  SaveGalleryFolderDataUrl: 'Gallery/CustomFolder',
  getProjectHasApproverUrl: 'Project/HasApprover',
  getAuditLogUrl: 'ProjectSpecification/AuditLog',
  lockSpecUrl: 'ProjectSpecification/Lock',
  getAcCodeReportUrl: 'Report/AcCodeReport',
  DownloadAcCodeReportUrl: 'Report/DownloadAcCodeReport',
  specificationPdfExportUrl: 'ProjectSpecification/Export/Specs',
  getSpecListForDownloadUrl: 'Report/Specs',
  exportExcelPOHAUrl: 'Poha/ExportExcel',
  exportExcelWOHAUrl: 'Woha/ExportExcel',
  PohaStatus: 'Poha/Status',
  createDraftSpecUrl: 'ProjectSpecification/CreateDraft',
  replaceDraftSpecUrl: 'ProjectSpecification/ReplaceDraft',
  GetUserListByVesselIdUrl: 'User/GetUserListByVesselId',
  GetYardListForExportUrl: 'Yard/YardListForExport',
  GetEmptyServiceLineListUrl: 'ProjectSpecification/GetEmptyServiceLineList',
  MicrosoftTokenValidationUrl: 'Login/MicrosoftTokenValidation',
  ExportExcelForSpecLibraryUrl: 'ProjectSpecification/ExportExcelForSpecLibrary',
  ImportExcelUrl: 'ProjectSpecification/ImportExcel',
  ExportExcelUrl: 'ProjectRfq/ExportExcel',
  GetSupervisorUsersUrl: 'VesselManagement/GetSupervisorUsers',
  SaveSpecificSpecsUrl: 'SpecificationPackage/SaveSpecificSpecs',
  UpdateSpecJobStatusUrl: 'ProjectSpecification/UpdateSpecStatus',
  ManagementGroupShortListUrl: 'ManagementGroup/GetManagementGroupShortList'
};
export default API_END_POINTS;
