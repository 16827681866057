import React from 'react';
import Loader from '../../../common/loader';
import CardListItem from './CardListItem';

const CardWrapper = (props) => {
  const { vesselData, loading, ...rest } = props;
  if (vesselData.totalItems > 0) {
    return (
      <ul data-testid="card-wraper">
        {vesselData.items.map((data) => {
          return (
            <CardListItem
              data={data}
              key={data.id}
              {...rest}
              loading={loading}
            />
          );
        })}
      </ul>
    );
  }
  return <>{loading ? <Loader /> : <div data-testid="card-wraper" className="border d-flex justify-content-center p-5">No data found</div>}</>;
};
export default CardWrapper;
