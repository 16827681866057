import React from 'react';
import { cloneDeep } from 'lodash';
import { Picky } from 'react-picky';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const CreateAcCode = ({
  formData, setFormData, managementList, mgmtListOfAcCode, setMgmtListOfAcCode
}) => {
  const { charValidation, handleValidation, formValidation } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    if (field === 'managementGroupId') {
      setMgmtListOfAcCode(e);
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['code', 'name'], fd, field);
    formValidation(['id'], e[0] || { id: '' });
  };
  return (
    <>
      <div className="" data-testid="create-accode">
        <div className="col-6">
          <label>
            Manangement Name
            <span className="text-primaryRed-1">*</span>
          </label>
          <Picky
            id="id"
            autoFocus
            className="modal-select mt-2"
            value={mgmtListOfAcCode}
            options={managementList}
            onChange={(option) => { handleChange(option, 'managementGroupId'); }}
            valueKey="id"
            labelKey="name"
            multiple
            placeholder="Select Manangement"
            includeFilter
            dropdownHeight={600}
            clearFilterOnClose
            isMandatory="true"
            includeSelectAll
          />
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <InputType1 id="code" onChange={(e) => handleChange(e, 'code')} value={formData.code} className="modal-inputs" label="Code" isMandatory="true" />
          </div>
          <div className="col-8">
            <InputType1 id="name" onChange={(e) => handleChange(e, 'name')} value={formData.name} className="modal-inputs" label="Name" isMandatory="true" />
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateAcCode;
