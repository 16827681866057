import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import TextEditor from './TextEditor';
import SidePanel from '../../../common/sidePanelCustom';

const ViewTemplate = ({ formData,
  onCloseWindow,
  showView,
  TemplateTypes,
  setFormData }) => {
  const readonly = true;
  return (
    <SidePanel title="Template Settings" size="large" onClose={onCloseWindow} showView={showView}>
      <div className="side-panel__container bg-white" data-testid="create-temp">
        <div className="side-panel__content">
          <div>
            <div className="d-flex my-2">
              <div className="col-4 me-2">
                <label>Template Name</label>
                <div className="text-ellipsis pe-2">
                  <span data-tooltip-id="view-spec" data-tooltip-content={`${formData.name}`}>
                    {_.truncate(formData.name, {
                      length: 25,
                      separator: ' '
                    })}
                  </span>

                </div>
              </div>
              <div className="col-4 me-2">
                <label>Template Category</label>
                <div>
                  {TemplateTypes
                    .filter((option) => option.value === formData?.category)[0]?.label}
                </div>
              </div>
              <div className="col-4 d-flex justify-content-end pe-2" />
            </div>
            <div className="row">
              <div className="editor-wrap">
                <TextEditor id="editor" formData={formData} readonly={readonly} setFormData={setFormData} />
              </div>
            </div>
          </div>
        </div>
        <Tooltip id="view-spec" place="top" className="tooltip mt-3" />
      </div>

    </SidePanel>
  );
};
export default ViewTemplate;
