import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../../common/InputType1';
import Pagination from '../../../../common/pagination';
import SidePanel from '../../../../common/sidePanel';
// import ImageGallery from '../../Gallery/imageGallery';
import GalleryFolderView from '../../Gallery/GalleryFolderView';

const PhotoReportComponent = (
  {
    images,
    setOpenDeleteModal,
    setActiveImageIndex,
    hanleNameChange,
    activeImageIndex,
    isReport,
    galleryImages,
    // getImages,
    // loading,
    // setLoading,
    // setCurrentpage,
    handleUploadImage,
    pageClick,
    currentpage,
    onSizeChange,
    pageSize,
    isTSI,
    setActiveImageIndexWraper, id
  }
) => {
  const [folderType, setFolderType] = useState(1);
  // const [reloadFolder, setReloadFolder] = useState();
  const [active, setActive] = useState('');
  const [customLoading, setCustomLoading] = useState(false);

  return (
    <div data-testid="photo-report-compo" className="row">
      {images && images.length ? (
        <div className="container">
          <div className="gallery justify-content-center">
            {images.map((i, index) => {
              return (
                <div className="gallery-wraper">
                  <div className="gallery-item">
                    {i.imageUrl && isTSI ? (
                      <button id="delete" onClick={() => setOpenDeleteModal(index)} type="button" className="delete-button">
                        <span className="icon-delete" />
                      </button>
                    ) : (
                      ''
                    )}
                    {i.imageUrl ? (
                      <img className="gallery-image" src={i.imageUrl} alt="" />
                    ) : (
                      <div
                        id="upload"
                        onClick={() => {
                          if (isTSI) {
                            setFolderType(1);
                            setActiveImageIndexWraper(index);
                          }
                        }}
                        className="bg-sky-blue cursor-pointer photo-content text-2xl"
                      >
                        <span className="brand-color d-block font-35 icon-image mb-2" />
                        <span className="brand-color opacity-6">Click here to choose image from Gallery</span>
                      </div>
                    )}
                  </div>
                  <div className="text-wraper">
                    <InputType1
                      id="description"
                      disabld={!i.imageUrl}
                      readOnly={!i.imageUrl}
                      disabled={!isTSI}
                      value={i.imageDescription}
                      onChange={(e) => {
                        hanleNameChange(e.target.value, index);
                      }}
                      className={`text-box w-full ${i.imageUrl ? '' : 'disabled-field'}`}
                      placeholder="Add Description"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="align-items-lg-start d-flex justify-content-center">
          <span className="font-18 font-bolds icon-Info me-2 rotate-180 text-black" />
          <p>No records found</p>
        </div>
      )}
      <SidePanel
        title={(
          <div className="d-flex align-items-center ">
            <div className="">Image Gallery</div>
            {/* <div className="ms-3 couter-bubbles status-due ">{galleryImages
              ?.totalItems}</div> */}
          </div>
        )}
        showView={activeImageIndex !== ''}
        size="large document-side-panel "
        bgColor="sky-white"
        onClose={() => {
          setFolderType(1);
          setActiveImageIndex('');
        }}
      >
        {activeImageIndex !== '' && (
          <GalleryFolderView
            id={id}
            isReport={isReport}
            handleUploadImage={handleUploadImage}
            folderType={folderType}
            setFolderType={setFolderType}
            active={active}
            setActive={setActive}
            setCustomLoading={setCustomLoading}
            customLoading={customLoading}
            // reloadFolder={reloadFolder}
          />
          // <ImageGallery
          //   isReport={isReport}
          //   uploadView={false}
          //   images={galleryImages}
          //   getImages={getImages}
          //   loading={loading}
          //   setLoading={setLoading}
          //   setCurrentpage={setCurrentpage}
          //   handleUploadImage={handleUploadImage}
          // />
        )}
        <footer className="side-panel__footer">
          {galleryImages?.totalItems > 20 && (
            <Pagination
              pageClick={pageClick}
              pageCount={galleryImages.totalPages}
              currentpage={currentpage}
              onSizeChange={onSizeChange}
              size={pageSize}
              fromGallery
            />
          )}
        </footer>
      </SidePanel>
      <Tooltip id="project-imageDescri" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default PhotoReportComponent;
