import React from 'react';
import ReactTable from 'react-table-v6';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import TableSortHeader from '../../../common/TableSortHeader';
import TableLoader from '../../../common/TableLoader';

const TemplateTable = (props) => {
  const {
    tableData,
    editData,
    deteleData,
    viewData,
    handleSort,
    sortData,
    loading
  } = props;
  const columns = [
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Template Name"
            headingProperty="name"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },

      resizable: false,
      sortable: false,
      accessor: 'name',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-report"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Template Category"
            headingProperty="categoryValue"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },

      resizable: false,
      sortable: false,
      accessor: 'categoryValue',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-report"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Created Date"
            headingProperty="createdDate"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },

      resizable: false,
      sortable: false,
      accessor: 'createdDate',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-report"
            data-tooltip-content={`${moment(value).format('DD MMM YYYY')}`}
          >
            {moment(value).format('DD MMM YYYY')}
          </span>
        );
      }
    },
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Status"
            headingProperty="statusValue"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },

      resizable: false,
      sortable: false,
      accessor: 'statusValue',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-report"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      width: 145,
      resizable: false,
      sortable: false,
      accessor: 'packageName',
      Cell: (row) => {
        const { original } = row;
        return (
          <div>
            <button type="button" className="link-btn" onClick={() => viewData(original)}>
              <span className="icon-eye font-22" />
            </button>
            <button
              type="button"
              className="link-btn"
              data-tooltip-id="project-report"
              data-tooltip-content={original?.mappedMessage}
              // disabled={original.isMapped}
              onClick={() => editData(original)}
              disabled={original.statusValue === 'Deleted' || original.statusValue === 'Published' || original?.isMapped}
            >
              <span className="icon-edit font-22 mx-3" />
            </button>
            <button
              disabled={original.statusValue === 'Deleted' || original?.isMapped}
              data-tooltip-id="project-report"
              data-tooltip-content={original?.mappedMessage}
              // disabled={original.isMapped}
              type="button"
              className="link-btn"
              onClick={() => deteleData(original)}
            >
              <span className="icon-delete font-22" />
            </button>
          </div>
        );
      }
    }
  ];
  const tableRows = tableData?.items || [];

  return (
    <>
      <div className="stripped-react-table" data-testid="table-template">
        <ReactTable
          loading={loading}
          LoadingComponent={TableLoader}
          className="table table-scroll"
          data={tableRows}
          columns={columns}
          pageSize={tableRows?.items?.length}
          minRows={0}
          showPagination={false}
        />
        <Tooltip id="project-report" place="top" className="tooltip mt-4" />
        <Tooltip id="project-value1" place="top" className="tooltip" />

      </div>
    </>

  );
};
export default TemplateTable;
