import React from 'react';
import { cloneDeep } from 'lodash';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const AddSubTypeVesselData = ({ formData, setFormData }) => {
  const { charValidation } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = charValidation(e.target.value);
    setFormData(fd);
  };
  return (
    <div className="modal-content" data-testid="add-vsubdata">
      <div className="row">
        <div className="col-5">
          <InputType1 autoFocus id="name" value={formData.name} onChange={(e) => handleChange(e, 'name')} className="modal-input" label="Vessel Sub Type" isMandatory="true" />
        </div>
      </div>
    </div>
  );
};

export default AddSubTypeVesselData;
