import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ApprovalTender from './ApprovalTender';
import helper from '../../../../../utils/helper';
import Tender from './index';
import { getProjectStatus } from '../../NewProject/services';

const TenderWrapper = () => {
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR
  || userRole === roles.VESSEL_USER
  || userRole === roles.ADMIN;
  const [state, setstate] = useState('');
  const { id } = useParams();
  useEffect(() => {
    getProjectStatus(id, 3).then((res) => {
      if (res?.success) {
        console.log('TENDER 3 -', res.data);
        if (res.data && res.data !== 0) {
          setstate('approve');
        }
      }
    });
  }, []);
  return (
    <div className="content-tenderwrap" data-testid="tender-main-wraper">
      {isTSI
        ? <Tender isTSI={isTSI} />
        : (
          <>
            {userRole === roles.EDITOR && state === ''
              ? <Tender isTSI={isTSI} />
              : <ApprovalTender isTSI={isTSI} userRole={userRole} />}
          </>
        )}
    </div>
  );
};
export default TenderWrapper;
