/* eslint-disable */
import React from 'react';
import SidePanel from '../../../../../common/sidePanel';
import SendVariations from '../../../SendVariation';

const EmailPreview = (props) => {
  const { showVariationPreview, setShowVariationPreview, previewContent,
    handleConfirmVariation } = props;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();

  return (
    <div>
      <SidePanel
        data-testid="EmailPreview"
        title="Variation Email Preview"
        size="large"
        showView={showVariationPreview}
        onClose={() => setShowVariationPreview(false)}
        bgColor="#fff"
      >

        <SendVariations previewContent={previewContent} />

        <footer className="side-panel__footer" data-testid="EmailPreview">
          <>
            <button type="button" className="primary-btn me-4 large"id="email-preview" onClick={()=>setShowVariationPreview(false)}>
              Close
            </button>
            <button type="button" 
            disabled={userRole === 'admin'}
            className="primary-btn me-4 large" onClick={handleConfirmVariation}>
              Confirm and Send
            </button>
          </>
        </footer>
      </SidePanel>
    </div>
  );
};
export default EmailPreview;
