import React from 'react';
import SidePanel from '../../../../../../common/sidePanelCustom';
import ComponentDetails from './ComponentDetails';

const ComponentDetailsWrap = (props) => {
  const {
    openModal,
    onCloseWindow,
    loading,
    formData,
    expanded,
    handleExpand,
    handleChangeType,
    checkStatus,
    setCheckStatus,
    scrollId,
    saveApiCall,
    specDetails,
    isTsiDisabled,
    fromSpecify
  } = props;
  const headingSpecText = `${specDetails?.specificationNo}. ${specDetails?.title}`;
  return (
    <div data-testid="com-5">
      {openModal ? (
        <SidePanel fromSpecify={fromSpecify} title="Components" size="large" onClose={onCloseWindow} showView={openModal} isAddComponent={headingSpecText}>
          <ComponentDetails
            loading={loading}
            formData={formData}
            expanded={expanded}
            isTsiDisabled={isTsiDisabled}
            setExpanded={handleExpand}
            handleChangeType={handleChangeType}
            checkStatus={checkStatus}
            setCheckStatus={setCheckStatus}
            scrollId={scrollId}
          />
          <footer className="side-panel__footer">
            <button type="button" className="secondary-btn me-4" onClick={onCloseWindow}>
              Cancel
            </button>
            {formData && formData.length ? (
              <button disabled={isTsiDisabled} type="button" className="primary-btn me-4" onClick={saveApiCall}>
                Map to the Spec
              </button>
            ) : ''}
          </footer>
        </SidePanel>
      ) : (
        ''
      )}
    </div>
  );
};
export default ComponentDetailsWrap;
