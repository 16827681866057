import React from 'react';
import SidePanel from '../../../../common/sidePanel';
import InputType1 from '../../../../common/InputType1';

const EditAccord = (props) => {
  const { showModal, onClose, onSave, updateInfo, vesselInfo, setVesselInfo } = props;
  const onUpdateInfo = (e, type) => {
    const infos = { ...vesselInfo };
    if (type === 'number') {
      infos[updateInfo.tabkey][e.target.name] = e.target.value.replace(/[^0-9 )(+-]/g, '').substring(0, 15);
    } else if (type === 'email') {
      infos[updateInfo.tabkey][e.target.name] = e.target.value.substring(0, 64);
    } else {
      infos[updateInfo.tabkey][e.target.name] = e.target.value;
    }
    setVesselInfo(infos);
  };
  return (
    <div data-testid="edit-accord">
      <SidePanel
        title={updateInfo?.tabLabel}
        onClose={onClose}
        buttonName="save"
        showView={showModal}
        onSave={onSave}
      >
        <div className="row mb-2">
          {
            updateInfo?.items?.map((item) => {
              return (
                <div className="mb-3 col-6">
                  <InputType1
                    label={item.label}
                    className="modal-input"
                    name={item.key}
                    id={item.key}
                    value={vesselInfo[updateInfo.tabkey] && vesselInfo[updateInfo.tabkey][item.key]}
                    onChange={(e) => { onUpdateInfo(e, item.type); }}
                  />
                </div>
              );
            })
          }
        </div>
      </SidePanel>
    </div>
  );
};
export default EditAccord;
