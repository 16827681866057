/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import Loader from '../../../../../../../common/loader';

const JobListing = (props) => {
  const { setEyeView, setBack, data, onSecOpen, mainSec,
    showsubSec, viewMore, setMainList, mainList, getPrevProjectSpecList,
    eyeView, prevProjItems, setFinalFilteredArray, loading } = props;

  const [prevList, setPrevList] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const arr = [];
    data?.map((items) => {
      items?.specifications?.map((i) => {
        if (i?.isPrevSpecMapped) {
          arr.push(i?.id);
          setPrevList(arr);
        }
      });
    });
  }, [data]);

  const handlSectionCheckBox = (mainId) => {
    const idList = [...mainList];
    if (idList.indexOf(mainId) > -1) {
      idList.splice(idList.indexOf(mainId), 1);
    } else {
      idList.push(mainId);
    }

    setMainList(idList);
    const combinedArray = [...idList, ...prevList];
    const uniqueArray = Array.from(new Set(combinedArray));
    const finalArray = uniqueArray.filter((item) => !prevList.includes(item));
    setFinalFilteredArray(finalArray);
  };

  const handleMainSectionCheckBox = (index, items) => {
    const idList = [...mainList];
    const mainId = items?.id;
    if (idList.indexOf(mainId) > -1) {
      idList.splice(idList.indexOf(mainId), 1);
      items?.specifications?.forEach((item) => {
        idList.splice(idList.indexOf(item?.id), 1);
      });
    } else {
      idList.push(mainId);
      items?.specifications?.forEach((item) => {
        idList.push(item?.id);
      });
    }

    setMainList(idList);
    const combinedArray = [...idList, ...prevList];
    const uniqueArray = Array.from(new Set(combinedArray));
    const finalArray = uniqueArray.filter((item) => !prevList.includes(item));
    setFinalFilteredArray(finalArray);
  };

  useEffect(() => {
    if (eyeView) {
      getPrevProjectSpecList();
    }
  }, [eyeView]);

  const isSectionChecked = (specData) => {
    let isAllSelect = true;
    specData?.specifications?.map((item) => {
      if (
        (mainList.indexOf(item.id) < 0 && !item.isPrevSpecMapped)) {
        isAllSelect = false;
      }
      return null;
    });
    return isAllSelect;
  };
  return (
    <>
      <div className="d-flex head-14 mt-1 brand-color justify-content-between" data-testid="joblisting">
        <div>
          <span
            data-tooltip-id="project-info"
            data-tooltip-content={prevProjItems?.projectFullName}
          >
            {_.truncate(prevProjItems?.projectName, { length: 35 })}
          </span>
        </div>
        {prevProjItems?.projectFullName
          && (
            <div>
              <span className="head-14">Docking Date : </span>
              <span
                data-tooltip-id="project-info"
                data-tooltip-content={prevProjItems?.dDdate ? moment(prevProjItems?.dDdate).format('DD-MMM-YYYY') : '-'}
              >

                {_.truncate(prevProjItems?.dDdate ? moment(prevProjItems?.dDdate).format('DD-MMM-YYYY') : '-', { length: 20 })}
              </span>
            </div>
          )}
      </div>
      <div className="p-3">
        <button
          data-testid="back"
          id="back"
          className="link-btn mb-2 mt-4"
          type="button"
          onClick={() => {
            setBack(true);
            setEyeView(false);
            setMainList('');
          }}
        >
          <span className="icon-arrow-left2 pe-2 me-2" />
          Back
        </button>
        {loading && <Loader />}
        {data?.map((items, index) => {
          return (
            <>
              <div className="d-flex align-items-center mt-3" id="accord">
                <span
                  id="open"
                  className={
                    items?.id === mainSec
                      ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                      : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                  }
                  onClick={() => {
                    onSecOpen(items?.id, true);
                  }}
                />
                <div className="check-box" id="maincheck" onClick={() => handleMainSectionCheckBox(index, items)}>
                  <input
                    type="checkbox"
                    name=""
                    value="MajorSpecs"
                    checked={isSectionChecked(items)}
                  />
                  <label htmlFor="MajorSpecs">{items.specificationNo}</label>
                </div>
                <span
                  data-tooltip-id="project-info"
                  data-tooltip-content={items.title}
                  className="ps-3"
                >
                  {_.truncate(items?.title, { length: 70 })}
                </span>
              </div>
              <div className="spec-library-border-down w-full mt-3" />
              {items?.id === mainSec
                && items?.specifications?.map((item) => {
                  return (
                    <div>
                      <div className="d-flex align-items-center  mt-4 ms-5" id="accord">
                        <span
                          id="openSub"
                          className={
                            item?.id === showsubSec
                              ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180'
                              : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                          }
                          onClick={() => {
                            onSecOpen(item?.id, false);
                          }}
                        />

                        <div
                          className="check-box"
                          onClick={() => handlSectionCheckBox(item?.id)}
                          id="subcheck"
                        >
                          <input
                            type="checkbox"
                            name=""
                            value="MajorSpecss"
                            checked={mainList.indexOf(item.id) > -1 || item.isPrevSpecMapped}

                          />
                          <label htmlFor="MajorSpecss">{item.specificationNo}</label>
                        </div>
                        <span
                          data-tooltip-id="project-info"
                          data-tooltip-content={item.title}
                          className="ps-3"
                        >
                          {_.truncate(item.title, { length: 43 })}
                        </span>
                        {item?.id === showsubSec && (
                          <button
                            type="button"
                            id="viewmore"
                            className="primary-btn small ms-5"
                            onClick={() => {
                              viewMore(item);
                            }}
                          >
                            View More
                          </button>
                        )}
                      </div>
                      {item?.id === showsubSec
                        && item?.serviceLines.map((i, num) => {
                          return (
                            <div>
                              {num === 0 && (
                                <div className=" d-flex p-3 align-items-center">
                                  <div className="col-1" />
                                  <div className="col-5 bg-sky-blue spec-library-border-down  ps-3 py-1">Service LN#</div>
                                  <div className="col-6 bg-sky-blue spec-library-border-down py-1">Service Lines</div>
                                </div>
                              )}

                              <div className="d-flex align-items-center ps-3">
                                <div className="col-1" />
                                <div className="col-5 ps-3 spec-library-border-down">{i.number}</div>

                                <div
                                  className="col-6 spec-library-border-down "
                                >
                                  <span
                                    data-tooltip-id="project-info"
                                    data-tooltip-content={i.serviceLineName}
                                  >
                                    {_.truncate(i.serviceLineName, {
                                      length: 45,
                                      separator: ' '
                                    })}
                                  </span>

                                </div>

                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </>
          );
        })}
        {data?.length === 0 && (
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">
              No data found
            </div>
          </div>
        )}
        <Tooltip id="project-info" place="top" className="tooltip m-4" />
      </div>
    </>
  );
};
export default JobListing;
