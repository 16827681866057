import React from 'react';
import Select from 'react-select';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';
import ModalBox from '../../../common/ModalBox';

const BlankPaintCost = ({
  setVendor,
  vendorList,
  addVendor,
  vendor,
  regionList,
  setSelectedRegion,
  initialRegions,
  // handleRegionHide,
  setOpenModalRegionDelete,
  // setOpenModal,
  addRegion,
  // openModal,
  openModalRegionDelete,
  removeRegion,
  selectedRegion,
  isTSI,
  setOpenDeleteVendorModal
}) => {
  const { charValidation } = helper;

  return (
    <div data-testid="upload-card" className="blank-paint">
      <div className="container-fluid pt-3">
        <div className="card m-5 p-5">
          <h2>Paint cost Setup</h2>
          <div className=" pt-4">
            <label className="pb-2">Add Region</label>
            <div className="d-flex justify-content-start ">
              <div className="w-full">
                <Select
                  autoFocus
                  value={selectedRegion}
                  onChange={(option) => setSelectedRegion(option)}
                  className="modal-select"
                  label="Region"
                  placeholder="Select"
                  options={initialRegions}
                  id="Region"
                  isDisabled={!isTSI}
                />
                {regionList && regionList.length ? ''
                  : (
                    <div className="font-13 ms-2 mt-3 my-2 text-content-grey">
                      <span className="icon-Info red mt-2" />
                      <i> Click Add button to add region</i>
                    </div>
                  )}

              </div>
              <button
                id="save-reg"
                onClick={() => addRegion()}
                className="primary-btn large ms-3 mt-1"
                type="button"
                disabled={!isTSI}
              >
                Add
              </button>
            </div>
          </div>
          <div className="col-gap-1 d-flex pt-4">
            {regionList.map((i) => (
              <div className="bg-sky-white d-flex mt-1 py-2">
                <span className="icon-location font-18 me-1 pt-1" />
                <div className="text-ellipsis me-4">
                  <span className=" " data-tooltip-id="project-cost" data-tooltip-content={i.label}>{i.label}</span>
                </div>
                <button disabled={!isTSI} type="button" id="delete-region" onClick={() => setOpenModalRegionDelete(i)}>
                  <span className="text-blue icon-close-line font-14 me-2 pt-2 cursor-pointer" />
                </button>
              </div>
            ))}
          </div>
          <div className="mt-5">
            <label className="pb-2">Add Vendor</label>

            <div className="">
              <div className="d-flex justify-content-start ">
                <div className="w-full">
                  <InputType1
                    id="vendor"
                    onChange={(e) => setVendor(charValidation(e.target.value))}
                    value={vendor?.toUpperCase()}
                    className="modal-input w-full"
                    label=""
                    disabled={!isTSI}
                  />
                  {vendorList && vendorList.length ? ''
                    : (
                      <span className="font-13 ms-2 my-2 text-content-grey">
                        <span className="icon-Info red" />
                        <i> Click Add button to add vendor </i>
                      </span>
                    )}
                </div>
                <button
                  id="save-ven"
                  onClick={() => addVendor()}
                  className="primary-btn large ms-3 mt-2"
                  type="button"
                  disabled={!isTSI}
                >
                  Add
                </button>
              </div>
            </div>
            <div className="">
              <div className=" p-3">
                {vendorList && vendorList.length ? (
                  <>
                    <div className="row border py-2 bg-sky-blue">
                      <div className=" t-head ps-5">VENDOR NAME</div>
                    </div>
                    {vendorList.map((i, index) => {
                      return (
                        <div className="row border py-2">
                          <div className="col-10 ps-5 text-ellipsis">
                            <span data-tooltip-id="project-cost" data-tooltip-content={i.vendor}>
                              {i?.vendor?.toUpperCase()}
                            </span>
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              className="link-btn ms-3"
                              onClick={() => {
                                setOpenDeleteVendorModal(index);
                              }}
                              disabled={!isTSI}
                            >
                              <span className="icon-delete icon-btn  brand-color cursor-pointer font-24" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : ''}
              </div>
            </div>
            {openModalRegionDelete ? (
              <ModalBox
                modalClassName="medium"
                onSave={() => removeRegion()}
                closeModal={() => setOpenModalRegionDelete(false)}
                heading="Delete Region"
                buttonName="Delete"
              >
                <p>Are you sure you want to delete region?</p>
              </ModalBox>
            ) : (
              ''
            )}
            <Tooltip id="project-cost" place="top" className="tooltip mt-3" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlankPaintCost;
