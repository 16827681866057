import React from 'react';
import { cloneDeep } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const AddRole = ({ formData, setFormData }) => {
  const { charValidation, handleValidation } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = charValidation(e.target.value);
    setFormData(fd);
    handleValidation(['name'], fd, field);
  };

  const handleSelectChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = e.value;
    setFormData(fd);
    handleValidation(['type'], fd, field);
  };
  const roleCategory = [
    { label: 'Author', value: 2 },
    { label: 'Editor', value: 3 },
    { label: 'Viewer', value: 4 },
    { label: 'Vessel User', value: 5 },
    { label: 'Admin', value: 1, isDisabled: true }
  ];
  return (
    <>
      <div className="modal-content m-3" data-testid="addrole">
        <div className="d-flex col-gap-1 align-items-center">
          <div className="col-6">
            <label>
              Role Category
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              autoFocus
              id="type"
              className="modal-select mt-2"
              name="type"
              label="type"
              placeholder="Select Category"
              isMandatory="true"
              options={roleCategory}
              onChange={(option) => { handleSelectChange(option, 'type'); }}
              value={roleCategory.filter((option) => option.value === formData.type)}
              // value={formData.type}
            />
          </div>
          <div className="col-6">
            <InputType1 id="name" className="modal-input" onChange={(e) => handleChange(e, 'name')} value={formData.name} label="Role Name" isMandatory="true" />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddRole;
