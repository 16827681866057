import React, { useEffect } from 'react';

const SidePanelCustom = (props) => {
  const { title, onClose, classHeader, setIcon, fromSupplementary, onBack, children, isComponentClicked, showView, size = 'medium', bgColor = 'bg-white', hideCloseBtn = true } = props;
  useEffect(() => {
    if (showView) { document.body.classList.add('model-open'); }
  }, [showView]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('model-open');
    };
  }, [showView]);
  return (
    <div data-testid="sp-panel" className={` ${showView ? `side-panel side-panel--from-right side-panel--is-visible ${size}` : 'side-panel side-panel--from-right'}`}>
      {!isComponentClicked && (
        <header className={`side-panel__header ${classHeader || ''}`}>
          {title?.toLowerCase().includes('jobs') ? (
            <h1>
              <button data-testid="back" className="link-btn mb-2" type="button" onClick={() => onBack()}>
                <span className="icon-arrow-left2 pe-2 me-2" />
              </button>
              {title}

            </h1>
          ) : (
            <>
              <h1>
                {title}
                {fromSupplementary && title === 'Supplementary Specs' ? <span data-testid="icon" onClick={() => setIcon(true)} className="cursor-pointer font-18 icon-Info ms-3 mb-5 second-brand-color" /> : ''}
              </h1>
            </>
          ) }
          {hideCloseBtn && <button data-testid="close-btn" type="button" className="side-panel__close react-side-close" onClick={() => { onClose(); }}>Close</button>}
        </header>
      )}

      <div className={`side-panel__container ${bgColor}`}>
        <div className="side-panel__content abc-supply">
          {children}
        </div>
      </div>

    </div>
  );
};
export default SidePanelCustom;
