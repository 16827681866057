import React from 'react';
import Loader from '../../../common/loader';
import Card from './card';

const CardWrapper = (props) => {
  const { userList, loading, ...rest } = props;
  if (userList.totalItems > 0) {
    return (
      userList.items?.map((userData) => {
        return (
          <Card
            userData={userData}
            {... rest}
          />
        );
      })
    );
  } if (loading) {
    return (<div className="border d-flex justify-content-center p-5"><Loader /></div>);
  }
  return (
    <div className="mt-5 p-4 d-flex justify-content-center user-list-policy" data-testid="yard-attach">
      <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
      <div className="font-18 animate__animated animate__headShake">
        No data found
      </div>
    </div>
  );
};
export default CardWrapper;
