import React, { useEffect } from 'react';

const SidePanel = (props) => {
  const { title, onClose, children, showView, size = 'medium', isAddComponent = '', fromSpecify } = props;
  useEffect(() => {
    if (showView) { document.body.classList.add('model-open'); }
  }, [showView]);

  useEffect(() => {
    return () => {
      if (fromSpecify) {
        document.body.classList.add('model-open');
      } else {
        document.body.classList.remove('model-open');
      }
    };
  }, [showView]);
  return (
    <div className={` ${showView ? `side-panel side-panel--from-right side-panel--is-visible ${size}` : 'side-panel side-panel--from-right'}`}>
      {isAddComponent ? (
        <header className="side-panel__header">
          <div className="col-gap-2 d-flex">
            <h1>Add Components</h1>
            <div className="spec-head text-ellipsis">
              <div title={isAddComponent} className="text-ellipsis">
                {isAddComponent}
              </div>
            </div>
          </div>
          <button type="button" className="side-panel__close react-side-close" data-test="click1" onClick={() => { onClose(); }}>Close</button>
        </header>
      ) : (
        <header className="side-panel__header">
          <h1 className="text-ellipsis w-52">{title}</h1>
          <button type="button" className="side-panel__close react-side-close" data-test="click2" onClick={() => { onClose(); }}>Close</button>
        </header>
      )}
      {children}
    </div>
  );
};
export default SidePanel;
