/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _, { cloneDeep } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../../../common/InputType1';
import Loader from '../../../../../common/loader';
import helper from '../../../../../../utils/helper';

const AddNonSpecItem = (
  { formData, setFormData, acCodeList, ondrop, fileLoading, currency, projectCurrency, getCurrency,
    currencyId, newACCode, setnewACCode }
) => {
  const { charValidation, handleValidation, restrictDecimal, ZERO_UUID } = helper;
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      if (field === 'currencyId') {
        getCurrency(e.value, currencyId, formData.amount);
      }
      fd[field] = e?.value;
      if (field === 'acCodeId') {
        fd.acCodeName = e?.label;
      }
    } else if (field === 'amount') {
      fd[field] = charValidation(restrictDecimal(e?.target?.value, 8));
      if (fd.currencyId) { getCurrency(fd.currencyId, currencyId, fd[field]); }
    } else if (field === 'acCodeName') {
      fd[field] = charValidation(e?.target?.value);
      fd.acCodeId = ZERO_UUID;
    } else {
      fd[field] = charValidation(e?.target?.value);
    }
    setFormData(fd);
    handleValidation(['nonSpecItem', 'amount', 'currencyId'], fd, field);
  };
  const deleteFile = (key) => {
    const attachment = { ...formData };
    attachment.attachments.splice(key, 1);
    setFormData(attachment);
  };
  const getTitle = () => {
    const value = acCodeList.filter((val) => val.value === formData.acCodeId);
    if (value && value.length) return value[0].label;
    return '';
  };
  useEffect(() => {
    if ((!formData.acCodeId || formData.acCodeId === ZERO_UUID) && formData.acCodeName) {
      setnewACCode(true);
    }
  }, []);
  return (
    <>
      <div className="modal-content" data-testid="non-spec-add">
        <div className="row mb-3">
          <div className="col-6">
            <InputType1
              id="nonSpecItem"
              name="nonSpecItem"
              value={formData.nonSpecItem}
              onChange={(e) => handleChange(e, 'nonSpecItem')}
              className="modal-input"
              label="Non Spec Line"
              isMandatory="true"
            />
          </div>
          <div className="col-6">
            <InputType1
              id="prPoNo"
              name="prPoNo"
              value={formData.prPoNo}
              onChange={(e) => handleChange(e, 'prPoNo')}
              className="modal-input"
              label="PO/PR Number"
            />
          </div>
          <div className="col-6 mt-4">
            <InputType1
              id="item"
              name="item"
              value={formData.item}
              onChange={(e) => handleChange(e, 'item')}
              className="modal-input"
              label="Item"
            />
          </div>
          {newACCode
            ? (
              <div className="col-6 mt-4">
                <InputType1
                  id="acCodeName"
                  name="acCodeName"
                  maxLength="50"
                  value={formData.acCodeName ? formData.acCodeName : ''}
                  onChange={(e) => handleChange(e, 'acCodeName')}
                  className="modal-input"
                  label="AC Code"
                />
              </div>
            ) : (
              <div className="col-6 mt-4">
                <label>
                  AC Code
                </label>
                <div title={getTitle()}>
                  <Select
                    className="mt-2"
                    placeholder="AC code"
                    options={acCodeList}
                    id="acCodeId"
                    classNamePrefix="react-tag"
                    value={acCodeList.filter((val) => val.value === formData.acCodeId)}
                    onChange={(e) => {
                      handleChange(e, 'acCodeId', 'select');
                    }}
                  />
                </div>
              </div>
            )}
          <div className="check-box mt-3 ac-check">
            <input
              type="checkbox"
              id="newACCode"
              name="newACCode"
              checked={newACCode}
              onChange={() => {
                if (!newACCode) {
                  const temp = cloneDeep(formData);
                  temp.acCodeId = ZERO_UUID;
                  temp.acCodeName = '';
                  setFormData(temp);
                }
                setnewACCode(!newACCode);
              }}
            />
            <label htmlFor="newACCode">Input AC Code Manually</label>
          </div>
          <div className="col-4 mt-4">
            <InputType1
              id="amount"
              name="amount"
              value={formData?.amount !== 0 ? formData?.amount : ''}
              onChange={(e) => handleChange(e, 'amount')}
              className="modal-input hide-arrows"
              label="Amount"
              type="number"
              min="0"
              step="0.01"
              isMandatory="true"
            />
          </div>
          <div className="col-4 mt-4">
            <label>
              Currency
              <span className="text-primaryRed-1">*</span>
            </label>
            <div>
              <Select
                className="modal-select mt-2"
                label="Currency"
                id="currencyId"
                placeholder="Select"
                isDisabled={formData.amount === ''}
                options={projectCurrency}
                value={projectCurrency?.filter((e) => e.value === formData?.currencyId)}
                onChange={(e) => handleChange(e, 'currencyId', 'select')}
              />
            </div>
          </div>
          <div className="col-4 mt-4">
            <InputType1
              id="ownerCost"
              name="ownerCost"
              disabled
              value={formData?.ownerCost === 0 || formData?.amount === '' ? '' : formData.ownerCost}
              className="modal-input hide-arrows"
              label={`Owner's Cost (${currency})`}
              type="number"
            />
          </div>
        </div>
        {fileLoading
          ? (
            <div className="drag-drop-file my-4">
              <Loader />
            </div>
          )
          : (
            <div className="relative">
              <div id="fileName-div" className="drag-drop-file my-4 file-waiting col-12">
                <input multiple className="p-5 align-self-center" id="fileName" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
                <div className="d-flex row w-full">
                  <div className="d-flex flex-column justify-content-center align-items-center col-8">
                    <div className="icon-buble mb-2">
                      <span className="icon-upload-cloud" />
                    </div>
                    <div>Chooses a file or drag it here</div>
                    <div className="font-12">(File formats: jpeg, jpg, png, tiff, pdf - Max size(5MB))</div>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center col-4">
                    <div className="div_btn">
                      Browse File
                    </div>
                  </div>
                </div>
              </div>
              <div className="attached-file-multi-nonspec">
                {formData.attachments?.map((item, key) => {
                  return (
                    <>
                      <div className="d-flex item">
                        <span className="icon-check-rnd me-2 font-20 file-added-multi"> </span>
                        <div>
                          <span
                            data-tooltip-id="project-phrepair"
                            data-tooltip-content={item?.name}
                          >
                            {_.truncate(item.name, {
                              length: 35,
                              separator: ' '
                            })}

                          </span>
                          <Tooltip id="project-phrepair" place="top" className="tooltip mg-left" />
                        </div>
                        <button type="button" id="delete" className="link-btn clear-file" onClick={() => { deleteFile(key); }}>
                          <span className="icon-delete delete-icon-multi ms-4 font-20 "> </span>
                        </button>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </>
  );
};
export default AddNonSpecItem;
