export const formatSave = (data) => {
  const saveData = {};
  saveData.projectId = data.projectId;
  saveData.projectCurrency = data.projectCurrency;
  saveData.currencyId = data.currencyId;
  saveData.rfqData = data.rfqData;
  saveData.serviceLineData = [];
  data.sectionData.map((item) => {
    item.specDataList.map((subItem) => {
      subItem.serviceLineList.map((serv) => {
        saveData.serviceLineData.push({
          id: serv.id,
          specId: subItem.projectSpecId,
          quantity: serv.quantity || '0',
          rate: serv.rate || '0',
          discount: serv.discount || '0',
          amount: serv.amount || '0'
        });
        return null;
      });
      return null;
    });
    return null;
  });
  return saveData;
};
