import React from 'react';
import Card from './card';

const ReportView = (props) => {
  const { onAddNew, setSearchText, searchText, onSearch, allData,
    onDeleteAttachment, isTSI, projectBasicDetails, isVesselUser } = props;
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-3">
          <div className="search large spec-search col-2">
            <span
              className={searchText ? ' icon-close-line ' : ''}
              onClick={() => { setSearchText(''); document.getElementById('searchs')?.focus(); }}
            />
            <span
              className="icon-search"
              id="Searching"
              onClick={() => {
                onSearch({ key: 'Enter' });
              }}
            />
            <input
              type="text"
              placeholder="Search by Name, Description"
              id="search"
              className="text-box large with-close"
              value={searchText}
              onChange={(e) => { setSearchText(e.target.value); }}
              onKeyPress={onSearch}
            />
          </div>
        </div>
        <div><button type="button" disabled={!(isTSI || isVesselUser) || projectBasicDetails?.isClosed} id="addnew" className="primary-btn" onClick={() => { onAddNew(); }}>Add New</button></div>
      </div>
      {allData.length < 1 && <div className="d-flex justify-content-center mt-3 opacity-6">No Records Found</div>}
      {allData.map((item) => {
        return <Card item={item} onDeleteAttachment={onDeleteAttachment} isTSI={isTSI} />;
      })}
    </>
  );
};
export default ReportView;
