import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  GetKfpList,
  SaveKfp,
  GetKfpDetails,
  DeleteKfp,
  PublishKfpList,
  GetUnitOfMeasuresList,
  GenerateTemplateKFP,
  BulkUploadKFP,
  exportKfpExcel
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getKfp = (payload) => {
  return serviceHandler({
    url: `${GetKfpList}`,
    methordType: 'post',
    payload
  });
};
export const getKfpDetails = (payload) => {
  return serviceHandler({
    url: `${GetKfpDetails}`,
    methordType: 'get',
    payload
  });
};
export const saveKfp = (payload) => {
  return serviceHandler({
    url: `${SaveKfp}`,
    methordType: 'post',
    payload
  });
};
export const deleteKfp = (payload) => {
  return serviceHandler({
    url: `${DeleteKfp}?id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const publishKfpList = (payload) => {
  return serviceHandler({
    url: `${PublishKfpList}`,
    methordType: 'put',
    payload
  });
};
export const getUomList = (payload) => {
  return serviceHandler({
    url: `${GetUnitOfMeasuresList}`,
    methordType: 'post',
    payload
  });
};

export const exportExcelKfp = (searchText) => {
  return axios
    .get(`${exportKfpExcel}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `KFP_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const bulkUploadKFP = (payload) => {
  return serviceHandler({
    url: `${BulkUploadKFP}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateKFP}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `KFP_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
