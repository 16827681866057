import React from 'react';
import Library from './library';
import NewSpecCard from './newSpecCard';
import PackageCard from './packageCard';
import ImportLibrary from './importLibrary';

const SpecListing = (props) => {
  const { cardDetails, handleEyeView, searchText, setSearchText, onSearch, isVesselUser,
    setCloseModal, isTsiDisabled } = props;

  return (
    <>
      {!isVesselUser && (
        <div className="p-3 bg-white spec-library-title-height" data-testid="addspec-list">
          <NewSpecCard {...props} cardDetails={cardDetails[0]} />
          <PackageCard {...props} cardDetails={cardDetails[1]} />
          <div className="spec-import-excel border-dot mt-3">
            <span className="ms-2 font-semibold">Import Specs from another project</span>
            <button
              type="button"
              className="secondary-btn large ms-auto col-4"
              id="spec-download"
              disabled={isTsiDisabled}
              onClick={() => {
                setCloseModal(true);
              }}
            >
              Import Specs (Excel)
            </button>
          </div>
        </div>
      )}
      <div className="border-down" />
      <Library
        cardDetails={cardDetails[2]}
        handleEyeView={handleEyeView}
        searchText={searchText}
        setSearchText={setSearchText}
        onSearch={onSearch}
      />
      <ImportLibrary
        cardDetails={cardDetails[2]}
        importedProjects={cardDetails[3]}
        handleEyeView={handleEyeView}
      />
    </>
  );
};
export default SpecListing;
