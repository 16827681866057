import React, { useState } from 'react';
import moment from 'moment';
import ModalBox from '../../../../../common/ModalBox';
import image2 from '../../../../../../images/user-placeholder.jpg';
import tiff from '../../../../../../images/tiff.png';

const Activites = (props) => {
  const { item, onDelete, isCompleted = false, isTSI } = props;
  const [preview, setPreview] = useState('');
  return (
    <div>
      <div className="card p-4 mt-4" data-testid="activity">
        <div className="d-flex justify-content-end">
          <div className="pb-2 font-semibold me-auto">{item.imageName}</div>
          {!isCompleted && isTSI && <span className="icon-delete font-24 pointer brand-color me-4" id="delete" onClick={() => onDelete(item)} />}
        </div>
        <div className="pb-3 font-13">{moment.utc(item.updatedAt).format('DD-MMM-YYYY HH:mm (UTC)')}</div>
        <div className="gimage_wrap">
          {item.imageUrlList.map((list) => {
            return (
              <div key={list.imageUrl} className="gimage_view">
                {list.imageUrl.includes('.tiff') || list.imageUrl.includes('.tif')
                  ? (
                    <a href={list.imageUrl} download="downloaded_file" className="" rel="noopener noreferrer">
                      <img src={tiff} alt="" />
                    </a>
                  )
                  : <img alt="pic 1" className="pe-3 pointer" src={list.imageUrl} onClick={() => { setPreview(list.imageUrl); }} />}
              </div>
            );
          })}
        </div>
        <div className="pb-3">{item.imageDescription}</div>
        <div className="d-flex justify-content-end align-items-center">
          <div><img src={item.userImage || image2} alt="" className="thmb-icon-xs" /></div>
          <div className="ms-2">{item.userName}</div>
        </div>
      </div>
      {preview
        && (
          <ModalBox
            modalClassName="large modal-scroll"
            heading="Preview"
            closeModal={() => setPreview(false)}
            buttonName=""
            removeClass={false}
          >
            <div className="d-flex justify-content-center h-full w-full">
              <a href={preview} target="_blank" rel="noreferrer" className="h-full w-full">
                <img src={preview} alt="" className="w-full" />
              </a>
            </div>
          </ModalBox>
        )}
    </div>
  );
};

export default Activites;
