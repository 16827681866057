import { serviceHandler } from '../../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../../utils/apiUrlConstants';

const {
  GetComponentListByProjectSpecUrl,
  SaveProjrctSpecComponentUrl,
  deleteSpecComponentUrl,
  GetSelectedComponentstByProjectSpecUrl
} = apiEndPoints;

export const GetComponentListByProjectSpec = (payload) => {
  return serviceHandler({
    url: `${GetComponentListByProjectSpecUrl}`,
    methordType: 'post',
    payload
  });
};

export const SaveProjrctSpecComponent = (payload, projectId, projectSpecificationId) => {
  return serviceHandler({
    url: `${SaveProjrctSpecComponentUrl}?projectId=${projectId}&SpecId=${projectSpecificationId}`,
    methordType: 'post',
    payload
  });
};
export const deleteSpecComponent = (payload) => {
  return serviceHandler({
    url: `${deleteSpecComponentUrl}?componentId=${payload.componentId}&specId=${payload.specId}`,
    methordType: 'put',
    payload
  });
};

export const GetSelectedComponentstByProjectSpec = (projectId, projectSpecificationId) => {
  return serviceHandler({
    url: `${GetSelectedComponentstByProjectSpecUrl}?projectId=${projectId}&specId=${projectSpecificationId}`,
    methordType: 'get'
  });
};
