import React from 'react';
import Loader from '../../../common/loader';
import PohaCard from './pohaCard';

const CardWrapper = (props) => {
  const { purchaseOrder, specList, loading, selectedCard, acCodeList, saveSpecOrder,
    onSelected, onCancel, setSelectedCard, setSpecOrderDeets, isTsiDisabled,
    specOrderDeets, currency, isTSI, projInfo, isVesselUser, projectBasicDetails,
    getPurchaseOrder } = props;
  if (loading) {
    return <Loader />;
  } if (purchaseOrder?.items?.length > 0) {
    return (
      purchaseOrder?.items?.map((item) => {
        return (
          <PohaCard
            item={item}
            specList={specList}
            selectedCard={selectedCard}
            acCodeList={acCodeList}
            currency={currency}
            isTsiDisabled={isTsiDisabled}
            saveSpecOrder={saveSpecOrder}
            onSelected={onSelected}
            onCancel={onCancel}
            setSelectedCard={setSelectedCard}
            setSpecOrderDeets={setSpecOrderDeets}
            specOrderDeets={specOrderDeets}
            isTSI={isTSI}
            projInfo={projInfo}
            isVesselUser={isVesselUser}
            projectBasicDetails={projectBasicDetails}
            getPurchaseOrder={getPurchaseOrder}
          />
        );
      })
    );
  }
  return (
    <div data-testid="card-wrapper" className="mt-3 p-4 d-flex justify-content-center"> No data found</div>
  );
};

export default CardWrapper;
