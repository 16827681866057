/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import GtSpecCard from './gtSpecCard';
import MajorSpecCard from './majorSpecCard';
import SpecLibrary from './SpecLibrary';

const SpecContent = (props) => {
  const { gtSpecData, mtSpecData, visibleSpec, setVisibleSpec,
    setSelectedSpec, selectedSpec, onDeleteSpec, onEditSpec, addNewSpec, closedSection,
    setSelectedSpecType, showModal, setShowModal, onCardSelect, onAccordianClose,
    selectedCard, prevProjItems, prevListInView, setPrevListInView,
    setPrevProjItems, getPrevProjectSpecList, projectId, onLibrarySave, isTsiDisabled, isTSI,
    setSelectedStatus, onReload, handleChange, arrayList, showContent, createDraft,
    projInfo, isVesselUser, replaceDraft, gtEditSpec } = props;
  const [showDataGT, setShowDataGT] = useState(true);
  const [showDataMS, setShowDataMS] = useState(true);
  const changeVisibility = (key) => {
    const visibility = [...visibleSpec];
    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }
    setVisibleSpec(visibility);
  };

  const onSpecSelect = (field, type) => {
    setSelectedSpec(field);
    setSelectedSpecType(type);
  };
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="d-flex p-4 justify-content-between">
        <div className="d-flex justify-content-center align-items-center spec-responsive">
          <div className="me-3">
            <div className="check-box">
              <input type="checkbox" id="GeneralTariff" name="" value="GeneralTariff" checked={visibleSpec.indexOf('gt') > -1} onClick={() => { changeVisibility('gt'); }} />
              <label htmlFor="GeneralTariff"> General Tariff</label>
            </div>

          </div>
          <div className="check-box">
            <input type="checkbox" id="MajorSpec" name="" value="MajorSpec" checked={visibleSpec.indexOf('mt') > -1} onClick={() => { changeVisibility('mt'); }} />
            <label htmlFor="MajorSpec"> Major Spec</label>
          </div>

        </div>
        {!isTSI && (
          <div>
            <button data-test="click" type="button" className="secondary-btn small p-3" onClick={() => setShowModal(true)}>
              Spec Library
            </button>
          </div>
        )}
      </div>
      <div className="spec-list side px-4">
        <div className="scroll-area">
          {visibleSpec.indexOf('gt') > -1 && (
            <GtSpecCard
              setSelectedStatus={setSelectedStatus}
              gtSpecData={gtSpecData}
              onSpecSelect={onSpecSelect}
              selectedSpec={selectedSpec}
              onEditSpec={onEditSpec}
              onAccordianClose={onAccordianClose}
              closedSection={closedSection}
              createDraft={createDraft}
              projInfo={projInfo}
              gtEditSpec={gtEditSpec}
              isTsiDisabled={isTsiDisabled}
              isVesselUser={isVesselUser}
              onDeleteSpec={onDeleteSpec}
              replaceDraft={replaceDraft}
              showDataGT={showDataGT}
              setShowDataGT={setShowDataGT}
            />
          )}
          {visibleSpec.indexOf('mt') > -1 && (
            <MajorSpecCard
              setSelectedStatus={setSelectedStatus}
              mtSpecData={mtSpecData}
              onSpecSelect={onSpecSelect}
              selectedSpec={selectedSpec}
              onDeleteSpec={onDeleteSpec}
              onEditSpec={onEditSpec}
              addNewSpec={addNewSpec}
              onAccordianClose={onAccordianClose}
              closedSection={closedSection}
              isTsiDisabled={isTsiDisabled}
              handleChange={handleChange}
              arrayList={arrayList}
              showContent={showContent}
              createDraft={createDraft}
              projInfo={projInfo}
              isVesselUser={isVesselUser}
              replaceDraft={replaceDraft}
              showDataMS={showDataMS}
              setShowDataMS={setShowDataMS}
            />
          )}
        </div>
      </div>
      <SpecLibrary
        onClose={onClose}
        showView={showModal}
        onSave={onLibrarySave}
        isTsiDisabled={isTsiDisabled}
        onCardSelect={onCardSelect}
        selectedCard={selectedCard}
        projectId={projectId}
        onReload={onReload}
        isTSI={isTSI}
        prevListInView={prevListInView}
        setPrevListInView={setPrevListInView}
        prevProjItems={prevProjItems}
        setPrevProjItems={setPrevProjItems}
        getPrevProjectSpecList={getPrevProjectSpecList}
      />

    </>
  );
};
export default SpecContent;
