import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const NonSpecChildContent = (props) => {
  const { gtSpecData, setSelectedSpec, selectedSpec, closedSection, onAccordianClose } = props;
  return (
    <>
      {gtSpecData && gtSpecData.length ? (
        <div className="border mt-4 p-3 md-radius" data-testid="non-spec-child">
          {gtSpecData?.map((item) => {
            return (
              <div key={item.id}>
                <div className="collapse-head d-flex justify-content-between mt-1">
                  <div
                    className="d-flex align-items-center pointer"
                    data-test="click"
                    id={item.id}
                    onClick={() => {
                      onAccordianClose(item.id);
                      setSelectedSpec(item);
                    }}
                  >
                    {closedSection.indexOf(item.id) < 0 ? (
                      <span className="icon-arrow-thin-right font-16 me-3 brand-color" />
                    ) : (
                      <span className="icon-arrow-thin-down font-10 me-3 brand-color" />
                    )}
                    <span
                      className="count me-2"
                      data-tooltip-id="project-title"
                      data-tooltip-content={item.specificationNo}
                    >
                      {_.truncate(item.specificationNo, { length: 8 })}
                    </span>
                    <span
                      data-tooltip-id="project-nonspec"
                      data-tooltip-content={item.title}
                    >
                      {_.truncate(item.title, { length: 25 })}
                    </span>
                    <Tooltip id="project-nonspec" place="top" className="tooltip mt-3" />
                  </div>
                </div>
                {closedSection.indexOf(item.id) > -1 && (
                <div className="collapse-body">
                  {item?.specLines?.map((field) => {
                    return (
                      <>
                        <div
                          key={field.id}
                          className={field.id === selectedSpec.id ? 'spec-item active pointer d-flex' : 'spec-item pointer d-flex'}
                          id={field.id}
                        >
                          <div
                            className=""
                            data-tooltip-id="project-title"
                            data-tooltip-content={field.specificationNo}
                          >
                            {_.truncate(field.specificationNo, { length: 8 })}
                          </div>
                          <span
                            data-tooltip-id="project-nonSpecItem"
                            data-tooltip-content={field.nonSpecItem}
                            className="ms-4"
                          >
                            {_.truncate(field.nonSpecItem, { length: 38 })}
                          </span>
                          <Tooltip id="project-nonSpecItem" place="top" className="tooltip mt-3" />
                        </div>

                      </>
                    );
                  })}
                </div>
                )}
              </div>
            );
          })}
        </div>
      )
        : <div className="card mt-3 p-4 bg-white d-flex justify-content-center">No data found</div>}
    </>
  );
};
export default NonSpecChildContent;
