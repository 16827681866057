/* istanbul ignore file */
import React from 'react';
import Loader from '../../../../common/loader';
import TableView from './tableView';

const filterList = [
  { label: 'All', key: 'all', value: 6 },
  { label: 'Plan', key: 'plan', value: 1 },
  { label: 'Specify', key: 'specify', value: 2 },
  { label: 'Tender', key: 'tender', value: 3 },
  { label: 'Repair', key: 'repair', value: 4 },
  { label: 'Close', key: 'close', value: 5 }
];

const TimelinePage = (props) => {
  const { groupsList, phaseId, updatePhaseId, loading, taskList,
    onOpenTask, openedList, statusChange, editMicro, deleteMicro, projDetails } = props;

  if (loading) {
    return <Loader />;
  }
  return (
    <div className=" card p-4">
      <div className="d-flex justify-content-end col-gap-1 font-13 mb-3 me-2">
        {filterList.map((fil) => {
          return (
            <div className=" pointer">
              <input
                type="radio"
                className="me-2 pointer"
                id={fil.key}
                name="filter"
                value={fil.value}
                checked={fil.value === phaseId}
                onChange={updatePhaseId}
              />
              <label htmlFor={fil.key} className="pointer font-semibold">
                {fil.label}
              </label>
            </div>
          );
        })}
      </div>
      {groupsList.length > 0 ? (
        <TableView
          taskList={taskList}
          onOpenTask={onOpenTask}
          openedList={openedList}
          statusChange={statusChange}
          editMicro={editMicro}
          deleteMicro={deleteMicro}
          projDetails={projDetails}
        />
      ) : (
        <div className="d-flex justify-content-center my-2 gray">No task available</div>
      )}
    </div>
  );
};
export default TimelinePage;
