import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
// import { cloneDeep } from 'lodash';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Loader from '../../../../common/loader';

import { getWOReport, wOReportDownload } from '../services';
// import { pieChartConfig } from '../../PhasePage/Repair/SpecComponents/constant';
import WoReportTable from './WoReportTable';
import helper from '../../../../../utils/helper';
import { repairDates } from '../../PhasePage/Repair/services';

const WoReport = () => {
  const statusList = [
    { label: 'Open', value: 'open' },
    { label: 'Completed', value: 'completed' },
    { label: 'On Hold', value: 'hold' },
    { label: 'Deferred To Next DD', value: 'deferred' }
  ];
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  // const [jobDoneByValue, setJobDoneByValue] = useState(0);
  const [repairStartDate, setRepairStartDate] = useState('');
  const [repairEndDate, setRepairEndDate] = useState('');
  const [tableData, setTableData] = useState({
    projectId: '',
    repairStartDate: '',
    repairEndDate: '',
    items: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { woReportConstants } = helper;
  const getRepairDates = () => {
    const payload = {
      projectId: id
    };
    repairDates(payload).then((res) => {
      setRepairStartDate(res?.data?.repairStartDate);
      setRepairEndDate(res?.data?.repairEndDate);
      setLoading(false);
    });
  };
  const reloadTable = (statusItem) => {
    const payload = {
      page,
      pageSize,
      search: '',
      sortProperty: 'asc',
      isDescending: true,
      projectId: id,
      status: statusItem
    };
    getWOReport(payload).then((res) => {
      setTableData(res?.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    reloadTable('');
    getRepairDates();
  }, []);

  useEffect(() => {
    const statusValue1 = statusValue ? statusValue.value : '';
    // const jobDoneByValue1 = jobDoneByValue ? jobDoneByValue.value : 0;
    reloadTable(statusValue1);
  }, [statusValue, page, pageSize]);

  const isClearable = true;
  const getColor = (s) => {
    const sList = cloneDeep(woReportConstants);
    const f = sList.filter((i) => i.label === s);
    if (f.length) return f[0].color;
    return 'fade-color';
  };
  const downloadReport = () => {
    const statusValue1 = statusValue ? statusValue.value : '';
    // const jobDoneByValue1 = jobDoneByValue ? jobDoneByValue.value : 0;
    const payload = {
      projectId: id,
      status: statusValue1
      // jobDoneBy: jobDoneByValue1
    };
    wOReportDownload(payload);
  };
  return (
    <div data-testid="woProgressReport">
      <div className="align-items-center d-flex justify-content-between report-ctrl">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <div className="font-20 font-bold">Work Order/Defects Report</div>
            <div className="ms-3 couter-bubbles status-due ">{tableData?.totalItems}</div>
          </div>
          <div className="d-flex align-items-center me-5">
            <label>
              Repair Period
              <span className="ms-2">:</span>
            </label>
            &nbsp;
            <div className="value">
              {repairStartDate
                ? moment(repairStartDate).format('DD-MMM-yyyy') : ''}

            </div>
            <div className="mx-4 value"> - </div>
            <div className="value">
              {repairEndDate
                ? moment(repairEndDate).format('DD-MMM-yyyy') : ''}

            </div>
          </div>

          <div
            data-tooltip-id="project-supplementary"
            data-tooltip-content={statusValue?.label}
          >
            <Select
              id="woselect"
              className="modal-select mx-3 w-250"
              placeholder="Status"
              options={statusList}
              isClearable={isClearable}
              value={statusValue}
              onChange={(e) => {
                if (e) {
                  setStatusValue(e);
                } else {
                  setStatusValue('');
                }
              }}
            />
          </div>
        </div>
        {/* <div>
          <button type="button" className="secondary-btn">
            Print/Share
          </button>
        </div> */}
      </div>
      <div className="report-canvas mt-4">
        {loading ? (
          <Loader />
        ) : (
          <WoReportTable
            tableData={tableData}
            getColor={getColor}
            setPage={setPage}
            setPageSize={setPageSize}
            page={page}
            pageSize={pageSize}
            downloadReport={downloadReport}
          />
        )}
      </div>
      <Tooltip id="project-supplementary" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default WoReport;
