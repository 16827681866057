import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import TableLoader from '../../../common/TableLoader';
import TableSortHeader from '../../../common/TableSortHeader';

const TagsTable = (props) => {
  const { tableData, deteleData, editData, loading, handleSort, sortData } = props;
  const column = [
    {
      Header: () => {
        return (
          <TableSortHeader
            heading="Tag Name"
            headingProperty="name"
            id="header-id"
            data={sortData}
            handleSort={handleSort}
          />
        );
      },
      accessor: 'name',
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { value } = row;
        console.log(value, 'value');
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-value"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: 'packageName',
      width: 120,
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <div className="d-flex">
            <div>
              <button
                type="button"
                className="link-btn"
                // disabled={original.isMapped}
                onClick={() => {
                  editData(original);
                }}
              >
                <span className="icon-edit icon-btn  brand-color font-24" />
              </button>
            </div>
            {/* <Tooltip id="project-value1" place="top" className="tooltip" /> */}

            <div
              data-tooltip-id="project-values"
              data-tooltip-content={original?.mappedMessage}
            >
              <button
                type="button"
                className="link-btn"
                disabled={original.isMapped}
                onClick={() => {
                  deteleData(original);
                }}
              >
                <span className="icon-delete icon-btn  brand-color font-24" />
              </button>
            </div>
            <Tooltip id="project-values" place="top" className="tooltip" />

          </div>
        );
      }
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="table-tags">
      <ReactTable
        loading={loading}
        LoadingComponent={TableLoader}
        className="table table-scroll"
        data={tableData?.items}
        pageSize={tableData?.items?.length}
        columns={column}
        minRows={0}
        showPagination={false}
      />
      <Tooltip id="project-value" place="top" className="tooltip mt-4" />
      <Tooltip id="project-value1" place="top" className="tooltip" />

    </div>
  );
};
export default TagsTable;
