/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { cloneDeep, round } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';
import SectionsSubTable from './SectionsSubTable';
import helper from '../../../../../../utils/helper';
import OtherCostTable from './OtherCostTable';
import DeviationCost from './DeviationCost';

const YardCostSummary = ({ repairCostSummary, allData, projectBasicDetails, renderVariance, renderVariancePercentage }) => {
  const { formatNumber } = helper;
  const [expanded, setExpanded] = useState([]);
  const [expandedSpec, setExpandedSpec] = useState([]);
  const [activeSect, setActiveSect] = useState();
  const [activeSpec, setActiveSpec] = useState();
  const [isShowPopup, setisShowPopup] = useState();

  const renderCell = (row, field, type) => {
    const { original, index } = row;
    // if (activeSect === index && type === 'section') {
    //   return <div className="white">-</div>;
    // }
    return (
      <span
        data-tooltip-id="project-specNames"
        data-tooltip-content={`${projectBasicDetails?.currencySymbol} ${original[field] ? formatNumber(original[field]) : formatNumber(0)}`}
      >
        {projectBasicDetails?.currencySymbol}
        {' '}
        {original[field] ? formatNumber(original[field]) : formatNumber(0)}
      </span>
    );
  };
  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: true,
      resizable: false,
      width: 40,
      Expander: ({ isExpanded, index }) => (isExpanded ? (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(repairCostSummary);
            const expandedRows = quotesCopy?.map(() => {
              return false;
            });
            setExpanded(expandedRows);
            setActiveSect();
            setExpandedSpec([]);
          }}
          className="font-13 icon-arrow-thin-right circle-arrow circle-arrow-small rotate-90 "
        />
      ) : (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(repairCostSummary);
            const expandedRows = quotesCopy?.map((data, key) => {
              if (index === key) {
                setActiveSect(index);
                return true;
              }
              return false;
            });
            setExpanded(expandedRows);
            setExpandedSpec([]);
          }}
          className="font-13 icon-arrow-thin-right circle-arrow circle-arrow-small"
        />
      ))
    },
    {
      Header: '',
      sortable: false,
      expander: false,
      width: 80,
      resizable: false,
      accessor: 'number',
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specNamed" data-tooltip-content={original.number} className="text-ellipsis">
            {original.number}
          </span>
        );
      }
    },
    {
      Header: '',
      sortable: false,
      expander: false,
      width: 140,
      resizable: false,
      accessor: 'specName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specNamed" data-tooltip-content={original.specName} className="text-ellipsis">
            {original.specName}
          </span>
        );
      }
    },
    {
      Header: 'Yard Est',
      width: 110,
      accessor: 'yardEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(251 244 241)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'yardEstimate', 'section');
      }
    },
    {
      Header: 'Owner Est',
      width: 110,
      accessor: 'ownerEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(251 244 241)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'ownerEstimate', 'section');
      }
    },
    {
      Header: 'Project Est',
      width: 110,
      accessor: 'projectEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(251 244 241)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'projectEstimate', 'section');
      }
    },
    {
      Header: 'yard Actual',
      width: 110,
      accessor: 'yardActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(227 244 253)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'yardActual', 'section');
      }
    },
    {
      Header: 'owner Actual',
      width: 140,
      accessor: 'ownerActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(227 244 253)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'ownerActual', 'section');
      }
    },
    {
      Header: 'project Actual',
      width: 140,
      accessor: 'projectActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(227 244 253)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'projectActual', 'section');
      }
    },
    {
      Header: 'variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.projectEstimate, original.projectActual, row);
      }
    },
    {
      Header: 'variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.projectEstimate, original.projectActual, row);
      }
    }
  ];
  /* istanbul ignore next */
  const columns2 = [
    {
      Header: '',
      sortable: false,
      expander: false,
      width: 100,
      resizable: false,
      accessor: 'testaccessor'
    },
    {
      Header: '',
      sortable: false,
      expander: false,
      width: 160,
      resizable: false,
      accessor: 'specName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specNamed" data-tooltip-content={original.specName} className="text-ellipsis">
            {original.specName}
          </span>
        );
      }
    },
    {
      Header: 'Yard Est',
      width: 110,
      accessor: 'yardEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(251 244 241)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'yardEstimate');
      }
    },
    {
      Header: 'Owner Est',
      width: 110,
      accessor: 'ownerEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(251 244 241)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'ownerEstimate');
      }
    },
    {
      Header: 'Project Est',
      width: 110,
      accessor: 'projectEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(251 244 241)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'projectEstimate');
      }
    },
    {
      Header: 'yard Actual',
      width: 110,
      accessor: 'yardActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(227 244 253)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'yardActual');
      }
    },
    {
      Header: 'owner Actual',
      width: 140,
      accessor: 'ownerActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(227 244 253)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'ownerActual');
      }
    },
    {
      Header: 'project Actual',
      width: 140,
      accessor: 'projectActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right', backgroundColor: 'rgb(227 244 253)', borderBottom: '1px solid #fff' },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'projectActual');
      }
    },
    {
      Header: 'variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.projectEstimate, original.projectActual, row);
      }
    },
    {
      Header: 'variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.projectEstimate, original.projectActual, row);
      }
    }
  ];
  const showPagination = false;
  const getToolTipContent = (content) => {
    let val = `${projectBasicDetails?.currencySymbol} ` || '';
    val = val?.concat(content ? formatNumber(content) : formatNumber(0));
    return val;
  };
  return (
    <div data-testid="YardCostSummary">
      <div className="card section-Card-table section-Card-table-rm-border">
        <div className="d-flex justify-content-between py-4 px-4">
          <div className="head-16">Non Spec Cost Details</div>
        </div>
        <ReactTable
          className=""
          data={repairCostSummary}
          columns={columns}
          pageSize={repairCostSummary && repairCostSummary.length > 0 ? repairCostSummary.length : 1}
          showPagination={showPagination}
          expanded={expanded}
          SubComponent={(mainProp) => {
            const { specs } = mainProp.original;
            /* istanbul ignore next */
            return (
              <ReactTable
                className=""
                data={specs || []}
                columns={columns2}
                pageSize={specs && specs?.length > 0 ? specs?.length : 1}
                showPagination={showPagination}
                TheadComponent={() => null}
              />
            );
          }}
        />
      </div>
      <div className="card my-3 section-Card-table">
        <div className="d-flex justify-content-between py-4 px-4">
          <div className="head-16 ">Other Costs</div>
        </div>
        <OtherCostTable
          repairCostSummary={allData}
          projectBasicDetails={projectBasicDetails}
          renderVariance={renderVariance}
          renderVariancePercentage={renderVariancePercentage}
        />
      </div>
      <div className="card my-3 section-Card-table">
        <div className="d-flex justify-content-between py-4 px-4 border-down">
          <div className="head-16 ">Cost summary</div>
        </div>
        <div className="row p-4 ">
          <div className="col-6">
            <div className="row mt-3">
              <div className="align-items-md-baseline col-5 d-flex text-content-grey">
                YARD COST WITHOUT DISCOUNT
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="YARD COST WITHOUT DISCOUNT" />

              </div>
              <div
                className="col-7 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(round(allData?.yardTotal))}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.yardTotal ? formatNumber(round(allData?.yardTotal)) : formatNumber(0)}
                </span>

              </div>
            </div>

            <div className="row mt-3">
              <div className="align-items-md-baseline col-5 d-flex text-content-grey">
                Overall Discount(%)
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="Overall Discount(%)" />
              </div>
              <div
                className="col-7 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={allData?.discountPercentage}
                >
                  {allData?.discountPercentage}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-5 d-flex text-content-grey">
                Additional Discount
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="Additional Discount" />
              </div>
              <div
                className="col-7 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(allData?.additionalDiscount)}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.additionalDiscount ? formatNumber(allData?.additionalDiscount) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-5 d-flex text-content-grey">
                Yard Final Bill
                <span
                  className="brand-color icon-Info ms-1"
                  data-tooltip-id="project-specNamed"
                  data-tooltip-content="Yard Cost Estimate Without Discount - Overall Discount(%) - Additional Discount"
                />
              </div>
              <div
                className="col-7 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(round(allData?.yardFinalTotal))}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.yardFinalTotal ? formatNumber(round(allData?.yardFinalTotal)) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-5 d-flex text-content-grey">
                Deviation & Off Hire
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="Deviation & Off Hire" />
              </div>
              <div
                className="col-7 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(allData?.deviationOffhireCost)}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.deviationOffhireCost ? formatNumber(allData?.deviationOffhireCost) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="col-5 font-20 text-content-grey font-bold">
                Project Cost
                <span
                  className="brand-color icon-Info ms-1 font-14"
                  data-tooltip-id="project-specNamed"
                  data-tooltip-content="Yard Finall + Owner Cost + Deviation Cost + Offhire Cost"
                />
              </div>
              <div
                className="col-7 font-20 font-bold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(round(allData?.contract))}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.contract ? formatNumber(round(allData?.contract)) : formatNumber(0)}
                </span>

              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row mt-3">
              <div className="align-items-md-baseline col-4 d-flex text-content-grey">
                Owner&apos;s Cost
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="Owner Cost" />
              </div>
              <div
                className="col-7 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(round(allData?.ownerCost))}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.ownerCost ? formatNumber(round(allData?.ownerCost)) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-4 d-flex text-content-grey">
                Deviation Cost
                <span
                  className="brand-color icon-Info ms-1"
                  data-tooltip-id="project-specNamed"
                  data-tooltip-content="Deviation actual in other cost"
                />
              </div>
              <div
                className="col-8 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(allData?.deviationCost)}
                >
                  {allData?.deviationCost < 0 ? '-' : ''}
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.deviationCost ? formatNumber(Math.abs(allData?.deviationCost)) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-4 d-flex text-content-grey">
                Off Hire Cost
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="Off Hire Cost" />
              </div>
              <div
                className="col-8 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(allData?.offhireCost)}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.offhireCost ? formatNumber(allData?.offhireCost) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-4 d-flex text-content-grey">
                Supplementary specs cost
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content=" Supplementary specs cost" />
              </div>
              <div
                className="col-8 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(allData?.supplementarySpecCost)}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.supplementarySpecCost ? formatNumber(allData?.supplementarySpecCost) : formatNumber(0)}
                </span>

              </div>
            </div>
            <div className="row mt-3">
              <div className="align-items-md-baseline col-4 d-flex text-content-grey">
                Variation specs cost
                <span className="brand-color icon-Info ms-1" data-tooltip-id="project-specNamed" data-tooltip-content="Variation specs cost" />
              </div>
              <div
                className="col-8 font-semibold text-ellipsis"
              >
                <span
                  data-tooltip-id="project-yardcount"
                  data-tooltip-content={getToolTipContent(allData?.variationSpecCost)}
                >
                  {projectBasicDetails?.currencySymbol}
                  {' '}
                  {allData?.variationSpecCost ? formatNumber(allData?.variationSpecCost) : formatNumber(0)}
                </span>

              </div>
            </div>
          </div>
        </div>
        <Tooltip id="project-specNamed" place="bottom" className="tooltip" />
        <Tooltip id="project-yardcount" place="top" className="tooltip mt-2" />
      </div>
    </div>
  );
};

export default YardCostSummary;
