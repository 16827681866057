/* istanbul ignore file */
import React from 'react';

const Loader = () => {
  return (
    <div className="content-loader">
      <div className="loader" />
      <span className="text">Loading, please wait...</span>
    </div>
  );
};
export default Loader;
