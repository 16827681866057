import React, { useState } from 'react';
import Select from 'react-select';
import ContentHeader from './contentHeader';
import ContentFooter from './contentFooter';
import QuotationTable from './quotationTable';
import { downloadDocuments } from '../service';
import ModalBox from '../../../common/ModalBox';
import Addattachment from '../../../TSI/Projects/PhasePage/Tender/editQuote/Addattachment';
import DownloadConfirmModal from '../DownloadConfirmationModal';

const ContentWrap = (props) => {
  const { allCurrency, specList, setSpecList, onSave, isOpenModal, setIsOpenModal,
    params, downloadQuote, isDownloadQuote } = props;
  console.log('specList', specList);
  const [isDownLoading, setIsDownLoading] = useState(false);
  const [downloadConfirmModal, setDownloadConfirmModal] = useState('');
  const onCurrencyChange = (e) => {
    const currentSpecList = { ...specList };
    currentSpecList.rfqData.currencyId = e.value;
    currentSpecList.rfqData.currencySymbol = e.currencySymbol;
    setSpecList(currentSpecList);
  };
  const downloadDoc = () => {
    setDownloadConfirmModal(true);
    // setIsDownLoading(true);
    // downloadDocuments({ key: params.key, isManual: false }).then(() => {
    //   setIsDownLoading(false);
    // });
  };
  const handleContinueDownload = () => {
    setIsDownLoading(true);
    downloadDocuments({ key: params.key, isManual: false }).then(() => {
      setIsDownLoading(false);
    });
    setDownloadConfirmModal(false);
  };
  return (
    <>
      <div>
        <div className="py-3 d-flex justify-content-between align-items-center">
          <h3 className="font-22">Yard Quotation</h3>
          <div className="d-flex">
            <button type="button" className="primary-btn mr-10" disabled={specList.rfqData?.status === 4} onClick={() => setIsOpenModal(true)}>Upload Yard T&C</button>
            <button
              type="button"
              className="primary-btn mr-10"
              disabled={isDownloadQuote
                || (specList?.rfqData?.statusFailed)
                || (specList && specList?.rfqData && specList?.rfqData?.status !== 4)}
              onClick={downloadQuote}
            >
              {isDownloadQuote ? 'Downloading' : 'Download Quote'}
            </button>
            <button type="button" className="primary-btn" disabled={specList.rfqData?.status === 4 || isDownLoading} onClick={downloadDoc}>{isDownLoading ? 'Requesting' : 'Attachments'}</button>
          </div>
        </div>
        <ContentHeader {...props} />
        <div className="my-2 d-flex justify-content-end">
          <Select
            className="w-250"
            id="currency"
            options={allCurrency}
            onChange={(e) => { onCurrencyChange(e); }}
            value={allCurrency?.filter((item) => item
              .value === specList.rfqData?.currencyId)[0]}
          />
        </div>
        <QuotationTable {...props} />
        <ContentFooter {...props} />
        {isOpenModal ? (
          <ModalBox
            modalClassName="medium"
            onSave={() => onSave()}
            closeModal={() => setIsOpenModal(false)}
            heading="Upload yard terms and conditions"
            buttonName="Upload"
          >
            <Addattachment specList={specList} setSpecList={setSpecList} isFromYard="yard" params={params} />
          </ModalBox>
        ) : (
          ''
        )}
      </div>
      {downloadConfirmModal && (
      <DownloadConfirmModal
        setDownloadConfirmModal={setDownloadConfirmModal}
        handleContinueInDownload={handleContinueDownload}
        // isDownload={isDownload}
      />
      )}
    </>
  );
};
export default ContentWrap;
