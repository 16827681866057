import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';

const {
  ClosedProjectDeferredSpecList
} = apiEndPoints;

export const closedProjectDeferredSpecList = (payload) => {
  return serviceHandler({
    url: `${ClosedProjectDeferredSpecList}`,
    methordType: 'post',
    payload
  });
};
