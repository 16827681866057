/* eslint-disable react/jsx-indent-props */
/* eslint-disable */
import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import SidePanel from '../../../../../common/sidePanel';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Gallery from './Gallery';
import ModalBox from '../../../../../common/ModalBox';
import JobDoneReport from '../JobDoneReport';
import UpdateCostModal from './UpdateCostModal';
import ViewMore from '../viewMore';
import DatePicker from '../SpecComponents/datePicker';
import { assigneeCode, SelectDropDown, SelectDropDownVesselUser } from '../SpecComponents/constant';
import helper from '../../../../../../utils/helper';
import { jobNameMap } from '../SpecComponents/constant';
import SpecReason from './SpecReason';
import SpecReasonLevel from './SpecReasonLevel';
import showAlert from '../../../../../../utils/alert';

const SpecCard = (props) => {
  const {
    projectId,
    projectCurrecySymbol,
    specList,
    mainSec,
    showsubSec,
    onSecOpen,
    setSpecList,
    showUpdateAssignee,
    showUpdateRole,
    onSpecStatusChange,
    onDateUpDate,
    onReloadSpecData,
    handleCancel,
    onProgresChange,
    isTSI,
    isCompleted,
    projectBasicDetails,
    setOpenModal,
    setServiceData
  } = props;

  const { roles } = helper;
  const [showGallery, setShowGallery] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = userRole === roles.VESSEL_USER;
  const isAuthor = userRole === roles.AUTHOR;

  const showMoreSpec = (spec) => {
    setViewMore(spec);
  };

  const onCloseViewMore = () => {
    setViewMore(false);
  };
  const [newCost, setNewCost] = useState(false);
  const [jobModal, setJobModal] = useState(false);
  const [jobDeleteModal, setJobDeleteModal] = useState(false);
  const [updateCostModal, setUpdateCostModal] = useState(false);

  const showGalleryDeets = (spec) => {
    setShowGallery(spec);
  };
  const onCloseGallery = () => {
    setShowGallery(false);
  };
  const hanldeClickJobReport = (job) => {
    setJobModal(job);
  };
  const hanldeClickUpdateJob = (serv, subsec) => {
    setUpdateCostModal({
      specData: subsec,
      serviceLineData: serv,
    });
    setNewCost(false);
  };
  const onCloseWindow = () => {
    setUpdateCostModal(false);
  };

  const addNewCost = () => {
    setNewCost(true);
  };
  return (
    <div className="spc-map" data-testid="specCard">
      <div className="d-flex border-down py-2 ps-3">
        <div className="col-1 font-bold">SEC#</div>
        <div className="col-8 font-bold">SPEC SECTION</div>
        <div className="col-3 font-bold">COMMENTS</div>
      </div>
      {specList?.specQuoteList?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6">No data found</div>}
      {specList?.specQuoteList?.map((sec, mainIndex) => {
        return (
          <div className={sec?.projectSpecId === mainSec && showsubSec ? 'highlight-tender' : ''}>
            <div className="d-flex border-down py-2 ps-3">
              <div className="col-1 d-flex align-items-center">
                <span
                  id={sec.projectSpecId}
                  className={
                    sec.projectSpecId === mainSec
                      ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                      : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                  }
                  onClick={() => {
                    onSecOpen(sec.projectSpecId, true);
                  }}
                />
                <span data-tooltip-id="project-sectitle" data-tooltip-content={sec.specificationNo}>
                  {_.truncate(sec.specificationNo, { length: 7 })}
                </span>
              </div>
              <div className="col-8">
                <span data-tooltip-id="project-sectitle" data-tooltip-content={sec?.title}>
                  {_.truncate(sec.title, { length: 40 })}
                </span>
              </div>
              <Tooltip id="project-sectitle" place="top" className="tooltip mt-3" />
              <div className="col-3">
                <SpecReasonLevel mainKey={mainIndex} specList={specList} reload={onReloadSpecData} projectBasicDetails={projectBasicDetails} />
              </div>
            </div>
            <div className="respo-scroll">
              {sec.projectSpecId === mainSec &&
                sec.specDataList.map((subsec, subIndex) => {
                  return (
                    <>
                      <div className="d-flex py-2 ps-3 ">
                        <div className="col-1" />
                        <div className="col-11 d-flex align-items-center justify-content-between bg-sky-white ps-3">
                          <div className="d-flex align-items-center">
                            <span
                              id={subsec.projectSpecId}
                              className={
                                subsec.projectSpecId === showsubSec
                                  ? 'font-14 icon-sort-up brand-color pointer me-2 rotate-180'
                                  : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                              }
                              onClick={() => {
                                onSecOpen(subsec.projectSpecId, false);
                              }}
                            />
                            <span className="me-3 w-30p" data-tooltip-id="project-sectitle" data-tooltip-content={subsec.specificationNo}>
                              {_.truncate(subsec.specificationNo, { length: 5 })}
                            </span>
                            <div className="w-250">
                              <span data-tooltip-id="project-sectitle" data-tooltip-content={subsec.title}>
                                {_.truncate(subsec.title, { length: 25 })}
                              </span>
                            </div>
                            <div className="me-1 px-2 ms-3 d-flex align-items-center">
                              <span className="icon-woha font-20 opacity-6 me-2" />
                              <div className="couter-bubble bg-white width-fit me-2 py-3 px-1">
                                {subsec.workOrderClosedCount + '/' + subsec.workOrderTotalCount}
                              </div>
                              <div className="me-3 py-2" data-tooltip-id="project-sectitle" data-tooltip-content={jobNameMap[subsec.jobStatus]}>
                                <Select
                                  className="whitespace-pre w-130"
                                  options={SelectDropDown}
                                  menuPlacement="auto"
                                  isDisabled={(!isTSI && !isVesselUser) || isCompleted}
                                  classNamePrefix="repair-select"
                                  maxMenuHeight={200}
                                  value={SelectDropDown.filter((list) => list.value === subsec.jobStatus)[0]}
                                  onChange={(e) => {
                                    if (isAuthor) {
                                      onSpecStatusChange(e, mainIndex, subIndex);
                                    } else if (e.value < 2) {
                                      onSpecStatusChange(e, mainIndex, subIndex);
                                    } else {
                                      showAlert('Please contact your Vessel Supervisor to update the status of the specification.', 'error');
                                    }
                                  }}
                                />
                              </div>
                              <SpecReason
                                specList={specList}
                                mainKey={mainIndex}
                                subKey={subIndex}
                                reload={onReloadSpecData}
                                projectBasicDetails={projectBasicDetails}
                                reasons={subsec?.statusCommentList || []}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center h-full">
                            <div className="me-1 px-2 border-left d-flex align-items-center repair_dpick">
                              <span className="icon-clock me-2 font-26 opacity-8" />
                              <DatePicker
                                mainIndex={mainIndex}
                                subIndex={subIndex}
                                specList={specList}
                                setSpecList={setSpecList}
                                onDateUpDate={onDateUpDate}
                                isTSI={isTSI}
                                isCompleted={isCompleted}
                              />
                            </div>
                            <div
                              className="w-130 d-flex me-1 px-1 border-left font-13 font-semibold brand-color"
                              onClick={() => {
                                if (isTSI) {
                                  showUpdateAssignee(mainIndex, subIndex, subsec?.specAssignedUsers?.length);
                                }
                              }}
                            >
                              <div className={isTSI ? 'd-flex pointer' : 'disabled-field d-flex'}>
                                <div>+</div> <div className="ws-nowrap">Assign To</div>
                              </div>
                              {subsec?.specAssignedUsers ? (
                                <div className={isTSI ? 'avatar-sm ms-1 pointer' : 'avatar-sm ms-1'}>{assigneeCode[subsec?.specAssignedUsers]}</div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className="w-90 d-flex me-1 px-1 border-left brand-color font-13 font-semibold"
                              onClick={() => {
                                if (isTSI) {
                                  showUpdateRole(mainIndex, subIndex, subsec.specAssignedStaffName);
                                }
                              }}
                            >
                              <div
                                data-tooltip-id="project-sectitle"
                                data-tooltip-content="Supervisor"
                                className={isTSI ? 'd-flex pointer' : 'disabled-field d-flex'}
                              >
                                <span>+</span> <span>Supr</span>
                              </div>
                              {subsec.specAssignedStaffName && (
                                <div
                                  className={isTSI ? 'avatar-sm ms-1 pointer' : 'avatar-sm ms-1'}
                                  data-tooltip-id="project-sectitle"
                                  data-tooltip-content={subsec.specAssignedStaffName}
                                >
                                  <div className="icon" ><span className="icon-user-filled brand-color" /></div>
                                </div>
                              )}
                            </div>
                            <div className="h-full bg-brand-color me-1 d-flex align-items-center">
                              <span
                                id="showMoreSpecId"
                                className="icon-eye font-22 cursor-pointer px-3 white"
                                data-tooltip-id="project-sectitle"
                                data-tooltip-content="View More"
                                onClick={() => {
                                  showMoreSpec(subsec);
                                }}
                              />
                            </div>
                            <div className="h-full bg-brand-color d-flex align-items-center">
                              <span
                                id="showGalleryDeetsId"
                                className="icon-side-pannel font-22 cursor-pointer px-3 white"
                                data-tooltip-id="project-sectitle"
                                data-tooltip-content="Gallery"
                                onClick={() => {
                                  showGalleryDeets(subsec);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {subsec.projectSpecId === showsubSec &&
                        subsec.serviceLineList.map((serv, num) => {
                          return (
                            <>
                              <div className="">
                                {num === 0 && (
                                  <div className="d-flex ps-3 align-items-center font-semibold">
                                    <div className="col-1" />
                                    <div className="col-001 px-3 py-2 border-left border-down pb-2">Service LN#</div>
                                    <div className="col-4 px-3 py-2 border-down pb-2">Service Lines</div>
                                    <div className="col-001 px-3 py-2 border-down pb-2">AC Code</div>
                                    <div className="col-1 px-3 py-2 border-down pb-2">Qty</div>
                                    <div className="col-02 px-3 py-2 border-down pb-2">UOM</div>
                                    <div className="col-2 px-3 py-2 border-down pb-2 border-left border-right">Progress</div>
                                  </div>
                                )}
                                <div className="d-flex ps-3">
                                  <div className="col-1" />
                                  <div className="col-001 px-3 py-2 border-left border-down">{serv.number}</div>
                                  <div className="col-4 px-3 py-2 text-justify text-break-all border-down">
                                    <span data-tooltip-id="project-sectitle" data-tooltip-content={serv.serviceLineName}>
                                      {serv.serviceLineName}
                                    </span>
                                  </div>
                                  <div className="col-001 px-3 py-2 border-down d-flex justify-content-between pe-3">
                                    <span data-tooltip-id="project-sectitle" data-tooltip-content={serv.acCode}>
                                      {_.truncate(serv.acCode, { length: 10 })}
                                    </span>
                                  </div>
                                  <div className="col-1 px-3 py-2 border-down  text-ellipsis">
                                    <span data-tooltip-id="project-sectitle" data-tooltip-content={serv.quantity}>
                                      {_.truncate(serv.quantity, { length: 7 })}
                                    </span>
                                  </div>
                                  <div className="col-02 px-3 py-2 border-down d-flex justify-content-between pe-3 border-right ">
                                    <span data-tooltip-id="project-sectitle" data-tooltip-content={serv.uomName}>
                                      {_.truncate(serv.uomName, { length: 15 })}
                                    </span>
                                    {isTSI && !projectBasicDetails?.isClosed && (
                                      <div className="menudropdown mt-2 ms-auto">
                                        <span className="dropbtn font-18 icon-dotted-menu-vertical text-blue cursor-pointer" />
                                        <div className="menudropdown-content">
                                          {(projectBasicDetails?.phase < 5) &&
                                            <a
                                              id="update-cost"
                                              className="pointer"
                                              onClick={() => {
                                                hanldeClickUpdateJob(serv, subsec);
                                              }}
                                            >
                                              Update Cost
                                            </a>
                                          }
                                          {isVesselUser ? (
                                            ''
                                          ) : (
                                            <a
                                              id="update-cost"
                                              className="pointer"
                                              onClick={() => {
                                                console.log('hello', subsec);
                                                setServiceData(serv);
                                                setOpenModal(true);
                                              }}
                                            >
                                              Edit AC Code
                                            </a>
                                          )}
                                          {projectBasicDetails?.phase < 5 &&
                                            (serv.jobStatus === 3 ? (
                                              <a
                                                id="revert"
                                                className="pointer"
                                                onClick={() => {
                                                  handleCancel(0, mainIndex, subIndex, num);
                                                }}
                                              >
                                                Revert
                                              </a>
                                            ) : (
                                              <a
                                                id="canceled"
                                                className="pointer"
                                                onClick={() => {
                                                  handleCancel(3, mainIndex, subIndex, num);
                                                }}
                                              >
                                                Cancel
                                              </a>
                                            ))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {serv.jobStatus === 3 ? (
                                    <div className="col-2 ps-3 border-down d-flex align-items-center">Cancelled</div>
                                  ) : (
                                    <div className="col-2 px-3 border-down d-flex align-items-center border-right">
                                      <input
                                        id="range"
                                        className="col-9"
                                        type="range"
                                        disabled={!(isTSI || isVesselUser) || isCompleted}
                                        value={serv.specProgressBarValue}
                                        onChange={(e) => {
                                          onProgresChange(e, mainIndex, subIndex, num);
                                        }}
                                      />
                                      <span className="ps-1 opacity-8">{serv.specProgressBarValue}%</span>
                                    </div>
                                  )}
                                </div>
                                {subsec.serviceLineList.length == num + 1 && (
                                  <div className="d-flex ps-3 mb-4 align-items-center">
                                    <div className="col-1" />
                                    <div className="col-7 body-gray px-3 border-down font-13 py-3">
                                      <label> Last Updated : </label>{' '}
                                      <span className="value">
                                        {' '}
                                        {subsec.lastUpdatedBy}, {moment.utc(subsec.lastUpdatedAt).format('DD-MMM-YYYY (UTC)')}
                                      </span>
                                    </div>
                                    <div className="body-gray border-down py-3 px-3 col-4 text-right ">
                                      <button
                                        id="hanldeClickJobReportId"
                                        type="button"
                                        className="link-btn  pe-3"
                                        onClick={() => {
                                          hanldeClickJobReport(subsec);
                                        }}
                                      >
                                        Job Done Report
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                    </>
                  );
                })}
            </div>
          </div>
        );
      })}
      <SidePanel title="Job Progress Gallery" showView={showGallery} onClose={onCloseGallery} bgColor="bg-sky-white">
        {showGallery && <Gallery showGallery={showGallery} isCompleted={isCompleted} isTSI={isTSI} isVesselUser={isVesselUser} />}
      </SidePanel>
      {viewMore && (
        <SidePanel
          title={
            <span data-tooltip-id="project-more" data-tooltip-content={viewMore.title}>{`${viewMore.specificationNo}. ${_.truncate(viewMore.title, {
              length: 55,
            })}`}</span>
          }
          showView={viewMore}
          onClose={onCloseViewMore}
          bgColor="bg-white"
          size="large"
        >
          <Tooltip id="project-more" place="bottom" className="tooltip ms-5" />
          {viewMore && <ViewMore isTSI={isTSI} showMore={viewMore} projectCurrecySymbol={projectCurrecySymbol} isYard={false} id={projectId} />}
        </SidePanel>
      )}
      {jobModal && <JobDoneReport jobModal={jobModal} setJobModal={setJobModal} isTSI={isTSI} projectBasicDetails={projectBasicDetails} isVesselUser={isVesselUser} />}
      {jobDeleteModal ? <ModalBox modalClassName="medium" saveDisable closeIcon closeModal={() => setJobDeleteModal(true)} buttonName="Close" /> : ''}
      {updateCostModal && (
        <SidePanel
          title={
            <div className="d-flex  align-items-center">
              <div>Update Cost</div>
              {!newCost && (
                <div
                  type="button"
                  className="primary-btn ms-4"
                  onClick={() => {
                    addNewCost();
                  }}
                >
                  Add New Cost
                </div>
              )}
            </div>
          }
          bgColor="bg-sky-white"
          onClose={onCloseWindow}
          size="large"
          showView={updateCostModal}
        >
          <div className="ps-3 pe-3">
            <UpdateCostModal
              onReloadSpecData={onReloadSpecData}
              projectId={projectId}
              updateCostModal={updateCostModal}
              newCost={newCost}
              setNewCost={setNewCost}
            />
          </div>
          <footer className="side-panel__footer">
            <button type="button" className="primary-btn me-4" onClick={onCloseWindow}>
              Close
            </button>
          </footer>
        </SidePanel>
      )}
    </div>
  );
};
export default SpecCard;
