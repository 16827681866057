/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { toLower } from 'lodash';
import { fileImages } from '../../../common/mappingConst';
import { GetData } from './services';

const SystemDocumentContainer = ({ subFolders, projectFromId, folderActiveData }) => {
  const [active, setActive] = useState('');
  const [childFolders, setChildFolders] = useState([]);
  const handleActiveData = (item, open = '', activeItem) => {
    if (activeItem === item.id) {
      const inPut = {
        projectId: projectFromId,
        parentId: item.id,
        phase: folderActiveData.phase,
        sortProperty: 'UpdatedAt',
        isDescending: true,
        folderId: item?.folderId
        // isDescending: true,
        // parentId: '8b43d0a8-3c11-498e-8df9-5669fc136246',
        // phase: 0,
        // projectId: 'fda16a98-9e61-4781-bfbe-43cec17178bd',
        // sortProperty: 'UpdatedAt'
      };
      GetData(inPut).then((res) => {
        console.log('childFolders', res.data);
        setChildFolders(res.data);
        if (open === 'open') {
          setActive(item.id);
        }
      });
    }
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };
  return (
    <div className="" data-testid="dms-7">
      {subFolders && subFolders.length ? (
        <div className="scroll-pannel-system">
          {subFolders
            && subFolders.map((c) => {
              if (c.isFolder) {
                return (
                  <div className="card p-4 mb-3">
                    <div className=" d-flex justify-content-between ">
                      <div
                        data-testid="dco-container-child"
                        id="dco-container1"
                        onClick={() => {
                          if (active === c.id) setActive('');
                          else handleActiveData(c, 'open', c.id);
                        }}
                        className="cursor-pointer d-flex justify-content-start text-ellipsis c-w-300"
                      >
                        {active === c.id ? (
                          <span className="font-26 icon-folder-open me-4 icon-ind" />
                        ) : (
                          <span className="font-26 icon-folder me-4 icon-ind" />
                        )}
                        <span data-tooltip-id="project-system" data-tooltip-content={c.name} className="me-5 text-ellipsis">
                          {c.name}
                        </span>
                      </div>
                    </div>
                    {active === c.id ? (
                      <div className="mt-3">
                        {childFolders && childFolders.length ? (
                          <>
                            {childFolders.map((child) => {
                              return (
                                <div className="ms-5 mt-3 p-4 bg-white d-flex justify-content-between ">
                                  <div className="d-flex justify-content-start ">
                                    <div className="thmb-icon-md">
                                      <span className={fileImages[toLower(child?.document.type)]} />
                                      {/* <img src={fileImages[c?.document.type]}
                                    alt="" style={{ height: 24 }} /> */}
                                    </div>
                                    <div className="c-w-300 text-ellipsis">
                                      <span
                                        data-tooltip-id="project-system"
                                        data-tooltip-content={child.name}
                                        className="ms-3 text-ellipsis cursor-pointer"
                                      >
                                        {child.name}
                                      </span>
                                      {child?.document?.fname && (
                                        <div className="ms-3 text-ellipsis cursor-pointer">
                                          <span data-tooltip-id="project-system" data-tooltip-content={child?.document?.fname}>
                                            {' '}
                                            <label>Yard:</label>
                                            {' '}
                                            {child?.document?.fname}
                                          </span>
                                        </div>
                                      )}
                                      <div className="d-flex mt-1 ms-3">
                                        <div className="border-right pe-3">
                                          {moment.utc(child.document.auditInfo.createdAt).format('DD-MMM-YYYY HH:mm')}
                                        </div>
                                        <div className="border-right px-3">{formatBytes(child.document.size)}</div>
                                        <div className="px-3 text-uppercase text-ellipsis">
                                          <span data-tooltip-id="project-system" data-tooltip-content={child.document.createdBy}>
                                            {child.document.createdBy}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <a href={child?.document?.path} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                                      <span className="icon-download font-20 me-2 text-blue cursor-pointer" />
                                    </a>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
                            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                            <div className="font-18 animate__animated animate__headShake">No data found</div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }
              return (
                <div className="card p-4 mb-3 d-flex justify-content-between ">
                  <div className="d-flex justify-content-start ">
                    <div className="thmb-icon-md">
                      <span className={fileImages[toLower(c?.document.type)]} />
                      {/* <img src={fileImages[c?.document.type]}
                       alt="" style={{ height: 24 }} /> */}
                    </div>
                    <div className="c-w-300 text-ellipsis fold-details">
                      <span data-tooltip-id="project-system" data-tooltip-content={c.document?.fname || c.name} className="ms-3 text-ellipsis">
                        {c.document?.fname || c.name}
                      </span>
                      <div className="d-flex mt-1 ms-3">
                        <div className="border-right pe-3">{moment(c.document.auditInfo.createdAt).format('DD-MMM-YYYY HH:mm')}</div>
                        <div className="border-right px-3">{formatBytes(c.document.size)}</div>
                        <div className="px-3 text-uppercase text-ellipsis">
                          <span data-tooltip-id="project-system" data-tooltip-content={c.document.createdBy}>
                            {c.document.createdBy}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    {c?.document?.path && (
                      <a href={c?.document?.path} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                        <span className="icon-download font-20 me-2 text-blue cursor-pointer" />
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="d-flex justify-content-center p-5">No records found</div>
      )}
      <Tooltip id="project-system" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default SystemDocumentContainer;
