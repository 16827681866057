import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import Loader from '../../../../common/loader';
import { getVariationOrderReport, vopReportDownload } from '../services';
import VariationOrdersReportComponent from './VariationOrdersReportComponent';

const VariationOrdersReport = () => {
  const statusList = [
    { label: 'Created', value: 1 },
    { label: 'Sent', value: 2 },
    { label: 'Received', value: 3 },
    { label: 'Add to Spec list', value: 4 },
    { label: 'Cancelled', value: 5 },
    { label: 'Deferred To Next DD', value: 6 }
  ];
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const [tableData, setTableData] = useState({
    projectId: '',
    repairStartDate: '',
    repairEndDate: '',
    specList: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const reloadTable = (status) => {
    const payload = {
      page,
      pageSize,
      search: '',
      sortProperty: '',
      isDescending: true,
      projectId: id,
      status
    };
    setLoading(true);
    getVariationOrderReport(payload).then((res) => {
      console.log(res);
      setTableData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    reloadTable(0);
  }, []);

  useEffect(() => {
    const statusValue1 = statusValue ? statusValue.value : 0;
    reloadTable(statusValue1);
  }, [statusValue, page, pageSize]);

  const downloadReport = () => {
    const statusValue1 = statusValue ? statusValue.value : 0;
    const payload = {
      projectId: id,
      status: statusValue1
    };
    vopReportDownload(payload);
  };
  const isClearable = true;

  return (
    <div data-testid="VariationOrdersReport">
      <div className="align-items-center d-flex justify-content-between report-ctrl">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <div className="font-20 font-bold">Variation Order Report</div>
            <div className="ms-3 couter-bubbles status-due ">{tableData?.specList?.totalItems}</div>
          </div>
          <div className="d-flex align-items-center me-5">
            <label>
              Repair Period
              <span className="ms-2">:</span>
            </label>
            &nbsp;
            <div className="value">{tableData?.repairStartDate ? moment(tableData?.repairStartDate).format('DD-MMM-yyyy') : ''}</div>
            <div className="mx-4 value"> - </div>
            <div className="value">{tableData?.repairEndDate ? moment(tableData?.repairEndDate).format('DD-MMM-yyyy') : ''}</div>
          </div>
          <div
            data-tooltip-id="project-specName"
            data-tooltip-content={statusValue?.label}

          >
            <Select
              className="modal-select mx-3 w-250"
              placeholder="Status"
              options={statusList}
              isClearable={isClearable}
              value={statusValue}
              onChange={(e) => {
                if (e) {
                  setStatusValue(e);
                } else {
                  setStatusValue('');
                }
              }}
            />
          </div>

        </div>

        {/* <div>
          <button type="button" className="secondary-btn">
            Print/Share
          </button>
        </div> */}
      </div>
      <div className="report-canvas mt-4">
        <div className="p-4">
          {loading ? (
            <Loader />
          ) : (
            <VariationOrdersReportComponent
              downloadReport={downloadReport}
              tableData={tableData}
              setPage={setPage}
              setPageSize={setPageSize}
              page={page}
              pageSize={pageSize}
            />
          )}
        </div>
      </div>
      <Tooltip id="project-specName" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default VariationOrdersReport;
