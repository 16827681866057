/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import qs from 'query-string';
import { useParams } from 'react-router-dom';
// import { projectSpecList, saveQuote,
// updateQuoteStatus, currencyList, getPublishUomList } from './services';
import ContentWrap from './children/contentWrap';
import QuoteFooter from './children/quoteFooter';
import showAlert from '../../../utils/alert';
import PageHeader from './children/pageHeader';
import CardSeletion from './cardSelection';
import SidePanel from '../../common/sidePanel';
import ViewMore from '../YardQuotation/ViewMore';
import Attachments from './Attachments';
import helper from '../../../utils/helper';
import ModalBox from '../../common/ModalBox';
import SubmitConfirm from './children/submitConfirm';
import AddServiceLine from '../YardQuotation/AddServiceLine';
import { UpdateCalculations, UpdateServiceNumbers } from '../YardQuotation/children/totalEstimate';
import { projectSpecListVariation, saveQuoteVariation, updateQuoteStatusVariation } from './service';
import { currencyList, getPublishUomList } from '../YardQuotation/service';
import PolicyFooter from '../../Admin/policyFooter';
import PrivacyPolicy from '../../Policies/privacy';
import TermsOfUse from '../../Policies/TermsOfUse';

// let isAccepted = 0;
const VariationReport = () => {
  const [specList, setSpecList] = useState({});
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [viewPage, setViewPage] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCurrency, setAllCurrency] = useState([]);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  const [addServiceLine, setAddServiceLine] = useState(false);
  const [newServiceData, setNewServiceData] = useState({});
  const [uomList, setUomList] = useState([]);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const { getDropDownOptions, formatNumber } = helper;

  const params = qs.parse(window.location.search);
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  const getSpecList = () => {
    projectSpecListVariation({ key: params.key }).then((res) => {
      if (res.success) {
        setSpecList(res.data);
        currencyList(res.data?.projectId).then((currency) => {
          if (currency.success) {
            setAllCurrency(getDropDownOptions(currency.data, 'id', 'currencyName'));
          }
        });
        getPublishUomList(res.data?.projectId).then((uom) => {
          if (uom.success) {
            setUomList(getDropDownOptions(uom.data, 'uomId', 'uomName'));
          }
        });
        setLoading(false);
        if (res.data.variationQuoteStatus === 1
          || (res.data.variationQuoteStatus === 2
            && res.data?.sectionData[0]?.variationSpecDataList[0]?.isManual)) {
          setViewPage(1);
        } else {
          setViewPage(2);
        }
      } else {
        setLoading(false);
        setSpecList({ rfqData: { status: 4, currencySymbol: '' } });
        setViewPage(2);
      }
    });
  };
  useEffect(() => {
    getSpecList();
  }, []);
  useEffect(() => {
    setLoading(true);
  }, []);
  const onCancel = () => {
    getSpecList();
  };
  const onAddServiceLine = (index1, index2) => {
    setAddServiceLine({ index1, index2 });
  };

  const onCurrencyChange = (e) => {
    const currentSpecList = { ...specList };
    currentSpecList.rfqData.currencyId = e.value;
    currentSpecList.rfqData.currencySymbol = e.currencySymbol;
    setSpecList(currentSpecList);
  };

  const total = (ser) => {
    let a = 0;
    ser.serviceLineList.map((item) => {
      if (item?.amount) {
        a += parseFloat(item.amount);
      }
      return null;
    });
    return formatNumber(a);
  };
  const onSave = (isSubmit) => {
    const a = {
      key: params.key,
      projectVariationQuoteList: [],
      isSubmit
    };
    specList?.sectionData?.map((sub) => {
      sub.variationSpecDataList.map((ser) => {
        const c = {
          id: ser.variationReceivedQuoteId,
          projectSpecId: ser.projectSpecId,
          serviceLineQuote: ser.serviceLineList,
          title: ser.title,
          specLevel: ser.specificationLevel,
          specNo: ser.specificationNo,
          type: ser.type,
          total: total(ser),
          parentSpecificationId: ser.parentSpecificationId,
          specDocuments: ser.specDocuments,
          currencyId: specList?.rfqData?.currencyId
        };
        a.projectVariationQuoteList?.push(c);
        return null;
      });
      return null;
    });
    saveQuoteVariation(a).then((res) => {
      if (res.success) {
        getSpecList();
        if (isSubmit) {
          setShowSubmitConfirm(2);
        } else {
          showAlert('Successfully saved as draft', 'success');
        }
      }
    });
  };
  const onSubmitQuote = (isSubmit) => {
    // alert('hi');
    if (isSubmit) {
      setShowSubmitConfirm(1);
    } else {
      onSave(isSubmit);
    }
  };
  // const onContiue = (pg, type) => {
  //   setViewPage(pg);
  //   if (type !== 'empty') {
  //     setIsManual(type);
  //   }
  // };
  const onContiue = (flag) => {
    setViewPage(2);
    updateQuoteStatusVariation({ key: params.key, status: 2, isManual: flag })?.then((res) => {
      if (res.success) {
        getSpecList();
      }
    });
  };
  const onRemoveLine = (index, subindex, numb) => {
    let currentSpecList = { ...specList };
    currentSpecList?.sectionData[index]?.variationSpecDataList[subindex]
      .serviceLineList.splice(numb, 1);
    currentSpecList = UpdateCalculations(currentSpecList, index);
    currentSpecList = UpdateServiceNumbers(currentSpecList, index, subindex);
    setSpecList(currentSpecList);
  };
  const onNewServiceSave = () => {
    let currentSpecList = { ...specList };
    currentSpecList.sectionData[addServiceLine.index1].variationSpecDataList[addServiceLine.index2]
      .serviceLineList.push(newServiceData);
    currentSpecList = UpdateCalculations(currentSpecList, addServiceLine.index1);
    setSpecList(currentSpecList);
    setAddServiceLine(false);
  };
  const renderPageContent = (page) => {
    if (page === 2) {
      return (
        <>
          <div className="card m-3 py-2 px-4">
            <ContentWrap
              specList={specList}
              setSpecList={setSpecList}
              onSecOpen={onSecOpen}
              mainSec={mainSec}
              showsubSec={showsubSec}
              setShowMore={setShowMore}
              setShowAttach={setShowAttach}
              allCurrency={allCurrency}
              params={params}
              onAddServiceLine={onAddServiceLine}
              onRemoveLine={onRemoveLine}
              onNewServiceSave={onNewServiceSave}
              onCurrencyChange={onCurrencyChange}
            />
            <QuoteFooter onSave={onSubmitQuote} onCancel={onCancel} specList={specList} />
          </div>

        </>
      );
    } if (page === 1) {
      return <div className="quote-content"><CardSeletion onContiue={onContiue} loading={loading} isManual={isManual} setIsManual={setIsManual} /></div>;
    }
    return null;
  };

  return (
    <div data-testid="y-quotation" className="">
      <PageHeader specList={specList} />
      <div className="tsi-resource">
        <div className="scorll-body-wrap">

          {renderPageContent(viewPage)}
          <SidePanel
            title={<span data-tooltip-id="project-more" data-tooltip-content={showMore.title}>{`${showMore.specificationNo}. ${_.truncate(showMore.title, { length: 55 })}`}</span>}
            size="large"
            onClose={() => { setShowMore(false); }}
            showView={showMore}
          >
            {showMore && <ViewMore showMore={showMore} />}
          </SidePanel>
          <SidePanel
            title={<span data-tooltip-id="project-more" data-tooltip-content={showAttach?.section?.title}>{`${showAttach?.section?.specificationNo}. ${_.truncate(showAttach?.section?.title, { length: 20 })}`}</span>}
            size="large"
            onClose={() => { setShowAttach(false); }}
            showView={showAttach}
          >
            {showAttach && (
            <Attachments
              showAttach={showAttach}
              specList={specList}
              setSpecList={setSpecList}
            />
            )}
          </SidePanel>
          <SidePanel
            title="Add New Service Line"
            size="small"
            onClose={() => { setAddServiceLine(false); }}
            showView={addServiceLine}
            onSave={onNewServiceSave}
            isTsiDisabled={!newServiceData.serviceLineName}
          >
            {addServiceLine
        && (
        <AddServiceLine
          specList={specList}
          setSpecList={setSpecList}
          setNewServiceData={setNewServiceData}
          newServiceData={newServiceData}
          addServiceLine={addServiceLine}
          uomList={uomList}
        />
        )}
          </SidePanel>
          {showSubmitConfirm && (
          <ModalBox
            modalClassName="medium"
            heading=""
            onSave={() => { onSave(true); }}
            closeModal={() => {
              setShowSubmitConfirm(false);
            }}
          >
            <SubmitConfirm
              showSubmitConfirm={showSubmitConfirm}
              setShowSubmitConfirm={setShowSubmitConfirm}
              onSave={onSave}
            />
          </ModalBox>
          )}
        </div>

        <Tooltip id="project-more" place="top" className="tooltip mt-4" />
        <div className="">
          <div className="">
            <div className="footer justify-content-between font-13">
              <PolicyFooter
                setShowPrivacyPolicy={setShowPrivacyPolicy}
                setShowTermsPolicy={setShowTermsPolicy}
              />
            </div>
          </div>

        </div>
        {showPrivacyPolicy && (
          <PrivacyPolicy
            setShowPrivacyPolicy={setShowPrivacyPolicy}
            showPrivacyPolicy={showPrivacyPolicy}
          />
        )}
        {showTermsPolicy && (
          <TermsOfUse
            setShowTermsPolicy={setShowTermsPolicy}
            showTermsPolicy={showTermsPolicy}
          />
        )}
      </div>

    </div>
  );
};
export default VariationReport;
