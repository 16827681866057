import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import ModalBox from '../../../common/ModalBox';

const Cards = (props) => {
  const {
    data,
    deleteApiCall,
    openDeleteModal,
    setOpenDeleteModal,
    setShowModal,
    setFormData,
    setSelectedVessels,
    setManagementId
  } = props;

  return (
    <div className="fleet-wrap" data-testid="f-cards">
      <div className="section1">
        <h6 className="head-14 mb-3">
          <span
            className="text-ellipsis pe-2"
            data-tooltip-id="project-fleet"
            data-tooltip-content={`${data?.name}`}
          >
            {_.truncate(data?.name, {
              length: 35,
              separator: ' '
            })}
          </span>
        </h6>
        <div className="sub-items">
          {data?.vesselList && data?.vesselList?.length > 0 && (

            data?.vesselList.map((item) => {
              return (
                <span>{item?.name}</span>
              );
            }))}

        </div>
      </div>
      <div className="detail-sec">
        <h6 className="head-14 mb-3">
          <span
            className="text-ellipsis pe-2"
            data-tooltip-id="project-fleet"
            data-tooltip-content={`${data?.managementName}`}
          >
            {_.truncate(data?.managementName, {
              length: 30,
              separator: ' '
            })}
          </span>
        </h6>
        {/* <span className="mb-2">Ph:  +91 44 4321 5514</span>
        <span>Fax: +91 44 4321 5500</span> */}
      </div>
      <div className="btn-section">
        <button
          type="button"
          id="edit"
          className="link-btn"
          // data-tooltip-id="project-fleet"
          // data-tooltip-content={data?.mappedMessage}
          // disabled={data.isMapped}
          onClick={() => {
            // setListData([]);
            setSelectedVessels(data?.vesselList);
            setManagementId(data?.managementId);
            setFormData(data);
            setShowModal(true);
          }}
        >
          <span className="icon-edit font-22" />
        </button>
        {/* <button
          type="button"
          className="link-btn"
          id="delete"
          onClick={() => {
            setOpenDeleteModal(true);
            setItem(data?.id);
          }}
        >
          <span className="icon-delete font-22" />
        </button> */}
      </div>
      {openDeleteModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete this Item ?</p>
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="project-fleet" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default Cards;
