/* istanbul ignore file */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import helper from '../../../utils/helper';
import Header from '../../TSI/Header';

const ResourceTSI = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const [count, setCount] = useState();
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = (userRole === roles.AUTHOR) || (userRole === roles.EDITOR);
  const subHeaderList = [
    { name: 'Reference web links', url: 'web' }
  ];
  if (isTSI) {
    subHeaderList.push(
      { name: 'Yard Cost History', url: 'yard' }
    );
  }
  subHeaderList.push(
    { name: 'Reference Doc', url: 'reference' }
  );
  // subHeaderList.push(
  //   { name: 'User Manual', url: 'usermanual' }
  // );

  return (
    <div data-testid="tsi-resource-index">
      <div className="header">
        <Header />
        <div className="sub-header" data-testid="data-config">
          <div className="d-flex  justify-content-center">
            <>
              {subHeaderList ? (
                <div className="d-flex item-set col-gap-2">
                  {subHeaderList.map((i) => {
                    return (
                      <button
                        onClick={() => navigate(`/user/resource/${i.url}`)}
                        className={location.includes(`/user/resource/${i.url}`) ? 'rnd-btn active' : 'rnd-btn'}
                        type="button"
                      >
                        {i.name}
                        {location.includes(`/user/resource/${i.url}`) && count !== '' && i.url !== 'usermanual' ? <span className="count-bar">{count}</span> : ''}
                      </button>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </>
          </div>
        </div>
      </div>
      <div className="tsi-resource">
          <Outlet context={[count, setCount]} />
      </div>
    </div>
  );
};
export default ResourceTSI;
