import moment from 'moment/moment';
import React from 'react';
import Select from 'react-select';

const BaseCurrency = (props) => {
  const { allCurrency, onBaseChange, tableData } = props;
  return (
    <div className="mb-2 md-radius bg-sky-white p-2 d-flex justify-content-between align-items-center pe-3">
      <div className="d-flex align-items-center">
        <span className="px-3">Base Currency</span>
        <Select
          className="search"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              cursor: 'pointer',
              borderColor: state.isFocused ? 'blue' : 'blue'
            })
          }}
          options={allCurrency}
          onChange={onBaseChange}
          value={allCurrency.filter((cur) => cur.value === (tableData?.additionalData
            && JSON.parse(tableData?.additionalData)?.BaseCurrencyId))}
        />
      </div>
      <label>
        As on :
        <span className="head-14 px-2">{tableData?.additionalData ? moment(JSON.parse(tableData?.additionalData || '')?.AsOnDate).format('DD-MMM-yyyy') : ''}</span>
      </label>
    </div>
  );
};
export default BaseCurrency;
