import React from 'react';
import showAlert from '../../../../../../../../utils/alert';
import ModalBox from '../../../../../../../common/ModalBox';

const AddToMainListModal = (props) => {
  const { setConfirmModal, setEyeView, onClose, AddToMainListBtnChange } = props;
  const handleContinue = () => {
    AddToMainListBtnChange();
    setConfirmModal(false);
    setEyeView(false);
    onClose(false);
    showAlert('Successfully added to the Main List', 'success');
  };
  return (
    <div data-testid="addmainlistmodal">
      <ModalBox
        heading="Confirm"
        closeModal={() => { setConfirmModal(false); }}
        buttonName="Continue"
        onSave={() => { handleContinue(); }}
      >
        <div className="font-13 p-1">Are you sure you want to add specifications to Main List?</div>
      </ModalBox>
    </div>
  );
};
export default AddToMainListModal;
