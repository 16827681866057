import React from 'react';

const Tags = (props) => {
  const { specData } = props;
  if (specData?.specTagData?.length < 1) {
    return (
      <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="yard-tag">
        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
        <div className="font-18 animate__animated animate__headShake">
          No data found
        </div>
      </div>
    );
  }
  return (
    <div data-testid="yard-tag">
      {specData?.specTagData?.map((item) => {
        return (
          <div className="px-4 py-3 border my-4 md-radius d-flex me-4">
            <div>
              <h4 className="font-semibold">
                {item.name}
              </h4>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Tags;
