/* eslint-disable no-unused-vars */
import React from 'react';
import qs from 'query-string';
import { downloadDocuments, UpdateQuoteStatus } from '../service';

const ManualCard = (props) => {
  const params = qs.parse(window.location.search);
  const { onContiue } = props;
  /* istanbul ignore next */
  const handleDownloadExcel = () => {
    UpdateQuoteStatus({ key: params.key, status: 2, isManual: true })?.then((res) => {
      if (res.success) {
        downloadDocuments({ key: params.key });
      }
    });
  };
  return (
    <div className="card p-4 quote_selcard me-5" data-testid="manual">
      <div className="mt-4 p-5 bg-sky-white round-radius"><span className="blue icon-rfq-manual font-35" /></div>
      <div className="font-bold mt-3 head">Supplementary RFQ - Excel</div>
      <div className="my-3 px-2 details">
        Download the DD attachments and enter the
        quotes in excel, send it to the Email provided in the instructions.
      </div>
      <button
        type="button"
        className="primary-btn mt-3 mb-4"
        // onClick={() => { onContiue(2, true); }}
        onClick={handleDownloadExcel}
      >
        Download Excel
      </button>
    </div>
  );
};
export default ManualCard;
