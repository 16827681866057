/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ReportModal from './ReportModal';
import PhotoReport from './Children/PhotoReport';
import CostUpdateReport from './Children/CostUpdateReport';
import DailyReport from './Children/DailyReport';
import JobProgressReport from './Children/JobProgressReport';
import SofReport from './Children/SofReport';
import VariationOrdersReport from './Children/VariationOrdersReport';
import helper from '../../../../utils/helper';
import PoReport from './Children/PoReport';
import WoReport from './Children/WoReport';
import VarianceReport from './Children/VarianceReport';
import AcCodeReport from './Children/AcCodeReport';

const Report = () => {
  const { projInfo } = useOutletContext() || { projInfo: {} };

  const [activeComp, setActiveComp] = useState('0');
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = (
    (userRole === roles.AUTHOR)
  || userRole === roles.EDITOR
  || (userRole === roles.VESSEL_USER)
  || (userRole === roles?.ADMIN));
  return (
    <div>
      <div className="sub-header" data-testid="report">
        <div className="d-flex ">
          <div className="d-flex justify-content-between align-items-center w-full ">
            <div className="d-flex">
              <button
                disabled={window.history?.length < 3}
                className="link-btn ms-5"
                type="button"
                data-test="click1"
                onClick={() => { window.history.back(); }}
              >
                <span className="icon-arrow-left2 me-2" />
                Back
              </button>
              <h2 className="head-18 me-5 ms-5 ps-4">Reports</h2>
            </div>
            <ReportModal activeComp={activeComp} setActiveComp={setActiveComp} />
          </div>
        </div>
      </div>
      <div className=" content-wrap d-flex reports-repair">
        <div className=" w-full">
          {activeComp === '0' ? <DailyReport projInfo={projInfo} /> : ''}
          {activeComp === '1' ? <SofReport /> : ''}
          {activeComp === '2' ? <JobProgressReport /> : ''}
          {activeComp === '3' ? <WoReport /> : ''}
          {activeComp === '4' ? <PoReport /> : ''}
          {activeComp === '5' ? <VariationOrdersReport /> : ''}
          {activeComp === '6' ? <CostUpdateReport /> : ''}
          {activeComp === '7' ? <PhotoReport isTSI={isTSI} projInfo={projInfo} /> : ''}
          {activeComp === '8' ? <VarianceReport /> : ''}
          {activeComp === '9' ? <AcCodeReport projInfo={projInfo} /> : ''}
        </div>
      </div>
    </div>
  );
};

export default Report;
