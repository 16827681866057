/* istanbul ignore file */
import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const { GetPaintCostUrl, SaveSectionUrl, PaintCostRegionUrl,
  PaintCostExportUrl, PaintCostImportUrl, specSupplimentoryExportUrl } = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;
export const GetPaintCost = (payload) => {
  return serviceHandler({
    url: `${GetPaintCostUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const SavePaintCost = (payload) => {
  return serviceHandler({
    url: `${GetPaintCostUrl}`,
    methordType: 'post',
    payload
  });
};
export const SaveSection = (payload) => {
  return serviceHandler({
    url: `${SaveSectionUrl}`,
    methordType: 'post',
    payload
  });
};
export const PaintCostRegion = (payload) => {
  return serviceHandler({
    url: `${PaintCostRegionUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const paintCostExport = (projectId) => {
  return axios
    .get(`${PaintCostExportUrl}?projectId=${projectId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    }).catch((error) => {
      handleError(error);
    });
};
// export const specSupplimentoryExport = (projectId) => {
//   return axios
//     .get(`${specSupplimentoryExportUrl}?projectId=${projectId}`, {
//       responseType: 'blob'
//     })
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       downloadFile(url, getExportedFileName(response));
//     }).catch((error) => {
//       handleError(error);
//     });
// };

export const specSupplimentoryExport = (projectId) => {
  return serviceHandler({
    url: `${specSupplimentoryExportUrl}?projectId=${projectId}`,
    methordType: 'get'
  });
};

export const importPaintCost = (id, payload) => {
  return serviceHandler({
    url: `${PaintCostImportUrl}?projectId=${id}`,
    methordType: 'post',
    payload
  });
};
