import React, { useState, useEffect } from 'react';
import Navbar from './Children/navbar';
import CardWrapper from './Children/cardWrapper';
import { ProjectSpecWorkOrderDefectList, DeleteProjectSpec } from '../../services';
import showAlert from '../../../../../../../utils/alert';
import ModalBox from '../../../../../../common/ModalBox';

let isSearchUpdated = false;
const WoDefectList = (props) => {
  const { projectSpecificationId, isTsiDisabled,
    onReload, isTSI, fromSpecify, fromVariation, fromSupplementary } = props;
  const [workOrder, setWorkOrder] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [visibleSpec, setVisibleSpec] = useState(['0', '1']);
  const [showSearchCount, setShowSearchCount] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const changeVisibility = (key) => {
    const visibility = [...visibleSpec];
    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }
    setVisibleSpec(visibility);
  };
  const checkType = () => {
    if (visibleSpec.length === 2) {
      return 0;
    } if (visibleSpec.length === 0) {
      return 3;
    } if (visibleSpec[0] === '0') {
      return 1;
    }
    return 2;
  };
  const workorderDefect = () => {
    ProjectSpecWorkOrderDefectList({
      projectSpecId: projectSpecificationId,
      type: checkType(),
      searchString: isSearchUpdated ? searchText : '' }).then((response) => {
      if (response?.success) {
        if (searchText) {
          setShowSearchCount(true);
        } else { setShowSearchCount(false); }
        setWorkOrder(response?.data);
      }
    });
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearchUpdated = true;
      workorderDefect();
    }
  };

  const onDelete = (key) => {
    setShowConfirm(key);
  };
  const deleteApiCall = () => {
    DeleteProjectSpec({ projectSpecificationId,
      workOrderId: showConfirm }).then((response) => {
      if (response?.success) {
        workorderDefect();
        showAlert('Successfully Deleted', 'success');
        setShowConfirm(false);
        onReload();
      }
    });
  };
  useEffect(() => {
    workorderDefect();
  }, [projectSpecificationId, visibleSpec]);

  useEffect(() => {
    if (!searchText) {
      workorderDefect();
    }
  }, [searchText]);
  return (
    <div className="mt-4 content-scroller-height" data-testid="wo-defect">
      <Navbar
        setSearchText={setSearchText}
        onSearch={onSearch}
        visibleSpec={visibleSpec}
        changeVisibility={changeVisibility}
        showSearchCount={showSearchCount}
        workOrder={workOrder}
        searchText={searchText}
      />
      <CardWrapper
        onDelete={onDelete}
        workOrder={workOrder}
        isTsiDisabled={isTsiDisabled}
        isTSI={isTSI}
        fromVariation={fromVariation}
        fromSupplementary={fromSupplementary}
      />
      {showConfirm ? (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          removeClass={!fromSpecify}
          onSave={() => deleteApiCall()}
          closeModal={() => setShowConfirm(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to remove WO/Defect ?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>

  );
};
export default WoDefectList;
