/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../utils/helper';
import InputType1 from '../../../common/InputType1';

const VendorSectionCard = (props) => {
  const { formatNumber } = helper;
  const { regions, i, ikey, vendorIndex, isOpenRow, handleChangeVendor, handleChangeVendorRegion, isTSI } = props;
  const getTooltip = (va) => {
    let t = '$ ';
    t = t.concat('', formatNumber(va || 0));
    return t;
  };
  if (isOpenRow.includes(i.sectionId)) {
    return (
      <div data-testid="p-7" className="ps-4  border-down">
        <div className={`${ikey ? 'header-top-h' : 'firstvendor-h'}`}>
          {regions?.map((r) => {
            if (r.isShow) {
              return (
                <div className="region-div-inner-h paint-l-border white">
                  <span className="icon-location font-18 me-1 pt-1 white" />
                  {r.label}
                </div>
              );
            }
            return '';
          })}
        </div>
        <div className="align-items-center bg-sky-blue d-flex me-4  text-content-black">
          <div className="p-2 paint-w-100">
            <div>
              Vol.solids
              <br />
              of paint
            </div>
          </div>
          <div className="p-2 paint-w-100">
            <div>
              Estimated
              <br />
              qty reqd.
            </div>
          </div>
          {regions?.map((r) => {
            if (r.isShow) {
              return (
                <>
                  <div className="ms-3 p-2 paint-w-100">
                    <div className="">
                      Price
                      <br />
                      per Ltr.
                    </div>
                  </div>
                  <div className="p-2 paint-w-100">
                    <div>
                      Total
                      <br />
                      price.
                    </div>
                  </div>
                </>
              );
            }
            return '';
          })}
        </div>
        {!i.vendorSectionRow || !i.vendorSectionRow.length ? <div className="no-data-paint">No data</div> : ''}
        {i.vendorSectionRow.map((iData, iKsy) => (
          <div className="d-flex me-4 px-2 text-content-black">
            <div className="py-3 paint-w-100" data-tooltip-id="view-prof" data-tooltip-content={iData.paintVolume}>
              <InputType1
                onChange={(e) => handleChangeVendor(e.target.value, 'paintVolume', vendorIndex, ikey, iKsy)}
                value={iData.paintVolume || ''}
                className="me-1 modal-inputs small"
                label=""
                disabled={!isTSI}
              />
            </div>
            <div className="py-3 paint-w-100" data-tooltip-id="view-prof" data-tooltip-content={iData.estimatedQuantity}>
              <InputType1
                onChange={(e) => handleChangeVendor(e.target.value, 'estimatedQuantity', vendorIndex, ikey, iKsy)}
                value={iData.estimatedQuantity || ''}
                className="mx-1 modal-inputs small"
                label=""
                disabled={!isTSI}
              />
            </div>
            {iData.regionPrices?.map((region, regionIndex) => {
              if (regions[regionIndex].isShow) {
                return (
                  <>
                    <div className="py-3 paint-w-100 r-b" data-tooltip-id="view-prof" data-tooltip-content={region.pricePerLitre}>
                      <InputType1
                        onChange={(e) => handleChangeVendorRegion(e.target.value, 'pricePerLitre', vendorIndex, ikey, iKsy, regionIndex)}
                        value={region.pricePerLitre || ''}
                        className="mx-1 modal-inputs small"
                        label=""
                        disabled={!isTSI}
                      />
                    </div>
                    <div className="py-3 paint-w-100" data-tooltip-id="view-prof" data-tooltip-content={region.totalPrice}>
                      <InputType1 disabled value={region.totalPrice} className="ms-1 modal-inputs small" label="" />
                      {iKsy === i.vendorSectionRow.length - 1 ? (
                        <div className={ikey === 0 ? 't-top1 text-right pe-1' : 't-top text-right  pe-1'}>
                          <div
                            className="font-bold text-content-black"
                            data-tooltip-id="view-prof"
                            data-tooltip-content={getTooltip(i.regionGrandTotal[regionIndex]?.grandTotal)}
                          >
                            $
                            {' '}
                            {_.truncate(formatNumber(i.regionGrandTotal[regionIndex]?.grandTotal) || 0, {
                              length: 9,
                              separator: ' '
                            })}
                          </div>
                          <div>Total</div>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </>
                );
              }
              return '';
            })}
            <Tooltip id="view-prof" place="top" className="tooltip " />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div data-testid="p-7" className={`ps-4 border-down ${ikey ? '' : ''}`}>
      <div className="region-divv">
        {regions?.map((r, regionIndex) => {
          if (r.isShow) {
            return (
              <div className="region-div-innerr paint-l-border text-right">
                <div
                  className="font-bold text-content-black ps-2"
                  data-tooltip-id="view-prof123"
                  data-tooltip-content={getTooltip(i?.regionGrandTotal[regionIndex]?.grandTotal)}
                >
                  $
                  {' '}
                  {_.truncate(formatNumber(i?.regionGrandTotal[regionIndex]?.grandTotal) || 0, {
                    length: 20,
                    separator: ' '
                  })}
                </div>
              </div>
            );
          }
          return '';
        })}
        <Tooltip id="view-prof123" place="top" className="tooltip " />
      </div>
    </div>
  );
};
export default VendorSectionCard;
