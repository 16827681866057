/* eslint-disable radix */
import _ from 'lodash';

export const collationSave = (data) => {
  const formatData = [];
  data?.map((item) => {
    formatData.push(
      {
        specId: item.id,
        yardInvoice: item.yardInvoice,
        comment: item.comment
      }
    );
    item.specs.map((subItem) => {
      formatData.push(
        {
          specId: subItem.id,
          yardInvoice: subItem.yardInvoice,
          comment: subItem.comment
        }
      );
      subItem.serviceLines.map((serItem) => {
        formatData.push(
          {
            specId: serItem.id,
            yardInvoice: serItem.yardInvoice,
            comment: serItem.comment
          }
        );
        return null;
      });
      return null;
    });
    return null;
  });
  return formatData;
};
export const getSpecamount = (service, amtField) => {
  let specTotal = 0;
  service.map((item) => {
    if (parseFloat(item[amtField])) { specTotal += parseFloat(item[amtField]); }
    return null;
  });
  return _.round(specTotal, 2).toString();
};
export const getSecAmount = (specs, amtField) => {
  let secTotal = 0;
  specs.map((item) => {
    if (parseFloat(item[amtField])) { secTotal += parseFloat(item[amtField]); }
    return null;
  });
  return _.round(secTotal, 2).toString();
};
export const totalAtContract = (data) => {
  let contract = 0;
  data?.map((item) => {
    item?.specs.map((field) => {
      field?.serviceLines.map((amt) => {
        contract += parseFloat(amt.ownerContractEstimate);
        return null;
      });
      return null;
    });
    return null;
  });
  return _.round(contract, 2);
};
export const totalAtRepair = (data) => {
  let repair = 0;
  data?.map((item) => {
    item?.specs.map((field) => {
      field?.serviceLines.map((amt) => {
        repair += parseFloat(amt.ownerRepairEstimate);
        return null;
      });
      return null;
    });
    return null;
  });
  return _.round(repair, 2);
};
export const totalAtYard = (data) => {
  let invoice = 0;
  data?.map((item) => {
    if (parseFloat(item.yardInvoice)) { invoice += parseFloat(item.yardInvoice); }
    return null;
  });
  return _.round(invoice, 2);
};
