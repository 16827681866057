import moment from 'moment';
import React from 'react';
import userImg from '../../../images/user-placeholder.jpg';

const CommentModal = (props) => {
  const { comment } = props;
  const commentArray = (comment && comment.comment) ? comment.comment.split('\n') : [];
  return (
    <>
      {comment !== 0 ? (
        <>
          <div className="d-flex w-full pb-4 font-13" data-testid="c-m">
            <div className="col-1 width-fit">
              {comment.profileImageUrl ? (
                <img src={comment.profileImageUrl} className="thmb-md ship-thumb" alt="" />
              ) : (
                <img src={userImg} alt="" className="thmb-md ship-thumb" />
              )}
            </div>
            <div className="col-11">
              <div className=" chat-bubble p-4 text-black">
                <div className="d-flex justify-content-between  pb-3 col-gap-1">
                  <p className="text-semi-bold">
                    {comment.name}
                  </p>
                  <p className="font-13 brand-color me-3">
                    <span className="icon-disc m-1 font-10" />
                    {comment.approvalType}
                  </p>
                </div>
                <p className=" py-1 ">
                  {commentArray.map((data) => (<p>{data}</p>))}
                </p>
              </div>
              <div className="d-flex text-black justify-content-end mt-3">
                {/* {comment.submissionDate} */}
                {moment(comment.submissionDate.split('.')[0]).format('DD-MMM-YYYY HH:mm')}
                {' '}
                (UTC)
              </div>
            </div>
          </div>

        </>
      ) : (
        <div className="d-flex value justify-content-center p-5 mt-5">
          No Comments Added
        </div>
      )}
    </>
  );
};

export default CommentModal;
