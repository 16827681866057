/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import TextEditor from './TextEditor';
import SidePanel from '../../../common/sidePanelCustom';
import helper from '../../../../utils/helper';
import { getTemplateKeyword } from './services';
import AreaLoader from '../../../common/areaLoader';

const CreateTemplate = ({ formData, setFormData, TemplateTypes,
  publish, onCloseWindow, onSave, showView }) => {
  const { handleValidation } = helper;
  const [state, setstate] = useState(false);
  const [showbtn, setShowbtn] = useState(true);
  const [loading, setLoader] = useState(false);
  const [infoData, setInfoData] = useState([]);

  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'textEditor') {
      fd[field] = e;
    } else if (type === 'select') {
      fd[field] = e.value;
    } else fd[field] = e.target.value;
    setFormData(fd);
    handleValidation(['name', 'category'], fd, field);
  };
  useEffect(() => {
    if (formData && formData?.category) {
      setLoader(true);
      getTemplateKeyword(formData?.category).then((res) => {
        setLoader(false);
        if (res.success) {
          setInfoData(res.data);
        }
      });
    }
  }, [formData]);
  return (
    <SidePanel title="Template Settings" size="large" onClose={onCloseWindow} showView={showView}>
      <div className="side-panel__container bg-white">
        <div className="side-panel__content">
          <div data-testid="create-temp">
            <div className="d-flex align-items-end">
              <div className="col-4 me-4">
                <InputType1
                  autoFocus
                  id="name"
                  onChange={(e) => handleChange(e, 'name', '')}
                  value={formData?.name}
                  className="modal-inputs"
                  label="Template Name"
                  isMandatory="true"
                />
              </div>
              <div className="col-4 mt-2">
                <label>
                  Template Category
                  <span className="text-primaryRed-1">*</span>
                </label>
                <Select
                  value={TemplateTypes.filter((option) => option.value === formData?.category)}
                  onChange={(option) => handleChange(option, 'category', 'select')}
                  className="modal-select select-height"
                  label="code"
                  id="category"
                  options={TemplateTypes}
                />
              </div>
              <div className="col-4 d-flex justify-content-end align-items-center pe-4">
                <span>Help</span>
                <span onClick={() => setstate(true)} className="cursor-pointer font-26 icon-Info orange" />
              </div>
            </div>
            {state ? (
              <div className="template-info-details animate__animated animate__headShake">
                <div className="d-flex justify-content-between mt-2 border-dark-down ">
                  <h4 className="head-14 p-4">Template Info</h4>
                  <span onClick={() => setstate(false)} className="cursor-pointer font-22 icon-close ms-5 brand-color p-4" />
                </div>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <AreaLoader />
                  </div>
                ) : (
                  <div className="fixed-h">
                    <div className="p-4 font-13">
                      For the templates, please use the keywords listed below,
                      wherever necessary to auto populate corresponding
                      field names in the application screen.
                    </div>
                    <div className="px-4 font-13">
                      For example, to show logo on a report,
                      please choose @logo where logo has to be placed.
                    </div>
                    {infoData && infoData.length ? (
                      <div className="p-4 ">
                        {infoData.map((item, key) => (
                          <div className={infoData.length - 1 !== key ? 'border-down p-2' : 'p-2'}>{item}</div>
                        ))}
                      </div>
                    ) : (
                      <div className="resource-footer-no-data mt-5 p-4 d-flex justify-content-center ">
                        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                        <div className=" animate__animated animate__headShake">{formData?.category ? 'No data found' : 'Please select Template Category'}</div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              ''
            )}
            <div className="row mt-3">
              <div className="col-12">
                <TextEditor
                  id="editor"
                  handleChange={handleChange}
                  formData={formData}
                  setFormData={setFormData}
                  readOnlyContent=""
                  showbtn={showbtn}
                  setShowbtn={setShowbtn}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="side-panel__footer">
        <button type="button" className="secondary-btn me-4" onClick={onCloseWindow}>
          Cancel
        </button>
        {formData.status < 1 && (
          <button id="save" type="button" className="primary-btn me-4" onClick={() => onSave()}>
            Save
          </button>
        )}
        {formData.id && formData.status < 1 && (
          <button type="button" className="primary-btn" onClick={publish}>
            Publish
          </button>
        )}
      </footer>
    </SidePanel>
  );
};
export default CreateTemplate;
