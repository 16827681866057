import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';

const ProjectApproval = (props) => {
  const { items } = props;
  return (
    <div>
      {' '}
      <div className="d-flex flex-column border-down">

        <div className="d-flex my-3" data-testid="projectApproval-dashboard">
          <div className="me-3">
            {/* <img className="thmb-sm" src={items?.vesselImage} alt="" /> */}
            {/* <img src={items.vesselImage || image2} className="thmb-sm" alt="" /> */}
            {items?.vesselImage ? (
              <img src={items?.vesselImage} alt="" className="thmb-sm" />
            ) : (
              <img src="/static/media/vessel-thmd.304ab3bca4863ea93cc6.jpg" alt="" className="thmb-sm" />
            )}
          </div>
          <div className="d-flex flex-column w-full">
            <div className="d-flex justify-content-between ">
              <div
                className="head-14"
                data-tooltip-id="project-items"
                data-tooltip-content={items?.name}
              >
                {_.truncate(items?.name, {
                  length: 20
                })}
                <Tooltip id="project-items" place="top" className="tooltip mt-3" />
              </div>
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <div className="ms-2">
                    { items?.approvalStatus === 1
                      ? (
                        <div className="d-flex  animate__animated animate__pulse">
                          <span className="icon-disc medium me-2" />
                          <div className="font-13">Pending for Approval</div>
                        </div>
                      )
                      : items?.approvalStatus === 3
                        ? (
                          <div className="d-flex animate__animated animate__pulse">
                            <span className="icon-disc pending me-2" />
                            <div className="font-13">Submitted for Revision</div>

                          </div>
                        )

                        : ''}
                  </div>
                </div>
                <div className="d-flex phase-ind ms-4">
                  {items?.phase === 1
                    ? <div className="plan">P</div>
                    : items?.phase === 2
                      ? <div className="specify">S</div>
                      : items?.phase === 3
                        ? <div className="tender">T</div>
                        : items?.phase === 4
                          ? <div className="repair">R</div>
                          : items?.phase === 5
                            ? <div className="close">C</div>
                            : ''}
                </div>
              </div>
            </div>
            <div className="mt-1 font-14">{items?.tsiName}</div>
            {/* <div className="d-flex mb-3 mt-2 align-items-center font-14">
              <label className="me-3 ">Submitted On :</label>
              <div className="value">{items?.submissionDate ?
                `${moment(items?.submissionDate).format('DD-MMM-YYYY')}` : ''}</div>
            </div> */}
          </div>
        </div>
      </div>

    </div>

  );
};
export default ProjectApproval;
