/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { cloneDeep, random, toNumber } from 'lodash';
import ModalBox from '../../../../common/ModalBox';
import AddDescription from './AddDescription';
import AddTags from './AddTags';
import { getMajorSpecs, saveMajorSpecs, saveMajorSpecsification } from './services';
import helper from '../../../../../utils/helper';
import showAlert from '../../../../../utils/alert';
import AddKfpToSpec from './AddKfpToSpec';
import TableTwo from './TableTwo';

const InnerTable1 = (props) => {
  const { parentSpecificationNo,
    parentSpecificationId,
    showPagination,
    mainProp,
    specArray,
    uomList,
    tagList,
    subElementId,
    setSubElementId,
    getEmptySpecificationApi
  } = props;

  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [openKfpModal, setOpenKfpModal] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [activeModalData, setActiveModalData] = useState();
  const [editIndex, setEditIndex] = useState();
  const [isTadAdding, stIsTadAdding] = useState();
  // const [loading, setLoading] = useState(true);
  const [pSpecificationId, setPSpecificationId] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);

  const location = window.location.pathname;
  const { SpecificationType,
    SpecificationLevel,
    ZERO_UUID,
    charValidation,
    formValidation,
    formValidationInArray,
    handleFormValidation
  } = helper;
  let SpecificationTypeNumber = SpecificationType.MajorSpecs;
  if (location.includes('/admin/data/spec/general')) {
    SpecificationTypeNumber = SpecificationType.GeneralTariff;
  }

  const [stateTag, setStateTag] = useState();
  const [isDescriptionEdit, setIsDescriptionEdit] = useState();

  const [formDataSpec, setFormDataSpec] = useState([]);
  const [isRefreshService, setIsRefreshService] = useState(random(100));
  const [isAddNewExist, setIsAddNewExist] = useState(false);
  const [isAddNewTagModal, setIsAddNewTagModal] = useState(false);
  const [isAddNewTagModalDelete, setIsAddNewTagModalDelete] = useState('');

  const addSpecLine = () => {
    const temp = cloneDeep(formDataSpec);
    let num;
    let nextNum;
    if (SpecificationTypeNumber === SpecificationType.MajorSpecs) {
      num = temp.length ? temp[temp.length - 1].specificationNo : parentSpecificationNo;
      nextNum = num + 1;
    } else {
      num = temp.length ? temp[temp.length - 1].specificationNo : `${parentSpecificationNo}.0`;
      const decimalStr = num.toString().split('.');
      nextNum = `${decimalStr[0]}.${toNumber(decimalStr[1]) + 1}`;
    }
    temp.push({
      id: ZERO_UUID,
      isNew: true,
      parentSpecificationId,
      specificationNo: nextNum,
      title: '',
      description: '',
      specificationLevel: SpecificationLevel.Specification,
      applicableVesselType: [],
      tags: [],
      type: SpecificationTypeNumber,
      uomName: '',
      quanityEnterBy: '',
      status: 0,
      serviceLines: []
    });
    setFormDataSpec(temp);
    setIsAddNewExist(true);
    setEditIndex(temp.length - 1);
    const expandedRows = temp?.map((data) => {
      if (data.isNew) return true;
      return false;
    });
    setExpanded(expandedRows);
  };
  const handleTableChange = (e, field, saveData, data) => {
    const temp = data || cloneDeep(formDataSpec);
    temp.forEach((i, index) => {
      if (i.specificationNo === saveData.specificationNo) {
        temp[index][field] = charValidation(e.target.value, 500);
        handleFormValidation('title', temp[index]);
      }
    });
    setFormDataSpec(temp);
  };
  const handleTableJob = (saveData, data) => {
    const temp = data || cloneDeep(formDataSpec);
    setFormDataSpec([]);
    temp.forEach((i, index) => {
      if (i.specificationNo === saveData.specificationNo) {
        temp[index].isMapped = !saveData.isMapped;
      }
    });
    setTimeout(() => {
      setFormDataSpec(temp);
    }, 10);
  };
  const editData = (row) => {
    setEditIndex(row.index);

    const quotesCopy = cloneDeep(formDataSpec);
    const expandedRows = quotesCopy?.map((data, key) => {
      if (row.index === key) return true;
      return false;
    });
    setExpanded(expandedRows);
  };
  const reloadTable = () => {
    getMajorSpecs(parentSpecificationId, SpecificationTypeNumber).then((res) => {
      const c = res.data?.map((i) => {
        return {
          ...i,
          specificationNo: SpecificationTypeNumber === 1
            ? i.specificationNo
            : toNumber(i.specificationNo)
        };
      });
      setFormDataSpec(c);
      setIsRefreshService(random(100));
      setTimeout(() => {
        if (subElementId) {
          const subElement = document.getElementById(subElementId);
          if (subElement) {
            subElement.click();
          }
        }
      }, 50);
    });
  };
  const cancel = () => {
    reloadTable();
    setFormDataSpec([]);
    setIsAddNewExist(false);
    setEditIndex();
  };

  const handleSaveScetion = (index, data) => {
    const formSave = data || cloneDeep(formDataSpec[index]);
    if (formSave.id === ZERO_UUID) {
      delete formSave.id;
    }
    formSave.specificationNo = String(formSave.specificationNo);
    formSave.kfp = [];
    let isValid = true;
    formSave?.serviceLines?.forEach((item) => {
      if (item.specificationLevel === 4) {
        const v = String(item.quanityEnterBy)?.trim();

        if (!v || v === null || v === '' || v === ' ') {
          isValid = false;
        }
      }
    });
    if (formValidation(['title'], formSave) && formValidationInArray(['title'], formSave.serviceLines) && isValid) {
      setLoadingSave(true);
      saveMajorSpecsification(formSave).then((res) => {
        setLoadingSave(false);
        if (res.success) {
          reloadTable();
          showAlert('Successfully Saved', 'success');
          setIsAddNewExist(false);
          setEditIndex();
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
      handleFormValidation('title', 'quanityEnterBy', formSave);
      // eslint-disable-next-line no-unused-expressions
      formSave.serviceLines && formSave.serviceLines.forEach((s) => {
        handleFormValidation('title', s, `new-title-${s.specificationNo}`);
        handleFormValidation('quanityEnterBy', s, `new-uom-${s.specificationNo}`);
      });
    }
  };
  const handleTagDataDelete = (data) => {
    const formSave = cloneDeep(activeModalData);
    if (formSave) { formSave.specificationNo = String(formSave?.specificationNo); }
    if (data) {
      const tagsList = [];
      formSave?.tags?.forEach((i) => {
        if (i !== data.id) tagsList.push(i);
      });
      formSave.tags = tagsList;
      const tagsListss = [];
      formSave.tagList.forEach((i) => {
        if (i.id !== data.id) tagsListss.push(i);
      });
      formSave.tagList = tagsListss;
    }
    saveMajorSpecs(formSave).then((res) => {
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
        setActiveModalData(res.data);
        setStateTag({ id: '' });
        setIsAddNewTagModalDelete('');
      }
    });
  };
  /* istanbul ignore next */
  const handleModalDataSave = (type = 'description') => {
    let isValid = false;
    let found = true;
    const formSave = cloneDeep(activeModalData);
    if (formSave?.id === ZERO_UUID) {
      delete formSave.id;
    }
    console.log('formSave', formSave);
    if (formSave) { formSave.specificationNo = String(formSave?.specificationNo); }
    if (stateTag && type === 'tag') {
      const tagsList = formSave.tags;
      if (!tagsList.includes(stateTag.id)) {
        tagsList.push(stateTag.id);
        found = false;
      }
      formSave.tags = tagsList;
      if (stateTag.id) {
        isValid = true;
      }
    } else { // Description
      isValid = true;
      found = false;
    }
    if (isValid && !found) {
      saveMajorSpecs(formSave).then((res) => {
        if (res.success) {
          reloadTable();
          if (type !== 'description'
          || (type === 'description' && isDescriptionEdit)
          || (type === 'description' && formSave.description)
          ) {
            showAlert('Successfully Saved', 'success');
          }
          if (stateTag) {
            setActiveModalData(res.data);
            setStateTag({ id: '' });
            setIsAddNewTagModal(false);
          } else {
            setOpenDescriptionModal(false);
            setStateTag();
            setIsDescriptionEdit('');
          }
        }
        stIsTadAdding(false);
      });
    } else {
      stIsTadAdding(false);
      if (!isValid) showAlert('Please enter the valid data', 'error');
      if (found && type === 'tag') showAlert('Tag already assigned.', 'error');
      if (type === 'tag') {
        handleFormValidation('id', stateTag, 'Code');
      }
    }
  };

  useEffect(() => {
    reloadTable();
  }, [parentSpecificationId]);
  const openDescModal = () => {
    setOpenDescriptionModal(true);
  };
  const viewTagModal = () => {
    setOpenTagModal(true);
  };
  const viewKfpModal = () => {
    setOpenKfpModal(true);
  };
  /* istanbul ignore next */
  const handleModalKFPDataSave = () => {
    if (stateTag) {
      const fd = {
        parentSpecificationId: stateTag.parentSpecificationId,
        specificationNo: stateTag.code,
        title: stateTag.description || '',
        description: '',
        specificationLevel: SpecificationLevel.KFP,
        applicableVesselType: [],
        tags: [],
        type: SpecificationTypeNumber,
        uomId: stateTag.uomId,
        uomName: '',
        quanityEnterBy: '',
        status: 0,
        serviceLines: [],
        kfpId: stateTag.id
      };
      if (formValidation(['title'], fd)) {
        saveMajorSpecs(fd).then((res) => {
          if (res.success) {
            reloadTable();
            showAlert('Successfully Saved', 'success');
            setOpenKfpModal(false);
            setActiveModalData();
            setStateTag();
          }
        });
      } else {
        showAlert('Please select valid KFP', 'error');
      }
    } else {
      showAlert('Please select KFP', 'error');
    }
  };
  const setStateTagData = (data) => {
    setStateTag(data);
  };
  return (
    <div data-testid="mt-innertable1">
      {/* {loading
        && (
        <div className="content-loader">
          <div className="loader" />
          <span className="text">Loading, Please Wait...</span>
        </div>
        )} */}
      {openKfpModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Add KFP"
          closeModal={() => {
            setOpenKfpModal(false);
            setActiveModalData();
            setStateTag();
          }}
          buttonName="Save"
          onSave={() => handleModalKFPDataSave()}
        >
          <AddKfpToSpec
            state={stateTag}
            setstate={setStateTagData}
          />
        </ModalBox>
      ) : (
        ''
      )}
      {openDescriptionModal ? (
        <ModalBox
          modalClassName="large"
          heading={isDescriptionEdit ? 'Edit Description' : 'Add Description'}
          closeModal={() => {
            setOpenDescriptionModal(false);
            setActiveModalData();
            setStateTag();
            setIsDescriptionEdit('');
          }}
          // buttonName="HI"
          // onSave={() => handleModalDataSave()}
        >
          <AddDescription
            activeModalData={activeModalData}
            setActiveModalData={setActiveModalData}
            projectSpecificationId={parentSpecificationId}
          />
        </ModalBox>
      ) : (
        ''
      )}
      {openTagModal ? (
        <AddTags
          isTadAdding={isTadAdding}
          stIsTadAdding={stIsTadAdding}
          isAddNewTagModal={isAddNewTagModal}
          setIsAddNewTagModal={setIsAddNewTagModal}
          isAddNewTagModalDelete={isAddNewTagModalDelete}
          setIsAddNewTagModalDelete={setIsAddNewTagModalDelete}
          state={stateTag}
          setstate={setStateTag}
          tagList={tagList}
          activeModalData={activeModalData}
          saveForm={handleModalDataSave}
          deleteData={handleTagDataDelete}
          modalClassName="medium"
          heading="Tags"
          closeModal={() => {
            setOpenTagModal(false);
            setActiveModalData();
            setStateTag();
            setIsAddNewTagModal(false);
            reloadTable();
            document.body.classList.remove('model-open');
          }}
        />
      ) : (
        ''
      )}
      <TableTwo
        formDataSpec={formDataSpec}
        SpecificationLevel={SpecificationLevel}
        showPagination={showPagination}
        uomList={uomList}
        handleSaveScetion={handleSaveScetion}
        editIndex={editIndex}
        SpecificationTypeNumber={SpecificationTypeNumber}
        specArray={specArray}
        mainProp={mainProp}
        setFormDataSpec={setFormDataSpec}
        cancel={cancel}
        isRefreshService={isRefreshService}
        ZERO_UUID={ZERO_UUID}
        setOpenKfpModal={setOpenKfpModal}
        stateTag={stateTag}
        setStateTag={setStateTag}
        charValidation={charValidation}
        expanded={expanded}
        setOpenDescriptionModal={setOpenDescriptionModal}
        setActiveModalData={setActiveModalData}
        setIsDescriptionEdit={setIsDescriptionEdit}
        setExpanded={setExpanded}
        setEditIndex={setEditIndex}
        setOpenTagModal={setOpenTagModal}
        editData={editData}
        handleTableChange={handleTableChange}
        handleTableJob={handleTableJob}
        openDescModal={openDescModal}
        viewTagModal={viewTagModal}
        viewKfpModal={viewKfpModal}
        setSubElementId={setSubElementId}
        pSpecificationId={pSpecificationId}
        setPSpecificationId={setPSpecificationId}
        reloadTable={reloadTable}
        getEmptySpecificationApi={getEmptySpecificationApi}
        loadingSave={loadingSave}
      />
      <div className="d-flex ps-5 py-4 bg-white">
        <button
          onClick={() => {
            if (!isAddNewExist
                  && parentSpecificationId
                  && parentSpecificationId !== ZERO_UUID) addSpecLine();
          }}
          type="button"
          className={!isAddNewExist
            && parentSpecificationId
            && parentSpecificationId !== ZERO_UUID ? 'link-btn ms-5 ' : 'link-btn ms-5 disabled'}
          id="add-subtype"
        >
          <span className="icon-plus-square me-2" />
          Add Spec line
        </button>
      </div>
    </div>
  );
};
export default InnerTable1;
