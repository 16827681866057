import React from 'react';
import Select from 'react-select';
import InputType1 from '../../../../common/InputType1';
import MajorSpecPackagesList from './MajorSpecPackagesList';

const CreateSpecPackages = (props) => {
  const {
    formData,
    handleChangeForm,
    formDataVesselType,
    ProjectTypeList,
    handleChangeType,
    isExpand,
    formDataToFillList,
    setIsExpand,
    ageOfVesselList
  } = props;

  return (
    <>
      <div className="" data-testid="create-spec">
        <div className="row">
          <div className="col-3">
            <InputType1
              value={formData.packageName}
              // onChange={(e) => handleChangeForm(e, 'packageName', '')}
              className="modal-package"
              label="Package Name"
              name="packageName"
              id="pname"
              title={formData.packageName}
              readOnly
              noMargin
            />
          </div>
          <div className="col-3">
            <label>
              Project Type
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              menuPosition="fixed"
              value={ProjectTypeList.filter((option) => option.value === formData.projectType)}
              onChange={(option) => handleChangeForm(option, 'projectType', 'select')}
              className="modal-select"
              label="code"
              options={ProjectTypeList}
              id="projectType"
            />
          </div>
          <div className="col-3">
            <label>
              Vessel Type
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              menuPosition="fixed"
              value={formDataVesselType.filter((option) => option.value === formData.vesselType)}
              onChange={(option) => handleChangeForm(option, 'vesselType', 'select')}
              className="modal-select"
              label="code"
              options={formDataVesselType}
              id="vesselType"
            />
          </div>
          {/* <div className="col-3">
            <InputType1
              value={formData.ageOfVessel}
              onChange={(e) => handleChangeForm(e, 'ageOfVessel', '')}
              className="modal-inputs"
              label="Age of the vessel"
              name="ageOfVessel"
              isMandatory="true"
              id="age"
            />
          </div> */}
          <div className="col-3">
            <label>
              Age of the vessel
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              menuPosition="fixed"
              value={ageOfVesselList.filter((option) => option.value === formData.ageOfVessel)}
              onChange={(option) => handleChangeForm(option, 'ageOfVessel', 'select')}
              className="modal-select"
              label="code"
              options={ageOfVesselList}
              id="ageOfVessel"
            />
          </div>
        </div>
        <MajorSpecPackagesList
          isExpand={isExpand}
          formDataToFillList={formDataToFillList}
          setIsExpand={setIsExpand}
          handleChangeType={handleChangeType}
        />
      </div>
    </>
  );
};
export default CreateSpecPackages;
