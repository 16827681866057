import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';

const {
  GetSpecificationList,
  SaveNonSpec,
  DeletSpecification
} = apiEndPoints;

export const getNonSpec = (payload) => {
  return serviceHandler({
    url: `${GetSpecificationList}`,
    methordType: 'post',
    payload
  });
};
export const saveNonSpec = (payload) => {
  return serviceHandler({
    url: `${SaveNonSpec}`,
    methordType: 'post',
    payload
  });
};
export const deleteNonSpec = (payload) => {
  return serviceHandler({
    url: `${DeletSpecification}?id=${payload.id}`,
    methordType: 'put',
    payload
  });
};
