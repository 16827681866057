/* istanbul ignore file */
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const Users = () => {
  const navigate = useNavigate();
  const location = window.location.pathname;
  const [count, setCount] = useState(0);

  const subHeaderList = [
    { name: 'User Roles & Permissions', url: 'roles' },
    { name: 'User List', url: 'list' }
  ];
  return (
    <div className="page-wrapper pt-3">
      <div className="sub-header" data-testid="user-permission">
        <div className="d-flex  justify-content-center">
          <>
            {subHeaderList ? (
              <div className="d-flex item-set col-gap-2">
                {subHeaderList.map((i) => {
                  return (
                    <button
                      onClick={() => navigate(`/admin/users/${i.url}`)}
                      className={location.includes(`/admin/users/${i.url}`) ? 'rnd-btn active' : 'rnd-btn'}
                      type="button"
                    >
                      {i.name}
                      {location.includes(`/admin/users/${i.url}`) && count !== '' ? <span className="count-bar">{count}</span> : ''}
                    </button>
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </>
        </div>
      </div>
      <Outlet context={[count, setCount]} />
    </div>
  );
};
export default Users;
