/* istanbul ignore file */
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ setCount, expandedSidebar }) => {
  const pathName = window.location.pathname;
  const navigate = useNavigate();
  const goToURL = (url) => {
    if (pathName !== url) {
      setCount(0);
    }
    navigate(url);
  };
  return (
    <div className={expandedSidebar ? 'side-menu-wrap admin expanded' : 'side-menu-wrap'} data-testid="admin-sidebar">
      <div className="scroll-area">
        <ul className="menu-item">
          <li className="sub-head">Vessels Data</li>
          <li
            className={pathName === '/admin/data/vesseldata' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/vesseldata');
            }}
          >
            <div className="icon">
              <span className="icon-vessel" />
            </div>
            <p>Vessel Types</p>
          </li>
          <li className="sub-head">Projects Data</li>
          <li
            className={pathName.includes('/admin/data/general-config') ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/general-config');
            }}
          >
            <div className="icon">
              <span className="icon-two-gear" />
            </div>
            <p>General config</p>
          </li>
          <li
            className={pathName.includes('/admin/data/spec') ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/spec/general');
            }}
          >
            <div className="icon">
              <span className="icon-art-board" />
            </div>
            <p>Specification Setup</p>
          </li>
          <li
            className={pathName === '/admin/data/task' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/task');
            }}
          >
            <div className="icon">
              <span className="icon-task " />
            </div>
            <p>Task</p>
          </li>
          <li
            className={pathName === '/admin/data/tags' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/tags');
            }}
          >
            <div className="icon">
              <span className="icon-tag" />
            </div>
            <p>Tags</p>
          </li>
          <li
            className={pathName === '/admin/data/kfp' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/kfp');
            }}
          >
            <div className="icon">
              <span className="icon-calculater" />
            </div>
            <p>KFP</p>
          </li>
          <li
            className={pathName === '/admin/data/templates' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/templates');
            }}
          >
            <div className="icon">
              <span className="icon-blocks" />
            </div>
            <p>Report Templates </p>
          </li>
          <li className="sub-head">General Data</li>
          <li
            className={pathName === '/admin/data/currency' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/currency');
            }}
          >
            <div className="icon">
              <span className="icon-dollar-circle" />
            </div>
            <p>Currency </p>
          </li>
          <li
            className={pathName === '/admin/data/ac-code' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/ac-code');
            }}
          >
            <div className="icon">
              <span className="icon-money-card" />
            </div>
            <p>AC Code</p>
          </li>
          <li
            className={pathName === '/admin/data/uom' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/uom');
            }}
          >
            <div className="icon">
              <span className="icon-stop-watch" />
            </div>
            <p>UOM</p>
          </li>
          <li className="sub-head">Yard Data</li>
          <li
            className={pathName === '/admin/data/yard' ? 'active' : ''}
            onClick={() => {
              goToURL('/admin/data/yard');
            }}
          >
            <div className="icon">
              <span className="icon-yard " />
            </div>
            <p>Yard Details </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
