/* eslint-disable no-unused-vars */
import React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Select from 'react-select';
import _, { cloneDeep } from 'lodash';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';
import showAlert from '../../../../utils/alert';

const CreateYard = ({ formData, setFormData, countryList, error, setError }) => {
  const { charValidation, handleValidation, ValidateEmail, addColor } = helper;
  const { YardType } = helper;
  const myStyle = {};

  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;
    } else if (field === 'phone') {
      const fdd = fd.contactInfo;
      fdd[field] = charValidation(e.target.value, 30);
      fd.contactInfo = fdd;
    } else if (field === 'contactPersonName') {
      const fdd = fd.contactInfo;
      fdd[field] = charValidation(e.target.value, 100);
      fd.contactInfo = fdd;
    } else if (field === 'latitude' || field === 'longitude') {
      const loc = { ...fd.locationInfo };
      loc[field] = charValidation(e.target.value.replace(/[^0-9,.,-]/g, ''), 25);
      fd.locationInfo = loc;
    } else if (field === 'email') {
      const fdd = fd.contactInfo;
      fdd[field] = e;
      if (fdd[field].length > 0) {
        setError(false);
      }
    } else fd[field] = charValidation(e.target.value);
    setFormData(fd);
    handleValidation(['yardName', 'countryId', 'dockTypeId', 'latitude', 'longitude', 'contactPersonName', 'phone', 'email'], fd, field);
  };
  const addType = () => {
    const fd = cloneDeep(formData);
    if (fd.dockTypeInfo) {
      fd.dockTypeInfo.push({
        dockTypeName: '',
        dockTypeId: '',
        length: '',
        breadth: ''
      });
    } else {
      fd.dockTypeInfo = [{
        dockTypeName: '',
        dockTypeId: '',
        length: '',
        breadth: ''
      }];
    }

    setFormData(fd);
  };
  const onDelete = (key) => {
    const fd = cloneDeep(formData);
    fd.dockTypeInfo.splice(key, 1);
    setFormData(fd);
  };
  const checkEmail = (e) => {
    if (ValidateEmail(e)) {
      return true;
    }
    showAlert('Please enter a valid email', 'error');
    return false;
  };
  const validateFieldsInForm = (currentDataV) => {
    currentDataV.dockTypeInfo.map((valid, key) => {
      console.log(valid, 'valid');

      ['dockTypeId', 'length', 'breadth'].forEach((item) => {
        const dId = item + key;

        if (valid[item] === null || valid[item] === '' || valid[item] === ' ') {
          addColor(dId, 'add');
        } else {
          addColor(dId, '');
        }
      });

      return null;
    });
  };
  const onDockTypeInfoChange = (e, name, key, select) => {
    const fd = cloneDeep(formData);
    if (select) {
      fd.dockTypeInfo[key][name] = e.value;
      fd.dockTypeInfo[key].dockTypeName = e.label;
    } else {
      const num = e?.target?.value?.substr(0, 6).replace(/[^0-9,.]/g, '');
      if (/^[0-9]*(\.[0-9]{0,2})?$/.test(num)) {
        fd.dockTypeInfo[key][name] = num;
      } else {
        fd.dockTypeInfo[key][name] = Number(_.round(num, 2));
      }
    }
    setFormData(fd);
    validateFieldsInForm(fd);
  };

  return (
    <>
      <div className="scrollable-modal p-3" data-testid="create-yard">
        <div className="row mt-4">
          <div className="col-8">
            <InputType1
              autoFocus
              onChange={(e) => {
                handleChange(e, 'yardName', '');
              }}
              value={formData.yardName}
              className="modal-inputs"
              label="Yard Name"
              id="yardName"
              isMandatory="true"
            />
          </div>
          <div className="col-4">
            <label>
              Country
              <span className="text-primaryRed-1 m-2">*</span>
            </label>
            <Select
              value={countryList.filter((option) => option.value === formData.countryId)}
              onChange={(option) => handleChange(option, 'countryId', 'select')}
              className="modal-select mt-2"
              label="Country"
              placeholder="Select"
              options={countryList}
              id="countryId"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <InputType1
              onChange={(e) => {
                handleChange(e, 'latitude', '');
              }}
              value={formData.locationInfo?.latitude}
              className="modal-inputs"
              label="Latitude"
              id="latitude"
              name="latitude"
              isMandatory="true"
            />
          </div>
          <div className="col-4">
            <InputType1
              onChange={(e) => {
                handleChange(e, 'longitude', '');
              }}
              value={formData.locationInfo?.longitude}
              className="modal-inputs"
              label="Longitude"
              id="longitude"
              isMandatory="true"
            />
          </div>
        </div>
        {formData.dockTypeInfo?.map((item, key) => {
          return (
            <div className="mt-3 bg-sky-white p-3 md-radius">
              <div className="d-flex justify-content-between align-items-center">
                <div className="head-14">
                  Doc Type
                  {' '}
                  {key + 1}
                </div>
                <button data-testid="on-delete" type="button" className="link-btn " onClick={() => { onDelete(key); }}>
                  <span className="icon-close font-26"> </span>
                </button>
              </div>

              <div className="row mt-3 d-flex">
                <div className="col-6">
                  <label>
                    Dock Type
                    <span className="text-primaryRed-1 m-2">*</span>
                  </label>
                  <Select
                    value={YardType.filter((option) => option.value === item.dockTypeId)}
                    onChange={(option) => onDockTypeInfoChange(option, 'dockTypeId', key, 'select')}
                    className="modal-select mt-2"
                    label="Yard Type"
                    placeholder="Select"
                    options={YardType}
                    id={'dockTypeId'.concat(key)}
                    data-testid="on-dockTypeId"
                  />
                </div>
                <div className="col-3">
                  <InputType1
                    onChange={(e) => {
                      onDockTypeInfoChange(e, 'length', key);
                    }}
                    value={item.length}
                    className="modal-inputs"
                    label="Length(m)"
                    id={'length'.concat(key)}
                    data-testid="on-length"
                    name="length"
                    isMandatory="true"
                  />
                </div>
                <div className="col-3">
                  <InputType1
                    onChange={(e) => {
                      onDockTypeInfoChange(e, 'breadth', key);
                    }}
                    value={item.breadth}
                    className="modal-inputs"
                    label="Breadth(m)"
                    id={'breadth'.concat(key)}
                    data-testid="on-breadth"
                    name="breadth"
                    isMandatory="true"
                  />
                </div>

              </div>
            </div>
          );
        })}
        <button id="addType" type="button" className="link-btn mt-4" onClick={addType}>{formData.dockTypeInfo?.length === 0 ? 'Add Type' : 'Add More'}</button>
        <div className="row mt-4">
          <div className="head-16 mb-3">Contact Details</div>
          <div className="col-6">
            <InputType1
              onChange={(e) => {
                handleChange(e, 'contactPersonName', '');
              }}
              value={formData.contactInfo?.contactPersonName}
              className="modal-inputs"
              label="Contact Person"
              id="contactPersonName"
              isMandatory="true"
            />
          </div>
          <div className="col-6">
            <InputType1
              onChange={(e) => {
                handleChange(e, 'phone', '');
              }}
              value={formData.contactInfo?.phone}
              className="modal-inputs"
              label="Phone"
              id="phone"
              isMandatory="true"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="input-label">
              <label className="text-sm">
                Email
                <span className="text-primaryRed-1 ml-2">*</span>
              </label>
            </div>
            <ReactMultiEmail
              className={error ? 'error-fields' : ''}
              style={myStyle}
              emails={formData?.contactInfo?.email === null ? [] : formData?.contactInfo?.email}
              id="email"
              validateEmail={(e) => checkEmail(e)}
              // className={error ? 'modal-input error-fields' : 'modal-input'}
              onChange={(e) => handleChange(e, 'email', '')}
              getLabel={(
                email,
                index,
                removeEmail
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span className="cursor-pointer" data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateYard;
