import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';

const {
  getGeneralTariffUrl,
  saveGeneralTariffUrl,
  getAllVesselTypeUrl,
  publishSpecificationDataUrl,
  getSpecificationStatusUrl,
  GetVesselCategoryListNewUrl
} = apiEndPoints;

export const getGeneralTariff = (payload) => {
  return serviceHandler({
    url: `${getGeneralTariffUrl}?type=1&isPublished=false`,
    methordType: 'get',
    payload
  });
};
export const getSpecificationStatus = (payload) => {
  return serviceHandler({
    url: `${getSpecificationStatusUrl}?type=${payload}`,
    methordType: 'get',
    payload
  });
};
export const saveGeneralTariff = (payload) => {
  return serviceHandler({
    url: `${saveGeneralTariffUrl}`,
    methordType: 'post',
    payload
  });
};
export const getVesselType = (payload) => {
  return serviceHandler({
    url: `${getAllVesselTypeUrl}`,
    methordType: 'get',
    payload
  });
};

export const publishList = (payload) => {
  return serviceHandler({
    url: `${publishSpecificationDataUrl}?type=${payload}`,
    methordType: 'get',
    payload
  });
};

export const GetVesselCategoryListNew = (payload) => {
  return serviceHandler({
    url: `${GetVesselCategoryListNewUrl}`,
    methordType: 'get',
    payload
  });
};
