/* eslint-disable prefer-template */
/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../../common/pagination';
import helper from '../../../../../utils/helper';

const PoReportTable = ({ tableData, downloadReport,
  setPage, setPageSize, page, pageSize, getColor }) => {
  const { formatNumber } = helper;
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  return (
    <div className="p-4" data-testid="poProgressReportComponent">
      {tableData && tableData?.items && tableData?.items?.length ? (
        <>
          <div>
            <div className="border-down row py-3">
              <div className="col-1 font-semibold">Ref #</div>
              <div className="col-2 font-semibold">Spec/Non Spec</div>
              <div className="col-1 font-semibold">PO/PR #</div>
              <div className="col-2 font-semibold">PO/PR Type</div>
              <div className="col-2 font-semibold">PO/PR Name</div>
              <div className="col-2 font-semibold p-0">Amount</div>
              <div className="col-2 font-semibold p-0">Status</div>
            </div>
            <div className="scroll-wrap">
              {tableData?.items?.map((i) => {
                return (
                  <div className="border-down row py-3">
                    <div className="col-1 ">
                      <div className="d-flex rw-1">
                        <span data-tooltip-id="project-job" data-tooltip-content={i.specificationNo}>
                          {i.specificationNo}
                        </span>
                      </div>
                    </div>
                    <div className="text-ellipsis col-2">
                      <span data-tooltip-id="project-job" data-tooltip-content={i?.title}>
                        {i.title}
                      </span>

                    </div>
                    <div className="col-1 text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i.poPrNo || '-'}>
                        {i.poPrNo || '-'}
                      </span>

                    </div>
                    <div className="col-2 text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i.poPrTypeName || '-'}>
                        {i.poPrTypeName || '-'}
                      </span>

                    </div>
                    <div className="col-2 text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i.poName || ''}>
                        {i.poName || '-'}
                      </span>

                    </div>
                    <div className="col-2 text-ellipsis ">
                      <span data-tooltip-id="project-job" data-tooltip-content={i?.amount ? i?.currencySymbol + ' ' + formatNumber(i.amount) : ''}>
                        {i?.amount ? i.currencySymbol : '-'}
                        {' '}
                        {formatNumber(i.amount)}
                      </span>

                    </div>
                    <div className="col-2 text-ellipsis " data-tooltip-id="project-job" data-tooltip-content={i.status || '-'}>
                      <div className="d-flex align-items-center">
                        <span className="icon-disc" style={{ color: getColor(i.status) }} />
                        <div className="ms-2 opacity-6">{i.status || '-'}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {tableData?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className=" footer-jobprogress">© 2024 ThinkPalm</div>

            </div>
            <button
              onClick={() => downloadReport()}
              type="button"
              className="primary-btn large mx-3"
            >
              Download Report
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="fixed-footer card d-flex justify-content-end">

            <div className="text-black font-12 col-6">
              <div className="mt-2 mb-2">© 2024 ThinkPalm</div>

            </div>
          </div>
        </>
      )}
      <Tooltip id="project-job" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default PoReportTable;
