import React from 'react';
import { cloneDeep } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';

const SectionsSubTable = ({ tableData, setActiveSpec, expanded, renderVariance, renderCell,
  setExpanded, renderServiceCell, renderVariancePercentage }) => {
  /* istanbul ignore next */
  const columns = [
    {
      Header: '',
      expander: true,
      resizable: false,
      width: 30,
      Expander: ({ isExpanded, index }) => (isExpanded ? (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(tableData);
            const expandedRows = quotesCopy?.map(() => {
              return false;
            });
            setExpanded(expandedRows);
            setActiveSpec();
          }}
          className="font-13 icon-arrow-thin-right circle-arrow circle-arrow-small rotate-90 "
        />
      ) : (
        <span
          onClick={() => {
            const quotesCopy = cloneDeep(tableData);
            const expandedRows = quotesCopy?.map((data, key) => {
              if (index === key) {
                setActiveSpec(index);
                return true;
              }
              return false;
            });
            setExpanded(expandedRows);
          }}
          className="font-13 icon-arrow-thin-right circle-arrow circle-arrow-small"
        />
      ))
    },
    {
      Header: 'Spec and Specification',
      expander: false,
      width: 70,
      resizable: false,
      accessor: 'number',
      sortable: false,
      Cell: (row) => {
        const { original } = row;
        return <div className="p2-4" data-tooltip-id="project-specName" data-tooltip-content={original.number}>{original.number}</div>;
      }
    },
    {
      Header: '',
      expander: false,
      resizable: false,
      width: 125,
      accessor: 'specName',
      sortable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specName" data-tooltip-content={original.specName} className="text-ellipsis">
            {original.specName}
          </span>
        );
      }
    },
    {
      Header: 'Yard Est',
      width: 110,
      accessor: 'yardEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'specification', 'yardEstimate');
      }
    },
    {
      Header: 'Owner Est',
      width: 110,
      accessor: 'ownerEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'specification', 'ownerEstimate');
      }
    },
    {
      Header: 'Project Est',
      width: 110,
      accessor: 'projectEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'specification', 'projectEstimate');
      }
    },
    {
      Header: 'yard Actual',
      width: 110,
      accessor: 'yardActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'specification', 'yardActual');
      }
    },
    {
      Header: 'owner Actual',
      width: 140,
      accessor: 'ownerActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'specification', 'ownerActual');
      }
    },
    {
      Header: 'project Actual',
      width: 140,
      accessor: 'projectActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderCell(row, 'specification', 'projectActual');
      }
    },
    {
      Header: 'variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.projectEstimate, original.projectActual, row, 'specification');
      }
    },
    {
      Header: 'variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.projectEstimate, original.projectActual, row);
      }
    }
  ];
  /** Service line */
  /* istanbul ignore next */
  const columns1 = [
    {
      Header: '',
      expander: false,
      width: 30,
      resizable: false
    },
    {
      Header: '',
      expander: false,
      resizable: false,
      width: 90,
      accessor: 'number',
      sortable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specName" data-tooltip-content={original.number} className="ps-4 text-ellipsis">
            {original.number}
          </span>
        );
      }
    },
    {
      Header: '',
      expander: false,
      resizable: false,
      width: 105,
      accessor: 'serviceLineName',
      sortable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <span data-tooltip-id="project-specName" data-tooltip-content={original.serviceLineName} className="text-ellipsis ps-4">
            {original.serviceLineName}
          </span>
        );
      }
    },
    {
      Header: 'Yard Est',
      width: 110,
      accessor: 'yardEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderServiceCell(row, 'yardEstimate');
      }
    },
    {
      Header: 'Owner Est',
      width: 110,
      accessor: 'ownerEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderServiceCell(row, 'ownerEstimate');
      }
    },
    {
      Header: 'Project Est',
      width: 110,
      accessor: 'projectEstimate',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#ffdfd7',
        color: '#7a4439'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(251 244 241)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderServiceCell(row, 'projectEstimate');
      }
    },
    {
      Header: 'yard Actual',
      width: 110,
      accessor: 'yardActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderServiceCell(row, 'yardActual');
      }
    },
    {
      Header: 'owner Actual',
      width: 140,
      accessor: 'ownerActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderServiceCell(row, 'ownerActual');
      }
    },
    {
      Header: 'project Actual',
      width: 140,
      accessor: 'projectActual',
      sortable: false,
      expander: false,
      headerStyle: {
        backgroundColor: '#b5ddff',
        color: '#265d8e'
      },
      style: { textAlign: 'right',
        backgroundColor: 'rgb(227 244 253)',
        borderBottom: '1px solid #fff'
      },
      resizable: false,
      Cell: (row) => {
        return renderServiceCell(row, 'projectActual');
      }
    },
    {
      Header: 'variance',
      accessor: 'variance',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariance(original.projectEstimate, original.projectActual, row, 'serviceline');
      }
    },
    {
      Header: 'variance(%)',
      accessor: 'variancepercent',
      sortable: false,
      expander: false,
      style: { textAlign: 'right' },
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return renderVariancePercentage(original.projectEstimate, original.projectActual, row, 'serviceline');
      }
    }
  ];
  const showPagination = false;
  return (
    <div className="ps-4" data-testid="sections-sub">
      <ReactTable
        className=""
        data={tableData}
        columns={columns}
        pageSize={tableData && tableData.length > 0 ? tableData.length : 1}
        showPagination={showPagination}
        expanded={expanded}
        TheadComponent={() => null}
        SubComponent={(mainProp) => {
          const { servLines } = mainProp.original;
          /* istanbul ignore next */
          return (
            <div className="text-content-grey">
              <ReactTable
                className=""
                data={servLines}
                columns={columns1}
                pageSize={servLines && servLines.length > 0 ? servLines.length : 1}
                showPagination={showPagination}
                TheadComponent={() => null}
              />
            </div>
          );
        }}
      />
      <Tooltip id="project-specName" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default SectionsSubTable;
