import React from 'react';
import InputType1 from '../../../common/InputType1';

function AddReference(props) {
  const { handleChange, formData } = props;
  return (
    <div className="container p-4 mt-3 mb-5" data-testid="add-links">
      <div className="row mb-3">
        <div className="pb-3 col-12">
          <InputType1 autoFocus className="modal-input" label="URL" name="url" id="url" value={formData.url} onChange={(e) => handleChange(e, 'url')} isMandatory="true" />
        </div>
      </div>
      <div className="row  mb-3">
        <div className="col-12">
          <InputType1 className="modal-input" label="Description" name="description" id="description" value={formData.description} onChange={(e) => handleChange(e, 'description')} isMandatory="true" />
        </div>
      </div>
    </div>
  );
}

export default AddReference;
