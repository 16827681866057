import React from 'react';
import { cloneDeep } from 'lodash';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const CreateSubFolder = ({ formData, setFormData, cancel, handleCreateNew }) => {
  const { charValidation } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = charValidation(e.target.value);
    setFormData(fd);
  };
  return (
    <>
      <div className="card mt-3 p-4 mb-4 bg-sky-blue animate__animated animate__bounceIn" data-testid="dms-2">
        <div className="align-items-end d-flex row">
          <div className="col-8">
            <InputType1
              autoFocus
              id="name"
              onChange={(e) => handleChange(e, 'name')}
              value={formData?.name}
              className="modal-inputs small"
              label="Name"
              isMandatory="true"
            />
          </div>
          <div className="col-4 col-gap-1 d-flex ">
            <button id="cancel" className="secondary-btn " type="button" onClick={() => cancel()}>
              Cancel
            </button>
            <button id="save" className="primary-btn " type="button" onClick={() => handleCreateNew()}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateSubFolder;
