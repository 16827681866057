import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from './AdminHeader';

const Admin = () => {
  return (
    <div className="page-wrapper1">
      <div className="header" data-testid="admin-main">
        <AdminHeader headerType="data-config" subHeaderType="general-tariff" heading="Specification Setup" />
      </div>
      <Outlet />
    </div>
  );
};
export default Admin;
