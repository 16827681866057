/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useNavigate } from 'react-router';
import brandLogo from '../../../images/brand-logo.svg';
import Caption from '../common/caption';
import Footer from '../common/footer';

const ResetSuccess = () => {
  const navigate = useNavigate();
  const handleBackToLogin = () => {
    navigate('/login');
  };
  return (
    <div data-testid="resetsuccess">
      <div className="wrapper login-wrap">
        <Caption />
        <div className="card login-card">
          <div className="brand-logo mb-5">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">
              <img src={brandLogo} alt="Maridock Logo" className="brand-img" />
            </a>
          </div>
          <div className="d-flex flex-column align-items-center">
            <span className="icon-check-rnd tick-green font-50 pb-5" />
            <h4 className="main-head">Password Reset</h4>
            <p className="mt-5 mt-2 font-18">Your password has been successfully reset </p>
          </div>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <button data-testid="back" className="primary-btn xlarge full-width mb-5 mt-5" type="button" onClick={handleBackToLogin}>
            Continue to Login
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default ResetSuccess;
