// import imageIcon from '../../images/image.png';
// import docIcon from '../../images/doc.png';
// import xlIcon from '../../images/xls.png';
// import pdfIcon from '../../images/pdf.png';
// import videoIcon from '../../images/video.png';
/* istanbul ignore file */

export const fileImages = {
  JPG: 'icon-file-picture',
  jpg: 'icon-file-picture',
  bmp: 'icon-file-picture',
  png: 'icon-file-picture',
  PNG: 'icon-file-picture',
  tif: 'icon-file-picture',
  tiff: 'icon-file-picture',
  jpeg: 'icon-file-picture',
  'image/jpeg': 'icon-file-picture',
  'image/png': 'icon-file-picture',
  'image/jpg': 'icon-file-picture',
  'application/pdf': 'icon-file-pdf',
  docx: 'icon-file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'icon-file-word',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'icon-file-excel',
  jfif: 'icon-file-picture',
  pdf: 'icon-file-pdf',
  string: 'icon-file-picture',
  xlsx: 'icon-file-excel',
  xls: 'icon-file-excel',
  mp4: 'icon-file-video',
  mpeg4: 'icon-file-video',
  '': 'icon-file-empty',
  zip: 'icon-file-zip',
  'application/x-zip-compressed': 'icon-file-zip'
};
