/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React, { useState } from 'react';
import _, { cloneDeep, map } from 'lodash';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import SidePanel from '../../../common/sidePanel';
import ViewMore from '../PhasePage/Repair/viewMore';
import Loader from '../../../common/loader';

const SpecCard = (props) => {
  const { projectId, projectCurrecySymbol, specList, showsubSec, onSecOpen,
    isTSI, isLoading, specToDownload, setSpecToDownload, sectionToDownload,
    setSectionToDownload } = props;
  const isFromDownloadSpec = true;
  const [viewMore, setViewMore] = useState(false);

  const showMoreSpec = (spec) => {
    setViewMore(spec);
  };

  const onCloseViewMore = () => {
    setViewMore(false);
  };

  const checkBoxStatus = (type, indexMain, subsec, indexSub) => {
    let status = false;
    if (type === 'main') {
      const idx = sectionToDownload?.indexOf(subsec?.projectSpecId);
      if (idx > -1) {
        status = true;
      }
    } else {
      const idx = specToDownload?.indexOf(subsec?.projectSpecId);
      if (idx > -1) {
        status = true;
      }
    }
    return status;
  };
  const handleCheckSpec = (type, indexMain, subsec, indexSub) => {
    console.log('subsec', type, subsec);

    if (type === 'main') {
      const i = cloneDeep(sectionToDownload);
      const idx = i.indexOf(subsec?.projectSpecId);

      if (idx > -1) {
        const pexIds = map(subsec?.specList, 'projectSpecId');
        console.log('pexIds', pexIds);
        if (pexIds?.length) {
          const ii = cloneDeep(specToDownload);
          pexIds.forEach((ele) => {
            const idxx = ii.indexOf(ele);
            if (idxx > -1) {
              ii.splice(idxx, 1);
            }
          });
          setSpecToDownload(ii);
        }
        i.splice(idx, 1);
      } else {
        const pexIds = map(subsec?.specList, 'projectSpecId');
        console.log('pexIds 2', pexIds);
        if (pexIds?.length) {
          const ii = cloneDeep(specToDownload);
          pexIds.forEach((ele) => {
            const idxx = ii.indexOf(ele);
            if (idxx === -1) {
              ii.push(ele);
            }
          });
          setSpecToDownload(ii);
        }
        i.push(subsec?.projectSpecId);
      }
      console.log('section', i);
      setSectionToDownload(i);
    } else {
      /** ELSE CASE - sub */
      const i = cloneDeep(specToDownload);
      const idx = i.indexOf(subsec?.projectSpecId);
      if (idx > -1) {
        i.splice(idx, 1);
        const ii = cloneDeep(sectionToDownload);
        const idxv = ii.indexOf(subsec?.parentSpecificationId);
        if (idxv > -1) {
          ii.splice(idxv, 1);
          setSectionToDownload(ii);
        }
      } else {
        i.push(subsec?.projectSpecId);
        const pexIds = map(specList[indexMain].specList, 'projectSpecId');
        console.log('pexIds 4', pexIds);
        if (pexIds?.length) {
          let doPush = true;
          pexIds.forEach((ele) => {
            const idxx = i.indexOf(ele);
            if (idxx === -1) {
              doPush = false;
            }
          });
          if (doPush) {
            const t = cloneDeep(sectionToDownload);
            t.push(subsec?.parentSpecificationId);
            setSectionToDownload(t);
          }
        }
      }
      setSpecToDownload(i);
    }
  };

  return (
    <div className="spc-map" data-testid="specCard">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="d-flex border-down py-2 ps-3">
            <div className="col-1 font-bold">SEC#</div>
            <div className="col-11 font-bold">SPEC SECTION</div>
          </div>
          {specList?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6">No data found</div>}
          {specList?.map((sec, indexMain) => {
            return (
              <div className="highlight-tender">
                <div className="d-flex border-down py-2 ps-3">
                  <div className="col-1 d-flex align-items-center">
                    <div
                      className="check-box"
                    >
                      <input
                        onClick={() => handleCheckSpec('main', indexMain, sec)}
                        checked={checkBoxStatus('main', indexMain, sec)}
                        type="checkbox"
                        value={sec.projectSpecId}
                        id={sec.projectSpecId}
                      />
                      <label htmlFor={sec.projectSpecId} />
                    </div>
                    <span
                      className="cursor-pointer"
                      onClick={() => handleCheckSpec('main', indexMain, sec)}
                      data-tooltip-id="project-sectitle"
                      data-tooltip-content={sec.specificationNo}
                    >
                      {_.truncate(sec.specificationNo, { length: 7 })}
                    </span>
                  </div>
                  <div className="col-11">
                    <span
                      className="cursor-pointer"
                      onClick={() => handleCheckSpec('main', indexMain, sec)}
                      data-tooltip-id="project-sectitle"
                      data-tooltip-content={sec?.title}
                    >
                      {_.truncate(sec.title, { length: 80 })}
                    </span>
                  </div>
                </div>
                <div className="respo-scroll">
                  {sec?.specList?.map((subsec, indexSub) => {
                    return (
                      <>
                        <div className="d-flex py-2 ps-3 ">
                          <div className="col-1" />
                          <div className="col-11 d-flex align-items-center justify-content-between bg-sky-white ps-3">
                            <div className="d-flex justify-content-start">
                              <span
                                className={
                                subsec?.projectSpecId === showsubSec
                                  ? ' me-3 font-14 icon-sort-up brand-color pointer  rotate-180'
                                  : ' me-3 font-14 icon-sort-up brand-color rotate-90 pointer '
                              }
                                onClick={() => {
                                  onSecOpen(subsec?.projectSpecId, false);
                                }}
                              />
                              <div className="d-flex align-items-center">
                                <div
                                  className="check-box"
                                >
                                  <input
                                    onClick={() => handleCheckSpec('sub', indexMain, subsec, indexSub)}
                                    checked={checkBoxStatus('sub', indexMain, subsec, indexSub)}
                                    type="checkbox"
                                    value={subsec?.projectSpecId}
                                    id={subsec?.projectSpecId}
                                  />
                                  <label htmlFor={subsec?.projectSpecId} />
                                </div>
                              </div>
                              <div className="">
                                <span
                                  onClick={() => handleCheckSpec('sub', indexMain, subsec, indexSub)}
                                  className="me-3 w-100 cursor-pointer"
                                  data-tooltip-id="project-sectitle"
                                  data-tooltip-content={subsec?.specificationNo}
                                >
                                  {_.truncate(subsec?.specificationNo, { length: 10 })}
                                </span>
                              </div>
                              <div className="">
                                <span
                                  className="cursor-pointer"
                                  onClick={() => handleCheckSpec('sub', indexMain, subsec, indexSub)}
                                  data-tooltip-id="project-sectitle"
                                  data-tooltip-content={subsec?.title}
                                >
                                  {_.truncate(subsec?.title, { length: 80 })}
                                </span>
                              </div>
                            </div>
                            <div className="h-full bg-brand-color me-1 d-flex align-items-center">
                              <span
                                id="showMoreSpecId"
                                className="icon-eye font-22 cursor-pointer p-3 white"
                                data-tooltip-id="project-sectitle"
                                data-tooltip-content="View More"
                                onClick={() => {
                                  showMoreSpec(subsec);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {subsec?.projectSpecId === showsubSec
                        && subsec?.specList?.map((serv, num) => {
                          return (
                            <>
                              <div className="">
                                {num === 0 && (
                                <div className="d-flex ps-3 align-items-center font-semibold">
                                  <div className="col-1" />
                                  <div className="col-2 px-3 py-2 border-left border-down pb-2">Service LN#</div>
                                  <div className="col-5 px-3 py-2  border-down pb-2">Service Lines</div>
                                  <div className="col-2 px-3 py-2  border-down pb-2">UOM</div>
                                  <div className="col-2 px-3 py-2  border-down pb-2">Qty</div>
                                </div>
                                )}
                                <div className="d-flex ps-3">
                                  <div className="col-1" />
                                  <div className="col-2 px-3 py-2  border-left border-down">{serv?.specificationNo}</div>
                                  <div className="col-5 px-3 py-2 text-justify text-break-all border-down">
                                    <span>{serv?.title}</span>
                                  </div>
                                  <div className="col-2 px-3 py-2  border-down text-ellipsis">
                                    <span data-tooltip-id="project-sectitle" data-tooltip-content={serv?.uomName}>
                                      {_.truncate(serv?.uomName, { length: 7 })}
                                    </span>
                                  </div>
                                  <div className="col-2 px-3 py-2  border-down pb-2">
                                    <span data-tooltip-id="project-sectitle" data-tooltip-content={serv?.quantity}>
                                      {_.truncate(serv?.quantity, { length: 7 })}
                                    </span>
                                  </div>
                                </div>
                                {subsec?.specList.length === num + 1 && (
                                <div className="d-flex ps-3  mb-4 align-items-center">
                                  <div className="col-1" />
                                  <div className="col-11 body-gray px-3 border-down font-13 py-3">
                                    <label> Last Updated :</label>
                                    {' '}
                                    <span className="value">
                                      {subsec?.lastUpdatedBy}
                                      ,
                                      {' '}
                                      {moment.utc(subsec?.lastUpdatedAt).format('DD-MMM-YYYY (UTC)')}
                                    </span>
                                  </div>
                                </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
                <Tooltip id="project-sectitle" className="tooltip" />
              </div>
            );
          })}
        </div>
      )}
      {viewMore && (
        <SidePanel
          title={(
            <span data-tooltip-id="project-more" data-tooltip-content={viewMore.title}>
              {`${viewMore.specificationNo}. ${_.truncate(viewMore.title, {
                length: 55
              })}`}
            </span>
          )}
          showView={viewMore}
          onClose={onCloseViewMore}
          bgColor="bg-white"
          size="large"
        >
          <Tooltip id="project-more" className="tooltip" />
          {viewMore && (
          <ViewMore
            isTSI={isTSI}
            showMore={viewMore}
            projectCurrecySymbol={projectCurrecySymbol}
            isFromDownloadSpec={isFromDownloadSpec}
            id={projectId}
          />
          )}
        </SidePanel>
      )}
    </div>
  );
};
export default SpecCard;
