import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
// import Loader from '../../../common/loader';

const CardListItem = (props) => {
  const [Expand, setExpand] = useState(false);
  const { data,
    setOpenSubTypeModal,
    setOpenSubTypeDeleteModal,
    setFormDataSubType,
    editData,
    deteleData
    // loading
  } = props;
  // if (!loading) {
  //   return (<Loader />);
  // }

  return (
    <div data-testid="card-vdata">
      <div className="scroll-area">
        <div className="p-4 border mb-4 md-radius border-hover">
          <div className="d-flex justify-content-between full-width align-items-center">
            <div className="d-flex align-items-center">
              <span
                onClick={() => setExpand(!Expand)}
                id="expand"
                className={`font-16 icon-arrow-thin-right circle-arrow me-4 ${Expand && 'rotate-90'}`}
              />
              <h4 className="font-semibold flex-grow-1">
                <p className="text-uppercase">
                  {data.name}
                  -
                  <span className="color-light-blue">{data.categoryShortName}</span>
                </p>
                <span className="text-primary"> </span>
              </h4>
            </div>

            <div className="d-flex ">
              {/* <button
                onClick={() => {
                  editData(data);
                }}
                className="secondary-btn small "
                type="button"
                id="edit"
              > */}
              <div>
                <button
                  type="button"
                  id="edit"
                  onClick={() => {
                    editData(data);
                  }}
                  // disabled={data?.isMapped}
                >
                  <span
                    className=" icon-edit icon-btn  brand-color  font-24"
                  />
                </button>
              </div>
              <Tooltip id="project-edit" place="top" className="tooltip mt-2" />
              <div
                data-tooltip-id="project-dlt"
                data-tooltip-content={data?.mappedMessage}
              >
                <button
                  type="button"
                  id="delete"
                  onClick={() => {
                    deteleData(data);
                  }}
                  disabled={data?.isMapped}
                >
                  <span
                    className="icon-delete  icon-btn  brand-color font-24"
                  />
                </button>
              </div>
              <Tooltip id="project-dlt" place="top" className="tooltip mt-2" />

              {/* Delete
              </button> */}
            </div>

          </div>
          <div>
            {Expand ? (
              <div className="ms-4 ps-5">
                <div className="border-up  my-4" />
                {data.vesselSubTypes && data.vesselSubTypes.length ? (
                  <>
                    <div className="  mb-3 font-semibold">
                      <span className="icon-disc me-2 color-light-blue" />
                      Sub Type
                    </div>
                    <div className="d-flex justify-content-start flex-wrap col-gap-2 align-items-start ">
                      {data.vesselSubTypes.map((i) => {
                        return (
                          <div className="d-flex quarter-width mb-4 align-items-start sub-strip">
                            <span
                              data-tooltip-id="project-name"
                              data-tooltip-content={i.name}
                              className="item-name text-ellipsis"
                            >
                              {i.name}
                            </span>
                            <Tooltip id="project-name" place="top" className="tooltip mt-2 me-5" />
                            <button
                              onClick={() => {
                                setFormDataSubType(i);
                                setOpenSubTypeDeleteModal(true);
                              }}
                              id="close"
                              className="link-btn ms-3 font-black"
                              type="button"
                              disabled={i?.isMapped}
                              data-tooltip-id="project-name"
                              data-tooltip-content={i?.mappedMessage}
                            >
                              <span className="icon-delete font-20" />
                            </button>
                          </div>
                        );
                      })}
                    </div>

                  </>
                ) : (
                  <div className=" py-3 no-data-color">
                    <span className="icon-exclamation_fill me-2 " />
                    No data found
                  </div>
                )}
                <div className="d-flex py-3">
                  <button
                    onClick={() => {
                      const v = {
                        name: '',
                        shortName: '',
                        description: '',
                        status: 0
                      };
                      v.vesselType = data.id;
                      setFormDataSubType(v);
                      setOpenSubTypeModal(true);
                    }}
                    type="button"
                    className="link-btn"
                    id="add-subtype"
                  >
                    <span className="icon-plus-square me-2" />
                    Add Vessel Sub Type
                  </button>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardListItem;
