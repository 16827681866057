import React from 'react';
import moment from 'moment';

const ShipDueView = (props) => {
  const { setIsEditDue, surveyInfo, intervals, isEditable, showChangeModal } = props;
  const getIntervalValue = (key) => {
    return intervals.filter((item) => item.id === surveyInfo[key])[0]?.label;
  };
  return (
    <div className="container" data-testid="due-view">
      <div className="card-inverse relative vessel-info p-5 ">
        <button id="upload" type="button" className="secondary-btn small absolute align-fix" onClick={() => showChangeModal()}>{`${surveyInfo?.vesselImageUrl ? 'Change Vessel Image' : 'Upload Vessel Image'}`}</button>
        <h4 className="head-16 white mb-4">Survey Notification</h4>
        <div className="row mb-5">
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Drydocking
                {' '}
                <span>{moment(surveyInfo.dryDockingSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2 mt-3 white">
                <div className="half-width">
                  <label className="white">Due Date</label>
                  <div className="font-semibold mt-2">{moment(surveyInfo?.dryDockingSurveyDueDate).format(' DD-MMM-YYYY')}</div>
                </div>
                <div className="half-width">
                  <label className="mb-2 white">Next Due</label>
                  <div className="font-semibold mt-2">{getIntervalValue('dryDockingSurveyIntervalScaleId')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Tailshaft Survey
                {' '}
                <span>{moment(surveyInfo?.tailshaftSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2 mt-3">
                <div className="half-width white">
                  <label className="white">Due Date</label>
                  <div className="font-semibold mt-2">{moment(surveyInfo?.tailshaftSurveyDueDate).format(' DD-MMM-YYYY')}</div>
                </div>
                <div className="half-width white">
                  <label className="mb-2 white">Next Due</label>
                  <div className="font-semibold mt-2">{getIntervalValue('tailshaftSurveyIntervalScaleId')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Intermediate Hull Survey
                {' '}
                <span>{moment(surveyInfo?.intermediateSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2 mt-3">
                <div className="half-width white">
                  <label className="white">Due Date</label>
                  <div className="font-semibold mt-2">{moment(surveyInfo?.intermediateSurveyDueDate).format(' DD-MMM-YYYY')}</div>
                </div>
                <div className="half-width white">
                  <label className="mb-2 white">Next Due</label>
                  <div className="font-semibold mt-2">{getIntervalValue('intermediateSurveyIntervalScaleId')}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="border p-4 notify-container relative md-radius">
              <div className="sub-head">
                Special Survey
                {' '}
                <span>{moment(surveyInfo?.specialSurveyDueDate).format(' DD-MMM-YYYY')}</span>
              </div>
              <div className="d-flex col-gap-2 mt-3">
                <div className="half-width white">
                  <label className="white">Due Date</label>
                  <div className="font-semibold mt-2">{moment(surveyInfo?.specialSurveyDueDate).format(' DD-MMM-YYYY')}</div>
                </div>
                <div className="half-width white">
                  <label className="mb-2 white">Next Due</label>
                  <div className="font-semibold mt-2">{getIntervalValue('specialSurveyIntervalScaleId')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button disabled={isEditable} type="button" className="primary-btn large" onClick={() => { setIsEditDue(true); }}>Edit</button>
        </div>
      </div>
    </div>
  );
};
export default ShipDueView;
