/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { statusPohaColor } from './utilities';
import helper from '../../../../../../../utils/helper';

const Card = (props) => {
  const { item, editData, deletePo, fromVariation, isTsiDisabled, isTSI,
    fromSupplementary, activeTabSupplementary, isVesselUser, projInfo, projectData
  } = props;
  const { formatNumber } = helper;
  let isDisabled = false;
  if (!fromVariation) {
    isDisabled = isTsiDisabled; // BUG: DRYD-4267
  }
  if (fromSupplementary || !activeTabSupplementary) {
    isDisabled = isTsiDisabled;
  }
  return (
    <>
      <tr data-testid="PO-card">
        <td>
          <div
            className="brand-color whitespace-pre"
            data-tooltip-id="project-po"
            data-tooltip-content={item?.prPoNo}
          >
            {_.truncate(item?.prPoNo, {
              length: 10,
              separator: ''
            })}
          </div>
          <div
            className="font-semibold brand-color font-14 po-ind"
            data-tooltip-id="project-po"
            data-tooltip-content={`${item.prPoType === 1 ? 'Purchase Request' : ' Purchase Order'} `}
          >
            {`${item.prPoType === 1 ? 'PR' : ' PO'} `}
          </div>
        </td>
        <td>
          <span
            className="whitespace-pre font-semibold"
            data-tooltip-id="project-po"
            data-tooltip-content={item.description}
          >
            {_.truncate(item?.description, {
              length: 25,
              separator: ''
            })}
          </span>
          <div className="docTypeName white width-fit d-flex align-items-center mt-2" style={{ background: statusPohaColor(item.status) }}>{item.statusName}</div>
        </td>
        <td>
          <span
            data-tooltip-id="project-po"
            data-tooltip-content={item.category}
          >
            {item.category || '-'}
          </span>
        </td>
        <td>
          <span
            data-tooltip-id="project-po"
            data-tooltip-content={item.purchaseType}
          >
            {item.purchaseType || '-'}
          </span>
        </td>
        <td>
          <div className="d-flex whitespace-pre">
            <span>
              {item?.amount !== 0 ? item?.currencySymbol : ''}
              {' '}
            </span>
            <span
              data-tooltip-id="project-po"
              data-tooltip-content={`${item?.amount !== 0 ? item?.currencySymbol : ''}  ${formatNumber(item?.amount)}`}
            >
              {_.truncate(formatNumber(item?.amount), {
                length: 10,
                separator: ''
              }) || 0}
            </span>
          </div>
        </td>
        <td>
          <div className="d-flex whitespace-pre">
            <span>
              {item?.ownerCost !== 0 ? item?.projectCurrencySymbol : ''}
              {' '}
            </span>
            <span
              data-tooltip-id="project-po"
              data-tooltip-content={` ${formatNumber(item?.ownerCost)}`}
            >
              {_.truncate(formatNumber(item?.ownerCost), {
                length: 10,
                separator: ''
              }) || 0}
            </span>
          </div>
        </td>
        <td>
          {isTSI && !isVesselUser ? (
            <div className="d-flex col-gap-1 ">
              <button
                onClick={() => {
                  editData(item);
                }}
                className="brand-color font-20 "
                type="button"
                id="edit"
                disabled={projInfo?.isClosed || projectData?.isClosed}
              >
                <span className="icon-edit me-1 " />
              </button>
              <button
                onClick={() => {
                  deletePo(item);
                }}
                className="brand-color font-20"
                type="button"
                id="delete"
                disabled={isDisabled || projInfo?.isClosed || projectData?.isClosed}
              >
                <span className="icon-delete me-1" />
              </button>
            </div>
          ) : ''}
        </td>
      </tr>
      <Tooltip id="project-po" place="top" className="tooltip mt-3" />
    </>
  );
};

export default Card;
