import React from 'react';
import Select from 'react-select';
import qs from 'query-string';
import { downloadExcelYard } from '../service';
import ContentHeader from './contentHeader';
import QuotationTable from './quotationTable';

const ContentWrap = (props) => {
  const { allCurrency, specList, onCurrencyChange } = props;
  const params = qs.parse(window.location.search);

  // const onCurrencyChange = (e) => {
  //   const currentSpecList = { ...specList };
  //   currentSpecList.rfqData.currencyId = e.value;
  //   currentSpecList.rfqData.currencySymbol = e.currencySymbol;
  //   setSpecList(currentSpecList);
  // };
  const handleDownloadExcel = () => {
    downloadExcelYard({ key: params.key });
  };
  return (
    <div>
      <div className="py-3 d-flex justify-content-between align-items-center">
        <h3 className="font-22">Yard Quotation</h3>
        <button
          type="button"
          className="primary-btn"
          disabled={specList?.supplementarySpecQuoteStatus !== 4}
          onClick={handleDownloadExcel}
        >
          Download Quotation
        </button>
      </div>
      <ContentHeader {...props} />
      <div className="my-2 d-flex justify-content-end">
        <Select
          className="w-250"
          isDisabled
          id="currency"
          options={allCurrency}
          onChange={(e) => { onCurrencyChange(e); }}
          value={allCurrency?.filter((item) => item
            .value === specList.rfqData?.currencyId)[0]}
        />
      </div>
      <QuotationTable {...props} />
    </div>
  );
};
export default ContentWrap;
