/* eslint-disable max-len */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import moment from 'moment';
import vesselThmd from '../../../../../../../../images/vessel-thmd.jpg';

const ImportLibrary = (props) => {
  const { cardDetails, importedProjects, handleEyeView } = props;
  return (
    <div className="" data-testid="library">
      <div className="px-2  mt-4">
        {importedProjects?.templateProjects?.length > 0
          && importedProjects?.templateProjects?.map((items) => {
            return (
              <div className=" card border-dot mt-4 p-3 d-flex align-items-center shadow-medium md-radius">
                <div className="px-3">
                  <img className="thmb-sm ship-thumb" src={items?.vesselImage || vesselThmd} alt="" />
                </div>
                <div className="">
                  <h3 className="brand-color font-semibold font-14">
                    <span
                      data-tooltip-id="project-lib"
                      data-tooltip-content={items?.projectFullName}
                    >
                      {_.truncate(items?.projectName, { length: 40 })}
                    </span>
                  </h3>
                  <div className="d-flex justify-content-start font-12">
                    <span>Imported Date :&nbsp;</span>
                    <span
                      data-tooltip-id="project-lib"
                      data-tooltip-content={items?.dDdate ? moment(items?.dDdate).format('DD-MMM-YYYY') : '-'}
                    >
                      {_.truncate(items?.dDdate ? moment(items?.dDdate).format('DD-MMM-YYYY') : '-', { length: 20 })}
                    </span>
                  </div>
                </div>
                <div className="ml-auto">
                  <div className="d-flex">
                    <button
                      type="button"
                      className="link-btn"
                      id="eyebtn"
                      onClick={() => handleEyeView(items)}
                    >
                      <span className="icon-eye font-20" />
                    </button>
                    <button
                      type="button"
                      className="view-txt-respo link-btn font-14 ps-2"
                      id="eye"
                      onClick={() => handleEyeView(items)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        {importedProjects?.templateProjects?.length === 0 && cardDetails?.projects?.length === 0 && (
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">
              No data found
            </div>
          </div>
        )}
      </div>
      <Tooltip id="project-lib" place="top" className="tooltip m-4" />
    </div>
  );
};
export default ImportLibrary;
