import React from 'react';

const SubmitConfirm = (props) => {
  const { showSubmitConfirm, setShowSubmitConfirm, onSave } = props;
  if (showSubmitConfirm === 1) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="icon-Info brand-color font-50 mb-3" />
        <div className="font-22 font-bold mb-3">Do you want to submit quote?</div>
        <p className="mb-3 gray">If confirm the Quote will be sent back to shore</p>
        <div className="d-flex justify-content-center mt-4">
          <button type="button" id="cancel" className="primary-btn me-3" onClick={() => { setShowSubmitConfirm(false); }}>Cancel</button>
          <button type="button" id="confirm" className="primary-btn" onClick={() => { onSave(true); }}>Confirm</button>
        </div>
      </div>
    );
  } if (showSubmitConfirm === 2) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <span className="icon-check-rnd green font-50 mb-2" />
        <div className="font-22 font-bold mb-3">Quote has been submitted successfully !!</div>
        <div className="d-flex justify-content-center mt-4">
          <button type="button" id="close" className="primary-btn me-3 large" onClick={() => { setShowSubmitConfirm(false); }}>Close Window</button>
        </div>
      </div>
    );
  }
  return null;
};
export default SubmitConfirm;
