/* eslint-disable max-len */
import React, { useState } from 'react';
import SidePanel from '../../../common/sidePanel';

const PermissionList = ({ showPModal, onClose }) => {
  const [state, setstate] = useState(false);
  return (
    <div>
      {showPModal ? (
        <SidePanel title="Permissions" showView={showPModal} onClose={onClose}>
          <table className="permission-table font-13">
            <tr className="heading">
              <td>PAGE</td>
              <td className="relative">
                <div className="align-items-center d-flex justify-content-between">
                  ROLE CATEGORY
                  <span onClick={() => setstate(true)} className="cursor-pointer font-26 icon-Info ms-3 second-brand-color" />
                </div>
                {state ? (
                  <div className="info-details animate__animated animate__headShake">
                    <div className="d-flex justify-content-between mt-2">
                      <h4 className="head-14">Category Info</h4>
                      <span onClick={() => setstate(false)} className="cursor-pointer font-22 icon-close ms-5 brand-color" />
                    </div>
                    <span className="font-13 text-content-gray ms-2">
                      <p className="mb-4">
                        <span className="font-bold me-1">Author</span>
                        <span className="font-semibold">
                          has Create, Edit and Delete permissions in all application pages. A superintendent can be an author for a project.
                        </span>
                      </p>
                      <p className="mb-4">
                        <span className="font-bold me-1">Editor</span>
                        <span className="font-semibold">
                          has create and edit permissions in all application pages. A back up superintendent can be an editor for a project.
                        </span>
                      </p>
                      <p className="mb-4">
                        <span className="font-bold me-1">Viewer</span>
                        <span className="font-semibold">
                          has only view option.
                          {/* and approval permissions in various application screens. A fleet manager or director can be a viewer. */}
                        </span>
                      </p>
                      <p>
                        <span className="font-bold me-1">Vessel User</span>
                        <span className="font-semibold">
                          will have create, edit and delete functionality within the vessel app.
                        </span>
                      </p>
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td className="centre-text">VIEW</td>
              <td className="centre-text">EDIT</td>
              <td className="centre-text">DELETE</td>
            </tr>
            <tbody className="wrap-table">
              <tr>
                <td className="font-semibold no-border-down">Plan</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Specify</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Paint Cost</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Tender - Supplementary Specs</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Tender - Pre quotation Preview</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Tender - Received Quotes</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Tender - Compare Quotes</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Repair </td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Repair - Project Cost Summary</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Repair - Reports</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Repair - Contacts</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Close - Project Cost Collation</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Close - Yard Evaluation</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Close - Project Document</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Close Project</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Tasks</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">WOHA</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">POHA</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">Components</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">DMS - Custom folder</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="font-semibold no-border-down">DMS - System Folder</td>
                <td>Author</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Editor</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border" />
                <td>Viewer</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
              <tr>
                <td className="no-border-last" />
                <td>Vessel User</td>
                <td className="bg-sky-white">
                  <span className="icon-check-rnd green" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
                <td className="bg-sky-white">
                  <span className="icon-close red" />
                </td>
              </tr>
            </tbody>
          </table>
        </SidePanel>
      ) : (
        ''
      )}
    </div>
  );
};
export default PermissionList;
