/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import InputType1 from '../../../../../common/InputType1';

const AddNewSOF = (props) => {
  const { formData, handleChange, setFormData, initialFormData, setsofId } = props;
  useEffect(() => {
    if (formData?.sofTimeStamp === '') {
      setFormData(initialFormData);
      setsofId('');
    }
  }, []);
  return (
    <div data-testid="SOF">
      <div className="col-12 d-flex">
        <div className="col-6 pe-5 label-space" data-testid="add-tag">
          <InputType1 maxLength={200} autoFocus className="modal-input" label="Statement" id="statement" name="statement" value={formData?.statement} onChange={(e) => handleChange('statement', e)} isMandatory="true" />
        </div>
        <div className="pe-4 col-6">
          <div className="">
            <span className="text-content-grey">
              Date & Time
            </span>
            <span className="text-primaryRed-1">*</span>
          </div>
          <InputType1
            value={formData?.sofTimeStamp}
            id="sofTimeStamp"
            onChange={(e) => handleChange('sofTimeStamp', e.target.value)}
            className="modal-input mt-2 mr-edit-modal"
            type="datetime-local"
            noMargin
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewSOF;
