/* eslint-disable no-debugger */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import ModalBox from '../../../../common/ModalBox';
import InputType1 from '../../../../common/InputType1';
import helper from '../../../../../utils/helper';
import { updateKfp } from './services';
import showAlert from '../../../../../utils/alert';

const ServiceLineModal = (props) => {
  const { setShowServiceLineModalSpecify, setIsPreOpened, emptySpecListGT,
    emptySpecListMT, setContinueSrv, isTsiSelected, setIsTsiSelected, setActivateSL } = props;
  const emptySpecList = [...emptySpecListGT, ...emptySpecListMT];

  const { restrictDecimal, fieldValidation } = helper;
  /* istanbul ignore next */
  const updateQuantity = (e, key, index) => {
    const currentInfo = [...isTsiSelected];
    currentInfo[key].kfpList[index][e.target.name] = restrictDecimal(fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6));
    setIsTsiSelected(currentInfo);
  };
  const handleContinue = () => {
    if (emptySpecList?.length > 0 || emptySpecListGT?.length > 0) {
      setShowServiceLineModalSpecify(false);
      setIsPreOpened(false);
    } else {
      const quantityList = [];
      let isValid = true;
      isTsiSelected.map((item) => {
        item.kfpList.map((item2) => {
          const i = {
            ...item2,
            parentSpecId: item.parentSpecId
          };
          quantityList.push(i);
          if (!item2.quantity || item2.quantity === 0) {
            isValid = false;
          }
          return null;
        });
        return null;
      });
      if (isValid) {
        updateKfp(quantityList).then((res) => {
          if (res?.success) {
            setIsPreOpened(true);
            setContinueSrv(true);
            setShowServiceLineModalSpecify(false);
          }
        });
      } else {
        showAlert('Please enter quantity for all KFPs', 'error');
      }
    }
  };
  return (
    <div className=" phase-wrapper content-wrapper" data-testid="ServiceLine">
      <ModalBox
        modalClassName="medium modal-scroll mt-2"
        className="secondary-btn"
        heading="Submit Specs for Approval"
        buttonName="Update"
        onSave={() => handleContinue()}
        closeModal={() => { setShowServiceLineModalSpecify(false); setIsPreOpened(false); }}
        extraClassName
        removeCancelBtn
      >
        {emptySpecList?.length > 0 || emptySpecListGT?.length > 0 ? (
          <div>
            <p className="head-black">Specs cannot be Submitted for Approval as there are service lines with no ac code added for the following specs.</p>
            {emptySpecListGT?.map((items) => {
              return (
                <div key={items?.specificationNo}>
                  <div
                    onClick={() => {
                      setShowServiceLineModalSpecify(false);
                      setActivateSL(items, 'gt');
                    }}
                    data-test="click1"
                    className="cursor-pointer mt-3 py-2 d-flex align-items-center bg-sky-white head-black md-radius"
                  >
                    <div className="ps-4 w-100">
                      {items?.specificationNo}
                    </div>
                    <div className="ms-3">
                      <span
                        data-tooltip-id="project-phase"
                        data-tooltip-content={items?.title}
                      >
                        {' '}
                        {_.truncate(items?.title, {
                          length: 60,
                          separator: ' '
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
            {emptySpecListMT?.map((items) => {
              return (
                <div key={items?.specificationNo}>
                  <div
                    onClick={() => {
                      setShowServiceLineModalSpecify(false);
                      setActivateSL(items, 'mt');
                    }}
                    data-test="click2"
                    className="cursor-pointer mt-3 py-2 d-flex align-items-center bg-sky-white head-black md-radius"
                  >
                    <div className="ps-4 w-100">
                      {items?.specificationNo}
                    </div>
                    <div className="ms-3">
                      <span
                        data-tooltip-id="project-phase"
                        data-tooltip-content={items?.title}
                      >
                        {' '}
                        {_.truncate(items?.title, {
                          length: 60,
                          separator: ' '
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {isTsiSelected?.length > 0
              && (
                <p className="head-black">
                  For the following KFPs, please enter corresponding
                  values and proceed with specs approval.
                </p>
              )}
            <div className="d-flex align-items-center">
              <table className="w-full html-table align-items-center">
                <thead>
                  <tr>
                    <th className="col-2">Spec No</th>
                    <th className="col-2">KFP code</th>
                    <th className="col-2">KFP name</th>
                    <th className="col-2">Qty</th>
                    <th className="col-2">UOM</th>
                  </tr>
                </thead>
                <tbody>
                  {isTsiSelected?.map((item, key) => {
                    return item?.kfpList?.map((item1, index) => {
                      return (
                        <tr className="head-black vertical-align">
                          <td className="col-2">{item.specNo}</td>
                          <td className="col-2">{item1.number}</td>
                          <td className="col-2">
                            <span className="text-ellipsis pe-2" data-tooltip-id="project-phase" data-tooltip-content={item1.serviceLineName}>
                              {_.truncate(item1.serviceLineName, {
                                length: 40,
                                separator: ' '
                              })}
                            </span>
                          </td>
                          <td className="col-2 ">
                            <div className="col-11 ms-2">
                              <InputType1
                                label=""
                                name="quantity"
                                id="quantity"
                                className="small w-full"
                                value={item1.quantity}
                                onChange={(e) => {
                                  updateQuantity(e, key, index);
                                }}
                              />
                            </div>
                          </td>
                          <td className="col-2">{item1.uom}</td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
        <>
          <Tooltip id="project-phase" place="top" className="tooltip mt-3" />
        </>
      </ModalBox>
    </div>
  );
};
export default ServiceLineModal;
