import React, { useMemo } from 'react';
import JoditEditor from 'jodit-react';

const TermsSection = (props) => {
  const { updateAgreementStatus, terms, statusState, specList, setSpecList, isManual } = props;
  const onCommentChange = (e) => {
    const currentSpecList = { ...specList };
    currentSpecList.rfqData.comment = e.target.value;
    currentSpecList.rfqData.status = statusState;
    setSpecList(currentSpecList);
  };
  const config = useMemo(
    () => ({
      readonly: true
    })
  );
  return (
    <div className="">
      <h4 className="font-22">Terms and Conditions</h4>
      <div data-testid="description-main relative" className="terms-view">
        <div className="yard-jodit text-editor mt-2">
          <div className="editor-wrap-description view-jodit termsandconImg">
            <JoditEditor
              className="yardterm-view"
              value={terms}
              config={config}
            />
          </div>
        </div>
      </div>
      <div className="mt-2 ms-3 mb-2">
        <input type="radio" id="accept" name="terms" value={2} checked={statusState === 2} onClick={() => { updateAgreementStatus(2); }} />
        <label htmlFor="accept" className="ps-3">Accept to quote</label>
      </div>
      <div className="ms-3 mb-2">
        <input type="radio" id="reject" name="terms" value={3} checked={statusState === 3} onClick={() => { updateAgreementStatus(3); }} />
        <label htmlFor="reject" className="ps-3">Reject to quote</label>
      </div>
      <div className="ms-3 ">
        <input type="radio" id="rejects" name="terms" value={5} checked={statusState === 5} onClick={() => { updateAgreementStatus(5); }} />
        <label htmlFor="rejects" className="ps-3">Accept to quote without agreeing to above terms and conditions.</label>
      </div>

      {!isManual
      && (
      <div className="ms-3 mt-2">
        <textarea
          value={specList?.rfqData?.comment}
          onChange={(e) => { onCommentChange(e); }}
          id="comment"
          col="10"
          placeholder="Yard Comment"
          className="text-box wp-50 h-text-area pt-2"
        />
      </div>
      )}
      {/* <div className="ms-3 mt-2">
        <input
          type="radio"
          id="AcceptedWithoutAgreement"
          name="terms"
          value={5}
          checked={statusState === 5}
          onClick={() => { updateAgreementStatus(5); }}
        />
        <label htmlFor="AcceptedWithoutAgreement" className="ps-3">
        I accept without agreement</label>
      </div> */}
    </div>
  );
};
export default TermsSection;
