/* eslint-disable max-len */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import showAlert from '../../../../../../utils/alert';
import ModalBox from '../../../../../common/ModalBox';
import { saveRepairSpecAttachment } from '../services';
import Attachments from './Attachment';
import CancelledJobsModal from './CancelledJobsModal';
import SpecCard from './SpecCard';
import Loader from '../../../../../common/areaLoader';

const Specifications = (props) => {
  const {
    onSearch,
    projectId,
    visibleSpec,
    setVisibleSpec,
    searchText,
    setSearchText,
    setSpecList,
    searchTexts,
    setSearchTexts,
    specList,
    projectBasicDetails,
    projInfo,
    isLoading,
    getSpecListData,
    setJobStatus,
    jobStatus
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [newDocument, setNewDocument] = useState([]);
  const userRole = localStorage.getItem('USER_ROLE');
  const statusList = [
    { label: 'Not Started', value: 0 },
    { label: 'In Progress', value: 1 },
    { label: 'Completed', value: 4 },
    { label: 'On Hold', value: 5 },
    { label: 'Delayed', value: 2 }
  ];
  const hanldeClick = () => {
    setOpenModal(true);
  };
  const closeApiCall = () => {
    setOpenModal(false);
  };
  const attachmentButton = () => {
    setOpenAttachmentModal(true);
  };
  const saveApiCall = () => {
    const newDoc = specList?.quoteDocuments?.filter((item) => item.documentId === '00000000-0000-0000-0000-000000000000');
    if (specList?.quoteDocuments.length > 0) {
      saveRepairSpecAttachment({ projectId, specDocument: newDoc }).then((res) => {
        if (res.success) {
          setOpenAttachmentModal(false);
          getSpecListData();
          showAlert('Successfully Saved');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <div className=" align-items-center" data-testid="spec">
        <div className=" d-flex justify-content-between align-items-center">
          <div className="search large search-large spec-search">
            <div className="d-flex justify-content-end">
              <span
                className={searchTexts ? ' icon-close-line ' : ''}
                onClick={() => {
                  setSearchTexts('');
                  document.getElementById('search')?.focus();
                }}
              />
              <span
                className="icon-search"
                id="search-click"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              className="text-box large with-close"
              value={searchTexts}
              placeholder="Search by Sec No., Sec Name, Spec No., Spec Name, ServiceLine No., ServiceLine Name"
              onKeyPress={onSearch}
              id="search"
              onChange={(e) => {
                setSearchTexts(e.target.value);
              }}
            />
          </div>
          <Select
            className="modal-select ms-4 col-2 me-auto"
            id="jobStatus"
            placeholder="Filter by Job Status"
            options={statusList}
            isClearable
            onChange={(e) => {
              if (e) {
                setJobStatus(e.value);
              } else {
                setJobStatus('');
              }
            }}
            value={statusList
              .filter((roleItem) => roleItem.value === jobStatus)}
          />
          {userRole === 'Admin' && (
            <div className="ms-auto">
              <button id="attachmentClick" className="secondary-btn large mb-4 me-3 mt-4" type="button" onClick={() => attachmentButton()}>
                <span data-tooltip-id="project-progress" data-tooltip-content="Please attach the supporting documents here">
                  Attachments
                </span>
              </button>
            </div>
          )}
          <div className="d-flex mt-4">
            <button
              id="hanldeClickId"
              className="secondary-btn large mb-4"
              type="button"
              onClick={() => {
                hanldeClick();
              }}
            >
              Deferred/Cancelled Jobs
            </button>
            {openModal ? (
              <CancelledJobsModal
                projectId={projectId}
                visibleSpec={visibleSpec}
                setVisibleSpec={setVisibleSpec}
                searchText={searchText}
                setSearchText={setSearchText}
                closeModal={() => {
                  setOpenModal(false);
                  setVisibleSpec([1, 2]);
                }}
                onSave={() => {
                  closeApiCall();
                  setVisibleSpec([1, 2]);
                }}
                projInfo={projInfo}
                getSpecListData={getSpecListData}
              />
            ) : (
              ''
            )}
            {openDeleteModal ? (
              <ModalBox modalClassName="medium" saveDisable closeIcon closeModal={() => setOpenDeleteModal(true)} buttonName="Close" />
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="p-1">
          <SpecCard {...props} projectBasicDetails={projectBasicDetails} projInfo={projInfo} />
        </div>
      </div>

      {openAttachmentModal && (
        <ModalBox
          modalClassName="medium"
          heading="Add Documents"
          onSave={saveApiCall}
          closeModal={() => {
            setOpenAttachmentModal(false);
            setNewDocument([]);
          }}
          buttonName="Save"
          saveDisable={projectBasicDetails?.isClosed}
        >
          <Attachments
            projectBasicDetails={projectBasicDetails}
            specList={specList}
            setSpecList={setSpecList}
            projectId={projectId}
            setNewDocument={setNewDocument}
            newDocument={newDocument}
            getSpecListData={getSpecListData}
          />
        </ModalBox>
      )}
      <Tooltip id="project-progress" place="top" className="tooltip " />
    </div>
  );
};

export default Specifications;
