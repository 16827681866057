/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-v6';
import { useNavigate, useOutletContext } from 'react-router-dom';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { GetDDHistory } from './services';
import Pagination from '../../../../common/pagination';
import Loader from '../../../../common/loader';

const DDHistory = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentpage] = useState(1);
  const navigate = useNavigate();

  const reloadTable = (page) => {
    GetDDHistory({
      page: page || currentpage,
      pageSize,
      search: searchText
    }).then((res) => {
      setData(res?.data);
      setCount(res?.data?.totalItems);
      setLoading(false);
    });
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      reloadTable();
      setCurrentpage(1);
    }
  };

  useEffect(() => {
    reloadTable();
    setLoading(true);
  }, [currentpage, pageSize]);

  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    document.getElementsByClassName('scroll-wrap')[0].scrollTop = 0;
  };

  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };

  const column = [
    {
      Header: 'PROJECT NAME',
      accessor: 'projName',
      width: 350,
      sortable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <span className="text-ellipsis" data-tooltip-id="project-Spectitle" data-tooltip-content={original.projName}>
            {original?.projName}
          </span>
        );
      }
    },
    {
      Header: 'MANAGEMENT',
      sortable: false,
      width: 200,
      accessor: 'managementName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span
            className="ms-2"
            data-tooltip-id="project-management"
            data-tooltip-content={`${original?.managementName}${original?.managementLocation}`}
          >
            {original?.managementName}
            {' '}
            {/* (
            {original?.managementLocation}
            ) */}
            (
            {_.truncate(original?.managementLocation, {
              length: 5,
              separator: ' '
            })}
            )
          </span>
        );
      }
    },
    {
      Header: 'YARD',
      sortable: false,
      width: 140,
      accessor: 'yardName',
      Cell: (row) => {
        const { original } = row;
        return (
          <span className="ms-2" data-tooltip-id="project-yardName" data-tooltip-content={original.yardName}>
            {_.truncate(original?.yardName, {
              length: 15,
              separator: ' '
            })}
          </span>
        );
      }
    },
    {
      Header: 'Repair Start Date',
      width: 150,
      sortable: false,
      accessor: 'Repair Start Date',
      Cell: (row) => {
        const { original } = row;
        return (
          <span className="ms-2">
            {/* {{original?.dDDate} &&  {moment(original?.dDDate)?.format('DD-MMM-YYYY')} } */}
            {original?.dDDate ? moment(original?.dDDate)?.format('DD-MMM-YYYY') : ' '}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      accessor: 'packageName',
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        return (
          <div data-testid="ddhistory">
            <span
              onClick={() => {
                const ddhistory = 'ddhistory';
                navigate(`/user/project/${original?.projId}/repair?vesselId=${original.vesselId}&isfromddhistory=${ddhistory}`);
              }}
              className="icon-eye font-22 cursor-pointer brand-color ms-4 "
            />
          </div>
        );
      }
    }
  ];

  useEffect(() => {
    if (!searchText) {
      reloadTable();
    }
  }, [searchText]);
  return (
    <div className="card  p-5 mb-4 height-fix " data-testid="dd-history">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex mb-3 col-gap-1 spec-search">
            <div className="search large">
              <div className="d-flex justify-content-end">
                <span
                  id="setSearchText"
                  className={searchText ? ' icon-close-line ' : ''}
                  onClick={() => {
                    setSearchText('');
                    document.getElementById('search')?.focus();
                  }}
                />
                <span
                  className="icon-search"
                  id="lens"
                  onClick={() => {
                    onSearch({ key: 'Enter' });
                  }}
                />
              </div>
              <input
                type="text"
                value={searchText}
                className="text-box large with-close"
                placeholder="Search by Project Name"
                id="search"
                onKeyPress={onSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            {/* <div className="sort-margin">
      <span className="icon-sort brand-color pointer mt-1" />
      <u className="brand-color pointer ms-2 count ">Sort</u>
    </div> */}
          </div>
          <div className="stripped-react-table" data-testid="spec-table">
            <ReactTable className="table table-scroll  " data={data?.items} columns={column} minRows={0} showPagination={false} />
          </div>
          <div data-testid="pagination">
            {data?.totalItems > 5 && (
              <Pagination
                pageClick={pageClick}
                pageCount={data?.totalPages}
                currentpage={currentpage}
                onSizeChange={onSizeChange}
                size={pageSize}
              />
            )}
            <Tooltip id="project-Spectitle" place="top" className="tooltip mt-4 me-5" />
            <Tooltip id="project-management" place="top" className="tooltip mt-4 me-5" />
            <Tooltip id="project-yardName" place="top" className="tooltip mt-4 me-5" />
          </div>
        </>
      )}
    </div>
  );
};
export default DDHistory;
