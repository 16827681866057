import React from 'react';
import _ from 'lodash';

const Addattachments = (props) => {
  const { ondrop, setAttachFormData, formDataAttachment } = props;
  const deleteFile = (key) => {
    const attachment = { ...formDataAttachment };
    attachment.attachments.splice(key, 1);
    setAttachFormData(attachment);
  };
  return (
    <div className="p-3 mt-2" data-testid="upload-card">
      <div className="row">
        <div id="fileName-div" className="drag-drop-file my-4 file-waiting col-12">
          <input multiple className="p-5 align-self-center" id="fileName" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
          <div className="d-flex row w-full">
            <div className="d-flex flex-column justify-content-center align-items-center col-8">
              <div className="icon-buble mb-2">
                <span className="icon-upload-cloud" />
              </div>
              <div>Choose a file or drag it here</div>
              <div className="font-12">(File formats: .xlsx, doc, pdf - Max size 5MB)</div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center col-4">
              <div className="div_btn">
                Browse File
              </div>
            </div>
          </div>
        </div>
        <div className="attached-file-multi-nonspec">
          {formDataAttachment?.attachments?.map((item, key) => {
            return (
              <>
                <div className="d-flex">
                  <span className="icon-check-rnd me-2 file-added-multi font-20"> </span>
                  <div>
                    {_.truncate(item.name, {
                      length: 25,
                      separator: ' '
                    })}
                  </div>
                  <button type="button" id="delete" className="link-btn clear-file" onClick={() => { deleteFile(key); }}>
                    <span className="icon-delete delete-icon-multi ms-4 font-20 "> </span>
                  </button>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Addattachments;
