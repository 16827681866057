/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import ContentHeader from '../../../../../Quotations/YardQuotation/children/contentHeader';
import ContentFooter from '../../../../../Quotations/YardQuotation/children/contentFooter';
import QuotationTable from '../../../../../Quotations/YardQuotation/children/quotationTable';
import ViewMore from '../../../../../Quotations/YardQuotation/ViewMore';
import SidePanel from '../../../../../common/sidePanel';
import Attachments from '../../../../../Quotations/YardQuotation/Attachments';
import { quotesUpdate } from '../services';
import showAlert from '../../../../../../utils/alert';
import { formatSave } from './supportFunc';
import InputType1 from '../../../../../common/InputType1';

const EditQuote = (props) => {
  const { specList, setSpecList, setEditable, getSpecListData } = props;
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [showMore, setShowMore] = useState(false);
  const [showAttach, setShowAttach] = useState(false);

  const saveQuote = () => {
    quotesUpdate(formatSave(specList)).then((res) => {
      if (res.success) {
        showAlert('Updated successfully', 'success');
        getSpecListData();
        setEditable(false);
      }
    });
  };

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };

  const cancelEdit = () => {
    getSpecListData();
    setEditable(false);
  };
  return (
    <div data-testid="edit_quote">
      <div className="px-3 mt-2">
        <div className="d-flex justify-content-between mt-3 mb-4 px-2 d-none1">
          <div className="head-16">{specList?.rfqData?.yardName}</div>
        </div>
        <ContentHeader {...props} />
        <div className="d-flex justify-content-end">
          <InputType1 value={specList.projectCurrency} disabled />
        </div>
        <QuotationTable
          setSpecList={setSpecList}
          specList={specList}
          onSecOpen={onSecOpen}
          mainSec={mainSec}
          showsubSec={showsubSec}
          setShowMore={setShowMore}
          setShowAttach={setShowAttach}
        />
        <ContentFooter {...props} />
      </div>
      <SidePanel
        title={<span title={showMore.title}>{`${showMore.specificationNo}. ${_.truncate(showMore.title, { length: 55 })}`}</span>}
        size="large"
        onClose={() => { setShowMore(false); }}
        showView={showMore}
        className="sidepanel-viewmore"
      >
        {showMore && <ViewMore showMore={showMore} />}
      </SidePanel>
      <SidePanel
        title={<span title={showAttach?.section?.title}>{`${showAttach?.section?.specificationNo}. ${_.truncate(showAttach?.section?.title, { length: 20 })}`}</span>}
        size="large"
        onClose={() => { setShowAttach(false); }}
        showView={showAttach}
        className="sidepanel-attach"
      >
        {showAttach && (
        <Attachments
          showAttach={showAttach}
          specList={specList}
          setSpecList={setSpecList}
        />
        )}
      </SidePanel>
      <div className="fixed-footer card d-flex justify-content-end">
        <button id="q-cancel" type="button" className="large secondary-btn mx-3" onClick={() => { cancelEdit(false); }}>Cancel</button>
        <button id="q-update" type="button" className="large primary-btn" onClick={() => { saveQuote(); }}>Update</button>
      </div>
    </div>
  );
};
export default EditQuote;
