import { serviceHandler } from '../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../utils/apiUrlConstants';

const {
  GetSupplementorySpecListForApproval,
  RejectSpecs,
  SubmitForRevision,
  ProjectApprovalStatus
} = apiEndPoints;

export const getSpecListById = (payload) => {
  return serviceHandler({
    url: `${GetSupplementorySpecListForApproval}?projectId=${payload?.id}&approvalLevel=${payload?.approvalLevel}&approvalStatus=${payload?.approvalStatus}`,
    methordType: 'get'
  });
};

export const reject = (payload) => {
  return serviceHandler({
    url: `${RejectSpecs}`,
    methordType: 'post',
    payload
  });
};
export const submit = (payload) => {
  return serviceHandler({
    url: `${SubmitForRevision}`,
    methordType: 'post',
    payload
  });
};
export const projectApproval = (id, type) => {
  return serviceHandler({
    url: `${ProjectApprovalStatus}?projectId=${id}&type=${type}`,
    methordType: 'get'
  });
};
