/* istanbul ignore file */
import React from 'react';
import ModalBox from '../../../../../common/ModalBox';
import { yardMailListConfirm } from '../services';

const ConfirmationModalForYard = (props) => {
  const { setConfirmationModalForYard, yardMailList, setSupplementarySended } = props;
  const handleConfirmSendYard = () => {
    yardMailListConfirm(yardMailList).then((res) => {
      if (res.success) {
        setConfirmationModalForYard(false);
        setSupplementarySended(true);
      }
    });
  };
  return (
    <div>
      <ModalBox
        heading="Confirm"
        closeModal={() => setConfirmationModalForYard(false)}
        buttonName="send"
        modalClassName="large modal-scroll"
        onSave={handleConfirmSendYard}
        saveDisable={yardMailList?.yardData?.length === 0}
      >
        <div>
          Are you Sure? Do You want to send supplementary specs mail to the selected yard.
        </div>
      </ModalBox>
    </div>
  );
};
export default ConfirmationModalForYard;
