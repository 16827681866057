/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Sections from './Children/Sections';
import Estimation from './Children/Estimation';
import SectionContent from './Documents/SectionContent';
import Stepper from './Children/Stepper';
import Evaluation from './yardEvaluation/evaluation';
import Sidebar from './yardEvaluation/yardInfo';
import ModalBox from '../../../../common/ModalBox';
import ModalWindow from './ModalBox/ModalWindow';
import {
  getProjectCollation,
  saveProjectCollation,
  getProjectEvaluation,
  saveProjectEvaluation,
  projectCloseProject,
  projectCloseYard,
  projectCloseDocument,
  closeProject,
  sendForApproval
} from './services';
import FooterButton from './Children/footerButton';
import showAlert from '../../../../../utils/alert';
import { collationSave, totalAtRepair, totalAtContract } from './Children/closeHelper';
import helper from '../../../../../utils/helper';
import { getProjectStatus, updateProjectStatus } from '../../NewProject/services';
import PreConfirm from './Children/PreConfirm';
import ApproveConfirm from './Children/approveConfirm';
import Loader2 from '../../../../common/loader2';
import ApproveSendConfirm from './Children/approveSendConfirm';
import TaskModal from '../Repair/TaskModal';
import { NotCompletedTasksDetails } from '../Repair/services';

const Close = () => {
  const [view, setView] = useState(1);
  const [ratings, setRatings] = useState({});
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [specList, setSpecList] = useState({});
  const [showUpload, setShowUpload] = useState(false);
  const [yardInfo, setYardInfo] = useState({});
  const [viewYardInfo, setViewYardInfo] = useState({});
  const [showView, setShowView] = useState(false);
  const [allDocs, setAllDocs] = useState({});
  const [pjtStatus, setPjtStatus] = useState({});
  const [reason, setReason] = useState('');
  const [showReason, setShowReason] = useState(false);
  const [aproveDisable, setApproveDisable] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showCloseConf, setShowCloseConf] = useState(false);
  const [closeSuccess, setCloseSuccess] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successView, setSuccessView] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [openTaskModal, setopenTaskModal] = useState(true);
  const [Status, setStatus] = useState('');
  const { id } = useParams();
  const { ValidateEmail, roles } = helper;
  const { projInfo, getProjDetails } = useOutletContext() || { projInfo: {} };

  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR || userRole === roles.ADMIN;
  const evaluationInfo = () => {
    projectCloseProject({ id }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setYardInfo(res.data);
        setViewYardInfo(res.data);
      }
    });
  };
  const getCollations = (isReload) => {
    getProjectCollation({ id }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setSpecList(res.data);
        if (isReload) {
          showAlert('Successfully cleared the unsaved data', 'success');
        }
      }
    });
  };
  const getDocuments = () => {
    projectCloseDocument({ id }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setAllDocs(res.data);
      }
    });
  };
  const getEvaluations = (isReload) => {
    getProjectEvaluation({ id }).then((resp) => {
      if (resp.success) {
        setIsLoading(false);
        setRatings(resp.data);
        if (isReload) {
          showAlert('Successfully cleared the unsaved data', 'success');
        }
      }
    });
  };
  const projectStatus = () => {
    getProjectStatus(id, 5).then((resp) => {
      if (resp.success) {
        setIsLoading(false);
        setPjtStatus(resp.data);
        if (resp.data) {
          setView(3);
        }
      }
    });
  };
  useEffect(() => {
    getCollations();
    getEvaluations();
    evaluationInfo();
    getDocuments();
    projectStatus();
    setIsLoading(true);
    if (!isTSI) {
      setView(3);
    }
  }, []);
  const saveCollation = (page, isSubmit) => {
    const collData = collationSave(specList.collation);
    saveProjectCollation({
      status: isSubmit ? 1 : 0,
      projectId: id,
      comment: specList.comment,
      attachment: specList.attachment,
      yardTotal: totalAtContract(specList?.collation),
      repairTotal: totalAtRepair(specList?.collation),
      collation: collData
    }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        getCollations();
        evaluationInfo();
        if (isSubmit) {
          showAlert('Successfully submitted', 'success');
          setView(2);
        } else {
          showAlert('Successfully saved as draft', 'success');
        }
      }
    });
  };
  const saveEvaluation = (page, isSubmit) => {
    const currentRatings = { ...ratings };
    currentRatings.status = isSubmit ? 1 : 0;
    let isValid = true;
    let isComment = true;
    if (currentRatings.comments === null) {
      isComment = false;
    }
    currentRatings?.evaluations?.map((i) => {
      i?.evaluationLines?.map((j) => {
        if (j.rating < 3 && j.remarks === '') {
          isValid = false;
        }
      });
    });
    if (isValid && isComment) {
      saveProjectEvaluation(currentRatings).then((res) => {
        if (res.success) {
          setIsLoading(false);
          if (isSubmit) {
            showAlert('Successfully submitted', 'success');
            setView(3);
          } else {
            showAlert('Successfully saved as draft', 'success');
          }
          getEvaluations();
        }
      });
    } else {
      showAlert('Please give remarks where rating less than 3 and enter the mandatory(*) fields', 'error');
    }
  };
  const savePageSelect = (page, isSubmit) => {
    if (page === 1) {
      saveCollation(page, isSubmit);
    } else if (page === 2) {
      saveEvaluation(page, isSubmit);
    } else {
      setView(view + 1);
    }
  };
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  const addAttach = () => {
    setShowUpload(true);
  };
  const onSaveAttach = () => {
    setShowUpload(false);
    const collData = collationSave(specList.collation);
    saveProjectCollation({
      status: 0,
      projectId: id,
      comment: specList.comment,
      attachment: specList.attachment,
      yardTotal: totalAtContract(specList?.collation),
      repairTotal: totalAtRepair(specList?.collation),
      collation: collData
    });
    showAlert('Successfully saved the Attachment', 'success');
  };
  const onReload = (tab) => {
    if (tab === 1) {
      getCollations(true);
    } else {
      getEvaluations(true);
    }
  };
  const onSaveYardInfo = () => {
    if (
      (!yardInfo.repairManagerMail || ValidateEmail(yardInfo.repairManagerMail))
      && (!yardInfo.commercialManagerMail || ValidateEmail(yardInfo.commercialManagerMail))
    ) {
      projectCloseYard({ ...yardInfo }).then((resp) => {
        if (resp.success) {
          setIsLoading(false);
          showAlert('Successfully updated yard info', 'success');
          setShowView(false);
          evaluationInfo();
        }
      });
    } else {
      showAlert('Please enter valid Email', 'error');
    }
  };
  const onCloseProject = () => {
    setShowCloseConf(false);
    closeProject({ id }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setCloseSuccess(true);
      }
    });
  };
  const sendForApprove = () => {
    sendForApproval({ projectId: id, type: 5 }).then((res) => {
      if (res.success) {
        setIsLoading(false);
        setShowSubmit(false);
        getProjDetails();
        setSuccessView(projInfo?.closeApprovalStatus === 3 ? 3 : 2);
        // showAlert(projInfo?.closeApprovalStatus === 3
        //  ? 'Successfully Resend for approval' : 'Successfully Send for approval', 'success');
        setApproveDisable(true);
      }
    });
  };
  const onApprove = (comment = reason, status = 2) => {
    const payload = {
      projectId: id,
      type: 5,
      level: pjtStatus,
      status,
      comment
    };
    updateProjectStatus(payload).then((res) => {
      setIsLoading(false);
      if (res.success) {
        if (status === 3) {
          setStatus(status);
          setSuccessView(true);
          showAlert('Successfully sent for revision', 'success');
        } else {
          showAlert('Successfully approved', 'success');
        }
        setShowReason(false);
        setReason('');
        setShowApprove(false);
        projectStatus();
      }
    });
  };
  const sentforRevision = () => {
    setShowReason(true);
  };
  const NotCompletedTask = () => {
    setopenTaskModal(true);
    NotCompletedTasksDetails({ phaseId: '5', projectId: id }).then((res) => {
      if (res.success) {
        setTaskData(res?.data);
      }
    });
  };
  useEffect(() => {
    NotCompletedTask();
  }, []);
  const renderPage = () => {
    if (isLoading) {
      return <Loader2 />;
    }
    if (view === 1) {
      return (
        <>
          <div className="d-flex justify-content-between">
            <Sections
              onSecOpen={onSecOpen}
              mainSec={mainSec}
              showsubSec={showsubSec}
              specList={specList}
              setSpecList={setSpecList}
              isDisabled={projInfo?.isClosed}
            />
            <Estimation
              mainSec={mainSec}
              showsubSec={showsubSec}
              specList={specList}
              setSpecList={setSpecList}
              addAttach={addAttach}
              setShowUpload={setShowUpload}
              showUpload={showUpload}
              onSaveAttach={onSaveAttach}
              isDisabled={projInfo?.isClosed}
              projInfo={projInfo}
              onSaveYardInfo={onSaveYardInfo}
            />
          </div>
        </>
      );
    }
    if (view === 2) {
      return (
        <>
          <div className="row mt-4">
            <div className="col-4">
              <Sidebar
                showView={showView}
                setShowView={setShowView}
                yardInfo={yardInfo}
                ratings={ratings}
                viewYardInfo={viewYardInfo}
                setYardInfo={setYardInfo}
                onSaveYardInfo={onSaveYardInfo}
                evaluationInfo={evaluationInfo}
                projInfo={projInfo}
              />
            </div>
            <div className="col-8">
              <Evaluation setRatings={setRatings} ratings={ratings} />
            </div>
          </div>
        </>
      );
    }
    if (view === 3) {
      return (
        <>
          <div className="row mt-4">
            <div className="col-4">
              <Sidebar
                showView={showView}
                setShowView={setShowView}
                yardInfo={yardInfo}
                ratings={ratings}
                viewYardInfo={viewYardInfo}
                setYardInfo={setYardInfo}
                onSaveYardInfo={onSaveYardInfo}
                evaluationInfo={evaluationInfo}
                projInfo={projInfo}
                showEdit={false}
              />
            </div>
            <div className="col-8">
              <SectionContent
                allDocs={allDocs}
                id={id}
                getDocuments={getDocuments}
                projInfo={projInfo}
              />
            </div>
          </div>
        </>
      );
    }
    return null;
  };
  return (
    <div>
      <div className="close-phase-footer content-wrap tsi-user pb-5 mb-5" data-testid="close-phase">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {view === 1 ? (
              <div className="d-flex head-18 ">Project Cost Collation</div>
            ) : view === 2 ? (
              <div className="d-flex head-18 ">Yard Evaluation</div>
            ) : view === 3 ? (
              <div className={isLoading ? 'opacity-0' : 'd-flex head-18'}>Documents</div>
            ) : (
              ''
            )}
          </div>
          <div>
            <Stepper
              projInfo={projInfo}
              view={view}
              setView={setView}
              specList={specList}
              ratings={ratings}
            />
          </div>
        </div>
        {renderPage()}
        <FooterButton
          view={view}
          setView={setView}
          savePageSelect={savePageSelect}
          specList={specList}
          onReload={onReload}
          ratings={ratings}
          onCloseProject={onCloseProject}
          setShowSubmit={setShowSubmit}
          sendForApprove={sendForApprove}
          setShowApprove={setShowApprove}
          onApprove={onApprove}
          sentforRevision={sentforRevision}
          pjtStatus={pjtStatus}
          aproveDisable={aproveDisable}
          projInfo={projInfo}
          id={id}
          setShowCloseConf={setShowCloseConf}
          setButtonClicked={setButtonClicked}
          buttonClicked={buttonClicked}
          getProjDetails={getProjDetails}
        />
        <div>
          {showReason && (
            <ModalBox
              heading={(
                <div>
                  Add Reason for Revision
                  {' '}
                  <span className="red">*</span>
                </div>
              )}
              closeModal={() => {
                setReason('');
                setShowReason(false);
              }}
              buttonName="Submit"
              saveDisable={!reason}
              onSave={() => {
                onApprove(reason, 3);
              }}
              modalClassName="medium modal-scroll"
            >
              <textarea
                id="reason"
                onChange={(e) => {
                  setReason(e.target.value);
                }}
                type="text"
                name="reason"
                value={reason}
                className="text-box w-full h-text-area p-3"
              >
                {reason}
              </textarea>
            </ModalBox>
          )}
          {showSubmit && (
            <ModalBox
              closeModal={() => {
                setShowSubmit(false);
              }}
            >
              <PreConfirm
                id={id}
                projInfo={projInfo}
                sendForApprove={sendForApprove}
                setShowSubmit={setShowSubmit}
              />
            </ModalBox>
          )}
          {showApprove && (
            <ModalBox
              closeModal={() => {
                setShowApprove(false);
              }}
            >
              <ApproveConfirm
                projInfo={projInfo}
                onApprove={onApprove}
                setShowApprove={setShowApprove}
              />
            </ModalBox>
          )}
          {successView && (
            <ModalBox
              closeModal={() => {
                setSuccessView(false);
              }}
            >
              <ApproveSendConfirm
                id={id}
                projInfo={projInfo}
                successView={successView}
                setSuccessView={setSuccessView}
                status={Status}
              />
            </ModalBox>
          )}
          {showCloseConf ? (
            taskData.length > 0 && openTaskModal ? (
              <TaskModal
                // isLoading={isLoading}
                taskData={taskData}
                openTaskModal={openTaskModal}
                setopenTaskModal={setopenTaskModal}
                isClose
              />
            ) : (
              <ModalBox
                heading="Are you sure?"
                closeModal={() => {
                  setShowCloseConf(false);
                }}
                buttonName="Continue"
                onSave={() => {
                  onCloseProject();
                }}
              >
                <div>Are you sure you want to close the project?</div>
              </ModalBox>
            )
          ) : null}
          {closeSuccess && (
            <div>
              <ModalBox
                closeModal={() => {
                  window.location.assign('/user/projects');
                  setCloseSuccess(false);
                }}
                modalClassName="medium modal-scroll"
              >
                <ModalWindow projInfo={projInfo} />
              </ModalBox>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Close;
