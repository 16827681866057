import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import React, { useState } from 'react';
import Select from 'react-select';
import { statusWohaColor } from './utilities';
import ModalBox from '../../../common/ModalBox';
import { deleteWohaApi } from './services';
import showAlert from '../../../../utils/alert';

const WohaCard = (props) => {
  const { dropDownOption,
    item, onSelected, selectedCard, onSave, isTSI, onCancel, isSaved, currentSpec, setCurrentSpec,
    projectBasicDetails, workorderDefect
  } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const data = [
    { label: 'Component :', key: 'component' },
    { label: 'Raised By :', key: 'raisedBy' },
    { label: 'Interval :', key: 'interval' }
  ];

  const deleteApiCall = () => {
    deleteWohaApi({ id: item.id }).then((res) => {
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
        setOpenDeleteModal(false);
        workorderDefect();
      }
    });
  };
  return (
    <div className="card p-4 mt-4" data-testid="woha-card">
      <div className="d-flex justify-content-start col-gap-1 align-items-center ">
        <div className="brand-color font-semibold col-2">
          <span data-tooltip-id="project-variatio" data-tooltip-content={item.externalId}>{_.truncate(item.externalId, { length: 20 })}</span>
        </div>
        <div className="head-14 col-6"><span data-tooltip-id="project-variatio" data-tooltip-content={item.title}>{_.truncate(item.title, { length: 72 })}</span></div>
        <div className="docTypeName white width-fit" style={{ background: statusWohaColor() }}>{item.statusName}</div>
        <button
          className="d-flex w-full"
          type="button"
          onClick={() => {
            setOpenDeleteModal(true);
          }}
        >
          <span className="icon-delete font-24 ms-auto link-btn" />
        </button>
      </div>
      <div className="d-flex col-12 row">
        {data.map((items) => {
          return (
            <>
              <div className="mt-3 d-flex col-4 ">
                <label className="">{items.label}</label>
                <div className="ms-3 value">
                  <span data-tooltip-id="project-variatio" data-tooltip-content={item[items.key]}>
                    {_.truncate(item[items.key], { length: 25 })}

                  </span>

                </div>
              </div>
            </>
          );
        })}
        <div className="d-flex mt-3">
          <label className="">Description :</label>
          <div className="ms-3 value">
            <span data-tooltip-id="project-variatio" data-tooltip-content={item.description}>{_.truncate(item.description, { length: 500 })}</span>

          </div>
        </div>
      </div>
      <div className="border-down mt-4" />
      {!item.specName ? (
        selectedCard === item.id
          ? (
            <>
              <div className="d-flex align-items-center mt-2 pb-2">
                <label className="head-14">Mapping to spec</label>
              </div>
              <div className="d-flex align-items-center border-down mt-2 pb-2">
                <label>Spec Name</label>
              </div>
              <div className="mt-3 ms-2 d-flex align-items-center">
                <div className="col-6">
                  <Select
                    className="modal-select w-full"
                    placeholder="Select Spec"
                    id="selectSpec"
                    options={dropDownOption}
                    onChange={(event) => {
                      setCurrentSpec(event.id);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#EBF2FF',
                        primary: 'LightGray'
                      }
                    })}
                  />
                </div>
                <button type="button" id="cancel" className="secondary-btn large ms-5" onClick={() => onCancel()}>Cancel</button>
                <button type="button" id="save" disabled={isSaved} className="primary-btn large ms-5" onClick={() => onSave(item.id, currentSpec)}>Save</button>
              </div>
            </>
          ) : <button type="button" disabled={!isTSI || projectBasicDetails?.isClosed || projectBasicDetails?.phase > 4} className="secondary-btn mt-4" onClick={() => onSelected(item.id)}>Map to spec</button>
      ) : (
        <>
          <div className="head-14 mt-3">Mapped to Spec</div>
          <div className="d-flex mt-3">
            <div className="col-3">Spec:</div>
            <div className="">{`${item?.specNo} ${item?.specName}`}</div>
          </div>
        </>
      )}
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => { deleteApiCall(); setOpenDeleteModal(false); }}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete the Woha/Defect ?</p>
        </ModalBox>
      ) : (
        ''
      )}

      <Tooltip id="project-variatio" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default WohaCard;
