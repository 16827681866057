import React from 'react';
import ReactTable from 'react-table-v6';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const NonSpecTable = (props) => {
  const { tableData, deteleData, editData } = props;
  const column = [
    {
      Header: () => {
        return (
          <div className="text-center">#SEC</div>
        );
      },
      accessor: 'specificationNo',
      sortable: false,
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis text-center"
            data-tooltip-id="project-sec"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'SECTION NAME',
      accessor: 'title',
      width: 650,
      sortable: false,
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-secname"
            data-tooltip-content={value}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'Actions',
      sortable: false,
      accessor: 'packageName',
      Cell: (row) => {
        const { original } = row;
        return (
          <div>
            <button
              type="button"
              className="link-btn"
              onClick={() => {
                editData(original);
              }}
            >
              <span className="icon-edit icon-btn  brand-color cursor-pointer font-24" />
            </button>
            <button
              type="button"
              className="link-btn ms-3"
              onClick={() => {
                deteleData(original);
              }}
            >
              <span className="icon-delete icon-btn  brand-color cursor-pointer font-24" />
            </button>
          </div>
        );
      }
    }
  ];
  return (
    <div className="stripped-react-table table-scroll" data-testid="nonspec-table">
      <ReactTable
        className="table"
        data={tableData?.items}
        columns={column}
        minRows={0}
        showPagination={false}
      />
      {' '}
      <Tooltip id="project-sec" place="top" className="tooltip mt-4 me-5" />
      <Tooltip id="project-secname" place="top" className="tooltip mt-4 me-5" />
    </div>
  );
};
export default NonSpecTable;
