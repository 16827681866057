import React, { useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { map } from 'lodash';

const InnerTable = (props) => {
  const {
    formData,
    expanded,
    setExpanded,
    handleChangeType,
    checkStatus,
    scrollId,
    isTsiDisabled
  } = props;
  console.log('checkStatus', checkStatus);
  const checkedStatus = (data) => {
    let d = false;
    const pexIds = map(checkStatus, 'id');
    if (pexIds.includes(data.id)) {
      d = true;
    }
    return d;
  };
  useEffect(() => {
    if (scrollId) {
      // const element = document.getElementById(scrollId);
      // element?.scrollIntoView();
    }
  }, [scrollId]);
  const renderTable = (data, showVL) => {
    return (
      <div className={showVL === 'show' ? 'ps-5 verticalLine' : ''}>
        {data.map((original) => {
          const hasChild = original.childComponents && original.childComponents.length;
          return (
            <>
              <div id={`div-${original.id}`} className="d-flex justify-content-between py-3">
                <div className="d-flex justify-content-start">
                  <div>
                    { hasChild ? (
                      <>
                        {expanded.indexOf(original.id) >= 0 ? (
                          <span data-testid="expand" onClick={() => setExpanded(original.id)} className="cursor-pointer font-10 icon-arrow-thin-down rotate-90 me-4 ml-12" />
                        ) : (
                          <span data-testid="collapse" onClick={() => setExpanded(original.id)} className="cursor-pointer font-16 icon-arrow-thin-right me-4 ml-12" />
                        )}
                      </>
                    ) : (
                      <div
                        onClick={(e) => {
                          handleChangeType(original);
                          e.preventDefault();
                        }}
                        className="check-box me-4"
                      >
                        <input
                          checked={checkedStatus(original)}
                          // onChange={(e) => {
                          //   handleChangeType(original);
                          //   e.preventDefault();
                          // }}
                          type="checkbox"
                          disabled={isTsiDisabled}
                          value={original.id}
                          id={`id-${original.id}`}
                        />
                        <label htmlFor={`id-${original.id}`} />
                      </div>
                    )}
                  </div>
                  <div
                    data-tooltip-id="project-comp"
                    data-tooltip-content={original.name}
                  >
                    {original.name}

                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  {hasChild ? '' : (
                    <div className="text-ellipsis w-100">
                      <span
                        className="text-ellipsis"
                        data-tooltip-id="project-comp"
                        data-tooltip-content={original.makerName}
                      >
                        {original.makerName}
                      </span>
                    </div>
                  )}
                  {hasChild
                    ? ''
                    : (
                      <div className="text-ellipsis w-100">
                        <span
                          className="text-ellipsis"
                          data-tooltip-id="project-comp"
                          data-tooltip-content={original.model}
                        >
                          {original.model}
                        </span>
                      </div>
                    )}
                </div>
              </div>
              {hasChild
                && expanded.indexOf(original.id) >= 0
                && renderTable(original.childComponents, 'show')}
            </>
          );
        })}
      </div>
    );
  };
  return (
    <div className="tb-component" data-testid="com-4">
      <div className="d-flex justify-content-between py-3 border-down">
        <div className="ms-3 ps-5 font-bold">COMPONENTS</div>
        <div className="d-flex justify-content-between">
          <div className="text-ellipsis w-100 font-bold">
            MAKER
          </div>
          <div className="text-ellipsis w-100 font-bold">
            MODEL
          </div>
        </div>
      </div>
      {formData && formData.length ? renderTable(formData, 'hide') : ''}
      <Tooltip id="project-comp" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default InnerTable;
