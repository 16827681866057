import React from 'react';
import _, { cloneDeep } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import helper from '../../../../../../../utils/helper';

const SupplementaryTable = (props) => {
  const { ReceivedQuoteStatus, formatNumber } = helper;

  const { specList, setShowView, mainSec, showsubSec,
    onSecOpen, setRecSpecList, viewMore, viewMoreAttachments, viewMoreComments } = props;
  const handleSelectChange = (e, index, subIndex) => {
    if (e) {
      const sp = cloneDeep(specList);
      sp[index].childData[subIndex].variationStatus = e.value;
      sp[index].childData[subIndex].variationStatusName = e.label;
      setRecSpecList(sp);
    }
  };

  const total = (sec) => {
    let a = 0;
    sec.childData.map((item) => {
      a += parseFloat(item.total);
      return null;
    });
    return _.round(a, 2).toString();
  };

  return (
    <div className="spc-map ps-3 supplementary-table" data-testid="sp-table">
      <div className="d-flex border-down mb-2 ps-3">
        <div className="col-2 font-bold">SEC#</div>
        <div className="col-8 font-bold">SECTION</div>
        <div className="col-3 font-bold ps-2">Total</div>
      </div>
      {specList?.map((sec, index) => {
        return (
          <div>
            <div className="d-flex border-down py-2 ps-3">
              <div className="col-2 d-flex align-items-center">
                <span
                  id="open"
                  className={
                    sec?.id === mainSec
                      ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                      : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                  }
                  onClick={() => {
                    onSecOpen(sec?.id, true);
                  }}
                />
                <div
                  className=""
                >
                  <span
                    data-tooltip-id="project-supplementary"
                    data-tooltip-content={sec?.specificationNo}
                  >
                    {_.truncate(sec?.specificationNo, { length: 10 })}
                  </span>
                </div>
              </div>
              <div
                className="col-7 text-ellipsis"
              >
                <span
                  data-tooltip-id="project-supplementary"
                  data-tooltip-content={sec?.title}
                >
                  {_.truncate(sec?.title, { length: 20 })}
                </span>
              </div>
              <div className="col-3 head-14">
                $
                {formatNumber(total(sec)) || 0}
              </div>
            </div>
            {sec?.id === mainSec
              && sec?.childData?.map((subsec, subIndex) => {
                return (
                  <>
                    <div className="d-flex mb-2 pb-2 ps-3 mt-3 border-down">
                      <div className="col-1" />
                      <div className="col-8 d-flex align-items-center">
                        <span
                          id="openSub"
                          className={
                            subsec?.id === showsubSec
                              ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180'
                              : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                          }
                          onClick={() => {
                            onSecOpen(subsec?.id, false);
                          }}
                        />
                        <span className="me-4 ml-12">{subsec?.specificationNo}</span>
                        <div
                          className="d-flex align-items-center text-ellipsis"
                        >
                          <span
                            data-tooltip-id="project-Addvariation"
                            data-tooltip-content={subsec.title}
                          >
                            {_.truncate(subsec?.title, { length: 20 })}
                          </span>

                          {subsec.id === showsubSec && (
                          <div className="d-flex align-items-center ps-5">
                            <button
                              type="button"
                              id="viewmore"
                              className="primary-btn small ms-5"
                              onClick={() => {
                                setShowView(false);
                                viewMore(subsec);
                              }}
                            >
                              View More
                            </button>
                            <button
                              className=" ps-5 brand-color d-flex align-items-center"
                              type="button"
                              disabled={subsec?.specDocuments?.length === 0
                                  || subsec?.specDocuments === null}
                              onClick={() => {
                                viewMoreAttachments(subsec);
                                setShowView(false);
                              }}
                              id="attach"
                            >
                              <span
                                id="attachment-click"
                                className={
                                    subsec?.specDocuments?.length === 0
                                    || subsec?.specDocuments === null
                                      ? 'icon-attachments font-24 brand-color disabled-field'
                                      : 'icon-attachments font-24 brand-color'
                                  }
                              />
                              Attachments
                            </button>
                            <div className="d-flex col-2 ms-5 brand-color">
                              <span
                                data-tooltip-id="project-Addvariation"
                                data-tooltip-content={formatNumber(subsec?.total) || 0}
                              >
                                {subsec?.currencySymbol}
                                {_.truncate(formatNumber(subsec?.total) || 0, { length: 10 })}
                              </span>
                            </div>
                          </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <Select
                            // className="whitespace-pre width-fit"
                            value={ReceivedQuoteStatus
                              .filter((option) => option.value === subsec.variationStatus)}
                            onChange={(e) => handleSelectChange(e, index, subIndex)}
                            className="w-200"
                            options={ReceivedQuoteStatus}
                            id="status"
                            // isDisabled={!isTSI}
                            isDisabled={subsec.isSelected}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="">
                      {subsec?.id === showsubSec
                        && subsec?.specServiceLine?.map((serv, num) => {
                          return (
                            <div>
                              {num === 0 && (
                                <div className="d-flex ms-3 align-items-center bg-sky-white">
                                  <div className="col-1 bg-white py-2 ps-3">&nbsp;</div>
                                  <div className="col-001  ps-3 border-down py-2">Service LN#</div>
                                  <div className="col-4  ps-3 border-down py-2">Service Lines</div>
                                  <div className="col-0  ps-3 border-down py-2">UOM</div>
                                  <div className="col-0  ps-3 border-down py-2">Qty</div>
                                  <div className="col-0  ps-5 border-down py-2">Rate</div>
                                  <div className="col-1  ps-5 border-down py-2">Disc[%]</div>
                                  <div className="col-1  ps-5 border-down py-2">Amount</div>
                                  <div className="col-1  ps-5 border-down py-2">Comments</div>
                                </div>
                              )}
                              <div className="d-flex border-down ps-3">
                                <div className="col-1" />
                                <div className="col-001 ps-3 py-2">{serv.number}</div>
                                <div
                                  className="col-4 text-justify text-break-all ps-3 py-2"
                                >
                                  <span>
                                    {serv.serviceLineName}
                                  </span>

                                </div>
                                <div
                                  className="col-0 ps-3 py-2"
                                >
                                  <span
                                    data-tooltip-id="project-supplementary"
                                    data-tooltip-content={serv.uomName || '-'}
                                  >
                                    {_.truncate(serv.uomName, { length: 6 })}
                                  </span>

                                </div>
                                {/* <div className="col-1 ps-3 py-2">{serv.uomName || '-'}</div> */}
                                <span
                                  className="col-0 ps-3 py-2"
                                  data-tooltip-id="project-supplementary"
                                  data-tooltip-content={serv.quantity || '-'}
                                >
                                  {' '}
                                  {_.truncate(serv.quantity || '-', { length: 6 })}

                                </span>

                                <span
                                  className="col-0 ps-5 py-2"
                                  data-tooltip-id="project-supplementary"
                                  data-tooltip-content={serv.rate || '-'}
                                >
                                  {' '}
                                  {_.truncate(serv.rate || '-', { length: 6 })}

                                </span>
                                <span
                                  className="col-1 ps-5 py-2"
                                  data-tooltip-id="project-supplementary"
                                  data-tooltip-content={serv?.discount?.concat('%') || '-'}
                                >
                                  {' '}
                                  {_.truncate(serv?.discount?.concat('%') || '-', { length: 6 })}

                                </span>
                                <div className="col-1 ps-5 py-2">
                                  <span
                                    data-tooltip-id="project-supplementary"
                                    data-tooltip-content={formatNumber(serv?.amount) || '-'}
                                  >
                                    {serv?.amount ? serv?.currencySymbol : ''}
                                    {' '}
                                    {_.truncate(formatNumber(serv?.amount) || '-', { length: 8 })}

                                  </span>

                                </div>
                                {/* <div className="col-1 ps-3 py-2">
                                  $
                                  {serv.amount || 0}
                                </div> */}
                                <div
                                  id="comments"
                                  className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-5' : 'col-1 ps-5 py-2'}
                                  onClick={() => {
                                    if (serv?.comments !== '' && serv?.comments !== null) {
                                      viewMoreComments(serv?.comments);
                                    }
                                  }}
                                >
                                  <span
                                    className={
                                      serv?.comments === '' || serv?.comments === null
                                        ? ' font-22 icon-add-comment brand-color disabled-field'
                                        : 'font-22 icon-add-comment brand-color cursor-pointer'
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </>
                );
              })}
            <Tooltip id="project-Addvariation" place="top" className="tooltip mt-3" />
            <Tooltip id="project-supplementary" place="top" className="tooltip mt-3" />
          </div>
        );
      })}
    </div>
  );
};
export default SupplementaryTable;
