/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Loader from '../../../../common/loader';
import ModalBox from '../../../../common/ModalBox';

const QuoteStatusModal = (props) => {
  const { statusData, setOpenViewQuoteStatusModal, isLoading } = props;
  const [showSentStatus, setShowSentStatus] = useState(0);
  const handleStatus = (id) => {
    setShowSentStatus(id);
  };

  return (
    <div data-testid="QuoteStatusModal">
      <ModalBox
        heading="Quote Sent Status"
        closeModal={() => {
          localStorage.setItem('fromNotification', false);
          setOpenViewQuoteStatusModal(false);
        }}
        onSave={() => setOpenViewQuoteStatusModal(false)}
        buttonName="Close"
        removeCancelBtn
        className="quoteStatusModal"
      >
        {isLoading ? <Loader /> : (
          <div className="quote-media">
            <div className="d-flex justify-content-start border-down font-13 quote-media">
              {statusData?.map((items, index) => {
                return (
                  <div className={showSentStatus === index ? 'active d-flex tab-items text-center px-2 me-4' : 'd-flex tab-items text-center px-2 me-4'}>
                    <div
                      id="handleStatus"
                      className=""
                      onClick={() => handleStatus(index)}
                    >
                      <div className="">{items.name}</div>
                    </div>
                    <div>
                      <span className="tag-count">{items.count}</span>
                    </div>
                  </div>
                );
              })}

            </div>
            <div className="ps-2">
              <div className="pt-4 scroll-wrap">
                {statusData[showSentStatus]?.yardList?.map(((item) => {
                  return (
                    <div className="card stylecard no-shadow py-3 px-4 mb-3 border-radius-none head-14 ">{item.yardName}</div>

                  );
                }))}
                {statusData[showSentStatus]?.yardList?.length === 0 && (
                <div className="mt-5 p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
                  <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                  <div className="font-18 animate__animated animate__headShake">
                    No data found
                  </div>
                </div>
                )}

              </div>
            </div>
          </div>
        )}

      </ModalBox>

    </div>
  );
};
export default QuoteStatusModal;
