import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';

const AddKFPTable = (props) => {
  const { kfpList, setstate, state } = props;
  const column = [
    {
      Header: 'Code',
      accessor: 'code',
      sortable: false,
      Cell: (row) => {
        const { value, original } = row;
        return (
          <div className="radio  text-ellipsis">
            <label>
              <input
                type="radio"
                value={original.code}
                checked={original.code === state?.code}
                onChange={() => setstate(
                  { ...original,
                    parentSpecificationId: state?.parentSpecificationId
                  }
                )}
              />
              <span
                className="ms-4"
                data-tooltip-id="project-kfp"
                data-tooltip-content={`${value}`}
              >
                {value}
              </span>
            </label>
          </div>
        );
      }
    },
    {
      Header: 'KP Category',
      sortable: false,
      accessor: 'kpCategory',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-kfp"
            data-tooltip-content={`${value}`}
          >
            {value}
          </span>
        );
      }
    },
    {
      Header: 'Description',
      sortable: false,
      accessor: 'description',
      Cell: (row) => {
        const { value } = row;
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-kfp"
            data-tooltip-content={`${value}`}
          >
            {value}
          </span>
        );
      }
    }
  ];
  return (
    <div className="stripped-react-table" data-testid="add-table-kfp">
      <ReactTable
        className="table kfp-map"
        data={kfpList?.items}
        columns={column}
        minRows={0}
        showPagination={false}
      />
      <Tooltip id="project-kfp" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default AddKFPTable;
