import React, { useState } from 'react';
import _ from 'lodash';
import { fileImages } from '../../../../../common/mappingConst';

const UploadedFiles = (props) => {
  const [expand, setExpand] = useState(false);
  const { openAddModal, docs, phase, setOpenDeleteModal, disabled } = props;
  return (
    <div className="border md-radius">
      <div className="d-flex bg-sky-white w-full justify-content-between align-items-center px-3 py-2">
        <h2 className="font-16 font-semibold">{phase.label}</h2>
        <div className="d-flex">
          <div className="relative">
            <button
              type="button"
              className="me-4 brand-color d-flex"
              onClick={() => { openAddModal(docs, phase.id); }}
              disabled={disabled}
            >
              <span className="icon-plus-square me-2" />
              <span className="pb-1">Add Attachment</span>
            </button>
            {docs?.docs?.length ? <div className="document-dot" /> : ''}
          </div>
          <div className={expand ? 'rotate-90' : 'rotate-0'}>
            <span
              className={expand ? 'icon-arrow-thin-right font-22 pointer' : 'icon-arrow-thin-right font-22 pointer'}
              onClick={() => { setExpand(!expand); }}
            />
          </div>
        </div>
      </div>
      {expand && (
      <div className="d-flex">
        <div className="col-10 ps-3">
          {docs?.docs.map((field, index) => {
            return (
              <div className="head-15 d-flex align-items-center my-2">
                {/* <span className="icon-file-text2 brand-color font-22 me-3" /> */}
                <div className="thmb-icon-sm1 me-2 brand-color font-22">
                  <span className={fileImages[_.toLower(field.type)]} />
                </div>
                <div className="head-10 opacity-6 pointer">
                  <a href={field.path} target="_blank" rel="noreferrer">{field?.name}</a>
                </div>
                {!disabled
            && (
            <div className="mt-2 red d-flex align-items-center">
              <a href={field.path} target="_blank" rel="noreferrer">
                <span className="icon-eye ms-4 font-20 brand-color pointer" />
              </a>
              <div>
                <span className="icon-delete ms-4 font-20 brand-color pointer" onClick={() => { setOpenDeleteModal({ key: index, docs, id: phase.id }); }} />
              </div>
            </div>
            )}
              </div>
            );
          })}
        </div>
      </div>
      )}
    </div>
  );
};
export default UploadedFiles;
