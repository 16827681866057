/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import _ from 'lodash';

const statusLabel = {
  Due: 'To Do',
  Overdue: 'Overdue',
  InProgress: 'In Progress',
  Completed: 'Completed',
  NotApplicable: 'Not Applicable',
  Onhold: 'On hold'
};
export const endDateCalc = (list, data) => {
  if (list.status === 3) {
    return moment(list.endDate);
  } if (list.status === 2 || list.status === 5) {
    if (list.endDate) {
      return moment(list.endDate);
    }
    return moment(list.startDate).add(data.offsetWithOnHold, 'day');
  } if (list.status === 1) {
    return moment();
  }
  return moment(list.startDate).add(data.offsetWithOnHold, 'day');
};
export const statusColor = (status) => {
  if (status === 0) {
    return '#16B5F9';
  } if (status === 1) {
    return '#F22C00';
  } if (status === 2) {
    return '#9C59B8';
  } if (status === 3) {
    return '#08B863';
  } if (status === 4) {
    return '#B7B7B7';
  }
  return '#FF9950';
};
export const customDropDown = (changeStatus, data, type, taskId, key) => {
  return (
    <div className={`dot-dropdown ${key === 0 ? ' first_child' : ''}`} key={data.id}>
      <div className="dropdown">
        <button type="button" className="dropbtn">
          <span className="icon-dotted-menu-vertical"> </span>
        </button>
        {data.status !== 3 && (
          <div className="dropdown-content">
            {(data.status === 0 || data.status === 5 || data.status === 4)
            && (
            <a id="start" onClick={() => { changeStatus(data, type, taskId, 2); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(0) }} />
              Start
            </a>
            )}
            {(data.status === 2 || data.status === 1)
            && (
            <a id="hold" onClick={() => { changeStatus(data, type, taskId, 5); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(1) }} />
              Hold
            </a>
            )}
            {(data.status === 2 || data.status === 1)
            && (
            <a id="completed" onClick={() => { changeStatus(data, type, taskId, 3); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(2) }} />
              Completed
            </a>
            )}
            {(data.status !== 3 && data.status !== 4) && (
            <a id="na" onClick={() => { changeStatus(data, type, taskId, 4); }}>
              <span className="t-statusdot mx-1" style={{ backgroundColor: statusColor(3) }} />
              N/A
            </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
// eslint-disable-next-line no-unused-vars
export const groupsList = (data, test, list, editMicro, deleteMicro, changeStatus, details) => {
  const formatData = [];
  data.map((item, key) => {
    formatData.push({
      id: item.id,
      title: (<div className="ms-1 d-flex justify-content-between align-items-center">
        <div className="pointer value d-flex align-items-center" id="click1" onClick={() => { test(item); }}>
          <Tooltip id="project-utility" place="auto" className="tooltip tooltip-w3" />
          {list.indexOf(item.id) > -1
            ? <span className="pointer icon-arrow-thin-down font-8 me-3" />
            : <span className="pointer icon-arrow-thin-right font-12 me-2" />}
          <span className="t-statusdot ms-1" style={{ backgroundColor: statusColor(item.status) }} />
          <span className="pointer text-uppercase label font-semibold" data-tooltip-id="project-utility" data-tooltip-content={item.taskName}>{_.truncate(item.taskName, { length: 28 })}</span>
        </div>
        {/* {details.backupTsiId !== localStorage.getItem('USER_ID') */}
        <div className="d-flex align-items-center">
          {customDropDown(changeStatus, item, 'main', item.id, key)}
        </div>
      </div>)
    });
    if (data.length === 1 && list.indexOf(item.id) === -1) {
      formatData.push({
        id: `${item.id}dummy`,
        title: (<div className="ms-1 d-flex justify-content-between align-items-center opacity-0">
          <div className="pointer value d-flex align-items-center">
            <span className="pointer icon-arrow-thin-right font-12 me-2" />
            <span className="t-statusdot ms-1" style={{ backgroundColor: statusColor(item.status) }} />
            <span className="pointer text-uppercase label font-semibold">{_.truncate(item.taskName, { length: 28 })}</span>
          </div>
        </div>)
      });
    }
    if (list.indexOf(item.id) > -1) {
      item.taskCategoryList.map((sub) => {
        formatData.push({
          id: sub.id,
          title: <div className="ps-2 ms-3 d-flex justify-content-between align-items-center">
            <div className="pointer value d-flex align-items-center" id="click2" onClick={() => { test(sub); }}>
              {list.indexOf(sub.id) > -1
                ? <span className="pointer icon-arrow-thin-down font-8 me-3" />
                : <span className="pointer icon-arrow-thin-right font-12 me-2" />}
              <span className="t-statusdot ms-1" style={{ backgroundColor: statusColor(sub.status) }} />
              <span data-tooltip-id="project-utility" data-tooltip-content={sub.taskCategoryName}>
                {_.truncate(sub.taskCategoryName, { length: 27 })}
              </span>
            </div>
            {/* { details.tsiId === localStorage.getItem('USER_ID') */}
            <div className="d-flex align-items-center">
              <span className="icon-plus-square me-3 font-22 pointer brand-color" id="click3" onClick={() => editMicro(sub, true)}> </span>
              {customDropDown(changeStatus, sub, 'sub', item.id)}
            </div>
          </div>
        });
        if (list.indexOf(sub.id) > -1) {
          sub.microTaskList.map((micro) => {
            formatData.push({
              id: micro.id,
              title: <div className="ps-4 ms-5 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <span className="t-statusdot ms-2" style={{ backgroundColor: statusColor(micro.status) }} />
                  <span data-tooltip-id="project-utility" data-tooltip-content={micro.microTaskName}>
                    {_.truncate(micro.microTaskName, { length: 25 })}
                  </span>
                </div>
                {/* {details.tsiId === localStorage.getItem('USER_ID') */}
                <div className="d-flex align-items-center">
                  <button disabled={micro.status === 3} className="link-btn me-3" type="button" id="click4" onClick={() => editMicro(micro, false)}>
                    <span className="icon-edit font-20 brand-color" />
                  </button>
                  <button disabled={micro.status === 3} className="link-btn me-3" type="button" id="click5" onClick={() => deleteMicro(micro)}>
                    <span className="icon-delete font-20 brand-color" />
                  </button>
                  {customDropDown(changeStatus, micro, 'micro', item.id)}
                </div>
                {/* <Tooltip id="project-utility" place="top" className="tooltip mt-3" /> */}
              </div>
            });
            return null;
          });
        }
        return null;
      });
    }
    return null;
  });
  return formatData;
};

export const itemList = (data, list) => {
  const itemData = [];
  data.map((item) => {
    item.statusDates.map((lvl1Date, key) => {
      itemData.push({
        id: item.id + key,
        group: item.id,
        start_time: moment(lvl1Date.startDate),
        end_time: endDateCalc(lvl1Date, item),
        title: statusLabel[item.statusName]
      });
      return null;
    });
    if (list.indexOf(item.id) > -1) {
      item.taskCategoryList.map((sub) => {
        sub.statusDates.map((lvl2Date, key2) => {
          itemData.push({
            id: sub.id + key2,
            group: sub.id,
            start_time: moment(lvl2Date.startDate),
            end_time: endDateCalc(lvl2Date, sub),
            title: statusLabel[sub.statusName]
          });
          return null;
        });
        if (list.indexOf(sub.id) > -1) {
          sub.microTaskList.map((micro) => {
            micro.statusDates.map((lvl3Date, key3) => {
              itemData.push({
                id: micro.id + key3,
                group: micro.id,
                start_time: moment(lvl3Date.startDate),
                end_time: endDateCalc(lvl3Date, micro),
                title: statusLabel[micro.statusName]
              });
              return null;
            });
            return null;
          });
        }
        return null;
      });
    }
    return null;
  });
  return itemData;
};
