/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProjectHasApprover } from '../services';

const modal = (props) => {
  const navigate = useNavigate();
  const { projectId, name, nameTooltip, showCreateMsg, projectDetails } = props;
  let x;
  const backtoProjects = () => {
    clearInterval(x);
    navigate('/user/projects');
  };
  const timer = () => {
    const countDownDate = new Date(moment().add(1, 'minutes')).getTime();
    x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById('countdownTimer').innerHTML = `${minutes}m ${seconds}s `;
      if (distance < 0) {
        clearInterval(x);
        backtoProjects();
      }
    }, 1000);
  };
  useEffect(() => {
    timer();
  }, []);
  useEffect(() => {
    return () => {
      clearInterval(x);
    };
  }, []);
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(projectId, type)?.then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" data-testid="confirmbuttonpage">
      <div className="head-18 pb-4">
        <span data-tooltip-id="project-page" data-tooltip-content={nameTooltip}>
          {' '}
          {name}
        </span>
      </div>
      <span className="icon-check-rnd tick-green font-50 pb-4" />
      {showCreateMsg ? (
        <span className="head-18">
          {`Project ${projectDetails?.planApprovalStatus === 3 ? 'resubmitted' : 'created'} successfully !!`}
        </span>
      ) : ''}
      <div className="mt-3">
        {hasApprover ? <p className="font-15 font-semibold">Project has been sent to the next level for approval</p>
          : ''}
      </div>
      <div className="p-3">
        <button type="button" className="primary-btn large m-3 p-3" id="backtoprojects" onClick={backtoProjects}>
          Back to projects
        </button>
      </div>
      <div className="d-flex text-center ms-5">
        <p className="d-flex mt-2 ms-3">
          System will automatically be redirected into home page -within
          <div id="countdownTimer" className="ms-3 font-semibold" />
        </p>
      </div>
      <Tooltip id="project-page" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default modal;
