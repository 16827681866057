import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import helper from '../../../../../../utils/helper';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const VerticalBarChart = ({ graphData, projectBasicDetails }) => {
  const { formatNumber } = helper;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      }
    }
  };
  const labels = ['Owner:', 'Yard:', 'Total:'];
  const data = {
    labels,
    datasets: [
      {
        label: 'Project Estimate',
        data: [graphData?.estimateOwner, graphData?.estimateYard, graphData?.estimateTotal],
        backgroundColor: '#f5b1a5'
      },
      {
        label: 'Project Actual',
        data: [graphData?.currentOwner, graphData?.currentYard, graphData?.currentTotal],
        backgroundColor: '#3498db'
      }
      // {
      //   label: 'Deviation Cost',
      //   data: [graphData.deviationOwner, graphData.deviationYard, graphData.deviationTotal],
      //   backgroundColor: '#005FAD'
      // },
    ]
  };
  const numberWithSymbol = (valueNumber) => {
    return `${projectBasicDetails?.currencySymbol} ${valueNumber ? formatNumber(valueNumber) : 0}`;
  };
  return (
    <div className="col-lg-7 col-md-12 card" style={{ height: '380px' }}>
      <div className="head-16 pb-4 border-down p-4 ">Cost Summary</div>
      <div className="row " data-testid="bar-chart">
        <div className="col-7 card-verticalBar p-4">
          <Bar options={options} data={data} />
        </div>
        <div className="col-5">
          <div className="pe-3">
            <div className="my-3">
              <span className="icon-disc estimated-color" />
              <span className="p-2 whitespace-pre font-semibold">Project Estimate</span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              <div>
                {' '}
                Owner
                <span className="ms-2">:</span>

              </div>
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData?.estimateOwner)}
              >
                {numberWithSymbol(graphData?.estimateOwner)}
              </span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              <div>
                Yard
                <span className="ms-2">:</span>
              </div>
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData?.estimateYard)}
              >
                {numberWithSymbol(graphData?.estimateYard)}
              </span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              <div>
                Total
                <span className="ms-2">:</span>

              </div>
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData?.estimateTotal)}
              >
                {numberWithSymbol(graphData?.estimateTotal)}
              </span>
            </div>
            <div className="my-3 pt-4 ">
              <span className="icon-disc actual-color" />
              <span className="p-2 font-semibold">Project Actual</span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              <div>
                Owner
                <span className="ms-2">:</span>

              </div>
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData?.currentOwner)}
              >
                {numberWithSymbol(graphData?.currentOwner)}
              </span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              <div>
                Yard
                <span className="ms-2">:</span>
              </div>
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData?.currentYard)}
              >
                {numberWithSymbol(graphData?.currentYard)}
              </span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              <div>
                Total
                <span className="ms-2">:</span>
              </div>
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData?.currentTotal)}
              >
                {numberWithSymbol(graphData?.currentTotal)}
              </span>
            </div>
            {/* <div className="my-3">
              <span className="icon-disc deviation" />
              <span className="p-2">Deviation Cost</span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              Owner
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData.deviationOwner)}
              >
                {numberWithSymbol(graphData.deviationOwner)}
              </span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              Yard
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData.deviationYard)}
              >
                {numberWithSymbol(graphData.deviationYard)}
              </span>
            </div>
            <div className="d-flex font-13 justify-content-between ps-5 text-content-grey my-1">
              Total
              <span
                className="text-ellipsis ps-2"
                data-tooltip-id="project-phrepair"
                data-tooltip-content={numberWithSymbol(graphData.deviationTotal)}
              >
                {numberWithSymbol(graphData.deviationTotal)}
              </span>
            </div>
             */}
          </div>
        </div>
      </div>
      <Tooltip id="project-phrepair" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default VerticalBarChart;
