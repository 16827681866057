/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import ModalBox from '../../../../../common/ModalBox';

const StatusConfirm = (props) => {
  const { setShowConfirm, onSpecStatusChange, showConfirm, specList } = props;
  const [reason, setReason] = useState('');
  //   {specification.workOrderClosedCount !== specification.workOrderTotalCount}
  const specification = specList.specQuoteList[showConfirm.key].specDataList[showConfirm.subKey];
  return (
    <div data-testid="YardCostSummary-status">
      <ModalBox
        modalClassName="medium"
        heading="Confirm - Status Update"
        closeModal={() => { setShowConfirm(false); }}
        buttonName="Continue"
        saveDisable={(showConfirm.e?.value !== 4
        || specification.workOrderClosedCount !== specification.workOrderTotalCount)
          ? !reason : false}
        onSave={() => {
          onSpecStatusChange(showConfirm.e, showConfirm.key,
            showConfirm.subKey, reason);
        }}
      >
        {specification.workOrderClosedCount !== specification.workOrderTotalCount
        && (
        <div className="mt-2 mb-3 d-flex">
          <span className="icon-Info me-3 font-24 brand-color" />
          Please note: There are Work Orders mapped to the specification which are not completed.
        </div>
        )}
        <label className="mb-1 ms-1">
          Add comment
          {(showConfirm.e?.value !== 4
        || specification.workOrderClosedCount !== specification.workOrderTotalCount)
         && <span className="red">*</span>}
        </label>
        <textarea
          autoFocus
          id="reason"
          onChange={(e) => {
            setReason(e.target.value);
          }}
          type="text"
          name="reason"
          value={reason}
          className="text-box w-full h-text-area p-2 mt-1 ms-1"
        >
          {reason}
        </textarea>
      </ModalBox>
    </div>
  );
};
export default StatusConfirm;
