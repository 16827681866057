import React from 'react';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const ContentFooter = (props) => {
  const { specList, setSpecList } = props;
  const { restrictDecimal, charValidation } = helper;
  const onUpdateHeader = (e, type) => {
    const currentSpecList = { ...specList };
    if (type === 'int') {
      currentSpecList.rfqData[e.target.name] = restrictDecimal(charValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 15));
    } else {
      currentSpecList.rfqData[e.target.name] = e.target.value;
    }
    currentSpecList.rfqData.yardFinalEstimate = (currentSpecList.rfqData
      .total - currentSpecList.rfqData.discount).toFixed(2).toString();
    currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList?.rfqData
      ?.total)?.toString();
    setSpecList(currentSpecList);
  };

  // const formatNumber = (number) => {
  //   if (number == null) {
  //     return '';
  //   }
  //   return number.toLocaleString('en-IN', {
  //     style: 'decimal',
  //     maximumFractionDigits: 2,
  //     minimumFractionDigits: 2
  //   });
  // };

  return (
    <>
      <div className="border-down my-3" />
      <div className="bg-sky-white p-3 d-flex justify-content-end sm-radius">
        <div className="w-280 pe-4">
          <InputType1
            min="0"
            type="number"
            step="0.01"
            id="discount"
            label={`Discount (${specList.rfqData?.currencySymbol})`}
            name="discount"
            value={specList?.rfqData?.discount}
            onChange={(e) => { onUpdateHeader(e, 'int'); }}
          />
        </div>
        <div className="w-280 pe-4">
          <InputType1
            id="YardEstimateWithoutDiscount"
            label={`Estimate Without Discount (${specList.rfqData?.currencySymbol})`}
            name="YardEstimateWithoutDiscount"
            disabled
            value={specList?.rfqData?.total}
          />
        </div>
        <div className="w-280 pe-4">
          <InputType1
            id="final"
            label={`Yard Final Estimate (${specList.rfqData?.currencySymbol})`}
            disabled
            name="yardFinalEstimate"
            value={specList?.rfqData?.yardFinalEstimate}
            onChange={(e) => { onUpdateHeader(e, 'int'); }}
          />
        </div>
      </div>
    </>
  );
};
export default ContentFooter;
