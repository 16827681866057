import React from 'react';

const ManualCard = (props) => {
  const { onContiue } = props;
  return (
    <div className="card p-4 quote_selcard me-5" data-testid="manual-card">
      <div className="mt-4 p-5 bg-sky-white round-radius"><span className="blue icon-rfq-manual font-35" /></div>
      <div className="font-bold mt-3 head">Manual RFQ</div>
      <div className="my-3 px-2 details">
        Download the DD attachments and enter the
        quotes in excel, send it to the Email provided in the instructions.
      </div>
      <button type="button" className="primary-btn mt-3 mb-4" onClick={() => { onContiue(2, true); }}>Agree and download DD files</button>
    </div>
  );
};
export default ManualCard;
