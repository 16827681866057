/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { isFilledAll } from '../yardEvaluation/constants';
import Comments from '../../../Comments';
import helper from '../../../../../../utils/helper';
import { getCommentsOnPhase } from '../../../services';
import PolicyFooter from '../../../../../Admin/policyFooter';
import PrivacyPolicy from '../../../../../Policies/privacy';
import TermsOfUse from '../../../../../Policies/TermsOfUse';
import { getProjectHasApprover } from '../../../NewProject/services';
import TaskModal from '../../Repair/TaskModal';
import { NotCompletedTasksDetails } from '../../Repair/services';
import { downloadYardEvaluation } from '../services';

const FooterButton = (props) => {
  const { view, setView, savePageSelect, onReload, specList, ratings,
    setShowCloseConf, setShowSubmit, setShowApprove, sentforRevision,
    pjtStatus, projInfo, aproveDisable, id, buttonClicked, setButtonClicked } = props;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const { roles } = helper;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR;
  const isAdmin = userRole === roles.ADMIN;
  const isNotApprover = userRole === roles.AUTHOR || userRole === roles.ADMIN;
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);
  const [isDownload, setIsDownload] = useState(false);

  const getName = (resubmit = false) => {
    let n = 'Submit';
    if (resubmit) {
      n = 'Resubmit';
    }
    if (hasApprover) {
      n += ' for Approval';
    }
    return n;
  };
  /* istanbul ignore next */
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  const [taskData, setTaskData] = useState([]);
  const [openTaskModal, setopenTaskModal] = useState(true);

  const checkTaskStatus = () => {
    setopenTaskModal(true);
    NotCompletedTasksDetails({ phaseId: '5', projectId: id }).then((res) => {
      if (res.success) {
        setTaskData(res?.data);
        if (res?.data && res?.data?.length) {
          //
        } else {
          setShowSubmit(true);
        }
      }
    });
  };
  const downloadReport = () => {
    setIsDownload(true);
    downloadYardEvaluation(id)
      .then(() => {
        setIsDownload(false);
      });
  };

  return (
    <>
      {taskData.length > 0 && openTaskModal && (
      <TaskModal
          // isLoading={isLoading}
        taskData={taskData}
        openTaskModal={openTaskModal}
        setopenTaskModal={setopenTaskModal}
        isClose="closed"
      />
      )}
      <div className=" fixed-footer d-flex justify-content-between bg-white disable_footbtn">
        <div>
          <Comments id={id} appendClassName="comments-btn" buttonClicked={buttonClicked} setButtonClicked={setButtonClicked} />
        </div>
        {isAdmin ? (
          <div className="text-black font-12 footer-specify" style={{ marginRight: '100px' }}>
            <div>© 2024 ThinkPalm</div>

          </div>
        ) : (
          <div className="text-black font-12 footer-specify" style={{ marginRight: '100px' }}>
            <div>© 2024 ThinkPalm</div>

          </div>
        )}

        <div className="d-flex justify-content-end bg-white">
          {isTSI && view !== 3 && (
          <button
            type="button"
            id="cancel"
            className="secondary-btn large mr-10"
            onClick={() => onReload(view)}
            disabled={view === 1 ? specList.status > 0 : (view === 2 ? ratings.status > 0 : false)}
          >
            Cancel
          </button>
          )}
          {(view === 2 && ratings.status === 1) && (
            <button
              disabled={isDownload}
              type="button"
              className="secondary-btn yard-download large mr-10"
              onClick={() => { downloadReport(); }}
            >
              Download
            </button>
          )}
          {view > 1 && (
          <button
            disabled={view === 1}
            type="button"
            id="prev"
            className="secondary-btn large mr-10"
            onClick={() => { setView(view - 1); }}
          >
            Previous
          </button>
          )}
          {((view === 1 && specList.status === 1) || (view === 2 && ratings.status === 1)) && (
          <button
            id="next"
            type="button"
            className="primary-btn large mr-10"
            onClick={() => { setView(view + 1); }}
          >
            Next
          </button>
          )}
          {(isTSI || isAdmin) && (
          <>
            <button
              type="button"
              className="secondary-btn large mr-10"
              disabled={view === 1 ? true
                : (view === 2 ? ratings.status > 0 : view === 3)}
              onClick={() => { savePageSelect(view); }}
              id="draft"
            >
              Save as Draft
            </button>
            {view !== 3 && (
            <button
              type="button"
              id="continue"
              className="primary-btn large mr-10"
              disabled={view === 1 ? projInfo?.isClosed : (view === 2
                ? ratings.status > 0 : false)}
              onClick={() => { savePageSelect(view, true); }}
            >
              Confirm and Continue
            </button>
            )}
            {isTSI && view === 3 && (
            <button
              type="button"
              id="closepjt"
              className="primary-btn large mr-10"
              onClick={() => checkTaskStatus()}
              disabled={(projInfo?.closeApprovalStatus !== 0
            && projInfo?.closeApprovalStatus !== 3) || aproveDisable}
            >
              {projInfo?.closeApprovalStatus === 3 ? getName(true) : getName()}
            </button>
            )}
            {view === 3 && projInfo?.closeApprovalStatus === 2 && (
            <button
              type="button"
              className="primary-btn large mr-10"
            // onClick={onCloseProject}
              onClick={() => setShowCloseConf(true)}
              disabled={projInfo?.closeApprovalStatus !== 2 || projInfo.isClosed}
            >
              Close Project
            </button>
            )}
          </>
          )}
          {!isNotApprover && view === 3 && (
          <>
            <button
              type="button"
              className="secondary-btn large mr-10"
              id="revision"
              onClick={sentforRevision}
              disabled={pjtStatus < 1}
            >
              Submit for revision
            </button>
            <button
              type="button"
              className="primary-btn large mr-10"
              id="approve"
              onClick={() => { setShowApprove(true); }}
              disabled={pjtStatus < 1}
            >
              Approve
            </button>
          </>
          )}
        </div>
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </>
  );
};
export default FooterButton;
