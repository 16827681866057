/* eslint-disable max-len */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import InputType1 from '../../../../../../common/InputType1';

const ZERO_UUID = '00000000-0000-0000-0000-000000000000';

const NavbarSupplementary = (props) => {
  const {
    saveForm,
    isLoading,
    sectionList,
    handleChange,
    formData,
    isSaved,
    setIsSaved,
    specId,
    sectionName,
    addClicked,
    setIsAddClicked,
    isTSI,
    specDetails,
    projInfo,
    isVesselUser
  } = props;
  console.log(projInfo, 'projInfo1');

  const AssigneeDropDown = [
    { label: 'Yard', value: 1 },
    { label: 'Vessel', value: 2 },
    { label: 'Sub Contractor', value: 3 }
  ];

  return (
    <div data-testid="sp-nav">
      {!isSaved ? (
        <div className="d-flex col-gap-2 align-items-center p-4 bg-sky-white card" data-testid="wodefect-nav">
          <div className="col-2">
            <label>
              Sec #
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              value={sectionList?.filter((option) => option.value === formData.sectionId)}
              onChange={(option) => handleChange(option, 'sectionId', 'select')}
              className=""
              label="code"
              isDisabled={specId !== ZERO_UUID}
              options={sectionList}
              id="sectionId"
            />
          </div>
          <div className="col-2">
            <InputType1
              value={formData?.specNoAutoGenerated}
              type="text"
              id="specNoAutoGenerated"
              disabled
              label="Spec #"
              className="text-box large"
              noMargin
            />
          </div>
          <div className="col-3">
            <InputType1
              value={formData?.specTitle}
              type="text"
              id="specTitle"
              isMandatory="true"
              label="Specification"
              className="text-box large"
              onChange={(e) => {
                handleChange(e, 'specTitle', 'text');
              }}
              noMargin
            />
          </div>

          <div className="col-2">
            <label>
              Assignee
              <span className="text-primaryRed-1">*</span>
            </label>

            <Select
              // className="status-select w-150"
              options={AssigneeDropDown}
              className=""
              label="code"
              //  isDisabled={isTsiDisabled}
              id="specAssigned"
              placeholder="Select..."
              value={AssigneeDropDown?.filter((option) => option.value === specDetails?.specAssigned)}
              onChange={(option) => handleChange(option, 'specAssigned', 'select')}
            />
          </div>

          <button
            id="save"
            className="primary-btn mt-auto large"
            data-testid="save"
            type="button"
            onClick={() => {
              saveForm();
            }}
            disabled={isLoading}
          >
            {addClicked ? 'Add' : 'Save'}
          </button>
        </div>
      ) : (
        <div className="d-flex col-gap-2 align-items-center bg-sky-white card-inverse p-3">
          <div className="d-flex col-2">
            <div className="">Sec# : </div>
            {/* <div className="value ml-8">
                {sectionName}
              </div> */}
            <div data-tooltip-id="project-poha" data-tooltip-content={sectionName} className="value ml-8 text-ellipsis">
              <span>{sectionName}</span>
            </div>
          </div>
          <div className="d-flex col-2">
            <div className="">Spec# : </div>
            {/* <div className="value ml-8">{formData?.specNoAutoGenerated}</div> */}
            <div data-tooltip-id="project-poha" data-tooltip-content={formData?.specNoAutoGenerated} className="value ml-8 text-ellipsis">
              <span>{formData?.specNoAutoGenerated}</span>
            </div>
          </div>
          <div className="d-flex col-3">
            <div>Spec : </div>
            <div className="value ms-2 text-ellipsis" data-tooltip-id="project-poha" data-tooltip-content={formData?.specTitle}>
              {_.truncate(formData?.specTitle, { length: 20 })}
            </div>
          </div>
          <div className="d-flex col-3">
            <div>Spec Assigned : </div>
            {/* <div data-tooltip-id="project-poha"
              data-tooltip-content={formData?.specAssigned}
              className="value ml-8 text-ellipsis"> */}
            <span className="value ml-8 text-ellipsis">{specDetails?.specAssignedName}</span>

            {/* </div> */}
          </div>
          {(isTSI || isVesselUser) && (
            <button
              type="button"
              onClick={() => {
                setIsSaved(false);
                setIsAddClicked(false);
              }}
              className="link-btn col-1"
              disabled={projInfo?.isClosed}
            >
              <span className="icon-edit font-22 pe-3" />
            </button>
          )}
        </div>
      )}
      <Tooltip id="project-poha" place="bottom" className="tooltip" />
    </div>
  );
};

export default NavbarSupplementary;
