import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import userImg from '../../../../images/user-placeholder.jpg';

const Card = (props) => {
  const { item, showAddContactModal, deleteContact, isClosed } = props;
  return (
    <div className="card mt-4 p-4" data-testId="contact-screen">
      <div className="d-flex">
        {/* {item.images ? <img src={item.images} alt="" className="thmb-xlg-card" />
        : <div className="thmb-xlg-card user-thmb m-auto" />} */}
        <div className="contact-img"><img src={item.images || userImg} alt="" className="thmb-xlg-card" /></div>
        <div className="ps-3 w-full">
          <div className="d-flex col-gap-1">
            <p
              className="value pb-2"
              data-tooltip-id="project-managementrole"
              data-tooltip-content={item.name}
            >
              {_.truncate(item?.name, {
                length: 80,
                separator: ' '
              })}
            </p>
            <div className="d-flex docTypeName-color status align-items-center  width-fit ">
              <span
                data-tooltip-id="project-managementrole"
                data-tooltip-content={item.contactCategory}
              >
                {_.truncate(item?.contactCategory, {
                  length: 20,
                  separator: ' '
                })}

              </span>
            </div>
          </div>
          <div className="d-flex pt-2 col-12 justify-content-between">
            <div className="col-4 opacity-6">
              <span
                data-tooltip-id="project-managementrole"
                data-tooltip-content={item.position}
              >
                {_.truncate(item?.position, {
                  length: 20,
                  separator: ' '
                })}

              </span>

              <p
                className="pt-2"
              >
                <span
                  data-tooltip-id="project-managementrole"
                  data-tooltip-content={item.company}
                >
                  {_.truncate(item?.company, {
                    length: 25,
                    separator: ' '
                  })}
                </span>

              </p>
            </div>
            <div className="col-4 ms-3">
              <div className="d-flex align-items-center  opacity-6">
                <span className="icon-cell-phone pe-3 font-22" />
                <p
                  data-tooltip-id="project-managementrole"
                  data-tooltip-content={item.mobile}
                >
                  {_.truncate(item?.mobile, {
                    length: 20,
                    separator: ' '
                  })}
                </p>
              </div>
              <div className="d-flex align-items-center pt-3  opacity-6">
                {item?.telephone && (
                <>
                  <span className="icon-phone pe-3 font-18" />
                  <p
                    data-tooltip-id="project-managementrole"
                    data-tooltip-content={item.telephone}
                  >
                    {_.truncate(item?.telephone, {
                      length: 20,
                      separator: ' '
                    })}
                  </p>
                </>
                )}

              </div>
            </div>
            <div className="col-4 ms-3">
              <div className="d-flex align-items-center  opacity-6">
                {item?.email && (
                <>
                  <span className="icon-email pe-3 font-18" />
                  <p
                    data-tooltip-id="project-managementrole"
                    data-tooltip-content={item.email}
                  >
                    {_.truncate(item?.email, {
                      length: 13,
                      separator: ' '
                    })}
                  </p>
                </>
                )}

              </div>
              <div className={item?.email ? 'd-flex align-items-center pt-3 opacity-6' : 'd-flex align-items-center  opacity-6'}>
                {item?.whatsapp && (
                <>
                  <span className="icon-whatsapp  pe-3 font-18" />
                  <p
                    data-tooltip-id="project-managementrole"
                    data-tooltip-content={item.whatsapp}
                  >
                    {_.truncate(item?.whatsapp, {
                      length: 15,
                      separator: ' '
                    })}
                  </p>
                </>
                )}

              </div>
            </div>
          </div>
        </div>
        <div className="d-flex ms-5">
          <button
            type="button"
            className="link-btn"
            disabled={isClosed}
          >
            <span
              className="icon-edit icon-btn  brand-color cursor-pointer font-20"
              id="showAddContactModal"
              onClick={() => {
                showAddContactModal(item);
              }}
            />
          </button>
          <button
            type="button"
            id="deleteContact"
            className="link-btn"
            disabled={isClosed}
            onClick={() => {
              deleteContact(item);
            }}
          >
            <span className="icon-delete icon-btn  brand-color cursor-pointer font-20" />
          </button>
        </div>
      </div>
      <Tooltip id="project-managementrole" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Card;
