import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';

const CreateVendor = ({ setVendor, setOpenModalVendor, vendorList,
  addVendor, vendor, isTSI, setOpenDeleteVendorModal }) => {
  const [isAddNewTagModal, setIsAddNewTagModal] = useState(false);
  return (
    <div className="back-drop" data-testid="vendor-add">
      <div className="modal medium">
        <div className="modal-header">
          <h3 className="">Vendors</h3>
          <button
            type="button"
            className="link-btn"
            onClick={() => {
              setVendor('');
              setOpenModalVendor();
            }}
          >
            <span className="icon-close font-26"> </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="modal-content p-3 scroll-wrap">
            <div className="">
              <div className=" p-3">
                {vendorList && vendorList.length ? (
                  <>
                    <div className="row border py-2 bg-sky-blue">
                      <div className=" t-head ps-5">VENDOR NAME</div>
                    </div>
                    {vendorList.map((i, index) => {
                      return (
                        <div className="row border py-2">
                          <div className="col-10 ps-5 text-ellipsis">
                            <span data-tooltip-id="project-cost" data-tooltip-content={i?.vendor?.toUpperCase()}>
                              {i?.vendor?.toUpperCase()}
                            </span>
                          </div>
                          <div
                            className="col-2"
                            data-tooltip-id="project-cost"
                            data-tooltip-content={vendorList.length < 2 ? 'At least one vendor is mandatory' : ''}
                          >
                            <button
                              type="button"
                              className="link-btn ms-3"
                              onClick={() => {
                                setOpenDeleteVendorModal(index);
                              }}
                              disabled={vendorList.length < 2}

                            >
                              <span className="icon-delete icon-btn  brand-color cursor-pointer font-24" />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          {isAddNewTagModal ? (
            <div className="align-items-center bg-blue d-flex md-radius py-2 row">
              <div className="col-9">
                <InputType1
                  autoFocus
                  id="vendor"
                  onChange={(e) => setVendor(e.target.value)}
                  value={vendor?.toUpperCase()}
                  className="modal-inputs small "
                  label="Vendor"
                  isMandatory="true"
                />
              </div>
              <div className="col-3 pt-4">
                <div className="flex-row pt-3">
                  <button
                    id="testcancel"
                    className="link-btn me-5"
                    type="button"
                    onClick={() => {
                      setVendor('');
                      setIsAddNewTagModal(false);
                    }}
                  >
                    <span className="icon-close me-1" />
                    Cancel
                  </button>
                  <button id="save-ven" onClick={() => addVendor()} className="link-btn" type="button">
                    <span className="icon-save me-1" />
                    Add
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-lg-start py-2">
              <button disabled={!isTSI} id="add-ven" className="link-btn" type="button" onClick={() => setIsAddNewTagModal(true)}>
                <span className="icon-plus-square me-2" />
                Add New Vendor
              </button>
            </div>
          )}
        </div>
      </div>
      <Tooltip id="project-cost" className="tooltip " />
    </div>
  );
};
export default CreateVendor;
