import React from 'react';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import Constants from '../../../../utils/helper';

const AddKfp = (props) => {
  const { formData, handleChange, uomList } = props;
  const formattedUom = uomList.map((item) => ({ label: item.uomName, value: item.uomId }));
  return (
    <>
      <div className="modal-content" data-testid="create-kfp">
        <div className="row">
          <div className="col-4">
            <InputType1 autoFocus id="code" value={formData.code} onChange={(e) => handleChange(e, '', 'code')} className="modal-input" name="code" label="Code" isMandatory="true" noMargin />
          </div>
          <div className="col-4">
            <label>
              KP Category
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              value={Constants.KpCategory
                .filter((option) => option.value === formData.kpCategoryId)}
              onChange={(option) => handleChange(option, 'kpCategoryId', 'kpCategoryId')}
              className="modal-select"
              label="KP Category"
              options={Constants.KpCategory}
              id="kpCategoryId"
            />
          </div>
          <div className="col-4">
            <label>
              UOM
              <span className="text-primaryRed-1">*</span>
            </label>
            <Select
              value={formattedUom.filter((option) => option.value === formData.uomId)}
              onChange={(option) => handleChange(option, 'uomId', 'uomId')}
              className="modal-select"
              label="UOM"
              options={formattedUom}
              id="uomId"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <InputType1 id="description" value={formData.description} onChange={(e) => handleChange(e, '', 'description')} name="description" className="modal-input" label="Description" isMandatory="true" />
          </div>
        </div>
      </div>
    </>
  );
};
export default AddKfp;
