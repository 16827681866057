import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ReactTable from 'react-table-v6';

const InnerTable = (props) => {
  const { innerTableData } = props;
  const column = [
    {
      Header: '',
      sortable: false,
      expander: true,
      resizable: false,
      width: 47,
      Expander: ({ isExpanded, original }) => {
        if (original.childComponents && original.childComponents.length) {
          if (isExpanded) return <span className="font-10 icon-arrow-thin-down rotate-90 " />;
          return <span className="font-16 icon-arrow-thin-right" />;
        }
        return <span className="icon-disc color-tree-icon" />;
      }
    },
    {
      Header: 'Components',
      accessor: 'name',
      sortable: false
    },
    {
      Header: 'Maker',
      accessor: 'name',
      width: 100,
      style: { paddingLeft: '30px' },
      sortable: false,
      resizable: false,
      Cell: (row) => {
        const { original } = row;
        if (original.childComponents && original.childComponents.length) {
          return '';
        }
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-mode"
            data-tooltip-content={`${original.makerName}`}
          >
            {original.makerName}
          </span>
        );
      }
    },
    {
      Header: 'Model',
      accessor: 'name',
      sortable: false,
      resizable: false,
      width: 100,
      style: { paddingLeft: '30px' },
      Cell: (row) => {
        const { original } = row;
        if (original.childComponents && original.childComponents.length) {
          return '';
        }
        return (
          <span
            className="text-ellipsis"
            data-tooltip-id="project-mode"
            data-tooltip-content={`${original.model}`}
          >
            {original.model}
          </span>
        );
      }
    }
  ];
  const renderTable = (data, showVL) => {
    return (
      <div className={showVL === 'show' ? 'ps-5 verticalLine' : ''}>
        <ReactTable
          className="component-scroll"
          data={data}
          columns={column}
          minRows={0}
          showPagination={false}
          SubComponent={(mainProp) => {
            const { childComponents } = mainProp.original;
            if (childComponents && childComponents.length) {
              return renderTable(childComponents, 'show');
            }
            return null;
          }}
        />
      </div>
    );
  };
  return (
    <div className="tb-component" data-testid="com-3">
      {innerTableData && innerTableData.length ? (
        renderTable(innerTableData, 'hide')
      ) : ''}
      <Tooltip id="project-mode" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default InnerTable;
