import React from 'react';
import ReactPaginate from 'react-paginate';

const KFPPagination = ({ pageClick, kfpList, inputObject }) => {
  return (
    <div data-testid="kfp-pagination">
      {kfpList?.totalItems > 5 && (
      <div className=" mt-2 d-flex justify-content-start align-items-center">
        <ReactPaginate
          breakLabel="..."
          className="d-flex pagination"
          pageClassName="page-item"
          nextLabel=">"
          onPageChange={pageClick}
          pageRangeDisplayed={3}
          pageCount={kfpList.totalPages}
          previousLabel="<"
          renderOnZeroPageCount={null}
          forcePage={inputObject.page - 1}
        />
      </div>
      )}
    </div>
  );
};
export default KFPPagination;
