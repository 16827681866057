import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import Loader from '../../../../common/loader';
import { cupReportDownload, getCostUpdationReport } from '../services';
import helper from '../../../../../utils/helper';
import CostUpdateReportComponent from './CostUpdateReportComponent';

const CostUpdateReport = () => {
  const { formatNumber } = helper;
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({
    projectId: '',
    repairStartDate: '',
    repairEndDate: '',
    costData: []
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const reloadTable = () => {
    const payload = {
      page,
      pageSize,
      search: '',
      sortProperty: '',
      isDescending: true,
      projectId: id
    };
    getCostUpdationReport(payload).then((res) => {
      setLoading(false);
      setTableData(res.data);
    });
  };

  useEffect(() => {
    reloadTable();
    setLoading(true);
  }, []);

  useEffect(() => {
    reloadTable();
  }, [page, pageSize]);
  const downloadReport = () => {
    const payload = {
      projectId: id
    };
    cupReportDownload(payload);
  };
  return (
    <div data-testid="CostUpdateReport">
      <div className="align-items-center d-flex justify-content-between report-ctrl">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <div className="font-20 font-bold">Cost Update Report</div>
            <div className="ms-3 couter-bubbles status-due ">{tableData?.costData?.totalItems}</div>
          </div>
          <div className="d-flex align-items-center me-5">
            <label>
              Repair Period
              <span className="ms-2">:</span>
            </label>
            &nbsp;
            <div className="value">{tableData?.repairStartDate ? moment(tableData?.repairStartDate).format('DD-MMM-yyyy') : ''}</div>
            <div className="mx-4 value"> - </div>
            <div className="value">{tableData?.repairEndDate ? moment(tableData?.repairEndDate).format('DD-MMM-yyyy') : ''}</div>
          </div>
        </div>
        {/* <div>
          <button type="button" className="secondary-btn">
            Print/Share
          </button>
        </div> */}
      </div>
      <div className="report-canvas mt-4">
        <div className="p-4">
          {loading ? (
            <Loader />
          ) : (
            <CostUpdateReportComponent
              downloadReport={downloadReport}
              tableData={tableData}
              setPage={setPage}
              setPageSize={setPageSize}
              page={page}
              pageSize={pageSize}
              formatNumber={formatNumber}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CostUpdateReport;
