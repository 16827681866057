/* eslint-disable */
import { React } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ModalBox from '../../../../common/ModalBox';
import Loader from '../../../../common/loader';
import qs from 'query-string';
 
const TaskModal = ({ isLoading, setopenTaskModal, taskData, isClose }) => {
  const params = qs.parse(window.location.search);
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <div>
      <ModalBox
        closeModal={() => {
          setopenTaskModal(false);
          navigate(`/user/project/${id}/task?vesselId=${params?.vesselId}`);
        }}
        heading={
          taskData.length > 0 ? (
            <div className="d-flex">
              <span>InComplete Tasks </span>
              {' '}
              <span className="ms-3 primary-btn small count-badge">{taskData.length}</span>
            </div>
          ) : (
            'Close the Repair phase'
          )
        }
        onSave={() => {
          setopenTaskModal(false);
          navigate(`/user/project/${id}/task?vesselId=${params?.vesselId}`);
        }}
        removeCancelBtn
        buttonName="Ok"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <div className="ps-2">
              <div className=" scroll-wrap">
                <div className="font-bold">
                  {isClose
                    ? 'Please complete the open tasks in Tasks page under Repair and Close phases to Submit the project.'
                    : 'Please complete the open tasks in Tasks page under Plan, Specify, and Tender Phases to proceed to Repair Phase.'}
                </div>
                <div className="">
                  {taskData.map((item) => {
                    return (
                      <>
                        <div className="cursor-pointer mt-3 py-2 d-flex align-items-center bg-sky-white md-radius"
                        
                        onClick={()=>
                          {
                          const phaseId=item.phaseId;
                          navigate(`/user/project/${id}/task?vesselId=${params?.vesselId}&phaseId=${phaseId}`);
                          }}
                          >
                          {' '}
                          <div className="ps-3">
                            <span data-tooltip-id="project-specc ms-2">
                              {item.phase}
                              {' '}
                              -
                              {' '}
                              {item.taskName}
                            </span>
                            <span>
                              {' '}
 
                            </span>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </ModalBox>
    </div>
  );
};
 
export default TaskModal;
