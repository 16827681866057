import React, { useMemo } from 'react';
import JoditEditor from 'jodit-react';

const Description = (props) => {
  const { specData, isFromDownloadSpec } = props;
  if (!specData.specDescData?.description) {
    return (
      <div className="mt-5 p-4 d-flex justify-content-center align-items-center " data-testid="yard-desc">
        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
        <div className="font-18 animate__animated animate__headShake">
          No data found
        </div>
      </div>
    );
  }
  const config = useMemo(
    () => ({
      readonly: true,
      toolbar: false
    }),
    [isFromDownloadSpec]
  );
  return (
    <div data-testid="yard-desc" className="description-main">
      <div className="text-editor mt-2">
        <div className="editor-wrap-description view-jodit jodit-full">
          <JoditEditor
            value={specData.specDescData.description}
            config={config}
          />
        </div>
      </div>
    </div>
  );
};
export default Description;
