/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Activites from './Activites';
import AddImages from '../SpecComponents/addImages';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import { s3UploadMutiple } from '../../../NewProject/services';
// import { s3Upload } from '../../../../../Admin/Vessels/Vessel/services';
import { getGalleryImages, saveGalleryImages, deleteGalleryImages } from '../services';
import ModalBox from '../../../../../common/ModalBox';
import Loader from '../../../../../common/loader';

const acceptedFiles = ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff'];
const Gallery = (props) => {
  const { showGallery, isCompleted = false, isTSI, isVesselUser } = props;
  const [showAdd, setShowAdd] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState('');
  const [allData, setAllData] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [newImage, setNewImage] = useState({ specId: showGallery.projectSpecId, imageName: '', imageDescription: '', imageUrlList: [] });
  const getAllImages = () => {
    getGalleryImages({ id: showGallery.projectSpecId }).then((res) => {
      if (res.success) {
        setLoading(false);
        setAllData(res.data);
      }
    });
  };
  useEffect(() => {
    getAllImages();
    setLoading(true);
  }, []);

  const { MAX_FILE_SIZE } = helper;
  const ondrop = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length > 10) {
      showAlert('You are only allowed to upload a maximum of 10 files at a time', 'error');
      return;
    }

    if (selectedFiles.length > 0) {
      const duplicateFiles = [];
      const validFiles = [];

      selectedFiles.forEach((files) => {
        if (fileNames.indexOf(files.name) > -1) {
          duplicateFiles.push(files.name);
        } else if (acceptedFiles.includes(files.type)) {
          const fileSize = files.size / 1024 / 1024;
          if (fileSize > MAX_FILE_SIZE) {
            showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
          } else {
            validFiles.push(files);
          }
        } else {
          showAlert('Please upload a valid file type', 'error');
        }
      });

      if (duplicateFiles.length > 0) {
        showAlert(`Duplicate images: ${duplicateFiles.join(', ')}`, 'error');
      }

      if (validFiles.length > 0) {
        const body = new FormData();
        validFiles.forEach((files) => {
          body.append('files', files);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length > 0) {
          const currentImage = { ...newImage };
          const currentFiles = [...fileNames];

          res.data.forEach((item) => {
            currentFiles.push(item.name);
            currentImage.imageUrlList
              .push({ imageUrl: item.path, subImageName: newImage.imageName });
          });

          setFileNames(currentFiles);
          setNewImage(currentImage);
          showAlert('Files Uploaded Successfully', 'success');
        }
      }
    } else {
      showAlert('No valid files selected', 'error');
    }

    e.target.value = '';
  };

  const onRemoveImg = (key) => {
    const currentImage = { ...newImage };
    const currentFiles = [...fileNames];
    currentImage.imageUrlList.splice(key, 1);
    currentFiles.splice(key, 1);
    setNewImage(currentImage);
    setFileNames(currentFiles);
  };
  const onCancelUpload = () => {
    setShowAdd(true);
    setFileNames([]);
    setNewImage({ specId: showGallery.projectSpecId, imageName: '', imageDescription: '', imageUrlList: [] });
  };
  const onSaveImage = () => {
    const inputObj = { imageUrlList: [] };
    inputObj.imageName = newImage.imageName;
    inputObj.imageDescription = newImage.imageDescription;
    inputObj.specId = showGallery.projectSpecId;
    newImage.imageUrlList.map((item, key) => {
      inputObj.imageUrlList.push({
        subName: item.imageName,
        imageUrl: item.imageUrl,
        subImageName: item.subImageName
      });

      if (newImage.imageUrlList.length === 1) {
        inputObj.imageUrlList[key].subNumber = 0;
      } else {
        inputObj.imageUrlList[key].subNumber = key + 1;
      }
      return null;
    });
    saveGalleryImages(inputObj)?.then((res) => {
      if (res.success) {
        showAlert('Successfully saved', 'success');
        onCancelUpload();
        getAllImages();
      }
    });
  };
  const deleteApiCall = (field) => {
    deleteGalleryImages({ id: showGallery.projectSpecId, imageId: field.imageId }).then((res) => {
      if (res.success) {
        showAlert('Successfully deleted', 'success');
        getAllImages();
      }
      setConfirmDelete(false);
    });
  };
  const onDelete = (field) => {
    setConfirmDelete(field);
  };
  return (
    <div data-testid="gallery" className="h-full">
      {loading ? (
        <Loader />
      ) : (
        <div>
          {showAdd ? (
            <div className="h-full">
              <div className="font-semibold font-18 mb-2">
                <span data-tooltip-id="project-progress" data-tooltip-content={showGallery.title}>
                  {`${showGallery.specificationNo}. ${_.truncate(
                    showGallery.title,
                    { length: 50 }
                  )}`}

                </span>
              </div>
              <Tooltip id="project-progress" place="top" className="tooltip " />
              {allData.length === 0 && (
                <div data-testid="card-wrapper" className="mt-3 p-4 d-flex justify-content-center">
                  {' '}
                  No data found
                </div>
              )}
              {allData.map((item) => {
                return (
                  <Activites
                    item={item}
                    onDelete={onDelete}
                    isCompleted={isCompleted}
                    isTSI={isTSI}
                  />
                );
              })}
              {!isCompleted && (isTSI || isVesselUser) && (
                <div
                  className="font-28 mt-2 round-btn"
                  id="addnew"
                  onClick={() => {
                    setShowAdd(false);
                  }}
                >
                  +
                </div>
              )}
            </div>
          ) : (
            <div>
              <button onClick={() => onCancelUpload()} type="button" className="link-btn my-3">
                <span className="icon-arrow-left2 me-2" />
                Back
              </button>
              <div className="font-semibold font-18 mb-3">
                <span data-tooltip-id="project-progress" data-tooltip-content={showGallery.title}>
                  {`${showGallery.specificationNo}. ${_.truncate(
                    showGallery.title,
                    { length: 50 }
                  )}`}
                </span>

              </div>
              <AddImages
                ondrop={ondrop}
                file={file}
                setFile={setFile}
                newImage={newImage}
                setNewImage={setNewImage}
                onRemoveImg={onRemoveImg}
                onDelete={onDelete}
              />
              <div className=" fixed-footer d-flex justify-content-end">
                <button type="button" className="secondary-btn me-3" onClick={onCancelUpload}>
                  Cancel
                </button>
                <button
                  type="button"
                  id="save"
                  className="primary-btn me-3"
                  disabled={!newImage.imageDescription
                    || !newImage.imageName || !newImage.imageUrlList.length}
                  onClick={onSaveImage}
                >
                  Save
                </button>
              </div>
            </div>
          )}
          {confirmDelete && (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall(confirmDelete)}
              closeModal={() => setConfirmDelete(false)}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete?</p>
            </ModalBox>
          )}
        </div>
      )}
    </div>
  );
};
export default Gallery;
