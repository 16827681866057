import JoditEditor from 'jodit-react';
import React, { useMemo } from 'react';

const SendVariations = ({ previewContent }) => {
  const config = useMemo(
    () => ({
      readonly: true
    })
  );
  const email = previewContent?.yardData?.contactInfo.email.join().replaceAll(',', ', ');
  return (
    <div className="p-5">
      <div className="row mb-3">
        <div className=" col-6 head-14">
          {/* <img
            src={previewContent.yardData?.flagImage}
            alt="new"
            height="150px"
            width="150px"
          />
          <br /> */}
          To,
          <p className="d-flex">{email}</p>
        </div>
        <div className="head-12 mt-3">
          {`Subject: ${previewContent?.subject} `}

        </div>
      </div>
      <div className="d-flex align-text-justify mt-5">
        <div className="editor-wrap-description view-jodit">
          <JoditEditor
            config={config}
            value={previewContent?.mailContent}
            readOnly
          />
        </div>
      </div>
    </div>
  );
};
export default SendVariations;
