import React from 'react';
import ReportView from './reportView';
import AddNewJob from './addNewJob';
// import Loader2 from '../../../../../common/loader2';

const ReportWrapper = (props) => {
  const { showReport, projectBasicDetails } = props;
  // if (loader) {
  //   return <Loader2 />;
  // }
  if (showReport) {
    return <ReportView {...props} projectBasicDetails={projectBasicDetails} />;
  }
  return <AddNewJob {...props} />;
};
export default ReportWrapper;
