import React from 'react';
import DashBoard from '../../TSI/DashBoard';

const Admin = () => {
  return (
    <>
      <div className="" data-testid="admin-dashboard">
        <div className="container-fluid ">
          <DashBoard showHeader={false} />
        </div>
      </div>
    </>
  );
};
export default Admin;
