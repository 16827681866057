import React, { useEffect, useState } from 'react';
import { termsContentApi } from '../Login/services';
import Terms from './terms';

const Conditions = ({ setOpenModal, acceptTerms, token }) => {
  console.log('openModal', token);
  const [showContent, setShowContent] = useState('');
  const conditionContent = () => {
    termsContentApi().then((res) => {
      console.log(res, 'kdf');
      setShowContent(res?.data);
    });
  };
  console.log(showContent, 'showContent');
  useEffect(() => {
    conditionContent();
  }, []);
  return (
    <div className="terms-strip" data-testid="terms-conditions">
      <Terms content={showContent} />
      <div className="d-flex justify-content-center align-items-end py-4">
        <div><button type="button" className="secondary-btn xlarge" id="open-modal" onClick={() => { setOpenModal(false); }}>Decline</button></div>
        <div><button type="button" className="primary-btn xlarge ms-4" id="close-modal" onClick={() => { acceptTerms(); }}>Accept</button></div>
      </div>
    </div>
  );
};

export default Conditions;
