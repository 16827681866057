/* istanbul ignore file */
import React, { useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const ModalWindow = (props) => {
  const navigate = useNavigate();
  const { projInfo } = props;
  let x;
  const backtoProjects = () => {
    clearInterval(x);
    navigate('/user/projects');
  };
  const timer = () => {
    const countDownDate = new Date(moment().add(1, 'minutes')).getTime();
    x = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      document.getElementById('countdownTimer').innerHTML = `${minutes}m ${seconds}s `;
      if (distance < 0) {
        clearInterval(x);
        backtoProjects();
      }
    }, 1000);
  };
  useEffect(() => {
    timer();
  }, []);
  useEffect(() => {
    return () => {
      clearInterval(x);
    };
  }, []);
  return (
    <div className="d-flex justify-content-center flex-column align-items-center" data-testid="confirmbuttonpage">
      <div className="head-18 pb-4 white-space-preserve">
        {projInfo.name}
      </div>
      <span className="icon-check-rnd tick-green font-50 pb-4" />
      <div className="mt-3">
        <p className="font-15 font-semibold">Project closed !!</p>
      </div>
      <div className="p-3">
        <button type="button" className="primary-btn large m-3 p-3" id="backtoprojects" onClick={backtoProjects}>
          Back to projects
        </button>
      </div>
      <div className="d-flex text-center ms-5">
        <p className="d-flex mt-2 ms-3">
          System will automatically be redirected into home page -within
          <div id="countdownTimer" className="ms-3 font-semibold" />
        </p>
      </div>
    </div>
  );
};
export default ModalWindow;
