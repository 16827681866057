/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import InputType1 from '../../../../../common/InputType1';
import { getProjectRepairCost, saveProjectRepairCost } from '../services';
import ModalBox from '../../../../../common/ModalBox';
import { getProjectDetailsTSI } from '../../../NewProject/services';

const UpdateCostModal = ({ newCost, setNewCost, updateCostModal, projectId, onReloadSpecData }) => {
  const { charValidation, handleValidation, formatNumber, fieldValidation } = helper;
  // eslint-disable-next-line no-unused-vars
  const [state, setstate] = useState([]);
  const [lastUpdatedQty, setLastUpdatedQty] = useState('');
  const [lastUpdatedRate, setLastUpdatedRate] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const initialData = {
    specId: updateCostModal?.specData.projectSpecId,
    serviceLineId: updateCostModal?.serviceLineData.id,
    updatedQuantity: '',
    updatedRate: '',
    description: '',
    updatedAt: new Date()
  };
  const [formData, setFormData] = useState(initialData);
  const Cancel = () => {
    setNewCost(false);
  };
  const resetForm = () => {
    if (updateCostModal) {
      const iFrom = {
        specId: updateCostModal?.specData.projectSpecId,
        serviceLineId: updateCostModal?.serviceLineData.id,
        updatedQuantity: '',
        updatedRate: '',
        description: '',
        updatedAt: new Date()
      };
      setFormData(iFrom);
    }
  };
  const reloadTable = () => {
    getProjectRepairCost(projectId, updateCostModal?.serviceLineData.id).then((res) => {
      setstate(res.data);
    });
  };
  useEffect(() => {
    resetForm();
    reloadTable();
    setLastUpdatedQty();
    setLastUpdatedQty(updateCostModal?.serviceLineData.quantity || 0);
    setLastUpdatedRate(updateCostModal?.serviceLineData.rate || 0);
    getProjectDetailsTSI({ id: projectId }).then((res) => {
      setProjectBasicDetails(res.data);
    });
  }, []);

  const saveBefore = () => {
    if ((formData.updatedQuantity !== '' || formData.updatedRate !== '') && formData.description) {
      setOpenDeleteModal(true);
    } else {
      //
      // eslint-disable-next-line no-lonely-if
      if (formData.description) {
        showAlert('Please enter the Updated Quantity Or Updated Price', 'error');
      } else {
        showAlert('Please enter the mandatory(*) fields', 'error');
      }
    }
  };
  /* istanbul ignore next */
  const save = () => {
    const fd = { ...formData };
    if ((formData.updatedQuantity !== '' || formData.updatedRate !== '') && formData.description) {
      if (formData.updatedQuantity === '') {
        fd.updatedQuantity = lastUpdatedQty;
      }
      if (formData.updatedRate === '') {
        fd.updatedRate = lastUpdatedRate;
      }
      saveProjectRepairCost(fd).then((res) => {
        setOpenDeleteModal(false);
        setTimeout(() => {
          document.body.classList.add('model-open');
        }, 30);
        if (res.success) {
          setLastUpdatedQty(fd.updatedQuantity);
          setLastUpdatedRate(fd.updatedRate);
          reloadTable();
          Cancel();
          resetForm();
          showAlert('Successfully Added', 'success');
          onReloadSpecData();
        }
      });
    } else {
      setTimeout(() => {
        document.body.classList.add('model-open');
      }, 30);
      //
      // eslint-disable-next-line no-lonely-if
      if (formData.description) {
        showAlert('Please enter the Updated Quantity Or Updated Price', 'error');
      } else {
        showAlert('Please enter the mandatory(*) fields', 'error');
      }
    }
  };
  const handleChangeData = (e, field) => {
    const fd = _.cloneDeep(formData);
    if (field === 'updatedQuantity') {
      fd[field] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6);
    } else if (field === 'updatedRate') {
      fd[field] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 5);
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
  };
  return (
    <div data-testid="update-cost">
      {newCost && (
        <div className="card p-3">
          <div className="row">
            <div className="col-3">
              <InputType1
                id="updatedQuantity"
                value={formData.updatedQuantity}
                onChange={(e) => handleChangeData(e, 'updatedQuantity')}
                className="modal-input mt-1"
                label="Updated Quantity"
                // type="number"
                // min="0"
                // step="0.01"
              />
            </div>
            <div className="col-3">
              <InputType1
                id="updatedRate"
                value={formData.updatedRate}
                onChange={(e) => handleChangeData(e, 'updatedRate')}
                disabled={updateCostModal?.serviceLineData?.specificationLevel === 4}
                className="modal-input mt-1"
                label="Updated Price"
                // type="number"
                // min="0"
                // step="0.01"
              />
            </div>
            <div className="col-4">
              <InputType1
                id="description"
                disabled={formData.updatedQuantity === '' && formData.updatedRate === ''}
                value={formData.description}
                onChange={(e) => handleChangeData(e, 'description')}
                className="modal-input mt-1"
                label="Description"
                isMandatory="true"
              />
            </div>
            <div className="align-items-end col-2 d-flex">
              <div
                id="cancel"
                type="button"
                className="secondary-btn me-3 large"
                onClick={() => {
                  Cancel();
                  resetForm();
                }}
              >
                Cancel
              </div>
              <div
                id="addnew"
                onClick={() => {
                  saveBefore(true);
                }}
                type="button"
                className="primary-btn large justify-content-start"
              >
                Add
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white br-5 pt-3 px-3 mt-5">
        <table className="table-striped">
          <thead>
            <tr>
              <th className="col-1 brand-color">
                #
                {updateCostModal?.serviceLineData.number}
              </th>
              <th className="col-3">
                <div className=" w-250 text-ellipsis">
                  <span data-tooltip-id="project-sectitle" data-tooltip-content={updateCostModal?.serviceLineData.serviceLineName}>{updateCostModal?.serviceLineData.serviceLineName}</span>

                </div>
              </th>
              <th>
                <div className="d-flex whitespace-pre align-items-center">
                  <label>
                    Quantity : &nbsp;
                  </label>
                  <span data-tooltip-id="project-sectitle" data-tooltip-content={lastUpdatedQty} className="value text-ellipsis ms-1">
                    {_.truncate(lastUpdatedQty, {
                      length: 14,
                      separator: ' '
                    })}

                  </span>
                </div>
              </th>
              <th>
                <div className="d-flex whitespace-pre align-items-center">
                  <label>
                    Price :
                  </label>
                  <span data-tooltip-id="project-sectitle" data-tooltip-content={formatNumber(lastUpdatedRate)} className="value text-ellipsis ms-1">
                    {' '}
                    {projectBasicDetails?.currencySymbol}
                    {' '}
                    {/* {lastUpdatedRate ? formatNumber(lastUpdatedRate) : 0}
                    {projectBasicDetails?.currencySymbol} */}
                    {/* {' '} */}
                    {/* {i.updatedRate ? formatNumber(i.updatedRate) : 0} */}
                    {_.truncate(`${lastUpdatedRate ? formatNumber(lastUpdatedRate) : 0}`, {
                      length: 14,
                      separator: ' '
                    })}
                  </span>
                </div>

              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray">
              <td />
              <td>
                <div className="w-230 text-ellipsis">
                  <label>Updated on</label>
                </div>
              </td>
              <td>
                <div className="d-flex w-150">
                  <label>
                    Updated Quantity
                  </label>
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <label>
                    Updated Price
                  </label>

                </div>
              </td>
              <td>
                <div className="w-150  ">
                  <label>Description</label>
                </div>
              </td>
              <td>
                <div className="w-110">
                  <label>Updated By</label>
                </div>
              </td>
            </tr>
            {
              state && state.length
                ? (
                  <>
                    {state.map((i) => (
                      <tr>
                        <td />
                        <td>
                          <div className="w-230 text-ellipsis">
                            <div className="value">
                              {/* {moment.utc(i.updatedAt).format('DD-MMM-YYYY')} */}
                              {moment.utc(i.updatedAt.split('.')[0]).format('DD-MMM-YYYY HH:mm')}
                              {' '}
                              (UTC)
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex w-150">
                            <span data-tooltip-id="project-sectitle" data-tooltip-content={i.updatedQuantity} className="text-ellipsis">
                              {_.truncate(i.updatedQuantity, {
                                length: 8,
                                separator: ' '
                              })}

                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex">
                            <span data-tooltip-id="project-sectitle" data-tooltip-content={formatNumber(i.updatedRate)} className="text-ellipsis">
                              {projectBasicDetails?.currencySymbol}
                              {' '}
                              {/* {i.updatedRate ? formatNumber(i.updatedRate) : 0} */}
                              {_.truncate(`${i.updatedRate ? formatNumber(i.updatedRate) : 0}`, {
                                length: 14,
                                separator: ' '
                              })}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="w-150 text-ellipsis ">
                            <span data-tooltip-id="project-sectitle" data-tooltip-content={i.description} className="text-ellipsis">
                              {_.truncate(i.description, {
                                length: 10,
                                separator: ' '
                              })}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="w-110 text-ellipsis ">
                            <span data-tooltip-id="project-sectitle" data-tooltip-content={i.updatedByRoleName} className="text-ellipsis">{i.updatedByRoleName ? i.updatedByRoleName : '-'}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )
                : (
                  <tr>
                    <td colSpan="5">
                      <div className="p-4 d-flex justify-content-center align-items-center" data-testid="yard-attach">
                        <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
                        <div className="font-18 animate__animated animate__headShake">
                          No data found
                        </div>
                      </div>
                    </td>
                  </tr>
                )
            }
          </tbody>
        </table>
      </div>
      {openDeleteModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Confirm"
          onSave={() => save()}
          closeModal={() => {
            setOpenDeleteModal(false);
            setTimeout(() => {
              document.body.classList.add('model-open');
            }, 30);
          }}
          buttonName="Confirm"
        >
          <p>Are you sure you want to save changes ?</p>
        </ModalBox>
      ) : (
        ''
      )}
      <Tooltip id="project-sectitle" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default UpdateCostModal;
