import React, { useEffect } from 'react';

const ChatBox = ({ heading, children, closeModal, modalClassName = 'medium', isCloseModal = true }) => {
  useEffect(() => {
    document.body.classList.add('model-open');
  }, [heading]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('model-open');
    };
  }, []);
  return (
    <div className="back-drop">
      <div
        className={`chat animate__animated animate__bounceIn ${modalClassName}`}
      >
        <div className="modal-header pb-3 border-down">
          <h3 className="">{heading}</h3>
          {isCloseModal && (
            <button id="closeModal" type="button" className="link-btn" onClick={closeModal}>
              <span className="icon-close font-26"> </span>
            </button>
          )}
        </div>
        <div>{children}</div>

      </div>
    </div>
  );
};

export default ChatBox;
