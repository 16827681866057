import axios from 'axios';
import { serviceHandler } from '../../../../utils/serviceHandler';
import apiEndPoints from '../../../../utils/apiUrlConstants';
import helper from '../../../../utils/helper';

const {
  getTaskUrl,
  saveTaskUrl,
  deleteTaskUrl,
  deleteSubTaskUrl,
  getTaskDetailUrl,
  getRoleListUrl,
  GetProjectTypeListUrl,
  saveTaskDetailsUrl,
  PublishTaskListUrl,
  GetPublishedStatusDetailsUrl,
  GenerateTemplateTask,
  BulkUploadTask,
  ExportExcelTask
} = apiEndPoints;

const { downloadFile, currentDate } = helper;

export const getPublishedStatusDetails = (payload) => {
  return serviceHandler({
    url: `${GetPublishedStatusDetailsUrl}`,
    methordType: 'get',
    payload
  });
};
export const getTask = (payload) => {
  return serviceHandler({
    url: `${getTaskUrl}`,
    methordType: 'post',
    payload
  });
};

export const getRoleList = (vesselId) => {
  return serviceHandler({
    url: `${getRoleListUrl}?vesselId=${vesselId}`,
    methordType: 'get'
  });
};
export const saveTask = (payload) => {
  return serviceHandler({
    url: `${saveTaskUrl}`,
    methordType: 'post',
    payload
  });
};
export const deleteTask = (payload) => {
  return serviceHandler({
    url: `${deleteTaskUrl}?taskId=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const deleteSubTask = (payload) => {
  return serviceHandler({
    url: `${deleteSubTaskUrl}?taskId=${payload}`,
    methordType: 'delete',
    payload
  });
};

export const getTaskDetail = (payload) => {
  return serviceHandler({
    url: `${getTaskDetailUrl}?taskId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const GetProjectTypeList = (payload) => {
  return serviceHandler({
    url: `${GetProjectTypeListUrl}?status=${payload}`,
    methordType: 'get',
    payload
  });
};
export const saveTaskDetail = (payload) => {
  return serviceHandler({
    url: `${saveTaskDetailsUrl}`,
    methordType: 'put',
    payload
  });
};
export const publishTaskList = (payload) => {
  return serviceHandler({
    url: `${PublishTaskListUrl}`,
    methordType: 'put',
    payload
  });
};
export const exportExcelTask = (searchText) => {
  return axios
    .get(`${ExportExcelTask}?search=${searchText}`, {
      responseType: 'blob'
    })
    .then((response) => {
      console.log(response, 'response');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Task_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const bulkUploadTask = (payload) => {
  return serviceHandler({
    url: `${BulkUploadTask}`,
    methordType: 'post',
    payload
  });
};

export const generatTemplate = () => {
  return axios
    .get(`${GenerateTemplateTask}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `Task_${currentDate()}.xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
