import React, { useState } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

function DatePicker(props) {
  const { mainIndex, subIndex, specList, setSpecList, onDateUpDate, isCompleted, isTSI } = props;
  const { startDate } = specList.specQuoteList[mainIndex].specDataList[subIndex];
  const { endDate } = specList.specQuoteList[mainIndex].specDataList[subIndex];
  const [focusedInput, setFocusedInput] = useState(null);
  /* istanbul ignore next */
  const handleDatesChange = (e) => {
    const currentSpec = { ...specList };
    if (e.startDate) {
      currentSpec.specQuoteList[mainIndex].specDataList[subIndex]
        .startDate = `${moment(e.startDate).format('YYYY-MM-DDT00:00:00')}Z`;
    }
    if (e.endDate) {
      currentSpec.specQuoteList[mainIndex].specDataList[subIndex]
        .endDate = `${moment(e.endDate).format('YYYY-MM-DDT00:00:00z')}Z`;
    }
    setSpecList(currentSpec);
  };
  const onClose = (e) => {
    onDateUpDate(e, mainIndex, subIndex);
  };
  return (
    <DateRangePicker
      startDate={startDate ? moment(startDate) : startDate}
      startDateId="tata-start-date"
      isOutsideRange={(day) => {
        return day.isBefore(moment(specList.repairStartDate)) || day.isAfter(moment(specList.repairStartDate).add(specList.repairDays, 'days'));
      }}
      id="d-pick"
      displayFormat="DD-MM-YYYY"
      disabled={!isTSI || isCompleted}
      endDate={endDate ? moment(endDate) : endDate}
      endDateId="tata-end-date"
      onDatesChange={handleDatesChange}
      focusedInput={focusedInput}
      onClose={onClose}
      onFocusChange={(focusedInputs) => setFocusedInput(focusedInputs)}
    />
  );
}

export default DatePicker;
