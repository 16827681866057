import axios from 'axios';
import { serviceHandler } from '../../../utils/serviceHandler';
import apiEndPoints from '../../../utils/apiUrlConstants';
import helper from '../../../utils/helper';

const {
  GetSupplementarySpecSendList,
  SaveSupplementarySpecQuote,
  getUpdateQuoteStatus,
  DownloadSupplementaryExcel,
  DownloadSupplemenetaryQuotation

} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const getSupplementarySpecSendList = (payload) => {
  return serviceHandler({
    url: `${GetSupplementarySpecSendList}?key=${payload.key}&searchString=${payload.searchString}`,
    methordType: 'get',
    payload
  });
};

export const saveSupplementarySpecQuote = (payload) => {
  return serviceHandler({
    url: `${SaveSupplementarySpecQuote}`,
    methordType: 'post',
    payload
  });
};
export const UpdateQuoteStatus = (payload) => {
  return serviceHandler({
    url: `${getUpdateQuoteStatus}`,
    methordType: 'post',
    payload
  });
};
export const downloadDocuments = (payload) => {
  return axios
    .get(`${DownloadSupplementaryExcel}?key=${payload.key}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const downloadExcelYard = (payload) => {
  return axios
    .get(`${DownloadSupplemenetaryQuotation}?key=${payload.key}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
