import React from 'react';
import { cloneDeep } from 'lodash';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const CreateFolder = ({ formData, setFormData, cancel, handleCreateNew }) => {
  const { charValidation } = helper;
  const handleChange = (e, field) => {
    const fd = cloneDeep(formData);
    fd[field] = charValidation(e.target.value);
    setFormData(fd);
  };
  return (
    <>
      <div data-testid="dms-1">
        <div className="newfold-box card p-4 animate__animated animate__bounceIn">
          <div className="">
            <div className="">
              <InputType1
                autoFocus
                id="name"
                onChange={(e) => handleChange(e, 'name')}
                value={formData?.name}
                className="modal-inputs small "
                label="Name"
                isMandatory="true"
              />
            </div>
            <div className="col-gap-1 d-flex justify-content-end pt-3">
              <button id="cancel" className="secondary-btn " type="button" onClick={() => cancel()}>
                Cancel
              </button>
              <button id="save" className="primary-btn " type="button" onClick={() => handleCreateNew()}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateFolder;
